import React from "react";
import hstore from "hstore.js";

import "./index.scss";

export const MapPopup = (props) => {
  const { data } = props;
  if (!data) return null;

  const deserialized = { ...data, ...hstore.parse(data?.tags_as_text) };
  delete deserialized.tags_as_text;
  delete deserialized["0"];
  return (
    <div className="map-popup">
      <h3 className="map-popup-title">Building: {deserialized.ogc_fid}</h3>
      <div className="map-popup-content">
        {Object.entries(deserialized).map(([key, value]) => (
          <div key={key} className="map-popup-content-item">
            {key}: {value}
          </div>
        ))}
      </div>
    </div>
  );
};
