import React, { useRef, useEffect, useState, useContext } from "react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from "!mapbox-gl";
import * as Layers from "./layers";

import { useMapPopup } from "../../ui/map-popup/use-map-popup";
import { MapContext } from "../../context/map-context";

import "./index.scss";
import { LayersContext } from "../../context/layers-context";

mapboxgl.accessToken =
  "pk.eyJ1Ijoic3VubnlzYW53YXIiLCJhIjoiY2wwNjV5N3kzMDQwbTNib2NhMnd6NGg2dCJ9.501q9aEzAkIe4RzQm-IzQg";

let kansasBuildingsLayer;
let missouriBuildingsLayer;
let marylandBuildingsLayer;
let northCarolinaBuildingsLayer;

let kansasParcelsLayer;
let missouriParcelsLayer;
let marylandParcelsLayer;
let northCarolinaParcelsLayer;

export const Map = ({ center, _zoom, parcelnumb, setParcelnumb }) => {
  const [filters] = useContext(MapContext);
  const [layers] = useContext(LayersContext);

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(-94.615);
  const [lat, setLat] = useState(38.9634);
  const [zoom, setZoom] = useState(_zoom || 9);
  const popupCtrl = useMapPopup(map);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/sunnysanwar/cky3uq73m6x3e14l56ql6hxaa",
      center: center || [-94.615, 38.9634],
      zoom: _zoom || 9
    });

    window.map = map.current;
    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom());
    });

    map.current.on("load", () => {
      if (!kansasBuildingsLayer) {
        kansasBuildingsLayer = new Layers.Buildings.BuildingsLayer({
          map: map.current,
          id: "kansas",
          sourceLayer: "kansas_buildings_ms_osm_landgrid",
          popupCtrl
        });
      }
      if (!missouriBuildingsLayer) {
        missouriBuildingsLayer = new Layers.Buildings.BuildingsLayer({
          map: map.current,
          id: "missouri",
          sourceLayer: "missouri__buildings_ms_osm_landgrid",
          popupCtrl
        });
      }
      if (!marylandBuildingsLayer) {
        marylandBuildingsLayer = new Layers.Buildings.BuildingsLayer({
          map: map.current,
          id: "maryland",
          sourceLayer: "maryland_buildings_ms_osm_landgrid",
          popupCtrl
        });
      }
      if (!northCarolinaBuildingsLayer) {
        northCarolinaBuildingsLayer = new Layers.Buildings.BuildingsLayer({
          map: map.current,
          id: "north_carolina",
          sourceLayer: "north_carolina_buildings_ms_osm_landgrid",
          popupCtrl
        });
      }

      if (!kansasParcelsLayer) {
        kansasParcelsLayer = new Layers.Parcels.ParcelsLayer({
          map: map.current,
          id: "kansas_parcels_new_cities",
          sourceLayer: "kansas_parcels_new_cities",
          popupCtrl,
          setParcelnumb
        });
      }
      if (!missouriParcelsLayer) {
        missouriParcelsLayer = new Layers.Parcels.ParcelsLayer({
          map: map.current,
          id: "missouri_parcels_cities",
          sourceLayer: "missouri_parcels_cities",
          popupCtrl,
          setParcelnumb
        });
      }
      if (!marylandParcelsLayer) {
        marylandParcelsLayer = new Layers.Parcels.ParcelsLayer({
          map: map.current,
          id: "maryland_parcels_cities",
          sourceLayer: "maryland_parcels_cities",
          popupCtrl,
          setParcelnumb
        });
      }
      if (!northCarolinaParcelsLayer) {
        northCarolinaParcelsLayer = new Layers.Parcels.ParcelsLayer({
          map: map.current,
          id: "north_carolina_parcels_cities",
          sourceLayer: "north_carolina_parcels_cities",
          popupCtrl,
          setParcelnumb
        });
      }

      kansasParcelsLayer.init();
      missouriParcelsLayer.init();
      marylandParcelsLayer.init();
      northCarolinaParcelsLayer.init();

      kansasParcelsLayer?.hide();
      missouriParcelsLayer?.hide();
      marylandParcelsLayer?.hide();
      northCarolinaParcelsLayer?.hide();

      missouriBuildingsLayer.init();
      kansasBuildingsLayer.init();
      marylandBuildingsLayer.init();
      northCarolinaBuildingsLayer.init();
    });
  }, [center, popupCtrl, _zoom, setParcelnumb]);

  useEffect(() => {
    kansasBuildingsLayer?.setFilter(filters);
    missouriBuildingsLayer?.setFilter(filters);
    marylandBuildingsLayer?.setFilter(filters);
    northCarolinaBuildingsLayer?.setFilter(filters);

    return () => {};
  }, [filters]);

  useEffect(() => {
    if (!layers) return;
    const [buildings, parcels] = layers;

    if (!buildings.value) {
      missouriBuildingsLayer?.hide();
      kansasBuildingsLayer?.hide();
      marylandBuildingsLayer?.hide();
      northCarolinaBuildingsLayer?.hide();
    } else {
      missouriBuildingsLayer?.show();
      kansasBuildingsLayer?.show();
      marylandBuildingsLayer?.show();
      northCarolinaBuildingsLayer?.show();
    }

    if (!parcels.value) {
      kansasParcelsLayer?.hide();
      missouriParcelsLayer?.hide();
      marylandParcelsLayer?.hide();
      northCarolinaParcelsLayer?.hide();
    } else {
      kansasParcelsLayer?.show();
      missouriParcelsLayer?.show();
      marylandParcelsLayer?.show();
      northCarolinaParcelsLayer?.show();
    }

    return () => {};
  }, [layers]);

  useEffect(() => {
    const layers = [
      missouriBuildingsLayer,
      kansasBuildingsLayer,
      marylandBuildingsLayer,
      northCarolinaBuildingsLayer,
      kansasParcelsLayer,
      missouriParcelsLayer,
      marylandParcelsLayer,
      northCarolinaParcelsLayer
    ];
    if(parcelnumb){
      layers.forEach(l=>l?.setParcelFilter(parcelnumb))
    }else{
      layers.forEach(l=>l?.setParcelFilter(null))
    }

    return () => {};
  }, [parcelnumb]);

  return (
    <>
      <div className="coordinates">
        Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
      </div>
      <div ref={mapContainer} className="map-container" />
    </>
  );
};
