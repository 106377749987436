import React, { useRef } from "react";
import mapboxGl from "mapbox-gl";
import ReactDOM from "react-dom";
import { MapPopup } from ".";

const useMapPopup = (map) => {
  const popUpRef = useRef(
    new mapboxGl.Popup({ maxWidth: "312px", offset: 14 })
  );

  return {
    render: ({ properties, center }) => {
      const popupNode = document.createElement("div");

      ReactDOM.render(<MapPopup data={properties} />, popupNode);

      popUpRef.current
        .setLngLat(center)
        .setDOMContent(popupNode)
        .addTo(map.current);
    },
    remove: () => {
      popUpRef.current.remove();
    },
  };
};

export { useMapPopup };
