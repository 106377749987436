const amenityFilterOptions = [
  "11",
  "22",
  "911 call center",
  "Administrative office",
  "Amphitheater",
  "Automotive",
  "BBQ",
  "Base_entry",
  "Brewery",
  "Casitas Del Sol",
  "Clery",
  "Combis",
  "Commercial Printing Facility",
  "Concession_Stand",
  "Condos",
  "Counseling",
  "DIY",
  "Daycare Center",
  "Drainage",
  "East Alabama Medical Center Financial Services",
  "East Residence Halls",
  "Electric_vehicle_charging_station",
  "Event_Center",
  "FAA FSDO",
  "Festival_Grounds",
  "Fire_Training_Facility",
  "Fishbowl",
  "Flight_School",
  "Gym",
  "Highland_Meadows_Office_/_Laundry_/_Mailboxes",
  "Historic_Site",
  "Indoor mausoleum",
  "Library",
  "Mainstream Automotive",
  "Massage Therapy",
  "Massage Therepy",
  "Modular Classrooms",
  "Mortgage",
  "Motor Vehicle Administration",
  "Northwestern_Mutual",
  "Open Green Area",
  "Outdoor Mausoleum",
  "Pediatric Therapy",
  "Personal Training",
  "Pet Supplies",
  "Plaza",
  "Police",
  "Police Jury",
  "Powell Valley National Bank",
  "Princeton University",
  "Property Management Company",
  "Radio station",
  "Salvation Army",
  "School",
  "Service Station",
  "Shelter Slab",
  "Skating Rink",
  "Ski_Patrol",
  "Stomp_Dance",
  "Student_Residence",
  "Tesla_charging_station",
  "The_Hair_Lift",
  "Tim Horton's",
  "Tobogganing",
  "Toilets:Flush",
  "Truck_inspection",
  "USDA agricultural research service",
  "Urgent Care",
  "Whiting_Criminal_Justice_Building",
  "Wifi_Internet_Coverage",
  "William W. Scranton Commons",
  "accounting",
  "activity center",
  "advice",
  "air_vent",
  "aircraft_fuel",
  "airway beacon",
  "alcohol",
  "amenity",
  "amphitheater",
  "amphitheatre",
  "amusement_arcade",
  "animal_boarding",
  "animal_boarding; animal_training",
  "animal_breeding",
  "animal_control",
  "animal_shelter",
  "animal_training",
  "antiques",
  "apartment",
  "apartments",
  "archive",
  "area-steps",
  "arena",
  "argricultural_center",
  "arking",
  "armory",
  "art_center",
  "art_gallery",
  "art_school",
  "art_studio",
  "arts_centre",
  "assisted-living-center",
  "assisted_living;skilled_nursing_facility",
  "association",
  "atm",
  "atta",
  "attorney",
  "attraction",
  "auction_house",
  "audiologist",
  "auditorium",
  "auto",
  "auto body shop",
  "auto:service",
  "automobile_club",
  "automotive parts",
  "autoparts",
  "baby_hatch",
  "bail_bonds",
  "bailbond",
  "bak",
  "baking_oven",
  "ballroom",
  "band stand",
  "band_shell",
  "bandstand",
  "bank",
  "bank ATM",
  "bank;fire_station;fast_food",
  "banquet_hall",
  "bar",
  "bar/food",
  "bar/restaurant",
  "bar;pub",
  "bar;restaurant",
  "basketball",
  "basketball_courts",
  "basketballl",
  "bat",
  "bath_house;toilets",
  "batting_cage",
  "batting_cages",
  "bbq",
  "bbq;waste_basket",
  "beach",
  "beauty",
  "beauty_school",
  "beer_garden",
  "bell",
  "bench",
  "beverages",
  "bicycle_parking",
  "bicycle_parking;motorcycle_parking",
  "bicycle_rental",
  "bicycle_repair_station",
  "biergarten",
  "biergarten;bar",
  "billiards",
  "bin",
  "bleac",
  "bleacher",
  "bleachers",
  "boat",
  "boat_dock",
  "boat_rental",
  "boat_repair",
  "boat_sharing",
  "boat_storage",
  "boat_storage; rv_storage",
  "boatyard",
  "books",
  "books;cafe",
  "booth",
  "botanical_gardens",
  "bowling_alley",
  "breezeway",
  "brewery",
  "brokerage",
  "brothel",
  "bui",
  "building_supply",
  "building_yard",
  "bureau_de_change",
  "bus_depot",
  "bus_garage",
  "bus_parking",
  "bus_station",
  "bus_stop",
  "business",
  "business_center",
  "cafe",
  "cafe;animal_shelter",
  "cafe;bicycle_rental",
  "cafe;fast_food",
  "cafe;pharmacy",
  "cafe;restaurant",
  "cafeteria",
  "café",
  "camp",
  "campground",
  "camping",
  "canadian_flag",
  "canteen",
  "car",
  "car_auction",
  "car_charging",
  "car_dealership",
  "car_parts",
  "car_pooling",
  "car_rental",
  "car_rental;bicycle_rental",
  "car_repair1",
  "car_sales",
  "car_service",
  "car_sharing",
  "car_wash",
  "car_wash; toilets",
  "car_wash;fuel",
  "cara",
  "caravan_rental",
  "caravan_storage",
  "cargo",
  "carousel",
  "cart_return",
  "cash",
  "casino",
  "catering",
  "cemetary",
  "cemetery",
  "center",
  "changerooms",
  "changing_room",
  "changing_rooms",
  "chapel",
  "charging",
  "charging_station",
  "charity",
  "check_cashing",
  "childcare",
  "childrens_home",
  "chiro",
  "chiropractor",
  "christ",
  "church",
  "cinema",
  "cinema;theatre",
  "city_building",
  "city_hall",
  "civic",
  "civic_centre",
  "classroom",
  "climbing structure",
  "climbing_structure",
  "clinic",
  "clock",
  "closed",
  "closet",
  "clothes",
  "club",
  "clubhouse",
  "co-op",
  "coast_guard",
  "codecademy",
  "coffee",
  "coffee_shop",
  "cold_storage",
  "college",
  "college;firefighter_training",
  "college;place_of_worship",
  "college;theatre",
  "comedy_club",
  "comm",
  "commercial",
  "community garden",
  "community services",
  "community swimming pool",
  "community_centre",
  "community_centre:for=juvenile",
  "community_centre;toilets",
  "community_centre=youth_centre + min_age=4 + max_age=99",
  "community_hall",
  "compressed_air",
  "concert_hall",
  "concession",
  "concession stand",
  "concession_stand",
  "concessions_stand",
  "concierge",
  "concrete_plant",
  "condo",
  "condominium",
  "conference_center",
  "conference_centre",
  "consession",
  "consession stand",
  "construction",
  "construction_equipment_dealer",
  "construction_equipment_supplier",
  "convenience",
  "convenience_store",
  "convent",
  "convention_center",
  "convention_centre",
  "cooking_school",
  "copyshop",
  "corn_maze",
  "corr",
  "counter",
  "country_club",
  "court_house",
  "court_yard",
  "courthouse",
  "courthouse1",
  "courthouse;police",
  "coworking_space",
  "credit union",
  "credit_union",
  "crematorium",
  "crematory_services",
  "cruise_terminal",
  "crypt",
  "culinary_school",
  "culture_center",
  "customs",
  "dancing_school",
  "day_care",
  "daycare",
  "deli",
  "dental",
  "dentist",
  "dentistry",
  "department_store",
  "depot",
  "device_charging_area",
  "device_charging_station",
  "diesel",
  "diet_club",
  "dining",
  "dining_hall",
  "dis",
  "dispatch_center",
  "disused",
  "disused:place_of_worship",
  "disused:post_office",
  "disused:restaurant",
  "disused:school",
  "disused;school",
  "dive_centre",
  "diving_board",
  "dmv",
  "doctors",
  "doctors;dentist",
  "doctors;school",
  "doctorshealthcare=doctor",
  "dog_grooming",
  "dog_toilet",
  "dog_training",
  "dog_wash",
  "dojang",
  "dojo",
  "donuts",
  "dormitory",
  "drain",
  "drainage",
  "dressing_room",
  "dressing_room;toilets",
  "drinking_water",
  "drinking_water;toilets",
  "drinks",
  "driver_testing",
  "driver_training",
  "driving_course",
  "driving_range",
  "driving_school",
  "drug_store",
  "dry cleaner",
  "dry cleaners",
  "dry toilets",
  "dry_cleaner",
  "dry_cleaning",
  "dump_truck_rental",
  "dumpster_rental",
  "education_centre",
  "educational_centre",
  "educational_facility",
  "elementary",
  "elevator",
  "embassy",
  "emergency_exit",
  "emergency_service",
  "emissions_testing",
  "employment_agency",
  "ems_station",
  "equestrian",
  "equipment rental",
  "equipment_rental",
  "event",
  "event_centre",
  "event_hall",
  "event_venue",
  "events_centre",
  "events_enue",
  "events_space",
  "events_venue",
  "events_venues",
  "exercise_point",
  "exhibition_centre",
  "exhibition_hall",
  "f",
  "fair_booth",
  "fairground",
  "fairgrounds",
  "family_life_center",
  "farm",
  "farm_stand",
  "fast_food",
  "fast_food;bath_house;toilets",
  "fast_food;bicycle_rental",
  "fast_food;fuel",
  "feeding_place",
  "ferry_terminal",
  "festival grounds",
  "festival_ground",
  "festival_grounds",
  "festival_grounds,",
  "field_shelter",
  "fieldhouse",
  "film_studio",
  "financial",
  "financial_advice",
  "fire",
  "fire_school",
  "fire_station",
  "fire_station, police",
  "fire_station;police",
  "fire_station;prison",
  "firehouse",
  "firing_range",
  "first aid",
  "first_aid",
  "fish_cleaning",
  "fish_hatchery",
  "fishing",
  "fixed_base_operator",
  "flight_school",
  "food",
  "food_bank",
  "food_court",
  "food_court; parking",
  "food_court;atm;toilets",
  "football",
  "former_place_of_worship",
  "fortune_teller",
  "fortune_telling",
  "fountain",
  "foutain",
  "framing",
  "frateneral_order",
  "fraternity",
  "fue",
  "fuel",
  "fuel distributor",
  "fuel, car_wash",
  "fuel;car wash",
  "fuel;car_wash",
  "fuel;convenience_store",
  "fuel;convenience_store;car_wash",
  "fuel;fast_food",
  "fuel;food_court",
  "fuel;grocery",
  "fuel;ice_cream",
  "fuel_pump",
  "fueld",
  "fun",
  "function_room",
  "funeral_chapel",
  "funeral_directors",
  "funeral_hall",
  "funeral_home",
  "furniture",
  "gallery",
  "gambling",
  "gambling;community_centre",
  "gaming",
  "garage",
  "garden",
  "garden_centre",
  "gas",
  "gasoline",
  "gate",
  "gaz",
  "gazebo",
  "general",
  "general aviation Terminal",
  "give_box",
  "golf_cart_rental",
  "golf_range",
  "government",
  "gr",
  "grandstand",
  "grandstands",
  "grass",
  "grave_yard",
  "greenfield",
  "greenhouse",
  "greenhouses",
  "grill",
  "grit_bin",
  "grocery",
  "grocery store",
  "group_home",
  "guard_house",
  "guide_services",
  "gun_range",
  "gunsmith",
  "gym",
  "gym;pool_hall",
  "gymnasium",
  "hairdresser",
  "hall",
  "harbourmaster",
  "hardware",
  "health_club",
  "healthcare",
  "heavy_equipment_rental",
  "highway_maintenance",
  "historic",
  "historic_site",
  "historical_education",
  "hobby_shop",
  "home_health",
  "homeless_shelter",
  "hookah",
  "hookah_lounge",
  "horse_riding",
  "hospital",
  "hospital;clinic",
  "hostel",
  "hot",
  "house",
  "housing",
  "hunting_stand",
  "ice",
  "ice_cream",
  "ice_cream;shaved_ice",
  "inclined elevator",
  "indoor",
  "indoor_dog_park",
  "indoor_range",
  "industrial",
  "industrial_education",
  "industrial_park",
  "information",
  "information_sign",
  "insurance",
  "internet_cafe",
  "jail",
  "jail_police",
  "jobcentre",
  "judo",
  "juice bar",
  "justice_complex",
  "justice_of_the_peace",
  "karaoke_box",
  "kayak_rental",
  "kennel",
  "kick_scooter_parking",
  "kindergarten",
  "kindergarten;childcare",
  "kindergarten;school",
  "kitchen",
  "kitchen_shelter",
  "labor_union",
  "labyrinth",
  "lake",
  "language_school",
  "laundromat",
  "laundry",
  "laundry_room;mail_box;gym",
  "lavoir",
  "lawyer",
  "lecture_theater",
  "legion",
  "leisure",
  "letter_box",
  "library",
  "library;police;fire_station",
  "licensing",
  "lifeguard",
  "lifeguard_station",
  "loading_dock",
  "loans",
  "locker",
  "lockers",
  "lodge",
  "lodging",
  "lookout",
  "lottery",
  "lounge",
  "love_hotel",
  "maintenance_patrol",
  "maintenence",
  "mall",
  "market",
  "marketplace",
  "massage",
  "math_school",
  "mausoleum",
  "meat_processor",
  "mechanic",
  "medical_practise",
  "meditation",
  "meditation_centre",
  "model_railway",
  "monastery",
  "money_lender",
  "money_transfer",
  "mortuary",
  "mortuary;crematory",
  "motel",
  "motorcraft launch",
  "motorcycle_parking",
  "motorcycle_rental",
  "mountain_rescue",
  "mpp_office",
  "museum",
  "music_rehearsal_place",
  "music_school",
  "music_venue",
  "nature_center",
  "newspaper",
  "nightclub",
  "nightclub;restaurant",
  "no",
  "no_parking",
  "nursery",
  "nursing_home",
  "nursing_home; social_facility",
  "observatory",
  "office",
  "office_supplies",
  "offices",
  "oil_change",
  "old_cinema",
  "old_fire_station",
  "old_parking",
  "old_prison",
  "open_air_stage",
  "orphanage",
  "orthodontist",
  "outdoor_cafe",
  "outdoor_seating",
  "outfitter",
  "p",
  "package_store",
  "pak",
  "palmist",
  "paper_factory",
  "par",
  "park bench",
  "park_office",
  "parking",
  "parking lane",
  "parking lot",
  "parking;post_office",
  "parking;school",
  "parking;theatre",
  "parkingElsa de Jesus Lopez Haro/Mexico/IBM",
  "parking_aisle",
  "parking_booth",
  "parking_disable",
  "parking_entrance",
  "parking_lot",
  "parking_space",
  "parkinga",
  "parkpa",
  "passenger_pickup_dropoff",
  "patio",
  "pavilion",
  "pawnbroker",
  "payment_centre",
  "performance_stage",
  "personal_stylist",
  "pet hospital",
  "pet_grooming",
  "pet_relief_area",
  "petrol",
  "pharmacy",
  "phone_repair",
  "photography",
  "picnic area",
  "picnic tables",
  "picnic_area",
  "picnic_shelter",
  "pitch",
  "pl",
  "pla",
  "place_of_meditation",
  "place_of_mourning",
  "place_of_worship",
  "place_of_worship;grave_yard",
  "place_of_worship;monastery",
  "place_of_worship;school",
  "planetarium",
  "planetarium;cinema",
  "plant_nursery",
  "planter",
  "platform",
  "play",
  "play structure",
  "playground",
  "playground_structure",
  "plaza",
  "po",
  "podium",
  "police",
  "police; townhall",
  "police; townhall; clerk's office",
  "police;courthouse",
  "police;fire_station",
  "police_academy",
  "polling_station",
  "pool",
  "pool,_Tennis",
  "porch",
  "post office",
  "post_box",
  "post_depot",
  "post_office",
  "ppudo",
  "prar",
  "prep_school",
  "preschool",
  "pressbox",
  "printer",
  "prison",
  "private_club",
  "private_security",
  "prk",
  "propane",
  "proposed",
  "prototyping",
  "pub",
  "pub; restaurant",
  "pub;cafe",
  "pub;parking",
  "pub;restaurant",
  "pub;stripclub",
  "public",
  "public_bath",
  "public_bookcase",
  "public_building",
  "public_building;courthouse",
  "public_building;fire_station",
  "public_building;post_office",
  "public_building;townhall",
  "public_facility",
  "public_hall",
  "public_plaza",
  "public_works",
  "publisher",
  "pumping_station",
  "putting_green",
  "q",
  "radio_studio",
  "rail",
  "railway",
  "rain_garden",
  "ranger_station",
  "realtor",
  "reception",
  "reception_desk",
  "recreation",
  "recreation center",
  "recreation_centre",
  "recreational",
  "rectory",
  "recycle",
  "recycling",
  "recycling;waste_disposa",
  "register_office",
  "registration_desk",
  "registry_of_motor_vehicles",
  "rehabilitation",
  "rent_to_own",
  "rescue_station",
  "research",
  "research_center",
  "research_institute",
  "research_station",
  "restaurant",
  "restaurant/bar",
  "restaurant; rentals",
  "restaurant;bar",
  "restaurant;cafe",
  "restaurant;fast_food",
  "restaurant;ski_school;toilets",
  "restroom",
  "retail",
  "retirement_home",
  "retirement_home;social_facility",
  "retirement_village",
  "road_depot",
  "road_maintenance",
  "roller_coaster",
  "roller_rink",
  "roof",
  "ruin",
  "rv parking",
  "rv_storage",
  "sailing_school",
  "salon",
  "sand",
  "sandbox",
  "sanitary_dump_station",
  "satellite_uplink",
  "school",
  "school grounds",
  "school;church",
  "school;college",
  "school;parking",
  "school;place_of_worship",
  "school;prison",
  "school;public_building",
  "school;townhall",
  "school_govbuilding",
  "school_grounds",
  "science_park",
  "scj",
  "scooter_rental",
  "scout_camp",
  "scout_house",
  "scrapyard",
  "scuba_diving",
  "sculpture",
  "seating",
  "security zone",
  "security_booth",
  "security_control",
  "security_zone",
  "self-storage",
  "self_storage",
  "seminary",
  "sen",
  "senior housing",
  "senior_center",
  "senior_housing",
  "seniors_home",
  "service_organization",
  "service_station",
  "services",
  "shaved_ice",
  "shed",
  "shelter",
  "shelter; picnic_table",
  "shelter;roof",
  "shelter;toilets",
  "sherriff",
  "shipping_and_receiving",
  "shirlpool",
  "shoeshine",
  "shop",
  "shopping cart bay",
  "shop|clothes",
  "shower",
  "shower;restrooms",
  "showers",
  "sidewalk",
  "sign",
  "silo",
  "site",
  "ski patrol",
  "ski_patrol",
  "ski_rental",
  "ski_school",
  "ski_school; toilets",
  "skylight",
  "slaughterhouse",
  "slide",
  "smoking_area",
  "snack_bar",
  "snack_cart",
  "snack_stand",
  "snackbar",
  "snacks",
  "snow_disposal",
  "snow_park",
  "snowballs",
  "soaicl_facility",
  "social_center",
  "social_centre",
  "social_centre;events_venue",
  "social_club",
  "social_club;events_venue",
  "social_facility",
  "social_facility; community_centre",
  "social_facility;place_of_worship",
  "social_facility;retirement_home",
  "social_service",
  "social_services",
  "sorority",
  "spa",
  "sperm_bank",
  "splash_pad",
  "sport",
  "sport_centre",
  "sports center",
  "stable",
  "stables",
  "stadium",
  "stadium_seating",
  "stage",
  "stands",
  "state park",
  "station",
  "stock_exchange",
  "storage",
  "storage_rental",
  "store",
  "street_side",
  "strip",
  "strip_club",
  "stripbar",
  "stripclub",
  "student_accommodation",
  "student_accomodation",
  "studio",
  "summer camp",
  "sun_shelter",
  "supermarket",
  "surface",
  "swimming",
  "swimming_pool",
  "swimming_pool_deck",
  "swimming_pool_supplies",
  "swingerclub",
  "swings",
  "t",
  "table",
  "tailor",
  "taproom",
  "tattoo",
  "taxi",
  "taxidermy",
  "teaching_area",
  "teahouse",
  "telegraph_office",
  "telephone",
  "telephone;toilets;vending_machine",
  "telescope",
  "tennis court",
  "theater",
  "theatre",
  "theatre:type=amphi",
  "therapy",
  "ticket",
  "ticket_booth",
  "toiilets",
  "toilets",
  "toilets,showers",
  "toilets; laundry",
  "toilets;fast_food",
  "toilets;shower;laundry",
  "toliets",
  "tool_rental",
  "tourism",
  "tourism_centre",
  "tourist_bus_parking",
  "town hall",
  "townhall",
  "townhall;library;fire_station",
  "toy_library",
  "trade_school",
  "traffic_park",
  "traile_park",
  "trailer park",
  "trailer_home",
  "trailer_park",
  "training",
  "training police_fire",
  "transmission repair",
  "transport",
  "trash_bin",
  "trolley_bay",
  "trolly_bay",
  "truck_parking",
  "truck_rental",
  "truck_stop",
  "truck_wash",
  "tutor",
  "tutoring",
  "ufo_landing_pad",
  "undertaker",
  "university",
  "university dorms",
  "university;arts_centre",
  "urgent_care",
  "vacant",
  "vacuum_cleaner",
  "vehicle_impound",
  "vehicle_inspection",
  "vehicle_rental",
  "vending_machine",
  "vending_machine;toilets",
  "vent",
  "veterinary",
  "veterinary_clinic",
  "vicarage",
  "viewing_platform",
  "visitor_center",
  "visitor_centre",
  "voting",
  "wading_pool",
  "waiting_area",
  "warehouse",
  "waste disposal",
  "waste_basket",
  "waste_container",
  "waste_disposal",
  "waste_dump_site",
  "waste_transfer_station",
  "waste_transfer_station;recycling",
  "water",
  "water treatment",
  "water_point",
  "water_slide",
  "water_works",
  "watering_place",
  "wedding_chapel",
  "weigh_station",
  "weighbridge",
  "welding",
  "welfare",
  "whirlpool",
  "wifi",
  "wildlife_hide",
  "window_fashions",
  "winery",
  "wood",
  "workshop",
  "wsch",
  "y",
  "yacht_club",
  "yard",
  "yes",
  "yoga",
];

const buildingOptions = [
  "101",
  "102",
  "106",
  "107",
  "113",
  "114",
  "12",
  "120",
  "121",
  "131",
  "141",
  "15",
  "160",
  "161",
  "166",
  "1y",
  "2",
  "20",
  "2001",
  "213",
  "214",
  "25",
  "27",
  "2900",
  "3",
  "308",
  "350",
  "36",
  "3818",
  "39",
  "4",
  "408",
  "4124",
  "44",
  "4621",
  "5",
  "6",
  "60660",
  "6500",
  "669",
  "7",
  "73",
  "748",
  "834",
  "852",
  "?",
  "?ATC tower?",
  "ADDITION",
  "AMC Painting",
  "Abandoned",
  "Abandoned_House",
  "Academic_Building",
  "Administratif",
  "Administration",
  "Administrative_and_Residential",
  "Agencia_de_viajes",
  "Aircraft hangars?",
  "Airport",
  "Alan",
  "Alba Public School",
  "Amedisys Home Health Care",
  "Andador 5",
  "Apartment_Homes",
  "Aquarium",
  "Arena",
  "Army_National_Guard",
  "Art_Building",
  "Assisted_Living_Facility",
  "Athletic_field_house",
  "Auditorio",
  "Auditorium",
  "Baboons_Home",
  "Baldwin Building",
  "Band_Office",
  "Bank",
  "Banquet_Hall",
  "Bar,_Golfcourse",
  "Bath_House",
  "Bayview South",
  "Beacon Academy",
  "Bed_and_Breakfast",
  "Bing",
  "Boat",
  "Boat_House",
  "Boathouse",
  "Bodega",
  "Borough Hall",
  "Bradbury Place",
  "Branch_168",
  "Buiding",
  "Building",
  "Building part",
  "Bunkhouse",
  "Business",
  "CHANGE ROOM",
  "CRYCC",
  "Cabin",
  "Cafeteria",
  "Campus_Library",
  "Car_Dealership",
  "Cardiology and Orthropedics",
  "Carriage Lane",
  "Carriage_House",
  "Casa",
  "Casa hermana",
  "Casa_con_local_en_frente",
  "Castle_Hill_Electrical_Supply",
  "Chabad_House",
  "Changing_rooms/bathrooms",
  "Chapel",
  "Charitable_Organization",
  "Child Care",
  "City fo Denton",
  "City_Government_Office",
  "Civic",
  "Clarifiers",
  "Class_Rooms",
  "Classroom",
  "Classrooms",
  "Clinic",
  "Club",
  "Club_House",
  "Clubhouse",
  "Coffee Kiosk",
  "Comercio",
  "Commercial",
  "Commercial,_House_of_Worship",
  "Community_Centre",
  "Company",
  "Composting_System",
  "Condominium",
  "Condos",
  "Consultorio_Médico",
  "Control Tower",
  "Convent",
  "Converted_Barn",
  "Corporate/retail",
  "Cottage Front",
  "Country_Club",
  "County_Building",
  "Courthouse",
  "Covered_Arena",
  "Covered_Basketball_Court",
  "Cow Creek Road",
  "Crayfish_Lab",
  "Crime_Fighters_Car_Wash",
  "Cultural_Center",
  "Curling Rink",
  "Danny's Bass Boat Shop",
  "Day Care",
  "Day_Lodge",
  "Dentist_Office",
  "Department_Building",
  "Depot",
  "Detached_Classroom",
  "Detached_hangar",
  "Dining/Instructional/Entertainment",
  "Dispatch",
  "Domo_que_cubre_las_canchas_deportivas",
  "Dormitory",
  "Drive_Through",
  "Dugout",
  "Dump Station",
  "Duplex",
  "Edificio Principal",
  "Edificio dministrativo",
  "Edificio económico administrativo",
  "Educational",
  "Emergency",
  "Engine_house",
  "Engineering_Building",
  "Escuela",
  "Esko Bus Barnes",
  "Eugene Belisle Hall",
  "Event_Center",
  "FIXME: Business",
  "FIXME: Unknown",
  "Farmacia_y_Consultorios_Medicos",
  "Faster Care",
  "Federal",
  "Feild",
  "Field",
  "Fire_Department",
  "Fire_House",
  "Fire_Station",
  "Fire_department",
  "Fishing_Stage",
  "Fitness_Center",
  "Food_truck",
  "Former college",
  "Forum",
  "Fraternal institution",
  "Fraternity",
  "Funeral Home",
  "G",
  "GA",
  "Garderie",
  "Gas Station",
  "Gas_Station",
  "Gate_House",
  "Gazebo",
  "Government",
  "Government_Facility",
  "Granite Falls",
  "Gravel_lot",
  "Grocery",
  "Grounds",
  "Group Housing",
  "Gym",
  "Gymnasium",
  "H",
  "HVAC",
  "Hackerspace",
  "Hanger",
  "Harbourview Apartments",
  "Health_Center",
  "Healthcare",
  "Healthrax",
  "Heiau",
  "Herrick_Memorial_Chapel",
  "High School",
  "Historical_Caboose",
  "Historical_House",
  "Historico",
  "Home",
  "Home_Office",
  "Horse_facility",
  "Hospital and Nursing Home",
  "Hotel",
  "Hotel,_Resort?",
  "Hotel/Motel",
  "INFRAESTRUCTURA",
  "Ice_Skating_Rink",
  "Instructional",
  "Insurance",
  "Iona_College_Dorm",
  "Jail_House",
  "Jerry Siegel Lane",
  "Jeweler",
  "John Johnston House",
  "John_McLain_LBRA Legacy Boat House",
  "Kennel",
  "Kennels",
  "Kwick_Stop_Coin_Op_Laundry_-_vacant",
  "LCC Trap House",
  "LOCAL_21",
  "Laboratorio Clinico",
  "Laboratory_and_Classroom",
  "Lakeland Bank Pavilion",
  "Laundry",
  "Law_Office",
  "Learning_Center/_Day_Care",
  "Leonard",
  "Liberty Dialysis",
  "Library",
  "Lighthouse",
  "Lighthouse_Carwash",
  "Livestock_Barn",
  "Lodge",
  "Long-Term_Guest_House",
  "Long_Beach_Public_Library",
  "Los_Angeles_County_Public_Library",
  "Lucerne Ave Walk",
  "Lucky Friday",
  "MACROPLAZA",
  "Maestro's Cafe",
  "Mail_box_shed",
  "Maintenance_Building",
  "Mall",
  "Manager Skagit Valley Apartments",
  "Manget Hall",
  "Market",
  "Mausoleum",
  "McMansion",
  "Meat_Locker",
  "Meat_Packing",
  "Mechanical_Room",
  "Mechanical_room",
  "Medical",
  "Medical Holding Building",
  "Medical_Office",
  "Medical_Offices",
  "Military",
  "Military_Installation",
  "Mini_super",
  "Mitchum Basketball Performance Center - Completion by Summer 2016",
  "Mixed_Use",
  "Mixed_use",
  "Mobile Home",
  "Modulo de policia",
  "Morse PL",
  "Morse TH",
  "Motel",
  "Motel_And_RV_Park",
  "Multi-tennant_Commercial",
  "Municipal",
  "Museum",
  "Music_Building",
  "Natatorium",
  "National_Guard",
  "New Education Building - Built By Fall 2016",
  "No",
  "No_longer_exists",
  "Nodo Vial de la Cruz",
  "Nose and Sinus Center Suite 400",
  "Nova Central School District",
  "Nursery_School",
  "Office",
  "Office_Building",
  "Office_and_Residential",
  "Offices",
  "Oficinas",
  "Ohio_University",
  "Old_Wentworth_Elevator",
  "Open_Pavilion",
  "Oratory",
  "Outdoor_Stage",
  "Palacio Municipal",
  "Palacio_Municipal",
  "Park_Maintenance",
  "Parking_Attendant_Booth",
  "Parque",
  "Pavilion",
  "Pemex",
  "Pharmacy",
  "Physical Plant (PP)",
  "Picnic Pavilion",
  "Pierce_County_Building",
  "Planta_Potabilizadora_Mintzita",
  "Playground",
  "Police Station",
  "Police_Department",
  "Pool_House",
  "Post_Office",
  "Pottery_Studio",
  "Preschool",
  "Private_Home_Owners_Cluhouse",
  "Property lines",
  "Public",
  "Public_Use",
  "Public_Works",
  "Public_use",
  "Publico",
  "Pump House",
  "Pumping_Station",
  "Quinta",
  "R3 Environmental",
  "ROO",
  "ROOF",
  "RV",
  "Railway",
  "Ranch",
  "Rec_Center",
  "Recording_Studio/Barber_Shop",
  "Recreational",
  "Recreational_Center",
  "Red_Barn",
  "Rehabilitation_Center",
  "Religious",
  "Rescue_Squad",
  "Residential,_business,_restaurants",
  "Residential_Condominium",
  "Residential_Real_Estate Appraisers",
  "Resort?",
  "Restroom",
  "Resturant",
  "Retail",
  "Retail_and_Pharmacy",
  "Retirement_Community",
  "Roof",
  "Résidence_Maria-Goretti",
  "SECRETARIA_DE_MOVILIDAD",
  "STORAGE",
  "Salon",
  "Salon_comunitario",
  "Salon_de_Eventos",
  "Salt_Shed",
  "School_Ground",
  "Science Incubator",
  "Security_Hut",
  "Shelter",
  "Shelter_House",
  "Shoe_Attic,_Chef's_Kitchen",
  "Shopping Center",
  "Silo",
  "Sindicato",
  "Skating_Rink",
  "Ski_Jump",
  "Ski_Lodge",
  "Skyway",
  "Slaughter_House",
  "Social_Hall",
  "Some_kind_of_structure?",
  "Sports",
  "Sports House",
  "Sports,_exercise_area_and_swimming_pool",
  "Spring Branch Home",
  "Station",
  "Station_1",
  "Storage Units",
  "Storage_Building",
  "Store",
  "Structure added, not on data, appears on satellite imagery",
  "Student_Athlete_Building",
  "Student_Center",
  "Suite_212,_Waverly_Plaza,_755_Waverly_Avenue,_Holtsville,_NY_11742,_USA",
  "Sumter Psychiatry Associates",
  "Supermarket",
  "Surgical Plus",
  "TUMSA Santa Barbara",
  "Taxa Central",
  "Tech 3",
  "Tech 4",
  "Telescope_Dome",
  "Telescope_dome",
  "Tent",
  "The Hangar",
  "The_Maryland-National_Capital_Park_and_Planing_Commission",
  "Tienda Soriana",
  "Title Company",
  "Toilets",
  "Torre_de_Investigación",
  "Tourist_Information_Center",
  "Tower",
  "Tower 1",
  "Townhome",
  "Townhouse",
  "Tractor supply",
  "Transportation_Brokerage",
  "Tyler King's House",
  "USU",
  "US_Post_Office",
  "Unidad Deportiva",
  "University Doctors",
  "University_Office_Building",
  "University_Police_Office",
  "Urban_Pioneer",
  "Urology Stuite",
  "Utility",
  "Utility_Suubstation",
  "VFW",
  "Vacancy",
  "Vacation_rental",
  "Venue/Barn",
  "Verizon Building",
  "Wagner's",
  "Waste water Pumping",
  "Water_Treatment_Facility",
  "Weymouth Housing Authority",
  "Wolfe_5",
  "Workshop",
  "YES",
  "YMCA",
  "YWCA",
  "Yes,_College",
  "Yeshiva",
  "Yoga_Studio",
  "Zap_House",
  "ab",
  "aba",
  "aban",
  "abandoned",
  "abasto",
  "academic",
  "academic_building",
  "accommodation",
  "activity_center",
  "added building based on aerial images",
  "administration",
  "administrative",
  "administrative/auxiliary",
  "adobe",
  "aeroplane",
  "affordable housing",
  "agricultural",
  "agriculture",
  "aircraft",
  "airplane",
  "airplane_fuselage",
  "airport",
  "airport_terminal",
  "alleyway",
  "allotment_house",
  "amenity",
  "amenity=fire_station;building=government",
  "amenity_center",
  "amphitheater",
  "amphitheatre",
  "amusement_ride",
  "anchorage",
  "antenna",
  "antique auto repair shop",
  "apa",
  "apartmants",
  "apartment",
  "apartment_block",
  "apartment_office",
  "apartmenta",
  "apartments (2nd floor and up)",
  "apartments;bridge",
  "apartments;commercial",
  "apartments;commerical",
  "apartments;garage",
  "apartments;garages",
  "apartments;office",
  "apartments;offices",
  "apartments;residential",
  "apartments;retail",
  "apartments;yes",
  "apartmentsq",
  "app",
  "appartments",
  "aprtments",
  "aquaculture",
  "arbor",
  "arboretum",
  "arbour",
  "arch",
  "archaeological_site",
  "arder",
  "arena",
  "armory",
  "armoury",
  "art_and_craft_store",
  "art_studio",
  "arts_centre",
  "athletic",
  "attached",
  "attraction",
  "auditorio",
  "auditorium",
  "auxiliary",
  "auxillary",
  "aviary",
  "awning",
  "backshop",
  "bakehouse",
  "bakery",
  "balcony",
  "bandshell",
  "bandstand",
  "bank",
  "banquet_hall",
  "bar",
  "barge",
  "barm",
  "barn",
  "barn;garage",
  "barn;house",
  "barn;school",
  "barns",
  "barrack",
  "barracks",
  "baseball_dugout",
  "basilica",
  "basin",
  "basketball_gym,_gymnastics room, dance room",
  "bath house",
  "bath_house",
  "bathroom",
  "batting_cage",
  "bed_and_breakfast",
  "bench",
  "benches for sports",
  "bho",
  "billboard",
  "bing",
  "bleacher",
  "bleachers",
  "block",
  "block house",
  "boat",
  "boat storage house",
  "boat_house",
  "boathouse",
  "boiler_house",
  "booth",
  "both",
  "boxcar",
  "bq",
  "brewery",
  "bri",
  "brick",
  "bridge",
  "bridge;roof",
  "buiding",
  "build",
  "building",
  "building:3",
  "bullring",
  "bungalow",
  "bunker",
  "burial_vault",
  "burned",
  "burned_down",
  "bus",
  "bus_shelter",
  "bus_station",
  "business",
  "business/Kavanaugh CallCenter Group",
  "business/organization",
  "c",
  "cabana",
  "cabin",
  "cabin0",
  "cabin1",
  "cabin2",
  "cabin4",
  "cabin5",
  "cabinet",
  "caboose",
  "cafe",
  "cafeteria",
  "cage",
  "campanile",
  "canopy",
  "car",
  "car wash",
  "car_park",
  "car_repair",
  "car_wash",
  "caravan",
  "caravan_static",
  "cargo_terminal",
  "carillon",
  "carousel",
  "carpark",
  "carport",
  "carport;garage",
  "carport;yes",
  "carports",
  "carps",
  "carriage_house",
  "carwash",
  "casa",
  "casa de cultura",
  "casino",
  "castle",
  "cathedral",
  "cement_pad",
  "central_office",
  "centre_d'accueil",
  "cesped",
  "chalet",
  "changing rooms",
  "changing_room",
  "chapel",
  "chapel;university",
  "charity",
  "checkpoint",
  "chhur",
  "chicken",
  "chicken_coop",
  "chicken_house",
  "chickenhouse",
  "childcare",
  "childcare facility",
  "chimney",
  "chr",
  "chruch",
  "chu",
  "chu]",
  "chuch",
  "chur",
  "church",
  "church;carport",
  "church;commercial",
  "church;roof",
  "church;yes",
  "church_office",
  "cinema",
  "circus",
  "cistern",
  "city_hall",
  "civic",
  "civic_centre",
  "classroom",
  "clinic",
  "cloister",
  "club",
  "club_house",
  "clubhouse",
  "clubhouse;gym",
  "coffee shop",
  "collapsed",
  "college",
  "college;yes",
  "college_building",
  "colocation",
  "columbarium",
  "column",
  "com",
  "comer",
  "comercial",
  "comm",
  "commecial",
  "commer",
  "commercal",
  "commercial",
  "commercial, residential",
  "commercial,_storage",
  "commercial; yes",
  "commercial;apartments",
  "commercial;appartment",
  "commercial;detached",
  "commercial;educational",
  "commercial;government",
  "commercial;greenhouse",
  "commercial;house",
  "commercial;industrial",
  "commercial;office",
  "commercial;office;parking",
  "commercial;residential",
  "commercial;retail",
  "commercial;roof",
  "commercial;service",
  "commercial;yes",
  "commercial_building",
  "commercialq",
  "commerciaretail",
  "commercq",
  "commerical",
  "commericial",
  "community_Centre",
  "community_center",
  "community_centre",
  "company",
  "company_store",
  "complete",
  "complex",
  "complexe_sports_et_loisirs",
  "computers",
  "concession stand",
  "concession_stand",
  "condo",
  "condominium",
  "condominiums",
  "condominum",
  "condos",
  "conference_center",
  "connector",
  "cons",
  "conservatory",
  "constrs",
  "construction",
  "construction , lived in",
  "constructionq",
  "container",
  "contamination cell",
  "control_tower",
  "convenience",
  "convenience_store",
  "convent",
  "convention_center",
  "coommercial",
  "corn_crib",
  "corridor",
  "cottage",
  "cotton_gin",
  "country_club",
  "country_store",
  "court",
  "courthouse",
  "courtyard",
  "cover",
  "covered",
  "covered area",
  "covered_area",
  "cow_barn",
  "cowshed",
  "credit_union",
  "critical_infrastructure:power",
  "crossing",
  "cultural_center",
  "curling",
  "cyes",
  "dam",
  "damaged",
  "dance_hall",
  "data",
  "data_center",
  "datacenter",
  "day_care_centre",
  "daycare",
  "de_Gobierno",
  "dealership",
  "deck",
  "deckhouse",
  "deconsecrated_church",
  "demolished",
  "demountable",
  "department_store",
  "depot",
  "derelict,_uninhabited",
  "destroyed",
  "detached",
  "detached;yes",
  "detachedq",
  "detachedsource=digitalglobe",
  "detachedv",
  "dhop",
  "digester",
  "diner",
  "dining",
  "dining hall",
  "dining_hall",
  "dismantled",
  "distillery",
  "distributor",
  "disused",
  "disused:church",
  "disused:garage",
  "disused:retail",
  "disused:terrace",
  "disused:yes",
  "doctor",
  "dog_house",
  "doghouse",
  "doityourself",
  "dome",
  "done",
  "dorm",
  "dormatory",
  "dormitory",
  "dormitory;university",
  "dormitory;yes",
  "drive-through",
  "drive-thru_atm",
  "drive_through",
  "dt",
  "dugout",
  "duplex",
  "education",
  "electricity",
  "elevator",
  "embassy",
  "emergency",
  "empty",
  "engine_house",
  "entrance",
  "equinix",
  "es",
  "escolar",
  "events_venue",
  "ew",
  "exhibition_hall",
  "factory",
  "family_house",
  "farm",
  "farm_auxiliary",
  "farm_auxiliary;barn",
  "farm_auxiliary;garage",
  "farm_auxiliary;house",
  "farm_auxiliaryq",
  "farm_auxiliaryq2q",
  "farm_auxillary",
  "farm_building",
  "farmhouse",
  "farmq",
  "fast_food",
  "fee station",
  "feed barn",
  "fence",
  "fer",
  "fidelity",
  "field",
  "field_house",
  "fieldhouse",
  "fire",
  "fire department",
  "fire station",
  "fire_department",
  "fire_hall",
  "fire_hydrant",
  "fire_lookout",
  "fire_station",
  "firehouse",
  "fishing_stage",
  "fitnes",
  "fixed_base_opperator",
  "fixme_multiple_buildings",
  "flat",
  "flats",
  "floating",
  "floating_home",
  "flour_mill",
  "folie",
  "folly",
  "food",
  "food_and_drink",
  "food_truck",
  "for-sale",
  "fort",
  "foundation",
  "foundation/ruin",
  "foundations",
  "fourplex",
  "fraternity",
  "fraternity house",
  "friary",
  "from old sat image",
  "fronton",
  "fuel",
  "function_hall",
  "funeral home",
  "funeral_home",
  "furnace",
  "furniture",
  "g",
  "gho",
  "ga",
  "ga'",
  "gabled",
  "gallery",
  "gaq",
  "gar",
  "garage",
  "garage'",
  "garage;apartments",
  "garage;government",
  "garage;house",
  "garage;shed",
  "garage;yes",
  "garageq",
  "garages",
  "garages;yes",
  "garbage_shed",
  "garden",
  "garq",
  "gas",
  "gas station",
  "gas_pumps_under_awning",
  "gas_station",
  "gasometer",
  "gasstation",
  "gate",
  "gate_house",
  "gated",
  "gatehouse",
  "gathering",
  "gathering_place",
  "gaza",
  "gazebo",
  "gazebo;roof",
  "gazeboo",
  "gazeebo",
  "gazibo",
  "general",
  "generator",
  "generic",
  "ger",
  "gf",
  "gh",
  "glasshouse",
  "gov",
  "government",
  "government;school",
  "government;yes",
  "government_office",
  "governmental",
  "granary",
  "grandstand",
  "grandstands",
  "grandtand",
  "grange",
  "grass",
  "greenhouse",
  "greenhouseq",
  "grist_mill",
  "grocery",
  "grocery store",
  "grocery_store",
  "groundskeeping",
  "group housing",
  "group_housing",
  "guard station",
  "guard_house",
  "guard_shack",
  "guardhouse",
  "guesthouse",
  "gym",
  "gym;clubhouse",
  "gymnasium",
  "h",
  "h'",
  "h]",
  "hairdresser",
  "hall",
  "hall_of_residence",
  "hallway",
  "hangar",
  "hangar;detached",
  "hangar;yes",
  "hange",
  "hanger",
  "hargar",
  "health",
  "health_House",
  "health_clinic",
  "healthcare",
  "heritage_building",
  "hg",
  "highrise",
  "hipped",
  "historic",
  "historic_building",
  "historical",
  "historical house",
  "hm",
  "ho",
  "hoaddr:housenumber=4305",
  "home",
  "hoouse",
  "hopsital",
  "hor",
  "hospital",
  "hospital;roof",
  "hospital;yes",
  "hostel",
  "hosue",
  "hot",
  "hotel",
  "hotel;roof",
  "hotel;yes",
  "hotel_building",
  "hotels",
  "houes",
  "house addr:city=Chula Vista",
  "house on the water",
  "house; commercial",
  "house;apartments",
  "house;carport",
  "house;detached",
  "house;garage",
  "house;residential",
  "house;roof",
  "house;static_caravan",
  "house;yes",
  "house=yes",
  "house`",
  "houseb3",
  "houseboat",
  "housebuilding=house",
  "housel",
  "houser",
  "houses",
  "housev",
  "housing",
  "housr",
  "hq",
  "hr",
  "hs",
  "hunting,_fishing,_camping_supplies",
  "hunting_stand",
  "hush_house",
  "hut",
  "hut;gazebo",
  "hydro",
  "hyes",
  "i",
  "ind",
  "indoor_swimming_pool",
  "indsutrial",
  "industrial",
  "industrial hanger 13",
  "industrial, building=yes",
  "industrial;yes",
  "industrials",
  "indzustrial",
  "inflated",
  "inn",
  "inner",
  "inside",
  "inside_store",
  "institute,_conference_centre",
  "institutional",
  "inu",
  "islamic_center",
  "iy",
  "j",
  "jail",
  "judging_booth",
  "kennel",
  "key",
  "kindergarten",
  "kingdom_hall",
  "kiosk",
  "kitchen",
  "latrine",
  "laundromat",
  "laundry",
  "laundry_room",
  "law enforcement",
  "leggett hall",
  "leisure",
  "lesiure",
  "lib",
  "library",
  "lifeguard_tower",
  "lighthouse",
  "line",
  "loading ramp",
  "loading_dock",
  "loafing_shed",
  "local",
  "locomotive",
  "lodge",
  "longhouse",
  "main",
  "main_terminal",
  "maintenance",
  "maintenance_shop",
  "maintenence",
  "mall",
  "manor",
  "manse",
  "mansion",
  "manufacture",
  "manufactured",
  "manufactured_home",
  "manufacturing",
  "mapillary",
  "marquee",
  "masonic",
  "masonic_lodge",
  "masons",
  "mausoleum",
  "maybe",
  "medical",
  "medical center",
  "medical_clinic",
  "medium",
  "meeting_room",
  "memorial",
  "mental_health_clinic",
  "mil",
  "military",
  "military_armoury",
  "mill",
  "minor parts",
  "mixd_use",
  "mixed",
  "mixed use",
  "mixed-use",
  "mixed_use",
  "mobile",
  "mobile_home",
  "modular",
  "monastery",
  "monume",
  "monument",
  "mortuary",
  "mosque",
  "motel",
  "motel;yes",
  "movable",
  "movable_house",
  "muesum",
  "multi_level_parking",
  "multi_office",
  "multiple_local_government_buildings",
  "multipurpose",
  "municipal",
  "municipal_government",
  "municipial",
  "municiple",
  "musem",
  "museum",
  "museums",
  "music_school",
  "musuem",
  "n",
  "nails",
  "name",
  "natatorium",
  "new",
  "nightclub",
  "noise_barrier",
  "non-profit",
  "none",
  "nonprofit_center",
  "nuclear",
  "nursing facility",
  "nursing_home",
  "o",
  "observation_tower",
  "observatory",
  "occupied",
  "of",
  "off",
  "office",
  "office;clubhouse",
  "office;company",
  "office;industrial",
  "office;public",
  "office;retail",
  "office;roof",
  "office;yes",
  "offices",
  "ofiice",
  "om",
  "open",
  "open_exposure",
  "organization",
  "ou",
  "out",
  "outdoor stage",
  "outdoor;yes",
  "outdoor_riding_arena",
  "outdoor_risers",
  "outdoor_structure",
  "outer",
  "outhouse",
  "outside",
  "p",
  "paddock",
  "pagoda",
  "painter",
  "palace",
  "palapa",
  "parish",
  "parish_hall",
  "park",
  "park shelter",
  "parking",
  "parking lot",
  "parking structure",
  "parking;yes",
  "parking_entrance",
  "parking_garage",
  "parking_lot",
  "parking_shelter",
  "parking_space",
  "parking_structure",
  "parliament",
  "parque",
  "parsonage",
  "part",
  "part=porch",
  "patio",
  "pavilion",
  "pavillion",
  "pavillon",
  "pavilon",
  "pedestrian",
  "pergola",
  "pharmaceutical_company",
  "pharmacy",
  "picnic shelter",
  "picnic_shelter",
  "pier",
  "place_of_worship",
  "plane",
  "planned",
  "plant",
  "plant_nursery",
  "platform",
  "police",
  "police_dept",
  "police_station",
  "pool",
  "pool maintnence",
  "pool_house",
  "poolhouse",
  "porch",
  "porkshed",
  "portable",
  "portable_classroom",
  "portable_toilet",
  "portables",
  "portico",
  "post office",
  "post_office",
  "potato_barn",
  "poultry_house",
  "poultry_stable",
  "power",
  "power_plant",
  "power_station",
  "prefab",
  "presbyterian",
  "presbytery",
  "press_box",
  "primary",
  "printing_facility",
  "prison",
  "private",
  "private property",
  "private_residence",
  "produce",
  "proposed",
  "pub",
  "public",
  "public;government",
  "public;yes",
  "public_building",
  "pulbic",
  "pump_house",
  "pumphouse",
  "pumping_station",
  "pyramid",
  "q",
  "quarry",
  "quonset",
  "quonset_hut",
  "r",
  "radio_station",
  "rail",
  "rail_car",
  "railroad",
  "railway",
  "railway_shed",
  "railway_shop",
  "railway_station",
  "ramada",
  "ranch",
  "razed",
  "razed building",
  "rebuilt",
  "rec_center",
  "recre",
  "recreation",
  "recreation center",
  "recreation_center",
  "recreation_centre",
  "recreation_ground",
  "recreational",
  "rectory",
  "ref",
  "refectory",
  "refinery",
  "religion",
  "religious",
  "religious;yes",
  "remnant",
  "renovation",
  "rental properties",
  "rental_office",
  "repair_shops",
  "res",
  "research",
  "research_centre",
  "reservoir",
  "residential",
  "residential, building=yes",
  "residential,building=yes",
  "residential;apartments",
  "residential;commercial",
  "residential;house",
  "residential;retail",
  "residential;roof",
  "residential;yes",
  "residentialQ",
  "residential_duplex",
  "residential_home_and_indoor_riding_arena/_farm",
  "residential_semi",
  "residential_single",
  "residential_town",
  "residentialbuilding=apartments",
  "residentialqIvan Aguirre",
  "resort",
  "rest",
  "rest_room",
  "restaurant",
  "restauraunt",
  "restored_replica",
  "restraunt;hotel",
  "restroom",
  "restrooms",
  "resturant",
  "ret",
  "ret]",
  "retai",
  "retaiil",
  "retail",
  "retail/residential",
  "retail; yes",
  "retail;apartments",
  "retail;commercial",
  "retail;residential",
  "retail;roof",
  "retail;shed",
  "retail;shed;yes",
  "retail;yes",
  "retail_store",
  "retailer",
  "retal",
  "retirement home",
  "retirement_home",
  "retr",
  "riding_hall",
  "riverboat",
  "rocket",
  "rof",
  "roff",
  "roiof",
  "romney",
  "roo",
  "rood",
  "roof",
  "roof;apartments",
  "roof;church",
  "roof;commercial",
  "roof;hospital",
  "roof;hotel",
  "roof;house",
  "roof;industrial",
  "roof;kindergarten",
  "roof;office",
  "roof;public",
  "roof;residential",
  "roof;retail",
  "roof;school",
  "roof;university",
  "roof;yes",
  "roof=no",
  "roof_overhang",
  "roofe3",
  "roofl",
  "roofm",
  "roofo",
  "roofs",
  "rooft",
  "room",
  "root",
  "ros",
  "rostrum",
  "roundhouse",
  "rppf",
  "ruind",
  "ruins",
  "ruins;barn",
  "s",
  "salon",
  "salt_barn",
  "salt_dome",
  "salt_pyramid",
  "sauna",
  "sawmill",
  "schh",
  "school",
  "school, office",
  "school; roof; school",
  "school;church",
  "school;commercial",
  "school;elementary_school",
  "school;roof",
  "school;yes",
  "school_district",
  "schooll",
  "scientific",
  "scoring_box",
  "seating",
  "seats",
  "security",
  "security_booth",
  "self-storage",
  "self_storage",
  "semi",
  "semidetached",
  "semidetached_house",
  "service",
  "service_station",
  "services publiques",
  "ses",
  "sg",
  "sgarage",
  "sh",
  "shack",
  "shadehouse",
  "shanty",
  "shed",
  "shed,_detached",
  "shed;garage;house",
  "shed;house",
  "shed;retail",
  "shed;yes",
  "shed_and_seasonal_restrooms",
  "shedm",
  "sheds",
  "sheepfold",
  "shelt",
  "shelter",
  "shingle mill",
  "ship",
  "shipping_container",
  "shooting_range",
  "shooting_stand",
  "shop",
  "shopping",
  "shopping center",
  "shopping_center",
  "shopping_mall",
  "shops",
  "show_house",
  "shrine",
  "sidewalk",
  "sign",
  "siil",
  "sile",
  "silo",
  "ska",
  "skating_rink",
  "ski jump",
  "ski lodge",
  "ski_jump",
  "skybridge",
  "skyscraper",
  "skywalk",
  "skyway",
  "sleeper_car",
  "slipway",
  "slurry_tank",
  "small",
  "smokery",
  "sn",
  "snack_bar",
  "social",
  "social_centre",
  "social_club",
  "social_facility",
  "sport",
  "sport_stand",
  "sports_centre",
  "sports_complex",
  "sports_hall",
  "springhouse",
  "square",
  "sroof",
  "ss",
  "sse",
  "stable",
  "stable'",
  "stable;garage",
  "stable;yes",
  "stables",
  "stadium",
  "stadium seating",
  "stadium;yes",
  "stadium_seating",
  "stage",
  "staircase",
  "stairs",
  "stand",
  "stands",
  "static caravan",
  "static_caravan",
  "static_mobile_home",
  "station",
  "stationary_caravan",
  "steam_plant",
  "steps",
  "stilt house",
  "stilt_house",
  "sto",
  "storage",
  "storage tank",
  "storage-self",
  "storage_facility",
  "storage_hall",
  "storage_locker",
  "storage_rental",
  "storage_shed",
  "storage_tank",
  "storage_unit",
  "storages",
  "store",
  "storefront",
  "strip mall",
  "strip_mall",
  "stripmall",
  "structure",
  "student_health_clinic_(building_43)",
  "studio",
  "sty",
  "substation",
  "subway_entrance",
  "sugar_shack",
  "suite",
  "suites",
  "supermarket",
  "surface",
  "swimming_pool",
  "sx",
  "synagogue",
  "t",
  "tabernacle",
  "taller",
  "tank",
  "tavern",
  "tech_cab",
  "teepee",
  "telco",
  "telecom",
  "telecommunications",
  "telephone_exchange",
  "telescope",
  "television_station",
  "temple",
  "tent",
  "tents",
  "terminal",
  "terr",
  "terrace",
  "tes",
  "textile_mill",
  "theater",
  "theater_arts_center",
  "theatre",
  "ticketing",
  "tiny_house",
  "tipi",
  "toilet",
  "toilets",
  "toll_booth",
  "tomb",
  "tourism",
  "tower",
  "tower 2",
  "tower 3",
  "tower_block",
  "town_hall",
  "townhall",
  "townhome",
  "townhomes",
  "townhosue",
  "townhouse",
  "townhouses",
  "track",
  "trader",
  "trailer",
  "trailer_park",
  "train",
  "train car",
  "train_car",
  "train_station",
  "train_wait_station",
  "transformer_tower",
  "transmitter",
  "transportation",
  "transports",
  "tree",
  "tree_house",
  "tree_row",
  "tribune",
  "triplex",
  "triumphal_arch",
  "true",
  "tunnel",
  "turning_circle",
  "tweak",
  "type",
  "u",
  "unclassified",
  "undefined",
  "underground",
  "univ",
  "univeristy",
  "university",
  "university library",
  "university;apartments",
  "university;chapel",
  "university;yes",
  "universityele=247",
  "univesity",
  "unknown",
  "unkown",
  "use:residential",
  "utilities",
  "utility",
  "vacant",
  "valley",
  "vehicle",
  "ventilation_shaft",
  "veranda",
  "veterinary",
  "vicarage",
  "villa",
  "walkway",
  "wall",
  "ward",
  "ware",
  "warehouse",
  "warehouse/offices",
  "warehouse;yes",
  "warm up cabin",
  "waste_disposal",
  "wastewater_plant",
  "water",
  "water treatment",
  "water_storage_tank",
  "water_tank",
  "water_tower",
  "watermill",
  "waterworks",
  "way",
  "wayside_shrine",
  "weapon_armory",
  "windmill",
  "winery",
  "wing",
  "wood",
  "woods",
  "work_camp",
  "works",
  "workshop",
  "x",
  "y'",
  "y3",
  "y]",
  "yard",
  "yb",
  "ye",
  "yer",
  "yert",
  "yes",
  "yes#TexasTech",
  "yes'",
  "yes,_Barn",
  "yes,_Bunkhouse",
  "yes,_Latrine",
  "yes,_cabin",
  "yes,_garage,_fire station",
  "yes,_museum",
  "yes:disused",
  "yes; commercial; yes; commercial",
  "yes; roof",
  "yes;ROO",
  "yes;apartments",
  "yes;cabin",
  "yes;carport",
  "yes;chapel",
  "yes;church",
  "yes;civic",
  "yes;college",
  "yes;commercial",
  "yes;commercial;residential",
  "yes;detached",
  "yes;dormitory",
  "yes;farm",
  "yes;fraternity_house",
  "yes;government",
  "yes;grandstand",
  "yes;hospital",
  "yes;hotel",
  "yes;hotel;roof",
  "yes;house",
  "yes;hut",
  "yes;industrial",
  "yes;motel",
  "yes;office",
  "yes;offices",
  "yes;parking",
  "yes;pavilion",
  "yes;public",
  "yes;religious",
  "yes;residential",
  "yes;retail",
  "yes;retail;commercial",
  "yes;roof",
  "yes;school",
  "yes;shed",
  "yes;terrace;house",
  "yes;transportation",
  "yes;university",
  "yes?",
  "yesQ",
  "yes_B3G_0H2",
  "yes_but_the_burgee_bar_is_on_the_second_floor_of_the_building",
  "yes_under_construction",
  "yesa",
  "yesaddr:city=Sambro Head",
  "yesbuilding=house",
  "yesh",
  "yeshamg",
  "yesleisure=sports_centre",
  "yesm",
  "yesname=Towne Motel",
  "yesq",
  "yesqq",
  "yesr",
  "yew",
  "yt",
  "yurt",
  "ywa",
  "yws",
  "yyes",
];

const commercialOptions = [
  "101",
  "102",
  "106",
  "107",
  "113",
  "114",
  "12",
  "120",
  "121",
  "131",
  "141",
  "15",
  "160",
  "161",
  "166",
  "1y",
  "2",
  "20",
  "2001",
  "213",
  "214",
  "25",
  "27",
  "2900",
  "3",
  "308",
  "350",
  "36",
  "3818",
  "39",
  "4",
  "408",
  "4124",
  "44",
  "4621",
  "5",
  "6",
  "60660",
  "6500",
  "669",
  "7",
  "73",
  "748",
  "834",
  "852",
  "?",
  "?ATC tower?",
  "ADDITION",
  "AMC Painting",
  "Abandoned",
  "Abandoned_House",
  "Academic_Building",
  "Administratif",
  "Administration",
  "Administrative_and_Residential",
  "Agencia_de_viajes",
  "Aircraft hangars?",
  "Airport",
  "Alan",
  "Alba Public School",
  "Amedisys Home Health Care",
  "Andador 5",
  "Apartment_Homes",
  "Aquarium",
  "Arena",
  "Army_National_Guard",
  "Art_Building",
  "Assisted_Living_Facility",
  "Athletic_field_house",
  "Auditorio",
  "Auditorium",
  "Baboons_Home",
  "Baldwin Building",
  "Band_Office",
  "Bank",
  "Banquet_Hall",
  "Bar,_Golfcourse",
  "Bath_House",
  "Bayview South",
  "Beacon Academy",
  "Bed_and_Breakfast",
  "Bing",
  "Boat",
  "Boat_House",
  "Boathouse",
  "Bodega",
  "Borough Hall",
  "Bradbury Place",
  "Branch_168",
  "Buiding",
  "Building",
  "Building part",
  "Bunkhouse",
  "Business",
  "CHANGE ROOM",
  "CRYCC",
  "Cabin",
  "Cafeteria",
  "Campus_Library",
  "Car_Dealership",
  "Cardiology and Orthropedics",
  "Carriage Lane",
  "Carriage_House",
  "Casa",
  "Casa hermana",
  "Casa_con_local_en_frente",
  "Castle_Hill_Electrical_Supply",
  "Chabad_House",
  "Changing_rooms/bathrooms",
  "Chapel",
  "Charitable_Organization",
  "Child Care",
  "City fo Denton",
  "City_Government_Office",
  "Civic",
  "Clarifiers",
  "Class_Rooms",
  "Classroom",
  "Classrooms",
  "Clinic",
  "Club",
  "Club_House",
  "Clubhouse",
  "Coffee Kiosk",
  "Comercio",
  "Commercial",
  "Commercial,_House_of_Worship",
  "Community_Centre",
  "Company",
  "Composting_System",
  "Condominium",
  "Condos",
  "Consultorio_Médico",
  "Control Tower",
  "Convent",
  "Converted_Barn",
  "Corporate/retail",
  "Cottage Front",
  "Country_Club",
  "County_Building",
  "Courthouse",
  "Covered_Arena",
  "Covered_Basketball_Court",
  "Cow Creek Road",
  "Crayfish_Lab",
  "Crime_Fighters_Car_Wash",
  "Cultural_Center",
  "Curling Rink",
  "Danny's Bass Boat Shop",
  "Day Care",
  "Day_Lodge",
  "Dentist_Office",
  "Department_Building",
  "Depot",
  "Detached_Classroom",
  "Detached_hangar",
  "Dining/Instructional/Entertainment",
  "Dispatch",
  "Domo_que_cubre_las_canchas_deportivas",
  "Dormitory",
  "Drive_Through",
  "Dugout",
  "Dump Station",
  "Duplex",
  "Edificio Principal",
  "Edificio dministrativo",
  "Edificio económico administrativo",
  "Educational",
  "Emergency",
  "Engine_house",
  "Engineering_Building",
  "Escuela",
  "Esko Bus Barnes",
  "Eugene Belisle Hall",
  "Event_Center",
  "FIXME: Business",
  "FIXME: Unknown",
  "Farmacia_y_Consultorios_Medicos",
  "Faster Care",
  "Federal",
  "Feild",
  "Field",
  "Fire_Department",
  "Fire_House",
  "Fire_Station",
  "Fire_department",
  "Fishing_Stage",
  "Fitness_Center",
  "Food_truck",
  "Former college",
  "Forum",
  "Fraternal institution",
  "Fraternity",
  "Funeral Home",
  "G",
  "GA",
  "Garderie",
  "Gas Station",
  "Gas_Station",
  "Gate_House",
  "Gazebo",
  "Government",
  "Government_Facility",
  "Granite Falls",
  "Gravel_lot",
  "Grocery",
  "Grounds",
  "Group Housing",
  "Gym",
  "Gymnasium",
  "H",
  "HVAC",
  "Hackerspace",
  "Hanger",
  "Harbourview Apartments",
  "Health_Center",
  "Healthcare",
  "Healthrax",
  "Heiau",
  "Herrick_Memorial_Chapel",
  "High School",
  "Historical_Caboose",
  "Historical_House",
  "Historico",
  "Home",
  "Home_Office",
  "Horse_facility",
  "Hospital and Nursing Home",
  "Hotel",
  "Hotel,_Resort?",
  "Hotel/Motel",
  "House 1",
  "INFRAESTRUCTURA",
  "Ice_Skating_Rink",
  "Instructional",
  "Insurance",
  "Iona_College_Dorm",
  "Jail_House",
  "Jerry Siegel Lane",
  "Jeweler",
  "John Johnston House",
  "John_McLain_LBRA Legacy Boat House",
  "Kennel",
  "Kennels",
  "Kwick_Stop_Coin_Op_Laundry_-_vacant",
  "LCC Trap House",
  "LOCAL_21",
  "Laboratorio Clinico",
  "Laboratory_and_Classroom",
  "Lakeland Bank Pavilion",
  "Laundry",
  "Law_Office",
  "Learning_Center/_Day_Care",
  "Leonard",
  "Liberty Dialysis",
  "Library",
  "Lighthouse",
  "Lighthouse_Carwash",
  "Livestock_Barn",
  "Lodge",
  "Long-Term_Guest_House",
  "Long_Beach_Public_Library",
  "Los_Angeles_County_Public_Library",
  "Lucerne Ave Walk",
  "Lucky Friday",
  "MACROPLAZA",
  "Maestro's Cafe",
  "Mail_box_shed",
  "Maintenance_Building",
  "Mall",
  "Manager Skagit Valley Apartments",
  "Manget Hall",
  "Market",
  "Mausoleum",
  "McMansion",
  "Meat_Locker",
  "Meat_Packing",
  "Mechanical_Room",
  "Mechanical_room",
  "Medical",
  "Medical Holding Building",
  "Medical_Office",
  "Medical_Offices",
  "Military",
  "Military_Installation",
  "Mini_super",
  "Mitchum Basketball Performance Center - Completion by Summer 2016",
  "Mixed_Use",
  "Mixed_use",
  "Mobile Home",
  "Modulo de policia",
  "Morse PL",
  "Morse TH",
  "Motel",
  "Motel_And_RV_Park",
  "Multi-tennant_Commercial",
  "Municipal",
  "Museum",
  "Music_Building",
  "Natatorium",
  "National_Guard",
  "New Education Building - Built By Fall 2016",
  "No",
  "No_longer_exists",
  "Nodo Vial de la Cruz",
  "Nose and Sinus Center Suite 400",
  "Nova Central School District",
  "Nursery_School",
  "Office",
  "Office_Building",
  "Office_and_Residential",
  "Offices",
  "Oficinas",
  "Ohio_University",
  "Old_Wentworth_Elevator",
  "Open_Pavilion",
  "Oratory",
  "Outdoor_Stage",
  "Palacio Municipal",
  "Palacio_Municipal",
  "Park_Maintenance",
  "Parking_Attendant_Booth",
  "Parque",
  "Pavilion",
  "Pemex",
  "Pharmacy",
  "Physical Plant (PP)",
  "Picnic Pavilion",
  "Pierce_County_Building",
  "Planta_Potabilizadora_Mintzita",
  "Playground",
  "Police Station",
  "Police_Department",
  "Pool_House",
  "Post_Office",
  "Pottery_Studio",
  "Preschool",
  "Private_Home_Owners_Cluhouse",
  "Property lines",
  "Public",
  "Public_Use",
  "Public_Works",
  "Public_use",
  "Publico",
  "Pump House",
  "Pumping_Station",
  "Quinta",
  "R3 Environmental",
  "ROO",
  "ROOF",
  "RV",
  "Railway",
  "Ranch",
  "Rec_Center",
  "Recording_Studio/Barber_Shop",
  "Recreational",
  "Recreational_Center",
  "Red_Barn",
  "Rehabilitation_Center",
  "Religious",
  "Rescue_Squad",
  "Residential,_business,_restaurants",
  "Residential_Condominium",
  "Residential_Real_Estate Appraisers",
  "Resort?",
  "Restroom",
  "Resturant",
  "Retail",
  "Retail_and_Pharmacy",
  "Retirement_Community",
  "Roof",
  "Résidence_Maria-Goretti",
  "SECRETARIA_DE_MOVILIDAD",
  "STORAGE",
  "Salon",
  "Salon_comunitario",
  "Salon_de_Eventos",
  "Salt_Shed",
  "School_Ground",
  "Science Incubator",
  "Security_Hut",
  "Shelter",
  "Shelter_House",
  "Shoe_Attic,_Chef's_Kitchen",
  "Shopping Center",
  "Silo",
  "Sindicato",
  "Skating_Rink",
  "Ski_Jump",
  "Ski_Lodge",
  "Skyway",
  "Slaughter_House",
  "Social_Hall",
  "Some_kind_of_structure?",
  "Sports",
  "Sports House",
  "Sports,_exercise_area_and_swimming_pool",
  "Spring Branch Home",
  "Station",
  "Station_1",
  "Storage Units",
  "Storage_Building",
  "Store",
  "Structure added, not on data, appears on satellite imagery",
  "Student_Athlete_Building",
  "Student_Center",
  "Suite_212,_Waverly_Plaza,_755_Waverly_Avenue,_Holtsville,_NY_11742,_USA",
  "Sumter Psychiatry Associates",
  "Supermarket",
  "Surgical Plus",
  "TUMSA Santa Barbara",
  "Taxa Central",
  "Tech 3",
  "Tech 4",
  "Telescope_Dome",
  "Telescope_dome",
  "Tent",
  "The Hangar",
  "The_Maryland-National_Capital_Park_and_Planing_Commission",
  "Tienda Soriana",
  "Title Company",
  "Toilets",
  "Torre_de_Investigación",
  "Tourist_Information_Center",
  "Tower",
  "Tower 1",
  "Townhome",
  "Townhouse",
  "Tractor supply",
  "Transportation_Brokerage",
  "Tyler King's House",
  "USU",
  "US_Post_Office",
  "Unidad Deportiva",
  "University Doctors",
  "University_Office_Building",
  "University_Police_Office",
  "Urban_Pioneer",
  "Urology Stuite",
  "Utility",
  "Utility_Suubstation",
  "VFW",
  "Vacancy",
  "Vacation_rental",
  "Venue/Barn",
  "Verizon Building",
  "Wagner's",
  "Waste water Pumping",
  "Water_Treatment_Facility",
  "Weymouth Housing Authority",
  "Wolfe_5",
  "Workshop",
  "YES",
  "YMCA",
  "YWCA",
  "Yes,_College",
  "Yeshiva",
  "Yoga_Studio",
  "Zap_House",
  "ab",
  "aba",
  "aban",
  "abandoned",
  "abasto",
  "academic",
  "academic_building",
  "accommodation",
  "activity_center",
  "added building based on aerial images",
  "administration",
  "administrative",
  "administrative/auxiliary",
  "adobe",
  "aeroplane",
  "affordable housing",
  "agricultural",
  "agriculture",
  "aircraft",
  "airplane",
  "airplane_fuselage",
  "airport",
  "airport_terminal",
  "alleyway",
  "allotment_house",
  "amenity",
  "amenity=fire_station;building=government",
  "amenity_center",
  "amphitheater",
  "amphitheatre",
  "amusement_ride",
  "anchorage",
  "antenna",
  "antique auto repair shop",
  "apa",
  "app",
  "aprtments",
  "aquaculture",
  "arbor",
  "arboretum",
  "arbour",
  "arch",
  "archaeological_site",
  "arder",
  "arena",
  "armory",
  "armoury",
  "art_and_craft_store",
  "art_studio",
  "arts_centre",
  "athletic",
  "attached",
  "attraction",
  "auditorio",
  "auditorium",
  "auxiliary",
  "auxillary",
  "aviary",
  "awning",
  "backshop",
  "bakehouse",
  "bakery",
  "balcony",
  "bandshell",
  "bandstand",
  "bank",
  "banquet_hall",
  "bar",
  "barge",
  "barm",
  "barn",
  "barn;garage",
  "barn;house",
  "barn;school",
  "barns",
  "barrack",
  "barracks",
  "baseball_dugout",
  "basilica",
  "basin",
  "basketball_gym,_gymnastics room, dance room",
  "bath house",
  "bath_house",
  "bathroom",
  "batting_cage",
  "bed_and_breakfast",
  "bench",
  "benches for sports",
  "bho",
  "billboard",
  "bing",
  "bleacher",
  "bleachers",
  "block",
  "block house",
  "boat",
  "boat storage house",
  "boat_house",
  "boathouse",
  "boiler_house",
  "booth",
  "both",
  "boxcar",
  "bq",
  "brewery",
  "bri",
  "brick",
  "bridge",
  "bridge;roof",
  "buiding",
  "build",
  "building",
  "building:3",
  "bullring",
  "bungalow",
  "bunker",
  "burial_vault",
  "burned",
  "burned_down",
  "bus",
  "bus_shelter",
  "bus_station",
  "business",
  "business/Kavanaugh CallCenter Group",
  "business/organization",
  "c",
  "cabana",
  "cabin",
  "cabin0",
  "cabin1",
  "cabin2",
  "cabin4",
  "cabin5",
  "cabinet",
  "caboose",
  "cafe",
  "cafeteria",
  "cage",
  "campanile",
  "canopy",
  "car",
  "car wash",
  "car_park",
  "car_repair",
  "car_wash",
  "caravan",
  "caravan_static",
  "cargo_terminal",
  "carillon",
  "carousel",
  "carpark",
  "carport",
  "carport;garage",
  "carport;yes",
  "carports",
  "carps",
  "carriage_house",
  "carwash",
  "casa",
  "casa de cultura",
  "casino",
  "castle",
  "cathedral",
  "cement_pad",
  "central_office",
  "centre_d'accueil",
  "cesped",
  "chalet",
  "changing rooms",
  "changing_room",
  "chapel",
  "chapel;university",
  "charity",
  "checkpoint",
  "chhur",
  "chicken",
  "chicken_coop",
  "chicken_house",
  "chickenhouse",
  "childcare",
  "childcare facility",
  "chimney",
  "chr",
  "chruch",
  "chu",
  "chu]",
  "chuch",
  "chur",
  "church",
  "church;carport",
  "church;commercial",
  "church;roof",
  "church;yes",
  "church_office",
  "cinema",
  "circus",
  "cistern",
  "city_hall",
  "civic",
  "civic_centre",
  "classroom",
  "clinic",
  "cloister",
  "club",
  "club_house",
  "clubhouse",
  "clubhouse;gym",
  "coffee shop",
  "collapsed",
  "college",
  "college;yes",
  "college_building",
  "colocation",
  "columbarium",
  "column",
  "com",
  "comer",
  "comercial",
  "comm",
  "commecial",
  "commer",
  "commercal",
  "commercial",
  "commercial, residential",
  "commercial,_storage",
  "commercial; yes",
  "commercial;apartments",
  "commercial;appartment",
  "commercial;detached",
  "commercial;educational",
  "commercial;government",
  "commercial;greenhouse",
  "commercial;house",
  "commercial;industrial",
  "commercial;office",
  "commercial;office;parking",
  "commercial;residential",
  "commercial;retail",
  "commercial;roof",
  "commercial;service",
  "commercial;yes",
  "commercial_building",
  "commercialq",
  "commerciaretail",
  "commercq",
  "commerical",
  "commericial",
  "community_Centre",
  "community_center",
  "community_centre",
  "company",
  "company_store",
  "complete",
  "complex",
  "complexe_sports_et_loisirs",
  "computers",
  "concession stand",
  "concession_stand",
  "condo",
  "condominium",
  "condominiums",
  "condominum",
  "condos",
  "conference_center",
  "connector",
  "cons",
  "conservatory",
  "constrs",
  "construction",
  "construction , lived in",
  "constructionq",
  "container",
  "contamination cell",
  "control_tower",
  "convenience",
  "convenience_store",
  "convent",
  "convention_center",
  "coommercial",
  "corn_crib",
  "corridor",
  "cottage",
  "cotton_gin",
  "country_club",
  "country_store",
  "court",
  "courthouse",
  "courtyard",
  "cover",
  "covered",
  "covered area",
  "covered_area",
  "cow_barn",
  "cowshed",
  "credit_union",
  "critical_infrastructure:power",
  "crossing",
  "cultural_center",
  "curling",
  "cyes",
  "dam",
  "damaged",
  "dance_hall",
  "data",
  "data_center",
  "datacenter",
  "day_care_centre",
  "daycare",
  "de_Gobierno",
  "dealership",
  "deck",
  "deckhouse",
  "deconsecrated_church",
  "demolished",
  "demountable",
  "department_store",
  "depot",
  "derelict,_uninhabited",
  "destroyed",
  "detached",
  "detached;yes",
  "detachedq",
  "detachedsource=digitalglobe",
  "detachedv",
  "dhop",
  "digester",
  "diner",
  "dining",
  "dining hall",
  "dining_hall",
  "dismantled",
  "distillery",
  "distributor",
  "disused",
  "disused:church",
  "disused:garage",
  "disused:retail",
  "disused:terrace",
  "disused:yes",
  "doctor",
  "dog_house",
  "doghouse",
  "doityourself",
  "dome",
  "done",
  "dorm",
  "dormatory",
  "dormitory",
  "dormitory;university",
  "dormitory;yes",
  "drive-through",
  "drive-thru_atm",
  "drive_through",
  "dt",
  "dugout",
  "duplex",
  "education",
  "electricity",
  "elevator",
  "embassy",
  "emergency",
  "empty",
  "engine_house",
  "entrance",
  "equinix",
  "es",
  "escolar",
  "events_venue",
  "ew",
  "exhibition_hall",
  "factory",
  "family_house",
  "farm",
  "farm_auxiliary",
  "farm_auxiliary;barn",
  "farm_auxiliary;garage",
  "farm_auxiliary;house",
  "farm_auxiliaryq",
  "farm_auxiliaryq2q",
  "farm_auxillary",
  "farm_building",
  "farmhouse",
  "farmq",
  "fast_food",
  "fee station",
  "feed barn",
  "fence",
  "fer",
  "fidelity",
  "field",
  "field_house",
  "fieldhouse",
  "fire",
  "fire department",
  "fire station",
  "fire_department",
  "fire_hall",
  "fire_hydrant",
  "fire_lookout",
  "fire_station",
  "firehouse",
  "fishing_stage",
  "fitnes",
  "fixed_base_opperator",
  "fixme_multiple_buildings",
  "flat",
  "flats",
  "floating",
  "floating_home",
  "flour_mill",
  "folie",
  "folly",
  "food",
  "food_and_drink",
  "food_truck",
  "for-sale",
  "fort",
  "foundation",
  "foundation/ruin",
  "foundations",
  "fourplex",
  "fraternity",
  "fraternity house",
  "friary",
  "from old sat image",
  "fronton",
  "fuel",
  "function_hall",
  "funeral home",
  "funeral_home",
  "furnace",
  "furniture",
  "g",
  "gho",
  "ga",
  "ga'",
  "gabled",
  "gallery",
  "gaq",
  "gar",
  "garage",
  "garage'",
  "garage;apartments",
  "garage;government",
  "garage;house",
  "garage;shed",
  "garage;yes",
  "garageq",
  "garages",
  "garages;yes",
  "garbage_shed",
  "garden",
  "garq",
  "gas",
  "gas station",
  "gas_pumps_under_awning",
  "gas_station",
  "gasometer",
  "gasstation",
  "gate",
  "gate_house",
  "gated",
  "gatehouse",
  "gathering",
  "gathering_place",
  "gaza",
  "gazebo",
  "gazebo;roof",
  "gazeboo",
  "gazeebo",
  "gazibo",
  "general",
  "generator",
  "generic",
  "ger",
  "gf",
  "gh",
  "glasshouse",
  "gov",
  "government",
  "government;school",
  "government;yes",
  "government_office",
  "governmental",
  "granary",
  "grandstand",
  "grandstands",
  "grandtand",
  "grange",
  "grass",
  "greenhouse",
  "greenhouseq",
  "grist_mill",
  "grocery",
  "grocery store",
  "grocery_store",
  "groundskeeping",
  "group housing",
  "group_housing",
  "guard station",
  "guard_house",
  "guard_shack",
  "guardhouse",
  "guesthouse",
  "gym",
  "gym;clubhouse",
  "gymnasium",
  "h",
  "h'",
  "h]",
  "hairdresser",
  "hall",
  "hall_of_residence",
  "hallway",
  "hangar",
  "hangar;detached",
  "hangar;yes",
  "hange",
  "hanger",
  "hargar",
  "health",
  "health_House",
  "health_clinic",
  "healthcare",
  "heritage_building",
  "hg",
  "highrise",
  "hipped",
  "historic",
  "historic_building",
  "historical",
  "historical house",
  "hm",
  "ho",
  "hoaddr:housenumber=4305",
  "home",
  "hoouse",
  "hopsital",
  "hor",
  "hospital",
  "hospital;roof",
  "hospital;yes",
  "hostel",
  "hosue",
  "hot",
  "hotel",
  "hotel;roof",
  "hotel;yes",
  "hotel_building",
  "hotels",
  "house addr:city=Chula Vista",
  "house on the water",
  "house; commercial",
  "house;apartments",
  "house;carport",
  "house;detached",
  "house;garage",
  "house;residential",
  "house;roof",
  "house;static_caravan",
  "house;yes",
  "house=yes",
  "house`",
  "houseb3",
  "houseboat",
  "housebuilding=house",
  "housel",
  "houser",
  "houses",
  "housev",
  "housing",
  "housr",
  "hq",
  "hr",
  "hs",
  "hunting,_fishing,_camping_supplies",
  "hunting_stand",
  "hush_house",
  "hut",
  "hut;gazebo",
  "hydro",
  "hyes",
  "i",
  "ind",
  "indoor_swimming_pool",
  "indsutrial",
  "industrial",
  "industrial hanger 13",
  "industrial, building=yes",
  "industrial;yes",
  "industrials",
  "indzustrial",
  "inflated",
  "inn",
  "inner",
  "inside",
  "inside_store",
  "institute,_conference_centre",
  "institutional",
  "inu",
  "islamic_center",
  "iy",
  "j",
  "jail",
  "judging_booth",
  "kennel",
  "key",
  "kindergarten",
  "kingdom_hall",
  "kiosk",
  "kitchen",
  "latrine",
  "laundromat",
  "laundry",
  "laundry_room",
  "law enforcement",
  "leggett hall",
  "leisure",
  "lesiure",
  "lib",
  "library",
  "lifeguard_tower",
  "lighthouse",
  "line",
  "loading ramp",
  "loading_dock",
  "loafing_shed",
  "local",
  "locomotive",
  "lodge",
  "longhouse",
  "main",
  "main_terminal",
  "maintenance",
  "maintenance_shop",
  "maintenence",
  "mall",
  "manor",
  "manse",
  "mansion",
  "manufacture",
  "manufactured",
  "manufactured_home",
  "manufacturing",
  "mapillary",
  "marquee",
  "masonic",
  "masonic_lodge",
  "masons",
  "mausoleum",
  "maybe",
  "medical",
  "medical center",
  "medical_clinic",
  "medium",
  "meeting_room",
  "memorial",
  "mental_health_clinic",
  "mil",
  "military",
  "military_armoury",
  "mill",
  "minor parts",
  "mixd_use",
  "mixed",
  "mixed use",
  "mixed-use",
  "mixed_use",
  "mobile",
  "mobile_home",
  "modular",
  "monastery",
  "monume",
  "monument",
  "mortuary",
  "mosque",
  "motel",
  "motel;yes",
  "movable",
  "movable_house",
  "muesum",
  "multi_level_parking",
  "multi_office",
  "multiple_local_government_buildings",
  "multipurpose",
  "municipal",
  "municipal_government",
  "municipial",
  "municiple",
  "musem",
  "museum",
  "museums",
  "music_school",
  "musuem",
  "n",
  "nails",
  "name",
  "natatorium",
  "new",
  "nightclub",
  "noise_barrier",
  "non-profit",
  "none",
  "nonprofit_center",
  "nuclear",
  "nursing facility",
  "nursing_home",
  "o",
  "observation_tower",
  "observatory",
  "occupied",
  "of",
  "off",
  "office",
  "office;clubhouse",
  "office;company",
  "office;industrial",
  "office;public",
  "office;retail",
  "office;roof",
  "office;yes",
  "offices",
  "ofiice",
  "om",
  "open",
  "open_exposure",
  "organization",
  "ou",
  "out",
  "outdoor stage",
  "outdoor;yes",
  "outdoor_riding_arena",
  "outdoor_risers",
  "outdoor_structure",
  "outer",
  "outhouse",
  "outside",
  "p",
  "paddock",
  "pagoda",
  "painter",
  "palace",
  "palapa",
  "parish",
  "parish_hall",
  "park",
  "park shelter",
  "parking",
  "parking lot",
  "parking structure",
  "parking;yes",
  "parking_entrance",
  "parking_garage",
  "parking_lot",
  "parking_shelter",
  "parking_space",
  "parking_structure",
  "parliament",
  "parque",
  "parsonage",
  "part",
  "part=porch",
  "patio",
  "pavilion",
  "pavillion",
  "pavillon",
  "pavilon",
  "pedestrian",
  "pergola",
  "pharmaceutical_company",
  "pharmacy",
  "picnic shelter",
  "picnic_shelter",
  "pier",
  "place_of_worship",
  "plane",
  "planned",
  "plant",
  "plant_nursery",
  "platform",
  "police",
  "police_dept",
  "police_station",
  "pool",
  "pool maintnence",
  "pool_house",
  "poolhouse",
  "porch",
  "porkshed",
  "portable",
  "portable_classroom",
  "portable_toilet",
  "portables",
  "portico",
  "post office",
  "post_office",
  "potato_barn",
  "poultry_house",
  "poultry_stable",
  "power",
  "power_plant",
  "power_station",
  "prefab",
  "presbyterian",
  "presbytery",
  "press_box",
  "primary",
  "printing_facility",
  "prison",
  "private",
  "private property",
  "private_residence",
  "produce",
  "proposed",
  "pub",
  "public",
  "public;government",
  "public;yes",
  "public_building",
  "pulbic",
  "pump_house",
  "pumphouse",
  "pumping_station",
  "pyramid",
  "q",
  "quarry",
  "quonset",
  "quonset_hut",
  "r",
  "radio_station",
  "rail",
  "rail_car",
  "railroad",
  "railway",
  "railway_shed",
  "railway_shop",
  "railway_station",
  "ramada",
  "ranch",
  "razed",
  "razed building",
  "rebuilt",
  "rec_center",
  "recre",
  "recreation",
  "recreation center",
  "recreation_center",
  "recreation_centre",
  "recreation_ground",
  "recreational",
  "rectory",
  "ref",
  "refectory",
  "refinery",
  "religion",
  "religious",
  "religious;yes",
  "remnant",
  "renovation",
  "rental properties",
  "rental_office",
  "repair_shops",
  "res",
  "research",
  "research_centre",
  "reservoir",
  "residential",
  "residential, building=yes",
  "residential,building=yes",
  "residential;apartments",
  "residential;commercial",
  "residential;house",
  "residential;retail",
  "residential;roof",
  "residential;yes",
  "residentialQ",
  "residential_duplex",
  "residential_home_and_indoor_riding_arena/_farm",
  "residential_semi",
  "residential_single",
  "residential_town",
  "residentialbuilding=apartments",
  "residentialqIvan Aguirre",
  "resort",
  "rest",
  "rest_room",
  "restaurant",
  "restauraunt",
  "restored_replica",
  "restraunt;hotel",
  "restroom",
  "restrooms",
  "resturant",
  "ret",
  "ret]",
  "retai",
  "retaiil",
  "retail",
  "retail/residential",
  "retail; yes",
  "retail;apartments",
  "retail;commercial",
  "retail;residential",
  "retail;roof",
  "retail;shed",
  "retail;shed;yes",
  "retail;yes",
  "retail_store",
  "retailer",
  "retal",
  "retirement home",
  "retirement_home",
  "retr",
  "riding_hall",
  "riverboat",
  "rocket",
  "rof",
  "roff",
  "roiof",
  "romney",
  "roo",
  "rood",
  "roof",
  "roof;apartments",
  "roof;church",
  "roof;commercial",
  "roof;hospital",
  "roof;hotel",
  "roof;house",
  "roof;industrial",
  "roof;kindergarten",
  "roof;office",
  "roof;public",
  "roof;residential",
  "roof;retail",
  "roof;school",
  "roof;university",
  "roof;yes",
  "roof=no",
  "roof_overhang",
  "roofe3",
  "roofl",
  "roofm",
  "roofo",
  "roofs",
  "rooft",
  "room",
  "root",
  "ros",
  "rostrum",
  "roundhouse",
  "rppf",
  "ruind",
  "ruins",
  "ruins;barn",
  "s",
  "salon",
  "salt_barn",
  "salt_dome",
  "salt_pyramid",
  "sauna",
  "sawmill",
  "schh",
  "school",
  "school, office",
  "school; roof; school",
  "school;church",
  "school;commercial",
  "school;elementary_school",
  "school;roof",
  "school;yes",
  "school_district",
  "schooll",
  "scientific",
  "scoring_box",
  "seating",
  "seats",
  "security",
  "security_booth",
  "self-storage",
  "self_storage",
  "semi",
  "semidetached",
  "semidetached_house",
  "service",
  "service_station",
  "services publiques",
  "ses",
  "sg",
  "sgarage",
  "sh",
  "shack",
  "shadehouse",
  "shanty",
  "shed",
  "shed,_detached",
  "shed;garage;house",
  "shed;house",
  "shed;retail",
  "shed;yes",
  "shed_and_seasonal_restrooms",
  "shedm",
  "sheds",
  "sheepfold",
  "shelt",
  "shelter",
  "shingle mill",
  "ship",
  "shipping_container",
  "shooting_range",
  "shooting_stand",
  "shop",
  "shopping",
  "shopping center",
  "shopping_center",
  "shopping_mall",
  "shops",
  "show_house",
  "shrine",
  "sidewalk",
  "sign",
  "siil",
  "sile",
  "silo",
  "ska",
  "skating_rink",
  "ski jump",
  "ski lodge",
  "ski_jump",
  "skybridge",
  "skyscraper",
  "skywalk",
  "skyway",
  "sleeper_car",
  "slipway",
  "slurry_tank",
  "small",
  "smokery",
  "sn",
  "snack_bar",
  "social",
  "social_centre",
  "social_club",
  "social_facility",
  "sport",
  "sport_stand",
  "sports_centre",
  "sports_complex",
  "sports_hall",
  "springhouse",
  "square",
  "sroof",
  "ss",
  "sse",
  "stable",
  "stable'",
  "stable;garage",
  "stable;yes",
  "stables",
  "stadium",
  "stadium seating",
  "stadium;yes",
  "stadium_seating",
  "stage",
  "staircase",
  "stairs",
  "stand",
  "stands",
  "static caravan",
  "static_caravan",
  "static_mobile_home",
  "station",
  "stationary_caravan",
  "steam_plant",
  "steps",
  "stilt house",
  "stilt_house",
  "sto",
  "storage",
  "storage tank",
  "storage-self",
  "storage_facility",
  "storage_hall",
  "storage_locker",
  "storage_rental",
  "storage_shed",
  "storage_tank",
  "storage_unit",
  "storages",
  "store",
  "storefront",
  "strip mall",
  "strip_mall",
  "stripmall",
  "structure",
  "student_health_clinic_(building_43)",
  "studio",
  "sty",
  "substation",
  "subway_entrance",
  "sugar_shack",
  "suite",
  "suites",
  "supermarket",
  "surface",
  "swimming_pool",
  "sx",
  "synagogue",
  "t",
  "tabernacle",
  "taller",
  "tank",
  "tavern",
  "tech_cab",
  "teepee",
  "telco",
  "telecom",
  "telecommunications",
  "telephone_exchange",
  "telescope",
  "television_station",
  "temple",
  "tent",
  "tents",
  "terminal",
  "terr",
  "terrace",
  "tes",
  "textile_mill",
  "theater",
  "theater_arts_center",
  "theatre",
  "ticketing",
  "tiny_house",
  "tipi",
  "toilet",
  "toilets",
  "toll_booth",
  "tomb",
  "tourism",
  "tower",
  "tower 2",
  "tower 3",
  "tower_block",
  "town_hall",
  "townhall",
  "townhome",
  "townhomes",
  "townhosue",
  "townhouse",
  "townhouses",
  "track",
  "trader",
  "trailer",
  "trailer_park",
  "train",
  "train car",
  "train_car",
  "train_station",
  "train_wait_station",
  "transformer_tower",
  "transmitter",
  "transportation",
  "transports",
  "tree",
  "tree_house",
  "tree_row",
  "tribune",
  "triplex",
  "triumphal_arch",
  "true",
  "tunnel",
  "turning_circle",
  "tweak",
  "type",
  "u",
  "unclassified",
  "undefined",
  "underground",
  "univ",
  "univeristy",
  "university",
  "university library",
  "university;apartments",
  "university;chapel",
  "university;yes",
  "universityele=247",
  "univesity",
  "unknown",
  "unkown",
  "use:residential",
  "utilities",
  "utility",
  "vacant",
  "valley",
  "vehicle",
  "ventilation_shaft",
  "veranda",
  "veterinary",
  "vicarage",
  "villa",
  "walkway",
  "wall",
  "ward",
  "ware",
  "warehouse",
  "warehouse/offices",
  "warehouse;yes",
  "warm up cabin",
  "waste_disposal",
  "wastewater_plant",
  "water",
  "water treatment",
  "water_storage_tank",
  "water_tank",
  "water_tower",
  "watermill",
  "waterworks",
  "way",
  "wayside_shrine",
  "weapon_armory",
  "windmill",
  "winery",
  "wing",
  "wood",
  "woods",
  "work_camp",
  "works",
  "workshop",
  "x",
  "y'",
  "y3",
  "y]",
  "yard",
  "yb",
  "ye",
  "yer",
  "yert",
  "yes#TexasTech",
  "yes,_Barn",
  "yes,_Bunkhouse",
  "yes,_Latrine",
  "yes,_cabin",
  "yes,_garage,_fire station",
  "yes,_museum",
  "yes:disused",
  "yes; commercial; yes; commercial",
  "yes; roof",
  "yes;ROO",
  "yes;apartments",
  "yes;cabin",
  "yes;carport",
  "yes;chapel",
  "yes;church",
  "yes;civic",
  "yes;college",
  "yes;commercial",
  "yes;commercial;residential",
  "yes;detached",
  "yes;dormitory",
  "yes;farm",
  "yes;fraternity_house",
  "yes;government",
  "yes;grandstand",
  "yes;hospital",
  "yes;hotel",
  "yes;hotel;roof",
  "yes;hut",
  "yes;industrial",
  "yes;motel",
  "yes;office",
  "yes;offices",
  "yes;parking",
  "yes;pavilion",
  "yes;public",
  "yes;religious",
  "yes;residential",
  "yes;retail",
  "yes;retail;commercial",
  "yes;roof",
  "yes;school",
  "yes;shed",
  "yes;terrace;house",
  "yes;transportation",
  "yes;university",
  "yes?",
  "yesQ",
  "yes_B3G_0H2",
  "yes_but_the_burgee_bar_is_on_the_second_floor_of_the_building",
  "yes_under_construction",
  "yesa",
  "yesaddr:city=Sambro Head",
  "yesbuilding=house",
  "yesh",
  "yeshamg",
  "yesleisure=sports_centre",
  "yesm",
  "yesname=Towne Motel",
  "yesq",
  "yesqq",
  "yesr",
  "yew",
  "yt",
  "yurt",
  "ywa",
  "yws",
  "yyes",
];
const residentialOptions = [
  "semidetached_House",
  "terrace",
  "static_caravan",
  "tree_house",
  "houseboat",
  "farm",
  "farm_auxiliary",
  "house",
  "residential",
];


const citiesOptions = [
  {
    "name": "Aaronsburg",
    "lng": -77.45409592605002,
    "lat": 40.901300133876504
  },
  {
    "name": "Abbeville",
    "lng": -92.12630874634254,
    "lat": 29.975026968244386
  },
  {
    "name": "Abbeville",
    "lng": -89.50233304586466,
    "lat": 34.50299366750195
  },
  {
    "name": "Abbeville",
    "lng": -85.25265852003103,
    "lat": 31.566761665883
  },
  {
    "name": "Abbeville",
    "lng": -83.30652396688632,
    "lat": 31.992471964804917
  },
  {
    "name": "Abbeville",
    "lng": -82.3779021740626,
    "lat": 34.17916933731696
  },
  {
    "name": "Abbot",
    "lng": -69.4758067917599,
    "lat": 45.19378256752125
  },
  {
    "name": "Abbotsford",
    "lng": -90.31522147352811,
    "lat": 44.943729878957335
  },
  {
    "name": "Abbotsford",
    "lng": -90.3060073184392,
    "lat": 44.964235017678384
  },
  {
    "name": "Abbotsford",
    "lng": -90.28495965544761,
    "lat": 44.951510846760655
  },
  {
    "name": "Abbyville",
    "lng": -98.20399042347468,
    "lat": 37.97030779963548
  },
  {
    "name": "Abercrombie",
    "lng": -96.72666676191825,
    "lat": 46.44650979168533
  },
  {
    "name": "Aberdeen",
    "lng": -123.84817219422938,
    "lat": 46.94573016004483
  },
  {
    "name": "Aberdeen",
    "lng": -123.81757558759163,
    "lat": 46.9745998704497
  },
  {
    "name": "Aberdeen",
    "lng": -123.74576171620791,
    "lat": 46.98769794662385
  },
  {
    "name": "Aberdeen",
    "lng": -123.68990389568035,
    "lat": 47.01595523091851
  },
  {
    "name": "Aberdeen",
    "lng": -112.83838107127828,
    "lat": 42.94409881904229
  },
  {
    "name": "Aberdeen",
    "lng": -98.46691758475171,
    "lat": 45.465402738618984
  },
  {
    "name": "Aberdeen",
    "lng": -88.55385294466758,
    "lat": 33.8286562217082
  },
  {
    "name": "Aberdeen",
    "lng": -83.77060971470259,
    "lat": 38.67140034194514
  },
  {
    "name": "Aberdeen",
    "lng": -79.43764871528235,
    "lat": 35.12596713806273
  },
  {
    "name": "Aberdeen",
    "lng": -79.43743311152303,
    "lat": 35.14071510614187
  },
  {
    "name": "Aberdeen",
    "lng": -79.43654898712369,
    "lat": 35.14316832615076
  },
  {
    "name": "Aberdeen",
    "lng": -79.43234145760024,
    "lat": 35.14237334912373
  },
  {
    "name": "Aberdeen",
    "lng": -79.43157868222887,
    "lat": 35.142091413190386
  },
  {
    "name": "Aberdeen",
    "lng": -79.42912280710242,
    "lat": 35.0940298459503
  },
  {
    "name": "Aberdeen",
    "lng": -79.42646994861244,
    "lat": 35.163733455457574
  },
  {
    "name": "Aberdeen",
    "lng": -79.42544363868487,
    "lat": 35.12101968328735
  },
  {
    "name": "Aberdeen",
    "lng": -79.4179460980114,
    "lat": 35.11539035258896
  },
  {
    "name": "Aberdeen",
    "lng": -79.4143562664346,
    "lat": 35.102374497646814
  },
  {
    "name": "Aberdeen",
    "lng": -79.41397839424161,
    "lat": 35.12092704717532
  },
  {
    "name": "Aberdeen",
    "lng": -79.4139120728382,
    "lat": 35.1222571187647
  },
  {
    "name": "Aberdeen",
    "lng": -79.3963027244582,
    "lat": 35.12298947655792
  },
  {
    "name": "Aberdeen",
    "lng": -79.39547328450844,
    "lat": 35.12138628014075
  },
  {
    "name": "Aberdeen",
    "lng": -79.39076811838737,
    "lat": 35.11394733141815
  },
  {
    "name": "Aberdeen",
    "lng": -79.3882075600216,
    "lat": 35.11521810888228
  },
  {
    "name": "Aberdeen",
    "lng": -79.38719478784047,
    "lat": 35.1157376710212
  },
  {
    "name": "Abie",
    "lng": -96.94951407984996,
    "lat": 41.33401570874268
  },
  {
    "name": "Abilene",
    "lng": -97.25564410165342,
    "lat": 38.93277797871767
  },
  {
    "name": "Abilene",
    "lng": -97.24126506054814,
    "lat": 38.93440178809596
  },
  {
    "name": "Abilene",
    "lng": -97.23619635019277,
    "lat": 38.90365538016838
  },
  {
    "name": "Abilene",
    "lng": -97.22127804470225,
    "lat": 38.92372983750984
  },
  {
    "name": "Abilene",
    "lng": -97.20121789193533,
    "lat": 38.899383896939554
  },
  {
    "name": "Abingdon",
    "lng": -90.40090580377013,
    "lat": 40.80378163340123
  },
  {
    "name": "Abingdon",
    "lng": -81.97123141596323,
    "lat": 36.70902269226036
  },
  {
    "name": "Abita Springs",
    "lng": -90.0416975283828,
    "lat": 30.458827910359137
  },
  {
    "name": "Abita Springs",
    "lng": -90.02922466445044,
    "lat": 30.47662295656897
  },
  {
    "name": "Absecon",
    "lng": -74.4941548578064,
    "lat": 39.42209369109193
  },
  {
    "name": "Accident",
    "lng": -79.31989012630385,
    "lat": 39.625780227999684
  },
  {
    "name": "Accomac",
    "lng": -75.66750376717177,
    "lat": 37.71955634483898
  },
  {
    "name": "Acequia",
    "lng": -113.59684634815407,
    "lat": 42.66882877058301
  },
  {
    "name": "Achille",
    "lng": -96.38833394812178,
    "lat": 33.83528569562029
  },
  {
    "name": "Ackerman",
    "lng": -89.17109378099141,
    "lat": 33.31100783623375
  },
  {
    "name": "Ackley",
    "lng": -93.05239466671631,
    "lat": 42.551888445759346
  },
  {
    "name": "Ackworth",
    "lng": -93.4745895827848,
    "lat": 41.36631826206381
  },
  {
    "name": "Ackworth",
    "lng": -93.47258747048602,
    "lat": 41.366182209182654
  },
  {
    "name": "Acton",
    "lng": -71.43777246712608,
    "lat": 42.484319398008935
  },
  {
    "name": "Acton",
    "lng": -70.9207601074099,
    "lat": 43.52994563892848
  },
  {
    "name": "Acworth",
    "lng": -84.69301823332812,
    "lat": 34.0473538651567
  },
  {
    "name": "Acworth",
    "lng": -84.6705946910138,
    "lat": 34.05633368222629
  },
  {
    "name": "Ada",
    "lng": -96.68574838675644,
    "lat": 34.75360803110325
  },
  {
    "name": "Ada",
    "lng": -96.66882135657283,
    "lat": 34.767806672438695
  },
  {
    "name": "Ada",
    "lng": -96.5164772126728,
    "lat": 47.2988610802512
  },
  {
    "name": "Ada",
    "lng": -83.82468721502518,
    "lat": 40.76803759404284
  },
  {
    "name": "Adair",
    "lng": -95.26767537911076,
    "lat": 36.43812192257078
  },
  {
    "name": "Adair",
    "lng": -94.6434308945213,
    "lat": 41.50038991272873
  },
  {
    "name": "Adairsville",
    "lng": -84.9634171215292,
    "lat": 34.38024426845885
  },
  {
    "name": "Adairsville",
    "lng": -84.92108614455823,
    "lat": 34.37013745454496
  },
  {
    "name": "Adairville",
    "lng": -86.85661763097997,
    "lat": 36.671361437487015
  },
  {
    "name": "Adak",
    "lng": -176.59989164715725,
    "lat": 51.882198945648014
  },
  {
    "name": "Adams",
    "lng": -98.07367756573196,
    "lat": 48.42014157089178
  },
  {
    "name": "Adams",
    "lng": -96.5126425628243,
    "lat": 40.45716176581808
  },
  {
    "name": "Adams",
    "lng": -92.71899425809947,
    "lat": 43.5653576223214
  },
  {
    "name": "Adams",
    "lng": -89.81690074926007,
    "lat": 43.955392748496486
  },
  {
    "name": "Adams",
    "lng": -87.06280073044717,
    "lat": 36.58237318362644
  },
  {
    "name": "Adams",
    "lng": -76.02276609082483,
    "lat": 43.810243995438285
  },
  {
    "name": "Adams",
    "lng": -73.11873403349738,
    "lat": 42.62685865887926
  },
  {
    "name": "Adamsville",
    "lng": -88.38651103536088,
    "lat": 35.255623765580104
  },
  {
    "name": "Adamsville",
    "lng": -87.02628210713954,
    "lat": 33.659759427320814
  },
  {
    "name": "Adamsville",
    "lng": -86.97448791156064,
    "lat": 33.60633311244154
  },
  {
    "name": "Adamsville",
    "lng": -86.95594844089459,
    "lat": 33.61338797318653
  },
  {
    "name": "Adamsville",
    "lng": -86.95541540305094,
    "lat": 33.612965894231536
  },
  {
    "name": "Adamsville",
    "lng": -86.92075077603582,
    "lat": 33.57985941832601
  },
  {
    "name": "Adamsville",
    "lng": -86.9201005523928,
    "lat": 33.58917110647318
  },
  {
    "name": "Adamsville",
    "lng": -86.91974376267514,
    "lat": 33.58159375192217
  },
  {
    "name": "Adamsville",
    "lng": -81.88268256085726,
    "lat": 40.0689149467764
  },
  {
    "name": "Addieville",
    "lng": -89.48646077107092,
    "lat": 38.391306571177545
  },
  {
    "name": "Addington",
    "lng": -97.96594279227129,
    "lat": 34.24321374012442
  },
  {
    "name": "Addis",
    "lng": -91.26698124297471,
    "lat": 30.362650516424107
  },
  {
    "name": "Addison",
    "lng": -88.03626980464449,
    "lat": 41.91212900388069
  },
  {
    "name": "Addison",
    "lng": -88.01623519698668,
    "lat": 41.913767265359276
  },
  {
    "name": "Addison",
    "lng": -88.00858412598161,
    "lat": 41.93137130531469
  },
  {
    "name": "Addison",
    "lng": -87.18057786358496,
    "lat": 34.21823885581833
  },
  {
    "name": "Addison",
    "lng": -87.17627483721039,
    "lat": 34.20199355540617
  },
  {
    "name": "Addison",
    "lng": -84.34926670187043,
    "lat": 41.986198818667894
  },
  {
    "name": "Addison",
    "lng": -77.23198870095086,
    "lat": 42.10632257918127
  },
  {
    "name": "Addison",
    "lng": -67.70729053641779,
    "lat": 44.51706855408406
  },
  {
    "name": "Addison (Webster Springs)",
    "lng": -80.40821661952356,
    "lat": 38.478193553869254
  },
  {
    "name": "Addyston",
    "lng": -84.71036142812426,
    "lat": 39.137049200648676
  },
  {
    "name": "Adel",
    "lng": -94.01181948426408,
    "lat": 41.610126796100374
  },
  {
    "name": "Adel",
    "lng": -83.42698566550631,
    "lat": 31.135316170173347
  },
  {
    "name": "Adelanto",
    "lng": -117.44123098306663,
    "lat": 34.58685693884755
  },
  {
    "name": "Adeline",
    "lng": -89.49186621614342,
    "lat": 42.14051706921501
  },
  {
    "name": "Adell",
    "lng": -87.9481848048287,
    "lat": 43.62133824309855
  },
  {
    "name": "Adelphi",
    "lng": -82.74563430293736,
    "lat": 39.46463188294639
  },
  {
    "name": "Adelphia Village",
    "lng": -121.94719153642363,
    "lat": 47.35296535829317
  },
  {
    "name": "Adena",
    "lng": -80.87620955683843,
    "lat": 40.21666361435621
  },
  {
    "name": "Admire",
    "lng": -96.10207137363716,
    "lat": 38.640918905519186
  },
  {
    "name": "Adona",
    "lng": -92.89905673904694,
    "lat": 35.040021595652284
  },
  {
    "name": "Adrian",
    "lng": -95.93304834828523,
    "lat": 43.633215079331066
  },
  {
    "name": "Adrian",
    "lng": -94.34988394712667,
    "lat": 38.39604054670562
  },
  {
    "name": "Adrian",
    "lng": -84.0446859992147,
    "lat": 41.899455476169315
  },
  {
    "name": "Adrian",
    "lng": -82.5920258198258,
    "lat": 32.53059118135982
  },
  {
    "name": "Advance",
    "lng": -89.91463234070082,
    "lat": 37.103370380036196
  },
  {
    "name": "Advance",
    "lng": -86.61999105158799,
    "lat": 39.99556329901888
  },
  {
    "name": "Afton",
    "lng": -110.93152137676698,
    "lat": 42.72749411869225
  },
  {
    "name": "Afton",
    "lng": -94.95762571366477,
    "lat": 36.6964547827225
  },
  {
    "name": "Afton",
    "lng": -94.19519266829867,
    "lat": 41.02767746776803
  },
  {
    "name": "Afton",
    "lng": -92.8176040787588,
    "lat": 44.90423265952972
  },
  {
    "name": "Afton",
    "lng": -75.52478939741653,
    "lat": 42.22917694300139
  },
  {
    "name": "Agar",
    "lng": -100.07337712184967,
    "lat": 44.839158610477966
  },
  {
    "name": "Agawam",
    "lng": -72.65255149975783,
    "lat": 42.06573698950023
  },
  {
    "name": "Agency",
    "lng": -94.74724008088329,
    "lat": 39.65966591621396
  },
  {
    "name": "Agency",
    "lng": -92.30728380973682,
    "lat": 40.99696814127955
  },
  {
    "name": "Agenda",
    "lng": -97.43222949248602,
    "lat": 39.70727381838193
  },
  {
    "name": "Agoura Hills",
    "lng": -118.76144614030545,
    "lat": 34.15062940072428
  },
  {
    "name": "Agra",
    "lng": -99.11960220882236,
    "lat": 39.76151105384847
  },
  {
    "name": "Agra",
    "lng": -96.8701812205846,
    "lat": 35.894869746021826
  },
  {
    "name": "Aguilar",
    "lng": -104.65503583106036,
    "lat": 37.40361810668482
  },
  {
    "name": "Ahmeek",
    "lng": -88.39710503782062,
    "lat": 47.298520273530535
  },
  {
    "name": "Ahoskie",
    "lng": -77.03045195828616,
    "lat": 36.30831328112174
  },
  {
    "name": "Ahoskie",
    "lng": -77.02359887203454,
    "lat": 36.30520928833847
  },
  {
    "name": "Ahoskie",
    "lng": -76.98462670537627,
    "lat": 36.28592197317691
  },
  {
    "name": "Aiken",
    "lng": -81.755248536094,
    "lat": 33.57170428119238
  },
  {
    "name": "Aiken",
    "lng": -81.74592607588923,
    "lat": 33.5141900219092
  },
  {
    "name": "Aiken",
    "lng": -81.74208657741809,
    "lat": 33.53657163323018
  },
  {
    "name": "Aiken",
    "lng": -81.74126577978339,
    "lat": 33.537405521802484
  },
  {
    "name": "Aiken",
    "lng": -81.74122843226239,
    "lat": 33.53814645711404
  },
  {
    "name": "Aiken",
    "lng": -81.72721685422196,
    "lat": 33.533865050178676
  },
  {
    "name": "Aiken",
    "lng": -81.70185441657893,
    "lat": 33.49666858643841
  },
  {
    "name": "Aiken",
    "lng": -81.70161474521498,
    "lat": 33.50141695242275
  },
  {
    "name": "Ailey",
    "lng": -82.56909924790538,
    "lat": 32.188119011601266
  },
  {
    "name": "Ainsworth",
    "lng": -99.85756630558275,
    "lat": 42.54843281717444
  },
  {
    "name": "Ainsworth",
    "lng": -91.554502447812,
    "lat": 41.2901278363207
  },
  {
    "name": "Airmont",
    "lng": -74.09890343386257,
    "lat": 41.09933266693737
  },
  {
    "name": "Airport Drive",
    "lng": -94.51613109024244,
    "lat": 37.14052919236645
  },
  {
    "name": "Airway Heights",
    "lng": -117.5789944448246,
    "lat": 47.64582155229278
  },
  {
    "name": "Aitkin",
    "lng": -93.71788055335398,
    "lat": 46.52248187812958
  },
  {
    "name": "Aitkin",
    "lng": -93.70769292537973,
    "lat": 46.52839548011642
  },
  {
    "name": "Aitkin",
    "lng": -93.69886007749989,
    "lat": 46.537786997669535
  },
  {
    "name": "Aitkin",
    "lng": -93.69782644070365,
    "lat": 46.539269626448345
  },
  {
    "name": "Aitkin",
    "lng": -93.68543037791169,
    "lat": 46.53972307381395
  },
  {
    "name": "Aitkin",
    "lng": -93.68079655598069,
    "lat": 46.54168287659967
  },
  {
    "name": "Aitkin",
    "lng": -93.676436196903,
    "lat": 46.54353851312832
  },
  {
    "name": "Aitkin",
    "lng": -93.6764054013459,
    "lat": 46.541735225222915
  },
  {
    "name": "Akaska",
    "lng": -100.1208611965429,
    "lat": 45.331026515316715
  },
  {
    "name": "Akeley",
    "lng": -94.72799842602339,
    "lat": 47.00059463180208
  },
  {
    "name": "Akhiok",
    "lng": -154.21372410745047,
    "lat": 56.945292078561394
  },
  {
    "name": "Akiak",
    "lng": -161.21237035728868,
    "lat": 60.90622805746658
  },
  {
    "name": "Akron",
    "lng": -103.21280401178878,
    "lat": 40.161037713109195
  },
  {
    "name": "Akron",
    "lng": -96.55707196126403,
    "lat": 42.827012834022014
  },
  {
    "name": "Akron",
    "lng": -96.55129181238529,
    "lat": 42.81850565631763
  },
  {
    "name": "Akron",
    "lng": -87.74284608720099,
    "lat": 32.878535966484144
  },
  {
    "name": "Akron",
    "lng": -86.02469641062201,
    "lat": 41.03914589373025
  },
  {
    "name": "Akron",
    "lng": -83.51430948283857,
    "lat": 43.56668581618534
  },
  {
    "name": "Akron",
    "lng": -81.52194525438982,
    "lat": 41.08010977272252
  },
  {
    "name": "Akutan",
    "lng": -165.7928657006688,
    "lat": 54.12900340697122
  },
  {
    "name": "Alabama",
    "lng": -86.84334045878514,
    "lat": 32.78216851212248
  },
  {
    "name": "Alabaster",
    "lng": -86.82312116291213,
    "lat": 33.220559052039626
  },
  {
    "name": "Alabaster",
    "lng": -86.7978642616439,
    "lat": 33.21544076737441
  },
  {
    "name": "Alabaster",
    "lng": -86.78316931754182,
    "lat": 33.20833727101
  },
  {
    "name": "Alabaster",
    "lng": -86.78083146000853,
    "lat": 33.19237933384814
  },
  {
    "name": "Alabaster",
    "lng": -86.7622864182172,
    "lat": 33.208285937908805
  },
  {
    "name": "Alachua",
    "lng": -82.52701470914576,
    "lat": 29.834755387733967
  },
  {
    "name": "Alachua",
    "lng": -82.48293195102684,
    "lat": 29.778084618741342
  },
  {
    "name": "Alachua",
    "lng": -82.4115736348102,
    "lat": 29.768924699590286
  },
  {
    "name": "Alakanuk",
    "lng": -164.63041537828636,
    "lat": 62.68940240768244
  },
  {
    "name": "Alamance",
    "lng": -79.48922523842762,
    "lat": 36.028518771992864
  },
  {
    "name": "Alamance",
    "lng": -79.48582632119988,
    "lat": 36.02212753214116
  },
  {
    "name": "Alamo",
    "lng": -103.46775419991069,
    "lat": 48.58248838864257
  },
  {
    "name": "Alamo",
    "lng": -89.11627504101713,
    "lat": 35.78290219295889
  },
  {
    "name": "Alamo",
    "lng": -87.05532352183428,
    "lat": 39.98359934043342
  },
  {
    "name": "Alamo",
    "lng": -82.78017688721889,
    "lat": 32.14717514279302
  },
  {
    "name": "Alamo Heights",
    "lng": -98.46813500627336,
    "lat": 29.482820298201055
  },
  {
    "name": "Alamogordo",
    "lng": -105.96238497259091,
    "lat": 32.883815378016266
  },
  {
    "name": "Alamosa",
    "lng": -105.87799089313731,
    "lat": 37.45721675678025
  },
  {
    "name": "Alamosa",
    "lng": -105.87692118089673,
    "lat": 37.475192596485336
  },
  {
    "name": "Alamosa",
    "lng": -105.87056523757808,
    "lat": 37.452538051174315
  },
  {
    "name": "Alanson",
    "lng": -84.78675367059728,
    "lat": 45.442558001502654
  },
  {
    "name": "Alapaha",
    "lng": -83.22353671605944,
    "lat": 31.38299144033806
  },
  {
    "name": "Alba",
    "lng": -94.41760323386282,
    "lat": 37.23715473441006
  },
  {
    "name": "Albany",
    "lng": -94.5826631503044,
    "lat": 45.6402405657604
  },
  {
    "name": "Albany",
    "lng": -94.57873865493698,
    "lat": 45.61799651339706
  },
  {
    "name": "Albany",
    "lng": -94.56652305712939,
    "lat": 45.62922623716904
  },
  {
    "name": "Albany",
    "lng": -94.55275530306058,
    "lat": 45.630477921573586
  },
  {
    "name": "Albany",
    "lng": -94.33353483320266,
    "lat": 40.24815547940333
  },
  {
    "name": "Albany",
    "lng": -90.58472676262124,
    "lat": 30.50263978613673
  },
  {
    "name": "Albany",
    "lng": -90.2179434749215,
    "lat": 41.78572290894884
  },
  {
    "name": "Albany",
    "lng": -89.43647963325537,
    "lat": 42.70734984769726
  },
  {
    "name": "Albany",
    "lng": -85.23243991450768,
    "lat": 40.30606808665489
  },
  {
    "name": "Albany",
    "lng": -85.13303596060632,
    "lat": 36.69350911992365
  },
  {
    "name": "Albany",
    "lng": -84.17620610281725,
    "lat": 31.577875120207967
  },
  {
    "name": "Albany",
    "lng": -82.19746933309014,
    "lat": 39.224477010905275
  },
  {
    "name": "Albany",
    "lng": -73.79868947575585,
    "lat": 42.6664639445506
  },
  {
    "name": "Albany",
    "lng": -72.3807620485079,
    "lat": 44.729234787663046
  },
  {
    "name": "Albemarle",
    "lng": -80.25424768089891,
    "lat": 35.362777302823005
  },
  {
    "name": "Albemarle",
    "lng": -80.24139830093098,
    "lat": 35.33725170838745
  },
  {
    "name": "Albemarle",
    "lng": -80.22115735516886,
    "lat": 35.39062859895284
  },
  {
    "name": "Albemarle",
    "lng": -80.2015417294841,
    "lat": 35.414717428604256
  },
  {
    "name": "Albemarle",
    "lng": -80.20001284006118,
    "lat": 35.32445041299268
  },
  {
    "name": "Albemarle",
    "lng": -80.19980567920801,
    "lat": 35.32251991146868
  },
  {
    "name": "Albemarle",
    "lng": -80.19280433838453,
    "lat": 35.36256947253598
  },
  {
    "name": "Albemarle",
    "lng": -80.18301863570869,
    "lat": 35.33252450079319
  },
  {
    "name": "Albemarle",
    "lng": -80.14822031562093,
    "lat": 35.30563757038898
  },
  {
    "name": "Albers",
    "lng": -89.61428602511008,
    "lat": 38.54517915201563
  },
  {
    "name": "Albert",
    "lng": -99.01106959469057,
    "lat": 38.45397633786851
  },
  {
    "name": "Albert City",
    "lng": -94.9491570913548,
    "lat": 42.7813744367955
  },
  {
    "name": "Albert Lea",
    "lng": -93.36427347383001,
    "lat": 43.65460578241092
  },
  {
    "name": "Alberta",
    "lng": -96.05036882674922,
    "lat": 45.574892002351966
  },
  {
    "name": "Alberta",
    "lng": -77.9124398161553,
    "lat": 36.84088810201296
  },
  {
    "name": "Alberta",
    "lng": -77.88997301347753,
    "lat": 36.86106594180883
  },
  {
    "name": "Alberton",
    "lng": -114.48126083267158,
    "lat": 47.00374272117371
  },
  {
    "name": "Albertville",
    "lng": -93.66129755310693,
    "lat": 45.236428455923374
  },
  {
    "name": "Albertville",
    "lng": -86.25073999658468,
    "lat": 34.2523521245513
  },
  {
    "name": "Albertville",
    "lng": -86.21056629425233,
    "lat": 34.263132026557656
  },
  {
    "name": "Albia",
    "lng": -92.80368033121457,
    "lat": 41.026712005089934
  },
  {
    "name": "Albin",
    "lng": -104.10161408288508,
    "lat": 41.41746733135387
  },
  {
    "name": "Albion",
    "lng": -117.2507132272755,
    "lat": 46.79192235041541
  },
  {
    "name": "Albion",
    "lng": -113.58043805329129,
    "lat": 42.40980810505162
  },
  {
    "name": "Albion",
    "lng": -98.0028123267365,
    "lat": 41.689283807779816
  },
  {
    "name": "Albion",
    "lng": -95.0992103192576,
    "lat": 34.66232237330106
  },
  {
    "name": "Albion",
    "lng": -92.98860309474718,
    "lat": 42.112565603478096
  },
  {
    "name": "Albion",
    "lng": -88.0581510348657,
    "lat": 38.37669471591119
  },
  {
    "name": "Albion",
    "lng": -88.05212428879207,
    "lat": 38.39285796537643
  },
  {
    "name": "Albion",
    "lng": -85.41869432447768,
    "lat": 41.39659327197256
  },
  {
    "name": "Albion",
    "lng": -84.75721321689775,
    "lat": 42.247799384601876
  },
  {
    "name": "Albion",
    "lng": -78.19019733330852,
    "lat": 43.24602674170943
  },
  {
    "name": "Albion",
    "lng": -69.44130308392506,
    "lat": 44.51708237072973
  },
  {
    "name": "Albright",
    "lng": -79.6399584080355,
    "lat": 39.493701637135295
  },
  {
    "name": "Albuquerque",
    "lng": -106.69152369286762,
    "lat": 35.08220908101591
  },
  {
    "name": "Albuquerque",
    "lng": -106.64237329343078,
    "lat": 35.107824748972696
  },
  {
    "name": "Alburnett",
    "lng": -91.62019915849272,
    "lat": 42.148407027564815
  },
  {
    "name": "Alburtis",
    "lng": -75.60011263941577,
    "lat": 40.50902640083883
  },
  {
    "name": "Alcester",
    "lng": -96.6279806156081,
    "lat": 43.02306551285849
  },
  {
    "name": "Alcoa",
    "lng": -83.9792988487443,
    "lat": 35.868998605942835
  },
  {
    "name": "Alcoa",
    "lng": -83.97489802770309,
    "lat": 35.80682382762044
  },
  {
    "name": "Alcoa",
    "lng": -83.94174131693376,
    "lat": 35.86923582058739
  },
  {
    "name": "Alda",
    "lng": -98.46879438865832,
    "lat": 40.866824771612656
  },
  {
    "name": "Alden",
    "lng": -98.31198879111736,
    "lat": 38.2438624153762
  },
  {
    "name": "Alden",
    "lng": -93.57342930330499,
    "lat": 43.669512681679244
  },
  {
    "name": "Alden",
    "lng": -93.38344003521502,
    "lat": 42.510521719890285
  },
  {
    "name": "Alden",
    "lng": -78.49460246593709,
    "lat": 42.89888105960528
  },
  {
    "name": "Alder Creek Village",
    "lng": -123.92721607689217,
    "lat": 46.18348852714435
  },
  {
    "name": "Alderson",
    "lng": -95.68938433658373,
    "lat": 34.900607587938545
  },
  {
    "name": "Alderson",
    "lng": -80.64363574358526,
    "lat": 37.72767932681319
  },
  {
    "name": "Aldie",
    "lng": -77.6453882641519,
    "lat": 38.97665202530218
  },
  {
    "name": "Aldrich",
    "lng": -94.93950743431637,
    "lat": 46.3746902667986
  },
  {
    "name": "Aldrich",
    "lng": -93.55140761620473,
    "lat": 37.549140416213234
  },
  {
    "name": "Aledo",
    "lng": -90.74591334650516,
    "lat": 41.198433675946006
  },
  {
    "name": "Aleknagik",
    "lng": -158.6921110237776,
    "lat": 59.29548185800791
  },
  {
    "name": "Alex",
    "lng": -97.77634717947656,
    "lat": 34.92274354999874
  },
  {
    "name": "Alexander",
    "lng": -103.6413185726216,
    "lat": 47.83897451462823
  },
  {
    "name": "Alexander",
    "lng": -99.55299182252661,
    "lat": 38.46929481413093
  },
  {
    "name": "Alexander",
    "lng": -92.45122175460664,
    "lat": 34.6185357183006
  },
  {
    "name": "Alexander",
    "lng": -78.25911164703402,
    "lat": 42.90201992209835
  },
  {
    "name": "Alexander",
    "lng": -67.47424628811565,
    "lat": 45.08645127028766
  },
  {
    "name": "Alexander City",
    "lng": -85.9389974001716,
    "lat": 32.930180228220514
  },
  {
    "name": "Alexander City",
    "lng": -85.93428227019399,
    "lat": 32.8615652301012
  },
  {
    "name": "Alexander City",
    "lng": -85.8820709418738,
    "lat": 32.88312199074469
  },
  {
    "name": "Alexandria",
    "lng": -97.78068505580369,
    "lat": 43.654914508850204
  },
  {
    "name": "Alexandria",
    "lng": -97.3878372850939,
    "lat": 40.24754764651978
  },
  {
    "name": "Alexandria",
    "lng": -95.42252723875048,
    "lat": 45.86478616971294
  },
  {
    "name": "Alexandria",
    "lng": -95.37339078141596,
    "lat": 45.882302839006094
  },
  {
    "name": "Alexandria",
    "lng": -92.50221406780321,
    "lat": 31.308763311089034
  },
  {
    "name": "Alexandria",
    "lng": -92.47019344318365,
    "lat": 31.292300357636453
  },
  {
    "name": "Alexandria",
    "lng": -91.45950541099486,
    "lat": 40.35974279291141
  },
  {
    "name": "Alexandria",
    "lng": -86.03414807135547,
    "lat": 36.07596847665667
  },
  {
    "name": "Alexandria",
    "lng": -85.68321340724462,
    "lat": 40.27632709393256
  },
  {
    "name": "Alexandria",
    "lng": -85.67581211472144,
    "lat": 40.258078419319624
  },
  {
    "name": "Alexandria",
    "lng": -84.38580333257221,
    "lat": 38.96221017940875
  },
  {
    "name": "Alexandria",
    "lng": -82.61271577133007,
    "lat": 40.09017146109476
  },
  {
    "name": "Alexandria",
    "lng": -77.08620313451979,
    "lat": 38.818474215451786
  },
  {
    "name": "Alexandria Bay",
    "lng": -75.91607337962532,
    "lat": 44.339935747432904
  },
  {
    "name": "Alexis",
    "lng": -90.55507678567211,
    "lat": 41.06318957888681
  },
  {
    "name": "Alford",
    "lng": -73.42589072248018,
    "lat": 42.24862992573206
  },
  {
    "name": "Alfordsville",
    "lng": -86.94822343578211,
    "lat": 38.5605083813412
  },
  {
    "name": "Alfred",
    "lng": -77.7896439516093,
    "lat": 42.25433669980141
  },
  {
    "name": "Alfred",
    "lng": -70.73076350646637,
    "lat": 43.48578921712846
  },
  {
    "name": "Alger",
    "lng": -83.84402928992459,
    "lat": 40.70943787380099
  },
  {
    "name": "Algoma",
    "lng": -89.03356253316811,
    "lat": 34.17884790924177
  },
  {
    "name": "Algoma",
    "lng": -87.44508009206203,
    "lat": 44.605530559720385
  },
  {
    "name": "Algona",
    "lng": -122.25032162821459,
    "lat": 47.281869775254364
  },
  {
    "name": "Algona",
    "lng": -94.27185911054202,
    "lat": 43.0785001417984
  },
  {
    "name": "Algona",
    "lng": -94.24826345574884,
    "lat": 43.08239370322282
  },
  {
    "name": "Algona",
    "lng": -94.22668318154489,
    "lat": 43.07393846807086
  },
  {
    "name": "Algonac",
    "lng": -82.53451708189421,
    "lat": 42.62188417021149
  },
  {
    "name": "Algonquin",
    "lng": -88.31587804275159,
    "lat": 42.16285803482748
  },
  {
    "name": "Algood",
    "lng": -85.446926966794,
    "lat": 36.20030663017456
  },
  {
    "name": "Alhambra",
    "lng": -118.135465853998,
    "lat": 34.08397836550309
  },
  {
    "name": "Alhambra",
    "lng": -89.7361182451358,
    "lat": 38.88785190467293
  },
  {
    "name": "Alice",
    "lng": -97.55601246857866,
    "lat": 46.75999349604249
  },
  {
    "name": "Aliceville",
    "lng": -88.1591995945059,
    "lat": 33.12319888685888
  },
  {
    "name": "Alicia",
    "lng": -91.08345992432855,
    "lat": 35.89291092234834
  },
  {
    "name": "Aline",
    "lng": -98.44864050122398,
    "lat": 36.50969086796323
  },
  {
    "name": "Aliso Viejo",
    "lng": -117.7288769139523,
    "lat": 33.579223652826784
  },
  {
    "name": "Allagash",
    "lng": -69.09018710002582,
    "lat": 47.09373171595891
  },
  {
    "name": "Allakaket",
    "lng": -152.64558708220255,
    "lat": 66.56482741323698
  },
  {
    "name": "Allardt",
    "lng": -84.8811118107024,
    "lat": 36.38066593109039
  },
  {
    "name": "Allegan",
    "lng": -85.84610575790816,
    "lat": 42.53005511604476
  },
  {
    "name": "Allegany",
    "lng": -78.4927159793228,
    "lat": 42.09171596398474
  },
  {
    "name": "Alleman",
    "lng": -93.60919364666536,
    "lat": 41.81488145935079
  },
  {
    "name": "Allen",
    "lng": -96.84320866196106,
    "lat": 42.41443364068725
  },
  {
    "name": "Allen",
    "lng": -96.41222607964717,
    "lat": 34.877523565962626
  },
  {
    "name": "Allen",
    "lng": -96.1695591280958,
    "lat": 38.655075040060794
  },
  {
    "name": "Allen",
    "lng": -84.76816583454081,
    "lat": 41.95819286735326
  },
  {
    "name": "Allen Park",
    "lng": -83.21063493266807,
    "lat": 42.2596697710294
  },
  {
    "name": "Allendale",
    "lng": -94.28867784058215,
    "lat": 40.48534047258302
  },
  {
    "name": "Allendale",
    "lng": -87.71034558980415,
    "lat": 38.52764146660199
  },
  {
    "name": "Allendale",
    "lng": -81.3091822126426,
    "lat": 33.00804690789845
  },
  {
    "name": "Allenhurst",
    "lng": -81.60782818960176,
    "lat": 31.783903266584822
  },
  {
    "name": "Allenstown",
    "lng": -71.38221184302819,
    "lat": 43.13775623671365
  },
  {
    "name": "Allensville",
    "lng": -87.06813611230173,
    "lat": 36.7174836659908
  },
  {
    "name": "Allentown",
    "lng": -75.47550133017643,
    "lat": 40.59624927766285
  },
  {
    "name": "Allenville",
    "lng": -89.75545282293275,
    "lat": 37.22180279967842
  },
  {
    "name": "Allenville",
    "lng": -88.5383561088045,
    "lat": 39.5585473028137
  },
  {
    "name": "Allerton",
    "lng": -93.3691861587898,
    "lat": 40.70764746430304
  },
  {
    "name": "Allerton",
    "lng": -87.93526268598686,
    "lat": 39.916155310011355
  },
  {
    "name": "Alliance",
    "lng": -102.90363388240073,
    "lat": 42.13008770335632
  },
  {
    "name": "Alliance",
    "lng": -102.87572708600874,
    "lat": 42.10021028056199
  },
  {
    "name": "Alliance",
    "lng": -102.87076532851796,
    "lat": 42.11725417912238
  },
  {
    "name": "Alliance",
    "lng": -81.11891662432184,
    "lat": 40.910619622995426
  },
  {
    "name": "Alliance",
    "lng": -76.80836656192713,
    "lat": 35.14333808391784
  },
  {
    "name": "Alligator",
    "lng": -90.72057511004701,
    "lat": 34.08992709332293
  },
  {
    "name": "Allison",
    "lng": -92.79562185005413,
    "lat": 42.75199972684441
  },
  {
    "name": "Allouez",
    "lng": -88.02617650481739,
    "lat": 44.47206988054054
  },
  {
    "name": "Allport",
    "lng": -91.78387914983112,
    "lat": 34.539628818791684
  },
  {
    "name": "Allport",
    "lng": -91.77868370743482,
    "lat": 34.543944499085235
  },
  {
    "name": "Alma",
    "lng": -106.06559153044178,
    "lat": 39.28525556806352
  },
  {
    "name": "Alma",
    "lng": -99.36352828996398,
    "lat": 40.101359209494795
  },
  {
    "name": "Alma",
    "lng": -96.2884949603354,
    "lat": 39.01507514968436
  },
  {
    "name": "Alma",
    "lng": -96.27724223295232,
    "lat": 39.018444895111514
  },
  {
    "name": "Alma",
    "lng": -94.24511678749582,
    "lat": 35.50462531747308
  },
  {
    "name": "Alma",
    "lng": -94.22596146699965,
    "lat": 35.51110978948754
  },
  {
    "name": "Alma",
    "lng": -94.21602125276014,
    "lat": 35.49167553158558
  },
  {
    "name": "Alma",
    "lng": -94.21602125276007,
    "lat": 35.491675531585564
  },
  {
    "name": "Alma",
    "lng": -94.21185828920129,
    "lat": 35.49301936668445
  },
  {
    "name": "Alma",
    "lng": -94.18018664006925,
    "lat": 35.506006044597676
  },
  {
    "name": "Alma",
    "lng": -93.54780042608172,
    "lat": 39.09640946780796
  },
  {
    "name": "Alma",
    "lng": -91.92229168318826,
    "lat": 44.33669693538437
  },
  {
    "name": "Alma",
    "lng": -88.91193724175817,
    "lat": 38.72309875177977
  },
  {
    "name": "Alma",
    "lng": -84.65563822116782,
    "lat": 43.38001577321203
  },
  {
    "name": "Alma",
    "lng": -82.47449874382636,
    "lat": 31.543234564146235
  },
  {
    "name": "Alma Center",
    "lng": -90.91287702260988,
    "lat": 44.43691917644836
  },
  {
    "name": "Almena",
    "lng": -99.70970466739718,
    "lat": 39.891298509142686
  },
  {
    "name": "Almena",
    "lng": -92.03833005402939,
    "lat": 45.41496631881973
  },
  {
    "name": "Almira",
    "lng": -118.93714652102075,
    "lat": 47.710716306919444
  },
  {
    "name": "Almond",
    "lng": -89.42309962984092,
    "lat": 44.26728986693198
  },
  {
    "name": "Almond",
    "lng": -89.40871635050176,
    "lat": 44.26080802954927
  },
  {
    "name": "Almond",
    "lng": -89.40810016083806,
    "lat": 44.249594813483405
  },
  {
    "name": "Almond",
    "lng": -77.73861337699128,
    "lat": 42.31931582626994
  },
  {
    "name": "Almont",
    "lng": -101.50268070223795,
    "lat": 46.72835197965296
  },
  {
    "name": "Almont",
    "lng": -83.04267253437348,
    "lat": 42.920429114753155
  },
  {
    "name": "Almyra",
    "lng": -91.41084621195479,
    "lat": 34.40569158209247
  },
  {
    "name": "Alna",
    "lng": -69.63436442880094,
    "lat": 44.087792635697944
  },
  {
    "name": "Alorton",
    "lng": -90.11375751816657,
    "lat": 38.58491582831423
  },
  {
    "name": "Alpena",
    "lng": -98.36835255358528,
    "lat": 44.18295157855873
  },
  {
    "name": "Alpena",
    "lng": -93.30377253793633,
    "lat": 36.289648784739605
  },
  {
    "name": "Alpena",
    "lng": -83.4379245505287,
    "lat": 45.074538771889316
  },
  {
    "name": "Alpha",
    "lng": -94.87167355498184,
    "lat": 43.64553722939147
  },
  {
    "name": "Alpha",
    "lng": -94.87126201212217,
    "lat": 43.63683069998471
  },
  {
    "name": "Alpha",
    "lng": -94.86860719449975,
    "lat": 43.646718592449744
  },
  {
    "name": "Alpha",
    "lng": -90.38043354283911,
    "lat": 41.19167663712811
  },
  {
    "name": "Alpha",
    "lng": -88.3775693232776,
    "lat": 46.04394803931219
  },
  {
    "name": "Alpha Base Camp",
    "lng": -81.11384059561729,
    "lat": 37.91484371347698
  },
  {
    "name": "Alpharetta",
    "lng": -84.34051150252516,
    "lat": 34.088604451172266
  },
  {
    "name": "Alpharetta",
    "lng": -84.28287693393045,
    "lat": 34.11175861887602
  },
  {
    "name": "Alpharetta",
    "lng": -84.27372909086347,
    "lat": 34.069937270155265
  },
  {
    "name": "Alpine",
    "lng": -111.77244890586785,
    "lat": 40.462943966597216
  },
  {
    "name": "Alpine",
    "lng": -111.02393791364275,
    "lat": 43.171847224604925
  },
  {
    "name": "Alpine",
    "lng": -111.01852206711943,
    "lat": 43.160817815133974
  },
  {
    "name": "Alsen",
    "lng": -98.70482135507852,
    "lat": 48.63029518032961
  },
  {
    "name": "Alsey",
    "lng": -90.43278073227764,
    "lat": 39.55926597202732
  },
  {
    "name": "Alsip",
    "lng": -87.7653299594154,
    "lat": 41.665123253891934
  },
  {
    "name": "Alsip",
    "lng": -87.73579509026631,
    "lat": 41.67021741262001
  },
  {
    "name": "Alston",
    "lng": -82.47892492894327,
    "lat": 32.08097040251662
  },
  {
    "name": "Alston Park",
    "lng": -122.33893918446454,
    "lat": 38.324284901912044
  },
  {
    "name": "Alta",
    "lng": -111.62263958187981,
    "lat": 40.58181377316925
  },
  {
    "name": "Alta",
    "lng": -95.30445233558973,
    "lat": 42.67172235894131
  },
  {
    "name": "Alta Sierra",
    "lng": -121.05235412897001,
    "lat": 39.12369532876574
  },
  {
    "name": "Alta Vista",
    "lng": -96.48894816530802,
    "lat": 38.86307335494294
  },
  {
    "name": "Alta Vista",
    "lng": -92.41695904722017,
    "lat": 43.19669183157704
  },
  {
    "name": "Altamont",
    "lng": -110.28804691076098,
    "lat": 40.3589778469423
  },
  {
    "name": "Altamont",
    "lng": -96.69015698032933,
    "lat": 44.84078188219404
  },
  {
    "name": "Altamont",
    "lng": -95.29562735991276,
    "lat": 37.19020718818015
  },
  {
    "name": "Altamont",
    "lng": -95.29219101709323,
    "lat": 37.163462541746256
  },
  {
    "name": "Altamont",
    "lng": -95.29216852998526,
    "lat": 37.150051344297275
  },
  {
    "name": "Altamont",
    "lng": -95.2865584089543,
    "lat": 37.18430954654212
  },
  {
    "name": "Altamont",
    "lng": -95.28654229123339,
    "lat": 37.18741292926981
  },
  {
    "name": "Altamont",
    "lng": -95.28641888396902,
    "lat": 37.15564103692346
  },
  {
    "name": "Altamont",
    "lng": -95.2854674476676,
    "lat": 37.185921165647464
  },
  {
    "name": "Altamont",
    "lng": -95.28176979519748,
    "lat": 37.174930819049045
  },
  {
    "name": "Altamont",
    "lng": -94.08678317185597,
    "lat": 39.88837572868547
  },
  {
    "name": "Altamont",
    "lng": -88.74751893893229,
    "lat": 39.05705068482656
  },
  {
    "name": "Altamont",
    "lng": -85.75739867705344,
    "lat": 35.469332455579234
  },
  {
    "name": "Altamont",
    "lng": -85.74365347466686,
    "lat": 35.43264504183082
  },
  {
    "name": "Altamont",
    "lng": -74.03401919643441,
    "lat": 42.70502398299649
  },
  {
    "name": "Altamont",
    "lng": -74.0222781719596,
    "lat": 42.704123545310644
  },
  {
    "name": "Altavista",
    "lng": -79.28550600541786,
    "lat": 37.12339134307229
  },
  {
    "name": "Altenburg",
    "lng": -89.58470097694823,
    "lat": 37.63042330099622
  },
  {
    "name": "Altheimer",
    "lng": -91.84805133887153,
    "lat": 34.32421475323572
  },
  {
    "name": "Alto",
    "lng": -83.57268154414635,
    "lat": 34.46489671037336
  },
  {
    "name": "Alto Pass",
    "lng": -89.31914161331534,
    "lat": 37.573680942939795
  },
  {
    "name": "Alton",
    "lng": -112.50619221220161,
    "lat": 37.430286619105
  },
  {
    "name": "Alton",
    "lng": -98.94843980298693,
    "lat": 39.46761430257136
  },
  {
    "name": "Alton",
    "lng": -96.00958254137004,
    "lat": 42.98744003921532
  },
  {
    "name": "Alton",
    "lng": -96.00105063036831,
    "lat": 42.999939594192135
  },
  {
    "name": "Alton",
    "lng": -91.3996760917931,
    "lat": 36.693720716026576
  },
  {
    "name": "Alton",
    "lng": -86.41946143843698,
    "lat": 38.12116336156127
  },
  {
    "name": "Alton",
    "lng": -71.24592595029267,
    "lat": 43.4905661343284
  },
  {
    "name": "Alton",
    "lng": -68.77166859363373,
    "lat": 45.04130858865089
  },
  {
    "name": "Altona",
    "lng": -90.1648192315527,
    "lat": 41.11505224149499
  },
  {
    "name": "Altona",
    "lng": -85.15315392285035,
    "lat": 41.35182277100674
  },
  {
    "name": "Altoona",
    "lng": -95.66148545131718,
    "lat": 37.52494179614408
  },
  {
    "name": "Altoona",
    "lng": -93.47825281607517,
    "lat": 41.648366652088
  },
  {
    "name": "Altoona",
    "lng": -91.43848639204396,
    "lat": 44.80278037922227
  },
  {
    "name": "Altoona",
    "lng": -86.31363353627631,
    "lat": 34.03305798432577
  },
  {
    "name": "Altoona",
    "lng": -78.40067140374707,
    "lat": 40.508253316669155
  },
  {
    "name": "Altura",
    "lng": -91.94358160794346,
    "lat": 44.0642091769121
  },
  {
    "name": "Alturas",
    "lng": -120.54847205118183,
    "lat": 41.48992289805739
  },
  {
    "name": "Altus",
    "lng": -99.306020035928,
    "lat": 34.656513385516355
  },
  {
    "name": "Altus",
    "lng": -93.76438446763667,
    "lat": 35.44676003808441
  },
  {
    "name": "Alva",
    "lng": -98.68787799546382,
    "lat": 36.80114653128513
  },
  {
    "name": "Alva",
    "lng": -98.664872670087,
    "lat": 36.78956638478237
  },
  {
    "name": "Alva",
    "lng": -98.65968168893463,
    "lat": 36.808861677958475
  },
  {
    "name": "Alva",
    "lng": -81.62649946018401,
    "lat": 26.71883616060223
  },
  {
    "name": "Alvan",
    "lng": -87.60690303928514,
    "lat": 40.30702892042655
  },
  {
    "name": "Alvarado",
    "lng": -96.99834870101074,
    "lat": 48.192913547175095
  },
  {
    "name": "Alvin",
    "lng": -95.5187384452786,
    "lat": 29.340834493093627
  },
  {
    "name": "Alvin",
    "lng": -95.29018541161251,
    "lat": 29.383873305855644
  },
  {
    "name": "Alvo",
    "lng": -96.38652318575747,
    "lat": 40.872265352467835
  },
  {
    "name": "Alvord",
    "lng": -96.30366464136425,
    "lat": 43.34176952606661
  },
  {
    "name": "Amador City",
    "lng": -120.82365111380177,
    "lat": 38.41891269544418
  },
  {
    "name": "Amagon",
    "lng": -91.10997376954046,
    "lat": 35.56295488477662
  },
  {
    "name": "Amalga",
    "lng": -111.89745838559638,
    "lat": 41.856720903197804
  },
  {
    "name": "Amanda",
    "lng": -82.74171705760739,
    "lat": 39.65010933514675
  },
  {
    "name": "Amazonia",
    "lng": -94.8926564858428,
    "lat": 39.88899373376154
  },
  {
    "name": "Amber",
    "lng": -97.88200738780965,
    "lat": 35.16019903658373
  },
  {
    "name": "Amberley Village",
    "lng": -84.42823791124592,
    "lat": 39.203432363704714
  },
  {
    "name": "Ambia",
    "lng": -87.51622958307156,
    "lat": 40.48934118586773
  },
  {
    "name": "Ambler",
    "lng": -157.8723351497855,
    "lat": 67.08770187120507
  },
  {
    "name": "Amboy",
    "lng": -94.15809893407852,
    "lat": 43.8879557760097
  },
  {
    "name": "Amboy",
    "lng": -89.367677169472,
    "lat": 41.72896051518161
  },
  {
    "name": "Amboy",
    "lng": -85.92748416028917,
    "lat": 40.60185861073165
  },
  {
    "name": "Ambrose",
    "lng": -103.48099708637542,
    "lat": 48.95598500293894
  },
  {
    "name": "Ambrose",
    "lng": -83.0137327974955,
    "lat": 31.59443958407585
  },
  {
    "name": "Amelia",
    "lng": -84.2184664226003,
    "lat": 39.02276332592698
  },
  {
    "name": "Amenia",
    "lng": -97.2241829547279,
    "lat": 47.00681812777485
  },
  {
    "name": "American Canyon",
    "lng": -122.2583646595161,
    "lat": 38.17971019107952
  },
  {
    "name": "American Canyon",
    "lng": -122.22704239619463,
    "lat": 38.22382430494433
  },
  {
    "name": "American Falls",
    "lng": -112.85420928891325,
    "lat": 42.78284794187314
  },
  {
    "name": "American Fork",
    "lng": -111.79503759308254,
    "lat": 40.378935725219016
  },
  {
    "name": "Americus",
    "lng": -96.26140749831941,
    "lat": 38.50661648396562
  },
  {
    "name": "Americus",
    "lng": -84.24212325167197,
    "lat": 32.097497016255986
  },
  {
    "name": "Americus",
    "lng": -84.22471255883315,
    "lat": 32.07364887567227
  },
  {
    "name": "Amery",
    "lng": -92.36340997020201,
    "lat": 45.304873268419584
  },
  {
    "name": "Amery",
    "lng": -92.34343430356967,
    "lat": 45.319422043831764
  },
  {
    "name": "Ames",
    "lng": -98.18670793895205,
    "lat": 36.247089105706124
  },
  {
    "name": "Ames",
    "lng": -93.62168567556165,
    "lat": 42.0256310589429
  },
  {
    "name": "Ames",
    "lng": -74.60168103045355,
    "lat": 42.83746836065378
  },
  {
    "name": "Amesville",
    "lng": -81.9548464519622,
    "lat": 39.401396135926674
  },
  {
    "name": "Amherst",
    "lng": -99.2695162277726,
    "lat": 40.83837412451689
  },
  {
    "name": "Amherst",
    "lng": -89.28325348206393,
    "lat": 44.44708027236406
  },
  {
    "name": "Amherst",
    "lng": -82.23034673541076,
    "lat": 41.402257660856
  },
  {
    "name": "Amherst",
    "lng": -79.05058136987424,
    "lat": 37.581640773067456
  },
  {
    "name": "Amherst",
    "lng": -72.5068650488486,
    "lat": 42.364507053225935
  },
  {
    "name": "Amherst",
    "lng": -71.60727585552891,
    "lat": 42.87116280119569
  },
  {
    "name": "Amherst",
    "lng": -68.40189448529055,
    "lat": 44.853303084790014
  },
  {
    "name": "Amherst Junction",
    "lng": -89.31684887529136,
    "lat": 44.46819466254465
  },
  {
    "name": "Amidon",
    "lng": -103.31977318689032,
    "lat": 46.4821903838978
  },
  {
    "name": "Amira",
    "lng": -95.7212646838797,
    "lat": 30.06361099056983
  },
  {
    "name": "Amite City",
    "lng": -90.51309905270622,
    "lat": 30.732346525468714
  },
  {
    "name": "Amity",
    "lng": -94.43542473108761,
    "lat": 39.86834773176495
  },
  {
    "name": "Amity",
    "lng": -93.46340460401369,
    "lat": 34.2661931502434
  },
  {
    "name": "Amity",
    "lng": -67.84542420681343,
    "lat": 45.91341122918055
  },
  {
    "name": "Amity Gardens",
    "lng": -75.73310616753143,
    "lat": 40.2698277301291
  },
  {
    "name": "Amityville",
    "lng": -73.41703976981262,
    "lat": 40.670777130084424
  },
  {
    "name": "Ammon",
    "lng": -111.95691406931198,
    "lat": 43.47448067069474
  },
  {
    "name": "Amo",
    "lng": -86.61256607111245,
    "lat": 39.688564037181976
  },
  {
    "name": "Amoret",
    "lng": -94.58738080991122,
    "lat": 38.25492822499816
  },
  {
    "name": "Amory",
    "lng": -88.4823031466623,
    "lat": 33.981302527875
  },
  {
    "name": "Amsterdam",
    "lng": -94.58904269554522,
    "lat": 38.34935182285708
  },
  {
    "name": "Amsterdam",
    "lng": -80.92166357640224,
    "lat": 40.47169720259697
  },
  {
    "name": "Anacoco",
    "lng": -93.34400894461042,
    "lat": 31.25446098885653
  },
  {
    "name": "Anacortes",
    "lng": -122.62701721999125,
    "lat": 48.48815781951797
  },
  {
    "name": "Anadarko",
    "lng": -98.24424556080263,
    "lat": 35.065010187935435
  },
  {
    "name": "Anaheim",
    "lng": -117.85731130848556,
    "lat": 33.838966972263094
  },
  {
    "name": "Anaktuvuk Pass",
    "lng": -151.71565746039306,
    "lat": 68.14989482510583
  },
  {
    "name": "Anamoose",
    "lng": -100.24212271314285,
    "lat": 47.88282651627409
  },
  {
    "name": "Anamosa",
    "lng": -91.27590113710971,
    "lat": 42.109150380996624
  },
  {
    "name": "Anamosa",
    "lng": -91.26497197201849,
    "lat": 42.11848148404698
  },
  {
    "name": "Anawalt",
    "lng": -81.44098663019444,
    "lat": 37.33670852108092
  },
  {
    "name": "Anchor",
    "lng": -88.53831760961518,
    "lat": 40.56825138752722
  },
  {
    "name": "Anchorage",
    "lng": -85.53645690431348,
    "lat": 38.26909602147065
  },
  {
    "name": "Andale",
    "lng": -97.62766132537641,
    "lat": 37.79154523924949
  },
  {
    "name": "Andalusia",
    "lng": -90.71645531497909,
    "lat": 41.4373428170037
  },
  {
    "name": "Andalusia",
    "lng": -86.4781617235214,
    "lat": 31.310066157586782
  },
  {
    "name": "Anderson",
    "lng": -149.15502388826272,
    "lat": 64.30770617646849
  },
  {
    "name": "Anderson",
    "lng": -122.2961332054076,
    "lat": 40.45077257971743
  },
  {
    "name": "Anderson",
    "lng": -94.44331994243937,
    "lat": 36.65223594760178
  },
  {
    "name": "Anderson",
    "lng": -87.2715936583332,
    "lat": 34.914754476507795
  },
  {
    "name": "Anderson",
    "lng": -85.68923279170032,
    "lat": 40.08938105615035
  },
  {
    "name": "Anderson",
    "lng": -82.66012632531,
    "lat": 34.56033154895013
  },
  {
    "name": "Anderson",
    "lng": -82.64737003466709,
    "lat": 34.521300503729464
  },
  {
    "name": "Andersonville",
    "lng": -84.14547538728793,
    "lat": 32.196539031304305
  },
  {
    "name": "Andover",
    "lng": -97.90371532235075,
    "lat": 45.410213853961665
  },
  {
    "name": "Andover",
    "lng": -97.13658174488654,
    "lat": 37.68911127005576
  },
  {
    "name": "Andover",
    "lng": -97.12328438673022,
    "lat": 37.6782626121112
  },
  {
    "name": "Andover",
    "lng": -97.12059890400533,
    "lat": 37.67982490808074
  },
  {
    "name": "Andover",
    "lng": -97.11952199074103,
    "lat": 37.66293308854197
  },
  {
    "name": "Andover",
    "lng": -97.11835675679389,
    "lat": 37.67608889357662
  },
  {
    "name": "Andover",
    "lng": -97.11476666192267,
    "lat": 37.72126034527967
  },
  {
    "name": "Andover",
    "lng": -97.11026070625107,
    "lat": 37.65949770821677
  },
  {
    "name": "Andover",
    "lng": -93.32645495155329,
    "lat": 45.257133085643076
  },
  {
    "name": "Andover",
    "lng": -90.29073474412226,
    "lat": 41.294897843567696
  },
  {
    "name": "Andover",
    "lng": -90.25273125436554,
    "lat": 41.98007209182391
  },
  {
    "name": "Andover",
    "lng": -80.56875880559575,
    "lat": 41.60667428166349
  },
  {
    "name": "Andover",
    "lng": -77.79546018751202,
    "lat": 42.15753990885787
  },
  {
    "name": "Andover",
    "lng": -72.37604938559181,
    "lat": 41.73313542207756
  },
  {
    "name": "Andover",
    "lng": -71.79315898393776,
    "lat": 43.44684814850751
  },
  {
    "name": "Andover",
    "lng": -70.7589541063909,
    "lat": 44.61941329395796
  },
  {
    "name": "Andrew",
    "lng": -90.59229996812054,
    "lat": 42.153245897598126
  },
  {
    "name": "Andrews",
    "lng": -85.60215811085209,
    "lat": 40.86024611198156
  },
  {
    "name": "Andrews",
    "lng": -83.8463997627334,
    "lat": 35.19882327218356
  },
  {
    "name": "Andrews",
    "lng": -83.8403442597177,
    "lat": 35.19306246166429
  },
  {
    "name": "Andrews",
    "lng": -83.8362862344738,
    "lat": 35.21151841770023
  },
  {
    "name": "Andrews",
    "lng": -83.82629145363885,
    "lat": 35.199486388541715
  },
  {
    "name": "Andrews",
    "lng": -83.82488341398611,
    "lat": 35.20897652859139
  },
  {
    "name": "Andrews",
    "lng": -83.80358383933527,
    "lat": 35.19541622700961
  },
  {
    "name": "Andrews",
    "lng": -83.80154827739909,
    "lat": 35.19735406469949
  },
  {
    "name": "Andrews",
    "lng": -83.79913173204109,
    "lat": 35.19709933994729
  },
  {
    "name": "Andrews",
    "lng": -83.79869802892188,
    "lat": 35.194828085348156
  },
  {
    "name": "Andrews",
    "lng": -79.56588179839915,
    "lat": 33.44948740583005
  },
  {
    "name": "Aneta",
    "lng": -97.98972112127353,
    "lat": 47.67954955748012
  },
  {
    "name": "Angel Fire",
    "lng": -105.27130861515585,
    "lat": 36.38237355118182
  },
  {
    "name": "Angelica",
    "lng": -78.02058407334347,
    "lat": 42.30548555280004
  },
  {
    "name": "Angels Camp",
    "lng": -120.55157188763526,
    "lat": 38.073154300462896
  },
  {
    "name": "Angie",
    "lng": -89.8177541379518,
    "lat": 30.963251948931802
  },
  {
    "name": "Angier",
    "lng": -78.76770210588592,
    "lat": 35.52179543778693
  },
  {
    "name": "Angier",
    "lng": -78.76515055731407,
    "lat": 35.53754784627454
  },
  {
    "name": "Angier",
    "lng": -78.7577142718506,
    "lat": 35.497396890438104
  },
  {
    "name": "Angier",
    "lng": -78.75089694334368,
    "lat": 35.540888629902426
  },
  {
    "name": "Angier",
    "lng": -78.74858869135782,
    "lat": 35.53369509459721
  },
  {
    "name": "Angier",
    "lng": -78.74619475320362,
    "lat": 35.52946079910215
  },
  {
    "name": "Angier",
    "lng": -78.74617427874465,
    "lat": 35.53670146499012
  },
  {
    "name": "Angier",
    "lng": -78.74352964777368,
    "lat": 35.5338195534303
  },
  {
    "name": "Angier",
    "lng": -78.73686006074487,
    "lat": 35.506463754873636
  },
  {
    "name": "Angier",
    "lng": -78.7204758319404,
    "lat": 35.51420843782429
  },
  {
    "name": "Angola",
    "lng": -84.99969137200385,
    "lat": 41.6442227465088
  },
  {
    "name": "Angola",
    "lng": -79.02948222780907,
    "lat": 42.637840038437034
  },
  {
    "name": "Angoon",
    "lng": -134.51677828075634,
    "lat": 57.454465419608375
  },
  {
    "name": "Anguilla",
    "lng": -90.82825897697933,
    "lat": 32.971869448760124
  },
  {
    "name": "Anita",
    "lng": -94.76597046020149,
    "lat": 41.443802967180076
  },
  {
    "name": "Aniwa",
    "lng": -89.20886546082866,
    "lat": 45.01146823266671
  },
  {
    "name": "Ankeny",
    "lng": -93.603378091884,
    "lat": 41.728905939899185
  },
  {
    "name": "Anmoore",
    "lng": -80.28623945982437,
    "lat": 39.26105726867559
  },
  {
    "name": "Ann Arbor",
    "lng": -83.79477943817533,
    "lat": 42.273896772837546
  },
  {
    "name": "Ann Arbor",
    "lng": -83.79265233703198,
    "lat": 42.27412685856021
  },
  {
    "name": "Ann Arbor",
    "lng": -83.78294184381993,
    "lat": 42.298691934874626
  },
  {
    "name": "Ann Arbor",
    "lng": -83.76721814407375,
    "lat": 42.30865014965903
  },
  {
    "name": "Ann Arbor",
    "lng": -83.74845086234312,
    "lat": 42.25620633779773
  },
  {
    "name": "Ann Arbor",
    "lng": -83.7308535031036,
    "lat": 42.27599108603078
  },
  {
    "name": "Ann Arbor",
    "lng": -83.71187694570158,
    "lat": 42.276777430288334
  },
  {
    "name": "Ann Arbor",
    "lng": -83.711141142583,
    "lat": 42.27634080582592
  },
  {
    "name": "Ann Arbor",
    "lng": -83.70965595367552,
    "lat": 42.27711054252143
  },
  {
    "name": "Ann Arbor",
    "lng": -83.70902980815322,
    "lat": 42.27664739475283
  },
  {
    "name": "Ann Arbor",
    "lng": -83.70884823354301,
    "lat": 42.27561747471597
  },
  {
    "name": "Ann Arbor",
    "lng": -83.70659846303677,
    "lat": 42.273914147249464
  },
  {
    "name": "Ann Lake Shortcut",
    "lng": -93.43263061304806,
    "lat": 45.83001866405649
  },
  {
    "name": "Ann Lake Shortcut",
    "lng": -93.4220936752671,
    "lat": 45.82900830414792
  },
  {
    "name": "Anna",
    "lng": -89.23894682930437,
    "lat": 37.46113364265576
  },
  {
    "name": "Anna",
    "lng": -84.17601493234427,
    "lat": 40.39645813452386
  },
  {
    "name": "Anna Maria island",
    "lng": -82.7346799488854,
    "lat": 27.529860864022627
  },
  {
    "name": "Annabella",
    "lng": -112.05856289706335,
    "lat": 38.70600643910459
  },
  {
    "name": "Annada",
    "lng": -90.82872328711841,
    "lat": 39.26230292695321
  },
  {
    "name": "Annandale",
    "lng": -94.12620529162312,
    "lat": 45.28193115940836
  },
  {
    "name": "Annandale",
    "lng": -94.1205192560481,
    "lat": 45.25966334055766
  },
  {
    "name": "Annapolis",
    "lng": -90.69802829235451,
    "lat": 37.36049970206006
  },
  {
    "name": "Annapolis",
    "lng": -76.5028620296005,
    "lat": 38.971401650651934
  },
  {
    "name": "Annawan",
    "lng": -89.8884876806536,
    "lat": 41.39585905755633
  },
  {
    "name": "Anniston",
    "lng": -89.32663883612261,
    "lat": 36.824340160094025
  },
  {
    "name": "Anniston",
    "lng": -85.84247124915306,
    "lat": 33.69537146069138
  },
  {
    "name": "Anniston",
    "lng": -85.81361930206455,
    "lat": 33.6712871968738
  },
  {
    "name": "Annville",
    "lng": -76.50767488918082,
    "lat": 40.332509067087805
  },
  {
    "name": "Anoka",
    "lng": -98.82852880620325,
    "lat": 42.946000235487304
  },
  {
    "name": "Anoka",
    "lng": -93.38933035631885,
    "lat": 45.20991010332964
  },
  {
    "name": "Anselmo",
    "lng": -99.86457684459289,
    "lat": 41.618581441361314
  },
  {
    "name": "Ansley",
    "lng": -99.38255409104639,
    "lat": 41.28793251798319
  },
  {
    "name": "Anson",
    "lng": -69.94867050972688,
    "lat": 44.831880396519004
  },
  {
    "name": "Ansonia",
    "lng": -84.63439866748996,
    "lat": 40.21412112731116
  },
  {
    "name": "Ansonia",
    "lng": -73.0689143306465,
    "lat": 41.34421981097021
  },
  {
    "name": "Ansonville",
    "lng": -80.10963777261001,
    "lat": 35.10417303120209
  },
  {
    "name": "Ansted",
    "lng": -81.1037239374336,
    "lat": 38.135697460407755
  },
  {
    "name": "Anthon",
    "lng": -95.8658596954173,
    "lat": 42.387793291683046
  },
  {
    "name": "Anthony",
    "lng": -98.07952767788505,
    "lat": 37.15883466678973
  },
  {
    "name": "Anthony",
    "lng": -98.0536750182414,
    "lat": 37.121432437851894
  },
  {
    "name": "Anthony",
    "lng": -98.0514061681776,
    "lat": 37.18103538031582
  },
  {
    "name": "Anthony",
    "lng": -98.04399242887561,
    "lat": 37.131299902018036
  },
  {
    "name": "Anthony",
    "lng": -98.03760269504987,
    "lat": 37.170472454105095
  },
  {
    "name": "Anthony",
    "lng": -98.03048649188081,
    "lat": 37.154985529773505
  },
  {
    "name": "Anthony",
    "lng": -97.99085043709081,
    "lat": 37.15039819094361
  },
  {
    "name": "Anthonyville",
    "lng": -90.34029616510124,
    "lat": 35.03983930959152
  },
  {
    "name": "Antigo",
    "lng": -89.15588301219053,
    "lat": 45.14318140202469
  },
  {
    "name": "Antigo",
    "lng": -89.14139292481862,
    "lat": 45.09486241057729
  },
  {
    "name": "Antimony",
    "lng": -111.98231890679415,
    "lat": 38.103040484064756
  },
  {
    "name": "Antioch",
    "lng": -121.79668628376312,
    "lat": 37.977550904575466
  },
  {
    "name": "Antioch",
    "lng": -88.07174209602016,
    "lat": 42.47436292623379
  },
  {
    "name": "Antioch",
    "lng": -81.06692063226662,
    "lat": 39.661052942016866
  },
  {
    "name": "Antler",
    "lng": -101.28296791365565,
    "lat": 48.971217271201276
  },
  {
    "name": "Antlers",
    "lng": -95.62158356387914,
    "lat": 34.23255894715856
  },
  {
    "name": "Antoine",
    "lng": -93.42257639019059,
    "lat": 34.03433075498525
  },
  {
    "name": "Antonito",
    "lng": -106.01031413272851,
    "lat": 37.075897794212686
  },
  {
    "name": "Antrim",
    "lng": -71.98305030495571,
    "lat": 43.053470577716205
  },
  {
    "name": "Antwerp",
    "lng": -84.73753388599262,
    "lat": 41.17955532130104
  },
  {
    "name": "Antwerp",
    "lng": -75.60765752292653,
    "lat": 44.19968746632148
  },
  {
    "name": "Anvik",
    "lng": -160.21422074762478,
    "lat": 62.65225787242746
  },
  {
    "name": "Apache",
    "lng": -98.35818284651748,
    "lat": 34.89411934820509
  },
  {
    "name": "Apalachicola",
    "lng": -84.99431076149725,
    "lat": 29.72383847716771
  },
  {
    "name": "Apex",
    "lng": -78.96759222855846,
    "lat": 35.675448321909826
  },
  {
    "name": "Apex",
    "lng": -78.94460256066375,
    "lat": 35.72867372205622
  },
  {
    "name": "Apex",
    "lng": -78.9435854957293,
    "lat": 35.736813245377945
  },
  {
    "name": "Apex",
    "lng": -78.93985132910467,
    "lat": 35.67156012759261
  },
  {
    "name": "Apex",
    "lng": -78.9383620799045,
    "lat": 35.67975358695055
  },
  {
    "name": "Apex",
    "lng": -78.93583718619375,
    "lat": 35.68037835876833
  },
  {
    "name": "Apex",
    "lng": -78.93553657021576,
    "lat": 35.67508080411442
  },
  {
    "name": "Apex",
    "lng": -78.93416408457881,
    "lat": 35.68145914084763
  },
  {
    "name": "Apex",
    "lng": -78.931581992526,
    "lat": 35.67199956565502
  },
  {
    "name": "Apex",
    "lng": -78.93136279021726,
    "lat": 35.767149843770035
  },
  {
    "name": "Apex",
    "lng": -78.9244330990832,
    "lat": 35.76269046636361
  },
  {
    "name": "Apex",
    "lng": -78.92220158646194,
    "lat": 35.74615202313486
  },
  {
    "name": "Apex",
    "lng": -78.9190305152951,
    "lat": 35.759327775743806
  },
  {
    "name": "Apex",
    "lng": -78.91728455472898,
    "lat": 35.692475055970114
  },
  {
    "name": "Apex",
    "lng": -78.91724095395267,
    "lat": 35.72544102119772
  },
  {
    "name": "Apex",
    "lng": -78.91007893971255,
    "lat": 35.7558177079775
  },
  {
    "name": "Apex",
    "lng": -78.90496709513181,
    "lat": 35.768047132527116
  },
  {
    "name": "Apex",
    "lng": -78.90490470331753,
    "lat": 35.77755268816311
  },
  {
    "name": "Apex",
    "lng": -78.89940351972466,
    "lat": 35.69809562262701
  },
  {
    "name": "Apex",
    "lng": -78.89676907527114,
    "lat": 35.71780294573427
  },
  {
    "name": "Apex",
    "lng": -78.89517474839495,
    "lat": 35.70229780280155
  },
  {
    "name": "Apex",
    "lng": -78.89507743210626,
    "lat": 35.70906279512967
  },
  {
    "name": "Apex",
    "lng": -78.89442565976894,
    "lat": 35.75402008180759
  },
  {
    "name": "Apex",
    "lng": -78.88968975944937,
    "lat": 35.719831773117164
  },
  {
    "name": "Apex",
    "lng": -78.88831701117356,
    "lat": 35.71698279828088
  },
  {
    "name": "Apex",
    "lng": -78.88627468616271,
    "lat": 35.76531542678114
  },
  {
    "name": "Apex",
    "lng": -78.88435144351202,
    "lat": 35.70627765381134
  },
  {
    "name": "Apex",
    "lng": -78.87191309181135,
    "lat": 35.72265501427727
  },
  {
    "name": "Apex",
    "lng": -78.85342300563912,
    "lat": 35.76434868166947
  },
  {
    "name": "Apex",
    "lng": -78.8450233950463,
    "lat": 35.75023257621346
  },
  {
    "name": "Apex",
    "lng": -78.81267061894621,
    "lat": 35.6880061437975
  },
  {
    "name": "Apex",
    "lng": -78.81172851644054,
    "lat": 35.715027715962755
  },
  {
    "name": "Apex",
    "lng": -78.80626631956949,
    "lat": 35.69871366189646
  },
  {
    "name": "Aplington",
    "lng": -92.88331449998877,
    "lat": 42.58189417835652
  },
  {
    "name": "Appalachia",
    "lng": -82.7881728496497,
    "lat": 36.90974973803622
  },
  {
    "name": "Apple Creek",
    "lng": -81.83349012369419,
    "lat": 40.74886321090799
  },
  {
    "name": "Apple River",
    "lng": -90.0937825506296,
    "lat": 42.50236250826397
  },
  {
    "name": "Apple Valley",
    "lng": -117.21099356178887,
    "lat": 34.53165577287017
  },
  {
    "name": "Apple Valley",
    "lng": -113.10341900823417,
    "lat": 37.080996117758865
  },
  {
    "name": "Apple Valley",
    "lng": -93.20040374115781,
    "lat": 44.74563349636868
  },
  {
    "name": "Appleby",
    "lng": -94.60683705825825,
    "lat": 31.717459219538153
  },
  {
    "name": "Applegate",
    "lng": -82.6370430755064,
    "lat": 43.35536276014311
  },
  {
    "name": "Appleton",
    "lng": -96.02315868841474,
    "lat": 45.1996936136123
  },
  {
    "name": "Appleton",
    "lng": -88.43808958200388,
    "lat": 44.3350322243473
  },
  {
    "name": "Appleton",
    "lng": -88.40400443686534,
    "lat": 44.20906253916047
  },
  {
    "name": "Appleton",
    "lng": -88.38868770877343,
    "lat": 44.277595749272244
  },
  {
    "name": "Appleton",
    "lng": -88.37393314123668,
    "lat": 44.22267882982181
  },
  {
    "name": "Appleton",
    "lng": -88.36952409553447,
    "lat": 44.223464696359684
  },
  {
    "name": "Appleton",
    "lng": -69.26529482442865,
    "lat": 44.30453297823232
  },
  {
    "name": "Appleton City",
    "lng": -94.03100736578912,
    "lat": 38.19056063987639
  },
  {
    "name": "Appomattox",
    "lng": -78.82665901085785,
    "lat": 37.35891559938172
  },
  {
    "name": "Aquilla",
    "lng": -81.17316013781965,
    "lat": 41.5465158899434
  },
  {
    "name": "Arab",
    "lng": -86.50338185941821,
    "lat": 34.28880933629638
  },
  {
    "name": "Arab",
    "lng": -86.49951306637413,
    "lat": 34.33095734303068
  },
  {
    "name": "Arab",
    "lng": -86.46127253077607,
    "lat": 34.31059820681028
  },
  {
    "name": "Arabi",
    "lng": -83.73021812771408,
    "lat": 31.82810429862562
  },
  {
    "name": "Aragon",
    "lng": -85.06708176424551,
    "lat": 34.04645867745433
  },
  {
    "name": "Aragon",
    "lng": -85.06553160486207,
    "lat": 34.04425615820265
  },
  {
    "name": "Aragon",
    "lng": -85.05645382465723,
    "lat": 34.04530975563011
  },
  {
    "name": "Aragon",
    "lng": -85.04781008278601,
    "lat": 34.055765611503595
  },
  {
    "name": "Aransas Pass",
    "lng": -97.11418095406228,
    "lat": 27.887744402718038
  },
  {
    "name": "Arapaho",
    "lng": -98.95935459443861,
    "lat": 35.57786366614428
  },
  {
    "name": "Arapahoe",
    "lng": -99.8980146983194,
    "lat": 40.30482788308189
  },
  {
    "name": "Arapahoe",
    "lng": -76.82596108670414,
    "lat": 35.02147456901393
  },
  {
    "name": "Arbela",
    "lng": -92.01560848000236,
    "lat": 40.4631498711354
  },
  {
    "name": "Arbor Grande",
    "lng": -82.42270598755728,
    "lat": 27.47520638953851
  },
  {
    "name": "Arbyrd",
    "lng": -90.24941002685787,
    "lat": 36.044794375651165
  },
  {
    "name": "Arbyrd",
    "lng": -90.23963339435082,
    "lat": 36.05371165222869
  },
  {
    "name": "Arcade",
    "lng": -83.53986808973384,
    "lat": 34.069339957626795
  },
  {
    "name": "Arcade",
    "lng": -78.43276324784642,
    "lat": 42.53172273666132
  },
  {
    "name": "Arcadia",
    "lng": -118.03722581548021,
    "lat": 34.13406104702606
  },
  {
    "name": "Arcadia",
    "lng": -99.12606219720547,
    "lat": 41.424823412959825
  },
  {
    "name": "Arcadia",
    "lng": -97.32550852086051,
    "lat": 35.665487724570916
  },
  {
    "name": "Arcadia",
    "lng": -95.0432173716894,
    "lat": 42.08619812252352
  },
  {
    "name": "Arcadia",
    "lng": -94.62400178766705,
    "lat": 37.641171985162515
  },
  {
    "name": "Arcadia",
    "lng": -92.9218235984815,
    "lat": 32.55057805199957
  },
  {
    "name": "Arcadia",
    "lng": -91.49482691900711,
    "lat": 44.24938665505029
  },
  {
    "name": "Arcadia",
    "lng": -90.6292322941027,
    "lat": 37.586402878867666
  },
  {
    "name": "Arcadia",
    "lng": -86.02105495229738,
    "lat": 40.17413115919605
  },
  {
    "name": "Arcadia",
    "lng": -83.51423478025988,
    "lat": 41.10818345465101
  },
  {
    "name": "Arcadia",
    "lng": -81.8585365428247,
    "lat": 27.221344028145314
  },
  {
    "name": "Arcadia Lakes",
    "lng": -80.96163942076376,
    "lat": 34.05344779213451
  },
  {
    "name": "Arcadia Lakes",
    "lng": -80.9578939064412,
    "lat": 34.061706950121405
  },
  {
    "name": "Arcanum",
    "lng": -84.5543464362705,
    "lat": 39.9922471147272
  },
  {
    "name": "Arcata",
    "lng": -124.08171176042548,
    "lat": 40.86789531795282
  },
  {
    "name": "Arcata",
    "lng": -123.99670440643114,
    "lat": 40.78790610794615
  },
  {
    "name": "Archbold",
    "lng": -84.30346053102001,
    "lat": 41.51608566344818
  },
  {
    "name": "Archdale",
    "lng": -79.96241303928771,
    "lat": 35.904797805197695
  },
  {
    "name": "Archdale",
    "lng": -79.95431571513588,
    "lat": 35.92222189572583
  },
  {
    "name": "Archdale",
    "lng": -79.9540914121016,
    "lat": 35.9202022982431
  },
  {
    "name": "Archdale",
    "lng": -79.9335403190571,
    "lat": 35.930066639866226
  },
  {
    "name": "Archdale",
    "lng": -79.93019034339315,
    "lat": 35.86131320841552
  },
  {
    "name": "Archdale",
    "lng": -79.92527308071777,
    "lat": 35.92264581396074
  },
  {
    "name": "Archdale",
    "lng": -79.91636969448162,
    "lat": 35.89752887725053
  },
  {
    "name": "Archer",
    "lng": -95.7442479246374,
    "lat": 43.11505318035599
  },
  {
    "name": "Archer",
    "lng": -82.51862329119976,
    "lat": 29.54006985124283
  },
  {
    "name": "Archie",
    "lng": -94.35014610343035,
    "lat": 38.482646376827795
  },
  {
    "name": "Arco",
    "lng": -113.30165495899999,
    "lat": 43.631575972651156
  },
  {
    "name": "Arco",
    "lng": -96.18257546090885,
    "lat": 44.38375712625218
  },
  {
    "name": "Arcola",
    "lng": -93.87587031617961,
    "lat": 37.54931705026875
  },
  {
    "name": "Arcola",
    "lng": -90.88000180256807,
    "lat": 33.270540462362376
  },
  {
    "name": "Arcola",
    "lng": -88.30136654449548,
    "lat": 39.683266114200556
  },
  {
    "name": "Arden Hills",
    "lng": -93.16707964734844,
    "lat": 45.072219226598264
  },
  {
    "name": "Ardmore",
    "lng": -97.18907457802507,
    "lat": 34.249055519740956
  },
  {
    "name": "Ardmore",
    "lng": -97.115033159739,
    "lat": 34.19922315037724
  },
  {
    "name": "Ardmore",
    "lng": -97.09957036344139,
    "lat": 34.21270482230469
  },
  {
    "name": "Ardmore",
    "lng": -97.05667487353625,
    "lat": 34.206275299970564
  },
  {
    "name": "Ardmore",
    "lng": -86.85577007995715,
    "lat": 35.00320436414826
  },
  {
    "name": "Ardmore",
    "lng": -86.83735425719864,
    "lat": 34.98471815158377
  },
  {
    "name": "Ardoch",
    "lng": -97.34075381575305,
    "lat": 48.20548242693364
  },
  {
    "name": "Ardsley",
    "lng": -73.83946938854137,
    "lat": 41.0135410080224
  },
  {
    "name": "Aredale",
    "lng": -93.00549727794301,
    "lat": 42.833197847014944
  },
  {
    "name": "Arena",
    "lng": -89.90819076042385,
    "lat": 43.16364159882775
  },
  {
    "name": "Arenzville",
    "lng": -90.37054998671452,
    "lat": 39.8804624487741
  },
  {
    "name": "Argenta",
    "lng": -88.81973503378744,
    "lat": 39.98550409610838
  },
  {
    "name": "Argo",
    "lng": -86.53821929284753,
    "lat": 33.68252847962678
  },
  {
    "name": "Argo",
    "lng": -86.52763617189783,
    "lat": 33.734323648695
  },
  {
    "name": "Argo",
    "lng": -86.52760657942719,
    "lat": 33.73435057252883
  },
  {
    "name": "Argo",
    "lng": -86.51653816919233,
    "lat": 33.69218074009514
  },
  {
    "name": "Argo",
    "lng": -86.51466107498283,
    "lat": 33.64206897761903
  },
  {
    "name": "Argo",
    "lng": -86.50742242689863,
    "lat": 33.69199321284757
  },
  {
    "name": "Argo",
    "lng": -86.50726820731491,
    "lat": 33.69244378596213
  },
  {
    "name": "Argo",
    "lng": -86.50600411591373,
    "lat": 33.73467726058781
  },
  {
    "name": "Argo",
    "lng": -86.50594887797904,
    "lat": 33.73462766641515
  },
  {
    "name": "Argo",
    "lng": -86.49977247074268,
    "lat": 33.74081574517701
  },
  {
    "name": "Argonia",
    "lng": -97.76318249674071,
    "lat": 37.26782215609063
  },
  {
    "name": "Argos",
    "lng": -86.25201216799464,
    "lat": 41.238806787539524
  },
  {
    "name": "Argusville",
    "lng": -96.94316750624301,
    "lat": 47.050061139884335
  },
  {
    "name": "Argyle",
    "lng": -96.8162816996238,
    "lat": 48.33651999531481
  },
  {
    "name": "Argyle",
    "lng": -92.02553345278936,
    "lat": 38.29538640108871
  },
  {
    "name": "Argyle",
    "lng": -89.86607521308389,
    "lat": 42.701367430476644
  },
  {
    "name": "Argyle",
    "lng": -82.64863622689657,
    "lat": 31.07426361077198
  },
  {
    "name": "Argyle",
    "lng": -73.49080854139666,
    "lat": 43.236616481987895
  },
  {
    "name": "Arimo",
    "lng": -112.17264811364888,
    "lat": 42.55987366202743
  },
  {
    "name": "Arion",
    "lng": -95.46228784199393,
    "lat": 41.947799242000805
  },
  {
    "name": "Arispe",
    "lng": -94.22082515178266,
    "lat": 40.94851841168864
  },
  {
    "name": "Ariton",
    "lng": -85.71290467890381,
    "lat": 31.5977785339923
  },
  {
    "name": "Arizona",
    "lng": -111.66663184362704,
    "lat": 34.33604552887783
  },
  {
    "name": "Arkadelphia",
    "lng": -93.07256291885615,
    "lat": 34.1254051518597
  },
  {
    "name": "Arkadelphia",
    "lng": -93.04584747846845,
    "lat": 34.12217356481638
  },
  {
    "name": "Arkansas",
    "lng": -92.43717916298036,
    "lat": 34.917401639846084
  },
  {
    "name": "Arkansas City",
    "lng": -97.03901419804423,
    "lat": 37.070787012612456
  },
  {
    "name": "Arkansas City",
    "lng": -97.0367763438895,
    "lat": 37.13602868669363
  },
  {
    "name": "Arkansas City",
    "lng": -97.03189662128389,
    "lat": 37.103081229495416
  },
  {
    "name": "Arkansas City",
    "lng": -97.0175195693289,
    "lat": 37.03930341304823
  },
  {
    "name": "Arkansas City",
    "lng": -97.01466884386107,
    "lat": 37.068730437047186
  },
  {
    "name": "Arkansas City",
    "lng": -97.00800294895505,
    "lat": 37.06842849144798
  },
  {
    "name": "Arkansas City",
    "lng": -97.00270750736405,
    "lat": 37.07649152930204
  },
  {
    "name": "Arkansas City",
    "lng": -91.2174719030105,
    "lat": 33.60472988164747
  },
  {
    "name": "Arkansas City",
    "lng": -91.20475947213912,
    "lat": 33.60892021540682
  },
  {
    "name": "Arkansas City",
    "lng": -91.2047594721391,
    "lat": 33.60892021540682
  },
  {
    "name": "Arkansas State Revenue Office",
    "lng": -94.20457964549274,
    "lat": 36.05234882357896
  },
  {
    "name": "Arkoe",
    "lng": -94.82905209962034,
    "lat": 40.259621080871106
  },
  {
    "name": "Arkoma",
    "lng": -94.4437366865182,
    "lat": 35.337756172685
  },
  {
    "name": "Arkport",
    "lng": -77.69547625726057,
    "lat": 42.39260002155939
  },
  {
    "name": "Arley",
    "lng": -87.2095277015236,
    "lat": 34.08685720014759
  },
  {
    "name": "Arlington",
    "lng": -122.14814916389817,
    "lat": 48.168355843477755
  },
  {
    "name": "Arlington",
    "lng": -98.17779531638266,
    "lat": 37.896407442539775
  },
  {
    "name": "Arlington",
    "lng": -97.13456285263145,
    "lat": 44.36287550264274
  },
  {
    "name": "Arlington",
    "lng": -96.35652775764181,
    "lat": 41.45485481091326
  },
  {
    "name": "Arlington",
    "lng": -94.07643894689917,
    "lat": 44.60836056137718
  },
  {
    "name": "Arlington",
    "lng": -91.67101884589039,
    "lat": 42.74873667020703
  },
  {
    "name": "Arlington",
    "lng": -89.66801549369674,
    "lat": 35.259529765810946
  },
  {
    "name": "Arlington",
    "lng": -89.37250188371539,
    "lat": 43.33513849426043
  },
  {
    "name": "Arlington",
    "lng": -89.24828457251635,
    "lat": 41.47166939281442
  },
  {
    "name": "Arlington",
    "lng": -89.01203501194014,
    "lat": 36.790410748520564
  },
  {
    "name": "Arlington",
    "lng": -84.72538414916389,
    "lat": 31.437797667260757
  },
  {
    "name": "Arlington",
    "lng": -83.65289692133652,
    "lat": 40.89374002920078
  },
  {
    "name": "Arlington",
    "lng": -71.16390312116111,
    "lat": 42.41866178932156
  },
  {
    "name": "Arlington Heights",
    "lng": -87.98248026506374,
    "lat": 42.09539790165462
  },
  {
    "name": "Arlington Heights",
    "lng": -84.455462386145,
    "lat": 39.215213449657064
  },
  {
    "name": "Arlington Heights",
    "lng": -75.21167848814704,
    "lat": 41.00382100590764
  },
  {
    "name": "Arma",
    "lng": -94.71231997533935,
    "lat": 37.53908837391642
  },
  {
    "name": "Arma",
    "lng": -94.71175273109067,
    "lat": 37.54763923415545
  },
  {
    "name": "Arma",
    "lng": -94.69980919182616,
    "lat": 37.54305194326719
  },
  {
    "name": "Armada",
    "lng": -82.88289036993994,
    "lat": 42.84235348019589
  },
  {
    "name": "Armington",
    "lng": -89.31426384335035,
    "lat": 40.33975357352281
  },
  {
    "name": "Armour",
    "lng": -98.34484922616889,
    "lat": 43.318991379616705
  },
  {
    "name": "Armstrong",
    "lng": -96.34481345009547,
    "lat": 34.05284257416578
  },
  {
    "name": "Armstrong",
    "lng": -94.48331951736328,
    "lat": 43.394384639272296
  },
  {
    "name": "Armstrong",
    "lng": -92.70417255685622,
    "lat": 39.26918036772391
  },
  {
    "name": "Arnaudville",
    "lng": -91.93196499580421,
    "lat": 30.402225300499822
  },
  {
    "name": "Arnegard",
    "lng": -103.43872501250033,
    "lat": 47.80840303288246
  },
  {
    "name": "Arnett",
    "lng": -99.7711891733855,
    "lat": 36.13507329446826
  },
  {
    "name": "Arnold",
    "lng": -100.19431987742733,
    "lat": 41.423904732477396
  },
  {
    "name": "Arnold",
    "lng": -79.76525749716674,
    "lat": 40.57886991898205
  },
  {
    "name": "Arnolds Park",
    "lng": -95.12632439394444,
    "lat": 43.36019422464947
  },
  {
    "name": "Arnoldsville",
    "lng": -83.21819693663848,
    "lat": 33.91048699384779
  },
  {
    "name": "Arpin",
    "lng": -90.03190908380236,
    "lat": 44.53946784153218
  },
  {
    "name": "Arriba",
    "lng": -103.27387532143648,
    "lat": 39.284106178864775
  },
  {
    "name": "Arrow Point",
    "lng": -93.61962584791799,
    "lat": 36.545561217109416
  },
  {
    "name": "Arrow Rock",
    "lng": -92.9472466370838,
    "lat": 39.070000008136056
  },
  {
    "name": "Arrowsic",
    "lng": -69.7853243817643,
    "lat": 43.861825419336895
  },
  {
    "name": "Arrowsmith",
    "lng": -88.63209132066382,
    "lat": 40.44928366037545
  },
  {
    "name": "Arroyo Grande",
    "lng": -120.58479396406251,
    "lat": 35.12423551174644
  },
  {
    "name": "Artas",
    "lng": -99.8068803786318,
    "lat": 45.88718186066194
  },
  {
    "name": "Artesia",
    "lng": -118.08064836631057,
    "lat": 33.86758726014759
  },
  {
    "name": "Artesia",
    "lng": -104.42698157837091,
    "lat": 32.849724197623694
  },
  {
    "name": "Artesian",
    "lng": -97.92354906281912,
    "lat": 44.00758357920506
  },
  {
    "name": "Arthur",
    "lng": -101.69294453582918,
    "lat": 41.57174897880722
  },
  {
    "name": "Arthur",
    "lng": -97.21852921473719,
    "lat": 47.10429213516435
  },
  {
    "name": "Arthur",
    "lng": -95.34682301867134,
    "lat": 42.33513134133767
  },
  {
    "name": "Arthur",
    "lng": -88.46952042828082,
    "lat": 39.71447918923236
  },
  {
    "name": "Arundel",
    "lng": -70.5300395016883,
    "lat": 43.44053062995233
  },
  {
    "name": "Arvada",
    "lng": -105.22031603234015,
    "lat": 39.820735340361786
  },
  {
    "name": "Arvada",
    "lng": -105.14453838332743,
    "lat": 39.830749881434095
  },
  {
    "name": "Arvada",
    "lng": -105.04541692941804,
    "lat": 39.802992081798024
  },
  {
    "name": "Arvin",
    "lng": -118.83059484772257,
    "lat": 35.19423763219237
  },
  {
    "name": "Asbury",
    "lng": -94.60508878387982,
    "lat": 37.2729856871983
  },
  {
    "name": "Asbury",
    "lng": -90.77995782750644,
    "lat": 42.51195593007877
  },
  {
    "name": "Asbury Park",
    "lng": -74.01085070086512,
    "lat": 40.222634976555305
  },
  {
    "name": "Ash Flat",
    "lng": -91.60910784429399,
    "lat": 36.23504301824198
  },
  {
    "name": "Ash Flat",
    "lng": -91.57622965205,
    "lat": 36.25419958437929
  },
  {
    "name": "Ash Grove",
    "lng": -93.58118629593815,
    "lat": 37.31768208049974
  },
  {
    "name": "Ash Grove",
    "lng": -93.58062405481344,
    "lat": 37.3241186849455
  },
  {
    "name": "Asharoken",
    "lng": -73.37865046229956,
    "lat": 40.962625661971884
  },
  {
    "name": "Ashburn",
    "lng": -83.65280016113095,
    "lat": 31.70956941555348
  },
  {
    "name": "Ashburn",
    "lng": -83.6499924695139,
    "lat": 31.736129599720247
  },
  {
    "name": "Ashburnham",
    "lng": -71.92521633810378,
    "lat": 42.659010920274454
  },
  {
    "name": "Ashby",
    "lng": -95.81550402045963,
    "lat": 46.093112331588465
  },
  {
    "name": "Ashby",
    "lng": -71.82318514140457,
    "lat": 42.67655437816106
  },
  {
    "name": "Ashdown",
    "lng": -94.12644318940511,
    "lat": 33.674561774925024
  },
  {
    "name": "Asheboro",
    "lng": -79.96696563246738,
    "lat": 35.68298644829025
  },
  {
    "name": "Asheboro",
    "lng": -79.89442226763447,
    "lat": 35.65300892414068
  },
  {
    "name": "Asheboro",
    "lng": -79.87798338445195,
    "lat": 35.73486921839415
  },
  {
    "name": "Asheboro",
    "lng": -79.85519753752888,
    "lat": 35.71628395684476
  },
  {
    "name": "Asheboro",
    "lng": -79.83063222357328,
    "lat": 35.77033767423792
  },
  {
    "name": "Asheboro",
    "lng": -79.82569164211326,
    "lat": 35.66246957502152
  },
  {
    "name": "Asheboro",
    "lng": -79.82242037491785,
    "lat": 35.65773051462624
  },
  {
    "name": "Asheboro",
    "lng": -79.82155915857541,
    "lat": 35.6534523305328
  },
  {
    "name": "Asheboro",
    "lng": -79.81768579178016,
    "lat": 35.678797787102134
  },
  {
    "name": "Asheboro",
    "lng": -79.81099737024557,
    "lat": 35.721503237198164
  },
  {
    "name": "Asheboro",
    "lng": -79.79952015641375,
    "lat": 35.67226287398919
  },
  {
    "name": "Asheboro",
    "lng": -79.79392821466789,
    "lat": 35.661404621125165
  },
  {
    "name": "Asheboro",
    "lng": -79.79032155892718,
    "lat": 35.66325674521558
  },
  {
    "name": "Asheboro",
    "lng": -79.78924671898868,
    "lat": 35.78030023859132
  },
  {
    "name": "Asheboro",
    "lng": -79.78906839681743,
    "lat": 35.667138373113524
  },
  {
    "name": "Asheboro",
    "lng": -79.7882193972365,
    "lat": 35.767644371534274
  },
  {
    "name": "Asheboro",
    "lng": -79.78156118411368,
    "lat": 35.71909298356328
  },
  {
    "name": "Asheboro",
    "lng": -79.7795439892535,
    "lat": 35.707483422932704
  },
  {
    "name": "Asheboro",
    "lng": -79.77713643513177,
    "lat": 35.70309037370947
  },
  {
    "name": "Asheboro",
    "lng": -79.77187229383588,
    "lat": 35.731359784812916
  },
  {
    "name": "Asher",
    "lng": -96.92543031945678,
    "lat": 34.98842548095912
  },
  {
    "name": "Asheville",
    "lng": -82.60112843792017,
    "lat": 35.59981504180065
  },
  {
    "name": "Asheville",
    "lng": -82.59222788841292,
    "lat": 35.51221382249981
  },
  {
    "name": "Asheville",
    "lng": -82.5918042919574,
    "lat": 35.618635249831854
  },
  {
    "name": "Asheville",
    "lng": -82.56078620763805,
    "lat": 35.480465886501946
  },
  {
    "name": "Asheville",
    "lng": -82.553791260622,
    "lat": 35.57435795710924
  },
  {
    "name": "Asheville",
    "lng": -82.5405994683886,
    "lat": 35.43520245110167
  },
  {
    "name": "Asheville",
    "lng": -82.53080958713666,
    "lat": 35.43507874723662
  },
  {
    "name": "Asheville",
    "lng": -82.51986663160928,
    "lat": 35.46999779451621
  },
  {
    "name": "Ashfield",
    "lng": -72.80657059522832,
    "lat": 42.52018777081247
  },
  {
    "name": "Ashford",
    "lng": -72.17044133675721,
    "lat": 41.89089394376966
  },
  {
    "name": "Ashkum",
    "lng": -87.95305422577918,
    "lat": 40.878239922866484
  },
  {
    "name": "Ashland",
    "lng": -99.769695782993,
    "lat": 37.18675153272537
  },
  {
    "name": "Ashland",
    "lng": -96.37131938801619,
    "lat": 41.04035912724885
  },
  {
    "name": "Ashland",
    "lng": -96.07051635180248,
    "lat": 34.76678480131117
  },
  {
    "name": "Ashland",
    "lng": -93.11707273227638,
    "lat": 32.116302667004604
  },
  {
    "name": "Ashland",
    "lng": -92.24889081588724,
    "lat": 38.78337182586803
  },
  {
    "name": "Ashland",
    "lng": -90.87124111206519,
    "lat": 46.580136544300714
  },
  {
    "name": "Ashland",
    "lng": -90.00787363683071,
    "lat": 39.888539508815136
  },
  {
    "name": "Ashland",
    "lng": -89.17774150292176,
    "lat": 34.83411929078768
  },
  {
    "name": "Ashland",
    "lng": -85.833510049283,
    "lat": 33.27040915051915
  },
  {
    "name": "Ashland",
    "lng": -82.64487487053339,
    "lat": 38.45928522282952
  },
  {
    "name": "Ashland",
    "lng": -82.3157590609751,
    "lat": 40.86690958802999
  },
  {
    "name": "Ashland",
    "lng": -77.471586560441,
    "lat": 37.75960896101217
  },
  {
    "name": "Ashland",
    "lng": -71.46865481512825,
    "lat": 42.2572608268511
  },
  {
    "name": "Ashland",
    "lng": -68.37031193896483,
    "lat": 46.65452649766454
  },
  {
    "name": "Ashland City",
    "lng": -87.04130355101317,
    "lat": 36.25594966185579
  },
  {
    "name": "Ashley",
    "lng": -99.37354204399473,
    "lat": 46.03485295635454
  },
  {
    "name": "Ashley",
    "lng": -89.18932354034722,
    "lat": 38.32889761209905
  },
  {
    "name": "Ashley",
    "lng": -85.06413221443921,
    "lat": 41.51983992741007
  },
  {
    "name": "Ashley",
    "lng": -84.47642569414062,
    "lat": 43.187773432252946
  },
  {
    "name": "Ashley",
    "lng": -82.95165247949947,
    "lat": 40.40980567256793
  },
  {
    "name": "Ashmore",
    "lng": -88.02021418240186,
    "lat": 39.53051069457772
  },
  {
    "name": "Ashtabula",
    "lng": -80.79813566138284,
    "lat": 41.880540174575046
  },
  {
    "name": "Ashton",
    "lng": -111.44831144219485,
    "lat": 44.07333178327761
  },
  {
    "name": "Ashton",
    "lng": -98.79510635779863,
    "lat": 41.24775604268275
  },
  {
    "name": "Ashton",
    "lng": -98.4980543485558,
    "lat": 44.99310012490903
  },
  {
    "name": "Ashton",
    "lng": -95.79241764123756,
    "lat": 43.308714687435106
  },
  {
    "name": "Ashton",
    "lng": -89.22242758917491,
    "lat": 41.868219723595594
  },
  {
    "name": "Ashville",
    "lng": -86.26997490248223,
    "lat": 33.83384922710295
  },
  {
    "name": "Ashville",
    "lng": -82.95748852432678,
    "lat": 39.72388846116718
  },
  {
    "name": "Ashwaubenon",
    "lng": -88.08885536247801,
    "lat": 44.47924408619954
  },
  {
    "name": "Askewville",
    "lng": -76.9411425499018,
    "lat": 36.11188266886564
  },
  {
    "name": "Askov",
    "lng": -92.7823052616111,
    "lat": 46.188620280149934
  },
  {
    "name": "Asotin",
    "lng": -117.04290420683483,
    "lat": 46.33712252181473
  },
  {
    "name": "Aspen",
    "lng": -106.8367455522341,
    "lat": 39.19517053686734
  },
  {
    "name": "Aspinwall",
    "lng": -95.1363876442511,
    "lat": 41.91055577773025
  },
  {
    "name": "Assaria",
    "lng": -97.60420075374259,
    "lat": 38.68018088642195
  },
  {
    "name": "Assumption",
    "lng": -89.04772897234388,
    "lat": 39.51800193605326
  },
  {
    "name": "Astatula",
    "lng": -81.73426806816416,
    "lat": 28.70651317787993
  },
  {
    "name": "Astatula",
    "lng": -81.72874440307967,
    "lat": 28.72145251650353
  },
  {
    "name": "Astoria",
    "lng": -96.54652264799118,
    "lat": 44.55772333379203
  },
  {
    "name": "Astoria",
    "lng": -90.35647514260938,
    "lat": 40.2280514334669
  },
  {
    "name": "Atalissa",
    "lng": -91.16643572069292,
    "lat": 41.57160704460839
  },
  {
    "name": "Atascadero",
    "lng": -120.68577384505994,
    "lat": 35.48235605754169
  },
  {
    "name": "Atchison",
    "lng": -95.24552005969804,
    "lat": 39.56042226708142
  },
  {
    "name": "Atchison",
    "lng": -95.17918322809393,
    "lat": 39.570318997586206
  },
  {
    "name": "Atchison",
    "lng": -95.16379024859614,
    "lat": 39.546186700982894
  },
  {
    "name": "Atchison",
    "lng": -95.1586369146341,
    "lat": 39.54920641064622
  },
  {
    "name": "Atchison",
    "lng": -95.15309028424979,
    "lat": 39.55161860403598
  },
  {
    "name": "Atchison",
    "lng": -95.14986629029094,
    "lat": 39.53663203001744
  },
  {
    "name": "Atchison",
    "lng": -95.13820800287644,
    "lat": 39.5441509591953
  },
  {
    "name": "Atchison",
    "lng": -95.12955843698306,
    "lat": 39.564669471606024
  },
  {
    "name": "Athalia",
    "lng": -82.30934762744366,
    "lat": 38.51268400744975
  },
  {
    "name": "Athens",
    "lng": -95.8351345691722,
    "lat": 32.20513226108303
  },
  {
    "name": "Athens",
    "lng": -95.76397197521952,
    "lat": 32.21880829195137
  },
  {
    "name": "Athens",
    "lng": -93.02543012682179,
    "lat": 32.65122036717789
  },
  {
    "name": "Athens",
    "lng": -90.07913764654333,
    "lat": 45.03485759848942
  },
  {
    "name": "Athens",
    "lng": -89.72162337910518,
    "lat": 39.96185502866782
  },
  {
    "name": "Athens",
    "lng": -86.97619915546207,
    "lat": 34.74468730920354
  },
  {
    "name": "Athens",
    "lng": -86.97497313771187,
    "lat": 34.74573195030725
  },
  {
    "name": "Athens",
    "lng": -86.97497313771183,
    "lat": 34.74573195030725
  },
  {
    "name": "Athens",
    "lng": -86.95164625780104,
    "lat": 34.78457286134764
  },
  {
    "name": "Athens",
    "lng": -86.91518092607204,
    "lat": 34.78003532759317
  },
  {
    "name": "Athens",
    "lng": -86.91518092607203,
    "lat": 34.78003532759317
  },
  {
    "name": "Athens",
    "lng": -85.23556418502808,
    "lat": 42.08658107362142
  },
  {
    "name": "Athens",
    "lng": -84.60441199981516,
    "lat": 35.457342502417006
  },
  {
    "name": "Athens",
    "lng": -82.10008784711557,
    "lat": 39.32687347695758
  },
  {
    "name": "Athens",
    "lng": -81.01596905787969,
    "lat": 37.423086019081786
  },
  {
    "name": "Athens",
    "lng": -73.81037865992472,
    "lat": 42.26951532248041
  },
  {
    "name": "Athens",
    "lng": -69.66369507297243,
    "lat": 44.953706680573134
  },
  {
    "name": "Athens-Clarke County Unified Government",
    "lng": -83.36902639803729,
    "lat": 33.950942265838115
  },
  {
    "name": "Atherton",
    "lng": -122.20318045441654,
    "lat": 37.4539806959678
  },
  {
    "name": "Athol",
    "lng": -116.70615297697464,
    "lat": 47.94515372511263
  },
  {
    "name": "Athol",
    "lng": -98.91980006376617,
    "lat": 39.76617790772693
  },
  {
    "name": "Athol",
    "lng": -72.2176817747091,
    "lat": 42.58415282368938
  },
  {
    "name": "Atka",
    "lng": -174.2212837043228,
    "lat": 52.23235649893792
  },
  {
    "name": "Atkins",
    "lng": -92.94624197257511,
    "lat": 35.241390422934984
  },
  {
    "name": "Atkins",
    "lng": -91.85860546829663,
    "lat": 41.9943208007882
  },
  {
    "name": "Atkinson",
    "lng": -98.97701614884504,
    "lat": 42.53254686913473
  },
  {
    "name": "Atkinson",
    "lng": -90.00852860442684,
    "lat": 41.41662006313585
  },
  {
    "name": "Atkinson",
    "lng": -78.16964030903951,
    "lat": 34.52771287705124
  },
  {
    "name": "Atkinson",
    "lng": -71.16010670351824,
    "lat": 42.83701228169977
  },
  {
    "name": "Atlanta",
    "lng": -99.47322311577318,
    "lat": 40.368410797925385
  },
  {
    "name": "Atlanta",
    "lng": -96.76703810489009,
    "lat": 37.435739669927244
  },
  {
    "name": "Atlanta",
    "lng": -92.73833924189056,
    "lat": 31.806408100093464
  },
  {
    "name": "Atlanta",
    "lng": -92.4800939032622,
    "lat": 39.89855250662491
  },
  {
    "name": "Atlanta",
    "lng": -89.23157996040742,
    "lat": 40.26339455143508
  },
  {
    "name": "Atlanta",
    "lng": -86.02707036884534,
    "lat": 40.21388187612479
  },
  {
    "name": "Atlanta",
    "lng": -84.42386266059982,
    "lat": 33.65345698664091
  },
  {
    "name": "Atlanta",
    "lng": -84.42278559850737,
    "lat": 33.76297731733281
  },
  {
    "name": "Atlantic",
    "lng": -95.01383330547156,
    "lat": 41.395708767265766
  },
  {
    "name": "Atlantic Beach",
    "lng": -78.713633465725,
    "lat": 33.79773716464941
  },
  {
    "name": "Atlantic Beach",
    "lng": -76.74191237144473,
    "lat": 34.70123231514816
  },
  {
    "name": "Atlantic City",
    "lng": -74.45088967217349,
    "lat": 39.37663391406432
  },
  {
    "name": "Atlantis",
    "lng": -80.10313707829623,
    "lat": 26.59608038839227
  },
  {
    "name": "Atmore",
    "lng": -87.49700198426534,
    "lat": 31.021961713172757
  },
  {
    "name": "Atmore",
    "lng": -87.48325857819549,
    "lat": 31.111213418982253
  },
  {
    "name": "Atmore",
    "lng": -87.48105458111748,
    "lat": 31.106987414965193
  },
  {
    "name": "Atmore",
    "lng": -87.46848339620402,
    "lat": 31.10579061124047
  },
  {
    "name": "Atmore",
    "lng": -87.46790285479396,
    "lat": 31.115301355728132
  },
  {
    "name": "Atmore",
    "lng": -87.44607406589343,
    "lat": 31.016108824111765
  },
  {
    "name": "Atoka",
    "lng": -96.16174519842518,
    "lat": 34.33468530320118
  },
  {
    "name": "Atoka",
    "lng": -96.12502605178813,
    "lat": 34.39176745887133
  },
  {
    "name": "Atoka",
    "lng": -96.1150895104308,
    "lat": 34.38425302294481
  },
  {
    "name": "Atoka",
    "lng": -96.1083194109873,
    "lat": 34.37909041237807
  },
  {
    "name": "Atoka",
    "lng": -89.78608507160693,
    "lat": 35.42276003739205
  },
  {
    "name": "Atomic City",
    "lng": -112.81242836997652,
    "lat": 43.44313504489028
  },
  {
    "name": "Atqasuk",
    "lng": -157.3865602636823,
    "lat": 70.48315227914478
  },
  {
    "name": "Attalla",
    "lng": -86.10403676409919,
    "lat": 34.00480345879426
  },
  {
    "name": "Attalla",
    "lng": -86.1022212684288,
    "lat": 34.03367537957072
  },
  {
    "name": "Attapulgus",
    "lng": -84.48387231309596,
    "lat": 30.74949843383582
  },
  {
    "name": "Attica",
    "lng": -98.2375506001307,
    "lat": 37.25476315202183
  },
  {
    "name": "Attica",
    "lng": -98.23222930527872,
    "lat": 37.23408601783432
  },
  {
    "name": "Attica",
    "lng": -98.22690615853892,
    "lat": 37.242852649632724
  },
  {
    "name": "Attica",
    "lng": -87.24513454132465,
    "lat": 40.287508511122674
  },
  {
    "name": "Attica",
    "lng": -82.89398222463774,
    "lat": 41.067397300205734
  },
  {
    "name": "Attica",
    "lng": -82.88714239656927,
    "lat": 41.063414776976934
  },
  {
    "name": "Attica",
    "lng": -78.27980889389012,
    "lat": 42.86409914256502
  },
  {
    "name": "Atwater",
    "lng": -120.59766834422855,
    "lat": 37.35407044908911
  },
  {
    "name": "Atwater",
    "lng": -94.77668654310907,
    "lat": 45.135749403747866
  },
  {
    "name": "Atwood",
    "lng": -101.04193004945853,
    "lat": 39.810264610790206
  },
  {
    "name": "Atwood",
    "lng": -96.33540320868622,
    "lat": 34.959819546156446
  },
  {
    "name": "Atwood",
    "lng": -88.66845496805654,
    "lat": 35.9750360683973
  },
  {
    "name": "Atwood",
    "lng": -88.46270068147199,
    "lat": 39.799293336249185
  },
  {
    "name": "Au Gres",
    "lng": -83.69348955936618,
    "lat": 44.04312527939765
  },
  {
    "name": "Aubrey",
    "lng": -90.89798428690692,
    "lat": 34.7198654061174
  },
  {
    "name": "Auburn",
    "lng": -122.21035267319284,
    "lat": 47.30345221192258
  },
  {
    "name": "Auburn",
    "lng": -121.10701592094323,
    "lat": 38.888585122629145
  },
  {
    "name": "Auburn",
    "lng": -121.08285456719027,
    "lat": 38.95385844710884
  },
  {
    "name": "Auburn",
    "lng": -121.07679550665458,
    "lat": 38.891343101525926
  },
  {
    "name": "Auburn",
    "lng": -95.8543341457185,
    "lat": 40.38037781467961
  },
  {
    "name": "Auburn",
    "lng": -95.84410367180631,
    "lat": 40.389681907373294
  },
  {
    "name": "Auburn",
    "lng": -95.83813351911223,
    "lat": 40.40088267103207
  },
  {
    "name": "Auburn",
    "lng": -95.81600259101064,
    "lat": 38.90606117537497
  },
  {
    "name": "Auburn",
    "lng": -94.87822615601472,
    "lat": 42.24957506322635
  },
  {
    "name": "Auburn",
    "lng": -89.74383328670605,
    "lat": 39.576385625601596
  },
  {
    "name": "Auburn",
    "lng": -86.71797742598697,
    "lat": 36.86442465424147
  },
  {
    "name": "Auburn",
    "lng": -85.56158756502309,
    "lat": 32.600083937664884
  },
  {
    "name": "Auburn",
    "lng": -85.55336219390408,
    "lat": 32.547654850110334
  },
  {
    "name": "Auburn",
    "lng": -85.55332597259493,
    "lat": 32.547645422603765
  },
  {
    "name": "Auburn",
    "lng": -85.54084129419465,
    "lat": 32.59101252179618
  },
  {
    "name": "Auburn",
    "lng": -85.53191925000293,
    "lat": 32.55141810285077
  },
  {
    "name": "Auburn",
    "lng": -85.48815442954911,
    "lat": 32.60916093454919
  },
  {
    "name": "Auburn",
    "lng": -85.48555112424305,
    "lat": 32.53549364157151
  },
  {
    "name": "Auburn",
    "lng": -85.46322105194177,
    "lat": 32.724230310863256
  },
  {
    "name": "Auburn",
    "lng": -85.40768684544969,
    "lat": 32.57157308095729
  },
  {
    "name": "Auburn",
    "lng": -85.05432462220928,
    "lat": 41.36672482802812
  },
  {
    "name": "Auburn",
    "lng": -83.84509195655501,
    "lat": 34.03799343264575
  },
  {
    "name": "Auburn",
    "lng": -83.83229987580128,
    "lat": 34.01383349746778
  },
  {
    "name": "Auburn",
    "lng": -83.81739505825935,
    "lat": 33.98321305093088
  },
  {
    "name": "Auburn",
    "lng": -83.80108877170827,
    "lat": 34.019899763642684
  },
  {
    "name": "Auburn",
    "lng": -80.85624003714676,
    "lat": 39.09615126718623
  },
  {
    "name": "Auburn",
    "lng": -76.56847909350648,
    "lat": 42.933845010717015
  },
  {
    "name": "Auburn",
    "lng": -71.84526950217682,
    "lat": 42.197238970188124
  },
  {
    "name": "Auburn",
    "lng": -71.34406705874451,
    "lat": 42.99049911461513
  },
  {
    "name": "Auburn",
    "lng": -70.24929356458681,
    "lat": 44.085241136242495
  },
  {
    "name": "Auburn Hills",
    "lng": -83.24470348819911,
    "lat": 42.67345642905337
  },
  {
    "name": "Auburn Lakes",
    "lng": -95.51997622845683,
    "lat": 30.131019620077122
  },
  {
    "name": "Auburndale",
    "lng": -90.01426171443022,
    "lat": 44.62658735404131
  },
  {
    "name": "Auburntown",
    "lng": -86.09702319446866,
    "lat": 35.95107626220021
  },
  {
    "name": "Audubon",
    "lng": -95.98388425046325,
    "lat": 46.864576515155306
  },
  {
    "name": "Audubon",
    "lng": -95.97368629006034,
    "lat": 46.85735479511334
  },
  {
    "name": "Audubon",
    "lng": -94.92833692443129,
    "lat": 41.71831760205259
  },
  {
    "name": "Audubon",
    "lng": -94.92637079949938,
    "lat": 41.70547501017328
  },
  {
    "name": "Audubon Park",
    "lng": -85.72695096321533,
    "lat": 38.20503891522437
  },
  {
    "name": "Augusta",
    "lng": -97.07836143983484,
    "lat": 37.6734436652945
  },
  {
    "name": "Augusta",
    "lng": -97.05564962208159,
    "lat": 37.708623100315535
  },
  {
    "name": "Augusta",
    "lng": -97.04709437442652,
    "lat": 37.67752659062103
  },
  {
    "name": "Augusta",
    "lng": -96.98232972499038,
    "lat": 37.66442953821167
  },
  {
    "name": "Augusta",
    "lng": -96.9730628311789,
    "lat": 37.69463365229954
  },
  {
    "name": "Augusta",
    "lng": -91.3614196554788,
    "lat": 35.28657787819076
  },
  {
    "name": "Augusta",
    "lng": -91.12054490406851,
    "lat": 44.678764853956594
  },
  {
    "name": "Augusta",
    "lng": -90.94978863577512,
    "lat": 40.23092704309894
  },
  {
    "name": "Augusta",
    "lng": -90.88070978367902,
    "lat": 38.56846405996387
  },
  {
    "name": "Augusta",
    "lng": -85.3523665345696,
    "lat": 42.337834235714325
  },
  {
    "name": "Augusta",
    "lng": -84.0002693858274,
    "lat": 38.77211541657875
  },
  {
    "name": "Augusta",
    "lng": -69.73188907061983,
    "lat": 44.33412176247873
  },
  {
    "name": "Aulander",
    "lng": -77.1136248732124,
    "lat": 36.22815319530691
  },
  {
    "name": "Aullville",
    "lng": -93.67776978403867,
    "lat": 39.017692933647865
  },
  {
    "name": "Ault",
    "lng": -104.73396309307891,
    "lat": 40.583454085291635
  },
  {
    "name": "Aurelia",
    "lng": -95.43686377199235,
    "lat": 42.713067886336034
  },
  {
    "name": "Aurelius",
    "lng": -84.52242547217722,
    "lat": 42.523863805073155
  },
  {
    "name": "Aurora",
    "lng": -111.93357004662163,
    "lat": 38.92005338194033
  },
  {
    "name": "Aurora",
    "lng": -104.8778225538404,
    "lat": 39.64927124772991
  },
  {
    "name": "Aurora",
    "lng": -104.72869679269306,
    "lat": 39.706573021483024
  },
  {
    "name": "Aurora",
    "lng": -104.67412588993268,
    "lat": 39.7426116843988
  },
  {
    "name": "Aurora",
    "lng": -104.64546297802005,
    "lat": 39.731937037999465
  },
  {
    "name": "Aurora",
    "lng": -104.62568912239372,
    "lat": 39.741950945346886
  },
  {
    "name": "Aurora",
    "lng": -104.59383225027292,
    "lat": 39.74880107319275
  },
  {
    "name": "Aurora",
    "lng": -104.59183265923596,
    "lat": 39.74315988991644
  },
  {
    "name": "Aurora",
    "lng": -104.57777849961812,
    "lat": 39.74725647212804
  },
  {
    "name": "Aurora",
    "lng": -104.55307808869597,
    "lat": 39.745386094336695
  },
  {
    "name": "Aurora",
    "lng": -104.54101062796632,
    "lat": 39.800522277268136
  },
  {
    "name": "Aurora",
    "lng": -98.00739504108188,
    "lat": 40.863773834148546
  },
  {
    "name": "Aurora",
    "lng": -97.52987872545026,
    "lat": 39.4519216068706
  },
  {
    "name": "Aurora",
    "lng": -97.50957389067706,
    "lat": 33.05599779381443
  },
  {
    "name": "Aurora",
    "lng": -96.68656467948036,
    "lat": 44.28213822261256
  },
  {
    "name": "Aurora",
    "lng": -93.7194606346956,
    "lat": 36.968325580906296
  },
  {
    "name": "Aurora",
    "lng": -92.2397704509486,
    "lat": 47.53124433679435
  },
  {
    "name": "Aurora",
    "lng": -91.72919550526017,
    "lat": 42.619481919162446
  },
  {
    "name": "Aurora",
    "lng": -88.39564732934576,
    "lat": 41.750051181883265
  },
  {
    "name": "Aurora",
    "lng": -88.35780939696896,
    "lat": 41.74508446491467
  },
  {
    "name": "Aurora",
    "lng": -88.35257205271341,
    "lat": 41.74026269768533
  },
  {
    "name": "Aurora",
    "lng": -88.33944958666878,
    "lat": 41.748974866324694
  },
  {
    "name": "Aurora",
    "lng": -88.30771437357672,
    "lat": 41.73443563922909
  },
  {
    "name": "Aurora",
    "lng": -88.29146594364255,
    "lat": 41.76657771725953
  },
  {
    "name": "Aurora",
    "lng": -88.25078617487381,
    "lat": 41.6963816900513
  },
  {
    "name": "Aurora",
    "lng": -84.9038795489526,
    "lat": 39.06508414624149
  },
  {
    "name": "Aurora",
    "lng": -81.34504687789502,
    "lat": 41.31186207310584
  },
  {
    "name": "Aurora",
    "lng": -76.79020245007507,
    "lat": 35.30310991816768
  },
  {
    "name": "Aurora",
    "lng": -76.69944171796519,
    "lat": 42.75119527489611
  },
  {
    "name": "Aurora",
    "lng": -68.27482309251413,
    "lat": 44.87005273466705
  },
  {
    "name": "Aurora Park",
    "lng": -87.80121342153167,
    "lat": 41.07882497445874
  },
  {
    "name": "Austell",
    "lng": -84.64874606763276,
    "lat": 33.81365625451871
  },
  {
    "name": "Austell",
    "lng": -84.64774884950376,
    "lat": 33.83053268294783
  },
  {
    "name": "Austell",
    "lng": -84.64523966530568,
    "lat": 33.82015304723988
  },
  {
    "name": "Austell",
    "lng": -84.64098052357073,
    "lat": 33.799064294569
  },
  {
    "name": "Austell",
    "lng": -84.60521505234664,
    "lat": 33.81876330321931
  },
  {
    "name": "Austin",
    "lng": -93.00378162409936,
    "lat": 43.68266363697182
  },
  {
    "name": "Austin",
    "lng": -92.98536171513776,
    "lat": 43.642406759685464
  },
  {
    "name": "Austin",
    "lng": -92.97718053633729,
    "lat": 43.67143984528429
  },
  {
    "name": "Austin",
    "lng": -92.9552836695726,
    "lat": 43.65309402422499
  },
  {
    "name": "Austin",
    "lng": -92.95262449999815,
    "lat": 43.71063645084738
  },
  {
    "name": "Austin",
    "lng": -91.9889288434519,
    "lat": 35.007141880016796
  },
  {
    "name": "Austin",
    "lng": -85.81561241972001,
    "lat": 38.75943660559125
  },
  {
    "name": "Austin",
    "lng": -85.81201389025236,
    "lat": 38.74158427475184
  },
  {
    "name": "Austin",
    "lng": -85.79537526757169,
    "lat": 38.73333846173397
  },
  {
    "name": "Autaugaville",
    "lng": -86.65794979831189,
    "lat": 32.43194924757674
  },
  {
    "name": "Autryville",
    "lng": -78.6412266572802,
    "lat": 34.9964549945511
  },
  {
    "name": "Auxvasse",
    "lng": -91.8955965014111,
    "lat": 39.01764477993926
  },
  {
    "name": "Ava",
    "lng": -92.68571870039281,
    "lat": 36.96677015350599
  },
  {
    "name": "Ava",
    "lng": -92.66577356206476,
    "lat": 36.953595761348645
  },
  {
    "name": "Ava",
    "lng": -89.49645010349029,
    "lat": 37.8885831085693
  },
  {
    "name": "Avalon",
    "lng": -118.31599915841244,
    "lat": 33.3532991815359
  },
  {
    "name": "Avalon",
    "lng": -83.19672479813264,
    "lat": 34.50266702955299
  },
  {
    "name": "Avant",
    "lng": -96.05985411524397,
    "lat": 36.48862399089024
  },
  {
    "name": "Avard",
    "lng": -98.78950258055022,
    "lat": 36.69827298891888
  },
  {
    "name": "Avenal",
    "lng": -120.11597221333564,
    "lat": 36.031209771669936
  },
  {
    "name": "Avera",
    "lng": -82.52705152441673,
    "lat": 33.19416995688421
  },
  {
    "name": "Avilla",
    "lng": -94.12984764666355,
    "lat": 37.19381772567811
  },
  {
    "name": "Avilla",
    "lng": -85.23439129233344,
    "lat": 41.36409780226634
  },
  {
    "name": "Aviston",
    "lng": -89.60883264925387,
    "lat": 38.61627253208868
  },
  {
    "name": "Avoca",
    "lng": -96.11915944165646,
    "lat": 40.79650586075511
  },
  {
    "name": "Avoca",
    "lng": -95.64536923611847,
    "lat": 43.94939878666909
  },
  {
    "name": "Avoca",
    "lng": -95.33962649121395,
    "lat": 41.499530519396075
  },
  {
    "name": "Avoca",
    "lng": -95.33726895767744,
    "lat": 41.48122433032137
  },
  {
    "name": "Avoca",
    "lng": -95.33400239304851,
    "lat": 41.502297596724425
  },
  {
    "name": "Avoca",
    "lng": -94.07095193549226,
    "lat": 36.39801031174798
  },
  {
    "name": "Avoca",
    "lng": -90.32625264588125,
    "lat": 43.19007508286702
  },
  {
    "name": "Avoca",
    "lng": -77.42227470256144,
    "lat": 42.41017932922859
  },
  {
    "name": "Avon",
    "lng": -106.53064983292735,
    "lat": 39.64937304512843
  },
  {
    "name": "Avon",
    "lng": -106.47075013737341,
    "lat": 39.633153886199814
  },
  {
    "name": "Avon",
    "lng": -98.05922113609213,
    "lat": 43.00520425522828
  },
  {
    "name": "Avon",
    "lng": -94.48765376196397,
    "lat": 45.61067491439483
  },
  {
    "name": "Avon",
    "lng": -94.45179996090812,
    "lat": 45.622799499693606
  },
  {
    "name": "Avon",
    "lng": -94.45028444812122,
    "lat": 45.60888443686103
  },
  {
    "name": "Avon",
    "lng": -90.43497176338029,
    "lat": 40.66176024268381
  },
  {
    "name": "Avon",
    "lng": -86.3811136218877,
    "lat": 39.766642273003214
  },
  {
    "name": "Avon",
    "lng": -85.27944345695343,
    "lat": 31.180350588306492
  },
  {
    "name": "Avon",
    "lng": -82.01871706039474,
    "lat": 41.44852176372938
  },
  {
    "name": "Avon",
    "lng": -77.74632184482624,
    "lat": 42.91231679566233
  },
  {
    "name": "Avon",
    "lng": -76.38171723579768,
    "lat": 40.342460148579995
  },
  {
    "name": "Avon",
    "lng": -75.50776624375743,
    "lat": 35.345873672452214
  },
  {
    "name": "Avon",
    "lng": -72.85398552714913,
    "lat": 41.790697181523576
  },
  {
    "name": "Avon",
    "lng": -70.31280835872512,
    "lat": 44.781337047223516
  },
  {
    "name": "Avon Lake",
    "lng": -82.01615237883566,
    "lat": 41.49451653449819
  },
  {
    "name": "Avon Park",
    "lng": -81.51219077226344,
    "lat": 27.601143733934965
  },
  {
    "name": "Avondale",
    "lng": -94.5453314778566,
    "lat": 39.15463928462889
  },
  {
    "name": "Avondale Estates",
    "lng": -84.26478312145008,
    "lat": 33.76992168300896
  },
  {
    "name": "Awendaw",
    "lng": -79.70716569473421,
    "lat": 32.93736812851402
  },
  {
    "name": "Awendaw",
    "lng": -79.70133942275406,
    "lat": 32.935966155843744
  },
  {
    "name": "Awendaw",
    "lng": -79.69255660843243,
    "lat": 32.938017415649654
  },
  {
    "name": "Awendaw",
    "lng": -79.67620332757282,
    "lat": 32.945972204366505
  },
  {
    "name": "Awendaw",
    "lng": -79.6626769796904,
    "lat": 32.9496868370932
  },
  {
    "name": "Awendaw",
    "lng": -79.63130029245842,
    "lat": 32.99922594774066
  },
  {
    "name": "Awendaw",
    "lng": -79.59955215606462,
    "lat": 33.00669463151322
  },
  {
    "name": "Awendaw",
    "lng": -79.59557785895316,
    "lat": 33.01932121256375
  },
  {
    "name": "Axtell",
    "lng": -99.12856601118966,
    "lat": 40.479327099880834
  },
  {
    "name": "Axtell",
    "lng": -96.25664159765851,
    "lat": 39.87127770137005
  },
  {
    "name": "Ayden",
    "lng": -77.4209784933812,
    "lat": 35.47110831071016
  },
  {
    "name": "Ayden",
    "lng": -77.39841633848812,
    "lat": 35.4584591055595
  },
  {
    "name": "Ayer",
    "lng": -71.57175388472682,
    "lat": 42.5628036109906
  },
  {
    "name": "Aynor",
    "lng": -79.20723008879592,
    "lat": 34.00060083937639
  },
  {
    "name": "Ayr",
    "lng": -98.44080214395053,
    "lat": 40.43832185930358
  },
  {
    "name": "Ayr",
    "lng": -98.44000828815868,
    "lat": 40.43328419237139
  },
  {
    "name": "Ayr",
    "lng": -97.49096612932917,
    "lat": 47.04118406683225
  },
  {
    "name": "Ayrshire",
    "lng": -94.83413294568501,
    "lat": 43.0394688770264
  },
  {
    "name": "Azle",
    "lng": -97.53762639813309,
    "lat": 32.89553746969825
  },
  {
    "name": "Aztec",
    "lng": -107.9711376474324,
    "lat": 36.823800638530706
  },
  {
    "name": "Aztec",
    "lng": -107.97113594244613,
    "lat": 36.823800735633945
  },
  {
    "name": "Aztec",
    "lng": -107.95495120730176,
    "lat": 36.771259860909154
  },
  {
    "name": "Babbie",
    "lng": -86.32295632725545,
    "lat": 31.302107958559613
  },
  {
    "name": "Babbitt",
    "lng": -91.94568187249293,
    "lat": 47.64464325885381
  },
  {
    "name": "Babcock Ranch",
    "lng": -81.72111725471333,
    "lat": 26.80597506856773
  },
  {
    "name": "Babylon",
    "lng": -73.32685745674567,
    "lat": 40.69438286365391
  },
  {
    "name": "Backus",
    "lng": -94.51451919446373,
    "lat": 46.82071912392706
  },
  {
    "name": "Baconton",
    "lng": -84.15913193940435,
    "lat": 31.38042526993713
  },
  {
    "name": "Bad Axe",
    "lng": -82.99835722730953,
    "lat": 43.804057818532435
  },
  {
    "name": "Badger",
    "lng": -97.20786762747585,
    "lat": 44.48550255671684
  },
  {
    "name": "Badger",
    "lng": -96.02121198196448,
    "lat": 48.77671887549696
  },
  {
    "name": "Badger",
    "lng": -94.14466330665167,
    "lat": 42.614206447014055
  },
  {
    "name": "Badin",
    "lng": -80.11674968403165,
    "lat": 35.406516307084836
  },
  {
    "name": "Baggs",
    "lng": -107.6573502556284,
    "lat": 41.034425491659945
  },
  {
    "name": "Bagley",
    "lng": -95.40317490494934,
    "lat": 47.52444175197644
  },
  {
    "name": "Bagley",
    "lng": -94.42985126610886,
    "lat": 41.84617093058866
  },
  {
    "name": "Bagley",
    "lng": -91.09712359515707,
    "lat": 42.9010984748778
  },
  {
    "name": "Bagnell",
    "lng": -92.60535051247363,
    "lat": 38.22937733629015
  },
  {
    "name": "Bailey",
    "lng": -78.11292333886077,
    "lat": 35.78061428065574
  },
  {
    "name": "Bailey Lakes",
    "lng": -82.35696442792307,
    "lat": 40.94901170423683
  },
  {
    "name": "Bailey's Prairie",
    "lng": -95.49765518511114,
    "lat": 29.15267506986709
  },
  {
    "name": "Baileyton",
    "lng": -86.6082283944219,
    "lat": 34.260813620135984
  },
  {
    "name": "Baileyton",
    "lng": -82.8289721464543,
    "lat": 36.33037968172926
  },
  {
    "name": "Baileyville",
    "lng": -67.44139247844049,
    "lat": 45.17845207388815
  },
  {
    "name": "Bainbridge",
    "lng": -86.81126628543976,
    "lat": 39.76141481902242
  },
  {
    "name": "Bainbridge",
    "lng": -84.57212924713666,
    "lat": 30.90449232740616
  },
  {
    "name": "Bainbridge",
    "lng": -83.26957282700101,
    "lat": 39.226463975937
  },
  {
    "name": "Bainbridge",
    "lng": -75.48041999925337,
    "lat": 42.296667549167
  },
  {
    "name": "Bainville",
    "lng": -104.21984338305043,
    "lat": 48.13929116247951
  },
  {
    "name": "Bairdstown",
    "lng": -83.60728869781052,
    "lat": 41.17118417598046
  },
  {
    "name": "Bairoil",
    "lng": -107.56104832876743,
    "lat": 42.23720251623408
  },
  {
    "name": "Baker",
    "lng": -104.27315903822597,
    "lat": 46.364392335096156
  },
  {
    "name": "Baker",
    "lng": -91.15796799718771,
    "lat": 30.583480608157704
  },
  {
    "name": "Baker",
    "lng": -89.76153488568136,
    "lat": 36.773550012854024
  },
  {
    "name": "Bakerhill",
    "lng": -85.29540301687938,
    "lat": 31.77692548457388
  },
  {
    "name": "Bakersfield",
    "lng": -119.15687836269548,
    "lat": 35.367242035908795
  },
  {
    "name": "Bakersfield",
    "lng": -119.02942519183182,
    "lat": 35.35325392551753
  },
  {
    "name": "Bakersfield",
    "lng": -119.02542428268826,
    "lat": 35.26921379398888
  },
  {
    "name": "Bakersfield",
    "lng": -119.01375285362755,
    "lat": 35.33244566542423
  },
  {
    "name": "Bakersfield",
    "lng": -118.9736038232157,
    "lat": 35.24523091071136
  },
  {
    "name": "Bakersfield",
    "lng": -118.95874637760174,
    "lat": 35.20131525455182
  },
  {
    "name": "Bakersfield",
    "lng": -118.94943453989023,
    "lat": 35.22358448497074
  },
  {
    "name": "Bakersfield",
    "lng": -92.144193726244,
    "lat": 36.52383856578951
  },
  {
    "name": "Bakersville",
    "lng": -82.1584154809258,
    "lat": 36.01524502512568
  },
  {
    "name": "Bakersville",
    "lng": -82.15032092949917,
    "lat": 36.00958526862532
  },
  {
    "name": "Bakersville",
    "lng": -82.1451505155215,
    "lat": 36.0281093992747
  },
  {
    "name": "Bal Harbour Village",
    "lng": -80.12368774055263,
    "lat": 25.8930401203133
  },
  {
    "name": "Balaton",
    "lng": -95.87075376708786,
    "lat": 44.233051854257646
  },
  {
    "name": "Balch Springs",
    "lng": -96.6149572346287,
    "lat": 32.71932071938043
  },
  {
    "name": "Balcones Heights",
    "lng": -98.55029905277189,
    "lat": 29.48993104441878
  },
  {
    "name": "Bald Head Island",
    "lng": -77.98692076295383,
    "lat": 33.86395181385524
  },
  {
    "name": "Bald Knob",
    "lng": -91.57089363202294,
    "lat": 35.312332647888745
  },
  {
    "name": "Baldwin",
    "lng": -92.37078302000057,
    "lat": 44.95399865539573
  },
  {
    "name": "Baldwin",
    "lng": -91.55216499699299,
    "lat": 29.838867929030677
  },
  {
    "name": "Baldwin",
    "lng": -90.83859166288059,
    "lat": 42.073182048089095
  },
  {
    "name": "Baldwin",
    "lng": -89.8451524508071,
    "lat": 38.18382989841136
  },
  {
    "name": "Baldwin",
    "lng": -85.85299656124643,
    "lat": 43.897116560304156
  },
  {
    "name": "Baldwin",
    "lng": -83.54935690372307,
    "lat": 34.48637915075456
  },
  {
    "name": "Baldwin",
    "lng": -81.97415790517306,
    "lat": 30.305154985436012
  },
  {
    "name": "Baldwin",
    "lng": -70.71885770550058,
    "lat": 43.832950713554624
  },
  {
    "name": "Baldwin City",
    "lng": -95.18904454127006,
    "lat": 38.77904698805208
  },
  {
    "name": "Baldwin City",
    "lng": -95.16109825164997,
    "lat": 38.754916408160824
  },
  {
    "name": "Baldwin City",
    "lng": -95.16070221419169,
    "lat": 38.77358181350605
  },
  {
    "name": "Baldwin Park",
    "lng": -117.971866998002,
    "lat": 34.08297862173463
  },
  {
    "name": "Baldwinsville",
    "lng": -76.33203513035825,
    "lat": 43.157019998172956
  },
  {
    "name": "Baldwyn",
    "lng": -88.64166813073552,
    "lat": 34.50719472117637
  },
  {
    "name": "Balfour",
    "lng": -100.53428440366834,
    "lat": 47.95175414006874
  },
  {
    "name": "Ball",
    "lng": -92.42271080155155,
    "lat": 31.3886821960274
  },
  {
    "name": "Ball",
    "lng": -92.41094719805312,
    "lat": 31.38578255503451
  },
  {
    "name": "Ball",
    "lng": -92.40907005358649,
    "lat": 31.419044230571878
  },
  {
    "name": "Ball",
    "lng": -92.4084267611246,
    "lat": 31.384550259321962
  },
  {
    "name": "Ball",
    "lng": -92.38118194778795,
    "lat": 31.404753078614142
  },
  {
    "name": "Ball",
    "lng": -92.3811396175136,
    "lat": 31.395855848333966
  },
  {
    "name": "Ball",
    "lng": -92.37969686804358,
    "lat": 31.410411669146374
  },
  {
    "name": "Ball",
    "lng": -92.37826403033287,
    "lat": 31.39567687238573
  },
  {
    "name": "Ball",
    "lng": -92.37709651206923,
    "lat": 31.4147602071706
  },
  {
    "name": "Ball",
    "lng": -92.3767291057747,
    "lat": 31.4011470857627
  },
  {
    "name": "Ball Ground",
    "lng": -84.40869025454596,
    "lat": 34.31646189694185
  },
  {
    "name": "Ball Ground",
    "lng": -84.4044755991034,
    "lat": 34.326249389325625
  },
  {
    "name": "Ball Ground",
    "lng": -84.40226278383577,
    "lat": 34.315432965123186
  },
  {
    "name": "Ball Ground",
    "lng": -84.39924539171865,
    "lat": 34.325954129704755
  },
  {
    "name": "Ball Ground",
    "lng": -84.3979094155564,
    "lat": 34.33085239888706
  },
  {
    "name": "Ball Ground",
    "lng": -84.36048030166731,
    "lat": 34.34438828558495
  },
  {
    "name": "Ballard",
    "lng": -109.94935326178918,
    "lat": 40.29489423544817
  },
  {
    "name": "Ballinger",
    "lng": -99.95548992826572,
    "lat": 31.740573772866618
  },
  {
    "name": "Balltown",
    "lng": -90.86980556665908,
    "lat": 42.637293697560594
  },
  {
    "name": "Ballwin",
    "lng": -90.55000196998884,
    "lat": 38.59510720366542
  },
  {
    "name": "Bally",
    "lng": -75.58755751431015,
    "lat": 40.4004495487345
  },
  {
    "name": "Balmorhea",
    "lng": -103.74332339086456,
    "lat": 30.983951210952828
  },
  {
    "name": "Balsam Lake",
    "lng": -92.45297003879514,
    "lat": 45.45847060477966
  },
  {
    "name": "Balta",
    "lng": -100.03691675412091,
    "lat": 48.16652994390936
  },
  {
    "name": "Baltic",
    "lng": -96.73677902373994,
    "lat": 43.76034126895379
  },
  {
    "name": "Baltic",
    "lng": -81.70202602297444,
    "lat": 40.44406325358316
  },
  {
    "name": "Baltic",
    "lng": -81.69738122154878,
    "lat": 40.45720666319257
  },
  {
    "name": "Baltic",
    "lng": -72.0864842901113,
    "lat": 41.614055807189686
  },
  {
    "name": "Baltimore",
    "lng": -82.60531104868932,
    "lat": 39.84587605208379
  },
  {
    "name": "Baltimore",
    "lng": -76.6107246782334,
    "lat": 39.30105959509717
  },
  {
    "name": "Bamberg",
    "lng": -81.03222886825175,
    "lat": 33.29959367528458
  },
  {
    "name": "Bancroft",
    "lng": -111.88301019733348,
    "lat": 42.720240749770284
  },
  {
    "name": "Bancroft",
    "lng": -97.75049779783997,
    "lat": 44.48940044228527
  },
  {
    "name": "Bancroft",
    "lng": -96.57326507775437,
    "lat": 42.01052222007318
  },
  {
    "name": "Bancroft",
    "lng": -94.21736682329946,
    "lat": 43.29262077906762
  },
  {
    "name": "Bancroft",
    "lng": -85.61259118874078,
    "lat": 38.28361395575431
  },
  {
    "name": "Bancroft",
    "lng": -84.06642309117316,
    "lat": 42.87636669401512
  },
  {
    "name": "Bancroft",
    "lng": -84.06018164085732,
    "lat": 42.88245051656242
  },
  {
    "name": "Bancroft",
    "lng": -81.84176220630204,
    "lat": 38.510680882192624
  },
  {
    "name": "Baneberry",
    "lng": -83.27826715080423,
    "lat": 36.04629277742014
  },
  {
    "name": "Bangor",
    "lng": -90.99337372716116,
    "lat": 43.892885324737
  },
  {
    "name": "Bangor",
    "lng": -90.9809088125882,
    "lat": 43.88740834322
  },
  {
    "name": "Bangor",
    "lng": -90.97628008785625,
    "lat": 43.88833999659161
  },
  {
    "name": "Bangor",
    "lng": -90.97622792848358,
    "lat": 43.89133083369815
  },
  {
    "name": "Bangor",
    "lng": -86.11328488118829,
    "lat": 42.31206764567084
  },
  {
    "name": "Bangor",
    "lng": -68.79068766807777,
    "lat": 44.83226349737645
  },
  {
    "name": "Banks",
    "lng": -92.26709384559061,
    "lat": 33.57588163115412
  },
  {
    "name": "Banks",
    "lng": -85.83643537079736,
    "lat": 31.81326508438318
  },
  {
    "name": "Bankston",
    "lng": -90.96212857197919,
    "lat": 42.509291088095836
  },
  {
    "name": "Bankston",
    "lng": -90.95875106336857,
    "lat": 42.501936718578676
  },
  {
    "name": "Banner",
    "lng": -89.90961403166101,
    "lat": 40.51382730675279
  },
  {
    "name": "Banner Elk",
    "lng": -81.87957291745123,
    "lat": 36.17145611105296
  },
  {
    "name": "Banner Elk",
    "lng": -81.86847533677783,
    "lat": 36.15851110233813
  },
  {
    "name": "Banner Elk",
    "lng": -81.86366409742635,
    "lat": 36.144616675770706
  },
  {
    "name": "Banner Elk",
    "lng": -81.86113504669106,
    "lat": 36.16852273346429
  },
  {
    "name": "Banner Elk",
    "lng": -81.86089431296014,
    "lat": 36.146154817764824
  },
  {
    "name": "Banner Elk",
    "lng": -81.8605967745274,
    "lat": 36.14276009206731
  },
  {
    "name": "Banner Elk",
    "lng": -81.85737693297162,
    "lat": 36.1442222311453
  },
  {
    "name": "Banner Elk",
    "lng": -81.85662171738242,
    "lat": 36.141385083452526
  },
  {
    "name": "Banner Elk",
    "lng": -81.85546668490043,
    "lat": 36.16973670647672
  },
  {
    "name": "Banning",
    "lng": -116.89906302768456,
    "lat": 33.94614700057122
  },
  {
    "name": "Bannockburn",
    "lng": -87.86965859494856,
    "lat": 42.19330404212077
  },
  {
    "name": "Bantry",
    "lng": -100.60975903976122,
    "lat": 48.49743629886859
  },
  {
    "name": "Bar Harbor",
    "lng": -68.26581515851844,
    "lat": 44.385628520157816
  },
  {
    "name": "Bar Nunn",
    "lng": -106.34742825806727,
    "lat": 42.92199543853662
  },
  {
    "name": "Baraboo",
    "lng": -89.76268159961162,
    "lat": 43.45289764989909
  },
  {
    "name": "Baraboo",
    "lng": -89.73734660666736,
    "lat": 43.46961000873307
  },
  {
    "name": "Barada",
    "lng": -95.57780268786803,
    "lat": 40.21846032224138
  },
  {
    "name": "Baraga",
    "lng": -88.49675049364949,
    "lat": 46.776456036835064
  },
  {
    "name": "Barberton",
    "lng": -81.6035933804161,
    "lat": 41.00955803137778
  },
  {
    "name": "Barbourmeade",
    "lng": -85.60074991740687,
    "lat": 38.29885671311604
  },
  {
    "name": "Barboursville",
    "lng": -82.29705571122024,
    "lat": 38.406193604885196
  },
  {
    "name": "Barbourville",
    "lng": -83.882968477474,
    "lat": 36.86531659461153
  },
  {
    "name": "Barbourville",
    "lng": -83.87502871777941,
    "lat": 36.85845948442962
  },
  {
    "name": "Barclay",
    "lng": -75.86387381241929,
    "lat": 39.14364060514654
  },
  {
    "name": "Bardolph",
    "lng": -90.56259358757276,
    "lat": 40.49631297357659
  },
  {
    "name": "Bardstown",
    "lng": -85.45828859296859,
    "lat": 37.81689400268866
  },
  {
    "name": "Bardwell",
    "lng": -96.69578592256845,
    "lat": 32.267256223079755
  },
  {
    "name": "Bardwell",
    "lng": -89.01014173838118,
    "lat": 36.873300882343386
  },
  {
    "name": "Bargersville",
    "lng": -86.20045592583027,
    "lat": 39.54123261425284
  },
  {
    "name": "Baring",
    "lng": -92.20587045745421,
    "lat": 40.24469581221733
  },
  {
    "name": "Barker",
    "lng": -78.55353497297126,
    "lat": 43.32832795647229
  },
  {
    "name": "Barkhamsted",
    "lng": -72.97294946087486,
    "lat": 41.93159308270661
  },
  {
    "name": "Barling",
    "lng": -94.27927930386363,
    "lat": 35.328383294045345
  },
  {
    "name": "Barlow",
    "lng": -89.04443416156819,
    "lat": 37.05116917434087
  },
  {
    "name": "Barnard",
    "lng": -98.04431765864004,
    "lat": 39.18916048421338
  },
  {
    "name": "Barnard",
    "lng": -94.82296913446444,
    "lat": 40.175281953237054
  },
  {
    "name": "Barnegat Light",
    "lng": -74.1083841707767,
    "lat": 39.75302359628123
  },
  {
    "name": "Barnes",
    "lng": -96.87310933046457,
    "lat": 39.71187911705948
  },
  {
    "name": "Barnes City",
    "lng": -92.46954343306615,
    "lat": 41.5069905679022
  },
  {
    "name": "Barneston",
    "lng": -96.58235399040824,
    "lat": 40.045107280320906
  },
  {
    "name": "Barneston",
    "lng": -96.572752472424,
    "lat": 40.04893741391396
  },
  {
    "name": "Barnesville",
    "lng": -96.41658055418843,
    "lat": 46.649888291609564
  },
  {
    "name": "Barnesville",
    "lng": -96.40756577835472,
    "lat": 46.66094618073917
  },
  {
    "name": "Barnesville",
    "lng": -84.18047537412488,
    "lat": 33.05084227211005
  },
  {
    "name": "Barnesville",
    "lng": -84.15236455111452,
    "lat": 33.051096253608954
  },
  {
    "name": "Barnesville",
    "lng": -81.17277164155875,
    "lat": 39.988793993058984
  },
  {
    "name": "Barnesville",
    "lng": -77.3798387871984,
    "lat": 39.22112756897663
  },
  {
    "name": "Barnett",
    "lng": -92.67457892726678,
    "lat": 38.377467330848745
  },
  {
    "name": "Barneveld",
    "lng": -89.89674322407465,
    "lat": 43.01173677373652
  },
  {
    "name": "Barneveld",
    "lng": -75.18912662966817,
    "lat": 43.273739652349185
  },
  {
    "name": "Barney",
    "lng": -96.99934180112112,
    "lat": 46.26586221349784
  },
  {
    "name": "Barnhill",
    "lng": -81.36790742167788,
    "lat": 40.44966272087901
  },
  {
    "name": "Barnsdall",
    "lng": -96.16344386119584,
    "lat": 36.56066660394582
  },
  {
    "name": "Barnsdall",
    "lng": -96.14708015817213,
    "lat": 36.560618056626055
  },
  {
    "name": "Barnum",
    "lng": -94.36485001933428,
    "lat": 42.50622377425079
  },
  {
    "name": "Barnum",
    "lng": -92.68838922608936,
    "lat": 46.50528768175381
  },
  {
    "name": "Barnwell",
    "lng": -81.36589030098611,
    "lat": 33.24193813017225
  },
  {
    "name": "Baroda",
    "lng": -86.48801948481558,
    "lat": 41.95475904144437
  },
  {
    "name": "Barrackville",
    "lng": -80.16973608143381,
    "lat": 39.50137934996329
  },
  {
    "name": "Barre",
    "lng": -72.10612441439882,
    "lat": 42.42011873708673
  },
  {
    "name": "Barre City",
    "lng": -72.50852576676056,
    "lat": 44.19976742037123
  },
  {
    "name": "Barrett",
    "lng": -95.8943976173385,
    "lat": 45.912404537354824
  },
  {
    "name": "Barrington",
    "lng": -88.10399694163725,
    "lat": 42.14841048253843
  },
  {
    "name": "Barrington",
    "lng": -71.32217086928371,
    "lat": 41.73954010687853
  },
  {
    "name": "Barrington",
    "lng": -71.32098441918316,
    "lat": 41.771412264560254
  },
  {
    "name": "Barrington",
    "lng": -71.31857900089463,
    "lat": 41.76543205052606
  },
  {
    "name": "Barrington",
    "lng": -71.31815348335219,
    "lat": 41.76279493821117
  },
  {
    "name": "Barrington",
    "lng": -71.3051467114,
    "lat": 41.741571064573606
  },
  {
    "name": "Barrington",
    "lng": -71.30135522541899,
    "lat": 41.75572706382251
  },
  {
    "name": "Barrington",
    "lng": -71.04225835228989,
    "lat": 43.2136836425262
  },
  {
    "name": "Barrington Hills",
    "lng": -88.21915548280575,
    "lat": 42.08921817764418
  },
  {
    "name": "Barrington Hills",
    "lng": -88.2048049870074,
    "lat": 42.140116918570016
  },
  {
    "name": "Barron",
    "lng": -91.8475195506193,
    "lat": 45.40151420672766
  },
  {
    "name": "Barry",
    "lng": -96.6377642555736,
    "lat": 32.09990183037325
  },
  {
    "name": "Barry",
    "lng": -96.56024791194163,
    "lat": 45.55827968695489
  },
  {
    "name": "Barry",
    "lng": -91.03979196575955,
    "lat": 39.69827200801565
  },
  {
    "name": "Barryton",
    "lng": -85.14361935233502,
    "lat": 43.75054597335413
  },
  {
    "name": "Barstow",
    "lng": -117.04706946761348,
    "lat": 34.865480809397724
  },
  {
    "name": "Barstow",
    "lng": -103.3955038997727,
    "lat": 31.461549477535065
  },
  {
    "name": "Bartelso",
    "lng": -89.46735392300373,
    "lat": 38.53702902032234
  },
  {
    "name": "Bartlesville",
    "lng": -96.00981124983667,
    "lat": 36.74948058969561
  },
  {
    "name": "Bartlesville",
    "lng": -95.94505984021536,
    "lat": 36.73553694261537
  },
  {
    "name": "Bartlett",
    "lng": -98.55256468122026,
    "lat": 41.88393257259449
  },
  {
    "name": "Bartlett",
    "lng": -97.43237661329734,
    "lat": 30.795198299550083
  },
  {
    "name": "Bartlett",
    "lng": -95.21120805189547,
    "lat": 37.05493812507062
  },
  {
    "name": "Bartlett",
    "lng": -89.8251146699751,
    "lat": 35.22996379944513
  },
  {
    "name": "Bartlett",
    "lng": -88.25621158356573,
    "lat": 41.9931059557438
  },
  {
    "name": "Bartlett",
    "lng": -88.19981464797213,
    "lat": 41.97809266087318
  },
  {
    "name": "Bartlett",
    "lng": -88.17844015770984,
    "lat": 41.97284692516024
  },
  {
    "name": "Bartley",
    "lng": -100.30972480487125,
    "lat": 40.252405170154255
  },
  {
    "name": "Barton",
    "lng": -79.01705088816587,
    "lat": 39.53236548772171
  },
  {
    "name": "Barton",
    "lng": -72.17643415778787,
    "lat": 44.747697474133446
  },
  {
    "name": "Barton Hills",
    "lng": -83.7557826316631,
    "lat": 42.316997173702894
  },
  {
    "name": "Bartonville",
    "lng": -97.15046288011526,
    "lat": 33.08052639804474
  },
  {
    "name": "Bartonville",
    "lng": -89.66087788893189,
    "lat": 40.63972430028819
  },
  {
    "name": "Bartow",
    "lng": -82.47116058202047,
    "lat": 32.88157455420211
  },
  {
    "name": "Barwick",
    "lng": -83.73928262338634,
    "lat": 30.892920215703526
  },
  {
    "name": "Basalt",
    "lng": -112.16504369852863,
    "lat": 43.314443455314056
  },
  {
    "name": "Basalt",
    "lng": -107.07864327724802,
    "lat": 39.37774913385841
  },
  {
    "name": "Basalt",
    "lng": -107.07846481567337,
    "lat": 39.37745260182375
  },
  {
    "name": "Basalt",
    "lng": -107.0271068968469,
    "lat": 39.362314839047016
  },
  {
    "name": "Basalt",
    "lng": -107.02702534260399,
    "lat": 39.36226269413907
  },
  {
    "name": "Basco",
    "lng": -91.1994949432226,
    "lat": 40.32780096921895
  },
  {
    "name": "Basecamp F",
    "lng": -81.14175242876662,
    "lat": 37.925882530259756
  },
  {
    "name": "Basehor",
    "lng": -94.96246614889641,
    "lat": 39.11008383022093
  },
  {
    "name": "Basehor",
    "lng": -94.93475093143545,
    "lat": 39.12955059582374
  },
  {
    "name": "Basehor",
    "lng": -94.91000518513324,
    "lat": 39.16505132786216
  },
  {
    "name": "Basile",
    "lng": -92.6010199392159,
    "lat": 30.485510961875985
  },
  {
    "name": "Basin",
    "lng": -108.06138436379375,
    "lat": 44.387947513770555
  },
  {
    "name": "Basin",
    "lng": -108.05061765080545,
    "lat": 44.38603400005652
  },
  {
    "name": "Basin",
    "lng": -108.04550929531729,
    "lat": 44.379902729943844
  },
  {
    "name": "Baskin",
    "lng": -91.74733030611318,
    "lat": 32.259069166512354
  },
  {
    "name": "Bassett",
    "lng": -99.53660819878093,
    "lat": 42.58252825926132
  },
  {
    "name": "Bassett",
    "lng": -95.4075832932057,
    "lat": 37.90619821015303
  },
  {
    "name": "Bassett",
    "lng": -95.40436476012972,
    "lat": 37.909806423617795
  },
  {
    "name": "Bassett",
    "lng": -92.51527887204813,
    "lat": 43.06152216333273
  },
  {
    "name": "Bassett",
    "lng": -90.1294324323731,
    "lat": 35.53557406441883
  },
  {
    "name": "Bassfield",
    "lng": -89.74557654375138,
    "lat": 31.495912853516124
  },
  {
    "name": "Bastrop",
    "lng": -97.32338718256756,
    "lat": 30.11120414098824
  },
  {
    "name": "Bastrop",
    "lng": -91.90575557910856,
    "lat": 32.77496320628612
  },
  {
    "name": "Batavia",
    "lng": -92.16728501856103,
    "lat": 40.9950155835815
  },
  {
    "name": "Batavia",
    "lng": -88.31094140985516,
    "lat": 41.8479244300917
  },
  {
    "name": "Batavia",
    "lng": -84.18118047545947,
    "lat": 39.078645924368864
  },
  {
    "name": "Batavia",
    "lng": -78.18023120169816,
    "lat": 42.99871016523429
  },
  {
    "name": "Batchtown",
    "lng": -90.65336620076167,
    "lat": 39.03319645086395
  },
  {
    "name": "Bates City",
    "lng": -94.06775182859828,
    "lat": 39.004647526919726
  },
  {
    "name": "Batesburg-Leesville",
    "lng": -81.5333168960616,
    "lat": 33.91174681752615
  },
  {
    "name": "Batesburg-Leesville",
    "lng": -81.48541580992728,
    "lat": 33.92549809564124
  },
  {
    "name": "Batesland",
    "lng": -102.10177987519823,
    "lat": 43.127836289585325
  },
  {
    "name": "Batesville",
    "lng": -91.66869297642052,
    "lat": 35.779375979167746
  },
  {
    "name": "Batesville",
    "lng": -91.65137870431192,
    "lat": 35.78886764126587
  },
  {
    "name": "Batesville",
    "lng": -91.62251121698841,
    "lat": 35.768720321095905
  },
  {
    "name": "Batesville",
    "lng": -89.9250895017997,
    "lat": 34.3147254234205
  },
  {
    "name": "Batesville",
    "lng": -85.21560810771284,
    "lat": 39.29760410305399
  },
  {
    "name": "Batesville",
    "lng": -85.20272470133656,
    "lat": 39.30247627427961
  },
  {
    "name": "Batesville",
    "lng": -81.28251188070453,
    "lat": 39.913705274465194
  },
  {
    "name": "Bath",
    "lng": -90.14254200871979,
    "lat": 40.19051795709024
  },
  {
    "name": "Bath",
    "lng": -77.31791370593045,
    "lat": 42.33622366191217
  },
  {
    "name": "Bath",
    "lng": -76.8119667308037,
    "lat": 35.47018099368389
  },
  {
    "name": "Bath",
    "lng": -69.83463534161795,
    "lat": 43.934570157857046
  },
  {
    "name": "Bath (Berkeley Springs)",
    "lng": -78.22732994265955,
    "lat": 39.625525720910794
  },
  {
    "name": "Bathgate",
    "lng": -97.47365515067547,
    "lat": 48.87994214277001
  },
  {
    "name": "Baton Rouge",
    "lng": -91.13080051381415,
    "lat": 30.442053245116938
  },
  {
    "name": "Battle Creek",
    "lng": -97.5994023356642,
    "lat": 41.99799765580352
  },
  {
    "name": "Battle Creek",
    "lng": -95.60009101293251,
    "lat": 42.31709490724054
  },
  {
    "name": "Battle Creek",
    "lng": -85.23076324372397,
    "lat": 42.297948246805376
  },
  {
    "name": "Battle Creek",
    "lng": -85.14336095404497,
    "lat": 42.34395911816206
  },
  {
    "name": "Battle Ground",
    "lng": -86.85335659953225,
    "lat": 40.507051949955375
  },
  {
    "name": "Battle Lake",
    "lng": -95.73508198645568,
    "lat": 46.27848182257248
  },
  {
    "name": "Battle Lake",
    "lng": -95.71424854332614,
    "lat": 46.28627568436526
  },
  {
    "name": "Battle Mountain",
    "lng": -116.93880372430897,
    "lat": 40.641714431218844
  },
  {
    "name": "Battlefield",
    "lng": -93.36820600878318,
    "lat": 37.11906761292925
  },
  {
    "name": "Baudette",
    "lng": -94.5927850346521,
    "lat": 48.71119536411641
  },
  {
    "name": "Bauxite",
    "lng": -92.49901911140837,
    "lat": 34.5602476745217
  },
  {
    "name": "Baxley",
    "lng": -82.35100979708994,
    "lat": 31.76383908021023
  },
  {
    "name": "Baxter",
    "lng": -94.27945938334148,
    "lat": 46.342572808999414
  },
  {
    "name": "Baxter",
    "lng": -94.25765800112688,
    "lat": 46.386999468231785
  },
  {
    "name": "Baxter",
    "lng": -93.15224107258709,
    "lat": 41.82515287628622
  },
  {
    "name": "Baxter",
    "lng": -85.63699911649186,
    "lat": 36.155796142368715
  },
  {
    "name": "Baxter Estates",
    "lng": -73.69470803726027,
    "lat": 40.83376686261004
  },
  {
    "name": "Baxter Springs",
    "lng": -94.7482547454927,
    "lat": 37.01830641881326
  },
  {
    "name": "Baxter Springs",
    "lng": -94.7348910799334,
    "lat": 37.019577042436175
  },
  {
    "name": "Bay",
    "lng": -90.55579990129924,
    "lat": 35.718508989945526
  },
  {
    "name": "Bay",
    "lng": -90.55479146163314,
    "lat": 35.7462059043138
  },
  {
    "name": "Bay City",
    "lng": -95.96035952421525,
    "lat": 28.9816031124826
  },
  {
    "name": "Bay City",
    "lng": -92.45393910782832,
    "lat": 44.586240174149026
  },
  {
    "name": "Bay City",
    "lng": -83.88807716035636,
    "lat": 43.59069110617865
  },
  {
    "name": "Bay Harbor Islands",
    "lng": -80.1347983523672,
    "lat": 25.88786867355003
  },
  {
    "name": "Bay Minette",
    "lng": -87.77743455564142,
    "lat": 30.882806702004437
  },
  {
    "name": "Bay Saint Louis",
    "lng": -89.35516845862902,
    "lat": 30.3194121192865
  },
  {
    "name": "Bay Springs",
    "lng": -89.27918310791016,
    "lat": 31.977328051595318
  },
  {
    "name": "Bay View",
    "lng": -82.82496429601244,
    "lat": 41.46849702582992
  },
  {
    "name": "Bay View Gardens",
    "lng": -89.52721320447485,
    "lat": 40.81020057383546
  },
  {
    "name": "Bay Village",
    "lng": -81.92869116390875,
    "lat": 41.488634140159384
  },
  {
    "name": "Bayard",
    "lng": -108.13363595556773,
    "lat": 32.759917217179726
  },
  {
    "name": "Bayard",
    "lng": -103.3230160208552,
    "lat": 41.757191676337925
  },
  {
    "name": "Bayard",
    "lng": -94.55868289031378,
    "lat": 41.852191209656
  },
  {
    "name": "Bayard",
    "lng": -94.55095830002846,
    "lat": 41.84839724494906
  },
  {
    "name": "Bayard",
    "lng": -79.366248309484,
    "lat": 39.27094576736215
  },
  {
    "name": "Bayboro",
    "lng": -76.7684054793051,
    "lat": 35.14422839802593
  },
  {
    "name": "Bayfield",
    "lng": -107.60237531464308,
    "lat": 37.24251534061702
  },
  {
    "name": "Bayfield",
    "lng": -107.59666356228045,
    "lat": 37.231151759742225
  },
  {
    "name": "Bayfield",
    "lng": -107.59544571701373,
    "lat": 37.23405956346998
  },
  {
    "name": "Bayfield",
    "lng": -107.58431198001455,
    "lat": 37.23385484213426
  },
  {
    "name": "Bayfield",
    "lng": -90.82255975094785,
    "lat": 46.81404445633159
  },
  {
    "name": "Baylis",
    "lng": -90.90946290822382,
    "lat": 39.72914152191178
  },
  {
    "name": "Bayonne",
    "lng": -74.10927068103514,
    "lat": 40.66491154674895
  },
  {
    "name": "Bayou La Batre",
    "lng": -88.26429674525187,
    "lat": 30.44257085133133
  },
  {
    "name": "Bayou La Batre",
    "lng": -88.26169636288135,
    "lat": 30.41343663725572
  },
  {
    "name": "Bayou La Batre",
    "lng": -88.26117569664882,
    "lat": 30.41311377971126
  },
  {
    "name": "Bayou La Batre",
    "lng": -88.26085624299348,
    "lat": 30.391883357116104
  },
  {
    "name": "Bayou La Batre",
    "lng": -88.23777837022988,
    "lat": 30.425715068339127
  },
  {
    "name": "Bayou Vista",
    "lng": -94.93906203298879,
    "lat": 29.325703185599526
  },
  {
    "name": "Bayport",
    "lng": -92.77919801301168,
    "lat": 45.01626995039271
  },
  {
    "name": "Bayside",
    "lng": -97.21102674645553,
    "lat": 28.096099713951297
  },
  {
    "name": "Bayside",
    "lng": -87.90115717900001,
    "lat": 43.18272427875864
  },
  {
    "name": "Baytown",
    "lng": -95.03162413783383,
    "lat": 29.794564073250843
  },
  {
    "name": "Baytown",
    "lng": -94.97804818240166,
    "lat": 29.804318507489437
  },
  {
    "name": "Baytown",
    "lng": -94.97350081017203,
    "lat": 29.80748782861027
  },
  {
    "name": "Baytown",
    "lng": -94.96920532676933,
    "lat": 29.751830922010313
  },
  {
    "name": "Baytown",
    "lng": -94.96771803986616,
    "lat": 29.806436308608184
  },
  {
    "name": "Baytown",
    "lng": -94.96602202365229,
    "lat": 29.811828163310107
  },
  {
    "name": "Baytown",
    "lng": -94.96330245477026,
    "lat": 29.805623093522193
  },
  {
    "name": "Baytown",
    "lng": -94.95015368024482,
    "lat": 29.81485233437246
  },
  {
    "name": "Baytown",
    "lng": -94.93596179674661,
    "lat": 29.821266252571387
  },
  {
    "name": "Baytown",
    "lng": -94.93272170243121,
    "lat": 29.716669361031624
  },
  {
    "name": "Baytown",
    "lng": -94.92812779520798,
    "lat": 29.8094131523084
  },
  {
    "name": "Bayview",
    "lng": -97.39724580117019,
    "lat": 26.132923597033475
  },
  {
    "name": "Bayville",
    "lng": -73.56079155470397,
    "lat": 40.92823717062236
  },
  {
    "name": "Bazile Mills",
    "lng": -97.90832924114873,
    "lat": 42.5124364441483
  },
  {
    "name": "Bazine",
    "lng": -99.69274073586051,
    "lat": 38.446060977352644
  },
  {
    "name": "Beach",
    "lng": -104.00771385807563,
    "lat": 46.914871180983276
  },
  {
    "name": "Beach City",
    "lng": -94.85478146459458,
    "lat": 29.727261877754557
  },
  {
    "name": "Beach City",
    "lng": -81.57973169609116,
    "lat": 40.653131783402515
  },
  {
    "name": "Beach Park",
    "lng": -87.8583903434257,
    "lat": 42.42615796668993
  },
  {
    "name": "Beach Park",
    "lng": -87.85368618213418,
    "lat": 42.42068288523398
  },
  {
    "name": "Beachwood",
    "lng": -81.50280194177193,
    "lat": 41.47600469302963
  },
  {
    "name": "Beacon",
    "lng": -92.68104128421318,
    "lat": 41.27610013993835
  },
  {
    "name": "Beacon",
    "lng": -73.96543592693416,
    "lat": 41.5035861284543
  },
  {
    "name": "Beaconsfield",
    "lng": -94.05072905018945,
    "lat": 40.807427795221315
  },
  {
    "name": "Beallsville",
    "lng": -81.03539821044315,
    "lat": 39.848866505307505
  },
  {
    "name": "Beals",
    "lng": -67.60164069842014,
    "lat": 44.45047489717833
  },
  {
    "name": "Beaman",
    "lng": -92.82188017942153,
    "lat": 42.220462978018354
  },
  {
    "name": "Bean Station",
    "lng": -83.28660258684715,
    "lat": 36.33158353649753
  },
  {
    "name": "Bear Creek",
    "lng": -97.9400155297344,
    "lat": 30.182381615865275
  },
  {
    "name": "Bear Creek",
    "lng": -88.72754806803992,
    "lat": 44.53133474704439
  },
  {
    "name": "Bear Creek",
    "lng": -87.71660795466305,
    "lat": 34.26521408589024
  },
  {
    "name": "Bear Grass",
    "lng": -77.12909826335891,
    "lat": 35.76604819401935
  },
  {
    "name": "Bear Lake",
    "lng": -86.14537815771553,
    "lat": 44.42030087685259
  },
  {
    "name": "Bear Lake",
    "lng": -79.50124106274397,
    "lat": 41.99340752732042
  },
  {
    "name": "Bear River",
    "lng": -111.02699401149128,
    "lat": 41.38585635793973
  },
  {
    "name": "Bear River City",
    "lng": -112.12504085941963,
    "lat": 41.612393039036476
  },
  {
    "name": "Bearcreek",
    "lng": -109.15768441189213,
    "lat": 45.16060333875868
  },
  {
    "name": "Bearden",
    "lng": -96.38523000156461,
    "lat": 35.35676967640791
  },
  {
    "name": "Bearden",
    "lng": -92.6187895788145,
    "lat": 33.727676450735146
  },
  {
    "name": "Beardsley",
    "lng": -96.71402294201921,
    "lat": 45.557657082675306
  },
  {
    "name": "Beardstown",
    "lng": -90.41782628598442,
    "lat": 39.99930434589508
  },
  {
    "name": "Beasley",
    "lng": -95.91492882686049,
    "lat": 29.49528569851287
  },
  {
    "name": "Beatrice",
    "lng": -96.80473657147662,
    "lat": 40.25404068469612
  },
  {
    "name": "Beatrice",
    "lng": -96.78853908225024,
    "lat": 40.27590614233396
  },
  {
    "name": "Beatrice",
    "lng": -96.7641881974638,
    "lat": 40.249591210704374
  },
  {
    "name": "Beatrice",
    "lng": -96.74573053078018,
    "lat": 40.27144694574639
  },
  {
    "name": "Beatrice",
    "lng": -96.74013350559429,
    "lat": 40.22914059893183
  },
  {
    "name": "Beatrice",
    "lng": -96.73427989599286,
    "lat": 40.231571524659
  },
  {
    "name": "Beatrice",
    "lng": -87.20904646288827,
    "lat": 31.733310324676527
  },
  {
    "name": "Beattie",
    "lng": -96.41777004857043,
    "lat": 39.862365149612984
  },
  {
    "name": "Beattyville",
    "lng": -83.70648867211668,
    "lat": 37.58387820262451
  },
  {
    "name": "Beaufort",
    "lng": -80.82212325213077,
    "lat": 32.515861250152035
  },
  {
    "name": "Beaufort",
    "lng": -80.82199764996754,
    "lat": 32.50701705083387
  },
  {
    "name": "Beaufort",
    "lng": -80.81735104389037,
    "lat": 32.51469156719011
  },
  {
    "name": "Beaufort",
    "lng": -80.81656312179182,
    "lat": 32.50668770283835
  },
  {
    "name": "Beaufort",
    "lng": -80.81144348222365,
    "lat": 32.505837467101685
  },
  {
    "name": "Beaufort",
    "lng": -80.80889556488225,
    "lat": 32.47586171036124
  },
  {
    "name": "Beaufort",
    "lng": -80.80846777053793,
    "lat": 32.48364486812484
  },
  {
    "name": "Beaufort",
    "lng": -80.80823406890863,
    "lat": 32.50615204806068
  },
  {
    "name": "Beaufort",
    "lng": -80.80611905316177,
    "lat": 32.483900646886866
  },
  {
    "name": "Beaufort",
    "lng": -80.8052926341032,
    "lat": 32.517695752502846
  },
  {
    "name": "Beaufort",
    "lng": -80.80475165943865,
    "lat": 32.503814293738614
  },
  {
    "name": "Beaufort",
    "lng": -80.80420529243041,
    "lat": 32.519983745163366
  },
  {
    "name": "Beaufort",
    "lng": -80.80330563188156,
    "lat": 32.51549686532796
  },
  {
    "name": "Beaufort",
    "lng": -80.80204264527936,
    "lat": 32.513068634886174
  },
  {
    "name": "Beaufort",
    "lng": -80.79916843338565,
    "lat": 32.512821889842954
  },
  {
    "name": "Beaufort",
    "lng": -80.79806369319587,
    "lat": 32.48669385684199
  },
  {
    "name": "Beaufort",
    "lng": -80.79713547738668,
    "lat": 32.484379260715
  },
  {
    "name": "Beaufort",
    "lng": -80.79669890709035,
    "lat": 32.48546114394915
  },
  {
    "name": "Beaufort",
    "lng": -80.79650113687333,
    "lat": 32.491522039987345
  },
  {
    "name": "Beaufort",
    "lng": -80.7950227496441,
    "lat": 32.483885561913915
  },
  {
    "name": "Beaufort",
    "lng": -80.79472360718889,
    "lat": 32.50466096242391
  },
  {
    "name": "Beaufort",
    "lng": -80.79283763847003,
    "lat": 32.51896680733518
  },
  {
    "name": "Beaufort",
    "lng": -80.78805874694672,
    "lat": 32.49713132007243
  },
  {
    "name": "Beaufort",
    "lng": -80.73285491224134,
    "lat": 32.45239426975908
  },
  {
    "name": "Beaufort",
    "lng": -80.72741064435209,
    "lat": 32.417557131449655
  },
  {
    "name": "Beaufort",
    "lng": -80.70405499100485,
    "lat": 32.44457697077928
  },
  {
    "name": "Beaufort",
    "lng": -80.6898297449604,
    "lat": 32.44546370684996
  },
  {
    "name": "Beaufort",
    "lng": -80.66191346750693,
    "lat": 32.41937078400042
  },
  {
    "name": "Beaufort",
    "lng": -80.65748729542018,
    "lat": 32.413828563843836
  },
  {
    "name": "Beaufort",
    "lng": -80.6491735725443,
    "lat": 32.41464442439144
  },
  {
    "name": "Beaufort",
    "lng": -80.64162278377952,
    "lat": 32.41470221564485
  },
  {
    "name": "Beaufort",
    "lng": -80.62724147077603,
    "lat": 32.39833941259168
  },
  {
    "name": "Beaufort",
    "lng": -76.67235450222053,
    "lat": 34.71651361605999
  },
  {
    "name": "Beaufort",
    "lng": -76.6529203302397,
    "lat": 34.720882112858376
  },
  {
    "name": "Beaufort",
    "lng": -76.65067056832979,
    "lat": 34.73427804484258
  },
  {
    "name": "Beaufort",
    "lng": -76.64233946745793,
    "lat": 34.761824727047994
  },
  {
    "name": "Beaumont",
    "lng": -116.97569640557508,
    "lat": 33.904339421761996
  },
  {
    "name": "Beaumont",
    "lng": -94.14512858610102,
    "lat": 30.08520434471848
  },
  {
    "name": "Beauregard",
    "lng": -90.38592324184137,
    "lat": 31.72103326271234
  },
  {
    "name": "Beaux Arts Village",
    "lng": -122.20088481583971,
    "lat": 47.58534372422322
  },
  {
    "name": "Beaver",
    "lng": -112.63822382134268,
    "lat": 38.27597088099236
  },
  {
    "name": "Beaver",
    "lng": -100.52357712417064,
    "lat": 36.815023302390735
  },
  {
    "name": "Beaver",
    "lng": -94.13999019509944,
    "lat": 42.03826129094029
  },
  {
    "name": "Beaver",
    "lng": -93.77265289789027,
    "lat": 36.475207757660975
  },
  {
    "name": "Beaver",
    "lng": -82.82525378297386,
    "lat": 39.03192432059999
  },
  {
    "name": "Beaver Bay",
    "lng": -91.30617361702532,
    "lat": 47.25617265078454
  },
  {
    "name": "Beaver City",
    "lng": -99.82755378976378,
    "lat": 40.138145271863124
  },
  {
    "name": "Beaver Cove",
    "lng": -69.472858135268,
    "lat": 45.5596511475512
  },
  {
    "name": "Beaver Creek",
    "lng": -106.51604821773094,
    "lat": 39.605240618901455
  },
  {
    "name": "Beaver Creek",
    "lng": -96.36227532575076,
    "lat": 43.612618595540376
  },
  {
    "name": "Beaver Crossing",
    "lng": -97.2824468996402,
    "lat": 40.7782204220527
  },
  {
    "name": "Beaver Dam",
    "lng": -88.83071488409496,
    "lat": 43.46897711457706
  },
  {
    "name": "Beaver Dam",
    "lng": -86.87788461210486,
    "lat": 37.40891905830148
  },
  {
    "name": "Beavercreek",
    "lng": -84.06179035754676,
    "lat": 39.73271865140548
  },
  {
    "name": "Beaverdam",
    "lng": -83.9737981838313,
    "lat": 40.83279906114135
  },
  {
    "name": "Beaverton",
    "lng": -88.02186139478714,
    "lat": 33.935410973428006
  },
  {
    "name": "Beaverton",
    "lng": -84.48853156738527,
    "lat": 43.88114434514074
  },
  {
    "name": "Beaverville",
    "lng": -87.65465986882259,
    "lat": 40.95350050557095
  },
  {
    "name": "Bechtelsville",
    "lng": -75.63081864144843,
    "lat": 40.37108662107652
  },
  {
    "name": "Beckemeyer",
    "lng": -89.43227132213661,
    "lat": 38.605801357959244
  },
  {
    "name": "Becker",
    "lng": -93.90474727495044,
    "lat": 45.40487565792947
  },
  {
    "name": "Becker",
    "lng": -93.87346716391437,
    "lat": 45.38071753871097
  },
  {
    "name": "Becket",
    "lng": -73.08327401624182,
    "lat": 42.28498945272029
  },
  {
    "name": "Beckley",
    "lng": -81.18412521299435,
    "lat": 37.78762273849735
  },
  {
    "name": "Beckville",
    "lng": -94.45608693818426,
    "lat": 32.243602214300815
  },
  {
    "name": "Beddington",
    "lng": -68.00902503839967,
    "lat": 44.813955165699724
  },
  {
    "name": "Bedford",
    "lng": -97.13500846147926,
    "lat": 32.84639215324379
  },
  {
    "name": "Bedford",
    "lng": -94.72391644100553,
    "lat": 40.67113884138071
  },
  {
    "name": "Bedford",
    "lng": -86.48948353198008,
    "lat": 38.860320097467905
  },
  {
    "name": "Bedford",
    "lng": -85.3207435,
    "lat": 38.58233650026773
  },
  {
    "name": "Bedford",
    "lng": -85.32032062081457,
    "lat": 38.58332210385166
  },
  {
    "name": "Bedford",
    "lng": -85.31940151674513,
    "lat": 38.58245994550416
  },
  {
    "name": "Bedford",
    "lng": -85.31793975902724,
    "lat": 38.59644687854508
  },
  {
    "name": "Bedford",
    "lng": -81.53591507194965,
    "lat": 41.3918506569623
  },
  {
    "name": "Bedford",
    "lng": -79.5178679475236,
    "lat": 37.33602106366892
  },
  {
    "name": "Bedford",
    "lng": -78.50333541542449,
    "lat": 40.01487873094746
  },
  {
    "name": "Bedford",
    "lng": -71.5305533303562,
    "lat": 42.94083265057914
  },
  {
    "name": "Bedford",
    "lng": -71.2782933499356,
    "lat": 42.496948584083945
  },
  {
    "name": "Bedford Heights",
    "lng": -81.50529256098719,
    "lat": 41.40423636438533
  },
  {
    "name": "Bedford Park",
    "lng": -87.79156491749742,
    "lat": 41.76698581154661
  },
  {
    "name": "Bedias",
    "lng": -95.94690368084107,
    "lat": 30.77919210792104
  },
  {
    "name": "Bee",
    "lng": -97.05826801390064,
    "lat": 41.006410088544705
  },
  {
    "name": "Bee Cave",
    "lng": -97.96066339069552,
    "lat": 30.30782676437317
  },
  {
    "name": "Beebe",
    "lng": -91.89971587175432,
    "lat": 35.07129717814684
  },
  {
    "name": "Beech Bottom",
    "lng": -80.65386979079706,
    "lat": 40.22626353922176
  },
  {
    "name": "Beech Grove",
    "lng": -86.08691172214631,
    "lat": 39.71502997862687
  },
  {
    "name": "Beech Mountain",
    "lng": -81.8883306400214,
    "lat": 36.21071813539455
  },
  {
    "name": "Beech Mountain",
    "lng": -81.87513718980766,
    "lat": 36.18528163892175
  },
  {
    "name": "Beech Mountain",
    "lng": -81.87252762594956,
    "lat": 36.18059198641541
  },
  {
    "name": "Beecher",
    "lng": -87.6321794405266,
    "lat": 41.355741910378256
  },
  {
    "name": "Beecher",
    "lng": -87.61660286012676,
    "lat": 41.34989265218819
  },
  {
    "name": "Beecher City",
    "lng": -88.78756204674018,
    "lat": 39.186651809336844
  },
  {
    "name": "Beechwood Village",
    "lng": -85.62961605026659,
    "lat": 38.25545479984674
  },
  {
    "name": "Beedeville",
    "lng": -91.1107325343239,
    "lat": 35.42918039411688
  },
  {
    "name": "Beemer",
    "lng": -96.80938326731699,
    "lat": 41.93012641195531
  },
  {
    "name": "Beemer",
    "lng": -96.80760357089686,
    "lat": 41.93767171331593
  },
  {
    "name": "Beersheba Springs",
    "lng": -85.6692431626204,
    "lat": 35.4588361975401
  },
  {
    "name": "Beeville",
    "lng": -97.74941041433344,
    "lat": 28.40588384673706
  },
  {
    "name": "Beggs",
    "lng": -96.05738188199697,
    "lat": 35.72946534373221
  },
  {
    "name": "Beggs",
    "lng": -96.0516796817707,
    "lat": 35.74121923006819
  },
  {
    "name": "Beggs",
    "lng": -96.04581912279015,
    "lat": 35.73588653168387
  },
  {
    "name": "Beggs",
    "lng": -96.0443128050179,
    "lat": 35.734431591751665
  },
  {
    "name": "Beggs",
    "lng": -96.04295242512558,
    "lat": 35.745193376133834
  },
  {
    "name": "Beggs",
    "lng": -96.04156211069484,
    "lat": 35.775411873335486
  },
  {
    "name": "Beggs",
    "lng": -96.04085335647362,
    "lat": 35.73205440434879
  },
  {
    "name": "Beggs",
    "lng": -96.02149117662971,
    "lat": 35.73522357799426
  },
  {
    "name": "Beggs",
    "lng": -96.01449782448267,
    "lat": 35.74966978926003
  },
  {
    "name": "Beggs",
    "lng": -96.01339291239204,
    "lat": 35.74353247674093
  },
  {
    "name": "Beggs",
    "lng": -96.00227928230471,
    "lat": 35.736175951953435
  },
  {
    "name": "Beggs",
    "lng": -95.95415099629548,
    "lat": 35.81583192588313
  },
  {
    "name": "Bejou",
    "lng": -95.97272466512752,
    "lat": 47.442921416006435
  },
  {
    "name": "Bel Air",
    "lng": -76.34654917144688,
    "lat": 39.53433268442487
  },
  {
    "name": "Bel Aire",
    "lng": -97.24546314990454,
    "lat": 37.77494242380371
  },
  {
    "name": "Bel-Nor",
    "lng": -90.31796418817933,
    "lat": 38.70165946078786
  },
  {
    "name": "Bel-Ridge",
    "lng": -90.32840125903525,
    "lat": 38.71297379322122
  },
  {
    "name": "Belcher",
    "lng": -93.8371670786491,
    "lat": 32.7521254429904
  },
  {
    "name": "Belchertown",
    "lng": -72.40038452858694,
    "lat": 42.27878854428464
  },
  {
    "name": "Belden",
    "lng": -97.20740528369484,
    "lat": 42.41171924530066
  },
  {
    "name": "Belding",
    "lng": -85.25765202689958,
    "lat": 43.09361935150062
  },
  {
    "name": "Belding",
    "lng": -85.23296995240175,
    "lat": 43.09680273152701
  },
  {
    "name": "Belen",
    "lng": -106.79946484087067,
    "lat": 34.66445625026005
  },
  {
    "name": "Belen",
    "lng": -106.79894614632238,
    "lat": 34.66627783870036
  },
  {
    "name": "Belen",
    "lng": -106.79628826986074,
    "lat": 34.729855956958005
  },
  {
    "name": "Belen",
    "lng": -106.78484578589924,
    "lat": 34.65708230841682
  },
  {
    "name": "Belfast",
    "lng": -69.0274513124236,
    "lat": 44.4266700357451
  },
  {
    "name": "Belfield",
    "lng": -103.19859300219343,
    "lat": 46.88613197516639
  },
  {
    "name": "Belgium",
    "lng": -87.8474003096586,
    "lat": 43.50351105396775
  },
  {
    "name": "Belgium",
    "lng": -87.63280855321923,
    "lat": 40.06072825189511
  },
  {
    "name": "Belgrade",
    "lng": -111.18508932119387,
    "lat": 45.763082846251706
  },
  {
    "name": "Belgrade",
    "lng": -111.1823907904024,
    "lat": 45.7822340985718
  },
  {
    "name": "Belgrade",
    "lng": -111.1454943842078,
    "lat": 45.794227726022946
  },
  {
    "name": "Belgrade",
    "lng": -98.0677278197407,
    "lat": 41.470838031527634
  },
  {
    "name": "Belgrade",
    "lng": -94.99978653990996,
    "lat": 45.45067533829399
  },
  {
    "name": "Belgrade",
    "lng": -69.84187325336138,
    "lat": 44.490259030813526
  },
  {
    "name": "Belhaven",
    "lng": -76.62332697203217,
    "lat": 35.54241757248639
  },
  {
    "name": "Belington",
    "lng": -79.93832891110536,
    "lat": 39.02196492600602
  },
  {
    "name": "Belk",
    "lng": -87.92860753697659,
    "lat": 33.65165036056986
  },
  {
    "name": "Belknap",
    "lng": -88.94045937213603,
    "lat": 37.3224267322187
  },
  {
    "name": "Bell Buckle",
    "lng": -86.35286282718393,
    "lat": 35.58996322862939
  },
  {
    "name": "Bell Center",
    "lng": -90.82565440493896,
    "lat": 43.29160489189326
  },
  {
    "name": "Bell City",
    "lng": -89.81959023290983,
    "lat": 37.02377971208136
  },
  {
    "name": "Bell Gardens",
    "lng": -118.15505494431423,
    "lat": 33.966265214668134
  },
  {
    "name": "Bella Villa",
    "lng": -90.28543863381901,
    "lat": 38.543639608057234
  },
  {
    "name": "Bella Vista",
    "lng": -94.27087063030243,
    "lat": 36.46672293004494
  },
  {
    "name": "Bellaire",
    "lng": -95.46199287433679,
    "lat": 29.70414320784676
  },
  {
    "name": "Bellaire",
    "lng": -85.20574844473657,
    "lat": 44.973710189269624
  },
  {
    "name": "Bellaire",
    "lng": -80.74656905198752,
    "lat": 40.01720380142785
  },
  {
    "name": "Bellbrook",
    "lng": -84.08627260645193,
    "lat": 39.6384127893032
  },
  {
    "name": "Belle",
    "lng": -91.72186269325877,
    "lat": 38.28515222494475
  },
  {
    "name": "Belle",
    "lng": -81.53876350395954,
    "lat": 38.23378502663808
  },
  {
    "name": "Belle Center",
    "lng": -83.74520454497447,
    "lat": 40.508863573754574
  },
  {
    "name": "Belle Fourche",
    "lng": -103.86161107590979,
    "lat": 44.73582419440431
  },
  {
    "name": "Belle Fourche",
    "lng": -103.8550133541205,
    "lat": 44.65433428806569
  },
  {
    "name": "Belle Fourche",
    "lng": -103.83097250362962,
    "lat": 44.67291154078555
  },
  {
    "name": "Belle Haven",
    "lng": -75.82591136806465,
    "lat": 37.55557418054693
  },
  {
    "name": "Belle Isle",
    "lng": -81.3684877175631,
    "lat": 28.472493378281087
  },
  {
    "name": "Belle Isle",
    "lng": -81.36837576716837,
    "lat": 28.473068950966802
  },
  {
    "name": "Belle Isle",
    "lng": -81.3683679304212,
    "lat": 28.47171159025331
  },
  {
    "name": "Belle Isle",
    "lng": -81.36827688865962,
    "lat": 28.473820654042648
  },
  {
    "name": "Belle Isle",
    "lng": -81.36826640709863,
    "lat": 28.471023525469782
  },
  {
    "name": "Belle Isle",
    "lng": -81.36697322712777,
    "lat": 28.474093438684132
  },
  {
    "name": "Belle Isle",
    "lng": -81.36695755412822,
    "lat": 28.472701956839916
  },
  {
    "name": "Belle Isle",
    "lng": -81.36695350135078,
    "lat": 28.471731888454336
  },
  {
    "name": "Belle Isle",
    "lng": -81.36204571302719,
    "lat": 28.45976051134136
  },
  {
    "name": "Belle Isle",
    "lng": -81.35484553071608,
    "lat": 28.44992518745745
  },
  {
    "name": "Belle Isle",
    "lng": -81.35476931070123,
    "lat": 28.445816515163045
  },
  {
    "name": "Belle Isle",
    "lng": -81.34874865421882,
    "lat": 28.473566427748292
  },
  {
    "name": "Belle Meade",
    "lng": -86.85630100464493,
    "lat": 36.09934786433239
  },
  {
    "name": "Belle Plaine",
    "lng": -97.28284807624047,
    "lat": 37.38012985263137
  },
  {
    "name": "Belle Plaine",
    "lng": -97.2792875833049,
    "lat": 37.39514500543954
  },
  {
    "name": "Belle Plaine",
    "lng": -93.78114445549238,
    "lat": 44.60458242680907
  },
  {
    "name": "Belle Plaine",
    "lng": -93.76407042969696,
    "lat": 44.618960549127074
  },
  {
    "name": "Belle Plaine",
    "lng": -92.2753144696429,
    "lat": 41.895303779367964
  },
  {
    "name": "Belle Prairie City",
    "lng": -88.55574094830123,
    "lat": 38.22349031150847
  },
  {
    "name": "Belle Rive",
    "lng": -88.74048993572029,
    "lat": 38.23172558851476
  },
  {
    "name": "Belle Terre",
    "lng": -73.0670502562854,
    "lat": 40.9607675042943
  },
  {
    "name": "Belle Valley",
    "lng": -81.55653159131738,
    "lat": 39.78881919687102
  },
  {
    "name": "Belleair",
    "lng": -82.81179403047433,
    "lat": 27.936278949076105
  },
  {
    "name": "Belleair Beach",
    "lng": -82.8363736251089,
    "lat": 27.92426842630274
  },
  {
    "name": "Belleair Bluffs",
    "lng": -82.81947484407438,
    "lat": 27.919971112723562
  },
  {
    "name": "Bellechester",
    "lng": -92.5113249861356,
    "lat": 44.37100470081083
  },
  {
    "name": "Bellefontaine",
    "lng": -83.76413317636074,
    "lat": 40.36312217238202
  },
  {
    "name": "Bellefontaine Neighbors",
    "lng": -90.22801154184295,
    "lat": 38.75281744829452
  },
  {
    "name": "Bellefonte",
    "lng": -93.04704433238486,
    "lat": 36.199869076742324
  },
  {
    "name": "Bellefonte",
    "lng": -82.68940698239511,
    "lat": 38.49678239556334
  },
  {
    "name": "Bellefonte",
    "lng": -77.76841557828502,
    "lat": 40.91408460270476
  },
  {
    "name": "Bellemeade",
    "lng": -85.59107024472458,
    "lat": 38.25103495316509
  },
  {
    "name": "Bellerive",
    "lng": -90.3121239201391,
    "lat": 38.71227822577276
  },
  {
    "name": "Belleview",
    "lng": -82.05634283054252,
    "lat": 29.0612149833625
  },
  {
    "name": "Belleville",
    "lng": -97.65842610595311,
    "lat": 39.81882723427029
  },
  {
    "name": "Belleville",
    "lng": -97.64033138397572,
    "lat": 39.82879818206882
  },
  {
    "name": "Belleville",
    "lng": -97.6298921725541,
    "lat": 39.82400445722159
  },
  {
    "name": "Belleville",
    "lng": -93.44577404554747,
    "lat": 35.09151308615254
  },
  {
    "name": "Belleville",
    "lng": -89.98669401051546,
    "lat": 38.51501283076019
  },
  {
    "name": "Belleville",
    "lng": -89.91005050212631,
    "lat": 38.50309725816093
  },
  {
    "name": "Belleville",
    "lng": -89.54005794778067,
    "lat": 42.863807958165815
  },
  {
    "name": "Belleville",
    "lng": -83.48409123927811,
    "lat": 42.20208031238161
  },
  {
    "name": "Bellevue",
    "lng": -122.15507002891397,
    "lat": 47.59536448050041
  },
  {
    "name": "Bellevue",
    "lng": -114.25506437435486,
    "lat": 43.46841280972493
  },
  {
    "name": "Bellevue",
    "lng": -95.97386785265962,
    "lat": 41.14399698163831
  },
  {
    "name": "Bellevue",
    "lng": -95.9506857666651,
    "lat": 41.133205273806986
  },
  {
    "name": "Bellevue",
    "lng": -95.93305535578932,
    "lat": 41.15922505264019
  },
  {
    "name": "Bellevue",
    "lng": -90.43248650267,
    "lat": 42.262754277479075
  },
  {
    "name": "Bellevue",
    "lng": -89.67327068250788,
    "lat": 40.687665624751006
  },
  {
    "name": "Bellevue",
    "lng": -87.95527697167715,
    "lat": 44.459261483699954
  },
  {
    "name": "Bellevue",
    "lng": -85.01866469820237,
    "lat": 42.44430680878233
  },
  {
    "name": "Bellevue",
    "lng": -84.47765678362312,
    "lat": 39.10111285015545
  },
  {
    "name": "Bellevue",
    "lng": -82.84429140018496,
    "lat": 41.27475557512797
  },
  {
    "name": "Bellewood",
    "lng": -85.65920812788656,
    "lat": 38.260836575111014
  },
  {
    "name": "Bellflower",
    "lng": -118.12714339503215,
    "lat": 33.888035638849516
  },
  {
    "name": "Bellflower",
    "lng": -91.35198361556172,
    "lat": 39.0034564889234
  },
  {
    "name": "Bellflower",
    "lng": -88.52585146213738,
    "lat": 40.34065443983602
  },
  {
    "name": "Bellingham",
    "lng": -122.47051114781304,
    "lat": 48.75366726127055
  },
  {
    "name": "Bellingham",
    "lng": -96.28410154702958,
    "lat": 45.13643944237427
  },
  {
    "name": "Bellmead",
    "lng": -97.08974843305707,
    "lat": 31.60265878875671
  },
  {
    "name": "Bellmont",
    "lng": -87.9106092992534,
    "lat": 38.38303319685733
  },
  {
    "name": "Bellport",
    "lng": -72.94232187400183,
    "lat": 40.75458978586901
  },
  {
    "name": "Bells",
    "lng": -96.41277740936914,
    "lat": 33.616602471851735
  },
  {
    "name": "Bells",
    "lng": -89.08524132550471,
    "lat": 35.72061546848714
  },
  {
    "name": "Bellville",
    "lng": -96.25991599813409,
    "lat": 29.94722916991045
  },
  {
    "name": "Bellville",
    "lng": -96.24623863901422,
    "lat": 29.921449781247077
  },
  {
    "name": "Bellville",
    "lng": -96.24527811289808,
    "lat": 29.932790319273813
  },
  {
    "name": "Bellville",
    "lng": -82.5241278829011,
    "lat": 40.62953485564835
  },
  {
    "name": "Bellville",
    "lng": -81.97432297482368,
    "lat": 32.15266500658067
  },
  {
    "name": "Bellwood",
    "lng": -97.23999084791127,
    "lat": 41.342055633527245
  },
  {
    "name": "Bellwood",
    "lng": -87.87605188092192,
    "lat": 41.88295960472835
  },
  {
    "name": "Belmond",
    "lng": -93.608525180706,
    "lat": 42.84633264524914
  },
  {
    "name": "Belmont",
    "lng": -90.33455867282493,
    "lat": 42.73767644834669
  },
  {
    "name": "Belmont",
    "lng": -88.20796282401798,
    "lat": 34.50481817787765
  },
  {
    "name": "Belmont",
    "lng": -81.26332409508261,
    "lat": 39.37868639647557
  },
  {
    "name": "Belmont",
    "lng": -81.04945316349651,
    "lat": 35.27224403313624
  },
  {
    "name": "Belmont",
    "lng": -81.04540053011563,
    "lat": 35.27388258232257
  },
  {
    "name": "Belmont",
    "lng": -81.04093282298881,
    "lat": 40.027979540276355
  },
  {
    "name": "Belmont",
    "lng": -81.04046446539627,
    "lat": 35.23727189188892
  },
  {
    "name": "Belmont",
    "lng": -81.0244865152114,
    "lat": 35.212373652754444
  },
  {
    "name": "Belmont",
    "lng": -81.0232107085166,
    "lat": 35.20178734159474
  },
  {
    "name": "Belmont",
    "lng": -81.02258334001556,
    "lat": 35.15796624950645
  },
  {
    "name": "Belmont",
    "lng": -81.0208928640029,
    "lat": 35.183613155648494
  },
  {
    "name": "Belmont",
    "lng": -78.0327799362166,
    "lat": 42.22255452943166
  },
  {
    "name": "Belmont",
    "lng": -71.17957938687873,
    "lat": 42.396019869255
  },
  {
    "name": "Belmont",
    "lng": -69.11427975739085,
    "lat": 44.37951501366223
  },
  {
    "name": "Belmore",
    "lng": -83.94121901299187,
    "lat": 41.154866163604844
  },
  {
    "name": "Beloit",
    "lng": -98.10815051389798,
    "lat": 39.46448628485867
  },
  {
    "name": "Beloit",
    "lng": -89.05166317504163,
    "lat": 42.55790155365748
  },
  {
    "name": "Beloit",
    "lng": -89.01765849795989,
    "lat": 42.52245196462109
  },
  {
    "name": "Beloit",
    "lng": -89.01146688885555,
    "lat": 42.52680540217104
  },
  {
    "name": "Beloit",
    "lng": -80.99638812806853,
    "lat": 40.91950483840804
  },
  {
    "name": "Belpre",
    "lng": -99.09991337137981,
    "lat": 37.95048011185457
  },
  {
    "name": "Belpre",
    "lng": -81.59718102310262,
    "lat": 39.281464255670265
  },
  {
    "name": "Belt",
    "lng": -110.92780984207121,
    "lat": 47.38740608433805
  },
  {
    "name": "Belton",
    "lng": -97.47478448674613,
    "lat": 31.05785074696966
  },
  {
    "name": "Belton",
    "lng": -94.5332375935027,
    "lat": 38.81905252588071
  },
  {
    "name": "Belton",
    "lng": -82.49356820933417,
    "lat": 34.52449274399683
  },
  {
    "name": "Beltrami",
    "lng": -96.52706245944663,
    "lat": 47.542533775279864
  },
  {
    "name": "Belvidere",
    "lng": -101.27107451744043,
    "lat": 43.83077208890312
  },
  {
    "name": "Belvidere",
    "lng": -97.55770598802782,
    "lat": 40.25463496076446
  },
  {
    "name": "Belvidere",
    "lng": -88.86512777657923,
    "lat": 42.27885307069383
  },
  {
    "name": "Belvidere",
    "lng": -88.86494215310985,
    "lat": 42.25412146203242
  },
  {
    "name": "Belvidere",
    "lng": -75.07296928895285,
    "lat": 40.829488818609185
  },
  {
    "name": "Belview",
    "lng": -95.32887490832546,
    "lat": 44.60668226673601
  },
  {
    "name": "Belville",
    "lng": -78.0776236497163,
    "lat": 34.184949038108
  },
  {
    "name": "Belville",
    "lng": -78.02968096552891,
    "lat": 34.23647463916576
  },
  {
    "name": "Belville",
    "lng": -78.02695244735632,
    "lat": 34.21659296352018
  },
  {
    "name": "Belville",
    "lng": -78.01470211197453,
    "lat": 34.21369370183419
  },
  {
    "name": "Belville",
    "lng": -77.99087591438433,
    "lat": 34.22058524504388
  },
  {
    "name": "Belvue",
    "lng": -96.17886100437141,
    "lat": 39.21621794372227
  },
  {
    "name": "Belwood",
    "lng": -81.52274353669516,
    "lat": 35.47939557000089
  },
  {
    "name": "Belzoni",
    "lng": -90.486294021413,
    "lat": 33.180509753389344
  },
  {
    "name": "Bement",
    "lng": -88.57244694265489,
    "lat": 39.92285190046845
  },
  {
    "name": "Bemidji",
    "lng": -94.93714757998275,
    "lat": 47.50099782333271
  },
  {
    "name": "Bemidji",
    "lng": -94.9051020758369,
    "lat": 47.505699014325835
  },
  {
    "name": "Bemidji",
    "lng": -94.88912181649911,
    "lat": 47.45010296850677
  },
  {
    "name": "Bemidji",
    "lng": -94.87936306707695,
    "lat": 47.483034810790215
  },
  {
    "name": "Bemidji",
    "lng": -94.8778912500093,
    "lat": 47.44495630078029
  },
  {
    "name": "Bemidji",
    "lng": -94.87486146483057,
    "lat": 47.44511897963247
  },
  {
    "name": "Bemidji",
    "lng": -94.85686378229573,
    "lat": 47.427645668919325
  },
  {
    "name": "Bemidji",
    "lng": -94.81603072596609,
    "lat": 47.46963490754467
  },
  {
    "name": "Bemidji",
    "lng": -94.80268906443736,
    "lat": 47.471519813220254
  },
  {
    "name": "Bemus Point",
    "lng": -79.38936591567092,
    "lat": 42.16344587137831
  },
  {
    "name": "Ben Lomond",
    "lng": -94.1176632138822,
    "lat": 33.833541498651265
  },
  {
    "name": "Bena",
    "lng": -94.20600975034559,
    "lat": 47.34050366342932
  },
  {
    "name": "Benavides",
    "lng": -98.40953499711101,
    "lat": 27.598163460777858
  },
  {
    "name": "Benbrook",
    "lng": -97.46283548936393,
    "lat": 32.676688879555236
  },
  {
    "name": "Benders Landing",
    "lng": -95.345836516747,
    "lat": 30.090486855750964
  },
  {
    "name": "Benedict",
    "lng": -101.08434377021491,
    "lat": 47.83013896669335
  },
  {
    "name": "Benedict",
    "lng": -97.60732975074441,
    "lat": 41.006243289579906
  },
  {
    "name": "Benedict",
    "lng": -95.74348392200109,
    "lat": 37.62688408920661
  },
  {
    "name": "Benham",
    "lng": -82.95190259228364,
    "lat": 36.965014954682914
  },
  {
    "name": "Benkelman",
    "lng": -101.53539657947033,
    "lat": 40.05173208058075
  },
  {
    "name": "Benld",
    "lng": -89.80233355876429,
    "lat": 39.0929957496238
  },
  {
    "name": "Bennet",
    "lng": -96.50392644705175,
    "lat": 40.6814641777038
  },
  {
    "name": "Bennett",
    "lng": -104.43433665262756,
    "lat": 39.73683688658808
  },
  {
    "name": "Bennett",
    "lng": -104.42798808915522,
    "lat": 39.73675695637988
  },
  {
    "name": "Bennett",
    "lng": -90.9736052737074,
    "lat": 41.73976993838115
  },
  {
    "name": "Bennettsville",
    "lng": -79.68746305592732,
    "lat": 34.63036726992833
  },
  {
    "name": "Bennettsville",
    "lng": -79.66610050739772,
    "lat": 34.645002986863595
  },
  {
    "name": "Bennington",
    "lng": -97.59283354724678,
    "lat": 39.03337172218567
  },
  {
    "name": "Bennington",
    "lng": -96.16084789915219,
    "lat": 41.368280504070945
  },
  {
    "name": "Bennington",
    "lng": -96.03757642500153,
    "lat": 34.003798067057886
  },
  {
    "name": "Bennington",
    "lng": -71.90851688165684,
    "lat": 43.012431568054964
  },
  {
    "name": "Benoit",
    "lng": -91.00869785962092,
    "lat": 33.651694371855925
  },
  {
    "name": "Bensenville",
    "lng": -87.94328246533179,
    "lat": 41.958352710460694
  },
  {
    "name": "Benson",
    "lng": -95.6057554100204,
    "lat": 45.31523480885477
  },
  {
    "name": "Benson",
    "lng": -89.12110283842858,
    "lat": 40.8507172481771
  },
  {
    "name": "Benson",
    "lng": -78.55453746862227,
    "lat": 35.39932046274438
  },
  {
    "name": "Benson",
    "lng": -78.54618014970343,
    "lat": 35.381403458294315
  },
  {
    "name": "Benson",
    "lng": -78.54367034981048,
    "lat": 35.400087633897314
  },
  {
    "name": "Benson",
    "lng": -78.54011766459817,
    "lat": 35.4180822129843
  },
  {
    "name": "Benson",
    "lng": -78.53497506019632,
    "lat": 35.42250776379828
  },
  {
    "name": "Benson",
    "lng": -78.5343359946809,
    "lat": 35.42505518292694
  },
  {
    "name": "Benson",
    "lng": -78.50878156042235,
    "lat": 35.392333194047
  },
  {
    "name": "Benson",
    "lng": -78.50519541455293,
    "lat": 35.40922126958359
  },
  {
    "name": "Bentley",
    "lng": -97.51598477861259,
    "lat": 37.88624886229339
  },
  {
    "name": "Bentley",
    "lng": -91.11167488153745,
    "lat": 40.34384803689542
  },
  {
    "name": "Bentleyville",
    "lng": -81.4120880215108,
    "lat": 41.41217683530587
  },
  {
    "name": "Bentleyville",
    "lng": -80.00442054538117,
    "lat": 40.117849232342344
  },
  {
    "name": "Benton",
    "lng": -97.10973456622948,
    "lat": 37.791201612007306
  },
  {
    "name": "Benton",
    "lng": -94.36082218810839,
    "lat": 40.70466403521041
  },
  {
    "name": "Benton",
    "lng": -94.35307956313126,
    "lat": 40.711097351814075
  },
  {
    "name": "Benton",
    "lng": -93.7401535756051,
    "lat": 32.690640976243536
  },
  {
    "name": "Benton",
    "lng": -92.5716616727922,
    "lat": 34.57739665889445
  },
  {
    "name": "Benton",
    "lng": -90.38323665804296,
    "lat": 42.569949811640484
  },
  {
    "name": "Benton",
    "lng": -89.56304398977092,
    "lat": 37.098588463469156
  },
  {
    "name": "Benton",
    "lng": -88.91968344124568,
    "lat": 38.00230373631067
  },
  {
    "name": "Benton",
    "lng": -88.91944267686792,
    "lat": 38.026301962272186
  },
  {
    "name": "Benton",
    "lng": -88.91463348611752,
    "lat": 38.02819931239088
  },
  {
    "name": "Benton",
    "lng": -88.91097005022081,
    "lat": 38.06083129307932
  },
  {
    "name": "Benton",
    "lng": -88.90163123083548,
    "lat": 37.99502191504286
  },
  {
    "name": "Benton",
    "lng": -88.90111506933646,
    "lat": 37.99491539828231
  },
  {
    "name": "Benton",
    "lng": -88.89357987868304,
    "lat": 38.002630931868815
  },
  {
    "name": "Benton",
    "lng": -88.38252225231312,
    "lat": 36.859182198822694
  },
  {
    "name": "Benton",
    "lng": -88.35927315349046,
    "lat": 36.851607390225475
  },
  {
    "name": "Benton",
    "lng": -84.65181821112326,
    "lat": 35.1745951692888
  },
  {
    "name": "Benton",
    "lng": -69.51724854470493,
    "lat": 44.595051534625114
  },
  {
    "name": "Benton City",
    "lng": -119.47967335247613,
    "lat": 46.2619731287756
  },
  {
    "name": "Benton City",
    "lng": -91.76485938297316,
    "lat": 39.1345497573285
  },
  {
    "name": "Benton Harbor",
    "lng": -86.4472376869305,
    "lat": 42.116001159009635
  },
  {
    "name": "Benton Ridge",
    "lng": -83.79266494417651,
    "lat": 41.00423606699719
  },
  {
    "name": "Bentonia",
    "lng": -90.36919304251305,
    "lat": 32.64359787237949
  },
  {
    "name": "Bentonville",
    "lng": -94.28149515100495,
    "lat": 36.3023487364899
  },
  {
    "name": "Bentonville",
    "lng": -94.24773479488807,
    "lat": 36.31529027901926
  },
  {
    "name": "Bentonville",
    "lng": -94.2304299795609,
    "lat": 36.354888702972715
  },
  {
    "name": "Benwood",
    "lng": -80.73275562096796,
    "lat": 40.01400731570974
  },
  {
    "name": "Benzonia",
    "lng": -86.09904652536669,
    "lat": 44.615954651678564
  },
  {
    "name": "Benzonia",
    "lng": -86.09162632704171,
    "lat": 44.624499497453186
  },
  {
    "name": "Berea",
    "lng": -84.30608225615552,
    "lat": 37.620435585508005
  },
  {
    "name": "Berea",
    "lng": -84.28838321164598,
    "lat": 37.591018840126715
  },
  {
    "name": "Berea",
    "lng": -81.86445493284742,
    "lat": 41.36965377958672
  },
  {
    "name": "Beresford",
    "lng": -96.78048445901545,
    "lat": 43.07971024668203
  },
  {
    "name": "Bergen",
    "lng": -100.72003525028407,
    "lat": 48.003739364724915
  },
  {
    "name": "Bergen",
    "lng": -77.94250173086074,
    "lat": 43.082800574368015
  },
  {
    "name": "Berger",
    "lng": -91.33931403259976,
    "lat": 38.67167192302254
  },
  {
    "name": "Bergholz",
    "lng": -80.88505686712482,
    "lat": 40.520796302805195
  },
  {
    "name": "Bergman",
    "lng": -93.01152516318784,
    "lat": 36.31284442480231
  },
  {
    "name": "Berkeley",
    "lng": -122.29974678838708,
    "lat": 37.86657955620888
  },
  {
    "name": "Berkeley",
    "lng": -90.33609127053545,
    "lat": 38.743940489074944
  },
  {
    "name": "Berkeley",
    "lng": -87.91146631399478,
    "lat": 41.88910958955582
  },
  {
    "name": "Berkeley Lake",
    "lng": -84.18389512852842,
    "lat": 33.980524353320234
  },
  {
    "name": "Berkey",
    "lng": -83.8384229493387,
    "lat": 41.70828692161498
  },
  {
    "name": "Berkley",
    "lng": -94.11362055408293,
    "lat": 41.94654514234279
  },
  {
    "name": "Berkley",
    "lng": -83.18534628449325,
    "lat": 42.4986002946127
  },
  {
    "name": "Berlin",
    "lng": -98.48846871618649,
    "lat": 46.37829812199191
  },
  {
    "name": "Berlin",
    "lng": -89.90266377128577,
    "lat": 39.7576490515171
  },
  {
    "name": "Berlin",
    "lng": -83.62366729500263,
    "lat": 31.06784572269426
  },
  {
    "name": "Berlin",
    "lng": -72.77581892424008,
    "lat": 41.611382619503566
  },
  {
    "name": "Berlin",
    "lng": -71.63552123442334,
    "lat": 42.38378583289103
  },
  {
    "name": "Berlin Heights",
    "lng": -82.49005018352045,
    "lat": 41.32031415587875
  },
  {
    "name": "Bermuda Run",
    "lng": -80.44174959657128,
    "lat": 36.0091950621788
  },
  {
    "name": "Bermuda Run",
    "lng": -80.426888518681,
    "lat": 36.000952358305085
  },
  {
    "name": "Bern",
    "lng": -95.9711493007392,
    "lat": 39.96133604673897
  },
  {
    "name": "Bernalillo",
    "lng": -106.55349134785497,
    "lat": 35.31257287878946
  },
  {
    "name": "Bernard",
    "lng": -90.83173174749405,
    "lat": 42.31323757870967
  },
  {
    "name": "Bernardston",
    "lng": -72.54900606791455,
    "lat": 42.69163963802149
  },
  {
    "name": "Berne",
    "lng": -84.9557185329115,
    "lat": 40.65670062071242
  },
  {
    "name": "Bernice",
    "lng": -94.9108248528198,
    "lat": 36.622001627518266
  },
  {
    "name": "Bernice",
    "lng": -92.65852325940523,
    "lat": 32.82732282108638
  },
  {
    "name": "Bernie",
    "lng": -89.97057975435119,
    "lat": 36.6717199629871
  },
  {
    "name": "Berrien Springs",
    "lng": -86.34029668051141,
    "lat": 41.94743523416946
  },
  {
    "name": "Berry",
    "lng": -87.60876590698815,
    "lat": 33.663007660650955
  },
  {
    "name": "Berry",
    "lng": -84.38418805785632,
    "lat": 38.51978479974848
  },
  {
    "name": "Berry Hill",
    "lng": -86.76748768258312,
    "lat": 36.11960645059024
  },
  {
    "name": "Berrysburg",
    "lng": -76.80941023350333,
    "lat": 40.60308687544581
  },
  {
    "name": "Berryville",
    "lng": -95.46633744641127,
    "lat": 32.087597762724485
  },
  {
    "name": "Berryville",
    "lng": -95.44888936192233,
    "lat": 32.0917894687311
  },
  {
    "name": "Berryville",
    "lng": -93.57039775008546,
    "lat": 36.37127823698247
  },
  {
    "name": "Berryville",
    "lng": -77.9822943541893,
    "lat": 39.15078363999546
  },
  {
    "name": "Bertha",
    "lng": -95.06177466620764,
    "lat": 46.267100928403686
  },
  {
    "name": "Berthold",
    "lng": -101.73597510002237,
    "lat": 48.31525410009879
  },
  {
    "name": "Berthold",
    "lng": -101.72496001515684,
    "lat": 48.314759365741274
  },
  {
    "name": "Berthoud",
    "lng": -105.1214681224083,
    "lat": 40.32979978338471
  },
  {
    "name": "Berthoud",
    "lng": -105.10285951505578,
    "lat": 40.32605143514133
  },
  {
    "name": "Berthoud",
    "lng": -105.05096980121651,
    "lat": 40.297553712378466
  },
  {
    "name": "Berthoud",
    "lng": -105.03755495070394,
    "lat": 40.30643806165514
  },
  {
    "name": "Berthoud",
    "lng": -105.03640630782664,
    "lat": 40.3060217922906
  },
  {
    "name": "Bertram",
    "lng": -91.55089422409482,
    "lat": 41.947684826423654
  },
  {
    "name": "Bertram",
    "lng": -91.53616416317718,
    "lat": 41.95217901463976
  },
  {
    "name": "Bertrand",
    "lng": -99.63238539485047,
    "lat": 40.52629979455643
  },
  {
    "name": "Bertrand",
    "lng": -89.44914830746988,
    "lat": 36.909299444484255
  },
  {
    "name": "Berwick",
    "lng": -91.23774550084462,
    "lat": 29.701257325679652
  },
  {
    "name": "Berwick",
    "lng": -76.24926368466762,
    "lat": 41.05543846933452
  },
  {
    "name": "Berwick",
    "lng": -70.84394598534213,
    "lat": 43.300682169814316
  },
  {
    "name": "Berwyn",
    "lng": -99.5006092212734,
    "lat": 41.350853054252376
  },
  {
    "name": "Berwyn",
    "lng": -87.7909135085943,
    "lat": 41.84333257956593
  },
  {
    "name": "Bessemer",
    "lng": -90.04979577045667,
    "lat": 46.47748262729901
  },
  {
    "name": "Bessemer",
    "lng": -86.97141732142867,
    "lat": 33.371456110050595
  },
  {
    "name": "Bessemer",
    "lng": -86.91918828555342,
    "lat": 33.32348683502972
  },
  {
    "name": "Bessemer",
    "lng": -86.91720475006436,
    "lat": 33.38236360726915
  },
  {
    "name": "Bessemer",
    "lng": -86.87401953525624,
    "lat": 33.357316298360914
  },
  {
    "name": "Bessemer",
    "lng": -86.85822251245476,
    "lat": 33.379312486299476
  },
  {
    "name": "Bessemer City",
    "lng": -81.30800534242823,
    "lat": 35.304220427329454
  },
  {
    "name": "Bessemer City",
    "lng": -81.2856273065591,
    "lat": 35.28478967564317
  },
  {
    "name": "Bessemer City",
    "lng": -81.25542901365826,
    "lat": 35.28101991388412
  },
  {
    "name": "Bessie",
    "lng": -98.98941029763883,
    "lat": 35.385414873066814
  },
  {
    "name": "Bethalto",
    "lng": -90.04673522482474,
    "lat": 38.901508608859345
  },
  {
    "name": "Bethalto",
    "lng": -90.0322603776404,
    "lat": 38.87097607810196
  },
  {
    "name": "Bethania",
    "lng": -80.33738820650423,
    "lat": 36.181064097477176
  },
  {
    "name": "Bethany",
    "lng": -97.66143652477089,
    "lat": 35.52023082312913
  },
  {
    "name": "Bethany",
    "lng": -97.64159570576014,
    "lat": 35.507032935317625
  },
  {
    "name": "Bethany",
    "lng": -94.0281778954623,
    "lat": 40.268445652290076
  },
  {
    "name": "Bethany",
    "lng": -88.74123446397512,
    "lat": 39.64407454305845
  },
  {
    "name": "Bethany",
    "lng": -86.37785804784427,
    "lat": 39.5337096589672
  },
  {
    "name": "Bethany",
    "lng": -80.56291541669201,
    "lat": 40.20522325880084
  },
  {
    "name": "Bethel",
    "lng": -161.7929618019941,
    "lat": 60.790559025036394
  },
  {
    "name": "Bethel",
    "lng": -93.27105949212279,
    "lat": 45.402322407094175
  },
  {
    "name": "Bethel",
    "lng": -92.02266518599885,
    "lat": 39.877772399252514
  },
  {
    "name": "Bethel",
    "lng": -84.08492721296626,
    "lat": 38.96250336884474
  },
  {
    "name": "Bethel",
    "lng": -77.37613765227619,
    "lat": 35.80723840560885
  },
  {
    "name": "Bethel",
    "lng": -73.39297272992206,
    "lat": 41.37471810936215
  },
  {
    "name": "Bethel",
    "lng": -70.77063368395993,
    "lat": 44.42822982735181
  },
  {
    "name": "Bethel Acres",
    "lng": -97.03700753090423,
    "lat": 35.30481630783681
  },
  {
    "name": "Bethel Acres",
    "lng": -97.00141077337408,
    "lat": 35.34716249772799
  },
  {
    "name": "Bethel Heights",
    "lng": -94.12820325863808,
    "lat": 36.22622370504799
  },
  {
    "name": "Bethel Springs",
    "lng": -88.61177974382169,
    "lat": 35.23472841146673
  },
  {
    "name": "Bethesda",
    "lng": -81.07268940893697,
    "lat": 40.01637888224333
  },
  {
    "name": "Bethlehem",
    "lng": -83.72088341945867,
    "lat": 33.92221319915064
  },
  {
    "name": "Bethlehem",
    "lng": -83.71813981704602,
    "lat": 33.91965946583279
  },
  {
    "name": "Bethlehem",
    "lng": -83.71677343323863,
    "lat": 33.92220725178726
  },
  {
    "name": "Bethlehem",
    "lng": -83.71658497328056,
    "lat": 33.921077419981785
  },
  {
    "name": "Bethlehem",
    "lng": -83.7095842748343,
    "lat": 33.93738405467615
  },
  {
    "name": "Bethlehem",
    "lng": -80.6898495997743,
    "lat": 40.04516239247805
  },
  {
    "name": "Bethlehem",
    "lng": -75.36792374313278,
    "lat": 40.62665772359081
  },
  {
    "name": "Bethune",
    "lng": -102.4234342819653,
    "lat": 39.30381130795331
  },
  {
    "name": "Bethune",
    "lng": -80.34853296791614,
    "lat": 34.41422116618368
  },
  {
    "name": "Bettendorf",
    "lng": -90.47674747033898,
    "lat": 41.56734868147178
  },
  {
    "name": "Betterton",
    "lng": -76.06941883213348,
    "lat": 39.36490164932191
  },
  {
    "name": "Bettles",
    "lng": -151.53772463496816,
    "lat": 66.90633207689173
  },
  {
    "name": "Bettsville",
    "lng": -83.2338769764736,
    "lat": 41.2440542150904
  },
  {
    "name": "Between",
    "lng": -83.8043249522988,
    "lat": 33.8177578450541
  },
  {
    "name": "Beulah",
    "lng": -101.77353898963176,
    "lat": 47.26618585970404
  },
  {
    "name": "Beulah",
    "lng": -90.98013781713041,
    "lat": 33.790471855792084
  },
  {
    "name": "Beulah",
    "lng": -86.09591601322128,
    "lat": 44.62955087911803
  },
  {
    "name": "Beulaville",
    "lng": -77.79004388012731,
    "lat": 34.92455784321801
  },
  {
    "name": "Beulaville",
    "lng": -77.77177813091832,
    "lat": 34.9227431310348
  },
  {
    "name": "Beverly",
    "lng": -97.97520034031197,
    "lat": 39.01390401536994
  },
  {
    "name": "Beverly",
    "lng": -81.63620140656664,
    "lat": 39.54998494710797
  },
  {
    "name": "Beverly",
    "lng": -79.87118220978363,
    "lat": 38.844768817863866
  },
  {
    "name": "Beverly",
    "lng": -74.92192522473331,
    "lat": 40.06522710453442
  },
  {
    "name": "Beverly Hills",
    "lng": -97.15597598185143,
    "lat": 31.522204689005676
  },
  {
    "name": "Beverly Hills",
    "lng": -90.2900561838042,
    "lat": 38.69786622105821
  },
  {
    "name": "Beverly Hills",
    "lng": -83.24234453831161,
    "lat": 42.52204306640604
  },
  {
    "name": "Beverly Shores",
    "lng": -86.98055427213652,
    "lat": 41.6850097283979
  },
  {
    "name": "Bevier",
    "lng": -92.56465892547715,
    "lat": 39.750904560748765
  },
  {
    "name": "Bevil Oaks",
    "lng": -94.27129342061853,
    "lat": 30.151733663590907
  },
  {
    "name": "Bevington",
    "lng": -93.79251264333294,
    "lat": 41.363048173372505
  },
  {
    "name": "Bexley",
    "lng": -82.93425467032418,
    "lat": 39.96506790858329
  },
  {
    "name": "Bicknell",
    "lng": -111.54414462896457,
    "lat": 38.34066436050273
  },
  {
    "name": "Bicknell",
    "lng": -87.33058092905549,
    "lat": 38.7724459691002
  },
  {
    "name": "Bicknell",
    "lng": -87.32070300648007,
    "lat": 38.783611194951526
  },
  {
    "name": "Bicknell",
    "lng": -87.31801147546349,
    "lat": 38.7856435738252
  },
  {
    "name": "Bicknell",
    "lng": -87.30662273912239,
    "lat": 38.77449375454357
  },
  {
    "name": "Bicknell",
    "lng": -87.30510437355602,
    "lat": 38.782566052533944
  },
  {
    "name": "Biddeford",
    "lng": -70.39795816850163,
    "lat": 43.44412967143699
  },
  {
    "name": "Bienville",
    "lng": -92.97878201913069,
    "lat": 32.36181275305321
  },
  {
    "name": "Big Bear Lake",
    "lng": -116.89599049531145,
    "lat": 34.24292668285958
  },
  {
    "name": "Big Bend",
    "lng": -88.20876073473592,
    "lat": 42.895574073730536
  },
  {
    "name": "Big Bend",
    "lng": -88.20840254791715,
    "lat": 42.88721690329593
  },
  {
    "name": "Big Cabin",
    "lng": -95.22239684969423,
    "lat": 36.53907831928489
  },
  {
    "name": "Big Cabin",
    "lng": -95.2166801440894,
    "lat": 36.57101861080564
  },
  {
    "name": "Big Creek",
    "lng": -89.41589501985555,
    "lat": 33.84639303491892
  },
  {
    "name": "Big Falls",
    "lng": -93.80927138374433,
    "lat": 48.17956870447538
  },
  {
    "name": "Big Falls",
    "lng": -89.01692370740346,
    "lat": 44.61740637233325
  },
  {
    "name": "Big Flat",
    "lng": -92.41450079370756,
    "lat": 35.989795113589196
  },
  {
    "name": "Big Flat",
    "lng": -92.406569921391,
    "lat": 36.005719874018496
  },
  {
    "name": "Big Flats",
    "lng": -76.93161821522085,
    "lat": 42.14311055612209
  },
  {
    "name": "Big Lake",
    "lng": -101.45517877125236,
    "lat": 31.19463583968693
  },
  {
    "name": "Big Lake",
    "lng": -95.35199286829824,
    "lat": 40.07362095488418
  },
  {
    "name": "Big Lake",
    "lng": -93.78446008977781,
    "lat": 45.34428489733302
  },
  {
    "name": "Big Lake",
    "lng": -93.77532954992074,
    "lat": 45.31976796136852
  },
  {
    "name": "Big Lake",
    "lng": -93.75234040500186,
    "lat": 45.344776512842756
  },
  {
    "name": "Big Lake",
    "lng": -93.74796173925941,
    "lat": 45.30093832210278
  },
  {
    "name": "Big Lake",
    "lng": -93.73111174581449,
    "lat": 45.32446259734991
  },
  {
    "name": "Big Lake",
    "lng": -93.71899266044441,
    "lat": 45.33049916811869
  },
  {
    "name": "Big Lake",
    "lng": -93.70808354060057,
    "lat": 45.330765225049504
  },
  {
    "name": "Big Lake",
    "lng": -93.70752836810688,
    "lat": 45.33157423044537
  },
  {
    "name": "Big Lake",
    "lng": -93.70669672332671,
    "lat": 45.33505279211822
  },
  {
    "name": "Big Lake",
    "lng": -93.70659108544235,
    "lat": 45.333818125508984
  },
  {
    "name": "Big Lake",
    "lng": -93.69747043520135,
    "lat": 45.32945214777478
  },
  {
    "name": "Big Lake",
    "lng": -93.6959442484911,
    "lat": 45.333842896196394
  },
  {
    "name": "Big Lake",
    "lng": -93.68783027840296,
    "lat": 45.32847318927994
  },
  {
    "name": "Big Piney",
    "lng": -110.11938506506267,
    "lat": 42.54017563815279
  },
  {
    "name": "Big Rapids",
    "lng": -85.4802620564547,
    "lat": 43.69921183919623
  },
  {
    "name": "Big Rock",
    "lng": -88.52911312207073,
    "lat": 41.76989411799694
  },
  {
    "name": "Big Rock",
    "lng": -88.52858356082336,
    "lat": 41.73884381423875
  },
  {
    "name": "Big Rock",
    "lng": -88.52626296013842,
    "lat": 41.733562121781866
  },
  {
    "name": "Big Rock",
    "lng": -88.5149658769965,
    "lat": 41.73360693852898
  },
  {
    "name": "Big Rock",
    "lng": -88.51235915297306,
    "lat": 41.73273508286086
  },
  {
    "name": "Big Rock",
    "lng": -88.49683959713738,
    "lat": 41.73955915831616
  },
  {
    "name": "Big Sandy",
    "lng": -110.11386885955497,
    "lat": 48.17847649156984
  },
  {
    "name": "Big Sandy",
    "lng": -95.11495806270688,
    "lat": 32.58504392567477
  },
  {
    "name": "Big Sandy",
    "lng": -88.08548497221784,
    "lat": 36.23235681212557
  },
  {
    "name": "Big Spring",
    "lng": -101.48003124106577,
    "lat": 32.23882022910661
  },
  {
    "name": "Big Springs",
    "lng": -102.08832581354,
    "lat": 41.060994777902735
  },
  {
    "name": "Big Springs",
    "lng": -102.07540809724416,
    "lat": 41.063173613002974
  },
  {
    "name": "Big Springs",
    "lng": -102.07490088723007,
    "lat": 41.059899227420175
  },
  {
    "name": "Big Springs",
    "lng": -102.07315935633976,
    "lat": 41.050860609970606
  },
  {
    "name": "Big Springs",
    "lng": -102.0717255197334,
    "lat": 41.04393857944944
  },
  {
    "name": "Big Springs",
    "lng": -102.07013095949962,
    "lat": 41.04412701260192
  },
  {
    "name": "Big Springs",
    "lng": -102.06808277857212,
    "lat": 41.05185765850191
  },
  {
    "name": "Big Stone City",
    "lng": -96.46569051728687,
    "lat": 45.29854567547457
  },
  {
    "name": "Big Stone Gap",
    "lng": -82.77613528794615,
    "lat": 36.86270728424763
  },
  {
    "name": "Big Timber",
    "lng": -109.94875622121829,
    "lat": 45.83438234197802
  },
  {
    "name": "Big Water",
    "lng": -111.66077059794418,
    "lat": 37.07288532448993
  },
  {
    "name": "Bigelow",
    "lng": -95.68914500034373,
    "lat": 43.50536699304461
  },
  {
    "name": "Bigelow",
    "lng": -95.28954567372719,
    "lat": 40.109730717078065
  },
  {
    "name": "Bigelow",
    "lng": -92.63117981155293,
    "lat": 34.997798349989885
  },
  {
    "name": "Bigfork",
    "lng": -93.65197031082123,
    "lat": 47.747004920763935
  },
  {
    "name": "Biggers",
    "lng": -90.8052541366106,
    "lat": 36.33194516388616
  },
  {
    "name": "Biggs",
    "lng": -121.71057975114229,
    "lat": 39.4129284105113
  },
  {
    "name": "Biggsville",
    "lng": -90.86238413774711,
    "lat": 40.85368935432471
  },
  {
    "name": "Billerica",
    "lng": -71.26727219520441,
    "lat": 42.558687133401214
  },
  {
    "name": "Billings",
    "lng": -108.6689260381152,
    "lat": 45.817091056134274
  },
  {
    "name": "Billings",
    "lng": -108.6643259424301,
    "lat": 45.781709509909824
  },
  {
    "name": "Billings",
    "lng": -108.6585591104963,
    "lat": 45.78332743310323
  },
  {
    "name": "Billings",
    "lng": -108.63063062329695,
    "lat": 45.78510726142208
  },
  {
    "name": "Billings",
    "lng": -108.6210253061479,
    "lat": 45.76784357494545
  },
  {
    "name": "Billings",
    "lng": -108.59934231516434,
    "lat": 45.8323307830454
  },
  {
    "name": "Billings",
    "lng": -108.54601602217912,
    "lat": 45.79072386956608
  },
  {
    "name": "Billings",
    "lng": -108.53408829215199,
    "lat": 45.732948699213374
  },
  {
    "name": "Billings",
    "lng": -108.52769181941429,
    "lat": 45.730904809299055
  },
  {
    "name": "Billings",
    "lng": -108.51524807057122,
    "lat": 45.714401397970434
  },
  {
    "name": "Billings",
    "lng": -108.49825713285311,
    "lat": 45.72020634477564
  },
  {
    "name": "Billings",
    "lng": -97.43453346868814,
    "lat": 36.53136618116396
  },
  {
    "name": "Billings",
    "lng": -93.55453692034021,
    "lat": 37.06290442128001
  },
  {
    "name": "Billingsley",
    "lng": -86.70871650726251,
    "lat": 32.66103703510604
  },
  {
    "name": "Biloxi",
    "lng": -88.94982397177246,
    "lat": 30.436545256995796
  },
  {
    "name": "Biloxi",
    "lng": -88.84808651570442,
    "lat": 30.375153227108175
  },
  {
    "name": "Biltmore Forest",
    "lng": -82.54018643934292,
    "lat": 35.53481049900394
  },
  {
    "name": "Binford",
    "lng": -98.34572672642079,
    "lat": 47.56040179170381
  },
  {
    "name": "Bingen",
    "lng": -121.46912287374901,
    "lat": 45.71549671319629
  },
  {
    "name": "Bingen",
    "lng": -121.45345764248981,
    "lat": 45.70784162298125
  },
  {
    "name": "Binger",
    "lng": -98.34387586196327,
    "lat": 35.310552456471584
  },
  {
    "name": "Bingham",
    "lng": -89.21372824249424,
    "lat": 39.112235904547084
  },
  {
    "name": "Bingham",
    "lng": -69.81713986484829,
    "lat": 45.030966864899206
  },
  {
    "name": "Bingham Farms",
    "lng": -83.27779404638243,
    "lat": 42.5176403200087
  },
  {
    "name": "Bingham Lake",
    "lng": -95.04623686354503,
    "lat": 43.90948953081169
  },
  {
    "name": "Binghamton",
    "lng": -75.90922903286209,
    "lat": 42.10145342040791
  },
  {
    "name": "Birch Tree",
    "lng": -91.50197697149677,
    "lat": 37.00286551126157
  },
  {
    "name": "Birch Tree",
    "lng": -91.4916332076409,
    "lat": 36.996683436851455
  },
  {
    "name": "Birchwood",
    "lng": -91.55056164205273,
    "lat": 45.65775157496208
  },
  {
    "name": "Birchwood Village",
    "lng": -92.97795373047846,
    "lat": 45.05977128600492
  },
  {
    "name": "Bird City",
    "lng": -101.53254951660057,
    "lat": 39.74965186864464
  },
  {
    "name": "Bird Island",
    "lng": -94.89419466805974,
    "lat": 44.765017717789654
  },
  {
    "name": "Bird Island",
    "lng": -94.87971530925121,
    "lat": 44.776716097724865
  },
  {
    "name": "Birds",
    "lng": -87.6701025581093,
    "lat": 38.8375657861885
  },
  {
    "name": "Birdseye",
    "lng": -86.69615416786665,
    "lat": 38.31496350166683
  },
  {
    "name": "Birdsong",
    "lng": -90.25976115713515,
    "lat": 35.461159728473525
  },
  {
    "name": "Birmingham",
    "lng": -94.4502971360631,
    "lat": 39.16757206024984
  },
  {
    "name": "Birmingham",
    "lng": -91.94746302197022,
    "lat": 40.878215198347725
  },
  {
    "name": "Birmingham",
    "lng": -86.95791917648741,
    "lat": 33.47191446417476
  },
  {
    "name": "Birmingham",
    "lng": -86.89974848356032,
    "lat": 33.3880759931046
  },
  {
    "name": "Birmingham",
    "lng": -86.79423037362281,
    "lat": 33.52770472151281
  },
  {
    "name": "Birmingham",
    "lng": -86.71498762925881,
    "lat": 33.63051454791291
  },
  {
    "name": "Birmingham",
    "lng": -86.7017844843687,
    "lat": 33.500737237729666
  },
  {
    "name": "Birmingham",
    "lng": -86.70092585594263,
    "lat": 33.621798136844454
  },
  {
    "name": "Birmingham",
    "lng": -86.6289830378538,
    "lat": 33.47514245833851
  },
  {
    "name": "Birmingham",
    "lng": -86.62255688392061,
    "lat": 33.60784558109267
  },
  {
    "name": "Birmingham",
    "lng": -86.61671372080495,
    "lat": 33.50550140290071
  },
  {
    "name": "Birmingham",
    "lng": -86.60609269513182,
    "lat": 33.56318850910649
  },
  {
    "name": "Birmingham",
    "lng": -83.2166490744724,
    "lat": 42.54470505501089
  },
  {
    "name": "Birnamwood",
    "lng": -89.20943797302648,
    "lat": 44.93186565456385
  },
  {
    "name": "Biron",
    "lng": -89.76633407635602,
    "lat": 44.428631667438715
  },
  {
    "name": "Biron",
    "lng": -89.74105370868891,
    "lat": 44.41770242843335
  },
  {
    "name": "Bisbee",
    "lng": -99.37877859270426,
    "lat": 48.62632815833425
  },
  {
    "name": "Biscay",
    "lng": -94.27395241472435,
    "lat": 44.82633472574945
  },
  {
    "name": "Biscayne Park",
    "lng": -80.18119243783666,
    "lat": 25.8818018624863
  },
  {
    "name": "Biscoe",
    "lng": -79.78255283727012,
    "lat": 35.35935769305313
  },
  {
    "name": "Biscoe",
    "lng": -79.77890160977344,
    "lat": 35.369700553607466
  },
  {
    "name": "Biscoe",
    "lng": -79.77571563380525,
    "lat": 35.369272846680346
  },
  {
    "name": "Bishop",
    "lng": -118.39740838564508,
    "lat": 37.367866222134936
  },
  {
    "name": "Bishop",
    "lng": -97.79768667339276,
    "lat": 27.585136626328257
  },
  {
    "name": "Bishop",
    "lng": -83.43717041461768,
    "lat": 33.817139499507654
  },
  {
    "name": "Bishop Hill",
    "lng": -90.1175116346139,
    "lat": 41.19981620048099
  },
  {
    "name": "Bishop Hills",
    "lng": -101.95151803534363,
    "lat": 35.26015656846004
  },
  {
    "name": "Bishopville",
    "lng": -80.24819998667498,
    "lat": 34.22030784953211
  },
  {
    "name": "Bismarck",
    "lng": -100.82761224062038,
    "lat": 46.77401223597504
  },
  {
    "name": "Bismarck",
    "lng": -100.76903775155046,
    "lat": 46.810663657703685
  },
  {
    "name": "Bismarck",
    "lng": -100.73938107222214,
    "lat": 46.82982456641515
  },
  {
    "name": "Bismarck",
    "lng": -90.62247931787529,
    "lat": 37.76741896868439
  },
  {
    "name": "Bismarck",
    "lng": -87.61126578324446,
    "lat": 40.25944238395039
  },
  {
    "name": "Bison",
    "lng": -102.46763418559301,
    "lat": 45.52349939421549
  },
  {
    "name": "Bison",
    "lng": -99.1979829415482,
    "lat": 38.519834411785986
  },
  {
    "name": "Bithlo",
    "lng": -81.10848667073152,
    "lat": 28.555159295872606
  },
  {
    "name": "Biwabik",
    "lng": -92.32334219509023,
    "lat": 47.554890942669154
  },
  {
    "name": "Biwabik",
    "lng": -92.31838684283466,
    "lat": 47.58724623557751
  },
  {
    "name": "Bixby",
    "lng": -95.90149078264615,
    "lat": 35.89543291227596
  },
  {
    "name": "Bixby",
    "lng": -95.89494439696207,
    "lat": 35.88887302102828
  },
  {
    "name": "Bixby",
    "lng": -95.8775773942378,
    "lat": 35.946364521027895
  },
  {
    "name": "Bixby",
    "lng": -95.83259815013693,
    "lat": 35.90016511235575
  },
  {
    "name": "Black",
    "lng": -85.74279127220638,
    "lat": 31.010250273997503
  },
  {
    "name": "Black Creek",
    "lng": -88.45060554418771,
    "lat": 44.47431141453302
  },
  {
    "name": "Black Creek",
    "lng": -77.93782534480574,
    "lat": 35.65953383151053
  },
  {
    "name": "Black Creek",
    "lng": -77.93320632630406,
    "lat": 35.635876991684974
  },
  {
    "name": "Black Creek",
    "lng": -77.91485601156883,
    "lat": 35.65337419641277
  },
  {
    "name": "Black Diamond",
    "lng": -122.01926699690246,
    "lat": 47.3153808757458
  },
  {
    "name": "Black Diamond",
    "lng": -121.97536559925723,
    "lat": 47.28294191500378
  },
  {
    "name": "Black Earth",
    "lng": -89.74690830208645,
    "lat": 43.134400458917064
  },
  {
    "name": "Black Farm Management Area",
    "lng": -71.72093044222758,
    "lat": 41.4744974793907
  },
  {
    "name": "Black Farm Management Area",
    "lng": -71.71579546688557,
    "lat": 41.49275850814307
  },
  {
    "name": "Black Jack",
    "lng": -90.26406773339399,
    "lat": 38.79929245309897
  },
  {
    "name": "Black Mountain",
    "lng": -82.32759428605331,
    "lat": 35.61518800939442
  },
  {
    "name": "Black Oak",
    "lng": -90.3675709204358,
    "lat": 35.83649772723934
  },
  {
    "name": "Black River",
    "lng": -75.79635415465789,
    "lat": 44.0092233181129
  },
  {
    "name": "Black River Falls",
    "lng": -90.84559175015713,
    "lat": 44.29776791312426
  },
  {
    "name": "Black River Falls",
    "lng": -90.80297883099789,
    "lat": 44.301229174022744
  },
  {
    "name": "Black Rock",
    "lng": -91.10745275649448,
    "lat": 36.10697458418199
  },
  {
    "name": "Black Springs",
    "lng": -93.71087686880226,
    "lat": 34.46133500241159
  },
  {
    "name": "Blackburn",
    "lng": -96.59651543080761,
    "lat": 36.37306738272887
  },
  {
    "name": "Blackburn",
    "lng": -93.485517977676,
    "lat": 39.104738038977466
  },
  {
    "name": "Blackduck",
    "lng": -94.54852123802426,
    "lat": 47.726206895462944
  },
  {
    "name": "Blackduck",
    "lng": -94.53126659988637,
    "lat": 47.7278085093296
  },
  {
    "name": "Blackey",
    "lng": -82.98139071428734,
    "lat": 37.1390918761049
  },
  {
    "name": "Blackfoot",
    "lng": -112.36829988420709,
    "lat": 43.20153987273312
  },
  {
    "name": "Blackfoot",
    "lng": -112.34538518623847,
    "lat": 43.19397308234506
  },
  {
    "name": "Blacksburg",
    "lng": -81.51813046625833,
    "lat": 35.122134038981464
  },
  {
    "name": "Blacksburg",
    "lng": -80.4279533732988,
    "lat": 37.22999633756851
  },
  {
    "name": "Blackshear",
    "lng": -82.2432184198737,
    "lat": 31.299408954980695
  },
  {
    "name": "Blackstone",
    "lng": -78.00295577129823,
    "lat": 37.082123570638174
  },
  {
    "name": "Blacksville",
    "lng": -80.21478012016347,
    "lat": 39.71732449251346
  },
  {
    "name": "Blackville",
    "lng": -81.28370396084931,
    "lat": 33.354703896147576
  },
  {
    "name": "Blackwater",
    "lng": -92.99198862515651,
    "lat": 38.97919750929896
  },
  {
    "name": "Blackwell",
    "lng": -100.31935691800007,
    "lat": 32.08505357112918
  },
  {
    "name": "Blackwell",
    "lng": -97.30035087644785,
    "lat": 36.8011066778424
  },
  {
    "name": "Bladen",
    "lng": -98.59588004840548,
    "lat": 40.32344465121313
  },
  {
    "name": "Bladenboro",
    "lng": -78.82658120150788,
    "lat": 34.548994803209645
  },
  {
    "name": "Bladenboro",
    "lng": -78.7938354192367,
    "lat": 34.5396441759318
  },
  {
    "name": "Bladenboro",
    "lng": -78.77835857138538,
    "lat": 34.55556155468652
  },
  {
    "name": "Bladenboro",
    "lng": -78.77416353144443,
    "lat": 34.55474744635963
  },
  {
    "name": "Blaine",
    "lng": -122.78273251199636,
    "lat": 48.970675519993165
  },
  {
    "name": "Blaine",
    "lng": -122.72720613695294,
    "lat": 48.9921792453055
  },
  {
    "name": "Blaine",
    "lng": -93.20776057733755,
    "lat": 45.16959279652935
  },
  {
    "name": "Blaine",
    "lng": -82.8532882933443,
    "lat": 38.026349396990064
  },
  {
    "name": "Blaine",
    "lng": -67.84956215257381,
    "lat": 46.487712302949724
  },
  {
    "name": "Blair",
    "lng": -99.33301887490258,
    "lat": 34.77924917432065
  },
  {
    "name": "Blair",
    "lng": -96.13687370452283,
    "lat": 41.54182451668432
  },
  {
    "name": "Blair",
    "lng": -91.22849296436652,
    "lat": 44.295275831600215
  },
  {
    "name": "Blairsburg",
    "lng": -93.64315649745558,
    "lat": 42.47821184414668
  },
  {
    "name": "Blairstown",
    "lng": -93.95775754341368,
    "lat": 38.557989055674796
  },
  {
    "name": "Blairstown",
    "lng": -92.08227324291451,
    "lat": 41.906043252659266
  },
  {
    "name": "Blairsville",
    "lng": -83.95518262155935,
    "lat": 34.876172984382364
  },
  {
    "name": "Blakely",
    "lng": -84.92289413533616,
    "lat": 31.38260221877685
  },
  {
    "name": "Blakesburg",
    "lng": -92.63476666689493,
    "lat": 40.9621993843043
  },
  {
    "name": "Blakeslee",
    "lng": -84.73104687875183,
    "lat": 41.524220005183224
  },
  {
    "name": "Blanca",
    "lng": -105.51352626892482,
    "lat": 37.439280802600145
  },
  {
    "name": "Blanchard",
    "lng": -97.68968734317389,
    "lat": 35.07685897850654
  },
  {
    "name": "Blanchard",
    "lng": -97.65895765174672,
    "lat": 35.155572646591544
  },
  {
    "name": "Blanchard",
    "lng": -97.62861248568218,
    "lat": 35.12035669897271
  },
  {
    "name": "Blanchard",
    "lng": -97.6221317707088,
    "lat": 35.10605030148786
  },
  {
    "name": "Blanchard",
    "lng": -95.2214971443937,
    "lat": 40.58019730813903
  },
  {
    "name": "Blanchard",
    "lng": -93.8842289519519,
    "lat": 32.60898902093466
  },
  {
    "name": "Blanchard",
    "lng": -93.8431740466028,
    "lat": 32.60126181315115
  },
  {
    "name": "Blanchardville",
    "lng": -89.86073032996498,
    "lat": 42.809455514210846
  },
  {
    "name": "Blanchester",
    "lng": -83.97973996834335,
    "lat": 39.2933293960614
  },
  {
    "name": "Blanco",
    "lng": -98.43630193476677,
    "lat": 30.09595454501955
  },
  {
    "name": "Blanco",
    "lng": -98.41678395856778,
    "lat": 30.098653869151853
  },
  {
    "name": "Bland",
    "lng": -91.63306438837579,
    "lat": 38.300448084506385
  },
  {
    "name": "Blandford",
    "lng": -72.9522139586165,
    "lat": 42.18688855499961
  },
  {
    "name": "Blanding",
    "lng": -109.50733606437932,
    "lat": 37.621304078868114
  },
  {
    "name": "Blandinsville",
    "lng": -90.86839437266195,
    "lat": 40.55393855018462
  },
  {
    "name": "Blandville",
    "lng": -88.96493232324572,
    "lat": 36.943846814737334
  },
  {
    "name": "Blanket",
    "lng": -98.78948680035776,
    "lat": 31.82484535278532
  },
  {
    "name": "Blasdell",
    "lng": -78.83249553735524,
    "lat": 42.79648894434633
  },
  {
    "name": "Blencoe",
    "lng": -96.08199360587096,
    "lat": 41.93034746468017
  },
  {
    "name": "Blenheim",
    "lng": -79.65245181793152,
    "lat": 34.509836969017904
  },
  {
    "name": "Bliss",
    "lng": -114.9480069419013,
    "lat": 42.92416051953256
  },
  {
    "name": "Blissfield",
    "lng": -83.86393185307104,
    "lat": 41.831568040805436
  },
  {
    "name": "Blockton",
    "lng": -94.47789649639749,
    "lat": 40.61687703979345
  },
  {
    "name": "Blodgett",
    "lng": -89.52635177887383,
    "lat": 37.004745933584076
  },
  {
    "name": "Blomkest",
    "lng": -95.02338636739638,
    "lat": 44.942779344196126
  },
  {
    "name": "Bloomburg",
    "lng": -94.05907346004898,
    "lat": 33.137796997107344
  },
  {
    "name": "Bloomdale",
    "lng": -83.55343621373429,
    "lat": 41.17157110757378
  },
  {
    "name": "Bloomer",
    "lng": -91.4900224401456,
    "lat": 45.10328212927462
  },
  {
    "name": "Bloomfield",
    "lng": -107.97336253027325,
    "lat": 36.740413263356864
  },
  {
    "name": "Bloomfield",
    "lng": -97.64813793964395,
    "lat": 42.598412315867385
  },
  {
    "name": "Bloomfield",
    "lng": -92.41749628559147,
    "lat": 40.74855577038657
  },
  {
    "name": "Bloomfield",
    "lng": -89.93094969775221,
    "lat": 36.88776038770641
  },
  {
    "name": "Bloomfield",
    "lng": -86.93728083252395,
    "lat": 39.02625642661407
  },
  {
    "name": "Bloomfield",
    "lng": -85.31506238934384,
    "lat": 37.91178421316954
  },
  {
    "name": "Bloomfield",
    "lng": -77.42414784342549,
    "lat": 42.89913199817952
  },
  {
    "name": "Bloomfield",
    "lng": -72.7407432176854,
    "lat": 41.8426379187585
  },
  {
    "name": "Bloomfield Hills",
    "lng": -83.24566468712533,
    "lat": 42.57803526075943
  },
  {
    "name": "Blooming Grove",
    "lng": -96.71728455848897,
    "lat": 32.09237000723406
  },
  {
    "name": "Blooming Prairie",
    "lng": -93.06802004420683,
    "lat": 43.85023511145328
  },
  {
    "name": "Blooming Prairie",
    "lng": -93.0552490182381,
    "lat": 43.86860929183618
  },
  {
    "name": "Bloomingburg",
    "lng": -83.3954502352073,
    "lat": 39.60774490048356
  },
  {
    "name": "Bloomingburg",
    "lng": -74.44247031809665,
    "lat": 41.555395895583175
  },
  {
    "name": "Bloomingdale",
    "lng": -88.12237700796294,
    "lat": 41.96345376487427
  },
  {
    "name": "Bloomingdale",
    "lng": -88.08895404600148,
    "lat": 41.94960531109909
  },
  {
    "name": "Bloomingdale",
    "lng": -88.05358347791571,
    "lat": 41.93246513478998
  },
  {
    "name": "Bloomingdale",
    "lng": -87.25017320351336,
    "lat": 39.8305995238044
  },
  {
    "name": "Bloomingdale",
    "lng": -85.95903254421233,
    "lat": 42.38328308680874
  },
  {
    "name": "Bloomingdale",
    "lng": -81.30984008381546,
    "lat": 32.08697909104482
  },
  {
    "name": "Bloomingdale",
    "lng": -81.3076782708242,
    "lat": 32.12497733171726
  },
  {
    "name": "Bloomingdale",
    "lng": -80.81784998255881,
    "lat": 40.34222828275702
  },
  {
    "name": "Bloomington",
    "lng": -111.40413905110584,
    "lat": 42.190795553625556
  },
  {
    "name": "Bloomington",
    "lng": -99.0383891949201,
    "lat": 40.09379210361479
  },
  {
    "name": "Bloomington",
    "lng": -93.31514833730995,
    "lat": 44.830599850504505
  },
  {
    "name": "Bloomington",
    "lng": -90.92655032461126,
    "lat": 42.8935128244242
  },
  {
    "name": "Bloomington",
    "lng": -90.91619101661581,
    "lat": 42.88194524511124
  },
  {
    "name": "Bloomington",
    "lng": -89.04318821046466,
    "lat": 40.44917839364266
  },
  {
    "name": "Bloomington",
    "lng": -88.96586444074067,
    "lat": 40.47775833069646
  },
  {
    "name": "Bloomington",
    "lng": -88.93803346848173,
    "lat": 40.442506425096475
  },
  {
    "name": "Bloomington",
    "lng": -88.91110658533566,
    "lat": 40.450522458035685
  },
  {
    "name": "Bloomington",
    "lng": -86.58489852525777,
    "lat": 39.14964554040473
  },
  {
    "name": "Bloomington",
    "lng": -86.58464964924225,
    "lat": 39.17263337369696
  },
  {
    "name": "Bloomington",
    "lng": -86.52569183876871,
    "lat": 39.16371800413301
  },
  {
    "name": "Bloomsburg",
    "lng": -76.45632039592032,
    "lat": 41.00242301396804
  },
  {
    "name": "Bloomsdale",
    "lng": -90.2214354136621,
    "lat": 38.0151538076385
  },
  {
    "name": "Bloomville",
    "lng": -83.01364256038774,
    "lat": 41.05135203427643
  },
  {
    "name": "Blossom",
    "lng": -95.38371603536118,
    "lat": 33.66336042013267
  },
  {
    "name": "Blountstown",
    "lng": -85.06228434493096,
    "lat": 30.443224173948302
  },
  {
    "name": "Blountstown",
    "lng": -85.06074447151843,
    "lat": 30.451581053266008
  },
  {
    "name": "Blountstown",
    "lng": -85.05780384940041,
    "lat": 30.43080271694515
  },
  {
    "name": "Blountstown",
    "lng": -85.04530313677215,
    "lat": 30.44325464242809
  },
  {
    "name": "Blountsville",
    "lng": -86.58676446353414,
    "lat": 34.081421359466745
  },
  {
    "name": "Blountsville",
    "lng": -85.2383700831836,
    "lat": 40.059815479925554
  },
  {
    "name": "Blowing Rock",
    "lng": -81.67139734875899,
    "lat": 36.12926637591466
  },
  {
    "name": "Bloxom",
    "lng": -75.6213381445223,
    "lat": 37.82906005728638
  },
  {
    "name": "Blue Ash",
    "lng": -84.38256374058919,
    "lat": 39.248012638711955
  },
  {
    "name": "Blue Earth",
    "lng": -94.10044810984293,
    "lat": 43.64090000862829
  },
  {
    "name": "Blue Eye",
    "lng": -93.3825024267532,
    "lat": 36.516061079781956
  },
  {
    "name": "Blue Grass",
    "lng": -90.76483871699277,
    "lat": 41.5095799270167
  },
  {
    "name": "Blue Hill",
    "lng": -98.44833786023862,
    "lat": 40.333024555148675
  },
  {
    "name": "Blue Hill",
    "lng": -68.56608002537097,
    "lat": 44.40441419972236
  },
  {
    "name": "Blue Island",
    "lng": -87.6809405987055,
    "lat": 41.65814492656451
  },
  {
    "name": "Blue Lake",
    "lng": -123.99307389392368,
    "lat": 40.881419644214006
  },
  {
    "name": "Blue Lake",
    "lng": -123.97564979600256,
    "lat": 40.874602496086034
  },
  {
    "name": "Blue Mound",
    "lng": -97.33830346340507,
    "lat": 32.854242592370376
  },
  {
    "name": "Blue Mound",
    "lng": -95.01003601261381,
    "lat": 38.08894932967487
  },
  {
    "name": "Blue Mound",
    "lng": -89.11856732981707,
    "lat": 39.70056345724135
  },
  {
    "name": "Blue Mountain",
    "lng": -93.7162181171758,
    "lat": 35.13110200999666
  },
  {
    "name": "Blue Mountain",
    "lng": -89.02780849147203,
    "lat": 34.67079713236628
  },
  {
    "name": "Blue Rapids",
    "lng": -96.65959804949591,
    "lat": 39.67937321726855
  },
  {
    "name": "Blue Ridge",
    "lng": -84.32401269309736,
    "lat": 34.865866391439404
  },
  {
    "name": "Blue Ridge Manor",
    "lng": -85.56476368251852,
    "lat": 38.2436407043625
  },
  {
    "name": "Blue River",
    "lng": -106.04046672876827,
    "lat": 39.41476099925823
  },
  {
    "name": "Blue River",
    "lng": -106.03624634837468,
    "lat": 39.43509717907637
  },
  {
    "name": "Blue River",
    "lng": -90.57188975279041,
    "lat": 43.185551589844216
  },
  {
    "name": "Blue Springs",
    "lng": -96.66318319317192,
    "lat": 40.13751879503281
  },
  {
    "name": "Blue Springs",
    "lng": -94.2864928775356,
    "lat": 38.96269666838292
  },
  {
    "name": "Blue Springs",
    "lng": -94.27232020055384,
    "lat": 39.01295262866656
  },
  {
    "name": "Blue Springs",
    "lng": -94.2230913573555,
    "lat": 39.02829478038514
  },
  {
    "name": "Blue Springs",
    "lng": -88.8722977134028,
    "lat": 34.40123711524154
  },
  {
    "name": "Blue Springs",
    "lng": -85.50352638027105,
    "lat": 31.663516417937213
  },
  {
    "name": "Bluefield",
    "lng": -81.27485965917582,
    "lat": 37.235112976626574
  },
  {
    "name": "Bluefield",
    "lng": -81.21626937796354,
    "lat": 37.26037433914697
  },
  {
    "name": "Bluefield",
    "lng": -81.17058659365435,
    "lat": 37.27110038453882
  },
  {
    "name": "Bluejacket",
    "lng": -95.07407374725028,
    "lat": 36.8007972075159
  },
  {
    "name": "Bluff City",
    "lng": -97.87490369974311,
    "lat": 37.076017331833995
  },
  {
    "name": "Bluff City",
    "lng": -93.13153735965024,
    "lat": 33.72236739357014
  },
  {
    "name": "Bluff City",
    "lng": -82.2778804493821,
    "lat": 36.46073812314031
  },
  {
    "name": "Bluffdale",
    "lng": -111.93857846100607,
    "lat": 40.47715586757624
  },
  {
    "name": "Bluffs",
    "lng": -90.53525262876164,
    "lat": 39.74925410759494
  },
  {
    "name": "Bluffton",
    "lng": -95.23378046219162,
    "lat": 46.469589845824274
  },
  {
    "name": "Bluffton",
    "lng": -85.17312213605513,
    "lat": 40.74245199913926
  },
  {
    "name": "Bluffton",
    "lng": -84.86904983808444,
    "lat": 31.519787483828136
  },
  {
    "name": "Bluffton",
    "lng": -83.88863810444171,
    "lat": 40.89123866767214
  },
  {
    "name": "Bluffton",
    "lng": -80.93083142005766,
    "lat": 32.21293777500533
  },
  {
    "name": "Bluffton",
    "lng": -80.85081686314851,
    "lat": 32.23669953996413
  },
  {
    "name": "Bluford",
    "lng": -88.73653048331731,
    "lat": 38.3256204804073
  },
  {
    "name": "Blum",
    "lng": -97.39716779935047,
    "lat": 32.14191755496198
  },
  {
    "name": "Blunt",
    "lng": -99.98855683223718,
    "lat": 44.515939183331135
  },
  {
    "name": "Blythe",
    "lng": -82.20263563195684,
    "lat": 33.30111818800602
  },
  {
    "name": "Blythedale",
    "lng": -93.92800806592312,
    "lat": 40.47515227190764
  },
  {
    "name": "Blytheville",
    "lng": -89.90512606702626,
    "lat": 35.93215342610933
  },
  {
    "name": "Blythewood",
    "lng": -80.9906634014809,
    "lat": 34.21425198834301
  },
  {
    "name": "Boardman",
    "lng": -78.94699834645135,
    "lat": 34.436346572644574
  },
  {
    "name": "Boaz",
    "lng": -90.52752125509456,
    "lat": 43.33012727560227
  },
  {
    "name": "Boaz",
    "lng": -86.17257377873092,
    "lat": 34.16610405378582
  },
  {
    "name": "Boaz",
    "lng": -86.14851990591461,
    "lat": 34.207089381335635
  },
  {
    "name": "Boaz",
    "lng": -86.13557336881175,
    "lat": 34.14909127765786
  },
  {
    "name": "Boca Raton",
    "lng": -80.10634577255017,
    "lat": 26.374214569707704
  },
  {
    "name": "Bock",
    "lng": -93.55280013781037,
    "lat": 45.78447225693606
  },
  {
    "name": "Bodcaw",
    "lng": -93.40132592183615,
    "lat": 33.56065476596151
  },
  {
    "name": "Bode",
    "lng": -94.28610582843955,
    "lat": 42.867489470124944
  },
  {
    "name": "Boerne",
    "lng": -98.72965847951833,
    "lat": 29.78481629385108
  },
  {
    "name": "Boerne",
    "lng": -98.68909082913281,
    "lat": 29.75691315169445
  },
  {
    "name": "Bogalusa",
    "lng": -89.8632690335585,
    "lat": 30.781195513812648
  },
  {
    "name": "Bogalusa",
    "lng": -89.84628907940485,
    "lat": 30.757690107553582
  },
  {
    "name": "Bogard",
    "lng": -93.52386106050221,
    "lat": 39.45792779646373
  },
  {
    "name": "Bogart",
    "lng": -83.5335311852374,
    "lat": 33.94745196788258
  },
  {
    "name": "Bogata",
    "lng": -95.2135490973043,
    "lat": 33.469810958195794
  },
  {
    "name": "Bogue",
    "lng": -99.68776402880438,
    "lat": 39.359485103537075
  },
  {
    "name": "Bogue",
    "lng": -77.03079112584638,
    "lat": 34.69744596319018
  },
  {
    "name": "Boiling Spring Lakes",
    "lng": -78.06686029515961,
    "lat": 34.03246190916998
  },
  {
    "name": "Boiling Springs",
    "lng": -81.66435871346464,
    "lat": 35.2517024472551
  },
  {
    "name": "Boiling Springs",
    "lng": -81.65422206641992,
    "lat": 35.23005234696085
  },
  {
    "name": "Boiling Springs",
    "lng": -81.65343961417024,
    "lat": 35.273886145672115
  },
  {
    "name": "Boiling Springs",
    "lng": -81.63916172151721,
    "lat": 35.250662070420155
  },
  {
    "name": "Boise",
    "lng": -116.23131193649378,
    "lat": 43.6009260763721
  },
  {
    "name": "Boise",
    "lng": -116.20840114549372,
    "lat": 43.520510666828905
  },
  {
    "name": "Boise",
    "lng": -116.10540328751173,
    "lat": 43.5265707222448
  },
  {
    "name": "Boise City",
    "lng": -102.51104959832848,
    "lat": 36.73095036831242
  },
  {
    "name": "Bokchito",
    "lng": -96.14567848326557,
    "lat": 34.02166135843486
  },
  {
    "name": "Bokchito",
    "lng": -96.14156727492336,
    "lat": 34.01778045807175
  },
  {
    "name": "Bokoshe",
    "lng": -94.7905216012373,
    "lat": 35.19083272218706
  },
  {
    "name": "Bokoshe",
    "lng": -94.78050432653014,
    "lat": 35.1839915929772
  },
  {
    "name": "Bolckow",
    "lng": -94.82120651409684,
    "lat": 40.1152174868133
  },
  {
    "name": "Boley",
    "lng": -96.47928793375624,
    "lat": 35.49033153486051
  },
  {
    "name": "Boligee",
    "lng": -88.02645053816356,
    "lat": 32.77003486812617
  },
  {
    "name": "Bolingbrook",
    "lng": -88.10191522952871,
    "lat": 41.69035513411974
  },
  {
    "name": "Bolivar",
    "lng": -93.41772523195431,
    "lat": 37.60615847882445
  },
  {
    "name": "Bolivar",
    "lng": -89.01475444098169,
    "lat": 35.265970070889196
  },
  {
    "name": "Bolivar",
    "lng": -81.45482215626961,
    "lat": 40.649498905591265
  },
  {
    "name": "Bolivar",
    "lng": -78.16623345564223,
    "lat": 42.06841880432769
  },
  {
    "name": "Bolivar",
    "lng": -77.75321244648019,
    "lat": 39.323567661229944
  },
  {
    "name": "Bolivia",
    "lng": -78.14736479612672,
    "lat": 34.069926214868346
  },
  {
    "name": "Bolton",
    "lng": -90.45858465151252,
    "lat": 32.35461229753606
  },
  {
    "name": "Bolton",
    "lng": -78.39910388512405,
    "lat": 34.317235640314244
  },
  {
    "name": "Bolton",
    "lng": -72.43901142945863,
    "lat": 41.766495195375974
  },
  {
    "name": "Bolton",
    "lng": -71.60728736086737,
    "lat": 42.43617022093121
  },
  {
    "name": "Bonanza",
    "lng": -106.14187257018516,
    "lat": 38.29660773118739
  },
  {
    "name": "Bonanza",
    "lng": -94.41649550703903,
    "lat": 35.233458028593596
  },
  {
    "name": "Bonaparte",
    "lng": -91.8002338543447,
    "lat": 40.70105650802288
  },
  {
    "name": "Bonduel",
    "lng": -88.44741909183003,
    "lat": 44.73836536679594
  },
  {
    "name": "Bondurant",
    "lng": -93.45513134912596,
    "lat": 41.69852869173935
  },
  {
    "name": "Bondville",
    "lng": -88.36829665674875,
    "lat": 40.11150321568988
  },
  {
    "name": "Bone Gap",
    "lng": -87.9974850580035,
    "lat": 38.44485578518478
  },
  {
    "name": "Bonesteel",
    "lng": -98.94676014735084,
    "lat": 43.07758237121726
  },
  {
    "name": "Bonfield",
    "lng": -88.05401024960189,
    "lat": 41.14505212260436
  },
  {
    "name": "Bonham",
    "lng": -96.19010137550312,
    "lat": 33.58802161877357
  },
  {
    "name": "Bonifay",
    "lng": -85.69600887905277,
    "lat": 30.8021617071343
  },
  {
    "name": "Bonifay",
    "lng": -85.69544419135441,
    "lat": 30.765051218052395
  },
  {
    "name": "Bonifay",
    "lng": -85.69518657167849,
    "lat": 30.80255937475266
  },
  {
    "name": "Bonifay",
    "lng": -85.69488035465729,
    "lat": 30.791367822369903
  },
  {
    "name": "Bonifay",
    "lng": -85.6818601146422,
    "lat": 30.786846482668576
  },
  {
    "name": "Bonifay",
    "lng": -85.6671791443231,
    "lat": 30.806219729317757
  },
  {
    "name": "Bonita",
    "lng": -91.67506597330163,
    "lat": 32.920511214468114
  },
  {
    "name": "Bonne Terre",
    "lng": -90.56016533298956,
    "lat": 37.934686960268394
  },
  {
    "name": "Bonne Terre",
    "lng": -90.54511073846523,
    "lat": 37.92052218537757
  },
  {
    "name": "Bonne Terre",
    "lng": -90.52155972662767,
    "lat": 37.917183544639315
  },
  {
    "name": "Bonne Terre",
    "lng": -90.5194940307398,
    "lat": 37.90663478761959
  },
  {
    "name": "Bonne Terre",
    "lng": -90.51538074967921,
    "lat": 37.925238962501695
  },
  {
    "name": "Bonneau",
    "lng": -79.95777763240294,
    "lat": 33.308430173902465
  },
  {
    "name": "Bonner Springs",
    "lng": -94.87767854775149,
    "lat": 39.08193459465866
  },
  {
    "name": "Bonners Ferry",
    "lng": -116.31829432827746,
    "lat": 48.69271910397398
  },
  {
    "name": "Bonney",
    "lng": -95.45085247349363,
    "lat": 29.301301327919795
  },
  {
    "name": "Bonney Lake",
    "lng": -122.2017337846614,
    "lat": 47.212760151327316
  },
  {
    "name": "Bonney Lake",
    "lng": -122.18564279803583,
    "lat": 47.14817275172517
  },
  {
    "name": "Bonney Lake",
    "lng": -122.16980215778497,
    "lat": 47.178394163365965
  },
  {
    "name": "Bonney Lake",
    "lng": -122.1444899580535,
    "lat": 47.17770669543069
  },
  {
    "name": "Bonnie",
    "lng": -88.90677282213953,
    "lat": 38.20300499450939
  },
  {
    "name": "Bonnieville",
    "lng": -85.9008165109245,
    "lat": 37.37728656714638
  },
  {
    "name": "Bono",
    "lng": -90.80078486553134,
    "lat": 35.91106475907965
  },
  {
    "name": "Booker",
    "lng": -100.54007663378667,
    "lat": 36.45600193275345
  },
  {
    "name": "Boone",
    "lng": -104.25789119355966,
    "lat": 38.24927783911847
  },
  {
    "name": "Boone",
    "lng": -93.89494174441089,
    "lat": 42.071225676007735
  },
  {
    "name": "Boone",
    "lng": -93.87710507985051,
    "lat": 42.05302685525877
  },
  {
    "name": "Boone",
    "lng": -81.71357201890505,
    "lat": 36.21988024094573
  },
  {
    "name": "Boone",
    "lng": -81.7130505475542,
    "lat": 36.21675747293159
  },
  {
    "name": "Boone",
    "lng": -81.71113904398801,
    "lat": 36.21479637048259
  },
  {
    "name": "Boone",
    "lng": -81.70304631718567,
    "lat": 36.22189347312059
  },
  {
    "name": "Boone",
    "lng": -81.66761341671997,
    "lat": 36.21065997672736
  },
  {
    "name": "Boone",
    "lng": -81.63439640716534,
    "lat": 36.22209383344579
  },
  {
    "name": "Boone",
    "lng": -81.63407254677374,
    "lat": 36.22520931109362
  },
  {
    "name": "Boone",
    "lng": -81.63094206182464,
    "lat": 36.18837502994588
  },
  {
    "name": "Boone",
    "lng": -81.61108811694866,
    "lat": 36.22636497667133
  },
  {
    "name": "Boones Mill",
    "lng": -79.95141485302089,
    "lat": 37.1155621305022
  },
  {
    "name": "Booneville",
    "lng": -93.91797580432578,
    "lat": 35.139170888351416
  },
  {
    "name": "Booneville",
    "lng": -88.56836534660187,
    "lat": 34.66436502332294
  },
  {
    "name": "Booneville",
    "lng": -83.67694750087253,
    "lat": 37.47485045503577
  },
  {
    "name": "Boonsboro",
    "lng": -77.65856589466688,
    "lat": 39.507587749579955
  },
  {
    "name": "Boonton",
    "lng": -74.40495048477628,
    "lat": 40.90468056752816
  },
  {
    "name": "Boonville",
    "lng": -123.37315976873572,
    "lat": 39.01126834889928
  },
  {
    "name": "Boonville",
    "lng": -92.75226658822723,
    "lat": 38.960630859958535
  },
  {
    "name": "Boonville",
    "lng": -87.32210310398858,
    "lat": 38.041621043957065
  },
  {
    "name": "Boonville",
    "lng": -87.27381674874664,
    "lat": 38.04594896456059
  },
  {
    "name": "Boonville",
    "lng": -87.24146880830014,
    "lat": 38.0358951887306
  },
  {
    "name": "Boonville",
    "lng": -80.70966751146717,
    "lat": 36.23355001183192
  },
  {
    "name": "Boonville",
    "lng": -75.33008359215127,
    "lat": 43.48114456000773
  },
  {
    "name": "Boothbay",
    "lng": -69.60256638132343,
    "lat": 43.81020613286007
  },
  {
    "name": "Boothbay Harbor",
    "lng": -69.63740365531511,
    "lat": 43.852220680907315
  },
  {
    "name": "Boothbay Harbor",
    "lng": -69.62333680814004,
    "lat": 43.85562137391147
  },
  {
    "name": "Boothwyn",
    "lng": -75.44802352715233,
    "lat": 39.84205221434157
  },
  {
    "name": "Borden",
    "lng": -85.949581488438,
    "lat": 38.47015536703817
  },
  {
    "name": "Bordentown City",
    "lng": -74.70836660826585,
    "lat": 40.149322405917694
  },
  {
    "name": "Borger",
    "lng": -101.40119858008744,
    "lat": 35.65984603757868
  },
  {
    "name": "Boron",
    "lng": -117.66587212981317,
    "lat": 35.0164150523724
  },
  {
    "name": "Borup",
    "lng": -96.5057521977714,
    "lat": 47.1802743466213
  },
  {
    "name": "Boscawen",
    "lng": -71.65304438166966,
    "lat": 43.3239140871667
  },
  {
    "name": "Boscobel",
    "lng": -90.70214189723738,
    "lat": 43.13863053177688
  },
  {
    "name": "Boscobel",
    "lng": -90.69048414696884,
    "lat": 43.13083973495832
  },
  {
    "name": "Boscobel",
    "lng": -90.67390293689061,
    "lat": 43.16114086091545
  },
  {
    "name": "Bosque Farms",
    "lng": -106.70149806274237,
    "lat": 34.853828983411326
  },
  {
    "name": "Bossier City",
    "lng": -93.71455287905489,
    "lat": 32.58959366042674
  },
  {
    "name": "Bossier City",
    "lng": -93.66641021729173,
    "lat": 32.52265256443439
  },
  {
    "name": "Bostic",
    "lng": -81.83535080284237,
    "lat": 35.3623093127576
  },
  {
    "name": "Boston",
    "lng": -84.8518072155061,
    "lat": 39.74110554349955
  },
  {
    "name": "Boston",
    "lng": -83.78999137205139,
    "lat": 30.79206631078338
  },
  {
    "name": "Boston Heights",
    "lng": -81.50873245792427,
    "lat": 41.25361179803252
  },
  {
    "name": "Bostwick",
    "lng": -83.5149597594312,
    "lat": 33.737801956261926
  },
  {
    "name": "Boswell",
    "lng": -95.86844924033505,
    "lat": 34.02806890689529
  },
  {
    "name": "Boswell",
    "lng": -87.38298370026908,
    "lat": 40.51877855134757
  },
  {
    "name": "Bosworth",
    "lng": -93.33555776486422,
    "lat": 39.469909269642095
  },
  {
    "name": "Bothell",
    "lng": -122.20437895645698,
    "lat": 47.773581717971965
  },
  {
    "name": "Botkins",
    "lng": -84.17815011235963,
    "lat": 40.458879551118414
  },
  {
    "name": "Bottineau",
    "lng": -100.44446049610383,
    "lat": 48.82521771312733
  },
  {
    "name": "Boulder",
    "lng": -112.12055301565833,
    "lat": 46.235731212889334
  },
  {
    "name": "Boulder",
    "lng": -111.43184212421883,
    "lat": 37.92280432229319
  },
  {
    "name": "Boulder",
    "lng": -105.26289920229549,
    "lat": 40.07633987667692
  },
  {
    "name": "Boulder",
    "lng": -105.25915649982201,
    "lat": 40.015890796481806
  },
  {
    "name": "Boulder",
    "lng": -105.24210936845434,
    "lat": 40.04252034018128
  },
  {
    "name": "Boulder",
    "lng": -105.21484424042637,
    "lat": 40.08154140346924
  },
  {
    "name": "Boulder",
    "lng": -105.21244941194395,
    "lat": 40.01752271034224
  },
  {
    "name": "Boulder",
    "lng": -105.20073741082889,
    "lat": 40.07058786722376
  },
  {
    "name": "Boulder City",
    "lng": -114.92839203971192,
    "lat": 35.83818779932696
  },
  {
    "name": "Bound Brook",
    "lng": -74.53843111655947,
    "lat": 40.56759138417785
  },
  {
    "name": "Bountiful",
    "lng": -111.86468021802985,
    "lat": 40.87217751596898
  },
  {
    "name": "Bourbon",
    "lng": -91.24923059643179,
    "lat": 38.150628946785446
  },
  {
    "name": "Bourbon",
    "lng": -86.11752436165983,
    "lat": 41.298125065027186
  },
  {
    "name": "Bourbonnais",
    "lng": -87.87809882557558,
    "lat": 41.18312060760227
  },
  {
    "name": "Bouton",
    "lng": -94.01029990259197,
    "lat": 41.85161031483221
  },
  {
    "name": "Bovey",
    "lng": -93.40355674797425,
    "lat": 47.29613833279516
  },
  {
    "name": "Bovill",
    "lng": -116.39355064439367,
    "lat": 46.858678883604796
  },
  {
    "name": "Bovina",
    "lng": -102.88465649368983,
    "lat": 34.515657638938734
  },
  {
    "name": "Bowbells",
    "lng": -102.24749597349619,
    "lat": 48.80353181093216
  },
  {
    "name": "Bowdle",
    "lng": -99.65647710595731,
    "lat": 45.45127130210168
  },
  {
    "name": "Bowdoin",
    "lng": -69.96939160519179,
    "lat": 44.05826595564544
  },
  {
    "name": "Bowdoinham",
    "lng": -69.86953616054006,
    "lat": 44.03466757885412
  },
  {
    "name": "Bowdon",
    "lng": -99.70881898666563,
    "lat": 47.46856212112643
  },
  {
    "name": "Bowdon",
    "lng": -85.25397589508113,
    "lat": 33.537838270878204
  },
  {
    "name": "Bowen",
    "lng": -91.06328189253142,
    "lat": 40.23205414741505
  },
  {
    "name": "Bowerbank",
    "lng": -69.25344758014772,
    "lat": 45.32150259552477
  },
  {
    "name": "Bowerston",
    "lng": -81.18738666711126,
    "lat": 40.42726196149536
  },
  {
    "name": "Bowersville",
    "lng": -83.72338747295022,
    "lat": 39.580901022770924
  },
  {
    "name": "Bowersville",
    "lng": -83.0832137936659,
    "lat": 34.372576773983084
  },
  {
    "name": "Bowie",
    "lng": -97.84403210233582,
    "lat": 33.55662164537939
  },
  {
    "name": "Bowie",
    "lng": -76.74087675987808,
    "lat": 38.956975096091696
  },
  {
    "name": "Bowlegs",
    "lng": -96.66894486267155,
    "lat": 35.147247257362366
  },
  {
    "name": "Bowler",
    "lng": -88.98186997173215,
    "lat": 44.86294997594716
  },
  {
    "name": "Bowling Green",
    "lng": -91.2336870515359,
    "lat": 39.349136564945226
  },
  {
    "name": "Bowling Green",
    "lng": -91.20014600897412,
    "lat": 39.344217071371986
  },
  {
    "name": "Bowling Green",
    "lng": -86.43904591245563,
    "lat": 36.97189359045244
  },
  {
    "name": "Bowling Green",
    "lng": -86.43284065650981,
    "lat": 37.01058766829839
  },
  {
    "name": "Bowling Green",
    "lng": -86.3413845773528,
    "lat": 37.03552663785009
  },
  {
    "name": "Bowling Green",
    "lng": -83.65003296953536,
    "lat": 41.37715444108652
  },
  {
    "name": "Bowling Green",
    "lng": -81.82452788121846,
    "lat": 27.637929591477736
  },
  {
    "name": "Bowling Green",
    "lng": -77.34724266711714,
    "lat": 38.05336439571836
  },
  {
    "name": "Bowlus",
    "lng": -94.40725559122146,
    "lat": 45.81904669627932
  },
  {
    "name": "Bowman",
    "lng": -103.40059229841246,
    "lat": 46.18339523015235
  },
  {
    "name": "Bowman",
    "lng": -83.02870975437098,
    "lat": 34.20367575834596
  },
  {
    "name": "Bowman",
    "lng": -80.68487431293896,
    "lat": 33.348457555031075
  },
  {
    "name": "Boxborough",
    "lng": -71.51779430830749,
    "lat": 42.48837835300289
  },
  {
    "name": "Boxholm",
    "lng": -94.10610505939059,
    "lat": 42.17362248870888
  },
  {
    "name": "Boy River",
    "lng": -94.12331636034531,
    "lat": 47.166946205565765
  },
  {
    "name": "Boyce",
    "lng": -92.66992752634089,
    "lat": 31.390620993597345
  },
  {
    "name": "Boyce",
    "lng": -78.06021251475202,
    "lat": 39.09319482235178
  },
  {
    "name": "Boyceville",
    "lng": -92.03839728287925,
    "lat": 45.04273902956647
  },
  {
    "name": "Boyd",
    "lng": -97.56799866069034,
    "lat": 33.08560316263833
  },
  {
    "name": "Boyd",
    "lng": -95.90110048564716,
    "lat": 44.85117391405708
  },
  {
    "name": "Boyd",
    "lng": -91.04088493179886,
    "lat": 44.951753871068306
  },
  {
    "name": "Boyden",
    "lng": -96.0031799420731,
    "lat": 43.189124720448234
  },
  {
    "name": "Boydton",
    "lng": -78.39073592265076,
    "lat": 36.666055226730975
  },
  {
    "name": "Boyertown",
    "lng": -75.63767864582327,
    "lat": 40.33238908063135
  },
  {
    "name": "Boyette",
    "lng": -82.28199968901494,
    "lat": 27.837702555138193
  },
  {
    "name": "Boykins",
    "lng": -77.19892442271743,
    "lat": 36.57781324971938
  },
  {
    "name": "Boyle",
    "lng": -90.72487063559244,
    "lat": 33.70647599462989
  },
  {
    "name": "Boylston",
    "lng": -71.72122885243506,
    "lat": 42.35633954023503
  },
  {
    "name": "Boyne City",
    "lng": -85.01299658917908,
    "lat": 45.213724267674095
  },
  {
    "name": "Boyne Falls",
    "lng": -84.91273360302009,
    "lat": 45.167595341781045
  },
  {
    "name": "Boynton",
    "lng": -95.65425342342473,
    "lat": 35.6494247660432
  },
  {
    "name": "Boynton Beach",
    "lng": -80.08113546894533,
    "lat": 26.52814262763589
  },
  {
    "name": "Boys Town",
    "lng": -96.1334696332154,
    "lat": 41.256103799286805
  },
  {
    "name": "Bozeman",
    "lng": -111.0817039663061,
    "lat": 45.679698631825616
  },
  {
    "name": "Bozeman",
    "lng": -111.06335010996379,
    "lat": 45.722791299951155
  },
  {
    "name": "Bozeman",
    "lng": -111.05936709596807,
    "lat": 45.72043413238385
  },
  {
    "name": "Bozeman",
    "lng": -111.05551878886361,
    "lat": 45.68377501593807
  },
  {
    "name": "Bozrah",
    "lng": -72.1749262638859,
    "lat": 41.5453406329383
  },
  {
    "name": "Braceville",
    "lng": -88.2464321082797,
    "lat": 41.22798286468765
  },
  {
    "name": "Brackettville",
    "lng": -100.41090520190745,
    "lat": 29.318086860114615
  },
  {
    "name": "Bradbury",
    "lng": -117.96818301420595,
    "lat": 34.153481311388354
  },
  {
    "name": "Braddock",
    "lng": -100.08974890507496,
    "lat": 46.56390662281895
  },
  {
    "name": "Braddyville",
    "lng": -95.03115938942638,
    "lat": 40.58066306055224
  },
  {
    "name": "Braden",
    "lng": -89.57144159668574,
    "lat": 35.36845224399348
  },
  {
    "name": "Bradenton",
    "lng": -82.68963390855815,
    "lat": 27.48193148657476
  },
  {
    "name": "Bradenton",
    "lng": -82.68938837403466,
    "lat": 27.48371073136047
  },
  {
    "name": "Bradenton",
    "lng": -82.68920835363132,
    "lat": 27.488721477585432
  },
  {
    "name": "Bradenton",
    "lng": -82.68867053354236,
    "lat": 27.486674845505224
  },
  {
    "name": "Bradenton",
    "lng": -82.68741500921962,
    "lat": 27.51395753056619
  },
  {
    "name": "Bradenton",
    "lng": -82.68740990081535,
    "lat": 27.499149126108705
  },
  {
    "name": "Bradenton",
    "lng": -82.68594432662069,
    "lat": 27.488809790849736
  },
  {
    "name": "Bradenton",
    "lng": -82.68582234877015,
    "lat": 27.490595468686404
  },
  {
    "name": "Bradenton",
    "lng": -82.68577646445223,
    "lat": 27.487953909745617
  },
  {
    "name": "Bradenton",
    "lng": -82.68545043446504,
    "lat": 27.491207612955055
  },
  {
    "name": "Bradenton",
    "lng": -82.6853328183862,
    "lat": 27.48993791153918
  },
  {
    "name": "Bradenton",
    "lng": -82.68509641098332,
    "lat": 27.491671709334394
  },
  {
    "name": "Bradenton",
    "lng": -82.68469798538324,
    "lat": 27.488862517417594
  },
  {
    "name": "Bradenton",
    "lng": -82.68462615479702,
    "lat": 27.492441182285024
  },
  {
    "name": "Bradenton",
    "lng": -82.684120149814,
    "lat": 27.490871999792326
  },
  {
    "name": "Bradenton",
    "lng": -82.67964623756787,
    "lat": 27.497606832735066
  },
  {
    "name": "Bradenton",
    "lng": -82.61947149003525,
    "lat": 27.496544610238132
  },
  {
    "name": "Bradenton",
    "lng": -82.60421550034972,
    "lat": 27.4924233841175
  },
  {
    "name": "Bradenton",
    "lng": -82.58411802588462,
    "lat": 27.4836286830679
  },
  {
    "name": "Bradenton",
    "lng": -82.5229592732414,
    "lat": 27.496352402933972
  },
  {
    "name": "Bradenton",
    "lng": -82.51991962177338,
    "lat": 27.495694892740676
  },
  {
    "name": "Bradenton",
    "lng": -82.51830865543442,
    "lat": 27.49721901226324
  },
  {
    "name": "Bradenton",
    "lng": -82.51584706877544,
    "lat": 27.487529115143705
  },
  {
    "name": "Bradenton",
    "lng": -82.51557349752919,
    "lat": 27.495779830239147
  },
  {
    "name": "Bradenton",
    "lng": -82.51542307438653,
    "lat": 27.490996018690666
  },
  {
    "name": "Bradenton",
    "lng": -82.51408625593292,
    "lat": 27.491070095267123
  },
  {
    "name": "Bradenton",
    "lng": -82.51371868147992,
    "lat": 27.490335378461456
  },
  {
    "name": "Bradenton",
    "lng": -82.51334642802112,
    "lat": 27.49009385292642
  },
  {
    "name": "Bradenton",
    "lng": -82.51309868630236,
    "lat": 27.488782033398053
  },
  {
    "name": "Bradenton",
    "lng": -82.51215939600446,
    "lat": 27.487782400644363
  },
  {
    "name": "Bradenton",
    "lng": -82.51196693301809,
    "lat": 27.490102642210015
  },
  {
    "name": "Bradenton",
    "lng": -82.5113632982575,
    "lat": 27.488338842636157
  },
  {
    "name": "Bradenton",
    "lng": -82.51102422543985,
    "lat": 27.486532561732773
  },
  {
    "name": "Bradenton",
    "lng": -82.50998766336924,
    "lat": 27.487009397565224
  },
  {
    "name": "Bradenton",
    "lng": -82.50977458306451,
    "lat": 27.488875238280112
  },
  {
    "name": "Bradenton",
    "lng": -82.50739685646516,
    "lat": 27.485736487061995
  },
  {
    "name": "Bradenton",
    "lng": -82.50482186733919,
    "lat": 27.484035930749915
  },
  {
    "name": "Bradenton",
    "lng": -82.50460421731005,
    "lat": 27.515110340376282
  },
  {
    "name": "Bradenton",
    "lng": -82.50439705496251,
    "lat": 27.485353987344183
  },
  {
    "name": "Bradenton",
    "lng": -82.5029902820048,
    "lat": 27.49752991406007
  },
  {
    "name": "Bradenton",
    "lng": -82.50289152682082,
    "lat": 27.484414230133517
  },
  {
    "name": "Bradenton",
    "lng": -82.50086913775803,
    "lat": 27.48448282113238
  },
  {
    "name": "Bradenton",
    "lng": -82.49925235828486,
    "lat": 27.484917876599905
  },
  {
    "name": "Bradenton",
    "lng": -82.49627619197534,
    "lat": 27.51699343632263
  },
  {
    "name": "Bradford",
    "lng": -91.45529438364855,
    "lat": 35.42205764381814
  },
  {
    "name": "Bradford",
    "lng": -89.65820991462267,
    "lat": 41.17717864679309
  },
  {
    "name": "Bradford",
    "lng": -88.81563442358605,
    "lat": 36.07405103568246
  },
  {
    "name": "Bradford",
    "lng": -84.42949315898562,
    "lat": 40.13146920292109
  },
  {
    "name": "Bradford",
    "lng": -78.64144027300686,
    "lat": 41.96031552826645
  },
  {
    "name": "Bradford",
    "lng": -71.96894014577558,
    "lat": 43.234944129722535
  },
  {
    "name": "Bradford",
    "lng": -68.90668217882607,
    "lat": 45.08829488548796
  },
  {
    "name": "Bradfordsville",
    "lng": -85.14848479507864,
    "lat": 37.49455490059003
  },
  {
    "name": "Bradgate",
    "lng": -94.42008607782871,
    "lat": 42.80310664222773
  },
  {
    "name": "Bradley",
    "lng": -97.70863892455954,
    "lat": 34.87791642458365
  },
  {
    "name": "Bradley",
    "lng": -97.64194463543173,
    "lat": 45.09040771155976
  },
  {
    "name": "Bradley",
    "lng": -93.65692727741686,
    "lat": 33.099340461660525
  },
  {
    "name": "Bradley",
    "lng": -87.84502602039066,
    "lat": 41.164290902777964
  },
  {
    "name": "Bradley",
    "lng": -68.56670705251697,
    "lat": 44.886397567288
  },
  {
    "name": "Bradleyville",
    "lng": -92.91746495265535,
    "lat": 36.78597442854729
  },
  {
    "name": "Bradner",
    "lng": -83.43630861487676,
    "lat": 41.32368042738839
  },
  {
    "name": "Bradshaw",
    "lng": -97.74704681040294,
    "lat": 40.88348598111447
  },
  {
    "name": "Bradshaw",
    "lng": -81.81236152391641,
    "lat": 37.34221099141282
  },
  {
    "name": "Bradshaw",
    "lng": -81.80117190755061,
    "lat": 37.353375658830046
  },
  {
    "name": "Brady",
    "lng": -100.36741483110872,
    "lat": 41.02240277455828
  },
  {
    "name": "Brady",
    "lng": -99.37033534374932,
    "lat": 31.132113471471904
  },
  {
    "name": "Bragg City",
    "lng": -89.91129315524495,
    "lat": 36.26813901582424
  },
  {
    "name": "Braggs",
    "lng": -95.1985205738663,
    "lat": 35.663104622056544
  },
  {
    "name": "Braham",
    "lng": -93.17157059913183,
    "lat": 45.72224960980416
  },
  {
    "name": "Braidwood",
    "lng": -88.22897325906503,
    "lat": 41.28956552749857
  },
  {
    "name": "Braidwood",
    "lng": -88.22789375082567,
    "lat": 41.28736283590638
  },
  {
    "name": "Braidwood",
    "lng": -88.22053728646615,
    "lat": 41.28753106150621
  },
  {
    "name": "Braidwood",
    "lng": -88.22011786235969,
    "lat": 41.27069603357584
  },
  {
    "name": "Braidwood",
    "lng": -88.21155342822362,
    "lat": 41.2872563400153
  },
  {
    "name": "Brainard",
    "lng": -97.00224239330515,
    "lat": 41.182345740153565
  },
  {
    "name": "Brainerd",
    "lng": -94.20629242358778,
    "lat": 46.36747162548442
  },
  {
    "name": "Brainerd",
    "lng": -94.20000248934537,
    "lat": 46.354346107138674
  },
  {
    "name": "Brainerd",
    "lng": -94.19864055571367,
    "lat": 46.3710740017039
  },
  {
    "name": "Brainerd",
    "lng": -94.16226157329864,
    "lat": 46.37966144200374
  },
  {
    "name": "Brainerd",
    "lng": -94.16135283198236,
    "lat": 46.37251584917994
  },
  {
    "name": "Brainerd",
    "lng": -94.16079043231973,
    "lat": 46.37968856712134
  },
  {
    "name": "Brainerd",
    "lng": -94.15990831488186,
    "lat": 46.37213121117058
  },
  {
    "name": "Brainerd",
    "lng": -94.15983603325111,
    "lat": 46.37341652265364
  },
  {
    "name": "Brainerd",
    "lng": -94.15943607930865,
    "lat": 46.37943469626589
  },
  {
    "name": "Brainerd",
    "lng": -94.15871059745123,
    "lat": 46.379597879174774
  },
  {
    "name": "Brainerd",
    "lng": -94.15278802697917,
    "lat": 46.381639698694926
  },
  {
    "name": "Brainerd",
    "lng": -94.15115187318129,
    "lat": 46.37556102455219
  },
  {
    "name": "Braman",
    "lng": -97.33418089167375,
    "lat": 36.92337902298534
  },
  {
    "name": "Bramwell",
    "lng": -81.31290620961857,
    "lat": 37.32698476126747
  },
  {
    "name": "Branch",
    "lng": -93.95574773711218,
    "lat": 35.30753616633646
  },
  {
    "name": "Branchville",
    "lng": -80.81587479677968,
    "lat": 33.2515000472816
  },
  {
    "name": "Branchville",
    "lng": -77.25011342882438,
    "lat": 36.569805421688514
  },
  {
    "name": "Brandenburg",
    "lng": -86.17750219201685,
    "lat": 37.99590309228765
  },
  {
    "name": "Brandenton Beach",
    "lng": -82.69614328536437,
    "lat": 27.465124519429146
  },
  {
    "name": "Brandon",
    "lng": -96.6165161699147,
    "lat": 43.591776234303445
  },
  {
    "name": "Brandon",
    "lng": -96.57689022880682,
    "lat": 43.59284687542131
  },
  {
    "name": "Brandon",
    "lng": -95.59445016077771,
    "lat": 45.9663226494855
  },
  {
    "name": "Brandon",
    "lng": -92.00278267539856,
    "lat": 42.31466934308249
  },
  {
    "name": "Brandon",
    "lng": -88.78199449632146,
    "lat": 43.73520763994645
  },
  {
    "name": "Brandonville",
    "lng": -79.62766795092308,
    "lat": 39.66706128731348
  },
  {
    "name": "Brandsville",
    "lng": -91.69645703127992,
    "lat": 36.650740325567774
  },
  {
    "name": "Brandt",
    "lng": -96.62515601185552,
    "lat": 44.66693347880419
  },
  {
    "name": "Branson",
    "lng": -103.88377716776864,
    "lat": 37.015492678000854
  },
  {
    "name": "Branson",
    "lng": -93.3084697866552,
    "lat": 36.614623284669875
  },
  {
    "name": "Branson",
    "lng": -93.28827337406175,
    "lat": 36.629368425424204
  },
  {
    "name": "Branson",
    "lng": -93.27847257239681,
    "lat": 36.612193962640966
  },
  {
    "name": "Branson",
    "lng": -93.27573675674756,
    "lat": 36.6106778336628
  },
  {
    "name": "Branson",
    "lng": -93.27540360645577,
    "lat": 36.62016732603398
  },
  {
    "name": "Branson",
    "lng": -93.27415679860415,
    "lat": 36.62068124034278
  },
  {
    "name": "Branson",
    "lng": -93.26193315292862,
    "lat": 36.65063676743434
  },
  {
    "name": "Branson",
    "lng": -93.25069212884299,
    "lat": 36.67175769484663
  },
  {
    "name": "Branson West",
    "lng": -93.37297527661775,
    "lat": 36.7022845238055
  },
  {
    "name": "Brantley",
    "lng": -86.25692352494313,
    "lat": 31.582248481397055
  },
  {
    "name": "Braselton",
    "lng": -83.87980593136665,
    "lat": 34.10896741725701
  },
  {
    "name": "Braselton",
    "lng": -83.80799560089643,
    "lat": 34.10862003235099
  },
  {
    "name": "Brashear",
    "lng": -92.37884561253951,
    "lat": 40.14833967149915
  },
  {
    "name": "Brasher Falls",
    "lng": -74.79294100118742,
    "lat": 44.804054288853656
  },
  {
    "name": "Braswell",
    "lng": -84.95904939985503,
    "lat": 33.983623217747976
  },
  {
    "name": "Bratenahl",
    "lng": -81.60565471784729,
    "lat": 41.55599798262962
  },
  {
    "name": "Bravo Base Camp",
    "lng": -81.11487195737226,
    "lat": 37.91964887658738
  },
  {
    "name": "Brawley",
    "lng": -115.53005587249699,
    "lat": 32.978287061657795
  },
  {
    "name": "Brawley",
    "lng": -115.51602699603998,
    "lat": 33.018811996709296
  },
  {
    "name": "Braxton",
    "lng": -89.97152980306197,
    "lat": 32.02639120721155
  },
  {
    "name": "Bray",
    "lng": -97.82071985287398,
    "lat": 34.616029989578024
  },
  {
    "name": "Braymer",
    "lng": -93.7961221671771,
    "lat": 39.59051218064802
  },
  {
    "name": "Brayton",
    "lng": -94.93031832403442,
    "lat": 41.54462139686719
  },
  {
    "name": "Brazil",
    "lng": -87.12412812127329,
    "lat": 39.522496381099046
  },
  {
    "name": "Brazoria",
    "lng": -95.56760448319773,
    "lat": 29.046312674023273
  },
  {
    "name": "Brazos Bend",
    "lng": -97.76104104754893,
    "lat": 32.47518190732011
  },
  {
    "name": "Brazos Country",
    "lng": -96.04227160128862,
    "lat": 29.754734859587508
  },
  {
    "name": "Brea",
    "lng": -117.86537131784532,
    "lat": 33.925345038021284
  },
  {
    "name": "Breaux Bridge",
    "lng": -91.90435540801235,
    "lat": 30.282898090438508
  },
  {
    "name": "Breckenridge",
    "lng": -106.04333651282086,
    "lat": 39.49936433537395
  },
  {
    "name": "Breckenridge",
    "lng": -98.91221813706137,
    "lat": 32.75659398562348
  },
  {
    "name": "Breckenridge",
    "lng": -97.72724057754186,
    "lat": 36.462212390394086
  },
  {
    "name": "Breckenridge",
    "lng": -97.72061587811093,
    "lat": 36.418330159947864
  },
  {
    "name": "Breckenridge",
    "lng": -97.72025415681317,
    "lat": 36.41385364020417
  },
  {
    "name": "Breckenridge",
    "lng": -96.5852744362483,
    "lat": 46.2660208301452
  },
  {
    "name": "Breckenridge",
    "lng": -93.80464912772675,
    "lat": 39.761442447552014
  },
  {
    "name": "Breckenridge",
    "lng": -84.47839882308226,
    "lat": 43.40748335361557
  },
  {
    "name": "Breckenridge Hills",
    "lng": -90.3683929489466,
    "lat": 38.71575510848393
  },
  {
    "name": "Brecksville",
    "lng": -81.61933224653242,
    "lat": 41.30791426414081
  },
  {
    "name": "Breda",
    "lng": -94.97536752249094,
    "lat": 42.183518144357734
  },
  {
    "name": "Breedsville",
    "lng": -86.07174373934264,
    "lat": 42.34887882290301
  },
  {
    "name": "Breese",
    "lng": -89.5503468825436,
    "lat": 38.60989395426253
  },
  {
    "name": "Breese",
    "lng": -89.546545312096,
    "lat": 38.61082866604886
  },
  {
    "name": "Breese",
    "lng": -89.5443243077281,
    "lat": 38.610615289824054
  },
  {
    "name": "Breese",
    "lng": -89.54263624971462,
    "lat": 38.610251106778065
  },
  {
    "name": "Breese",
    "lng": -89.52228379005163,
    "lat": 38.61400973735037
  },
  {
    "name": "Breezy Point",
    "lng": -94.21726420569003,
    "lat": 46.608278819264925
  },
  {
    "name": "Bremen",
    "lng": -87.22030341840913,
    "lat": 37.36234124751105
  },
  {
    "name": "Bremen",
    "lng": -86.15216096548647,
    "lat": 41.44718852518147
  },
  {
    "name": "Bremen",
    "lng": -85.17117680481427,
    "lat": 33.7225923438793
  },
  {
    "name": "Bremen",
    "lng": -85.16718293862644,
    "lat": 33.708617903574314
  },
  {
    "name": "Bremen",
    "lng": -85.14936688736161,
    "lat": 33.70885332536516
  },
  {
    "name": "Bremen",
    "lng": -85.14268502930753,
    "lat": 33.74313007855566
  },
  {
    "name": "Bremen",
    "lng": -82.42958270415518,
    "lat": 39.70633295182776
  },
  {
    "name": "Bremen",
    "lng": -69.42179566567317,
    "lat": 43.997332889731034
  },
  {
    "name": "Bremerton",
    "lng": -122.77563938066709,
    "lat": 47.5652597299461
  },
  {
    "name": "Bremerton",
    "lng": -122.76773937292867,
    "lat": 47.56785978442735
  },
  {
    "name": "Bremerton",
    "lng": -122.71244011117172,
    "lat": 47.54412804911804
  },
  {
    "name": "Bremerton",
    "lng": -122.68774602700444,
    "lat": 47.51989583818159
  },
  {
    "name": "Bremond",
    "lng": -96.67597455836012,
    "lat": 31.165709633494238
  },
  {
    "name": "Brenham",
    "lng": -96.39619786299174,
    "lat": 30.158420881268153
  },
  {
    "name": "Brentford",
    "lng": -98.32249902616525,
    "lat": 45.15919569095244
  },
  {
    "name": "Brentwood",
    "lng": -121.71889144172819,
    "lat": 37.93557026592123
  },
  {
    "name": "Brentwood",
    "lng": -90.34759154357788,
    "lat": 38.6193431746836
  },
  {
    "name": "Brentwood",
    "lng": -86.77581207394384,
    "lat": 35.99176073763906
  },
  {
    "name": "Brentwood",
    "lng": -76.95706344944337,
    "lat": 38.94392786916899
  },
  {
    "name": "Brentwood",
    "lng": -71.04480160348864,
    "lat": 42.98896609804717
  },
  {
    "name": "Brevard",
    "lng": -82.78689504483219,
    "lat": 35.21244508646796
  },
  {
    "name": "Brevard",
    "lng": -82.75170368909274,
    "lat": 35.242674448024914
  },
  {
    "name": "Brevard",
    "lng": -82.7501370441935,
    "lat": 35.21747126897074
  },
  {
    "name": "Brevard",
    "lng": -82.74565149801259,
    "lat": 35.22606282630239
  },
  {
    "name": "Brevard",
    "lng": -82.74480881106683,
    "lat": 35.24179465123552
  },
  {
    "name": "Brevard",
    "lng": -82.74427436666667,
    "lat": 35.241386099725396
  },
  {
    "name": "Brevard",
    "lng": -82.74350240550501,
    "lat": 35.21893160163274
  },
  {
    "name": "Brevard",
    "lng": -82.7393891999125,
    "lat": 35.210201478887484
  },
  {
    "name": "Brevard",
    "lng": -82.73520935046879,
    "lat": 35.247340132025705
  },
  {
    "name": "Brevard",
    "lng": -82.73459395380515,
    "lat": 35.24513686456653
  },
  {
    "name": "Brevard",
    "lng": -82.72494882066158,
    "lat": 35.244793573050764
  },
  {
    "name": "Brevard",
    "lng": -82.71479936061411,
    "lat": 35.27078770550947
  },
  {
    "name": "Brevard",
    "lng": -82.69774797933786,
    "lat": 35.26969573278003
  },
  {
    "name": "Brevard",
    "lng": -82.69685462354525,
    "lat": 35.27203744480119
  },
  {
    "name": "Brevard",
    "lng": -82.69654363289202,
    "lat": 35.25065414595276
  },
  {
    "name": "Brevard",
    "lng": -82.69536713390067,
    "lat": 35.27197461450188
  },
  {
    "name": "Brevard",
    "lng": -82.69283431113413,
    "lat": 35.27019302977125
  },
  {
    "name": "Brevard",
    "lng": -82.68507208310128,
    "lat": 35.268562083219315
  },
  {
    "name": "Brevig Mission",
    "lng": -166.4934292632057,
    "lat": 65.34149473642672
  },
  {
    "name": "Brewer",
    "lng": -68.73511128014876,
    "lat": 44.78362167821593
  },
  {
    "name": "Brewster",
    "lng": -119.78092577668455,
    "lat": 48.10313284907275
  },
  {
    "name": "Brewster",
    "lng": -119.71954347795013,
    "lat": 48.10454583651447
  },
  {
    "name": "Brewster",
    "lng": -101.37711662665292,
    "lat": 39.36305011387513
  },
  {
    "name": "Brewster",
    "lng": -99.86499361062758,
    "lat": 41.93827308440739
  },
  {
    "name": "Brewster",
    "lng": -95.46460269429583,
    "lat": 43.697211473690764
  },
  {
    "name": "Brewster",
    "lng": -81.59889384759997,
    "lat": 40.71361741137377
  },
  {
    "name": "Brewster",
    "lng": -73.61564775239941,
    "lat": 41.39720396824169
  },
  {
    "name": "Brewton",
    "lng": -87.07464917339175,
    "lat": 31.11772944435069
  },
  {
    "name": "Brewton",
    "lng": -87.06449288482698,
    "lat": 31.048625535589224
  },
  {
    "name": "Brian Head",
    "lng": -112.84157079567518,
    "lat": 37.6977240264103
  },
  {
    "name": "Briarcliff",
    "lng": -98.04604396643971,
    "lat": 30.409188865567344
  },
  {
    "name": "Briarcliff",
    "lng": -92.28234268194788,
    "lat": 36.2721632942942
  },
  {
    "name": "Briarcliff Manor",
    "lng": -73.84546030130942,
    "lat": 41.1396599762488
  },
  {
    "name": "Briarcliffe Acres",
    "lng": -78.74388648441534,
    "lat": 33.78608021009645
  },
  {
    "name": "Briaroaks",
    "lng": -97.30332300429349,
    "lat": 32.49578707722673
  },
  {
    "name": "Briarwood",
    "lng": -85.59212159492013,
    "lat": 38.27799254953674
  },
  {
    "name": "Brice",
    "lng": -82.83181796165432,
    "lat": 39.91730535125879
  },
  {
    "name": "Bricelyn",
    "lng": -93.81317740975814,
    "lat": 43.56071192602052
  },
  {
    "name": "Bridge City",
    "lng": -93.84834335935808,
    "lat": 30.07659416119035
  },
  {
    "name": "Bridge City",
    "lng": -93.83975165909642,
    "lat": 30.031411345707475
  },
  {
    "name": "Bridge Creek",
    "lng": -97.7367881935831,
    "lat": 35.23491359527889
  },
  {
    "name": "Bridgeport",
    "lng": -119.67314783683608,
    "lat": 48.006133377064664
  },
  {
    "name": "Bridgeport",
    "lng": -103.09541889946728,
    "lat": 41.663017481702624
  },
  {
    "name": "Bridgeport",
    "lng": -98.37747374264487,
    "lat": 35.54758964486
  },
  {
    "name": "Bridgeport",
    "lng": -97.77126450129559,
    "lat": 33.21238376384092
  },
  {
    "name": "Bridgeport",
    "lng": -87.75910543669373,
    "lat": 38.70942388008213
  },
  {
    "name": "Bridgeport",
    "lng": -80.7474923268018,
    "lat": 40.06703008682844
  },
  {
    "name": "Bridgeport",
    "lng": -80.24657291685986,
    "lat": 39.30158293814008
  },
  {
    "name": "Bridgeport",
    "lng": -73.19549929659209,
    "lat": 41.18705693866094
  },
  {
    "name": "Bridger",
    "lng": -108.91502426385944,
    "lat": 45.29369645424378
  },
  {
    "name": "Bridgeton",
    "lng": -90.4274078094143,
    "lat": 38.76722163512202
  },
  {
    "name": "Bridgeton",
    "lng": -77.02903173559406,
    "lat": 35.130663676154754
  },
  {
    "name": "Bridgeton",
    "lng": -77.02702656850913,
    "lat": 35.13107857552323
  },
  {
    "name": "Bridgeton",
    "lng": -77.02647923395057,
    "lat": 35.13053663623158
  },
  {
    "name": "Bridgeton",
    "lng": -77.02134014685468,
    "lat": 35.123234307335224
  },
  {
    "name": "Bridgeton",
    "lng": -77.01527016793528,
    "lat": 35.122993614583905
  },
  {
    "name": "Bridgeton",
    "lng": -75.22796808160746,
    "lat": 39.42851510429923
  },
  {
    "name": "Bridgeview",
    "lng": -87.8066233707396,
    "lat": 41.740201673868775
  },
  {
    "name": "Bridgewater",
    "lng": -97.49838450733924,
    "lat": 43.55021259538373
  },
  {
    "name": "Bridgewater",
    "lng": -94.66828930751868,
    "lat": 41.246985217346264
  },
  {
    "name": "Bridgewater",
    "lng": -78.96746199656441,
    "lat": 38.386261878029714
  },
  {
    "name": "Bridgewater",
    "lng": -75.2509864537175,
    "lat": 42.87754997615995
  },
  {
    "name": "Bridgewater",
    "lng": -73.3601646282323,
    "lat": 41.52091156388853
  },
  {
    "name": "Bridgewater",
    "lng": -67.84730600954168,
    "lat": 46.41908932459288
  },
  {
    "name": "Bridgman",
    "lng": -86.56536583979553,
    "lat": 41.93967386824427
  },
  {
    "name": "Bridgton",
    "lng": -70.73616797789518,
    "lat": 44.048195707537836
  },
  {
    "name": "Brier",
    "lng": -122.27354497177306,
    "lat": 47.792423662060905
  },
  {
    "name": "Brigantine",
    "lng": -74.37570870046011,
    "lat": 39.410489814519494
  },
  {
    "name": "Brigham City",
    "lng": -112.0453128010093,
    "lat": 41.50320627398693
  },
  {
    "name": "Brighton",
    "lng": -104.83728749221159,
    "lat": 39.916175846628015
  },
  {
    "name": "Brighton",
    "lng": -104.83628613231242,
    "lat": 39.90868049484017
  },
  {
    "name": "Brighton",
    "lng": -104.83116008644713,
    "lat": 39.989592735403185
  },
  {
    "name": "Brighton",
    "lng": -104.81717147712085,
    "lat": 40.007938804737975
  },
  {
    "name": "Brighton",
    "lng": -104.81111608242003,
    "lat": 39.91663221793661
  },
  {
    "name": "Brighton",
    "lng": -104.81035504621825,
    "lat": 39.915096933743826
  },
  {
    "name": "Brighton",
    "lng": -104.79533185018043,
    "lat": 39.92925260683044
  },
  {
    "name": "Brighton",
    "lng": -104.79302881221042,
    "lat": 39.973684929985865
  },
  {
    "name": "Brighton",
    "lng": -91.82121318099674,
    "lat": 41.17454490678587
  },
  {
    "name": "Brighton",
    "lng": -90.14053523269006,
    "lat": 39.040374970017695
  },
  {
    "name": "Brighton",
    "lng": -89.73379819771144,
    "lat": 35.48153313653611
  },
  {
    "name": "Brighton",
    "lng": -86.94539238947868,
    "lat": 33.43922516917517
  },
  {
    "name": "Brighton",
    "lng": -83.78475359171219,
    "lat": 42.529804516342885
  },
  {
    "name": "Brightwaters",
    "lng": -73.25788403586996,
    "lat": 40.71689196552666
  },
  {
    "name": "Brilliant",
    "lng": -87.77409870209308,
    "lat": 34.00941871024915
  },
  {
    "name": "Brillion",
    "lng": -88.09343957799365,
    "lat": 44.1906641435783
  },
  {
    "name": "Brillion",
    "lng": -88.07053509951473,
    "lat": 44.175536225090674
  },
  {
    "name": "Brimfield",
    "lng": -89.8901374665273,
    "lat": 40.83193926357354
  },
  {
    "name": "Brimfield",
    "lng": -89.88305262046207,
    "lat": 40.83799826026652
  },
  {
    "name": "Brimfield",
    "lng": -72.20621547648291,
    "lat": 42.12864336928146
  },
  {
    "name": "Brimson",
    "lng": -93.73837546572591,
    "lat": 40.14501369397227
  },
  {
    "name": "Brinkhaven",
    "lng": -82.19137459697734,
    "lat": 40.46922542367074
  },
  {
    "name": "Brinkley",
    "lng": -91.19016989412069,
    "lat": 34.88986097184882
  },
  {
    "name": "Brinsmade",
    "lng": -99.3240277229778,
    "lat": 48.18360623955141
  },
  {
    "name": "Brinson",
    "lng": -84.73804184915835,
    "lat": 30.97945275166953
  },
  {
    "name": "Bristol",
    "lng": -97.74850512223529,
    "lat": 45.34665682573715
  },
  {
    "name": "Bristol",
    "lng": -85.82157369031525,
    "lat": 41.71979727592782
  },
  {
    "name": "Bristol",
    "lng": -82.21447760992054,
    "lat": 36.55781799059038
  },
  {
    "name": "Bristol",
    "lng": -82.1606763829822,
    "lat": 36.61812397759885
  },
  {
    "name": "Bristol",
    "lng": -82.15737006662978,
    "lat": 36.548159440516066
  },
  {
    "name": "Bristol",
    "lng": -72.94064212939111,
    "lat": 41.681110375969844
  },
  {
    "name": "Bristol",
    "lng": -71.28893282383929,
    "lat": 41.73354646970821
  },
  {
    "name": "Bristol",
    "lng": -71.26977909290281,
    "lat": 41.68228367207692
  },
  {
    "name": "Bristol",
    "lng": -69.46623189840628,
    "lat": 43.88829729445415
  },
  {
    "name": "Bristow",
    "lng": -98.58332263882555,
    "lat": 42.840764520246225
  },
  {
    "name": "Bristow",
    "lng": -96.3937200655556,
    "lat": 35.833817985079016
  },
  {
    "name": "Bristow",
    "lng": -92.90793787729321,
    "lat": 42.77380463990324
  },
  {
    "name": "Britt",
    "lng": -93.8030179165826,
    "lat": 43.09859637629678
  },
  {
    "name": "Britton",
    "lng": -97.75259060694408,
    "lat": 45.792654170021066
  },
  {
    "name": "Britton",
    "lng": -83.83152429757344,
    "lat": 41.987547490762154
  },
  {
    "name": "Broad Top City",
    "lng": -78.14056806380655,
    "lat": 40.20118159543748
  },
  {
    "name": "Broadalbin",
    "lng": -74.19585363069359,
    "lat": 43.059624039026154
  },
  {
    "name": "Broaddus",
    "lng": -94.27003949500421,
    "lat": 31.304974983238964
  },
  {
    "name": "Broadland",
    "lng": -98.3465562453367,
    "lat": 44.49382570118324
  },
  {
    "name": "Broadlands",
    "lng": -87.99711728461102,
    "lat": 39.90872634666394
  },
  {
    "name": "Broadmoor",
    "lng": -122.48134071675427,
    "lat": 37.69150485351486
  },
  {
    "name": "Broadmoor",
    "lng": -122.47173052384255,
    "lat": 37.68931958121129
  },
  {
    "name": "Broadus",
    "lng": -105.4082125285922,
    "lat": 45.443068082869175
  },
  {
    "name": "Broadview",
    "lng": -108.87886334043542,
    "lat": 46.09879069296997
  },
  {
    "name": "Broadview",
    "lng": -87.856185988766,
    "lat": 41.85833247964973
  },
  {
    "name": "Broadview Heights",
    "lng": -81.67818045902665,
    "lat": 41.3195276420809
  },
  {
    "name": "Broadwater",
    "lng": -102.85239950289524,
    "lat": 41.597945674818774
  },
  {
    "name": "Broadway",
    "lng": -79.05477365230983,
    "lat": 35.4579602714704
  },
  {
    "name": "Broadway",
    "lng": -78.80147855075718,
    "lat": 38.60829373686777
  },
  {
    "name": "Broadwell",
    "lng": -89.44286129391845,
    "lat": 40.06686649287994
  },
  {
    "name": "Brock",
    "lng": -95.96005279866422,
    "lat": 40.48047555393957
  },
  {
    "name": "Brocket",
    "lng": -98.35555787116735,
    "lat": 48.21058934863979
  },
  {
    "name": "Brockport",
    "lng": -77.94054254324081,
    "lat": 43.21365998830605
  },
  {
    "name": "Brockton",
    "lng": -104.91408452051375,
    "lat": 48.14997907575401
  },
  {
    "name": "Brockville",
    "lng": -75.69705118125891,
    "lat": 44.60049701064328
  },
  {
    "name": "Brocton",
    "lng": -87.93348084397758,
    "lat": 39.71546937158932
  },
  {
    "name": "Brocton",
    "lng": -79.44282112446562,
    "lat": 42.3898432191896
  },
  {
    "name": "Brodhead",
    "lng": -89.37533770359504,
    "lat": 42.61717798933053
  },
  {
    "name": "Brodhead",
    "lng": -84.41605105817415,
    "lat": 37.40380045200559
  },
  {
    "name": "Brodheadsville",
    "lng": -75.40170272302923,
    "lat": 40.926756542086295
  },
  {
    "name": "Brodnax",
    "lng": -78.03185898246869,
    "lat": 36.703276124885996
  },
  {
    "name": "Broeck Pointe",
    "lng": -85.58580260568472,
    "lat": 38.29545937228689
  },
  {
    "name": "Brokaw",
    "lng": -89.65755225767143,
    "lat": 45.02733507795994
  },
  {
    "name": "Broken Bow",
    "lng": -99.64380458071453,
    "lat": 41.39414751077356
  },
  {
    "name": "Broken Bow",
    "lng": -99.63953542524506,
    "lat": 41.405206099828234
  },
  {
    "name": "Broken Bow",
    "lng": -94.7680780223551,
    "lat": 34.03105469393728
  },
  {
    "name": "Broken Bow",
    "lng": -94.75160643360462,
    "lat": 33.97520973142498
  },
  {
    "name": "Broken Bow",
    "lng": -94.73918700575676,
    "lat": 34.02587711951726
  },
  {
    "name": "Broken Bow",
    "lng": -94.7266034460334,
    "lat": 34.15000537752724
  },
  {
    "name": "Bromide",
    "lng": -96.49526314496272,
    "lat": 34.41705971697222
  },
  {
    "name": "Bromley",
    "lng": -84.56214768012778,
    "lat": 39.08041144202179
  },
  {
    "name": "Bronaugh",
    "lng": -94.46818673660184,
    "lat": 37.69418410390967
  },
  {
    "name": "Bronson",
    "lng": -96.21052888703313,
    "lat": 42.40903573300431
  },
  {
    "name": "Bronson",
    "lng": -95.07324649722963,
    "lat": 37.89567208801354
  },
  {
    "name": "Bronson",
    "lng": -85.1914555226865,
    "lat": 41.872681900624066
  },
  {
    "name": "Bronson",
    "lng": -82.63961372012041,
    "lat": 29.448911432008934
  },
  {
    "name": "Bronte",
    "lng": -100.29545509082537,
    "lat": 31.885752459916386
  },
  {
    "name": "Bronwood",
    "lng": -84.36429414628869,
    "lat": 31.830518186961086
  },
  {
    "name": "Bronxville",
    "lng": -73.82644706161527,
    "lat": 40.939443843517175
  },
  {
    "name": "Brook",
    "lng": -87.36545840920299,
    "lat": 40.86651858874427
  },
  {
    "name": "Brook Park",
    "lng": -93.0727123538573,
    "lat": 45.94829983696783
  },
  {
    "name": "Brook Park",
    "lng": -81.8217642802499,
    "lat": 41.40366889966848
  },
  {
    "name": "Brookeville",
    "lng": -77.0590991377162,
    "lat": 39.180898031283604
  },
  {
    "name": "Brookfield",
    "lng": -93.07721151811211,
    "lat": 39.78538267505811
  },
  {
    "name": "Brookfield",
    "lng": -88.12294485624933,
    "lat": 43.06399199305644
  },
  {
    "name": "Brookfield",
    "lng": -87.8470217715902,
    "lat": 41.82452247094077
  },
  {
    "name": "Brookfield",
    "lng": -73.39225225447643,
    "lat": 41.467447136237205
  },
  {
    "name": "Brookfield",
    "lng": -72.10441238655909,
    "lat": 42.193647447306894
  },
  {
    "name": "Brookford",
    "lng": -81.35240952649961,
    "lat": 35.69792668852599
  },
  {
    "name": "Brookford",
    "lng": -81.34589964223485,
    "lat": 35.70288499574314
  },
  {
    "name": "Brookford",
    "lng": -81.3419099676728,
    "lat": 35.70954749676117
  },
  {
    "name": "Brookhaven",
    "lng": -90.44323242694905,
    "lat": 31.580363244008534
  },
  {
    "name": "Brookhaven",
    "lng": -84.33146854334588,
    "lat": 33.87459562781197
  },
  {
    "name": "Brookings",
    "lng": -96.78537610246897,
    "lat": 44.30277615677154
  },
  {
    "name": "Brookland",
    "lng": -90.5767103191336,
    "lat": 35.89913761325383
  },
  {
    "name": "Brooklet",
    "lng": -81.66679669030091,
    "lat": 32.38968908555874
  },
  {
    "name": "Brooklin",
    "lng": -68.5535196847545,
    "lat": 44.26846591492651
  },
  {
    "name": "Brookline",
    "lng": -71.67059934825836,
    "lat": 42.74624316791485
  },
  {
    "name": "Brooklyn",
    "lng": -92.44501027542377,
    "lat": 41.73205799826837
  },
  {
    "name": "Brooklyn",
    "lng": -90.16798817933727,
    "lat": 38.65443533642616
  },
  {
    "name": "Brooklyn",
    "lng": -89.37169634995576,
    "lat": 42.85310539683138
  },
  {
    "name": "Brooklyn",
    "lng": -86.36741913629872,
    "lat": 39.54365649135386
  },
  {
    "name": "Brooklyn",
    "lng": -84.24900593263182,
    "lat": 42.105748881811444
  },
  {
    "name": "Brooklyn",
    "lng": -81.74979236557635,
    "lat": 41.43486947451212
  },
  {
    "name": "Brooklyn Center",
    "lng": -93.31610479896173,
    "lat": 45.06808807119187
  },
  {
    "name": "Brooklyn Center",
    "lng": -71.95477592959398,
    "lat": 41.78974980004693
  },
  {
    "name": "Brooklyn Heights",
    "lng": -94.38553566185324,
    "lat": 37.16941087492712
  },
  {
    "name": "Brooklyn Heights",
    "lng": -81.66794192027976,
    "lat": 41.41780439718126
  },
  {
    "name": "Brooklyn Park",
    "lng": -93.35053879801299,
    "lat": 45.11119765160829
  },
  {
    "name": "Brookneal",
    "lng": -78.94857265404644,
    "lat": 37.05321209150136
  },
  {
    "name": "Brookport",
    "lng": -88.62753072353536,
    "lat": 37.12636754685381
  },
  {
    "name": "Brooks",
    "lng": -96.00353702511924,
    "lat": 47.81651333852771
  },
  {
    "name": "Brooks",
    "lng": -69.12145710673357,
    "lat": 44.531558254319684
  },
  {
    "name": "Brooksburg",
    "lng": -85.24449624414765,
    "lat": 38.73503861831875
  },
  {
    "name": "Brookshire",
    "lng": -95.95452195265764,
    "lat": 29.78221383106116
  },
  {
    "name": "Brookshire",
    "lng": -95.95253386333256,
    "lat": 29.80163725223328
  },
  {
    "name": "Brookside",
    "lng": -105.19080170684735,
    "lat": 38.413402080965724
  },
  {
    "name": "Brookside",
    "lng": -86.9010703777812,
    "lat": 33.63809336594079
  },
  {
    "name": "Brookside",
    "lng": -80.76072356308443,
    "lat": 40.070829514186535
  },
  {
    "name": "Brookside Village",
    "lng": -95.31808817628321,
    "lat": 29.59086585375162
  },
  {
    "name": "Brookston",
    "lng": -92.60274165916678,
    "lat": 46.8653988653323
  },
  {
    "name": "Brookston",
    "lng": -86.86629651260728,
    "lat": 40.60028387138379
  },
  {
    "name": "Brooksville",
    "lng": -96.96080058329125,
    "lat": 35.21037222026028
  },
  {
    "name": "Brooksville",
    "lng": -88.59315978968307,
    "lat": 33.22895306794916
  },
  {
    "name": "Brooksville",
    "lng": -88.58086401990346,
    "lat": 33.23360359543333
  },
  {
    "name": "Brooksville",
    "lng": -84.06662860261162,
    "lat": 38.68174704482579
  },
  {
    "name": "Brooksville",
    "lng": -82.40095132573576,
    "lat": 28.508338353165392
  },
  {
    "name": "Brooksville",
    "lng": -82.3869136129881,
    "lat": 28.542481000346893
  },
  {
    "name": "Brooksville",
    "lng": -82.38129715486527,
    "lat": 28.539333502255506
  },
  {
    "name": "Brooksville",
    "lng": -82.37614619544694,
    "lat": 28.551968810399046
  },
  {
    "name": "Brooksville",
    "lng": -82.35380769223529,
    "lat": 28.56080502929816
  },
  {
    "name": "Brooksville",
    "lng": -68.75964093139227,
    "lat": 44.35208771233626
  },
  {
    "name": "Brookview",
    "lng": -75.79325181794572,
    "lat": 38.57407375217252
  },
  {
    "name": "Brookville",
    "lng": -97.86472066737076,
    "lat": 38.773614050417464
  },
  {
    "name": "Brookville",
    "lng": -85.01035634749412,
    "lat": 39.4214346653288
  },
  {
    "name": "Brookville",
    "lng": -84.41752618914073,
    "lat": 39.83916920976298
  },
  {
    "name": "Brookville",
    "lng": -73.56973659623259,
    "lat": 40.81205915921236
  },
  {
    "name": "Brookwood",
    "lng": -87.35561430305711,
    "lat": 33.25952454630006
  },
  {
    "name": "Brookwood",
    "lng": -87.32858652675314,
    "lat": 33.23928941072454
  },
  {
    "name": "Brookwood",
    "lng": -87.30226487325992,
    "lat": 33.19113169028133
  },
  {
    "name": "Brooten",
    "lng": -95.1159321369055,
    "lat": 45.50024145214254
  },
  {
    "name": "Broughton",
    "lng": -88.46236160672531,
    "lat": 37.9344844380674
  },
  {
    "name": "Broughton",
    "lng": -84.53498593123575,
    "lat": 41.08800896849901
  },
  {
    "name": "Broussard",
    "lng": -91.95727525967814,
    "lat": 30.154707851957834
  },
  {
    "name": "Broussard",
    "lng": -91.95390769667527,
    "lat": 30.139535087816586
  },
  {
    "name": "Broussard",
    "lng": -91.9533554699415,
    "lat": 30.09185107163141
  },
  {
    "name": "Browerville",
    "lng": -94.86499154893664,
    "lat": 46.08033947136251
  },
  {
    "name": "Browerville",
    "lng": -94.85193627274464,
    "lat": 46.091084616200014
  },
  {
    "name": "Brown City",
    "lng": -82.98793112457295,
    "lat": 43.21143861572995
  },
  {
    "name": "Brown Deer",
    "lng": -87.97496355614615,
    "lat": 43.1743582032287
  },
  {
    "name": "Browndell",
    "lng": -93.97998072012037,
    "lat": 31.125066656985474
  },
  {
    "name": "Brownell",
    "lng": -99.74436290450124,
    "lat": 38.640559121087776
  },
  {
    "name": "Brownfield",
    "lng": -102.27302365575292,
    "lat": 33.17566714014841
  },
  {
    "name": "Brownfield",
    "lng": -70.9167340332568,
    "lat": 43.933009231308944
  },
  {
    "name": "Browning",
    "lng": -113.0151108945642,
    "lat": 48.556333857411396
  },
  {
    "name": "Browning",
    "lng": -93.15996816962303,
    "lat": 40.03480327583342
  },
  {
    "name": "Browning",
    "lng": -90.37300320962696,
    "lat": 40.12705051533323
  },
  {
    "name": "Brownington",
    "lng": -93.72271987970058,
    "lat": 38.245703035104555
  },
  {
    "name": "Browns",
    "lng": -87.98313669501466,
    "lat": 38.37751933701028
  },
  {
    "name": "Browns Valley",
    "lng": -96.83282147788304,
    "lat": 45.59466948271288
  },
  {
    "name": "Brownsboro",
    "lng": -95.61304887861279,
    "lat": 32.298426718223006
  },
  {
    "name": "Brownsboro Farm",
    "lng": -85.5955777671724,
    "lat": 38.30439923352792
  },
  {
    "name": "Brownsboro Village",
    "lng": -85.66572396981756,
    "lat": 38.26284628308968
  },
  {
    "name": "Brownsburg",
    "lng": -86.38052827760652,
    "lat": 39.83486169516323
  },
  {
    "name": "Brownsdale",
    "lng": -92.86860812962014,
    "lat": 43.74032143544104
  },
  {
    "name": "Brownstown",
    "lng": -88.95520076410725,
    "lat": 38.99437857921876
  },
  {
    "name": "Brownstown",
    "lng": -86.04670825430641,
    "lat": 38.879648782485454
  },
  {
    "name": "Brownstown Township",
    "lng": -83.26938172000804,
    "lat": 42.15541557488158
  },
  {
    "name": "Brownstown Township",
    "lng": -83.23218654630666,
    "lat": 42.09733252277996
  },
  {
    "name": "Brownstown Township",
    "lng": -83.18302164335428,
    "lat": 42.05638427210862
  },
  {
    "name": "Brownsville",
    "lng": -97.45659669848094,
    "lat": 25.998076784401476
  },
  {
    "name": "Brownsville",
    "lng": -91.28228142404538,
    "lat": 43.69928134596312
  },
  {
    "name": "Brownsville",
    "lng": -89.2568731155837,
    "lat": 35.58993806150951
  },
  {
    "name": "Brownsville",
    "lng": -88.4952096002585,
    "lat": 43.61514759647832
  },
  {
    "name": "Brownsville",
    "lng": -86.26553886812536,
    "lat": 37.19443775361143
  },
  {
    "name": "Brownton",
    "lng": -94.35096943209227,
    "lat": 44.732553213459155
  },
  {
    "name": "Browntown",
    "lng": -89.78939241994415,
    "lat": 42.57874572908266
  },
  {
    "name": "Brownville",
    "lng": -95.66108575306758,
    "lat": 40.397617543988375
  },
  {
    "name": "Brownville",
    "lng": -75.98341597617582,
    "lat": 44.00587484052846
  },
  {
    "name": "Brownville",
    "lng": -69.00989450658446,
    "lat": 45.35935006414476
  },
  {
    "name": "Brownwood",
    "lng": -98.97658859315095,
    "lat": 31.712556050825764
  },
  {
    "name": "Broxton",
    "lng": -82.88789340005756,
    "lat": 31.624784946374284
  },
  {
    "name": "Bruce",
    "lng": -96.89030511125867,
    "lat": 44.43812089741795
  },
  {
    "name": "Bruce",
    "lng": -91.2732158494764,
    "lat": 45.4589057340021
  },
  {
    "name": "Bruce",
    "lng": -89.34546407608559,
    "lat": 33.99090835993794
  },
  {
    "name": "Bruceton",
    "lng": -88.24696522583635,
    "lat": 36.03394714928116
  },
  {
    "name": "Bruceton Mills",
    "lng": -79.64060638558135,
    "lat": 39.65939580462451
  },
  {
    "name": "Bruceville",
    "lng": -87.4148405814332,
    "lat": 38.757822162944564
  },
  {
    "name": "Bruceville-Eddy",
    "lng": -97.24721333994528,
    "lat": 31.311968904313126
  },
  {
    "name": "Brule",
    "lng": -101.88828962777744,
    "lat": 41.09480837023589
  },
  {
    "name": "Brumley",
    "lng": -92.48433765576695,
    "lat": 38.088018406979806
  },
  {
    "name": "Brundidge",
    "lng": -85.81781347594291,
    "lat": 31.71822846165946
  },
  {
    "name": "Bruning",
    "lng": -97.56469250288207,
    "lat": 40.33579407492835
  },
  {
    "name": "Bruno",
    "lng": -96.96090601859076,
    "lat": 41.283034475173935
  },
  {
    "name": "Bruno",
    "lng": -92.66804698996319,
    "lat": 46.28098755797106
  },
  {
    "name": "Brunson",
    "lng": -81.18873571786645,
    "lat": 32.924240989796814
  },
  {
    "name": "Brunsville",
    "lng": -96.26636519601504,
    "lat": 42.81100228813863
  },
  {
    "name": "Brunswick",
    "lng": -97.97175593570653,
    "lat": 42.337704721293264
  },
  {
    "name": "Brunswick",
    "lng": -93.12653524620187,
    "lat": 39.425645312878714
  },
  {
    "name": "Brunswick",
    "lng": -81.81976962607557,
    "lat": 41.2461408257909
  },
  {
    "name": "Brunswick",
    "lng": -81.4739589717902,
    "lat": 31.14501795722395
  },
  {
    "name": "Brunswick",
    "lng": -78.70575834442678,
    "lat": 34.29213512450302
  },
  {
    "name": "Brunswick",
    "lng": -77.62590652189999,
    "lat": 39.31783911568245
  },
  {
    "name": "Brunswick",
    "lng": -69.97791750272303,
    "lat": 43.89727816721674
  },
  {
    "name": "Brushton",
    "lng": -74.51189367186494,
    "lat": 44.83048514182491
  },
  {
    "name": "Brusly",
    "lng": -91.2516049637873,
    "lat": 30.39425178996823
  },
  {
    "name": "Brussels",
    "lng": -90.58906907478561,
    "lat": 38.948488810108536
  },
  {
    "name": "Bryan",
    "lng": -96.36720290820763,
    "lat": 30.66570370731194
  },
  {
    "name": "Bryan",
    "lng": -84.55013217873727,
    "lat": 41.47124088603039
  },
  {
    "name": "Bryant",
    "lng": -97.46727478409557,
    "lat": 44.58990078432854
  },
  {
    "name": "Bryant",
    "lng": -92.49130043144105,
    "lat": 34.61514074743036
  },
  {
    "name": "Bryant",
    "lng": -90.09505759356344,
    "lat": 40.46570425204493
  },
  {
    "name": "Bryant",
    "lng": -84.96326889882168,
    "lat": 40.535781716767644
  },
  {
    "name": "Bryce Canyon City",
    "lng": -112.1587456302779,
    "lat": 37.68143670836743
  },
  {
    "name": "Bryceland",
    "lng": -92.99239795445942,
    "lat": 32.448537519690866
  },
  {
    "name": "Bryn Athyn",
    "lng": -75.067398005627,
    "lat": 40.141065944557674
  },
  {
    "name": "Bryson",
    "lng": -98.4038404743231,
    "lat": 33.190705492319125
  },
  {
    "name": "Bryson",
    "lng": -98.38720786613452,
    "lat": 33.161243382010454
  },
  {
    "name": "Bryson City",
    "lng": -83.44710021346248,
    "lat": 35.42623473413903
  },
  {
    "name": "Buchanan",
    "lng": -98.82910247213482,
    "lat": 47.062603748575654
  },
  {
    "name": "Buchanan",
    "lng": -86.36548756835224,
    "lat": 41.82836416934584
  },
  {
    "name": "Buchanan",
    "lng": -85.18816330259024,
    "lat": 33.80100959629549
  },
  {
    "name": "Buchanan",
    "lng": -85.13992512665831,
    "lat": 33.8157051698374
  },
  {
    "name": "Buchanan",
    "lng": -79.68953649123203,
    "lat": 37.52105877355213
  },
  {
    "name": "Buchanan",
    "lng": -73.94661524485963,
    "lat": 41.26420689336436
  },
  {
    "name": "Buchtel",
    "lng": -82.18088014445199,
    "lat": 39.463314942968005
  },
  {
    "name": "Buck Grove",
    "lng": -95.39526049850416,
    "lat": 41.917733455907644
  },
  {
    "name": "Buck Hill Management Area",
    "lng": -71.78944915029697,
    "lat": 41.971642881477955
  },
  {
    "name": "Buck Hill Management Area",
    "lng": -71.78253715362196,
    "lat": 41.99406635883021
  },
  {
    "name": "Buckeye",
    "lng": -93.37582205717263,
    "lat": 42.41953781582844
  },
  {
    "name": "Buckeye Lake",
    "lng": -82.48269022748977,
    "lat": 39.93551750378584
  },
  {
    "name": "Buckfield",
    "lng": -70.36998017672084,
    "lat": 44.28356194263894
  },
  {
    "name": "Buckhannon",
    "lng": -80.22668977877291,
    "lat": 38.99283715153794
  },
  {
    "name": "Buckholts",
    "lng": -97.128598383215,
    "lat": 30.873831351638312
  },
  {
    "name": "Buckhorn",
    "lng": -83.47172094528239,
    "lat": 37.34620671786969
  },
  {
    "name": "Buckhorn",
    "lng": -76.4929065228105,
    "lat": 41.01536332595056
  },
  {
    "name": "Buckingham",
    "lng": -88.17491264378374,
    "lat": 41.04706797595759
  },
  {
    "name": "Buckland",
    "lng": -161.13128390209016,
    "lat": 65.9799869096999
  },
  {
    "name": "Buckland",
    "lng": -84.26039156964823,
    "lat": 40.624020154154316
  },
  {
    "name": "Buckland",
    "lng": -72.78802016930973,
    "lat": 42.591717585683625
  },
  {
    "name": "Buckley",
    "lng": -122.01987146740835,
    "lat": 47.16142421028187
  },
  {
    "name": "Buckley",
    "lng": -88.03746547820468,
    "lat": 40.59746018721001
  },
  {
    "name": "Buckley",
    "lng": -85.67194845386527,
    "lat": 44.503309569488465
  },
  {
    "name": "Bucklin",
    "lng": -99.63487498286287,
    "lat": 37.54919315669404
  },
  {
    "name": "Bucklin",
    "lng": -99.62191105742833,
    "lat": 37.54303912697242
  },
  {
    "name": "Bucklin",
    "lng": -92.88794488678835,
    "lat": 39.78362390765428
  },
  {
    "name": "Buckman",
    "lng": -94.09399295454267,
    "lat": 45.89735621037054
  },
  {
    "name": "Buckner",
    "lng": -94.19287320081645,
    "lat": 39.133535089321725
  },
  {
    "name": "Buckner",
    "lng": -93.43579373844906,
    "lat": 33.35720692598399
  },
  {
    "name": "Buckner",
    "lng": -89.01515214908689,
    "lat": 37.980708905433225
  },
  {
    "name": "Bucksport",
    "lng": -68.7489444327635,
    "lat": 44.63982417344177
  },
  {
    "name": "Bucoda",
    "lng": -122.86819995196016,
    "lat": 46.79681784887638
  },
  {
    "name": "Bucyrus",
    "lng": -102.78830689041618,
    "lat": 46.06457399342777
  },
  {
    "name": "Bucyrus",
    "lng": -82.97177754699942,
    "lat": 40.80527988066978
  },
  {
    "name": "Buda",
    "lng": -89.67910644277055,
    "lat": 41.3291040605174
  },
  {
    "name": "Bude",
    "lng": -90.8508936486474,
    "lat": 31.46296108639497
  },
  {
    "name": "Buellton",
    "lng": -120.19441972720865,
    "lat": 34.61525438709087
  },
  {
    "name": "Buena Park",
    "lng": -118.00459547913091,
    "lat": 33.8572299078174
  },
  {
    "name": "Buena Vista",
    "lng": -106.13901702623805,
    "lat": 38.83177729872954
  },
  {
    "name": "Buena Vista",
    "lng": -106.13901364835013,
    "lat": 38.83177723415395
  },
  {
    "name": "Buena Vista",
    "lng": -84.51759810510781,
    "lat": 32.318606108219434
  },
  {
    "name": "Buena Vista",
    "lng": -79.35688929973104,
    "lat": 37.73187400370048
  },
  {
    "name": "Buena Vista Township",
    "lng": -74.88834248474737,
    "lat": 39.515053953068204
  },
  {
    "name": "Buffalo",
    "lng": -106.7155222616097,
    "lat": 44.34195739374702
  },
  {
    "name": "Buffalo",
    "lng": -106.6849433775495,
    "lat": 44.35393669411382
  },
  {
    "name": "Buffalo",
    "lng": -106.67567132632625,
    "lat": 44.35763891167148
  },
  {
    "name": "Buffalo",
    "lng": -103.54341185758321,
    "lat": 45.5863756642166
  },
  {
    "name": "Buffalo",
    "lng": -99.63962019377475,
    "lat": 36.831472884332264
  },
  {
    "name": "Buffalo",
    "lng": -99.63821911082218,
    "lat": 36.82796486430555
  },
  {
    "name": "Buffalo",
    "lng": -99.63579015680783,
    "lat": 36.83469763910942
  },
  {
    "name": "Buffalo",
    "lng": -99.62760847783474,
    "lat": 36.83528207968804
  },
  {
    "name": "Buffalo",
    "lng": -99.62534495026912,
    "lat": 36.84610039354859
  },
  {
    "name": "Buffalo",
    "lng": -97.5565636931821,
    "lat": 46.917908129536045
  },
  {
    "name": "Buffalo",
    "lng": -97.54992063229045,
    "lat": 46.92051716088194
  },
  {
    "name": "Buffalo",
    "lng": -96.06608647785785,
    "lat": 31.4600192170614
  },
  {
    "name": "Buffalo",
    "lng": -95.69721348480566,
    "lat": 37.709088414944695
  },
  {
    "name": "Buffalo",
    "lng": -93.9083497335614,
    "lat": 45.17154271565002
  },
  {
    "name": "Buffalo",
    "lng": -93.9053139799897,
    "lat": 45.19215317951366
  },
  {
    "name": "Buffalo",
    "lng": -93.86353230524996,
    "lat": 45.179176374144426
  },
  {
    "name": "Buffalo",
    "lng": -93.09724386406472,
    "lat": 37.644294064129326
  },
  {
    "name": "Buffalo",
    "lng": -93.07530311179706,
    "lat": 37.643860166252715
  },
  {
    "name": "Buffalo",
    "lng": -93.07355255198239,
    "lat": 37.64382605816829
  },
  {
    "name": "Buffalo",
    "lng": -90.7170439303304,
    "lat": 41.46663191267388
  },
  {
    "name": "Buffalo",
    "lng": -89.40894051307603,
    "lat": 39.849882631164135
  },
  {
    "name": "Buffalo",
    "lng": -81.98194059604155,
    "lat": 38.611655388273654
  },
  {
    "name": "Buffalo",
    "lng": -78.85296829713421,
    "lat": 42.89814848233623
  },
  {
    "name": "Buffalo Center",
    "lng": -93.94275873036618,
    "lat": 43.38932645310566
  },
  {
    "name": "Buffalo City",
    "lng": -91.86560212954151,
    "lat": 44.22387538198801
  },
  {
    "name": "Buffalo Gap",
    "lng": -103.31530503337424,
    "lat": 43.49218631791883
  },
  {
    "name": "Buffalo Gap",
    "lng": -99.83436219205886,
    "lat": 32.28389881064415
  },
  {
    "name": "Buffalo Grove",
    "lng": -87.96359523456023,
    "lat": 42.16714676081001
  },
  {
    "name": "Buffalo Grove",
    "lng": -87.95572247234873,
    "lat": 42.20530070159721
  },
  {
    "name": "Buffalo Grove",
    "lng": -87.94618692925383,
    "lat": 42.18218661657459
  },
  {
    "name": "Buffalo Grove",
    "lng": -87.9233812838492,
    "lat": 42.15605069368978
  },
  {
    "name": "Buffalo Lake",
    "lng": -94.61677649128137,
    "lat": 44.73673113309832
  },
  {
    "name": "Buffalo Springs",
    "lng": -101.70789937391612,
    "lat": 33.53278144835575
  },
  {
    "name": "Buffalo Township",
    "lng": -98.86739230830928,
    "lat": 38.392699764251894
  },
  {
    "name": "Buford",
    "lng": -84.01168863321998,
    "lat": 34.14505991311784
  },
  {
    "name": "Buford",
    "lng": -83.99214434505674,
    "lat": 34.11863653243401
  },
  {
    "name": "Buford",
    "lng": -83.97885135213535,
    "lat": 34.093147969475915
  },
  {
    "name": "Buford",
    "lng": -83.95985047269113,
    "lat": 34.10929842045151
  },
  {
    "name": "Buford",
    "lng": -83.95687322536632,
    "lat": 34.064707445724366
  },
  {
    "name": "Buford",
    "lng": -83.95276919416885,
    "lat": 34.16607147185558
  },
  {
    "name": "Buford",
    "lng": -83.9463239263852,
    "lat": 34.16228460610497
  },
  {
    "name": "Buford",
    "lng": -83.94196202952074,
    "lat": 34.162393556973534
  },
  {
    "name": "Buhl",
    "lng": -114.75953677331341,
    "lat": 42.59836376772146
  },
  {
    "name": "Buhl",
    "lng": -92.76916332716053,
    "lat": 47.49939047129343
  },
  {
    "name": "Buhler",
    "lng": -97.77065079557467,
    "lat": 38.13868837978372
  },
  {
    "name": "Bull Creek",
    "lng": -93.20218471401878,
    "lat": 36.71498562391254
  },
  {
    "name": "Bull Shoals",
    "lng": -92.59025357161512,
    "lat": 36.374793203942595
  },
  {
    "name": "Bull Valley",
    "lng": -88.36346670763305,
    "lat": 42.32227020702045
  },
  {
    "name": "Bullard",
    "lng": -95.31840828055961,
    "lat": 32.14240523255567
  },
  {
    "name": "Bulls Gap",
    "lng": -83.07839718075368,
    "lat": 36.26164918985515
  },
  {
    "name": "Bulpitt",
    "lng": -89.42576234292306,
    "lat": 39.59187920123449
  },
  {
    "name": "Bulverde",
    "lng": -98.45554601395004,
    "lat": 29.73872768149243
  },
  {
    "name": "Bulverde",
    "lng": -98.44364822241518,
    "lat": 29.77628619075871
  },
  {
    "name": "Bulverde",
    "lng": -98.41347894220618,
    "lat": 29.763202609170303
  },
  {
    "name": "Bunceton",
    "lng": -92.79913897640638,
    "lat": 38.78944539126098
  },
  {
    "name": "Buncombe",
    "lng": -88.97459299064504,
    "lat": 37.471493483735166
  },
  {
    "name": "Bunker",
    "lng": -91.21201367211971,
    "lat": 37.457329402040074
  },
  {
    "name": "Bunker Hill",
    "lng": -98.69888896538596,
    "lat": 38.87530395561194
  },
  {
    "name": "Bunker Hill",
    "lng": -89.95121058439788,
    "lat": 39.04161315416265
  },
  {
    "name": "Bunker Hill",
    "lng": -89.94742773040872,
    "lat": 39.03282082486714
  },
  {
    "name": "Bunker Hill",
    "lng": -86.1011224255857,
    "lat": 40.66019695968339
  },
  {
    "name": "Bunker Hill Village",
    "lng": -95.5318394485467,
    "lat": 29.76473431841929
  },
  {
    "name": "Bunkie",
    "lng": -92.18864462234714,
    "lat": 30.95400236221422
  },
  {
    "name": "Bunkie",
    "lng": -92.16478934051982,
    "lat": 30.950117150573877
  },
  {
    "name": "Bunn",
    "lng": -78.25202060213643,
    "lat": 35.95915040483827
  },
  {
    "name": "Bunnell",
    "lng": -81.32362312758919,
    "lat": 29.419976487241794
  },
  {
    "name": "Bunnell",
    "lng": -81.28366623076678,
    "lat": 29.36681864413108
  },
  {
    "name": "Bunnell",
    "lng": -81.23006999909246,
    "lat": 29.47529458039917
  },
  {
    "name": "Bunnell",
    "lng": -81.19540409049199,
    "lat": 29.399765768186914
  },
  {
    "name": "Bunnell",
    "lng": -81.18330884507188,
    "lat": 29.363427241986958
  },
  {
    "name": "Bunnell",
    "lng": -81.18006716024814,
    "lat": 29.36606199133919
  },
  {
    "name": "Bunnell",
    "lng": -81.17771871553178,
    "lat": 29.36895834354465
  },
  {
    "name": "Bunnell",
    "lng": -81.1763435634141,
    "lat": 29.359912565182267
  },
  {
    "name": "Bunnell",
    "lng": -81.17454889953169,
    "lat": 29.36283025938842
  },
  {
    "name": "Burbank",
    "lng": -118.32351235362853,
    "lat": 34.18789811614479
  },
  {
    "name": "Burbank",
    "lng": -96.72920769065671,
    "lat": 36.69646371385748
  },
  {
    "name": "Burbank",
    "lng": -87.76850217469425,
    "lat": 41.74449448538839
  },
  {
    "name": "Burbank",
    "lng": -81.99483070118693,
    "lat": 40.986176531487665
  },
  {
    "name": "Burchard",
    "lng": -96.34881802457099,
    "lat": 40.14938155332441
  },
  {
    "name": "Burden",
    "lng": -96.75536080349326,
    "lat": 37.314485067755676
  },
  {
    "name": "Burdett",
    "lng": -99.5259479613309,
    "lat": 38.19338894303896
  },
  {
    "name": "Burdett",
    "lng": -76.84395699438012,
    "lat": 42.41686859551936
  },
  {
    "name": "Burdette",
    "lng": -89.94637876691999,
    "lat": 35.815252049205256
  },
  {
    "name": "Bureau Junction",
    "lng": -89.36415925368308,
    "lat": 41.28773299755085
  },
  {
    "name": "Burgaw",
    "lng": -77.9223060861618,
    "lat": 34.55065995451357
  },
  {
    "name": "Burgaw",
    "lng": -77.88136316937515,
    "lat": 34.56839117324029
  },
  {
    "name": "Burgess",
    "lng": -94.61527398001331,
    "lat": 37.55677427707248
  },
  {
    "name": "Burgin",
    "lng": -84.76356594821152,
    "lat": 37.754701118496826
  },
  {
    "name": "Burgoon",
    "lng": -83.25140296743123,
    "lat": 41.267484666930464
  },
  {
    "name": "Burien",
    "lng": -122.35582169887948,
    "lat": 47.47357291812939
  },
  {
    "name": "Burkburnett",
    "lng": -98.56731602555185,
    "lat": 34.074801098387354
  },
  {
    "name": "Burke",
    "lng": -99.29230292918369,
    "lat": 43.182821190062825
  },
  {
    "name": "Burke",
    "lng": -94.76653430634677,
    "lat": 31.234448796994705
  },
  {
    "name": "Burke",
    "lng": -74.16996261199257,
    "lat": 44.90315669280123
  },
  {
    "name": "Burkesville",
    "lng": -85.37026017532405,
    "lat": 36.794292716923614
  },
  {
    "name": "Burkesville",
    "lng": -85.35988401065201,
    "lat": 36.782125017880695
  },
  {
    "name": "Burket",
    "lng": -85.9687307738658,
    "lat": 41.15489744698959
  },
  {
    "name": "Burkettsville",
    "lng": -84.64251488008588,
    "lat": 40.3530591312345
  },
  {
    "name": "Burkeville",
    "lng": -78.20124808841881,
    "lat": 37.18788634381433
  },
  {
    "name": "Burkittsville",
    "lng": -77.62750134902431,
    "lat": 39.39224246856675
  },
  {
    "name": "Burleson",
    "lng": -97.41076009099679,
    "lat": 32.515740619362866
  },
  {
    "name": "Burleson",
    "lng": -97.40062012048475,
    "lat": 32.526721774740054
  },
  {
    "name": "Burleson",
    "lng": -97.33427309839797,
    "lat": 32.51723375995212
  },
  {
    "name": "Burleson",
    "lng": -97.28289996613212,
    "lat": 32.48363021962343
  },
  {
    "name": "Burley",
    "lng": -113.7928520907476,
    "lat": 42.53793716813923
  },
  {
    "name": "Burlingame",
    "lng": -95.83471271201346,
    "lat": 38.7516290451324
  },
  {
    "name": "Burlingame Management Area",
    "lng": -71.73072888091501,
    "lat": 41.40407657631536
  },
  {
    "name": "Burlingame Management Area",
    "lng": -71.71617672646596,
    "lat": 41.428267849025055
  },
  {
    "name": "Burlingame Management Area",
    "lng": -71.71326106713725,
    "lat": 41.42252849338484
  },
  {
    "name": "Burlingame Management Area",
    "lng": -71.71226277428912,
    "lat": 41.4052544584684
  },
  {
    "name": "Burlingame Management Area",
    "lng": -71.69631618987344,
    "lat": 41.414272507723446
  },
  {
    "name": "Burlingame Management Area",
    "lng": -71.69092321710606,
    "lat": 41.39922488793352
  },
  {
    "name": "Burlingame State Park",
    "lng": -71.70269989487939,
    "lat": 41.376196347744916
  },
  {
    "name": "Burlingame State Park",
    "lng": -71.68209337276191,
    "lat": 41.39047217010585
  },
  {
    "name": "Burlingame State Park",
    "lng": -71.6796080730928,
    "lat": 41.378749472761164
  },
  {
    "name": "Burlington",
    "lng": -122.32995879475256,
    "lat": 48.46752970473426
  },
  {
    "name": "Burlington",
    "lng": -108.43202301819842,
    "lat": 44.4470660892511
  },
  {
    "name": "Burlington",
    "lng": -101.42486624227242,
    "lat": 48.27617717361385
  },
  {
    "name": "Burlington",
    "lng": -98.42377860910497,
    "lat": 36.89982700492854
  },
  {
    "name": "Burlington",
    "lng": -95.76696211357064,
    "lat": 38.19906744172788
  },
  {
    "name": "Burlington",
    "lng": -95.76332015019003,
    "lat": 38.19758699427151
  },
  {
    "name": "Burlington",
    "lng": -95.74497051094927,
    "lat": 38.195577369577386
  },
  {
    "name": "Burlington",
    "lng": -95.74157309457469,
    "lat": 38.169332355921306
  },
  {
    "name": "Burlington",
    "lng": -95.74077606053324,
    "lat": 38.18090074671985
  },
  {
    "name": "Burlington",
    "lng": -95.73971715196029,
    "lat": 38.17784642154361
  },
  {
    "name": "Burlington",
    "lng": -91.1246509691263,
    "lat": 40.806902233553565
  },
  {
    "name": "Burlington",
    "lng": -88.57045779835346,
    "lat": 42.04296439563944
  },
  {
    "name": "Burlington",
    "lng": -88.54818354864105,
    "lat": 42.04130675144684
  },
  {
    "name": "Burlington",
    "lng": -88.30511902993932,
    "lat": 42.69096487383703
  },
  {
    "name": "Burlington",
    "lng": -88.27014312940142,
    "lat": 42.673843513875035
  },
  {
    "name": "Burlington",
    "lng": -86.39456956217737,
    "lat": 40.48088992780389
  },
  {
    "name": "Burlington",
    "lng": -85.07755383591724,
    "lat": 42.104806764267515
  },
  {
    "name": "Burlington",
    "lng": -79.56456978222411,
    "lat": 36.06281588729585
  },
  {
    "name": "Burlington",
    "lng": -79.56191431386156,
    "lat": 36.06351515599953
  },
  {
    "name": "Burlington",
    "lng": -79.53164361928954,
    "lat": 36.081288906033436
  },
  {
    "name": "Burlington",
    "lng": -79.51746592145795,
    "lat": 36.06140316351553
  },
  {
    "name": "Burlington",
    "lng": -79.51531822582398,
    "lat": 36.06119778268888
  },
  {
    "name": "Burlington",
    "lng": -79.5097177415723,
    "lat": 36.06426234567469
  },
  {
    "name": "Burlington",
    "lng": -79.49118169793981,
    "lat": 36.0579840188692
  },
  {
    "name": "Burlington",
    "lng": -79.46807928797423,
    "lat": 36.07670070251015
  },
  {
    "name": "Burlington",
    "lng": -79.44262085452267,
    "lat": 36.04825552625022
  },
  {
    "name": "Burlington",
    "lng": -79.40561557129799,
    "lat": 36.12259652064774
  },
  {
    "name": "Burlington",
    "lng": -73.23130079529594,
    "lat": 44.487392631706435
  },
  {
    "name": "Burlington",
    "lng": -72.9590300930318,
    "lat": 41.75988121950073
  },
  {
    "name": "Burlington",
    "lng": -71.2026811442698,
    "lat": 42.50216003734265
  },
  {
    "name": "Burlington",
    "lng": -68.3823652045295,
    "lat": 45.24362156494311
  },
  {
    "name": "Burlington City",
    "lng": -74.8525144946074,
    "lat": 40.078371928689194
  },
  {
    "name": "Burlington Junction",
    "lng": -95.06790912879212,
    "lat": 40.446991281437754
  },
  {
    "name": "Burlison",
    "lng": -89.78450392798429,
    "lat": 35.557105631132266
  },
  {
    "name": "Burnet",
    "lng": -98.234791583236,
    "lat": 30.7520233710814
  },
  {
    "name": "Burnettown",
    "lng": -81.86584730814488,
    "lat": 33.51671286500592
  },
  {
    "name": "Burnettsville",
    "lng": -86.59467311698711,
    "lat": 40.7611409312435
  },
  {
    "name": "Burnham",
    "lng": -87.54476258413553,
    "lat": 41.6375549502351
  },
  {
    "name": "Burnham",
    "lng": -77.56313235003205,
    "lat": 40.635384720136216
  },
  {
    "name": "Burnham",
    "lng": -69.38254989545418,
    "lat": 44.68744267833125
  },
  {
    "name": "Burns",
    "lng": -104.3590354075634,
    "lat": 41.1908713927898
  },
  {
    "name": "Burns",
    "lng": -96.8880542395274,
    "lat": 38.08959846950886
  },
  {
    "name": "Burns",
    "lng": -96.87847808414074,
    "lat": 38.09162557522272
  },
  {
    "name": "Burns",
    "lng": -87.31319921256902,
    "lat": 36.05309133474646
  },
  {
    "name": "Burns Flat",
    "lng": -99.17503221007121,
    "lat": 35.354806631715945
  },
  {
    "name": "Burns Harbor",
    "lng": -87.12563736015385,
    "lat": 41.61984722471202
  },
  {
    "name": "Burnside",
    "lng": -84.60138733699989,
    "lat": 36.98692854863061
  },
  {
    "name": "Burnsville",
    "lng": -93.27930791672817,
    "lat": 44.76483746678702
  },
  {
    "name": "Burnsville",
    "lng": -88.3181975818061,
    "lat": 34.835097108809244
  },
  {
    "name": "Burnsville",
    "lng": -82.2974096408067,
    "lat": 35.91640242832285
  },
  {
    "name": "Burnsville",
    "lng": -80.65397717413457,
    "lat": 38.85796452273208
  },
  {
    "name": "Burnt Prairie",
    "lng": -88.25844975908102,
    "lat": 38.250874167918994
  },
  {
    "name": "Burnt Prairie",
    "lng": -88.25391552655866,
    "lat": 38.25096104328821
  },
  {
    "name": "Burr",
    "lng": -96.29978284867371,
    "lat": 40.53594738854819
  },
  {
    "name": "Burr Oak",
    "lng": -98.30561768976806,
    "lat": 39.87004604822955
  },
  {
    "name": "Burr Oak",
    "lng": -85.32122046705628,
    "lat": 41.847189697634455
  },
  {
    "name": "Burr Ridge",
    "lng": -87.91986750828286,
    "lat": 41.74885064708055
  },
  {
    "name": "Burrillville",
    "lng": -71.69843052259677,
    "lat": 41.97055740323652
  },
  {
    "name": "Burrton",
    "lng": -97.67133604825966,
    "lat": 38.023698212334686
  },
  {
    "name": "Burrton",
    "lng": -97.65872288124628,
    "lat": 38.026243869317305
  },
  {
    "name": "Burt",
    "lng": -94.22105392747022,
    "lat": 43.19890961019322
  },
  {
    "name": "Burton",
    "lng": -99.59185191993545,
    "lat": 42.91192690714358
  },
  {
    "name": "Burton",
    "lng": -83.61744379835103,
    "lat": 42.99742632067331
  },
  {
    "name": "Burton",
    "lng": -81.1456214912572,
    "lat": 41.47080924099118
  },
  {
    "name": "Burtrum",
    "lng": -94.68736804488069,
    "lat": 45.86586279488847
  },
  {
    "name": "Burwell",
    "lng": -99.13415797693939,
    "lat": 41.77998079260301
  },
  {
    "name": "Bush",
    "lng": -89.12951202336828,
    "lat": 37.84270544698739
  },
  {
    "name": "Bushnell",
    "lng": -103.89088711809879,
    "lat": 41.23241607497122
  },
  {
    "name": "Bushnell",
    "lng": -96.64220226334729,
    "lat": 44.32770488104456
  },
  {
    "name": "Bushnell",
    "lng": -90.50895607506867,
    "lat": 40.55144941430905
  },
  {
    "name": "Bushnell",
    "lng": -82.13130760716854,
    "lat": 28.66898896093229
  },
  {
    "name": "Bushnell",
    "lng": -82.1292090342975,
    "lat": 28.66957464901645
  },
  {
    "name": "Bushnell",
    "lng": -82.12873282628813,
    "lat": 28.66898733054854
  },
  {
    "name": "Bushnell",
    "lng": -82.12620869009834,
    "lat": 28.657447155127997
  },
  {
    "name": "Bushnell",
    "lng": -82.12469852258684,
    "lat": 28.658194731395596
  },
  {
    "name": "Bushnell",
    "lng": -82.12312888254183,
    "lat": 28.658823415810776
  },
  {
    "name": "Bushnell",
    "lng": -82.12310504819459,
    "lat": 28.699887560348834
  },
  {
    "name": "Bushnell",
    "lng": -82.12184652649633,
    "lat": 28.658026930241572
  },
  {
    "name": "Bushnell",
    "lng": -82.12035967528196,
    "lat": 28.671373435035253
  },
  {
    "name": "Bushnell",
    "lng": -82.11751713914255,
    "lat": 28.688134228906648
  },
  {
    "name": "Bushnell",
    "lng": -82.11309320049509,
    "lat": 28.6688585250052
  },
  {
    "name": "Bushnell",
    "lng": -82.10771316154886,
    "lat": 28.685441630608015
  },
  {
    "name": "Bushnell",
    "lng": -82.10358364995994,
    "lat": 28.654839994954585
  },
  {
    "name": "Bushnell",
    "lng": -82.10350072501245,
    "lat": 28.657341259750577
  },
  {
    "name": "Bushnell",
    "lng": -82.10303481987506,
    "lat": 28.656551180727533
  },
  {
    "name": "Bushnell",
    "lng": -82.10301963559188,
    "lat": 28.654838933036547
  },
  {
    "name": "Bushnell",
    "lng": -82.10033076666666,
    "lat": 28.667501766584582
  },
  {
    "name": "Bushton",
    "lng": -98.39549552918271,
    "lat": 38.51243487224619
  },
  {
    "name": "Bussey",
    "lng": -92.88426033691168,
    "lat": 41.20537267009165
  },
  {
    "name": "Butler",
    "lng": -99.18519430257253,
    "lat": 35.61861659297871
  },
  {
    "name": "Butler",
    "lng": -97.711609718328,
    "lat": 45.26199180647878
  },
  {
    "name": "Butler",
    "lng": -94.35249230404463,
    "lat": 38.274634293933126
  },
  {
    "name": "Butler",
    "lng": -94.3387254849542,
    "lat": 38.25913272981657
  },
  {
    "name": "Butler",
    "lng": -89.53180269326431,
    "lat": 39.19754917120882
  },
  {
    "name": "Butler",
    "lng": -88.21386340481561,
    "lat": 32.09518002554716
  },
  {
    "name": "Butler",
    "lng": -88.07125638219983,
    "lat": 43.10853050771618
  },
  {
    "name": "Butler",
    "lng": -84.87162460755304,
    "lat": 41.42704374080362
  },
  {
    "name": "Butler",
    "lng": -84.3716522860697,
    "lat": 38.78766777414627
  },
  {
    "name": "Butler",
    "lng": -84.2375983706045,
    "lat": 32.556981690676054
  },
  {
    "name": "Butler",
    "lng": -82.42084062597739,
    "lat": 40.589633573551
  },
  {
    "name": "Butler",
    "lng": -79.89617405981078,
    "lat": 40.86134418304325
  },
  {
    "name": "Butlerville",
    "lng": -84.09088102611152,
    "lat": 39.30114449920071
  },
  {
    "name": "Butner",
    "lng": -78.75071149476877,
    "lat": 36.12821723875214
  },
  {
    "name": "Butte",
    "lng": -100.66596516696978,
    "lat": 47.83702748094293
  },
  {
    "name": "Butte",
    "lng": -98.84790414760009,
    "lat": 42.91245396686795
  },
  {
    "name": "Butte City",
    "lng": -113.24086403278389,
    "lat": 43.606990507032464
  },
  {
    "name": "Butterfield",
    "lng": -94.79406568020784,
    "lat": 43.95835610669941
  },
  {
    "name": "Butterfield",
    "lng": -94.78501212152922,
    "lat": 43.960738191502074
  },
  {
    "name": "Butterfield",
    "lng": -93.90429288160826,
    "lat": 36.746806773697735
  },
  {
    "name": "Butternut",
    "lng": -90.4988152877255,
    "lat": 46.01379565931636
  },
  {
    "name": "Buttonwillow",
    "lng": -119.44064679366336,
    "lat": 35.40922325682974
  },
  {
    "name": "Buxton",
    "lng": -97.10009452696463,
    "lat": 47.60238281427211
  },
  {
    "name": "Buxton",
    "lng": -70.53764854390025,
    "lat": 43.6429384072025
  },
  {
    "name": "Byars",
    "lng": -97.05409220312687,
    "lat": 34.87237302083957
  },
  {
    "name": "Byers",
    "lng": -98.86694738944614,
    "lat": 37.787935722951815
  },
  {
    "name": "Byesville",
    "lng": -81.54581318863029,
    "lat": 39.973324996630744
  },
  {
    "name": "Byhalia",
    "lng": -89.68903614639025,
    "lat": 34.869061748095305
  },
  {
    "name": "Byng",
    "lng": -96.66780795445479,
    "lat": 34.86590536250191
  },
  {
    "name": "Bynum",
    "lng": -97.00307794169181,
    "lat": 31.96907142084788
  },
  {
    "name": "Byrdstown",
    "lng": -85.13403158709338,
    "lat": 36.573502014515284
  },
  {
    "name": "Byrnes Mill",
    "lng": -90.57417195003983,
    "lat": 38.43950089802297
  },
  {
    "name": "Byromville",
    "lng": -83.90749385181996,
    "lat": 32.20196610157417
  },
  {
    "name": "Byron",
    "lng": -108.50821059976235,
    "lat": 44.79633503773459
  },
  {
    "name": "Byron",
    "lng": -97.76857989943234,
    "lat": 40.004855400847006
  },
  {
    "name": "Byron",
    "lng": -92.64163444524489,
    "lat": 44.037950476868595
  },
  {
    "name": "Byron",
    "lng": -89.26268017186077,
    "lat": 42.126284968731404
  },
  {
    "name": "Byron",
    "lng": -83.94671051991715,
    "lat": 42.82391666554309
  },
  {
    "name": "Byron",
    "lng": -83.75404724717303,
    "lat": 32.64752521169009
  },
  {
    "name": "Byron",
    "lng": -70.66403883301496,
    "lat": 44.724185996027124
  },
  {
    "name": "Cabery",
    "lng": -88.20422187091539,
    "lat": 40.99537789034601
  },
  {
    "name": "Cabool",
    "lng": -92.1035271880359,
    "lat": 37.12669522009761
  },
  {
    "name": "Cabool",
    "lng": -92.06904498706142,
    "lat": 37.102976117197876
  },
  {
    "name": "Cabot",
    "lng": -92.02690575128351,
    "lat": 34.979051635759134
  },
  {
    "name": "Cache",
    "lng": -98.6275244385124,
    "lat": 34.62853050789999
  },
  {
    "name": "Cache",
    "lng": -98.59343801967374,
    "lat": 34.632639200163595
  },
  {
    "name": "Cactus",
    "lng": -102.00868298250403,
    "lat": 36.04495851748889
  },
  {
    "name": "Cactus",
    "lng": -101.98537064702427,
    "lat": 36.02521600058972
  },
  {
    "name": "Caddo",
    "lng": -96.26905678451118,
    "lat": 34.12988278604783
  },
  {
    "name": "Caddo Mills",
    "lng": -96.33922998797807,
    "lat": 33.05761541972747
  },
  {
    "name": "Caddo Mills",
    "lng": -96.31627704956112,
    "lat": 33.06217736825669
  },
  {
    "name": "Caddo Mills",
    "lng": -96.23041959449367,
    "lat": 33.05204835484803
  },
  {
    "name": "Caddo Mills",
    "lng": -96.20184175627895,
    "lat": 33.03403536895322
  },
  {
    "name": "Caddo Valley",
    "lng": -93.09814211634482,
    "lat": 34.18212947595948
  },
  {
    "name": "Caddo Valley",
    "lng": -93.06722445160379,
    "lat": 34.18982916580637
  },
  {
    "name": "Caddo Valley",
    "lng": -93.0658453521277,
    "lat": 34.19023980245697
  },
  {
    "name": "Cadillac",
    "lng": -85.41651622179337,
    "lat": 44.24921880033348
  },
  {
    "name": "Cadiz",
    "lng": -87.81758666666664,
    "lat": 36.85265566649915
  },
  {
    "name": "Cadiz",
    "lng": -87.807991982501,
    "lat": 36.86938058806394
  },
  {
    "name": "Cadiz",
    "lng": -85.48711853278273,
    "lat": 39.95078372667464
  },
  {
    "name": "Cadiz",
    "lng": -80.99795792099087,
    "lat": 40.26444715538601
  },
  {
    "name": "Cadott",
    "lng": -91.15171427186115,
    "lat": 44.95095461317729
  },
  {
    "name": "Cadwell",
    "lng": -83.04213479102235,
    "lat": 32.339503921549884
  },
  {
    "name": "Cahokia",
    "lng": -90.177658357447,
    "lat": 38.56496709905824
  },
  {
    "name": "Cainsville",
    "lng": -93.77467559550226,
    "lat": 40.43980358680297
  },
  {
    "name": "Cairo",
    "lng": -98.6063853099423,
    "lat": 41.002848273795465
  },
  {
    "name": "Cairo",
    "lng": -92.44103610646297,
    "lat": 39.51122731247879
  },
  {
    "name": "Cairo",
    "lng": -89.18197673828432,
    "lat": 37.006139694073184
  },
  {
    "name": "Cairo",
    "lng": -84.20483294213864,
    "lat": 30.87867393902601
  },
  {
    "name": "Cairo",
    "lng": -84.08447133864537,
    "lat": 40.83075454781648
  },
  {
    "name": "Cairo",
    "lng": -81.15430086051522,
    "lat": 39.20670025404291
  },
  {
    "name": "Cajah's Mountain",
    "lng": -81.53587634396766,
    "lat": 35.84978343548637
  },
  {
    "name": "Calabasas",
    "lng": -118.6688647852183,
    "lat": 34.13744768078357
  },
  {
    "name": "Calabash",
    "lng": -78.55206258861094,
    "lat": 33.89617310286899
  },
  {
    "name": "Calais",
    "lng": -67.2238017527425,
    "lat": 45.1340632529831
  },
  {
    "name": "Calamar city",
    "lng": -101.69021802768407,
    "lat": 40.04356367049
  },
  {
    "name": "Calamus",
    "lng": -90.75989482287895,
    "lat": 41.8264471630982
  },
  {
    "name": "Caldwell",
    "lng": -116.71851832592483,
    "lat": 43.635025716486666
  },
  {
    "name": "Caldwell",
    "lng": -116.71833449410339,
    "lat": 43.67147135289094
  },
  {
    "name": "Caldwell",
    "lng": -116.68622665460067,
    "lat": 43.60570034513981
  },
  {
    "name": "Caldwell",
    "lng": -116.65960206211736,
    "lat": 43.64580970002879
  },
  {
    "name": "Caldwell",
    "lng": -116.64801789075595,
    "lat": 43.59749779634169
  },
  {
    "name": "Caldwell",
    "lng": -116.61177887185048,
    "lat": 43.663574609655186
  },
  {
    "name": "Caldwell",
    "lng": -116.58957714541178,
    "lat": 43.67732282462403
  },
  {
    "name": "Caldwell",
    "lng": -97.60858249834308,
    "lat": 37.03492118808351
  },
  {
    "name": "Caldwell",
    "lng": -96.70061699988658,
    "lat": 30.530779820659657
  },
  {
    "name": "Caldwell",
    "lng": -90.81345374971389,
    "lat": 35.078845606715674
  },
  {
    "name": "Caldwell",
    "lng": -81.51278654031877,
    "lat": 39.74682976761928
  },
  {
    "name": "Cale",
    "lng": -93.23495498364284,
    "lat": 33.62781313348683
  },
  {
    "name": "Caledonia",
    "lng": -91.50094252498866,
    "lat": 43.634427930213946
  },
  {
    "name": "Caledonia",
    "lng": -90.77207119552597,
    "lat": 37.76473998884063
  },
  {
    "name": "Caledonia",
    "lng": -88.89392439324398,
    "lat": 42.36862386137087
  },
  {
    "name": "Caledonia",
    "lng": -88.32596297764738,
    "lat": 33.683620542140986
  },
  {
    "name": "Caledonia",
    "lng": -87.87301914918112,
    "lat": 42.79970234776412
  },
  {
    "name": "Caledonia",
    "lng": -85.54522173398458,
    "lat": 42.78274934539371
  },
  {
    "name": "Caledonia",
    "lng": -85.51529529202226,
    "lat": 42.794319488756564
  },
  {
    "name": "Caledonia",
    "lng": -82.9693411744251,
    "lat": 40.63625371476851
  },
  {
    "name": "Caledonia",
    "lng": -77.85708579238035,
    "lat": 42.975718876999736
  },
  {
    "name": "Calera",
    "lng": -96.44606040684961,
    "lat": 33.936337965875644
  },
  {
    "name": "Calera",
    "lng": -96.42902179637102,
    "lat": 33.93941147021573
  },
  {
    "name": "Calera",
    "lng": -86.79826875323546,
    "lat": 33.169666923746206
  },
  {
    "name": "Calera",
    "lng": -86.75110758047437,
    "lat": 33.12425556485971
  },
  {
    "name": "Calera",
    "lng": -86.71669404845386,
    "lat": 33.11144857649753
  },
  {
    "name": "Calera",
    "lng": -86.7106089399944,
    "lat": 33.15744547927323
  },
  {
    "name": "Calera",
    "lng": -86.68338037632783,
    "lat": 33.13366995785415
  },
  {
    "name": "Calera",
    "lng": -86.68338037632775,
    "lat": 33.133669957854195
  },
  {
    "name": "Calexico",
    "lng": -115.49516470198799,
    "lat": 32.6844327820419
  },
  {
    "name": "Calhoun",
    "lng": -93.6251040352968,
    "lat": 38.46837315931066
  },
  {
    "name": "Calhoun",
    "lng": -88.04259434408826,
    "lat": 38.651158767859734
  },
  {
    "name": "Calhoun",
    "lng": -87.25985829333197,
    "lat": 37.54092201929337
  },
  {
    "name": "Calhoun",
    "lng": -84.97437171754386,
    "lat": 34.48748137799937
  },
  {
    "name": "Calhoun",
    "lng": -84.95572741029365,
    "lat": 34.446463315551014
  },
  {
    "name": "Calhoun",
    "lng": -84.95256016794008,
    "lat": 34.52894819615328
  },
  {
    "name": "Calhoun",
    "lng": -84.9395782149974,
    "lat": 34.55911375569788
  },
  {
    "name": "Calhoun",
    "lng": -84.93947579502516,
    "lat": 34.49360952120057
  },
  {
    "name": "Calhoun",
    "lng": -84.93783029156306,
    "lat": 34.56468197441398
  },
  {
    "name": "Calhoun",
    "lng": -84.93693972935833,
    "lat": 34.53570747263735
  },
  {
    "name": "Calhoun",
    "lng": -84.93597105733075,
    "lat": 34.45716860721642
  },
  {
    "name": "Calhoun",
    "lng": -84.93410229208934,
    "lat": 34.558823128799965
  },
  {
    "name": "Calhoun",
    "lng": -84.93282830602502,
    "lat": 34.435304008078475
  },
  {
    "name": "Calhoun",
    "lng": -84.93082787174541,
    "lat": 34.55229272567683
  },
  {
    "name": "Calhoun",
    "lng": -84.92911194962913,
    "lat": 34.555519664129584
  },
  {
    "name": "Calhoun",
    "lng": -84.92761489231847,
    "lat": 34.55115179323744
  },
  {
    "name": "Calhoun",
    "lng": -84.92621944213738,
    "lat": 34.54494074704548
  },
  {
    "name": "Calhoun",
    "lng": -84.92066417667593,
    "lat": 34.50616246248841
  },
  {
    "name": "Calhoun",
    "lng": -84.9185542363257,
    "lat": 34.44421048138745
  },
  {
    "name": "Calhoun",
    "lng": -84.9167804750782,
    "lat": 34.505768983117505
  },
  {
    "name": "Calhoun",
    "lng": -84.91674031108344,
    "lat": 34.459584092060716
  },
  {
    "name": "Calhoun",
    "lng": -84.9161332184123,
    "lat": 34.43647030547432
  },
  {
    "name": "Calhoun",
    "lng": -84.91414059415554,
    "lat": 34.47201420472751
  },
  {
    "name": "Calhoun",
    "lng": -84.91331114295471,
    "lat": 34.47014216757083
  },
  {
    "name": "Calhoun",
    "lng": -84.91262,
    "lat": 34.464483004866224
  },
  {
    "name": "Calhoun",
    "lng": -84.7457528986681,
    "lat": 35.29756086457172
  },
  {
    "name": "Calhoun City",
    "lng": -89.31443235109514,
    "lat": 33.8586145340321
  },
  {
    "name": "Calhoun Falls",
    "lng": -82.59560920726328,
    "lat": 34.09207181883706
  },
  {
    "name": "Calico Rock",
    "lng": -92.13409546435987,
    "lat": 36.14017534103533
  },
  {
    "name": "Caliente",
    "lng": -114.51952455471464,
    "lat": 37.61239601364004
  },
  {
    "name": "California",
    "lng": -123.05194940890696,
    "lat": 37.733506401175816
  },
  {
    "name": "California",
    "lng": -119.96464374354639,
    "lat": 34.01238668595221
  },
  {
    "name": "California",
    "lng": -119.73426240566445,
    "lat": 37.38297058053858
  },
  {
    "name": "California",
    "lng": -119.69471917009034,
    "lat": 33.36228936839096
  },
  {
    "name": "California",
    "lng": -119.50218143030604,
    "lat": 33.24727081291555
  },
  {
    "name": "California",
    "lng": -119.04122511252183,
    "lat": 33.476510106593295
  },
  {
    "name": "California",
    "lng": -118.4915782475406,
    "lat": 32.910236071879794
  },
  {
    "name": "California",
    "lng": -118.44850758164168,
    "lat": 33.391492867199474
  },
  {
    "name": "California",
    "lng": -92.56400303928548,
    "lat": 38.630930338090735
  },
  {
    "name": "California",
    "lng": -84.26169092916707,
    "lat": 38.920127806120334
  },
  {
    "name": "Calimesa",
    "lng": -117.05482327114417,
    "lat": 33.987199253372864
  },
  {
    "name": "Calio",
    "lng": -98.93803973092507,
    "lat": 48.623813301189045
  },
  {
    "name": "Calipatria",
    "lng": -115.54946116875101,
    "lat": 33.14384509376965
  },
  {
    "name": "Calipatria",
    "lng": -115.51467017607361,
    "lat": 33.125277158685805
  },
  {
    "name": "Calipatria",
    "lng": -115.4922181896117,
    "lat": 33.1692709366304
  },
  {
    "name": "Calistoga",
    "lng": -122.58236771652653,
    "lat": 38.58170767841653
  },
  {
    "name": "Callao",
    "lng": -92.6237030296821,
    "lat": 39.762301799778804
  },
  {
    "name": "Callaway",
    "lng": -99.9203015898028,
    "lat": 41.29143052479304
  },
  {
    "name": "Callaway",
    "lng": -95.91183007810922,
    "lat": 46.98201447142305
  },
  {
    "name": "Callender",
    "lng": -94.29548161661471,
    "lat": 42.36166011088049
  },
  {
    "name": "Calmar",
    "lng": -91.86785325002074,
    "lat": 43.18088354068986
  },
  {
    "name": "Calpella",
    "lng": -123.19723058186621,
    "lat": 39.2322284518924
  },
  {
    "name": "Calumet",
    "lng": -98.12464517830078,
    "lat": 35.60136893014052
  },
  {
    "name": "Calumet",
    "lng": -95.55148922895037,
    "lat": 42.94508256099653
  },
  {
    "name": "Calumet",
    "lng": -93.26658563876492,
    "lat": 47.31981080348082
  },
  {
    "name": "Calumet",
    "lng": -88.45349015973513,
    "lat": 47.2478849634051
  },
  {
    "name": "Calumet City",
    "lng": -87.55049608942991,
    "lat": 41.613390725161864
  },
  {
    "name": "Calumet Park",
    "lng": -87.65779143132107,
    "lat": 41.6650489038619
  },
  {
    "name": "Calvert",
    "lng": -96.67154230605485,
    "lat": 30.979150128044466
  },
  {
    "name": "Calvert City",
    "lng": -88.36696094231658,
    "lat": 37.03285335677325
  },
  {
    "name": "Calverton Park",
    "lng": -90.31052993699468,
    "lat": 38.76561555303891
  },
  {
    "name": "Calvin",
    "lng": -98.9380740969076,
    "lat": 48.85186312068127
  },
  {
    "name": "Calvin",
    "lng": -96.25290702321522,
    "lat": 34.96722416425976
  },
  {
    "name": "Calvin",
    "lng": -92.77580620959768,
    "lat": 31.96169199109798
  },
  {
    "name": "Calypso",
    "lng": -78.10441459266916,
    "lat": 35.15478456113733
  },
  {
    "name": "Camanche",
    "lng": -90.2768672186659,
    "lat": 41.7927965287983
  },
  {
    "name": "Camargo",
    "lng": -99.28845816584108,
    "lat": 36.01790211581498
  },
  {
    "name": "Camargo",
    "lng": -88.16708756104057,
    "lat": 39.79903925594859
  },
  {
    "name": "Camargo",
    "lng": -83.89257665811401,
    "lat": 37.99696037702838
  },
  {
    "name": "Camarillo",
    "lng": -119.03224452731764,
    "lat": 34.22300243728682
  },
  {
    "name": "Cambria",
    "lng": -89.11867289563645,
    "lat": 37.780056293705385
  },
  {
    "name": "Cambria",
    "lng": -89.11093206332325,
    "lat": 43.541631511350666
  },
  {
    "name": "Cambridge",
    "lng": -116.67775628869678,
    "lat": 44.57185379144879
  },
  {
    "name": "Cambridge",
    "lng": -100.16610904095675,
    "lat": 40.28408650957418
  },
  {
    "name": "Cambridge",
    "lng": -96.66696483668578,
    "lat": 37.317491976851116
  },
  {
    "name": "Cambridge",
    "lng": -93.53592401788593,
    "lat": 41.900086210397156
  },
  {
    "name": "Cambridge",
    "lng": -93.26173062756239,
    "lat": 45.55706756699505
  },
  {
    "name": "Cambridge",
    "lng": -93.24407972905921,
    "lat": 45.56052375366028
  },
  {
    "name": "Cambridge",
    "lng": -93.22953243395881,
    "lat": 45.52604938951586
  },
  {
    "name": "Cambridge",
    "lng": -93.22414735217522,
    "lat": 45.56337970498471
  },
  {
    "name": "Cambridge",
    "lng": -90.1907487030195,
    "lat": 41.29834838825738
  },
  {
    "name": "Cambridge",
    "lng": -89.02145207740459,
    "lat": 43.00686165793781
  },
  {
    "name": "Cambridge",
    "lng": -85.61667940275353,
    "lat": 38.22172017238081
  },
  {
    "name": "Cambridge",
    "lng": -81.58775916442876,
    "lat": 40.0050097645369
  },
  {
    "name": "Cambridge",
    "lng": -81.58671884230398,
    "lat": 40.023585166018165
  },
  {
    "name": "Cambridge",
    "lng": -81.57416821568195,
    "lat": 39.99399118052476
  },
  {
    "name": "Cambridge",
    "lng": -76.07906436674787,
    "lat": 38.551169941054
  },
  {
    "name": "Cambridge",
    "lng": -73.3811376783267,
    "lat": 43.02790698373224
  },
  {
    "name": "Cambridge",
    "lng": -72.87823025117838,
    "lat": 44.639212136843284
  },
  {
    "name": "Cambridge",
    "lng": -71.11850680761859,
    "lat": 42.375857213946645
  },
  {
    "name": "Cambridge",
    "lng": -69.44292565020208,
    "lat": 45.038189233595375
  },
  {
    "name": "Cambridge City",
    "lng": -85.17045859353428,
    "lat": 39.8124631142876
  },
  {
    "name": "Camden",
    "lng": -94.01998865070313,
    "lat": 39.20029254469011
  },
  {
    "name": "Camden",
    "lng": -92.84657191245226,
    "lat": 33.567266722715175
  },
  {
    "name": "Camden",
    "lng": -90.77332409520608,
    "lat": 40.15282963364168
  },
  {
    "name": "Camden",
    "lng": -88.02301389810533,
    "lat": 36.05280303389876
  },
  {
    "name": "Camden",
    "lng": -87.99763603439702,
    "lat": 36.06112017716118
  },
  {
    "name": "Camden",
    "lng": -87.29660317005569,
    "lat": 32.00074418175678
  },
  {
    "name": "Camden",
    "lng": -86.53903654019888,
    "lat": 40.60981555469899
  },
  {
    "name": "Camden",
    "lng": -84.75618367572773,
    "lat": 41.75508639914662
  },
  {
    "name": "Camden",
    "lng": -84.64452147494956,
    "lat": 39.637395308526955
  },
  {
    "name": "Camden",
    "lng": -80.61286260749459,
    "lat": 34.29190291837295
  },
  {
    "name": "Camden",
    "lng": -80.60959235748783,
    "lat": 34.257130672966575
  },
  {
    "name": "Camden",
    "lng": -80.58360727591182,
    "lat": 34.230959048177574
  },
  {
    "name": "Camden",
    "lng": -80.57520553928634,
    "lat": 34.22626418826961
  },
  {
    "name": "Camden",
    "lng": -75.74773073595601,
    "lat": 43.336479533932184
  },
  {
    "name": "Camden",
    "lng": -75.10739701238768,
    "lat": 39.93624729549834
  },
  {
    "name": "Camden",
    "lng": -69.07536975600678,
    "lat": 44.2235694739428
  },
  {
    "name": "Camden Point",
    "lng": -94.74852147816415,
    "lat": 39.45415617564759
  },
  {
    "name": "Camden-on-Gauley",
    "lng": -80.59728940398503,
    "lat": 38.36855647586769
  },
  {
    "name": "Camdenton",
    "lng": -92.75002246042948,
    "lat": 38.01229508059173
  },
  {
    "name": "Cameron",
    "lng": -111.42692258986986,
    "lat": 35.853734573986166
  },
  {
    "name": "Cameron",
    "lng": -96.97622315661827,
    "lat": 30.860717657480013
  },
  {
    "name": "Cameron",
    "lng": -94.53587470810047,
    "lat": 35.1350879510936
  },
  {
    "name": "Cameron",
    "lng": -94.23374801485083,
    "lat": 39.74515210153257
  },
  {
    "name": "Cameron",
    "lng": -91.75842688448806,
    "lat": 45.42135718244926
  },
  {
    "name": "Cameron",
    "lng": -91.75554150660614,
    "lat": 45.387235302661665
  },
  {
    "name": "Cameron",
    "lng": -91.75022866894035,
    "lat": 45.39058981583461
  },
  {
    "name": "Cameron",
    "lng": -91.7411032477883,
    "lat": 45.407162690527386
  },
  {
    "name": "Cameron",
    "lng": -80.71503355278419,
    "lat": 33.55795717784462
  },
  {
    "name": "Cameron",
    "lng": -80.56918828914611,
    "lat": 39.82792307948566
  },
  {
    "name": "Cameron",
    "lng": -79.27570449573253,
    "lat": 35.31751519290542
  },
  {
    "name": "Cameron",
    "lng": -79.25428468226885,
    "lat": 35.32599017980937
  },
  {
    "name": "Cameron",
    "lng": -79.23318018746612,
    "lat": 35.315381821869856
  },
  {
    "name": "Camilla",
    "lng": -84.20691077412485,
    "lat": 31.235732276388372
  },
  {
    "name": "Camillus",
    "lng": -76.30949840047867,
    "lat": 43.038672929811284
  },
  {
    "name": "Cammack Village",
    "lng": -92.34497831930278,
    "lat": 34.78080119166164
  },
  {
    "name": "Camp Crook",
    "lng": -103.97488819271501,
    "lat": 45.55005351820139
  },
  {
    "name": "Camp Douglas",
    "lng": -90.26937148315919,
    "lat": 43.91936007567772
  },
  {
    "name": "Camp Hill",
    "lng": -85.65374818333956,
    "lat": 32.79987535468434
  },
  {
    "name": "Camp Point",
    "lng": -91.06649028681825,
    "lat": 40.0384793967064
  },
  {
    "name": "Campbell",
    "lng": -98.73152225861233,
    "lat": 40.29700656969565
  },
  {
    "name": "Campbell",
    "lng": -96.40547928291923,
    "lat": 46.0975327278237
  },
  {
    "name": "Campbell",
    "lng": -95.990548951482,
    "lat": 33.12803154063923
  },
  {
    "name": "Campbell",
    "lng": -95.95568185239722,
    "lat": 33.156946090291385
  },
  {
    "name": "Campbell",
    "lng": -95.95286297890145,
    "lat": 33.14797048851916
  },
  {
    "name": "Campbell",
    "lng": -90.0762550942902,
    "lat": 36.49296556275062
  },
  {
    "name": "Campbell",
    "lng": -81.45266863789735,
    "lat": 28.260357576598942
  },
  {
    "name": "Campbell",
    "lng": -80.59053525431501,
    "lat": 41.07777275739043
  },
  {
    "name": "Campbell Hill",
    "lng": -89.5508053351452,
    "lat": 37.929782248498434
  },
  {
    "name": "Campbell Station",
    "lng": -91.25375740077999,
    "lat": 35.66779853947871
  },
  {
    "name": "Campbellsburg",
    "lng": -86.26133297676661,
    "lat": 38.651404870677204
  },
  {
    "name": "Campbellsburg",
    "lng": -85.20700416076129,
    "lat": 38.52637758507367
  },
  {
    "name": "Campbellsport",
    "lng": -88.28124506552727,
    "lat": 43.59759260596233
  },
  {
    "name": "Campbellsville",
    "lng": -85.37888556892364,
    "lat": 37.34068651382011
  },
  {
    "name": "Campbellsville",
    "lng": -85.34975091889935,
    "lat": 37.34939186437143
  },
  {
    "name": "Campbelltown",
    "lng": -76.58471598792148,
    "lat": 40.276158992037075
  },
  {
    "name": "Campo",
    "lng": -102.57880354048882,
    "lat": 37.10473177243411
  },
  {
    "name": "Campobello",
    "lng": -82.15152698237242,
    "lat": 35.12578766382393
  },
  {
    "name": "Campobello",
    "lng": -82.14031261887926,
    "lat": 35.09987441466894
  },
  {
    "name": "Campti",
    "lng": -93.1157598781776,
    "lat": 31.898166293294327
  },
  {
    "name": "Campton",
    "lng": -83.54725344243039,
    "lat": 37.73591083580199
  },
  {
    "name": "Campton Hills",
    "lng": -88.46846530401847,
    "lat": 41.998635815246914
  },
  {
    "name": "Campton Hills",
    "lng": -88.4164390563885,
    "lat": 41.94957992421861
  },
  {
    "name": "Campus",
    "lng": -88.30732698539728,
    "lat": 41.02461543922399
  },
  {
    "name": "Canaan",
    "lng": -73.33228332961372,
    "lat": 42.03475026547726
  },
  {
    "name": "Canaan",
    "lng": -73.31016645914865,
    "lat": 41.9598142803875
  },
  {
    "name": "Canaan",
    "lng": -69.54975798922885,
    "lat": 44.77916154584372
  },
  {
    "name": "Canadian",
    "lng": -100.38383146352652,
    "lat": 35.909770356384776
  },
  {
    "name": "Canadian",
    "lng": -95.64966402229238,
    "lat": 35.17415240403854
  },
  {
    "name": "Canajoharie",
    "lng": -74.56994447348764,
    "lat": 42.899778605939304
  },
  {
    "name": "Canal Fulton",
    "lng": -81.5853124113513,
    "lat": 40.888502162291424
  },
  {
    "name": "Canal Winchester",
    "lng": -82.8145978628481,
    "lat": 39.84299254568093
  },
  {
    "name": "Canalou",
    "lng": -89.68650484498363,
    "lat": 36.754017531343756
  },
  {
    "name": "Canandaigua",
    "lng": -77.28052642294516,
    "lat": 42.88851485956956
  },
  {
    "name": "Canaseraga",
    "lng": -77.77729099936477,
    "lat": 42.46334872736669
  },
  {
    "name": "Canastota",
    "lng": -75.75607057974352,
    "lat": 43.08356133994595
  },
  {
    "name": "Canby",
    "lng": -96.29606336666667,
    "lat": 44.718616942350444
  },
  {
    "name": "Canby",
    "lng": -96.26895750162711,
    "lat": 44.71560271784906
  },
  {
    "name": "Candia",
    "lng": -71.30953726199675,
    "lat": 43.05947425826704
  },
  {
    "name": "Cando",
    "lng": -99.20299834681418,
    "lat": 48.487212643065064
  },
  {
    "name": "Candor",
    "lng": -79.7427752289958,
    "lat": 35.29191022357907
  },
  {
    "name": "Candor",
    "lng": -79.72115569450129,
    "lat": 35.30276269392869
  },
  {
    "name": "Candor",
    "lng": -76.33664859801806,
    "lat": 42.228437713130084
  },
  {
    "name": "Caney",
    "lng": -96.21700847993755,
    "lat": 34.23558499847858
  },
  {
    "name": "Caney",
    "lng": -95.93315183181251,
    "lat": 37.01316505697889
  },
  {
    "name": "Caney",
    "lng": -95.9150922528255,
    "lat": 37.02248322701125
  },
  {
    "name": "Caney",
    "lng": -95.89624417167342,
    "lat": 37.024623601554374
  },
  {
    "name": "Caney City",
    "lng": -96.03813886055676,
    "lat": 32.20962718347672
  },
  {
    "name": "Caneyville",
    "lng": -86.48988709495625,
    "lat": 37.42488217846523
  },
  {
    "name": "Canfield",
    "lng": -80.76704065601979,
    "lat": 41.03150848103474
  },
  {
    "name": "Canisteo",
    "lng": -77.60529516344393,
    "lat": 42.2703186938365
  },
  {
    "name": "Canistota",
    "lng": -97.29258546101772,
    "lat": 43.5977560077686
  },
  {
    "name": "Cankton",
    "lng": -92.11308386025688,
    "lat": 30.345534114818953
  },
  {
    "name": "Cannelburg",
    "lng": -86.99724322197387,
    "lat": 38.66777513096144
  },
  {
    "name": "Cannelton",
    "lng": -86.73704808075223,
    "lat": 37.91051325277781
  },
  {
    "name": "Cannon Falls",
    "lng": -92.90339549186204,
    "lat": 44.51236054332727
  },
  {
    "name": "Cannonville",
    "lng": -112.05309771865574,
    "lat": 37.568852803473376
  },
  {
    "name": "Canon",
    "lng": -83.11068727028764,
    "lat": 34.345360456062295
  },
  {
    "name": "Canova",
    "lng": -97.5040449353358,
    "lat": 43.88120779685335
  },
  {
    "name": "Canterbury",
    "lng": -71.999813429469,
    "lat": 41.697909223027544
  },
  {
    "name": "Canterbury",
    "lng": -71.5453743184204,
    "lat": 43.352158153929466
  },
  {
    "name": "Canton",
    "lng": -98.58899621828148,
    "lat": 36.056461214172685
  },
  {
    "name": "Canton",
    "lng": -97.43007616402875,
    "lat": 38.38592780241357
  },
  {
    "name": "Canton",
    "lng": -96.58458315292728,
    "lat": 43.30321856867471
  },
  {
    "name": "Canton",
    "lng": -95.8639901917052,
    "lat": 32.554110642407885
  },
  {
    "name": "Canton",
    "lng": -95.84937185169014,
    "lat": 32.54975823361199
  },
  {
    "name": "Canton",
    "lng": -95.84857824286738,
    "lat": 32.5487216931222
  },
  {
    "name": "Canton",
    "lng": -91.94233018179784,
    "lat": 43.53334317562219
  },
  {
    "name": "Canton",
    "lng": -91.92984394394276,
    "lat": 43.529729254556926
  },
  {
    "name": "Canton",
    "lng": -91.52682214347602,
    "lat": 40.12927108476415
  },
  {
    "name": "Canton",
    "lng": -90.04116491812121,
    "lat": 40.563109493541894
  },
  {
    "name": "Canton",
    "lng": -90.02470905403915,
    "lat": 32.60277529373501
  },
  {
    "name": "Canton",
    "lng": -84.5035202633586,
    "lat": 34.1890985603536
  },
  {
    "name": "Canton",
    "lng": -84.49476208222664,
    "lat": 34.25325355282491
  },
  {
    "name": "Canton",
    "lng": -84.49048234665221,
    "lat": 34.24735836771015
  },
  {
    "name": "Canton",
    "lng": -84.46657584774053,
    "lat": 34.210237427983174
  },
  {
    "name": "Canton",
    "lng": -82.84133175718311,
    "lat": 35.61556897684912
  },
  {
    "name": "Canton",
    "lng": -82.83848659672267,
    "lat": 35.536123361969764
  },
  {
    "name": "Canton",
    "lng": -82.8023717394763,
    "lat": 35.53599945725415
  },
  {
    "name": "Canton",
    "lng": -81.36779806486986,
    "lat": 40.80801976873988
  },
  {
    "name": "Canton",
    "lng": -75.16718831464374,
    "lat": 44.6008929742736
  },
  {
    "name": "Canton",
    "lng": -72.90850674667628,
    "lat": 41.85992832498869
  },
  {
    "name": "Canton",
    "lng": -70.298586900541,
    "lat": 44.47244311877038
  },
  {
    "name": "Canton City",
    "lng": -97.66767026809451,
    "lat": 48.687879245627435
  },
  {
    "name": "Canton Valley",
    "lng": -72.89772442191342,
    "lat": 41.831558178944874
  },
  {
    "name": "Cantrall",
    "lng": -89.6789964490391,
    "lat": 39.9352890817482
  },
  {
    "name": "Cantril",
    "lng": -92.0684384127271,
    "lat": 40.642726177418744
  },
  {
    "name": "Canute",
    "lng": -99.27752258467963,
    "lat": 35.42197289846875
  },
  {
    "name": "Canyon",
    "lng": -101.9189819427397,
    "lat": 34.99115986004448
  },
  {
    "name": "Canyon Lake",
    "lng": -117.26206132936561,
    "lat": 33.688312049997016
  },
  {
    "name": "Canyon Lakes at Stonegate",
    "lng": -95.68151601436895,
    "lat": 29.92147084889082
  },
  {
    "name": "Canyon Lakes at Stonegate",
    "lng": -95.68036752171871,
    "lat": 29.916720580464858
  },
  {
    "name": "Canyon Lakes at Stonegate",
    "lng": -95.6756289011396,
    "lat": 29.92587361600333
  },
  {
    "name": "Canyon Lakes at Stonegate",
    "lng": -95.67521294238814,
    "lat": 29.92113098963833
  },
  {
    "name": "Canyon Lakes at Stonegate",
    "lng": -95.67059356627695,
    "lat": 29.916142757582612
  },
  {
    "name": "Canyon Lakes at Stonegate",
    "lng": -95.6702331825767,
    "lat": 29.911973179549193
  },
  {
    "name": "Canyon Lakes at Stonegate",
    "lng": -95.66631051922977,
    "lat": 29.916175406158715
  },
  {
    "name": "Canyon Lakes at Stonegate",
    "lng": -95.6634848508467,
    "lat": 29.91195603391684
  },
  {
    "name": "Canyon Lakes at Stonegate",
    "lng": -95.66180238574961,
    "lat": 29.91638447889202
  },
  {
    "name": "Capac",
    "lng": -82.92642792322957,
    "lat": 43.009455410386806
  },
  {
    "name": "Cape Carteret",
    "lng": -77.06820716867463,
    "lat": 34.7161578805302
  },
  {
    "name": "Cape Carteret",
    "lng": -77.05726005148315,
    "lat": 34.69695004854635
  },
  {
    "name": "Cape Charles",
    "lng": -76.0090999174317,
    "lat": 37.25760340804845
  },
  {
    "name": "Cape Coral",
    "lng": -82.05747381534671,
    "lat": 26.6385946051415
  },
  {
    "name": "Cape Coral",
    "lng": -81.99571887873918,
    "lat": 26.64484894841917
  },
  {
    "name": "Cape Elizabeth",
    "lng": -70.2019779677057,
    "lat": 43.569021770192556
  },
  {
    "name": "Cape Girardeau",
    "lng": -89.60856187196747,
    "lat": 37.37770745358672
  },
  {
    "name": "Cape Girardeau",
    "lng": -89.55642342130535,
    "lat": 37.30726931458015
  },
  {
    "name": "Cape May",
    "lng": -74.90185007994882,
    "lat": 38.93865442927804
  },
  {
    "name": "Cape Vincent",
    "lng": -76.33019954923681,
    "lat": 44.1262740230503
  },
  {
    "name": "Capital Villiage",
    "lng": -69.81895436773178,
    "lat": 44.32674296356314
  },
  {
    "name": "Capitan",
    "lng": -105.59851428933503,
    "lat": 33.53946983526101
  },
  {
    "name": "Capitan",
    "lng": -105.58518168926943,
    "lat": 33.55044315950944
  },
  {
    "name": "Capitola",
    "lng": -121.94452127965486,
    "lat": 36.965155371185965
  },
  {
    "name": "Capon Bridge",
    "lng": -78.43373445081414,
    "lat": 39.299758735829855
  },
  {
    "name": "Capron",
    "lng": -98.57757032070954,
    "lat": 36.89667739223214
  },
  {
    "name": "Capron",
    "lng": -88.73929128919296,
    "lat": 42.39855540084522
  },
  {
    "name": "Capron",
    "lng": -77.20128057106342,
    "lat": 36.709328651639694
  },
  {
    "name": "Caratunk",
    "lng": -69.90652864420912,
    "lat": 45.21416027127604
  },
  {
    "name": "Caraway",
    "lng": -90.3224897490917,
    "lat": 35.75952204505993
  },
  {
    "name": "Carbon",
    "lng": -98.82719424990496,
    "lat": 32.26840639909121
  },
  {
    "name": "Carbon",
    "lng": -94.82400343690715,
    "lat": 41.049661164665544
  },
  {
    "name": "Carbon",
    "lng": -87.10787061127678,
    "lat": 39.599091383196225
  },
  {
    "name": "Carbon Cliff",
    "lng": -90.39201666281791,
    "lat": 41.4982980278085
  },
  {
    "name": "Carbon Hill",
    "lng": -88.29995528369189,
    "lat": 41.29692164540937
  },
  {
    "name": "Carbon Hill",
    "lng": -87.52418026163461,
    "lat": 33.8962523227607
  },
  {
    "name": "Carbon Hill",
    "lng": -87.52418026163461,
    "lat": 33.896252322760716
  },
  {
    "name": "Carbon Hill",
    "lng": -87.5027396155647,
    "lat": 33.87385678428737
  },
  {
    "name": "Carbon Hill",
    "lng": -87.5027396155647,
    "lat": 33.87385678428738
  },
  {
    "name": "Carbonado",
    "lng": -122.0536974235607,
    "lat": 47.08059463731125
  },
  {
    "name": "Carbondale",
    "lng": -107.21447815932115,
    "lat": 39.39462027840871
  },
  {
    "name": "Carbondale",
    "lng": -95.69320186305104,
    "lat": 38.81923563864248
  },
  {
    "name": "Carbondale",
    "lng": -89.22375450098338,
    "lat": 37.7220041610807
  },
  {
    "name": "Carbondale",
    "lng": -89.14921057904766,
    "lat": 37.74552274091897
  },
  {
    "name": "Carbondale",
    "lng": -75.50484469422946,
    "lat": 41.57158731360787
  },
  {
    "name": "Cardiff",
    "lng": -86.93055691262998,
    "lat": 33.64718837367098
  },
  {
    "name": "Cardington",
    "lng": -82.89378328412583,
    "lat": 40.49798768445043
  },
  {
    "name": "Cardwell",
    "lng": -90.29090989714976,
    "lat": 36.04745439126009
  },
  {
    "name": "Carencro",
    "lng": -92.04043180383992,
    "lat": 30.311357608750768
  },
  {
    "name": "Carencro",
    "lng": -92.01427326275342,
    "lat": 30.30418561044087
  },
  {
    "name": "Carencro",
    "lng": -91.99967516551666,
    "lat": 30.314617915536573
  },
  {
    "name": "Carey",
    "lng": -113.94488584505403,
    "lat": 43.31059969212778
  },
  {
    "name": "Carey",
    "lng": -83.38396287458227,
    "lat": 40.950110970207575
  },
  {
    "name": "Caribou",
    "lng": -67.99196437072672,
    "lat": 46.86625821305475
  },
  {
    "name": "Carl",
    "lng": -83.81272661072828,
    "lat": 34.004519875036436
  },
  {
    "name": "Carl's Corner",
    "lng": -97.05255876945714,
    "lat": 32.08421982372597
  },
  {
    "name": "Carleton",
    "lng": -97.68158409056197,
    "lat": 40.30332302296201
  },
  {
    "name": "Carleton",
    "lng": -83.38983561550071,
    "lat": 42.05719407147488
  },
  {
    "name": "Carlin",
    "lng": -116.11307581211545,
    "lat": 40.717083563246796
  },
  {
    "name": "Carlinville",
    "lng": -89.8828170762521,
    "lat": 39.2983941072759
  },
  {
    "name": "Carlinville",
    "lng": -89.87605823612104,
    "lat": 39.277258735693415
  },
  {
    "name": "Carlisle",
    "lng": -93.52326895923972,
    "lat": 41.50757063454271
  },
  {
    "name": "Carlisle",
    "lng": -93.49323575280266,
    "lat": 41.512068581616184
  },
  {
    "name": "Carlisle",
    "lng": -91.73491715543786,
    "lat": 34.79513621289159
  },
  {
    "name": "Carlisle",
    "lng": -87.40142365572177,
    "lat": 38.96077850876811
  },
  {
    "name": "Carlisle",
    "lng": -84.31996814672593,
    "lat": 39.58070386812808
  },
  {
    "name": "Carlisle",
    "lng": -84.034870311796,
    "lat": 38.31817312098886
  },
  {
    "name": "Carlisle",
    "lng": -81.46451303948659,
    "lat": 34.59204114883726
  },
  {
    "name": "Carlisle",
    "lng": -77.20439896562809,
    "lat": 40.19981854901201
  },
  {
    "name": "Carlisle",
    "lng": -71.35127059257633,
    "lat": 42.53002215721331
  },
  {
    "name": "Carlock",
    "lng": -89.13218223021289,
    "lat": 40.58332962261308
  },
  {
    "name": "Carlos",
    "lng": -95.29249054444115,
    "lat": 45.974157093231746
  },
  {
    "name": "Carlsbad",
    "lng": -117.31125601150808,
    "lat": 33.119328639283715
  },
  {
    "name": "Carlsbad",
    "lng": -104.23957165729016,
    "lat": 32.459713853422066
  },
  {
    "name": "Carlsbad",
    "lng": -104.23876333368034,
    "lat": 32.40095225572934
  },
  {
    "name": "Carlton",
    "lng": -97.2931185310823,
    "lat": 38.68655335359947
  },
  {
    "name": "Carlton",
    "lng": -92.42318253434145,
    "lat": 46.65899835078894
  },
  {
    "name": "Carlton",
    "lng": -92.3895104634922,
    "lat": 46.66753161693249
  },
  {
    "name": "Carlton",
    "lng": -83.03851739116989,
    "lat": 34.042654100497565
  },
  {
    "name": "Carlyle",
    "lng": -89.37335001566483,
    "lat": 38.62179257344188
  },
  {
    "name": "Carmel",
    "lng": -86.14592532847753,
    "lat": 39.965088316049766
  },
  {
    "name": "Carmel",
    "lng": -69.03154445893495,
    "lat": 44.79694284349847
  },
  {
    "name": "Carmel-by-the-Sea",
    "lng": -121.96096122756673,
    "lat": 36.54501719762047
  },
  {
    "name": "Carmel-by-the-Sea",
    "lng": -121.91415234719763,
    "lat": 36.565648508397466
  },
  {
    "name": "Carmen",
    "lng": -98.45792838031753,
    "lat": 36.578944144391976
  },
  {
    "name": "Carmi",
    "lng": -88.20556272502203,
    "lat": 38.07454542596552
  },
  {
    "name": "Carmi",
    "lng": -88.19091396198361,
    "lat": 38.0745719746786
  },
  {
    "name": "Carmi",
    "lng": -88.16943196452115,
    "lat": 38.0868537516121
  },
  {
    "name": "Carnation",
    "lng": -121.90885194007681,
    "lat": 47.646294148872606
  },
  {
    "name": "Carnation",
    "lng": -121.90070834178661,
    "lat": 47.6279586181529
  },
  {
    "name": "Carnation",
    "lng": -121.87023269423919,
    "lat": 47.631403706459366
  },
  {
    "name": "Carnegie",
    "lng": -98.60082691030962,
    "lat": 35.10279759654778
  },
  {
    "name": "Carnegie",
    "lng": -98.56282434460192,
    "lat": 35.116555980390146
  },
  {
    "name": "Carnesville",
    "lng": -83.23236146633859,
    "lat": 34.3713480318811
  },
  {
    "name": "Carnesville",
    "lng": -83.2266780987261,
    "lat": 34.38280542642426
  },
  {
    "name": "Carney",
    "lng": -97.01522638555684,
    "lat": 35.80642211104623
  },
  {
    "name": "Carney",
    "lng": -87.55082745483983,
    "lat": 45.59088730954543
  },
  {
    "name": "Caro",
    "lng": -83.40215774711605,
    "lat": 43.48928514007519
  },
  {
    "name": "Caro",
    "lng": -83.38701343358572,
    "lat": 43.50878869300927
  },
  {
    "name": "Carol Stream",
    "lng": -88.13087298909053,
    "lat": 41.91843021282392
  },
  {
    "name": "Carolina",
    "lng": -86.52267125020718,
    "lat": 31.23368714004387
  },
  {
    "name": "Carolina Beach",
    "lng": -77.8961695411361,
    "lat": 34.039892182308854
  },
  {
    "name": "Carolina Management Area",
    "lng": -71.68785269853197,
    "lat": 41.44322034945926
  },
  {
    "name": "Carolina Management Area",
    "lng": -71.68542631238154,
    "lat": 41.47302671692125
  },
  {
    "name": "Carolina Shores",
    "lng": -78.57322374333533,
    "lat": 33.90768037325785
  },
  {
    "name": "Carpendale",
    "lng": -78.78960665447917,
    "lat": 39.62779794079242
  },
  {
    "name": "Carpenter",
    "lng": -93.01723378501936,
    "lat": 43.415024982318734
  },
  {
    "name": "Carpentersville",
    "lng": -88.28964517284882,
    "lat": 42.1226661617443
  },
  {
    "name": "Carpinteria",
    "lng": -119.5135776170579,
    "lat": 34.37740638627747
  },
  {
    "name": "Carpio",
    "lng": -101.71531732157042,
    "lat": 48.443099721171905
  },
  {
    "name": "Carrabassett Valley",
    "lng": -70.26953932668363,
    "lat": 45.066900621588026
  },
  {
    "name": "Carrabelle",
    "lng": -84.68986152044376,
    "lat": 29.862016587242394
  },
  {
    "name": "Carrabelle",
    "lng": -84.68969113850798,
    "lat": 29.831848203775255
  },
  {
    "name": "Carrabelle",
    "lng": -84.68710178702565,
    "lat": 29.850318574374555
  },
  {
    "name": "Carrabelle",
    "lng": -84.6823204657627,
    "lat": 29.831042693222237
  },
  {
    "name": "Carrabelle",
    "lng": -84.68223704792267,
    "lat": 29.8606162299067
  },
  {
    "name": "Carrabelle",
    "lng": -84.68182082089028,
    "lat": 29.83598348966156
  },
  {
    "name": "Carrabelle",
    "lng": -84.67644140210163,
    "lat": 29.852898117324973
  },
  {
    "name": "Carrabelle",
    "lng": -84.67544866854362,
    "lat": 29.85033472893411
  },
  {
    "name": "Carrabelle",
    "lng": -84.66873968407084,
    "lat": 29.84700465539128
  },
  {
    "name": "Carrabelle",
    "lng": -84.66697872961915,
    "lat": 29.841570267157763
  },
  {
    "name": "Carrabelle",
    "lng": -84.66481477172111,
    "lat": 29.83738045753782
  },
  {
    "name": "Carrabelle",
    "lng": -84.6580672566042,
    "lat": 29.837462785558813
  },
  {
    "name": "Carrabelle",
    "lng": -84.65440926627195,
    "lat": 29.880767296755145
  },
  {
    "name": "Carrboro",
    "lng": -79.12389025348709,
    "lat": 35.92535175223646
  },
  {
    "name": "Carrboro",
    "lng": -79.1023545897036,
    "lat": 35.96647373233694
  },
  {
    "name": "Carrboro",
    "lng": -79.0876324031108,
    "lat": 35.925741173402734
  },
  {
    "name": "Carrier",
    "lng": -98.01719979609841,
    "lat": 36.47859418293411
  },
  {
    "name": "Carrier Mills",
    "lng": -88.629411391003,
    "lat": 37.6884856270005
  },
  {
    "name": "Carrington",
    "lng": -99.13001684091621,
    "lat": 47.45079195917916
  },
  {
    "name": "Carrizo Springs",
    "lng": -99.8588786500248,
    "lat": 28.526602223900785
  },
  {
    "name": "Carrizozo",
    "lng": -105.88358801123894,
    "lat": 33.64450124825617
  },
  {
    "name": "Carroll",
    "lng": -97.19257814685567,
    "lat": 42.27938192356677
  },
  {
    "name": "Carroll",
    "lng": -97.1908909048698,
    "lat": 42.275752164601364
  },
  {
    "name": "Carroll",
    "lng": -94.86483850748883,
    "lat": 42.06945080797861
  },
  {
    "name": "Carroll",
    "lng": -82.7037929307895,
    "lat": 39.798988494608665
  },
  {
    "name": "Carrollton",
    "lng": -93.49521633834999,
    "lat": 39.3633526958165
  },
  {
    "name": "Carrollton",
    "lng": -90.40620302283247,
    "lat": 39.294814175625056
  },
  {
    "name": "Carrollton",
    "lng": -89.9218031675541,
    "lat": 33.50610550438015
  },
  {
    "name": "Carrollton",
    "lng": -88.09507431880746,
    "lat": 33.26187961758055
  },
  {
    "name": "Carrollton",
    "lng": -85.16434633790476,
    "lat": 38.67909124505408
  },
  {
    "name": "Carrollton",
    "lng": -85.08757452559537,
    "lat": 33.55196130965898
  },
  {
    "name": "Carrollton",
    "lng": -85.08387256109405,
    "lat": 33.581966637977864
  },
  {
    "name": "Carrollton",
    "lng": -85.05119754029121,
    "lat": 33.55971033787964
  },
  {
    "name": "Carrollton",
    "lng": -85.01501421371391,
    "lat": 33.5754360991037
  },
  {
    "name": "Carrollton",
    "lng": -81.08987909109604,
    "lat": 40.58035461870613
  },
  {
    "name": "Carrollwood",
    "lng": -82.51440549226416,
    "lat": 28.057892697859348
  },
  {
    "name": "Carrsville",
    "lng": -88.3738274419079,
    "lat": 37.396868401121296
  },
  {
    "name": "Carson",
    "lng": -101.57062507354706,
    "lat": 46.42231030913944
  },
  {
    "name": "Carson",
    "lng": -95.41985928428738,
    "lat": 41.23576261286675
  },
  {
    "name": "Carson City",
    "lng": -84.84704309243548,
    "lat": 43.17988753053552
  },
  {
    "name": "Carsonville",
    "lng": -82.67299078116736,
    "lat": 43.42576497537254
  },
  {
    "name": "Carter",
    "lng": -110.93998357168225,
    "lat": 47.7811800042019
  },
  {
    "name": "Carter",
    "lng": -99.50381942158138,
    "lat": 35.217460326023065
  },
  {
    "name": "Carter Lake",
    "lng": -95.91755113342742,
    "lat": 41.288504117624036
  },
  {
    "name": "Cartersville",
    "lng": -84.80072512915216,
    "lat": 34.163290864632096
  },
  {
    "name": "Carterville",
    "lng": -94.43884814238004,
    "lat": 37.14659018309258
  },
  {
    "name": "Carterville",
    "lng": -89.14588338130767,
    "lat": 37.747282075579854
  },
  {
    "name": "Carterville",
    "lng": -89.14323627892894,
    "lat": 37.74653706370283
  },
  {
    "name": "Carterville",
    "lng": -89.10917664040721,
    "lat": 37.767641205078796
  },
  {
    "name": "Carterville",
    "lng": -89.10728165838724,
    "lat": 37.76725209757286
  },
  {
    "name": "Carterville",
    "lng": -89.0837180558228,
    "lat": 37.75996189456616
  },
  {
    "name": "Carterville",
    "lng": -89.08169996958178,
    "lat": 37.78724288537672
  },
  {
    "name": "Carterville",
    "lng": -89.0803737989101,
    "lat": 37.77837284818713
  },
  {
    "name": "Carterville",
    "lng": -89.07750119635125,
    "lat": 37.745329222773144
  },
  {
    "name": "Carterville",
    "lng": -89.0578829227562,
    "lat": 37.79685329604654
  },
  {
    "name": "Carterville",
    "lng": -89.05108998626244,
    "lat": 37.76444468253321
  },
  {
    "name": "Carthage",
    "lng": -97.7149574956498,
    "lat": 44.16859942281004
  },
  {
    "name": "Carthage",
    "lng": -94.33696049577189,
    "lat": 32.15227652712243
  },
  {
    "name": "Carthage",
    "lng": -94.32184210078337,
    "lat": 37.151074110520135
  },
  {
    "name": "Carthage",
    "lng": -92.55530523775212,
    "lat": 34.07261277137758
  },
  {
    "name": "Carthage",
    "lng": -91.12852988937198,
    "lat": 40.41506188847443
  },
  {
    "name": "Carthage",
    "lng": -89.53364626401077,
    "lat": 32.74317642775438
  },
  {
    "name": "Carthage",
    "lng": -85.9430914553998,
    "lat": 36.256436983086665
  },
  {
    "name": "Carthage",
    "lng": -85.57148537843032,
    "lat": 39.736725055771686
  },
  {
    "name": "Carthage",
    "lng": -79.42776495169383,
    "lat": 35.29296314252601
  },
  {
    "name": "Carthage",
    "lng": -79.42741560362931,
    "lat": 35.28856539789116
  },
  {
    "name": "Carthage",
    "lng": -79.42578434521833,
    "lat": 35.29723564053101
  },
  {
    "name": "Carthage",
    "lng": -79.42572471571587,
    "lat": 35.26889015278465
  },
  {
    "name": "Carthage",
    "lng": -79.42363092055963,
    "lat": 35.298932729037396
  },
  {
    "name": "Carthage",
    "lng": -79.42271024890901,
    "lat": 35.2450366924784
  },
  {
    "name": "Carthage",
    "lng": -79.41993768426431,
    "lat": 35.29661295614769
  },
  {
    "name": "Carthage",
    "lng": -79.41876727327792,
    "lat": 35.31094385911437
  },
  {
    "name": "Carthage",
    "lng": -79.41874880788009,
    "lat": 35.29563276813297
  },
  {
    "name": "Carthage",
    "lng": -79.41710422733776,
    "lat": 35.366773581486505
  },
  {
    "name": "Carthage",
    "lng": -79.41408334574862,
    "lat": 35.33996787315641
  },
  {
    "name": "Carthage",
    "lng": -79.39968238740512,
    "lat": 35.3586770890352
  },
  {
    "name": "Carthage",
    "lng": -79.37936374477668,
    "lat": 35.35050845076076
  },
  {
    "name": "Carthage",
    "lng": -79.35814450271077,
    "lat": 35.32351545961649
  },
  {
    "name": "Carthage",
    "lng": -79.35093740926395,
    "lat": 35.324797695403504
  },
  {
    "name": "Carthage",
    "lng": -79.34652080499976,
    "lat": 35.31884389114885
  },
  {
    "name": "Carthage",
    "lng": -75.60241647284198,
    "lat": 43.98444287499482
  },
  {
    "name": "Carthage",
    "lng": -70.42294879192616,
    "lat": 44.62035309740937
  },
  {
    "name": "Caruthersville",
    "lng": -89.66641042822464,
    "lat": 36.181477776747684
  },
  {
    "name": "Carver",
    "lng": -93.6501700631986,
    "lat": 44.781303043825
  },
  {
    "name": "Carver",
    "lng": -93.62980304262032,
    "lat": 44.75941104221967
  },
  {
    "name": "Cary",
    "lng": -90.92461150248037,
    "lat": 32.80564212419388
  },
  {
    "name": "Cary",
    "lng": -88.24833475704384,
    "lat": 42.213123127324934
  },
  {
    "name": "Cary",
    "lng": -78.93032900491617,
    "lat": 35.812762189573654
  },
  {
    "name": "Cary",
    "lng": -78.92836747559038,
    "lat": 35.835090152674496
  },
  {
    "name": "Cary",
    "lng": -78.92675721915485,
    "lat": 35.80167673704693
  },
  {
    "name": "Cary",
    "lng": -78.91858786141515,
    "lat": 35.77760696087457
  },
  {
    "name": "Cary",
    "lng": -78.89543518751361,
    "lat": 35.77490338376933
  },
  {
    "name": "Cary",
    "lng": -78.89180548553368,
    "lat": 35.866279054726164
  },
  {
    "name": "Cary",
    "lng": -78.88668620150042,
    "lat": 35.848829534426336
  },
  {
    "name": "Cary",
    "lng": -78.87699584908746,
    "lat": 35.76145986819754
  },
  {
    "name": "Cary",
    "lng": -78.81985553870896,
    "lat": 35.87068600437699
  },
  {
    "name": "Cary",
    "lng": -78.81810068690861,
    "lat": 35.780748237128854
  },
  {
    "name": "Cary",
    "lng": -78.81700494453375,
    "lat": 35.88434829156405
  },
  {
    "name": "Cary",
    "lng": -78.81591472076613,
    "lat": 35.865267796227506
  },
  {
    "name": "Cary",
    "lng": -78.8092287816525,
    "lat": 35.89040238103426
  },
  {
    "name": "Cary",
    "lng": -78.80157233930251,
    "lat": 35.686845654015414
  },
  {
    "name": "Cary",
    "lng": -78.79522353595664,
    "lat": 35.851011483801564
  },
  {
    "name": "Cary",
    "lng": -78.77840112855466,
    "lat": 35.67825088804391
  },
  {
    "name": "Cary",
    "lng": -78.77613698037528,
    "lat": 35.814798780698936
  },
  {
    "name": "Cary",
    "lng": -78.77204068592219,
    "lat": 35.81720509074944
  },
  {
    "name": "Cary",
    "lng": -78.77177267247149,
    "lat": 35.815831641166234
  },
  {
    "name": "Cary",
    "lng": -78.76895151540913,
    "lat": 35.81560779336598
  },
  {
    "name": "Cary",
    "lng": -78.76568260874322,
    "lat": 35.709644058999935
  },
  {
    "name": "Cary",
    "lng": -78.74707339234742,
    "lat": 35.79873989219515
  },
  {
    "name": "Cary",
    "lng": -78.74205717533447,
    "lat": 35.81394768425651
  },
  {
    "name": "Cary",
    "lng": -78.74157922565604,
    "lat": 35.80732695013753
  },
  {
    "name": "Cary",
    "lng": -78.73959222476705,
    "lat": 35.73227943588632
  },
  {
    "name": "Carytown",
    "lng": -94.33436114940505,
    "lat": 37.26160836405373
  },
  {
    "name": "Caryville",
    "lng": -84.21964975953553,
    "lat": 36.32494247937035
  },
  {
    "name": "Casa",
    "lng": -93.04482535667076,
    "lat": 35.025308729862324
  },
  {
    "name": "Casar",
    "lng": -81.61684416941493,
    "lat": 35.512708567726975
  },
  {
    "name": "Cascade",
    "lng": -116.04608258161117,
    "lat": 44.511186311736836
  },
  {
    "name": "Cascade",
    "lng": -116.01699633744165,
    "lat": 44.49042252278265
  },
  {
    "name": "Cascade",
    "lng": -111.71294505865127,
    "lat": 47.27774506696398
  },
  {
    "name": "Cascade",
    "lng": -111.71104843038255,
    "lat": 47.27678738225528
  },
  {
    "name": "Cascade",
    "lng": -111.70342194484135,
    "lat": 47.27053209576033
  },
  {
    "name": "Cascade",
    "lng": -111.70072320014984,
    "lat": 47.2826250406188
  },
  {
    "name": "Cascade",
    "lng": -111.69285938203595,
    "lat": 47.279569011931336
  },
  {
    "name": "Cascade",
    "lng": -91.00470814284465,
    "lat": 42.301843058896864
  },
  {
    "name": "Cascade",
    "lng": -88.00776353915724,
    "lat": 43.65995242146859
  },
  {
    "name": "Casco",
    "lng": -87.6971037527329,
    "lat": 44.53313503645788
  },
  {
    "name": "Casco",
    "lng": -87.61954361997334,
    "lat": 44.555571988392956
  },
  {
    "name": "Casco",
    "lng": -70.52050475921693,
    "lat": 43.96507085978266
  },
  {
    "name": "Caseville",
    "lng": -83.27441588378171,
    "lat": 43.94141547985886
  },
  {
    "name": "Casey",
    "lng": -94.5213561002382,
    "lat": 41.50745236574901
  },
  {
    "name": "Casey",
    "lng": -87.98944882053824,
    "lat": 39.30263863143774
  },
  {
    "name": "Caseyville",
    "lng": -90.03463375004839,
    "lat": 38.62984473443218
  },
  {
    "name": "Caseyville",
    "lng": -90.00467114880199,
    "lat": 38.63571636408473
  },
  {
    "name": "Cash",
    "lng": -90.93270306505046,
    "lat": 35.79988390037154
  },
  {
    "name": "Cashion",
    "lng": -97.67530606682821,
    "lat": 35.80392036433656
  },
  {
    "name": "Cashion Community",
    "lng": -98.50804122869657,
    "lat": 34.0363493109398
  },
  {
    "name": "Cashmere",
    "lng": -120.46689974923247,
    "lat": 47.51744053183713
  },
  {
    "name": "Cashton",
    "lng": -90.78870624558384,
    "lat": 43.74048374928034
  },
  {
    "name": "Casnovia",
    "lng": -85.78930571247568,
    "lat": 43.233621285014394
  },
  {
    "name": "Casper",
    "lng": -106.34615147025879,
    "lat": 42.80036631815624
  },
  {
    "name": "Casper",
    "lng": -106.34101428890142,
    "lat": 42.74785831947512
  },
  {
    "name": "Casper",
    "lng": -106.32053181930883,
    "lat": 42.842910057739275
  },
  {
    "name": "Casper",
    "lng": -106.31640016174725,
    "lat": 42.820889935019665
  },
  {
    "name": "Caspian",
    "lng": -88.6266400444882,
    "lat": 46.06530921609124
  },
  {
    "name": "Cass City",
    "lng": -83.17673853703835,
    "lat": 43.600518125842974
  },
  {
    "name": "Cass Lake",
    "lng": -94.61570562237154,
    "lat": 47.38559819029651
  },
  {
    "name": "Cass Lake",
    "lng": -94.6010856713985,
    "lat": 47.379010427566314
  },
  {
    "name": "Cass Lake",
    "lng": -94.57382602302448,
    "lat": 47.380036488609925
  },
  {
    "name": "Cassadaga",
    "lng": -79.31810674015638,
    "lat": 42.342731280460875
  },
  {
    "name": "Casselton",
    "lng": -97.21247067470439,
    "lat": 46.89739982683373
  },
  {
    "name": "Cassoday",
    "lng": -96.63709162586434,
    "lat": 38.038618292666975
  },
  {
    "name": "Cassopolis",
    "lng": -86.00956674297292,
    "lat": 41.91118509583734
  },
  {
    "name": "Casstown",
    "lng": -84.12847545049112,
    "lat": 40.05273683743034
  },
  {
    "name": "Cassville",
    "lng": -93.87234291647549,
    "lat": 36.66797333938414
  },
  {
    "name": "Cassville",
    "lng": -93.8681476350733,
    "lat": 36.6788280515041
  },
  {
    "name": "Cassville",
    "lng": -91.0109570328799,
    "lat": 42.72494298930237
  },
  {
    "name": "Cassville",
    "lng": -90.9838351144299,
    "lat": 42.71297541901112
  },
  {
    "name": "Castalia",
    "lng": -91.67686594389136,
    "lat": 43.112666872845146
  },
  {
    "name": "Castalia",
    "lng": -82.8080588293204,
    "lat": 41.40098801878514
  },
  {
    "name": "Castalia",
    "lng": -78.05759699614916,
    "lat": 36.08227955179142
  },
  {
    "name": "Castana",
    "lng": -95.91041993369005,
    "lat": 42.07336583360412
  },
  {
    "name": "Castile",
    "lng": -78.05253927960321,
    "lat": 42.631191937235215
  },
  {
    "name": "Castine",
    "lng": -84.62471774345394,
    "lat": 39.93104263962176
  },
  {
    "name": "Castine",
    "lng": -68.81162780858293,
    "lat": 44.40877339455447
  },
  {
    "name": "Castle",
    "lng": -96.38348313820318,
    "lat": 35.47529899403932
  },
  {
    "name": "Castle Dale",
    "lng": -111.02290573419495,
    "lat": 39.22172191030136
  },
  {
    "name": "Castle Hill",
    "lng": -68.23727509869882,
    "lat": 46.70264835459442
  },
  {
    "name": "Castle Hills",
    "lng": -98.51973669209139,
    "lat": 29.522861129601846
  },
  {
    "name": "Castle Pines",
    "lng": -104.848404115723,
    "lat": 39.453552058995164
  },
  {
    "name": "Castle Rock",
    "lng": -122.89951888694331,
    "lat": 46.27002394519074
  },
  {
    "name": "Castle Rock",
    "lng": -104.89437697286009,
    "lat": 39.32854447499759
  },
  {
    "name": "Castle Rock",
    "lng": -104.87216536698284,
    "lat": 39.42610472028991
  },
  {
    "name": "Castle Rock",
    "lng": -104.87178309267114,
    "lat": 39.34096332331031
  },
  {
    "name": "Castle Rock",
    "lng": -104.86251183321362,
    "lat": 39.38988563436056
  },
  {
    "name": "Castle Rock",
    "lng": -104.84854629782794,
    "lat": 39.38199004462005
  },
  {
    "name": "Castle Rock",
    "lng": -104.83973335024409,
    "lat": 39.37638411826256
  },
  {
    "name": "Castle Valley",
    "lng": -109.39604325602889,
    "lat": 38.63211531273924
  },
  {
    "name": "Castleberry",
    "lng": -87.02695182410707,
    "lat": 31.30012819571169
  },
  {
    "name": "Castleford",
    "lng": -114.87180570734927,
    "lat": 42.52056867416632
  },
  {
    "name": "Castleton-on-Hudson",
    "lng": -73.74909521972936,
    "lat": 42.53319459919365
  },
  {
    "name": "Castlewood",
    "lng": -97.03092065394117,
    "lat": 44.72415849551305
  },
  {
    "name": "Castor",
    "lng": -93.16600218436592,
    "lat": 32.254918251890565
  },
  {
    "name": "Castorland",
    "lng": -75.51643788255936,
    "lat": 43.88610540161398
  },
  {
    "name": "Castroville",
    "lng": -98.87112069563503,
    "lat": 29.35058008801449
  },
  {
    "name": "Caswell",
    "lng": -67.859945365395,
    "lat": 47.0041268912477
  },
  {
    "name": "Caswell Beach",
    "lng": -78.04727762219609,
    "lat": 33.90757115176187
  },
  {
    "name": "Catawba",
    "lng": -90.53351285535167,
    "lat": 45.53711724920548
  },
  {
    "name": "Catawba",
    "lng": -83.6225950840553,
    "lat": 39.999963730669656
  },
  {
    "name": "Catawba",
    "lng": -81.08602087071071,
    "lat": 35.737721456824154
  },
  {
    "name": "Catawba",
    "lng": -81.06391393826677,
    "lat": 35.7030022329214
  },
  {
    "name": "Cathedral City",
    "lng": -116.46627411284402,
    "lat": 33.83624384529714
  },
  {
    "name": "Cathlamet",
    "lng": -123.38962649362753,
    "lat": 46.218121722068716
  },
  {
    "name": "Cathlamet",
    "lng": -123.3830562591115,
    "lat": 46.20459652483833
  },
  {
    "name": "Catlettsburg",
    "lng": -82.6021613949462,
    "lat": 38.41933156910214
  },
  {
    "name": "Catlin",
    "lng": -87.70791038097228,
    "lat": 40.067565514584516
  },
  {
    "name": "Cato",
    "lng": -76.57282355937159,
    "lat": 43.16794234823942
  },
  {
    "name": "Catoosa",
    "lng": -95.76049092778604,
    "lat": 36.18287796313486
  },
  {
    "name": "Catron",
    "lng": -89.70601340149229,
    "lat": 36.611048557736616
  },
  {
    "name": "Catskill",
    "lng": -73.86569892693694,
    "lat": 42.21449181341002
  },
  {
    "name": "Cattaraugus",
    "lng": -78.8673122114826,
    "lat": 42.330024549612006
  },
  {
    "name": "Caulksville",
    "lng": -93.86804092618505,
    "lat": 35.30177810521017
  },
  {
    "name": "Causey",
    "lng": -103.1066200004597,
    "lat": 33.857622427452775
  },
  {
    "name": "Cavalier",
    "lng": -97.62555961672173,
    "lat": 48.80077943931439
  },
  {
    "name": "Cavalier",
    "lng": -97.62371195490708,
    "lat": 48.79407315989542
  },
  {
    "name": "Cavalier",
    "lng": -97.61383683873034,
    "lat": 48.80587110486968
  },
  {
    "name": "Cave",
    "lng": -91.04829967234338,
    "lat": 39.02141147922571
  },
  {
    "name": "Cave City",
    "lng": -91.54673618844079,
    "lat": 35.94818418902893
  },
  {
    "name": "Cave City",
    "lng": -85.96462271940158,
    "lat": 37.14011353777331
  },
  {
    "name": "Cave Spring",
    "lng": -85.3390939781526,
    "lat": 34.10642675811949
  },
  {
    "name": "Cave Spring",
    "lng": -85.31875065465594,
    "lat": 34.120162701360535
  },
  {
    "name": "Cave Springs",
    "lng": -94.22647926143301,
    "lat": 36.293135608749765
  },
  {
    "name": "Cave Springs",
    "lng": -94.2234506849116,
    "lat": 36.26308375951137
  },
  {
    "name": "Cave Springs",
    "lng": -94.22082435068585,
    "lat": 36.31177787123489
  },
  {
    "name": "Cave Springs",
    "lng": -94.19948113333332,
    "lat": 36.26284949977639
  },
  {
    "name": "Cave Springs",
    "lng": -94.19653563947811,
    "lat": 36.28802257790035
  },
  {
    "name": "Cave-In-Rock",
    "lng": -88.16593493195211,
    "lat": 37.47082513518544
  },
  {
    "name": "Cavour",
    "lng": -98.04209544217262,
    "lat": 44.369329946486786
  },
  {
    "name": "Cawker City",
    "lng": -98.43303304214665,
    "lat": 39.50958184261547
  },
  {
    "name": "Cayce",
    "lng": -81.15053907206388,
    "lat": 33.94055214037462
  },
  {
    "name": "Cayce",
    "lng": -81.05782128685753,
    "lat": 33.95211729504474
  },
  {
    "name": "Cayuga",
    "lng": -97.38441861527346,
    "lat": 46.07598155116659
  },
  {
    "name": "Cayuga",
    "lng": -87.46463082268414,
    "lat": 39.947320932199105
  },
  {
    "name": "Cayuga",
    "lng": -76.72655801893818,
    "lat": 42.919102444840384
  },
  {
    "name": "Cayuga Heights",
    "lng": -76.48769240780445,
    "lat": 42.46791438804358
  },
  {
    "name": "Cazenovia",
    "lng": -90.19916821336466,
    "lat": 43.523609180566254
  },
  {
    "name": "Cazenovia",
    "lng": -75.85017232173747,
    "lat": 42.92827441552611
  },
  {
    "name": "Cañon City",
    "lng": -105.21869769580111,
    "lat": 38.445361512442844
  },
  {
    "name": "Cecil",
    "lng": -88.4485748942602,
    "lat": 44.811147469252504
  },
  {
    "name": "Cecil",
    "lng": -84.60174097764619,
    "lat": 41.219080647357806
  },
  {
    "name": "Cecil",
    "lng": -83.39222032220873,
    "lat": 31.046133078683486
  },
  {
    "name": "Cecilton",
    "lng": -75.86784721139652,
    "lat": 39.40479343056215
  },
  {
    "name": "Cedar",
    "lng": -98.93996670426968,
    "lat": 39.656767300714456
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.64152914814751,
    "lat": 34.226986353482495
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.62566301273384,
    "lat": 34.240620417229174
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.59197609627651,
    "lat": 34.219324221450336
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.55212150273901,
    "lat": 34.21683219592334
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.55199741237287,
    "lat": 34.21627031702277
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.55197888052373,
    "lat": 34.21774195299004
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.55196066047326,
    "lat": 34.216125567199164
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.55192221659175,
    "lat": 34.215967012379565
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.55175471026455,
    "lat": 34.21491589806851
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.55173501036177,
    "lat": 34.215482475346214
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.55165584217944,
    "lat": 34.216167507057264
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.55158257979556,
    "lat": 34.21508848908678
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.55152926841814,
    "lat": 34.21463039169046
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.55108734322589,
    "lat": 34.2142921911071
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.55107802447151,
    "lat": 34.21557511525527
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.55104436365195,
    "lat": 34.215224267102506
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.55085066843625,
    "lat": 34.21649060276016
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.55078897722528,
    "lat": 34.215252745230714
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.55075075188738,
    "lat": 34.21504119348498
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.55069756525853,
    "lat": 34.21621414571028
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.5506674457429,
    "lat": 34.21575540981014
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.55046915563284,
    "lat": 34.2140505598563
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.55038990784524,
    "lat": 34.21661538516121
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.55037845109804,
    "lat": 34.21584038639543
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.55028956673785,
    "lat": 34.2163925781455
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.55028503224852,
    "lat": 34.21515576278355
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.55018412448085,
    "lat": 34.215562881901086
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.5501362783875,
    "lat": 34.21451260661252
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.55003828500534,
    "lat": 34.2158158450329
  },
  {
    "name": "Cedar Bluff",
    "lng": -85.54923297363297,
    "lat": 34.21741296650465
  },
  {
    "name": "Cedar Bluff",
    "lng": -81.76328304519767,
    "lat": 37.08756340298938
  },
  {
    "name": "Cedar Bluffs",
    "lng": -96.60971680533113,
    "lat": 41.39703925022037
  },
  {
    "name": "Cedar City",
    "lng": -113.09547205594632,
    "lat": 37.683538655344776
  },
  {
    "name": "Cedar Creek",
    "lng": -96.10160499185666,
    "lat": 41.045053993581625
  },
  {
    "name": "Cedar Crest",
    "lng": -106.38041124043471,
    "lat": 35.110354701971765
  },
  {
    "name": "Cedar Falls",
    "lng": -92.4536504598865,
    "lat": 42.51953876744784
  },
  {
    "name": "Cedar Fort",
    "lng": -112.10860094835742,
    "lat": 40.34137929841473
  },
  {
    "name": "Cedar Grove",
    "lng": -87.82409888898596,
    "lat": 43.56749498218912
  },
  {
    "name": "Cedar Grove",
    "lng": -84.93715870704092,
    "lat": 39.35610708980642
  },
  {
    "name": "Cedar Grove",
    "lng": -81.43791912011565,
    "lat": 38.22237707096512
  },
  {
    "name": "Cedar Highlands",
    "lng": -113.06665684658346,
    "lat": 37.62967778680156
  },
  {
    "name": "Cedar Hill",
    "lng": -96.95897363437022,
    "lat": 32.58108044624749
  },
  {
    "name": "Cedar Hill",
    "lng": -87.00068599390885,
    "lat": 36.55117624897841
  },
  {
    "name": "Cedar Hill Lakes",
    "lng": -90.65807459163427,
    "lat": 38.32998984302368
  },
  {
    "name": "Cedar Hills",
    "lng": -111.75304282245072,
    "lat": 40.413494925568564
  },
  {
    "name": "Cedar Lake",
    "lng": -87.43802126626501,
    "lat": 41.37115627310328
  },
  {
    "name": "Cedar Mills",
    "lng": -94.51959969112791,
    "lat": 44.94309757464334
  },
  {
    "name": "Cedar Park",
    "lng": -97.8272877992707,
    "lat": 30.464026007079333
  },
  {
    "name": "Cedar Park",
    "lng": -97.81945839848515,
    "lat": 30.512035543005048
  },
  {
    "name": "Cedar Point",
    "lng": -96.82123823235638,
    "lat": 38.25991428920836
  },
  {
    "name": "Cedar Point",
    "lng": -89.12601574655548,
    "lat": 41.26511007594768
  },
  {
    "name": "Cedar Point",
    "lng": -77.08362108920947,
    "lat": 34.68546175440609
  },
  {
    "name": "Cedar Point",
    "lng": -77.07113248838972,
    "lat": 34.70902136770222
  },
  {
    "name": "Cedar Rapids",
    "lng": -98.14930078960394,
    "lat": 41.55890838187699
  },
  {
    "name": "Cedar Rapids",
    "lng": -91.72426577274874,
    "lat": 41.924635214689175
  },
  {
    "name": "Cedar Rapids",
    "lng": -91.67825735865897,
    "lat": 41.96661165267206
  },
  {
    "name": "Cedar Rapids",
    "lng": -91.57887699534312,
    "lat": 41.96568001715198
  },
  {
    "name": "Cedar Rock",
    "lng": -81.45800395845262,
    "lat": 35.94366772957085
  },
  {
    "name": "Cedar Springs",
    "lng": -85.57087071045243,
    "lat": 43.220569386699786
  },
  {
    "name": "Cedar Springs",
    "lng": -85.5630806935477,
    "lat": 43.219528449940746
  },
  {
    "name": "Cedar Springs",
    "lng": -85.55203545117399,
    "lat": 43.219761681722126
  },
  {
    "name": "Cedar Vale",
    "lng": -96.5013495095757,
    "lat": 37.10565226642686
  },
  {
    "name": "Cedar Valley",
    "lng": -97.5273617988998,
    "lat": 35.86940399289327
  },
  {
    "name": "Cedarburg",
    "lng": -87.9901307415178,
    "lat": 43.299468322886945
  },
  {
    "name": "Cedarburg",
    "lng": -87.97713358720175,
    "lat": 43.314030556859656
  },
  {
    "name": "Cedarburg",
    "lng": -87.95553859686967,
    "lat": 43.28518685251235
  },
  {
    "name": "Cedaredge",
    "lng": -107.92470543617921,
    "lat": 38.89377826054983
  },
  {
    "name": "Cedarhurst",
    "lng": -73.72783405322791,
    "lat": 40.625198139024924
  },
  {
    "name": "Cedartown",
    "lng": -85.2527578007217,
    "lat": 34.02058090536942
  },
  {
    "name": "Cedarville",
    "lng": -94.36155225563638,
    "lat": 35.58306477687053
  },
  {
    "name": "Cedarville",
    "lng": -89.63640478757499,
    "lat": 42.37566687002363
  },
  {
    "name": "Cedarville",
    "lng": -83.81035954977759,
    "lat": 39.74721792879852
  },
  {
    "name": "Cedarville",
    "lng": -82.35850300616261,
    "lat": 37.31403188373192
  },
  {
    "name": "Celeste",
    "lng": -96.19447699352581,
    "lat": 33.28968141842703
  },
  {
    "name": "Celina",
    "lng": -85.50067136967265,
    "lat": 36.54795251067642
  },
  {
    "name": "Celina",
    "lng": -84.56256213139153,
    "lat": 40.555058536803465
  },
  {
    "name": "Celoron",
    "lng": -79.27770161816935,
    "lat": 42.10522731498005
  },
  {
    "name": "Cement",
    "lng": -98.13628916502962,
    "lat": 34.93623010811471
  },
  {
    "name": "Cement City",
    "lng": -84.32794369409203,
    "lat": 42.06820053434481
  },
  {
    "name": "Centennial",
    "lng": -104.97798876594604,
    "lat": 39.56784082059236
  },
  {
    "name": "Centennial",
    "lng": -104.88580259454092,
    "lat": 39.587496167987304
  },
  {
    "name": "Center",
    "lng": -106.11020836107997,
    "lat": 37.75133259527769
  },
  {
    "name": "Center",
    "lng": -101.29903417784432,
    "lat": 47.1148295495961
  },
  {
    "name": "Center",
    "lng": -97.87622093596954,
    "lat": 42.60896960609668
  },
  {
    "name": "Center",
    "lng": -94.17953894397348,
    "lat": 31.79294655527775
  },
  {
    "name": "Center",
    "lng": -91.52914833299882,
    "lat": 39.50955735857775
  },
  {
    "name": "Center City",
    "lng": -92.81771962876682,
    "lat": 45.39671375089014
  },
  {
    "name": "Center City",
    "lng": -92.80066870322229,
    "lat": 45.38598778951234
  },
  {
    "name": "Center Junction",
    "lng": -91.09038432194521,
    "lat": 42.11577241580312
  },
  {
    "name": "Center Line",
    "lng": -83.02737201690542,
    "lat": 42.48050318005203
  },
  {
    "name": "Center Point",
    "lng": -91.77986506154966,
    "lat": 42.18486337594343
  },
  {
    "name": "Center Point",
    "lng": -87.07517054621627,
    "lat": 39.41536835718678
  },
  {
    "name": "Center Point",
    "lng": -86.7103898147525,
    "lat": 33.64058089726371
  },
  {
    "name": "Center Point",
    "lng": -86.70685095373808,
    "lat": 33.66483725796778
  },
  {
    "name": "Center Point",
    "lng": -86.69231989325908,
    "lat": 33.67083395072146
  },
  {
    "name": "Center Point",
    "lng": -86.69016094510847,
    "lat": 33.668639555610056
  },
  {
    "name": "Center Point",
    "lng": -86.68762720243411,
    "lat": 33.67253560076331
  },
  {
    "name": "Center Point",
    "lng": -86.68516721950101,
    "lat": 33.67003374710473
  },
  {
    "name": "Center Point",
    "lng": -86.68434279863779,
    "lat": 33.64388243071566
  },
  {
    "name": "Center Point",
    "lng": -86.66236068762015,
    "lat": 33.65311515952644
  },
  {
    "name": "Centerburg",
    "lng": -82.69816343541738,
    "lat": 40.30107596320703
  },
  {
    "name": "Centerburg",
    "lng": -82.69131244005357,
    "lat": 40.30439543969334
  },
  {
    "name": "Centerfield",
    "lng": -111.81842217335776,
    "lat": 39.12745003456639
  },
  {
    "name": "Centerton",
    "lng": -94.33550717659334,
    "lat": 36.360740648535604
  },
  {
    "name": "Centerton",
    "lng": -94.29716656110001,
    "lat": 36.35536325427084
  },
  {
    "name": "Centerton",
    "lng": -94.29716656109998,
    "lat": 36.35536325427078
  },
  {
    "name": "Centerton",
    "lng": -94.2712691467097,
    "lat": 36.39356913251498
  },
  {
    "name": "Centertown",
    "lng": -92.40925747715424,
    "lat": 38.61773650566865
  },
  {
    "name": "Centertown",
    "lng": -86.99570861675741,
    "lat": 37.41789359248795
  },
  {
    "name": "Centertown",
    "lng": -85.91994025933569,
    "lat": 35.725334740452055
  },
  {
    "name": "Centerview",
    "lng": -93.84517051835235,
    "lat": 38.7453921236668
  },
  {
    "name": "Centerville",
    "lng": -111.8848436527476,
    "lat": 40.92839482894888
  },
  {
    "name": "Centerville",
    "lng": -96.95946218873647,
    "lat": 43.11652726415204
  },
  {
    "name": "Centerville",
    "lng": -95.97967420532468,
    "lat": 31.258135898403598
  },
  {
    "name": "Centerville",
    "lng": -93.05408506809874,
    "lat": 45.16404472928022
  },
  {
    "name": "Centerville",
    "lng": -92.87172666220575,
    "lat": 40.72930525933031
  },
  {
    "name": "Centerville",
    "lng": -90.95999335824915,
    "lat": 37.43646929503514
  },
  {
    "name": "Centerville",
    "lng": -87.45873344292907,
    "lat": 35.80417945934644
  },
  {
    "name": "Centerville",
    "lng": -87.42588826143796,
    "lat": 35.81273219840509
  },
  {
    "name": "Centerville",
    "lng": -84.99236211199633,
    "lat": 39.82092058294102
  },
  {
    "name": "Centerville",
    "lng": -84.14438932661109,
    "lat": 39.63459188456677
  },
  {
    "name": "Centerville",
    "lng": -83.68537742628179,
    "lat": 32.63411907641457
  },
  {
    "name": "Centerville",
    "lng": -82.44576444503733,
    "lat": 38.89787739755939
  },
  {
    "name": "Centerville",
    "lng": -78.10886177589268,
    "lat": 36.18535117043185
  },
  {
    "name": "Centrahoma",
    "lng": -96.34360505280478,
    "lat": 34.61040876057745
  },
  {
    "name": "Central",
    "lng": -82.77884836182518,
    "lat": 34.723812808372614
  },
  {
    "name": "Central",
    "lng": -82.7569949278348,
    "lat": 34.70541736813276
  },
  {
    "name": "Central City",
    "lng": -105.51687361377324,
    "lat": 39.7974680438151
  },
  {
    "name": "Central City",
    "lng": -103.76963864162373,
    "lat": 44.36829253854623
  },
  {
    "name": "Central City",
    "lng": -98.00020111797546,
    "lat": 41.11331805054228
  },
  {
    "name": "Central City",
    "lng": -94.23463959256263,
    "lat": 35.329988922523015
  },
  {
    "name": "Central City",
    "lng": -91.53427390096174,
    "lat": 42.200047324162426
  },
  {
    "name": "Central City",
    "lng": -91.5237832851564,
    "lat": 42.20255331345429
  },
  {
    "name": "Central City",
    "lng": -89.12838811656066,
    "lat": 38.548420620042066
  },
  {
    "name": "Central City",
    "lng": -87.12789755043168,
    "lat": 37.296809071221546
  },
  {
    "name": "Central Falls",
    "lng": -71.39351170559154,
    "lat": 41.88993329666137
  },
  {
    "name": "Central High",
    "lng": -98.08913815850723,
    "lat": 34.6170927885547
  },
  {
    "name": "Central Lake",
    "lng": -85.26181058624162,
    "lat": 45.06927774016393
  },
  {
    "name": "Central Pacolet",
    "lng": -81.75260342449062,
    "lat": 34.90958398138719
  },
  {
    "name": "Central Square",
    "lng": -76.1418586063443,
    "lat": 43.28574953087501
  },
  {
    "name": "Central Valley",
    "lng": -112.09663687796714,
    "lat": 38.699166050214615
  },
  {
    "name": "Centralhatchee",
    "lng": -85.10374765160874,
    "lat": 33.368929472874186
  },
  {
    "name": "Centralia",
    "lng": -96.13032449748216,
    "lat": 39.72462045608652
  },
  {
    "name": "Centralia",
    "lng": -92.13424068730237,
    "lat": 39.21052868000434
  },
  {
    "name": "Centralia",
    "lng": -90.83658465713863,
    "lat": 42.47236051367376
  },
  {
    "name": "Centralia",
    "lng": -89.12400588024992,
    "lat": 38.51895875944223
  },
  {
    "name": "Centralia",
    "lng": -76.34379138118396,
    "lat": 40.80452389963776
  },
  {
    "name": "Centre",
    "lng": -85.69503433189155,
    "lat": 34.17993023037872
  },
  {
    "name": "Centre",
    "lng": -85.6847027608665,
    "lat": 34.17726000864414
  },
  {
    "name": "Centre",
    "lng": -85.67036964629557,
    "lat": 34.15508556793248
  },
  {
    "name": "Centre Island",
    "lng": -73.52521031270442,
    "lat": 40.91589624720727
  },
  {
    "name": "Centreville",
    "lng": -91.0655705681997,
    "lat": 31.08703135957952
  },
  {
    "name": "Centreville",
    "lng": -90.10392291295703,
    "lat": 38.579556021854195
  },
  {
    "name": "Centreville",
    "lng": -87.14685880253356,
    "lat": 32.93279259260497
  },
  {
    "name": "Centreville",
    "lng": -87.13332935796497,
    "lat": 32.959823340014125
  },
  {
    "name": "Centreville",
    "lng": -85.54873851498441,
    "lat": 41.92484348083312
  },
  {
    "name": "Centreville",
    "lng": -85.5255139717851,
    "lat": 41.92123117373143
  },
  {
    "name": "Centreville",
    "lng": -76.06305427051784,
    "lat": 39.04189613873896
  },
  {
    "name": "Centuria",
    "lng": -92.55773867727616,
    "lat": 45.449825036182304
  },
  {
    "name": "Century",
    "lng": -87.28031842438361,
    "lat": 30.962756458794086
  },
  {
    "name": "Century",
    "lng": -87.2626942853684,
    "lat": 30.976186100627924
  },
  {
    "name": "Ceredo",
    "lng": -82.55471811683351,
    "lat": 38.39497182065535
  },
  {
    "name": "Ceres",
    "lng": -120.95522312774965,
    "lat": 37.597306043586805
  },
  {
    "name": "Ceresco",
    "lng": -96.64584880432963,
    "lat": 41.057982916932076
  },
  {
    "name": "Cerro Gordo",
    "lng": -88.7345793976791,
    "lat": 39.8893554707287
  },
  {
    "name": "Cerro Gordo",
    "lng": -78.9285494253243,
    "lat": 34.32285287592574
  },
  {
    "name": "Ceylon",
    "lng": -94.6306153197056,
    "lat": 43.532806189582814
  },
  {
    "name": "Chadbourn",
    "lng": -78.82543624431786,
    "lat": 34.32463096945053
  },
  {
    "name": "Chadron",
    "lng": -103.00248128357771,
    "lat": 42.82607859188689
  },
  {
    "name": "Chadwick",
    "lng": -89.8884422777806,
    "lat": 42.01423611978737
  },
  {
    "name": "Chaffee",
    "lng": -89.66146451078126,
    "lat": 37.181151855518465
  },
  {
    "name": "Chagrin Falls",
    "lng": -81.3886879013377,
    "lat": 41.43198512882144
  },
  {
    "name": "Chain of Rocks",
    "lng": -90.80211258284389,
    "lat": 38.91551311371298
  },
  {
    "name": "Chain-O-Lakes",
    "lng": -93.72417872603529,
    "lat": 36.53419705043385
  },
  {
    "name": "Challis",
    "lng": -114.22820850439437,
    "lat": 44.50514601643081
  },
  {
    "name": "Chalmers",
    "lng": -86.86764328664823,
    "lat": 40.66254996051
  },
  {
    "name": "Chama",
    "lng": -106.58427604118503,
    "lat": 36.892611761421335
  },
  {
    "name": "Chamberlain",
    "lng": -99.32353618900531,
    "lat": 43.781539307097745
  },
  {
    "name": "Chambers",
    "lng": -98.74845624673975,
    "lat": 42.204715653968265
  },
  {
    "name": "Chambersburg",
    "lng": -77.65563903449362,
    "lat": 39.93143791522137
  },
  {
    "name": "Chamblee",
    "lng": -84.30079778848743,
    "lat": 33.88425621235404
  },
  {
    "name": "Chamois",
    "lng": -91.77000016282075,
    "lat": 38.676279201111775
  },
  {
    "name": "Champ",
    "lng": -90.45351707660237,
    "lat": 38.745040682068456
  },
  {
    "name": "Champaign",
    "lng": -88.27356172382825,
    "lat": 40.11442708927665
  },
  {
    "name": "Champions Trail",
    "lng": -95.55781821481915,
    "lat": 30.005053472982212
  },
  {
    "name": "Champlain",
    "lng": -73.44080697322322,
    "lat": 44.98827579310109
  },
  {
    "name": "Champlin",
    "lng": -93.39021097870825,
    "lat": 45.170221355853826
  },
  {
    "name": "Chancellor",
    "lng": -96.98772477016246,
    "lat": 43.37238287574123
  },
  {
    "name": "Chandler",
    "lng": -96.94013716028701,
    "lat": 35.73956837452984
  },
  {
    "name": "Chandler",
    "lng": -96.90669870312786,
    "lat": 35.69231675131445
  },
  {
    "name": "Chandler",
    "lng": -96.90585778732171,
    "lat": 35.742972993927
  },
  {
    "name": "Chandler",
    "lng": -96.87547676196239,
    "lat": 35.70941106631709
  },
  {
    "name": "Chandler",
    "lng": -96.85454642298868,
    "lat": 35.72567920541841
  },
  {
    "name": "Chandler",
    "lng": -96.82717354868886,
    "lat": 35.726983800421934
  },
  {
    "name": "Chandler",
    "lng": -95.95263527760866,
    "lat": 43.92889549315867
  },
  {
    "name": "Chandler",
    "lng": -95.47846181029534,
    "lat": 32.30527910244568
  },
  {
    "name": "Chandler",
    "lng": -87.3741559879642,
    "lat": 38.03705041928325
  },
  {
    "name": "Chandlerville",
    "lng": -90.1512552042232,
    "lat": 40.0470104884276
  },
  {
    "name": "Chanhassen",
    "lng": -93.56207425274212,
    "lat": 44.85447213654969
  },
  {
    "name": "Channahon",
    "lng": -88.37963352183748,
    "lat": 41.39812271835923
  },
  {
    "name": "Channahon",
    "lng": -88.37846064931333,
    "lat": 41.384379394787906
  },
  {
    "name": "Channahon",
    "lng": -88.37083013804336,
    "lat": 41.399327329146004
  },
  {
    "name": "Channahon",
    "lng": -88.35958739576118,
    "lat": 41.4065143508953
  },
  {
    "name": "Channahon",
    "lng": -88.35000684136782,
    "lat": 41.414072835740946
  },
  {
    "name": "Channahon",
    "lng": -88.31663585705599,
    "lat": 41.43532834999935
  },
  {
    "name": "Channahon",
    "lng": -88.2616206030389,
    "lat": 41.41086114413196
  },
  {
    "name": "Channahon",
    "lng": -88.25449349107384,
    "lat": 41.42100843111262
  },
  {
    "name": "Channahon",
    "lng": -88.24172188756475,
    "lat": 41.40678992593786
  },
  {
    "name": "Channahon",
    "lng": -88.22081290937156,
    "lat": 41.45973793122578
  },
  {
    "name": "Channing",
    "lng": -102.33224845108948,
    "lat": 35.6819015144991
  },
  {
    "name": "Chanute",
    "lng": -95.48682803012117,
    "lat": 37.68394523461238
  },
  {
    "name": "Chanute",
    "lng": -95.46604601084475,
    "lat": 37.661244835351305
  },
  {
    "name": "Chanute",
    "lng": -95.46186775083923,
    "lat": 37.669982081773874
  },
  {
    "name": "Chanute",
    "lng": -95.45168377044605,
    "lat": 37.641209712626505
  },
  {
    "name": "Chanute",
    "lng": -95.45040122075872,
    "lat": 37.63958416770454
  },
  {
    "name": "Chapel Hill",
    "lng": -86.6949263837603,
    "lat": 35.628726930397676
  },
  {
    "name": "Chapel Hill",
    "lng": -79.06783723683345,
    "lat": 35.97076069632496
  },
  {
    "name": "Chapel Hill",
    "lng": -79.03889209811385,
    "lat": 35.926551639998465
  },
  {
    "name": "Chapin",
    "lng": -90.4027413021798,
    "lat": 39.76702020859375
  },
  {
    "name": "Chapin",
    "lng": -81.3439832880174,
    "lat": 34.16506394790994
  },
  {
    "name": "Chapman",
    "lng": -98.15938455538172,
    "lat": 41.02327836060871
  },
  {
    "name": "Chapman",
    "lng": -97.02347032321855,
    "lat": 38.974467979135625
  },
  {
    "name": "Chapman",
    "lng": -68.11130057532597,
    "lat": 46.61404449525239
  },
  {
    "name": "Chapmanville",
    "lng": -82.02154582827887,
    "lat": 37.972648584511845
  },
  {
    "name": "Chappell",
    "lng": -102.46725243355633,
    "lat": 41.094380065123374
  },
  {
    "name": "Chardon",
    "lng": -81.208299863223,
    "lat": 41.58011432916527
  },
  {
    "name": "Chariton",
    "lng": -93.30920307210637,
    "lat": 41.016788827640184
  },
  {
    "name": "Charlack",
    "lng": -90.34272073099994,
    "lat": 38.702960495665494
  },
  {
    "name": "Charlemont",
    "lng": -72.86492956679828,
    "lat": 42.64021796135992
  },
  {
    "name": "Charleroi",
    "lng": -79.90012056755023,
    "lat": 40.13861945475519
  },
  {
    "name": "Charles City",
    "lng": -92.67439281966504,
    "lat": 43.06457410446006
  },
  {
    "name": "Charles Town",
    "lng": -77.87384647807748,
    "lat": 39.28648843175049
  },
  {
    "name": "Charles Town",
    "lng": -77.86435846151976,
    "lat": 39.27305912072816
  },
  {
    "name": "Charles Town",
    "lng": -77.8487462706798,
    "lat": 39.29358043003265
  },
  {
    "name": "Charles Town",
    "lng": -77.81998484581572,
    "lat": 39.293039434968954
  },
  {
    "name": "Charleston",
    "lng": -111.45991281024276,
    "lat": 40.46614441366139
  },
  {
    "name": "Charleston",
    "lng": -94.04826705387647,
    "lat": 35.294815221784425
  },
  {
    "name": "Charleston",
    "lng": -90.05518007958872,
    "lat": 34.00750926872047
  },
  {
    "name": "Charleston",
    "lng": -89.33518918846134,
    "lat": 36.91784369926185
  },
  {
    "name": "Charleston",
    "lng": -88.21375367143274,
    "lat": 39.48545774924487
  },
  {
    "name": "Charleston",
    "lng": -88.19625297664867,
    "lat": 39.469212992763104
  },
  {
    "name": "Charleston",
    "lng": -88.17746015545119,
    "lat": 39.48422213187603
  },
  {
    "name": "Charleston",
    "lng": -84.76104388752375,
    "lat": 35.28624989518183
  },
  {
    "name": "Charleston",
    "lng": -81.71517221215751,
    "lat": 38.31626324594868
  },
  {
    "name": "Charleston",
    "lng": -81.62948540918515,
    "lat": 38.34911735871458
  },
  {
    "name": "Charleston",
    "lng": -80.11172360661752,
    "lat": 32.78023574996918
  },
  {
    "name": "Charleston",
    "lng": -80.07668150692955,
    "lat": 32.796998275820606
  },
  {
    "name": "Charleston",
    "lng": -80.0538656885965,
    "lat": 32.83500416139284
  },
  {
    "name": "Charleston",
    "lng": -80.04805607746599,
    "lat": 32.795490881903035
  },
  {
    "name": "Charleston",
    "lng": -80.0208016687129,
    "lat": 32.78087229038539
  },
  {
    "name": "Charleston",
    "lng": -80.0201028434143,
    "lat": 32.81389149013034
  },
  {
    "name": "Charleston",
    "lng": -80.00917179992943,
    "lat": 32.80464708462167
  },
  {
    "name": "Charleston",
    "lng": -79.9642353982467,
    "lat": 32.716237409044425
  },
  {
    "name": "Charleston",
    "lng": -79.9608010240107,
    "lat": 32.812393463741394
  },
  {
    "name": "Charleston",
    "lng": -79.93452501922292,
    "lat": 32.751562000976
  },
  {
    "name": "Charleston",
    "lng": -79.91594058253743,
    "lat": 32.749775030734924
  },
  {
    "name": "Charleston",
    "lng": -79.82324184968374,
    "lat": 32.92559989522479
  },
  {
    "name": "Charleston",
    "lng": -69.03378318070801,
    "lat": 45.0709317730298
  },
  {
    "name": "Charlestown",
    "lng": -85.6783018155616,
    "lat": 38.457229967020105
  },
  {
    "name": "Charlestown",
    "lng": -85.671715,
    "lat": 38.473701000050376
  },
  {
    "name": "Charlestown",
    "lng": -85.66789001229976,
    "lat": 38.42710256795411
  },
  {
    "name": "Charlestown",
    "lng": -75.9792312621394,
    "lat": 39.57700285605366
  },
  {
    "name": "Charlestown",
    "lng": -71.72453881375564,
    "lat": 41.33342101178529
  },
  {
    "name": "Charlestown",
    "lng": -71.72085906013143,
    "lat": 41.34049900451641
  },
  {
    "name": "Charlestown",
    "lng": -71.72078436765439,
    "lat": 41.339567596569985
  },
  {
    "name": "Charlestown",
    "lng": -71.72029739093503,
    "lat": 41.34659836809791
  },
  {
    "name": "Charlestown",
    "lng": -71.71980560754541,
    "lat": 41.340976630588436
  },
  {
    "name": "Charlestown",
    "lng": -71.7178309083995,
    "lat": 41.34121435978226
  },
  {
    "name": "Charlestown",
    "lng": -71.71623556047271,
    "lat": 41.337521282339196
  },
  {
    "name": "Charlestown",
    "lng": -71.71361062079505,
    "lat": 41.34405850471205
  },
  {
    "name": "Charlestown",
    "lng": -71.66989129656186,
    "lat": 41.401368936724886
  },
  {
    "name": "Charlestown",
    "lng": -71.65236511178436,
    "lat": 41.35802865827633
  },
  {
    "name": "Charlestown",
    "lng": -71.6397213307619,
    "lat": 41.36457532129353
  },
  {
    "name": "Charlestown",
    "lng": -71.6386464755026,
    "lat": 41.3632518438679
  },
  {
    "name": "Charlestown",
    "lng": -71.6373589712054,
    "lat": 41.36226345533642
  },
  {
    "name": "Charlestown",
    "lng": -71.63589505071036,
    "lat": 41.362659422348315
  },
  {
    "name": "Charlestown",
    "lng": -71.63533111485013,
    "lat": 41.361541541354136
  },
  {
    "name": "Charlestown",
    "lng": -71.63502573830903,
    "lat": 41.362246181400444
  },
  {
    "name": "Charlestown",
    "lng": -71.6330094449372,
    "lat": 41.36228207795397
  },
  {
    "name": "Charlestown",
    "lng": -71.63284470756011,
    "lat": 41.36262946391851
  },
  {
    "name": "Charlestown",
    "lng": -71.63235263597107,
    "lat": 41.36443422505811
  },
  {
    "name": "Charlestown",
    "lng": -71.63187029225999,
    "lat": 41.360392580908744
  },
  {
    "name": "Charlestown",
    "lng": -71.63178579831138,
    "lat": 41.36288546257715
  },
  {
    "name": "Charlestown",
    "lng": -71.63004990893734,
    "lat": 41.36478975129481
  },
  {
    "name": "Charlestown",
    "lng": -71.62993523221407,
    "lat": 41.362913505871774
  },
  {
    "name": "Charlevoix",
    "lng": -85.25542544817819,
    "lat": 45.3140212631885
  },
  {
    "name": "Charlotte",
    "lng": -98.70063198570759,
    "lat": 28.859282222143694
  },
  {
    "name": "Charlotte",
    "lng": -90.46825296340845,
    "lat": 41.9621630602623
  },
  {
    "name": "Charlotte",
    "lng": -87.34732205504078,
    "lat": 36.1820666703133
  },
  {
    "name": "Charlotte",
    "lng": -84.83055777239315,
    "lat": 42.566293074665865
  },
  {
    "name": "Charlotte",
    "lng": -80.8342210055525,
    "lat": 35.20667131554439
  },
  {
    "name": "Charlotte",
    "lng": -67.27400008529442,
    "lat": 45.01253966893084
  },
  {
    "name": "Charlotte Court House",
    "lng": -78.63809185525625,
    "lat": 37.05582387455589
  },
  {
    "name": "Charlotte Harbor",
    "lng": -82.06207581334631,
    "lat": 26.96574050057523
  },
  {
    "name": "Charlotte Park",
    "lng": -82.04862990806893,
    "lat": 26.904052583017894
  },
  {
    "name": "Charlottesville",
    "lng": -78.485477742044,
    "lat": 38.037477194537495
  },
  {
    "name": "Charlton",
    "lng": -71.9680306516487,
    "lat": 42.13510449959843
  },
  {
    "name": "Charter Oak",
    "lng": -95.58950242363161,
    "lat": 42.067924024095475
  },
  {
    "name": "Charterwood",
    "lng": -95.57088585416106,
    "lat": 30.00500219089635
  },
  {
    "name": "Chase",
    "lng": -98.34865504484249,
    "lat": 38.35563007765293
  },
  {
    "name": "Chase City",
    "lng": -78.46095079128484,
    "lat": 36.79984569064848
  },
  {
    "name": "Chaseburg",
    "lng": -91.10179412832031,
    "lat": 43.66095308492963
  },
  {
    "name": "Chaseburg",
    "lng": -91.10029109029429,
    "lat": 43.654573660400025
  },
  {
    "name": "Chaska",
    "lng": -93.60909719504285,
    "lat": 44.81635542990659
  },
  {
    "name": "Chataignier",
    "lng": -92.31683153316678,
    "lat": 30.569569965315313
  },
  {
    "name": "Chateaugay",
    "lng": -74.0800365322579,
    "lat": 44.92670350607906
  },
  {
    "name": "Chatfield",
    "lng": -92.18312549454583,
    "lat": 43.84476864698796
  },
  {
    "name": "Chatfield",
    "lng": -82.94136849763073,
    "lat": 40.951834550413444
  },
  {
    "name": "Chatham",
    "lng": -92.45203671517497,
    "lat": 32.30937085612031
  },
  {
    "name": "Chatham",
    "lng": -89.69558130813303,
    "lat": 39.67291560617941
  },
  {
    "name": "Chatham",
    "lng": -86.93145595090384,
    "lat": 46.34393954375437
  },
  {
    "name": "Chatham",
    "lng": -79.39673272296338,
    "lat": 36.81843520521085
  },
  {
    "name": "Chatham",
    "lng": -73.5975947306021,
    "lat": 42.36206289106433
  },
  {
    "name": "Chatom",
    "lng": -88.24660913891248,
    "lat": 31.469042641820195
  },
  {
    "name": "Chatsworth",
    "lng": -96.51452777868944,
    "lat": 42.91625314643909
  },
  {
    "name": "Chatsworth",
    "lng": -88.2974357185902,
    "lat": 40.72934238531025
  },
  {
    "name": "Chatsworth",
    "lng": -84.78703640543216,
    "lat": 34.789254481612616
  },
  {
    "name": "Chatsworth",
    "lng": -84.78356306735724,
    "lat": 34.780787508104154
  },
  {
    "name": "Chattahoochee",
    "lng": -84.8339564605695,
    "lat": 30.697679828501137
  },
  {
    "name": "Chattahoochee County",
    "lng": -84.78683133181097,
    "lat": 32.347077125949504
  },
  {
    "name": "Chattahoochee Hills",
    "lng": -84.75190633985231,
    "lat": 33.56100087595385
  },
  {
    "name": "Chattahoochee Hills",
    "lng": -84.6910285482324,
    "lat": 33.51534002576841
  },
  {
    "name": "Chattahoochee Hills",
    "lng": -84.67982433333333,
    "lat": 33.51109033334037
  },
  {
    "name": "Chattahoochee Hills",
    "lng": -84.66462299660732,
    "lat": 33.53722632953834
  },
  {
    "name": "Chattahoochee Hills",
    "lng": -84.66453733333333,
    "lat": 33.54102700055952
  },
  {
    "name": "Chattanooga",
    "lng": -98.65409657313135,
    "lat": 34.42424752089843
  },
  {
    "name": "Chattanooga",
    "lng": -85.36722214816216,
    "lat": 35.09299391383394
  },
  {
    "name": "Chattanooga",
    "lng": -85.24897250722584,
    "lat": 35.06842779124421
  },
  {
    "name": "Chaumont",
    "lng": -76.12992213184762,
    "lat": 44.06776988303779
  },
  {
    "name": "Chauncey",
    "lng": -83.06455135545053,
    "lat": 32.10590314020238
  },
  {
    "name": "Chauncey",
    "lng": -82.12725969474361,
    "lat": 39.40049122958504
  },
  {
    "name": "Chautauqua",
    "lng": -96.17744451942133,
    "lat": 37.024010209435005
  },
  {
    "name": "Chebanse",
    "lng": -87.90993210392126,
    "lat": 41.006944264288535
  },
  {
    "name": "Chebanse",
    "lng": -87.90699301672625,
    "lat": 40.991904057919456
  },
  {
    "name": "Chebeague Island",
    "lng": -70.08356453036765,
    "lat": 43.70978423798549
  },
  {
    "name": "Cheboygan",
    "lng": -84.4684306790437,
    "lat": 45.64158156829789
  },
  {
    "name": "Checotah",
    "lng": -95.52239819919158,
    "lat": 35.48204807704924
  },
  {
    "name": "Chefornak",
    "lng": -164.25340507625992,
    "lat": 60.15336492306859
  },
  {
    "name": "Chehalis",
    "lng": -122.96597617693868,
    "lat": 46.6649300847024
  },
  {
    "name": "Chelan",
    "lng": -120.02980408974062,
    "lat": 47.840671489470104
  },
  {
    "name": "Chelan",
    "lng": -119.9436927263306,
    "lat": 47.86584000140055
  },
  {
    "name": "Chelmsford",
    "lng": -71.36312747878299,
    "lat": 42.59998187682821
  },
  {
    "name": "Chelsea",
    "lng": -98.74337558283774,
    "lat": 45.16755739801702
  },
  {
    "name": "Chelsea",
    "lng": -95.4344803585449,
    "lat": 36.534817440097186
  },
  {
    "name": "Chelsea",
    "lng": -95.43400589689607,
    "lat": 36.52103655165408
  },
  {
    "name": "Chelsea",
    "lng": -92.3945469464854,
    "lat": 41.92044249344903
  },
  {
    "name": "Chelsea",
    "lng": -86.66203315024174,
    "lat": 33.35214579416792
  },
  {
    "name": "Chelsea",
    "lng": -86.64189507593797,
    "lat": 33.36793440372929
  },
  {
    "name": "Chelsea",
    "lng": -86.63648797688727,
    "lat": 33.346838111180354
  },
  {
    "name": "Chelsea",
    "lng": -86.62632324213432,
    "lat": 33.32235921827047
  },
  {
    "name": "Chelsea",
    "lng": -86.55465640373795,
    "lat": 33.344156981844314
  },
  {
    "name": "Chelsea",
    "lng": -84.01868066550003,
    "lat": 42.313193805248765
  },
  {
    "name": "Chelsea",
    "lng": -69.71923678544124,
    "lat": 44.26239397509421
  },
  {
    "name": "Chenequa",
    "lng": -88.38368175200232,
    "lat": 43.12299181111058
  },
  {
    "name": "Cheney",
    "lng": -117.57874817285013,
    "lat": 47.489145930487304
  },
  {
    "name": "Cheney",
    "lng": -97.78055331660806,
    "lat": 37.63241446898296
  },
  {
    "name": "Cheney",
    "lng": -97.76210142134744,
    "lat": 37.63692963751009
  },
  {
    "name": "Cheney",
    "lng": -97.76077932208875,
    "lat": 37.62748821609
  },
  {
    "name": "Cheneyville",
    "lng": -92.290113108647,
    "lat": 31.010999012030453
  },
  {
    "name": "Chenoa",
    "lng": -88.7256481545104,
    "lat": 40.738913067423624
  },
  {
    "name": "Cheraw",
    "lng": -103.51092597137203,
    "lat": 38.10810784561174
  },
  {
    "name": "Cheraw",
    "lng": -79.903174760773,
    "lat": 34.69626761488906
  },
  {
    "name": "Cheriton",
    "lng": -75.96859574292964,
    "lat": 37.29199503683488
  },
  {
    "name": "Cherokee",
    "lng": -98.35505638457045,
    "lat": 36.7547179207084
  },
  {
    "name": "Cherokee",
    "lng": -95.55283630228783,
    "lat": 42.750845233949434
  },
  {
    "name": "Cherokee",
    "lng": -94.82158032737215,
    "lat": 37.34486731953476
  },
  {
    "name": "Cherokee",
    "lng": -87.96702383226332,
    "lat": 34.75774301332131
  },
  {
    "name": "Cherokee Village",
    "lng": -91.56958277516391,
    "lat": 36.29595700364144
  },
  {
    "name": "Cherry",
    "lng": -89.21369947430554,
    "lat": 41.42824487012993
  },
  {
    "name": "Cherry Creek",
    "lng": -79.10085817688926,
    "lat": 42.295846304210265
  },
  {
    "name": "Cherry Hills Village",
    "lng": -104.94805674919803,
    "lat": 39.63755434868442
  },
  {
    "name": "Cherry Valley",
    "lng": -90.75358696606875,
    "lat": 35.40270001510761
  },
  {
    "name": "Cherry Valley",
    "lng": -88.96197988928101,
    "lat": 42.23752613791804
  },
  {
    "name": "Cherry Valley",
    "lng": -88.95462643858842,
    "lat": 42.263472049070344
  },
  {
    "name": "Cherry Valley",
    "lng": -74.75075089490737,
    "lat": 42.797793500888694
  },
  {
    "name": "Cherryfield",
    "lng": -67.93927691225414,
    "lat": 44.6363778612314
  },
  {
    "name": "Cherryvale",
    "lng": -95.55247630419699,
    "lat": 37.26785014227876
  },
  {
    "name": "Cherryville",
    "lng": -81.37847569666677,
    "lat": 35.38439628561302
  },
  {
    "name": "Cherryville",
    "lng": -81.37022876313188,
    "lat": 35.36699339376436
  },
  {
    "name": "Chesapeake",
    "lng": -82.44818921228574,
    "lat": 38.430036038245255
  },
  {
    "name": "Chesapeake",
    "lng": -81.53620399922339,
    "lat": 38.22314634799816
  },
  {
    "name": "Chesapeake",
    "lng": -76.30234043726105,
    "lat": 36.67782009404114
  },
  {
    "name": "Chesapeake Beach",
    "lng": -76.54837067654823,
    "lat": 38.693647891041614
  },
  {
    "name": "Chesapeake Beach",
    "lng": -76.5395271740676,
    "lat": 38.679567721814486
  },
  {
    "name": "Chesapeake City",
    "lng": -75.8111989758317,
    "lat": 39.52766002957528
  },
  {
    "name": "Cheshire",
    "lng": -82.1129982796462,
    "lat": 38.950483808773
  },
  {
    "name": "Cheshire",
    "lng": -73.15120341813439,
    "lat": 42.563861175446554
  },
  {
    "name": "Chesnee",
    "lng": -81.86273572272506,
    "lat": 35.14620630987715
  },
  {
    "name": "Chester",
    "lng": -110.96765832310278,
    "lat": 48.51028638448443
  },
  {
    "name": "Chester",
    "lng": -110.96508538050632,
    "lat": 48.51530612083949
  },
  {
    "name": "Chester",
    "lng": -110.9573478260845,
    "lat": 48.51706873114275
  },
  {
    "name": "Chester",
    "lng": -97.61831391719848,
    "lat": 40.00973699645437
  },
  {
    "name": "Chester",
    "lng": -94.59972409000774,
    "lat": 30.92113066985809
  },
  {
    "name": "Chester",
    "lng": -94.17805751109641,
    "lat": 35.67775159246272
  },
  {
    "name": "Chester",
    "lng": -92.36396508367031,
    "lat": 43.491592872258536
  },
  {
    "name": "Chester",
    "lng": -89.82615828663978,
    "lat": 37.9199426663824
  },
  {
    "name": "Chester",
    "lng": -83.15511999006728,
    "lat": 32.394514007120584
  },
  {
    "name": "Chester",
    "lng": -81.2139520489041,
    "lat": 34.70489934371967
  },
  {
    "name": "Chester",
    "lng": -80.5627268087447,
    "lat": 40.61292439446932
  },
  {
    "name": "Chester",
    "lng": -75.37172514313481,
    "lat": 39.845748283624715
  },
  {
    "name": "Chester",
    "lng": -74.69188985003382,
    "lat": 40.78758948279635
  },
  {
    "name": "Chester",
    "lng": -74.2767665817983,
    "lat": 41.357234051582275
  },
  {
    "name": "Chester",
    "lng": -72.94005473455732,
    "lat": 42.2911375565137
  },
  {
    "name": "Chester",
    "lng": -72.48321113355189,
    "lat": 41.405948018027345
  },
  {
    "name": "Chester",
    "lng": -71.25101639954696,
    "lat": 42.967455154115314
  },
  {
    "name": "Chester",
    "lng": -68.51651202188442,
    "lat": 45.430156905991666
  },
  {
    "name": "Chesterfield",
    "lng": -90.57915369221699,
    "lat": 38.6580664697841
  },
  {
    "name": "Chesterfield",
    "lng": -90.06676549075561,
    "lat": 39.25661422533728
  },
  {
    "name": "Chesterfield",
    "lng": -85.59410512297298,
    "lat": 40.11310351432264
  },
  {
    "name": "Chesterfield",
    "lng": -80.08261978047295,
    "lat": 34.73420896477617
  },
  {
    "name": "Chesterfield",
    "lng": -72.8445625607164,
    "lat": 42.38692664023219
  },
  {
    "name": "Chesterhill",
    "lng": -81.8667140594239,
    "lat": 39.490719670105705
  },
  {
    "name": "Chesterton",
    "lng": -87.05561877112353,
    "lat": 41.600291773580395
  },
  {
    "name": "Chesterton",
    "lng": -87.02659364266826,
    "lat": 41.644293057818864
  },
  {
    "name": "Chestertown",
    "lng": -76.0722600606025,
    "lat": 39.22018366219355
  },
  {
    "name": "Chesterville",
    "lng": -82.68159879982207,
    "lat": 40.47763463012623
  },
  {
    "name": "Chesterville",
    "lng": -70.09178867394766,
    "lat": 44.5493298626752
  },
  {
    "name": "Chestnut Ridge",
    "lng": -74.05517377002592,
    "lat": 41.082958091523025
  },
  {
    "name": "Chetek",
    "lng": -91.65324181607235,
    "lat": 45.31594332653462
  },
  {
    "name": "Chetopa",
    "lng": -95.09254627436391,
    "lat": 37.03762941613754
  },
  {
    "name": "Chevak",
    "lng": -165.59045767616217,
    "lat": 61.52904160654962
  },
  {
    "name": "Cheval",
    "lng": -82.51848095986193,
    "lat": 28.145911115666863
  },
  {
    "name": "Cheviot",
    "lng": -84.61382292153232,
    "lat": 39.15776460476254
  },
  {
    "name": "Chevy Chase",
    "lng": -77.08319947676141,
    "lat": 38.981955593582555
  },
  {
    "name": "Chevy Chase Section Five",
    "lng": -77.07392708532674,
    "lat": 38.983923452261
  },
  {
    "name": "Chevy Chase Section Three",
    "lng": -77.07412010403159,
    "lat": 38.979232852674166
  },
  {
    "name": "Chevy Chase Village",
    "lng": -77.07949596083665,
    "lat": 38.96974253481353
  },
  {
    "name": "Chewelah",
    "lng": -117.75378741467937,
    "lat": 48.31368875116141
  },
  {
    "name": "Chewelah",
    "lng": -117.72000300288906,
    "lat": 48.28779394640532
  },
  {
    "name": "Chewelah",
    "lng": -117.71945226882178,
    "lat": 48.27889765181147
  },
  {
    "name": "Cheyenne",
    "lng": -99.67805244766,
    "lat": 35.61027649251377
  },
  {
    "name": "Cheyenne",
    "lng": -99.67292263639301,
    "lat": 35.59782608821099
  },
  {
    "name": "Cheyenne Wells",
    "lng": -102.35204222591173,
    "lat": 38.819199568017716
  },
  {
    "name": "Chicago",
    "lng": -87.68557591071558,
    "lat": 41.83841779723742
  },
  {
    "name": "Chicago Heights",
    "lng": -87.63462999009678,
    "lat": 41.51003954565956
  },
  {
    "name": "Chicago Ridge",
    "lng": -87.77945406075592,
    "lat": 41.7034162612005
  },
  {
    "name": "Chichester",
    "lng": -71.39950723100141,
    "lat": 43.25742466387485
  },
  {
    "name": "Chickamauga",
    "lng": -85.28902983024672,
    "lat": 34.8743893945489
  },
  {
    "name": "Chickamaw Beach",
    "lng": -94.38357361245126,
    "lat": 46.744924043381054
  },
  {
    "name": "Chickasaw",
    "lng": -88.07913083057325,
    "lat": 30.771352862375867
  },
  {
    "name": "Chickasaw",
    "lng": -84.49363064486386,
    "lat": 40.43656812787904
  },
  {
    "name": "Chickasawhatchee",
    "lng": -84.3802485950692,
    "lat": 31.701227490465637
  },
  {
    "name": "Chickasha",
    "lng": -97.9472143411228,
    "lat": 35.04106576365388
  },
  {
    "name": "Chico",
    "lng": -121.81680580322968,
    "lat": 39.75672341397228
  },
  {
    "name": "Chico",
    "lng": -97.7983346749791,
    "lat": 33.29318028695673
  },
  {
    "name": "Chico",
    "lng": -97.78876321964908,
    "lat": 33.311609267460476
  },
  {
    "name": "Chicopee",
    "lng": -72.57185204404118,
    "lat": 42.176389933829775
  },
  {
    "name": "Chiefland",
    "lng": -82.87084328877808,
    "lat": 29.530019736173
  },
  {
    "name": "Chiefland",
    "lng": -82.8682526360746,
    "lat": 29.49214165314788
  },
  {
    "name": "Chignik",
    "lng": -158.40898715798795,
    "lat": 56.30060138279239
  },
  {
    "name": "Childersburg",
    "lng": -86.39430536829472,
    "lat": 33.25313003282226
  },
  {
    "name": "Childersburg",
    "lng": -86.35168017147566,
    "lat": 33.27933671181771
  },
  {
    "name": "Childersburg",
    "lng": -86.34659177964153,
    "lat": 33.25331657995863
  },
  {
    "name": "Childersburg",
    "lng": -86.34413734375723,
    "lat": 33.25512955141523
  },
  {
    "name": "Childersburg",
    "lng": -86.34329159645054,
    "lat": 33.2295149268014
  },
  {
    "name": "Childersburg",
    "lng": -86.34020648956678,
    "lat": 33.226226909098926
  },
  {
    "name": "Childersburg",
    "lng": -86.33597847896823,
    "lat": 33.341222892574535
  },
  {
    "name": "Childersburg",
    "lng": -86.33213330129995,
    "lat": 33.22128088125261
  },
  {
    "name": "Childersburg",
    "lng": -86.33090051547302,
    "lat": 33.233302940736436
  },
  {
    "name": "Childersburg",
    "lng": -86.31840421077332,
    "lat": 33.21508061489771
  },
  {
    "name": "Childersburg",
    "lng": -86.31201162450098,
    "lat": 33.29514710788143
  },
  {
    "name": "Childersburg",
    "lng": -86.3004297812717,
    "lat": 33.33580246352186
  },
  {
    "name": "Childersburg",
    "lng": -86.29955703397411,
    "lat": 33.28091412272971
  },
  {
    "name": "Childress",
    "lng": -100.25161960354056,
    "lat": 34.42934263134798
  },
  {
    "name": "Chilhowee",
    "lng": -93.85570769983545,
    "lat": 38.58892225064602
  },
  {
    "name": "Chilhowie",
    "lng": -81.68328105440263,
    "lat": 36.800969133384385
  },
  {
    "name": "Chillicothe",
    "lng": -99.51441131254953,
    "lat": 34.255978029696394
  },
  {
    "name": "Chillicothe",
    "lng": -93.54976004470248,
    "lat": 39.79529507754379
  },
  {
    "name": "Chillicothe",
    "lng": -92.53002100574189,
    "lat": 41.08597138213515
  },
  {
    "name": "Chillicothe",
    "lng": -89.50182910152132,
    "lat": 40.91531208552303
  },
  {
    "name": "Chillicothe",
    "lng": -82.99377352163737,
    "lat": 39.33934782346647
  },
  {
    "name": "Chilo",
    "lng": -84.13799893781814,
    "lat": 38.79372000184881
  },
  {
    "name": "Chilton",
    "lng": -88.16255828324061,
    "lat": 44.029319820813626
  },
  {
    "name": "Chilton",
    "lng": -88.16013248824947,
    "lat": 44.00225144755588
  },
  {
    "name": "Chilton",
    "lng": -88.15951805654775,
    "lat": 44.004446481144925
  },
  {
    "name": "Chimney Rock",
    "lng": -82.25557963764787,
    "lat": 35.45018273188112
  },
  {
    "name": "China",
    "lng": -94.33316459407598,
    "lat": 30.052464682970964
  },
  {
    "name": "China",
    "lng": -69.5328550595143,
    "lat": 44.418310979240594
  },
  {
    "name": "China Grove",
    "lng": -98.34461501391415,
    "lat": 29.392435924250897
  },
  {
    "name": "China Grove",
    "lng": -80.57979445968202,
    "lat": 35.5718766569012
  },
  {
    "name": "China Grove",
    "lng": -80.55242644722243,
    "lat": 35.574887251464176
  },
  {
    "name": "Chincoteague",
    "lng": -75.3525644597444,
    "lat": 37.95050365564588
  },
  {
    "name": "Chino",
    "lng": -117.66684032994745,
    "lat": 33.984263991465845
  },
  {
    "name": "Chino Valley",
    "lng": -112.41074986900065,
    "lat": 34.75923462451566
  },
  {
    "name": "Chinook",
    "lng": -109.23151197735959,
    "lat": 48.59015392675557
  },
  {
    "name": "Chipley",
    "lng": -85.54218302761757,
    "lat": 30.777411561911926
  },
  {
    "name": "Chipley",
    "lng": -85.52062587952754,
    "lat": 30.76366425572569
  },
  {
    "name": "Chippewa Falls",
    "lng": -91.43064301288297,
    "lat": 44.94429037036482
  },
  {
    "name": "Chippewa Falls",
    "lng": -91.39027936144964,
    "lat": 44.93549143776323
  },
  {
    "name": "Chippewa Lake",
    "lng": -81.90466089844904,
    "lat": 41.073644472431766
  },
  {
    "name": "Chireno",
    "lng": -94.34586133382872,
    "lat": 31.498956741337597
  },
  {
    "name": "Chisago City",
    "lng": -92.91276045634514,
    "lat": 45.342325154341744
  },
  {
    "name": "Chisago City",
    "lng": -92.90283107826424,
    "lat": 45.40599728467198
  },
  {
    "name": "Chisago City",
    "lng": -92.89083978204108,
    "lat": 45.39603086292848
  },
  {
    "name": "Chisago City",
    "lng": -92.88802520501274,
    "lat": 45.39517368486964
  },
  {
    "name": "Chisago City",
    "lng": -92.8868437834516,
    "lat": 45.39098918029609
  },
  {
    "name": "Chisago City",
    "lng": -92.88399809831694,
    "lat": 45.39329058365161
  },
  {
    "name": "Chisago City",
    "lng": -92.88382295724409,
    "lat": 45.38597097979517
  },
  {
    "name": "Chisago City",
    "lng": -92.88345373169882,
    "lat": 45.39383808654639
  },
  {
    "name": "Chisago City",
    "lng": -92.87795684371939,
    "lat": 45.394205205363036
  },
  {
    "name": "Chisago City",
    "lng": -92.8741110292845,
    "lat": 45.39805257659171
  },
  {
    "name": "Chisago City",
    "lng": -92.8666719410207,
    "lat": 45.38519762669941
  },
  {
    "name": "Chisholm",
    "lng": -92.87876285203465,
    "lat": 47.48769243146063
  },
  {
    "name": "Chittenango",
    "lng": -75.87488567158965,
    "lat": 43.04660379988091
  },
  {
    "name": "Chocowinity",
    "lng": -77.10148936224063,
    "lat": 35.51456436415987
  },
  {
    "name": "Choctaw",
    "lng": -97.26661812873931,
    "lat": 35.47992300463569
  },
  {
    "name": "Chokio",
    "lng": -96.17417435986965,
    "lat": 45.5731725611524
  },
  {
    "name": "Choteau",
    "lng": -112.17629368592398,
    "lat": 47.81594523299497
  },
  {
    "name": "Choudrant",
    "lng": -92.51184868057366,
    "lat": 32.5509951772157
  },
  {
    "name": "Chouteau",
    "lng": -95.33852523440498,
    "lat": 36.189147813870136
  },
  {
    "name": "Chowchilla",
    "lng": -120.28643401287992,
    "lat": 37.11218577081125
  },
  {
    "name": "Chowchilla",
    "lng": -120.24900806931528,
    "lat": 37.118269338651224
  },
  {
    "name": "Chowchilla",
    "lng": -120.15597199153204,
    "lat": 37.097736274703344
  },
  {
    "name": "Chrisman",
    "lng": -87.67521440050129,
    "lat": 39.8044450808306
  },
  {
    "name": "Chrisney",
    "lng": -87.03160650288177,
    "lat": 38.00973012621188
  },
  {
    "name": "Christiansburg",
    "lng": -84.0252403034239,
    "lat": 40.05653998218581
  },
  {
    "name": "Christiansburg",
    "lng": -80.40365321011386,
    "lat": 37.14059419112464
  },
  {
    "name": "Christine",
    "lng": -98.49774001604736,
    "lat": 28.78623045595615
  },
  {
    "name": "Christine",
    "lng": -96.80611610060053,
    "lat": 46.57495625760439
  },
  {
    "name": "Christopher",
    "lng": -89.05287249916688,
    "lat": 37.97099046051816
  },
  {
    "name": "Chuathbaluk",
    "lng": -159.249893491752,
    "lat": 61.57506991751765
  },
  {
    "name": "Chubbuck",
    "lng": -112.46234544911135,
    "lat": 42.92628333614214
  },
  {
    "name": "Chugwater",
    "lng": -104.82293317952765,
    "lat": 41.75575024187065
  },
  {
    "name": "Chula",
    "lng": -93.4775254452146,
    "lat": 39.921954226641716
  },
  {
    "name": "Chula Vista",
    "lng": -117.01412375471524,
    "lat": 32.628632726452096
  },
  {
    "name": "Chunky",
    "lng": -88.93017238508754,
    "lat": 32.327488384953234
  },
  {
    "name": "Church Creek",
    "lng": -76.15301082551917,
    "lat": 38.50354485835958
  },
  {
    "name": "Church Hill",
    "lng": -82.71403303547257,
    "lat": 36.52049660210061
  },
  {
    "name": "Church Hill",
    "lng": -75.98071809066066,
    "lat": 39.144966957465954
  },
  {
    "name": "Church Point",
    "lng": -92.21448874154706,
    "lat": 30.403703142464064
  },
  {
    "name": "Churchs Ferry",
    "lng": -99.19435006257014,
    "lat": 48.26862799971664
  },
  {
    "name": "Churchville",
    "lng": -77.88338088195371,
    "lat": 43.102973791242405
  },
  {
    "name": "Churdan",
    "lng": -94.47799068638474,
    "lat": 42.15403501095456
  },
  {
    "name": "Churubusco",
    "lng": -85.31986984104023,
    "lat": 41.230911169596276
  },
  {
    "name": "Cibolo",
    "lng": -98.22646639396665,
    "lat": 29.579955164740912
  },
  {
    "name": "Cibolo",
    "lng": -98.21423794887251,
    "lat": 29.525894353565757
  },
  {
    "name": "Cibolo",
    "lng": -98.21239900259108,
    "lat": 29.55369720162335
  },
  {
    "name": "Cibolo",
    "lng": -98.21198955137952,
    "lat": 29.546587342893268
  },
  {
    "name": "Cibolo",
    "lng": -98.21155116666667,
    "lat": 29.525688100064677
  },
  {
    "name": "Cibolo",
    "lng": -98.21126632531039,
    "lat": 29.528207908940793
  },
  {
    "name": "Cibolo",
    "lng": -98.20928223802535,
    "lat": 29.52779951159292
  },
  {
    "name": "Cibolo",
    "lng": -98.20810044984448,
    "lat": 29.546611248812418
  },
  {
    "name": "Cibolo",
    "lng": -98.20780647590804,
    "lat": 29.544703403260634
  },
  {
    "name": "Cibolo",
    "lng": -98.20663711696312,
    "lat": 29.546060324779987
  },
  {
    "name": "Cibolo",
    "lng": -98.20203494200938,
    "lat": 29.527076447626868
  },
  {
    "name": "Cibolo",
    "lng": -98.20158323185052,
    "lat": 29.522285961616056
  },
  {
    "name": "Cibolo",
    "lng": -98.19726600405116,
    "lat": 29.577893930475344
  },
  {
    "name": "Cibolo",
    "lng": -98.1857302433498,
    "lat": 29.531726969945307
  },
  {
    "name": "Cibolo",
    "lng": -98.18510749833993,
    "lat": 29.495589770167353
  },
  {
    "name": "Cibolo",
    "lng": -98.178876958688,
    "lat": 29.540262664536076
  },
  {
    "name": "Cibolo",
    "lng": -98.17745235372051,
    "lat": 29.534597376300354
  },
  {
    "name": "Cibolo",
    "lng": -98.17738140335538,
    "lat": 29.52347366809603
  },
  {
    "name": "Cibolo",
    "lng": -98.17538613957122,
    "lat": 29.500057247387215
  },
  {
    "name": "Cibolo",
    "lng": -98.17305054268165,
    "lat": 29.530495711494968
  },
  {
    "name": "Cibolo",
    "lng": -98.16794639300562,
    "lat": 29.505369607188747
  },
  {
    "name": "Cibolo",
    "lng": -98.16295932626413,
    "lat": 29.526944213112408
  },
  {
    "name": "Cibolo",
    "lng": -98.16233624614391,
    "lat": 29.51388222533371
  },
  {
    "name": "Cibolo",
    "lng": -98.16096257986042,
    "lat": 29.526364961714783
  },
  {
    "name": "Cibolo",
    "lng": -98.15325266945368,
    "lat": 29.523447529569626
  },
  {
    "name": "Cibolo",
    "lng": -98.15170475610996,
    "lat": 29.529732406419697
  },
  {
    "name": "Cibolo",
    "lng": -98.14765407931327,
    "lat": 29.532377541647303
  },
  {
    "name": "Cibolo",
    "lng": -98.14448234776918,
    "lat": 29.525332331901165
  },
  {
    "name": "Cibolo",
    "lng": -98.14442042431087,
    "lat": 29.530375863126736
  },
  {
    "name": "Cicero",
    "lng": -87.75925590168124,
    "lat": 41.844508808462194
  },
  {
    "name": "Cicero",
    "lng": -86.02457084331888,
    "lat": 40.12486190187808
  },
  {
    "name": "Cimarron",
    "lng": -104.91254691718976,
    "lat": 36.50920745678297
  },
  {
    "name": "Cimarron",
    "lng": -100.35814689144709,
    "lat": 37.80788395322297
  },
  {
    "name": "Cimarron",
    "lng": -100.34615448591191,
    "lat": 37.809632737187975
  },
  {
    "name": "Cimarron",
    "lng": -100.34351591835052,
    "lat": 37.82497708036765
  },
  {
    "name": "Cimarron",
    "lng": -100.34274435174702,
    "lat": 37.82121354021349
  },
  {
    "name": "Cimarron",
    "lng": -100.33343720440826,
    "lat": 37.80749878311019
  },
  {
    "name": "Cimarron",
    "lng": -100.32285811778921,
    "lat": 37.8045198302094
  },
  {
    "name": "Cimarron City",
    "lng": -97.60226207905326,
    "lat": 35.88866917595721
  },
  {
    "name": "Cincinnati",
    "lng": -92.92220506840025,
    "lat": 40.63095916014451
  },
  {
    "name": "Cincinnati",
    "lng": -84.50612622321236,
    "lat": 39.1412565058401
  },
  {
    "name": "Circle",
    "lng": -105.5869555840399,
    "lat": 47.41763921567757
  },
  {
    "name": "Circle Pines",
    "lng": -93.15051636347651,
    "lat": 45.140780761488735
  },
  {
    "name": "Circleville",
    "lng": -112.27161603266462,
    "lat": 38.16839074223697
  },
  {
    "name": "Circleville",
    "lng": -95.85579948078123,
    "lat": 39.50936474523934
  },
  {
    "name": "Circleville",
    "lng": -82.93353422201449,
    "lat": 39.60623139355503
  },
  {
    "name": "Cisco",
    "lng": -98.98048955144176,
    "lat": 32.38482454670633
  },
  {
    "name": "Cisco",
    "lng": -88.72541979716848,
    "lat": 40.011619146326034
  },
  {
    "name": "Cisco",
    "lng": -88.72397412799852,
    "lat": 40.01938378177623
  },
  {
    "name": "Cisne",
    "lng": -88.43714250801543,
    "lat": 38.513920752645355
  },
  {
    "name": "Cissna Park",
    "lng": -87.8923422358077,
    "lat": 40.566698244824046
  },
  {
    "name": "Citronelle",
    "lng": -88.31068773297854,
    "lat": 31.06897069518484
  },
  {
    "name": "Citronelle",
    "lng": -88.31068773297851,
    "lat": 31.06897069518486
  },
  {
    "name": "Citronelle",
    "lng": -88.24172650695549,
    "lat": 31.0981842552509
  },
  {
    "name": "Citronelle",
    "lng": -88.24172370304032,
    "lat": 31.098186400206423
  },
  {
    "name": "City Hall",
    "lng": -89.40350538534503,
    "lat": 40.70374564655885
  },
  {
    "name": "City of Auburn",
    "lng": -84.07557616853653,
    "lat": 43.602084345902874
  },
  {
    "name": "City of Berlin",
    "lng": -88.95028930395057,
    "lat": 43.97058274769963
  },
  {
    "name": "City of Cohoes",
    "lng": -73.7077452301748,
    "lat": 42.773269383566806
  },
  {
    "name": "City of Essexville",
    "lng": -83.8422045145106,
    "lat": 43.612281776693855
  },
  {
    "name": "City of Frankenmuth",
    "lng": -83.73996141012185,
    "lat": 43.33203784733211
  },
  {
    "name": "City of Green Lake",
    "lng": -88.95775004130073,
    "lat": 43.84328605622381
  },
  {
    "name": "City of Green Lake",
    "lng": -88.94719356637579,
    "lat": 43.848312064063926
  },
  {
    "name": "City of Green Lake",
    "lng": -88.93363823876066,
    "lat": 43.84453652006604
  },
  {
    "name": "City of Pewaukee",
    "lng": -88.24068624929015,
    "lat": 43.0700161837569
  },
  {
    "name": "City of Pinconning",
    "lng": -83.96378120400875,
    "lat": 43.857268852939626
  },
  {
    "name": "City of Saginaw",
    "lng": -83.95035849430892,
    "lat": 43.41925649224482
  },
  {
    "name": "City of Superior",
    "lng": -92.06062258778769,
    "lat": 46.69677639735346
  },
  {
    "name": "City of Superior",
    "lng": -92.01090165177061,
    "lat": 46.652787711244386
  },
  {
    "name": "City of Troy",
    "lng": -73.67513785941995,
    "lat": 42.735474748936454
  },
  {
    "name": "City of Utica",
    "lng": -75.2270504451294,
    "lat": 43.096437544601635
  },
  {
    "name": "City of Verona",
    "lng": -89.5380003135247,
    "lat": 42.98895245185231
  },
  {
    "name": "City of Watervliet",
    "lng": -73.70681168589444,
    "lat": 42.724405284277054
  },
  {
    "name": "Claflin",
    "lng": -98.53702554358277,
    "lat": 38.52439836284206
  },
  {
    "name": "Claire City",
    "lng": -97.10353060634704,
    "lat": 45.8566188670303
  },
  {
    "name": "Clanton",
    "lng": -86.62247082796434,
    "lat": 32.84225416516228
  },
  {
    "name": "Clara City",
    "lng": -95.36701899602616,
    "lat": 44.95773352587411
  },
  {
    "name": "Clare",
    "lng": -94.34442835303913,
    "lat": 42.587296204898834
  },
  {
    "name": "Clare",
    "lng": -84.78705871744593,
    "lat": 43.8236461873264
  },
  {
    "name": "Clare",
    "lng": -84.78488275242728,
    "lat": 43.823236762696354
  },
  {
    "name": "Clare",
    "lng": -84.7842080445799,
    "lat": 43.82212842455619
  },
  {
    "name": "Clare",
    "lng": -84.76285852367016,
    "lat": 43.82451484742467
  },
  {
    "name": "Clare",
    "lng": -84.76069231643353,
    "lat": 43.845508716817
  },
  {
    "name": "Clare",
    "lng": -84.74815445618991,
    "lat": 43.82732178234948
  },
  {
    "name": "Clare",
    "lng": -84.74452345102722,
    "lat": 43.8253805489665
  },
  {
    "name": "Claremont",
    "lng": -117.71534988025384,
    "lat": 34.125897290125245
  },
  {
    "name": "Claremont",
    "lng": -98.01522019948297,
    "lat": 45.671867697285826
  },
  {
    "name": "Claremont",
    "lng": -92.99762219813942,
    "lat": 44.04494078913933
  },
  {
    "name": "Claremont",
    "lng": -87.97287178523621,
    "lat": 38.71760153596485
  },
  {
    "name": "Claremont",
    "lng": -81.16602643199266,
    "lat": 35.73183527860653
  },
  {
    "name": "Claremont",
    "lng": -81.15476319372654,
    "lat": 35.68539019108423
  },
  {
    "name": "Claremont",
    "lng": -81.15319223391175,
    "lat": 35.711082921304815
  },
  {
    "name": "Claremont",
    "lng": -81.14730681148178,
    "lat": 35.70290164369349
  },
  {
    "name": "Claremont",
    "lng": -81.13191861940983,
    "lat": 35.704922497693914
  },
  {
    "name": "Claremont",
    "lng": -76.96534135754214,
    "lat": 37.23077437598578
  },
  {
    "name": "Claremont",
    "lng": -72.3367741385436,
    "lat": 43.37927676465205
  },
  {
    "name": "Claremore",
    "lng": -95.61050873302032,
    "lat": 36.311867984102534
  },
  {
    "name": "Claremore",
    "lng": -95.58937859171888,
    "lat": 36.35525686561492
  },
  {
    "name": "Clarence",
    "lng": -93.0290802204135,
    "lat": 31.819803442348228
  },
  {
    "name": "Clarence",
    "lng": -92.26013568805041,
    "lat": 39.743181797555266
  },
  {
    "name": "Clarence",
    "lng": -91.05783315875642,
    "lat": 41.88576314753033
  },
  {
    "name": "Clarendon",
    "lng": -100.89178563792595,
    "lat": 34.93664648308943
  },
  {
    "name": "Clarendon",
    "lng": -91.30549332210047,
    "lat": 34.69530526421394
  },
  {
    "name": "Clarendon Hills",
    "lng": -87.95689122571213,
    "lat": 41.79811182297751
  },
  {
    "name": "Clarinda",
    "lng": -95.03380792762715,
    "lat": 40.73765763961495
  },
  {
    "name": "Clarington",
    "lng": -80.86476638324581,
    "lat": 39.776452860528074
  },
  {
    "name": "Clarion",
    "lng": -93.72959317189807,
    "lat": 42.73180464850873
  },
  {
    "name": "Clarion",
    "lng": -79.37993130545219,
    "lat": 41.21029311182981
  },
  {
    "name": "Clarissa",
    "lng": -94.94908584399727,
    "lat": 46.12846345727084
  },
  {
    "name": "Clark",
    "lng": -97.73500568884167,
    "lat": 44.88137005011606
  },
  {
    "name": "Clark",
    "lng": -92.34199287576662,
    "lat": 39.28036166803193
  },
  {
    "name": "Clark Fork",
    "lng": -116.17680608361326,
    "lat": 48.147164215480736
  },
  {
    "name": "Clark\"s Point",
    "lng": -158.53535061496947,
    "lat": 58.83432523029342
  },
  {
    "name": "Clarkedale",
    "lng": -90.2103081676757,
    "lat": 35.29705687592478
  },
  {
    "name": "Clarkesville",
    "lng": -83.52834174342075,
    "lat": 34.61021690415809
  },
  {
    "name": "Clarkfield",
    "lng": -95.80788692479241,
    "lat": 44.7907336519211
  },
  {
    "name": "Clarks",
    "lng": -97.83950191006365,
    "lat": 41.21627201463808
  },
  {
    "name": "Clarks",
    "lng": -92.14083877698553,
    "lat": 32.02881793441931
  },
  {
    "name": "Clarks Grove",
    "lng": -93.3272813322968,
    "lat": 43.76274742742714
  },
  {
    "name": "Clarks Hill",
    "lng": -86.72432983548076,
    "lat": 40.24722064105335
  },
  {
    "name": "Clarksburg",
    "lng": -92.66610556134411,
    "lat": 38.66120267259977
  },
  {
    "name": "Clarksburg",
    "lng": -88.39305141090064,
    "lat": 35.86373567596228
  },
  {
    "name": "Clarksburg",
    "lng": -83.15466185604684,
    "lat": 39.50584910908877
  },
  {
    "name": "Clarksburg",
    "lng": -83.14862228478579,
    "lat": 39.50678987211586
  },
  {
    "name": "Clarksburg",
    "lng": -80.32313424817131,
    "lat": 39.28607101410914
  },
  {
    "name": "Clarksburg",
    "lng": -73.10893707114822,
    "lat": 42.72665884208255
  },
  {
    "name": "Clarksdale",
    "lng": -94.55076974190004,
    "lat": 39.81384933162452
  },
  {
    "name": "Clarksdale",
    "lng": -90.57293643422611,
    "lat": 34.19713820982621
  },
  {
    "name": "Clarkson",
    "lng": -97.12208834761798,
    "lat": 41.72383827833227
  },
  {
    "name": "Clarkson",
    "lng": -86.23656081036631,
    "lat": 37.49545255794808
  },
  {
    "name": "Clarkson",
    "lng": -86.22020057303814,
    "lat": 37.49448566465906
  },
  {
    "name": "Clarkson Valley",
    "lng": -90.59443802802271,
    "lat": 38.62554089587197
  },
  {
    "name": "Clarkston",
    "lng": -117.05024987497066,
    "lat": 46.41538006305063
  },
  {
    "name": "Clarkston",
    "lng": -112.0504076145398,
    "lat": 41.92049135415685
  },
  {
    "name": "Clarkston",
    "lng": -84.24059163274468,
    "lat": 33.81160995288439
  },
  {
    "name": "Clarksville",
    "lng": -95.05612695115596,
    "lat": 33.61096877428117
  },
  {
    "name": "Clarksville",
    "lng": -93.4798782572776,
    "lat": 35.45683709108014
  },
  {
    "name": "Clarksville",
    "lng": -92.66806220566203,
    "lat": 42.77995321655514
  },
  {
    "name": "Clarksville",
    "lng": -90.90470358458371,
    "lat": 39.370472914518565
  },
  {
    "name": "Clarksville",
    "lng": -87.34269580292631,
    "lat": 36.56958331877201
  },
  {
    "name": "Clarksville",
    "lng": -85.7674670706983,
    "lat": 38.3219722813813
  },
  {
    "name": "Clarksville",
    "lng": -85.24245820283616,
    "lat": 42.8420870131503
  },
  {
    "name": "Clarksville",
    "lng": -83.98283425064695,
    "lat": 39.40159087742012
  },
  {
    "name": "Clarksville",
    "lng": -78.56519940222582,
    "lat": 36.6199839164179
  },
  {
    "name": "Clarksville City",
    "lng": -94.89399121157186,
    "lat": 32.53360941148897
  },
  {
    "name": "Clarkton",
    "lng": -89.96808877977003,
    "lat": 36.45204558900019
  },
  {
    "name": "Clarkton",
    "lng": -78.6547610490203,
    "lat": 34.4889937741034
  },
  {
    "name": "Clatonia",
    "lng": -96.85132633715625,
    "lat": 40.46538048946018
  },
  {
    "name": "Claude",
    "lng": -101.36282995203786,
    "lat": 35.10745612902399
  },
  {
    "name": "Clawson",
    "lng": -111.09353224710262,
    "lat": 39.13898182626353
  },
  {
    "name": "Clawson",
    "lng": -83.15041238957392,
    "lat": 42.53667102988907
  },
  {
    "name": "Claxton",
    "lng": -81.90803534411339,
    "lat": 32.16123262405241
  },
  {
    "name": "Clay",
    "lng": -87.82063634663365,
    "lat": 37.47630139423413
  },
  {
    "name": "Clay",
    "lng": -86.6555075870886,
    "lat": 33.64587197721963
  },
  {
    "name": "Clay",
    "lng": -86.6544156325557,
    "lat": 33.64666170461817
  },
  {
    "name": "Clay",
    "lng": -86.65356562674587,
    "lat": 33.6469125188424
  },
  {
    "name": "Clay",
    "lng": -86.64684900270046,
    "lat": 33.662107541768066
  },
  {
    "name": "Clay",
    "lng": -86.64682142546482,
    "lat": 33.66215607001911
  },
  {
    "name": "Clay",
    "lng": -86.64269459581696,
    "lat": 33.683260786855534
  },
  {
    "name": "Clay",
    "lng": -86.64129642105617,
    "lat": 33.653630109129324
  },
  {
    "name": "Clay",
    "lng": -86.63708728398082,
    "lat": 33.69016781520547
  },
  {
    "name": "Clay",
    "lng": -86.63556312574998,
    "lat": 33.68829937119972
  },
  {
    "name": "Clay",
    "lng": -86.63556312574997,
    "lat": 33.68829937119972
  },
  {
    "name": "Clay",
    "lng": -86.63485855116,
    "lat": 33.689725225867186
  },
  {
    "name": "Clay",
    "lng": -86.63376549999997,
    "lat": 33.68976347995942
  },
  {
    "name": "Clay",
    "lng": -86.6321452868746,
    "lat": 33.68976626204244
  },
  {
    "name": "Clay",
    "lng": -86.63008746111738,
    "lat": 33.68957856705318
  },
  {
    "name": "Clay",
    "lng": -86.62886050144645,
    "lat": 33.690850356957
  },
  {
    "name": "Clay",
    "lng": -86.62777344554914,
    "lat": 33.69090234414666
  },
  {
    "name": "Clay",
    "lng": -86.62777344554912,
    "lat": 33.69090234414666
  },
  {
    "name": "Clay",
    "lng": -86.5980322765439,
    "lat": 33.70613800501073
  },
  {
    "name": "Clay",
    "lng": -86.59758233424601,
    "lat": 33.70622686607842
  },
  {
    "name": "Clay",
    "lng": -86.57182984930193,
    "lat": 33.701782801342695
  },
  {
    "name": "Clay",
    "lng": -86.56997580612718,
    "lat": 33.71301943873196
  },
  {
    "name": "Clay",
    "lng": -86.56270354210588,
    "lat": 33.72282013022205
  },
  {
    "name": "Clay",
    "lng": -81.0813890097395,
    "lat": 38.466230457259584
  },
  {
    "name": "Clay Center",
    "lng": -98.05496440014174,
    "lat": 40.52553646680094
  },
  {
    "name": "Clay Center",
    "lng": -97.1482733152511,
    "lat": 39.385820510885466
  },
  {
    "name": "Clay Center",
    "lng": -97.12390190292953,
    "lat": 39.380664145319
  },
  {
    "name": "Clay Center",
    "lng": -83.36428728054132,
    "lat": 41.57322799235977
  },
  {
    "name": "Clay City",
    "lng": -88.3486228861508,
    "lat": 38.68579437024171
  },
  {
    "name": "Clay City",
    "lng": -87.11270233522481,
    "lat": 39.27696596220696
  },
  {
    "name": "Clay City",
    "lng": -83.92739307089113,
    "lat": 37.86198301283076
  },
  {
    "name": "Claycomo",
    "lng": -94.47949910741342,
    "lat": 39.19895178709197
  },
  {
    "name": "Clayhatchee",
    "lng": -85.71460349356417,
    "lat": 31.24342487787991
  },
  {
    "name": "Claypool",
    "lng": -85.88248588444955,
    "lat": 41.1305595050501
  },
  {
    "name": "Clayton",
    "lng": -114.39964715275644,
    "lat": 44.25920591027879
  },
  {
    "name": "Clayton",
    "lng": -103.15810526158523,
    "lat": 36.443574154466425
  },
  {
    "name": "Clayton",
    "lng": -100.17646917910979,
    "lat": 39.73703626957805
  },
  {
    "name": "Clayton",
    "lng": -95.35647419278236,
    "lat": 34.58573676787926
  },
  {
    "name": "Clayton",
    "lng": -92.1710775668132,
    "lat": 45.325728405324135
  },
  {
    "name": "Clayton",
    "lng": -91.53728358089123,
    "lat": 31.72262881498658
  },
  {
    "name": "Clayton",
    "lng": -91.14935725221795,
    "lat": 42.9022825569383
  },
  {
    "name": "Clayton",
    "lng": -90.95803059619485,
    "lat": 40.03009497252686
  },
  {
    "name": "Clayton",
    "lng": -90.3302680237269,
    "lat": 38.644410915726624
  },
  {
    "name": "Clayton",
    "lng": -86.52313479887144,
    "lat": 39.688322060823104
  },
  {
    "name": "Clayton",
    "lng": -85.45219302639114,
    "lat": 31.881373785627417
  },
  {
    "name": "Clayton",
    "lng": -84.34638382391336,
    "lat": 39.87838927246101
  },
  {
    "name": "Clayton",
    "lng": -84.31429632231645,
    "lat": 39.905222812325206
  },
  {
    "name": "Clayton",
    "lng": -84.29734463545594,
    "lat": 39.88370754899202
  },
  {
    "name": "Clayton",
    "lng": -84.28236086003132,
    "lat": 39.84206494659921
  },
  {
    "name": "Clayton",
    "lng": -84.23557461216078,
    "lat": 41.86509023807306
  },
  {
    "name": "Clayton",
    "lng": -83.40171983213962,
    "lat": 34.87760048950057
  },
  {
    "name": "Clayton",
    "lng": -78.48196568892642,
    "lat": 35.621771086250625
  },
  {
    "name": "Clayton",
    "lng": -78.46570170176953,
    "lat": 35.64763768897054
  },
  {
    "name": "Clayton",
    "lng": -78.45134326756052,
    "lat": 35.67254515051069
  },
  {
    "name": "Clayton",
    "lng": -78.43509648179054,
    "lat": 35.70073832081574
  },
  {
    "name": "Clayton",
    "lng": -78.43125145106082,
    "lat": 35.64143397226339
  },
  {
    "name": "Clayton",
    "lng": -78.42787803361512,
    "lat": 35.62722424101489
  },
  {
    "name": "Clayton",
    "lng": -78.41740237380273,
    "lat": 35.61414712029856
  },
  {
    "name": "Clayton",
    "lng": -78.41658532639916,
    "lat": 35.65184526094869
  },
  {
    "name": "Clayton",
    "lng": -78.39703970167689,
    "lat": 35.63721655586652
  },
  {
    "name": "Clayton",
    "lng": -76.08262814179551,
    "lat": 44.236966353338175
  },
  {
    "name": "Clayville",
    "lng": -75.24864038103354,
    "lat": 42.974335665621
  },
  {
    "name": "Cle Elum",
    "lng": -120.96437473486652,
    "lat": 47.19395754364772
  },
  {
    "name": "Clear Lake",
    "lng": -96.68260226893602,
    "lat": 44.7543318482302
  },
  {
    "name": "Clear Lake",
    "lng": -96.66572865867914,
    "lat": 44.783429624152625
  },
  {
    "name": "Clear Lake",
    "lng": -94.02413544229663,
    "lat": 45.41578365845172
  },
  {
    "name": "Clear Lake",
    "lng": -93.99640332484464,
    "lat": 45.44888121824304
  },
  {
    "name": "Clear Lake",
    "lng": -93.37396689055213,
    "lat": 43.13473458757576
  },
  {
    "name": "Clear Lake",
    "lng": -92.26760607863787,
    "lat": 45.24922659911036
  },
  {
    "name": "Clear Lake",
    "lng": -89.56832831253166,
    "lat": 39.813808100478965
  },
  {
    "name": "Clear Lake",
    "lng": -84.83918072956494,
    "lat": 41.73821117956474
  },
  {
    "name": "Clear Lake Shores",
    "lng": -95.03277277781307,
    "lat": 29.545715610193547
  },
  {
    "name": "Clearbrook",
    "lng": -95.43309509999546,
    "lat": 47.69421510031408
  },
  {
    "name": "Clearbrook",
    "lng": -95.42926453139565,
    "lat": 47.69642559403189
  },
  {
    "name": "Clearbrook",
    "lng": -95.42734635947834,
    "lat": 47.69007968274154
  },
  {
    "name": "Clearfield",
    "lng": -112.02380261341224,
    "lat": 41.10298668992459
  },
  {
    "name": "Clearfield",
    "lng": -94.48360237259705,
    "lat": 40.80281552760943
  },
  {
    "name": "Clearfield",
    "lng": -78.43904275289462,
    "lat": 41.021516053717065
  },
  {
    "name": "Clearmont",
    "lng": -106.38110480326753,
    "lat": 44.64014338192176
  },
  {
    "name": "Clearmont",
    "lng": -95.03289830943456,
    "lat": 40.50791942559254
  },
  {
    "name": "Clearview",
    "lng": -96.19075792201743,
    "lat": 35.397103087692074
  },
  {
    "name": "Clearview",
    "lng": -80.69011566755337,
    "lat": 40.13990036537551
  },
  {
    "name": "Clearwater",
    "lng": -98.18972652459429,
    "lat": 42.17069790077327
  },
  {
    "name": "Clearwater",
    "lng": -97.51457483377553,
    "lat": 37.49843947241217
  },
  {
    "name": "Clearwater",
    "lng": -97.50052877619216,
    "lat": 37.51310174642169
  },
  {
    "name": "Clearwater",
    "lng": -97.49921736957415,
    "lat": 37.49140215729393
  },
  {
    "name": "Clearwater",
    "lng": -97.49809106637116,
    "lat": 37.50573633430524
  },
  {
    "name": "Clearwater",
    "lng": -97.49743722429851,
    "lat": 37.54432044620666
  },
  {
    "name": "Clearwater",
    "lng": -97.49313897269415,
    "lat": 37.536871873510314
  },
  {
    "name": "Clearwater",
    "lng": -97.48611562623195,
    "lat": 37.53410172041327
  },
  {
    "name": "Clearwater",
    "lng": -94.0554187509562,
    "lat": 45.40811804562899
  },
  {
    "name": "Clearwater",
    "lng": -94.05045993184422,
    "lat": 45.40198084871365
  },
  {
    "name": "Clearwater",
    "lng": -94.04231378242741,
    "lat": 45.41188477977525
  },
  {
    "name": "Clearwater",
    "lng": -82.78561950000092,
    "lat": 27.939832149989922
  },
  {
    "name": "Clearwater",
    "lng": -82.7839691338954,
    "lat": 27.992763493246592
  },
  {
    "name": "Clearwater",
    "lng": -82.76919838121835,
    "lat": 27.979123830098924
  },
  {
    "name": "Clearwater",
    "lng": -82.7403147353977,
    "lat": 27.967374000216637
  },
  {
    "name": "Clearwater",
    "lng": -82.73990790212329,
    "lat": 27.96992751334793
  },
  {
    "name": "Clearwater",
    "lng": -82.73880824999995,
    "lat": 27.970645200046157
  },
  {
    "name": "Clearwater",
    "lng": -82.73547611514492,
    "lat": 27.986591880783337
  },
  {
    "name": "Clearwater",
    "lng": -82.72823157125751,
    "lat": 27.98814748859576
  },
  {
    "name": "Clearwater",
    "lng": -82.72775017567287,
    "lat": 27.98880260251096
  },
  {
    "name": "Clearwater",
    "lng": -82.72772453021784,
    "lat": 27.987555345125166
  },
  {
    "name": "Clearwater",
    "lng": -82.7277201221129,
    "lat": 27.988167021341475
  },
  {
    "name": "Clearwater",
    "lng": -82.72741265967511,
    "lat": 27.986938621696225
  },
  {
    "name": "Clearwater",
    "lng": -82.72302150385117,
    "lat": 27.98131621219287
  },
  {
    "name": "Clearwater",
    "lng": -82.72226989169138,
    "lat": 27.980114324863877
  },
  {
    "name": "Cleburne",
    "lng": -97.42071906544456,
    "lat": 32.43201604563693
  },
  {
    "name": "Cleburne",
    "lng": -97.41652428987032,
    "lat": 32.43675308649457
  },
  {
    "name": "Cleburne",
    "lng": -97.41459718233362,
    "lat": 32.35591847337276
  },
  {
    "name": "Cleburne",
    "lng": -97.41163981889625,
    "lat": 32.432067939925275
  },
  {
    "name": "Cleghorn",
    "lng": -95.71268153157969,
    "lat": 42.81234107710489
  },
  {
    "name": "Clements",
    "lng": -95.05473948638944,
    "lat": 44.38004027720038
  },
  {
    "name": "Clemmons",
    "lng": -80.39800993650032,
    "lat": 36.077328961386236
  },
  {
    "name": "Clemmons",
    "lng": -80.38587816629334,
    "lat": 36.031696345858236
  },
  {
    "name": "Clemons",
    "lng": -93.15871857661762,
    "lat": 42.1136791848856
  },
  {
    "name": "Clemson",
    "lng": -82.81256038879674,
    "lat": 34.68424602883679
  },
  {
    "name": "Clendenin",
    "lng": -81.35000454651095,
    "lat": 38.486352860028866
  },
  {
    "name": "Cleo Springs",
    "lng": -98.44010076612474,
    "lat": 36.404562722515585
  },
  {
    "name": "Clermont",
    "lng": -91.65233061510152,
    "lat": 43.00006272009587
  },
  {
    "name": "Clermont",
    "lng": -86.32065210802891,
    "lat": 39.81655566257834
  },
  {
    "name": "Clermont",
    "lng": -83.77289465393757,
    "lat": 34.478154010564836
  },
  {
    "name": "Cleveland",
    "lng": -110.85555292057282,
    "lat": 39.3494530461362
  },
  {
    "name": "Cleveland",
    "lng": -99.12152071418096,
    "lat": 46.89366290285788
  },
  {
    "name": "Cleveland",
    "lng": -99.11995433142633,
    "lat": 46.89040890821295
  },
  {
    "name": "Cleveland",
    "lng": -96.46443160992388,
    "lat": 36.30198336649171
  },
  {
    "name": "Cleveland",
    "lng": -96.43954276138413,
    "lat": 36.27010665462322
  },
  {
    "name": "Cleveland",
    "lng": -95.16769927268983,
    "lat": 30.302441557677817
  },
  {
    "name": "Cleveland",
    "lng": -95.15382130571223,
    "lat": 30.333813445961034
  },
  {
    "name": "Cleveland",
    "lng": -95.08170796791033,
    "lat": 30.338247074619556
  },
  {
    "name": "Cleveland",
    "lng": -94.59796533247076,
    "lat": 38.678781233083065
  },
  {
    "name": "Cleveland",
    "lng": -93.83523772340567,
    "lat": 44.3235980703477
  },
  {
    "name": "Cleveland",
    "lng": -90.72865946005756,
    "lat": 33.74415151497972
  },
  {
    "name": "Cleveland",
    "lng": -90.31799891292819,
    "lat": 41.50333292429157
  },
  {
    "name": "Cleveland",
    "lng": -87.74619337583503,
    "lat": 43.91816733986067
  },
  {
    "name": "Cleveland",
    "lng": -84.87011536639326,
    "lat": 35.1819226863825
  },
  {
    "name": "Cleveland",
    "lng": -83.76142318521516,
    "lat": 34.59765163609106
  },
  {
    "name": "Cleveland",
    "lng": -82.15257844162733,
    "lat": 36.94337043135994
  },
  {
    "name": "Cleveland",
    "lng": -81.6802095853404,
    "lat": 41.47931821689226
  },
  {
    "name": "Cleveland",
    "lng": -80.68238764284098,
    "lat": 35.732280732376516
  },
  {
    "name": "Cleveland",
    "lng": -75.88466691475347,
    "lat": 43.23976804399137
  },
  {
    "name": "Cleveland Heights",
    "lng": -81.56354505295327,
    "lat": 41.51119029918429
  },
  {
    "name": "Clever",
    "lng": -93.4723660481239,
    "lat": 37.033447320186454
  },
  {
    "name": "Cleves",
    "lng": -84.74667261892122,
    "lat": 39.16333163650192
  },
  {
    "name": "Clewiston",
    "lng": -80.94001625546103,
    "lat": 26.75293049153132
  },
  {
    "name": "Cliff Village",
    "lng": -94.51719769542598,
    "lat": 37.0251476357532
  },
  {
    "name": "Clifford",
    "lng": -97.41013476763514,
    "lat": 47.34817466358511
  },
  {
    "name": "Clifford",
    "lng": -85.86901161293055,
    "lat": 39.282604348406544
  },
  {
    "name": "Clifford",
    "lng": -83.1793136628527,
    "lat": 43.31484426454039
  },
  {
    "name": "Clifton",
    "lng": -112.00459682352117,
    "lat": 42.18728824292945
  },
  {
    "name": "Clifton",
    "lng": -109.28828012756655,
    "lat": 33.02492290219351
  },
  {
    "name": "Clifton",
    "lng": -97.58392084010833,
    "lat": 31.782236876834634
  },
  {
    "name": "Clifton",
    "lng": -97.28075230777164,
    "lat": 39.567938644006574
  },
  {
    "name": "Clifton",
    "lng": -87.9936615701645,
    "lat": 35.376736314571346
  },
  {
    "name": "Clifton",
    "lng": -87.93396224592794,
    "lat": 40.93446063620897
  },
  {
    "name": "Clifton",
    "lng": -83.82564936803124,
    "lat": 39.797002619031076
  },
  {
    "name": "Clifton",
    "lng": -77.38655620119552,
    "lat": 38.78022750756692
  },
  {
    "name": "Clifton",
    "lng": -74.15755195512493,
    "lat": 40.863160719747185
  },
  {
    "name": "Clifton",
    "lng": -68.50715461563028,
    "lat": 44.80454763510795
  },
  {
    "name": "Clifton Forge",
    "lng": -79.82492241823063,
    "lat": 37.82314273509582
  },
  {
    "name": "Clifton Hill",
    "lng": -92.66672553934507,
    "lat": 39.43930973024573
  },
  {
    "name": "Clifton Springs",
    "lng": -77.13475638640703,
    "lat": 42.960882897533295
  },
  {
    "name": "Climax",
    "lng": -96.81221028119484,
    "lat": 47.60951291607268
  },
  {
    "name": "Climax",
    "lng": -96.22403153933831,
    "lat": 37.719186520997624
  },
  {
    "name": "Climax",
    "lng": -85.33599340726676,
    "lat": 42.23829829759796
  },
  {
    "name": "Climax",
    "lng": -84.43135370092985,
    "lat": 30.875560100097832
  },
  {
    "name": "Climax Springs",
    "lng": -93.05060913895306,
    "lat": 38.1016093836338
  },
  {
    "name": "Clinchco",
    "lng": -82.3558736748609,
    "lat": 37.15823018857158
  },
  {
    "name": "Clinchport",
    "lng": -82.74517117494932,
    "lat": 36.67805325597495
  },
  {
    "name": "Clint",
    "lng": -106.22887954503172,
    "lat": 31.59009212370091
  },
  {
    "name": "Clinton",
    "lng": -112.06550296399011,
    "lat": 41.13952044597376
  },
  {
    "name": "Clinton",
    "lng": -102.3478802826916,
    "lat": 42.75972064327075
  },
  {
    "name": "Clinton",
    "lng": -98.97227146085548,
    "lat": 35.50597344964926
  },
  {
    "name": "Clinton",
    "lng": -98.94995562100021,
    "lat": 35.525522568906325
  },
  {
    "name": "Clinton",
    "lng": -96.44156784399675,
    "lat": 45.46335080147985
  },
  {
    "name": "Clinton",
    "lng": -96.43997888008677,
    "lat": 45.45586116375058
  },
  {
    "name": "Clinton",
    "lng": -93.76782904097159,
    "lat": 38.371548422019096
  },
  {
    "name": "Clinton",
    "lng": -92.45503669394105,
    "lat": 35.57969189829734
  },
  {
    "name": "Clinton",
    "lng": -92.44229737836541,
    "lat": 35.531265696724056
  },
  {
    "name": "Clinton",
    "lng": -92.44071689415749,
    "lat": 35.528591114629144
  },
  {
    "name": "Clinton",
    "lng": -92.43859881042819,
    "lat": 35.52616086126292
  },
  {
    "name": "Clinton",
    "lng": -92.43451151323514,
    "lat": 35.55057958578138
  },
  {
    "name": "Clinton",
    "lng": -92.41846605697181,
    "lat": 35.601505927396374
  },
  {
    "name": "Clinton",
    "lng": -91.01498410806157,
    "lat": 30.861353569324397
  },
  {
    "name": "Clinton",
    "lng": -90.34029002882708,
    "lat": 32.353965977314864
  },
  {
    "name": "Clinton",
    "lng": -90.2423321447545,
    "lat": 41.84291090165491
  },
  {
    "name": "Clinton",
    "lng": -88.99390226817785,
    "lat": 36.666288538419884
  },
  {
    "name": "Clinton",
    "lng": -88.96340420996557,
    "lat": 40.14687227886685
  },
  {
    "name": "Clinton",
    "lng": -88.86812893010256,
    "lat": 42.557178325552265
  },
  {
    "name": "Clinton",
    "lng": -87.40456082290054,
    "lat": 39.66067190682021
  },
  {
    "name": "Clinton",
    "lng": -84.12834157891086,
    "lat": 36.09860050937792
  },
  {
    "name": "Clinton",
    "lng": -83.96866112480615,
    "lat": 42.06991350895384
  },
  {
    "name": "Clinton",
    "lng": -81.87680938418633,
    "lat": 34.518365372864565
  },
  {
    "name": "Clinton",
    "lng": -81.87601061839197,
    "lat": 34.513489479521134
  },
  {
    "name": "Clinton",
    "lng": -81.86382719088951,
    "lat": 34.47732414310449
  },
  {
    "name": "Clinton",
    "lng": -81.63052024436254,
    "lat": 40.928443914887275
  },
  {
    "name": "Clinton",
    "lng": -78.33212299620078,
    "lat": 35.000743747614784
  },
  {
    "name": "Clinton",
    "lng": -78.32782801861325,
    "lat": 35.03384169914112
  },
  {
    "name": "Clinton",
    "lng": -78.32630613295485,
    "lat": 35.02154682174911
  },
  {
    "name": "Clinton",
    "lng": -78.30242610878462,
    "lat": 34.979776009422146
  },
  {
    "name": "Clinton",
    "lng": -75.37838935765302,
    "lat": 43.04900216501061
  },
  {
    "name": "Clinton",
    "lng": -74.91241896767383,
    "lat": 40.635624884100395
  },
  {
    "name": "Clinton",
    "lng": -72.52785164270304,
    "lat": 41.293657564696844
  },
  {
    "name": "Clinton",
    "lng": -71.6888241189137,
    "lat": 42.41191157711706
  },
  {
    "name": "Clinton",
    "lng": -69.53589607945534,
    "lat": 44.67022851604203
  },
  {
    "name": "Clintonville",
    "lng": -88.75109118912722,
    "lat": 44.62209294283964
  },
  {
    "name": "Clintwood",
    "lng": -82.45718167403044,
    "lat": 37.15096615322288
  },
  {
    "name": "Clio",
    "lng": -93.45139521700675,
    "lat": 40.63614472419048
  },
  {
    "name": "Clio",
    "lng": -85.61091225214193,
    "lat": 31.70855749771522
  },
  {
    "name": "Clio",
    "lng": -83.7354332600052,
    "lat": 43.17737316575853
  },
  {
    "name": "Clio",
    "lng": -79.54630287877868,
    "lat": 34.57926043593336
  },
  {
    "name": "Clitherall",
    "lng": -95.63099682348475,
    "lat": 46.274359089207465
  },
  {
    "name": "Clive",
    "lng": -93.79807338380886,
    "lat": 41.61471541668886
  },
  {
    "name": "Clontarf",
    "lng": -95.67691945492028,
    "lat": 45.37694446870293
  },
  {
    "name": "Cloquet",
    "lng": -92.49238031999683,
    "lat": 46.72211724864848
  },
  {
    "name": "Cloudcroft",
    "lng": -105.73324995644997,
    "lat": 32.95308975041036
  },
  {
    "name": "Clover",
    "lng": -81.22352694113204,
    "lat": 35.11161639731865
  },
  {
    "name": "Cloverdale",
    "lng": -123.02088214440927,
    "lat": 38.797770009542695
  },
  {
    "name": "Cloverdale",
    "lng": -122.99249765281007,
    "lat": 38.775024727645864
  },
  {
    "name": "Cloverdale",
    "lng": -86.79925905001645,
    "lat": 39.520779102875075
  },
  {
    "name": "Cloverdale",
    "lng": -84.30371541598723,
    "lat": 41.01930708926881
  },
  {
    "name": "Cloverport",
    "lng": -86.62846131419121,
    "lat": 37.8310711281415
  },
  {
    "name": "Clovis",
    "lng": -119.68957491343576,
    "lat": 36.94258787397167
  },
  {
    "name": "Clovis",
    "lng": -119.68688313388438,
    "lat": 36.82686508007164
  },
  {
    "name": "Clovis",
    "lng": -119.63921841185389,
    "lat": 36.820260291774346
  },
  {
    "name": "Clovis",
    "lng": -103.19154089538773,
    "lat": 34.43723314902859
  },
  {
    "name": "Clute",
    "lng": -95.39723317541838,
    "lat": 29.025750973582255
  },
  {
    "name": "Clute",
    "lng": -95.39417542385388,
    "lat": 29.005119611203792
  },
  {
    "name": "Clute",
    "lng": -95.38947964978206,
    "lat": 29.004135792904023
  },
  {
    "name": "Clutier",
    "lng": -92.40359571014126,
    "lat": 42.07934751327578
  },
  {
    "name": "Clyde",
    "lng": -97.40025914930824,
    "lat": 39.59185185339476
  },
  {
    "name": "Clyde",
    "lng": -94.66936219370213,
    "lat": 40.26549714778026
  },
  {
    "name": "Clyde",
    "lng": -82.97798808458168,
    "lat": 41.304946128159955
  },
  {
    "name": "Clyde",
    "lng": -82.91095790611672,
    "lat": 35.53309389270112
  },
  {
    "name": "Clyde",
    "lng": -76.8707147692639,
    "lat": 43.08394698122207
  },
  {
    "name": "Clyde Hill",
    "lng": -122.21797746172852,
    "lat": 47.630361073656225
  },
  {
    "name": "Clyde Park",
    "lng": -110.60523184206943,
    "lat": 45.88405966097698
  },
  {
    "name": "Clyman",
    "lng": -88.723333599503,
    "lat": 43.311573203961935
  },
  {
    "name": "Coachella",
    "lng": -116.20094002675485,
    "lat": 33.64566481267733
  },
  {
    "name": "Coachella",
    "lng": -116.14465840714581,
    "lat": 33.690935247299954
  },
  {
    "name": "Coachella",
    "lng": -116.12310942730201,
    "lat": 33.67612474105908
  },
  {
    "name": "Coahoma",
    "lng": -101.30872788019079,
    "lat": 32.29578001223099
  },
  {
    "name": "Coahoma",
    "lng": -90.52225656875777,
    "lat": 34.36610308830055
  },
  {
    "name": "Coal City",
    "lng": -88.29865345733143,
    "lat": 41.25315235360315
  },
  {
    "name": "Coal City",
    "lng": -88.27919092531107,
    "lat": 41.27794233905484
  },
  {
    "name": "Coal Creek",
    "lng": -105.1423619173087,
    "lat": 38.35952256988947
  },
  {
    "name": "Coal Grove",
    "lng": -82.64227898802682,
    "lat": 38.497725706963145
  },
  {
    "name": "Coal Hill",
    "lng": -93.66573147641947,
    "lat": 35.437209823294154
  },
  {
    "name": "Coal Hill",
    "lng": -93.66573028166044,
    "lat": 35.43721050087591
  },
  {
    "name": "Coal Run Village",
    "lng": -82.54954671079477,
    "lat": 37.51018937601765
  },
  {
    "name": "Coal Run Village",
    "lng": -82.54713657746024,
    "lat": 37.52287548647731
  },
  {
    "name": "Coal Valley",
    "lng": -90.47025249305672,
    "lat": 41.42180468874412
  },
  {
    "name": "Coal Valley",
    "lng": -90.44632148511457,
    "lat": 41.44163686013891
  },
  {
    "name": "Coaldale",
    "lng": -75.91609019642758,
    "lat": 40.819754630570614
  },
  {
    "name": "Coalgate",
    "lng": -96.21979735957024,
    "lat": 34.53267807906482
  },
  {
    "name": "Coaling",
    "lng": -87.35325020206126,
    "lat": 33.16416827562882
  },
  {
    "name": "Coalinga",
    "lng": -120.3537869590779,
    "lat": 36.14572930296109
  },
  {
    "name": "Coalinga",
    "lng": -120.29535285612063,
    "lat": 36.16219698156235
  },
  {
    "name": "Coalinga",
    "lng": -120.24505723659212,
    "lat": 36.12987107481984
  },
  {
    "name": "Coalmont",
    "lng": -85.71147726684978,
    "lat": 35.34466059188746
  },
  {
    "name": "Coalton",
    "lng": -89.30296177408309,
    "lat": 39.28479175501535
  },
  {
    "name": "Coalton",
    "lng": -82.61081065100345,
    "lat": 39.11175251058024
  },
  {
    "name": "Coalville",
    "lng": -111.41282673020613,
    "lat": 40.912072527821394
  },
  {
    "name": "Coates",
    "lng": -93.03212532178712,
    "lat": 44.716057064351446
  },
  {
    "name": "Coatesville",
    "lng": -86.66977481746055,
    "lat": 39.688109512091216
  },
  {
    "name": "Coatesville",
    "lng": -75.8199356409843,
    "lat": 39.98501507223963
  },
  {
    "name": "Coats",
    "lng": -98.82497195474008,
    "lat": 37.51121073248969
  },
  {
    "name": "Coats",
    "lng": -78.679150288099,
    "lat": 35.43355043877414
  },
  {
    "name": "Coats",
    "lng": -78.66834667404096,
    "lat": 35.406716062800704
  },
  {
    "name": "Coatsburg",
    "lng": -91.16037095937087,
    "lat": 40.03245576184932
  },
  {
    "name": "Cobalt",
    "lng": -90.28759372644937,
    "lat": 37.54547742261838
  },
  {
    "name": "Cobb",
    "lng": -90.32901230644997,
    "lat": 42.96641996954916
  },
  {
    "name": "Cobbtown",
    "lng": -82.13870700225941,
    "lat": 32.28057639094781
  },
  {
    "name": "Cobden",
    "lng": -94.8465309617965,
    "lat": 44.28252499064329
  },
  {
    "name": "Cobden",
    "lng": -89.25455567282422,
    "lat": 37.53357378517624
  },
  {
    "name": "Cobleskill",
    "lng": -74.48649593440044,
    "lat": 42.67992372192468
  },
  {
    "name": "Coburg",
    "lng": -95.26478810318766,
    "lat": 40.91889928024233
  },
  {
    "name": "Cochran",
    "lng": -83.35245699744081,
    "lat": 32.3884607421544
  },
  {
    "name": "Cochran",
    "lng": -83.33983265198323,
    "lat": 32.37347386765061
  },
  {
    "name": "Cochrane",
    "lng": -91.8383129331775,
    "lat": 44.22790635431132
  },
  {
    "name": "Cockrell Hill",
    "lng": -96.88853873919996,
    "lat": 32.7381939066962
  },
  {
    "name": "Cocoa West",
    "lng": -80.77129786067047,
    "lat": 28.359431746144853
  },
  {
    "name": "Cody",
    "lng": -109.05501579481192,
    "lat": 44.521187344360314
  },
  {
    "name": "Cody",
    "lng": -101.24891869168631,
    "lat": 42.93775052402547
  },
  {
    "name": "Coeburn",
    "lng": -82.46952411096821,
    "lat": 36.94451741445342
  },
  {
    "name": "Coeur d'Alene",
    "lng": -116.79327219923239,
    "lat": 47.70400003475676
  },
  {
    "name": "Coffee City",
    "lng": -95.49117245540398,
    "lat": 32.13332287009619
  },
  {
    "name": "Coffee Springs",
    "lng": -85.91030858610303,
    "lat": 31.166401148194716
  },
  {
    "name": "Coffeen",
    "lng": -89.38977167179281,
    "lat": 39.08824989040437
  },
  {
    "name": "Coffeeville",
    "lng": -89.68448175908631,
    "lat": 33.9764945505123
  },
  {
    "name": "Coffeeville",
    "lng": -88.08604697778776,
    "lat": 31.76656075722664
  },
  {
    "name": "Coffey",
    "lng": -94.00653266909676,
    "lat": 40.105554694280315
  },
  {
    "name": "Coffeyville",
    "lng": -95.6301265323353,
    "lat": 37.040875609501846
  },
  {
    "name": "Coffeyville",
    "lng": -95.58110525492476,
    "lat": 37.09485204557364
  },
  {
    "name": "Coffeyville",
    "lng": -95.58068710365684,
    "lat": 37.05121238783967
  },
  {
    "name": "Coffman Cove",
    "lng": -132.8377147523989,
    "lat": 56.00821370586785
  },
  {
    "name": "Cofield",
    "lng": -76.9105169736376,
    "lat": 36.35669060633718
  },
  {
    "name": "Coggon",
    "lng": -91.53034801901532,
    "lat": 42.27851001819459
  },
  {
    "name": "Cogswell",
    "lng": -97.78444084725193,
    "lat": 46.10686469564853
  },
  {
    "name": "Cohasset",
    "lng": -93.63959811947285,
    "lat": 47.239072202103266
  },
  {
    "name": "Cohocton",
    "lng": -77.50000621184488,
    "lat": 42.4999309648533
  },
  {
    "name": "Cohutta",
    "lng": -84.95528203470616,
    "lat": 34.95418508182511
  },
  {
    "name": "Coin",
    "lng": -95.23497914325412,
    "lat": 40.65582246388384
  },
  {
    "name": "Cokato",
    "lng": -94.18788202738483,
    "lat": 45.07697318291456
  },
  {
    "name": "Cokedale",
    "lng": -104.62158394447036,
    "lat": 37.14422862945915
  },
  {
    "name": "Coker",
    "lng": -87.68021620743394,
    "lat": 33.244811595493296
  },
  {
    "name": "Cokeville",
    "lng": -110.9692362123346,
    "lat": 42.04531560828349
  },
  {
    "name": "Cokeville",
    "lng": -110.95507100478726,
    "lat": 42.0844658775917
  },
  {
    "name": "Colbert",
    "lng": -96.50447418160017,
    "lat": 33.85711744217529
  },
  {
    "name": "Colbert",
    "lng": -83.21385054699182,
    "lat": 34.037044308363505
  },
  {
    "name": "Colby",
    "lng": -101.06461306511977,
    "lat": 39.40365279768075
  },
  {
    "name": "Colby",
    "lng": -101.04757947325652,
    "lat": 39.38674622211657
  },
  {
    "name": "Colby",
    "lng": -101.00850934144603,
    "lat": 39.35835830234428
  },
  {
    "name": "Colby",
    "lng": -90.31590729024548,
    "lat": 44.911928368074314
  },
  {
    "name": "Colchester",
    "lng": -90.79222684612321,
    "lat": 40.42542544192148
  },
  {
    "name": "Colchester",
    "lng": -72.34745250406735,
    "lat": 41.561732707301644
  },
  {
    "name": "Colcord",
    "lng": -94.69210195492134,
    "lat": 36.264244353747806
  },
  {
    "name": "Cold Bay",
    "lng": -162.74702229619467,
    "lat": 55.213119379609765
  },
  {
    "name": "Cold Brook",
    "lng": -75.03859749622116,
    "lat": 43.24002575161684
  },
  {
    "name": "Cold Spring",
    "lng": -94.43188522844049,
    "lat": 45.45535746104984
  },
  {
    "name": "Cold Spring",
    "lng": -94.42234965662983,
    "lat": 45.47403819598221
  },
  {
    "name": "Cold Spring",
    "lng": -94.4171329657501,
    "lat": 45.446379491747315
  },
  {
    "name": "Cold Spring",
    "lng": -94.4140536733014,
    "lat": 45.456951228568286
  },
  {
    "name": "Cold Spring",
    "lng": -84.4351635003212,
    "lat": 39.01285782369585
  },
  {
    "name": "Cold Spring",
    "lng": -73.9544765607122,
    "lat": 41.4190365508521
  },
  {
    "name": "Coldspring",
    "lng": -95.1307177627683,
    "lat": 30.589777789348886
  },
  {
    "name": "Coldstream",
    "lng": -85.52578855680486,
    "lat": 38.314406477657656
  },
  {
    "name": "Coldwater",
    "lng": -99.33758540780559,
    "lat": 37.25877704346372
  },
  {
    "name": "Coldwater",
    "lng": -89.97572061015315,
    "lat": 34.69024487839264
  },
  {
    "name": "Coldwater",
    "lng": -89.96012996248295,
    "lat": 34.705567082637124
  },
  {
    "name": "Coldwater",
    "lng": -85.00302869966445,
    "lat": 41.9406884520395
  },
  {
    "name": "Coldwater",
    "lng": -84.98115701730192,
    "lat": 41.988108048373434
  },
  {
    "name": "Coldwater",
    "lng": -84.96845579393927,
    "lat": 41.92843765850568
  },
  {
    "name": "Coldwater",
    "lng": -84.96434310327713,
    "lat": 41.97825910117166
  },
  {
    "name": "Coldwater",
    "lng": -84.63166174454685,
    "lat": 40.48334077222328
  },
  {
    "name": "Cole",
    "lng": -97.56619919553036,
    "lat": 35.09929910274729
  },
  {
    "name": "Cole Camp",
    "lng": -93.2029372709019,
    "lat": 38.45949350988619
  },
  {
    "name": "Colebrook",
    "lng": -73.0808416439442,
    "lat": 42.001537947572935
  },
  {
    "name": "Coleharbor",
    "lng": -101.22118537233983,
    "lat": 47.54245710950302
  },
  {
    "name": "Coleman",
    "lng": -99.42231289048816,
    "lat": 31.831635236457956
  },
  {
    "name": "Coleman",
    "lng": -88.03480191894985,
    "lat": 45.06949538267825
  },
  {
    "name": "Coleman",
    "lng": -84.58709249972904,
    "lat": 43.75880369307471
  },
  {
    "name": "Colerain",
    "lng": -76.76632233989613,
    "lat": 36.201604942627974
  },
  {
    "name": "Coleraine",
    "lng": -93.4524042927928,
    "lat": 47.26393150777076
  },
  {
    "name": "Coleridge",
    "lng": -97.20277865691084,
    "lat": 42.506571199130136
  },
  {
    "name": "Colesburg",
    "lng": -91.20111411262106,
    "lat": 42.638764549643305
  },
  {
    "name": "Coleta",
    "lng": -89.80185828470238,
    "lat": 41.902536148745305
  },
  {
    "name": "Colfax",
    "lng": -120.95429416705382,
    "lat": 39.094457669256606
  },
  {
    "name": "Colfax",
    "lng": -120.94081962881732,
    "lat": 39.0920605974421
  },
  {
    "name": "Colfax",
    "lng": -120.93820004432534,
    "lat": 39.082810209006624
  },
  {
    "name": "Colfax",
    "lng": -117.33854667772258,
    "lat": 46.9032349184568
  },
  {
    "name": "Colfax",
    "lng": -96.87182082288766,
    "lat": 46.47008607563514
  },
  {
    "name": "Colfax",
    "lng": -93.25435483847207,
    "lat": 41.692349715488405
  },
  {
    "name": "Colfax",
    "lng": -93.24768824720083,
    "lat": 41.695624649387476
  },
  {
    "name": "Colfax",
    "lng": -93.2455119225752,
    "lat": 41.696177196019455
  },
  {
    "name": "Colfax",
    "lng": -93.23842719354317,
    "lat": 41.67993218019155
  },
  {
    "name": "Colfax",
    "lng": -92.70020293181142,
    "lat": 31.520827653077383
  },
  {
    "name": "Colfax",
    "lng": -91.72618203749101,
    "lat": 44.99741108146267
  },
  {
    "name": "Colfax",
    "lng": -88.6160983226902,
    "lat": 40.5665836563258
  },
  {
    "name": "Colfax",
    "lng": -86.66791987521532,
    "lat": 40.194297303072645
  },
  {
    "name": "Colfax",
    "lng": -86.6679179116467,
    "lat": 40.1942987957992
  },
  {
    "name": "Collbran",
    "lng": -107.9665635402719,
    "lat": 39.238942166412336
  },
  {
    "name": "Collbran",
    "lng": -107.95445915733566,
    "lat": 39.2467080996484
  },
  {
    "name": "Collbran",
    "lng": -107.95445915733563,
    "lat": 39.246708099648394
  },
  {
    "name": "College Corner",
    "lng": -84.81107330506488,
    "lat": 39.5680411537641
  },
  {
    "name": "College Park",
    "lng": -84.46278466923752,
    "lat": 33.6370283696136
  },
  {
    "name": "College Place",
    "lng": -118.4152438389341,
    "lat": 46.03492935180591
  },
  {
    "name": "College Place",
    "lng": -118.41166609993546,
    "lat": 46.03178304610294
  },
  {
    "name": "College Place",
    "lng": -118.38398155875736,
    "lat": 46.044022205222134
  },
  {
    "name": "College Springs",
    "lng": -95.12099553493736,
    "lat": 40.62131378106124
  },
  {
    "name": "Collegedale",
    "lng": -85.04909309988784,
    "lat": 35.0489424389072
  },
  {
    "name": "Colleyville",
    "lng": -97.1485966269543,
    "lat": 32.891341342755794
  },
  {
    "name": "Collierville",
    "lng": -89.69305405497346,
    "lat": 35.05617064674922
  },
  {
    "name": "Collins",
    "lng": -93.62235830674052,
    "lat": 37.88910952654224
  },
  {
    "name": "Collins",
    "lng": -93.3082495914277,
    "lat": 41.90191427825879
  },
  {
    "name": "Collins",
    "lng": -89.56693201532369,
    "lat": 31.646972613389558
  },
  {
    "name": "Collins",
    "lng": -82.11029390463513,
    "lat": 32.17936178246539
  },
  {
    "name": "Collinston",
    "lng": -91.87180366101161,
    "lat": 32.68926994350112
  },
  {
    "name": "Collinsville",
    "lng": -96.90759236778344,
    "lat": 33.559216371747574
  },
  {
    "name": "Collinsville",
    "lng": -95.89754845916622,
    "lat": 36.36353994216899
  },
  {
    "name": "Collinsville",
    "lng": -95.86004420510993,
    "lat": 36.38171992240262
  },
  {
    "name": "Collinsville",
    "lng": -95.85921395141024,
    "lat": 36.37309248523821
  },
  {
    "name": "Collinsville",
    "lng": -95.81983221509837,
    "lat": 36.375728681159075
  },
  {
    "name": "Collinsville",
    "lng": -95.81907246141401,
    "lat": 36.38707114072224
  },
  {
    "name": "Collinsville",
    "lng": -95.81425993616098,
    "lat": 36.37791653554138
  },
  {
    "name": "Collinsville",
    "lng": -90.00585773804437,
    "lat": 38.67693584083765
  },
  {
    "name": "Collinsville",
    "lng": -85.86315211698167,
    "lat": 34.2673593870063
  },
  {
    "name": "Collinsville",
    "lng": -72.92214260195587,
    "lat": 41.81899123500047
  },
  {
    "name": "Collinwood",
    "lng": -87.74346376666108,
    "lat": 35.174668193507586
  },
  {
    "name": "Collyer",
    "lng": -100.1184460243587,
    "lat": 39.03726473076201
  },
  {
    "name": "Colman",
    "lng": -96.81491866461165,
    "lat": 43.98346252143203
  },
  {
    "name": "Colman",
    "lng": -96.78989221336646,
    "lat": 43.99454418130144
  },
  {
    "name": "Colmar Manor",
    "lng": -76.94380885942311,
    "lat": 38.93034523857395
  },
  {
    "name": "Colmesneil",
    "lng": -94.42206799431442,
    "lat": 30.90964772126418
  },
  {
    "name": "Colo",
    "lng": -93.31855882560784,
    "lat": 42.01450018204079
  },
  {
    "name": "Cologne",
    "lng": -93.8138032087465,
    "lat": 44.767161960274734
  },
  {
    "name": "Cologne",
    "lng": -93.80092818707666,
    "lat": 44.76741840511173
  },
  {
    "name": "Cologne",
    "lng": -93.79245438521893,
    "lat": 44.76969681441471
  },
  {
    "name": "Coloma",
    "lng": -89.56721911745692,
    "lat": 44.060584178847314
  },
  {
    "name": "Coloma",
    "lng": -89.52233678935768,
    "lat": 44.03342377941448
  },
  {
    "name": "Coloma",
    "lng": -89.51517850243717,
    "lat": 44.01625362209252
  },
  {
    "name": "Coloma",
    "lng": -86.30833224145219,
    "lat": 42.18647602292401
  },
  {
    "name": "Colome",
    "lng": -99.71687010602577,
    "lat": 43.259665725705695
  },
  {
    "name": "Colon",
    "lng": -96.60694543495435,
    "lat": 41.29805926174782
  },
  {
    "name": "Colon",
    "lng": -85.32299179829977,
    "lat": 41.95909430873418
  },
  {
    "name": "Colona",
    "lng": -90.34457360777665,
    "lat": 41.466727463186615
  },
  {
    "name": "Colona",
    "lng": -90.34441804885411,
    "lat": 41.48789682926203
  },
  {
    "name": "Colonial Beach",
    "lng": -76.97844907063605,
    "lat": 38.256496610028975
  },
  {
    "name": "Colonial Heights",
    "lng": -77.39664143482092,
    "lat": 37.265066019130764
  },
  {
    "name": "Colonial Park",
    "lng": -76.80672323836079,
    "lat": 40.29967020188304
  },
  {
    "name": "Colonie",
    "lng": -73.83333703586538,
    "lat": 42.719911058640626
  },
  {
    "name": "Colony",
    "lng": -98.67131214732373,
    "lat": 35.348619776668755
  },
  {
    "name": "Colony",
    "lng": -95.36273699867645,
    "lat": 38.07114468035773
  },
  {
    "name": "Colony",
    "lng": -86.91888704800381,
    "lat": 33.93093188148638
  },
  {
    "name": "Colony",
    "lng": -86.89510678551054,
    "lat": 33.94511858366782
  },
  {
    "name": "Colony",
    "lng": -86.89492858823387,
    "lat": 33.94498564393503
  },
  {
    "name": "Colony",
    "lng": -86.8867543454671,
    "lat": 33.95848909464621
  },
  {
    "name": "Colony",
    "lng": -86.8867543454671,
    "lat": 33.95848909464622
  },
  {
    "name": "Colony",
    "lng": -86.87160379061808,
    "lat": 33.947671567475155
  },
  {
    "name": "Colorado",
    "lng": -105.5477598393377,
    "lat": 39.02686847321781
  },
  {
    "name": "Colorado City",
    "lng": -112.98292153164985,
    "lat": 36.977372098400664
  },
  {
    "name": "Colorado City",
    "lng": -100.85830106935235,
    "lat": 32.39948595187461
  },
  {
    "name": "Colorado River Indian Tribes",
    "lng": -114.38294071652643,
    "lat": 33.952194396939205
  },
  {
    "name": "Colorado Springs",
    "lng": -104.76046479751298,
    "lat": 38.86745432058329
  },
  {
    "name": "Colp",
    "lng": -89.07860122136539,
    "lat": 37.80742749791541
  },
  {
    "name": "Colquitt",
    "lng": -84.73176508273214,
    "lat": 31.17425826424523
  },
  {
    "name": "Colrain",
    "lng": -72.71679738472959,
    "lat": 42.683370049025356
  },
  {
    "name": "Colstrip",
    "lng": -106.62765255283095,
    "lat": 45.89604646512036
  },
  {
    "name": "Colt",
    "lng": -90.81317650630223,
    "lat": 35.132489624421126
  },
  {
    "name": "Colton",
    "lng": -117.32557736085303,
    "lat": 34.05383816463959
  },
  {
    "name": "Colton",
    "lng": -117.12829522224102,
    "lat": 46.56777159704524
  },
  {
    "name": "Colton",
    "lng": -96.9280466613279,
    "lat": 43.78706157469838
  },
  {
    "name": "Columbia",
    "lng": -98.31087627736915,
    "lat": 45.61439248829022
  },
  {
    "name": "Columbia",
    "lng": -92.38736510023985,
    "lat": 38.90885155845625
  },
  {
    "name": "Columbia",
    "lng": -92.32674327783738,
    "lat": 38.948159305475315
  },
  {
    "name": "Columbia",
    "lng": -92.07692005174177,
    "lat": 32.10251097352528
  },
  {
    "name": "Columbia",
    "lng": -90.21560748276374,
    "lat": 38.458132097746756
  },
  {
    "name": "Columbia",
    "lng": -89.82846230996859,
    "lat": 31.25781217875582
  },
  {
    "name": "Columbia",
    "lng": -87.04902133865635,
    "lat": 35.62085844369884
  },
  {
    "name": "Columbia",
    "lng": -87.01942932331481,
    "lat": 35.690644094856495
  },
  {
    "name": "Columbia",
    "lng": -87.00906765570701,
    "lat": 35.689735693403215
  },
  {
    "name": "Columbia",
    "lng": -85.30785836470172,
    "lat": 37.10351534382001
  },
  {
    "name": "Columbia",
    "lng": -85.29959055571925,
    "lat": 37.08189513901528
  },
  {
    "name": "Columbia",
    "lng": -85.28396645128221,
    "lat": 37.100799075649164
  },
  {
    "name": "Columbia",
    "lng": -85.11030805754761,
    "lat": 31.296772983137398
  },
  {
    "name": "Columbia",
    "lng": -81.15627125137016,
    "lat": 34.0961939493309
  },
  {
    "name": "Columbia",
    "lng": -81.15531399727412,
    "lat": 34.09743905702616
  },
  {
    "name": "Columbia",
    "lng": -81.1400910506472,
    "lat": 34.08322939456194
  },
  {
    "name": "Columbia",
    "lng": -81.10782581601438,
    "lat": 34.02900040836437
  },
  {
    "name": "Columbia",
    "lng": -81.03802879139772,
    "lat": 34.07241464946345
  },
  {
    "name": "Columbia",
    "lng": -81.0089257107213,
    "lat": 34.06516871609376
  },
  {
    "name": "Columbia",
    "lng": -80.99761047654265,
    "lat": 34.04709005903251
  },
  {
    "name": "Columbia",
    "lng": -80.98070675433884,
    "lat": 34.08713722480621
  },
  {
    "name": "Columbia",
    "lng": -80.90208827984392,
    "lat": 34.03492608783932
  },
  {
    "name": "Columbia",
    "lng": -80.88848340174856,
    "lat": 34.078581370456085
  },
  {
    "name": "Columbia",
    "lng": -80.84457809883041,
    "lat": 34.11459157554875
  },
  {
    "name": "Columbia",
    "lng": -80.838153149441,
    "lat": 34.11093724610329
  },
  {
    "name": "Columbia",
    "lng": -80.83563073098146,
    "lat": 34.122095935538574
  },
  {
    "name": "Columbia",
    "lng": -80.8350389236437,
    "lat": 34.122745002349184
  },
  {
    "name": "Columbia",
    "lng": -80.83486979918112,
    "lat": 34.1233484291701
  },
  {
    "name": "Columbia",
    "lng": -80.8230109089504,
    "lat": 34.10820775658282
  },
  {
    "name": "Columbia",
    "lng": -78.16383092822116,
    "lat": 37.753321301346446
  },
  {
    "name": "Columbia",
    "lng": -76.2498825417119,
    "lat": 35.91228867829885
  },
  {
    "name": "Columbia",
    "lng": -76.24916031510855,
    "lat": 35.9131251832055
  },
  {
    "name": "Columbia",
    "lng": -76.2490799805823,
    "lat": 35.91167082818186
  },
  {
    "name": "Columbia",
    "lng": -76.24870987263003,
    "lat": 35.924028752849864
  },
  {
    "name": "Columbia",
    "lng": -76.24800810918462,
    "lat": 35.90858519426729
  },
  {
    "name": "Columbia",
    "lng": -76.24800434627521,
    "lat": 35.92452576765575
  },
  {
    "name": "Columbia",
    "lng": -76.24774966261847,
    "lat": 35.923740528900815
  },
  {
    "name": "Columbia",
    "lng": -76.24689094624503,
    "lat": 35.909817274937254
  },
  {
    "name": "Columbia",
    "lng": -76.24536810360995,
    "lat": 35.91277586129354
  },
  {
    "name": "Columbia",
    "lng": -76.24511600993121,
    "lat": 35.9214929418544
  },
  {
    "name": "Columbia",
    "lng": -76.24335369649138,
    "lat": 35.9134669313134
  },
  {
    "name": "Columbia",
    "lng": -76.22938986751194,
    "lat": 35.91558063370428
  },
  {
    "name": "Columbia",
    "lng": -72.30720772570756,
    "lat": 41.693886877157716
  },
  {
    "name": "Columbia",
    "lng": -67.8145005826708,
    "lat": 44.67011919231233
  },
  {
    "name": "Columbia City",
    "lng": -85.48547729226271,
    "lat": 41.16124089413242
  },
  {
    "name": "Columbia Falls",
    "lng": -114.19183713717233,
    "lat": 48.369658689706206
  },
  {
    "name": "Columbia Falls",
    "lng": -67.71869064256835,
    "lat": 44.685448210194984
  },
  {
    "name": "Columbia Heights",
    "lng": -93.24719546301911,
    "lat": 45.048409483820905
  },
  {
    "name": "Columbia Village",
    "lng": -117.15951304651632,
    "lat": 46.72535284072866
  },
  {
    "name": "Columbiana",
    "lng": -86.60639859704449,
    "lat": 33.19312325195156
  },
  {
    "name": "Columbiana",
    "lng": -86.5632255631928,
    "lat": 33.200656465473365
  },
  {
    "name": "Columbiana",
    "lng": -80.67452476267766,
    "lat": 40.88675414575266
  },
  {
    "name": "Columbiaville",
    "lng": -83.4071831295572,
    "lat": 43.15721020330214
  },
  {
    "name": "Columbine Valley",
    "lng": -105.0348590598222,
    "lat": 39.59937343547244
  },
  {
    "name": "Columbus",
    "lng": -109.24862635464922,
    "lat": 45.63514071490607
  },
  {
    "name": "Columbus",
    "lng": -107.63993485301792,
    "lat": 31.824863500127208
  },
  {
    "name": "Columbus",
    "lng": -102.78129666213155,
    "lat": 48.90492955060454
  },
  {
    "name": "Columbus",
    "lng": -97.35552307033434,
    "lat": 41.435420236570884
  },
  {
    "name": "Columbus",
    "lng": -96.55647889600252,
    "lat": 29.705617276619716
  },
  {
    "name": "Columbus",
    "lng": -94.84413890069726,
    "lat": 37.172271258236556
  },
  {
    "name": "Columbus",
    "lng": -94.81766035348178,
    "lat": 37.17110427441195
  },
  {
    "name": "Columbus",
    "lng": -93.0808971583861,
    "lat": 45.26858044460079
  },
  {
    "name": "Columbus",
    "lng": -91.14563545560566,
    "lat": 39.98822261985546
  },
  {
    "name": "Columbus",
    "lng": -89.10162815441466,
    "lat": 36.75902367172477
  },
  {
    "name": "Columbus",
    "lng": -89.02983467088958,
    "lat": 43.33545200400893
  },
  {
    "name": "Columbus",
    "lng": -85.91966248682658,
    "lat": 39.20929899842874
  },
  {
    "name": "Columbus",
    "lng": -84.87630108224454,
    "lat": 32.509943637353864
  },
  {
    "name": "Columbus",
    "lng": -82.98494342509774,
    "lat": 39.986182269064045
  },
  {
    "name": "Columbus",
    "lng": -82.20102913018448,
    "lat": 35.24914106757637
  },
  {
    "name": "Columbus",
    "lng": -82.19341975539821,
    "lat": 35.28641714052411
  },
  {
    "name": "Columbus",
    "lng": -82.18664177038184,
    "lat": 35.257815224576476
  },
  {
    "name": "Columbus",
    "lng": -82.18313063423882,
    "lat": 35.26363745932452
  },
  {
    "name": "Columbus",
    "lng": -82.18215705396373,
    "lat": 35.263794369649915
  },
  {
    "name": "Columbus",
    "lng": -79.58349927118054,
    "lat": 41.94787033103104
  },
  {
    "name": "Columbus City",
    "lng": -91.37476168731884,
    "lat": 41.25913858443268
  },
  {
    "name": "Columbus Grove",
    "lng": -84.05929155834193,
    "lat": 40.91861788099503
  },
  {
    "name": "Columbus Junction",
    "lng": -91.36407893187508,
    "lat": 41.2791217615402
  },
  {
    "name": "Colusa",
    "lng": -122.00959208558687,
    "lat": 39.20493675412768
  },
  {
    "name": "Colville",
    "lng": -117.89874926398817,
    "lat": 48.5453600809621
  },
  {
    "name": "Colwell",
    "lng": -92.59166212795196,
    "lat": 43.157190777230795
  },
  {
    "name": "Colwich",
    "lng": -97.5693349560318,
    "lat": 37.77201121422212
  },
  {
    "name": "Colwich",
    "lng": -97.53613457306008,
    "lat": 37.781869464300335
  },
  {
    "name": "Comanche",
    "lng": -97.95562053425598,
    "lat": 34.3664223675391
  },
  {
    "name": "Comanche",
    "lng": -97.94861250476151,
    "lat": 34.35976374595537
  },
  {
    "name": "Combes",
    "lng": -97.72536645634835,
    "lat": 26.243903505446042
  },
  {
    "name": "Combine",
    "lng": -96.54349967086371,
    "lat": 32.60414792318973
  },
  {
    "name": "Combine",
    "lng": -96.53214200699507,
    "lat": 32.61863833127449
  },
  {
    "name": "Combine",
    "lng": -96.53180150574441,
    "lat": 32.607433075869885
  },
  {
    "name": "Combine",
    "lng": -96.51780332957742,
    "lat": 32.58911140399184
  },
  {
    "name": "Combined Locks",
    "lng": -88.30642735577098,
    "lat": 44.26416052739915
  },
  {
    "name": "Comer",
    "lng": -83.12634166051282,
    "lat": 34.06250015250051
  },
  {
    "name": "Comfrey",
    "lng": -94.90284685370811,
    "lat": 44.111064681872534
  },
  {
    "name": "Commerce",
    "lng": -95.89917633243789,
    "lat": 33.24205194664043
  },
  {
    "name": "Commerce",
    "lng": -94.87053687584782,
    "lat": 36.93161954107701
  },
  {
    "name": "Commerce",
    "lng": -94.86793773618133,
    "lat": 36.93938884633092
  },
  {
    "name": "Commerce",
    "lng": -89.4466281792157,
    "lat": 37.158281858158965
  },
  {
    "name": "Commerce",
    "lng": -83.47295712234416,
    "lat": 34.21289544550972
  },
  {
    "name": "Commerce City",
    "lng": -104.93349336983496,
    "lat": 39.85677088880466
  },
  {
    "name": "Commerce City",
    "lng": -104.92319968809925,
    "lat": 39.83750693351603
  },
  {
    "name": "Commerce City",
    "lng": -104.92232136081881,
    "lat": 39.83974299491014
  },
  {
    "name": "Commerce City",
    "lng": -104.9220754566058,
    "lat": 39.83890602030063
  },
  {
    "name": "Commerce City",
    "lng": -104.92164317207158,
    "lat": 39.83995539207629
  },
  {
    "name": "Commerce City",
    "lng": -104.91940600775462,
    "lat": 39.85753278046755
  },
  {
    "name": "Commerce City",
    "lng": -104.91824012564086,
    "lat": 39.84126067722261
  },
  {
    "name": "Commerce City",
    "lng": -104.916526548457,
    "lat": 39.86277500924275
  },
  {
    "name": "Commerce City",
    "lng": -104.91637863965339,
    "lat": 39.82885361917152
  },
  {
    "name": "Commerce City",
    "lng": -104.91634607330872,
    "lat": 39.83356505013511
  },
  {
    "name": "Commerce City",
    "lng": -104.91567682712484,
    "lat": 39.83019096039424
  },
  {
    "name": "Commerce City",
    "lng": -104.91315447618659,
    "lat": 39.83380223815247
  },
  {
    "name": "Commerce City",
    "lng": -104.91294753945256,
    "lat": 39.83243666288919
  },
  {
    "name": "Commerce City",
    "lng": -104.91260782023933,
    "lat": 39.83336360881126
  },
  {
    "name": "Commerce City",
    "lng": -104.91260782023929,
    "lat": 39.83336360881125
  },
  {
    "name": "Commerce City",
    "lng": -104.91236705781002,
    "lat": 39.83271811321438
  },
  {
    "name": "Commerce City",
    "lng": -104.91215701357561,
    "lat": 39.836069025257174
  },
  {
    "name": "Commerce City",
    "lng": -104.91205141956634,
    "lat": 39.83429229286854
  },
  {
    "name": "Commerce City",
    "lng": -104.91148867561718,
    "lat": 39.833058799927876
  },
  {
    "name": "Commerce City",
    "lng": -104.91148150428442,
    "lat": 39.833435471743776
  },
  {
    "name": "Commerce City",
    "lng": -104.91142215017767,
    "lat": 39.83390447512704
  },
  {
    "name": "Commerce City",
    "lng": -104.91043590887496,
    "lat": 39.83396206663924
  },
  {
    "name": "Commerce City",
    "lng": -104.9100783782541,
    "lat": 39.83333751275488
  },
  {
    "name": "Commerce City",
    "lng": -104.90982131622548,
    "lat": 39.83766916383815
  },
  {
    "name": "Commerce City",
    "lng": -104.90612800463273,
    "lat": 39.840570802808806
  },
  {
    "name": "Commerce City",
    "lng": -104.90311330050866,
    "lat": 39.841114127287135
  },
  {
    "name": "Commerce City",
    "lng": -104.90214232093344,
    "lat": 39.845441324631594
  },
  {
    "name": "Commerce City",
    "lng": -104.90103606558743,
    "lat": 39.8450791023003
  },
  {
    "name": "Commerce City",
    "lng": -104.84555265083647,
    "lat": 39.8632727408329
  },
  {
    "name": "Commerce City",
    "lng": -104.66869635459135,
    "lat": 39.91045705009688
  },
  {
    "name": "Commercial Point",
    "lng": -83.03804434830403,
    "lat": 39.78037318141968
  },
  {
    "name": "Como",
    "lng": -95.47561845245535,
    "lat": 33.06002679984938
  },
  {
    "name": "Como",
    "lng": -89.9387654490432,
    "lat": 34.513507954066874
  },
  {
    "name": "Como",
    "lng": -77.00731338382806,
    "lat": 36.50106018473148
  },
  {
    "name": "Compton",
    "lng": -118.2274041789067,
    "lat": 33.89300627630975
  },
  {
    "name": "Compton",
    "lng": -89.08608692878947,
    "lat": 41.69402447776258
  },
  {
    "name": "Comstock",
    "lng": -99.24301598365287,
    "lat": 41.55722054354235
  },
  {
    "name": "Comstock",
    "lng": -96.74615131892193,
    "lat": 46.6595462133835
  },
  {
    "name": "Conception Junction",
    "lng": -94.6913628492058,
    "lat": 40.26838340637236
  },
  {
    "name": "Conconully",
    "lng": -119.7575236584959,
    "lat": 48.55430937702063
  },
  {
    "name": "Conconully",
    "lng": -119.75158489227242,
    "lat": 48.55961785199238
  },
  {
    "name": "Concord",
    "lng": -122.00161417124876,
    "lat": 37.972216108259495
  },
  {
    "name": "Concord",
    "lng": -96.98911837633142,
    "lat": 42.3838113323299
  },
  {
    "name": "Concord",
    "lng": -91.85074310716212,
    "lat": 35.6623741425987
  },
  {
    "name": "Concord",
    "lng": -90.37094820073187,
    "lat": 39.81597004657667
  },
  {
    "name": "Concord",
    "lng": -84.64543026964105,
    "lat": 42.1762038568068
  },
  {
    "name": "Concord",
    "lng": -84.43840270003389,
    "lat": 33.09176692685709
  },
  {
    "name": "Concord",
    "lng": -83.49146998256946,
    "lat": 38.68753405624671
  },
  {
    "name": "Concord",
    "lng": -80.76423262929607,
    "lat": 35.44035724363183
  },
  {
    "name": "Concord",
    "lng": -80.7523853880899,
    "lat": 35.40310621880785
  },
  {
    "name": "Concord",
    "lng": -80.74658534992572,
    "lat": 35.432126614433585
  },
  {
    "name": "Concord",
    "lng": -80.74093609002098,
    "lat": 35.418469305361455
  },
  {
    "name": "Concord",
    "lng": -80.71365182330376,
    "lat": 35.407919589767474
  },
  {
    "name": "Concord",
    "lng": -80.70774058556684,
    "lat": 35.35809306198476
  },
  {
    "name": "Concord",
    "lng": -80.69249058254385,
    "lat": 35.4337700480504
  },
  {
    "name": "Concord",
    "lng": -80.63090823894773,
    "lat": 35.39539013738366
  },
  {
    "name": "Concord",
    "lng": -80.60561743153445,
    "lat": 35.345141097393316
  },
  {
    "name": "Concord",
    "lng": -80.59877822281308,
    "lat": 35.33668432953106
  },
  {
    "name": "Concord",
    "lng": -80.59326836065297,
    "lat": 35.35585940227349
  },
  {
    "name": "Concord",
    "lng": -80.5837771604845,
    "lat": 35.31452109369137
  },
  {
    "name": "Concord",
    "lng": -80.578931179107,
    "lat": 35.37641305350993
  },
  {
    "name": "Concord",
    "lng": -80.57246795259677,
    "lat": 35.36799830193007
  },
  {
    "name": "Concord",
    "lng": -80.56546535553161,
    "lat": 35.35788045076889
  },
  {
    "name": "Concord",
    "lng": -80.55244724478874,
    "lat": 35.43706728350209
  },
  {
    "name": "Concord",
    "lng": -80.55086935752189,
    "lat": 35.41625350176313
  },
  {
    "name": "Concord",
    "lng": -80.54673457460802,
    "lat": 35.366373190028334
  },
  {
    "name": "Concord",
    "lng": -80.54443132954503,
    "lat": 35.36409586325237
  },
  {
    "name": "Concord",
    "lng": -80.54167191183068,
    "lat": 35.404915884341456
  },
  {
    "name": "Concord",
    "lng": -80.53429848853648,
    "lat": 35.410464054850316
  },
  {
    "name": "Concord",
    "lng": -80.530509510071,
    "lat": 35.39088553193605
  },
  {
    "name": "Concord",
    "lng": -80.52847825381332,
    "lat": 35.401865698558126
  },
  {
    "name": "Concord",
    "lng": -80.51674285860877,
    "lat": 35.41528375889488
  },
  {
    "name": "Concord",
    "lng": -80.50729826682281,
    "lat": 35.39032240166308
  },
  {
    "name": "Concord",
    "lng": -80.49296543346256,
    "lat": 35.38865321114013
  },
  {
    "name": "Concord",
    "lng": -71.55956297002388,
    "lat": 43.230758573303326
  },
  {
    "name": "Concord",
    "lng": -71.36385747426912,
    "lat": 42.46198739680266
  },
  {
    "name": "Concordia",
    "lng": -97.6468257772162,
    "lat": 39.565393471606434
  },
  {
    "name": "Concordia",
    "lng": -93.56829809135967,
    "lat": 38.987785611452715
  },
  {
    "name": "Concrete",
    "lng": -121.75058651578978,
    "lat": 48.53756354316393
  },
  {
    "name": "Conde",
    "lng": -98.09625451957093,
    "lat": 45.157118375886554
  },
  {
    "name": "Conesville",
    "lng": -91.34862189827588,
    "lat": 41.379639658001615
  },
  {
    "name": "Conesville",
    "lng": -81.89176832310028,
    "lat": 40.18507017047126
  },
  {
    "name": "Conetoe",
    "lng": -77.45715991162596,
    "lat": 35.817550636963695
  },
  {
    "name": "Conetoe",
    "lng": -77.45523835511814,
    "lat": 35.82281620078781
  },
  {
    "name": "Coney Island",
    "lng": -93.39729258654219,
    "lat": 36.59321713242827
  },
  {
    "name": "Conger",
    "lng": -93.52757281907435,
    "lat": 43.615244955220504
  },
  {
    "name": "Congerville",
    "lng": -89.20513545939646,
    "lat": 40.61679490450887
  },
  {
    "name": "Congress",
    "lng": -82.05585664344747,
    "lat": 40.925184772951944
  },
  {
    "name": "Conneaut",
    "lng": -80.5685908234624,
    "lat": 41.92772444135182
  },
  {
    "name": "Connecticut",
    "lng": -72.7377054497424,
    "lat": 41.57490366491733
  },
  {
    "name": "Connell",
    "lng": -118.83911221549066,
    "lat": 46.66457671522724
  },
  {
    "name": "Connellsville",
    "lng": -79.58995028303097,
    "lat": 40.01578076199803
  },
  {
    "name": "Connelly Springs",
    "lng": -81.49655571308233,
    "lat": 35.75541409446872
  },
  {
    "name": "Connersville",
    "lng": -85.14080817470474,
    "lat": 39.659002911617925
  },
  {
    "name": "Conning Towers Nautilus Park",
    "lng": -72.0683281071979,
    "lat": 41.385018551556556
  },
  {
    "name": "Conover",
    "lng": -81.2638536571901,
    "lat": 35.7147627570028
  },
  {
    "name": "Conover",
    "lng": -81.23926623003288,
    "lat": 35.74205349633029
  },
  {
    "name": "Conover",
    "lng": -81.23492272791168,
    "lat": 35.70324684377468
  },
  {
    "name": "Conover",
    "lng": -81.21717062694823,
    "lat": 35.715444216369924
  },
  {
    "name": "Conover",
    "lng": -81.16913752140066,
    "lat": 35.753869381616695
  },
  {
    "name": "Conrad",
    "lng": -111.94773634557961,
    "lat": 48.1731029101284
  },
  {
    "name": "Conrad",
    "lng": -111.93112627474557,
    "lat": 48.183605859215746
  },
  {
    "name": "Conrad",
    "lng": -92.87411657140935,
    "lat": 42.22342997842897
  },
  {
    "name": "Conrath",
    "lng": -91.0354688153807,
    "lat": 45.38407944107651
  },
  {
    "name": "Conroe",
    "lng": -95.64580823747701,
    "lat": 30.394733700623487
  },
  {
    "name": "Conroe",
    "lng": -95.53754299994614,
    "lat": 30.321315026803394
  },
  {
    "name": "Conroe",
    "lng": -95.53011621636213,
    "lat": 30.313626327433038
  },
  {
    "name": "Conroe",
    "lng": -95.47989102772627,
    "lat": 30.321706813626886
  },
  {
    "name": "Conroe",
    "lng": -95.45246780137862,
    "lat": 30.205090299028743
  },
  {
    "name": "Conshohocken",
    "lng": -75.30346481339208,
    "lat": 40.07724804597406
  },
  {
    "name": "Constableville",
    "lng": -75.42805480370495,
    "lat": 43.56487870672524
  },
  {
    "name": "Constantine",
    "lng": -85.66488726565281,
    "lat": 41.837828686667336
  },
  {
    "name": "Continental",
    "lng": -84.26742165566529,
    "lat": 41.09895927794755
  },
  {
    "name": "Converse",
    "lng": -93.70013028052776,
    "lat": 31.779539922949475
  },
  {
    "name": "Converse",
    "lng": -85.89829005393119,
    "lat": 40.573335067081246
  },
  {
    "name": "Converse",
    "lng": -85.87944210918405,
    "lat": 40.58769140371378
  },
  {
    "name": "Converse",
    "lng": -85.86701864700422,
    "lat": 40.579012727829785
  },
  {
    "name": "Convoy",
    "lng": -84.70529915998797,
    "lat": 40.91789883493956
  },
  {
    "name": "Conway",
    "lng": -97.67518336628726,
    "lat": 48.23444638007002
  },
  {
    "name": "Conway",
    "lng": -94.62000333552896,
    "lat": 40.74918250093462
  },
  {
    "name": "Conway",
    "lng": -92.83118704846909,
    "lat": 37.50558030115543
  },
  {
    "name": "Conway",
    "lng": -92.46945310915294,
    "lat": 35.07539008976685
  },
  {
    "name": "Conway",
    "lng": -79.05138285333072,
    "lat": 33.80824527087555
  },
  {
    "name": "Conway",
    "lng": -79.04238519158746,
    "lat": 33.84207482615321
  },
  {
    "name": "Conway",
    "lng": -79.04019871057515,
    "lat": 33.86890013902273
  },
  {
    "name": "Conway",
    "lng": -79.02441375569401,
    "lat": 33.78490665310039
  },
  {
    "name": "Conway",
    "lng": -77.22881952322548,
    "lat": 36.43712024607823
  },
  {
    "name": "Conway",
    "lng": -72.70643116343214,
    "lat": 42.508163177419924
  },
  {
    "name": "Conway Springs",
    "lng": -97.7389675,
    "lat": 37.395268003454085
  },
  {
    "name": "Conway Springs",
    "lng": -97.73382168618525,
    "lat": 37.39590275387152
  },
  {
    "name": "Conway Springs",
    "lng": -97.64361785381203,
    "lat": 37.38943620280954
  },
  {
    "name": "Conway Springs",
    "lng": -97.63366603198277,
    "lat": 37.393221354010386
  },
  {
    "name": "Conway Springs",
    "lng": -97.62099674636409,
    "lat": 37.393409621330605
  },
  {
    "name": "Conway Springs",
    "lng": -97.61957112528201,
    "lat": 37.385761699692935
  },
  {
    "name": "Conway Springs",
    "lng": -97.61933806213185,
    "lat": 37.3870461661083
  },
  {
    "name": "Conyers",
    "lng": -84.00995206921672,
    "lat": 33.66161257314041
  },
  {
    "name": "Conyers",
    "lng": -84.00044775353223,
    "lat": 33.6849846873712
  },
  {
    "name": "Conyers",
    "lng": -83.93955342363097,
    "lat": 33.67226793065806
  },
  {
    "name": "Cook",
    "lng": -96.1616455121002,
    "lat": 40.510579178754575
  },
  {
    "name": "Cook",
    "lng": -96.15701966666666,
    "lat": 40.50833100040353
  },
  {
    "name": "Cook",
    "lng": -92.68872259825558,
    "lat": 47.841415769550046
  },
  {
    "name": "Cookeville",
    "lng": -85.50789252598793,
    "lat": 36.14948565975921
  },
  {
    "name": "Cooksville",
    "lng": -88.71445869564583,
    "lat": 40.542529439156866
  },
  {
    "name": "Cool Valley",
    "lng": -90.30569920314746,
    "lat": 38.72518787638899
  },
  {
    "name": "Cooleemee",
    "lng": -80.55608657924925,
    "lat": 35.81256091467071
  },
  {
    "name": "Coolidge",
    "lng": -111.5288590812739,
    "lat": 32.96131612519221
  },
  {
    "name": "Coolidge",
    "lng": -102.00778836010458,
    "lat": 38.04153817992239
  },
  {
    "name": "Coolidge",
    "lng": -96.6522942100356,
    "lat": 31.751933440157128
  },
  {
    "name": "Coolidge",
    "lng": -83.86638040666055,
    "lat": 31.01137844534057
  },
  {
    "name": "Coolville",
    "lng": -81.7987385088722,
    "lat": 39.21908458239413
  },
  {
    "name": "Coon Rapids",
    "lng": -94.67845372514041,
    "lat": 41.87462746749519
  },
  {
    "name": "Coon Rapids",
    "lng": -93.30940710622541,
    "lat": 45.17557023371975
  },
  {
    "name": "Coon Valley",
    "lng": -91.01143165481817,
    "lat": 43.702206729282025
  },
  {
    "name": "Cooper",
    "lng": -95.70694970693441,
    "lat": 33.36612745785621
  },
  {
    "name": "Cooper",
    "lng": -95.70619869472246,
    "lat": 33.37005136478541
  },
  {
    "name": "Cooper",
    "lng": -95.69104566202266,
    "lat": 33.37182044125347
  },
  {
    "name": "Cooper",
    "lng": -95.67796022528918,
    "lat": 33.37427281190352
  },
  {
    "name": "Cooper",
    "lng": -95.6766686553236,
    "lat": 33.373461255715945
  },
  {
    "name": "Cooper",
    "lng": -95.67556680337442,
    "lat": 33.37158191031689
  },
  {
    "name": "Cooper",
    "lng": -67.42684541179241,
    "lat": 44.99484174030684
  },
  {
    "name": "Cooperstown",
    "lng": -98.12571451861025,
    "lat": 47.444918216758424
  },
  {
    "name": "Cooperstown",
    "lng": -74.93002676351865,
    "lat": 42.6996875235594
  },
  {
    "name": "Coopersville",
    "lng": -85.93410292153418,
    "lat": 43.0657910174884
  },
  {
    "name": "Cooperton",
    "lng": -98.87589170799626,
    "lat": 34.86620829853743
  },
  {
    "name": "Coopertown",
    "lng": -86.96582830942565,
    "lat": 36.414215374886155
  },
  {
    "name": "Coosada",
    "lng": -86.3281664285697,
    "lat": 32.49788478584468
  },
  {
    "name": "Cooter",
    "lng": -89.80917036847015,
    "lat": 36.046693620801825
  },
  {
    "name": "Copan",
    "lng": -95.92538200186408,
    "lat": 36.900719610144435
  },
  {
    "name": "Cope",
    "lng": -81.00692988319966,
    "lat": 33.377992043360734
  },
  {
    "name": "Copeland",
    "lng": -100.62880640915016,
    "lat": 37.540526236598744
  },
  {
    "name": "Copemish",
    "lng": -85.92389473620482,
    "lat": 44.480116373877024
  },
  {
    "name": "Copenhagen",
    "lng": -75.67266785369581,
    "lat": 43.89339309269568
  },
  {
    "name": "Coppell",
    "lng": -96.99073205903355,
    "lat": 32.96397395400024
  },
  {
    "name": "Copper Canyon",
    "lng": -97.09752204473182,
    "lat": 33.09649975456867
  },
  {
    "name": "Copper City",
    "lng": -88.3874890478657,
    "lat": 47.2841832907919
  },
  {
    "name": "Copperas Cove",
    "lng": -97.91413482829195,
    "lat": 31.11923274473433
  },
  {
    "name": "Copperhill",
    "lng": -84.38567364883478,
    "lat": 35.009879107008594
  },
  {
    "name": "Copperhill",
    "lng": -84.37496462126539,
    "lat": 34.99415069109401
  },
  {
    "name": "Coppock",
    "lng": -91.71505776914954,
    "lat": 41.16356378514987
  },
  {
    "name": "Coralville",
    "lng": -91.59652564938901,
    "lat": 41.69900803100069
  },
  {
    "name": "Corbin",
    "lng": -84.09850383948981,
    "lat": 36.93226429980355
  },
  {
    "name": "Corbin",
    "lng": -84.07607709009588,
    "lat": 36.96235686252535
  },
  {
    "name": "Corbin City",
    "lng": -74.71971550509029,
    "lat": 39.304607703684894
  },
  {
    "name": "Corcoran",
    "lng": -119.57824574285542,
    "lat": 36.0816623770431
  },
  {
    "name": "Corcoran",
    "lng": -119.56380890738056,
    "lat": 36.120223653954554
  },
  {
    "name": "Corcoran",
    "lng": -119.56105183815244,
    "lat": 36.13631030789537
  },
  {
    "name": "Corcoran",
    "lng": -119.56103575504008,
    "lat": 36.08260980438046
  },
  {
    "name": "Corcoran",
    "lng": -119.54788070552404,
    "lat": 36.11074441521716
  },
  {
    "name": "Corcoran",
    "lng": -93.583687553627,
    "lat": 45.108902143140014
  },
  {
    "name": "Cordele",
    "lng": -83.79806975225765,
    "lat": 31.989894063162605
  },
  {
    "name": "Cordele",
    "lng": -83.79303244232362,
    "lat": 31.947537666095357
  },
  {
    "name": "Cordele",
    "lng": -83.76901994126679,
    "lat": 31.956014974721743
  },
  {
    "name": "Corder",
    "lng": -93.63890432258736,
    "lat": 39.099189235990536
  },
  {
    "name": "Cordova",
    "lng": -145.6326143257942,
    "lat": 60.54008168649185
  },
  {
    "name": "Cordova",
    "lng": -97.35161139579724,
    "lat": 40.71636759273845
  },
  {
    "name": "Cordova",
    "lng": -90.3216215065274,
    "lat": 41.677375142416906
  },
  {
    "name": "Cordova",
    "lng": -87.19266185632088,
    "lat": 33.76130546579854
  },
  {
    "name": "Cordova",
    "lng": -80.91943197815385,
    "lat": 33.43600995842322
  },
  {
    "name": "Corfu",
    "lng": -78.4028312692645,
    "lat": 42.961182369283414
  },
  {
    "name": "Corinna",
    "lng": -69.25690326662449,
    "lat": 44.949816847590384
  },
  {
    "name": "Corinne",
    "lng": -112.12269161647224,
    "lat": 41.54969351821103
  },
  {
    "name": "Corinth",
    "lng": -97.06819190458599,
    "lat": 33.14327975815443
  },
  {
    "name": "Corinth",
    "lng": -93.4234450419808,
    "lat": 35.06927577945716
  },
  {
    "name": "Corinth",
    "lng": -88.514100769327,
    "lat": 34.94742710457434
  },
  {
    "name": "Corinth",
    "lng": -84.56443937361314,
    "lat": 38.495649705428335
  },
  {
    "name": "Corinth",
    "lng": -73.8309653833159,
    "lat": 43.24563830092309
  },
  {
    "name": "Corinth",
    "lng": -69.01074723039373,
    "lat": 44.98050584178791
  },
  {
    "name": "Corn",
    "lng": -98.7815392471684,
    "lat": 35.379115850533154
  },
  {
    "name": "Cornelia",
    "lng": -83.55196771394866,
    "lat": 34.51683730292943
  },
  {
    "name": "Cornelia",
    "lng": -83.53132970307028,
    "lat": 34.515467504550045
  },
  {
    "name": "Cornelia",
    "lng": -83.51040966064127,
    "lat": 34.49533690457309
  },
  {
    "name": "Cornelius",
    "lng": -80.8887658178889,
    "lat": 35.47535110212793
  },
  {
    "name": "Cornelius",
    "lng": -80.86739353574033,
    "lat": 35.451553182435525
  },
  {
    "name": "Cornelius",
    "lng": -80.83692199621936,
    "lat": 35.46308152060863
  },
  {
    "name": "Cornell",
    "lng": -91.15224657189226,
    "lat": 45.1629990030849
  },
  {
    "name": "Cornell",
    "lng": -91.10574423554235,
    "lat": 45.16707815322297
  },
  {
    "name": "Cornell",
    "lng": -88.7293888329058,
    "lat": 40.99219430376625
  },
  {
    "name": "Cornersville",
    "lng": -86.84462013858034,
    "lat": 35.35250633887849
  },
  {
    "name": "Corning",
    "lng": -122.18291444002261,
    "lat": 39.926331667704446
  },
  {
    "name": "Corning",
    "lng": -96.02872598086995,
    "lat": 39.656631678426564
  },
  {
    "name": "Corning",
    "lng": -95.45457067997219,
    "lat": 40.248649870612276
  },
  {
    "name": "Corning",
    "lng": -94.73969118738322,
    "lat": 40.99231653484816
  },
  {
    "name": "Corning",
    "lng": -90.58418775293107,
    "lat": 36.41344112973472
  },
  {
    "name": "Corning",
    "lng": -82.0875665783105,
    "lat": 39.6016717713975
  },
  {
    "name": "Corning",
    "lng": -77.05615309506688,
    "lat": 42.147022779990216
  },
  {
    "name": "Cornish",
    "lng": -111.95647901506017,
    "lat": 41.971649791007266
  },
  {
    "name": "Cornish",
    "lng": -97.59634312224708,
    "lat": 34.163550915288006
  },
  {
    "name": "Cornish",
    "lng": -70.81027988293994,
    "lat": 43.768134453480094
  },
  {
    "name": "Cornlea",
    "lng": -97.56690408731957,
    "lat": 41.680049464751725
  },
  {
    "name": "Cornville",
    "lng": -69.67519906247692,
    "lat": 44.86286830586133
  },
  {
    "name": "Cornwall",
    "lng": -73.32993997328641,
    "lat": 41.84734177070573
  },
  {
    "name": "Cornwall-on-Hudson",
    "lng": -74.0144950005516,
    "lat": 41.43681975004334
  },
  {
    "name": "Corona",
    "lng": -105.59708166283151,
    "lat": 34.249999246013445
  },
  {
    "name": "Corona",
    "lng": -96.76458078482456,
    "lat": 45.33522560905403
  },
  {
    "name": "Coronado",
    "lng": -117.16689124764845,
    "lat": 32.64322492090877
  },
  {
    "name": "Corral City",
    "lng": -97.23074611080295,
    "lat": 33.09835358591553
  },
  {
    "name": "Corrales",
    "lng": -106.61910524834391,
    "lat": 35.2367399629563
  },
  {
    "name": "Correctionville",
    "lng": -95.78307095591491,
    "lat": 42.47723797963483
  },
  {
    "name": "Correll",
    "lng": -96.16255435803183,
    "lat": 45.23188283798192
  },
  {
    "name": "Corrigan",
    "lng": -94.82678060020854,
    "lat": 30.998574037422408
  },
  {
    "name": "Corry",
    "lng": -79.63555030753511,
    "lat": 41.926030177540596
  },
  {
    "name": "Corsica",
    "lng": -98.40725695988786,
    "lat": 43.42245095858837
  },
  {
    "name": "Corsicana",
    "lng": -96.4680264532412,
    "lat": 32.082077251826966
  },
  {
    "name": "Corte Madera",
    "lng": -122.50672337739194,
    "lat": 37.92552072674454
  },
  {
    "name": "Cortez",
    "lng": -108.57686684797694,
    "lat": 37.34983686154027
  },
  {
    "name": "Cortland",
    "lng": -96.70609863526509,
    "lat": 40.505926795087504
  },
  {
    "name": "Cortland",
    "lng": -88.70997415918191,
    "lat": 41.923307960255215
  },
  {
    "name": "Cortland",
    "lng": -88.6792107981753,
    "lat": 41.92550367791036
  },
  {
    "name": "Cortland",
    "lng": -80.71865418756268,
    "lat": 41.32993945968819
  },
  {
    "name": "Cortland",
    "lng": -76.17840748449836,
    "lat": 42.600393969703674
  },
  {
    "name": "Cortlandt",
    "lng": -73.90181759331266,
    "lat": 41.25526591299322
  },
  {
    "name": "Corunna",
    "lng": -85.14399461407392,
    "lat": 41.43590151294134
  },
  {
    "name": "Corunna",
    "lng": -84.1160840191121,
    "lat": 42.98378182199121
  },
  {
    "name": "Corwin",
    "lng": -84.06773679539543,
    "lat": 39.52484942707952
  },
  {
    "name": "Corwith",
    "lng": -93.95723519183117,
    "lat": 42.989015143184034
  },
  {
    "name": "Corydon",
    "lng": -93.31738050967041,
    "lat": 40.757685669321944
  },
  {
    "name": "Corydon",
    "lng": -87.70637958653981,
    "lat": 37.74020901578334
  },
  {
    "name": "Corydon",
    "lng": -86.12572846221288,
    "lat": 38.213028490859536
  },
  {
    "name": "Cosby",
    "lng": -94.67981917739812,
    "lat": 39.86383920824061
  },
  {
    "name": "Coshocton",
    "lng": -81.84833824920278,
    "lat": 40.26191665227595
  },
  {
    "name": "Cosmopolis",
    "lng": -123.77285109475793,
    "lat": 46.954018283494165
  },
  {
    "name": "Cosmos",
    "lng": -94.6952384266738,
    "lat": 44.935427318347266
  },
  {
    "name": "Costa Mesa",
    "lng": -117.91346927358474,
    "lat": 33.66675318537145
  },
  {
    "name": "Cotati",
    "lng": -122.70914341531171,
    "lat": 38.32782250111014
  },
  {
    "name": "Cotesfield",
    "lng": -98.63359580050641,
    "lat": 41.357570073995916
  },
  {
    "name": "Coto de Caza",
    "lng": -117.58606072498249,
    "lat": 33.59616249443376
  },
  {
    "name": "Cottage City",
    "lng": -76.9491277198333,
    "lat": 38.93849993429827
  },
  {
    "name": "Cottage Grove",
    "lng": -92.92747034308357,
    "lat": 44.816110300149084
  },
  {
    "name": "Cottage Grove",
    "lng": -88.47910132179258,
    "lat": 36.37853167956809
  },
  {
    "name": "Cottageville",
    "lng": -80.48108647299553,
    "lat": 32.93731812545978
  },
  {
    "name": "Cotter",
    "lng": -92.52125496622213,
    "lat": 36.281834505928536
  },
  {
    "name": "Cotter",
    "lng": -92.52107775821719,
    "lat": 36.29169410825441
  },
  {
    "name": "Cotter",
    "lng": -92.50497892839057,
    "lat": 36.28644734184438
  },
  {
    "name": "Cotter",
    "lng": -91.46240945061234,
    "lat": 41.29263412651506
  },
  {
    "name": "Cottleville",
    "lng": -90.65774163565352,
    "lat": 38.75202912329406
  },
  {
    "name": "Cotton Plant",
    "lng": -91.25232287030681,
    "lat": 35.005843993079274
  },
  {
    "name": "Cotton Valley",
    "lng": -93.42278340674798,
    "lat": 32.813473326301704
  },
  {
    "name": "Cottonport",
    "lng": -92.05061352951711,
    "lat": 30.989074753856276
  },
  {
    "name": "Cottonwood",
    "lng": -122.28610886433198,
    "lat": 40.390476469291166
  },
  {
    "name": "Cottonwood",
    "lng": -116.34975164337921,
    "lat": 46.051046214413866
  },
  {
    "name": "Cottonwood",
    "lng": -101.90208423207467,
    "lat": 43.9656622060228
  },
  {
    "name": "Cottonwood",
    "lng": -96.39549965216067,
    "lat": 32.46220547757188
  },
  {
    "name": "Cottonwood",
    "lng": -95.67558707728892,
    "lat": 44.61285574334286
  },
  {
    "name": "Cottonwood",
    "lng": -85.30100084632916,
    "lat": 31.05496869984194
  },
  {
    "name": "Cottonwood Falls",
    "lng": -96.54239180841222,
    "lat": 38.36915070021539
  },
  {
    "name": "Cottonwood Heights",
    "lng": -111.8145729253352,
    "lat": 40.613697316414594
  },
  {
    "name": "Cottonwood Shores",
    "lng": -98.32674650890864,
    "lat": 30.555170849026727
  },
  {
    "name": "Cotulla",
    "lng": -99.23665104443356,
    "lat": 28.43632428830721
  },
  {
    "name": "Couderay",
    "lng": -91.3004851155337,
    "lat": 45.797648820762014
  },
  {
    "name": "Coudersport",
    "lng": -78.01603277342168,
    "lat": 41.775847598567864
  },
  {
    "name": "Coulee City",
    "lng": -119.28913401503318,
    "lat": 47.61347520381653
  },
  {
    "name": "Coulee City",
    "lng": -119.28858490060094,
    "lat": 47.60205371082763
  },
  {
    "name": "Coulee Dam",
    "lng": -118.97600554425208,
    "lat": 47.96850689485011
  },
  {
    "name": "Coulter",
    "lng": -93.37009537628457,
    "lat": 42.73311691046586
  },
  {
    "name": "Coulterville",
    "lng": -89.60456535648733,
    "lat": 38.1849227293021
  },
  {
    "name": "Council",
    "lng": -116.44563480586673,
    "lat": 44.75039751656997
  },
  {
    "name": "Council",
    "lng": -116.43640242264654,
    "lat": 44.73038665997053
  },
  {
    "name": "Council Bluffs",
    "lng": -95.85157106126736,
    "lat": 41.236883526478366
  },
  {
    "name": "Council Grove",
    "lng": -96.49115864235338,
    "lat": 38.66103249471738
  },
  {
    "name": "Council Hill",
    "lng": -95.65253716734175,
    "lat": 35.55590246248507
  },
  {
    "name": "Country Club",
    "lng": -94.82047741669373,
    "lat": 39.83879123701779
  },
  {
    "name": "Country Club Heights",
    "lng": -85.68769413581062,
    "lat": 40.12447538942925
  },
  {
    "name": "Country Club Hills",
    "lng": -90.27480542714501,
    "lat": 38.720733328733914
  },
  {
    "name": "Country Club Hills",
    "lng": -87.72387397198125,
    "lat": 41.5635224687402
  },
  {
    "name": "Country Life Acres",
    "lng": -90.45542399041753,
    "lat": 38.62226681735273
  },
  {
    "name": "Countryside",
    "lng": -87.87524193259817,
    "lat": 41.77409381537476
  },
  {
    "name": "County Line",
    "lng": -86.72858436249993,
    "lat": 33.82396902007141
  },
  {
    "name": "Coupeville",
    "lng": -122.67766080541432,
    "lat": 48.21701925313848
  },
  {
    "name": "Coupland",
    "lng": -97.39794823171181,
    "lat": 30.456774541538238
  },
  {
    "name": "Courtenay",
    "lng": -98.56850939465441,
    "lat": 47.22431664621757
  },
  {
    "name": "Courtland",
    "lng": -97.89618314944794,
    "lat": 39.78310308560146
  },
  {
    "name": "Courtland",
    "lng": -94.34644914935116,
    "lat": 44.26995119367812
  },
  {
    "name": "Courtland",
    "lng": -89.94326408025182,
    "lat": 34.24089377934194
  },
  {
    "name": "Courtland",
    "lng": -87.32642540100784,
    "lat": 34.670443530744535
  },
  {
    "name": "Courtland",
    "lng": -77.06195833252403,
    "lat": 36.71226051557419
  },
  {
    "name": "Coushatta",
    "lng": -93.34057298037794,
    "lat": 32.0256922622434
  },
  {
    "name": "Cove",
    "lng": -94.81987933886022,
    "lat": 29.81332823127183
  },
  {
    "name": "Cove",
    "lng": -94.41608071070284,
    "lat": 34.43653910367295
  },
  {
    "name": "Cove City",
    "lng": -77.32052606610857,
    "lat": 35.18896897450961
  },
  {
    "name": "Cove Neck",
    "lng": -73.49607293092764,
    "lat": 40.88660854089988
  },
  {
    "name": "Coventry",
    "lng": -72.33946596336764,
    "lat": 41.78290464275987
  },
  {
    "name": "Coventry",
    "lng": -71.6607678435052,
    "lat": 41.69324394595794
  },
  {
    "name": "Covina",
    "lng": -117.88177668509834,
    "lat": 34.090288589775014
  },
  {
    "name": "Covina",
    "lng": -117.87212331805571,
    "lat": 34.08210707789968
  },
  {
    "name": "Covina",
    "lng": -117.85486512032242,
    "lat": 34.102401349367305
  },
  {
    "name": "Covington",
    "lng": -122.1050085075936,
    "lat": 47.366423722244704
  },
  {
    "name": "Covington",
    "lng": -97.58816102857418,
    "lat": 36.30762081249254
  },
  {
    "name": "Covington",
    "lng": -97.26032288644882,
    "lat": 32.17838874598627
  },
  {
    "name": "Covington",
    "lng": -90.1124787886911,
    "lat": 30.480886947860483
  },
  {
    "name": "Covington",
    "lng": -90.0882000239528,
    "lat": 30.475739539454672
  },
  {
    "name": "Covington",
    "lng": -90.0861153482375,
    "lat": 30.502266624186568
  },
  {
    "name": "Covington",
    "lng": -90.08538655299387,
    "lat": 30.4715339435247
  },
  {
    "name": "Covington",
    "lng": -90.08252587958188,
    "lat": 30.47302570249023
  },
  {
    "name": "Covington",
    "lng": -89.64879739613238,
    "lat": 35.565343765399504
  },
  {
    "name": "Covington",
    "lng": -87.39103949926582,
    "lat": 40.14045380981757
  },
  {
    "name": "Covington",
    "lng": -84.51666086502775,
    "lat": 39.033592019747005
  },
  {
    "name": "Covington",
    "lng": -84.35111294699847,
    "lat": 40.11727273970242
  },
  {
    "name": "Covington",
    "lng": -83.85131669942777,
    "lat": 33.63744196177937
  },
  {
    "name": "Covington",
    "lng": -83.84998476864335,
    "lat": 33.56375703890899
  },
  {
    "name": "Covington",
    "lng": -83.84686745515765,
    "lat": 33.60485897702489
  },
  {
    "name": "Covington",
    "lng": -83.8319693158037,
    "lat": 33.61372956982539
  },
  {
    "name": "Covington",
    "lng": -83.82908580820374,
    "lat": 33.61036624120599
  },
  {
    "name": "Covington",
    "lng": -83.80908206695958,
    "lat": 33.6273427815697
  },
  {
    "name": "Covington",
    "lng": -79.98675316926646,
    "lat": 37.778619277220244
  },
  {
    "name": "Cowan",
    "lng": -86.01405643341677,
    "lat": 35.16302551888757
  },
  {
    "name": "Coward",
    "lng": -79.74984876968257,
    "lat": 33.995162792842265
  },
  {
    "name": "Coward",
    "lng": -79.74871499218514,
    "lat": 33.971818266393036
  },
  {
    "name": "Cowarts",
    "lng": -85.30594294832935,
    "lat": 31.203820437199642
  },
  {
    "name": "Cowden",
    "lng": -88.85935055652,
    "lat": 39.249223965241214
  },
  {
    "name": "Cowen",
    "lng": -80.55419630861118,
    "lat": 38.411043071059325
  },
  {
    "name": "Coweta",
    "lng": -95.65418641813213,
    "lat": 35.96771604524305
  },
  {
    "name": "Coweta",
    "lng": -95.62976703819295,
    "lat": 35.940942058225566
  },
  {
    "name": "Coweta",
    "lng": -95.58104445771566,
    "lat": 35.96818259770394
  },
  {
    "name": "Cowgill",
    "lng": -93.92599648334023,
    "lat": 39.56038202059174
  },
  {
    "name": "Cowles",
    "lng": -98.44891182999368,
    "lat": 40.171459595322375
  },
  {
    "name": "Cowles",
    "lng": -98.44059399999996,
    "lat": 40.1697586684529
  },
  {
    "name": "Cowley",
    "lng": -108.46956795822406,
    "lat": 44.883555851155684
  },
  {
    "name": "Cowlington",
    "lng": -94.78787149629461,
    "lat": 35.310605835228806
  },
  {
    "name": "Cowpens",
    "lng": -81.80418759681105,
    "lat": 35.018748579154284
  },
  {
    "name": "Coxsackie",
    "lng": -73.80791244060198,
    "lat": 42.3560768656242
  },
  {
    "name": "Coy",
    "lng": -91.87109212918173,
    "lat": 34.5404951141341
  },
  {
    "name": "Coyanosa Tx.",
    "lng": -103.06400538767258,
    "lat": 31.241594258850405
  },
  {
    "name": "Coyle",
    "lng": -97.2374125475745,
    "lat": 35.95412619775768
  },
  {
    "name": "Coyote Flats",
    "lng": -97.29441668435544,
    "lat": 32.35281929513188
  },
  {
    "name": "Coyville",
    "lng": -95.89573617324041,
    "lat": 37.687201612506094
  },
  {
    "name": "Cozad",
    "lng": -99.98636219628347,
    "lat": 40.86127001302774
  },
  {
    "name": "Crab Orchard",
    "lng": -96.42248755634013,
    "lat": 40.3346460446048
  },
  {
    "name": "Crab Orchard",
    "lng": -84.88397136889505,
    "lat": 35.90642003214945
  },
  {
    "name": "Crab Orchard",
    "lng": -84.50652537120587,
    "lat": 37.46270355771247
  },
  {
    "name": "Craig",
    "lng": -133.1186651700107,
    "lat": 55.48965887892978
  },
  {
    "name": "Craig",
    "lng": -107.55501218417317,
    "lat": 40.51731902273552
  },
  {
    "name": "Craig",
    "lng": -96.36730321520187,
    "lat": 41.79102757727693
  },
  {
    "name": "Craig",
    "lng": -96.362392265657,
    "lat": 41.7852138772115
  },
  {
    "name": "Craig",
    "lng": -96.30951879311097,
    "lat": 42.895553053781214
  },
  {
    "name": "Craig",
    "lng": -95.37403768285738,
    "lat": 40.19273677446321
  },
  {
    "name": "Craig Beach",
    "lng": -80.98271945318479,
    "lat": 41.11599067268291
  },
  {
    "name": "Craigmont",
    "lng": -116.47279249204318,
    "lat": 46.24218639161599
  },
  {
    "name": "Craigsville",
    "lng": -79.38538396992652,
    "lat": 38.083791892705534
  },
  {
    "name": "Crainville",
    "lng": -89.06107463790997,
    "lat": 37.750739528082875
  },
  {
    "name": "Cramerton",
    "lng": -81.0733822826941,
    "lat": 35.23466039463934
  },
  {
    "name": "Cranberry Isles",
    "lng": -68.20527201562761,
    "lat": 44.242323966697974
  },
  {
    "name": "Crandall",
    "lng": -96.46156115686232,
    "lat": 32.61617359719004
  },
  {
    "name": "Crandall",
    "lng": -96.45401969529999,
    "lat": 32.62793343625476
  },
  {
    "name": "Crandall",
    "lng": -96.42576142841453,
    "lat": 32.637883055137145
  },
  {
    "name": "Crandall",
    "lng": -96.42287793851726,
    "lat": 32.646989485126184
  },
  {
    "name": "Crandall",
    "lng": -86.06588958908974,
    "lat": 38.28797337520991
  },
  {
    "name": "Crandon",
    "lng": -88.89730023550744,
    "lat": 45.56867227491211
  },
  {
    "name": "Crane",
    "lng": -102.35042595778094,
    "lat": 31.39189493225771
  },
  {
    "name": "Crane",
    "lng": -93.57159105120931,
    "lat": 36.90270105015557
  },
  {
    "name": "Crane",
    "lng": -86.9012739263348,
    "lat": 38.894323311229705
  },
  {
    "name": "Cranfills Gap",
    "lng": -97.83027589290614,
    "lat": 31.775273908576096
  },
  {
    "name": "Cranston",
    "lng": -71.4861768265938,
    "lat": 41.76578677346786
  },
  {
    "name": "Crary",
    "lng": -98.63859655719125,
    "lat": 48.07186851617666
  },
  {
    "name": "Crawford",
    "lng": -107.6100321415374,
    "lat": 38.70514651667451
  },
  {
    "name": "Crawford",
    "lng": -103.41401014607926,
    "lat": 42.68537289755199
  },
  {
    "name": "Crawford",
    "lng": -97.44515048611169,
    "lat": 31.538160245305985
  },
  {
    "name": "Crawford",
    "lng": -88.6252286447513,
    "lat": 33.302298241008785
  },
  {
    "name": "Crawford",
    "lng": -83.15518691988758,
    "lat": 33.88304335048857
  },
  {
    "name": "Crawford",
    "lng": -67.57726096812125,
    "lat": 45.03398218993416
  },
  {
    "name": "Crawfordsville",
    "lng": -91.53665740465334,
    "lat": 41.21397750211298
  },
  {
    "name": "Crawfordsville",
    "lng": -90.32428419730155,
    "lat": 35.22657119921844
  },
  {
    "name": "Crawfordsville",
    "lng": -86.90468749576505,
    "lat": 40.024372171350514
  },
  {
    "name": "Crawfordsville",
    "lng": -86.8960323271315,
    "lat": 40.04345886325555
  },
  {
    "name": "Crawfordville",
    "lng": -82.8965468354536,
    "lat": 33.554532757768825
  },
  {
    "name": "Creal Springs",
    "lng": -88.83741907598416,
    "lat": 37.61977718255731
  },
  {
    "name": "Creede",
    "lng": -106.92722786853753,
    "lat": 37.853409959667076
  },
  {
    "name": "Creedmoor",
    "lng": -97.75240912807345,
    "lat": 30.05638586494227
  },
  {
    "name": "Creedmoor",
    "lng": -97.74895590302536,
    "lat": 30.079286413374305
  },
  {
    "name": "Creedmoor",
    "lng": -97.74148180514791,
    "lat": 30.085679485006885
  },
  {
    "name": "Creedmoor",
    "lng": -97.73747906026965,
    "lat": 30.11020429206873
  },
  {
    "name": "Creedmoor",
    "lng": -97.73622406000696,
    "lat": 30.05125170635802
  },
  {
    "name": "Creedmoor",
    "lng": -97.73354149756491,
    "lat": 30.06068360651827
  },
  {
    "name": "Creedmoor",
    "lng": -97.73141274982802,
    "lat": 30.063014841591297
  },
  {
    "name": "Creedmoor",
    "lng": -97.72950690555417,
    "lat": 30.106372521946334
  },
  {
    "name": "Creedmoor",
    "lng": -97.72668809440984,
    "lat": 30.06974538543403
  },
  {
    "name": "Creedmoor",
    "lng": -97.72492452304144,
    "lat": 30.068223732207418
  },
  {
    "name": "Creedmoor",
    "lng": -97.7226741941083,
    "lat": 30.054371232895704
  },
  {
    "name": "Creedmoor",
    "lng": -97.72063423503508,
    "lat": 30.108639322789937
  },
  {
    "name": "Creedmoor",
    "lng": -97.71897431611497,
    "lat": 30.10541230152773
  },
  {
    "name": "Creedmoor",
    "lng": -97.71413064759204,
    "lat": 30.09705431256206
  },
  {
    "name": "Creedmoor",
    "lng": -78.70029102993088,
    "lat": 36.11194896401553
  },
  {
    "name": "Creedmoor",
    "lng": -78.6765676674813,
    "lat": 36.12246308633459
  },
  {
    "name": "Creedmoor",
    "lng": -78.62323537495159,
    "lat": 36.126618962272964
  },
  {
    "name": "Creekside",
    "lng": -85.56918969266117,
    "lat": 38.291689922647
  },
  {
    "name": "Creekside Village",
    "lng": -95.37088611818648,
    "lat": 30.130076780432184
  },
  {
    "name": "Creighton",
    "lng": -97.90716352956917,
    "lat": 42.46486085912869
  },
  {
    "name": "Creighton",
    "lng": -94.07208412750305,
    "lat": 38.49654328070927
  },
  {
    "name": "Crenshaw",
    "lng": -90.1946383311011,
    "lat": 34.50467044206767
  },
  {
    "name": "Creola",
    "lng": -92.48284841029985,
    "lat": 31.42999368491037
  },
  {
    "name": "Creola",
    "lng": -88.00999829006342,
    "lat": 30.89030277644971
  },
  {
    "name": "Cresbard",
    "lng": -98.94774624476106,
    "lat": 45.16915968769761
  },
  {
    "name": "Crescent",
    "lng": -97.59493722206302,
    "lat": 35.95207660369113
  },
  {
    "name": "Crescent",
    "lng": -95.85879315489146,
    "lat": 41.3656214699014
  },
  {
    "name": "Crescent City",
    "lng": -124.19932435318206,
    "lat": 41.75436634464045
  },
  {
    "name": "Crescent City",
    "lng": -124.15056109920256,
    "lat": 41.853552544750166
  },
  {
    "name": "Crescent City",
    "lng": -87.85630910207149,
    "lat": 40.77184705389007
  },
  {
    "name": "Crescent City",
    "lng": -81.51724421746579,
    "lat": 29.437427693251742
  },
  {
    "name": "Crescent Springs",
    "lng": -84.57899819407139,
    "lat": 39.05496264439825
  },
  {
    "name": "Cresco",
    "lng": -92.11633107777035,
    "lat": 43.371749640405994
  },
  {
    "name": "Cresson",
    "lng": -97.61289629703381,
    "lat": 32.532216148387356
  },
  {
    "name": "Crest Hill",
    "lng": -88.14784020668908,
    "lat": 41.569158472000616
  },
  {
    "name": "Crest Hill",
    "lng": -88.12027688828647,
    "lat": 41.59177593510293
  },
  {
    "name": "Crest Hill",
    "lng": -88.11227123587501,
    "lat": 41.57206762236907
  },
  {
    "name": "Crested Butte",
    "lng": -106.97725035010731,
    "lat": 38.86762935020941
  },
  {
    "name": "Crestline",
    "lng": -82.74548843354448,
    "lat": 40.783784899819935
  },
  {
    "name": "Creston",
    "lng": -118.51998831316692,
    "lat": 47.75940397358473
  },
  {
    "name": "Creston",
    "lng": -97.36267964464665,
    "lat": 41.70789626987539
  },
  {
    "name": "Creston",
    "lng": -94.3650313958716,
    "lat": 41.05982132942026
  },
  {
    "name": "Creston",
    "lng": -88.97410168501348,
    "lat": 41.93351333063734
  },
  {
    "name": "Creston",
    "lng": -81.90001738070582,
    "lat": 40.976267576369594
  },
  {
    "name": "Crestone",
    "lng": -105.69609066061808,
    "lat": 37.99418536866761
  },
  {
    "name": "Crestview",
    "lng": -86.60240558411151,
    "lat": 30.78273342037435
  },
  {
    "name": "Crestview",
    "lng": -86.5791411177056,
    "lat": 30.74744038359773
  },
  {
    "name": "Crestview",
    "lng": -86.55999614131312,
    "lat": 30.77927629247253
  },
  {
    "name": "Crestview",
    "lng": -86.55824480921738,
    "lat": 30.786234967471408
  },
  {
    "name": "Crestview",
    "lng": -86.55612805080844,
    "lat": 30.73943017558527
  },
  {
    "name": "Crestview",
    "lng": -86.55447165243386,
    "lat": 30.790789968050362
  },
  {
    "name": "Crestview",
    "lng": -86.53959796620641,
    "lat": 30.737943162329046
  },
  {
    "name": "Crestview",
    "lng": -84.41657044811252,
    "lat": 39.024069034239105
  },
  {
    "name": "Crestview Hills",
    "lng": -84.5697132896059,
    "lat": 39.02499276376095
  },
  {
    "name": "Crestwood",
    "lng": -90.37826824908356,
    "lat": 38.55690365055937
  },
  {
    "name": "Crestwood",
    "lng": -87.73953259572093,
    "lat": 41.64554761998245
  },
  {
    "name": "Crestwood",
    "lng": -85.48253888511098,
    "lat": 38.33446742213791
  },
  {
    "name": "Creswell",
    "lng": -76.39297554029268,
    "lat": 35.870217566926726
  },
  {
    "name": "Crete",
    "lng": -96.95856103197227,
    "lat": 40.6255350989651
  },
  {
    "name": "Crete",
    "lng": -96.94984599800001,
    "lat": 40.61207066129447
  },
  {
    "name": "Crete",
    "lng": -87.64591998371547,
    "lat": 41.41240134289632
  },
  {
    "name": "Crete",
    "lng": -87.63434667481998,
    "lat": 41.422344578592366
  },
  {
    "name": "Crete",
    "lng": -87.63289312627943,
    "lat": 41.41601950682823
  },
  {
    "name": "Crete",
    "lng": -87.61576042495922,
    "lat": 41.44986360119433
  },
  {
    "name": "Crete",
    "lng": -87.58360547016628,
    "lat": 41.44130713842903
  },
  {
    "name": "Creve Coeur",
    "lng": -90.44300866823478,
    "lat": 38.66202542847093
  },
  {
    "name": "Creve Coeur",
    "lng": -89.59862348894492,
    "lat": 40.64241716977331
  },
  {
    "name": "Crewe",
    "lng": -78.13056870879659,
    "lat": 37.18119175737582
  },
  {
    "name": "Cridersville",
    "lng": -84.14600286419896,
    "lat": 40.65123499366569
  },
  {
    "name": "Crisfield",
    "lng": -75.87391807501292,
    "lat": 37.97309245270791
  },
  {
    "name": "Crisfield",
    "lng": -75.87154316846242,
    "lat": 37.97502739074951
  },
  {
    "name": "Crisfield",
    "lng": -75.86855057248472,
    "lat": 37.97802454844522
  },
  {
    "name": "Crisfield",
    "lng": -75.85202476216587,
    "lat": 37.97954490431926
  },
  {
    "name": "Crisfield",
    "lng": -75.85182754327694,
    "lat": 37.99726965599049
  },
  {
    "name": "Crittenden",
    "lng": -84.61725034678012,
    "lat": 38.757409115522606
  },
  {
    "name": "Crittenden",
    "lng": -84.60908857704848,
    "lat": 38.78416874023326
  },
  {
    "name": "Crivitz",
    "lng": -88.00718700078198,
    "lat": 45.23414325899007
  },
  {
    "name": "Crocker",
    "lng": -92.26767831474841,
    "lat": 37.94865301031807
  },
  {
    "name": "Crockett",
    "lng": -95.45639820915991,
    "lat": 31.317687883401728
  },
  {
    "name": "Crofton",
    "lng": -97.49861669607088,
    "lat": 42.731615981924094
  },
  {
    "name": "Crofton",
    "lng": -87.48420076755473,
    "lat": 37.047834000424714
  },
  {
    "name": "Croghan",
    "lng": -75.39122719985815,
    "lat": 43.89456275555319
  },
  {
    "name": "Cromwell",
    "lng": -96.4572007288223,
    "lat": 35.36327889102464
  },
  {
    "name": "Cromwell",
    "lng": -94.46182604549797,
    "lat": 41.04065147462261
  },
  {
    "name": "Cromwell",
    "lng": -92.87267190688814,
    "lat": 46.67955430887214
  },
  {
    "name": "Cromwell",
    "lng": -85.61420102860049,
    "lat": 41.40324072375023
  },
  {
    "name": "Cromwell",
    "lng": -72.663842098575,
    "lat": 41.61225582396516
  },
  {
    "name": "Crook",
    "lng": -102.8013892355681,
    "lat": 40.85875390538419
  },
  {
    "name": "Crooked Lake Park",
    "lng": -81.58955743581843,
    "lat": 27.829302817511078
  },
  {
    "name": "Crooks",
    "lng": -96.80923300323336,
    "lat": 43.65906245639558
  },
  {
    "name": "Crookston",
    "lng": -100.7534355940168,
    "lat": 42.9263108595221
  },
  {
    "name": "Crookston",
    "lng": -96.60628428384723,
    "lat": 47.7746557800698
  },
  {
    "name": "Crooksville",
    "lng": -82.09554186511018,
    "lat": 39.769168363240574
  },
  {
    "name": "Crosby",
    "lng": -103.29695308164023,
    "lat": 48.91640757798687
  },
  {
    "name": "Crosby",
    "lng": -93.95739706666392,
    "lat": 46.48930340478526
  },
  {
    "name": "Crosby",
    "lng": -91.06319440382124,
    "lat": 31.27948499470695
  },
  {
    "name": "Crosbyton",
    "lng": -101.2375711660675,
    "lat": 33.64050002543887
  },
  {
    "name": "Cross City",
    "lng": -83.13451259189857,
    "lat": 29.618833233030372
  },
  {
    "name": "Cross City",
    "lng": -83.1242879984364,
    "lat": 29.63756117603624
  },
  {
    "name": "Cross City",
    "lng": -83.12179596692117,
    "lat": 29.622913816345243
  },
  {
    "name": "Cross City",
    "lng": -83.11973588975742,
    "lat": 29.63905863477102
  },
  {
    "name": "Cross Hill",
    "lng": -81.98338527765681,
    "lat": 34.30477255586594
  },
  {
    "name": "Cross Plains",
    "lng": -99.16577547826577,
    "lat": 32.12706840552054
  },
  {
    "name": "Cross Plains",
    "lng": -86.67497004162533,
    "lat": 36.54083267890109
  },
  {
    "name": "Cross Timber",
    "lng": -97.32603039028086,
    "lat": 32.48325193774249
  },
  {
    "name": "Cross Timbers",
    "lng": -93.22935881078526,
    "lat": 38.02427318743308
  },
  {
    "name": "Crossett",
    "lng": -91.96317670135254,
    "lat": 33.12802753352524
  },
  {
    "name": "Crossgate",
    "lng": -85.62966428395862,
    "lat": 38.27928103199513
  },
  {
    "name": "Crosslake",
    "lng": -94.09317572640457,
    "lat": 46.67443450500137
  },
  {
    "name": "Crossnore",
    "lng": -81.93073696957808,
    "lat": 36.02209824469265
  },
  {
    "name": "Crossville",
    "lng": -88.06433836089167,
    "lat": 38.162478058436854
  },
  {
    "name": "Crossville",
    "lng": -85.99344442821574,
    "lat": 34.28680176536128
  },
  {
    "name": "Crossville",
    "lng": -85.03052037884133,
    "lat": 35.95144066817912
  },
  {
    "name": "Crossville",
    "lng": -85.02470395396183,
    "lat": 35.979721253707055
  },
  {
    "name": "Crossville",
    "lng": -84.98827816225312,
    "lat": 35.91356956680381
  },
  {
    "name": "Croswell",
    "lng": -82.61773050053363,
    "lat": 43.273653371871696
  },
  {
    "name": "Crothersville",
    "lng": -85.8397684437231,
    "lat": 38.79462040061925
  },
  {
    "name": "Croton-on-Hudson",
    "lng": -73.89974684816204,
    "lat": 41.2002476369992
  },
  {
    "name": "Crouch",
    "lng": -115.97278656901406,
    "lat": 44.11603834278836
  },
  {
    "name": "Crowder",
    "lng": -95.67873752779387,
    "lat": 35.11565647026766
  },
  {
    "name": "Crowder",
    "lng": -90.13770093495786,
    "lat": 34.17292387090509
  },
  {
    "name": "Crowley",
    "lng": -103.85979547500037,
    "lat": 38.193516834879794
  },
  {
    "name": "Crowley",
    "lng": -97.35891699149671,
    "lat": 32.577772839397966
  },
  {
    "name": "Crowley",
    "lng": -92.37530374043924,
    "lat": 30.21734825261716
  },
  {
    "name": "Crown City",
    "lng": -82.29110360284348,
    "lat": 38.589998828957846
  },
  {
    "name": "Crown Point",
    "lng": -87.34532950088087,
    "lat": 41.413910606836104
  },
  {
    "name": "Crows Nest",
    "lng": -86.16906350667504,
    "lat": 39.85648116532549
  },
  {
    "name": "Cruger",
    "lng": -90.23551171469833,
    "lat": 33.32301711337052
  },
  {
    "name": "Crump",
    "lng": -88.33589901761245,
    "lat": 35.23173651938334
  },
  {
    "name": "Crystal",
    "lng": -97.66891403129398,
    "lat": 48.598157790240165
  },
  {
    "name": "Crystal",
    "lng": -93.3776555443995,
    "lat": 45.02434439480585
  },
  {
    "name": "Crystal",
    "lng": -93.3595846907042,
    "lat": 45.037918065336704
  },
  {
    "name": "Crystal",
    "lng": -68.36550031892672,
    "lat": 45.990314522031184
  },
  {
    "name": "Crystal City",
    "lng": -99.82571323536558,
    "lat": 28.690848554618995
  },
  {
    "name": "Crystal City",
    "lng": -90.38051508232103,
    "lat": 38.22354443042349
  },
  {
    "name": "Crystal Falls",
    "lng": -88.32648228060717,
    "lat": 46.09677925782176
  },
  {
    "name": "Crystal Lake",
    "lng": -93.79275425347733,
    "lat": 43.22308276133791
  },
  {
    "name": "Crystal Lake",
    "lng": -88.41587483142649,
    "lat": 42.25689627028372
  },
  {
    "name": "Crystal Lake",
    "lng": -88.40754065181355,
    "lat": 42.24808215572808
  },
  {
    "name": "Crystal Lake",
    "lng": -88.37466693356433,
    "lat": 42.23488435677766
  },
  {
    "name": "Crystal Lake",
    "lng": -88.35472291512862,
    "lat": 42.25866344173068
  },
  {
    "name": "Crystal Lake",
    "lng": -88.33013923909641,
    "lat": 42.232085566645935
  },
  {
    "name": "Crystal Lake",
    "lng": -88.28029741024754,
    "lat": 42.25433641408054
  },
  {
    "name": "Crystal Lake Park",
    "lng": -90.43200671230413,
    "lat": 38.62124745543472
  },
  {
    "name": "Crystal Lakes",
    "lng": -94.18741119168777,
    "lat": 39.360695626775474
  },
  {
    "name": "Crystal River",
    "lng": -82.65092126773592,
    "lat": 28.91724960431654
  },
  {
    "name": "Crystal River",
    "lng": -82.644853312051,
    "lat": 28.91854003143754
  },
  {
    "name": "Crystal River",
    "lng": -82.59829245812033,
    "lat": 28.869241944807133
  },
  {
    "name": "Crystal River",
    "lng": -82.59640572452263,
    "lat": 28.895331159714168
  },
  {
    "name": "Crystal River",
    "lng": -82.58714278532838,
    "lat": 28.913477301383857
  },
  {
    "name": "Crystal Springs",
    "lng": -90.35403444856745,
    "lat": 31.990312750494255
  },
  {
    "name": "Cuba",
    "lng": -106.95175373330218,
    "lat": 36.021319993786044
  },
  {
    "name": "Cuba",
    "lng": -97.45727848968416,
    "lat": 39.802129558379015
  },
  {
    "name": "Cuba",
    "lng": -91.41203896724228,
    "lat": 38.05404517377542
  },
  {
    "name": "Cuba",
    "lng": -91.40572108292965,
    "lat": 38.06718726935252
  },
  {
    "name": "Cuba",
    "lng": -90.1933546352652,
    "lat": 40.49359896136632
  },
  {
    "name": "Cuba",
    "lng": -88.37553155761046,
    "lat": 32.44106032366325
  },
  {
    "name": "Cuba",
    "lng": -78.27532147331212,
    "lat": 42.21832231055324
  },
  {
    "name": "Cuba City",
    "lng": -90.43233505372709,
    "lat": 42.604123410918675
  },
  {
    "name": "Cudahy",
    "lng": -118.1831800269293,
    "lat": 33.96324207281388
  },
  {
    "name": "Cudahy",
    "lng": -87.86399714731616,
    "lat": 42.94671022362162
  },
  {
    "name": "Cuero",
    "lng": -97.28706117645339,
    "lat": 29.102332591608967
  },
  {
    "name": "Culbertson",
    "lng": -104.5152619358554,
    "lat": 48.14726548579127
  },
  {
    "name": "Culbertson",
    "lng": -100.83512356107074,
    "lat": 40.22839848029588
  },
  {
    "name": "Culdesac",
    "lng": -116.67009686519867,
    "lat": 46.374895834794664
  },
  {
    "name": "Cullen",
    "lng": -93.44655485198753,
    "lat": 32.96972125126907
  },
  {
    "name": "Cullison",
    "lng": -98.90528535019018,
    "lat": 37.63032909547395
  },
  {
    "name": "Cullman",
    "lng": -86.88767293988069,
    "lat": 34.22002610925704
  },
  {
    "name": "Cullman",
    "lng": -86.88623701046927,
    "lat": 34.194311329882076
  },
  {
    "name": "Cullman",
    "lng": -86.88089390884946,
    "lat": 34.15539745483965
  },
  {
    "name": "Cullman",
    "lng": -86.84093161292886,
    "lat": 34.18060691097622
  },
  {
    "name": "Cullman",
    "lng": -86.81790864338286,
    "lat": 34.13989082952764
  },
  {
    "name": "Cullman",
    "lng": -86.81509209246114,
    "lat": 34.13184155917962
  },
  {
    "name": "Cullman",
    "lng": -86.80928317162093,
    "lat": 34.13128538019463
  },
  {
    "name": "Cullman",
    "lng": -86.8070370755095,
    "lat": 34.197639769719615
  },
  {
    "name": "Cullman",
    "lng": -86.80699110396341,
    "lat": 34.19565664732338
  },
  {
    "name": "Cullman",
    "lng": -86.80428841840413,
    "lat": 34.196559135301506
  },
  {
    "name": "Cullman",
    "lng": -86.79437675202648,
    "lat": 34.19255960311834
  },
  {
    "name": "Cullman",
    "lng": -86.78071579188787,
    "lat": 34.19017643171501
  },
  {
    "name": "Cullom",
    "lng": -88.2698428922519,
    "lat": 40.878472030422905
  },
  {
    "name": "Culpeper",
    "lng": -78.00111039880242,
    "lat": 38.470110800361795
  },
  {
    "name": "Culver",
    "lng": -97.75913281019237,
    "lat": 38.968611215172096
  },
  {
    "name": "Culver",
    "lng": -86.42193683119339,
    "lat": 41.217715495639375
  },
  {
    "name": "Cumberland",
    "lng": -94.86909503041467,
    "lat": 41.27364410635564
  },
  {
    "name": "Cumberland",
    "lng": -92.03806379207903,
    "lat": 45.559205726239405
  },
  {
    "name": "Cumberland",
    "lng": -92.02755833391714,
    "lat": 45.53529382600934
  },
  {
    "name": "Cumberland",
    "lng": -92.0077495871603,
    "lat": 45.53198560782338
  },
  {
    "name": "Cumberland",
    "lng": -92.00606724902475,
    "lat": 45.532918461853285
  },
  {
    "name": "Cumberland",
    "lng": -85.94558886661123,
    "lat": 39.78433044563573
  },
  {
    "name": "Cumberland",
    "lng": -82.98621425926623,
    "lat": 36.98402569594564
  },
  {
    "name": "Cumberland",
    "lng": -81.65872252905085,
    "lat": 39.853280834089716
  },
  {
    "name": "Cumberland",
    "lng": -71.41969545011408,
    "lat": 41.97026167755899
  },
  {
    "name": "Cumberland",
    "lng": -70.24850690421142,
    "lat": 43.78756443644945
  },
  {
    "name": "Cumberland City",
    "lng": -87.63955665615168,
    "lat": 36.38290185367205
  },
  {
    "name": "Cumberland Gap",
    "lng": -83.665805525671,
    "lat": 36.59745374722475
  },
  {
    "name": "Cumby",
    "lng": -95.8383539332846,
    "lat": 33.13436062521364
  },
  {
    "name": "Cumming",
    "lng": -93.78527775196453,
    "lat": 41.48545913124422
  },
  {
    "name": "Cumming",
    "lng": -93.75301301238363,
    "lat": 41.488141308546425
  },
  {
    "name": "Cumming",
    "lng": -84.1357589898978,
    "lat": 34.18231154313321
  },
  {
    "name": "Cumming",
    "lng": -84.1339405025846,
    "lat": 34.20643615245897
  },
  {
    "name": "Cumming",
    "lng": -84.10743350904164,
    "lat": 34.229159989317004
  },
  {
    "name": "Cummington",
    "lng": -72.91958459833643,
    "lat": 42.463139558569786
  },
  {
    "name": "Cuney",
    "lng": -95.41475716408111,
    "lat": 32.0374289393098
  },
  {
    "name": "Cunningham",
    "lng": -98.4324904220985,
    "lat": 37.645077175212414
  },
  {
    "name": "Cupertino",
    "lng": -122.0453413149595,
    "lat": 37.317224300995306
  },
  {
    "name": "Curlew",
    "lng": -94.73779082327543,
    "lat": 42.979742017480405
  },
  {
    "name": "Curran",
    "lng": -89.77821569255588,
    "lat": 39.742190043666994
  },
  {
    "name": "Currie",
    "lng": -95.66689254508123,
    "lat": 44.0704476220781
  },
  {
    "name": "Curryville",
    "lng": -91.3413494082643,
    "lat": 39.34574256101954
  },
  {
    "name": "Curtis",
    "lng": -100.51126752062332,
    "lat": 40.63356274234902
  },
  {
    "name": "Curtiss",
    "lng": -90.43496313269543,
    "lat": 44.95224273019368
  },
  {
    "name": "Cushing",
    "lng": -98.36925502411316,
    "lat": 41.29482592555419
  },
  {
    "name": "Cushing",
    "lng": -96.76091385507486,
    "lat": 35.97969758119754
  },
  {
    "name": "Cushing",
    "lng": -95.67570141079689,
    "lat": 42.46496199077445
  },
  {
    "name": "Cushing",
    "lng": -94.84114949640828,
    "lat": 31.812604024579112
  },
  {
    "name": "Cushing",
    "lng": -69.2601889408954,
    "lat": 44.010608781204404
  },
  {
    "name": "Cushman",
    "lng": -91.77808697595783,
    "lat": 35.86584602848127
  },
  {
    "name": "Cusick",
    "lng": -117.29544080882596,
    "lat": 48.33436358950108
  },
  {
    "name": "Cusseta",
    "lng": -85.30574789105529,
    "lat": 32.787655013776835
  },
  {
    "name": "Cusseta",
    "lng": -84.76909420998514,
    "lat": 32.305627546851575
  },
  {
    "name": "Custar",
    "lng": -83.8438097037859,
    "lat": 41.2845959978702
  },
  {
    "name": "Custer",
    "lng": -103.6001700546559,
    "lat": 43.767175622454005
  },
  {
    "name": "Custer",
    "lng": -103.57436499336632,
    "lat": 43.77209117179208
  },
  {
    "name": "Custer",
    "lng": -86.21855811096613,
    "lat": 43.950984074666245
  },
  {
    "name": "Custer City",
    "lng": -98.88692434743844,
    "lat": 35.663767604490715
  },
  {
    "name": "Cut Bank",
    "lng": -112.33088279794568,
    "lat": 48.63452702124282
  },
  {
    "name": "Cut and Shoot",
    "lng": -95.35379783792388,
    "lat": 30.340370643046775
  },
  {
    "name": "Cuthbert",
    "lng": -84.79402742866279,
    "lat": 31.771328386503757
  },
  {
    "name": "Cutler",
    "lng": -119.28827410932159,
    "lat": 36.52624520818942
  },
  {
    "name": "Cutler",
    "lng": -89.56769052921418,
    "lat": 38.03234236289307
  },
  {
    "name": "Cutler",
    "lng": -89.56340786411673,
    "lat": 38.03983825130252
  },
  {
    "name": "Cutler",
    "lng": -67.22422106942905,
    "lat": 44.64514312476744
  },
  {
    "name": "Cuyahoga Falls",
    "lng": -81.58084392774042,
    "lat": 41.13785426466796
  },
  {
    "name": "Cuyahoga Falls",
    "lng": -81.57749125288889,
    "lat": 41.140613667975735
  },
  {
    "name": "Cuyahoga Falls",
    "lng": -81.57651417313717,
    "lat": 41.1398080901338
  },
  {
    "name": "Cuyahoga Falls",
    "lng": -81.56819641867904,
    "lat": 41.14197875994422
  },
  {
    "name": "Cuyahoga Falls",
    "lng": -81.5204244009189,
    "lat": 41.16412088357568
  },
  {
    "name": "Cuyahoga Heights",
    "lng": -81.65614087675819,
    "lat": 41.43501250174902
  },
  {
    "name": "Cuyler Town",
    "lng": -75.93700925323544,
    "lat": 42.726223541980396
  },
  {
    "name": "Cuyuna",
    "lng": -93.9258004619246,
    "lat": 46.51145438964386
  },
  {
    "name": "Cygnet",
    "lng": -83.64374378815661,
    "lat": 41.240682645572356
  },
  {
    "name": "Cylinder",
    "lng": -94.55418964218997,
    "lat": 43.09114421338054
  },
  {
    "name": "Cynthiana",
    "lng": -87.70852984090077,
    "lat": 38.187257824089784
  },
  {
    "name": "Cynthiana",
    "lng": -84.31877907558805,
    "lat": 38.378631421835244
  },
  {
    "name": "Cynthiana",
    "lng": -84.28913990870267,
    "lat": 38.38835078514968
  },
  {
    "name": "Cypress",
    "lng": -118.03862154182444,
    "lat": 33.81714952405671
  },
  {
    "name": "Cypress",
    "lng": -89.01873404120731,
    "lat": 37.36545744209855
  },
  {
    "name": "Cypress Lake",
    "lng": -81.89994791263182,
    "lat": 26.539114554489807
  },
  {
    "name": "Cyril",
    "lng": -98.20305415491693,
    "lat": 34.89863990512682
  },
  {
    "name": "Cyrus",
    "lng": -95.73820535486425,
    "lat": 45.61478683749616
  },
  {
    "name": "D'Iberville",
    "lng": -88.89849612424062,
    "lat": 30.45451470006216
  },
  {
    "name": "D'Lo",
    "lng": -89.90112564155828,
    "lat": 31.986917832435118
  },
  {
    "name": "DISH",
    "lng": -97.30356384198734,
    "lat": 33.12947489669794
  },
  {
    "name": "Dacoma",
    "lng": -98.5635219318532,
    "lat": 36.659597096747625
  },
  {
    "name": "Dacono",
    "lng": -104.94982685495602,
    "lat": 40.06173128866181
  },
  {
    "name": "Dacula",
    "lng": -83.92273320039016,
    "lat": 33.958806729235924
  },
  {
    "name": "Dacula",
    "lng": -83.8943929053145,
    "lat": 33.981979482134555
  },
  {
    "name": "Dade City",
    "lng": -82.23913566757828,
    "lat": 28.351500832951427
  },
  {
    "name": "Dade City",
    "lng": -82.2164478418693,
    "lat": 28.37563289415176
  },
  {
    "name": "Dade City",
    "lng": -82.21082561238993,
    "lat": 28.374978355935724
  },
  {
    "name": "Dade City",
    "lng": -82.19434522876739,
    "lat": 28.376661007240713
  },
  {
    "name": "Dade City",
    "lng": -82.19359914813614,
    "lat": 28.35513362950285
  },
  {
    "name": "Dade City",
    "lng": -82.19264910294962,
    "lat": 28.376698904856077
  },
  {
    "name": "Dade City",
    "lng": -82.19100335123449,
    "lat": 28.378703425915973
  },
  {
    "name": "Dade City",
    "lng": -82.19017754492491,
    "lat": 28.327426936472023
  },
  {
    "name": "Dade City",
    "lng": -82.18872973077957,
    "lat": 28.372653847106648
  },
  {
    "name": "Dade City",
    "lng": -82.18836410355517,
    "lat": 28.37335878830498
  },
  {
    "name": "Dade City",
    "lng": -82.18827592157501,
    "lat": 28.378090589950272
  },
  {
    "name": "Dade City",
    "lng": -82.18825067849171,
    "lat": 28.336344429591588
  },
  {
    "name": "Dade City",
    "lng": -82.18798947605141,
    "lat": 28.37280627367856
  },
  {
    "name": "Dade City",
    "lng": -82.18520422237701,
    "lat": 28.326632710112587
  },
  {
    "name": "Dade City",
    "lng": -82.18332599002967,
    "lat": 28.330786837056618
  },
  {
    "name": "Dade City",
    "lng": -82.16598689668035,
    "lat": 28.36932229684162
  },
  {
    "name": "Dadeville",
    "lng": -93.6738581822318,
    "lat": 37.47933321798055
  },
  {
    "name": "Dadeville",
    "lng": -85.76749565501893,
    "lat": 32.83259206252745
  },
  {
    "name": "Daggett",
    "lng": -87.60462067486131,
    "lat": 45.46198486425205
  },
  {
    "name": "Dahlgren",
    "lng": -88.6846863300142,
    "lat": 38.198052126597915
  },
  {
    "name": "Dahlonega",
    "lng": -83.97899308544197,
    "lat": 34.53027757631739
  },
  {
    "name": "Dahlonega",
    "lng": -83.97389126687054,
    "lat": 34.513547294777894
  },
  {
    "name": "Daingerfield",
    "lng": -94.72493441993656,
    "lat": 33.030443657825415
  },
  {
    "name": "Daisetta",
    "lng": -94.64256901171284,
    "lat": 30.113515124804007
  },
  {
    "name": "Daisy",
    "lng": -93.74055764281395,
    "lat": 34.23493255015012
  },
  {
    "name": "Daisy",
    "lng": -81.83558655589721,
    "lat": 32.15114701215574
  },
  {
    "name": "Dakota",
    "lng": -91.35801815843364,
    "lat": 43.91212840636195
  },
  {
    "name": "Dakota",
    "lng": -89.52656505366022,
    "lat": 42.387917175142164
  },
  {
    "name": "Dakota City",
    "lng": -96.41805822907806,
    "lat": 42.41675736632805
  },
  {
    "name": "Dakota City",
    "lng": -94.19767725811094,
    "lat": 42.7229304571182
  },
  {
    "name": "Dale",
    "lng": -86.98546213539345,
    "lat": 38.17347649813916
  },
  {
    "name": "Daleville",
    "lng": -85.713574002453,
    "lat": 31.289386766104883
  },
  {
    "name": "Daleville",
    "lng": -85.55740019532588,
    "lat": 40.118730269320906
  },
  {
    "name": "Dalhart",
    "lng": -102.51251965163455,
    "lat": 36.058195553891245
  },
  {
    "name": "Dallas",
    "lng": -99.51750704972633,
    "lat": 43.23783808777502
  },
  {
    "name": "Dallas",
    "lng": -91.81424539301156,
    "lat": 45.25906318555816
  },
  {
    "name": "Dallas",
    "lng": -84.84194203400693,
    "lat": 33.91534767304711
  },
  {
    "name": "Dallas",
    "lng": -84.79992187560946,
    "lat": 33.914557656612914
  },
  {
    "name": "Dallas",
    "lng": -84.78579990839499,
    "lat": 33.9083385668613
  },
  {
    "name": "Dallas",
    "lng": -84.78403782476676,
    "lat": 33.90702955625294
  },
  {
    "name": "Dallas",
    "lng": -81.1991442342429,
    "lat": 35.32812395875307
  },
  {
    "name": "Dallas",
    "lng": -81.19438521144814,
    "lat": 35.33034608443463
  },
  {
    "name": "Dallas",
    "lng": -81.18490354314962,
    "lat": 35.31685430360327
  },
  {
    "name": "Dallas",
    "lng": -81.17321494484216,
    "lat": 35.3262828507953
  },
  {
    "name": "Dallas",
    "lng": -81.16479605442198,
    "lat": 35.322086386704136
  },
  {
    "name": "Dallas",
    "lng": -81.16344143591174,
    "lat": 35.311497782715335
  },
  {
    "name": "Dallas",
    "lng": -81.16063656208728,
    "lat": 35.311386614757936
  },
  {
    "name": "Dallas",
    "lng": -81.15798245995367,
    "lat": 35.31130959541316
  },
  {
    "name": "Dallas",
    "lng": -81.15725239525307,
    "lat": 35.32568165409027
  },
  {
    "name": "Dallas",
    "lng": -81.15725138952044,
    "lat": 35.31446614241575
  },
  {
    "name": "Dallas",
    "lng": -81.15716113577777,
    "lat": 35.301633498374905
  },
  {
    "name": "Dallas",
    "lng": -81.14832884271327,
    "lat": 35.32613981083884
  },
  {
    "name": "Dallas",
    "lng": -81.13400291044903,
    "lat": 35.33073900478424
  },
  {
    "name": "Dallas",
    "lng": -81.1337625862468,
    "lat": 35.32794687980093
  },
  {
    "name": "Dallas Center",
    "lng": -93.98163168933328,
    "lat": 41.68539569442277
  },
  {
    "name": "Dallas City",
    "lng": -91.16446656699402,
    "lat": 40.63527993595833
  },
  {
    "name": "Dalton",
    "lng": -102.97079534491112,
    "lat": 41.408215677414205
  },
  {
    "name": "Dalton",
    "lng": -95.915519196331,
    "lat": 46.17390299171706
  },
  {
    "name": "Dalton",
    "lng": -92.9920884759562,
    "lat": 39.397655710494845
  },
  {
    "name": "Dalton",
    "lng": -84.99868927671034,
    "lat": 34.74288964098227
  },
  {
    "name": "Dalton",
    "lng": -84.98697675064584,
    "lat": 34.73807792818902
  },
  {
    "name": "Dalton",
    "lng": -84.98176384908876,
    "lat": 34.77101520487373
  },
  {
    "name": "Dalton",
    "lng": -84.93418623264462,
    "lat": 34.810922437895904
  },
  {
    "name": "Dalton",
    "lng": -84.92302625535117,
    "lat": 34.802447408898345
  },
  {
    "name": "Dalton",
    "lng": -84.86993312078113,
    "lat": 34.72433809368008
  },
  {
    "name": "Dalton",
    "lng": -81.70378348064885,
    "lat": 40.79921222733291
  },
  {
    "name": "Dalton",
    "lng": -73.15329714676712,
    "lat": 42.47956361894483
  },
  {
    "name": "Dalton City",
    "lng": -88.8065323146991,
    "lat": 39.71674829262073
  },
  {
    "name": "Dalton Gardens",
    "lng": -116.76958267007676,
    "lat": 47.732864174216225
  },
  {
    "name": "Dalworthington Gardens",
    "lng": -97.15654125481761,
    "lat": 32.693666574429514
  },
  {
    "name": "Dalzell",
    "lng": -89.17664992881431,
    "lat": 41.35605920761103
  },
  {
    "name": "Damar",
    "lng": -99.58474297907325,
    "lat": 39.319239790957006
  },
  {
    "name": "Damariscotta",
    "lng": -69.49209507343929,
    "lat": 44.03448560553355
  },
  {
    "name": "Damascus",
    "lng": -92.4108478601405,
    "lat": 35.367150173856764
  },
  {
    "name": "Damascus",
    "lng": -92.38962766030015,
    "lat": 35.36656157974136
  },
  {
    "name": "Damascus",
    "lng": -84.71698073962695,
    "lat": 31.2988619453645
  },
  {
    "name": "Damascus",
    "lng": -81.79337733333332,
    "lat": 36.62073999997985
  },
  {
    "name": "Damascus",
    "lng": -81.78920205027852,
    "lat": 36.632725172670625
  },
  {
    "name": "Damiansville",
    "lng": -89.61501950393561,
    "lat": 38.50877766561692
  },
  {
    "name": "Dana",
    "lng": -94.23870660084916,
    "lat": 42.10717162906038
  },
  {
    "name": "Dana",
    "lng": -88.94995468850529,
    "lat": 40.95650455068903
  },
  {
    "name": "Dana",
    "lng": -87.49453428325572,
    "lat": 39.80725664988841
  },
  {
    "name": "Dana Point",
    "lng": -117.70685587847998,
    "lat": 33.443074985842365
  },
  {
    "name": "Danbury",
    "lng": -100.40510400253862,
    "lat": 40.03770763879105
  },
  {
    "name": "Danbury",
    "lng": -95.7222677007012,
    "lat": 42.23545362324945
  },
  {
    "name": "Danbury",
    "lng": -95.34617984878284,
    "lat": 29.22742188305227
  },
  {
    "name": "Danbury",
    "lng": -80.21328504519947,
    "lat": 36.41003489780908
  },
  {
    "name": "Danbury",
    "lng": -73.47090465660726,
    "lat": 41.40160317960441
  },
  {
    "name": "Danbury",
    "lng": -71.86668410475781,
    "lat": 43.527711463743174
  },
  {
    "name": "Dandridge",
    "lng": -83.4308547958936,
    "lat": 36.02901617583011
  },
  {
    "name": "Dane",
    "lng": -89.49916298460889,
    "lat": 43.24977265880886
  },
  {
    "name": "Danforth",
    "lng": -87.97785966653824,
    "lat": 40.82197314378409
  },
  {
    "name": "Danforth",
    "lng": -67.83807101416797,
    "lat": 45.62709437253429
  },
  {
    "name": "Daniel",
    "lng": -111.40990527945335,
    "lat": 40.46715930530856
  },
  {
    "name": "Danielsville",
    "lng": -83.22017281528481,
    "lat": 34.12367550931126
  },
  {
    "name": "Danielsville",
    "lng": -83.21128550399779,
    "lat": 34.12939690121902
  },
  {
    "name": "Danielsville",
    "lng": -83.20946347603744,
    "lat": 34.12836170035202
  },
  {
    "name": "Dannebrog",
    "lng": -98.54573709260404,
    "lat": 41.11860619142982
  },
  {
    "name": "Dannemora",
    "lng": -73.71905922004771,
    "lat": 44.7197115337985
  },
  {
    "name": "Dansville",
    "lng": -84.30300155045266,
    "lat": 42.55582913308734
  },
  {
    "name": "Dansville",
    "lng": -77.69694626528633,
    "lat": 42.5623848884606
  },
  {
    "name": "Dante",
    "lng": -98.18630715352081,
    "lat": 43.0407220390384
  },
  {
    "name": "Danube",
    "lng": -95.10248510856184,
    "lat": 44.79196983998546
  },
  {
    "name": "Danvers",
    "lng": -95.75593096864479,
    "lat": 45.28146695680772
  },
  {
    "name": "Danvers",
    "lng": -89.17533039974222,
    "lat": 40.52975354889783
  },
  {
    "name": "Danville",
    "lng": -121.96985039277989,
    "lat": 37.81215275011438
  },
  {
    "name": "Danville",
    "lng": -97.89207150573297,
    "lat": 37.28595597723349
  },
  {
    "name": "Danville",
    "lng": -93.39019667514134,
    "lat": 35.05288971376344
  },
  {
    "name": "Danville",
    "lng": -91.31447390993497,
    "lat": 40.85996798092549
  },
  {
    "name": "Danville",
    "lng": -87.61147323517221,
    "lat": 40.142257471040274
  },
  {
    "name": "Danville",
    "lng": -86.51265032952895,
    "lat": 39.759722439611735
  },
  {
    "name": "Danville",
    "lng": -84.77702729033527,
    "lat": 37.64263292110734
  },
  {
    "name": "Danville",
    "lng": -83.2461272443154,
    "lat": 32.6058436880594
  },
  {
    "name": "Danville",
    "lng": -82.26072224637502,
    "lat": 40.44708252090774
  },
  {
    "name": "Danville",
    "lng": -81.83425004536514,
    "lat": 38.080773430790785
  },
  {
    "name": "Danville",
    "lng": -79.4088267740366,
    "lat": 36.58315358649427
  },
  {
    "name": "Danville",
    "lng": -76.61208958132887,
    "lat": 40.96147319191232
  },
  {
    "name": "Danville",
    "lng": -71.121122915125,
    "lat": 42.928741199571796
  },
  {
    "name": "Daphne",
    "lng": -87.88989051390445,
    "lat": 30.630757257769933
  },
  {
    "name": "Daphne",
    "lng": -87.85653899095942,
    "lat": 30.572000008871733
  },
  {
    "name": "Daphne",
    "lng": -87.85635381289563,
    "lat": 30.584500833463018
  },
  {
    "name": "Darby",
    "lng": -114.17968122928686,
    "lat": 46.02349639902042
  },
  {
    "name": "Darbyville",
    "lng": -83.11379616914117,
    "lat": 39.695716456089286
  },
  {
    "name": "Dardanelle",
    "lng": -93.16417026842514,
    "lat": 35.22520966763436
  },
  {
    "name": "Dardenne Prairie",
    "lng": -90.73181209058501,
    "lat": 38.75471209676443
  },
  {
    "name": "Dardenne Prairie",
    "lng": -90.71938549561143,
    "lat": 38.77544619314915
  },
  {
    "name": "Dardenne Prairie",
    "lng": -90.70494735376163,
    "lat": 38.73430793776594
  },
  {
    "name": "Dardenne Prairie",
    "lng": -90.7045483155731,
    "lat": 38.73547584078967
  },
  {
    "name": "Dardenne Prairie",
    "lng": -90.70223890592817,
    "lat": 38.73783543996776
  },
  {
    "name": "Dardenne Prairie",
    "lng": -90.70194992020508,
    "lat": 38.733856898891915
  },
  {
    "name": "Dardenne Prairie",
    "lng": -90.70133820063273,
    "lat": 38.73634541473963
  },
  {
    "name": "Dardenne Prairie",
    "lng": -90.69952681798529,
    "lat": 38.7308869352326
  },
  {
    "name": "Darfur",
    "lng": -94.83775562712773,
    "lat": 44.05349701760043
  },
  {
    "name": "Darien",
    "lng": -88.71308588452924,
    "lat": 42.60075463514355
  },
  {
    "name": "Darien",
    "lng": -88.70839792649784,
    "lat": 42.593234668059246
  },
  {
    "name": "Darien",
    "lng": -88.00728322652027,
    "lat": 41.70094458860701
  },
  {
    "name": "Darien",
    "lng": -87.98216711202328,
    "lat": 41.744864880567604
  },
  {
    "name": "Darien",
    "lng": -81.43879695090918,
    "lat": 31.41032908862088
  },
  {
    "name": "Darien",
    "lng": -81.43167756051275,
    "lat": 31.34861508560842
  },
  {
    "name": "Darien",
    "lng": -81.42079640590997,
    "lat": 31.411715906926503
  },
  {
    "name": "Darien",
    "lng": -73.47904646750222,
    "lat": 41.052050627314976
  },
  {
    "name": "Darlington",
    "lng": -94.39968204395902,
    "lat": 40.19800361696075
  },
  {
    "name": "Darlington",
    "lng": -90.1266184118577,
    "lat": 42.66466067735834
  },
  {
    "name": "Darlington",
    "lng": -90.11668919240437,
    "lat": 42.681364605509295
  },
  {
    "name": "Darlington",
    "lng": -86.77745779432064,
    "lat": 40.10759652043425
  },
  {
    "name": "Darmstadt",
    "lng": -87.57980001043927,
    "lat": 38.09380842953818
  },
  {
    "name": "Darrington",
    "lng": -121.60298374050102,
    "lat": 48.250856731574956
  },
  {
    "name": "Darrouzett",
    "lng": -100.32579245285488,
    "lat": 36.444885852839754
  },
  {
    "name": "Darwin",
    "lng": -94.41358876209907,
    "lat": 45.096794511101464
  },
  {
    "name": "Dasher",
    "lng": -83.2254717418128,
    "lat": 30.74766877233986
  },
  {
    "name": "Dassel",
    "lng": -94.32515498793698,
    "lat": 45.07382460391791
  },
  {
    "name": "Dassel",
    "lng": -94.3149133373476,
    "lat": 45.08302524982381
  },
  {
    "name": "Datto",
    "lng": -90.72849508233622,
    "lat": 36.39246388655006
  },
  {
    "name": "Dauphin Island",
    "lng": -88.1835235963188,
    "lat": 30.246046368932106
  },
  {
    "name": "Davenport",
    "lng": -118.17476280434063,
    "lat": 47.6436797157353
  },
  {
    "name": "Davenport",
    "lng": -118.15964457197228,
    "lat": 47.66202932252515
  },
  {
    "name": "Davenport",
    "lng": -118.15393349384263,
    "lat": 47.65104084938946
  },
  {
    "name": "Davenport",
    "lng": -118.14608145944777,
    "lat": 47.66889887218735
  },
  {
    "name": "Davenport",
    "lng": -118.14309375513767,
    "lat": 47.66215038304107
  },
  {
    "name": "Davenport",
    "lng": -97.81151237730845,
    "lat": 40.31231083856567
  },
  {
    "name": "Davenport",
    "lng": -97.06496015796905,
    "lat": 46.71492004846532
  },
  {
    "name": "Davenport",
    "lng": -96.77318680452997,
    "lat": 35.718938729156164
  },
  {
    "name": "Davenport",
    "lng": -96.77173129706529,
    "lat": 35.70384322600532
  },
  {
    "name": "Davenport",
    "lng": -96.76418218682196,
    "lat": 35.7078938764064
  },
  {
    "name": "Davenport",
    "lng": -90.60530885489496,
    "lat": 41.5565922674998
  },
  {
    "name": "Davenport",
    "lng": -81.61270307981604,
    "lat": 28.15872603699052
  },
  {
    "name": "Davenport",
    "lng": -81.60918956994749,
    "lat": 28.145643733503217
  },
  {
    "name": "Davenport",
    "lng": -81.58535501530532,
    "lat": 28.158563097133808
  },
  {
    "name": "Davenport",
    "lng": -81.56368886779119,
    "lat": 28.16120683415152
  },
  {
    "name": "Davey",
    "lng": -96.66919325092728,
    "lat": 40.98375936363643
  },
  {
    "name": "David City",
    "lng": -97.12709220782608,
    "lat": 41.254582003057685
  },
  {
    "name": "Davidson",
    "lng": -99.07796263721164,
    "lat": 34.24259491157191
  },
  {
    "name": "Davidson",
    "lng": -80.8499820042327,
    "lat": 35.51711724733115
  },
  {
    "name": "Davidson",
    "lng": -80.82510356456956,
    "lat": 35.48400626442504
  },
  {
    "name": "Davidson",
    "lng": -80.81685342116596,
    "lat": 35.47015515891679
  },
  {
    "name": "Davis",
    "lng": -121.7370956729015,
    "lat": 38.55524236940841
  },
  {
    "name": "Davis",
    "lng": -97.18376068290041,
    "lat": 34.506213756387126
  },
  {
    "name": "Davis",
    "lng": -97.16561162837084,
    "lat": 34.45936366184255
  },
  {
    "name": "Davis",
    "lng": -97.12527177199047,
    "lat": 34.455773379017685
  },
  {
    "name": "Davis",
    "lng": -96.99450350196379,
    "lat": 43.25952527931113
  },
  {
    "name": "Davis",
    "lng": -89.41488147170416,
    "lat": 42.4215806781712
  },
  {
    "name": "Davis",
    "lng": -79.46470623899583,
    "lat": 39.134670779677045
  },
  {
    "name": "Davis City",
    "lng": -93.81208768885705,
    "lat": 40.640306149940095
  },
  {
    "name": "Davis Junction",
    "lng": -89.08982911807253,
    "lat": 42.114352217906536
  },
  {
    "name": "Davisboro",
    "lng": -82.60326119554021,
    "lat": 32.98250948370183
  },
  {
    "name": "Davison",
    "lng": -83.51864429476868,
    "lat": 43.03178886424555
  },
  {
    "name": "Daviston",
    "lng": -85.6371082631848,
    "lat": 33.05570126739873
  },
  {
    "name": "Davy",
    "lng": -81.64754394435163,
    "lat": 37.475842897980876
  },
  {
    "name": "Dawson",
    "lng": -99.7523239280119,
    "lat": 46.86773184665083
  },
  {
    "name": "Dawson",
    "lng": -96.71508791123796,
    "lat": 31.894117566524084
  },
  {
    "name": "Dawson",
    "lng": -96.05940663736794,
    "lat": 44.93652408419618
  },
  {
    "name": "Dawson",
    "lng": -96.05031630162263,
    "lat": 44.92886376755369
  },
  {
    "name": "Dawson",
    "lng": -95.82986051123932,
    "lat": 40.13068801721485
  },
  {
    "name": "Dawson",
    "lng": -94.22032487777786,
    "lat": 41.84315848402172
  },
  {
    "name": "Dawson",
    "lng": -89.46265444657156,
    "lat": 39.85327582548942
  },
  {
    "name": "Dawson",
    "lng": -84.44364199780884,
    "lat": 31.77213237142695
  },
  {
    "name": "Dawson",
    "lng": -84.44311705058806,
    "lat": 31.788327547579854
  },
  {
    "name": "Dawson Springs",
    "lng": -87.68809009395584,
    "lat": 37.17358859362308
  },
  {
    "name": "Dawsonville",
    "lng": -84.11946313818261,
    "lat": 34.419648533034284
  },
  {
    "name": "Dawsonville",
    "lng": -84.11778324871003,
    "lat": 34.43142393106416
  },
  {
    "name": "Dawsonville",
    "lng": -84.11591911125704,
    "lat": 34.44976483394625
  },
  {
    "name": "Dawsonville",
    "lng": -84.11086632564108,
    "lat": 34.45414762812488
  },
  {
    "name": "Dawsonville",
    "lng": -84.09783701977341,
    "lat": 34.45235961743111
  },
  {
    "name": "Dawsonville",
    "lng": -84.06651395540223,
    "lat": 34.449403303042274
  },
  {
    "name": "Daykin",
    "lng": -97.29832806281846,
    "lat": 40.32171957435772
  },
  {
    "name": "Dayton",
    "lng": -118.00333056731687,
    "lat": 46.31553150266159
  },
  {
    "name": "Dayton",
    "lng": -117.97619379145034,
    "lat": 46.31719186868257
  },
  {
    "name": "Dayton",
    "lng": -111.97790816364557,
    "lat": 42.11179227791711
  },
  {
    "name": "Dayton",
    "lng": -107.2624888681339,
    "lat": 44.87329622539612
  },
  {
    "name": "Dayton",
    "lng": -94.90243831172846,
    "lat": 30.034997161038916
  },
  {
    "name": "Dayton",
    "lng": -94.07059014228192,
    "lat": 42.26201087252396
  },
  {
    "name": "Dayton",
    "lng": -93.47577008481272,
    "lat": 45.190630794888634
  },
  {
    "name": "Dayton",
    "lng": -87.64157265198757,
    "lat": 32.35095789432351
  },
  {
    "name": "Dayton",
    "lng": -86.77470417817483,
    "lat": 40.3758088405085
  },
  {
    "name": "Dayton",
    "lng": -85.01219593250315,
    "lat": 35.49103514797737
  },
  {
    "name": "Dayton",
    "lng": -84.98232278879014,
    "lat": 35.52887716897329
  },
  {
    "name": "Dayton",
    "lng": -84.46425886984618,
    "lat": 39.11281914398506
  },
  {
    "name": "Dayton",
    "lng": -84.2240645588619,
    "lat": 39.901947831882204
  },
  {
    "name": "Dayton",
    "lng": -84.19761144001386,
    "lat": 39.76869222380291
  },
  {
    "name": "Dayton",
    "lng": -78.94116191837273,
    "lat": 38.41728415652672
  },
  {
    "name": "Dayton",
    "lng": -70.57290200110054,
    "lat": 43.546163269666906
  },
  {
    "name": "Daytona Beach",
    "lng": -81.10592881626762,
    "lat": 29.197465516623303
  },
  {
    "name": "Daytona Beach",
    "lng": -81.08623719018253,
    "lat": 29.155771364874493
  },
  {
    "name": "Daytona Beach",
    "lng": -81.02638166881593,
    "lat": 29.16326712556375
  },
  {
    "name": "Daytona Beach",
    "lng": -81.0219544657222,
    "lat": 29.233613804820255
  },
  {
    "name": "Daytona Beach",
    "lng": -81.01366133961609,
    "lat": 29.230738912236358
  },
  {
    "name": "Daytona Beach",
    "lng": -80.98656131342315,
    "lat": 29.177606299475105
  },
  {
    "name": "Daytona Beach Shores",
    "lng": -80.98040106101178,
    "lat": 29.171007443393492
  },
  {
    "name": "Daytona Beach Shores",
    "lng": -80.97916859790199,
    "lat": 29.164826229142818
  },
  {
    "name": "Daytona Beach Shores",
    "lng": -80.9786105153041,
    "lat": 29.162698020931458
  },
  {
    "name": "Daytona Beach Shores",
    "lng": -80.97859893296419,
    "lat": 29.164035948398404
  },
  {
    "name": "Daytona Beach Shores",
    "lng": -80.97809524784553,
    "lat": 29.16458609284477
  },
  {
    "name": "Daytona Beach Shores",
    "lng": -80.97653229616004,
    "lat": 29.163252351669257
  },
  {
    "name": "Daytona Beach Shores",
    "lng": -80.96962071272154,
    "lat": 29.14942071453467
  },
  {
    "name": "Dayville",
    "lng": -71.88239652768247,
    "lat": 41.84419985761812
  },
  {
    "name": "Dazey",
    "lng": -98.20069542357675,
    "lat": 47.188324831252366
  },
  {
    "name": "De Beque",
    "lng": -108.19527963541027,
    "lat": 39.28795527907891
  },
  {
    "name": "De Graff",
    "lng": -95.46850964795465,
    "lat": 45.25980188573487
  },
  {
    "name": "De Graff",
    "lng": -83.91653022874026,
    "lat": 40.311837275216156
  },
  {
    "name": "De Kalb",
    "lng": -94.92351779876194,
    "lat": 39.5882417159693
  },
  {
    "name": "De Kalb",
    "lng": -94.61622431530128,
    "lat": 33.50722251190269
  },
  {
    "name": "De Kalb",
    "lng": -88.65597262164421,
    "lat": 32.770689521835905
  },
  {
    "name": "De Land",
    "lng": -88.64343415038141,
    "lat": 40.12180538233843
  },
  {
    "name": "De Land Southwest",
    "lng": -81.31163299449548,
    "lat": 29.007782458482605
  },
  {
    "name": "De Leon",
    "lng": -98.5351632867287,
    "lat": 32.11135648698513
  },
  {
    "name": "De Motte",
    "lng": -87.19740994551917,
    "lat": 41.19871608194494
  },
  {
    "name": "De Pere",
    "lng": -88.07947018698572,
    "lat": 44.431121865521156
  },
  {
    "name": "De Pue",
    "lng": -89.32232730621578,
    "lat": 41.33235438365508
  },
  {
    "name": "De Pue",
    "lng": -89.29557420392055,
    "lat": 41.327286683499715
  },
  {
    "name": "De Pue",
    "lng": -89.27368014793736,
    "lat": 41.326339254098514
  },
  {
    "name": "De Queen",
    "lng": -94.3419838096991,
    "lat": 34.04256327374393
  },
  {
    "name": "De Smet",
    "lng": -97.54828237126756,
    "lat": 44.38634458870305
  },
  {
    "name": "De Soto",
    "lng": -95.033408877348,
    "lat": 38.88982416587755
  },
  {
    "name": "De Soto",
    "lng": -95.02186233598655,
    "lat": 38.99162115991979
  },
  {
    "name": "De Soto",
    "lng": -95.00424003658722,
    "lat": 38.94666871689839
  },
  {
    "name": "De Soto",
    "lng": -94.9937434571768,
    "lat": 38.988467387058165
  },
  {
    "name": "De Soto",
    "lng": -94.95389344114817,
    "lat": 38.96994329148028
  },
  {
    "name": "De Soto",
    "lng": -94.00978374973155,
    "lat": 41.5365108776657
  },
  {
    "name": "De Soto",
    "lng": -91.19725368843937,
    "lat": 43.42835834585742
  },
  {
    "name": "De Soto",
    "lng": -90.56080034667565,
    "lat": 38.14106009200164
  },
  {
    "name": "De Soto",
    "lng": -89.22682648038715,
    "lat": 37.816244400176814
  },
  {
    "name": "De Soto",
    "lng": -84.06365513692447,
    "lat": 31.95466884298892
  },
  {
    "name": "De Valls Bluff",
    "lng": -91.46097247529121,
    "lat": 34.78609716969044
  },
  {
    "name": "De Witt",
    "lng": -96.92224974083406,
    "lat": 40.39536850164133
  },
  {
    "name": "De Witt",
    "lng": -93.21991540191823,
    "lat": 39.384882003201405
  },
  {
    "name": "De Witt",
    "lng": -91.33834505563266,
    "lat": 34.28735213003462
  },
  {
    "name": "De Witt",
    "lng": -88.7852623560019,
    "lat": 40.18463856983903
  },
  {
    "name": "DeCordova",
    "lng": -97.69117559754501,
    "lat": 32.42789923341167
  },
  {
    "name": "DeForest",
    "lng": -89.34798752159675,
    "lat": 43.23815614757817
  },
  {
    "name": "DeForest",
    "lng": -89.32350300406581,
    "lat": 43.199609471090056
  },
  {
    "name": "DeFuniak Springs",
    "lng": -86.15775179840058,
    "lat": 30.735866083762602
  },
  {
    "name": "DeFuniak Springs",
    "lng": -86.12123441759599,
    "lat": 30.71205297338913
  },
  {
    "name": "DeFuniak Springs",
    "lng": -86.10954266594231,
    "lat": 30.701013134749566
  },
  {
    "name": "DeFuniak Springs",
    "lng": -86.0962508277433,
    "lat": 30.73189946991442
  },
  {
    "name": "DeKalb",
    "lng": -88.74760447367963,
    "lat": 41.92944830467033
  },
  {
    "name": "DeQuincy",
    "lng": -93.44551560346173,
    "lat": 30.44903502234426
  },
  {
    "name": "DeRidder",
    "lng": -93.29308733175243,
    "lat": 30.846684377047094
  },
  {
    "name": "DeRuyter",
    "lng": -75.88674251526449,
    "lat": 42.75852616653686
  },
  {
    "name": "DeSoto",
    "lng": -96.86337297298876,
    "lat": 32.59929872284946
  },
  {
    "name": "DeTour Village",
    "lng": -83.91477454064304,
    "lat": 45.98992758784132
  },
  {
    "name": "DeWitt",
    "lng": -90.55563833914623,
    "lat": 41.83893583681208
  },
  {
    "name": "DeWitt",
    "lng": -90.54486820140754,
    "lat": 41.822838365952
  },
  {
    "name": "DeWitt",
    "lng": -84.57499169379837,
    "lat": 42.83626109496284
  },
  {
    "name": "Deadwood",
    "lng": -103.71974281213302,
    "lat": 44.38763833381337
  },
  {
    "name": "Dean",
    "lng": -98.36740941986913,
    "lat": 33.93003942929536
  },
  {
    "name": "Dearborn",
    "lng": -94.77448753531165,
    "lat": 39.52435363565565
  },
  {
    "name": "Dearborn",
    "lng": -94.76427270061723,
    "lat": 39.527939385066205
  },
  {
    "name": "Dearborn",
    "lng": -83.21291909737012,
    "lat": 42.312700061233244
  },
  {
    "name": "Dearborn Heights",
    "lng": -83.27686407582794,
    "lat": 42.31644528533205
  },
  {
    "name": "Dearing",
    "lng": -95.69554266095459,
    "lat": 37.053743789947305
  },
  {
    "name": "Dearing",
    "lng": -82.3852198089642,
    "lat": 33.41487797462739
  },
  {
    "name": "Deary",
    "lng": -116.55704935819102,
    "lat": 46.800223651981355
  },
  {
    "name": "Deatsville",
    "lng": -86.39153265812989,
    "lat": 32.586630355158796
  },
  {
    "name": "Deaver",
    "lng": -108.59601822834804,
    "lat": 44.889024759884805
  },
  {
    "name": "Deblois",
    "lng": -67.98650630668772,
    "lat": 44.726987377225555
  },
  {
    "name": "Decatur",
    "lng": -97.5912074716729,
    "lat": 33.2308847513365
  },
  {
    "name": "Decatur",
    "lng": -97.5733742990577,
    "lat": 33.259178299726415
  },
  {
    "name": "Decatur",
    "lng": -97.56188335093576,
    "lat": 33.20778039925705
  },
  {
    "name": "Decatur",
    "lng": -96.25095908666991,
    "lat": 42.00713667584971
  },
  {
    "name": "Decatur",
    "lng": -94.45300222173618,
    "lat": 36.3389069804827
  },
  {
    "name": "Decatur",
    "lng": -89.109120679757,
    "lat": 32.43329112283173
  },
  {
    "name": "Decatur",
    "lng": -89.00121584826506,
    "lat": 39.88615493012769
  },
  {
    "name": "Decatur",
    "lng": -88.99306038483444,
    "lat": 39.88391191537898
  },
  {
    "name": "Decatur",
    "lng": -88.97464317803916,
    "lat": 39.803548748175764
  },
  {
    "name": "Decatur",
    "lng": -88.93385029363088,
    "lat": 39.85575065632213
  },
  {
    "name": "Decatur",
    "lng": -88.88664082542971,
    "lat": 39.82150645481809
  },
  {
    "name": "Decatur",
    "lng": -87.09241183627869,
    "lat": 34.66165562333206
  },
  {
    "name": "Decatur",
    "lng": -87.09023014509035,
    "lat": 34.659084340559914
  },
  {
    "name": "Decatur",
    "lng": -87.06704931059711,
    "lat": 34.624204729398414
  },
  {
    "name": "Decatur",
    "lng": -87.05581678842574,
    "lat": 34.62350011484199
  },
  {
    "name": "Decatur",
    "lng": -87.05151542735085,
    "lat": 34.619036967933354
  },
  {
    "name": "Decatur",
    "lng": -86.99137629163289,
    "lat": 34.57297221282174
  },
  {
    "name": "Decatur",
    "lng": -86.90904968241647,
    "lat": 34.542528286447904
  },
  {
    "name": "Decatur",
    "lng": -85.97464059988575,
    "lat": 42.107581316086204
  },
  {
    "name": "Decatur",
    "lng": -84.92805198733278,
    "lat": 40.830137795111334
  },
  {
    "name": "Decatur",
    "lng": -84.91705051976882,
    "lat": 40.80944617291177
  },
  {
    "name": "Decatur",
    "lng": -84.79270357121077,
    "lat": 35.527634321399894
  },
  {
    "name": "Decatur",
    "lng": -84.29683524727864,
    "lat": 33.77114522439831
  },
  {
    "name": "Decatur City",
    "lng": -93.83586472902807,
    "lat": 40.73848591396053
  },
  {
    "name": "Decatur City",
    "lng": -93.83260572983727,
    "lat": 40.74303651179546
  },
  {
    "name": "Decaturville",
    "lng": -88.11935565204571,
    "lat": 35.581926563370224
  },
  {
    "name": "Decherd",
    "lng": -86.0753966305936,
    "lat": 35.216081501502806
  },
  {
    "name": "Decker",
    "lng": -87.5238481861957,
    "lat": 38.51855817998727
  },
  {
    "name": "Deckerville",
    "lng": -82.74205326055778,
    "lat": 43.52649182072147
  },
  {
    "name": "Declo",
    "lng": -113.62873098776788,
    "lat": 42.51959911546021
  },
  {
    "name": "Decorah",
    "lng": -91.78475745672453,
    "lat": 43.301630306630415
  },
  {
    "name": "Dedham",
    "lng": -94.82332325302876,
    "lat": 41.90838929175751
  },
  {
    "name": "Dedham",
    "lng": -68.58178676368873,
    "lat": 44.69345023278205
  },
  {
    "name": "Deep River",
    "lng": -92.37282284625775,
    "lat": 41.58168115885038
  },
  {
    "name": "Deep River",
    "lng": -72.4622543685891,
    "lat": 41.36893755138416
  },
  {
    "name": "Deephaven",
    "lng": -93.53122301670868,
    "lat": 44.933161475788566
  },
  {
    "name": "Deephaven",
    "lng": -93.52910967836755,
    "lat": 44.931977066588246
  },
  {
    "name": "Deephaven",
    "lng": -93.5237439586688,
    "lat": 44.92943078629407
  },
  {
    "name": "Deepwater",
    "lng": -93.77463377182971,
    "lat": 38.259170109878355
  },
  {
    "name": "Deer Creek",
    "lng": -97.51929318638622,
    "lat": 36.8066557363433
  },
  {
    "name": "Deer Creek",
    "lng": -95.32174102165332,
    "lat": 46.39091941880278
  },
  {
    "name": "Deer Creek",
    "lng": -89.33152745143605,
    "lat": 40.62912468822955
  },
  {
    "name": "Deer Grove",
    "lng": -89.68740919062604,
    "lat": 41.60976083354184
  },
  {
    "name": "Deer Isle",
    "lng": -68.72252807881947,
    "lat": 44.231204658391356
  },
  {
    "name": "Deer Lodge",
    "lng": -112.73298798667412,
    "lat": 46.397535439316925
  },
  {
    "name": "Deer Park",
    "lng": -117.44032841151153,
    "lat": 47.96340311736712
  },
  {
    "name": "Deer Park",
    "lng": -95.11387248301685,
    "lat": 29.698497347160586
  },
  {
    "name": "Deer Park",
    "lng": -92.38777668901943,
    "lat": 45.18874790888064
  },
  {
    "name": "Deer Park",
    "lng": -88.0870492270206,
    "lat": 42.16577615278936
  },
  {
    "name": "Deer Park",
    "lng": -84.39771843800929,
    "lat": 39.20375082330313
  },
  {
    "name": "Deer Park",
    "lng": -79.32588288431037,
    "lat": 39.42401739010915
  },
  {
    "name": "Deer River",
    "lng": -93.79586564094008,
    "lat": 47.340951226644854
  },
  {
    "name": "Deer River",
    "lng": -93.76939244979988,
    "lat": 47.32535958533421
  },
  {
    "name": "Deer Trail",
    "lng": -104.04324173679377,
    "lat": 39.615348013305216
  },
  {
    "name": "Deerfield",
    "lng": -101.1331637701404,
    "lat": 37.98196349062201
  },
  {
    "name": "Deerfield",
    "lng": -94.50772138181414,
    "lat": 37.83865943947715
  },
  {
    "name": "Deerfield",
    "lng": -89.07571816623852,
    "lat": 43.049043645535725
  },
  {
    "name": "Deerfield",
    "lng": -83.77889658226839,
    "lat": 41.89007484347555
  },
  {
    "name": "Deerfield",
    "lng": -72.60972944219449,
    "lat": 42.52203882757246
  },
  {
    "name": "Deerfield",
    "lng": -71.25698653815392,
    "lat": 43.13810957305223
  },
  {
    "name": "Deerfield Beach",
    "lng": -80.12759281302532,
    "lat": 26.30501682301272
  },
  {
    "name": "Deering",
    "lng": -162.74466725753078,
    "lat": 66.07264400068782
  },
  {
    "name": "Deering",
    "lng": -101.05179138577338,
    "lat": 48.39588194483792
  },
  {
    "name": "Deering",
    "lng": -101.043172594929,
    "lat": 48.39540870113477
  },
  {
    "name": "Deering",
    "lng": -71.85150588842505,
    "lat": 43.07442254449301
  },
  {
    "name": "Deersville",
    "lng": -81.1879760984959,
    "lat": 40.30826906482114
  },
  {
    "name": "Deerwood",
    "lng": -93.90000781166033,
    "lat": 46.47256760943682
  },
  {
    "name": "Deferiet",
    "lng": -75.67947367328422,
    "lat": 44.034411907022864
  },
  {
    "name": "Defiance",
    "lng": -95.3398329717022,
    "lat": 41.82577312964665
  },
  {
    "name": "Defiance",
    "lng": -84.3678225047131,
    "lat": 41.28096676561379
  },
  {
    "name": "Del City",
    "lng": -97.44081256837254,
    "lat": 35.44832153126792
  },
  {
    "name": "Del Mar",
    "lng": -117.26248929592406,
    "lat": 32.963023344058286
  },
  {
    "name": "Del Norte",
    "lng": -106.3539323188626,
    "lat": 37.67841772836213
  },
  {
    "name": "Del Rey Oaks",
    "lng": -121.83832037568888,
    "lat": 36.593129435464796
  },
  {
    "name": "Del Rio",
    "lng": -100.87998452733706,
    "lat": 29.3707230206001
  },
  {
    "name": "Delafield",
    "lng": -88.39124079327351,
    "lat": 43.072080903367564
  },
  {
    "name": "Delano",
    "lng": -119.32599791692769,
    "lat": 35.749386015435086
  },
  {
    "name": "Delano",
    "lng": -119.26062076694274,
    "lat": 35.76789123137505
  },
  {
    "name": "Delano",
    "lng": -93.81151786069387,
    "lat": 45.056594144459346
  },
  {
    "name": "Delano",
    "lng": -93.80230097263734,
    "lat": 45.04972932116009
  },
  {
    "name": "Delano",
    "lng": -93.79081933103501,
    "lat": 45.02863382139419
  },
  {
    "name": "Delano",
    "lng": -93.79063734260461,
    "lat": 45.03620350232619
  },
  {
    "name": "Delanson",
    "lng": -74.18408674571491,
    "lat": 42.74740568042488
  },
  {
    "name": "Delaplaine",
    "lng": -90.72574452393788,
    "lat": 36.23046960484115
  },
  {
    "name": "Delavan",
    "lng": -94.01738968618159,
    "lat": 43.767680864505316
  },
  {
    "name": "Delavan",
    "lng": -89.54587375082417,
    "lat": 40.37111695549964
  },
  {
    "name": "Delavan",
    "lng": -88.63199507821226,
    "lat": 42.62857469408241
  },
  {
    "name": "Delaware",
    "lng": -95.64257131453485,
    "lat": 36.77894774662102
  },
  {
    "name": "Delaware",
    "lng": -91.33923579463548,
    "lat": 42.471759196154835
  },
  {
    "name": "Delaware",
    "lng": -83.07453734148602,
    "lat": 40.287029885570654
  },
  {
    "name": "Delaware",
    "lng": -75.45353165510872,
    "lat": 38.99813089182214
  },
  {
    "name": "Delaware Water Gap",
    "lng": -75.13922755117864,
    "lat": 40.97312397869295
  },
  {
    "name": "Delbarton",
    "lng": -82.185888940402,
    "lat": 37.70522124500458
  },
  {
    "name": "Delcambre",
    "lng": -91.99151808605887,
    "lat": 29.95142313986773
  },
  {
    "name": "Delevan",
    "lng": -78.47906784947938,
    "lat": 42.491135826544564
  },
  {
    "name": "Delhi",
    "lng": -95.2132792666845,
    "lat": 44.5978717158315
  },
  {
    "name": "Delhi",
    "lng": -91.48995605213833,
    "lat": 32.45323145514599
  },
  {
    "name": "Delhi",
    "lng": -91.33068813335893,
    "lat": 42.43033162910727
  },
  {
    "name": "Delhi",
    "lng": -74.91499092143216,
    "lat": 42.278576299235
  },
  {
    "name": "Delia",
    "lng": -95.96476017553762,
    "lat": 39.23938410816041
  },
  {
    "name": "Delight",
    "lng": -93.50729206331371,
    "lat": 34.03008284230722
  },
  {
    "name": "Dell",
    "lng": -90.03404149410271,
    "lat": 35.85718631617983
  },
  {
    "name": "Dell City",
    "lng": -105.1998028238175,
    "lat": 31.934881739302842
  },
  {
    "name": "Dell Rapids",
    "lng": -96.71528629544179,
    "lat": 43.82524332022431
  },
  {
    "name": "Dellroy",
    "lng": -81.19921898371521,
    "lat": 40.55503308144832
  },
  {
    "name": "Dellview",
    "lng": -81.41270714391091,
    "lat": 35.386552472613445
  },
  {
    "name": "Dellwood",
    "lng": -92.9670959114799,
    "lat": 45.09855931542816
  },
  {
    "name": "Dellwood",
    "lng": -90.27668070577599,
    "lat": 38.75631249963782
  },
  {
    "name": "Delmar",
    "lng": -90.60824077570393,
    "lat": 41.99857739515649
  },
  {
    "name": "Delmont",
    "lng": -98.15915346353238,
    "lat": 43.26663810493381
  },
  {
    "name": "Deloit",
    "lng": -95.31734028820271,
    "lat": 42.09721037369429
  },
  {
    "name": "Delphi",
    "lng": -86.6671631992121,
    "lat": 40.58309650468776
  },
  {
    "name": "Delphos",
    "lng": -97.76587229449083,
    "lat": 39.27498000970228
  },
  {
    "name": "Delphos",
    "lng": -97.76256097898077,
    "lat": 39.260595498801656
  },
  {
    "name": "Delphos",
    "lng": -94.3399261870912,
    "lat": 40.663081685601945
  },
  {
    "name": "Delphos",
    "lng": -84.33504214457226,
    "lat": 40.845441182632456
  },
  {
    "name": "Delray Beach",
    "lng": -80.09042350821142,
    "lat": 26.455038875449837
  },
  {
    "name": "Delta",
    "lng": -112.54724036615161,
    "lat": 39.36223456157802
  },
  {
    "name": "Delta",
    "lng": -108.07705951356245,
    "lat": 38.75596467291627
  },
  {
    "name": "Delta",
    "lng": -92.32898440115827,
    "lat": 41.32215861171338
  },
  {
    "name": "Delta",
    "lng": -90.94241048660521,
    "lat": 32.32201202507659
  },
  {
    "name": "Delta",
    "lng": -89.73965344439634,
    "lat": 37.197464749956765
  },
  {
    "name": "Delta",
    "lng": -84.00483894472853,
    "lat": 41.57529732175823
  },
  {
    "name": "Delta",
    "lng": -83.98026550474881,
    "lat": 41.57320247035545
  },
  {
    "name": "Deltona",
    "lng": -81.25440604817712,
    "lat": 28.86564527723302
  },
  {
    "name": "Deltona",
    "lng": -81.21457613151908,
    "lat": 28.908015560255855
  },
  {
    "name": "Deltona",
    "lng": -81.19406523333335,
    "lat": 28.852792966680457
  },
  {
    "name": "Deltona",
    "lng": -81.18278067991481,
    "lat": 28.838027475022855
  },
  {
    "name": "Deming",
    "lng": -107.79543875070117,
    "lat": 32.24877451025854
  },
  {
    "name": "Deming",
    "lng": -107.75093777790492,
    "lat": 32.26408921584036
  },
  {
    "name": "Demopolis",
    "lng": -87.82981011545957,
    "lat": 32.4980146376266
  },
  {
    "name": "Demorest",
    "lng": -83.5423314941039,
    "lat": 34.564964097118356
  },
  {
    "name": "Denair",
    "lng": -120.79941971843935,
    "lat": 37.52619497501845
  },
  {
    "name": "Dendron",
    "lng": -76.9240570365904,
    "lat": 37.036415433374486
  },
  {
    "name": "Denham",
    "lng": -92.94158090458234,
    "lat": 46.36172281602121
  },
  {
    "name": "Denham Springs",
    "lng": -90.95938766400539,
    "lat": 30.47434470068817
  },
  {
    "name": "Denison",
    "lng": -96.58095122187152,
    "lat": 33.76743631009218
  },
  {
    "name": "Denison",
    "lng": -95.62846478591462,
    "lat": 39.39381018929187
  },
  {
    "name": "Denison",
    "lng": -95.352766533147,
    "lat": 42.016163716531686
  },
  {
    "name": "Denmark",
    "lng": -87.83100817804393,
    "lat": 44.34939492617062
  },
  {
    "name": "Denmark",
    "lng": -81.13947354717779,
    "lat": 33.31815658811069
  },
  {
    "name": "Denmark",
    "lng": -70.80718357507789,
    "lat": 43.980597342041335
  },
  {
    "name": "Denning",
    "lng": -93.7567610840454,
    "lat": 35.42516251253782
  },
  {
    "name": "Dennis Acres",
    "lng": -94.50425977590714,
    "lat": 37.04634319485439
  },
  {
    "name": "Dennison",
    "lng": -93.0414790191647,
    "lat": 44.40682082751243
  },
  {
    "name": "Dennison",
    "lng": -93.03021457385317,
    "lat": 44.40884888811559
  },
  {
    "name": "Dennison",
    "lng": -81.32796296766375,
    "lat": 40.39435752018986
  },
  {
    "name": "Dennison",
    "lng": -81.32533699357765,
    "lat": 40.407028709742626
  },
  {
    "name": "Dennysville",
    "lng": -67.2544174968687,
    "lat": 44.93416451551109
  },
  {
    "name": "Dent",
    "lng": -95.7213252688919,
    "lat": 46.55822371997566
  },
  {
    "name": "Dent",
    "lng": -95.7184297440241,
    "lat": 46.552295690218564
  },
  {
    "name": "Denton",
    "lng": -109.94891795853773,
    "lat": 47.32172602624199
  },
  {
    "name": "Denton",
    "lng": -97.23600448205214,
    "lat": 33.16812164519938
  },
  {
    "name": "Denton",
    "lng": -97.2348101637692,
    "lat": 33.17347653250249
  },
  {
    "name": "Denton",
    "lng": -97.22498212889974,
    "lat": 33.2181432006308
  },
  {
    "name": "Denton",
    "lng": -97.14766432707505,
    "lat": 33.20960373540869
  },
  {
    "name": "Denton",
    "lng": -97.06298735330168,
    "lat": 33.177643743641276
  },
  {
    "name": "Denton",
    "lng": -97.0592106671401,
    "lat": 33.17220023720174
  },
  {
    "name": "Denton",
    "lng": -96.8459794372725,
    "lat": 40.73924266892222
  },
  {
    "name": "Denton",
    "lng": -95.26948718909337,
    "lat": 39.73179142853711
  },
  {
    "name": "Denton",
    "lng": -82.69687345102614,
    "lat": 31.7222206259705
  },
  {
    "name": "Denton",
    "lng": -80.12540084526572,
    "lat": 35.62478541794989
  },
  {
    "name": "Denton",
    "lng": -80.10970372485163,
    "lat": 35.63466861764608
  },
  {
    "name": "Denton",
    "lng": -80.10423570275661,
    "lat": 35.637931180993945
  },
  {
    "name": "Denton",
    "lng": -80.10134168356377,
    "lat": 35.69860487476281
  },
  {
    "name": "Denton",
    "lng": -80.09546372848263,
    "lat": 35.696354305415156
  },
  {
    "name": "Denton",
    "lng": -75.82654494781099,
    "lat": 38.877021501752935
  },
  {
    "name": "Denver",
    "lng": -94.323296765456,
    "lat": 40.39894883071109
  },
  {
    "name": "Denver",
    "lng": -92.33413136311823,
    "lat": 42.66898356923047
  },
  {
    "name": "Denver City",
    "lng": -102.83187848756805,
    "lat": 32.96803824808373
  },
  {
    "name": "Depew",
    "lng": -96.5074914563401,
    "lat": 35.80097330599341
  },
  {
    "name": "Depew",
    "lng": -96.5004836130591,
    "lat": 35.799941566969665
  },
  {
    "name": "Depew",
    "lng": -78.70441044109336,
    "lat": 42.911782433335894
  },
  {
    "name": "Deport",
    "lng": -95.31742126251434,
    "lat": 33.529150414812186
  },
  {
    "name": "Deposit",
    "lng": -75.42245002815045,
    "lat": 42.063424980261054
  },
  {
    "name": "Derby",
    "lng": -97.27129587802308,
    "lat": 37.52434535018446
  },
  {
    "name": "Derby",
    "lng": -97.25558714565568,
    "lat": 37.555027110088865
  },
  {
    "name": "Derby",
    "lng": -93.45647118310352,
    "lat": 40.930327892782785
  },
  {
    "name": "Derby",
    "lng": -73.08333454815316,
    "lat": 41.32650863238657
  },
  {
    "name": "Derby Center",
    "lng": -72.13535649840577,
    "lat": 44.95494878328415
  },
  {
    "name": "Derby Line",
    "lng": -72.10283083831997,
    "lat": 45.001118831242515
  },
  {
    "name": "Dering Harbor",
    "lng": -72.34176297554309,
    "lat": 41.09312505440945
  },
  {
    "name": "Derma",
    "lng": -89.287760828287,
    "lat": 33.85887623786235
  },
  {
    "name": "Dermott",
    "lng": -91.43249672369824,
    "lat": 33.526825078857705
  },
  {
    "name": "Derry",
    "lng": -71.28044119190182,
    "lat": 42.88890501272134
  },
  {
    "name": "Des Arc",
    "lng": -91.50656784175997,
    "lat": 34.977719245276006
  },
  {
    "name": "Des Arc",
    "lng": -90.63517690111966,
    "lat": 37.283840648671955
  },
  {
    "name": "Des Lacs",
    "lng": -101.56344536501021,
    "lat": 48.25641292391143
  },
  {
    "name": "Des Moines",
    "lng": -103.83585595880314,
    "lat": 36.76258687977016
  },
  {
    "name": "Des Moines",
    "lng": -93.61043882211739,
    "lat": 41.572532122612664
  },
  {
    "name": "Des Moines",
    "lng": -93.60174379569031,
    "lat": 41.65179435116195
  },
  {
    "name": "Des Peres",
    "lng": -90.44796562532238,
    "lat": 38.59734716889463
  },
  {
    "name": "Des Plaines",
    "lng": -87.90097950044236,
    "lat": 42.034372191230304
  },
  {
    "name": "Desert Hot Springs",
    "lng": -116.55054454571051,
    "lat": 33.96696353664649
  },
  {
    "name": "Deshka Landing",
    "lng": -150.1984494408168,
    "lat": 61.7197308822851
  },
  {
    "name": "Deshler",
    "lng": -97.72341571447105,
    "lat": 40.13962460504234
  },
  {
    "name": "Desloge",
    "lng": -90.51912920974638,
    "lat": 37.87507343283745
  },
  {
    "name": "Destin",
    "lng": -86.47066880242909,
    "lat": 30.394693875109756
  },
  {
    "name": "Detroit",
    "lng": -90.6759597591752,
    "lat": 39.620147557836894
  },
  {
    "name": "Detroit",
    "lng": -88.16811449426558,
    "lat": 34.02788139527485
  },
  {
    "name": "Detroit",
    "lng": -83.10222262508077,
    "lat": 42.38341006509446
  },
  {
    "name": "Detroit",
    "lng": -69.31131026846623,
    "lat": 44.76242817472552
  },
  {
    "name": "Detroit Lakes",
    "lng": -95.84470878285818,
    "lat": 46.805913064832495
  },
  {
    "name": "Devers",
    "lng": -94.58597141693599,
    "lat": 30.02869899181147
  },
  {
    "name": "Devils Lake",
    "lng": -98.87650962983432,
    "lat": 48.113038196109315
  },
  {
    "name": "Devine",
    "lng": -98.93361793336328,
    "lat": 29.144700392447756
  },
  {
    "name": "Devine",
    "lng": -98.90481136248228,
    "lat": 29.145593683432924
  },
  {
    "name": "Devol",
    "lng": -98.58812388091926,
    "lat": 34.19541619544392
  },
  {
    "name": "Devonshire",
    "lng": -122.28700628900567,
    "lat": 37.50208030247438
  },
  {
    "name": "Dewar",
    "lng": -95.94842979679765,
    "lat": 35.45834807049489
  },
  {
    "name": "Deweese",
    "lng": -98.13921632028024,
    "lat": 40.354683368930175
  },
  {
    "name": "Dewey",
    "lng": -95.93750459324593,
    "lat": 36.81059500291937
  },
  {
    "name": "Dewey",
    "lng": -95.9348587403823,
    "lat": 36.81476252264772
  },
  {
    "name": "Dewey",
    "lng": -95.93340664234837,
    "lat": 36.81166668121977
  },
  {
    "name": "Dewey",
    "lng": -95.93261215452826,
    "lat": 36.78995349708731
  },
  {
    "name": "Dewey",
    "lng": -95.93226094367171,
    "lat": 36.814619889739554
  },
  {
    "name": "Deweyville",
    "lng": -112.08833331478426,
    "lat": 41.69303015646479
  },
  {
    "name": "Dewitt",
    "lng": -76.09515772208451,
    "lat": 43.03424358810854
  },
  {
    "name": "Dexter",
    "lng": -104.37321259424155,
    "lat": 33.195680195027585
  },
  {
    "name": "Dexter",
    "lng": -104.35773764468607,
    "lat": 33.19413612632856
  },
  {
    "name": "Dexter",
    "lng": -96.7159466718595,
    "lat": 37.1796225364166
  },
  {
    "name": "Dexter",
    "lng": -94.22769967844864,
    "lat": 41.51508660375377
  },
  {
    "name": "Dexter",
    "lng": -92.71105471161063,
    "lat": 43.73306449362339
  },
  {
    "name": "Dexter",
    "lng": -92.7013573011454,
    "lat": 43.71979455638484
  },
  {
    "name": "Dexter",
    "lng": -89.96316834416857,
    "lat": 36.793076553470534
  },
  {
    "name": "Dexter",
    "lng": -83.8800772440449,
    "lat": 42.33236722966375
  },
  {
    "name": "Dexter",
    "lng": -83.05951114999934,
    "lat": 32.433044727409595
  },
  {
    "name": "Dexter",
    "lng": -76.04245905199069,
    "lat": 44.01123969100787
  },
  {
    "name": "Dexter",
    "lng": -69.28194840726123,
    "lat": 45.038862226739504
  },
  {
    "name": "Dexter City",
    "lng": -81.47388089727231,
    "lat": 39.65926569728722
  },
  {
    "name": "Diagonal",
    "lng": -94.34094808882774,
    "lat": 40.80904792399018
  },
  {
    "name": "Diamond",
    "lng": -94.31516173191379,
    "lat": 36.99584209302653
  },
  {
    "name": "Diamond",
    "lng": -88.25069193417941,
    "lat": 41.28521568826691
  },
  {
    "name": "Diamond Bar",
    "lng": -117.81612502534372,
    "lat": 33.999180133109206
  },
  {
    "name": "Diamond City",
    "lng": -92.91295621914479,
    "lat": 36.45748870583649
  },
  {
    "name": "Diamond Springs Post Office",
    "lng": -120.81388503121619,
    "lat": 38.69440131393671
  },
  {
    "name": "Diamondville",
    "lng": -110.53573804698006,
    "lat": 41.77717166111817
  },
  {
    "name": "Diaz",
    "lng": -91.26280755977356,
    "lat": 35.64761151299638
  },
  {
    "name": "Diaz",
    "lng": -91.26221808763405,
    "lat": 35.64772833422752
  },
  {
    "name": "Dibble",
    "lng": -97.62801063710481,
    "lat": 35.01543898040014
  },
  {
    "name": "Diboll",
    "lng": -94.7830290026306,
    "lat": 31.188158762378773
  },
  {
    "name": "Dickens",
    "lng": -100.83680361852508,
    "lat": 33.62127312533637
  },
  {
    "name": "Dickens",
    "lng": -95.02219355364767,
    "lat": 43.13386662603003
  },
  {
    "name": "Dickey",
    "lng": -98.46821847135297,
    "lat": 46.53673141551787
  },
  {
    "name": "Dickey",
    "lng": -84.66258721761021,
    "lat": 31.553821822362067
  },
  {
    "name": "Dickeyville",
    "lng": -90.59293552145542,
    "lat": 42.62632255909737
  },
  {
    "name": "Dickinson",
    "lng": -102.78642965528812,
    "lat": 46.8857734102053
  },
  {
    "name": "Dickinson",
    "lng": -95.05715966227137,
    "lat": 29.45458906367432
  },
  {
    "name": "Dickson",
    "lng": -96.99745362485683,
    "lat": 34.187667672378005
  },
  {
    "name": "Dickson",
    "lng": -87.36594594990494,
    "lat": 36.06370740648539
  },
  {
    "name": "Diehlstadt",
    "lng": -89.43234421198946,
    "lat": 36.959301748718396
  },
  {
    "name": "Dierks",
    "lng": -94.01764104949225,
    "lat": 34.104773189067345
  },
  {
    "name": "Dierks",
    "lng": -94.0172852746136,
    "lat": 34.120705180556996
  },
  {
    "name": "Dieterich",
    "lng": -88.38186095494133,
    "lat": 39.06012304849152
  },
  {
    "name": "Dietrich",
    "lng": -114.26628868391623,
    "lat": 42.91279630450773
  },
  {
    "name": "Diggins",
    "lng": -92.85045228978349,
    "lat": 37.1739533803348
  },
  {
    "name": "Dighton",
    "lng": -100.4664422814024,
    "lat": 38.481095684177475
  },
  {
    "name": "Dike",
    "lng": -92.62572057173416,
    "lat": 42.46412212301602
  },
  {
    "name": "Dill City",
    "lng": -99.13360486367782,
    "lat": 35.2821767020525
  },
  {
    "name": "Dillard",
    "lng": -83.38206116167026,
    "lat": 34.9747250311739
  },
  {
    "name": "Dillard",
    "lng": -83.37464532927775,
    "lat": 34.981019644405926
  },
  {
    "name": "Diller",
    "lng": -96.93734987816623,
    "lat": 40.10888841179187
  },
  {
    "name": "Dilley",
    "lng": -99.17710488059079,
    "lat": 28.66704101066845
  },
  {
    "name": "Dillingham",
    "lng": -158.5376010921935,
    "lat": 59.054915153542815
  },
  {
    "name": "Dillon",
    "lng": -112.634854984966,
    "lat": 45.217603151496114
  },
  {
    "name": "Dillon",
    "lng": -112.6303296194238,
    "lat": 45.23141806530469
  },
  {
    "name": "Dillon",
    "lng": -112.6046973284519,
    "lat": 45.23227530652717
  },
  {
    "name": "Dillon",
    "lng": -106.03832578390958,
    "lat": 39.620841150777714
  },
  {
    "name": "Dillon",
    "lng": -79.39129991746795,
    "lat": 34.41955797771231
  },
  {
    "name": "Dillon",
    "lng": -79.36900139575741,
    "lat": 34.42251052495912
  },
  {
    "name": "Dillonvale",
    "lng": -80.77519790094374,
    "lat": 40.19871207678604
  },
  {
    "name": "Dillsboro",
    "lng": -85.05860734496072,
    "lat": 39.018411738496205
  },
  {
    "name": "Dillsboro",
    "lng": -83.25203614666415,
    "lat": 35.36913724764022
  },
  {
    "name": "Dillsburg",
    "lng": -77.0334797263139,
    "lat": 40.110302900426035
  },
  {
    "name": "Dillwyn",
    "lng": -78.46130443420611,
    "lat": 37.541175390949014
  },
  {
    "name": "Dilworth",
    "lng": -96.69846927669346,
    "lat": 46.87952193132452
  },
  {
    "name": "Dimmitt",
    "lng": -102.3118637802655,
    "lat": 34.532815950580485
  },
  {
    "name": "Dimmitt",
    "lng": -102.30126119688322,
    "lat": 34.55159762167681
  },
  {
    "name": "Dimmitt",
    "lng": -102.29279263419829,
    "lat": 34.547664645651516
  },
  {
    "name": "Dimock",
    "lng": -97.98792454997319,
    "lat": 43.47600484781986
  },
  {
    "name": "Dimondale",
    "lng": -84.64721382724056,
    "lat": 42.64822509466542
  },
  {
    "name": "Dinosaur",
    "lng": -109.00858946652717,
    "lat": 40.24055174329083
  },
  {
    "name": "Dinosaur",
    "lng": -108.99303561149054,
    "lat": 40.23343663840819
  },
  {
    "name": "Dinuba",
    "lng": -119.39888438947038,
    "lat": 36.545348322406554
  },
  {
    "name": "Diomede",
    "lng": -168.9232351561764,
    "lat": 65.7537757446481
  },
  {
    "name": "Disney",
    "lng": -95.01926679630363,
    "lat": 36.476886797501805
  },
  {
    "name": "District of Columbia",
    "lng": -77.01638201908085,
    "lat": 38.90487036508467
  },
  {
    "name": "Divernon",
    "lng": -89.65391597772192,
    "lat": 39.57003241480794
  },
  {
    "name": "Dix",
    "lng": -103.48667309766586,
    "lat": 41.234281914824855
  },
  {
    "name": "Dix",
    "lng": -88.9422749970878,
    "lat": 38.443051738473514
  },
  {
    "name": "Dixfield",
    "lng": -70.38258075015416,
    "lat": 44.54751090019791
  },
  {
    "name": "Dixie Inn",
    "lng": -93.33142812802345,
    "lat": 32.59688413486465
  },
  {
    "name": "Dixmont",
    "lng": -69.13332024049926,
    "lat": 44.69571155158923
  },
  {
    "name": "Dixmoor",
    "lng": -87.66723776410932,
    "lat": 41.632968992462935
  },
  {
    "name": "Dixon",
    "lng": -121.82761887822838,
    "lat": 38.45297042982716
  },
  {
    "name": "Dixon",
    "lng": -121.80906288902291,
    "lat": 38.394818399695566
  },
  {
    "name": "Dixon",
    "lng": -121.79430239835895,
    "lat": 38.38554018501569
  },
  {
    "name": "Dixon",
    "lng": -107.53555528472147,
    "lat": 41.034475395086154
  },
  {
    "name": "Dixon",
    "lng": -96.9948362100425,
    "lat": 42.41540826754881
  },
  {
    "name": "Dixon",
    "lng": -92.09592241097013,
    "lat": 37.995342551698236
  },
  {
    "name": "Dixon",
    "lng": -92.08784295942051,
    "lat": 37.99818347933321
  },
  {
    "name": "Dixon",
    "lng": -90.78299191315178,
    "lat": 41.7423615878567
  },
  {
    "name": "Dixon",
    "lng": -89.47883204703874,
    "lat": 41.84396294165653
  },
  {
    "name": "Dixon",
    "lng": -87.69191876824705,
    "lat": 37.51675243020397
  },
  {
    "name": "Dixonville",
    "lng": -79.01051089855834,
    "lat": 40.71530076773867
  },
  {
    "name": "Dobbins Heights",
    "lng": -79.69327556385345,
    "lat": 34.90686668883147
  },
  {
    "name": "Dobbs Ferry",
    "lng": -73.86972047325617,
    "lat": 41.01259916358441
  },
  {
    "name": "Dobson",
    "lng": -80.78538111609896,
    "lat": 36.39204821630863
  },
  {
    "name": "Dobson",
    "lng": -80.7847678418347,
    "lat": 36.39655619284951
  },
  {
    "name": "Dobson",
    "lng": -80.7810712820575,
    "lat": 36.39383137772556
  },
  {
    "name": "Dobson",
    "lng": -80.72054513960865,
    "lat": 36.3931212901643
  },
  {
    "name": "Dobson",
    "lng": -80.71779247854201,
    "lat": 36.37762002875603
  },
  {
    "name": "Doddsville",
    "lng": -90.52143915585285,
    "lat": 33.6598642634586
  },
  {
    "name": "Dodge",
    "lng": -102.20280936648318,
    "lat": 47.30571495959231
  },
  {
    "name": "Dodge",
    "lng": -96.88049985606864,
    "lat": 41.72188585097372
  },
  {
    "name": "Dodge Center",
    "lng": -92.85822985056788,
    "lat": 44.03896760547743
  },
  {
    "name": "Dodge Center",
    "lng": -92.85234576274375,
    "lat": 44.02828186332805
  },
  {
    "name": "Dodge City",
    "lng": -100.02049454506708,
    "lat": 37.75951689230016
  },
  {
    "name": "Dodge City",
    "lng": -99.96588512720739,
    "lat": 37.764205842830364
  },
  {
    "name": "Dodge City",
    "lng": -99.95876603047493,
    "lat": 37.7362745325319
  },
  {
    "name": "Dodge City",
    "lng": -99.95592337707498,
    "lat": 37.736566486096834
  },
  {
    "name": "Dodge City",
    "lng": -99.95486577326174,
    "lat": 37.73484632643557
  },
  {
    "name": "Dodge City",
    "lng": -99.94593859869632,
    "lat": 37.769129166600926
  },
  {
    "name": "Dodge City",
    "lng": -86.88408415474764,
    "lat": 34.04546363427898
  },
  {
    "name": "Dodgeville",
    "lng": -90.12998229293696,
    "lat": 42.96555012840025
  },
  {
    "name": "Dodson",
    "lng": -108.24677041652167,
    "lat": 48.395523712100136
  },
  {
    "name": "Dodson",
    "lng": -100.02041898791227,
    "lat": 34.765375228986784
  },
  {
    "name": "Dodson",
    "lng": -92.65871632122546,
    "lat": 32.08077773151528
  },
  {
    "name": "Doerun",
    "lng": -83.91695775323171,
    "lat": 31.321825791317732
  },
  {
    "name": "Doland",
    "lng": -98.09985035907869,
    "lat": 44.89449274113632
  },
  {
    "name": "Dolgeville",
    "lng": -74.77486365147571,
    "lat": 43.103075129884324
  },
  {
    "name": "Dolliver",
    "lng": -94.61534074129285,
    "lat": 43.46321733549192
  },
  {
    "name": "Dolores",
    "lng": -108.49959558540874,
    "lat": 37.47409871537867
  },
  {
    "name": "Dolton",
    "lng": -97.38485554187203,
    "lat": 43.49114505156319
  },
  {
    "name": "Dolton",
    "lng": -87.59796277702358,
    "lat": 41.62844298483695
  },
  {
    "name": "Domino",
    "lng": -94.1199926417902,
    "lat": 33.25478552112218
  },
  {
    "name": "Donahue",
    "lng": -90.67461563913226,
    "lat": 41.69156347813645
  },
  {
    "name": "Donalds",
    "lng": -82.34664022980601,
    "lat": 34.376600595541376
  },
  {
    "name": "Donaldson",
    "lng": -96.8980189223806,
    "lat": 48.57550378447908
  },
  {
    "name": "Donaldson",
    "lng": -92.91952012941671,
    "lat": 34.234977124090705
  },
  {
    "name": "Donaldsonville",
    "lng": -90.99252977987362,
    "lat": 30.095400007209207
  },
  {
    "name": "Donalsonville",
    "lng": -84.87915829567588,
    "lat": 31.040406590174793
  },
  {
    "name": "Dongola",
    "lng": -89.16548977436985,
    "lat": 37.35989139576489
  },
  {
    "name": "Dongola",
    "lng": -89.1578512850249,
    "lat": 37.36858684214074
  },
  {
    "name": "Doniphan",
    "lng": -98.37188569740286,
    "lat": 40.77453734823856
  },
  {
    "name": "Doniphan",
    "lng": -90.821860471209,
    "lat": 36.62335615614637
  },
  {
    "name": "Donna",
    "lng": -98.05594864481604,
    "lat": 26.146765229161893
  },
  {
    "name": "Donnellson",
    "lng": -91.56412068935703,
    "lat": 40.64518353921532
  },
  {
    "name": "Donnellson",
    "lng": -89.48248071244979,
    "lat": 39.02922808913538
  },
  {
    "name": "Donnellson",
    "lng": -89.47386150580078,
    "lat": 39.03081439965524
  },
  {
    "name": "Donnelly",
    "lng": -116.07891781556826,
    "lat": 44.735244952136505
  },
  {
    "name": "Donnelly",
    "lng": -96.04275525622509,
    "lat": 45.69616092054261
  },
  {
    "name": "Donnelly",
    "lng": -96.040041129324,
    "lat": 45.67762022717904
  },
  {
    "name": "Donnelly",
    "lng": -96.00854467944444,
    "lat": 45.68943596199111
  },
  {
    "name": "Donnelsville",
    "lng": -83.94319708852717,
    "lat": 39.91593856317188
  },
  {
    "name": "Donnybrook",
    "lng": -101.88743450419412,
    "lat": 48.50776561150019
  },
  {
    "name": "Donovan",
    "lng": -87.61404101193315,
    "lat": 40.88513551452476
  },
  {
    "name": "Donovan Estates",
    "lng": -114.67818995950738,
    "lat": 32.70939685984056
  },
  {
    "name": "Dooling",
    "lng": -83.92881766096309,
    "lat": 32.23016994306814
  },
  {
    "name": "Doolittle",
    "lng": -91.88883538284152,
    "lat": 37.94231977897032
  },
  {
    "name": "Doolittle",
    "lng": -91.87257358926009,
    "lat": 37.9353678189077
  },
  {
    "name": "Doon",
    "lng": -96.23287329518693,
    "lat": 43.279069953391925
  },
  {
    "name": "Dora",
    "lng": -103.3369429390291,
    "lat": 33.93253557685066
  },
  {
    "name": "Dora",
    "lng": -87.0849584036271,
    "lat": 33.729268494768505
  },
  {
    "name": "Doran",
    "lng": -96.48559380496508,
    "lat": 46.18509422292938
  },
  {
    "name": "Doraville",
    "lng": -84.27107832469414,
    "lat": 33.907237063137494
  },
  {
    "name": "Dorchester",
    "lng": -97.11502756605366,
    "lat": 40.6474181134187
  },
  {
    "name": "Dorchester",
    "lng": -96.69751947484792,
    "lat": 33.5333198004581
  },
  {
    "name": "Dorchester",
    "lng": -90.33094243493366,
    "lat": 45.0025118087265
  },
  {
    "name": "Dorchester",
    "lng": -89.88776873065066,
    "lat": 39.0858843824521
  },
  {
    "name": "Dorrance",
    "lng": -98.59007865839638,
    "lat": 38.84693615821782
  },
  {
    "name": "Dorris",
    "lng": -121.92056027407773,
    "lat": 41.96439419980736
  },
  {
    "name": "Dortches",
    "lng": -77.85666763492064,
    "lat": 36.01130102302904
  },
  {
    "name": "Dos Palos",
    "lng": -120.63386149064199,
    "lat": 36.98545979032092
  },
  {
    "name": "Dothan",
    "lng": -85.42525136518874,
    "lat": 31.159572717900573
  },
  {
    "name": "Dothan",
    "lng": -85.40613133806643,
    "lat": 31.232925714647187
  },
  {
    "name": "Double Oak",
    "lng": -97.11160586052505,
    "lat": 33.063384219620644
  },
  {
    "name": "Double Springs",
    "lng": -87.42310398818357,
    "lat": 34.1701562884145
  },
  {
    "name": "Double Springs",
    "lng": -87.40295850544025,
    "lat": 34.14950437140656
  },
  {
    "name": "Double Springs",
    "lng": -87.38807607674006,
    "lat": 34.15819511193978
  },
  {
    "name": "Double Springs",
    "lng": -87.38494406328554,
    "lat": 34.15766238474129
  },
  {
    "name": "Dougherty",
    "lng": -97.05140041293689,
    "lat": 34.400466920039776
  },
  {
    "name": "Dougherty",
    "lng": -93.03571657414551,
    "lat": 42.92201942012245
  },
  {
    "name": "Douglas",
    "lng": -134.39756130858905,
    "lat": 58.27758742646601
  },
  {
    "name": "Douglas",
    "lng": -109.6018125612127,
    "lat": 31.465864385675495
  },
  {
    "name": "Douglas",
    "lng": -109.53735378863227,
    "lat": 31.351342527858147
  },
  {
    "name": "Douglas",
    "lng": -105.39773909882467,
    "lat": 42.75353607200234
  },
  {
    "name": "Douglas",
    "lng": -105.36624033085204,
    "lat": 42.77341887670115
  },
  {
    "name": "Douglas",
    "lng": -101.50164306597104,
    "lat": 47.85795442994791
  },
  {
    "name": "Douglas",
    "lng": -97.66749053475601,
    "lat": 36.260410686322075
  },
  {
    "name": "Douglas",
    "lng": -96.38821880767036,
    "lat": 40.59318916238539
  },
  {
    "name": "Douglas",
    "lng": -86.32287179190513,
    "lat": 34.16526777078458
  },
  {
    "name": "Douglas",
    "lng": -86.32237730902673,
    "lat": 34.17057648263016
  },
  {
    "name": "Douglas",
    "lng": -86.20852585520977,
    "lat": 42.64112186398977
  },
  {
    "name": "Douglas",
    "lng": -82.86126303066746,
    "lat": 31.549266080513334
  },
  {
    "name": "Douglas",
    "lng": -82.85389467597052,
    "lat": 31.50585114361011
  },
  {
    "name": "Douglass",
    "lng": -97.01199726348814,
    "lat": 37.51720651857903
  },
  {
    "name": "Douglass",
    "lng": -97.00021327325825,
    "lat": 37.52460620112783
  },
  {
    "name": "Douglass Hills",
    "lng": -85.54986441695361,
    "lat": 38.23672173378331
  },
  {
    "name": "Douglassville",
    "lng": -94.35193698989995,
    "lat": 33.191651286690295
  },
  {
    "name": "Douglasville",
    "lng": -84.7836984177377,
    "lat": 33.76407625042854
  },
  {
    "name": "Douglasville",
    "lng": -84.75659391941618,
    "lat": 33.76058775079815
  },
  {
    "name": "Douglasville",
    "lng": -84.74945572668724,
    "lat": 33.742101021934054
  },
  {
    "name": "Douglasville",
    "lng": -84.73008320179495,
    "lat": 33.72360439880665
  },
  {
    "name": "Douglasville",
    "lng": -84.72044896942803,
    "lat": 33.767343827689416
  },
  {
    "name": "Douglasville",
    "lng": -84.71065965096577,
    "lat": 33.75169311339534
  },
  {
    "name": "Douglasville",
    "lng": -84.69697505871102,
    "lat": 33.740356676924684
  },
  {
    "name": "Douglasville",
    "lng": -84.67399345123218,
    "lat": 33.761498869492804
  },
  {
    "name": "Douglasville",
    "lng": -84.65965606589732,
    "lat": 33.76293373146028
  },
  {
    "name": "Douglasville",
    "lng": -84.65682553385761,
    "lat": 33.769713744789094
  },
  {
    "name": "Douglasville",
    "lng": -84.62623628239014,
    "lat": 33.72161753878605
  },
  {
    "name": "Douglasville",
    "lng": -84.61951279817906,
    "lat": 33.76844712011667
  },
  {
    "name": "Douglasville",
    "lng": -84.6188258410185,
    "lat": 33.78906929316849
  },
  {
    "name": "Douglasville",
    "lng": -84.61595767667731,
    "lat": 33.7813451285347
  },
  {
    "name": "Douglasville",
    "lng": -84.60196146806636,
    "lat": 33.774271394511004
  },
  {
    "name": "Dousman",
    "lng": -88.49189151817289,
    "lat": 43.01744196463957
  },
  {
    "name": "Dousman",
    "lng": -88.48356449449322,
    "lat": 43.01814462641876
  },
  {
    "name": "Dousman",
    "lng": -88.48208283140357,
    "lat": 42.98460459865318
  },
  {
    "name": "Dousman",
    "lng": -88.47336172521457,
    "lat": 43.013963590561445
  },
  {
    "name": "Dove Creek",
    "lng": -108.90695373512177,
    "lat": 37.76715436904724
  },
  {
    "name": "Dover",
    "lng": -116.60978287924033,
    "lat": 48.260666924746026
  },
  {
    "name": "Dover",
    "lng": -97.9109417473258,
    "lat": 35.98158412932795
  },
  {
    "name": "Dover",
    "lng": -97.9083521404409,
    "lat": 35.9759591750527
  },
  {
    "name": "Dover",
    "lng": -93.68977214168041,
    "lat": 39.194184946042775
  },
  {
    "name": "Dover",
    "lng": -93.11424639810821,
    "lat": 35.391054174943946
  },
  {
    "name": "Dover",
    "lng": -93.11423950391051,
    "lat": 35.3910168465198
  },
  {
    "name": "Dover",
    "lng": -92.13404662025698,
    "lat": 43.96983991741418
  },
  {
    "name": "Dover",
    "lng": -89.39606309428254,
    "lat": 41.433957587846656
  },
  {
    "name": "Dover",
    "lng": -87.84372021603856,
    "lat": 36.48190262538062
  },
  {
    "name": "Dover",
    "lng": -83.88444057786947,
    "lat": 38.756312996559366
  },
  {
    "name": "Dover",
    "lng": -81.4803868128127,
    "lat": 40.53022510228422
  },
  {
    "name": "Dover",
    "lng": -77.43375548314155,
    "lat": 35.215484007978716
  },
  {
    "name": "Dover",
    "lng": -76.84843674772246,
    "lat": 40.00330297028249
  },
  {
    "name": "Dover",
    "lng": -74.55966788929528,
    "lat": 40.885863427208044
  },
  {
    "name": "Dover",
    "lng": -70.8842292131391,
    "lat": 43.18918802902728
  },
  {
    "name": "Dover-Foxcroft",
    "lng": -69.20470482103711,
    "lat": 45.18089648492643
  },
  {
    "name": "Dovray",
    "lng": -95.54783734712764,
    "lat": 44.05450432868348
  },
  {
    "name": "Dow City",
    "lng": -95.49430405825957,
    "lat": 41.927413670538115
  },
  {
    "name": "Dowagiac",
    "lng": -86.13864278236758,
    "lat": 41.97939877134843
  },
  {
    "name": "Dowagiac",
    "lng": -86.11141448925392,
    "lat": 41.98390681455012
  },
  {
    "name": "Dowagiac",
    "lng": -86.08569916222001,
    "lat": 41.97515540424849
  },
  {
    "name": "Dowelltown",
    "lng": -85.94223533039205,
    "lat": 36.012145853673886
  },
  {
    "name": "Downers Grove",
    "lng": -88.01703686526515,
    "lat": 41.79474861648633
  },
  {
    "name": "Downey",
    "lng": -118.1311175161116,
    "lat": 33.93784775867052
  },
  {
    "name": "Downey",
    "lng": -112.12330347165118,
    "lat": 42.42882879436419
  },
  {
    "name": "Downing",
    "lng": -92.36886333017208,
    "lat": 40.48731759842683
  },
  {
    "name": "Downing",
    "lng": -92.12432740892973,
    "lat": 45.04976717043929
  },
  {
    "name": "Downs",
    "lng": -98.54591878286757,
    "lat": 39.5026420150372
  },
  {
    "name": "Downs",
    "lng": -88.89045365499659,
    "lat": 40.3998311913491
  },
  {
    "name": "Downsville",
    "lng": -92.41292019218677,
    "lat": 32.6264374887043
  },
  {
    "name": "Downtown Kansas City",
    "lng": -94.5860490871772,
    "lat": 39.097927574403606
  },
  {
    "name": "Dows",
    "lng": -93.50121403406965,
    "lat": 42.659268414751196
  },
  {
    "name": "Doyle",
    "lng": -85.51303423538396,
    "lat": 35.855788457543966
  },
  {
    "name": "Doylestown",
    "lng": -89.14575474377791,
    "lat": 43.427531480463145
  },
  {
    "name": "Doylestown",
    "lng": -81.695720697144,
    "lat": 40.969855266820915
  },
  {
    "name": "Doylestown",
    "lng": -75.12801248045211,
    "lat": 40.313899915930904
  },
  {
    "name": "Doyline",
    "lng": -93.41185054292077,
    "lat": 32.524957472856904
  },
  {
    "name": "Dozier",
    "lng": -86.36936389100607,
    "lat": 31.503075800556513
  },
  {
    "name": "Dr. Phillips",
    "lng": -81.49891988829097,
    "lat": 28.453710207919094
  },
  {
    "name": "Dracut",
    "lng": -71.30092478849187,
    "lat": 42.68327823110218
  },
  {
    "name": "Drake",
    "lng": -100.3746391065371,
    "lat": 47.92261860663554
  },
  {
    "name": "Drakes Branch",
    "lng": -78.60088040350172,
    "lat": 36.993350502542086
  },
  {
    "name": "Drakesboro",
    "lng": -87.05019513002752,
    "lat": 37.21716102297789
  },
  {
    "name": "Drakesville",
    "lng": -92.48144014629848,
    "lat": 40.798456848813146
  },
  {
    "name": "Draper",
    "lng": -111.86059887109329,
    "lat": 40.49564373510842
  },
  {
    "name": "Draper",
    "lng": -100.53718529595034,
    "lat": 43.92618783387212
  },
  {
    "name": "Draper Village",
    "lng": -79.68469352257239,
    "lat": 36.5130912302431
  },
  {
    "name": "Drayton",
    "lng": -97.17922807603097,
    "lat": 48.56203848793501
  },
  {
    "name": "Dresden",
    "lng": -100.41878010005291,
    "lat": 39.62170034786424
  },
  {
    "name": "Dresden",
    "lng": -88.69388990680189,
    "lat": 36.278442082364286
  },
  {
    "name": "Dresden",
    "lng": -82.01134121032986,
    "lat": 40.121448654742814
  },
  {
    "name": "Dresden",
    "lng": -76.95718269234429,
    "lat": 42.68348626843437
  },
  {
    "name": "Dresden",
    "lng": -69.73957092880526,
    "lat": 44.07929483402853
  },
  {
    "name": "Dresser",
    "lng": -92.63459671506955,
    "lat": 45.361668151673285
  },
  {
    "name": "Drew",
    "lng": -90.53039765315435,
    "lat": 33.81005227778354
  },
  {
    "name": "Drexel",
    "lng": -94.60253269416002,
    "lat": 38.50034087792172
  },
  {
    "name": "Drexel",
    "lng": -81.62152784398496,
    "lat": 35.76455267062148
  },
  {
    "name": "Drexel",
    "lng": -81.61646408442401,
    "lat": 35.75101906748688
  },
  {
    "name": "Drexel",
    "lng": -81.61641755368292,
    "lat": 35.770943381409154
  },
  {
    "name": "Drexel",
    "lng": -81.60965010808133,
    "lat": 35.76801350456262
  },
  {
    "name": "Drexel",
    "lng": -81.60810378540927,
    "lat": 35.756336321677914
  },
  {
    "name": "Drexel",
    "lng": -81.60248497523301,
    "lat": 35.75017975586721
  },
  {
    "name": "Drexel",
    "lng": -81.60236095951745,
    "lat": 35.74874501537703
  },
  {
    "name": "Drexel",
    "lng": -81.60112145514859,
    "lat": 35.747660076027046
  },
  {
    "name": "Drexel",
    "lng": -81.58486861533603,
    "lat": 35.772528654067834
  },
  {
    "name": "Driggs",
    "lng": -111.10373048856822,
    "lat": 43.72957311720818
  },
  {
    "name": "Dripping Springs",
    "lng": -98.11920019467608,
    "lat": 30.20523501426482
  },
  {
    "name": "Dripping Springs",
    "lng": -98.09055965523208,
    "lat": 30.19097570222015
  },
  {
    "name": "Dripping Springs",
    "lng": -98.04210319879263,
    "lat": 30.1956386458177
  },
  {
    "name": "Dripping Springs",
    "lng": -98.00805975647141,
    "lat": 30.193737527173305
  },
  {
    "name": "Dripping Springs",
    "lng": -98.00586632299307,
    "lat": 30.19361990488182
  },
  {
    "name": "Dripping Springs",
    "lng": -97.996503209225,
    "lat": 30.196142656702836
  },
  {
    "name": "Dripping Springs",
    "lng": -97.9804089400096,
    "lat": 30.204858590711563
  },
  {
    "name": "Driscoll",
    "lng": -97.75096761893283,
    "lat": 27.67297950107382
  },
  {
    "name": "Druid Hills",
    "lng": -85.66176914724798,
    "lat": 38.26493598899329
  },
  {
    "name": "Drummond",
    "lng": -113.14699648381148,
    "lat": 46.666185896717195
  },
  {
    "name": "Drummond",
    "lng": -111.34335735186423,
    "lat": 43.999589959896625
  },
  {
    "name": "Drummond",
    "lng": -98.0358045500811,
    "lat": 36.301192067939255
  },
  {
    "name": "Drumright",
    "lng": -96.59898854742728,
    "lat": 35.98929931672202
  },
  {
    "name": "Dry Prong",
    "lng": -92.53050628792516,
    "lat": 31.58065246454011
  },
  {
    "name": "Dry Ridge",
    "lng": -84.60046771000505,
    "lat": 38.67717459791336
  },
  {
    "name": "Dryden",
    "lng": -83.12401079986024,
    "lat": 42.94557621693108
  },
  {
    "name": "Dryden",
    "lng": -76.29953120399381,
    "lat": 42.49047376712558
  },
  {
    "name": "Du Bois",
    "lng": -96.04666936386828,
    "lat": 40.03454605831801
  },
  {
    "name": "Du Bois",
    "lng": -89.2126792554488,
    "lat": 38.221397528418805
  },
  {
    "name": "Du Pont",
    "lng": -82.8691563357676,
    "lat": 30.98901295298558
  },
  {
    "name": "Du Quoin",
    "lng": -89.23231482449016,
    "lat": 38.001931676832406
  },
  {
    "name": "DuBois",
    "lng": -78.75645267824609,
    "lat": 41.12250882689202
  },
  {
    "name": "DuPont",
    "lng": -122.6756373901574,
    "lat": 47.08172276807378
  },
  {
    "name": "DuPont",
    "lng": -122.64959219501372,
    "lat": 47.107926584950434
  },
  {
    "name": "Duarte",
    "lng": -117.95037257054656,
    "lat": 34.1610649269628
  },
  {
    "name": "Dubach",
    "lng": -92.65746519818236,
    "lat": 32.70195243743326
  },
  {
    "name": "Dubberly",
    "lng": -93.23739427962083,
    "lat": 32.54125527953812
  },
  {
    "name": "Dublin",
    "lng": -121.89628169389393,
    "lat": 37.716180253928776
  },
  {
    "name": "Dublin",
    "lng": -85.20575551584827,
    "lat": 39.81255149182093
  },
  {
    "name": "Dublin",
    "lng": -83.14488254307172,
    "lat": 40.111314736970584
  },
  {
    "name": "Dublin",
    "lng": -82.93695068430627,
    "lat": 32.48599218043044
  },
  {
    "name": "Dublin",
    "lng": -82.92491115301476,
    "lat": 32.535587878697456
  },
  {
    "name": "Dublin",
    "lng": -80.68320084251434,
    "lat": 37.09887819452361
  },
  {
    "name": "Dublin",
    "lng": -78.72309375072808,
    "lat": 34.65624717479076
  },
  {
    "name": "Dubois",
    "lng": -112.22926179938105,
    "lat": 44.172714439383626
  },
  {
    "name": "Dubois",
    "lng": -109.64425666844649,
    "lat": 43.538905368708996
  },
  {
    "name": "Dubuque",
    "lng": -90.70655643431336,
    "lat": 42.50083118013447
  },
  {
    "name": "Duchesne",
    "lng": -110.39415737609369,
    "lat": 40.175277704159626
  },
  {
    "name": "Duck",
    "lng": -75.75504141280132,
    "lat": 36.182870393901055
  },
  {
    "name": "Duck Hill",
    "lng": -89.71539622923764,
    "lat": 33.63164942687948
  },
  {
    "name": "Ducktown",
    "lng": -84.38812210341618,
    "lat": 35.03700194924352
  },
  {
    "name": "Dudley",
    "lng": -90.09157911134741,
    "lat": 36.789747088092035
  },
  {
    "name": "Dudley",
    "lng": -83.08046937215528,
    "lat": 32.532128343193754
  },
  {
    "name": "Dudley",
    "lng": -71.93517504101386,
    "lat": 42.05500404266714
  },
  {
    "name": "Due West",
    "lng": -82.38838194001946,
    "lat": 34.33345093322065
  },
  {
    "name": "Duenweg",
    "lng": -94.41273083461152,
    "lat": 37.08402556164218
  },
  {
    "name": "Duffield",
    "lng": -82.79597995614097,
    "lat": 36.71965019259312
  },
  {
    "name": "Dugger",
    "lng": -87.25903923314497,
    "lat": 39.07036603678382
  },
  {
    "name": "Duluth",
    "lng": -92.11946352553116,
    "lat": 46.78194585432055
  },
  {
    "name": "Duluth",
    "lng": -84.14930990306182,
    "lat": 34.005408728623955
  },
  {
    "name": "Dumas",
    "lng": -101.96414080929317,
    "lat": 35.86156950736641
  },
  {
    "name": "Dumas",
    "lng": -91.48551577145881,
    "lat": 33.88317870929462
  },
  {
    "name": "Dumas",
    "lng": -88.84309439365515,
    "lat": 34.63806840121609
  },
  {
    "name": "Dumbarton",
    "lng": -77.49781169120239,
    "lat": 37.60251684317233
  },
  {
    "name": "Dumfries",
    "lng": -77.32332394051,
    "lat": 38.56699698750472
  },
  {
    "name": "Dumont",
    "lng": -96.42332235031176,
    "lat": 45.71723452447554
  },
  {
    "name": "Dumont",
    "lng": -92.97362472016056,
    "lat": 42.752200219635675
  },
  {
    "name": "Dunbar",
    "lng": -96.03086409722822,
    "lat": 40.668679340029826
  },
  {
    "name": "Dunbar",
    "lng": -81.73540104050467,
    "lat": 38.38441007883199
  },
  {
    "name": "Dunbar",
    "lng": -81.73443908241336,
    "lat": 38.3667647956494
  },
  {
    "name": "Duncan",
    "lng": -109.09633899704284,
    "lat": 32.735093728626524
  },
  {
    "name": "Duncan",
    "lng": -98.0243112030761,
    "lat": 34.48396389720547
  },
  {
    "name": "Duncan",
    "lng": -98.00987187920651,
    "lat": 34.468520459906536
  },
  {
    "name": "Duncan",
    "lng": -97.9965135645571,
    "lat": 34.57873110945194
  },
  {
    "name": "Duncan",
    "lng": -97.96081017849608,
    "lat": 34.52986989145256
  },
  {
    "name": "Duncan",
    "lng": -97.94909871410462,
    "lat": 34.461264489214365
  },
  {
    "name": "Duncan",
    "lng": -97.89240862588677,
    "lat": 34.511939045253605
  },
  {
    "name": "Duncan",
    "lng": -97.8907222324465,
    "lat": 34.50769707606837
  },
  {
    "name": "Duncan",
    "lng": -97.84650218788111,
    "lat": 34.60491258031899
  },
  {
    "name": "Duncan",
    "lng": -97.81911340078015,
    "lat": 34.52002805004955
  },
  {
    "name": "Duncan",
    "lng": -97.68426323640647,
    "lat": 34.611974696008076
  },
  {
    "name": "Duncan",
    "lng": -97.49336860618169,
    "lat": 41.39008257242783
  },
  {
    "name": "Duncan",
    "lng": -90.74589591346987,
    "lat": 34.04181053827598
  },
  {
    "name": "Duncan",
    "lng": -82.14102505191558,
    "lat": 34.937944462541914
  },
  {
    "name": "Duncan",
    "lng": -82.10731276153636,
    "lat": 34.91821060225986
  },
  {
    "name": "Duncan",
    "lng": -82.10591047117006,
    "lat": 34.923642292908035
  },
  {
    "name": "Duncanville",
    "lng": -96.91275853953174,
    "lat": 32.645963524785294
  },
  {
    "name": "Duncombe",
    "lng": -93.99691398335145,
    "lat": 42.46980627943011
  },
  {
    "name": "Dundas",
    "lng": -93.2065160606157,
    "lat": 44.428136040307756
  },
  {
    "name": "Dundee",
    "lng": -95.46677463014395,
    "lat": 43.84411565916767
  },
  {
    "name": "Dundee",
    "lng": -91.546510807683,
    "lat": 42.57923842528541
  },
  {
    "name": "Dundee",
    "lng": -83.66599144363997,
    "lat": 41.96587300003905
  },
  {
    "name": "Dundee",
    "lng": -83.65531126625406,
    "lat": 41.9937322016566
  },
  {
    "name": "Dundee",
    "lng": -83.63292848950535,
    "lat": 41.970888372775335
  },
  {
    "name": "Dundee",
    "lng": -81.60062248069666,
    "lat": 28.01197124264959
  },
  {
    "name": "Dundee",
    "lng": -81.56641086791758,
    "lat": 28.03912006094606
  },
  {
    "name": "Dundee",
    "lng": -76.97742018946794,
    "lat": 42.5237826747217
  },
  {
    "name": "Dune Acres",
    "lng": -87.09657915674279,
    "lat": 41.64484912690025
  },
  {
    "name": "Dunfermline",
    "lng": -90.03208801342812,
    "lat": 40.49142484868738
  },
  {
    "name": "Dungannon",
    "lng": -82.46811496306081,
    "lat": 36.82838816516211
  },
  {
    "name": "Dunkerton",
    "lng": -92.16049616220258,
    "lat": 42.56799571492507
  },
  {
    "name": "Dunkirk",
    "lng": -85.2072544277083,
    "lat": 40.37443924706478
  },
  {
    "name": "Dunkirk",
    "lng": -83.64297873012922,
    "lat": 40.78797191423171
  },
  {
    "name": "Dunkirk",
    "lng": -79.33226779318314,
    "lat": 42.48039859783422
  },
  {
    "name": "Dunkirk",
    "lng": -76.67296180035952,
    "lat": 38.71557242721457
  },
  {
    "name": "Dunlap",
    "lng": -96.3675198929945,
    "lat": 38.576084426934614
  },
  {
    "name": "Dunlap",
    "lng": -95.6019390895194,
    "lat": 41.851748629208885
  },
  {
    "name": "Dunlap",
    "lng": -89.67894901713376,
    "lat": 40.84352385664128
  },
  {
    "name": "Dunlap",
    "lng": -89.66214519703567,
    "lat": 40.85181153659631
  },
  {
    "name": "Dunlap",
    "lng": -89.65890748151145,
    "lat": 40.84177864274023
  },
  {
    "name": "Dunlap",
    "lng": -89.6506355394938,
    "lat": 40.84888908682522
  },
  {
    "name": "Dunlap",
    "lng": -89.64969348381645,
    "lat": 40.85158879168334
  },
  {
    "name": "Dunlap",
    "lng": -85.38965700110712,
    "lat": 35.36764169348101
  },
  {
    "name": "Dunn",
    "lng": -78.65295770305529,
    "lat": 35.31119318737153
  },
  {
    "name": "Dunn",
    "lng": -78.64642276797368,
    "lat": 35.32606246106494
  },
  {
    "name": "Dunn",
    "lng": -78.6316003004911,
    "lat": 35.32941557122743
  },
  {
    "name": "Dunn",
    "lng": -78.61489710119102,
    "lat": 35.27111803999231
  },
  {
    "name": "Dunn",
    "lng": -78.61374800834766,
    "lat": 35.3114861495471
  },
  {
    "name": "Dunn",
    "lng": -78.59910082763496,
    "lat": 35.327315066089234
  },
  {
    "name": "Dunn",
    "lng": -78.59693854953696,
    "lat": 35.32676210211756
  },
  {
    "name": "Dunn",
    "lng": -78.5936976015792,
    "lat": 35.28278437565473
  },
  {
    "name": "Dunn",
    "lng": -78.59178555255598,
    "lat": 35.27753873654509
  },
  {
    "name": "Dunn",
    "lng": -78.5832081791562,
    "lat": 35.30647038963048
  },
  {
    "name": "Dunn",
    "lng": -78.57713443244018,
    "lat": 35.31884969148763
  },
  {
    "name": "Dunn Center",
    "lng": -102.62347280158906,
    "lat": 47.35267375510335
  },
  {
    "name": "Dunnell",
    "lng": -94.7751777058394,
    "lat": 43.560476263579446
  },
  {
    "name": "Dunnellon",
    "lng": -82.4488041930422,
    "lat": 29.04901211399095
  },
  {
    "name": "Dunnellon",
    "lng": -82.44620618824042,
    "lat": 29.06842188688398
  },
  {
    "name": "Dunnellon",
    "lng": -82.40472386981095,
    "lat": 29.067717188505807
  },
  {
    "name": "Dunning",
    "lng": -100.10456171673846,
    "lat": 41.8270264668369
  },
  {
    "name": "Dunreith",
    "lng": -85.4366708609037,
    "lat": 39.80280364270554
  },
  {
    "name": "Dunseith",
    "lng": -100.06237444228665,
    "lat": 48.813028363664415
  },
  {
    "name": "Dunsmuir",
    "lng": -122.27114731835063,
    "lat": 41.23180258567668
  },
  {
    "name": "Dunstable",
    "lng": -71.50054546816907,
    "lat": 42.6764152764827
  },
  {
    "name": "Dunwoody",
    "lng": -84.31221294657996,
    "lat": 33.94187437043372
  },
  {
    "name": "Dupo",
    "lng": -90.20806426526991,
    "lat": 38.51762449757571
  },
  {
    "name": "Dupont",
    "lng": -85.51687733931502,
    "lat": 38.89143567137838
  },
  {
    "name": "Dupont",
    "lng": -84.30100383660991,
    "lat": 41.05466811149802
  },
  {
    "name": "Dupree",
    "lng": -101.60131639068855,
    "lat": 45.04947929535377
  },
  {
    "name": "Duquesne",
    "lng": -94.45721030151225,
    "lat": 37.070972097286464
  },
  {
    "name": "Durand",
    "lng": -91.96103797056465,
    "lat": 44.6282400764741
  },
  {
    "name": "Durand",
    "lng": -89.33245208701183,
    "lat": 42.42781343865464
  },
  {
    "name": "Durand",
    "lng": -89.32627642242142,
    "lat": 42.4346953517197
  },
  {
    "name": "Durand",
    "lng": -83.9886923947484,
    "lat": 42.91367582865237
  },
  {
    "name": "Durango",
    "lng": -107.9143338050048,
    "lat": 37.27511423749042
  },
  {
    "name": "Durango",
    "lng": -107.88639616622031,
    "lat": 37.287965136466326
  },
  {
    "name": "Durango",
    "lng": -107.87369368036181,
    "lat": 37.281746466366705
  },
  {
    "name": "Durango",
    "lng": -107.87139982641553,
    "lat": 37.28155340982716
  },
  {
    "name": "Durango",
    "lng": -107.86557447713696,
    "lat": 37.2891287414954
  },
  {
    "name": "Durango",
    "lng": -107.86419336726725,
    "lat": 37.225634137552646
  },
  {
    "name": "Durango",
    "lng": -107.86231432002675,
    "lat": 37.27464846683613
  },
  {
    "name": "Durango",
    "lng": -107.86182483720327,
    "lat": 37.2977997396035
  },
  {
    "name": "Durango",
    "lng": -107.861247744832,
    "lat": 37.291472317241634
  },
  {
    "name": "Durango",
    "lng": -107.859471883495,
    "lat": 37.27599702990942
  },
  {
    "name": "Durango",
    "lng": -107.85841234959106,
    "lat": 37.28962798348513
  },
  {
    "name": "Durango",
    "lng": -107.85781774520052,
    "lat": 37.222178970442215
  },
  {
    "name": "Durango",
    "lng": -107.85512446996144,
    "lat": 37.22022873733308
  },
  {
    "name": "Durango",
    "lng": -107.85102927120454,
    "lat": 37.29751580953874
  },
  {
    "name": "Durango",
    "lng": -107.8492228541614,
    "lat": 37.29889888152388
  },
  {
    "name": "Durango",
    "lng": -107.82480284472926,
    "lat": 37.240610402498454
  },
  {
    "name": "Durango",
    "lng": -90.77447509381606,
    "lat": 42.56104906634045
  },
  {
    "name": "Durant",
    "lng": -96.39378956509714,
    "lat": 33.99570173509034
  },
  {
    "name": "Durant",
    "lng": -90.91454034539281,
    "lat": 41.60118862332238
  },
  {
    "name": "Durant",
    "lng": -89.85597473617261,
    "lat": 33.080153528665576
  },
  {
    "name": "Durbin",
    "lng": -79.82787006986051,
    "lat": 38.54711375800044
  },
  {
    "name": "Durfee Hill Management Area",
    "lng": -71.78623503125714,
    "lat": 41.90187846602908
  },
  {
    "name": "Durfee Hill Management Area",
    "lng": -71.76266060851238,
    "lat": 41.91454048401513
  },
  {
    "name": "Durfee Hill Management Area",
    "lng": -71.75849162021531,
    "lat": 41.91721259909483
  },
  {
    "name": "Durfee Hill Management Area",
    "lng": -71.74790101802623,
    "lat": 41.90388548126746
  },
  {
    "name": "Durham",
    "lng": -97.22724918867779,
    "lat": 38.48489660637744
  },
  {
    "name": "Durham",
    "lng": -78.99653396212076,
    "lat": 35.930290314739736
  },
  {
    "name": "Durham",
    "lng": -78.99457622893658,
    "lat": 35.98965131721308
  },
  {
    "name": "Durham",
    "lng": -78.99224733894663,
    "lat": 35.886100796045355
  },
  {
    "name": "Durham",
    "lng": -78.99133462444335,
    "lat": 35.98869004386478
  },
  {
    "name": "Durham",
    "lng": -78.97893974386989,
    "lat": 35.924273064774674
  },
  {
    "name": "Durham",
    "lng": -78.97798941469468,
    "lat": 35.89448231196364
  },
  {
    "name": "Durham",
    "lng": -78.97608136869768,
    "lat": 36.07341320532965
  },
  {
    "name": "Durham",
    "lng": -78.97189942319741,
    "lat": 36.075878290137155
  },
  {
    "name": "Durham",
    "lng": -78.9591881610421,
    "lat": 35.89054611881881
  },
  {
    "name": "Durham",
    "lng": -78.95826849480505,
    "lat": 36.07512517258315
  },
  {
    "name": "Durham",
    "lng": -78.93966098659143,
    "lat": 36.10328137834889
  },
  {
    "name": "Durham",
    "lng": -78.9386375025714,
    "lat": 35.87097201382906
  },
  {
    "name": "Durham",
    "lng": -78.93244502164505,
    "lat": 35.881482040463396
  },
  {
    "name": "Durham",
    "lng": -78.93097042430986,
    "lat": 35.876671603857794
  },
  {
    "name": "Durham",
    "lng": -78.90538523517044,
    "lat": 35.97514479717114
  },
  {
    "name": "Durham",
    "lng": -78.88133805351505,
    "lat": 36.09117965696318
  },
  {
    "name": "Durham",
    "lng": -78.87409867188275,
    "lat": 36.06155424603725
  },
  {
    "name": "Durham",
    "lng": -78.8719735342986,
    "lat": 36.09156296794463
  },
  {
    "name": "Durham",
    "lng": -78.86584388517781,
    "lat": 36.115189004363835
  },
  {
    "name": "Durham",
    "lng": -78.85879898813735,
    "lat": 36.06022145231356
  },
  {
    "name": "Durham",
    "lng": -78.85816456078607,
    "lat": 36.05068060594445
  },
  {
    "name": "Durham",
    "lng": -78.85250091134576,
    "lat": 36.02743686114798
  },
  {
    "name": "Durham",
    "lng": -78.84929303483429,
    "lat": 35.959204295915264
  },
  {
    "name": "Durham",
    "lng": -78.84507861389774,
    "lat": 35.96400839939129
  },
  {
    "name": "Durham",
    "lng": -78.84495702080997,
    "lat": 35.95623161631098
  },
  {
    "name": "Durham",
    "lng": -78.84409321366964,
    "lat": 35.95555893602919
  },
  {
    "name": "Durham",
    "lng": -78.84280789547496,
    "lat": 36.02865612900508
  },
  {
    "name": "Durham",
    "lng": -78.84164800307333,
    "lat": 35.95478200694646
  },
  {
    "name": "Durham",
    "lng": -78.8404937447311,
    "lat": 35.95386763676327
  },
  {
    "name": "Durham",
    "lng": -78.83904075077211,
    "lat": 36.052535016277844
  },
  {
    "name": "Durham",
    "lng": -78.83763367570897,
    "lat": 36.046173288856124
  },
  {
    "name": "Durham",
    "lng": -78.83684576300368,
    "lat": 36.045646071400526
  },
  {
    "name": "Durham",
    "lng": -78.82385615834241,
    "lat": 36.02544328095976
  },
  {
    "name": "Durham",
    "lng": -78.8236896521838,
    "lat": 35.975593990967205
  },
  {
    "name": "Durham",
    "lng": -78.81365745038879,
    "lat": 35.97396280669866
  },
  {
    "name": "Durham",
    "lng": -78.81178891518319,
    "lat": 35.93101330180522
  },
  {
    "name": "Durham",
    "lng": -78.810950422352,
    "lat": 35.928911971043824
  },
  {
    "name": "Durham",
    "lng": -78.80719668420745,
    "lat": 36.035530059259415
  },
  {
    "name": "Durham",
    "lng": -78.80683863600748,
    "lat": 35.931669842911724
  },
  {
    "name": "Durham",
    "lng": -78.80658050918466,
    "lat": 35.98248319802359
  },
  {
    "name": "Durham",
    "lng": -78.80646007586736,
    "lat": 35.99652917725408
  },
  {
    "name": "Durham",
    "lng": -78.8052269332845,
    "lat": 35.997465843941065
  },
  {
    "name": "Durham",
    "lng": -78.80442914853522,
    "lat": 36.020946764576834
  },
  {
    "name": "Durham",
    "lng": -78.80330749918214,
    "lat": 35.989899206665406
  },
  {
    "name": "Durham",
    "lng": -78.79921403542373,
    "lat": 36.01561256154316
  },
  {
    "name": "Durham",
    "lng": -78.79646184021307,
    "lat": 35.94125809636301
  },
  {
    "name": "Durham",
    "lng": -78.78662919983188,
    "lat": 35.975005416248706
  },
  {
    "name": "Durham",
    "lng": -78.77194894357356,
    "lat": 35.969432582581774
  },
  {
    "name": "Durham",
    "lng": -78.77168060336211,
    "lat": 35.98311080687612
  },
  {
    "name": "Durham",
    "lng": -72.68280332970701,
    "lat": 41.46222674843544
  },
  {
    "name": "Durham",
    "lng": -70.1296475165656,
    "lat": 43.96447112083585
  },
  {
    "name": "Duson",
    "lng": -92.1921741393563,
    "lat": 30.236101030292204
  },
  {
    "name": "Dustin",
    "lng": -96.032013411538,
    "lat": 35.27115900994988
  },
  {
    "name": "Dutch John",
    "lng": -109.40422867095513,
    "lat": 40.9300653410967
  },
  {
    "name": "Dutchtown",
    "lng": -89.65575485263702,
    "lat": 37.25088058988875
  },
  {
    "name": "Dutton",
    "lng": -111.71424949312507,
    "lat": 47.84753467849042
  },
  {
    "name": "Dutton",
    "lng": -85.91184508393638,
    "lat": 34.60614685667026
  },
  {
    "name": "Duvall",
    "lng": -121.97237537809927,
    "lat": 47.735369169524624
  },
  {
    "name": "Dwight",
    "lng": -97.01907287981649,
    "lat": 41.08271140081907
  },
  {
    "name": "Dwight",
    "lng": -96.74000424788227,
    "lat": 46.30378162318907
  },
  {
    "name": "Dwight",
    "lng": -96.59223565253288,
    "lat": 38.844831331497694
  },
  {
    "name": "Dwight",
    "lng": -88.42335556908598,
    "lat": 41.09800797906229
  },
  {
    "name": "Dycusburg",
    "lng": -88.18461279533481,
    "lat": 37.16036054808038
  },
  {
    "name": "Dyer",
    "lng": -94.1384382852746,
    "lat": 35.494384934048085
  },
  {
    "name": "Dyer",
    "lng": -88.9923803233579,
    "lat": 36.07116370343818
  },
  {
    "name": "Dyer",
    "lng": -87.50880661386762,
    "lat": 41.498285406948014
  },
  {
    "name": "Dyer Brook",
    "lng": -68.23421431952367,
    "lat": 46.08052905320627
  },
  {
    "name": "Dyersburg",
    "lng": -89.37777142411338,
    "lat": 36.04673199222534
  },
  {
    "name": "Dyersville",
    "lng": -91.11770861326495,
    "lat": 42.48172111989906
  },
  {
    "name": "Dyess",
    "lng": -90.21329250837688,
    "lat": 35.58935589681016
  },
  {
    "name": "Dysart",
    "lng": -92.3090977224746,
    "lat": 42.17192432155314
  },
  {
    "name": "Eads",
    "lng": -102.77977480609789,
    "lat": 38.481333015169525
  },
  {
    "name": "Eagan",
    "lng": -93.16388501883762,
    "lat": 44.81693029997737
  },
  {
    "name": "Eagar",
    "lng": -109.29556918943312,
    "lat": 34.10576532999797
  },
  {
    "name": "Eagarville",
    "lng": -89.7831555200195,
    "lat": 39.11212088188532
  },
  {
    "name": "Eagle",
    "lng": -141.19387854737147,
    "lat": 64.78034661082934
  },
  {
    "name": "Eagle",
    "lng": -116.43276376258522,
    "lat": 43.72284027763836
  },
  {
    "name": "Eagle",
    "lng": -116.42400003376484,
    "lat": 43.69076487069027
  },
  {
    "name": "Eagle",
    "lng": -116.3865424930973,
    "lat": 43.72221644084607
  },
  {
    "name": "Eagle",
    "lng": -116.3355406007645,
    "lat": 43.68786665614287
  },
  {
    "name": "Eagle",
    "lng": -106.82647967473119,
    "lat": 39.66431505665929
  },
  {
    "name": "Eagle",
    "lng": -106.81550735757818,
    "lat": 39.63619188405295
  },
  {
    "name": "Eagle",
    "lng": -96.43308466099786,
    "lat": 40.816367297771436
  },
  {
    "name": "Eagle",
    "lng": -88.46936790813791,
    "lat": 42.879093043944536
  },
  {
    "name": "Eagle",
    "lng": -84.79075179747352,
    "lat": 42.80954071281921
  },
  {
    "name": "Eagle Bend",
    "lng": -95.0341052422973,
    "lat": 46.16426269222869
  },
  {
    "name": "Eagle Butte",
    "lng": -101.26837920105322,
    "lat": 44.993051391151674
  },
  {
    "name": "Eagle Butte",
    "lng": -101.2273798825431,
    "lat": 44.99073503886697
  },
  {
    "name": "Eagle Grove",
    "lng": -93.90237376843201,
    "lat": 42.66617400764081
  },
  {
    "name": "Eagle Harbor",
    "lng": -76.68670414968823,
    "lat": 38.56693955152571
  },
  {
    "name": "Eagle Lake",
    "lng": -96.32828198091612,
    "lat": 29.587966341066515
  },
  {
    "name": "Eagle Lake",
    "lng": -93.9065942757974,
    "lat": 44.172826837238
  },
  {
    "name": "Eagle Lake",
    "lng": -93.88393920881592,
    "lat": 44.16111995382042
  },
  {
    "name": "Eagle Lake",
    "lng": -81.75785749853267,
    "lat": 27.975774096950875
  },
  {
    "name": "Eagle Lake",
    "lng": -68.63012871031778,
    "lat": 47.05780620973002
  },
  {
    "name": "Eagle Mountain",
    "lng": -112.01149158048024,
    "lat": 40.31366774169216
  },
  {
    "name": "Eagle Nest",
    "lng": -105.25956227630964,
    "lat": 36.54808381698177
  },
  {
    "name": "Eagle Pass",
    "lng": -100.48294400217812,
    "lat": 28.711710364823453
  },
  {
    "name": "Eagle River",
    "lng": -89.2560357958512,
    "lat": 45.924204045064904
  },
  {
    "name": "Eagle River",
    "lng": -89.22947421883751,
    "lat": 45.91431355283123
  },
  {
    "name": "Eagleville",
    "lng": -93.9861121803308,
    "lat": 40.468449138962825
  },
  {
    "name": "Eagleville",
    "lng": -86.65305199638568,
    "lat": 35.74052382129808
  },
  {
    "name": "Eakly",
    "lng": -98.55618987743776,
    "lat": 35.30541802386275
  },
  {
    "name": "Earl",
    "lng": -81.53444447302448,
    "lat": 35.19561681674772
  },
  {
    "name": "Earl Park",
    "lng": -87.41993212385064,
    "lat": 40.68555820246374
  },
  {
    "name": "Earle",
    "lng": -90.4642764795198,
    "lat": 35.273352991465146
  },
  {
    "name": "Earlham",
    "lng": -94.1223592233267,
    "lat": 41.49439212383122
  },
  {
    "name": "Earlimart",
    "lng": -119.27146165125919,
    "lat": 35.88183307142052
  },
  {
    "name": "Earling",
    "lng": -95.41907771943289,
    "lat": 41.776294743480065
  },
  {
    "name": "Earlington",
    "lng": -87.51453367351193,
    "lat": 37.273324151503886
  },
  {
    "name": "Earlsboro",
    "lng": -96.8013927101901,
    "lat": 35.32769249983308
  },
  {
    "name": "Earlton",
    "lng": -95.46994746255321,
    "lat": 37.58736375335404
  },
  {
    "name": "Earlville",
    "lng": -91.2700179468263,
    "lat": 42.48252792077752
  },
  {
    "name": "Earlville",
    "lng": -88.9229545796552,
    "lat": 41.588068148805725
  },
  {
    "name": "Earlville",
    "lng": -75.54360914106184,
    "lat": 42.740080888252336
  },
  {
    "name": "Early",
    "lng": -98.93734010692798,
    "lat": 31.74508831062001
  },
  {
    "name": "Early",
    "lng": -95.15234869632687,
    "lat": 42.46138100853323
  },
  {
    "name": "Earth",
    "lng": -102.40917638047262,
    "lat": 34.23380105404166
  },
  {
    "name": "Easley",
    "lng": -82.5840212150965,
    "lat": 34.81970978465748
  },
  {
    "name": "Easley",
    "lng": -82.57080202496412,
    "lat": 34.80223854247633
  },
  {
    "name": "Easley",
    "lng": -82.54576733921253,
    "lat": 34.82926709451039
  },
  {
    "name": "East Alton",
    "lng": -90.107254836596,
    "lat": 38.88390673607013
  },
  {
    "name": "East Arcadia",
    "lng": -78.32354390574608,
    "lat": 34.38153493822852
  },
  {
    "name": "East Arcadia",
    "lng": -78.29354390138359,
    "lat": 34.3871430904479
  },
  {
    "name": "East Aurora",
    "lng": -78.61717350834532,
    "lat": 42.766722908799615
  },
  {
    "name": "East Bank",
    "lng": -81.44414014196467,
    "lat": 38.21532255752555
  },
  {
    "name": "East Bend",
    "lng": -80.50870540221351,
    "lat": 36.21667702524353
  },
  {
    "name": "East Bernard",
    "lng": -96.06203608161682,
    "lat": 29.524032373510682
  },
  {
    "name": "East Bethel",
    "lng": -93.20352750647251,
    "lat": 45.35555094380413
  },
  {
    "name": "East Brewton",
    "lng": -87.05559425539568,
    "lat": 31.090540277558336
  },
  {
    "name": "East Brookfield",
    "lng": -72.04671896560394,
    "lat": 42.20327237889614
  },
  {
    "name": "East Brooklyn",
    "lng": -88.26603174493489,
    "lat": 41.17276557784617
  },
  {
    "name": "East Brooklyn",
    "lng": -71.90227088530538,
    "lat": 41.798042886697964
  },
  {
    "name": "East Camden",
    "lng": -92.74336812885416,
    "lat": 33.60835557501475
  },
  {
    "name": "East Canton",
    "lng": -81.2836629011623,
    "lat": 40.788983430292596
  },
  {
    "name": "East Cape Girardeau",
    "lng": -89.48691011569397,
    "lat": 37.29573251931636
  },
  {
    "name": "East Carbon",
    "lng": -110.43275766285672,
    "lat": 39.53354583069831
  },
  {
    "name": "East Carondelet",
    "lng": -90.24078901377545,
    "lat": 38.53609514971457
  },
  {
    "name": "East Cleveland",
    "lng": -81.57945123369765,
    "lat": 41.53175930525445
  },
  {
    "name": "East Dublin",
    "lng": -82.86732075504412,
    "lat": 32.54818913914039
  },
  {
    "name": "East Dubuque",
    "lng": -90.62939009665854,
    "lat": 42.489902604432444
  },
  {
    "name": "East Dubuque",
    "lng": -90.59757217693058,
    "lat": 42.46567522743615
  },
  {
    "name": "East Dubuque",
    "lng": -90.59565575790616,
    "lat": 42.46705206635396
  },
  {
    "name": "East Duke",
    "lng": -99.56962711664815,
    "lat": 34.66279656877782
  },
  {
    "name": "East Dundee",
    "lng": -88.27373491031342,
    "lat": 42.08880610574034
  },
  {
    "name": "East Dundee",
    "lng": -88.25433527916307,
    "lat": 42.09724066204405
  },
  {
    "name": "East Ellijay",
    "lng": -84.47613401540289,
    "lat": 34.6758026383953
  },
  {
    "name": "East Galesburg",
    "lng": -90.31091580551066,
    "lat": 40.9427743925153
  },
  {
    "name": "East Germantown",
    "lng": -85.13684291267977,
    "lat": 39.813172026702766
  },
  {
    "name": "East Gillespie",
    "lng": -89.81268511136226,
    "lat": 39.1378192541537
  },
  {
    "name": "East Granby",
    "lng": -72.74113816329064,
    "lat": 41.94248683352811
  },
  {
    "name": "East Grand Forks",
    "lng": -97.01385186981582,
    "lat": 47.928508893253735
  },
  {
    "name": "East Grand Rapids",
    "lng": -85.60882256293434,
    "lat": 42.946372310911656
  },
  {
    "name": "East Greenwich",
    "lng": -71.50628716542143,
    "lat": 41.6359782645629
  },
  {
    "name": "East Gull Lake",
    "lng": -94.35475394510047,
    "lat": 46.3948051009129
  },
  {
    "name": "East Haddam",
    "lng": -72.39456384212879,
    "lat": 41.479803219447255
  },
  {
    "name": "East Hampton",
    "lng": -72.50754653558121,
    "lat": 41.569724110623156
  },
  {
    "name": "East Hartford",
    "lng": -72.61529551294099,
    "lat": 41.7634573171391
  },
  {
    "name": "East Haven",
    "lng": -72.85891788071086,
    "lat": 41.295379102767676
  },
  {
    "name": "East Hazel Crest",
    "lng": -87.65058270374763,
    "lat": 41.57576596006375
  },
  {
    "name": "East Hills",
    "lng": -73.62918189009598,
    "lat": 40.79583613729513
  },
  {
    "name": "East Hodge",
    "lng": -92.71402993723716,
    "lat": 32.277549991078175
  },
  {
    "name": "East Hope",
    "lng": -116.29127213939911,
    "lat": 48.24142129018777
  },
  {
    "name": "East Jordan",
    "lng": -85.13082262560931,
    "lat": 45.15602254314761
  },
  {
    "name": "East Jordan",
    "lng": -85.11278882135137,
    "lat": 45.128177859847355
  },
  {
    "name": "East Lake-Orient Park",
    "lng": -82.3787824067111,
    "lat": 27.98269266859554
  },
  {
    "name": "East Lansing",
    "lng": -84.48323660390608,
    "lat": 42.74858618553173
  },
  {
    "name": "East Laurinburg",
    "lng": -79.44500862231871,
    "lat": 34.769076844915844
  },
  {
    "name": "East Liverpool",
    "lng": -80.56801349031727,
    "lat": 40.6331412155785
  },
  {
    "name": "East Longmeadow",
    "lng": -72.49905307375529,
    "lat": 42.05977755289935
  },
  {
    "name": "East Lyme",
    "lng": -72.23160347272247,
    "lat": 41.3556095589003
  },
  {
    "name": "East Lynne",
    "lng": -94.2306976242526,
    "lat": 38.6682077710703
  },
  {
    "name": "East Machias",
    "lng": -67.41927677725027,
    "lat": 44.77718001087222
  },
  {
    "name": "East Millinocket",
    "lng": -68.58644071462997,
    "lat": 45.64710167666371
  },
  {
    "name": "East Moline",
    "lng": -90.38886407327837,
    "lat": 41.52009091893279
  },
  {
    "name": "East Moline",
    "lng": -90.36939976889782,
    "lat": 41.5149420779871
  },
  {
    "name": "East Mountain",
    "lng": -94.85398545015704,
    "lat": 32.59967346395046
  },
  {
    "name": "East Nassau",
    "lng": -73.50853170380765,
    "lat": 42.53312810965187
  },
  {
    "name": "East New Market",
    "lng": -75.92301007914949,
    "lat": 38.596896419719265
  },
  {
    "name": "East Orange",
    "lng": -74.21168731130498,
    "lat": 40.76504946988008
  },
  {
    "name": "East Palatka",
    "lng": -81.59819228951024,
    "lat": 29.650595723913582
  },
  {
    "name": "East Palestine",
    "lng": -80.54630877029449,
    "lat": 40.83862332831429
  },
  {
    "name": "East Palo Alto",
    "lng": -122.13510560415793,
    "lat": 37.46700372608792
  },
  {
    "name": "East Peoria",
    "lng": -89.56995460146884,
    "lat": 40.62605881969525
  },
  {
    "name": "East Peoria",
    "lng": -89.5418147919629,
    "lat": 40.67344158261738
  },
  {
    "name": "East Peru",
    "lng": -93.92951356255759,
    "lat": 41.227046114057885
  },
  {
    "name": "East Point",
    "lng": -84.47001656718797,
    "lat": 33.66958518212688
  },
  {
    "name": "East Prairie",
    "lng": -89.38417624655382,
    "lat": 36.77892925362711
  },
  {
    "name": "East Prairie",
    "lng": -89.3764210128179,
    "lat": 36.7898898836304
  },
  {
    "name": "East Prospect",
    "lng": -76.52140309208781,
    "lat": 39.97101150093219
  },
  {
    "name": "East Providence",
    "lng": -71.36930088497319,
    "lat": 41.777725503760415
  },
  {
    "name": "East Providence",
    "lng": -71.36733283081345,
    "lat": 41.77722285163315
  },
  {
    "name": "East Providence",
    "lng": -71.35667578132797,
    "lat": 41.807672550854114
  },
  {
    "name": "East Randolph",
    "lng": -78.9521266076761,
    "lat": 42.1691528004349
  },
  {
    "name": "East Ridge",
    "lng": -85.22851141255443,
    "lat": 34.99727419314847
  },
  {
    "name": "East Rochester",
    "lng": -77.48683178195084,
    "lat": 43.11230985481496
  },
  {
    "name": "East Rockaway",
    "lng": -73.66714334872185,
    "lat": 40.64314088345388
  },
  {
    "name": "East Saint Louis",
    "lng": -90.13043437292897,
    "lat": 38.615230053005796
  },
  {
    "name": "East Saint Louis",
    "lng": -90.05143168242805,
    "lat": 38.59850329839909
  },
  {
    "name": "East Sparta",
    "lng": -81.35246941028286,
    "lat": 40.670049206255655
  },
  {
    "name": "East Spencer",
    "lng": -80.4274766892531,
    "lat": 35.682035820092125
  },
  {
    "name": "East Syracuse",
    "lng": -76.06978686464815,
    "lat": 43.06392780279702
  },
  {
    "name": "East Tawakoni",
    "lng": -95.93964636127585,
    "lat": 32.90096050041041
  },
  {
    "name": "East Tawas",
    "lng": -83.48460150560393,
    "lat": 44.288366195988644
  },
  {
    "name": "East Thermopolis",
    "lng": -108.19788260174441,
    "lat": 43.64409411436111
  },
  {
    "name": "East Troy",
    "lng": -88.3979846927048,
    "lat": 42.784950398905174
  },
  {
    "name": "East Washington",
    "lng": -80.23243539822607,
    "lat": 40.17443568962217
  },
  {
    "name": "East Wenatchee",
    "lng": -120.28077543274972,
    "lat": 47.4174102620875
  },
  {
    "name": "East Williston",
    "lng": -73.63378752096578,
    "lat": 40.7609565306368
  },
  {
    "name": "East Windsor",
    "lng": -72.56716216667508,
    "lat": 41.90495313961873
  },
  {
    "name": "East York",
    "lng": -76.6759079263786,
    "lat": 39.9686780240068
  },
  {
    "name": "Eastborough",
    "lng": -97.25914363216273,
    "lat": 37.68542759745249
  },
  {
    "name": "Eastbrook",
    "lng": -68.2323234028264,
    "lat": 44.69257832634116
  },
  {
    "name": "Eastford",
    "lng": -72.09160436598957,
    "lat": 41.892056236385386
  },
  {
    "name": "Easthampton",
    "lng": -72.67205460149927,
    "lat": 42.26514367135448
  },
  {
    "name": "Eastlake",
    "lng": -86.2935531544602,
    "lat": 44.2448826438627
  },
  {
    "name": "Eastlake",
    "lng": -81.43221327688504,
    "lat": 41.65825858199475
  },
  {
    "name": "Eastland",
    "lng": -98.8174467043678,
    "lat": 32.4031203245868
  },
  {
    "name": "Eastman",
    "lng": -91.02527417473257,
    "lat": 43.16228202906782
  },
  {
    "name": "Eastman",
    "lng": -83.1750636686606,
    "lat": 32.19617176266304
  },
  {
    "name": "Easton",
    "lng": -95.11656425948406,
    "lat": 39.34484020070068
  },
  {
    "name": "Easton",
    "lng": -94.64018873970467,
    "lat": 39.72202850601635
  },
  {
    "name": "Easton",
    "lng": -94.59068871453815,
    "lat": 32.3822646197971
  },
  {
    "name": "Easton",
    "lng": -93.89997126665914,
    "lat": 43.76609798124505
  },
  {
    "name": "Easton",
    "lng": -89.84158427044252,
    "lat": 40.23207865203478
  },
  {
    "name": "Easton",
    "lng": -76.07000672294379,
    "lat": 38.77575114104525
  },
  {
    "name": "Easton",
    "lng": -75.2200919653303,
    "lat": 40.685598897773815
  },
  {
    "name": "Easton",
    "lng": -73.30004711096281,
    "lat": 41.26495212472772
  },
  {
    "name": "Easton",
    "lng": -67.85474202376508,
    "lat": 46.64328484493148
  },
  {
    "name": "Eastover",
    "lng": -80.69524995307948,
    "lat": 33.87829973706714
  },
  {
    "name": "Eastover",
    "lng": -80.68520640966996,
    "lat": 33.8656137178866
  },
  {
    "name": "Eastover",
    "lng": -78.7861616501275,
    "lat": 35.09591027849858
  },
  {
    "name": "Eastpointe",
    "lng": -82.94607575054475,
    "lat": 42.46570815518151
  },
  {
    "name": "Eastport",
    "lng": -67.01084367156069,
    "lat": 44.91456074012749
  },
  {
    "name": "Eastview",
    "lng": -88.55133761667136,
    "lat": 35.0867474183058
  },
  {
    "name": "Eastville",
    "lng": -75.94100475603683,
    "lat": 37.3511394804761
  },
  {
    "name": "Eaton",
    "lng": -104.71302298664419,
    "lat": 40.52564223220573
  },
  {
    "name": "Eaton",
    "lng": -85.35473287314296,
    "lat": 40.34109425991982
  },
  {
    "name": "Eaton",
    "lng": -84.63443040638502,
    "lat": 39.75048639075451
  },
  {
    "name": "Eaton Rapids",
    "lng": -84.65269101290357,
    "lat": 42.50906344148767
  },
  {
    "name": "Eatonton",
    "lng": -83.3886358273443,
    "lat": 33.325767506877284
  },
  {
    "name": "Eatonville",
    "lng": -122.26887309938958,
    "lat": 46.86812093091703
  },
  {
    "name": "Eatonville",
    "lng": -81.39077235683254,
    "lat": 28.61693976134758
  },
  {
    "name": "Eau Claire",
    "lng": -86.30357612803323,
    "lat": 41.9842041953509
  },
  {
    "name": "Ebensburg",
    "lng": -78.72636622665641,
    "lat": 40.48839089129446
  },
  {
    "name": "Echo",
    "lng": -95.41144855974333,
    "lat": 44.62164973115083
  },
  {
    "name": "Eckley",
    "lng": -102.48854055335292,
    "lat": 40.11247373903471
  },
  {
    "name": "Eclectic",
    "lng": -86.06706960935898,
    "lat": 32.6679903994094
  },
  {
    "name": "Eclectic",
    "lng": -86.03440088099298,
    "lat": 32.635200629240366
  },
  {
    "name": "Economy",
    "lng": -85.08672572162058,
    "lat": 39.977538288081654
  },
  {
    "name": "Ecorse",
    "lng": -83.14010129955028,
    "lat": 42.24916413095044
  },
  {
    "name": "Ecru",
    "lng": -89.0249905738392,
    "lat": 34.35636153856899
  },
  {
    "name": "Ed Z'berg Sugar Pine Point State Park",
    "lng": -120.12496460285054,
    "lat": 39.02004655980367
  },
  {
    "name": "Ed Z'berg Sugar Pine Point State Park",
    "lng": -120.12333440680072,
    "lat": 39.033910258543266
  },
  {
    "name": "Ed Z'berg Sugar Pine Point State Park",
    "lng": -120.1224477837379,
    "lat": 39.027554877969806
  },
  {
    "name": "Ed Z'berg Sugar Pine Point State Park",
    "lng": -120.12153696461976,
    "lat": 39.027258405180405
  },
  {
    "name": "Edcouch",
    "lng": -97.96308922199205,
    "lat": 26.293722824107054
  },
  {
    "name": "Eddington",
    "lng": -68.62170383369715,
    "lat": 44.80906670617642
  },
  {
    "name": "Eddyville",
    "lng": -99.62399152015298,
    "lat": 41.01220981993528
  },
  {
    "name": "Eddyville",
    "lng": -92.63051769021985,
    "lat": 41.159424643201
  },
  {
    "name": "Eddyville",
    "lng": -88.58460246748854,
    "lat": 37.50029465020696
  },
  {
    "name": "Eddyville",
    "lng": -88.07823283893867,
    "lat": 37.07527613609731
  },
  {
    "name": "Eden",
    "lng": -114.20908677483601,
    "lat": 42.60530882125195
  },
  {
    "name": "Eden",
    "lng": -99.84396529495675,
    "lat": 31.216247285586235
  },
  {
    "name": "Eden",
    "lng": -97.41971173383254,
    "lat": 45.61676613449671
  },
  {
    "name": "Eden",
    "lng": -90.32395647504363,
    "lat": 32.98371057064235
  },
  {
    "name": "Eden",
    "lng": -79.74597357533514,
    "lat": 36.47110632936517
  },
  {
    "name": "Eden",
    "lng": -79.74150885382593,
    "lat": 36.50334484630476
  },
  {
    "name": "Eden",
    "lng": -79.70556461753047,
    "lat": 36.49780694440339
  },
  {
    "name": "Eden Prairie",
    "lng": -93.45951557117598,
    "lat": 44.848811246813746
  },
  {
    "name": "Eden Valley",
    "lng": -94.54517307435114,
    "lat": 45.324528463688296
  },
  {
    "name": "Edenton",
    "lng": -76.67954817454678,
    "lat": 36.05123105762237
  },
  {
    "name": "Edenton",
    "lng": -76.63026880671794,
    "lat": 36.08796879027935
  },
  {
    "name": "Edenton",
    "lng": -76.63006659426067,
    "lat": 36.09105495491134
  },
  {
    "name": "Edenton",
    "lng": -76.62890556843524,
    "lat": 36.085938194494524
  },
  {
    "name": "Edenton",
    "lng": -76.6245552816166,
    "lat": 36.083911748320396
  },
  {
    "name": "Edenton",
    "lng": -76.61901213376399,
    "lat": 36.0775821275317
  },
  {
    "name": "Edenton",
    "lng": -76.61824168001705,
    "lat": 36.08058119941811
  },
  {
    "name": "Edenton",
    "lng": -76.61360358830807,
    "lat": 36.07755418040521
  },
  {
    "name": "Edenton",
    "lng": -76.60830577048992,
    "lat": 36.06670806389346
  },
  {
    "name": "Edenton",
    "lng": -76.5685881098861,
    "lat": 36.02753389230578
  },
  {
    "name": "Edgar",
    "lng": -97.97097918754719,
    "lat": 40.3682313355014
  },
  {
    "name": "Edgar",
    "lng": -89.96272809065765,
    "lat": 44.922763706451
  },
  {
    "name": "Edgar Springs",
    "lng": -91.86806901649419,
    "lat": 37.70257915464632
  },
  {
    "name": "Edge Hill",
    "lng": -82.62498897063138,
    "lat": 33.15342052323598
  },
  {
    "name": "Edgecliff Village",
    "lng": -97.34061941299218,
    "lat": 32.65596525129597
  },
  {
    "name": "Edgecomb",
    "lng": -69.62313582872565,
    "lat": 43.968669900519494
  },
  {
    "name": "Edgefield",
    "lng": -93.33596175858064,
    "lat": 32.05008118145236
  },
  {
    "name": "Edgefield",
    "lng": -81.92918947881562,
    "lat": 33.786857751396056
  },
  {
    "name": "Edgeley",
    "lng": -98.71276892537183,
    "lat": 46.362767019175685
  },
  {
    "name": "Edgeley",
    "lng": -98.70471252284719,
    "lat": 46.35357627641581
  },
  {
    "name": "Edgemont",
    "lng": -103.82749826721268,
    "lat": 43.298297562662626
  },
  {
    "name": "Edgerton",
    "lng": -106.24766327384539,
    "lat": 43.414154587662246
  },
  {
    "name": "Edgerton",
    "lng": -96.13043669678117,
    "lat": 43.875235533971576
  },
  {
    "name": "Edgerton",
    "lng": -95.0554131123733,
    "lat": 38.76030534011389
  },
  {
    "name": "Edgerton",
    "lng": -95.01301132672525,
    "lat": 38.76197953077342
  },
  {
    "name": "Edgerton",
    "lng": -95.00036337808106,
    "lat": 38.750542982110446
  },
  {
    "name": "Edgerton",
    "lng": -94.62900691529687,
    "lat": 39.502184923802766
  },
  {
    "name": "Edgerton",
    "lng": -94.61504851526502,
    "lat": 39.50366528910299
  },
  {
    "name": "Edgerton",
    "lng": -89.06984516252528,
    "lat": 42.8385893252655
  },
  {
    "name": "Edgerton",
    "lng": -84.74965899728127,
    "lat": 41.44972672704933
  },
  {
    "name": "Edgewater",
    "lng": -105.0626292681397,
    "lat": 39.75081949971523
  },
  {
    "name": "Edgewood",
    "lng": -122.28311146642505,
    "lat": 47.23089168614889
  },
  {
    "name": "Edgewood",
    "lng": -106.18611058783425,
    "lat": 35.06845284033156
  },
  {
    "name": "Edgewood",
    "lng": -106.17648385311341,
    "lat": 35.0965065024938
  },
  {
    "name": "Edgewood",
    "lng": -106.17404250969244,
    "lat": 35.07985194461432
  },
  {
    "name": "Edgewood",
    "lng": -106.17356125961541,
    "lat": 35.08373735004967
  },
  {
    "name": "Edgewood",
    "lng": -106.1735140262483,
    "lat": 35.076296514465824
  },
  {
    "name": "Edgewood",
    "lng": -106.17113612136755,
    "lat": 35.05693772719681
  },
  {
    "name": "Edgewood",
    "lng": -106.16975105504716,
    "lat": 35.084394915529025
  },
  {
    "name": "Edgewood",
    "lng": -95.8832522449937,
    "lat": 32.694568131899786
  },
  {
    "name": "Edgewood",
    "lng": -91.40215620589873,
    "lat": 42.64415734114285
  },
  {
    "name": "Edgewood",
    "lng": -88.66412982674737,
    "lat": 38.92216232780485
  },
  {
    "name": "Edgewood",
    "lng": -85.73755678967568,
    "lat": 40.10326886652805
  },
  {
    "name": "Edgewood",
    "lng": -84.56012380428898,
    "lat": 39.009025035981026
  },
  {
    "name": "Edgewood",
    "lng": -81.37778648091707,
    "lat": 28.49927104158001
  },
  {
    "name": "Edgewood",
    "lng": -81.37714368841445,
    "lat": 28.487873183159984
  },
  {
    "name": "Edgewood",
    "lng": -80.74557015582731,
    "lat": 41.87851958237311
  },
  {
    "name": "Edina",
    "lng": -93.36018232561048,
    "lat": 44.89138546905211
  },
  {
    "name": "Edina",
    "lng": -92.1732268765893,
    "lat": 40.16797531143689
  },
  {
    "name": "Edinburg",
    "lng": -98.16084263958751,
    "lat": 26.318916329937796
  },
  {
    "name": "Edinburg",
    "lng": -98.13607845393555,
    "lat": 26.259879870072815
  },
  {
    "name": "Edinburg",
    "lng": -97.86267879854519,
    "lat": 48.49544592671854
  },
  {
    "name": "Edinburg",
    "lng": -89.39012095739041,
    "lat": 39.657890337570436
  },
  {
    "name": "Edinburg",
    "lng": -78.56345885956144,
    "lat": 38.82350705256774
  },
  {
    "name": "Edinburg",
    "lng": -68.68688974170122,
    "lat": 45.16875978594134
  },
  {
    "name": "Edinburgh",
    "lng": -85.96251027853411,
    "lat": 39.350894133881155
  },
  {
    "name": "Edison",
    "lng": -99.77630556627287,
    "lat": 40.27765849027081
  },
  {
    "name": "Edison",
    "lng": -84.73740356702227,
    "lat": 31.560841887095982
  },
  {
    "name": "Edison",
    "lng": -82.86319316188518,
    "lat": 40.558258745388244
  },
  {
    "name": "Edisto Beach",
    "lng": -80.3079779545168,
    "lat": 32.50866583136225
  },
  {
    "name": "Edmond",
    "lng": -99.81991924262482,
    "lat": 39.62712491728405
  },
  {
    "name": "Edmond",
    "lng": -97.41608234212497,
    "lat": 35.66881891784788
  },
  {
    "name": "Edmonds",
    "lng": -122.37048515340436,
    "lat": 47.82573439437788
  },
  {
    "name": "Edmondson",
    "lng": -90.30538817061613,
    "lat": 35.10504252717502
  },
  {
    "name": "Edmonson",
    "lng": -101.89666097190995,
    "lat": 34.27910639691661
  },
  {
    "name": "Edmonton",
    "lng": -85.61888308068757,
    "lat": 36.98449762895029
  },
  {
    "name": "Edmore",
    "lng": -98.45318496641669,
    "lat": 48.41221202541407
  },
  {
    "name": "Edmore",
    "lng": -85.03674113534154,
    "lat": 43.40730781146276
  },
  {
    "name": "Edmundson",
    "lng": -90.36574589201064,
    "lat": 38.73511106381572
  },
  {
    "name": "Edna",
    "lng": -96.64863651172578,
    "lat": 28.975608932667537
  },
  {
    "name": "Edna",
    "lng": -96.62872986188637,
    "lat": 28.980265691408636
  },
  {
    "name": "Edna",
    "lng": -95.35904408986515,
    "lat": 37.058678007841536
  },
  {
    "name": "Edna",
    "lng": -95.358526445373,
    "lat": 37.06919201994696
  },
  {
    "name": "Edom",
    "lng": -95.6098681792769,
    "lat": 32.374280987491616
  },
  {
    "name": "Edon",
    "lng": -84.76955044209531,
    "lat": 41.55614336082679
  },
  {
    "name": "Edwards",
    "lng": -90.60555976349612,
    "lat": 32.332612319121935
  },
  {
    "name": "Edwardsburg",
    "lng": -86.08348081762163,
    "lat": 41.79655809665501
  },
  {
    "name": "Edwardsport",
    "lng": -87.25203580848247,
    "lat": 38.8120064141746
  },
  {
    "name": "Edwardsville",
    "lng": -94.81663707400816,
    "lat": 39.07726662647005
  },
  {
    "name": "Edwardsville",
    "lng": -89.98776938670605,
    "lat": 38.79213879683258
  },
  {
    "name": "Edwardsville",
    "lng": -89.97907304740114,
    "lat": 38.81501377091204
  },
  {
    "name": "Edwardsville",
    "lng": -89.94148474885031,
    "lat": 38.819293369840985
  },
  {
    "name": "Edwardsville",
    "lng": -89.93108581463186,
    "lat": 38.81309202285556
  },
  {
    "name": "Edwardsville",
    "lng": -85.50912007883241,
    "lat": 33.706056797133996
  },
  {
    "name": "Eek",
    "lng": -162.03106855787217,
    "lat": 60.21579096140327
  },
  {
    "name": "Effie",
    "lng": -93.63795787584849,
    "lat": 47.84127582861073
  },
  {
    "name": "Effingham",
    "lng": -95.39751445812044,
    "lat": 39.52250856071792
  },
  {
    "name": "Effingham",
    "lng": -88.55100198071324,
    "lat": 39.12068604525922
  },
  {
    "name": "Effort",
    "lng": -75.43893910759357,
    "lat": 40.94360719598765
  },
  {
    "name": "Egan",
    "lng": -96.64853811289673,
    "lat": 44.00032045023058
  },
  {
    "name": "Egegik",
    "lng": -157.5366072101236,
    "lat": 58.23403890971247
  },
  {
    "name": "Egeland",
    "lng": -99.09793068408784,
    "lat": 48.62757192842064
  },
  {
    "name": "Egg Harbor",
    "lng": -87.2837733476652,
    "lat": 45.05196283890429
  },
  {
    "name": "Egg Harbor City",
    "lng": -74.5967728813827,
    "lat": 39.56394617312349
  },
  {
    "name": "Egg Harbor Township",
    "lng": -74.62360891410412,
    "lat": 39.38679177591028
  },
  {
    "name": "Egg Harbor Township",
    "lng": -74.54138387640933,
    "lat": 39.33271521486644
  },
  {
    "name": "Egg Harbor Township",
    "lng": -74.50144129520251,
    "lat": 39.3785439179567
  },
  {
    "name": "Egremont",
    "lng": -73.43938466191436,
    "lat": 42.17027026273068
  },
  {
    "name": "Egypt",
    "lng": -90.94584146510128,
    "lat": 35.86778020033713
  },
  {
    "name": "Egypt Lake-Leto",
    "lng": -82.50618300942689,
    "lat": 28.017683409099742
  },
  {
    "name": "Ehrhardt",
    "lng": -81.01421799173606,
    "lat": 33.09855516648383
  },
  {
    "name": "Eitzen",
    "lng": -91.46363408933267,
    "lat": 43.508109885197676
  },
  {
    "name": "Ekalaka",
    "lng": -104.55065704032731,
    "lat": 45.88937589386374
  },
  {
    "name": "Ekron",
    "lng": -86.17742016542324,
    "lat": 37.93068557995717
  },
  {
    "name": "Ekwok",
    "lng": -157.48412476496452,
    "lat": 59.34702924815406
  },
  {
    "name": "El Cajon",
    "lng": -116.95898628274264,
    "lat": 32.80368948561524
  },
  {
    "name": "El Cajon",
    "lng": -116.90753196300457,
    "lat": 32.79617199348583
  },
  {
    "name": "El Campo",
    "lng": -96.27520212942315,
    "lat": 29.198823184068488
  },
  {
    "name": "El Cenizo",
    "lng": -99.50290198252151,
    "lat": 27.33175954480124
  },
  {
    "name": "El Centro",
    "lng": -115.55759378105255,
    "lat": 32.78696601069677
  },
  {
    "name": "El Dara",
    "lng": -90.99118296011883,
    "lat": 39.62269597891549
  },
  {
    "name": "El Dorado",
    "lng": -96.90255660642507,
    "lat": 37.81348603836571
  },
  {
    "name": "El Dorado",
    "lng": -96.88508927089144,
    "lat": 37.83732162291166
  },
  {
    "name": "El Dorado",
    "lng": -96.86124346908439,
    "lat": 37.82295846235957
  },
  {
    "name": "El Dorado",
    "lng": -96.8336138294914,
    "lat": 37.814925048471146
  },
  {
    "name": "El Dorado",
    "lng": -96.82525937611133,
    "lat": 37.76907676338557
  },
  {
    "name": "El Dorado",
    "lng": -96.81709585665342,
    "lat": 37.7755854941136
  },
  {
    "name": "El Dorado",
    "lng": -92.66408714643887,
    "lat": 33.21833747506591
  },
  {
    "name": "El Dorado Hills",
    "lng": -121.06066531069071,
    "lat": 38.687414756968465
  },
  {
    "name": "El Dorado Springs",
    "lng": -94.01957327323808,
    "lat": 37.86937019533362
  },
  {
    "name": "El Lago",
    "lng": -95.04396694054418,
    "lat": 29.572586591734847
  },
  {
    "name": "El Mirage",
    "lng": -112.32715477454421,
    "lat": 33.590528470028985
  },
  {
    "name": "El Paso",
    "lng": -106.43014558336827,
    "lat": 31.84779132509021
  },
  {
    "name": "El Paso",
    "lng": -89.02540580177003,
    "lat": 40.755489718978254
  },
  {
    "name": "El Paso",
    "lng": -89.01718209078963,
    "lat": 40.738620912010184
  },
  {
    "name": "El Portal",
    "lng": -80.19555024405547,
    "lat": 25.855553963214206
  },
  {
    "name": "El Reno",
    "lng": -97.9659858191536,
    "lat": 35.54297124857455
  },
  {
    "name": "Elaine",
    "lng": -90.85332045492194,
    "lat": 34.30857114917023
  },
  {
    "name": "Eland",
    "lng": -89.20893336222656,
    "lat": 44.867015111132176
  },
  {
    "name": "Elba",
    "lng": -98.56922871323468,
    "lat": 41.28530035158054
  },
  {
    "name": "Elba",
    "lng": -92.01827581024061,
    "lat": 44.08639330562064
  },
  {
    "name": "Elba",
    "lng": -86.07592862051801,
    "lat": 31.417253039316357
  },
  {
    "name": "Elba",
    "lng": -78.18890841158982,
    "lat": 43.076812759041914
  },
  {
    "name": "Elberfeld",
    "lng": -87.44815506782437,
    "lat": 38.16165057992642
  },
  {
    "name": "Elberon",
    "lng": -92.31601698234915,
    "lat": 42.00539062330917
  },
  {
    "name": "Elberta",
    "lng": -87.6185243582897,
    "lat": 30.412808030144973
  },
  {
    "name": "Elberta",
    "lng": -87.60196722317963,
    "lat": 30.4639552486075
  },
  {
    "name": "Elberta",
    "lng": -87.59459087406644,
    "lat": 30.38044930431664
  },
  {
    "name": "Elberta",
    "lng": -87.5945907178626,
    "lat": 30.380448792654132
  },
  {
    "name": "Elberta",
    "lng": -87.59214848828215,
    "lat": 30.413983313643545
  },
  {
    "name": "Elberta",
    "lng": -87.59214848828212,
    "lat": 30.413983313643545
  },
  {
    "name": "Elberta",
    "lng": -87.57053245666177,
    "lat": 30.348597697198457
  },
  {
    "name": "Elberta",
    "lng": -87.5646358335374,
    "lat": 30.402468837123887
  },
  {
    "name": "Elberta",
    "lng": -87.53757300818623,
    "lat": 30.40015266382428
  },
  {
    "name": "Elberta",
    "lng": -86.23185178143143,
    "lat": 44.619850397002196
  },
  {
    "name": "Elberton",
    "lng": -82.90240947929004,
    "lat": 34.09789179180308
  },
  {
    "name": "Elberton",
    "lng": -82.86786279169193,
    "lat": 34.10721972880365
  },
  {
    "name": "Elbing",
    "lng": -97.12709581187711,
    "lat": 38.05416721198831
  },
  {
    "name": "Elbow Lake",
    "lng": -95.99680786047698,
    "lat": 45.9970881117333
  },
  {
    "name": "Elbow Lake",
    "lng": -95.97816850988046,
    "lat": 45.989801422033345
  },
  {
    "name": "Elbridge",
    "lng": -76.45063790519522,
    "lat": 43.02263880769224
  },
  {
    "name": "Elbridge",
    "lng": -76.44241514739059,
    "lat": 43.03793949154531
  },
  {
    "name": "Elburn",
    "lng": -88.46050905339052,
    "lat": 41.88511917288746
  },
  {
    "name": "Eldon",
    "lng": -92.57731874142247,
    "lat": 38.35019784928829
  },
  {
    "name": "Eldon",
    "lng": -92.23031368956873,
    "lat": 40.92786801623767
  },
  {
    "name": "Eldon",
    "lng": -92.21751551812605,
    "lat": 40.917595773190875
  },
  {
    "name": "Eldora",
    "lng": -93.10135178520099,
    "lat": 42.360483448265235
  },
  {
    "name": "Eldorado",
    "lng": -100.59792317947826,
    "lat": 30.861756949745445
  },
  {
    "name": "Eldorado",
    "lng": -99.64985516687929,
    "lat": 34.472937645763125
  },
  {
    "name": "Eldorado",
    "lng": -99.64349903006224,
    "lat": 34.47891779650298
  },
  {
    "name": "Eldorado",
    "lng": -88.44152879507804,
    "lat": 37.81191048301908
  },
  {
    "name": "Eldorado",
    "lng": -84.67521092343453,
    "lat": 39.904363434532854
  },
  {
    "name": "Eldorado",
    "lng": -75.78936904216197,
    "lat": 38.584111886077665
  },
  {
    "name": "Eldred",
    "lng": -90.55397510457698,
    "lat": 39.286151383145324
  },
  {
    "name": "Eldridge",
    "lng": -90.58098545612377,
    "lat": 41.63904592922992
  },
  {
    "name": "Eldridge",
    "lng": -87.62010812902913,
    "lat": 33.92201843632921
  },
  {
    "name": "Eleanor",
    "lng": -81.92627318575687,
    "lat": 38.53720294225167
  },
  {
    "name": "Electra",
    "lng": -98.92080090281041,
    "lat": 34.032103716554616
  },
  {
    "name": "Electric City",
    "lng": -119.0374704216773,
    "lat": 47.93024597888862
  },
  {
    "name": "Electric City",
    "lng": -119.02663187023455,
    "lat": 47.935035446240434
  },
  {
    "name": "Elephant Butte",
    "lng": -107.2267586812318,
    "lat": 33.18123103352852
  },
  {
    "name": "Eleva",
    "lng": -91.47011582564026,
    "lat": 44.57628772975033
  },
  {
    "name": "Elgin",
    "lng": -101.84274381431742,
    "lat": 46.40250518088974
  },
  {
    "name": "Elgin",
    "lng": -98.3276343613199,
    "lat": 34.77625371332494
  },
  {
    "name": "Elgin",
    "lng": -98.31497161707942,
    "lat": 34.78398658973659
  },
  {
    "name": "Elgin",
    "lng": -98.3083445102319,
    "lat": 34.80787850991406
  },
  {
    "name": "Elgin",
    "lng": -98.30499698736526,
    "lat": 34.78311144355921
  },
  {
    "name": "Elgin",
    "lng": -98.29147663561739,
    "lat": 34.78213723323683
  },
  {
    "name": "Elgin",
    "lng": -98.28655141173124,
    "lat": 34.792005637950396
  },
  {
    "name": "Elgin",
    "lng": -98.08263864854332,
    "lat": 41.983233377363774
  },
  {
    "name": "Elgin",
    "lng": -97.3878859387417,
    "lat": 30.351873727391293
  },
  {
    "name": "Elgin",
    "lng": -97.37743130729713,
    "lat": 30.315496269626184
  },
  {
    "name": "Elgin",
    "lng": -97.3741377220643,
    "lat": 30.32115436012231
  },
  {
    "name": "Elgin",
    "lng": -97.37258140350593,
    "lat": 30.318240456780753
  },
  {
    "name": "Elgin",
    "lng": -97.37095708319076,
    "lat": 30.322704410759567
  },
  {
    "name": "Elgin",
    "lng": -97.36925377362019,
    "lat": 30.322176334324368
  },
  {
    "name": "Elgin",
    "lng": -96.27883786925004,
    "lat": 37.002214654819106
  },
  {
    "name": "Elgin",
    "lng": -92.24955621663014,
    "lat": 44.124694890127735
  },
  {
    "name": "Elgin",
    "lng": -92.24936203357784,
    "lat": 44.129523571391864
  },
  {
    "name": "Elgin",
    "lng": -92.24457016577104,
    "lat": 44.11835188252401
  },
  {
    "name": "Elgin",
    "lng": -92.23722765195872,
    "lat": 44.108990886358
  },
  {
    "name": "Elgin",
    "lng": -91.6353843260226,
    "lat": 42.955325198262486
  },
  {
    "name": "Elgin",
    "lng": -88.4447877295007,
    "lat": 42.06983769504904
  },
  {
    "name": "Elgin",
    "lng": -88.4210099727099,
    "lat": 41.97590302110301
  },
  {
    "name": "Elgin",
    "lng": -88.39574591618708,
    "lat": 41.99415663261686
  },
  {
    "name": "Elgin",
    "lng": -88.34157957853392,
    "lat": 42.09303215116555
  },
  {
    "name": "Elgin",
    "lng": -88.31670469825484,
    "lat": 42.0432380697386
  },
  {
    "name": "Elgin",
    "lng": -88.31614880549043,
    "lat": 42.04021041931997
  },
  {
    "name": "Elgin",
    "lng": -88.23361466998436,
    "lat": 42.04059229755478
  },
  {
    "name": "Elgin",
    "lng": -84.47601493498497,
    "lat": 40.742585792559765
  },
  {
    "name": "Elgin",
    "lng": -80.80645065962536,
    "lat": 34.164905377478824
  },
  {
    "name": "Elgin",
    "lng": -80.79331556253074,
    "lat": 34.169675096509096
  },
  {
    "name": "Elida",
    "lng": -103.65532489695335,
    "lat": 33.94426489898465
  },
  {
    "name": "Elida",
    "lng": -84.20037834633783,
    "lat": 40.786918802094384
  },
  {
    "name": "Elim",
    "lng": -162.27337401111782,
    "lat": 64.61838738653108
  },
  {
    "name": "Eliot",
    "lng": -70.78601418548857,
    "lat": 43.14885339297564
  },
  {
    "name": "Elizabeth",
    "lng": -104.60688256308696,
    "lat": 39.34989261411062
  },
  {
    "name": "Elizabeth",
    "lng": -104.60688256308693,
    "lat": 39.3498926141106
  },
  {
    "name": "Elizabeth",
    "lng": -104.60252707934961,
    "lat": 39.36206371008453
  },
  {
    "name": "Elizabeth",
    "lng": -96.13085793340709,
    "lat": 46.379806761418294
  },
  {
    "name": "Elizabeth",
    "lng": -92.79863892389794,
    "lat": 30.86695514354827
  },
  {
    "name": "Elizabeth",
    "lng": -90.22092513402886,
    "lat": 42.31639025171668
  },
  {
    "name": "Elizabeth",
    "lng": -85.97295286626333,
    "lat": 38.123893358555804
  },
  {
    "name": "Elizabeth",
    "lng": -81.39759275977482,
    "lat": 39.06174349016895
  },
  {
    "name": "Elizabeth",
    "lng": -74.19168928471737,
    "lat": 40.665316796134675
  },
  {
    "name": "Elizabeth City",
    "lng": -76.28542692266511,
    "lat": 36.29723583862172
  },
  {
    "name": "Elizabeth City",
    "lng": -76.25502000317255,
    "lat": 36.30975683739818
  },
  {
    "name": "Elizabeth City",
    "lng": -76.25404405173846,
    "lat": 36.31025345639111
  },
  {
    "name": "Elizabeth City",
    "lng": -76.23077272649275,
    "lat": 36.30886355428271
  },
  {
    "name": "Elizabeth City",
    "lng": -76.22818601300354,
    "lat": 36.31159124653784
  },
  {
    "name": "Elizabeth City",
    "lng": -76.22524855398264,
    "lat": 36.293462902762045
  },
  {
    "name": "Elizabeth City",
    "lng": -76.22388841372559,
    "lat": 36.272047971267085
  },
  {
    "name": "Elizabeth City",
    "lng": -76.22370323058219,
    "lat": 36.26587187530299
  },
  {
    "name": "Elizabeth City",
    "lng": -76.22295666422066,
    "lat": 36.27005429309422
  },
  {
    "name": "Elizabeth City",
    "lng": -76.21844808503197,
    "lat": 36.26290389409446
  },
  {
    "name": "Elizabeth City",
    "lng": -76.20535687870705,
    "lat": 36.30055666588884
  },
  {
    "name": "Elizabethton",
    "lng": -82.23791336333146,
    "lat": 36.336464329051836
  },
  {
    "name": "Elizabethton",
    "lng": -82.18512537609148,
    "lat": 36.35973133504685
  },
  {
    "name": "Elizabethtown",
    "lng": -88.30514251494748,
    "lat": 37.44988858900357
  },
  {
    "name": "Elizabethtown",
    "lng": -85.87513769650403,
    "lat": 37.7026086048329
  },
  {
    "name": "Elizabethtown",
    "lng": -85.81244101509242,
    "lat": 39.135121104635076
  },
  {
    "name": "Elizabethtown",
    "lng": -78.64982434772224,
    "lat": 34.63252500934891
  },
  {
    "name": "Elizabethtown",
    "lng": -78.62773391350404,
    "lat": 34.61799178953292
  },
  {
    "name": "Elizabethtown",
    "lng": -78.62367936393022,
    "lat": 34.60049933039893
  },
  {
    "name": "Elizabethtown",
    "lng": -78.60988912943043,
    "lat": 34.62392505967978
  },
  {
    "name": "Elizabethtown",
    "lng": -76.5988292666808,
    "lat": 40.1532420617019
  },
  {
    "name": "Elk City",
    "lng": -99.47499049952857,
    "lat": 35.38950873539681
  },
  {
    "name": "Elk City",
    "lng": -99.43257076613541,
    "lat": 35.38451766792459
  },
  {
    "name": "Elk City",
    "lng": -95.909983564778,
    "lat": 37.28974717819142
  },
  {
    "name": "Elk Creek",
    "lng": -96.12694154731469,
    "lat": 40.288256029545224
  },
  {
    "name": "Elk Falls",
    "lng": -96.192314477471,
    "lat": 37.37386869361116
  },
  {
    "name": "Elk Garden",
    "lng": -79.1555557910645,
    "lat": 39.38622817210084
  },
  {
    "name": "Elk Grove Village",
    "lng": -87.99297486552733,
    "lat": 42.00610445712032
  },
  {
    "name": "Elk Horn",
    "lng": -95.06131506233795,
    "lat": 41.593122526763906
  },
  {
    "name": "Elk Mound",
    "lng": -91.68637632470542,
    "lat": 44.874446407053355
  },
  {
    "name": "Elk Mountain",
    "lng": -106.41350071780079,
    "lat": 41.68830910166571
  },
  {
    "name": "Elk Park",
    "lng": -81.98081315785619,
    "lat": 36.158247153168034
  },
  {
    "name": "Elk Point",
    "lng": -96.68036484481934,
    "lat": 42.68185622189809
  },
  {
    "name": "Elk Rapids",
    "lng": -85.40568117345882,
    "lat": 44.89502183628721
  },
  {
    "name": "Elk Ridge",
    "lng": -111.6774707061616,
    "lat": 40.00991290002862
  },
  {
    "name": "Elk River",
    "lng": -116.18110504570265,
    "lat": 46.78300546441722
  },
  {
    "name": "Elk River",
    "lng": -93.56695562264865,
    "lat": 45.331365485041054
  },
  {
    "name": "Elk Run Heights",
    "lng": -92.25023766352832,
    "lat": 42.46619413964949
  },
  {
    "name": "Elkader",
    "lng": -91.40290507472757,
    "lat": 42.85740788092713
  },
  {
    "name": "Elkhart",
    "lng": -101.8939053503436,
    "lat": 37.00395050705849
  },
  {
    "name": "Elkhart",
    "lng": -95.57898121544119,
    "lat": 31.627967013739582
  },
  {
    "name": "Elkhart",
    "lng": -93.524574085039,
    "lat": 41.79217859475038
  },
  {
    "name": "Elkhart",
    "lng": -93.51788273773226,
    "lat": 41.81021787945083
  },
  {
    "name": "Elkhart",
    "lng": -89.48009775934968,
    "lat": 40.01752176277196
  },
  {
    "name": "Elkhart",
    "lng": -85.9614929854402,
    "lat": 41.691204240636104
  },
  {
    "name": "Elkhart Lake",
    "lng": -88.01473969760326,
    "lat": 43.8322690264884
  },
  {
    "name": "Elkhorn",
    "lng": -88.53838810453009,
    "lat": 42.6707721884472
  },
  {
    "name": "Elkhorn City",
    "lng": -82.34538288130409,
    "lat": 37.30096248529727
  },
  {
    "name": "Elkin",
    "lng": -80.88138796344421,
    "lat": 36.2536243008209
  },
  {
    "name": "Elkin",
    "lng": -80.87428928592253,
    "lat": 36.248830845465804
  },
  {
    "name": "Elkin",
    "lng": -80.87394745473408,
    "lat": 36.24972494529863
  },
  {
    "name": "Elkin",
    "lng": -80.87354806613749,
    "lat": 36.25058534730598
  },
  {
    "name": "Elkin",
    "lng": -80.86862897242686,
    "lat": 36.25975329096797
  },
  {
    "name": "Elkin",
    "lng": -80.86719868449454,
    "lat": 36.25932052456006
  },
  {
    "name": "Elkin",
    "lng": -80.84830336345222,
    "lat": 36.29368425993856
  },
  {
    "name": "Elkin",
    "lng": -80.847072949922,
    "lat": 36.26046088218163
  },
  {
    "name": "Elkin",
    "lng": -80.82409355147617,
    "lat": 36.28061156821478
  },
  {
    "name": "Elkin",
    "lng": -80.82169690217641,
    "lat": 36.27820604965767
  },
  {
    "name": "Elkin",
    "lng": -80.78766211989601,
    "lat": 36.27882652367773
  },
  {
    "name": "Elkins",
    "lng": -94.02577577026778,
    "lat": 36.017871139263264
  },
  {
    "name": "Elkins",
    "lng": -79.85306303188196,
    "lat": 38.924386064202324
  },
  {
    "name": "Elkins",
    "lng": -79.84090920660165,
    "lat": 38.88168541257794
  },
  {
    "name": "Elkmont",
    "lng": -86.97631354066208,
    "lat": 34.928916332048836
  },
  {
    "name": "Elko",
    "lng": -115.76795514681993,
    "lat": 40.83768093143237
  },
  {
    "name": "Elko",
    "lng": -115.71159282998444,
    "lat": 40.88859698778405
  },
  {
    "name": "Elko",
    "lng": -81.37993949487925,
    "lat": 33.380961496579495
  },
  {
    "name": "Elko New Market",
    "lng": -93.37301082163614,
    "lat": 44.58108908303722
  },
  {
    "name": "Elko New Market",
    "lng": -93.3683333244308,
    "lat": 44.56067838351342
  },
  {
    "name": "Elko New Market",
    "lng": -93.36285507116177,
    "lat": 44.561147789406654
  },
  {
    "name": "Elko New Market",
    "lng": -93.33439498961859,
    "lat": 44.56638164961072
  },
  {
    "name": "Elko New Market",
    "lng": -93.33008071992971,
    "lat": 44.583836871940946
  },
  {
    "name": "Elkport",
    "lng": -91.27483915807777,
    "lat": 42.741999677347856
  },
  {
    "name": "Elkton",
    "lng": -96.47915173064968,
    "lat": 44.235326188565615
  },
  {
    "name": "Elkton",
    "lng": -92.70648662029288,
    "lat": 43.66026992664258
  },
  {
    "name": "Elkton",
    "lng": -87.16085591898171,
    "lat": 36.812530707093394
  },
  {
    "name": "Elkton",
    "lng": -86.89753319604036,
    "lat": 35.06181380586631
  },
  {
    "name": "Elkton",
    "lng": -83.18094964859537,
    "lat": 43.818237828817054
  },
  {
    "name": "Elkton",
    "lng": -78.6161764904802,
    "lat": 38.410596665242565
  },
  {
    "name": "Elkville",
    "lng": -89.23671401039036,
    "lat": 37.91000753246989
  },
  {
    "name": "Ellaville",
    "lng": -84.30889662984973,
    "lat": 32.23801902289452
  },
  {
    "name": "Ellenboro",
    "lng": -81.76019305814593,
    "lat": 35.32903992810104
  },
  {
    "name": "Ellenboro",
    "lng": -81.05501470044369,
    "lat": 39.26777168708133
  },
  {
    "name": "Ellendale",
    "lng": -98.52491487696716,
    "lat": 46.00455370335841
  },
  {
    "name": "Ellendale",
    "lng": -93.29932982762286,
    "lat": 43.87271938225052
  },
  {
    "name": "Ellensburg",
    "lng": -120.54745834142977,
    "lat": 46.99919336389946
  },
  {
    "name": "Ellenton",
    "lng": -83.58762204740708,
    "lat": 31.175943723174996
  },
  {
    "name": "Ellenville",
    "lng": -74.36218345661922,
    "lat": 41.700676472803465
  },
  {
    "name": "Ellerbe",
    "lng": -79.77271850284012,
    "lat": 35.071928435486356
  },
  {
    "name": "Ellerbe",
    "lng": -79.7636374348659,
    "lat": 35.0942621923293
  },
  {
    "name": "Ellerbe",
    "lng": -79.7595868388866,
    "lat": 35.0703586381055
  },
  {
    "name": "Ellerbe",
    "lng": -79.75748086523609,
    "lat": 35.062878180190324
  },
  {
    "name": "Ellettsville",
    "lng": -86.62382140923455,
    "lat": 39.23224782961897
  },
  {
    "name": "Ellicottville",
    "lng": -78.67219513947423,
    "lat": 42.27463823562809
  },
  {
    "name": "Ellijay",
    "lng": -84.48747838569916,
    "lat": 34.69220199782328
  },
  {
    "name": "Ellington",
    "lng": -90.97204126158546,
    "lat": 37.236488066181245
  },
  {
    "name": "Ellington",
    "lng": -72.44843024112639,
    "lat": 41.91517353782604
  },
  {
    "name": "Ellinwood",
    "lng": -98.61016382607755,
    "lat": 38.360503940581914
  },
  {
    "name": "Ellinwood",
    "lng": -98.58163320051806,
    "lat": 38.35556388722572
  },
  {
    "name": "Ellinwood",
    "lng": -98.5695358809172,
    "lat": 38.3527307548452
  },
  {
    "name": "Ellinwood",
    "lng": -98.5652614823371,
    "lat": 38.352708280452745
  },
  {
    "name": "Elliott",
    "lng": -97.81485638080882,
    "lat": 46.402269128651916
  },
  {
    "name": "Elliott",
    "lng": -95.16293553521763,
    "lat": 41.14937454397797
  },
  {
    "name": "Elliott",
    "lng": -88.27694195348091,
    "lat": 40.466198203161305
  },
  {
    "name": "Ellis",
    "lng": -99.55675237192492,
    "lat": 38.93476834281841
  },
  {
    "name": "Ellis Grove",
    "lng": -89.91003454369526,
    "lat": 38.00934708662926
  },
  {
    "name": "Ellisburg",
    "lng": -76.13458847433522,
    "lat": 43.734958724897155
  },
  {
    "name": "Ellisville",
    "lng": -90.58801885956744,
    "lat": 38.589296847812584
  },
  {
    "name": "Ellisville",
    "lng": -90.3060429489045,
    "lat": 40.62730809079489
  },
  {
    "name": "Ellisville",
    "lng": -89.20904877918015,
    "lat": 31.596979008187002
  },
  {
    "name": "Elloree",
    "lng": -80.57181133970376,
    "lat": 33.530029900062004
  },
  {
    "name": "Ellport",
    "lng": -80.2623611925401,
    "lat": 40.86208814167093
  },
  {
    "name": "Ellsinore",
    "lng": -90.74634633245964,
    "lat": 36.933689315428474
  },
  {
    "name": "Ellston",
    "lng": -94.10824097182292,
    "lat": 40.84011486737405
  },
  {
    "name": "Ellsworth",
    "lng": -98.23600478774168,
    "lat": 38.71783830683234
  },
  {
    "name": "Ellsworth",
    "lng": -98.22741970987171,
    "lat": 38.73573368822456
  },
  {
    "name": "Ellsworth",
    "lng": -96.01817568861772,
    "lat": 43.52048201353532
  },
  {
    "name": "Ellsworth",
    "lng": -93.58174352867519,
    "lat": 42.310321140268414
  },
  {
    "name": "Ellsworth",
    "lng": -92.48047059445061,
    "lat": 44.7365167114213
  },
  {
    "name": "Ellsworth",
    "lng": -88.71610227297485,
    "lat": 40.4495464102943
  },
  {
    "name": "Ellsworth",
    "lng": -85.24367832641232,
    "lat": 45.16651033798452
  },
  {
    "name": "Ellsworth",
    "lng": -80.02137308621388,
    "lat": 40.10733785678522
  },
  {
    "name": "Ellsworth",
    "lng": -68.48716236860112,
    "lat": 44.584241954138506
  },
  {
    "name": "Ellwood City",
    "lng": -80.28286407706672,
    "lat": 40.86186457509712
  },
  {
    "name": "Elm City",
    "lng": -77.8623457723695,
    "lat": 35.80719303895153
  },
  {
    "name": "Elm City",
    "lng": -77.86085909338165,
    "lat": 35.82101994846276
  },
  {
    "name": "Elm City",
    "lng": -77.85825565118013,
    "lat": 35.82700946272786
  },
  {
    "name": "Elm Creek",
    "lng": -99.37559365421345,
    "lat": 40.720096183827145
  },
  {
    "name": "Elm Grove",
    "lng": -88.08668105180399,
    "lat": 43.047977734884675
  },
  {
    "name": "Elm Grove Village",
    "lng": -95.19667183794914,
    "lat": 30.07596352251117
  },
  {
    "name": "Elm Springs",
    "lng": -94.24357678298423,
    "lat": 36.220315748717915
  },
  {
    "name": "Elm Springs",
    "lng": -94.2366708788798,
    "lat": 36.20694207146936
  },
  {
    "name": "Elm Springs",
    "lng": -94.2366708788798,
    "lat": 36.206942071469385
  },
  {
    "name": "Elm Springs",
    "lng": -94.18864157366097,
    "lat": 36.18958856986941
  },
  {
    "name": "Elm Springs",
    "lng": -94.18654302097896,
    "lat": 36.190082810452814
  },
  {
    "name": "Elma",
    "lng": -123.43587312300873,
    "lat": 47.00196066115642
  },
  {
    "name": "Elma",
    "lng": -123.42463586387551,
    "lat": 47.010751083822164
  },
  {
    "name": "Elma",
    "lng": -123.4223107941679,
    "lat": 46.999239248602045
  },
  {
    "name": "Elma",
    "lng": -123.40257811919281,
    "lat": 47.023062157546086
  },
  {
    "name": "Elma",
    "lng": -123.40211546986566,
    "lat": 47.00607147412944
  },
  {
    "name": "Elma",
    "lng": -92.43860741633071,
    "lat": 43.245731314395286
  },
  {
    "name": "Elmdale",
    "lng": -96.6457971576287,
    "lat": 38.373083528400265
  },
  {
    "name": "Elmdale",
    "lng": -94.49424541341195,
    "lat": 45.83401359950614
  },
  {
    "name": "Elmendorf",
    "lng": -98.33740487718386,
    "lat": 29.26092644339881
  },
  {
    "name": "Elmendorf",
    "lng": -98.32049358242838,
    "lat": 29.25765184330147
  },
  {
    "name": "Elmendorf",
    "lng": -98.30358102304676,
    "lat": 29.254115581042615
  },
  {
    "name": "Elmer",
    "lng": -99.35197485307967,
    "lat": 34.480859514399896
  },
  {
    "name": "Elmer",
    "lng": -92.64993486912786,
    "lat": 39.957144132189015
  },
  {
    "name": "Elmer City",
    "lng": -118.95296618269359,
    "lat": 47.99984221213767
  },
  {
    "name": "Elmira",
    "lng": -123.36178830775076,
    "lat": 44.06871739693773
  },
  {
    "name": "Elmira",
    "lng": -94.15464413527587,
    "lat": 39.508635572582165
  },
  {
    "name": "Elmira",
    "lng": -76.80970832169342,
    "lat": 42.093805200460075
  },
  {
    "name": "Elmira Heights",
    "lng": -76.82556683139131,
    "lat": 42.12669034522697
  },
  {
    "name": "Elmo",
    "lng": -110.81560425717637,
    "lat": 39.38771379180618
  },
  {
    "name": "Elmo",
    "lng": -95.11681332065051,
    "lat": 40.51884395221074
  },
  {
    "name": "Elmore",
    "lng": -94.0882563613345,
    "lat": 43.50630262362042
  },
  {
    "name": "Elmore",
    "lng": -86.36835599999998,
    "lat": 32.549785999595564
  },
  {
    "name": "Elmore",
    "lng": -86.36822366368771,
    "lat": 32.546174336399
  },
  {
    "name": "Elmore",
    "lng": -86.36282352258524,
    "lat": 32.53811016141916
  },
  {
    "name": "Elmore",
    "lng": -86.3307993022098,
    "lat": 32.54623949968725
  },
  {
    "name": "Elmore",
    "lng": -83.29129089179507,
    "lat": 41.470339169952666
  },
  {
    "name": "Elmore City",
    "lng": -97.3948561494648,
    "lat": 34.62531478840055
  },
  {
    "name": "Elmsford",
    "lng": -73.81527346715411,
    "lat": 41.054289027671906
  },
  {
    "name": "Elmwood",
    "lng": -96.29464303028246,
    "lat": 40.842533807587216
  },
  {
    "name": "Elmwood",
    "lng": -92.14918471400757,
    "lat": 44.78002613981606
  },
  {
    "name": "Elmwood",
    "lng": -89.96558355991627,
    "lat": 40.78040931804395
  },
  {
    "name": "Elmwood Park",
    "lng": -87.82249368822593,
    "lat": 42.69174122685983
  },
  {
    "name": "Elmwood Park",
    "lng": -87.816310793222,
    "lat": 41.92246439708012
  },
  {
    "name": "Elmwood Place",
    "lng": -84.48919285861159,
    "lat": 39.185533870509
  },
  {
    "name": "Elnora",
    "lng": -87.08483969352997,
    "lat": 38.87611768560967
  },
  {
    "name": "Elon",
    "lng": -79.50901204185138,
    "lat": 36.0991941514168
  },
  {
    "name": "Elon",
    "lng": -79.5067844584976,
    "lat": 36.15235201463462
  },
  {
    "name": "Elon",
    "lng": -79.49694414736877,
    "lat": 36.12710500651701
  },
  {
    "name": "Elon",
    "lng": -79.49344158991525,
    "lat": 36.12295526824273
  },
  {
    "name": "Eloy",
    "lng": -111.6043121885012,
    "lat": 32.746426247513895
  },
  {
    "name": "Eloy",
    "lng": -111.57923733333334,
    "lat": 32.813401668225005
  },
  {
    "name": "Elrosa",
    "lng": -94.94741427767259,
    "lat": 45.56276607535127
  },
  {
    "name": "Elroy",
    "lng": -90.27043132809494,
    "lat": 43.741652699486764
  },
  {
    "name": "Elsa",
    "lng": -97.99299768148937,
    "lat": 26.297073530893414
  },
  {
    "name": "Elsah",
    "lng": -90.35538947605716,
    "lat": 38.95404308413429
  },
  {
    "name": "Elsberry",
    "lng": -90.78989732039564,
    "lat": 39.16999904562107
  },
  {
    "name": "Elsie",
    "lng": -101.38915499116688,
    "lat": 40.847167600780544
  },
  {
    "name": "Elsie",
    "lng": -84.40951728093921,
    "lat": 43.08997430035234
  },
  {
    "name": "Elsie",
    "lng": -84.39760488520302,
    "lat": 43.10025484148791
  },
  {
    "name": "Elsie",
    "lng": -84.38839790908915,
    "lat": 43.08947245745894
  },
  {
    "name": "Elsinore",
    "lng": -112.14881197927271,
    "lat": 38.683418219725134
  },
  {
    "name": "Elsmere",
    "lng": -84.60167744373463,
    "lat": 38.99510139752351
  },
  {
    "name": "Elsmore",
    "lng": -95.15007591730392,
    "lat": 37.79414116097861
  },
  {
    "name": "Elsmore",
    "lng": -95.14489820580256,
    "lat": 37.794519526413914
  },
  {
    "name": "Elton",
    "lng": -92.69756223687334,
    "lat": 30.481181512630705
  },
  {
    "name": "Elvaston",
    "lng": -91.24931177604765,
    "lat": 40.395421786097536
  },
  {
    "name": "Elwood",
    "lng": -112.13734173740727,
    "lat": 41.67617298995224
  },
  {
    "name": "Elwood",
    "lng": -99.86647833333333,
    "lat": 40.594974335132214
  },
  {
    "name": "Elwood",
    "lng": -99.86188761587104,
    "lat": 40.589589716475736
  },
  {
    "name": "Elwood",
    "lng": -94.91685628876101,
    "lat": 39.75092372771515
  },
  {
    "name": "Elwood",
    "lng": -94.88426223966344,
    "lat": 39.75277690070211
  },
  {
    "name": "Elwood",
    "lng": -88.17535156733148,
    "lat": 41.42713300451272
  },
  {
    "name": "Elwood",
    "lng": -88.13241074970752,
    "lat": 41.41097299945289
  },
  {
    "name": "Elwood",
    "lng": -85.84355211987356,
    "lat": 40.29238574587725
  },
  {
    "name": "Elwood",
    "lng": -85.83736050822,
    "lat": 40.27534343667129
  },
  {
    "name": "Elwood",
    "lng": -85.8073202386075,
    "lat": 40.27528019424017
  },
  {
    "name": "Ely",
    "lng": -114.87084836176761,
    "lat": 39.26504077249345
  },
  {
    "name": "Ely",
    "lng": -91.85169201217361,
    "lat": 47.905460238761826
  },
  {
    "name": "Ely",
    "lng": -91.5847031098744,
    "lat": 41.875004376567745
  },
  {
    "name": "Elyria",
    "lng": -99.00530122170689,
    "lat": 41.68004864900909
  },
  {
    "name": "Elyria",
    "lng": -82.10633225650051,
    "lat": 41.37676601301376
  },
  {
    "name": "Elysburg",
    "lng": -76.54915989525219,
    "lat": 40.86799174282665
  },
  {
    "name": "Elysian",
    "lng": -93.67641748683124,
    "lat": 44.20657126105495
  },
  {
    "name": "Embarrass",
    "lng": -88.70380384326738,
    "lat": 44.66846485814275
  },
  {
    "name": "Embden",
    "lng": -69.9316920787563,
    "lat": 44.924832943607655
  },
  {
    "name": "Emden",
    "lng": -89.48505600778257,
    "lat": 40.29815952325904
  },
  {
    "name": "Emelle",
    "lng": -88.31648600513708,
    "lat": 32.7286778880461
  },
  {
    "name": "Emerald Beach",
    "lng": -93.67109626595631,
    "lat": 36.57559762662697
  },
  {
    "name": "Emerald Isle",
    "lng": -77.03178448262094,
    "lat": 34.6643994568729
  },
  {
    "name": "Emerald Lakes",
    "lng": -75.41540960845414,
    "lat": 41.08450030993637
  },
  {
    "name": "Emerson",
    "lng": -97.21775900664619,
    "lat": 49.016200177910456
  },
  {
    "name": "Emerson",
    "lng": -96.72637862717251,
    "lat": 42.27840420440203
  },
  {
    "name": "Emerson",
    "lng": -95.4028212943163,
    "lat": 41.01801362970269
  },
  {
    "name": "Emerson",
    "lng": -93.19587940327338,
    "lat": 33.097283752886995
  },
  {
    "name": "Emerson",
    "lng": -84.74850176468756,
    "lat": 34.13026877088027
  },
  {
    "name": "Emery",
    "lng": -111.2522458376516,
    "lat": 38.925132604062284
  },
  {
    "name": "Emery",
    "lng": -97.6195467701853,
    "lat": 43.60202592754899
  },
  {
    "name": "Emeryville",
    "lng": -122.29836215082118,
    "lat": 37.8389909461304
  },
  {
    "name": "Emhouse",
    "lng": -96.57720253091169,
    "lat": 32.16070262602158
  },
  {
    "name": "Emigsville",
    "lng": -76.73095737647235,
    "lat": 40.00848765812602
  },
  {
    "name": "Emily",
    "lng": -93.96651631845856,
    "lat": 46.76018829248054
  },
  {
    "name": "Eminence",
    "lng": -91.35872515446586,
    "lat": 37.15091119884134
  },
  {
    "name": "Eminence",
    "lng": -85.17713167000596,
    "lat": 38.36390749368427
  },
  {
    "name": "Emington",
    "lng": -88.35772320612215,
    "lat": 40.969404338723166
  },
  {
    "name": "Emma",
    "lng": -93.49525638774318,
    "lat": 38.97530928618748
  },
  {
    "name": "Emmet",
    "lng": -98.80930167316896,
    "lat": 42.47569862302601
  },
  {
    "name": "Emmetsburg",
    "lng": -94.68016298359048,
    "lat": 43.11430833975869
  },
  {
    "name": "Emmetsburg",
    "lng": -94.65790290892343,
    "lat": 43.11146860665083
  },
  {
    "name": "Emmett",
    "lng": -116.53769735297293,
    "lat": 43.8520688095745
  },
  {
    "name": "Emmett",
    "lng": -116.48697747856815,
    "lat": 43.86926470568451
  },
  {
    "name": "Emmett",
    "lng": -116.48678625007307,
    "lat": 43.86925599486976
  },
  {
    "name": "Emmett",
    "lng": -96.05728734838816,
    "lat": 39.30724625746549
  },
  {
    "name": "Emmett",
    "lng": -82.76539682413117,
    "lat": 42.993374108042545
  },
  {
    "name": "Emmitsburg",
    "lng": -77.32174784348072,
    "lat": 39.705126881237916
  },
  {
    "name": "Emmonak",
    "lng": -164.53486951541774,
    "lat": 62.77975597506262
  },
  {
    "name": "Emmons",
    "lng": -93.48509523094748,
    "lat": 43.50704664430249
  },
  {
    "name": "Emory",
    "lng": -95.76722526684448,
    "lat": 32.87622535071173
  },
  {
    "name": "Empire",
    "lng": -120.90421584863208,
    "lat": 37.64303650654363
  },
  {
    "name": "Empire",
    "lng": -105.68301749397473,
    "lat": 39.759500588789535
  },
  {
    "name": "Empire",
    "lng": -86.0594658363307,
    "lat": 44.81233145265681
  },
  {
    "name": "Empire",
    "lng": -80.62558466429445,
    "lat": 40.51041444368898
  },
  {
    "name": "Empire City",
    "lng": -98.05660491218583,
    "lat": 34.47349951548616
  },
  {
    "name": "Emporia",
    "lng": -96.2414472235846,
    "lat": 38.420112838108494
  },
  {
    "name": "Emporia",
    "lng": -96.23866196406723,
    "lat": 38.417654095772264
  },
  {
    "name": "Emporia",
    "lng": -96.23635947115443,
    "lat": 38.421816001150304
  },
  {
    "name": "Emporia",
    "lng": -96.20904612644573,
    "lat": 38.39289500557948
  },
  {
    "name": "Emporia",
    "lng": -96.19175455087539,
    "lat": 38.40971905338456
  },
  {
    "name": "Emporia",
    "lng": -96.19059162513521,
    "lat": 38.32748783520417
  },
  {
    "name": "Emporia",
    "lng": -96.15186246917285,
    "lat": 38.386623300794305
  },
  {
    "name": "Emporia",
    "lng": -96.15183845702626,
    "lat": 38.383950847996076
  },
  {
    "name": "Emporia",
    "lng": -96.15181962687515,
    "lat": 38.388249821293776
  },
  {
    "name": "Emporia",
    "lng": -77.53562400520286,
    "lat": 36.69532893609038
  },
  {
    "name": "Encampment",
    "lng": -106.79481132273125,
    "lat": 41.208755661448315
  },
  {
    "name": "Enchanted Oaks",
    "lng": -96.10908034513598,
    "lat": 32.26642908205711
  },
  {
    "name": "Encinal",
    "lng": -99.35502321329136,
    "lat": 28.040698997768327
  },
  {
    "name": "Encinitas",
    "lng": -117.29160723493139,
    "lat": 33.041901003248725
  },
  {
    "name": "Encino",
    "lng": -105.45858278946727,
    "lat": 34.65182293939831
  },
  {
    "name": "Endeavor",
    "lng": -89.46950745251459,
    "lat": 43.71631126746989
  },
  {
    "name": "Enderlin",
    "lng": -97.5989574231264,
    "lat": 46.62240473037291
  },
  {
    "name": "Enderlin",
    "lng": -97.56763912103659,
    "lat": 46.61258660915721
  },
  {
    "name": "Endicott",
    "lng": -117.68658848860144,
    "lat": 46.92798200851836
  },
  {
    "name": "Endicott",
    "lng": -97.09539853294652,
    "lat": 40.08147354290193
  },
  {
    "name": "Endicott",
    "lng": -76.06453212069823,
    "lat": 42.09717795936438
  },
  {
    "name": "Energy",
    "lng": -89.02467501627481,
    "lat": 37.77425581404776
  },
  {
    "name": "Energy",
    "lng": -89.02175075305026,
    "lat": 37.75658494062701
  },
  {
    "name": "Enfield",
    "lng": -88.33813809232804,
    "lat": 38.10191765506885
  },
  {
    "name": "Enfield",
    "lng": -77.66783975060011,
    "lat": 36.180262680752726
  },
  {
    "name": "Enfield",
    "lng": -72.55469925044387,
    "lat": 41.984018702594895
  },
  {
    "name": "Enfield",
    "lng": -68.58645127075158,
    "lat": 45.26938197370839
  },
  {
    "name": "England",
    "lng": -91.96901386582856,
    "lat": 34.546126744759036
  },
  {
    "name": "Englewood",
    "lng": -104.99414816797234,
    "lat": 39.64684857010039
  },
  {
    "name": "Englewood",
    "lng": -99.9867178699351,
    "lat": 37.040060108806806
  },
  {
    "name": "Englewood",
    "lng": -84.48941397965028,
    "lat": 35.421894418330076
  },
  {
    "name": "Englewood",
    "lng": -84.30711103980994,
    "lat": 39.86429482481181
  },
  {
    "name": "Englewood",
    "lng": -73.97349608415999,
    "lat": 40.89171733335278
  },
  {
    "name": "English",
    "lng": -86.4591588935389,
    "lat": 38.340766103311516
  },
  {
    "name": "Enid",
    "lng": -97.87013454684698,
    "lat": 36.40612898831113
  },
  {
    "name": "Enigma",
    "lng": -83.32898577654724,
    "lat": 31.40774093546337
  },
  {
    "name": "Ennis",
    "lng": -111.74175875348341,
    "lat": 45.347410081684195
  },
  {
    "name": "Ennis",
    "lng": -111.73129094169316,
    "lat": 45.34610213121647
  },
  {
    "name": "Ennis",
    "lng": -96.64266344905887,
    "lat": 32.29170116008187
  },
  {
    "name": "Ennis",
    "lng": -96.63780989385803,
    "lat": 32.323685287288754
  },
  {
    "name": "Enoch",
    "lng": -113.04453669892416,
    "lat": 37.766685247526596
  },
  {
    "name": "Enola",
    "lng": -92.20658355790863,
    "lat": 35.195906438647675
  },
  {
    "name": "Enon",
    "lng": -83.93298524371595,
    "lat": 39.86535394550841
  },
  {
    "name": "Enosburg Falls",
    "lng": -72.80261783955027,
    "lat": 44.908343923055945
  },
  {
    "name": "Ensign",
    "lng": -100.23314072010452,
    "lat": 37.653052590632036
  },
  {
    "name": "Enterprise",
    "lng": -113.74308905769021,
    "lat": 37.57164719510369
  },
  {
    "name": "Enterprise",
    "lng": -97.11607956291557,
    "lat": 38.90229879887258
  },
  {
    "name": "Enterprise",
    "lng": -88.81499584748286,
    "lat": 32.173869049631534
  },
  {
    "name": "Enterprise",
    "lng": -85.8463692606003,
    "lat": 31.32943644474617
  },
  {
    "name": "Entiat",
    "lng": -120.21633423367769,
    "lat": 47.6786404365282
  },
  {
    "name": "Enumclaw",
    "lng": -122.0244129003812,
    "lat": 47.2270712723931
  },
  {
    "name": "Enumclaw",
    "lng": -121.9898001305007,
    "lat": 47.2018419051691
  },
  {
    "name": "Enumclaw",
    "lng": -121.98189478277334,
    "lat": 47.164473154051954
  },
  {
    "name": "Enumclaw",
    "lng": -121.97559951462743,
    "lat": 47.22298941973853
  },
  {
    "name": "Enumclaw",
    "lng": -121.97004724569834,
    "lat": 47.246003090254554
  },
  {
    "name": "Enumclaw",
    "lng": -121.9549222496461,
    "lat": 47.185322736719634
  },
  {
    "name": "Enumclaw",
    "lng": -121.94228498582187,
    "lat": 47.19364924711168
  },
  {
    "name": "Enville",
    "lng": -88.43303251314302,
    "lat": 35.39232984127732
  },
  {
    "name": "Eolia",
    "lng": -91.01198484241688,
    "lat": 39.23884005837558
  },
  {
    "name": "Epes",
    "lng": -88.12729606328432,
    "lat": 32.69063452455973
  },
  {
    "name": "Ephesus",
    "lng": -85.25958774830195,
    "lat": 33.40697301693393
  },
  {
    "name": "Ephraim",
    "lng": -111.5845348542031,
    "lat": 39.3561967442339
  },
  {
    "name": "Ephraim",
    "lng": -87.16617505869534,
    "lat": 45.158591095234655
  },
  {
    "name": "Ephrata",
    "lng": -119.53532541697864,
    "lat": 47.312783858297195
  },
  {
    "name": "Ephrata",
    "lng": -76.18130291970961,
    "lat": 40.18091672635218
  },
  {
    "name": "Epping",
    "lng": -103.35767149461053,
    "lat": 48.281619920706085
  },
  {
    "name": "Epping",
    "lng": -71.0746773903569,
    "lat": 43.050112902557665
  },
  {
    "name": "Epps",
    "lng": -91.48141177111856,
    "lat": 32.60382414592074
  },
  {
    "name": "Epsom",
    "lng": -71.33878328621643,
    "lat": 43.215121059906906
  },
  {
    "name": "Epworth",
    "lng": -90.93104054725949,
    "lat": 42.447508681704484
  },
  {
    "name": "Epworth",
    "lng": -90.92264429342413,
    "lat": 42.4391095244335
  },
  {
    "name": "Equality",
    "lng": -88.34217567319463,
    "lat": 37.73715730506688
  },
  {
    "name": "Erath",
    "lng": -92.03742459413746,
    "lat": 29.958786812734697
  },
  {
    "name": "Erhard",
    "lng": -96.09648795430874,
    "lat": 46.48358348809928
  },
  {
    "name": "Erick",
    "lng": -99.86841621288502,
    "lat": 35.21510260963466
  },
  {
    "name": "Ericson",
    "lng": -98.67857753451975,
    "lat": 41.78044218800213
  },
  {
    "name": "Erie",
    "lng": -105.10390011400834,
    "lat": 40.014645793790024
  },
  {
    "name": "Erie",
    "lng": -105.03979857749766,
    "lat": 40.037153026532415
  },
  {
    "name": "Erie",
    "lng": -95.24329369746407,
    "lat": 37.569378793958556
  },
  {
    "name": "Erie",
    "lng": -90.08154328119028,
    "lat": 41.658721084551736
  },
  {
    "name": "Erie",
    "lng": -80.07312053814515,
    "lat": 42.11662704471071
  },
  {
    "name": "Erin",
    "lng": -87.70254526725685,
    "lat": 36.31597619016617
  },
  {
    "name": "Erin Springs",
    "lng": -97.6054455478386,
    "lat": 34.81139818443787
  },
  {
    "name": "Erlanger",
    "lng": -84.58630357103539,
    "lat": 39.01092352246393
  },
  {
    "name": "Eros",
    "lng": -92.42393333405491,
    "lat": 32.39255823733153
  },
  {
    "name": "Erskine",
    "lng": -96.01509376974619,
    "lat": 47.6656957041785
  },
  {
    "name": "Erving",
    "lng": -72.42908868519109,
    "lat": 42.6076090660263
  },
  {
    "name": "Erwin",
    "lng": -97.44085807988534,
    "lat": 44.48802371598541
  },
  {
    "name": "Erwin",
    "lng": -82.41162650350688,
    "lat": 36.14546988358508
  },
  {
    "name": "Erwin",
    "lng": -78.69797117152636,
    "lat": 35.31094853539263
  },
  {
    "name": "Erwin",
    "lng": -78.68746160414,
    "lat": 35.349529658016046
  },
  {
    "name": "Erwin",
    "lng": -78.6726994210705,
    "lat": 35.32215584396651
  },
  {
    "name": "Erwin",
    "lng": -78.66442734396871,
    "lat": 35.30802270646861
  },
  {
    "name": "Esbon",
    "lng": -98.43394611740263,
    "lat": 39.82201829601776
  },
  {
    "name": "Escalante",
    "lng": -111.60013262208318,
    "lat": 37.76499899183224
  },
  {
    "name": "Escalon",
    "lng": -121.00719790634103,
    "lat": 37.755172122647245
  },
  {
    "name": "Escalon",
    "lng": -121.00345798044106,
    "lat": 37.75623591702249
  },
  {
    "name": "Escalon",
    "lng": -120.9975723864246,
    "lat": 37.7938707904975
  },
  {
    "name": "Escanaba",
    "lng": -87.16017963866234,
    "lat": 45.7557151969025
  },
  {
    "name": "Escanaba",
    "lng": -87.11331053179829,
    "lat": 45.73995333099965
  },
  {
    "name": "Escanaba",
    "lng": -87.09164308280276,
    "lat": 45.74939650969259
  },
  {
    "name": "Escanaba",
    "lng": -87.0692702022389,
    "lat": 45.70870969227032
  },
  {
    "name": "Escanaba",
    "lng": -87.04434986732932,
    "lat": 45.74013780412799
  },
  {
    "name": "Escondido",
    "lng": -117.07212602473071,
    "lat": 33.13471970189414
  },
  {
    "name": "Escondido",
    "lng": -117.01984535485728,
    "lat": 33.18084909913935
  },
  {
    "name": "Eskridge",
    "lng": -96.1048465462968,
    "lat": 38.85941798710449
  },
  {
    "name": "Esmond",
    "lng": -99.76509236640626,
    "lat": 48.03325727053562
  },
  {
    "name": "Espanola",
    "lng": -106.0675828075298,
    "lat": 36.00397718037736
  },
  {
    "name": "Esperance",
    "lng": -74.25892094659027,
    "lat": 42.765131199793835
  },
  {
    "name": "Essex",
    "lng": -95.30382158696162,
    "lat": 40.832678776268075
  },
  {
    "name": "Essex",
    "lng": -89.86282533856209,
    "lat": 36.81156210562019
  },
  {
    "name": "Essex",
    "lng": -72.41450551900226,
    "lat": 41.349966346332266
  },
  {
    "name": "Essex Junction",
    "lng": -73.11446521998161,
    "lat": 44.490040765544556
  },
  {
    "name": "Estancia",
    "lng": -106.03124459185914,
    "lat": 34.76691711864182
  },
  {
    "name": "Estell Manor",
    "lng": -74.77510049837605,
    "lat": 39.358614879021474
  },
  {
    "name": "Estelline",
    "lng": -100.4399466814557,
    "lat": 34.54699966322799
  },
  {
    "name": "Estelline",
    "lng": -96.90104352390121,
    "lat": 44.57693875313458
  },
  {
    "name": "Estes Park",
    "lng": -105.54960361083815,
    "lat": 40.36958799013619
  },
  {
    "name": "Estes Park",
    "lng": -105.54960361083812,
    "lat": 40.3695879901362
  },
  {
    "name": "Estes Park",
    "lng": -105.53076250360641,
    "lat": 40.33579348300107
  },
  {
    "name": "Estes Park",
    "lng": -105.5307625036064,
    "lat": 40.33579348300107
  },
  {
    "name": "Estes Park",
    "lng": -105.51993615440831,
    "lat": 40.37099558049434
  },
  {
    "name": "Estherville",
    "lng": -94.8342171746758,
    "lat": 43.39978168299808
  },
  {
    "name": "Estherwood",
    "lng": -92.46331481608992,
    "lat": 30.181614877966116
  },
  {
    "name": "Estill",
    "lng": -81.24118900111553,
    "lat": 32.75386208392293
  },
  {
    "name": "Estill Springs",
    "lng": -86.16623383407754,
    "lat": 35.28607025197212
  },
  {
    "name": "Estill Springs",
    "lng": -86.15655164834212,
    "lat": 35.28980992305792
  },
  {
    "name": "Estill Springs",
    "lng": -86.15303132304415,
    "lat": 35.26656653443737
  },
  {
    "name": "Estill Springs",
    "lng": -86.13955160866321,
    "lat": 35.27549550977037
  },
  {
    "name": "Estral Beach",
    "lng": -83.23600603887115,
    "lat": 41.986583530504014
  },
  {
    "name": "Ethan",
    "lng": -97.98313296726356,
    "lat": 43.5462896442439
  },
  {
    "name": "Ethel",
    "lng": -92.74095019464033,
    "lat": 39.893273599958
  },
  {
    "name": "Ethel",
    "lng": -89.46503738568218,
    "lat": 33.12203340007821
  },
  {
    "name": "Ethel",
    "lng": -89.46300906993146,
    "lat": 33.12999941858027
  },
  {
    "name": "Ethelsville",
    "lng": -88.21609330034161,
    "lat": 33.41406663470103
  },
  {
    "name": "Ethridge",
    "lng": -87.3012245785311,
    "lat": 35.321595010378076
  },
  {
    "name": "Etna",
    "lng": -122.89449866915197,
    "lat": 41.45819084625152
  },
  {
    "name": "Etna",
    "lng": -69.13384470600907,
    "lat": 44.78529637816479
  },
  {
    "name": "Etna Green",
    "lng": -86.05587452083635,
    "lat": 41.28088573557404
  },
  {
    "name": "Etna Green",
    "lng": -86.04572534395142,
    "lat": 41.27556106382521
  },
  {
    "name": "Eton",
    "lng": -84.7664936995184,
    "lat": 34.82578711864635
  },
  {
    "name": "Etowah",
    "lng": -97.16393091184632,
    "lat": 35.12910826751935
  },
  {
    "name": "Etowah",
    "lng": -90.23212889638233,
    "lat": 35.722497967830265
  },
  {
    "name": "Etowah",
    "lng": -84.52797209319992,
    "lat": 35.338336409679385
  },
  {
    "name": "Ettrick",
    "lng": -91.26674222768422,
    "lat": 44.170256627206065
  },
  {
    "name": "Eubank",
    "lng": -84.65379512562136,
    "lat": 37.27941709871076
  },
  {
    "name": "Euclid",
    "lng": -81.51900667471901,
    "lat": 41.59235874138587
  },
  {
    "name": "Eudora",
    "lng": -95.09750878066139,
    "lat": 38.938221761219324
  },
  {
    "name": "Eudora",
    "lng": -95.094753865747,
    "lat": 38.91947083363958
  },
  {
    "name": "Eudora",
    "lng": -91.26405378194642,
    "lat": 33.11876174803966
  },
  {
    "name": "Eufaula",
    "lng": -95.5860926340129,
    "lat": 35.29204752137771
  },
  {
    "name": "Eufaula",
    "lng": -85.15033841270821,
    "lat": 31.91034200348688
  },
  {
    "name": "Euharlee",
    "lng": -84.93266845582001,
    "lat": 34.14415932520464
  },
  {
    "name": "Euless",
    "lng": -97.07983746168918,
    "lat": 32.85073247367116
  },
  {
    "name": "Eunice",
    "lng": -103.19253746964435,
    "lat": 32.44277205306254
  },
  {
    "name": "Eunice",
    "lng": -92.4190665990698,
    "lat": 30.490436095818612
  },
  {
    "name": "Eupora",
    "lng": -89.27644800872679,
    "lat": 33.54346862342845
  },
  {
    "name": "Eureka",
    "lng": -124.15685489766868,
    "lat": 40.794140736643364
  },
  {
    "name": "Eureka",
    "lng": -115.04753366168167,
    "lat": 48.876331357282666
  },
  {
    "name": "Eureka",
    "lng": -112.11507330997314,
    "lat": 39.95867101848009
  },
  {
    "name": "Eureka",
    "lng": -99.62263198921929,
    "lat": 45.76965737864863
  },
  {
    "name": "Eureka",
    "lng": -99.6075631132152,
    "lat": 45.776985572419235
  },
  {
    "name": "Eureka",
    "lng": -96.29122841426867,
    "lat": 37.85065205582308
  },
  {
    "name": "Eureka",
    "lng": -96.28860422445456,
    "lat": 37.82327576958689
  },
  {
    "name": "Eureka",
    "lng": -96.28598868104237,
    "lat": 32.01330822474717
  },
  {
    "name": "Eureka",
    "lng": -96.28546872038103,
    "lat": 37.81242244300414
  },
  {
    "name": "Eureka",
    "lng": -96.28521966596885,
    "lat": 31.988382145527083
  },
  {
    "name": "Eureka",
    "lng": -90.6491423186628,
    "lat": 38.50153031844852
  },
  {
    "name": "Eureka",
    "lng": -89.29252400353644,
    "lat": 40.70496041069516
  },
  {
    "name": "Eureka",
    "lng": -89.27740516377129,
    "lat": 40.71463611254431
  },
  {
    "name": "Eureka",
    "lng": -89.27013639297249,
    "lat": 40.70832001875161
  },
  {
    "name": "Eureka",
    "lng": -77.87632421744773,
    "lat": 35.54147067589526
  },
  {
    "name": "Eureka Springs",
    "lng": -93.74512770736601,
    "lat": 36.41037845811813
  },
  {
    "name": "Eustace",
    "lng": -96.03959960273566,
    "lat": 32.29455760410266
  },
  {
    "name": "Eustace",
    "lng": -96.03864611016444,
    "lat": 32.28676495188549
  },
  {
    "name": "Eustace",
    "lng": -96.0295586882549,
    "lat": 32.32447799528716
  },
  {
    "name": "Eustace",
    "lng": -96.024553983172,
    "lat": 32.330563358847115
  },
  {
    "name": "Eustace",
    "lng": -96.0117998543444,
    "lat": 32.30721931504213
  },
  {
    "name": "Eustace",
    "lng": -96.00887752334319,
    "lat": 32.29217139074099
  },
  {
    "name": "Eustace",
    "lng": -96.00772097986612,
    "lat": 32.32713440747767
  },
  {
    "name": "Eustace",
    "lng": -96.0048020886182,
    "lat": 32.29595373899955
  },
  {
    "name": "Eustace",
    "lng": -95.98436307814463,
    "lat": 32.32863368799554
  },
  {
    "name": "Eustis",
    "lng": -100.02955050171337,
    "lat": 40.66452752893286
  },
  {
    "name": "Eustis",
    "lng": -70.49176418032847,
    "lat": 45.180597325817075
  },
  {
    "name": "Eutaw",
    "lng": -87.8986473023331,
    "lat": 32.84360736514796
  },
  {
    "name": "Eutawville",
    "lng": -80.34218748384163,
    "lat": 33.39718736223975
  },
  {
    "name": "Eva",
    "lng": -86.76162801472516,
    "lat": 34.32705849506692
  },
  {
    "name": "Evans",
    "lng": -104.73921398018982,
    "lat": 40.36671000881508
  },
  {
    "name": "Evans Mills",
    "lng": -75.80812559793388,
    "lat": 44.08706797681088
  },
  {
    "name": "Evansdale",
    "lng": -92.27746351521664,
    "lat": 42.46386809757718
  },
  {
    "name": "Evanston",
    "lng": -110.96459256702973,
    "lat": 41.2602588232179
  },
  {
    "name": "Evanston",
    "lng": -87.69385657067123,
    "lat": 42.04634539515248
  },
  {
    "name": "Evansville",
    "lng": -106.25249297489655,
    "lat": 42.86808921683648
  },
  {
    "name": "Evansville",
    "lng": -106.21907326118182,
    "lat": 42.843487632223706
  },
  {
    "name": "Evansville",
    "lng": -95.686785578854,
    "lat": 46.00623305354029
  },
  {
    "name": "Evansville",
    "lng": -89.93406178626786,
    "lat": 38.088376575241135
  },
  {
    "name": "Evansville",
    "lng": -89.92161281818343,
    "lat": 38.09149484424156
  },
  {
    "name": "Evansville",
    "lng": -89.29668212636444,
    "lat": 42.778264882561686
  },
  {
    "name": "Evansville",
    "lng": -87.53402868653237,
    "lat": 37.98819708589129
  },
  {
    "name": "Evant",
    "lng": -98.15016910542211,
    "lat": 31.47616331842545
  },
  {
    "name": "Evart",
    "lng": -85.27427666772653,
    "lat": 43.900171832812106
  },
  {
    "name": "Evarts",
    "lng": -83.19752819952687,
    "lat": 36.864109578132194
  },
  {
    "name": "Eveleth",
    "lng": -92.54421684925279,
    "lat": 47.46367514002946
  },
  {
    "name": "Evendale",
    "lng": -84.42671836462125,
    "lat": 39.250786948318975
  },
  {
    "name": "Evening Shade",
    "lng": -91.62187170238663,
    "lat": 36.069929125771594
  },
  {
    "name": "Everest",
    "lng": -95.42484494199282,
    "lat": 39.676741921221605
  },
  {
    "name": "Everett",
    "lng": -122.23129326731224,
    "lat": 47.96330876267938
  },
  {
    "name": "Everett",
    "lng": -121.87987798405685,
    "lat": 47.95535332073944
  },
  {
    "name": "Everett",
    "lng": -121.83594434444692,
    "lat": 47.948154338077785
  },
  {
    "name": "Everett",
    "lng": -121.79888228694169,
    "lat": 47.95660247098374
  },
  {
    "name": "Everett",
    "lng": -78.36660559322422,
    "lat": 40.013423553309394
  },
  {
    "name": "Everett",
    "lng": -71.05442953921909,
    "lat": 42.40645836719952
  },
  {
    "name": "Everetts",
    "lng": -77.1722924852258,
    "lat": 35.83469002372139
  },
  {
    "name": "Evergreen",
    "lng": -92.628582560066,
    "lat": 37.52644404453234
  },
  {
    "name": "Evergreen",
    "lng": -92.60106018254484,
    "lat": 37.54966570707066
  },
  {
    "name": "Evergreen",
    "lng": -92.55074359375696,
    "lat": 37.540624145272524
  },
  {
    "name": "Evergreen",
    "lng": -92.10516825334341,
    "lat": 30.95275908303429
  },
  {
    "name": "Evergreen",
    "lng": -86.95836385895088,
    "lat": 31.437942374648543
  },
  {
    "name": "Evergreen Park",
    "lng": -87.70134169831604,
    "lat": 41.72128607128864
  },
  {
    "name": "Everly",
    "lng": -95.31988843538137,
    "lat": 43.161801208664194
  },
  {
    "name": "Everman",
    "lng": -97.28301445410291,
    "lat": 32.62963752181923
  },
  {
    "name": "Everson",
    "lng": -122.35339794037462,
    "lat": 48.91088725031405
  },
  {
    "name": "Everton",
    "lng": -93.702457790201,
    "lat": 37.34283799968744
  },
  {
    "name": "Everton",
    "lng": -92.90954690135784,
    "lat": 36.1541419943949
  },
  {
    "name": "Ewing",
    "lng": -98.34308756552662,
    "lat": 42.259094966734125
  },
  {
    "name": "Ewing",
    "lng": -91.71468343936381,
    "lat": 40.00874941894824
  },
  {
    "name": "Ewing",
    "lng": -88.85284528241004,
    "lat": 38.08885706310214
  },
  {
    "name": "Ewing",
    "lng": -83.86206613671777,
    "lat": 38.42775421302287
  },
  {
    "name": "Excel",
    "lng": -87.34327406887299,
    "lat": 31.426432700813354
  },
  {
    "name": "Excelsior",
    "lng": -93.56637309370159,
    "lat": 44.901219872321654
  },
  {
    "name": "Excelsior Estates",
    "lng": -94.20818690961009,
    "lat": 39.38995351973478
  },
  {
    "name": "Excelsior Springs",
    "lng": -94.23786286784063,
    "lat": 39.3389809868958
  },
  {
    "name": "Exeland",
    "lng": -91.24251184569249,
    "lat": 45.66697327896812
  },
  {
    "name": "Exeter",
    "lng": -119.16380543741397,
    "lat": 36.271572327722765
  },
  {
    "name": "Exeter",
    "lng": -119.14459365443919,
    "lat": 36.29522159338756
  },
  {
    "name": "Exeter",
    "lng": -97.4487949239946,
    "lat": 40.64453354654559
  },
  {
    "name": "Exeter",
    "lng": -93.93996482294406,
    "lat": 36.67065092034372
  },
  {
    "name": "Exeter",
    "lng": -90.49603246406525,
    "lat": 39.71906889761647
  },
  {
    "name": "Exeter",
    "lng": -71.63170777104354,
    "lat": 41.56608418122538
  },
  {
    "name": "Exeter",
    "lng": -70.9644099915341,
    "lat": 42.99028908741529
  },
  {
    "name": "Exeter",
    "lng": -69.13549969905402,
    "lat": 44.96441730473975
  },
  {
    "name": "Exira",
    "lng": -94.87995187250571,
    "lat": 41.591836861769295
  },
  {
    "name": "Exline",
    "lng": -92.84324528980298,
    "lat": 40.649094426195404
  },
  {
    "name": "Exmore",
    "lng": -75.82807455411921,
    "lat": 37.52945851647158
  },
  {
    "name": "Eyota",
    "lng": -92.23212838020886,
    "lat": 43.988330058035885
  },
  {
    "name": "Fabius",
    "lng": -75.98468748309062,
    "lat": 42.834657245307355
  },
  {
    "name": "Fair Bluff",
    "lng": -79.03432925872461,
    "lat": 34.31095934330571
  },
  {
    "name": "Fair Grove",
    "lng": -93.15225388975111,
    "lat": 37.38304277886256
  },
  {
    "name": "Fair Haven",
    "lng": -76.70446928408032,
    "lat": 43.326331308283386
  },
  {
    "name": "Fair Oaks",
    "lng": -95.726139150725,
    "lat": 36.106335538973354
  },
  {
    "name": "Fair Oaks",
    "lng": -95.71349484407507,
    "lat": 36.1715693483738
  },
  {
    "name": "Fair Oaks",
    "lng": -95.68303399428932,
    "lat": 36.12461343186158
  },
  {
    "name": "Fair Play",
    "lng": -93.5762812900648,
    "lat": 37.63340239856366
  },
  {
    "name": "Fairbank",
    "lng": -92.04852694637206,
    "lat": 42.63970997048508
  },
  {
    "name": "Fairbanks",
    "lng": -147.65331779221725,
    "lat": 64.83537231164266
  },
  {
    "name": "Fairborn",
    "lng": -84.00958212618038,
    "lat": 39.80129042945363
  },
  {
    "name": "Fairburn",
    "lng": -103.20869633791922,
    "lat": 43.686942433217325
  },
  {
    "name": "Fairburn",
    "lng": -84.59139373429102,
    "lat": 33.54970269481292
  },
  {
    "name": "Fairbury",
    "lng": -97.17649888672463,
    "lat": 40.14383738596748
  },
  {
    "name": "Fairbury",
    "lng": -88.51595552949907,
    "lat": 40.74601321301768
  },
  {
    "name": "Fairchild",
    "lng": -90.95773087487127,
    "lat": 44.60370211897469
  },
  {
    "name": "Fairchilds",
    "lng": -95.77762209411974,
    "lat": 29.43973080250243
  },
  {
    "name": "Fairdale",
    "lng": -98.23111238778863,
    "lat": 48.48994475468992
  },
  {
    "name": "Fairfax",
    "lng": -122.59557550179228,
    "lat": 37.98825426635598
  },
  {
    "name": "Fairfax",
    "lng": -98.88894504506067,
    "lat": 43.02804960194046
  },
  {
    "name": "Fairfax",
    "lng": -96.70753309852844,
    "lat": 36.5700707205059
  },
  {
    "name": "Fairfax",
    "lng": -95.39195312376008,
    "lat": 40.33919028165976
  },
  {
    "name": "Fairfax",
    "lng": -94.72325064574872,
    "lat": 44.528364971286514
  },
  {
    "name": "Fairfax",
    "lng": -94.70992712719978,
    "lat": 44.52550315289868
  },
  {
    "name": "Fairfax",
    "lng": -91.77907740552497,
    "lat": 41.92835454407304
  },
  {
    "name": "Fairfax",
    "lng": -84.39707069654781,
    "lat": 39.143248386098584
  },
  {
    "name": "Fairfax",
    "lng": -81.236477993712,
    "lat": 32.959268891627275
  },
  {
    "name": "Fairfax",
    "lng": -77.29979297490922,
    "lat": 38.8530769699494
  },
  {
    "name": "Fairfield",
    "lng": -122.03905994829339,
    "lat": 38.25599670834192
  },
  {
    "name": "Fairfield",
    "lng": -117.17487258657071,
    "lat": 47.384814318963514
  },
  {
    "name": "Fairfield",
    "lng": -114.80061322768007,
    "lat": 43.348903198892806
  },
  {
    "name": "Fairfield",
    "lng": -112.08429086423212,
    "lat": 40.24621019017883
  },
  {
    "name": "Fairfield",
    "lng": -111.98119510118022,
    "lat": 47.61536147080051
  },
  {
    "name": "Fairfield",
    "lng": -98.10323832760619,
    "lat": 40.432310740350395
  },
  {
    "name": "Fairfield",
    "lng": -96.17005136277378,
    "lat": 31.718311190627798
  },
  {
    "name": "Fairfield",
    "lng": -96.14688902333359,
    "lat": 31.716852694102716
  },
  {
    "name": "Fairfield",
    "lng": -91.96668883434013,
    "lat": 41.00668106515622
  },
  {
    "name": "Fairfield",
    "lng": -88.37225131470564,
    "lat": 38.379776086103924
  },
  {
    "name": "Fairfield",
    "lng": -88.3688370234596,
    "lat": 38.35711978210818
  },
  {
    "name": "Fairfield",
    "lng": -86.91940055880325,
    "lat": 33.47469959238321
  },
  {
    "name": "Fairfield",
    "lng": -85.38389284402614,
    "lat": 37.93221822095705
  },
  {
    "name": "Fairfield",
    "lng": -84.54061642601822,
    "lat": 39.330221432178654
  },
  {
    "name": "Fairfield",
    "lng": -73.27314790358226,
    "lat": 41.176231877818964
  },
  {
    "name": "Fairfield",
    "lng": -73.25369544026026,
    "lat": 41.1433506633984
  },
  {
    "name": "Fairfield",
    "lng": -69.67499211161775,
    "lat": 44.63734614698585
  },
  {
    "name": "Fairfield Bay",
    "lng": -92.26721765058906,
    "lat": 35.60963007442471
  },
  {
    "name": "Fairfield Bay",
    "lng": -92.26261133333333,
    "lat": 35.58185799969986
  },
  {
    "name": "Fairgrove",
    "lng": -83.54343790763103,
    "lat": 43.52446838620773
  },
  {
    "name": "Fairhope",
    "lng": -87.89748386982883,
    "lat": 30.50786463589875
  },
  {
    "name": "Fairhope",
    "lng": -87.89006184461806,
    "lat": 30.506523691533893
  },
  {
    "name": "Fairhope",
    "lng": -87.88710003941964,
    "lat": 30.49751628885027
  },
  {
    "name": "Fairhope",
    "lng": -87.88663670351357,
    "lat": 30.50710704564866
  },
  {
    "name": "Fairhope",
    "lng": -87.88521364150185,
    "lat": 30.532302250332542
  },
  {
    "name": "Fairhope",
    "lng": -87.88095609286862,
    "lat": 30.493050994899104
  },
  {
    "name": "Fairhope",
    "lng": -87.87848963518806,
    "lat": 30.461253976420878
  },
  {
    "name": "Fairhope",
    "lng": -87.87798512271573,
    "lat": 30.53156766704446
  },
  {
    "name": "Fairhope",
    "lng": -87.87380740260632,
    "lat": 30.504397453654764
  },
  {
    "name": "Fairhope",
    "lng": -87.86355073121945,
    "lat": 30.535716717063888
  },
  {
    "name": "Fairhope",
    "lng": -87.85821030465297,
    "lat": 30.54376901704189
  },
  {
    "name": "Fairhope",
    "lng": -87.8484028106895,
    "lat": 30.515959012453866
  },
  {
    "name": "Fairhope",
    "lng": -87.84702006726275,
    "lat": 30.52920273005863
  },
  {
    "name": "Fairland",
    "lng": -94.8623063946283,
    "lat": 36.740025589419496
  },
  {
    "name": "Fairland",
    "lng": -94.84977474563959,
    "lat": 36.75127604010924
  },
  {
    "name": "Fairland",
    "lng": -94.83675427661538,
    "lat": 36.758432531325326
  },
  {
    "name": "Fairland",
    "lng": -94.82584924470922,
    "lat": 36.759614955657476
  },
  {
    "name": "Fairlawn",
    "lng": -81.62123599019921,
    "lat": 41.1269804638585
  },
  {
    "name": "Fairmont",
    "lng": -97.704446964129,
    "lat": 36.35522497188595
  },
  {
    "name": "Fairmont",
    "lng": -97.58533769659628,
    "lat": 40.635757153769234
  },
  {
    "name": "Fairmont",
    "lng": -94.4618595842818,
    "lat": 43.643765134429266
  },
  {
    "name": "Fairmont",
    "lng": -94.44506805569252,
    "lat": 43.68305103389562
  },
  {
    "name": "Fairmont",
    "lng": -80.14862320615752,
    "lat": 39.4769310840449
  },
  {
    "name": "Fairmont",
    "lng": -79.13216103343652,
    "lat": 34.52171260515798
  },
  {
    "name": "Fairmont",
    "lng": -79.11327447122605,
    "lat": 34.493631719491795
  },
  {
    "name": "Fairmont",
    "lng": -79.09271876766807,
    "lat": 34.50044755152414
  },
  {
    "name": "Fairmont City",
    "lng": -90.1023334110356,
    "lat": 38.65021110276306
  },
  {
    "name": "Fairmount",
    "lng": -96.60281779134984,
    "lat": 46.054643739472596
  },
  {
    "name": "Fairmount",
    "lng": -87.82930413856975,
    "lat": 40.04556475474373
  },
  {
    "name": "Fairmount",
    "lng": -85.64772195461089,
    "lat": 40.41732867883047
  },
  {
    "name": "Fairmount",
    "lng": -84.69979753523397,
    "lat": 34.437994813215504
  },
  {
    "name": "Fairplay",
    "lng": -105.99637161994634,
    "lat": 39.224754441360375
  },
  {
    "name": "Fairport",
    "lng": -77.4426426234298,
    "lat": 43.098897167394945
  },
  {
    "name": "Fairport Harbor",
    "lng": -81.27309311819755,
    "lat": 41.74764237194181
  },
  {
    "name": "Fairview",
    "lng": -111.43685957616242,
    "lat": 39.62997179006671
  },
  {
    "name": "Fairview",
    "lng": -104.05075276229883,
    "lat": 47.85107861170087
  },
  {
    "name": "Fairview",
    "lng": -98.4772785086666,
    "lat": 36.269550949795
  },
  {
    "name": "Fairview",
    "lng": -98.45150222223513,
    "lat": 36.29136899643706
  },
  {
    "name": "Fairview",
    "lng": -96.48756108001331,
    "lat": 43.222282720382644
  },
  {
    "name": "Fairview",
    "lng": -95.72703034658468,
    "lat": 39.84007772098521
  },
  {
    "name": "Fairview",
    "lng": -94.08664955052953,
    "lat": 36.81727482523277
  },
  {
    "name": "Fairview",
    "lng": -90.18441545444459,
    "lat": 40.64858094114681
  },
  {
    "name": "Fairview",
    "lng": -87.15563375212251,
    "lat": 35.9438039563661
  },
  {
    "name": "Fairview",
    "lng": -87.15400782607695,
    "lat": 35.98872993097134
  },
  {
    "name": "Fairview",
    "lng": -87.12625345175039,
    "lat": 35.98343021270998
  },
  {
    "name": "Fairview",
    "lng": -86.6834208525213,
    "lat": 34.247388873213346
  },
  {
    "name": "Fairview",
    "lng": -84.4846334219137,
    "lat": 38.99756324595781
  },
  {
    "name": "Fairview",
    "lng": -81.23468107923343,
    "lat": 40.05736143719918
  },
  {
    "name": "Fairview",
    "lng": -80.53453178842794,
    "lat": 35.154543721360945
  },
  {
    "name": "Fairview",
    "lng": -80.24682664335374,
    "lat": 39.59247641081593
  },
  {
    "name": "Fairview Heights",
    "lng": -90.00543947769835,
    "lat": 38.597368692706496
  },
  {
    "name": "Fairview Park",
    "lng": -87.41418843808741,
    "lat": 39.68227558740493
  },
  {
    "name": "Fairview Park",
    "lng": -81.8529414469575,
    "lat": 41.441979489741875
  },
  {
    "name": "Fairwater",
    "lng": -88.868127985465,
    "lat": 43.74235996881153
  },
  {
    "name": "Fairway",
    "lng": -94.62880215292691,
    "lat": 39.02435627517682
  },
  {
    "name": "Fairwood",
    "lng": -117.41579852580175,
    "lat": 47.767832463074626
  },
  {
    "name": "Faison",
    "lng": -78.18777347604346,
    "lat": 35.1153855750932
  },
  {
    "name": "Faison",
    "lng": -78.18534629933832,
    "lat": 35.11378419835929
  },
  {
    "name": "Faison",
    "lng": -78.18423372903835,
    "lat": 35.115932358691886
  },
  {
    "name": "Faison",
    "lng": -78.13679368685757,
    "lat": 35.11648253066429
  },
  {
    "name": "Faith",
    "lng": -102.03634194105578,
    "lat": 45.025777237247944
  },
  {
    "name": "Faith",
    "lng": -80.45911692368273,
    "lat": 35.58930231707927
  },
  {
    "name": "Falcon",
    "lng": -90.25562911927024,
    "lat": 34.391140855406086
  },
  {
    "name": "Falcon",
    "lng": -78.64819754704018,
    "lat": 35.192268371638846
  },
  {
    "name": "Falcon Heights",
    "lng": -93.17703582868955,
    "lat": 44.989933903954615
  },
  {
    "name": "Falconer",
    "lng": -79.19727159184971,
    "lat": 42.1189841109821
  },
  {
    "name": "Falfurrias",
    "lng": -98.14532680242574,
    "lat": 27.224503333401668
  },
  {
    "name": "Falkland",
    "lng": -77.51324962783794,
    "lat": 35.69854675083091
  },
  {
    "name": "Falkner",
    "lng": -88.93311170158582,
    "lat": 34.849350584832194
  },
  {
    "name": "Falkville",
    "lng": -86.90889010241162,
    "lat": 34.379477298317596
  },
  {
    "name": "Falkville",
    "lng": -86.90828074631229,
    "lat": 34.37935556142861
  },
  {
    "name": "Falkville",
    "lng": -86.88867475814068,
    "lat": 34.36738603516195
  },
  {
    "name": "Fall City",
    "lng": -121.90205609638876,
    "lat": 47.56687403011341
  },
  {
    "name": "Fall Creek",
    "lng": -95.26051610068409,
    "lat": 29.92756118361663
  },
  {
    "name": "Fall Creek",
    "lng": -91.27466800116035,
    "lat": 44.761960079196264
  },
  {
    "name": "Fall Creek",
    "lng": -91.26013064367298,
    "lat": 44.77685801738543
  },
  {
    "name": "Fall River",
    "lng": -96.0285421650674,
    "lat": 37.60772808841319
  },
  {
    "name": "Fall River",
    "lng": -89.04538019988516,
    "lat": 43.385615449865995
  },
  {
    "name": "Falling Spring",
    "lng": -80.35751493334858,
    "lat": 37.99264978054436
  },
  {
    "name": "Fallis",
    "lng": -97.11837461318355,
    "lat": 35.75036319604134
  },
  {
    "name": "Fallon",
    "lng": -118.77807503498921,
    "lat": 39.47408684022789
  },
  {
    "name": "Falls Church",
    "lng": -77.17514681873325,
    "lat": 38.884706684810176
  },
  {
    "name": "Falls City",
    "lng": -98.02190428689971,
    "lat": 28.981716995478084
  },
  {
    "name": "Falls City",
    "lng": -95.59957527216567,
    "lat": 40.06531750509895
  },
  {
    "name": "Fallston",
    "lng": -81.50225958632902,
    "lat": 35.428944658739816
  },
  {
    "name": "Falmouth",
    "lng": -84.33402924322226,
    "lat": 38.67394171235915
  },
  {
    "name": "Falmouth",
    "lng": -84.32904955982438,
    "lat": 38.657603815663705
  },
  {
    "name": "Falmouth",
    "lng": -70.27181609121219,
    "lat": 43.74258088481325
  },
  {
    "name": "False Pass",
    "lng": -163.3366646994364,
    "lat": 54.8387121906751
  },
  {
    "name": "Fanshawe",
    "lng": -94.85605933105401,
    "lat": 34.973198704493925
  },
  {
    "name": "Farber",
    "lng": -91.5762208428183,
    "lat": 39.274599817474524
  },
  {
    "name": "Fargo",
    "lng": -99.62212576946224,
    "lat": 36.3742753639909
  },
  {
    "name": "Fargo",
    "lng": -96.82754941017264,
    "lat": 46.867248958484154
  },
  {
    "name": "Fargo",
    "lng": -91.1764361470294,
    "lat": 34.95365362653136
  },
  {
    "name": "Fargo",
    "lng": -82.5722176127059,
    "lat": 30.688347051481784
  },
  {
    "name": "Faribault",
    "lng": -93.30353446175889,
    "lat": 44.300662294242336
  },
  {
    "name": "Faribault",
    "lng": -93.28710798544341,
    "lat": 44.35983365542001
  },
  {
    "name": "Faribault",
    "lng": -93.27866030137798,
    "lat": 44.298519184322
  },
  {
    "name": "Farina",
    "lng": -88.77958689890481,
    "lat": 38.829995008743325
  },
  {
    "name": "Farley",
    "lng": -94.82844079669086,
    "lat": 39.288759621094606
  },
  {
    "name": "Farley",
    "lng": -91.00920270000739,
    "lat": 42.44374818075658
  },
  {
    "name": "Farmer",
    "lng": -97.68903287164575,
    "lat": 43.72530500020177
  },
  {
    "name": "Farmer City",
    "lng": -88.64233037197226,
    "lat": 40.24796445279447
  },
  {
    "name": "Farmers Branch",
    "lng": -96.88056253931234,
    "lat": 32.92716432880375
  },
  {
    "name": "Farmersburg",
    "lng": -91.36675514270715,
    "lat": 42.95915095385717
  },
  {
    "name": "Farmersburg",
    "lng": -87.38072821321008,
    "lat": 39.252518755271495
  },
  {
    "name": "Farmersville",
    "lng": -119.21773695833043,
    "lat": 36.28214734369459
  },
  {
    "name": "Farmersville",
    "lng": -119.20807067115169,
    "lat": 36.30587385488702
  },
  {
    "name": "Farmersville",
    "lng": -89.65313508437373,
    "lat": 39.44245863030956
  },
  {
    "name": "Farmersville",
    "lng": -89.64322506568833,
    "lat": 39.4439412410007
  },
  {
    "name": "Farmersville",
    "lng": -89.64198782190583,
    "lat": 39.446315603749255
  },
  {
    "name": "Farmersville",
    "lng": -84.42766583141332,
    "lat": 39.67901862942241
  },
  {
    "name": "Farmerville",
    "lng": -92.40140448055672,
    "lat": 32.775648811353165
  },
  {
    "name": "Farmingdale",
    "lng": -73.4460531491764,
    "lat": 40.73357569808969
  },
  {
    "name": "Farmingdale",
    "lng": -69.8213021906126,
    "lat": 44.25882590086501
  },
  {
    "name": "Farmington",
    "lng": -117.04641320830761,
    "lat": 47.08876775745907
  },
  {
    "name": "Farmington",
    "lng": -111.90643213699536,
    "lat": 40.9845161326065
  },
  {
    "name": "Farmington",
    "lng": -108.18230412207123,
    "lat": 36.755466575723304
  },
  {
    "name": "Farmington",
    "lng": -94.25369977710243,
    "lat": 36.03698746702289
  },
  {
    "name": "Farmington",
    "lng": -93.1686650659561,
    "lat": 44.65723554602477
  },
  {
    "name": "Farmington",
    "lng": -91.73892721073776,
    "lat": 40.63895309113247
  },
  {
    "name": "Farmington",
    "lng": -90.43017181560663,
    "lat": 37.78296435663866
  },
  {
    "name": "Farmington",
    "lng": -90.00324842711927,
    "lat": 40.69716870530545
  },
  {
    "name": "Farmington",
    "lng": -88.44316524209778,
    "lat": 34.92243366960305
  },
  {
    "name": "Farmington",
    "lng": -83.37837118297273,
    "lat": 42.46138587965353
  },
  {
    "name": "Farmington",
    "lng": -80.25137340431009,
    "lat": 39.511971773292956
  },
  {
    "name": "Farmington",
    "lng": -72.8408756505797,
    "lat": 41.72878138378936
  },
  {
    "name": "Farmington",
    "lng": -71.07581476473965,
    "lat": 43.36321887283946
  },
  {
    "name": "Farmington",
    "lng": -70.14100120631016,
    "lat": 44.676038396819706
  },
  {
    "name": "Farmington Hills",
    "lng": -83.37711647857697,
    "lat": 42.48601267351109
  },
  {
    "name": "Farmland",
    "lng": -85.12733359149703,
    "lat": 40.18955911211663
  },
  {
    "name": "Farmville",
    "lng": -78.40006646218744,
    "lat": 37.29565998617606
  },
  {
    "name": "Farmville",
    "lng": -77.63532142970342,
    "lat": 35.59709769402916
  },
  {
    "name": "Farmville",
    "lng": -77.60522475707224,
    "lat": 35.61745693508455
  },
  {
    "name": "Farmville",
    "lng": -77.59242367352846,
    "lat": 35.595178331909764
  },
  {
    "name": "Farmville",
    "lng": -77.5753294340667,
    "lat": 35.57308623880676
  },
  {
    "name": "Farmville",
    "lng": -77.57385748866166,
    "lat": 35.57052550801127
  },
  {
    "name": "Farmville",
    "lng": -77.56996156709663,
    "lat": 35.57118634052947
  },
  {
    "name": "Farmville",
    "lng": -77.56439106369724,
    "lat": 35.613428553044486
  },
  {
    "name": "Farmville",
    "lng": -77.56069576259488,
    "lat": 35.58942983093576
  },
  {
    "name": "Farmville",
    "lng": -77.56040676580791,
    "lat": 35.58484295801106
  },
  {
    "name": "Farmville",
    "lng": -77.55738171240942,
    "lat": 35.59963321783871
  },
  {
    "name": "Farnam",
    "lng": -100.21549258725427,
    "lat": 40.70649374374561
  },
  {
    "name": "Farnham",
    "lng": -79.0808592474528,
    "lat": 42.59506510175449
  },
  {
    "name": "Farnhamville",
    "lng": -94.4077613078248,
    "lat": 42.276798781499245
  },
  {
    "name": "Farr West",
    "lng": -112.03175471766983,
    "lat": 41.30160593698202
  },
  {
    "name": "Farragut",
    "lng": -95.48070715552406,
    "lat": 40.720212758118606
  },
  {
    "name": "Farragut",
    "lng": -84.18205691456416,
    "lat": 35.87341685879118
  },
  {
    "name": "Farrell",
    "lng": -80.49720730663628,
    "lat": 41.211284325745964
  },
  {
    "name": "Farwell",
    "lng": -103.03728196788785,
    "lat": 34.38559973694437
  },
  {
    "name": "Farwell",
    "lng": -98.62805507902273,
    "lat": 41.21562300188633
  },
  {
    "name": "Farwell",
    "lng": -95.61887528438619,
    "lat": 45.75240765277079
  },
  {
    "name": "Farwell",
    "lng": -84.86774028487979,
    "lat": 43.83626078735044
  },
  {
    "name": "Fate",
    "lng": -96.38734787596269,
    "lat": 32.94243147722044
  },
  {
    "name": "Faulkton",
    "lng": -99.12717033196893,
    "lat": 45.034156153075386
  },
  {
    "name": "Faunsdale",
    "lng": -87.59310278510938,
    "lat": 32.457951219543716
  },
  {
    "name": "Fawn Grove",
    "lng": -76.4521137799447,
    "lat": 39.73111624220078
  },
  {
    "name": "Faxon",
    "lng": -98.57936478433284,
    "lat": 34.46026625090271
  },
  {
    "name": "Fayette",
    "lng": -111.85419099356697,
    "lat": 39.22463722103051
  },
  {
    "name": "Fayette",
    "lng": -92.68573038765835,
    "lat": 39.14702206843958
  },
  {
    "name": "Fayette",
    "lng": -91.80337084456968,
    "lat": 42.84152104452073
  },
  {
    "name": "Fayette",
    "lng": -91.06206008987817,
    "lat": 31.712206895684865
  },
  {
    "name": "Fayette",
    "lng": -87.83073115705461,
    "lat": 33.69431713277927
  },
  {
    "name": "Fayette",
    "lng": -84.32833175674557,
    "lat": 41.67285245663258
  },
  {
    "name": "Fayette",
    "lng": -70.0701363000372,
    "lat": 44.437321736551645
  },
  {
    "name": "Fayetteville",
    "lng": -94.16664423718007,
    "lat": 36.072222140949016
  },
  {
    "name": "Fayetteville",
    "lng": -94.1309999517248,
    "lat": 35.99675259710323
  },
  {
    "name": "Fayetteville",
    "lng": -89.7958621342241,
    "lat": 38.37767794792274
  },
  {
    "name": "Fayetteville",
    "lng": -86.56259834712303,
    "lat": 35.15025235653748
  },
  {
    "name": "Fayetteville",
    "lng": -84.47078556901529,
    "lat": 33.4500109180726
  },
  {
    "name": "Fayetteville",
    "lng": -83.93109554772201,
    "lat": 39.18559002093168
  },
  {
    "name": "Fayetteville",
    "lng": -81.10949648100555,
    "lat": 38.045000134251126
  },
  {
    "name": "Fayetteville",
    "lng": -81.10134833082584,
    "lat": 38.086326388328295
  },
  {
    "name": "Fayetteville",
    "lng": -79.00782119924823,
    "lat": 34.99864754511761
  },
  {
    "name": "Fayetteville",
    "lng": -78.95327853643145,
    "lat": 35.06159281218278
  },
  {
    "name": "Fayetteville",
    "lng": -78.93607801601705,
    "lat": 35.11117531357778
  },
  {
    "name": "Fayetteville",
    "lng": -78.91839767112381,
    "lat": 35.01415577994038
  },
  {
    "name": "Fayetteville",
    "lng": -78.90601761129611,
    "lat": 34.99633629193747
  },
  {
    "name": "Fayetteville",
    "lng": -78.90437074349013,
    "lat": 35.01452500985524
  },
  {
    "name": "Fayetteville",
    "lng": -78.88372635310007,
    "lat": 35.1599600217153
  },
  {
    "name": "Fayetteville",
    "lng": -78.87920858420917,
    "lat": 35.005014442193655
  },
  {
    "name": "Fayetteville",
    "lng": -78.87857146458018,
    "lat": 34.98982541983966
  },
  {
    "name": "Fayetteville",
    "lng": -78.82842842999798,
    "lat": 35.09973811622022
  },
  {
    "name": "Fayetteville",
    "lng": -78.82517404689511,
    "lat": 35.04642411534227
  },
  {
    "name": "Fayetteville",
    "lng": -78.7843202252556,
    "lat": 35.03174001380061
  },
  {
    "name": "Fayetteville",
    "lng": -75.99848249838308,
    "lat": 43.030777938210306
  },
  {
    "name": "Feather Sound",
    "lng": -82.65454731354791,
    "lat": 27.920328884121407
  },
  {
    "name": "Federal Dam",
    "lng": -94.2190701823026,
    "lat": 47.24069993573841
  },
  {
    "name": "Federal Heights",
    "lng": -105.01541623932182,
    "lat": 39.864999764994714
  },
  {
    "name": "Federal Way",
    "lng": -122.33597364487751,
    "lat": 47.3089784946252
  },
  {
    "name": "Federalsburg",
    "lng": -75.7729428731172,
    "lat": 38.69266520137288
  },
  {
    "name": "Felicity",
    "lng": -84.09848848918729,
    "lat": 38.83894243153838
  },
  {
    "name": "Fellsmere",
    "lng": -80.61582185459956,
    "lat": 27.738948412931318
  },
  {
    "name": "Felsenthal",
    "lng": -92.15333677484813,
    "lat": 33.058836601318184
  },
  {
    "name": "Felton",
    "lng": -96.5056181706035,
    "lat": 47.07490330020569
  },
  {
    "name": "Felton",
    "lng": -76.56113357224332,
    "lat": 39.857115205869405
  },
  {
    "name": "Fennimore",
    "lng": -90.64919374207922,
    "lat": 42.979313391791244
  },
  {
    "name": "Fennville",
    "lng": -86.10529024713657,
    "lat": 42.59437679553878
  },
  {
    "name": "Fenton",
    "lng": -94.42796830873311,
    "lat": 43.21815754231125
  },
  {
    "name": "Fenton",
    "lng": -92.91642097045755,
    "lat": 30.363768892741124
  },
  {
    "name": "Fenton",
    "lng": -90.44883908822952,
    "lat": 38.52762058316475
  },
  {
    "name": "Fenton",
    "lng": -83.71432867837234,
    "lat": 42.799333349140724
  },
  {
    "name": "Fenwood",
    "lng": -90.01454274193257,
    "lat": 44.865784428689054
  },
  {
    "name": "Ferdinand",
    "lng": -116.39000836980443,
    "lat": 46.151915654322444
  },
  {
    "name": "Ferdinand",
    "lng": -86.86317538876352,
    "lat": 38.22644345711951
  },
  {
    "name": "Fergus Falls",
    "lng": -96.07603360900737,
    "lat": 46.285420301048234
  },
  {
    "name": "Ferguson",
    "lng": -92.86303923962234,
    "lat": 41.9384826465571
  },
  {
    "name": "Ferguson",
    "lng": -90.29494687810701,
    "lat": 38.74901644143191
  },
  {
    "name": "Ferguson",
    "lng": -84.59484484503508,
    "lat": 37.04960655345916
  },
  {
    "name": "Ferguson",
    "lng": -84.59375465210968,
    "lat": 37.06718875771198
  },
  {
    "name": "Fern Forest CDP",
    "lng": -155.13217167448286,
    "lat": 19.46478997430019
  },
  {
    "name": "Fernan Lake Village",
    "lng": -116.74718070609678,
    "lat": 47.6725818457458
  },
  {
    "name": "Ferndale",
    "lng": -124.26139306784819,
    "lat": 40.57856621381467
  },
  {
    "name": "Ferndale",
    "lng": -122.58970358275872,
    "lat": 48.8511651409119
  },
  {
    "name": "Ferndale",
    "lng": -83.13134594871876,
    "lat": 42.45924112704722
  },
  {
    "name": "Fernley",
    "lng": -119.3129251154121,
    "lat": 39.526389907919764
  },
  {
    "name": "Fernley",
    "lng": -119.31255928377595,
    "lat": 39.55428333942731
  },
  {
    "name": "Fernley",
    "lng": -119.29533328036756,
    "lat": 39.5120742362881
  },
  {
    "name": "Fernley",
    "lng": -119.19099891812102,
    "lat": 39.56624276119316
  },
  {
    "name": "Ferrelview",
    "lng": -94.66540724140313,
    "lat": 39.31425041905824
  },
  {
    "name": "Ferriday",
    "lng": -91.55601091632852,
    "lat": 31.63446083262904
  },
  {
    "name": "Ferris",
    "lng": -96.67060570764613,
    "lat": 32.538587691479925
  },
  {
    "name": "Ferris",
    "lng": -96.61347998570687,
    "lat": 32.53535381171118
  },
  {
    "name": "Ferris",
    "lng": -91.16834016268851,
    "lat": 40.46954576782722
  },
  {
    "name": "Ferron",
    "lng": -111.13371514882225,
    "lat": 39.09134388731452
  },
  {
    "name": "Ferrysburg",
    "lng": -86.22258717776644,
    "lat": 43.08507611176997
  },
  {
    "name": "Ferryville",
    "lng": -91.09034292842301,
    "lat": 43.35011426642319
  },
  {
    "name": "Fertile",
    "lng": -96.28940182876708,
    "lat": 47.53397847799995
  },
  {
    "name": "Fertile",
    "lng": -93.42322147712405,
    "lat": 43.26210107476056
  },
  {
    "name": "Fessenden",
    "lng": -99.62702459057148,
    "lat": 47.6492045654833
  },
  {
    "name": "Festus",
    "lng": -90.41580444046835,
    "lat": 38.19909500657747
  },
  {
    "name": "Festus",
    "lng": -90.40926814359884,
    "lat": 38.22062063361112
  },
  {
    "name": "Fidelity",
    "lng": -94.30930948714622,
    "lat": 37.08189437118562
  },
  {
    "name": "Fidelity",
    "lng": -90.16423843900057,
    "lat": 39.15462052205297
  },
  {
    "name": "Fielding",
    "lng": -112.11714032490029,
    "lat": 41.81185268438
  },
  {
    "name": "Fieldon",
    "lng": -90.49956678794659,
    "lat": 39.10869710333783
  },
  {
    "name": "Fife",
    "lng": -122.35184347225625,
    "lat": 47.23246769939788
  },
  {
    "name": "Fife Lake",
    "lng": -85.35411731729755,
    "lat": 44.574862848742455
  },
  {
    "name": "Fifty Lakes",
    "lng": -94.14375049709403,
    "lat": 46.72462209260196
  },
  {
    "name": "Fifty Lakes",
    "lng": -94.08820874126943,
    "lat": 46.76272074935726
  },
  {
    "name": "Fifty-Six",
    "lng": -92.23075238553892,
    "lat": 35.962841244144435
  },
  {
    "name": "Fifty-Six",
    "lng": -92.2306737433895,
    "lat": 35.9625532996396
  },
  {
    "name": "Filer",
    "lng": -114.61147149150739,
    "lat": 42.56789016741349
  },
  {
    "name": "Filley",
    "lng": -96.53466995803387,
    "lat": 40.287887503716895
  },
  {
    "name": "Filley",
    "lng": -96.53420231389151,
    "lat": 40.285063325703774
  },
  {
    "name": "Filley",
    "lng": -96.53102664980692,
    "lat": 40.28873913569782
  },
  {
    "name": "Fillmore",
    "lng": -118.9169175418649,
    "lat": 34.399435933279584
  },
  {
    "name": "Fillmore",
    "lng": -112.33865539064077,
    "lat": 38.96409845665784
  },
  {
    "name": "Fillmore",
    "lng": -112.3236478794997,
    "lat": 38.99549308328153
  },
  {
    "name": "Fillmore",
    "lng": -94.97314620474386,
    "lat": 40.02530180274682
  },
  {
    "name": "Fillmore",
    "lng": -89.27851120777532,
    "lat": 39.11536988150712
  },
  {
    "name": "Fillmore",
    "lng": -86.75317227330456,
    "lat": 39.66972501571572
  },
  {
    "name": "Fincastle",
    "lng": -85.5415782465515,
    "lat": 38.30832976128186
  },
  {
    "name": "Fincastle",
    "lng": -79.87494669179897,
    "lat": 37.500494857477825
  },
  {
    "name": "Findlay",
    "lng": -88.7545932224737,
    "lat": 39.52234116171245
  },
  {
    "name": "Findlay",
    "lng": -83.63797535398707,
    "lat": 41.04582022429859
  },
  {
    "name": "Fingal",
    "lng": -97.79782241975383,
    "lat": 46.76843694300488
  },
  {
    "name": "Fingal",
    "lng": -97.79298677085934,
    "lat": 46.762328892556184
  },
  {
    "name": "Finger",
    "lng": -88.61737115983064,
    "lat": 35.35686081628
  },
  {
    "name": "Finlayson",
    "lng": -92.92737705437709,
    "lat": 46.20539407445155
  },
  {
    "name": "Finley",
    "lng": -97.8373296234023,
    "lat": 47.51251051645957
  },
  {
    "name": "Fircrest",
    "lng": -122.5155299970948,
    "lat": 47.23063297786176
  },
  {
    "name": "Firebaugh",
    "lng": -120.45334828451432,
    "lat": 36.853164788424984
  },
  {
    "name": "Firestone",
    "lng": -104.98801458258995,
    "lat": 40.166878963879356
  },
  {
    "name": "Firestone",
    "lng": -104.97840299155926,
    "lat": 40.14896794974026
  },
  {
    "name": "Firestone",
    "lng": -104.95640411549915,
    "lat": 40.1304421492172
  },
  {
    "name": "Firestone",
    "lng": -104.93707011515579,
    "lat": 40.144461759810575
  },
  {
    "name": "Firestone",
    "lng": -104.93615749984414,
    "lat": 40.144316362497925
  },
  {
    "name": "Firestone",
    "lng": -104.9112712193207,
    "lat": 40.17126288463996
  },
  {
    "name": "Firth",
    "lng": -112.1834539495163,
    "lat": 43.3057873557706
  },
  {
    "name": "Firth",
    "lng": -96.60504487627315,
    "lat": 40.535096707352324
  },
  {
    "name": "Fisher",
    "lng": -96.79947468076915,
    "lat": 47.79918096031933
  },
  {
    "name": "Fisher",
    "lng": -93.45980222969882,
    "lat": 31.49330563419328
  },
  {
    "name": "Fisher",
    "lng": -90.97292893563926,
    "lat": 35.49046220275205
  },
  {
    "name": "Fisher",
    "lng": -88.35031189334981,
    "lat": 40.31568205475655
  },
  {
    "name": "Fishers",
    "lng": -85.96740943918736,
    "lat": 39.958885607841715
  },
  {
    "name": "Fishkill",
    "lng": -73.89383666899958,
    "lat": 41.533630983590456
  },
  {
    "name": "Fisk",
    "lng": -90.20760030445206,
    "lat": 36.782641746226155
  },
  {
    "name": "Fitchburg",
    "lng": -89.42545121575671,
    "lat": 42.98589616785033
  },
  {
    "name": "Fitchburg",
    "lng": -71.81560295790615,
    "lat": 42.5912574084132
  },
  {
    "name": "Fithian",
    "lng": -87.8750145660941,
    "lat": 40.114358777545306
  },
  {
    "name": "Fitzgerald",
    "lng": -83.27340237251013,
    "lat": 31.68992910361634
  },
  {
    "name": "Fitzgerald",
    "lng": -83.25376986914624,
    "lat": 31.73217892667115
  },
  {
    "name": "Fitzgerald",
    "lng": -83.25366937838261,
    "lat": 31.729783701768262
  },
  {
    "name": "Fitzgerald",
    "lng": -83.25152610379959,
    "lat": 31.71305385578448
  },
  {
    "name": "Fitzhugh",
    "lng": -96.76652945022502,
    "lat": 34.65809752451229
  },
  {
    "name": "Five Points",
    "lng": -85.35159194552195,
    "lat": 33.01731181708903
  },
  {
    "name": "Flagler",
    "lng": -103.06622125112078,
    "lat": 39.294585471612606
  },
  {
    "name": "Flagler Beach",
    "lng": -81.13263976846854,
    "lat": 29.475769554188407
  },
  {
    "name": "Flagler Beach",
    "lng": -81.10641687716638,
    "lat": 29.430821444935795
  },
  {
    "name": "Flagler Beach",
    "lng": -81.10601366532556,
    "lat": 29.4249660663694
  },
  {
    "name": "Flagstaff",
    "lng": -111.61751983197138,
    "lat": 35.18645015214031
  },
  {
    "name": "Flanagan",
    "lng": -88.8596690945316,
    "lat": 40.87703113437639
  },
  {
    "name": "Flandreau",
    "lng": -96.59780978043277,
    "lat": 44.04660383978273
  },
  {
    "name": "Flasher",
    "lng": -101.23248239185388,
    "lat": 46.4521247838924
  },
  {
    "name": "Flat Rock",
    "lng": -87.67337507342727,
    "lat": 38.903684848497754
  },
  {
    "name": "Flat Rock",
    "lng": -83.27160826660227,
    "lat": 42.099259608729724
  },
  {
    "name": "Flat Rock",
    "lng": -82.45255664145091,
    "lat": 35.267565535630936
  },
  {
    "name": "Flatwoods",
    "lng": -82.72900905992027,
    "lat": 38.521165647169795
  },
  {
    "name": "Flatwoods",
    "lng": -80.65259918467116,
    "lat": 38.72055934334088
  },
  {
    "name": "Flaxton",
    "lng": -102.39325970884038,
    "lat": 48.89745077033722
  },
  {
    "name": "Flaxville",
    "lng": -105.17445659218853,
    "lat": 48.80388864242085
  },
  {
    "name": "Fleetwood",
    "lng": -75.82102257042372,
    "lat": 40.45649251047033
  },
  {
    "name": "Fleischmanns",
    "lng": -74.53400800947374,
    "lat": 42.155458586911124
  },
  {
    "name": "Fleming",
    "lng": -102.83944314405066,
    "lat": 40.68177129300781
  },
  {
    "name": "Fleming",
    "lng": -94.05360737503291,
    "lat": 39.19573338538589
  },
  {
    "name": "Fleming-Neon",
    "lng": -82.70175820233953,
    "lat": 37.19490064910019
  },
  {
    "name": "Flemingsburg",
    "lng": -83.74318366107582,
    "lat": 38.41265526162815
  },
  {
    "name": "Flemingsburg",
    "lng": -83.73284121742643,
    "lat": 38.42315329258646
  },
  {
    "name": "Flemington",
    "lng": -93.5014440043963,
    "lat": 37.8038766618855
  },
  {
    "name": "Flemington",
    "lng": -81.56041953359544,
    "lat": 31.850622833594517
  },
  {
    "name": "Flemington",
    "lng": -80.12851191209698,
    "lat": 39.26661221098453
  },
  {
    "name": "Flensburg",
    "lng": -94.52998460096934,
    "lat": 45.94810122355221
  },
  {
    "name": "Fletcher",
    "lng": -98.23880031957673,
    "lat": 34.822416394095434
  },
  {
    "name": "Fletcher",
    "lng": -84.1118739441526,
    "lat": 40.14178831263143
  },
  {
    "name": "Fletcher",
    "lng": -82.51397621918709,
    "lat": 35.40823046669761
  },
  {
    "name": "Fletcher",
    "lng": -82.51351363006773,
    "lat": 35.410132397396694
  },
  {
    "name": "Fletcher",
    "lng": -82.50652566277988,
    "lat": 35.39798559498991
  },
  {
    "name": "Fletcher",
    "lng": -82.50643752043835,
    "lat": 35.43290529059022
  },
  {
    "name": "Fletcher",
    "lng": -82.5056940653048,
    "lat": 35.39490948886342
  },
  {
    "name": "Fletcher",
    "lng": -82.50378706726447,
    "lat": 35.409681473624076
  },
  {
    "name": "Fletcher",
    "lng": -82.47673167580426,
    "lat": 35.42391261786059
  },
  {
    "name": "Fletcher",
    "lng": -82.47187059404399,
    "lat": 35.420764389429564
  },
  {
    "name": "Flint Hill",
    "lng": -90.86856783481396,
    "lat": 38.86384879352414
  },
  {
    "name": "Flint Hill",
    "lng": -90.84704842097557,
    "lat": 38.845295045183036
  },
  {
    "name": "Flippin",
    "lng": -92.59221511325657,
    "lat": 36.27740219968572
  },
  {
    "name": "Flomaton",
    "lng": -87.25430480954186,
    "lat": 31.013081430114507
  },
  {
    "name": "Floodwood",
    "lng": -92.92836883372586,
    "lat": 46.920093274284476
  },
  {
    "name": "Floodwood",
    "lng": -92.92170078166477,
    "lat": 46.899257501204346
  },
  {
    "name": "Floodwood",
    "lng": -92.91661804602477,
    "lat": 46.908877272285295
  },
  {
    "name": "Floodwood",
    "lng": -92.91581365162337,
    "lat": 46.929233007244946
  },
  {
    "name": "Floodwood",
    "lng": -92.91425960904137,
    "lat": 46.935477326058965
  },
  {
    "name": "Flora",
    "lng": -90.31320724200354,
    "lat": 32.54336432959422
  },
  {
    "name": "Flora",
    "lng": -88.47589166599136,
    "lat": 38.66882243162899
  },
  {
    "name": "Flora",
    "lng": -86.52235617631469,
    "lat": 40.544477473951865
  },
  {
    "name": "Floral Park",
    "lng": -73.7028320483038,
    "lat": 40.722605237154
  },
  {
    "name": "Florala",
    "lng": -86.31385325909176,
    "lat": 31.01757877508483
  },
  {
    "name": "Flordell Hills",
    "lng": -90.26487897284987,
    "lat": 38.71735706719621
  },
  {
    "name": "Florence",
    "lng": -111.41234067112968,
    "lat": 33.05459395548661
  },
  {
    "name": "Florence",
    "lng": -105.111534733525,
    "lat": 38.38236486311954
  },
  {
    "name": "Florence",
    "lng": -97.79248740249247,
    "lat": 30.83981788001382
  },
  {
    "name": "Florence",
    "lng": -97.32631340133298,
    "lat": 45.05478978932555
  },
  {
    "name": "Florence",
    "lng": -96.92934174642313,
    "lat": 38.243196288792596
  },
  {
    "name": "Florence",
    "lng": -96.92595728101286,
    "lat": 38.23461686360835
  },
  {
    "name": "Florence",
    "lng": -96.05784397780282,
    "lat": 44.239715654111315
  },
  {
    "name": "Florence",
    "lng": -96.05161351673277,
    "lat": 44.23712741496369
  },
  {
    "name": "Florence",
    "lng": -90.61069740173855,
    "lat": 39.628691642495724
  },
  {
    "name": "Florence",
    "lng": -90.12271544407963,
    "lat": 32.15610164326335
  },
  {
    "name": "Florence",
    "lng": -87.6361846595668,
    "lat": 34.84995342590536
  },
  {
    "name": "Florence",
    "lng": -84.68273443128658,
    "lat": 38.962153749022164
  },
  {
    "name": "Florence",
    "lng": -84.65627196339521,
    "lat": 38.96586443029074
  },
  {
    "name": "Florence",
    "lng": -84.64746289639805,
    "lat": 38.989203846174064
  },
  {
    "name": "Florence",
    "lng": -79.82380774462128,
    "lat": 34.201005503729846
  },
  {
    "name": "Florence",
    "lng": -79.8226034848034,
    "lat": 34.20733224517874
  },
  {
    "name": "Florence",
    "lng": -79.82173726417524,
    "lat": 34.20294999714263
  },
  {
    "name": "Florence",
    "lng": -79.82096202956421,
    "lat": 34.20702058804762
  },
  {
    "name": "Florence",
    "lng": -79.81986189325205,
    "lat": 34.20618153771515
  },
  {
    "name": "Florence",
    "lng": -79.78385912933712,
    "lat": 34.17653324106693
  },
  {
    "name": "Florence",
    "lng": -79.75420284878261,
    "lat": 34.22247793816389
  },
  {
    "name": "Florence",
    "lng": -79.75230697462058,
    "lat": 34.229491742077045
  },
  {
    "name": "Floresville",
    "lng": -98.16303710687059,
    "lat": 29.140011979934805
  },
  {
    "name": "Florida",
    "lng": -91.79023890433608,
    "lat": 39.49265354879888
  },
  {
    "name": "Florida",
    "lng": -84.20021066974246,
    "lat": 41.323787404209654
  },
  {
    "name": "Florida",
    "lng": -82.88121701288338,
    "lat": 24.666249186928084
  },
  {
    "name": "Florida",
    "lng": -82.5632132514028,
    "lat": 28.486350186191185
  },
  {
    "name": "Florida",
    "lng": -74.35397721881995,
    "lat": 41.33050939263467
  },
  {
    "name": "Florida",
    "lng": -73.0145087963512,
    "lat": 42.68036447687157
  },
  {
    "name": "Florida City",
    "lng": -80.46845643809289,
    "lat": 25.44183657397943
  },
  {
    "name": "Florida Ridge",
    "lng": -80.38472181390024,
    "lat": 27.580554656000594
  },
  {
    "name": "Florien",
    "lng": -93.45836876863687,
    "lat": 31.44800157036576
  },
  {
    "name": "Floris",
    "lng": -92.33269979906572,
    "lat": 40.86479113534067
  },
  {
    "name": "Florissant",
    "lng": -90.32666359216424,
    "lat": 38.79956361649645
  },
  {
    "name": "Flossmoor",
    "lng": -87.68570312723905,
    "lat": 41.53910472292523
  },
  {
    "name": "Flovilla",
    "lng": -83.9029022274835,
    "lat": 33.25240664734251
  },
  {
    "name": "Flower Hill",
    "lng": -73.67541849839765,
    "lat": 40.807484846445945
  },
  {
    "name": "Flower Mound",
    "lng": -97.11402219603269,
    "lat": 33.03376911917769
  },
  {
    "name": "Flowery Branch",
    "lng": -83.94915237900832,
    "lat": 34.1732337655689
  },
  {
    "name": "Flowery Branch",
    "lng": -83.92770914294694,
    "lat": 34.205060337705525
  },
  {
    "name": "Flowery Branch",
    "lng": -83.92615296538018,
    "lat": 34.21254607506536
  },
  {
    "name": "Flowery Branch",
    "lng": -83.92316898170627,
    "lat": 34.13420878260389
  },
  {
    "name": "Flowery Branch",
    "lng": -83.91182831522659,
    "lat": 34.1747088469067
  },
  {
    "name": "Floyd",
    "lng": -103.55129494385773,
    "lat": 34.22916225727838
  },
  {
    "name": "Floyd",
    "lng": -92.7390209377926,
    "lat": 43.12788741272398
  },
  {
    "name": "Floyd",
    "lng": -80.31842167075878,
    "lat": 36.911794199949384
  },
  {
    "name": "Floydada",
    "lng": -101.33678584079667,
    "lat": 33.983455255918905
  },
  {
    "name": "Flushing",
    "lng": -83.83972931842877,
    "lat": 43.063650431535585
  },
  {
    "name": "Flushing",
    "lng": -81.06451497610782,
    "lat": 40.14812053857349
  },
  {
    "name": "Foley",
    "lng": -93.9095196978291,
    "lat": 45.66356356871125
  },
  {
    "name": "Foley",
    "lng": -90.74123692868596,
    "lat": 39.04576535364001
  },
  {
    "name": "Foley",
    "lng": -87.7484880861734,
    "lat": 30.409170475471125
  },
  {
    "name": "Foley",
    "lng": -87.74464787974877,
    "lat": 30.402522036380777
  },
  {
    "name": "Foley",
    "lng": -87.71764937539923,
    "lat": 30.396604162804113
  },
  {
    "name": "Foley",
    "lng": -87.68944650429412,
    "lat": 30.438256088064684
  },
  {
    "name": "Foley",
    "lng": -87.68625534770793,
    "lat": 30.33202831354142
  },
  {
    "name": "Foley",
    "lng": -87.67540046500282,
    "lat": 30.3445459856
  },
  {
    "name": "Foley",
    "lng": -87.6651918918708,
    "lat": 30.40176793174144
  },
  {
    "name": "Foley",
    "lng": -87.61468515958103,
    "lat": 30.360198749396346
  },
  {
    "name": "Foley",
    "lng": -87.61428202156765,
    "lat": 30.356921222970197
  },
  {
    "name": "Foley",
    "lng": -87.61327735695957,
    "lat": 30.35849162789616
  },
  {
    "name": "Foley",
    "lng": -87.61216178942989,
    "lat": 30.357322382393882
  },
  {
    "name": "Foley",
    "lng": -87.61184526596494,
    "lat": 30.361064148680065
  },
  {
    "name": "Foley",
    "lng": -87.60983670548437,
    "lat": 30.347426413355734
  },
  {
    "name": "Folkston",
    "lng": -82.00790928780735,
    "lat": 30.838073182318247
  },
  {
    "name": "Follansbee",
    "lng": -80.59766198246733,
    "lat": 40.338319846479486
  },
  {
    "name": "Folly Beach",
    "lng": -79.94133450206787,
    "lat": 32.66008254856591
  },
  {
    "name": "Folsom",
    "lng": -103.9186086316365,
    "lat": 36.84826582871368
  },
  {
    "name": "Folsom",
    "lng": -90.1944504883664,
    "lat": 30.630372361054118
  },
  {
    "name": "Fonda",
    "lng": -94.8455222363103,
    "lat": 42.58170571428358
  },
  {
    "name": "Fonda",
    "lng": -74.37201198372588,
    "lat": 42.95360404917838
  },
  {
    "name": "Fontana",
    "lng": -117.46001303837105,
    "lat": 34.097241594310226
  },
  {
    "name": "Fontana",
    "lng": -94.84384484269616,
    "lat": 38.42383313298371
  },
  {
    "name": "Fontana",
    "lng": -94.82233586630304,
    "lat": 38.43116490244609
  },
  {
    "name": "Fontana-on-Geneva Lake",
    "lng": -88.5662803711629,
    "lat": 42.546679768449216
  },
  {
    "name": "Fontanelle",
    "lng": -94.56026160789389,
    "lat": 41.29002121465088
  },
  {
    "name": "Foosland",
    "lng": -88.42909809379562,
    "lat": 40.36107999762309
  },
  {
    "name": "Footville",
    "lng": -89.20930264696781,
    "lat": 42.6722128438459
  },
  {
    "name": "Forada",
    "lng": -95.35669760406329,
    "lat": 45.7884230743471
  },
  {
    "name": "Foraker",
    "lng": -96.56911550175366,
    "lat": 36.87215956283483
  },
  {
    "name": "Forbes",
    "lng": -98.78216127405244,
    "lat": 45.94248269253805
  },
  {
    "name": "Ford",
    "lng": -99.75383878782382,
    "lat": 37.63662929879835
  },
  {
    "name": "Ford Heights",
    "lng": -87.58348035524598,
    "lat": 41.50912348391092
  },
  {
    "name": "Ford Heights",
    "lng": -87.55914024526953,
    "lat": 41.514647375532114
  },
  {
    "name": "Fordland",
    "lng": -92.94268923959154,
    "lat": 37.15684310859533
  },
  {
    "name": "Fordoche",
    "lng": -91.61805294161857,
    "lat": 30.594195221292427
  },
  {
    "name": "Fordsville",
    "lng": -86.71701615461707,
    "lat": 37.63574878317896
  },
  {
    "name": "Fordville",
    "lng": -97.79557101504624,
    "lat": 48.21659934418228
  },
  {
    "name": "Fordyce",
    "lng": -97.36277050032692,
    "lat": 42.69821813717965
  },
  {
    "name": "Fordyce",
    "lng": -92.41634561006826,
    "lat": 33.818362652689515
  },
  {
    "name": "Foreman",
    "lng": -94.39750861274611,
    "lat": 33.720019110264865
  },
  {
    "name": "Forest",
    "lng": -91.41255527825197,
    "lat": 32.792366735078595
  },
  {
    "name": "Forest",
    "lng": -89.47611968273145,
    "lat": 32.35952423770978
  },
  {
    "name": "Forest",
    "lng": -83.5119135604257,
    "lat": 40.80291444923741
  },
  {
    "name": "Forest Acres",
    "lng": -80.9720459818814,
    "lat": 34.032003496206514
  },
  {
    "name": "Forest Acres",
    "lng": -80.95220050740522,
    "lat": 34.04759954417
  },
  {
    "name": "Forest Acres",
    "lng": -80.95117272779987,
    "lat": 34.04249051908438
  },
  {
    "name": "Forest Acres",
    "lng": -80.9460388159672,
    "lat": 34.04830407327405
  },
  {
    "name": "Forest Acres",
    "lng": -80.94590116690615,
    "lat": 34.04573663476519
  },
  {
    "name": "Forest City",
    "lng": -95.18842328842543,
    "lat": 39.98284458019943
  },
  {
    "name": "Forest City",
    "lng": -93.6366033752296,
    "lat": 43.25710313005487
  },
  {
    "name": "Forest City",
    "lng": -89.83249649583486,
    "lat": 40.37215473376013
  },
  {
    "name": "Forest City",
    "lng": -81.87150066380046,
    "lat": 35.33456255426357
  },
  {
    "name": "Forest Hill",
    "lng": -97.26623733883793,
    "lat": 32.662099949642155
  },
  {
    "name": "Forest Hill",
    "lng": -92.52422590050325,
    "lat": 31.047725342529706
  },
  {
    "name": "Forest Hill",
    "lng": -92.49247500514802,
    "lat": 31.06026896577793
  },
  {
    "name": "Forest Hills",
    "lng": -86.84028027408857,
    "lat": 36.0651221691192
  },
  {
    "name": "Forest Hills",
    "lng": -85.58322808224098,
    "lat": 38.21558088437893
  },
  {
    "name": "Forest Hills",
    "lng": -83.19542265928396,
    "lat": 35.295759008036676
  },
  {
    "name": "Forest Lake",
    "lng": -92.95812824208403,
    "lat": 45.25361401584724
  },
  {
    "name": "Forest Park",
    "lng": -97.44699950443602,
    "lat": 35.51257840246419
  },
  {
    "name": "Forest Park",
    "lng": -87.81568340711922,
    "lat": 41.86831974965457
  },
  {
    "name": "Forest Park",
    "lng": -84.5258086633929,
    "lat": 39.2860204130939
  },
  {
    "name": "Forest Park",
    "lng": -84.35903266052733,
    "lat": 33.62099453644307
  },
  {
    "name": "Forest Park",
    "lng": -84.35321065265317,
    "lat": 33.60516371553865
  },
  {
    "name": "Forest River",
    "lng": -97.47054488467117,
    "lat": 48.216526331307904
  },
  {
    "name": "Foreston",
    "lng": -93.70957507519843,
    "lat": 45.73208288942712
  },
  {
    "name": "Forestville",
    "lng": -87.47876700681674,
    "lat": 44.69061081946054
  },
  {
    "name": "Forestville",
    "lng": -82.61249084691711,
    "lat": 43.65990829040952
  },
  {
    "name": "Forestville",
    "lng": -79.17554396571943,
    "lat": 42.4684633675776
  },
  {
    "name": "Forestville",
    "lng": -76.29216512138586,
    "lat": 40.6937487321562
  },
  {
    "name": "Forgan",
    "lng": -100.53777612098533,
    "lat": 36.90676830787315
  },
  {
    "name": "Foristell",
    "lng": -90.9641253819897,
    "lat": 38.79235297917669
  },
  {
    "name": "Foristell",
    "lng": -90.96282235875935,
    "lat": 38.81600034108912
  },
  {
    "name": "Foristell",
    "lng": -90.95167255731607,
    "lat": 38.79978853708373
  },
  {
    "name": "Forkland",
    "lng": -87.86708968211043,
    "lat": 32.648821493990255
  },
  {
    "name": "Forks",
    "lng": -124.38846424833189,
    "lat": 47.95366887777493
  },
  {
    "name": "Forman",
    "lng": -97.63645259036507,
    "lat": 46.10368606815915
  },
  {
    "name": "Formoso",
    "lng": -97.99317878102588,
    "lat": 39.77900792677139
  },
  {
    "name": "Forney",
    "lng": -96.45193546911972,
    "lat": 32.74478261568581
  },
  {
    "name": "Forrest",
    "lng": -88.40967824350464,
    "lat": 40.751079665034005
  },
  {
    "name": "Forrest City",
    "lng": -90.79311294265078,
    "lat": 35.01346509603551
  },
  {
    "name": "Forreston",
    "lng": -89.57825643046907,
    "lat": 42.126534648400686
  },
  {
    "name": "Forsan",
    "lng": -101.36678341746126,
    "lat": 32.11047881151214
  },
  {
    "name": "Forsyth",
    "lng": -106.67828231131287,
    "lat": 46.26598816642077
  },
  {
    "name": "Forsyth",
    "lng": -93.1096528433698,
    "lat": 36.691532737787156
  },
  {
    "name": "Forsyth",
    "lng": -88.96413479528402,
    "lat": 39.92615779529518
  },
  {
    "name": "Forsyth",
    "lng": -83.95913808758571,
    "lat": 33.05362088880496
  },
  {
    "name": "Forsyth",
    "lng": -83.95125227910954,
    "lat": 33.01882160382015
  },
  {
    "name": "Forsyth",
    "lng": -83.94608615023051,
    "lat": 33.06016801539211
  },
  {
    "name": "Forsyth",
    "lng": -83.93709998933599,
    "lat": 33.0339945796812
  },
  {
    "name": "Forsyth",
    "lng": -83.93452140853539,
    "lat": 33.015099960766136
  },
  {
    "name": "Fort Adams State Park",
    "lng": -71.33959227194863,
    "lat": 41.47470790259758
  },
  {
    "name": "Fort Ann",
    "lng": -73.49071170555703,
    "lat": 43.41460704140505
  },
  {
    "name": "Fort Atkinson",
    "lng": -91.93448245375335,
    "lat": 43.14416909908506
  },
  {
    "name": "Fort Atkinson",
    "lng": -88.84569705883837,
    "lat": 42.92318041713925
  },
  {
    "name": "Fort Atkinson",
    "lng": -88.81676710698126,
    "lat": 42.965197591896505
  },
  {
    "name": "Fort Benton",
    "lng": -110.66583908850012,
    "lat": 47.82130815966881
  },
  {
    "name": "Fort Benton",
    "lng": -110.63684856197808,
    "lat": 47.84269608619008
  },
  {
    "name": "Fort Bragg",
    "lng": -123.81483619136517,
    "lat": 39.45343765334782
  },
  {
    "name": "Fort Bragg",
    "lng": -123.81483619136515,
    "lat": 39.45343765334782
  },
  {
    "name": "Fort Bragg",
    "lng": -123.8046108285811,
    "lat": 39.4193001628879
  },
  {
    "name": "Fort Bragg",
    "lng": -123.8033883206817,
    "lat": 39.44163088193175
  },
  {
    "name": "Fort Bragg",
    "lng": -123.80338832068166,
    "lat": 39.4416308819317
  },
  {
    "name": "Fort Branch",
    "lng": -87.57383991872125,
    "lat": 38.24578194861324
  },
  {
    "name": "Fort Calhoun",
    "lng": -96.02631996974269,
    "lat": 41.45635013431917
  },
  {
    "name": "Fort Carson",
    "lng": -104.78354275981161,
    "lat": 38.740421365476855
  },
  {
    "name": "Fort Cobb",
    "lng": -98.44592937114021,
    "lat": 35.107728891998036
  },
  {
    "name": "Fort Cobb",
    "lng": -98.44282461820022,
    "lat": 35.10245124320905
  },
  {
    "name": "Fort Cobb",
    "lng": -98.43780115578757,
    "lat": 35.09764286094226
  },
  {
    "name": "Fort Coffee",
    "lng": -94.59263365213269,
    "lat": 35.26767314672763
  },
  {
    "name": "Fort Coffee",
    "lng": -94.57282379458715,
    "lat": 35.294044538298586
  },
  {
    "name": "Fort Collins",
    "lng": -105.07055126254996,
    "lat": 40.616028172914945
  },
  {
    "name": "Fort Collins",
    "lng": -105.06590993214934,
    "lat": 40.54809718989128
  },
  {
    "name": "Fort Deposit",
    "lng": -86.56637161252338,
    "lat": 31.986261249144853
  },
  {
    "name": "Fort Dodge",
    "lng": -94.17516536606992,
    "lat": 42.50986372077004
  },
  {
    "name": "Fort Edward",
    "lng": -73.58315279101755,
    "lat": 43.268806133348825
  },
  {
    "name": "Fort Fairfield",
    "lng": -67.85524851190308,
    "lat": 46.777784029130046
  },
  {
    "name": "Fort Gaines",
    "lng": -85.05299709954399,
    "lat": 31.623955789261533
  },
  {
    "name": "Fort Gay",
    "lng": -82.59509735889074,
    "lat": 38.12019558559374
  },
  {
    "name": "Fort Gibson",
    "lng": -95.25997038249781,
    "lat": 35.77670493132347
  },
  {
    "name": "Fort Jennings",
    "lng": -84.29962286370815,
    "lat": 40.906449677623165
  },
  {
    "name": "Fort Johnson",
    "lng": -74.23801601197049,
    "lat": 42.959160224740344
  },
  {
    "name": "Fort Jones",
    "lng": -122.84122470754454,
    "lat": 41.60832297944926
  },
  {
    "name": "Fort Kent",
    "lng": -68.55845296146633,
    "lat": 47.23587496399639
  },
  {
    "name": "Fort Klamath",
    "lng": -121.99464413295206,
    "lat": 42.70215924168868
  },
  {
    "name": "Fort Laramie",
    "lng": -104.51772922181199,
    "lat": 42.2133556947325
  },
  {
    "name": "Fort Lauderdale",
    "lng": -80.20158214529255,
    "lat": 26.18870729991361
  },
  {
    "name": "Fort Lauderdale",
    "lng": -80.18337708768709,
    "lat": 26.083198381532206
  },
  {
    "name": "Fort Lauderdale",
    "lng": -80.18276405809466,
    "lat": 26.08462839719644
  },
  {
    "name": "Fort Lauderdale",
    "lng": -80.1723260505029,
    "lat": 26.198560510880963
  },
  {
    "name": "Fort Lauderdale",
    "lng": -80.15614742623632,
    "lat": 26.17793974427034
  },
  {
    "name": "Fort Lauderdale",
    "lng": -80.14813041233114,
    "lat": 26.19818102119815
  },
  {
    "name": "Fort Lauderdale",
    "lng": -80.1384655102277,
    "lat": 26.13294495597986
  },
  {
    "name": "Fort Lawn",
    "lng": -80.89910946612049,
    "lat": 34.70023889945698
  },
  {
    "name": "Fort Loramie",
    "lng": -84.36950270144108,
    "lat": 40.34436163840273
  },
  {
    "name": "Fort Lupton",
    "lng": -104.79766931550338,
    "lat": 40.08017798087998
  },
  {
    "name": "Fort Madison",
    "lng": -91.35025187808978,
    "lat": 40.620971447866786
  },
  {
    "name": "Fort Meade",
    "lng": -81.82258690140472,
    "lat": 27.779192182620072
  },
  {
    "name": "Fort Meade",
    "lng": -81.7970737256777,
    "lat": 27.757395044844724
  },
  {
    "name": "Fort Meade",
    "lng": -81.79480847577067,
    "lat": 27.72814552262221
  },
  {
    "name": "Fort Mill",
    "lng": -80.93886179671293,
    "lat": 34.99850618982404
  },
  {
    "name": "Fort Mill",
    "lng": -80.91671654573186,
    "lat": 35.04710561834535
  },
  {
    "name": "Fort Mitchell",
    "lng": -84.55591596243843,
    "lat": 39.045650442002966
  },
  {
    "name": "Fort Morgan",
    "lng": -103.81379221843649,
    "lat": 40.241683278070774
  },
  {
    "name": "Fort Morgan",
    "lng": -103.79009412295679,
    "lat": 40.256030529096435
  },
  {
    "name": "Fort Myers",
    "lng": -81.8301126919882,
    "lat": 26.619555896170525
  },
  {
    "name": "Fort Myers Beach",
    "lng": -81.92074765020153,
    "lat": 26.433472785095947
  },
  {
    "name": "Fort Myers Shores",
    "lng": -81.73844914408889,
    "lat": 26.713513312423256
  },
  {
    "name": "Fort Oglethorpe",
    "lng": -85.26273819032106,
    "lat": 34.958068827426025
  },
  {
    "name": "Fort Oglethorpe",
    "lng": -85.25741502643373,
    "lat": 34.97232244795542
  },
  {
    "name": "Fort Oglethorpe",
    "lng": -85.24710262677543,
    "lat": 34.930996402819616
  },
  {
    "name": "Fort Payne",
    "lng": -85.6968920950904,
    "lat": 34.45539946407474
  },
  {
    "name": "Fort Peck",
    "lng": -106.4552705819414,
    "lat": 48.0070933676685
  },
  {
    "name": "Fort Pierre",
    "lng": -100.38345506538278,
    "lat": 44.368064555552436
  },
  {
    "name": "Fort Plain",
    "lng": -74.62774016431467,
    "lat": 42.9315950284182
  },
  {
    "name": "Fort Polk South",
    "lng": -93.21576322494188,
    "lat": 31.051100540394593
  },
  {
    "name": "Fort Ransom",
    "lng": -97.93114474862705,
    "lat": 46.52454898572745
  },
  {
    "name": "Fort Recovery",
    "lng": -84.77603012724295,
    "lat": 40.41133679723599
  },
  {
    "name": "Fort Ripley",
    "lng": -94.36303691555864,
    "lat": 46.169143657922994
  },
  {
    "name": "Fort Scott",
    "lng": -94.71731289719403,
    "lat": 37.80464286625251
  },
  {
    "name": "Fort Scott",
    "lng": -94.71458372654062,
    "lat": 37.85663759976246
  },
  {
    "name": "Fort Scott",
    "lng": -94.71314479823798,
    "lat": 37.85447121085961
  },
  {
    "name": "Fort Scott",
    "lng": -94.71047053322728,
    "lat": 37.85414386185469
  },
  {
    "name": "Fort Scott",
    "lng": -94.70947763777794,
    "lat": 37.85356946091547
  },
  {
    "name": "Fort Scott",
    "lng": -94.70707706970822,
    "lat": 37.85333498110332
  },
  {
    "name": "Fort Scott",
    "lng": -94.70503523396741,
    "lat": 37.85427096732088
  },
  {
    "name": "Fort Scott",
    "lng": -94.70377341439689,
    "lat": 37.8294536056397
  },
  {
    "name": "Fort Scott",
    "lng": -94.70309942694604,
    "lat": 37.79712794045503
  },
  {
    "name": "Fort Scott",
    "lng": -94.7026372919801,
    "lat": 37.79203492456385
  },
  {
    "name": "Fort Scott",
    "lng": -94.70251152011711,
    "lat": 37.790069844023186
  },
  {
    "name": "Fort Scott",
    "lng": -94.70029614649842,
    "lat": 37.791907788159186
  },
  {
    "name": "Fort Scott",
    "lng": -94.70005967474673,
    "lat": 37.7879706509412
  },
  {
    "name": "Fort Scott",
    "lng": -94.67652277712062,
    "lat": 37.83369397077517
  },
  {
    "name": "Fort Shawnee",
    "lng": -84.1472420630603,
    "lat": 40.684526257946224
  },
  {
    "name": "Fort Shawnee",
    "lng": -84.13112535042433,
    "lat": 40.681121795654434
  },
  {
    "name": "Fort Smith",
    "lng": -94.36950199153812,
    "lat": 35.34932052580066
  },
  {
    "name": "Fort Stockton",
    "lng": -102.88461948867953,
    "lat": 30.892718471223823
  },
  {
    "name": "Fort Stockton",
    "lng": -102.86803211402051,
    "lat": 30.879825836197604
  },
  {
    "name": "Fort Sumner",
    "lng": -104.2439859157019,
    "lat": 34.472921136304294
  },
  {
    "name": "Fort Sumner",
    "lng": -104.21861753288006,
    "lat": 34.48718929664439
  },
  {
    "name": "Fort Supply",
    "lng": -99.57348886350682,
    "lat": 36.571962805293396
  },
  {
    "name": "Fort Thomas",
    "lng": -84.45020574663596,
    "lat": 39.0804328263658
  },
  {
    "name": "Fort Towson",
    "lng": -95.29719968383965,
    "lat": 34.02436771598339
  },
  {
    "name": "Fort Valley",
    "lng": -83.88153837936073,
    "lat": 32.55221533888352
  },
  {
    "name": "Fort Valley",
    "lng": -83.7446818788138,
    "lat": 32.59464348687112
  },
  {
    "name": "Fort Walton Beach",
    "lng": -86.636010714342,
    "lat": 30.470799617339072
  },
  {
    "name": "Fort Walton Beach",
    "lng": -86.62388677981313,
    "lat": 30.41631982784783
  },
  {
    "name": "Fort Walton Beach",
    "lng": -86.61676974712377,
    "lat": 30.458557711175985
  },
  {
    "name": "Fort Walton Beach",
    "lng": -86.603249377099,
    "lat": 30.43006383262138
  },
  {
    "name": "Fort Wayne",
    "lng": -85.14353865444899,
    "lat": 41.08860557789562
  },
  {
    "name": "Fort Worth",
    "lng": -97.53567775285397,
    "lat": 32.77279696480265
  },
  {
    "name": "Fort Worth",
    "lng": -97.47062766666666,
    "lat": 32.69341833294616
  },
  {
    "name": "Fort Worth",
    "lng": -97.34719019740662,
    "lat": 32.78144478260822
  },
  {
    "name": "Fort Worth",
    "lng": -97.34339905620762,
    "lat": 33.04165940538872
  },
  {
    "name": "Fort Worth",
    "lng": -97.28232267876692,
    "lat": 32.92706443361891
  },
  {
    "name": "Fort Wright",
    "lng": -84.53580543690971,
    "lat": 39.04588166462366
  },
  {
    "name": "Fort Yates",
    "lng": -100.63018173938451,
    "lat": 46.08659886751061
  },
  {
    "name": "Fort Yukon",
    "lng": -145.24138860656794,
    "lat": 66.58024285032643
  },
  {
    "name": "Fortescue",
    "lng": -95.3176067999758,
    "lat": 40.051952594741955
  },
  {
    "name": "Fortuna",
    "lng": -124.14236134281309,
    "lat": 40.58601311604128
  },
  {
    "name": "Fortuna",
    "lng": -103.7783316057011,
    "lat": 48.90989085822847
  },
  {
    "name": "Fortville",
    "lng": -85.84763178047862,
    "lat": 39.9341610122401
  },
  {
    "name": "Foss",
    "lng": -99.17074083404337,
    "lat": 35.44984600060812
  },
  {
    "name": "Fosston",
    "lng": -95.75839129654342,
    "lat": 47.58607214814652
  },
  {
    "name": "Fosston",
    "lng": -95.73844363740214,
    "lat": 47.570731446864144
  },
  {
    "name": "Foster",
    "lng": -97.66512616429672,
    "lat": 42.274736939026454
  },
  {
    "name": "Foster",
    "lng": -97.4928213377609,
    "lat": 34.594127430026035
  },
  {
    "name": "Foster",
    "lng": -94.50786051998712,
    "lat": 38.16642701866322
  },
  {
    "name": "Foster",
    "lng": -71.73570290549091,
    "lat": 41.78891280582913
  },
  {
    "name": "Fosters Mill",
    "lng": -95.16786801010537,
    "lat": 30.048844697607137
  },
  {
    "name": "Fostoria",
    "lng": -95.15541206092325,
    "lat": 43.2417856565699
  },
  {
    "name": "Fostoria",
    "lng": -83.41316472617862,
    "lat": 41.15985846706769
  },
  {
    "name": "Fouke",
    "lng": -93.88521852227986,
    "lat": 33.26226063027389
  },
  {
    "name": "Fountain",
    "lng": -104.74476586297715,
    "lat": 38.75118264500564
  },
  {
    "name": "Fountain",
    "lng": -104.7333370274805,
    "lat": 38.655731815859525
  },
  {
    "name": "Fountain",
    "lng": -104.7102580875442,
    "lat": 38.70434649588124
  },
  {
    "name": "Fountain",
    "lng": -104.69472454035852,
    "lat": 38.684423557043964
  },
  {
    "name": "Fountain",
    "lng": -104.66308177452532,
    "lat": 38.73393152469789
  },
  {
    "name": "Fountain",
    "lng": -104.66277257531634,
    "lat": 38.579684701923114
  },
  {
    "name": "Fountain",
    "lng": -104.65290347477128,
    "lat": 38.56834145946308
  },
  {
    "name": "Fountain",
    "lng": -104.64306753159084,
    "lat": 38.723465950531676
  },
  {
    "name": "Fountain",
    "lng": -92.13422981111512,
    "lat": 43.742544878051476
  },
  {
    "name": "Fountain",
    "lng": -86.18065697924388,
    "lat": 44.04818005453438
  },
  {
    "name": "Fountain",
    "lng": -77.64231533333333,
    "lat": 35.66796666640037
  },
  {
    "name": "Fountain",
    "lng": -77.6307810800227,
    "lat": 35.67200229452545
  },
  {
    "name": "Fountain City",
    "lng": -91.69705040296122,
    "lat": 44.12017965060959
  },
  {
    "name": "Fountain City",
    "lng": -84.91901916303898,
    "lat": 39.95485452474086
  },
  {
    "name": "Fountain Green",
    "lng": -111.64129681196223,
    "lat": 39.62809492720169
  },
  {
    "name": "Fountain Hill",
    "lng": -91.85117087117096,
    "lat": 33.357483148662375
  },
  {
    "name": "Fountain Hill",
    "lng": -75.39614311598338,
    "lat": 40.602806944822234
  },
  {
    "name": "Fountain Inn",
    "lng": -82.20065536532942,
    "lat": 34.69449292541521
  },
  {
    "name": "Fountain Lake",
    "lng": -92.91424948640372,
    "lat": 34.61095118543748
  },
  {
    "name": "Fountain N' Lakes",
    "lng": -90.84925538189754,
    "lat": 38.96890639096011
  },
  {
    "name": "Fountain Run",
    "lng": -85.96025784758521,
    "lat": 36.723634864154604
  },
  {
    "name": "Fountain Valley",
    "lng": -117.9513609396613,
    "lat": 33.710513554868115
  },
  {
    "name": "Four Oaks",
    "lng": -78.43856995672807,
    "lat": 35.43668295798795
  },
  {
    "name": "Four Oaks",
    "lng": -78.41883419591946,
    "lat": 35.44995160961727
  },
  {
    "name": "Four Oaks",
    "lng": -78.4018865402935,
    "lat": 35.4604450561078
  },
  {
    "name": "Four Oaks",
    "lng": -78.39166735607019,
    "lat": 35.46202917924385
  },
  {
    "name": "Four Oaks",
    "lng": -78.38839856017161,
    "lat": 35.454261840482644
  },
  {
    "name": "Four Oaks",
    "lng": -78.38586470111458,
    "lat": 35.45316062560707
  },
  {
    "name": "Four Oaks",
    "lng": -78.38417879424813,
    "lat": 35.45964210376196
  },
  {
    "name": "Fourche",
    "lng": -92.61902238981293,
    "lat": 34.99334898285762
  },
  {
    "name": "Fowler",
    "lng": -119.67354744655704,
    "lat": 36.624237653031656
  },
  {
    "name": "Fowler",
    "lng": -119.65994162083494,
    "lat": 36.61283809167612
  },
  {
    "name": "Fowler",
    "lng": -104.02610873417602,
    "lat": 38.129671513873255
  },
  {
    "name": "Fowler",
    "lng": -100.19555458688356,
    "lat": 37.382785714347904
  },
  {
    "name": "Fowler",
    "lng": -87.31855221488574,
    "lat": 40.617152162550866
  },
  {
    "name": "Fowler",
    "lng": -84.74130722193122,
    "lat": 43.00410475682175
  },
  {
    "name": "Fowlerton",
    "lng": -85.57866157110064,
    "lat": 40.41459442108192
  },
  {
    "name": "Fowlerton",
    "lng": -85.57277122789858,
    "lat": 40.40960661774941
  },
  {
    "name": "Fowlerville",
    "lng": -84.07439584350278,
    "lat": 42.659479465169056
  },
  {
    "name": "Fox Chase",
    "lng": -85.68914639331587,
    "lat": 38.046239198310424
  },
  {
    "name": "Fox Lake",
    "lng": -88.91306628469702,
    "lat": 43.56179312623376
  },
  {
    "name": "Fox Lake",
    "lng": -88.18441024343387,
    "lat": 42.424977624663946
  },
  {
    "name": "Fox Point",
    "lng": -87.90067058072904,
    "lat": 43.15767590681628
  },
  {
    "name": "Fox River Grove",
    "lng": -88.21483265610294,
    "lat": 42.19575129388278
  },
  {
    "name": "Foxfield",
    "lng": -104.78553227708758,
    "lat": 39.58837962061662
  },
  {
    "name": "Foxfire",
    "lng": -79.57620569292045,
    "lat": 35.17270192598692
  },
  {
    "name": "Foxfire",
    "lng": -79.5688272776172,
    "lat": 35.180010589097755
  },
  {
    "name": "Foxfire",
    "lng": -79.56766926449689,
    "lat": 35.15707657035702
  },
  {
    "name": "Foxhome",
    "lng": -96.31230416885003,
    "lat": 46.27692735585934
  },
  {
    "name": "Foyil",
    "lng": -95.52139545734693,
    "lat": 36.430929483483844
  },
  {
    "name": "Framingham",
    "lng": -71.43676013573614,
    "lat": 42.30855042665788
  },
  {
    "name": "Francestown",
    "lng": -71.8153037204285,
    "lat": 42.99570579141792
  },
  {
    "name": "Francesville",
    "lng": -86.88370730698765,
    "lat": 40.98541676267692
  },
  {
    "name": "Francis",
    "lng": -111.27407448961388,
    "lat": 40.610490388694615
  },
  {
    "name": "Francis",
    "lng": -96.59245217648034,
    "lat": 34.874485068838226
  },
  {
    "name": "Francis Creek",
    "lng": -87.72124288133892,
    "lat": 44.200647252210636
  },
  {
    "name": "Francisco",
    "lng": -87.4485227502871,
    "lat": 38.3333402794721
  },
  {
    "name": "Frankford",
    "lng": -91.32093852890125,
    "lat": 39.493371118694895
  },
  {
    "name": "Frankfort",
    "lng": -98.30960858233156,
    "lat": 44.87785104579618
  },
  {
    "name": "Frankfort",
    "lng": -96.41697660260017,
    "lat": 39.70392521917825
  },
  {
    "name": "Frankfort",
    "lng": -87.90464044463413,
    "lat": 41.48778774540559
  },
  {
    "name": "Frankfort",
    "lng": -87.89316791695747,
    "lat": 41.47779993031524
  },
  {
    "name": "Frankfort",
    "lng": -87.86635548197813,
    "lat": 41.483358504947496
  },
  {
    "name": "Frankfort",
    "lng": -87.83401245489063,
    "lat": 41.495702467359735
  },
  {
    "name": "Frankfort",
    "lng": -87.83376070100225,
    "lat": 41.45158164176079
  },
  {
    "name": "Frankfort",
    "lng": -87.83060343271357,
    "lat": 41.459153741588025
  },
  {
    "name": "Frankfort",
    "lng": -87.82170371709535,
    "lat": 41.528008795174244
  },
  {
    "name": "Frankfort",
    "lng": -87.81948201303032,
    "lat": 41.5279159661877
  },
  {
    "name": "Frankfort",
    "lng": -87.81505922698047,
    "lat": 41.506108835807886
  },
  {
    "name": "Frankfort",
    "lng": -87.8109013194415,
    "lat": 41.50626208361052
  },
  {
    "name": "Frankfort",
    "lng": -87.80510111557733,
    "lat": 41.507134494870684
  },
  {
    "name": "Frankfort",
    "lng": -87.80385087490005,
    "lat": 41.50769572161489
  },
  {
    "name": "Frankfort",
    "lng": -87.77951341264851,
    "lat": 41.451175115227024
  },
  {
    "name": "Frankfort",
    "lng": -86.51077840061735,
    "lat": 40.27967900485009
  },
  {
    "name": "Frankfort",
    "lng": -86.23346912436352,
    "lat": 44.63701425823948
  },
  {
    "name": "Frankfort",
    "lng": -84.86470685841334,
    "lat": 38.192338690960845
  },
  {
    "name": "Frankfort",
    "lng": -83.18417312035318,
    "lat": 39.40684270593688
  },
  {
    "name": "Frankfort",
    "lng": -75.07216938938195,
    "lat": 43.038320604619166
  },
  {
    "name": "Frankfort",
    "lng": -68.91905799359517,
    "lat": 44.598569693703
  },
  {
    "name": "Franklin",
    "lng": -111.80368410508063,
    "lat": 42.01243426047619
  },
  {
    "name": "Franklin",
    "lng": -98.95141561498096,
    "lat": 40.09655082188658
  },
  {
    "name": "Franklin",
    "lng": -96.48750105719566,
    "lat": 31.025020973432113
  },
  {
    "name": "Franklin",
    "lng": -94.89177500336719,
    "lat": 44.538837717552276
  },
  {
    "name": "Franklin",
    "lng": -94.8850431770146,
    "lat": 44.518740117279634
  },
  {
    "name": "Franklin",
    "lng": -94.88198334687463,
    "lat": 44.52846507572181
  },
  {
    "name": "Franklin",
    "lng": -92.75503023576388,
    "lat": 39.011154545711214
  },
  {
    "name": "Franklin",
    "lng": -91.78966223600969,
    "lat": 36.14861870769302
  },
  {
    "name": "Franklin",
    "lng": -91.78637424900384,
    "lat": 36.163976555489405
  },
  {
    "name": "Franklin",
    "lng": -91.7705045109862,
    "lat": 36.17272572572914
  },
  {
    "name": "Franklin",
    "lng": -91.77002623601722,
    "lat": 36.1729893890937
  },
  {
    "name": "Franklin",
    "lng": -91.51126491917304,
    "lat": 40.66682379747449
  },
  {
    "name": "Franklin",
    "lng": -91.50977227536856,
    "lat": 29.78505991797548
  },
  {
    "name": "Franklin",
    "lng": -90.04770008653188,
    "lat": 39.620507346656574
  },
  {
    "name": "Franklin",
    "lng": -88.010472646688,
    "lat": 42.88543254938801
  },
  {
    "name": "Franklin",
    "lng": -86.85323371688382,
    "lat": 35.92138019245432
  },
  {
    "name": "Franklin",
    "lng": -86.75904685096913,
    "lat": 35.93228585972425
  },
  {
    "name": "Franklin",
    "lng": -86.56006120319964,
    "lat": 36.732909050208335
  },
  {
    "name": "Franklin",
    "lng": -86.05523981586543,
    "lat": 39.49425104931586
  },
  {
    "name": "Franklin",
    "lng": -85.8066494197854,
    "lat": 32.45509488243279
  },
  {
    "name": "Franklin",
    "lng": -85.09847035591488,
    "lat": 33.279511864128246
  },
  {
    "name": "Franklin",
    "lng": -84.29514554059107,
    "lat": 39.553813743302285
  },
  {
    "name": "Franklin",
    "lng": -83.43759807183942,
    "lat": 35.162900597617806
  },
  {
    "name": "Franklin",
    "lng": -83.39879359817006,
    "lat": 35.13848150538796
  },
  {
    "name": "Franklin",
    "lng": -83.3981171377868,
    "lat": 35.14601720178559
  },
  {
    "name": "Franklin",
    "lng": -83.3979416379264,
    "lat": 35.138339172486994
  },
  {
    "name": "Franklin",
    "lng": -83.39651477598301,
    "lat": 35.132613522821835
  },
  {
    "name": "Franklin",
    "lng": -83.39412592696853,
    "lat": 35.14798043146885
  },
  {
    "name": "Franklin",
    "lng": -83.39019000310397,
    "lat": 35.19885515105753
  },
  {
    "name": "Franklin",
    "lng": -83.38080857166582,
    "lat": 35.179966649555595
  },
  {
    "name": "Franklin",
    "lng": -83.36303113730052,
    "lat": 35.214162753729084
  },
  {
    "name": "Franklin",
    "lng": -83.30284286303754,
    "lat": 42.51877922942962
  },
  {
    "name": "Franklin",
    "lng": -79.84252177989133,
    "lat": 41.393609770615555
  },
  {
    "name": "Franklin",
    "lng": -79.33213644835472,
    "lat": 38.64601033543095
  },
  {
    "name": "Franklin",
    "lng": -76.93856373616454,
    "lat": 36.683682716331774
  },
  {
    "name": "Franklin",
    "lng": -75.16596168034297,
    "lat": 42.34167146720685
  },
  {
    "name": "Franklin",
    "lng": -72.14320769408774,
    "lat": 41.61529412307768
  },
  {
    "name": "Franklin",
    "lng": -71.6692348056034,
    "lat": 43.44994945502238
  },
  {
    "name": "Franklin",
    "lng": -68.2321315572726,
    "lat": 44.600190976257096
  },
  {
    "name": "Franklin Grove",
    "lng": -89.30056324512036,
    "lat": 41.8409484355148
  },
  {
    "name": "Franklin Park",
    "lng": -87.8784742787014,
    "lat": 41.93560114802028
  },
  {
    "name": "Franklin Springs",
    "lng": -83.14589419007092,
    "lat": 34.28402346133762
  },
  {
    "name": "Franklinton",
    "lng": -90.14588418669155,
    "lat": 30.847927398741547
  },
  {
    "name": "Franklinton",
    "lng": -78.45141398300271,
    "lat": 36.10301110306168
  },
  {
    "name": "Franklintown",
    "lng": -77.02954721020636,
    "lat": 40.07604206875678
  },
  {
    "name": "Franklinville",
    "lng": -79.6891892378861,
    "lat": 35.74285952478305
  },
  {
    "name": "Franklinville",
    "lng": -79.68910226991892,
    "lat": 35.72600420300472
  },
  {
    "name": "Franklinville",
    "lng": -79.67401869459914,
    "lat": 35.74177335348459
  },
  {
    "name": "Frankston",
    "lng": -95.5043623753235,
    "lat": 32.056102690063426
  },
  {
    "name": "Frankton",
    "lng": -85.77182728074695,
    "lat": 40.22106655875916
  },
  {
    "name": "Frannie",
    "lng": -108.6205406601793,
    "lat": 44.971029102868485
  },
  {
    "name": "Fraser",
    "lng": -105.80492562519393,
    "lat": 39.92939049776112
  },
  {
    "name": "Fraser",
    "lng": -93.97359578322003,
    "lat": 42.1251340860817
  },
  {
    "name": "Fraser",
    "lng": -82.94964055757735,
    "lat": 42.53879657127974
  },
  {
    "name": "Frazee",
    "lng": -95.70283630401512,
    "lat": 46.72803621428888
  },
  {
    "name": "Frazee",
    "lng": -95.69694369688295,
    "lat": 46.73160743149198
  },
  {
    "name": "Frazee",
    "lng": -95.69660808923632,
    "lat": 46.73072167304435
  },
  {
    "name": "Frazeysburg",
    "lng": -82.11720975566149,
    "lat": 40.11826352550482
  },
  {
    "name": "Frederic",
    "lng": -92.4767545671433,
    "lat": 45.6636802671193
  },
  {
    "name": "Frederic",
    "lng": -92.46414239237386,
    "lat": 45.65488295521033
  },
  {
    "name": "Frederick",
    "lng": -104.99411453025873,
    "lat": 40.135233031445786
  },
  {
    "name": "Frederick",
    "lng": -104.96410412153142,
    "lat": 40.10749718142445
  },
  {
    "name": "Frederick",
    "lng": -104.96391648059686,
    "lat": 40.10813153447439
  },
  {
    "name": "Frederick",
    "lng": -104.93959303222688,
    "lat": 40.1248881998697
  },
  {
    "name": "Frederick",
    "lng": -99.01395056161783,
    "lat": 34.392350957438815
  },
  {
    "name": "Frederick",
    "lng": -98.98878030795558,
    "lat": 34.3506992336642
  },
  {
    "name": "Frederick",
    "lng": -98.50681328210815,
    "lat": 45.83239422005547
  },
  {
    "name": "Frederick",
    "lng": -98.26759980046849,
    "lat": 38.51253225131663
  },
  {
    "name": "Frederick",
    "lng": -77.41418783299014,
    "lat": 39.43360010859276
  },
  {
    "name": "Fredericksburg",
    "lng": -98.87603956072205,
    "lat": 30.26710089542912
  },
  {
    "name": "Fredericksburg",
    "lng": -92.19652903214663,
    "lat": 42.964790352779126
  },
  {
    "name": "Fredericksburg",
    "lng": -86.1886934423475,
    "lat": 38.4285624799797
  },
  {
    "name": "Fredericksburg",
    "lng": -81.86924855463984,
    "lat": 40.677132314439525
  },
  {
    "name": "Fredericksburg",
    "lng": -77.48713754546438,
    "lat": 38.299400285814045
  },
  {
    "name": "Fredericksburg",
    "lng": -76.4376353611917,
    "lat": 40.444096242286214
  },
  {
    "name": "Fredericktown",
    "lng": -90.29879846010188,
    "lat": 37.5643751366221
  },
  {
    "name": "Fredericktown",
    "lng": -82.54869035853483,
    "lat": 40.478483537146474
  },
  {
    "name": "Frederika",
    "lng": -92.30544358748755,
    "lat": 42.88350000535954
  },
  {
    "name": "Fredonia",
    "lng": -112.52232472726008,
    "lat": 36.96655351788098
  },
  {
    "name": "Fredonia",
    "lng": -99.09569194566495,
    "lat": 46.32912546122698
  },
  {
    "name": "Fredonia",
    "lng": -95.82752621598554,
    "lat": 37.52040068552671
  },
  {
    "name": "Fredonia",
    "lng": -95.82262612336373,
    "lat": 37.53316522493372
  },
  {
    "name": "Fredonia",
    "lng": -91.33935460976095,
    "lat": 41.284414493628915
  },
  {
    "name": "Fredonia",
    "lng": -88.06011334439086,
    "lat": 37.208598743107615
  },
  {
    "name": "Fredonia",
    "lng": -87.9484159792098,
    "lat": 43.47524063600786
  },
  {
    "name": "Fredonia",
    "lng": -87.94301348469132,
    "lat": 43.45439077223571
  },
  {
    "name": "Fredonia",
    "lng": -79.33198951391515,
    "lat": 42.44067560674147
  },
  {
    "name": "Fredonia (Biscoe)",
    "lng": -91.41012568642485,
    "lat": 34.81939403576104
  },
  {
    "name": "Free Soil",
    "lng": -86.21101006544365,
    "lat": 44.10706298426171
  },
  {
    "name": "Freeborn",
    "lng": -93.57366690048677,
    "lat": 43.76529686741804
  },
  {
    "name": "Freeborn",
    "lng": -93.56417258974088,
    "lat": 43.76606686707371
  },
  {
    "name": "Freeburg",
    "lng": -91.92233238816424,
    "lat": 38.31611090919804
  },
  {
    "name": "Freeburg",
    "lng": -91.92101251844613,
    "lat": 38.31087182411945
  },
  {
    "name": "Freeburg",
    "lng": -89.91594028045303,
    "lat": 38.441323792313845
  },
  {
    "name": "Freeburg",
    "lng": -76.9412191256159,
    "lat": 40.76265876232219
  },
  {
    "name": "Freedom",
    "lng": -99.11312330543001,
    "lat": 36.76816491403542
  },
  {
    "name": "Freedom",
    "lng": -69.34715678014393,
    "lat": 44.498624130530466
  },
  {
    "name": "Freeman",
    "lng": -97.4294671151983,
    "lat": 43.349928121955216
  },
  {
    "name": "Freeman",
    "lng": -94.50619905584072,
    "lat": 38.62167084656616
  },
  {
    "name": "Freeman",
    "lng": -94.498049017516,
    "lat": 38.61548085388496
  },
  {
    "name": "Freeman Spur",
    "lng": -88.99935869284622,
    "lat": 37.86130693540172
  },
  {
    "name": "Freeport",
    "lng": -97.85434939331515,
    "lat": 37.19887908335453
  },
  {
    "name": "Freeport",
    "lng": -95.36031130733457,
    "lat": 28.944325574614037
  },
  {
    "name": "Freeport",
    "lng": -94.6887765864285,
    "lat": 45.6626104400649
  },
  {
    "name": "Freeport",
    "lng": -89.63533340122476,
    "lat": 42.28906661874675
  },
  {
    "name": "Freeport",
    "lng": -89.58555899081506,
    "lat": 42.30387825368945
  },
  {
    "name": "Freeport",
    "lng": -86.19195383533432,
    "lat": 30.49935169244085
  },
  {
    "name": "Freeport",
    "lng": -86.19186693749383,
    "lat": 30.489135158638156
  },
  {
    "name": "Freeport",
    "lng": -86.1402120162533,
    "lat": 30.50253514811328
  },
  {
    "name": "Freeport",
    "lng": -86.12831022395883,
    "lat": 30.47473032593866
  },
  {
    "name": "Freeport",
    "lng": -86.12438993366082,
    "lat": 30.480111881928597
  },
  {
    "name": "Freeport",
    "lng": -85.3159696642175,
    "lat": 42.764107327135015
  },
  {
    "name": "Freeport",
    "lng": -81.26872499730247,
    "lat": 40.21098332845987
  },
  {
    "name": "Freeport",
    "lng": -73.58498553239917,
    "lat": 40.65156263121999
  },
  {
    "name": "Freeport",
    "lng": -70.09397687956076,
    "lat": 43.846737765714096
  },
  {
    "name": "Freer",
    "lng": -98.61805715541381,
    "lat": 27.882329894215296
  },
  {
    "name": "Freeville",
    "lng": -76.34572341855691,
    "lat": 42.51141597080733
  },
  {
    "name": "Freistatt",
    "lng": -93.89798598387932,
    "lat": 37.02135926596776
  },
  {
    "name": "Fremont",
    "lng": -121.99518246732092,
    "lat": 37.5246499291663
  },
  {
    "name": "Fremont",
    "lng": -111.61492387779559,
    "lat": 38.45546113934082
  },
  {
    "name": "Fremont",
    "lng": -96.4909607891946,
    "lat": 41.44121030864986
  },
  {
    "name": "Fremont",
    "lng": -92.43387840087873,
    "lat": 41.21247845654821
  },
  {
    "name": "Fremont",
    "lng": -88.89578859916486,
    "lat": 44.265626347193546
  },
  {
    "name": "Fremont",
    "lng": -88.87139912623496,
    "lat": 44.260358652382926
  },
  {
    "name": "Fremont",
    "lng": -85.94930847535296,
    "lat": 43.46823327988865
  },
  {
    "name": "Fremont",
    "lng": -84.93430488299352,
    "lat": 41.730007661885985
  },
  {
    "name": "Fremont",
    "lng": -83.1144724517305,
    "lat": 41.35306999902884
  },
  {
    "name": "Fremont",
    "lng": -77.97548971533183,
    "lat": 35.54362606914118
  },
  {
    "name": "Fremont",
    "lng": -71.12494976470764,
    "lat": 42.989046509231834
  },
  {
    "name": "Fremont Hills",
    "lng": -93.25309826691202,
    "lat": 37.06459134813873
  },
  {
    "name": "French Camp",
    "lng": -89.39929308137361,
    "lat": 33.29300034666411
  },
  {
    "name": "French Lick",
    "lng": -86.62011621394646,
    "lat": 38.54648366387126
  },
  {
    "name": "French Settlement",
    "lng": -90.803289821244,
    "lat": 30.31600018533982
  },
  {
    "name": "Frenchboro",
    "lng": -68.29445961616729,
    "lat": 44.14223647116981
  },
  {
    "name": "Frenchburg",
    "lng": -83.62270869948496,
    "lat": 37.95404256445318
  },
  {
    "name": "Frenchville",
    "lng": -68.39947638277316,
    "lat": 47.273693253639
  },
  {
    "name": "Fresno",
    "lng": -119.90491313781352,
    "lat": 36.69176917995539
  },
  {
    "name": "Fresno",
    "lng": -119.78927202238607,
    "lat": 36.78712004485897
  },
  {
    "name": "Fresno",
    "lng": -119.70886215410812,
    "lat": 36.72951904263484
  },
  {
    "name": "Friars Point",
    "lng": -90.6379135389207,
    "lat": 34.36795918073742
  },
  {
    "name": "Friday Harbor",
    "lng": -123.12654068358965,
    "lat": 48.53082176435173
  },
  {
    "name": "Friday Harbor",
    "lng": -123.1054265810249,
    "lat": 48.52425483597902
  },
  {
    "name": "Friday Harbor",
    "lng": -123.10078059792778,
    "lat": 48.52635750376212
  },
  {
    "name": "Friday Harbor",
    "lng": -123.03243093440338,
    "lat": 48.55448662750063
  },
  {
    "name": "Friday Harbor",
    "lng": -123.02139959138513,
    "lat": 48.53309962499574
  },
  {
    "name": "Fridley",
    "lng": -93.259464125224,
    "lat": 45.08427893306394
  },
  {
    "name": "Friend",
    "lng": -97.28413145041841,
    "lat": 40.65073606695858
  },
  {
    "name": "Friendly",
    "lng": -81.0618084918449,
    "lat": 39.51355034721698
  },
  {
    "name": "Friendship",
    "lng": -99.22951894522002,
    "lat": 34.69808898917416
  },
  {
    "name": "Friendship",
    "lng": -93.00366025705357,
    "lat": 34.224345627608216
  },
  {
    "name": "Friendship",
    "lng": -89.82029142563401,
    "lat": 43.9720408250159
  },
  {
    "name": "Friendship",
    "lng": -89.24079470394179,
    "lat": 35.907378746286255
  },
  {
    "name": "Friendship",
    "lng": -69.34288406016141,
    "lat": 43.962522561366995
  },
  {
    "name": "Friendship Heights Village",
    "lng": -77.08997928713252,
    "lat": 38.963330266452424
  },
  {
    "name": "Friendsville",
    "lng": -84.13405881019007,
    "lat": 35.754904600025064
  },
  {
    "name": "Friendsville",
    "lng": -79.4043005379205,
    "lat": 39.66231895842345
  },
  {
    "name": "Friendswood",
    "lng": -95.19821077924261,
    "lat": 29.51089878970728
  },
  {
    "name": "Fries",
    "lng": -80.97523376129774,
    "lat": 36.7139383007468
  },
  {
    "name": "Friesland",
    "lng": -89.06685804574107,
    "lat": 43.588637460280054
  },
  {
    "name": "Friona",
    "lng": -102.72292316317726,
    "lat": 34.63909618993004
  },
  {
    "name": "Frisco",
    "lng": -106.09293618889022,
    "lat": 39.5796695237954
  },
  {
    "name": "Frisco",
    "lng": -106.09272130647621,
    "lat": 39.5795596974227
  },
  {
    "name": "Frisco City",
    "lng": -87.40353074218254,
    "lat": 31.43512334728081
  },
  {
    "name": "Fritch",
    "lng": -101.59670884870611,
    "lat": 35.64327599098549
  },
  {
    "name": "Frohna",
    "lng": -89.62129985849131,
    "lat": 37.63664635175971
  },
  {
    "name": "Froid",
    "lng": -104.49202455807361,
    "lat": 48.335214464815735
  },
  {
    "name": "Fromberg",
    "lng": -108.90646795949782,
    "lat": 45.39177889260099
  },
  {
    "name": "Front Royal",
    "lng": -78.18379937606963,
    "lat": 38.925969398505224
  },
  {
    "name": "Frontenac",
    "lng": -94.7206285118116,
    "lat": 37.44535006828388
  },
  {
    "name": "Frontenac",
    "lng": -94.69962504627112,
    "lat": 37.458419898707916
  },
  {
    "name": "Frontenac",
    "lng": -94.68382088487876,
    "lat": 37.46482891357695
  },
  {
    "name": "Frontenac",
    "lng": -90.418996224867,
    "lat": 38.63005744236277
  },
  {
    "name": "Frontier",
    "lng": -96.83328802283293,
    "lat": 46.80019955600942
  },
  {
    "name": "Frost",
    "lng": -96.80853610322461,
    "lat": 32.07880738308715
  },
  {
    "name": "Frost",
    "lng": -93.92480537882335,
    "lat": 43.58457756903414
  },
  {
    "name": "Frostproof",
    "lng": -81.58547195285017,
    "lat": 27.74355236375585
  },
  {
    "name": "Frostproof",
    "lng": -81.52264190206925,
    "lat": 27.749159908169023
  },
  {
    "name": "Frostproof",
    "lng": -81.51882405609412,
    "lat": 27.78700698208331
  },
  {
    "name": "Fruit Heights",
    "lng": -111.90814213869757,
    "lat": 41.02758273932521
  },
  {
    "name": "Fruita",
    "lng": -108.72646564014154,
    "lat": 39.153602480392536
  },
  {
    "name": "Fruita",
    "lng": -108.68831775714443,
    "lat": 39.135242667700865
  },
  {
    "name": "Fruitdale",
    "lng": -103.69548080398347,
    "lat": 44.66921673530826
  },
  {
    "name": "Fruithurst",
    "lng": -85.43112734604976,
    "lat": 33.72960479099883
  },
  {
    "name": "Fruitland",
    "lng": -116.9220258389516,
    "lat": 44.01951944819226
  },
  {
    "name": "Fruitland",
    "lng": -91.12880105909468,
    "lat": 41.34744173434773
  },
  {
    "name": "Fruitland",
    "lng": -75.62500140722392,
    "lat": 38.321304655986275
  },
  {
    "name": "Fruitland Park",
    "lng": -81.94544129177729,
    "lat": 28.86213280863427
  },
  {
    "name": "Fruitland Park",
    "lng": -81.93775146784063,
    "lat": 28.86302784152704
  },
  {
    "name": "Fruitland Park",
    "lng": -81.92795088379687,
    "lat": 28.861009844735943
  },
  {
    "name": "Fruitland Park",
    "lng": -81.92241163574933,
    "lat": 28.84507157497625
  },
  {
    "name": "Fruitland Park",
    "lng": -81.91838361629198,
    "lat": 28.840469458732937
  },
  {
    "name": "Fruitland Park",
    "lng": -81.90827916987651,
    "lat": 28.865146452100618
  },
  {
    "name": "Fruitland Park",
    "lng": -81.90580856507431,
    "lat": 28.88882635814746
  },
  {
    "name": "Fruitport",
    "lng": -86.15612730719839,
    "lat": 43.12478037764559
  },
  {
    "name": "Fruitvale",
    "lng": -108.4955564714682,
    "lat": 39.07886214921436
  },
  {
    "name": "Fruitvale",
    "lng": -108.49378726577713,
    "lat": 39.09334426707565
  },
  {
    "name": "Fruitvale",
    "lng": -108.47928991764529,
    "lat": 39.08177684938112
  },
  {
    "name": "Fruitvale",
    "lng": -108.47851495412453,
    "lat": 39.09350110192958
  },
  {
    "name": "Fruitvale",
    "lng": -95.80461425162228,
    "lat": 32.683483148801415
  },
  {
    "name": "Frye Island",
    "lng": -70.5242464899153,
    "lat": 43.837311764629916
  },
  {
    "name": "Fryeburg",
    "lng": -70.93834141872092,
    "lat": 44.05486779641099
  },
  {
    "name": "Fulda",
    "lng": -95.60240997559742,
    "lat": 43.8690734420518
  },
  {
    "name": "Fulda",
    "lng": -95.59203901307185,
    "lat": 43.8738653007941
  },
  {
    "name": "Fullerton",
    "lng": -117.92781911111122,
    "lat": 33.88408633652467
  },
  {
    "name": "Fullerton",
    "lng": -98.4273050514577,
    "lat": 46.1632687779639
  },
  {
    "name": "Fullerton",
    "lng": -97.97248272981888,
    "lat": 41.36388552138467
  },
  {
    "name": "Fulshear",
    "lng": -95.87651945843528,
    "lat": 29.694877503347893
  },
  {
    "name": "Fulton",
    "lng": -97.82262787173907,
    "lat": 43.72883082571385
  },
  {
    "name": "Fulton",
    "lng": -97.03773575673203,
    "lat": 28.072407101608107
  },
  {
    "name": "Fulton",
    "lng": -94.71976775307577,
    "lat": 38.00979291095583
  },
  {
    "name": "Fulton",
    "lng": -93.81374087680783,
    "lat": 33.61256941561123
  },
  {
    "name": "Fulton",
    "lng": -91.95486293445556,
    "lat": 38.90283842701721
  },
  {
    "name": "Fulton",
    "lng": -91.95040755029002,
    "lat": 38.8550591520638
  },
  {
    "name": "Fulton",
    "lng": -90.15773071697785,
    "lat": 41.8646123826134
  },
  {
    "name": "Fulton",
    "lng": -88.88132588008013,
    "lat": 36.51351820711788
  },
  {
    "name": "Fulton",
    "lng": -88.40216862847961,
    "lat": 34.26157351588499
  },
  {
    "name": "Fulton",
    "lng": -87.74008015082306,
    "lat": 31.791741181436855
  },
  {
    "name": "Fulton",
    "lng": -86.26417967956777,
    "lat": 40.946147094060535
  },
  {
    "name": "Fulton",
    "lng": -82.82901003935054,
    "lat": 40.46305050620742
  },
  {
    "name": "Fulton",
    "lng": -76.4161971646597,
    "lat": 43.317147588811245
  },
  {
    "name": "Fultondale",
    "lng": -86.80189361775635,
    "lat": 33.617594143271546
  },
  {
    "name": "Fultondale",
    "lng": -86.80161200328658,
    "lat": 33.61770428846556
  },
  {
    "name": "Fultondale",
    "lng": -86.78849120567922,
    "lat": 33.635125736815816
  },
  {
    "name": "Fultondale",
    "lng": -86.78329862691075,
    "lat": 33.63144244634226
  },
  {
    "name": "Fultonham",
    "lng": -82.1424093374296,
    "lat": 39.85575115730072
  },
  {
    "name": "Fultonville",
    "lng": -74.37042727024735,
    "lat": 42.94632312750768
  },
  {
    "name": "Fults",
    "lng": -90.21265731597114,
    "lat": 38.16406435416304
  },
  {
    "name": "Funk",
    "lng": -99.25083744712784,
    "lat": 40.463050244345
  },
  {
    "name": "Funkley",
    "lng": -94.42655547705058,
    "lat": 47.78584928609527
  },
  {
    "name": "Funkstown",
    "lng": -77.70813274514579,
    "lat": 39.6089428149368
  },
  {
    "name": "Funston",
    "lng": -83.87704167453269,
    "lat": 31.198888254353662
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.86627388422112,
    "lat": 35.57242376063594
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.86164775049336,
    "lat": 35.58221028385384
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.85876007413,
    "lat": 35.57281722097619
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.85007034087717,
    "lat": 35.56851934468582
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.8378008407784,
    "lat": 35.578232225873364
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.83130402322911,
    "lat": 35.597605043494795
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.82888041273176,
    "lat": 35.58403838712823
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.82718783767413,
    "lat": 35.59082328604251
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.82542425885062,
    "lat": 35.59801762120498
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.8195401069052,
    "lat": 35.56087089478494
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.81116232617156,
    "lat": 35.551728671108876
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.80710608988124,
    "lat": 35.55656203606247
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.79812123058338,
    "lat": 35.61788071163132
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.79574440551659,
    "lat": 35.55643649698063
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.79193682693122,
    "lat": 35.56165107314331
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.78815700813061,
    "lat": 35.61833359047217
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.78311513002846,
    "lat": 35.62869290153996
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.78309915768271,
    "lat": 35.58906958345468
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.77759758203801,
    "lat": 35.54610763905203
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.7739107021686,
    "lat": 35.56882928223208
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.77003875071357,
    "lat": 35.61191820882143
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.76905790548568,
    "lat": 35.6242003519255
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.76721160039841,
    "lat": 35.57106433090265
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.76713615488512,
    "lat": 35.567727334915844
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.76464478845202,
    "lat": 35.639698390750105
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.763302280055,
    "lat": 35.63332870559136
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.7607801614165,
    "lat": 35.62320133344828
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.75859420571986,
    "lat": 35.61425824349336
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.75661359700186,
    "lat": 35.568804681728615
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.75533019948766,
    "lat": 35.611080106711945
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.75307327038432,
    "lat": 35.583056049774505
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.75222140195484,
    "lat": 35.62206325761993
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.7472973402344,
    "lat": 35.61938404587029
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.73834999743407,
    "lat": 35.63241173633869
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.73071020194541,
    "lat": 35.59431623775199
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.73016754199948,
    "lat": 35.643921525228
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.72916655830915,
    "lat": 35.59045939582565
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.72883029316908,
    "lat": 35.58556032378184
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.72725278838321,
    "lat": 35.63763129428979
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.72506998420272,
    "lat": 35.639248530169375
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.72402042502235,
    "lat": 35.61984661069701
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.72286593081031,
    "lat": 35.62063225681786
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.72078551492089,
    "lat": 35.59272532177044
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.72030116357107,
    "lat": 35.647335008325726
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.71916165381184,
    "lat": 35.597852395817235
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.7173562477464,
    "lat": 35.65875100555398
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.71151616213825,
    "lat": 35.64063035644718
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.71084007994591,
    "lat": 35.64688975521213
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.70931576376637,
    "lat": 35.64888981789162
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.70891479633276,
    "lat": 35.645302838921396
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.70774205675572,
    "lat": 35.660290516240615
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.70764574809458,
    "lat": 35.64323535387558
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.70692103451587,
    "lat": 35.666016337285356
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.70575417080057,
    "lat": 35.66100761415361
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.70290078831619,
    "lat": 35.66019365512033
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.69090566167964,
    "lat": 35.591680879114136
  },
  {
    "name": "Fuquay-Varina",
    "lng": -78.67998663748253,
    "lat": 35.583122822031186
  },
  {
    "name": "Furman",
    "lng": -81.18770491637287,
    "lat": 32.68124836679461
  },
  {
    "name": "Fyffe",
    "lng": -85.90496563820476,
    "lat": 34.44960661043808
  },
  {
    "name": "Gaastra",
    "lng": -88.60493266138566,
    "lat": 46.055721132878055
  },
  {
    "name": "Gackle",
    "lng": -99.14144038087586,
    "lat": 46.62645402688073
  },
  {
    "name": "Gadsden",
    "lng": -88.98611060415871,
    "lat": 35.77687853986378
  },
  {
    "name": "Gadsden",
    "lng": -86.06633425695063,
    "lat": 33.9583511542777
  },
  {
    "name": "Gadsden",
    "lng": -86.01497922529494,
    "lat": 34.00949575088935
  },
  {
    "name": "Gaffney",
    "lng": -81.65696996847393,
    "lat": 35.074754620041894
  },
  {
    "name": "Gage",
    "lng": -99.75778064977989,
    "lat": 36.31821042352492
  },
  {
    "name": "Gagetown",
    "lng": -83.2448967011282,
    "lat": 43.65722280491943
  },
  {
    "name": "Gahanna",
    "lng": -82.8637869237678,
    "lat": 40.02516108572181
  },
  {
    "name": "Gaines",
    "lng": -83.9108064229787,
    "lat": 42.87244080868459
  },
  {
    "name": "Gainesboro",
    "lng": -85.6495561014982,
    "lat": 36.36171368019705
  },
  {
    "name": "Gainesville",
    "lng": -97.14844050312252,
    "lat": 33.63848462104475
  },
  {
    "name": "Gainesville",
    "lng": -92.42521702944123,
    "lat": 36.60591097473048
  },
  {
    "name": "Gainesville",
    "lng": -88.16079322087188,
    "lat": 32.81493649178593
  },
  {
    "name": "Gainesville",
    "lng": -83.88754473163795,
    "lat": 34.24399514623762
  },
  {
    "name": "Gainesville",
    "lng": -83.86430834316879,
    "lat": 34.35999171126055
  },
  {
    "name": "Gainesville",
    "lng": -83.86324454325911,
    "lat": 34.24109407739372
  },
  {
    "name": "Gainesville",
    "lng": -83.86293275154163,
    "lat": 34.24530194718773
  },
  {
    "name": "Gainesville",
    "lng": -83.86229009205782,
    "lat": 34.230566511477655
  },
  {
    "name": "Gainesville",
    "lng": -83.84588572014567,
    "lat": 34.26012698765926
  },
  {
    "name": "Gainesville",
    "lng": -83.83766178880815,
    "lat": 34.28005147592501
  },
  {
    "name": "Gainesville",
    "lng": -83.83437062185945,
    "lat": 34.28371284313294
  },
  {
    "name": "Gainesville",
    "lng": -83.83336614351884,
    "lat": 34.278233163789615
  },
  {
    "name": "Gainesville",
    "lng": -83.82984103982263,
    "lat": 34.29043969421103
  },
  {
    "name": "Gainesville",
    "lng": -83.81966559260492,
    "lat": 34.24078498243684
  },
  {
    "name": "Gainesville",
    "lng": -83.78464427340795,
    "lat": 34.318178280980746
  },
  {
    "name": "Gainesville",
    "lng": -82.34512977574232,
    "lat": 29.682083542962868
  },
  {
    "name": "Gainesville",
    "lng": -78.13465860992736,
    "lat": 42.64149461693558
  },
  {
    "name": "Gainesville",
    "lng": -77.62405618916412,
    "lat": 38.79458653545817
  },
  {
    "name": "Galatia",
    "lng": -98.95790421975047,
    "lat": 38.64117376571636
  },
  {
    "name": "Galatia",
    "lng": -88.61483749575861,
    "lat": 37.84142575893658
  },
  {
    "name": "Galax",
    "lng": -80.91755289136755,
    "lat": 36.66573037039965
  },
  {
    "name": "Galena",
    "lng": -156.8583073522814,
    "lat": 64.73876516766525
  },
  {
    "name": "Galena",
    "lng": -94.6625302037447,
    "lat": 37.07576905332068
  },
  {
    "name": "Galena",
    "lng": -94.64153049731694,
    "lat": 37.043673471901876
  },
  {
    "name": "Galena",
    "lng": -94.63951583199187,
    "lat": 37.053350559616874
  },
  {
    "name": "Galena",
    "lng": -94.63835421968082,
    "lat": 37.054702333972315
  },
  {
    "name": "Galena",
    "lng": -94.63485490978393,
    "lat": 37.07535240881578
  },
  {
    "name": "Galena",
    "lng": -93.47092778775242,
    "lat": 36.80533672593705
  },
  {
    "name": "Galena",
    "lng": -90.43028813896346,
    "lat": 42.422216169970156
  },
  {
    "name": "Galena",
    "lng": -90.40573066220637,
    "lat": 42.41161076415817
  },
  {
    "name": "Galena",
    "lng": -82.8789469181709,
    "lat": 40.221323454057746
  },
  {
    "name": "Galena",
    "lng": -75.87930110161527,
    "lat": 39.34283673462082
  },
  {
    "name": "Galena Park",
    "lng": -95.2333464533888,
    "lat": 29.7452357287442
  },
  {
    "name": "Gales Ferry",
    "lng": -72.08539458011883,
    "lat": 41.421488864494684
  },
  {
    "name": "Galesburg",
    "lng": -97.40873738468595,
    "lat": 47.2701908090945
  },
  {
    "name": "Galesburg",
    "lng": -95.35639114046282,
    "lat": 37.47235127923874
  },
  {
    "name": "Galesburg",
    "lng": -90.3762871650533,
    "lat": 40.95055634084604
  },
  {
    "name": "Galesburg",
    "lng": -85.41838127387345,
    "lat": 42.29085558098661
  },
  {
    "name": "Galestown",
    "lng": -75.71554132500202,
    "lat": 38.56381429636806
  },
  {
    "name": "Galesville",
    "lng": -91.35940780199832,
    "lat": 44.075004074963395
  },
  {
    "name": "Galesville",
    "lng": -91.35558053365914,
    "lat": 44.08424317260328
  },
  {
    "name": "Galien",
    "lng": -86.49956242321893,
    "lat": 41.8015904880587
  },
  {
    "name": "Galion",
    "lng": -82.7776976513693,
    "lat": 40.739308232989934
  },
  {
    "name": "Gallatin",
    "lng": -95.15215064774384,
    "lat": 31.896944033711744
  },
  {
    "name": "Gallatin",
    "lng": -93.96411741369535,
    "lat": 39.91058976617903
  },
  {
    "name": "Gallatin",
    "lng": -86.49889979312714,
    "lat": 36.40734044031976
  },
  {
    "name": "Gallatin",
    "lng": -86.45940655728758,
    "lat": 36.377705767703404
  },
  {
    "name": "Gallaway",
    "lng": -89.60866506273469,
    "lat": 35.327553513192406
  },
  {
    "name": "Gallipolis",
    "lng": -82.19305841698404,
    "lat": 38.81865499080991
  },
  {
    "name": "Gallup",
    "lng": -108.74752702523715,
    "lat": 35.51772368289604
  },
  {
    "name": "Galt",
    "lng": -121.33092850585857,
    "lat": 38.30040893217887
  },
  {
    "name": "Galt",
    "lng": -121.3006550310826,
    "lat": 38.2645388512218
  },
  {
    "name": "Galt",
    "lng": -93.60467556803374,
    "lat": 42.69363382828863
  },
  {
    "name": "Galt",
    "lng": -93.38804876528351,
    "lat": 40.127246516587746
  },
  {
    "name": "Galva",
    "lng": -97.53830970093327,
    "lat": 38.38394226645043
  },
  {
    "name": "Galva",
    "lng": -95.41724055300301,
    "lat": 42.506367840889496
  },
  {
    "name": "Galva",
    "lng": -90.03742041303869,
    "lat": 41.16889695237053
  },
  {
    "name": "Galveston",
    "lng": -86.19141168915512,
    "lat": 40.57710441107704
  },
  {
    "name": "Gamaliel",
    "lng": -85.79274324042213,
    "lat": 36.63966371914139
  },
  {
    "name": "Gambell",
    "lng": -171.71738591142758,
    "lat": 63.76124095197149
  },
  {
    "name": "Gambier",
    "lng": -82.3948307077178,
    "lat": 40.376371148017654
  },
  {
    "name": "Gamewell",
    "lng": -81.59791346531338,
    "lat": 35.86384402281026
  },
  {
    "name": "Ganado",
    "lng": -96.51104936766572,
    "lat": 29.041249422493365
  },
  {
    "name": "Gandy",
    "lng": -100.45832986881345,
    "lat": 41.46974813081869
  },
  {
    "name": "Gandy",
    "lng": -82.62772933918882,
    "lat": 27.865017392048976
  },
  {
    "name": "Gans",
    "lng": -94.69407696531609,
    "lat": 35.38704887615956
  },
  {
    "name": "Gantt",
    "lng": -86.48425862782125,
    "lat": 31.409229455009278
  },
  {
    "name": "Garber",
    "lng": -97.5808320543027,
    "lat": 36.436552643246834
  },
  {
    "name": "Garber",
    "lng": -91.26296301798497,
    "lat": 42.74476593047116
  },
  {
    "name": "Garden",
    "lng": -86.55279178257216,
    "lat": 45.773108435709396
  },
  {
    "name": "Garden City",
    "lng": -116.27433688848163,
    "lat": 43.65259237490423
  },
  {
    "name": "Garden City",
    "lng": -111.4122093326874,
    "lat": 41.937114952736515
  },
  {
    "name": "Garden City",
    "lng": -104.68928075744722,
    "lat": 40.39488891005074
  },
  {
    "name": "Garden City",
    "lng": -100.85297290927564,
    "lat": 37.975885277232614
  },
  {
    "name": "Garden City",
    "lng": -100.83505705164093,
    "lat": 37.95235219928744
  },
  {
    "name": "Garden City",
    "lng": -97.5804122254886,
    "lat": 44.95926375704702
  },
  {
    "name": "Garden City",
    "lng": -94.21641608272718,
    "lat": 38.571354670389454
  },
  {
    "name": "Garden City",
    "lng": -94.19245512027022,
    "lat": 38.56020241333643
  },
  {
    "name": "Garden City",
    "lng": -94.19016239771028,
    "lat": 38.57488514862023
  },
  {
    "name": "Garden City",
    "lng": -86.74892756418144,
    "lat": 34.016675143209
  },
  {
    "name": "Garden City",
    "lng": -83.34118361170178,
    "lat": 42.324388634628065
  },
  {
    "name": "Garden City",
    "lng": -81.19648470212682,
    "lat": 32.054369547363464
  },
  {
    "name": "Garden City",
    "lng": -81.17096096086871,
    "lat": 32.09726079836312
  },
  {
    "name": "Garden City",
    "lng": -73.64478140425153,
    "lat": 40.72664524501781
  },
  {
    "name": "Garden Grove",
    "lng": -117.96006303941695,
    "lat": 33.77872781985062
  },
  {
    "name": "Garden Grove",
    "lng": -93.6068520577988,
    "lat": 40.82672386800248
  },
  {
    "name": "Garden Plain",
    "lng": -97.68120385369942,
    "lat": 37.65931672390292
  },
  {
    "name": "Garden Ridge",
    "lng": -98.29293003429824,
    "lat": 29.63792110348656
  },
  {
    "name": "Gardena",
    "lng": -118.30726516370731,
    "lat": 33.89437342219395
  },
  {
    "name": "Gardena",
    "lng": -100.49853794602674,
    "lat": 48.701333842888936
  },
  {
    "name": "Gardendale",
    "lng": -86.83976417358615,
    "lat": 33.70726814515078
  },
  {
    "name": "Gardendale",
    "lng": -86.82763027877243,
    "lat": 33.72890920938409
  },
  {
    "name": "Gardendale",
    "lng": -86.8276302787724,
    "lat": 33.728909209384085
  },
  {
    "name": "Gardendale",
    "lng": -86.81839622132908,
    "lat": 33.71211616170274
  },
  {
    "name": "Gardendale",
    "lng": -86.81827973859968,
    "lat": 33.712273595704104
  },
  {
    "name": "Gardendale",
    "lng": -86.80901289717437,
    "lat": 33.728590394429894
  },
  {
    "name": "Gardendale",
    "lng": -86.80901289717436,
    "lat": 33.728590394429894
  },
  {
    "name": "Gardendale",
    "lng": -86.80456859291077,
    "lat": 33.66251251014811
  },
  {
    "name": "Gardendale",
    "lng": -86.80431220573422,
    "lat": 33.66321121961135
  },
  {
    "name": "Gardendale",
    "lng": -86.80360295674969,
    "lat": 33.70141957483569
  },
  {
    "name": "Gardendale",
    "lng": -86.78994145300693,
    "lat": 33.635507084164324
  },
  {
    "name": "Gardendale",
    "lng": -86.78935527683306,
    "lat": 33.63473660401555
  },
  {
    "name": "Gardendale",
    "lng": -86.78935527683306,
    "lat": 33.63473660401556
  },
  {
    "name": "Gardiner",
    "lng": -69.79209809822524,
    "lat": 44.19105347414772
  },
  {
    "name": "Gardner",
    "lng": -96.96861089482617,
    "lat": 47.144511277957044
  },
  {
    "name": "Gardner",
    "lng": -94.94250420897018,
    "lat": 38.80183679463918
  },
  {
    "name": "Gardner",
    "lng": -94.93302815676357,
    "lat": 38.84409059938724
  },
  {
    "name": "Gardner",
    "lng": -94.93096222609141,
    "lat": 38.81554851370741
  },
  {
    "name": "Gardner",
    "lng": -88.31468172663745,
    "lat": 41.19305629546562
  },
  {
    "name": "Gardner",
    "lng": -71.9867570796481,
    "lat": 42.58452997672894
  },
  {
    "name": "Garfield",
    "lng": -117.13961356213555,
    "lat": 47.00988408637888
  },
  {
    "name": "Garfield",
    "lng": -99.24440892125743,
    "lat": 38.076978036275364
  },
  {
    "name": "Garfield",
    "lng": -95.49473677083759,
    "lat": 45.93860293918456
  },
  {
    "name": "Garfield",
    "lng": -95.48249039529824,
    "lat": 45.94912213779216
  },
  {
    "name": "Garfield",
    "lng": -93.97574902664667,
    "lat": 36.455465453927765
  },
  {
    "name": "Garfield",
    "lng": -82.09622816808385,
    "lat": 32.6495405067575
  },
  {
    "name": "Garfield",
    "lng": -74.10840199781939,
    "lat": 40.8791743647718
  },
  {
    "name": "Garfield Heights",
    "lng": -81.60377666845788,
    "lat": 41.41990662469895
  },
  {
    "name": "Garland",
    "lng": -112.16274591089913,
    "lat": 41.736195181294704
  },
  {
    "name": "Garland",
    "lng": -96.98529162380044,
    "lat": 40.94458710340356
  },
  {
    "name": "Garland",
    "lng": -96.6303772890647,
    "lat": 32.91012024904112
  },
  {
    "name": "Garland",
    "lng": -93.71416555896597,
    "lat": 33.3609683034621
  },
  {
    "name": "Garland",
    "lng": -89.75257906910376,
    "lat": 35.58246032781991
  },
  {
    "name": "Garland",
    "lng": -78.39471342605754,
    "lat": 34.785854154840536
  },
  {
    "name": "Garland",
    "lng": -69.15859639176679,
    "lat": 45.05437168458846
  },
  {
    "name": "Garnavillo",
    "lng": -91.23626706669143,
    "lat": 42.866464279089534
  },
  {
    "name": "Garner",
    "lng": -93.60310246695579,
    "lat": 43.099955956892224
  },
  {
    "name": "Garner",
    "lng": -91.78052945082776,
    "lat": 35.142120533538865
  },
  {
    "name": "Garner",
    "lng": -78.6991922656769,
    "lat": 35.66663361044621
  },
  {
    "name": "Garner",
    "lng": -78.68658350312236,
    "lat": 35.653047385613206
  },
  {
    "name": "Garner",
    "lng": -78.66905973456633,
    "lat": 35.676754882730414
  },
  {
    "name": "Garner",
    "lng": -78.66546408930002,
    "lat": 35.65361746134401
  },
  {
    "name": "Garner",
    "lng": -78.66275631872614,
    "lat": 35.65933715780723
  },
  {
    "name": "Garner",
    "lng": -78.65585218159356,
    "lat": 35.71118409512688
  },
  {
    "name": "Garner",
    "lng": -78.65006456578892,
    "lat": 35.694215207881946
  },
  {
    "name": "Garner",
    "lng": -78.64877400038034,
    "lat": 35.711426208891986
  },
  {
    "name": "Garner",
    "lng": -78.64756556910649,
    "lat": 35.68591329423179
  },
  {
    "name": "Garner",
    "lng": -78.63033526826419,
    "lat": 35.672897735085485
  },
  {
    "name": "Garner",
    "lng": -78.62120362219171,
    "lat": 35.652999131748466
  },
  {
    "name": "Garner",
    "lng": -78.61716508472323,
    "lat": 35.70071551925711
  },
  {
    "name": "Garner",
    "lng": -78.6127441262079,
    "lat": 35.65648581065725
  },
  {
    "name": "Garner",
    "lng": -78.60512143146009,
    "lat": 35.66361436183957
  },
  {
    "name": "Garner",
    "lng": -78.60418322211247,
    "lat": 35.71838353428738
  },
  {
    "name": "Garner",
    "lng": -78.60307407082372,
    "lat": 35.716200464368654
  },
  {
    "name": "Garner",
    "lng": -78.60092291076768,
    "lat": 35.663582854957006
  },
  {
    "name": "Garner",
    "lng": -78.59660379092185,
    "lat": 35.66398800278471
  },
  {
    "name": "Garner",
    "lng": -78.59548431959465,
    "lat": 35.65132110935515
  },
  {
    "name": "Garner",
    "lng": -78.59259068535648,
    "lat": 35.72466737167839
  },
  {
    "name": "Garner",
    "lng": -78.5909564591035,
    "lat": 35.71099650876514
  },
  {
    "name": "Garner",
    "lng": -78.59093376713504,
    "lat": 35.68747610267613
  },
  {
    "name": "Garner",
    "lng": -78.5900497965137,
    "lat": 35.711829778771055
  },
  {
    "name": "Garner",
    "lng": -78.5878645866212,
    "lat": 35.669870354823104
  },
  {
    "name": "Garner",
    "lng": -78.58040656361611,
    "lat": 35.684133908514475
  },
  {
    "name": "Garner",
    "lng": -78.57968228393366,
    "lat": 35.65502280935186
  },
  {
    "name": "Garner",
    "lng": -78.57587194579708,
    "lat": 35.67094181608328
  },
  {
    "name": "Garner",
    "lng": -78.57558985907066,
    "lat": 35.67286030993774
  },
  {
    "name": "Garner",
    "lng": -78.5692603702562,
    "lat": 35.65966961231661
  },
  {
    "name": "Garner",
    "lng": -78.56717030666353,
    "lat": 35.701327263432624
  },
  {
    "name": "Garner",
    "lng": -78.56666607701148,
    "lat": 35.68338834365273
  },
  {
    "name": "Garner",
    "lng": -78.56410950805534,
    "lat": 35.68053866194043
  },
  {
    "name": "Garner",
    "lng": -78.56385563470663,
    "lat": 35.682857413604786
  },
  {
    "name": "Garner",
    "lng": -78.56301063976255,
    "lat": 35.68999565088369
  },
  {
    "name": "Garner",
    "lng": -78.55896464926728,
    "lat": 35.68851638880761
  },
  {
    "name": "Garner",
    "lng": -78.55501369387119,
    "lat": 35.69223868204914
  },
  {
    "name": "Garner",
    "lng": -78.55260112700245,
    "lat": 35.681640709218556
  },
  {
    "name": "Garner",
    "lng": -78.5522818938261,
    "lat": 35.67608131566497
  },
  {
    "name": "Garner",
    "lng": -78.54955119308191,
    "lat": 35.68664132226364
  },
  {
    "name": "Garner",
    "lng": -78.54379884293769,
    "lat": 35.69955820063722
  },
  {
    "name": "Garner",
    "lng": -78.5339455135092,
    "lat": 35.680913152003
  },
  {
    "name": "Garner",
    "lng": -78.53338687086575,
    "lat": 35.68307597101747
  },
  {
    "name": "Garnett",
    "lng": -95.24250076105453,
    "lat": 38.28494610214524
  },
  {
    "name": "Garnett",
    "lng": -95.21474545824627,
    "lat": 38.27740418898844
  },
  {
    "name": "Garretson",
    "lng": -96.50274517724085,
    "lat": 43.714744477621196
  },
  {
    "name": "Garrett",
    "lng": -96.65393793846023,
    "lat": 32.355365466818014
  },
  {
    "name": "Garrett",
    "lng": -96.65070230699077,
    "lat": 32.36722974962058
  },
  {
    "name": "Garrett",
    "lng": -96.64754686602667,
    "lat": 32.361657964605286
  },
  {
    "name": "Garrett",
    "lng": -88.42464062233395,
    "lat": 39.79720463841901
  },
  {
    "name": "Garrett",
    "lng": -85.12349253069638,
    "lat": 41.35378086740288
  },
  {
    "name": "Garrettsville",
    "lng": -81.09336161724671,
    "lat": 41.284196057609606
  },
  {
    "name": "Garrison",
    "lng": -101.42178003908958,
    "lat": 47.653052218040884
  },
  {
    "name": "Garrison",
    "lng": -97.1633679947181,
    "lat": 41.17542747431407
  },
  {
    "name": "Garrison",
    "lng": -94.4931455531436,
    "lat": 31.82518560696492
  },
  {
    "name": "Garrison",
    "lng": -93.82657521574927,
    "lat": 46.29906673604967
  },
  {
    "name": "Garrison",
    "lng": -92.14450710006604,
    "lat": 42.14475687646746
  },
  {
    "name": "Garvin",
    "lng": -95.76061919365226,
    "lat": 44.21414673372436
  },
  {
    "name": "Garvin",
    "lng": -94.93378979430776,
    "lat": 33.95632142157559
  },
  {
    "name": "Garwin",
    "lng": -92.67911530581924,
    "lat": 42.09347823892666
  },
  {
    "name": "Gary",
    "lng": -96.45768817333337,
    "lat": 44.7949119669903
  },
  {
    "name": "Gary",
    "lng": -96.26557814309996,
    "lat": 47.37245982361567
  },
  {
    "name": "Gary",
    "lng": -87.34699937224488,
    "lat": 41.59077746423406
  },
  {
    "name": "Gary",
    "lng": -81.54174231813602,
    "lat": 37.3620151347974
  },
  {
    "name": "Gary",
    "lng": -81.50128215295382,
    "lat": 37.35767172935283
  },
  {
    "name": "Gary City",
    "lng": -94.36790050792978,
    "lat": 32.02772816183725
  },
  {
    "name": "Garysburg",
    "lng": -77.56038413612502,
    "lat": 36.447854261125926
  },
  {
    "name": "Gas",
    "lng": -95.3452682873948,
    "lat": 37.922878892490246
  },
  {
    "name": "Gas",
    "lng": -95.33684879116807,
    "lat": 37.93211215404796
  },
  {
    "name": "Gas City",
    "lng": -85.60111798900891,
    "lat": 40.489733210606225
  },
  {
    "name": "Gas City",
    "lng": -85.54673782900502,
    "lat": 40.479164986593304
  },
  {
    "name": "Gasconade",
    "lng": -91.55993473923523,
    "lat": 38.67026355041899
  },
  {
    "name": "Gascoyne",
    "lng": -103.0787586865151,
    "lat": 46.11897522061769
  },
  {
    "name": "Gasport",
    "lng": -78.57638635063826,
    "lat": 43.195030166664175
  },
  {
    "name": "Gassaway",
    "lng": -80.77223520415643,
    "lat": 38.67059491647593
  },
  {
    "name": "Gassaway",
    "lng": -80.76480425799792,
    "lat": 38.65697452674189
  },
  {
    "name": "Gassville",
    "lng": -92.486817072962,
    "lat": 36.28496538362756
  },
  {
    "name": "Gaston",
    "lng": -85.50086834921034,
    "lat": 40.31371944414872
  },
  {
    "name": "Gaston",
    "lng": -81.1008157411744,
    "lat": 33.81757896956917
  },
  {
    "name": "Gaston",
    "lng": -77.64483245296084,
    "lat": 36.49651866922649
  },
  {
    "name": "Gastonia",
    "lng": -81.29683913904492,
    "lat": 35.23744156613757
  },
  {
    "name": "Gastonia",
    "lng": -81.22129039981031,
    "lat": 35.30415317521579
  },
  {
    "name": "Gastonia",
    "lng": -81.2182832998453,
    "lat": 35.308442166631046
  },
  {
    "name": "Gastonia",
    "lng": -81.21762283120609,
    "lat": 35.21992513603595
  },
  {
    "name": "Gastonia",
    "lng": -81.20617152947666,
    "lat": 35.20767998371194
  },
  {
    "name": "Gastonia",
    "lng": -81.20557265478615,
    "lat": 35.20831816808834
  },
  {
    "name": "Gastonia",
    "lng": -81.1997481817345,
    "lat": 35.22101978191858
  },
  {
    "name": "Gastonia",
    "lng": -81.19923864769527,
    "lat": 35.16610137270165
  },
  {
    "name": "Gastonia",
    "lng": -81.18392038214824,
    "lat": 35.22228529989866
  },
  {
    "name": "Gastonia",
    "lng": -81.18314936221877,
    "lat": 35.249241204992
  },
  {
    "name": "Gastonia",
    "lng": -81.14921578330667,
    "lat": 35.186832044556475
  },
  {
    "name": "Gastonia",
    "lng": -81.14381707820938,
    "lat": 35.18431680446742
  },
  {
    "name": "Gastonia",
    "lng": -81.14204143874422,
    "lat": 35.186177082883376
  },
  {
    "name": "Gastonia",
    "lng": -81.09627957467576,
    "lat": 35.1977225249721
  },
  {
    "name": "Gate",
    "lng": -100.05521751744612,
    "lat": 36.85169438061704
  },
  {
    "name": "Gate City",
    "lng": -82.57817748367619,
    "lat": 36.640103455410156
  },
  {
    "name": "Gates",
    "lng": -89.40808812285354,
    "lat": 35.84061785991298
  },
  {
    "name": "Gates Mills",
    "lng": -81.40995746756704,
    "lat": 41.53149693131935
  },
  {
    "name": "Gatesville",
    "lng": -97.73567355165844,
    "lat": 31.442422936175948
  },
  {
    "name": "Gatesville",
    "lng": -97.70287475196753,
    "lat": 31.416423828009112
  },
  {
    "name": "Gatesville",
    "lng": -76.75659165828469,
    "lat": 36.407355698761144
  },
  {
    "name": "Gateway",
    "lng": -93.93653418645394,
    "lat": 36.48549499529297
  },
  {
    "name": "Gatlinburg",
    "lng": -83.49357646695893,
    "lat": 35.7249071337626
  },
  {
    "name": "Gattman",
    "lng": -88.23657385989831,
    "lat": 33.88484852751268
  },
  {
    "name": "Gauley Bridge",
    "lng": -81.20387782437554,
    "lat": 38.1680989121574
  },
  {
    "name": "Gautier",
    "lng": -88.65614591753514,
    "lat": 30.40914697933376
  },
  {
    "name": "Gaylord",
    "lng": -98.84695898493851,
    "lat": 39.64626527026643
  },
  {
    "name": "Gaylord",
    "lng": -94.23015059450938,
    "lat": 44.55016491461011
  },
  {
    "name": "Gaylord",
    "lng": -94.21192668458876,
    "lat": 44.55552215681517
  },
  {
    "name": "Gaylord",
    "lng": -84.72091915371826,
    "lat": 45.02442046931404
  },
  {
    "name": "Gaylord",
    "lng": -84.71570048879107,
    "lat": 45.0071025688651
  },
  {
    "name": "Gaylord",
    "lng": -84.67594049722453,
    "lat": 45.02192896405566
  },
  {
    "name": "Gaylord",
    "lng": -84.67498188197044,
    "lat": 45.04096833161437
  },
  {
    "name": "Gays",
    "lng": -88.49635431597949,
    "lat": 39.458152959527524
  },
  {
    "name": "Gays Mills",
    "lng": -90.84896955842714,
    "lat": 43.321795019104094
  },
  {
    "name": "Gayville",
    "lng": -97.17343945092932,
    "lat": 42.889499553993
  },
  {
    "name": "Geary",
    "lng": -98.29143146302265,
    "lat": 35.59277893616156
  },
  {
    "name": "Geddes",
    "lng": -98.69785563876712,
    "lat": 43.25325075827465
  },
  {
    "name": "Geiger",
    "lng": -88.31312173341715,
    "lat": 32.86961099329495
  },
  {
    "name": "Gem",
    "lng": -100.89723854548073,
    "lat": 39.42597963478328
  },
  {
    "name": "Gem Lake",
    "lng": -93.04051887482987,
    "lat": 45.05803960515324
  },
  {
    "name": "Gene Autry",
    "lng": -97.03863853712454,
    "lat": 34.28414928845781
  },
  {
    "name": "Genesee",
    "lng": -116.92838922724748,
    "lat": 46.551608213088905
  },
  {
    "name": "Geneseo",
    "lng": -98.15446562421883,
    "lat": 38.51697589608672
  },
  {
    "name": "Geneseo",
    "lng": -90.15400142613521,
    "lat": 41.45079980131839
  },
  {
    "name": "Geneseo",
    "lng": -77.8095527098644,
    "lat": 42.798946447798045
  },
  {
    "name": "Geneva",
    "lng": -97.60122838315182,
    "lat": 40.527049505980095
  },
  {
    "name": "Geneva",
    "lng": -93.26825300839988,
    "lat": 43.82278214954953
  },
  {
    "name": "Geneva",
    "lng": -93.12974225454263,
    "lat": 42.67489915636534
  },
  {
    "name": "Geneva",
    "lng": -88.32431942496278,
    "lat": 41.88326556595941
  },
  {
    "name": "Geneva",
    "lng": -85.87635568836828,
    "lat": 31.043706382647635
  },
  {
    "name": "Geneva",
    "lng": -84.95655211699582,
    "lat": 40.597381347355665
  },
  {
    "name": "Geneva",
    "lng": -80.94948490867982,
    "lat": 41.856050882672875
  },
  {
    "name": "Geneva",
    "lng": -80.94604789196988,
    "lat": 41.80033975298792
  },
  {
    "name": "Geneva",
    "lng": -76.98263886299178,
    "lat": 42.86441316566126
  },
  {
    "name": "Geneva",
    "lng": -76.97783061988353,
    "lat": 42.88641936667018
  },
  {
    "name": "Genoa",
    "lng": -103.49917349026266,
    "lat": 39.278237847147075
  },
  {
    "name": "Genoa",
    "lng": -97.73219934902778,
    "lat": 41.446213170113495
  },
  {
    "name": "Genoa",
    "lng": -91.22647591567596,
    "lat": 43.57305184479352
  },
  {
    "name": "Genoa",
    "lng": -88.69740767050746,
    "lat": 42.093591674988026
  },
  {
    "name": "Genoa",
    "lng": -88.68576572655064,
    "lat": 42.08208008309446
  },
  {
    "name": "Genoa",
    "lng": -83.36176802215658,
    "lat": 41.51994155113501
  },
  {
    "name": "Genoa City",
    "lng": -88.3272817480338,
    "lat": 42.50575904873317
  },
  {
    "name": "Genoa City",
    "lng": -88.31481608822827,
    "lat": 42.49569589467346
  },
  {
    "name": "Genoa City",
    "lng": -88.30861492942637,
    "lat": 42.507803680403605
  },
  {
    "name": "Genola",
    "lng": -111.84646026539309,
    "lat": 40.01173683699964
  },
  {
    "name": "Genola",
    "lng": -94.11286764684911,
    "lat": 45.96562309932314
  },
  {
    "name": "Gentry",
    "lng": -94.49294420462432,
    "lat": 36.256989333135024
  },
  {
    "name": "Gentry",
    "lng": -94.46050849082226,
    "lat": 36.2586733676445
  },
  {
    "name": "Gentry",
    "lng": -94.4232578367252,
    "lat": 40.33256845124629
  },
  {
    "name": "Gentryville",
    "lng": -87.03152226591385,
    "lat": 38.10911866966574
  },
  {
    "name": "George",
    "lng": -119.8638799679251,
    "lat": 47.081545523690494
  },
  {
    "name": "George",
    "lng": -119.86316829876314,
    "lat": 47.101765526002325
  },
  {
    "name": "George",
    "lng": -119.85799535323157,
    "lat": 47.08570693566881
  },
  {
    "name": "George",
    "lng": -119.85712790924157,
    "lat": 47.077832790606465
  },
  {
    "name": "George",
    "lng": -96.00347611634673,
    "lat": 43.34197857689643
  },
  {
    "name": "George Washington Management Area",
    "lng": -71.778172530627,
    "lat": 41.92447412347649
  },
  {
    "name": "George Washington Management Area",
    "lng": -71.7638960837838,
    "lat": 41.94070912624593
  },
  {
    "name": "Georgetown",
    "lng": -111.36349473567657,
    "lat": 42.47908353413242
  },
  {
    "name": "Georgetown",
    "lng": -105.69503456782773,
    "lat": 39.71759244154131
  },
  {
    "name": "Georgetown",
    "lng": -97.69471355802382,
    "lat": 30.667266776898014
  },
  {
    "name": "Georgetown",
    "lng": -96.79592065450711,
    "lat": 47.078371217458766
  },
  {
    "name": "Georgetown",
    "lng": -92.38203517136904,
    "lat": 31.759717570782612
  },
  {
    "name": "Georgetown",
    "lng": -91.45392312877317,
    "lat": 35.12667657851351
  },
  {
    "name": "Georgetown",
    "lng": -90.16478199900403,
    "lat": 31.869799810358042
  },
  {
    "name": "Georgetown",
    "lng": -87.63538114526523,
    "lat": 39.977411442930844
  },
  {
    "name": "Georgetown",
    "lng": -85.96208106146081,
    "lat": 38.29533455588016
  },
  {
    "name": "Georgetown",
    "lng": -84.54756037343704,
    "lat": 38.225746679996874
  },
  {
    "name": "Georgetown",
    "lng": -83.90931642195655,
    "lat": 38.862071853982414
  },
  {
    "name": "Georgetown",
    "lng": -83.89858434906154,
    "lat": 38.869936172308876
  },
  {
    "name": "Georgetown",
    "lng": -79.29593944731671,
    "lat": 33.36282484242785
  },
  {
    "name": "Georgetown",
    "lng": -69.73354609121829,
    "lat": 43.7609476296446
  },
  {
    "name": "Georgia",
    "lng": -83.43249652272539,
    "lat": 32.66270822058923
  },
  {
    "name": "Georgiana",
    "lng": -86.74664980414836,
    "lat": 31.63918113357113
  },
  {
    "name": "Gerald",
    "lng": -91.33040428306745,
    "lat": 38.399166435187546
  },
  {
    "name": "Geraldine",
    "lng": -110.26717468757388,
    "lat": 47.60287090740367
  },
  {
    "name": "Geraldine",
    "lng": -86.00370598444245,
    "lat": 34.35772324854552
  },
  {
    "name": "Gering",
    "lng": -103.67890642523022,
    "lat": 41.812750976613344
  },
  {
    "name": "Gering",
    "lng": -103.66599399703998,
    "lat": 41.82861334824487
  },
  {
    "name": "Gering",
    "lng": -103.65036795475395,
    "lat": 41.813132558279065
  },
  {
    "name": "Gering",
    "lng": -103.64133756794403,
    "lat": 41.81285522176924
  },
  {
    "name": "German Valley",
    "lng": -89.48450266054827,
    "lat": 42.21423615756715
  },
  {
    "name": "Germantown",
    "lng": -89.78254992428099,
    "lat": 35.08297635627184
  },
  {
    "name": "Germantown",
    "lng": -89.54125593744939,
    "lat": 38.555219580336356
  },
  {
    "name": "Germantown",
    "lng": -84.3639162809788,
    "lat": 39.632094429448316
  },
  {
    "name": "Germantown",
    "lng": -83.96415652669234,
    "lat": 38.65592223351876
  },
  {
    "name": "Germantown Hills",
    "lng": -89.46705550876023,
    "lat": 40.77114901912345
  },
  {
    "name": "Germantown Hills",
    "lng": -89.44136338615068,
    "lat": 40.77273321977661
  },
  {
    "name": "Geronimo",
    "lng": -98.3891731444481,
    "lat": 34.48512464928857
  },
  {
    "name": "Gerster",
    "lng": -93.57682139273227,
    "lat": 37.95469138972345
  },
  {
    "name": "Gerty",
    "lng": -96.28895465054404,
    "lat": 34.83567038200906
  },
  {
    "name": "Gettysburg",
    "lng": -99.95370368338634,
    "lat": 45.00585885189919
  },
  {
    "name": "Gettysburg",
    "lng": -84.49641723689504,
    "lat": 40.115572401136916
  },
  {
    "name": "Gettysburg",
    "lng": -77.23386910241447,
    "lat": 39.83046705942715
  },
  {
    "name": "Geuda Springs",
    "lng": -97.15030841322175,
    "lat": 37.11148123668022
  },
  {
    "name": "Ghent",
    "lng": -95.89247743540056,
    "lat": 44.51159605007876
  },
  {
    "name": "Ghent",
    "lng": -85.0615270007624,
    "lat": 38.733990029639344
  },
  {
    "name": "Gholson",
    "lng": -97.23470440604228,
    "lat": 31.74082646669425
  },
  {
    "name": "Gholson",
    "lng": -97.23409997806826,
    "lat": 31.710397034551132
  },
  {
    "name": "Gibbon",
    "lng": -98.84573552378612,
    "lat": 40.7464248330216
  },
  {
    "name": "Gibbon",
    "lng": -94.52418515398271,
    "lat": 44.533238625434805
  },
  {
    "name": "Gibbs",
    "lng": -92.41662007078679,
    "lat": 40.0990166625291
  },
  {
    "name": "Gibraltar",
    "lng": -83.20264048858071,
    "lat": 42.09603345451482
  },
  {
    "name": "Gibsland",
    "lng": -93.05388746443191,
    "lat": 32.539272070121676
  },
  {
    "name": "Gibson",
    "lng": -92.39226321503949,
    "lat": 41.48023926368109
  },
  {
    "name": "Gibson",
    "lng": -88.84432212317213,
    "lat": 35.87451589825699
  },
  {
    "name": "Gibson",
    "lng": -82.59586069775823,
    "lat": 33.233335850794894
  },
  {
    "name": "Gibson",
    "lng": -79.60689898667374,
    "lat": 34.75947454522672
  },
  {
    "name": "Gibson City",
    "lng": -88.37882074843235,
    "lat": 40.46644999399295
  },
  {
    "name": "Gibsonburg",
    "lng": -83.3220207101861,
    "lat": 41.38595023874964
  },
  {
    "name": "Gibsonville",
    "lng": -79.56143781555913,
    "lat": 36.082500718347966
  },
  {
    "name": "Gibsonville",
    "lng": -79.54080091097883,
    "lat": 36.1007746038805
  },
  {
    "name": "Gideon",
    "lng": -89.91276454186206,
    "lat": 36.44887655722085
  },
  {
    "name": "Gifford",
    "lng": -88.02139837142587,
    "lat": 40.307792634614614
  },
  {
    "name": "Gifford",
    "lng": -81.23856510181197,
    "lat": 32.86008379660026
  },
  {
    "name": "Gig Harbor",
    "lng": -122.59393796203197,
    "lat": 47.33170560323915
  },
  {
    "name": "Gilbert",
    "lng": -93.64622872365102,
    "lat": 42.10719008927477
  },
  {
    "name": "Gilbert",
    "lng": -92.71659669461468,
    "lat": 35.99047091367162
  },
  {
    "name": "Gilbert",
    "lng": -92.46184921938764,
    "lat": 47.49095530315751
  },
  {
    "name": "Gilbert",
    "lng": -91.65581777264964,
    "lat": 32.050434386929616
  },
  {
    "name": "Gilbert",
    "lng": -81.86888954268584,
    "lat": 37.6141950146913
  },
  {
    "name": "Gilbert",
    "lng": -81.39312024980691,
    "lat": 33.92342442857782
  },
  {
    "name": "Gilbertown",
    "lng": -88.31948878599212,
    "lat": 31.875790812113813
  },
  {
    "name": "Gilberts",
    "lng": -88.3716167488916,
    "lat": 42.10972602857776
  },
  {
    "name": "Gilbertsville",
    "lng": -75.32250013244965,
    "lat": 42.46936714171167
  },
  {
    "name": "Gilbertsville",
    "lng": -75.32009370730424,
    "lat": 42.47733697208291
  },
  {
    "name": "Gilbertville",
    "lng": -92.2140286221507,
    "lat": 42.418322722443726
  },
  {
    "name": "Gilboa",
    "lng": -83.92170196624839,
    "lat": 41.018772267377265
  },
  {
    "name": "Gilby",
    "lng": -97.46770314671919,
    "lat": 48.08370082380852
  },
  {
    "name": "Gilcrest",
    "lng": -104.78172196399787,
    "lat": 40.2839630874367
  },
  {
    "name": "Gilcrest",
    "lng": -104.77853160995052,
    "lat": 40.29041575709535
  },
  {
    "name": "Gilead",
    "lng": -97.41505226859769,
    "lat": 40.14609328272285
  },
  {
    "name": "Gilead",
    "lng": -70.94974522684136,
    "lat": 44.39986880452159
  },
  {
    "name": "Gill",
    "lng": -72.5111310444676,
    "lat": 42.633738372416154
  },
  {
    "name": "Gillespie",
    "lng": -89.81710645517116,
    "lat": 39.1258702190919
  },
  {
    "name": "Gillett",
    "lng": -91.37881662661046,
    "lat": 34.118889160347095
  },
  {
    "name": "Gillett",
    "lng": -88.30643240361029,
    "lat": 44.8896536634974
  },
  {
    "name": "Gillett Grove",
    "lng": -95.03655395890922,
    "lat": 43.01603688028873
  },
  {
    "name": "Gillette",
    "lng": -105.4983367556059,
    "lat": 44.275354475398586
  },
  {
    "name": "Gillette",
    "lng": -105.4870242278812,
    "lat": 44.234259865803985
  },
  {
    "name": "Gillham",
    "lng": -94.31452452144325,
    "lat": 34.168861042232514
  },
  {
    "name": "Gilliam",
    "lng": -93.84328718257143,
    "lat": 32.82896907547716
  },
  {
    "name": "Gilliam",
    "lng": -93.00412661466734,
    "lat": 39.23273835317534
  },
  {
    "name": "Gillsville",
    "lng": -83.6383814931223,
    "lat": 34.30775078365153
  },
  {
    "name": "Gilman",
    "lng": -93.94855915325954,
    "lat": 45.73529537386474
  },
  {
    "name": "Gilman",
    "lng": -92.78815084790428,
    "lat": 41.87885258829916
  },
  {
    "name": "Gilman",
    "lng": -90.8071765220817,
    "lat": 45.16620663682599
  },
  {
    "name": "Gilman",
    "lng": -87.99717192671838,
    "lat": 40.76308915188287
  },
  {
    "name": "Gilman City",
    "lng": -93.8739142919822,
    "lat": 40.13121559591502
  },
  {
    "name": "Gilmer",
    "lng": -94.94660017508542,
    "lat": 32.73190121675144
  },
  {
    "name": "Gilmore",
    "lng": -90.29731872507436,
    "lat": 35.41317050386744
  },
  {
    "name": "Gilmore City",
    "lng": -94.43668997537571,
    "lat": 42.72667672571419
  },
  {
    "name": "Gilroy",
    "lng": -121.58414835724255,
    "lat": 37.00484422734306
  },
  {
    "name": "Gilt Edge",
    "lng": -89.83027492166524,
    "lat": 35.53361147615548
  },
  {
    "name": "Giltner",
    "lng": -98.15426172594728,
    "lat": 40.77383676937493
  },
  {
    "name": "Ginger Blue",
    "lng": -94.45972065927349,
    "lat": 36.58940591136075
  },
  {
    "name": "Girard",
    "lng": -94.86546895507784,
    "lat": 37.510064165321516
  },
  {
    "name": "Girard",
    "lng": -94.8457473407569,
    "lat": 37.5091694815041
  },
  {
    "name": "Girard",
    "lng": -94.82021735881628,
    "lat": 37.50983093024124
  },
  {
    "name": "Girard",
    "lng": -89.78204652972312,
    "lat": 39.44657267894978
  },
  {
    "name": "Girard",
    "lng": -81.712170212672,
    "lat": 33.03994571645177
  },
  {
    "name": "Girard",
    "lng": -80.69607490265312,
    "lat": 41.16589555565305
  },
  {
    "name": "Gladbrook",
    "lng": -92.71503283230966,
    "lat": 42.18602597703032
  },
  {
    "name": "Glade",
    "lng": -99.31154991794064,
    "lat": 39.68311340387274
  },
  {
    "name": "Glade Spring",
    "lng": -81.77251187029952,
    "lat": 36.789965853422935
  },
  {
    "name": "Gladewater",
    "lng": -94.94544561522373,
    "lat": 32.542593147427155
  },
  {
    "name": "Gladstone",
    "lng": -102.56944806901743,
    "lat": 46.859394480610995
  },
  {
    "name": "Gladstone",
    "lng": -94.55924776718516,
    "lat": 39.21338072621592
  },
  {
    "name": "Gladstone",
    "lng": -90.95670194285188,
    "lat": 40.86401427487286
  },
  {
    "name": "Gladstone",
    "lng": -87.03566494378215,
    "lat": 45.852285482197445
  },
  {
    "name": "Gladstone",
    "lng": -87.01634923816721,
    "lat": 45.86070796347231
  },
  {
    "name": "Gladwin",
    "lng": -84.48771118611583,
    "lat": 43.98361783184382
  },
  {
    "name": "Glandorf",
    "lng": -84.07897338301314,
    "lat": 41.02780243873074
  },
  {
    "name": "Glasco",
    "lng": -97.83686141089252,
    "lat": 39.36101786441255
  },
  {
    "name": "Glasford",
    "lng": -89.81309856127771,
    "lat": 40.57290549322213
  },
  {
    "name": "Glasgow",
    "lng": -106.63222504631986,
    "lat": 48.19869390235298
  },
  {
    "name": "Glasgow",
    "lng": -92.83936275297012,
    "lat": 39.22792913128173
  },
  {
    "name": "Glasgow",
    "lng": -90.47999189983462,
    "lat": 39.5487172795868
  },
  {
    "name": "Glasgow",
    "lng": -85.92557739806996,
    "lat": 37.00484613080717
  },
  {
    "name": "Glasgow",
    "lng": -81.42177018969277,
    "lat": 38.210880935171076
  },
  {
    "name": "Glasgow",
    "lng": -79.45147479073802,
    "lat": 37.6347831850623
  },
  {
    "name": "Glastonbury",
    "lng": -72.54725425061535,
    "lat": 41.69213200152103
  },
  {
    "name": "Gleason",
    "lng": -88.61069205243753,
    "lat": 36.21737602913733
  },
  {
    "name": "Glen",
    "lng": -88.41992497653366,
    "lat": 34.858475519748936
  },
  {
    "name": "Glen Allen",
    "lng": -90.0282344551782,
    "lat": 37.31705114342209
  },
  {
    "name": "Glen Allen",
    "lng": -90.02552866805247,
    "lat": 37.317348356170854
  },
  {
    "name": "Glen Allen",
    "lng": -87.73071254835483,
    "lat": 33.89601901993634
  },
  {
    "name": "Glen Alpine",
    "lng": -81.81541449879023,
    "lat": 35.76812544351953
  },
  {
    "name": "Glen Alpine",
    "lng": -81.78280151684675,
    "lat": 35.72990590200009
  },
  {
    "name": "Glen Carbon",
    "lng": -89.98298063135692,
    "lat": 38.75760478549346
  },
  {
    "name": "Glen Carbon",
    "lng": -89.95367907715132,
    "lat": 38.78569109180505
  },
  {
    "name": "Glen Cove",
    "lng": -73.64504180418389,
    "lat": 40.8910737595967
  },
  {
    "name": "Glen Dale",
    "lng": -80.75621144140209,
    "lat": 39.946181803214216
  },
  {
    "name": "Glen Echo",
    "lng": -77.1411027011247,
    "lat": 38.96813032917826
  },
  {
    "name": "Glen Echo Park",
    "lng": -90.29628068326343,
    "lat": 38.70084723803507
  },
  {
    "name": "Glen Elder",
    "lng": -98.30637535996952,
    "lat": 39.50013889449741
  },
  {
    "name": "Glen Ellyn",
    "lng": -88.0633868336946,
    "lat": 41.867009504458885
  },
  {
    "name": "Glen Ellyn",
    "lng": -88.05069862790727,
    "lat": 41.89767888093336
  },
  {
    "name": "Glen Ellyn",
    "lng": -88.04620726228536,
    "lat": 41.89766448802274
  },
  {
    "name": "Glen Flora",
    "lng": -90.89347919391754,
    "lat": 45.49695707670813
  },
  {
    "name": "Glen Lyn",
    "lng": -80.85826427595569,
    "lat": 37.37274168439953
  },
  {
    "name": "Glen Park",
    "lng": -75.95477251559547,
    "lat": 44.00151516315492
  },
  {
    "name": "Glen Rock",
    "lng": -76.73070277711676,
    "lat": 39.793498400599724
  },
  {
    "name": "Glen Rose",
    "lng": -97.74410818731968,
    "lat": 32.2462087575858
  },
  {
    "name": "Glen Ullin",
    "lng": -101.83194948911418,
    "lat": 46.81220653830649
  },
  {
    "name": "Glenaire",
    "lng": -94.45159537261934,
    "lat": 39.21973880458789
  },
  {
    "name": "Glenbeulah",
    "lng": -88.04680983385241,
    "lat": 43.79842538987994
  },
  {
    "name": "Glenburn",
    "lng": -101.2209886498059,
    "lat": 48.51303759813905
  },
  {
    "name": "Glenburn",
    "lng": -68.84883575743022,
    "lat": 44.9076342955824
  },
  {
    "name": "Glencoe",
    "lng": -96.93056112673969,
    "lat": 36.229106380275425
  },
  {
    "name": "Glencoe",
    "lng": -96.92873999780748,
    "lat": 36.24200485536939
  },
  {
    "name": "Glencoe",
    "lng": -94.16979109087372,
    "lat": 44.76760159204899
  },
  {
    "name": "Glencoe",
    "lng": -94.15234252257714,
    "lat": 44.759825646317424
  },
  {
    "name": "Glencoe",
    "lng": -94.15084857142857,
    "lat": 44.770002898388434
  },
  {
    "name": "Glencoe",
    "lng": -94.12652422767408,
    "lat": 44.76728169292143
  },
  {
    "name": "Glencoe",
    "lng": -87.76408119991095,
    "lat": 42.13472852228866
  },
  {
    "name": "Glencoe",
    "lng": -85.98527485686166,
    "lat": 33.91819864315383
  },
  {
    "name": "Glencoe",
    "lng": -85.96047201610263,
    "lat": 33.97231651130945
  },
  {
    "name": "Glencoe",
    "lng": -85.94701798372947,
    "lat": 33.9964577662514
  },
  {
    "name": "Glencoe",
    "lng": -85.93207603498381,
    "lat": 33.94484717730727
  },
  {
    "name": "Glencoe",
    "lng": -85.90379545493165,
    "lat": 33.95585186914426
  },
  {
    "name": "Glencoe",
    "lng": -84.82420742400491,
    "lat": 38.725188109928034
  },
  {
    "name": "Glendale",
    "lng": -118.24682814629402,
    "lat": 34.181925241385464
  },
  {
    "name": "Glendale",
    "lng": -112.60349545144089,
    "lat": 37.33425206005072
  },
  {
    "name": "Glendale",
    "lng": -104.93519029034972,
    "lat": 39.703584061376766
  },
  {
    "name": "Glendale",
    "lng": -104.92890966819974,
    "lat": 39.70160299633886
  },
  {
    "name": "Glendale",
    "lng": -90.38255631576729,
    "lat": 38.593526522045195
  },
  {
    "name": "Glendale",
    "lng": -87.92776521578135,
    "lat": 43.128985421353086
  },
  {
    "name": "Glendale",
    "lng": -84.45829483460562,
    "lat": 39.27044584412587
  },
  {
    "name": "Glendale Heights",
    "lng": -88.07842448870825,
    "lat": 41.91949412051115
  },
  {
    "name": "Glendive",
    "lng": -104.72398403359311,
    "lat": 47.111461086915874
  },
  {
    "name": "Glendive",
    "lng": -104.70699414826932,
    "lat": 47.110294852597534
  },
  {
    "name": "Glendo",
    "lng": -105.02539968247189,
    "lat": 42.504142320055855
  },
  {
    "name": "Glendora",
    "lng": -117.84657048853956,
    "lat": 34.144965262185416
  },
  {
    "name": "Glendora",
    "lng": -90.2926961964423,
    "lat": 33.827644951779135
  },
  {
    "name": "Glenfield",
    "lng": -98.56616255119701,
    "lat": 47.45491941157614
  },
  {
    "name": "Glenford",
    "lng": -82.31959659189701,
    "lat": 39.88655540372761
  },
  {
    "name": "Glenham",
    "lng": -100.27111792585632,
    "lat": 45.532994520142495
  },
  {
    "name": "Glenmont",
    "lng": -82.0921609748915,
    "lat": 40.51904273500128
  },
  {
    "name": "Glenmora",
    "lng": -92.5823771343474,
    "lat": 30.973331824123076
  },
  {
    "name": "Glenn Heights",
    "lng": -96.85474612729153,
    "lat": 32.55060002035997
  },
  {
    "name": "Glenns Ferry",
    "lng": -115.30703906845457,
    "lat": 42.95036168587836
  },
  {
    "name": "Glennville",
    "lng": -81.93033918385255,
    "lat": 31.939488415160046
  },
  {
    "name": "Glenpool",
    "lng": -96.00468785083564,
    "lat": 35.949693942393026
  },
  {
    "name": "Glenrock",
    "lng": -105.86235689012133,
    "lat": 42.85646713535222
  },
  {
    "name": "Glens Falls",
    "lng": -73.64603034017571,
    "lat": 43.3106745048436
  },
  {
    "name": "Glenview",
    "lng": -87.82121912463114,
    "lat": 42.08256468550845
  },
  {
    "name": "Glenview",
    "lng": -85.6518144321582,
    "lat": 38.302386357408736
  },
  {
    "name": "Glenview Hills",
    "lng": -85.63839325591262,
    "lat": 38.29524294272293
  },
  {
    "name": "Glenview Manor",
    "lng": -85.63320860786469,
    "lat": 38.290664014707474
  },
  {
    "name": "Glenvil",
    "lng": -98.25454553608654,
    "lat": 40.50267905456906
  },
  {
    "name": "Glenville",
    "lng": -93.28011008579782,
    "lat": 43.57323122399636
  },
  {
    "name": "Glenville",
    "lng": -80.83394326227233,
    "lat": 38.93798910764614
  },
  {
    "name": "Glenwillow",
    "lng": -81.47274731739573,
    "lat": 41.36070338223532
  },
  {
    "name": "Glenwood",
    "lng": -111.98759648441225,
    "lat": 38.76244608597518
  },
  {
    "name": "Glenwood",
    "lng": -95.74040739731812,
    "lat": 41.044581046493086
  },
  {
    "name": "Glenwood",
    "lng": -95.36650931511333,
    "lat": 45.65334916161118
  },
  {
    "name": "Glenwood",
    "lng": -95.33052219005138,
    "lat": 45.62516273717621
  },
  {
    "name": "Glenwood",
    "lng": -92.57660856544152,
    "lat": 40.5243034524263
  },
  {
    "name": "Glenwood",
    "lng": -87.6121128687693,
    "lat": 41.54149216090083
  },
  {
    "name": "Glenwood",
    "lng": -86.17297442591207,
    "lat": 31.667540922694485
  },
  {
    "name": "Glenwood",
    "lng": -85.30209677614528,
    "lat": 39.62612857254068
  },
  {
    "name": "Glenwood",
    "lng": -82.67119272378766,
    "lat": 32.18051648072193
  },
  {
    "name": "Glenwood City",
    "lng": -92.16998954973099,
    "lat": 45.056419943073934
  },
  {
    "name": "Glenwood Springs",
    "lng": -107.33473490572516,
    "lat": 39.54564984402992
  },
  {
    "name": "Glidden",
    "lng": -94.72662861465582,
    "lat": 42.057667128528784
  },
  {
    "name": "Globe",
    "lng": -110.751237260823,
    "lat": 33.38691772738711
  },
  {
    "name": "Glocester",
    "lng": -71.6891853447135,
    "lat": 41.89338080145693
  },
  {
    "name": "Gloria Glens Park",
    "lng": -81.90084187333846,
    "lat": 41.05776329467454
  },
  {
    "name": "Gloster",
    "lng": -91.01772226055874,
    "lat": 31.195038221773288
  },
  {
    "name": "Gloucester City",
    "lng": -75.11729961231569,
    "lat": 39.89241791650522
  },
  {
    "name": "Glouster",
    "lng": -82.08418394015567,
    "lat": 39.501990099896105
  },
  {
    "name": "Glyndon",
    "lng": -96.57845389760423,
    "lat": 46.87084753728417
  },
  {
    "name": "Gnadenhutten",
    "lng": -81.42928596772722,
    "lat": 40.359458491261606
  },
  {
    "name": "Gobles",
    "lng": -85.87745861036758,
    "lat": 42.361603636068054
  },
  {
    "name": "Goddard",
    "lng": -97.56374314451413,
    "lat": 37.664697409940224
  },
  {
    "name": "Goddard",
    "lng": -97.54189877458181,
    "lat": 37.660746581271844
  },
  {
    "name": "Goddard",
    "lng": -97.53773797690666,
    "lat": 37.65726972347073
  },
  {
    "name": "Godfrey",
    "lng": -90.21562439195144,
    "lat": 38.95814226348136
  },
  {
    "name": "Godley",
    "lng": -97.53219854683073,
    "lat": 32.45126178913985
  },
  {
    "name": "Godley",
    "lng": -88.24515811062237,
    "lat": 41.2384905296111
  },
  {
    "name": "Godwin",
    "lng": -78.68086431530152,
    "lat": 35.217196662880156
  },
  {
    "name": "Goehner",
    "lng": -97.2196513718378,
    "lat": 40.83268517040133
  },
  {
    "name": "Goessel",
    "lng": -97.34574057193268,
    "lat": 38.24690114093189
  },
  {
    "name": "Goff",
    "lng": -95.93177808201249,
    "lat": 39.664142315043584
  },
  {
    "name": "Goffstown",
    "lng": -71.56783277160753,
    "lat": 43.01927821431232
  },
  {
    "name": "Golconda",
    "lng": -88.48723011427877,
    "lat": 37.36219202005816
  },
  {
    "name": "Gold Bar",
    "lng": -121.6918921386303,
    "lat": 47.855784907827605
  },
  {
    "name": "Golden",
    "lng": -105.21268930852425,
    "lat": 39.74197030662407
  },
  {
    "name": "Golden",
    "lng": -105.21268929116935,
    "lat": 39.74197273906746
  },
  {
    "name": "Golden",
    "lng": -91.01918252250846,
    "lat": 40.11031405152542
  },
  {
    "name": "Golden",
    "lng": -88.18724553916663,
    "lat": 34.487121233525336
  },
  {
    "name": "Golden City",
    "lng": -94.09425487639716,
    "lat": 37.3931037985484
  },
  {
    "name": "Golden Gate",
    "lng": -88.20468284418139,
    "lat": 38.35888455209114
  },
  {
    "name": "Golden Hills",
    "lng": -118.50239828011796,
    "lat": 35.151249614846925
  },
  {
    "name": "Golden Meadow",
    "lng": -90.27389457006257,
    "lat": 29.387593173605097
  },
  {
    "name": "Golden Oak",
    "lng": -81.54131782942977,
    "lat": 28.401413006910936
  },
  {
    "name": "Golden Oak",
    "lng": -81.53865672385075,
    "lat": 28.393299198080047
  },
  {
    "name": "Golden Valley",
    "lng": -102.06549816187906,
    "lat": 47.290096084710264
  },
  {
    "name": "Golden Valley",
    "lng": -93.35910546651773,
    "lat": 44.990054197257194
  },
  {
    "name": "Goldendale",
    "lng": -120.81911278131766,
    "lat": 45.82137504471927
  },
  {
    "name": "Goldfield",
    "lng": -93.92057378658333,
    "lat": 42.736089052825285
  },
  {
    "name": "Goldonna",
    "lng": -92.9159934901074,
    "lat": 32.019401292305034
  },
  {
    "name": "Goldsboro",
    "lng": -78.06295108881696,
    "lat": 35.431173871695904
  },
  {
    "name": "Goldsboro",
    "lng": -78.06257664933666,
    "lat": 35.42784901365365
  },
  {
    "name": "Goldsboro",
    "lng": -78.04433940279094,
    "lat": 35.416015956599615
  },
  {
    "name": "Goldsboro",
    "lng": -78.04010840910912,
    "lat": 35.3318378629004
  },
  {
    "name": "Goldsboro",
    "lng": -78.03721855684837,
    "lat": 35.32040553806103
  },
  {
    "name": "Goldsboro",
    "lng": -78.03620304618258,
    "lat": 35.328772320272435
  },
  {
    "name": "Goldsboro",
    "lng": -78.03611124330368,
    "lat": 35.335026519424886
  },
  {
    "name": "Goldsboro",
    "lng": -78.03560872882302,
    "lat": 35.411368948240835
  },
  {
    "name": "Goldsboro",
    "lng": -78.03528264508397,
    "lat": 35.32784313999967
  },
  {
    "name": "Goldsboro",
    "lng": -78.03432137373653,
    "lat": 35.41050913672133
  },
  {
    "name": "Goldsboro",
    "lng": -78.0311272949714,
    "lat": 35.33932573068104
  },
  {
    "name": "Goldsboro",
    "lng": -78.0303716733389,
    "lat": 35.342899566676024
  },
  {
    "name": "Goldsboro",
    "lng": -78.02921800304381,
    "lat": 35.342486712170334
  },
  {
    "name": "Goldsboro",
    "lng": -78.02417776586094,
    "lat": 35.34771051553781
  },
  {
    "name": "Goldsboro",
    "lng": -78.02320110430749,
    "lat": 35.40473859627228
  },
  {
    "name": "Goldsboro",
    "lng": -78.01981994797261,
    "lat": 35.34970627585782
  },
  {
    "name": "Goldsboro",
    "lng": -78.01624410877857,
    "lat": 35.35069321528387
  },
  {
    "name": "Goldsboro",
    "lng": -78.00820380207666,
    "lat": 35.34415791635755
  },
  {
    "name": "Goldsboro",
    "lng": -77.98197549416281,
    "lat": 35.42230927055892
  },
  {
    "name": "Goldsboro",
    "lng": -77.98084709863441,
    "lat": 35.43446213469903
  },
  {
    "name": "Goldsboro",
    "lng": -77.9734802271451,
    "lat": 35.31875201203398
  },
  {
    "name": "Goldsboro",
    "lng": -77.97095868990147,
    "lat": 35.37848401171445
  },
  {
    "name": "Goldsboro",
    "lng": -77.97082801183596,
    "lat": 35.40734199016068
  },
  {
    "name": "Goldsboro",
    "lng": -77.9656955081526,
    "lat": 35.312674760320576
  },
  {
    "name": "Goldsboro",
    "lng": -77.95863581605586,
    "lat": 35.41444234357337
  },
  {
    "name": "Goldsboro",
    "lng": -77.93766811405504,
    "lat": 35.41543499751285
  },
  {
    "name": "Goldsboro",
    "lng": -77.93211764656049,
    "lat": 35.367306321547495
  },
  {
    "name": "Goldsboro",
    "lng": -77.92278074585683,
    "lat": 35.33422110365739
  },
  {
    "name": "Goldsboro",
    "lng": -77.9210457298811,
    "lat": 35.33628407148722
  },
  {
    "name": "Goldsboro",
    "lng": -77.91935435744517,
    "lat": 35.33578442935486
  },
  {
    "name": "Goldsboro",
    "lng": -77.91251071183888,
    "lat": 35.34539877667859
  },
  {
    "name": "Goldsboro",
    "lng": -77.89868777398927,
    "lat": 35.40369164618866
  },
  {
    "name": "Goldsboro",
    "lng": -77.89582407708882,
    "lat": 35.383747130431665
  },
  {
    "name": "Goldsboro",
    "lng": -77.89262181439022,
    "lat": 35.36346833622712
  },
  {
    "name": "Goldsboro",
    "lng": -76.75168380144505,
    "lat": 40.15451509627879
  },
  {
    "name": "Goldsboro",
    "lng": -75.78212644882258,
    "lat": 39.03159589226878
  },
  {
    "name": "Goldsby",
    "lng": -97.47347431685077,
    "lat": 35.129115399801094
  },
  {
    "name": "Goldsmith",
    "lng": -102.6163883042219,
    "lat": 31.983319187758127
  },
  {
    "name": "Goldston",
    "lng": -79.32850461187287,
    "lat": 35.59295715666699
  },
  {
    "name": "Goldville",
    "lng": -85.78313008979768,
    "lat": 33.084134169086624
  },
  {
    "name": "Goleta",
    "lng": -119.85963724441437,
    "lat": 34.43600131122295
  },
  {
    "name": "Golf",
    "lng": -87.78502817797371,
    "lat": 42.058638982751845
  },
  {
    "name": "Golf Manor",
    "lng": -84.44682531277981,
    "lat": 39.18737444052621
  },
  {
    "name": "Goliad",
    "lng": -97.39169168720919,
    "lat": 28.67092744004047
  },
  {
    "name": "Golinda",
    "lng": -97.07630321376719,
    "lat": 31.371095695613274
  },
  {
    "name": "Golovin",
    "lng": -163.00100072412573,
    "lat": 64.56367889022701
  },
  {
    "name": "Goltry",
    "lng": -98.1508144306252,
    "lat": 36.532011500906144
  },
  {
    "name": "Golva",
    "lng": -103.98311528991121,
    "lat": 46.73468459540851
  },
  {
    "name": "Gonvick",
    "lng": -95.51254039579386,
    "lat": 47.739311535856736
  },
  {
    "name": "Gonzales",
    "lng": -121.47797006403104,
    "lat": 36.49161577877214
  },
  {
    "name": "Gonzales",
    "lng": -121.43897769018754,
    "lat": 36.50763045175353
  },
  {
    "name": "Gonzales",
    "lng": -97.44723805107905,
    "lat": 29.512540875710666
  },
  {
    "name": "Gonzales",
    "lng": -90.92339646659627,
    "lat": 30.213111063179895
  },
  {
    "name": "Good Hope",
    "lng": -90.67553667359837,
    "lat": 40.55746082215356
  },
  {
    "name": "Good Hope",
    "lng": -86.86429810979953,
    "lat": 34.11079311736902
  },
  {
    "name": "Good Hope",
    "lng": -83.60937938789881,
    "lat": 33.78499104964556
  },
  {
    "name": "Good Thunder",
    "lng": -94.07030710492317,
    "lat": 44.006773203558694
  },
  {
    "name": "Goodell",
    "lng": -93.6136587583996,
    "lat": 42.92365829119287
  },
  {
    "name": "Goodfield",
    "lng": -89.2694392861974,
    "lat": 40.62519875460762
  },
  {
    "name": "Goodhue",
    "lng": -92.62564948626886,
    "lat": 44.39961816840233
  },
  {
    "name": "Gooding",
    "lng": -114.71336727449346,
    "lat": 42.93730494315755
  },
  {
    "name": "Goodland",
    "lng": -101.71040703077112,
    "lat": 39.34920409757845
  },
  {
    "name": "Goodland",
    "lng": -87.29539458547548,
    "lat": 40.76375178044129
  },
  {
    "name": "Goodlettsville",
    "lng": -86.70415483419269,
    "lat": 36.33211154590966
  },
  {
    "name": "Goodlow",
    "lng": -96.21514815145896,
    "lat": 32.10906683530405
  },
  {
    "name": "Goodman",
    "lng": -94.40898055343204,
    "lat": 36.73872018294134
  },
  {
    "name": "Goodman",
    "lng": -89.91284572772739,
    "lat": 32.967696638058335
  },
  {
    "name": "Goodnews Bay",
    "lng": -161.57565789326324,
    "lat": 59.128714191385264
  },
  {
    "name": "Goodnight",
    "lng": -93.23113022977861,
    "lat": 37.45158280009464
  },
  {
    "name": "Goodrich",
    "lng": -100.12468925193991,
    "lat": 47.47598996600751
  },
  {
    "name": "Goodrich",
    "lng": -94.94645965812099,
    "lat": 30.609096054309134
  },
  {
    "name": "Goodrich",
    "lng": -83.50908104118808,
    "lat": 42.91472772192649
  },
  {
    "name": "Goodridge",
    "lng": -95.80393236461906,
    "lat": 48.14412054107345
  },
  {
    "name": "Goodview",
    "lng": -91.75804712978069,
    "lat": 44.08716521209498
  },
  {
    "name": "Goodview",
    "lng": -91.73543527866069,
    "lat": 44.08444585172339
  },
  {
    "name": "Goodview",
    "lng": -91.73406605169122,
    "lat": 44.08521764032843
  },
  {
    "name": "Goodview",
    "lng": -91.72254985160244,
    "lat": 44.06684067702417
  },
  {
    "name": "Goodview",
    "lng": -91.71603135949964,
    "lat": 44.068987109865134
  },
  {
    "name": "Goodwater",
    "lng": -86.0519474817078,
    "lat": 33.06102749965783
  },
  {
    "name": "Goodwell",
    "lng": -101.62244214316642,
    "lat": 36.59477253775409
  },
  {
    "name": "Goodwin",
    "lng": -96.84970097173051,
    "lat": 44.87751469880944
  },
  {
    "name": "Goose Creek",
    "lng": -85.59058836008735,
    "lat": 38.29328345610225
  },
  {
    "name": "Goose Creek",
    "lng": -80.00574816000697,
    "lat": 32.99192198490418
  },
  {
    "name": "Goose Lake",
    "lng": -90.38188621077138,
    "lat": 41.96756060763051
  },
  {
    "name": "Gordon",
    "lng": -102.21071327562044,
    "lat": 42.808201227246734
  },
  {
    "name": "Gordon",
    "lng": -102.20392916170938,
    "lat": 42.80648137150366
  },
  {
    "name": "Gordon",
    "lng": -84.50907507501545,
    "lat": 39.93027819227723
  },
  {
    "name": "Gordon",
    "lng": -83.33492381178283,
    "lat": 32.886638899191034
  },
  {
    "name": "Gordonsville",
    "lng": -85.93191312073328,
    "lat": 36.18169362443682
  },
  {
    "name": "Gordonsville",
    "lng": -78.18774329778019,
    "lat": 38.13564431544618
  },
  {
    "name": "Gordonville",
    "lng": -89.67394774575138,
    "lat": 37.311057377908575
  },
  {
    "name": "Gordonville",
    "lng": -86.71115132467762,
    "lat": 32.153159082019705
  },
  {
    "name": "Gordonville",
    "lng": -86.67044446248225,
    "lat": 32.13896933340504
  },
  {
    "name": "Gore",
    "lng": -95.11309320719828,
    "lat": 35.541662996942584
  },
  {
    "name": "Goreville",
    "lng": -88.95566966218321,
    "lat": 37.56104026083051
  },
  {
    "name": "Gorham",
    "lng": -99.02362413555997,
    "lat": 38.88061156371184
  },
  {
    "name": "Gorham",
    "lng": -89.48310190377552,
    "lat": 37.715118983472706
  },
  {
    "name": "Gorham",
    "lng": -70.45814293050692,
    "lat": 43.703475254127966
  },
  {
    "name": "Gorman",
    "lng": -98.67207720469571,
    "lat": 32.213539034305676
  },
  {
    "name": "Goshen",
    "lng": -111.90084552447424,
    "lat": 39.951061760397494
  },
  {
    "name": "Goshen",
    "lng": -94.00530783427914,
    "lat": 36.10483575511514
  },
  {
    "name": "Goshen",
    "lng": -94.00530783427912,
    "lat": 36.10483575511512
  },
  {
    "name": "Goshen",
    "lng": -93.95995156670301,
    "lat": 36.083005901305874
  },
  {
    "name": "Goshen",
    "lng": -86.12398999281818,
    "lat": 31.718096006027693
  },
  {
    "name": "Goshen",
    "lng": -85.83060264476525,
    "lat": 41.574355530404475
  },
  {
    "name": "Goshen",
    "lng": -85.58238359592059,
    "lat": 38.40248029109902
  },
  {
    "name": "Goshen",
    "lng": -79.50669387319718,
    "lat": 37.99000948390913
  },
  {
    "name": "Goshen",
    "lng": -74.32686323569992,
    "lat": 41.40139097278205
  },
  {
    "name": "Goshen",
    "lng": -73.23357607423564,
    "lat": 41.850104910195036
  },
  {
    "name": "Goshen",
    "lng": -72.80644846599085,
    "lat": 42.442494270700465
  },
  {
    "name": "Gosnell",
    "lng": -89.97839044073768,
    "lat": 35.98010160703459
  },
  {
    "name": "Gosnell",
    "lng": -89.96978796115414,
    "lat": 35.95964443416504
  },
  {
    "name": "Gosnell",
    "lng": -89.96978796115403,
    "lat": 35.959644434165
  },
  {
    "name": "Gosport",
    "lng": -86.66556004941643,
    "lat": 39.35075739720515
  },
  {
    "name": "Goss",
    "lng": -91.94525154235025,
    "lat": 39.51466208408162
  },
  {
    "name": "Gotebo",
    "lng": -98.87443469926815,
    "lat": 35.07136639577184
  },
  {
    "name": "Gothenburg",
    "lng": -100.16910336828056,
    "lat": 40.94551755399517
  },
  {
    "name": "Gothenburg",
    "lng": -100.16517692591734,
    "lat": 40.88933549451179
  },
  {
    "name": "Gothenburg",
    "lng": -100.15424782062688,
    "lat": 40.92507725501122
  },
  {
    "name": "Gould",
    "lng": -99.7736891341223,
    "lat": 34.6692981317393
  },
  {
    "name": "Gould",
    "lng": -91.56404136965138,
    "lat": 33.98719093776668
  },
  {
    "name": "Gouldsboro",
    "lng": -68.04911810336276,
    "lat": 44.42416966158916
  },
  {
    "name": "Gouverneur",
    "lng": -75.46661387547785,
    "lat": 44.336658762554265
  },
  {
    "name": "Govan",
    "lng": -81.17483402585226,
    "lat": 33.22277038746075
  },
  {
    "name": "Gove City",
    "lng": -100.49005083283552,
    "lat": 38.95997052702699
  },
  {
    "name": "Gowanda",
    "lng": -78.93382153078215,
    "lat": 42.46120476393387
  },
  {
    "name": "Gower",
    "lng": -94.59480718664467,
    "lat": 39.61291232635823
  },
  {
    "name": "Gowrie",
    "lng": -94.28883147245816,
    "lat": 42.27782114127061
  },
  {
    "name": "Grabill",
    "lng": -84.96846063989156,
    "lat": 41.209915417133
  },
  {
    "name": "Grace",
    "lng": -111.73054771606722,
    "lat": 42.5755996060499
  },
  {
    "name": "Grace City",
    "lng": -98.80417069748408,
    "lat": 47.55283699489321
  },
  {
    "name": "Gracemont",
    "lng": -98.25711108321984,
    "lat": 35.18776219711996
  },
  {
    "name": "Graceville",
    "lng": -96.43725750647687,
    "lat": 45.56870392798711
  },
  {
    "name": "Graceville",
    "lng": -85.51236585094863,
    "lat": 30.96093530743757
  },
  {
    "name": "Grady",
    "lng": -103.31554257392077,
    "lat": 34.821491727770905
  },
  {
    "name": "Grady",
    "lng": -91.69765165619495,
    "lat": 34.07876126403382
  },
  {
    "name": "Graettinger",
    "lng": -94.75044488504615,
    "lat": 43.236929071955004
  },
  {
    "name": "Graf",
    "lng": -90.8731839098237,
    "lat": 42.49381772204553
  },
  {
    "name": "Graford",
    "lng": -98.24763606310432,
    "lat": 32.9374853227433
  },
  {
    "name": "Grafton",
    "lng": -97.71461643349507,
    "lat": 40.62978278608403
  },
  {
    "name": "Grafton",
    "lng": -97.40631413567381,
    "lat": 48.4146618950017
  },
  {
    "name": "Grafton",
    "lng": -93.06870998796683,
    "lat": 43.330533703176464
  },
  {
    "name": "Grafton",
    "lng": -90.42588891534247,
    "lat": 38.97649184419703
  },
  {
    "name": "Grafton",
    "lng": -87.94819174728184,
    "lat": 43.320727396960685
  },
  {
    "name": "Grafton",
    "lng": -82.05521084209667,
    "lat": 41.26442181749651
  },
  {
    "name": "Grafton",
    "lng": -82.03652480036625,
    "lat": 41.28107891795363
  },
  {
    "name": "Grafton",
    "lng": -80.01619783426972,
    "lat": 39.34091519717121
  },
  {
    "name": "Grafton",
    "lng": -71.68375923137812,
    "lat": 42.208551229020095
  },
  {
    "name": "Graham",
    "lng": -122.28504731716495,
    "lat": 47.032841923473654
  },
  {
    "name": "Graham",
    "lng": -95.04044188385522,
    "lat": 40.20108079608269
  },
  {
    "name": "Graham",
    "lng": -82.50296880855421,
    "lat": 31.829157749380695
  },
  {
    "name": "Graham",
    "lng": -79.42773397267484,
    "lat": 36.03712511293455
  },
  {
    "name": "Graham",
    "lng": -79.3909715353604,
    "lat": 36.02965518455138
  },
  {
    "name": "Graham",
    "lng": -79.3905997269454,
    "lat": 36.06019339867418
  },
  {
    "name": "Graham",
    "lng": -79.34634906499817,
    "lat": 36.046117431229916
  },
  {
    "name": "Grain Valley",
    "lng": -94.20828243357911,
    "lat": 39.01662655426919
  },
  {
    "name": "Grainfield",
    "lng": -100.4684467161024,
    "lat": 39.114215685465915
  },
  {
    "name": "Grainola",
    "lng": -96.64879231945655,
    "lat": 36.93793747632408
  },
  {
    "name": "Grambling",
    "lng": -92.712224514586,
    "lat": 32.52782621448789
  },
  {
    "name": "Gramercy",
    "lng": -90.6929556103181,
    "lat": 30.061074641790768
  },
  {
    "name": "Granada",
    "lng": -94.3495236491583,
    "lat": 43.693055768919876
  },
  {
    "name": "Granbury",
    "lng": -97.7881594767047,
    "lat": 32.413300737897934
  },
  {
    "name": "Granbury",
    "lng": -97.76979627687587,
    "lat": 32.44795183402065
  },
  {
    "name": "Granby",
    "lng": -105.95036216563028,
    "lat": 40.10392068770402
  },
  {
    "name": "Granby",
    "lng": -105.9076228127923,
    "lat": 40.05092449075178
  },
  {
    "name": "Granby",
    "lng": -105.90756743696612,
    "lat": 40.05086552021619
  },
  {
    "name": "Granby",
    "lng": -94.28309321238564,
    "lat": 36.91053543071838
  },
  {
    "name": "Granby",
    "lng": -94.26076180796335,
    "lat": 36.918046118033125
  },
  {
    "name": "Granby",
    "lng": -94.250810894628,
    "lat": 36.899151844736515
  },
  {
    "name": "Granby",
    "lng": -72.83484574808499,
    "lat": 41.969547745862435
  },
  {
    "name": "Granby",
    "lng": -72.50361999829855,
    "lat": 42.260833245199755
  },
  {
    "name": "Grand Beach",
    "lng": -86.78858805212012,
    "lat": 41.77400528082908
  },
  {
    "name": "Grand Blanc",
    "lng": -83.61821214326923,
    "lat": 42.92584901792518
  },
  {
    "name": "Grand Cane",
    "lng": -93.80880152307877,
    "lat": 32.08363091309527
  },
  {
    "name": "Grand Canyon Village",
    "lng": -112.15665311195076,
    "lat": 36.049257660331165
  },
  {
    "name": "Grand Coteau",
    "lng": -92.04378352030199,
    "lat": 30.42024072947139
  },
  {
    "name": "Grand Coulee",
    "lng": -119.00334270551049,
    "lat": 47.93974093444832
  },
  {
    "name": "Grand Falls Plaza",
    "lng": -94.5407672114313,
    "lat": 37.031009032107605
  },
  {
    "name": "Grand Falls Plaza",
    "lng": -94.54072271411448,
    "lat": 37.0296224966983
  },
  {
    "name": "Grand Falls Plaza",
    "lng": -94.53780796630728,
    "lat": 37.035531167466836
  },
  {
    "name": "Grand Forks",
    "lng": -97.07513633426177,
    "lat": 47.91219143711318
  },
  {
    "name": "Grand Haven",
    "lng": -86.22035103864421,
    "lat": 43.055350073684096
  },
  {
    "name": "Grand Island",
    "lng": -98.35814408636591,
    "lat": 40.92462969333408
  },
  {
    "name": "Grand Isle",
    "lng": -90.02773707738605,
    "lat": 29.2150597830087
  },
  {
    "name": "Grand Isle",
    "lng": -68.12963292137313,
    "lat": 47.24989041429851
  },
  {
    "name": "Grand Junction",
    "lng": -108.666809628631,
    "lat": 39.10298117317387
  },
  {
    "name": "Grand Junction",
    "lng": -108.6573203995778,
    "lat": 39.12616111945956
  },
  {
    "name": "Grand Junction",
    "lng": -108.65257174670762,
    "lat": 39.08799516623938
  },
  {
    "name": "Grand Junction",
    "lng": -108.61433560509734,
    "lat": 39.070836844402656
  },
  {
    "name": "Grand Junction",
    "lng": -108.5971508092012,
    "lat": 39.06649965134764
  },
  {
    "name": "Grand Junction",
    "lng": -108.59232085488772,
    "lat": 39.065197140891556
  },
  {
    "name": "Grand Junction",
    "lng": -108.58729468163052,
    "lat": 39.05871686331845
  },
  {
    "name": "Grand Junction",
    "lng": -108.58000010545875,
    "lat": 39.05317556559017
  },
  {
    "name": "Grand Junction",
    "lng": -108.56829265103262,
    "lat": 39.08707887416248
  },
  {
    "name": "Grand Junction",
    "lng": -108.54481330245287,
    "lat": 39.03820778044142
  },
  {
    "name": "Grand Junction",
    "lng": -108.53100259363691,
    "lat": 39.04705427685957
  },
  {
    "name": "Grand Junction",
    "lng": -108.52625696361795,
    "lat": 39.02949408134917
  },
  {
    "name": "Grand Junction",
    "lng": -108.50481115617784,
    "lat": 39.023024629236716
  },
  {
    "name": "Grand Junction",
    "lng": -108.4978255759532,
    "lat": 39.0202232049331
  },
  {
    "name": "Grand Junction",
    "lng": -108.49316509475598,
    "lat": 39.084702727467565
  },
  {
    "name": "Grand Junction",
    "lng": -94.23695917460097,
    "lat": 42.0329472385449
  },
  {
    "name": "Grand Junction",
    "lng": -89.18884657289749,
    "lat": 35.050020128404874
  },
  {
    "name": "Grand Lake",
    "lng": -105.8236478112258,
    "lat": 40.2505017051531
  },
  {
    "name": "Grand Lake Towne",
    "lng": -95.02549698281233,
    "lat": 36.50480723879474
  },
  {
    "name": "Grand Ledge",
    "lng": -84.74370734337718,
    "lat": 42.75458173673634
  },
  {
    "name": "Grand Marais",
    "lng": -90.34444989437996,
    "lat": 47.75856495469429
  },
  {
    "name": "Grand Marais",
    "lng": -90.31524923667783,
    "lat": 47.75743121980038
  },
  {
    "name": "Grand Marais",
    "lng": -90.31432140747769,
    "lat": 47.756757905051835
  },
  {
    "name": "Grand Meadow",
    "lng": -92.57017842821061,
    "lat": 43.70621188735604
  },
  {
    "name": "Grand Meadow",
    "lng": -92.56912762554828,
    "lat": 43.710697250671046
  },
  {
    "name": "Grand Mound",
    "lng": -90.65063531077094,
    "lat": 41.823731900506665
  },
  {
    "name": "Grand Oaks",
    "lng": -95.69670261477319,
    "lat": 29.83829688753919
  },
  {
    "name": "Grand Pass",
    "lng": -93.44275793567904,
    "lat": 39.20498571537186
  },
  {
    "name": "Grand Prairie",
    "lng": -97.02111768975146,
    "lat": 32.68662654055137
  },
  {
    "name": "Grand Prairie",
    "lng": -96.93883463861728,
    "lat": 32.74233864357042
  },
  {
    "name": "Grand Rapids",
    "lng": -93.53255413588188,
    "lat": 47.23795639401637
  },
  {
    "name": "Grand Rapids",
    "lng": -85.65568911453352,
    "lat": 42.96152887868154
  },
  {
    "name": "Grand Rapids",
    "lng": -83.86541103589299,
    "lat": 41.41061912189917
  },
  {
    "name": "Grand Ridge",
    "lng": -88.83102875491981,
    "lat": 41.23613655093059
  },
  {
    "name": "Grand River",
    "lng": -93.96311132568843,
    "lat": 40.81914791868243
  },
  {
    "name": "Grand River",
    "lng": -81.28460625861943,
    "lat": 41.741821220582594
  },
  {
    "name": "Grand Rivers",
    "lng": -88.23237845019666,
    "lat": 37.00476452451835
  },
  {
    "name": "Grand Saline",
    "lng": -95.71155096514606,
    "lat": 32.677545558256845
  },
  {
    "name": "Grand Terrace",
    "lng": -117.31337161929913,
    "lat": 34.03111983225176
  },
  {
    "name": "Grand Tower",
    "lng": -89.50118438532529,
    "lat": 37.63422990051774
  },
  {
    "name": "Grand View",
    "lng": -116.0918467690624,
    "lat": 42.98429019919119
  },
  {
    "name": "Grand View-on-Hudson",
    "lng": -73.92078101030228,
    "lat": 41.06281420978174
  },
  {
    "name": "Grandfalls",
    "lng": -102.85447815266808,
    "lat": 31.340553749384505
  },
  {
    "name": "Grandfather",
    "lng": -81.84745680583201,
    "lat": 36.098850744345455
  },
  {
    "name": "Grandfield",
    "lng": -98.68742261445979,
    "lat": 34.23066843413191
  },
  {
    "name": "Grandin",
    "lng": -90.82199257608052,
    "lat": 36.830804120925066
  },
  {
    "name": "Grandin",
    "lng": -90.8183143017124,
    "lat": 36.8376354678794
  },
  {
    "name": "Grandview",
    "lng": -119.91101130070946,
    "lat": 46.25804890591195
  },
  {
    "name": "Grandview",
    "lng": -119.9014637238507,
    "lat": 46.20606512634593
  },
  {
    "name": "Grandview",
    "lng": -119.89262215725469,
    "lat": 46.27340410660817
  },
  {
    "name": "Grandview",
    "lng": -97.1774900962363,
    "lat": 32.26850362310528
  },
  {
    "name": "Grandview",
    "lng": -94.5227237932638,
    "lat": 38.88023060452079
  },
  {
    "name": "Grandview",
    "lng": -91.18788524381387,
    "lat": 41.27715736510099
  },
  {
    "name": "Grandview",
    "lng": -89.61810149963293,
    "lat": 39.81753243864479
  },
  {
    "name": "Grandview",
    "lng": -89.61794277971914,
    "lat": 39.817549318289664
  },
  {
    "name": "Grandview",
    "lng": -86.98417043119903,
    "lat": 37.93699225599257
  },
  {
    "name": "Grandview Heights",
    "lng": -83.0407793285147,
    "lat": 39.97933734883757
  },
  {
    "name": "Grandview Plaza",
    "lng": -96.79260502440317,
    "lat": 39.032216245686676
  },
  {
    "name": "Grandville",
    "lng": -85.75640014776253,
    "lat": 42.900265362413556
  },
  {
    "name": "Granger",
    "lng": -120.19285432462186,
    "lat": 46.34582041929404
  },
  {
    "name": "Granger",
    "lng": -109.96266567074196,
    "lat": 41.596985871173935
  },
  {
    "name": "Granger",
    "lng": -97.44110272402395,
    "lat": 30.717968953394475
  },
  {
    "name": "Granger",
    "lng": -93.82366741487073,
    "lat": 41.7617913443713
  },
  {
    "name": "Granger",
    "lng": -91.97350610471408,
    "lat": 40.46695923967761
  },
  {
    "name": "Grangeville",
    "lng": -116.12245088398586,
    "lat": 45.92596997265216
  },
  {
    "name": "Granite",
    "lng": -99.37034902180673,
    "lat": 34.95614184460882
  },
  {
    "name": "Granite City",
    "lng": -90.12623967380877,
    "lat": 38.72952121843831
  },
  {
    "name": "Granite City",
    "lng": -90.12185633781895,
    "lat": 38.75984623545924
  },
  {
    "name": "Granite City",
    "lng": -90.10723499189808,
    "lat": 38.73579452854887
  },
  {
    "name": "Granite City",
    "lng": -90.0966214492912,
    "lat": 38.73635813237152
  },
  {
    "name": "Granite City",
    "lng": -90.09249235434007,
    "lat": 38.73722420609111
  },
  {
    "name": "Granite Falls",
    "lng": -121.9675131557593,
    "lat": 48.086411903930646
  },
  {
    "name": "Granite Falls",
    "lng": -95.53943250756089,
    "lat": 44.81106122698384
  },
  {
    "name": "Granite Falls",
    "lng": -95.48591410826188,
    "lat": 44.80045922570434
  },
  {
    "name": "Granite Falls",
    "lng": -81.46037726961939,
    "lat": 35.8152021001304
  },
  {
    "name": "Granite Falls",
    "lng": -81.44626838843308,
    "lat": 35.81995433827533
  },
  {
    "name": "Granite Falls",
    "lng": -81.42978154495249,
    "lat": 35.79925549985579
  },
  {
    "name": "Granite Falls",
    "lng": -81.42693202267274,
    "lat": 35.81243722713652
  },
  {
    "name": "Granite Falls",
    "lng": -81.41616998765534,
    "lat": 35.7777681921135
  },
  {
    "name": "Granite Falls",
    "lng": -81.40525451446732,
    "lat": 35.782731211862526
  },
  {
    "name": "Granite Falls",
    "lng": -81.38929209889636,
    "lat": 35.7724260589093
  },
  {
    "name": "Granite Falls",
    "lng": -81.38734963691455,
    "lat": 35.78466513609208
  },
  {
    "name": "Granite Quarry",
    "lng": -80.45509377052058,
    "lat": 35.60053677203835
  },
  {
    "name": "Granite Quarry",
    "lng": -80.44594929667149,
    "lat": 35.613622098284466
  },
  {
    "name": "Granite Quarry",
    "lng": -80.44124684839828,
    "lat": 35.61873325486495
  },
  {
    "name": "Granite Quarry",
    "lng": -80.43050225081059,
    "lat": 35.592581439046114
  },
  {
    "name": "Granite Quarry",
    "lng": -80.4295891579607,
    "lat": 35.60140721637835
  },
  {
    "name": "Granite Quarry",
    "lng": -80.42764518293333,
    "lat": 35.599437622383924
  },
  {
    "name": "Granite Shoals",
    "lng": -98.37112862806418,
    "lat": 30.588268420892497
  },
  {
    "name": "Granjeno",
    "lng": -98.30312132448307,
    "lat": 26.137395771875276
  },
  {
    "name": "Grannis",
    "lng": -94.32196973590504,
    "lat": 34.239821331927715
  },
  {
    "name": "Grano",
    "lng": -101.58875659909584,
    "lat": 48.61524879139276
  },
  {
    "name": "Grant",
    "lng": -94.98478765593606,
    "lat": 41.14281908798153
  },
  {
    "name": "Grant",
    "lng": -92.90902028041626,
    "lat": 45.08255752269221
  },
  {
    "name": "Grant",
    "lng": -86.24942963978364,
    "lat": 34.51839755110389
  },
  {
    "name": "Grant",
    "lng": -85.80878120707982,
    "lat": 43.33568499540731
  },
  {
    "name": "Grant City",
    "lng": -94.41345759915863,
    "lat": 40.48577912686727
  },
  {
    "name": "Grant Park",
    "lng": -87.63523436656149,
    "lat": 41.242965569205424
  },
  {
    "name": "Grant Town",
    "lng": -80.17732831760254,
    "lat": 39.559010811696055
  },
  {
    "name": "Grant-Valkaria",
    "lng": -80.57056853214569,
    "lat": 27.93235143020398
  },
  {
    "name": "Grant-Valkaria",
    "lng": -80.51661646710018,
    "lat": 27.925137251012245
  },
  {
    "name": "Grantfork",
    "lng": -89.66655982851319,
    "lat": 38.82889949062607
  },
  {
    "name": "Granton",
    "lng": -90.46124620288525,
    "lat": 44.58846648948248
  },
  {
    "name": "Grants",
    "lng": -107.83476905095618,
    "lat": 35.153859119333326
  },
  {
    "name": "Grantsboro",
    "lng": -76.84468200462835,
    "lat": 35.14487669699438
  },
  {
    "name": "Grantsburg",
    "lng": -92.68706895364905,
    "lat": 45.77875680233927
  },
  {
    "name": "Grantsburg",
    "lng": -92.66416222471828,
    "lat": 45.798187754050666
  },
  {
    "name": "Grantsville",
    "lng": -112.47783131128267,
    "lat": 40.614878209623086
  },
  {
    "name": "Grantsville",
    "lng": -81.09333823042401,
    "lat": 38.92041060164312
  },
  {
    "name": "Grantsville",
    "lng": -79.1530420917788,
    "lat": 39.69698984351844
  },
  {
    "name": "Grantville",
    "lng": -84.82927615028869,
    "lat": 33.23711660870634
  },
  {
    "name": "Grantville",
    "lng": -84.82552138179818,
    "lat": 33.22546669392376
  },
  {
    "name": "Grantwood Village",
    "lng": -90.35313527439514,
    "lat": 38.55092789527635
  },
  {
    "name": "Granville",
    "lng": -100.84491497950974,
    "lat": 48.26636144430366
  },
  {
    "name": "Granville",
    "lng": -95.87479435349968,
    "lat": 42.985245679646816
  },
  {
    "name": "Granville",
    "lng": -89.23004499777731,
    "lat": 41.2636869628604
  },
  {
    "name": "Granville",
    "lng": -82.5030820212517,
    "lat": 40.068707500744225
  },
  {
    "name": "Granville",
    "lng": -79.99744833749783,
    "lat": 39.64711473369076
  },
  {
    "name": "Granville",
    "lng": -73.2615067555496,
    "lat": 43.40825674739496
  },
  {
    "name": "Granville",
    "lng": -72.90858569403147,
    "lat": 42.08168961205008
  },
  {
    "name": "Grapeland",
    "lng": -95.48019374258295,
    "lat": 31.494309868111984
  },
  {
    "name": "Grapevine",
    "lng": -97.07396173496237,
    "lat": 32.93403104606163
  },
  {
    "name": "Grass Lake",
    "lng": -84.20738276715174,
    "lat": 42.251108204575516
  },
  {
    "name": "Grass Range",
    "lng": -108.80293310087845,
    "lat": 47.02605046310637
  },
  {
    "name": "Grass Range",
    "lng": -108.79897989969447,
    "lat": 47.02637343989151
  },
  {
    "name": "Grasston",
    "lng": -93.15256655282619,
    "lat": 45.79577409857608
  },
  {
    "name": "Gratiot",
    "lng": -90.02351894425718,
    "lat": 42.580216719223756
  },
  {
    "name": "Gratiot",
    "lng": -82.21667701519948,
    "lat": 39.95146043380712
  },
  {
    "name": "Gratis",
    "lng": -84.52849601574702,
    "lat": 39.6484036749451
  },
  {
    "name": "Graton",
    "lng": -122.8659882914314,
    "lat": 38.43751887423956
  },
  {
    "name": "Gratz",
    "lng": -84.947092949557,
    "lat": 38.472102225653884
  },
  {
    "name": "Gratz",
    "lng": -76.7156586616271,
    "lat": 40.60742560634038
  },
  {
    "name": "Gravette",
    "lng": -94.44680790250672,
    "lat": 36.41929575402098
  },
  {
    "name": "Gravette",
    "lng": -94.34544593005232,
    "lat": 36.43245031763703
  },
  {
    "name": "Gravity",
    "lng": -94.74346373486807,
    "lat": 40.76074416397758
  },
  {
    "name": "Gravois Mills",
    "lng": -92.82441914626266,
    "lat": 38.30486240577965
  },
  {
    "name": "Gray",
    "lng": -94.98587969049014,
    "lat": 41.84169583192739
  },
  {
    "name": "Gray",
    "lng": -83.56986164165205,
    "lat": 32.98536704515983
  },
  {
    "name": "Gray",
    "lng": -83.53376273274594,
    "lat": 33.00429252873628
  },
  {
    "name": "Gray",
    "lng": -70.34943103691816,
    "lat": 43.88769994018765
  },
  {
    "name": "Gray Court",
    "lng": -82.11384073075821,
    "lat": 34.60744910695111
  },
  {
    "name": "Gray Summit",
    "lng": -90.81773513404161,
    "lat": 38.495328383841205
  },
  {
    "name": "Gray Summit",
    "lng": -90.77433921498609,
    "lat": 38.48661817159152
  },
  {
    "name": "Gray Summit",
    "lng": -90.76269547677957,
    "lat": 38.488565842437126
  },
  {
    "name": "Grayling",
    "lng": -160.1073305673834,
    "lat": 62.89939465384384
  },
  {
    "name": "Grayling",
    "lng": -84.70858286364813,
    "lat": 44.65660768520245
  },
  {
    "name": "Graymoor-Devondale",
    "lng": -85.61712671426709,
    "lat": 38.27338652906092
  },
  {
    "name": "Grays Prairie",
    "lng": -96.35023315206476,
    "lat": 32.47343393763879
  },
  {
    "name": "Grayslake",
    "lng": -88.06163132538533,
    "lat": 42.33819396175017
  },
  {
    "name": "Grayslake",
    "lng": -88.03318421153345,
    "lat": 42.34394297925895
  },
  {
    "name": "Grayslake",
    "lng": -88.0173133879752,
    "lat": 42.31563127133087
  },
  {
    "name": "Grayslake",
    "lng": -88.00565912845414,
    "lat": 42.38314162560672
  },
  {
    "name": "Grayslake",
    "lng": -87.98467875258385,
    "lat": 42.33996037928031
  },
  {
    "name": "Grayson",
    "lng": -95.87285133793675,
    "lat": 35.50487412394488
  },
  {
    "name": "Grayson",
    "lng": -95.86312315254334,
    "lat": 35.52062886753576
  },
  {
    "name": "Grayson",
    "lng": -95.85926755958015,
    "lat": 35.51376826484412
  },
  {
    "name": "Grayson",
    "lng": -95.85630510776299,
    "lat": 35.508893924736356
  },
  {
    "name": "Grayson",
    "lng": -92.11157054299039,
    "lat": 32.04917405135653
  },
  {
    "name": "Grayson",
    "lng": -83.95799363390725,
    "lat": 33.89044280243715
  },
  {
    "name": "Grayson",
    "lng": -82.93746072341615,
    "lat": 38.33324492192175
  },
  {
    "name": "Graysville",
    "lng": -86.9718731189887,
    "lat": 33.652007953975634
  },
  {
    "name": "Graysville",
    "lng": -86.96736576821189,
    "lat": 33.658289111646056
  },
  {
    "name": "Graysville",
    "lng": -86.96699827828428,
    "lat": 33.68188231946783
  },
  {
    "name": "Graysville",
    "lng": -86.96658923598274,
    "lat": 33.6578164907535
  },
  {
    "name": "Graysville",
    "lng": -86.9650421591865,
    "lat": 33.68211865801179
  },
  {
    "name": "Graysville",
    "lng": -86.92402129166449,
    "lat": 33.61744676354742
  },
  {
    "name": "Graysville",
    "lng": -86.92349092536385,
    "lat": 33.61519331534923
  },
  {
    "name": "Graysville",
    "lng": -86.92349092536385,
    "lat": 33.615193315349245
  },
  {
    "name": "Graysville",
    "lng": -86.9234702454004,
    "lat": 33.61768191167377
  },
  {
    "name": "Graysville",
    "lng": -85.07625599285493,
    "lat": 35.44953047620293
  },
  {
    "name": "Graysville",
    "lng": -81.17458435502296,
    "lat": 39.66334571931237
  },
  {
    "name": "Grayville",
    "lng": -87.99720382996858,
    "lat": 38.256905435814495
  },
  {
    "name": "Great Barrington",
    "lng": -73.3415777560912,
    "lat": 42.211006348287704
  },
  {
    "name": "Great Bend",
    "lng": -98.86347737728268,
    "lat": 38.34714858916885
  },
  {
    "name": "Great Bend",
    "lng": -98.79924252056665,
    "lat": 38.35321477357483
  },
  {
    "name": "Great Bend",
    "lng": -98.78002081068982,
    "lat": 38.33986381168805
  },
  {
    "name": "Great Bend",
    "lng": -98.7778008913142,
    "lat": 38.3656624163446
  },
  {
    "name": "Great Bend",
    "lng": -98.76788328320653,
    "lat": 38.349775200330136
  },
  {
    "name": "Great Bend",
    "lng": -98.73440926927758,
    "lat": 38.35458452079227
  },
  {
    "name": "Great Bend",
    "lng": -98.72477597064842,
    "lat": 38.34966589705385
  },
  {
    "name": "Great Bend",
    "lng": -96.80104481332823,
    "lat": 46.15461412210162
  },
  {
    "name": "Great Falls",
    "lng": -111.30074386307811,
    "lat": 47.50141392137367
  },
  {
    "name": "Great Falls",
    "lng": -80.904819518763,
    "lat": 34.57534275545154
  },
  {
    "name": "Great Neck",
    "lng": -73.73334634351599,
    "lat": 40.802862949189816
  },
  {
    "name": "Great Neck Estates",
    "lng": -73.73970607013288,
    "lat": 40.78573691520712
  },
  {
    "name": "Great Neck Plaza",
    "lng": -73.72621357472664,
    "lat": 40.786876666393944
  },
  {
    "name": "Great Pond",
    "lng": -68.29911316534121,
    "lat": 44.95887005735029
  },
  {
    "name": "Greeley",
    "lng": -104.77064531061293,
    "lat": 40.414087194224685
  },
  {
    "name": "Greeley",
    "lng": -95.1270273323962,
    "lat": 38.3678744081855
  },
  {
    "name": "Greeley",
    "lng": -91.3415793464947,
    "lat": 42.584980984508185
  },
  {
    "name": "Greeley Center",
    "lng": -98.53051701939646,
    "lat": 41.54834425301236
  },
  {
    "name": "Greeleyville",
    "lng": -80.00133667217189,
    "lat": 33.5838408270631
  },
  {
    "name": "Greeleyville",
    "lng": -79.98934464507653,
    "lat": 33.5796309799764
  },
  {
    "name": "Green",
    "lng": -97.00010949595493,
    "lat": 39.42995504408869
  },
  {
    "name": "Green",
    "lng": -81.47565560043954,
    "lat": 40.94833752579639
  },
  {
    "name": "Green Bay",
    "lng": -87.99385255057696,
    "lat": 44.51485473167982
  },
  {
    "name": "Green Bay",
    "lng": -87.86626727768721,
    "lat": 44.53931995328854
  },
  {
    "name": "Green Camp",
    "lng": -83.2076129898979,
    "lat": 40.53201667240303
  },
  {
    "name": "Green City",
    "lng": -92.95632436570068,
    "lat": 40.26389956435159
  },
  {
    "name": "Green Cove Springs",
    "lng": -81.68349884477969,
    "lat": 29.987972503557785
  },
  {
    "name": "Green Forest",
    "lng": -93.43096225401572,
    "lat": 36.33469115723743
  },
  {
    "name": "Green Island",
    "lng": -73.69245291768496,
    "lat": 42.74743421463888
  },
  {
    "name": "Green Isle",
    "lng": -94.00517897200328,
    "lat": 44.680355024674306
  },
  {
    "name": "Green Level",
    "lng": -79.34631726365771,
    "lat": 36.121638383940436
  },
  {
    "name": "Green Mountain Falls",
    "lng": -105.0232835084127,
    "lat": 38.934156063540556
  },
  {
    "name": "Green Oaks",
    "lng": -87.91438278620556,
    "lat": 42.29828931649192
  },
  {
    "name": "Green Oaks",
    "lng": -87.90305326696964,
    "lat": 42.26739792365612
  },
  {
    "name": "Green Oaks",
    "lng": -87.89280844370363,
    "lat": 42.283420186150195
  },
  {
    "name": "Green Oaks",
    "lng": -87.88911384179265,
    "lat": 42.27912938723358
  },
  {
    "name": "Green Park",
    "lng": -90.33660838772035,
    "lat": 38.52428841524848
  },
  {
    "name": "Green Ridge",
    "lng": -93.41024639347796,
    "lat": 38.621108332285495
  },
  {
    "name": "Green River",
    "lng": -110.17232451096889,
    "lat": 38.99352122935677
  },
  {
    "name": "Green River",
    "lng": -109.49071318033438,
    "lat": 41.45823320168461
  },
  {
    "name": "Green River",
    "lng": -109.46974617531464,
    "lat": 41.51508138483434
  },
  {
    "name": "Green Spring",
    "lng": -85.61379654035807,
    "lat": 38.31644994052242
  },
  {
    "name": "Green Springs",
    "lng": -83.05315994476263,
    "lat": 41.25720534575388
  },
  {
    "name": "Green Valley",
    "lng": -89.64379991168087,
    "lat": 40.4073125303475
  },
  {
    "name": "Greenback",
    "lng": -84.18532879189569,
    "lat": 35.62108952644135
  },
  {
    "name": "Greenback",
    "lng": -84.17209638343598,
    "lat": 35.64975886480982
  },
  {
    "name": "Greenbrier",
    "lng": -92.38356915993035,
    "lat": 35.228840353179194
  },
  {
    "name": "Greenbrier",
    "lng": -86.79904980749987,
    "lat": 36.42335103989593
  },
  {
    "name": "Greenbush",
    "lng": -96.183730976854,
    "lat": 48.700286332566876
  },
  {
    "name": "Greenbush",
    "lng": -68.59130929465759,
    "lat": 45.080770357766106
  },
  {
    "name": "Greencastle",
    "lng": -92.87844323577394,
    "lat": 40.260687594253014
  },
  {
    "name": "Greencastle",
    "lng": -86.84183238114503,
    "lat": 39.64309437284235
  },
  {
    "name": "Greendale",
    "lng": -90.3123680502699,
    "lat": 38.69388040456202
  },
  {
    "name": "Greendale",
    "lng": -88.00187441717927,
    "lat": 42.93712205573871
  },
  {
    "name": "Greendale",
    "lng": -84.85024441363937,
    "lat": 39.13112478865339
  },
  {
    "name": "Greene",
    "lng": -92.80412641260384,
    "lat": 42.89685819563919
  },
  {
    "name": "Greene",
    "lng": -75.76943645146957,
    "lat": 42.329659367154974
  },
  {
    "name": "Greene",
    "lng": -70.14461104191146,
    "lat": 44.19040237523995
  },
  {
    "name": "Greenevers",
    "lng": -77.92456775333773,
    "lat": 34.82708286746118
  },
  {
    "name": "Greeneville",
    "lng": -82.88313548928096,
    "lat": 36.15384317808756
  },
  {
    "name": "Greeneville",
    "lng": -82.81489504871912,
    "lat": 36.16893021078882
  },
  {
    "name": "Greenfield",
    "lng": -121.24480200150279,
    "lat": 36.32304579643718
  },
  {
    "name": "Greenfield",
    "lng": -98.37750536704768,
    "lat": 35.728777960255606
  },
  {
    "name": "Greenfield",
    "lng": -94.45933434830809,
    "lat": 41.30582749872989
  },
  {
    "name": "Greenfield",
    "lng": -93.8431598605669,
    "lat": 37.416481657200826
  },
  {
    "name": "Greenfield",
    "lng": -93.68837231899582,
    "lat": 45.09969494158003
  },
  {
    "name": "Greenfield",
    "lng": -90.20802290121209,
    "lat": 39.34430740765489
  },
  {
    "name": "Greenfield",
    "lng": -88.80382064859413,
    "lat": 36.159686711416896
  },
  {
    "name": "Greenfield",
    "lng": -88.00494792445754,
    "lat": 42.96189187513867
  },
  {
    "name": "Greenfield",
    "lng": -85.77300435098833,
    "lat": 39.79437455847103
  },
  {
    "name": "Greenfield",
    "lng": -83.3883898445825,
    "lat": 39.353693726139234
  },
  {
    "name": "Greenfield",
    "lng": -72.59854692837428,
    "lat": 42.61401487683175
  },
  {
    "name": "Greenfield",
    "lng": -71.8710916059833,
    "lat": 42.94044669708263
  },
  {
    "name": "Greenfield Village",
    "lng": -83.22541406234473,
    "lat": 42.30504387346585
  },
  {
    "name": "Greenhills",
    "lng": -84.51938963210634,
    "lat": 39.2669742348355
  },
  {
    "name": "Greenland",
    "lng": -94.19033385171976,
    "lat": 35.99658089532323
  },
  {
    "name": "Greenland",
    "lng": -94.19032578708702,
    "lat": 35.996574054401535
  },
  {
    "name": "Greenleaf",
    "lng": -116.8220466204752,
    "lat": 43.6744083930235
  },
  {
    "name": "Greenleaf",
    "lng": -116.81558843852548,
    "lat": 43.68052550970624
  },
  {
    "name": "Greenleaf",
    "lng": -96.98022957624143,
    "lat": 39.72726159462975
  },
  {
    "name": "Greenport",
    "lng": -72.36695199437104,
    "lat": 41.10300465166901
  },
  {
    "name": "Greens Fork",
    "lng": -85.0413202489549,
    "lat": 39.89166238427057
  },
  {
    "name": "Greensboro",
    "lng": -87.59522630545325,
    "lat": 32.70158902853228
  },
  {
    "name": "Greensboro",
    "lng": -85.46397093746498,
    "lat": 39.87854987183559
  },
  {
    "name": "Greensboro",
    "lng": -83.21074440621983,
    "lat": 33.5364062937724
  },
  {
    "name": "Greensboro",
    "lng": -83.20256873220235,
    "lat": 33.56381422749946
  },
  {
    "name": "Greensboro",
    "lng": -83.19857392634212,
    "lat": 33.54997247017568
  },
  {
    "name": "Greensboro",
    "lng": -83.18170042049813,
    "lat": 33.576381489713086
  },
  {
    "name": "Greensboro",
    "lng": -83.17147600868383,
    "lat": 33.55078880406304
  },
  {
    "name": "Greensboro",
    "lng": -79.94679275351797,
    "lat": 36.1367244970297
  },
  {
    "name": "Greensboro",
    "lng": -79.94472702977092,
    "lat": 36.135341163862485
  },
  {
    "name": "Greensboro",
    "lng": -79.92078906215987,
    "lat": 36.03331617370777
  },
  {
    "name": "Greensboro",
    "lng": -79.91942051075075,
    "lat": 36.029156569042705
  },
  {
    "name": "Greensboro",
    "lng": -79.91911810918872,
    "lat": 36.031339055702965
  },
  {
    "name": "Greensboro",
    "lng": -79.91857977304409,
    "lat": 36.03851294843437
  },
  {
    "name": "Greensboro",
    "lng": -79.91439142186715,
    "lat": 36.04445355072444
  },
  {
    "name": "Greensboro",
    "lng": -79.89422106543957,
    "lat": 35.988862898120004
  },
  {
    "name": "Greensboro",
    "lng": -79.89408804089713,
    "lat": 35.98740981134123
  },
  {
    "name": "Greensboro",
    "lng": -79.88927470620348,
    "lat": 35.99589134635729
  },
  {
    "name": "Greensboro",
    "lng": -79.87556737582743,
    "lat": 36.026434592023215
  },
  {
    "name": "Greensboro",
    "lng": -79.82773389650602,
    "lat": 36.09695194183463
  },
  {
    "name": "Greensboro",
    "lng": -79.81664041935699,
    "lat": 35.99843661257712
  },
  {
    "name": "Greensboro",
    "lng": -79.81108186353768,
    "lat": 35.990834074763534
  },
  {
    "name": "Greensboro",
    "lng": -79.80885590318759,
    "lat": 35.99738071728197
  },
  {
    "name": "Greensboro",
    "lng": -79.80644046608255,
    "lat": 35.99449713476031
  },
  {
    "name": "Greensboro",
    "lng": -79.80566868721195,
    "lat": 35.99834681944639
  },
  {
    "name": "Greensboro",
    "lng": -79.73309813535327,
    "lat": 36.13254615653764
  },
  {
    "name": "Greensboro",
    "lng": -79.72809450877736,
    "lat": 36.12829342372359
  },
  {
    "name": "Greensboro",
    "lng": -79.72678965733552,
    "lat": 36.07127840531035
  },
  {
    "name": "Greensboro",
    "lng": -79.7261600303384,
    "lat": 36.14732868887259
  },
  {
    "name": "Greensboro",
    "lng": -79.72550702791217,
    "lat": 36.04482772455211
  },
  {
    "name": "Greensboro",
    "lng": -79.7238493878286,
    "lat": 36.07729911207553
  },
  {
    "name": "Greensboro",
    "lng": -79.72150722049025,
    "lat": 36.04629880331229
  },
  {
    "name": "Greensboro",
    "lng": -79.71815888610237,
    "lat": 36.15324457417189
  },
  {
    "name": "Greensboro",
    "lng": -79.717844434006,
    "lat": 36.039895785451606
  },
  {
    "name": "Greensboro",
    "lng": -79.71670814312037,
    "lat": 36.14172842265591
  },
  {
    "name": "Greensboro",
    "lng": -79.71387717751097,
    "lat": 36.06573569735446
  },
  {
    "name": "Greensboro",
    "lng": -79.71366943941723,
    "lat": 35.978127271926894
  },
  {
    "name": "Greensboro",
    "lng": -79.71327278833425,
    "lat": 36.05388317630531
  },
  {
    "name": "Greensboro",
    "lng": -79.71276333237012,
    "lat": 36.15143575658171
  },
  {
    "name": "Greensboro",
    "lng": -79.71202389925112,
    "lat": 36.06055776672024
  },
  {
    "name": "Greensboro",
    "lng": -79.71143544089365,
    "lat": 36.055261054983205
  },
  {
    "name": "Greensboro",
    "lng": -79.70978665093247,
    "lat": 36.16185476579883
  },
  {
    "name": "Greensboro",
    "lng": -79.70740821104245,
    "lat": 36.152741957650136
  },
  {
    "name": "Greensboro",
    "lng": -79.70592823308593,
    "lat": 36.088428982160636
  },
  {
    "name": "Greensboro",
    "lng": -79.70551293035723,
    "lat": 36.046437577593316
  },
  {
    "name": "Greensboro",
    "lng": -79.69884304284965,
    "lat": 36.04408098304827
  },
  {
    "name": "Greensboro",
    "lng": -79.698728025224,
    "lat": 36.04228286004114
  },
  {
    "name": "Greensboro",
    "lng": -79.69345982775366,
    "lat": 36.09042375481265
  },
  {
    "name": "Greensboro",
    "lng": -79.690683282239,
    "lat": 36.08776358804636
  },
  {
    "name": "Greensboro",
    "lng": -79.68802698518178,
    "lat": 36.09083891168766
  },
  {
    "name": "Greensboro",
    "lng": -79.68317955952449,
    "lat": 36.08688408630251
  },
  {
    "name": "Greensboro",
    "lng": -79.67909267571545,
    "lat": 36.086884973850495
  },
  {
    "name": "Greensboro",
    "lng": -79.66350441560307,
    "lat": 36.09430670493915
  },
  {
    "name": "Greensboro",
    "lng": -79.65116653991323,
    "lat": 36.071441418540864
  },
  {
    "name": "Greensboro",
    "lng": -79.65015212532624,
    "lat": 36.060994268412294
  },
  {
    "name": "Greensboro",
    "lng": -79.64065806888051,
    "lat": 36.054538305858856
  },
  {
    "name": "Greensboro",
    "lng": -79.62456072115401,
    "lat": 36.05625495793656
  },
  {
    "name": "Greensboro",
    "lng": -75.80801219650034,
    "lat": 38.97642343535122
  },
  {
    "name": "Greensburg",
    "lng": -99.29292449954609,
    "lat": 37.60489711524085
  },
  {
    "name": "Greensburg",
    "lng": -90.6697597292406,
    "lat": 30.83000412316789
  },
  {
    "name": "Greensburg",
    "lng": -85.495512458048,
    "lat": 37.257646260458706
  },
  {
    "name": "Greensburg",
    "lng": -85.48421365777965,
    "lat": 39.34219288824523
  },
  {
    "name": "Greensburg",
    "lng": -79.54439803684598,
    "lat": 40.311258632412056
  },
  {
    "name": "Greentop",
    "lng": -92.56570716511284,
    "lat": 40.35077914654137
  },
  {
    "name": "Greentown",
    "lng": -85.96325686297202,
    "lat": 40.47854814272658
  },
  {
    "name": "Greentown",
    "lng": -85.95262123412282,
    "lat": 40.453263206942744
  },
  {
    "name": "Greenup",
    "lng": -88.15979197178504,
    "lat": 39.248371724023094
  },
  {
    "name": "Greenup",
    "lng": -82.82992056451808,
    "lat": 38.57291943905771
  },
  {
    "name": "Greenview",
    "lng": -89.7405701611328,
    "lat": 40.0849613195184
  },
  {
    "name": "Greenville",
    "lng": -96.10990603336552,
    "lat": 33.11166774001613
  },
  {
    "name": "Greenville",
    "lng": -95.14604482106068,
    "lat": 43.016938638262744
  },
  {
    "name": "Greenville",
    "lng": -91.05141286030998,
    "lat": 33.38504136492071
  },
  {
    "name": "Greenville",
    "lng": -90.44696962559406,
    "lat": 37.12896561373927
  },
  {
    "name": "Greenville",
    "lng": -89.39487366228195,
    "lat": 38.89377732714281
  },
  {
    "name": "Greenville",
    "lng": -89.38934510610218,
    "lat": 38.88659513252161
  },
  {
    "name": "Greenville",
    "lng": -87.17654356510818,
    "lat": 37.21217164229307
  },
  {
    "name": "Greenville",
    "lng": -87.14895783061414,
    "lat": 37.24463420576629
  },
  {
    "name": "Greenville",
    "lng": -86.63642340462694,
    "lat": 31.842102892200415
  },
  {
    "name": "Greenville",
    "lng": -85.98963328923195,
    "lat": 38.36886256720969
  },
  {
    "name": "Greenville",
    "lng": -85.98738036359357,
    "lat": 38.366500050934405
  },
  {
    "name": "Greenville",
    "lng": -85.985507160016,
    "lat": 38.37229710396762
  },
  {
    "name": "Greenville",
    "lng": -85.29052664247526,
    "lat": 43.18020803617987
  },
  {
    "name": "Greenville",
    "lng": -85.25407360633253,
    "lat": 43.179199209099004
  },
  {
    "name": "Greenville",
    "lng": -84.7156788174592,
    "lat": 33.032333820900206
  },
  {
    "name": "Greenville",
    "lng": -84.62071172774108,
    "lat": 40.10435400146848
  },
  {
    "name": "Greenville",
    "lng": -82.36487056148658,
    "lat": 34.835414612494546
  },
  {
    "name": "Greenville",
    "lng": -82.31138732239354,
    "lat": 34.84779737801896
  },
  {
    "name": "Greenville",
    "lng": -82.30974837923604,
    "lat": 34.847062483391106
  },
  {
    "name": "Greenville",
    "lng": -77.4482717626579,
    "lat": 35.62209211158274
  },
  {
    "name": "Greenville",
    "lng": -77.44798545757645,
    "lat": 35.56542371746033
  },
  {
    "name": "Greenville",
    "lng": -77.44445279757734,
    "lat": 35.560896934455165
  },
  {
    "name": "Greenville",
    "lng": -77.44434679663536,
    "lat": 35.57007597131483
  },
  {
    "name": "Greenville",
    "lng": -77.44270931997272,
    "lat": 35.56383962642211
  },
  {
    "name": "Greenville",
    "lng": -77.43862640165173,
    "lat": 35.54894532417887
  },
  {
    "name": "Greenville",
    "lng": -77.43481533266275,
    "lat": 35.584462320890864
  },
  {
    "name": "Greenville",
    "lng": -77.43171814535809,
    "lat": 35.55764981804023
  },
  {
    "name": "Greenville",
    "lng": -77.4241803489301,
    "lat": 35.628690345285065
  },
  {
    "name": "Greenville",
    "lng": -77.42323351211218,
    "lat": 35.54633448907997
  },
  {
    "name": "Greenville",
    "lng": -77.40238009197614,
    "lat": 35.54873972601449
  },
  {
    "name": "Greenville",
    "lng": -77.39709604011286,
    "lat": 35.55338383967813
  },
  {
    "name": "Greenville",
    "lng": -77.38886185709906,
    "lat": 35.64144985572822
  },
  {
    "name": "Greenville",
    "lng": -77.3810715280664,
    "lat": 35.59714401245723
  },
  {
    "name": "Greenville",
    "lng": -77.36246210864738,
    "lat": 35.65121451097133
  },
  {
    "name": "Greenville",
    "lng": -77.35708691482861,
    "lat": 35.534155293687185
  },
  {
    "name": "Greenville",
    "lng": -77.34584886717768,
    "lat": 35.62465900767524
  },
  {
    "name": "Greenville",
    "lng": -77.34458368416776,
    "lat": 35.63046790751109
  },
  {
    "name": "Greenville",
    "lng": -77.33135368819302,
    "lat": 35.62732616586936
  },
  {
    "name": "Greenville",
    "lng": -77.32956482723647,
    "lat": 35.63716090510379
  },
  {
    "name": "Greenville",
    "lng": -77.32226360721428,
    "lat": 35.62993109734736
  },
  {
    "name": "Greenville",
    "lng": -77.3143757220066,
    "lat": 35.61110962015227
  },
  {
    "name": "Greenville",
    "lng": -77.29507150834161,
    "lat": 35.61322037065758
  },
  {
    "name": "Greenville",
    "lng": -71.79939900103852,
    "lat": 42.757952974481675
  },
  {
    "name": "Greenville",
    "lng": -69.60222269489829,
    "lat": 45.520146911408574
  },
  {
    "name": "Greenville",
    "lng": -69.54697992162187,
    "lat": 45.46326281128672
  },
  {
    "name": "Greenwald",
    "lng": -94.8593424855682,
    "lat": 45.601416541200045
  },
  {
    "name": "Greenway",
    "lng": -90.22212838256436,
    "lat": 36.340583540810236
  },
  {
    "name": "Greenwich",
    "lng": -82.51880355640232,
    "lat": 41.03061522528282
  },
  {
    "name": "Greenwich",
    "lng": -73.62599236384567,
    "lat": 41.045284508744565
  },
  {
    "name": "Greenwich",
    "lng": -73.49677321988057,
    "lat": 43.0864985141169
  },
  {
    "name": "Greenwood",
    "lng": -96.44335509906686,
    "lat": 40.961416777332175
  },
  {
    "name": "Greenwood",
    "lng": -94.34284161499292,
    "lat": 38.851610324009854
  },
  {
    "name": "Greenwood",
    "lng": -94.2406261067058,
    "lat": 35.21324779424849
  },
  {
    "name": "Greenwood",
    "lng": -93.96349235505507,
    "lat": 32.43609387593086
  },
  {
    "name": "Greenwood",
    "lng": -93.92379073013969,
    "lat": 32.44032333094814
  },
  {
    "name": "Greenwood",
    "lng": -93.5519702579221,
    "lat": 44.909389671625874
  },
  {
    "name": "Greenwood",
    "lng": -90.5994764760531,
    "lat": 44.76553619050032
  },
  {
    "name": "Greenwood",
    "lng": -90.19929093967984,
    "lat": 33.5125470709579
  },
  {
    "name": "Greenwood",
    "lng": -88.3896164578354,
    "lat": 42.39152312762316
  },
  {
    "name": "Greenwood",
    "lng": -86.10728260368633,
    "lat": 39.60193484547738
  },
  {
    "name": "Greenwood",
    "lng": -82.19032459620782,
    "lat": 34.228959568586944
  },
  {
    "name": "Greenwood",
    "lng": -82.18942346073497,
    "lat": 34.24184301091375
  },
  {
    "name": "Greenwood",
    "lng": -82.15356683254483,
    "lat": 34.19378166870257
  },
  {
    "name": "Greenwood",
    "lng": -70.67386195656778,
    "lat": 44.33665061053927
  },
  {
    "name": "Greenwood Lake",
    "lng": -74.28901753043489,
    "lat": 41.22135540564338
  },
  {
    "name": "Greenwood Village",
    "lng": -104.91311628561137,
    "lat": 39.61524074075111
  },
  {
    "name": "Greer",
    "lng": -82.26803159905796,
    "lat": 34.91791185193949
  },
  {
    "name": "Greer",
    "lng": -82.25354920330719,
    "lat": 34.95615807206747
  },
  {
    "name": "Greer",
    "lng": -82.23428403235692,
    "lat": 34.93626024453881
  },
  {
    "name": "Greer",
    "lng": -82.23202693795824,
    "lat": 34.90263775896981
  },
  {
    "name": "Greer",
    "lng": -82.21536118363197,
    "lat": 34.9522057488352
  },
  {
    "name": "Greers Ferry",
    "lng": -92.16886394751488,
    "lat": 35.56738012107917
  },
  {
    "name": "Gregory",
    "lng": -99.42499271501202,
    "lat": 43.23243522011418
  },
  {
    "name": "Gregory",
    "lng": -97.29176012721972,
    "lat": 27.922100314595415
  },
  {
    "name": "Grenada",
    "lng": -89.81304195450188,
    "lat": 33.781616684270524
  },
  {
    "name": "Grenola",
    "lng": -96.45234321359287,
    "lat": 37.340857039779316
  },
  {
    "name": "Grenola",
    "lng": -96.449262522174,
    "lat": 37.35072320477608
  },
  {
    "name": "Grenora",
    "lng": -103.9366557191514,
    "lat": 48.61990184039301
  },
  {
    "name": "Grenville",
    "lng": -103.6134162717119,
    "lat": 36.59306628678347
  },
  {
    "name": "Grenville",
    "lng": -97.3902323055097,
    "lat": 45.46699304450498
  },
  {
    "name": "Gresham",
    "lng": -97.40107662944042,
    "lat": 41.02842172337346
  },
  {
    "name": "Gresham",
    "lng": -88.78605820844761,
    "lat": 44.84808537146108
  },
  {
    "name": "Gresham",
    "lng": -88.76367106491017,
    "lat": 44.84240776496
  },
  {
    "name": "Gretna",
    "lng": -97.56076506128248,
    "lat": 49.00790889068305
  },
  {
    "name": "Gretna",
    "lng": -96.25659384399795,
    "lat": 41.11870275548184
  },
  {
    "name": "Gretna",
    "lng": -96.25331715600306,
    "lat": 41.11406795658919
  },
  {
    "name": "Gretna",
    "lng": -96.24082178784246,
    "lat": 41.1418043736513
  },
  {
    "name": "Gretna",
    "lng": -96.22415070435291,
    "lat": 41.13532536643454
  },
  {
    "name": "Gretna",
    "lng": -90.05172884509786,
    "lat": 29.91011734143858
  },
  {
    "name": "Gretna",
    "lng": -84.72166874180036,
    "lat": 30.584191759499298
  },
  {
    "name": "Gretna",
    "lng": -84.6732573465923,
    "lat": 30.59734396847406
  },
  {
    "name": "Gretna",
    "lng": -79.3646381547611,
    "lat": 36.94922819243972
  },
  {
    "name": "Grey Eagle",
    "lng": -94.74912496030699,
    "lat": 45.82428724203025
  },
  {
    "name": "Greybull",
    "lng": -108.0588418653488,
    "lat": 44.489129803841294
  },
  {
    "name": "Gridley",
    "lng": -121.69663630414139,
    "lat": 39.36221741210167
  },
  {
    "name": "Gridley",
    "lng": -95.88312741326679,
    "lat": 38.097492470879395
  },
  {
    "name": "Gridley",
    "lng": -95.87920510390957,
    "lat": 38.11289136300755
  },
  {
    "name": "Gridley",
    "lng": -95.86699061017163,
    "lat": 38.09812826934938
  },
  {
    "name": "Gridley",
    "lng": -88.88005308519539,
    "lat": 40.7441307788032
  },
  {
    "name": "Griffin",
    "lng": -87.91487743336802,
    "lat": 38.20431785599499
  },
  {
    "name": "Griffin",
    "lng": -84.274626732131,
    "lat": 33.241913329158514
  },
  {
    "name": "Griffith",
    "lng": -87.42412803747828,
    "lat": 41.527969568748624
  },
  {
    "name": "Griffithville",
    "lng": -91.64496035468972,
    "lat": 35.123050258069554
  },
  {
    "name": "Grifton",
    "lng": -77.43338171915143,
    "lat": 35.38005791978755
  },
  {
    "name": "Griggsville",
    "lng": -90.72729011944489,
    "lat": 39.70787077049658
  },
  {
    "name": "Grimes",
    "lng": -93.79487787246774,
    "lat": 41.678303622076
  },
  {
    "name": "Grimes",
    "lng": -85.45029197351553,
    "lat": 31.29985723723578
  },
  {
    "name": "Grimesland",
    "lng": -77.24584978661596,
    "lat": 35.59321675899906
  },
  {
    "name": "Grimesland",
    "lng": -77.19141479563413,
    "lat": 35.562302578997596
  },
  {
    "name": "Grinnell",
    "lng": -100.63119105826208,
    "lat": 39.126643096934956
  },
  {
    "name": "Grinnell",
    "lng": -92.72441733285925,
    "lat": 41.73600972667445
  },
  {
    "name": "Griswold",
    "lng": -95.1394004613992,
    "lat": 41.23431020276136
  },
  {
    "name": "Griswold",
    "lng": -71.92266612945686,
    "lat": 41.5852929365114
  },
  {
    "name": "Groesbeck",
    "lng": -96.52837893827888,
    "lat": 31.525818509171145
  },
  {
    "name": "Groom",
    "lng": -101.10669220748294,
    "lat": 35.2052183532314
  },
  {
    "name": "Gross",
    "lng": -98.56973045467151,
    "lat": 42.94608272030335
  },
  {
    "name": "Grosse Pointe",
    "lng": -82.90491860224542,
    "lat": 42.38033197052131
  },
  {
    "name": "Grosse Pointe Farms",
    "lng": -82.86934694306537,
    "lat": 42.391245723967536
  },
  {
    "name": "Grosse Pointe Park",
    "lng": -82.92277284594725,
    "lat": 42.37284356461358
  },
  {
    "name": "Grosse Pointe Shores",
    "lng": -82.82739396482116,
    "lat": 42.42934116729847
  },
  {
    "name": "Grosse Pointe Woods",
    "lng": -82.8988209716207,
    "lat": 42.4366584660355
  },
  {
    "name": "Grosse Tete",
    "lng": -91.43949802330796,
    "lat": 30.414823959902836
  },
  {
    "name": "Groton",
    "lng": -76.36282835463989,
    "lat": 42.58722135267168
  },
  {
    "name": "Groton",
    "lng": -72.03030532981006,
    "lat": 41.34734760011081
  },
  {
    "name": "Groton",
    "lng": -71.56141271264266,
    "lat": 42.61368152182
  },
  {
    "name": "Groton Long Point",
    "lng": -72.00869834887189,
    "lat": 41.31459838590365
  },
  {
    "name": "Grottoes",
    "lng": -78.82519877057511,
    "lat": 38.26914758614277
  },
  {
    "name": "Grove",
    "lng": -94.78841469432294,
    "lat": 36.5926206811785
  },
  {
    "name": "Grove City",
    "lng": -94.68189981876175,
    "lat": 45.14914415022835
  },
  {
    "name": "Grove City",
    "lng": -83.07057505546447,
    "lat": 39.866608707267574
  },
  {
    "name": "Grove City",
    "lng": -80.0892144510906,
    "lat": 41.157185700261365
  },
  {
    "name": "Grove Hill",
    "lng": -87.77586314871105,
    "lat": 31.703261934122306
  },
  {
    "name": "Groveland",
    "lng": -81.85953570385469,
    "lat": 28.574308575078895
  },
  {
    "name": "Groveland",
    "lng": -81.8480179403779,
    "lat": 28.568415332039944
  },
  {
    "name": "Groveland",
    "lng": -81.83014837858762,
    "lat": 28.63551755737996
  },
  {
    "name": "Groveland",
    "lng": -81.82894064591517,
    "lat": 28.646339202550813
  },
  {
    "name": "Groveland",
    "lng": -81.82748419205156,
    "lat": 28.63325479854194
  },
  {
    "name": "Groveland",
    "lng": -81.82017122729617,
    "lat": 28.602592505218325
  },
  {
    "name": "Groveport",
    "lng": -82.90521625624099,
    "lat": 39.840008713954674
  },
  {
    "name": "Groveport",
    "lng": -82.89798678582378,
    "lat": 39.858671022407684
  },
  {
    "name": "Grover",
    "lng": -104.22669946339141,
    "lat": 40.8698601755972
  },
  {
    "name": "Grover",
    "lng": -81.45193484341337,
    "lat": 35.17344548481214
  },
  {
    "name": "Grover Beach",
    "lng": -120.65178050642463,
    "lat": 35.1099853477934
  },
  {
    "name": "Grover Hill",
    "lng": -84.47777669205851,
    "lat": 41.01882689430467
  },
  {
    "name": "Groves",
    "lng": -93.91635342419305,
    "lat": 29.945647951107567
  },
  {
    "name": "Groveton",
    "lng": -95.12686097624324,
    "lat": 31.05713936792492
  },
  {
    "name": "Grovetown",
    "lng": -82.20695869424485,
    "lat": 33.44964682275453
  },
  {
    "name": "Grubbs",
    "lng": -91.07546192905002,
    "lat": 35.6539518683319
  },
  {
    "name": "Gruetli-Laager",
    "lng": -85.63764005678982,
    "lat": 35.372683042212124
  },
  {
    "name": "Grundy",
    "lng": -82.09875387693917,
    "lat": 37.27542653249161
  },
  {
    "name": "Grundy Center",
    "lng": -92.77380222729425,
    "lat": 42.36375187896583
  },
  {
    "name": "Gruver",
    "lng": -101.43213686197826,
    "lat": 36.23549909785498
  },
  {
    "name": "Gruver",
    "lng": -101.40576727992374,
    "lat": 36.26317533809743
  },
  {
    "name": "Gruver",
    "lng": -94.70355839031977,
    "lat": 43.39312846472729
  },
  {
    "name": "Grygla",
    "lng": -95.62176548461777,
    "lat": 48.30135505700022
  },
  {
    "name": "Gu-Win",
    "lng": -87.87036027676835,
    "lat": 33.94434691310088
  },
  {
    "name": "Guadalupe",
    "lng": -120.57288810572462,
    "lat": 34.959919158581464
  },
  {
    "name": "Guernsey",
    "lng": -104.74493518417148,
    "lat": 42.26846126008165
  },
  {
    "name": "Guernsey",
    "lng": -104.742870189726,
    "lat": 42.25655430229612
  },
  {
    "name": "Guernsey",
    "lng": -104.72328554834421,
    "lat": 42.269130771578254
  },
  {
    "name": "Guernsey",
    "lng": -92.34607396582719,
    "lat": 41.65049321771012
  },
  {
    "name": "Gueydan",
    "lng": -92.50699481142381,
    "lat": 30.029000199343866
  },
  {
    "name": "Guide Rock",
    "lng": -98.32881205474965,
    "lat": 40.07369616782152
  },
  {
    "name": "Guilford",
    "lng": -94.73577154738969,
    "lat": 40.16862004026731
  },
  {
    "name": "Guilford",
    "lng": -69.35063948049233,
    "lat": 45.21128656654664
  },
  {
    "name": "Guin",
    "lng": -87.9379831232099,
    "lat": 34.01696495548229
  },
  {
    "name": "Guin",
    "lng": -87.93247443657388,
    "lat": 34.00864375879971
  },
  {
    "name": "Guin",
    "lng": -87.93247443657387,
    "lat": 34.0086437587997
  },
  {
    "name": "Guin",
    "lng": -87.90862182179409,
    "lat": 33.97508064412652
  },
  {
    "name": "Guin",
    "lng": -87.90862182179406,
    "lat": 33.975080644126486
  },
  {
    "name": "Guin",
    "lng": -87.89348902798558,
    "lat": 34.027983646255045
  },
  {
    "name": "Guin",
    "lng": -87.89348902798558,
    "lat": 34.02798364625505
  },
  {
    "name": "Guion",
    "lng": -91.93882237691123,
    "lat": 35.92680644726989
  },
  {
    "name": "Gulf Breeze",
    "lng": -87.1619409944518,
    "lat": 30.36245224994884
  },
  {
    "name": "Gulf Port",
    "lng": -91.08451096622737,
    "lat": 40.80764398951645
  },
  {
    "name": "Gulf Shores",
    "lng": -87.71200301281964,
    "lat": 30.25925526715104
  },
  {
    "name": "Gulf Shores",
    "lng": -87.69830713836757,
    "lat": 30.270190296027387
  },
  {
    "name": "Gulf Shores",
    "lng": -87.6815320897253,
    "lat": 30.325313079564058
  },
  {
    "name": "Gulf Shores",
    "lng": -87.64574086485605,
    "lat": 30.297022502342937
  },
  {
    "name": "Gulf Shores",
    "lng": -87.64574086485601,
    "lat": 30.29702250234293
  },
  {
    "name": "Gulfport",
    "lng": -89.06938643821188,
    "lat": 30.42003350407571
  },
  {
    "name": "Gully",
    "lng": -95.62292153803965,
    "lat": 47.76834492943802
  },
  {
    "name": "Gum Springs",
    "lng": -93.09569546453795,
    "lat": 34.06393201298264
  },
  {
    "name": "Gumbranch",
    "lng": -81.68388071221949,
    "lat": 31.836802217281623
  },
  {
    "name": "Gun Barrel City",
    "lng": -96.16703063678067,
    "lat": 32.31359210643965
  },
  {
    "name": "Gun Barrel City",
    "lng": -96.12888798330975,
    "lat": 32.32765621989248
  },
  {
    "name": "Gunn City",
    "lng": -94.16399390783171,
    "lat": 38.66598297891274
  },
  {
    "name": "Gunnison",
    "lng": -111.81351546704482,
    "lat": 39.15692968452967
  },
  {
    "name": "Gunnison",
    "lng": -106.92836035410888,
    "lat": 38.54454474542519
  },
  {
    "name": "Gunnison",
    "lng": -90.94674834571028,
    "lat": 33.94367799707721
  },
  {
    "name": "Gunter",
    "lng": -96.76453675995562,
    "lat": 33.464772559148685
  },
  {
    "name": "Guntersville",
    "lng": -86.27419006094085,
    "lat": 34.30156247949379
  },
  {
    "name": "Guntersville",
    "lng": -86.26901172708564,
    "lat": 34.41683572995891
  },
  {
    "name": "Guntersville",
    "lng": -86.26883311698546,
    "lat": 34.41457086664263
  },
  {
    "name": "Guntersville",
    "lng": -86.26429249121456,
    "lat": 34.42077770400393
  },
  {
    "name": "Guntersville",
    "lng": -86.26342997985269,
    "lat": 34.3673182102993
  },
  {
    "name": "Guntersville",
    "lng": -86.23954814025049,
    "lat": 34.35996134279285
  },
  {
    "name": "Guntown",
    "lng": -88.66407606691907,
    "lat": 34.44464629552205
  },
  {
    "name": "Gurdon",
    "lng": -93.1545477123852,
    "lat": 33.91543722348744
  },
  {
    "name": "Gurley",
    "lng": -102.974418793372,
    "lat": 41.32139270301783
  },
  {
    "name": "Gurnee",
    "lng": -87.9391172176192,
    "lat": 42.37071186851332
  },
  {
    "name": "Gustavus",
    "lng": -135.7410960904975,
    "lat": 58.41832920945451
  },
  {
    "name": "Gustine",
    "lng": -120.99516793066877,
    "lat": 37.254557617412054
  },
  {
    "name": "Gustine",
    "lng": -120.9759678094253,
    "lat": 37.253521112002915
  },
  {
    "name": "Guthrie",
    "lng": -97.43645585873912,
    "lat": 35.842473933328556
  },
  {
    "name": "Guthrie",
    "lng": -97.38644496168672,
    "lat": 35.88654695625833
  },
  {
    "name": "Guthrie",
    "lng": -97.35390099828834,
    "lat": 35.87803216073818
  },
  {
    "name": "Guthrie",
    "lng": -87.17065723775079,
    "lat": 36.64776880012058
  },
  {
    "name": "Guthrie Center",
    "lng": -94.49903006820387,
    "lat": 41.67792208719042
  },
  {
    "name": "Guttenberg",
    "lng": -91.10560671976077,
    "lat": 42.78812294784102
  },
  {
    "name": "Guttenberg",
    "lng": -74.00465554875977,
    "lat": 40.792746307747926
  },
  {
    "name": "Guy",
    "lng": -92.33667602892315,
    "lat": 35.32731654223867
  },
  {
    "name": "Guy",
    "lng": -92.33663675518027,
    "lat": 35.32728896603537
  },
  {
    "name": "Guymon",
    "lng": -101.47847767304974,
    "lat": 36.68960090405804
  },
  {
    "name": "Guymon",
    "lng": -101.44850238979416,
    "lat": 36.70277846377586
  },
  {
    "name": "Guymon",
    "lng": -101.42535104153968,
    "lat": 36.72323819228956
  },
  {
    "name": "Guys",
    "lng": -88.54153744966966,
    "lat": 35.01930049902909
  },
  {
    "name": "Guyton",
    "lng": -81.41576167525379,
    "lat": 32.324962054780606
  },
  {
    "name": "Guyton",
    "lng": -81.3935376523679,
    "lat": 32.33551047878439
  },
  {
    "name": "Gwinner",
    "lng": -97.66901260738811,
    "lat": 46.23577408950667
  },
  {
    "name": "Gypsum",
    "lng": -106.96898153909434,
    "lat": 39.6570542316562
  },
  {
    "name": "Gypsum",
    "lng": -106.95408769513129,
    "lat": 39.5873514935456
  },
  {
    "name": "Gypsum",
    "lng": -106.944982462274,
    "lat": 39.60116081584676
  },
  {
    "name": "Gypsum",
    "lng": -106.92885919975484,
    "lat": 39.637164470116424
  },
  {
    "name": "Gypsum",
    "lng": -106.83280656056121,
    "lat": 39.64805704575757
  },
  {
    "name": "Gypsum",
    "lng": -97.42686430406442,
    "lat": 38.7057811032259
  },
  {
    "name": "Hackberry",
    "lng": -96.9181254398871,
    "lat": 33.150532535543135
  },
  {
    "name": "Hackensack",
    "lng": -94.52016526666667,
    "lat": 46.914912437961966
  },
  {
    "name": "Hackensack",
    "lng": -94.51578348864932,
    "lat": 46.92704086706131
  },
  {
    "name": "Hackensack",
    "lng": -74.04610032537236,
    "lat": 40.88898355797383
  },
  {
    "name": "Hackett",
    "lng": -94.41056367819682,
    "lat": 35.18776131927466
  },
  {
    "name": "Hackettstown",
    "lng": -74.82568219482853,
    "lat": 40.853927800724705
  },
  {
    "name": "Hackleburg",
    "lng": -87.83041163159955,
    "lat": 34.26957098392175
  },
  {
    "name": "Hadar",
    "lng": -97.45097182186913,
    "lat": 42.107221174848114
  },
  {
    "name": "Haddam",
    "lng": -97.30451602441144,
    "lat": 39.855329090194296
  },
  {
    "name": "Haddam",
    "lng": -72.54585282354167,
    "lat": 41.467675735068454
  },
  {
    "name": "Hadley",
    "lng": -95.85745712687871,
    "lat": 43.998721640003865
  },
  {
    "name": "Hadley",
    "lng": -95.8528303715008,
    "lat": 44.00523057617225
  },
  {
    "name": "Hadley",
    "lng": -72.56922355490188,
    "lat": 42.355664573173435
  },
  {
    "name": "Hagan",
    "lng": -81.93053563370789,
    "lat": 32.153642345337445
  },
  {
    "name": "Hagerman",
    "lng": -114.89764893163766,
    "lat": 42.81639151914041
  },
  {
    "name": "Hagerman",
    "lng": -114.88923688675344,
    "lat": 42.81248014008886
  },
  {
    "name": "Hagerman",
    "lng": -104.32836241149784,
    "lat": 33.1145307819759
  },
  {
    "name": "Hagerstown",
    "lng": -85.15599061638788,
    "lat": 39.911539916544804
  },
  {
    "name": "Hagerstown",
    "lng": -77.72168872716695,
    "lat": 39.64020448795833
  },
  {
    "name": "Hague",
    "lng": -99.99895491378128,
    "lat": 46.028906301453624
  },
  {
    "name": "Hahira",
    "lng": -83.37782529408452,
    "lat": 30.993078810376556
  },
  {
    "name": "Haigler",
    "lng": -101.93847262782678,
    "lat": 40.01206144276911
  },
  {
    "name": "Hailey",
    "lng": -114.30006018062993,
    "lat": 43.51410495415449
  },
  {
    "name": "Haileyville",
    "lng": -95.57851801742645,
    "lat": 34.85411225772157
  },
  {
    "name": "Hainesville",
    "lng": -88.06893218892783,
    "lat": 42.341595458059864
  },
  {
    "name": "Hainesville",
    "lng": -88.05975100271701,
    "lat": 42.33889854463299
  },
  {
    "name": "Halaula CDP",
    "lng": -155.78073818129792,
    "lat": 20.22734435445481
  },
  {
    "name": "Halbur",
    "lng": -94.971680433634,
    "lat": 42.006378730092074
  },
  {
    "name": "Hale",
    "lng": -93.34330550083716,
    "lat": 39.60508548975274
  },
  {
    "name": "Hale Center",
    "lng": -101.8462693885321,
    "lat": 34.06611392944653
  },
  {
    "name": "Haleburg",
    "lng": -85.13806813677094,
    "lat": 31.409977021004146
  },
  {
    "name": "Hales Corners",
    "lng": -88.04916766125932,
    "lat": 42.94117855835312
  },
  {
    "name": "Haleyville",
    "lng": -87.63711837126374,
    "lat": 34.21005494113654
  },
  {
    "name": "Haleyville",
    "lng": -87.62333861154806,
    "lat": 34.19229883933152
  },
  {
    "name": "Haleyville",
    "lng": -87.62205047468781,
    "lat": 34.26371504792485
  },
  {
    "name": "Haleyville",
    "lng": -87.61884376067317,
    "lat": 34.18802082788875
  },
  {
    "name": "Haleyville",
    "lng": -87.61657760520559,
    "lat": 34.23822503462864
  },
  {
    "name": "Haleyville",
    "lng": -87.61559497066644,
    "lat": 34.188691497946074
  },
  {
    "name": "Haleyville",
    "lng": -87.61378059543556,
    "lat": 34.178346102650636
  },
  {
    "name": "Half Moon Bay",
    "lng": -122.43808470756622,
    "lat": 37.46847241571469
  },
  {
    "name": "Halfway",
    "lng": -93.23869063608318,
    "lat": 37.61888958986958
  },
  {
    "name": "Halifax",
    "lng": -78.9279363488385,
    "lat": 36.761215876383055
  },
  {
    "name": "Halifax",
    "lng": -77.59010205456381,
    "lat": 36.325487195565806
  },
  {
    "name": "Hall Summit",
    "lng": -93.30551096286302,
    "lat": 32.17677093543407
  },
  {
    "name": "Hallam",
    "lng": -96.78660466224677,
    "lat": 40.537515336291335
  },
  {
    "name": "Hallam",
    "lng": -76.60420506513542,
    "lat": 40.00240769122246
  },
  {
    "name": "Hallett",
    "lng": -96.56787137093244,
    "lat": 36.231521222458284
  },
  {
    "name": "Hallettsville",
    "lng": -96.94349018126506,
    "lat": 29.443010507632838
  },
  {
    "name": "Halliday",
    "lng": -102.33889308890082,
    "lat": 47.35223469307513
  },
  {
    "name": "Hallock",
    "lng": -96.94250862500296,
    "lat": 48.77329722553422
  },
  {
    "name": "Hallowell",
    "lng": -69.81406345173188,
    "lat": 44.29047808498406
  },
  {
    "name": "Halls",
    "lng": -89.4049347986166,
    "lat": 35.87934988707327
  },
  {
    "name": "Hallsburg",
    "lng": -96.94636465080096,
    "lat": 31.568398619683173
  },
  {
    "name": "Hallsburg",
    "lng": -96.90614108274237,
    "lat": 31.585754227081317
  },
  {
    "name": "Hallsville",
    "lng": -94.57062147431984,
    "lat": 32.502828489057734
  },
  {
    "name": "Hallsville",
    "lng": -92.22760822308229,
    "lat": 39.11913661661775
  },
  {
    "name": "Halltown",
    "lng": -93.62910167119851,
    "lat": 37.19458199301762
  },
  {
    "name": "Hallwood",
    "lng": -75.58956102699037,
    "lat": 37.877425471117604
  },
  {
    "name": "Halma",
    "lng": -96.59723689959331,
    "lat": 48.6599799382318
  },
  {
    "name": "Halsey",
    "lng": -100.26975966999792,
    "lat": 41.90329939355127
  },
  {
    "name": "Halstad",
    "lng": -96.82582694923435,
    "lat": 47.351097783112486
  },
  {
    "name": "Halstead",
    "lng": -97.50953966146238,
    "lat": 37.999683628912834
  },
  {
    "name": "Haltom City",
    "lng": -97.27072699836614,
    "lat": 32.8176286745428
  },
  {
    "name": "Ham Lake",
    "lng": -93.2039510828436,
    "lat": 45.25444831752676
  },
  {
    "name": "Hamberg",
    "lng": -99.51552514543006,
    "lat": 47.762786887774055
  },
  {
    "name": "Hambleton",
    "lng": -79.64543191092689,
    "lat": 39.0813258941219
  },
  {
    "name": "Hamburg",
    "lng": -95.65482828972014,
    "lat": 40.60620482307154
  },
  {
    "name": "Hamburg",
    "lng": -93.96448472466035,
    "lat": 44.73276519100495
  },
  {
    "name": "Hamburg",
    "lng": -91.79724900326347,
    "lat": 33.22486841011826
  },
  {
    "name": "Hamburg",
    "lng": -90.71524886942565,
    "lat": 39.23274846808606
  },
  {
    "name": "Hamburg",
    "lng": -78.83487564520212,
    "lat": 42.72340981910997
  },
  {
    "name": "Hamburg",
    "lng": -75.98265749964771,
    "lat": 40.555718037590424
  },
  {
    "name": "Hamden",
    "lng": -82.52429920562643,
    "lat": 39.159116792655034
  },
  {
    "name": "Hamel",
    "lng": -89.84383704989875,
    "lat": 38.88916530230302
  },
  {
    "name": "Hamel",
    "lng": -89.82494911091148,
    "lat": 38.88862053603232
  },
  {
    "name": "Hamer",
    "lng": -112.20250624907472,
    "lat": 43.92117810163148
  },
  {
    "name": "Hamersville",
    "lng": -83.98550893906123,
    "lat": 38.91826582725653
  },
  {
    "name": "Hamilton",
    "lng": -121.9831154541514,
    "lat": 48.527253390129125
  },
  {
    "name": "Hamilton",
    "lng": -121.9823385,
    "lat": 48.53878250268147
  },
  {
    "name": "Hamilton",
    "lng": -114.16003107262082,
    "lat": 46.25150387018363
  },
  {
    "name": "Hamilton",
    "lng": -114.15589105912399,
    "lat": 46.26091652767178
  },
  {
    "name": "Hamilton",
    "lng": -114.15395284552592,
    "lat": 46.23197525313003
  },
  {
    "name": "Hamilton",
    "lng": -114.14620348970783,
    "lat": 46.28664012548951
  },
  {
    "name": "Hamilton",
    "lng": -98.1212920783975,
    "lat": 31.699520530266586
  },
  {
    "name": "Hamilton",
    "lng": -98.08985220298257,
    "lat": 31.710873540135267
  },
  {
    "name": "Hamilton",
    "lng": -97.45274095037544,
    "lat": 48.80813477956192
  },
  {
    "name": "Hamilton",
    "lng": -96.16386390164188,
    "lat": 37.98051014266657
  },
  {
    "name": "Hamilton",
    "lng": -94.00195277439504,
    "lat": 39.74264277447792
  },
  {
    "name": "Hamilton",
    "lng": -92.9041404789788,
    "lat": 41.170484405344155
  },
  {
    "name": "Hamilton",
    "lng": -91.36245631643736,
    "lat": 40.38991543105512
  },
  {
    "name": "Hamilton",
    "lng": -87.97524066250885,
    "lat": 34.134400241035706
  },
  {
    "name": "Hamilton",
    "lng": -84.92016366586368,
    "lat": 41.54066823952253
  },
  {
    "name": "Hamilton",
    "lng": -84.8759451254074,
    "lat": 32.764699861768094
  },
  {
    "name": "Hamilton",
    "lng": -84.56551418824856,
    "lat": 39.39385473733043
  },
  {
    "name": "Hamilton",
    "lng": -77.66411424133946,
    "lat": 39.13487918699125
  },
  {
    "name": "Hamilton",
    "lng": -77.20669121183172,
    "lat": 35.94431858718926
  },
  {
    "name": "Hamilton",
    "lng": -75.54980033859978,
    "lat": 42.82926218605194
  },
  {
    "name": "Hamler",
    "lng": -84.03366798481294,
    "lat": 41.23046916734003
  },
  {
    "name": "Hamlet",
    "lng": -101.23455132165633,
    "lat": 40.3844513196539
  },
  {
    "name": "Hamlet",
    "lng": -86.58316055224819,
    "lat": 41.37902713345596
  },
  {
    "name": "Hamlet",
    "lng": -79.7242207018102,
    "lat": 34.869297577743005
  },
  {
    "name": "Hamlet",
    "lng": -79.70974444097156,
    "lat": 34.888908658884034
  },
  {
    "name": "Hamlet",
    "lng": -79.70769951620434,
    "lat": 34.8736910504412
  },
  {
    "name": "Hamlet",
    "lng": -79.69014396159324,
    "lat": 34.87753053753377
  },
  {
    "name": "Hamlin",
    "lng": -100.13289110014892,
    "lat": 32.8897713170256
  },
  {
    "name": "Hamlin",
    "lng": -95.62750806383734,
    "lat": 39.91558437458329
  },
  {
    "name": "Hamlin",
    "lng": -82.10779488554655,
    "lat": 38.28052810154361
  },
  {
    "name": "Hamlin",
    "lng": -67.87743145655303,
    "lat": 47.08283203829016
  },
  {
    "name": "Hammon",
    "lng": -99.38292850260581,
    "lat": 35.63229759162524
  },
  {
    "name": "Hammond",
    "lng": -92.43800954550046,
    "lat": 44.968894322277066
  },
  {
    "name": "Hammond",
    "lng": -92.37440034054993,
    "lat": 44.22254637986107
  },
  {
    "name": "Hammond",
    "lng": -92.36512443333335,
    "lat": 44.22458093644723
  },
  {
    "name": "Hammond",
    "lng": -90.45703036837835,
    "lat": 30.506457192901127
  },
  {
    "name": "Hammond",
    "lng": -90.40570198963961,
    "lat": 30.48378605295728
  },
  {
    "name": "Hammond",
    "lng": -88.59228880265853,
    "lat": 39.79942571965586
  },
  {
    "name": "Hammond",
    "lng": -87.49100990363561,
    "lat": 41.61718691330735
  },
  {
    "name": "Hammond",
    "lng": -75.69354316545106,
    "lat": 44.44661361881512
  },
  {
    "name": "Hammond",
    "lng": -67.97304550632282,
    "lat": 46.231974368971905
  },
  {
    "name": "Hammondsport",
    "lng": -77.22356718264918,
    "lat": 42.40825874293047
  },
  {
    "name": "Hammondville",
    "lng": -85.63968008866607,
    "lat": 34.56859903024314
  },
  {
    "name": "Hammonton",
    "lng": -74.76791301449049,
    "lat": 39.65713102916917
  },
  {
    "name": "Hampden",
    "lng": -98.65431671620784,
    "lat": 48.539598436818146
  },
  {
    "name": "Hampden",
    "lng": -72.41564237570512,
    "lat": 42.06385958136248
  },
  {
    "name": "Hampden",
    "lng": -68.88958369929797,
    "lat": 44.73504099738137
  },
  {
    "name": "Hampden (CDP)",
    "lng": -68.83600859251504,
    "lat": 44.747847703298774
  },
  {
    "name": "Hampshire",
    "lng": -88.51224858334083,
    "lat": 42.10991852260603
  },
  {
    "name": "Hampshire",
    "lng": -88.50981296835381,
    "lat": 42.14951325186615
  },
  {
    "name": "Hampshire",
    "lng": -88.48475703333332,
    "lat": 42.138376843336914
  },
  {
    "name": "Hampstead",
    "lng": -76.85493680975144,
    "lat": 39.61050436345515
  },
  {
    "name": "Hampstead",
    "lng": -71.17044604266745,
    "lat": 42.88216351146013
  },
  {
    "name": "Hampton",
    "lng": -97.88752922429461,
    "lat": 40.88111428474575
  },
  {
    "name": "Hampton",
    "lng": -93.20498746376322,
    "lat": 42.742018300730074
  },
  {
    "name": "Hampton",
    "lng": -93.0023932750566,
    "lat": 44.60859231735906
  },
  {
    "name": "Hampton",
    "lng": -92.46658446284762,
    "lat": 33.53477432777062
  },
  {
    "name": "Hampton",
    "lng": -90.40460495704973,
    "lat": 41.55553954936472
  },
  {
    "name": "Hampton",
    "lng": -84.28632603665443,
    "lat": 33.38411828645442
  },
  {
    "name": "Hampton",
    "lng": -84.25629413960037,
    "lat": 33.36327652104979
  },
  {
    "name": "Hampton",
    "lng": -81.10922341096865,
    "lat": 32.86759752270081
  },
  {
    "name": "Hampton",
    "lng": -76.25691404186658,
    "lat": 37.036039685847626
  },
  {
    "name": "Hamtramck",
    "lng": -83.0560306381904,
    "lat": 42.39532899227148
  },
  {
    "name": "Hanaford",
    "lng": -88.83562073691138,
    "lat": 37.957931294000375
  },
  {
    "name": "Hanahan",
    "lng": -80.00337719288274,
    "lat": 32.930387599036294
  },
  {
    "name": "Hanapepe",
    "lng": -159.58778000534846,
    "lat": 21.913492733666917
  },
  {
    "name": "Hanceville",
    "lng": -86.7635775055589,
    "lat": 34.063909251221496
  },
  {
    "name": "Hancock",
    "lng": -95.79496422767109,
    "lat": 45.49776655329168
  },
  {
    "name": "Hancock",
    "lng": -95.36395101742947,
    "lat": 41.39293317439938
  },
  {
    "name": "Hancock",
    "lng": -89.52157511009509,
    "lat": 44.13336677760148
  },
  {
    "name": "Hancock",
    "lng": -89.4985640727913,
    "lat": 44.128716505315396
  },
  {
    "name": "Hancock",
    "lng": -88.59935289157413,
    "lat": 47.13457955586606
  },
  {
    "name": "Hancock",
    "lng": -75.28353403147004,
    "lat": 41.95266122662657
  },
  {
    "name": "Hancock",
    "lng": -73.31978350487286,
    "lat": 42.531388951332325
  },
  {
    "name": "Hancock",
    "lng": -71.99577516860494,
    "lat": 42.975761120258696
  },
  {
    "name": "Hancock",
    "lng": -68.29645744812353,
    "lat": 44.528331330072994
  },
  {
    "name": "Handley",
    "lng": -81.36729282728307,
    "lat": 38.186254157500386
  },
  {
    "name": "Hanford",
    "lng": -119.67647374569856,
    "lat": 36.29406998195977
  },
  {
    "name": "Hanford",
    "lng": -119.66266176632058,
    "lat": 36.29216408592401
  },
  {
    "name": "Hanford",
    "lng": -119.65088943225167,
    "lat": 36.327174523312266
  },
  {
    "name": "Hanford",
    "lng": -119.64190140752537,
    "lat": 36.29320485009898
  },
  {
    "name": "Hanford",
    "lng": -119.63351495957481,
    "lat": 36.338601426206594
  },
  {
    "name": "Hanging Rock",
    "lng": -82.7274482086005,
    "lat": 38.55988227529728
  },
  {
    "name": "Hankinson",
    "lng": -96.89184662969745,
    "lat": 46.07164922001059
  },
  {
    "name": "Hankinson",
    "lng": -96.85232808146317,
    "lat": 46.066237536147575
  },
  {
    "name": "Hanksville",
    "lng": -110.71378654388519,
    "lat": 38.3724351004021
  },
  {
    "name": "Hanley Falls",
    "lng": -95.61945789569755,
    "lat": 44.69182532219539
  },
  {
    "name": "Hanley Hills",
    "lng": -90.32501358255399,
    "lat": 38.685592193091786
  },
  {
    "name": "Hanlontown",
    "lng": -93.37893413783804,
    "lat": 43.28081681051658
  },
  {
    "name": "Hanna",
    "lng": -106.55964354268471,
    "lat": 41.869798082095755
  },
  {
    "name": "Hanna",
    "lng": -95.88933183414055,
    "lat": 35.20426377157535
  },
  {
    "name": "Hanna City",
    "lng": -89.8064040584911,
    "lat": 40.693554243107954
  },
  {
    "name": "Hannaford",
    "lng": -98.18901997219491,
    "lat": 47.31417724583733
  },
  {
    "name": "Hannah",
    "lng": -98.69035485735166,
    "lat": 48.972993258112446
  },
  {
    "name": "Hannibal",
    "lng": -91.41697825532015,
    "lat": 39.70265174679499
  },
  {
    "name": "Hannibal",
    "lng": -91.39280125481831,
    "lat": 39.7095169607648
  },
  {
    "name": "Hannibal",
    "lng": -76.57772094779308,
    "lat": 43.3192809321223
  },
  {
    "name": "Hanover",
    "lng": -96.88504692901816,
    "lat": 39.88925608178541
  },
  {
    "name": "Hanover",
    "lng": -96.87581825993568,
    "lat": 39.893121249537465
  },
  {
    "name": "Hanover",
    "lng": -93.70162809127739,
    "lat": 45.14503617046326
  },
  {
    "name": "Hanover",
    "lng": -93.65933428155859,
    "lat": 45.159267366727505
  },
  {
    "name": "Hanover",
    "lng": -90.2736366464398,
    "lat": 42.25519194649593
  },
  {
    "name": "Hanover",
    "lng": -85.4712800617453,
    "lat": 38.71386030825142
  },
  {
    "name": "Hanover",
    "lng": -84.55382414219562,
    "lat": 42.10012832571884
  },
  {
    "name": "Hanover",
    "lng": -82.27317761058619,
    "lat": 40.083073944703365
  },
  {
    "name": "Hanover",
    "lng": -76.98352064251671,
    "lat": 39.81182250754124
  },
  {
    "name": "Hanover",
    "lng": -72.06440216254421,
    "lat": 41.64481286473092
  },
  {
    "name": "Hanover",
    "lng": -70.72594793665435,
    "lat": 44.4967517673846
  },
  {
    "name": "Hanover Park",
    "lng": -88.14421130177087,
    "lat": 41.98247942832234
  },
  {
    "name": "Hanoverton",
    "lng": -80.93549698365949,
    "lat": 40.754690674384776
  },
  {
    "name": "Hansboro",
    "lng": -99.3807155609192,
    "lat": 48.95232097842892
  },
  {
    "name": "Hansell",
    "lng": -93.10408299411033,
    "lat": 42.757596134290964
  },
  {
    "name": "Hansen",
    "lng": -114.30117731306738,
    "lat": 42.531365504535906
  },
  {
    "name": "Hanska",
    "lng": -94.49446029713442,
    "lat": 44.14881279280174
  },
  {
    "name": "Hanson",
    "lng": -87.47912276704152,
    "lat": 37.41816348673311
  },
  {
    "name": "Hanson",
    "lng": -87.45781436844253,
    "lat": 37.40592338599461
  },
  {
    "name": "Hanston",
    "lng": -99.71261824447426,
    "lat": 38.12299694536679
  },
  {
    "name": "Hapeville",
    "lng": -84.4093031257635,
    "lat": 33.66088243434104
  },
  {
    "name": "Happy",
    "lng": -101.85715316753644,
    "lat": 34.741587552924464
  },
  {
    "name": "Harahan",
    "lng": -90.20330840144693,
    "lat": 29.937305547730784
  },
  {
    "name": "Harahan",
    "lng": -90.19047881479361,
    "lat": 29.940357127343546
  },
  {
    "name": "Harbine",
    "lng": -96.97414919138852,
    "lat": 40.191627451986896
  },
  {
    "name": "Harbor Beach",
    "lng": -82.65604101685959,
    "lat": 43.84529723980992
  },
  {
    "name": "Harbor Springs",
    "lng": -84.98900404550515,
    "lat": 45.432563670712305
  },
  {
    "name": "Harbor View",
    "lng": -83.44475770860018,
    "lat": 41.69328902540266
  },
  {
    "name": "Harcourt",
    "lng": -94.17451476628302,
    "lat": 42.26053971845088
  },
  {
    "name": "Hardeeville",
    "lng": -81.03193819267898,
    "lat": 32.30116541222976
  },
  {
    "name": "Hardesty",
    "lng": -101.19366105210639,
    "lat": 36.614870175638124
  },
  {
    "name": "Hardin",
    "lng": -107.60819733665076,
    "lat": 45.74241419640664
  },
  {
    "name": "Hardin",
    "lng": -94.73673878685813,
    "lat": 30.15048629452371
  },
  {
    "name": "Hardin",
    "lng": -93.83135223530952,
    "lat": 39.2676423194709
  },
  {
    "name": "Hardin",
    "lng": -90.62492548845611,
    "lat": 39.15903346948329
  },
  {
    "name": "Hardin",
    "lng": -88.3016718312742,
    "lat": 36.76472596107515
  },
  {
    "name": "Harding",
    "lng": -94.03780148067597,
    "lat": 46.119364702170316
  },
  {
    "name": "Hardinsburg",
    "lng": -86.45349890915452,
    "lat": 37.77552328671387
  },
  {
    "name": "Hardinsburg",
    "lng": -86.2732648440909,
    "lat": 38.46204573649925
  },
  {
    "name": "Hardtner",
    "lng": -98.64932831269527,
    "lat": 37.01473136174691
  },
  {
    "name": "Hardwick",
    "lng": -96.19748952507587,
    "lat": 43.7740975781371
  },
  {
    "name": "Hardwick",
    "lng": -72.21503404495833,
    "lat": 42.35479276552934
  },
  {
    "name": "Hardy",
    "lng": -97.92380619334439,
    "lat": 40.01135121453324
  },
  {
    "name": "Hardy",
    "lng": -94.05107015532317,
    "lat": 42.810090116654564
  },
  {
    "name": "Hardy",
    "lng": -91.47943681442364,
    "lat": 36.31945971217177
  },
  {
    "name": "Harker Heights",
    "lng": -97.64419792190071,
    "lat": 31.057892598257393
  },
  {
    "name": "Harlan",
    "lng": -95.32687595119135,
    "lat": 41.64962612833831
  },
  {
    "name": "Harlan",
    "lng": -83.31888017574995,
    "lat": 36.83883467597734
  },
  {
    "name": "Harlem",
    "lng": -108.78474867959757,
    "lat": 48.53192154360627
  },
  {
    "name": "Harlem",
    "lng": -82.31836124673146,
    "lat": 33.43227039500854
  },
  {
    "name": "Harlem",
    "lng": -82.31826195146348,
    "lat": 33.441564153685825
  },
  {
    "name": "Harlem",
    "lng": -82.31735886790571,
    "lat": 33.42912188630363
  },
  {
    "name": "Harlem",
    "lng": -82.31669819543521,
    "lat": 33.42854211507604
  },
  {
    "name": "Harlem",
    "lng": -82.31387485454206,
    "lat": 33.41569273857358
  },
  {
    "name": "Harleyville",
    "lng": -80.44800707259593,
    "lat": 33.212729382850135
  },
  {
    "name": "Harlingen",
    "lng": -97.76114641406852,
    "lat": 26.2048532422343
  },
  {
    "name": "Harlingen",
    "lng": -97.75841817219779,
    "lat": 26.210145461568544
  },
  {
    "name": "Harlingen",
    "lng": -97.74557817193887,
    "lat": 26.208379368109636
  },
  {
    "name": "Harlingen",
    "lng": -97.74055990536759,
    "lat": 26.209438444129567
  },
  {
    "name": "Harlingen",
    "lng": -97.6942484214603,
    "lat": 26.19138229698778
  },
  {
    "name": "Harlowton",
    "lng": -109.8345321534075,
    "lat": 46.43682245668413
  },
  {
    "name": "Harman",
    "lng": -79.52455157441374,
    "lat": 38.92106909911397
  },
  {
    "name": "Harmon",
    "lng": -89.55612823417991,
    "lat": 41.71940288285002
  },
  {
    "name": "Harmonsburg",
    "lng": -80.31419158413125,
    "lat": 41.665535454443585
  },
  {
    "name": "Harmony",
    "lng": -92.00748468313375,
    "lat": 43.55396622805813
  },
  {
    "name": "Harmony",
    "lng": -87.07344097695655,
    "lat": 39.53334483751936
  },
  {
    "name": "Harmony",
    "lng": -80.77353467941872,
    "lat": 35.95929157877369
  },
  {
    "name": "Harmony",
    "lng": -69.54372156518478,
    "lat": 44.97482863162782
  },
  {
    "name": "Harper",
    "lng": -98.02792930048913,
    "lat": 37.2847641626973
  },
  {
    "name": "Harper",
    "lng": -98.0192905080662,
    "lat": 37.296260522976034
  },
  {
    "name": "Harper",
    "lng": -92.0509783028112,
    "lat": 41.36374275578539
  },
  {
    "name": "Harper Woods",
    "lng": -82.92938036063357,
    "lat": 42.43899015971087
  },
  {
    "name": "Harpers Ferry",
    "lng": -91.15241233792268,
    "lat": 43.20075603434007
  },
  {
    "name": "Harpers Ferry",
    "lng": -77.74111150970658,
    "lat": 39.325427714581956
  },
  {
    "name": "Harpersville",
    "lng": -86.43189323337285,
    "lat": 33.32126888216774
  },
  {
    "name": "Harpster",
    "lng": -83.2502751707239,
    "lat": 40.73846875195546
  },
  {
    "name": "Harpswell",
    "lng": -69.96453710705227,
    "lat": 43.73756814658383
  },
  {
    "name": "Harrah",
    "lng": -120.54228000434873,
    "lat": 46.40695846318568
  },
  {
    "name": "Harrah",
    "lng": -97.18666716479008,
    "lat": 35.4801690868024
  },
  {
    "name": "Harrah",
    "lng": -97.17837102404003,
    "lat": 35.43392253648261
  },
  {
    "name": "Harrah",
    "lng": -97.17113542483644,
    "lat": 35.43466990967148
  },
  {
    "name": "Harrell",
    "lng": -92.4000773318186,
    "lat": 33.50985980292349
  },
  {
    "name": "Harrells",
    "lng": -78.20149616568432,
    "lat": 34.72786525740914
  },
  {
    "name": "Harrellsville",
    "lng": -76.7917773688929,
    "lat": 36.30167369047316
  },
  {
    "name": "Harrietta",
    "lng": -85.70069332458802,
    "lat": 44.30908507427898
  },
  {
    "name": "Harriman",
    "lng": -84.56060284898216,
    "lat": 35.93101227560481
  },
  {
    "name": "Harriman",
    "lng": -74.1444285566143,
    "lat": 41.308882645648055
  },
  {
    "name": "Harrington",
    "lng": -118.25517100637857,
    "lat": 47.48021741699886
  },
  {
    "name": "Harrington",
    "lng": -67.80361619171276,
    "lat": 44.532683026308156
  },
  {
    "name": "Harris",
    "lng": -95.43718608783638,
    "lat": 43.44508927084003
  },
  {
    "name": "Harris",
    "lng": -93.35028249870997,
    "lat": 40.30615578642333
  },
  {
    "name": "Harris",
    "lng": -92.98684331541908,
    "lat": 45.5976066771039
  },
  {
    "name": "Harrisburg",
    "lng": -96.70330082469688,
    "lat": 43.43183461455589
  },
  {
    "name": "Harrisburg",
    "lng": -92.45802335600011,
    "lat": 39.140033869819035
  },
  {
    "name": "Harrisburg",
    "lng": -90.7216389611119,
    "lat": 35.5637409206085
  },
  {
    "name": "Harrisburg",
    "lng": -88.54570698602487,
    "lat": 37.737454382975436
  },
  {
    "name": "Harrisburg",
    "lng": -83.16927942442338,
    "lat": 39.810612435411436
  },
  {
    "name": "Harrisburg",
    "lng": -80.65684900946714,
    "lat": 35.288278928881326
  },
  {
    "name": "Harrisburg",
    "lng": -80.65667962377731,
    "lat": 35.32186282256196
  },
  {
    "name": "Harrisburg",
    "lng": -80.65284892767052,
    "lat": 35.291189493857026
  },
  {
    "name": "Harrisburg",
    "lng": -80.64023016536659,
    "lat": 35.29619107352029
  },
  {
    "name": "Harrisburg",
    "lng": -80.63981023544329,
    "lat": 35.270569591549
  },
  {
    "name": "Harrisburg",
    "lng": -80.63829556072766,
    "lat": 35.31942733293749
  },
  {
    "name": "Harrisburg",
    "lng": -80.62796245882012,
    "lat": 35.312402125602
  },
  {
    "name": "Harrisburg",
    "lng": -80.61821223058038,
    "lat": 35.29456925029695
  },
  {
    "name": "Harrisburg",
    "lng": -80.61152327283138,
    "lat": 35.307582414871675
  },
  {
    "name": "Harrisburg",
    "lng": -80.60865556654495,
    "lat": 35.31533528680177
  },
  {
    "name": "Harrisburg",
    "lng": -80.60490894098228,
    "lat": 35.30275785900516
  },
  {
    "name": "Harrisburg",
    "lng": -76.88427284602307,
    "lat": 40.275030673551306
  },
  {
    "name": "Harrison",
    "lng": -116.8037380117671,
    "lat": 47.467407412618726
  },
  {
    "name": "Harrison",
    "lng": -116.76839113206357,
    "lat": 47.46665336430178
  },
  {
    "name": "Harrison",
    "lng": -103.88245267975871,
    "lat": 42.688084352870135
  },
  {
    "name": "Harrison",
    "lng": -93.11970399271043,
    "lat": 36.24378691761685
  },
  {
    "name": "Harrison",
    "lng": -84.80798833471329,
    "lat": 44.016831428389374
  },
  {
    "name": "Harrison",
    "lng": -84.78980890426625,
    "lat": 39.25549327964792
  },
  {
    "name": "Harrison",
    "lng": -82.72583355383027,
    "lat": 32.8263232900746
  },
  {
    "name": "Harrison",
    "lng": -74.15312150763467,
    "lat": 40.743109778303634
  },
  {
    "name": "Harrison",
    "lng": -73.71924838782392,
    "lat": 41.02333034703862
  },
  {
    "name": "Harrison",
    "lng": -70.64043576063159,
    "lat": 44.09562333697961
  },
  {
    "name": "Harrisonburg",
    "lng": -91.82508714875969,
    "lat": 31.76801293044687
  },
  {
    "name": "Harrisonburg",
    "lng": -78.87347689169981,
    "lat": 38.43619740396246
  },
  {
    "name": "Harrisonville",
    "lng": -94.34664104811185,
    "lat": 38.65302307238091
  },
  {
    "name": "Harrisonville",
    "lng": -94.33866271587267,
    "lat": 38.61740307687246
  },
  {
    "name": "Harristown",
    "lng": -89.06070541056437,
    "lat": 39.842846130829436
  },
  {
    "name": "Harrisville",
    "lng": -111.98596138524044,
    "lat": 41.28533152267953
  },
  {
    "name": "Harrisville",
    "lng": -83.29461709084626,
    "lat": 44.657402733407515
  },
  {
    "name": "Harrisville",
    "lng": -81.04853302761934,
    "lat": 39.210451945330234
  },
  {
    "name": "Harrisville",
    "lng": -80.8869385107669,
    "lat": 40.18149335512817
  },
  {
    "name": "Harrisville",
    "lng": -75.32075207224607,
    "lat": 44.15289055284407
  },
  {
    "name": "Harrod",
    "lng": -83.92134826830971,
    "lat": 40.70749306111155
  },
  {
    "name": "Harrodsburg",
    "lng": -84.84738317273188,
    "lat": 37.76606586362131
  },
  {
    "name": "Harrogate",
    "lng": -83.64651692322504,
    "lat": 36.574428682082974
  },
  {
    "name": "Harrold",
    "lng": -99.73926886033664,
    "lat": 44.523487542066064
  },
  {
    "name": "Hart",
    "lng": -102.11500622783679,
    "lat": 34.385712325246395
  },
  {
    "name": "Hart",
    "lng": -86.36950215560579,
    "lat": 43.694955463258616
  },
  {
    "name": "Hartford",
    "lng": -96.9448454973693,
    "lat": 43.62208032480062
  },
  {
    "name": "Hartford",
    "lng": -95.95667544775792,
    "lat": 38.30810429410711
  },
  {
    "name": "Hartford",
    "lng": -94.37941778173949,
    "lat": 35.02375069062311
  },
  {
    "name": "Hartford",
    "lng": -93.40379158474616,
    "lat": 41.45779053254437
  },
  {
    "name": "Hartford",
    "lng": -90.0909263207367,
    "lat": 38.82114021794528
  },
  {
    "name": "Hartford",
    "lng": -88.43734061090953,
    "lat": 43.32262624787388
  },
  {
    "name": "Hartford",
    "lng": -88.38068851699349,
    "lat": 43.32164836884783
  },
  {
    "name": "Hartford",
    "lng": -88.33399728661244,
    "lat": 43.32526872702233
  },
  {
    "name": "Hartford",
    "lng": -88.3051412284746,
    "lat": 43.31796559586241
  },
  {
    "name": "Hartford",
    "lng": -86.89295451368478,
    "lat": 37.450747489019484
  },
  {
    "name": "Hartford",
    "lng": -86.16602461989095,
    "lat": 42.20494867463293
  },
  {
    "name": "Hartford",
    "lng": -85.69155249958307,
    "lat": 31.105521594281527
  },
  {
    "name": "Hartford",
    "lng": -82.68800210494939,
    "lat": 40.24016887765807
  },
  {
    "name": "Hartford",
    "lng": -72.6834308920616,
    "lat": 41.766013092484386
  },
  {
    "name": "Hartford",
    "lng": -70.33633330636934,
    "lat": 44.38225153036469
  },
  {
    "name": "Hartford City",
    "lng": -85.373486175294,
    "lat": 40.45362896673322
  },
  {
    "name": "Hartford City",
    "lng": -85.36892000116778,
    "lat": 40.47566761736615
  },
  {
    "name": "Hartford City",
    "lng": -81.99050939792586,
    "lat": 38.997875201008355
  },
  {
    "name": "Hartington",
    "lng": -97.26644593937088,
    "lat": 42.62078554887033
  },
  {
    "name": "Hartland",
    "lng": -93.48431379750289,
    "lat": 43.804273344443736
  },
  {
    "name": "Hartland",
    "lng": -88.35364048286488,
    "lat": 43.117628342985846
  },
  {
    "name": "Hartland",
    "lng": -88.3416694516488,
    "lat": 43.1011974940599
  },
  {
    "name": "Hartland",
    "lng": -88.30737630964084,
    "lat": 43.12198048528564
  },
  {
    "name": "Hartland",
    "lng": -72.94886198821331,
    "lat": 42.00439044935656
  },
  {
    "name": "Hartland",
    "lng": -69.5205648362623,
    "lat": 44.879926151700175
  },
  {
    "name": "Hartley",
    "lng": -102.39329816458932,
    "lat": 35.89144305753011
  },
  {
    "name": "Hartley",
    "lng": -95.47647160831046,
    "lat": 43.179028122964674
  },
  {
    "name": "Hartline",
    "lng": -119.10830284242245,
    "lat": 47.68954392032284
  },
  {
    "name": "Hartman",
    "lng": -102.21875231575773,
    "lat": 38.12102518698513
  },
  {
    "name": "Hartman",
    "lng": -93.62359133708358,
    "lat": 35.435277786841645
  },
  {
    "name": "Hartsburg",
    "lng": -92.31038363894785,
    "lat": 38.6950118356754
  },
  {
    "name": "Hartsburg",
    "lng": -89.44113097285766,
    "lat": 40.25053773162043
  },
  {
    "name": "Hartselle",
    "lng": -86.93982065251045,
    "lat": 34.43918485718049
  },
  {
    "name": "Hartshorne",
    "lng": -95.55857255036446,
    "lat": 34.83884742071985
  },
  {
    "name": "Hartstown",
    "lng": -80.3824039348015,
    "lat": 41.551137776358544
  },
  {
    "name": "Hartsville",
    "lng": -85.69918576864826,
    "lat": 39.26693133896685
  },
  {
    "name": "Hartsville",
    "lng": -80.08287042097226,
    "lat": 34.3658194501355
  },
  {
    "name": "Hartsville",
    "lng": -80.06780935618698,
    "lat": 34.35345840389589
  },
  {
    "name": "Hartville",
    "lng": -104.72452995395996,
    "lat": 42.32756420712597
  },
  {
    "name": "Hartville",
    "lng": -92.51184665262758,
    "lat": 37.25030116162823
  },
  {
    "name": "Hartville",
    "lng": -81.33501787507502,
    "lat": 40.961488066264884
  },
  {
    "name": "Hartwell",
    "lng": -82.95711787257355,
    "lat": 34.36220909237841
  },
  {
    "name": "Hartwell",
    "lng": -82.95382721724822,
    "lat": 34.34122243284842
  },
  {
    "name": "Hartwell",
    "lng": -82.92942653951931,
    "lat": 34.350162354470974
  },
  {
    "name": "Hartwell",
    "lng": -82.92482795452072,
    "lat": 34.37395955580882
  },
  {
    "name": "Hartwick",
    "lng": -92.34506154949665,
    "lat": 41.785469926996164
  },
  {
    "name": "Harvard",
    "lng": -98.09612129310999,
    "lat": 40.620076695384775
  },
  {
    "name": "Harvard",
    "lng": -88.62118766960674,
    "lat": 42.429698040234456
  },
  {
    "name": "Harvard",
    "lng": -71.58529765453741,
    "lat": 42.50591823323746
  },
  {
    "name": "Harvel",
    "lng": -89.53228013948174,
    "lat": 39.35766411516081
  },
  {
    "name": "Harvest",
    "lng": -86.7520853662205,
    "lat": 34.85576034148309
  },
  {
    "name": "Harvey",
    "lng": -99.93870756697483,
    "lat": 47.76031528665328
  },
  {
    "name": "Harvey",
    "lng": -99.93099459189555,
    "lat": 47.77506488569863
  },
  {
    "name": "Harvey",
    "lng": -99.91275181433772,
    "lat": 47.79237310395726
  },
  {
    "name": "Harvey",
    "lng": -92.92301709931604,
    "lat": 41.317792831942185
  },
  {
    "name": "Harvey",
    "lng": -87.6519290015881,
    "lat": 41.60759974556353
  },
  {
    "name": "Harveysburg",
    "lng": -83.99805482615467,
    "lat": 39.50089523375112
  },
  {
    "name": "Harveyville",
    "lng": -95.96274901045138,
    "lat": 38.78963804755249
  },
  {
    "name": "Harwinton",
    "lng": -73.0583243862188,
    "lat": 41.7550766168767
  },
  {
    "name": "Harwood",
    "lng": -96.87951425601013,
    "lat": 46.97614472387897
  },
  {
    "name": "Harwood",
    "lng": -94.15391585564804,
    "lat": 37.956692492085025
  },
  {
    "name": "Harwood Heights",
    "lng": -87.80575137317001,
    "lat": 41.96633659565576
  },
  {
    "name": "Haskell",
    "lng": -99.73199842693471,
    "lat": 33.159751744796246
  },
  {
    "name": "Haskell",
    "lng": -95.68048836708556,
    "lat": 35.8188092967363
  },
  {
    "name": "Haskell",
    "lng": -92.64053542291498,
    "lat": 34.50962388170755
  },
  {
    "name": "Haskins",
    "lng": -83.70371537360539,
    "lat": 41.46382523230134
  },
  {
    "name": "Haslet",
    "lng": -97.33921622036021,
    "lat": 32.96113566766416
  },
  {
    "name": "Hassell",
    "lng": -77.27632165301542,
    "lat": 35.90838886209617
  },
  {
    "name": "Hastings",
    "lng": -98.43999000241497,
    "lat": 40.567995484592515
  },
  {
    "name": "Hastings",
    "lng": -98.39633617204564,
    "lat": 40.5969993403585
  },
  {
    "name": "Hastings",
    "lng": -98.10871913583055,
    "lat": 34.224274426859516
  },
  {
    "name": "Hastings",
    "lng": -95.49474762973526,
    "lat": 41.02520390796121
  },
  {
    "name": "Hastings",
    "lng": -92.85379021427595,
    "lat": 44.73209917913833
  },
  {
    "name": "Hastings",
    "lng": -85.28860768825005,
    "lat": 42.649790951290655
  },
  {
    "name": "Hastings-on-Hudson",
    "lng": -73.87973374717717,
    "lat": 40.99033972458329
  },
  {
    "name": "Haswell",
    "lng": -103.1648974785691,
    "lat": 38.45247447113951
  },
  {
    "name": "Hatch",
    "lng": -112.43478907123,
    "lat": 37.649301114563166
  },
  {
    "name": "Hatch",
    "lng": -107.18704272472164,
    "lat": 32.65499710578017
  },
  {
    "name": "Hatfield",
    "lng": -96.19040790781206,
    "lat": 43.954688303397575
  },
  {
    "name": "Hatfield",
    "lng": -94.37922366838774,
    "lat": 34.48610443027823
  },
  {
    "name": "Hatfield",
    "lng": -94.37921434703905,
    "lat": 34.48606592582765
  },
  {
    "name": "Hatfield",
    "lng": -72.621738372813,
    "lat": 42.38791930967908
  },
  {
    "name": "Hatley",
    "lng": -89.3371531571612,
    "lat": 44.88647063304071
  },
  {
    "name": "Hatley",
    "lng": -88.41839190471445,
    "lat": 33.97732057953764
  },
  {
    "name": "Hattiesburg",
    "lng": -89.31641829333124,
    "lat": 31.30726691978254
  },
  {
    "name": "Hatton",
    "lng": -118.82760937740329,
    "lat": 46.77345758853191
  },
  {
    "name": "Hatton",
    "lng": -97.4587641579762,
    "lat": 47.636973697632136
  },
  {
    "name": "Haubstadt",
    "lng": -87.57550265569816,
    "lat": 38.2035965332063
  },
  {
    "name": "Haugen",
    "lng": -91.77884051202909,
    "lat": 45.60781379715957
  },
  {
    "name": "Haughton",
    "lng": -93.50427276305376,
    "lat": 32.527092250229714
  },
  {
    "name": "Hauser",
    "lng": -117.01421479133414,
    "lat": 47.77192069805266
  },
  {
    "name": "Hauser",
    "lng": -117.01006515715152,
    "lat": 47.75193163491928
  },
  {
    "name": "Havana",
    "lng": -97.61837168517911,
    "lat": 45.95169851168791
  },
  {
    "name": "Havana",
    "lng": -95.9422914658976,
    "lat": 37.09211191100906
  },
  {
    "name": "Havana",
    "lng": -93.52711565678815,
    "lat": 35.11049420856863
  },
  {
    "name": "Havana",
    "lng": -90.05622895274041,
    "lat": 40.29522741593873
  },
  {
    "name": "Havana",
    "lng": -84.41839484479918,
    "lat": 30.61001626979756
  },
  {
    "name": "Havana",
    "lng": -84.41611829999839,
    "lat": 30.636636049722256
  },
  {
    "name": "Havana",
    "lng": -84.41477368954693,
    "lat": 30.637064492225456
  },
  {
    "name": "Havana",
    "lng": -84.41354852407866,
    "lat": 30.62980510335483
  },
  {
    "name": "Havelock",
    "lng": -94.70099774169502,
    "lat": 42.833066337031696
  },
  {
    "name": "Havelock",
    "lng": -76.93840109948792,
    "lat": 34.93329860667543
  },
  {
    "name": "Havelock",
    "lng": -76.93008838442466,
    "lat": 34.8745803637926
  },
  {
    "name": "Havelock",
    "lng": -76.89749469808213,
    "lat": 34.907183494949265
  },
  {
    "name": "Haven",
    "lng": -97.78161084618559,
    "lat": 37.901638145378875
  },
  {
    "name": "Havensville",
    "lng": -96.0764229122856,
    "lat": 39.51139632930441
  },
  {
    "name": "Haverhill",
    "lng": -92.96077422382045,
    "lat": 41.9441243504859
  },
  {
    "name": "Haverhill",
    "lng": -80.12172154827002,
    "lat": 26.690970360123167
  },
  {
    "name": "Haverstraw",
    "lng": -73.95181352025176,
    "lat": 41.192765437507795
  },
  {
    "name": "Haviland",
    "lng": -99.10528741805848,
    "lat": 37.617595157061366
  },
  {
    "name": "Haviland",
    "lng": -84.59442137927655,
    "lat": 41.01351308853682
  },
  {
    "name": "Haviland",
    "lng": -84.58351491150535,
    "lat": 41.01862155202495
  },
  {
    "name": "Havre",
    "lng": -109.67179459890738,
    "lat": 48.54382427616985
  },
  {
    "name": "Havre de Grace",
    "lng": -76.10892124706052,
    "lat": 39.5475099774415
  },
  {
    "name": "Haw River",
    "lng": -79.37555389983925,
    "lat": 36.10949095016975
  },
  {
    "name": "Haw River",
    "lng": -79.36109596509161,
    "lat": 36.0936977663572
  },
  {
    "name": "Hawaii",
    "lng": -178.33295753041028,
    "lat": 28.420282880108118
  },
  {
    "name": "Hawaii",
    "lng": -175.8442930055606,
    "lat": 27.846317130023824
  },
  {
    "name": "Hawaii",
    "lng": -173.9886304358729,
    "lat": 26.065797438685472
  },
  {
    "name": "Hawaii",
    "lng": -171.73779228650346,
    "lat": 25.774110172372307
  },
  {
    "name": "Hawaii",
    "lng": -170.6291486658819,
    "lat": 25.506494070523583
  },
  {
    "name": "Hawaii",
    "lng": -168.00110626672964,
    "lat": 24.99887151748374
  },
  {
    "name": "Hawaii",
    "lng": -166.23016251893097,
    "lat": 23.779339313764623
  },
  {
    "name": "Hawaii",
    "lng": -164.70201607367505,
    "lat": 23.571229097669402
  },
  {
    "name": "Hawaii",
    "lng": -161.92319945127198,
    "lat": 23.060296721202022
  },
  {
    "name": "Hawaii",
    "lng": -160.54126267876205,
    "lat": 21.656404018759694
  },
  {
    "name": "Hawaii",
    "lng": -160.15315638111838,
    "lat": 21.903974771477067
  },
  {
    "name": "Hawaii",
    "lng": -159.52848580278066,
    "lat": 22.06114705269274
  },
  {
    "name": "Hawaii",
    "lng": -157.96143268951855,
    "lat": 21.455612059808647
  },
  {
    "name": "Hawaii",
    "lng": -157.0133865992527,
    "lat": 21.141477907439484
  },
  {
    "name": "Hawaii",
    "lng": -156.93312918632682,
    "lat": 20.84143282706943
  },
  {
    "name": "Hawaii",
    "lng": -156.38271371632808,
    "lat": 20.76785623379405
  },
  {
    "name": "Hawaii",
    "lng": -155.5229215782922,
    "lat": 19.603364759603206
  },
  {
    "name": "Hawaiian Beaches CDP",
    "lng": -154.91742814879524,
    "lat": 19.531536189750874
  },
  {
    "name": "Hawaiian Ocean View",
    "lng": -155.75685831298821,
    "lat": 19.06768142102026
  },
  {
    "name": "Hawarden",
    "lng": -96.48314196059864,
    "lat": 43.00177050486559
  },
  {
    "name": "Hawesville",
    "lng": -86.75481573111428,
    "lat": 37.897420716192016
  },
  {
    "name": "Hawk Cove",
    "lng": -96.0837586898068,
    "lat": 32.88405202676485
  },
  {
    "name": "Hawk Cove",
    "lng": -96.07624897999968,
    "lat": 32.882901411971666
  },
  {
    "name": "Hawk Point",
    "lng": -91.1315087888776,
    "lat": 38.9717207719895
  },
  {
    "name": "Hawkeye",
    "lng": -91.95045813357183,
    "lat": 42.937787560962974
  },
  {
    "name": "Hawkins",
    "lng": -95.20274513770426,
    "lat": 32.591723213195394
  },
  {
    "name": "Hawkins",
    "lng": -90.71480429545929,
    "lat": 45.51144260985426
  },
  {
    "name": "Hawkinsville",
    "lng": -83.48262131619528,
    "lat": 32.29567942079136
  },
  {
    "name": "Hawkinsville",
    "lng": -83.43385066026079,
    "lat": 32.31500105178196
  },
  {
    "name": "Hawley",
    "lng": -96.31779771069162,
    "lat": 46.87702282410793
  },
  {
    "name": "Hawley",
    "lng": -72.90651683927224,
    "lat": 42.584879193797036
  },
  {
    "name": "Haworth",
    "lng": -94.65713532617598,
    "lat": 33.840263152669806
  },
  {
    "name": "Hawthorn Woods",
    "lng": -88.06241684158694,
    "lat": 42.2315052903763
  },
  {
    "name": "Hawthorne",
    "lng": -118.62739384017807,
    "lat": 38.52560575667604
  },
  {
    "name": "Hawthorne",
    "lng": -118.34775324011008,
    "lat": 33.91470013149602
  },
  {
    "name": "Hawthorne",
    "lng": -82.08377878496239,
    "lat": 29.58821298025502
  },
  {
    "name": "Haxtun",
    "lng": -102.62902438539253,
    "lat": 40.64192704826313
  },
  {
    "name": "Hay Springs",
    "lng": -102.68964323811412,
    "lat": 42.6834755055539
  },
  {
    "name": "Hayden",
    "lng": -116.80395450205124,
    "lat": 47.76803693784792
  },
  {
    "name": "Hayden",
    "lng": -116.76631416554414,
    "lat": 47.772488226693824
  },
  {
    "name": "Hayden",
    "lng": -110.78143345752962,
    "lat": 32.99951486400352
  },
  {
    "name": "Hayden",
    "lng": -107.27434523355903,
    "lat": 40.49725826719406
  },
  {
    "name": "Hayden",
    "lng": -107.26286436935176,
    "lat": 40.497094912773335
  },
  {
    "name": "Hayden",
    "lng": -107.24084271101454,
    "lat": 40.48442478977326
  },
  {
    "name": "Hayden",
    "lng": -86.75316183160447,
    "lat": 33.89541012007435
  },
  {
    "name": "Hayden Lake",
    "lng": -116.75586714603955,
    "lat": 47.763806752305875
  },
  {
    "name": "Hayes Center",
    "lng": -101.02041075711577,
    "lat": 40.51154338152635
  },
  {
    "name": "Hayesville",
    "lng": -92.24783351771198,
    "lat": 41.26481725066149
  },
  {
    "name": "Hayesville",
    "lng": -83.82844604542328,
    "lat": 35.02626740028282
  },
  {
    "name": "Hayesville",
    "lng": -83.82656857940343,
    "lat": 35.02625547138149
  },
  {
    "name": "Hayesville",
    "lng": -83.81825412113747,
    "lat": 35.04622373022556
  },
  {
    "name": "Hayesville",
    "lng": -83.8168119968915,
    "lat": 35.038197119533706
  },
  {
    "name": "Hayesville",
    "lng": -83.81331607714331,
    "lat": 35.03542274157821
  },
  {
    "name": "Hayesville",
    "lng": -82.25959005114316,
    "lat": 40.775196358984566
  },
  {
    "name": "Hayfield",
    "lng": -92.84712500756007,
    "lat": 43.8902782129779
  },
  {
    "name": "Haymarket",
    "lng": -77.63633743983338,
    "lat": 38.81217721641331
  },
  {
    "name": "Haynes",
    "lng": -102.47103332405888,
    "lat": 45.97386266027891
  },
  {
    "name": "Haynes",
    "lng": -90.79220382110204,
    "lat": 34.89089545576713
  },
  {
    "name": "Haynesville",
    "lng": -93.13754830812695,
    "lat": 32.96672166633934
  },
  {
    "name": "Haynesville",
    "lng": -67.97763435491203,
    "lat": 45.81085478836689
  },
  {
    "name": "Hayneville",
    "lng": -86.57871888955032,
    "lat": 32.182179651374376
  },
  {
    "name": "Hays",
    "lng": -99.35802600626661,
    "lat": 38.91090022318394
  },
  {
    "name": "Hays",
    "lng": -99.35284507187336,
    "lat": 38.90855780105016
  },
  {
    "name": "Hays",
    "lng": -99.32121086660143,
    "lat": 38.88058697487125
  },
  {
    "name": "Hays",
    "lng": -99.28593800093368,
    "lat": 38.857652494275165
  },
  {
    "name": "Hays",
    "lng": -99.2791324982016,
    "lat": 38.847153130887904
  },
  {
    "name": "Hays",
    "lng": -97.87242541526592,
    "lat": 30.121428238500688
  },
  {
    "name": "Haysi",
    "lng": -82.29307881505467,
    "lat": 37.21501690217614
  },
  {
    "name": "Haysville",
    "lng": -97.39615013526671,
    "lat": 37.542429511248514
  },
  {
    "name": "Haysville",
    "lng": -97.39191793846868,
    "lat": 37.53937767661149
  },
  {
    "name": "Haysville",
    "lng": -97.390505258542,
    "lat": 37.54979027369399
  },
  {
    "name": "Haysville",
    "lng": -97.38880318039415,
    "lat": 37.54980563836764
  },
  {
    "name": "Haysville",
    "lng": -97.38396544241168,
    "lat": 37.54244735545056
  },
  {
    "name": "Haysville",
    "lng": -97.37499249509855,
    "lat": 37.55347254931478
  },
  {
    "name": "Haysville",
    "lng": -97.36607717995022,
    "lat": 37.59567171407005
  },
  {
    "name": "Haysville",
    "lng": -97.35070297787492,
    "lat": 37.56612021787727
  },
  {
    "name": "Haysville",
    "lng": -97.34914574593463,
    "lat": 37.55490929591562
  },
  {
    "name": "Haysville",
    "lng": -97.34133928977059,
    "lat": 37.55171278907622
  },
  {
    "name": "Haysville",
    "lng": -97.31629383087551,
    "lat": 37.56710119469462
  },
  {
    "name": "Hayti",
    "lng": -97.20391048376413,
    "lat": 44.65684401361893
  },
  {
    "name": "Hayti",
    "lng": -89.74753064753936,
    "lat": 36.232283468356506
  },
  {
    "name": "Hayti Heights",
    "lng": -89.76819069113306,
    "lat": 36.23117020494302
  },
  {
    "name": "Hayward",
    "lng": -122.10150351395477,
    "lat": 37.62817731112124
  },
  {
    "name": "Hayward",
    "lng": -93.24597048373694,
    "lat": 43.649840634606385
  },
  {
    "name": "Hayward",
    "lng": -91.4826548156214,
    "lat": 46.0093563833484
  },
  {
    "name": "Haywood City",
    "lng": -89.60028319635005,
    "lat": 37.01167886650607
  },
  {
    "name": "Hazard",
    "lng": -99.0772584568652,
    "lat": 41.09153290805065
  },
  {
    "name": "Hazard",
    "lng": -83.19790536483124,
    "lat": 37.2565601570414
  },
  {
    "name": "Hazel",
    "lng": -97.38099377413887,
    "lat": 44.758223786538395
  },
  {
    "name": "Hazel",
    "lng": -88.32535197372475,
    "lat": 36.505645104272084
  },
  {
    "name": "Hazel Crest",
    "lng": -87.68991034313702,
    "lat": 41.573164133673565
  },
  {
    "name": "Hazel Crest",
    "lng": -87.68671667067724,
    "lat": 41.58544941234321
  },
  {
    "name": "Hazel Green",
    "lng": -90.43611355633402,
    "lat": 42.53402339771611
  },
  {
    "name": "Hazel Park",
    "lng": -83.09769007379967,
    "lat": 42.461927148213256
  },
  {
    "name": "Hazel Run",
    "lng": -95.71655949181897,
    "lat": 44.74845346762252
  },
  {
    "name": "Hazelton",
    "lng": -114.1365444725754,
    "lat": 42.59523132974158
  },
  {
    "name": "Hazelton",
    "lng": -100.28076840054977,
    "lat": 46.48545846359806
  },
  {
    "name": "Hazelton",
    "lng": -98.41003030371857,
    "lat": 37.094076830709966
  },
  {
    "name": "Hazelton",
    "lng": -98.40122558432114,
    "lat": 37.08936881875814
  },
  {
    "name": "Hazelwood",
    "lng": -90.39027539553369,
    "lat": 38.79365172124881
  },
  {
    "name": "Hazen",
    "lng": -101.62616421144754,
    "lat": 47.299429077970835
  },
  {
    "name": "Hazen",
    "lng": -91.57297038427822,
    "lat": 34.795909900647054
  },
  {
    "name": "Hazlehurst",
    "lng": -90.39263453153703,
    "lat": 31.864491540049922
  },
  {
    "name": "Hazlehurst",
    "lng": -82.59850290101478,
    "lat": 31.865262893791357
  },
  {
    "name": "Hazleton",
    "lng": -91.90573375735713,
    "lat": 42.61777687690819
  },
  {
    "name": "Hazleton",
    "lng": -87.5406783733411,
    "lat": 38.48921311133839
  },
  {
    "name": "Hazleton",
    "lng": -75.97241774750096,
    "lat": 40.95041698602215
  },
  {
    "name": "Head of the Harbor",
    "lng": -73.16244016237957,
    "lat": 40.8987803985321
  },
  {
    "name": "Headland",
    "lng": -85.35221799622495,
    "lat": 31.350207606298152
  },
  {
    "name": "Headrick",
    "lng": -99.13736986297161,
    "lat": 34.62683733060393
  },
  {
    "name": "Healdsburg",
    "lng": -122.90000330782439,
    "lat": 38.65420422673779
  },
  {
    "name": "Healdsburg",
    "lng": -122.88126076417842,
    "lat": 38.610257775484556
  },
  {
    "name": "Healdsburg",
    "lng": -122.88014402635652,
    "lat": 38.603685828548436
  },
  {
    "name": "Healdsburg",
    "lng": -122.86499910311744,
    "lat": 38.590573082689886
  },
  {
    "name": "Healdsburg",
    "lng": -122.86434632565084,
    "lat": 38.62243239475843
  },
  {
    "name": "Healdsburg",
    "lng": -122.86363425894871,
    "lat": 38.5829626404608
  },
  {
    "name": "Healdsburg",
    "lng": -122.85893313369087,
    "lat": 38.629758127456995
  },
  {
    "name": "Healdton",
    "lng": -97.48684224673711,
    "lat": 34.2335422770502
  },
  {
    "name": "Hearne",
    "lng": -96.595579613023,
    "lat": 30.876981470111797
  },
  {
    "name": "Heartwell",
    "lng": -98.78915313995647,
    "lat": 40.569921428581594
  },
  {
    "name": "Heath",
    "lng": -96.46852907250278,
    "lat": 32.844111254619094
  },
  {
    "name": "Heath",
    "lng": -86.46724247656635,
    "lat": 31.35783141837163
  },
  {
    "name": "Heath",
    "lng": -82.44074780496149,
    "lat": 40.02441027210051
  },
  {
    "name": "Heath",
    "lng": -72.82020001330353,
    "lat": 42.689538293859975
  },
  {
    "name": "Heath Springs",
    "lng": -80.67505236904485,
    "lat": 34.59327262601062
  },
  {
    "name": "Heavener",
    "lng": -94.60750701888333,
    "lat": 34.89177219531993
  },
  {
    "name": "Heber",
    "lng": -111.39826820637012,
    "lat": 40.50684746625601
  },
  {
    "name": "Heber Springs",
    "lng": -92.02944766455893,
    "lat": 35.49957344773848
  },
  {
    "name": "Hebron",
    "lng": -102.04440007087136,
    "lat": 46.902281189153896
  },
  {
    "name": "Hebron",
    "lng": -97.58772573482248,
    "lat": 40.16794086180758
  },
  {
    "name": "Hebron",
    "lng": -88.43433399663886,
    "lat": 42.464624734818045
  },
  {
    "name": "Hebron",
    "lng": -87.20286904750289,
    "lat": 41.325186643590484
  },
  {
    "name": "Hebron",
    "lng": -82.48967982675036,
    "lat": 39.964463491391655
  },
  {
    "name": "Hebron",
    "lng": -75.68687025627297,
    "lat": 38.42423034671553
  },
  {
    "name": "Hebron",
    "lng": -72.3901238721363,
    "lat": 41.65902437837993
  },
  {
    "name": "Hebron",
    "lng": -70.38175469383683,
    "lat": 44.210745248648294
  },
  {
    "name": "Hebron Estates",
    "lng": -85.66787153943152,
    "lat": 38.04992361909481
  },
  {
    "name": "Hecker",
    "lng": -89.99340651873317,
    "lat": 38.30541788422906
  },
  {
    "name": "Hecla",
    "lng": -98.15180381564898,
    "lat": 45.8820985978597
  },
  {
    "name": "Hector",
    "lng": -94.72255377929042,
    "lat": 44.730251757699236
  },
  {
    "name": "Hector",
    "lng": -94.71193747396973,
    "lat": 44.74138389035279
  },
  {
    "name": "Hector",
    "lng": -92.97589620575954,
    "lat": 35.464585201198034
  },
  {
    "name": "Hedgesville",
    "lng": -77.99447276459915,
    "lat": 39.55460427167938
  },
  {
    "name": "Hedley",
    "lng": -100.65905084404594,
    "lat": 34.867383762949665
  },
  {
    "name": "Hedrick",
    "lng": -92.30790451065559,
    "lat": 41.171272514752545
  },
  {
    "name": "Hedwig Village",
    "lng": -95.51951465369213,
    "lat": 29.779796145761658
  },
  {
    "name": "Heeia",
    "lng": -157.81447085235885,
    "lat": 21.42476484927168
  },
  {
    "name": "Heflin",
    "lng": -93.26824290385203,
    "lat": 32.456514033815935
  },
  {
    "name": "Heflin",
    "lng": -85.57023046421553,
    "lat": 33.64720864410476
  },
  {
    "name": "Heflin",
    "lng": -85.49679104720272,
    "lat": 33.64516325419795
  },
  {
    "name": "Heidelberg",
    "lng": -88.98781132439976,
    "lat": 31.889735489357296
  },
  {
    "name": "Helen",
    "lng": -83.72027735684976,
    "lat": 34.70431847992515
  },
  {
    "name": "Helena",
    "lng": -98.27211152321793,
    "lat": 36.54644052135232
  },
  {
    "name": "Helena",
    "lng": -98.26820823256548,
    "lat": 36.542260534533675
  },
  {
    "name": "Helena",
    "lng": -86.93525186670786,
    "lat": 33.293323123565386
  },
  {
    "name": "Helena",
    "lng": -86.93525186670777,
    "lat": 33.29332312356537
  },
  {
    "name": "Helena",
    "lng": -86.90096891667808,
    "lat": 33.298231312293666
  },
  {
    "name": "Helena",
    "lng": -86.86585838593713,
    "lat": 33.28459544544065
  },
  {
    "name": "Helena",
    "lng": -86.8656588718261,
    "lat": 33.28463650722729
  },
  {
    "name": "Helena",
    "lng": -83.29198359287881,
    "lat": 41.34036187160382
  },
  {
    "name": "Helena",
    "lng": -82.91069691797435,
    "lat": 32.078641175399085
  },
  {
    "name": "Helena-West Helena",
    "lng": -90.61999065827284,
    "lat": 34.53130751973392
  },
  {
    "name": "Helotes",
    "lng": -98.69648883103226,
    "lat": 29.569597409175042
  },
  {
    "name": "Helper",
    "lng": -110.85963071628792,
    "lat": 39.689708523091845
  },
  {
    "name": "Hemby Bridge",
    "lng": -80.62748418618787,
    "lat": 35.10613500865921
  },
  {
    "name": "Hemet",
    "lng": -117.03687599476558,
    "lat": 33.77694941141797
  },
  {
    "name": "Hemet",
    "lng": -116.99437898937009,
    "lat": 33.735130394218615
  },
  {
    "name": "Hemingford",
    "lng": -103.07546380708051,
    "lat": 42.321131596078665
  },
  {
    "name": "Hemingway",
    "lng": -79.44628497533141,
    "lat": 33.75343217842925
  },
  {
    "name": "Hemlock",
    "lng": -82.15447391865774,
    "lat": 39.59046135715717
  },
  {
    "name": "Hemphill",
    "lng": -93.8511029065003,
    "lat": 31.343119800653863
  },
  {
    "name": "Hempstead",
    "lng": -96.07816367935666,
    "lat": 30.100161072791845
  },
  {
    "name": "Hempstead",
    "lng": -73.61916884907072,
    "lat": 40.70432031113854
  },
  {
    "name": "Henderson",
    "lng": -115.03870468327229,
    "lat": 36.012504539484475
  },
  {
    "name": "Henderson",
    "lng": -97.81126197196413,
    "lat": 40.779469119187105
  },
  {
    "name": "Henderson",
    "lng": -95.43006902287553,
    "lat": 41.139941210603745
  },
  {
    "name": "Henderson",
    "lng": -94.7963848596222,
    "lat": 32.157275078033415
  },
  {
    "name": "Henderson",
    "lng": -93.9090696213233,
    "lat": 44.527952349041044
  },
  {
    "name": "Henderson",
    "lng": -91.79963498307866,
    "lat": 30.316321915687396
  },
  {
    "name": "Henderson",
    "lng": -90.35358476622622,
    "lat": 41.02388411900404
  },
  {
    "name": "Henderson",
    "lng": -88.6529723746831,
    "lat": 35.44441476696669
  },
  {
    "name": "Henderson",
    "lng": -87.57940386863375,
    "lat": 37.84035581328311
  },
  {
    "name": "Henderson",
    "lng": -82.13605147414737,
    "lat": 38.83050536429715
  },
  {
    "name": "Henderson",
    "lng": -78.45516056474358,
    "lat": 36.307173337061606
  },
  {
    "name": "Henderson",
    "lng": -78.41601322645639,
    "lat": 36.32564288707529
  },
  {
    "name": "Henderson",
    "lng": -78.37834537342019,
    "lat": 36.35494906412847
  },
  {
    "name": "Henderson",
    "lng": -78.3731666422811,
    "lat": 36.318082984079545
  },
  {
    "name": "Henderson",
    "lng": -75.76637703441422,
    "lat": 39.07474875790029
  },
  {
    "name": "Hendersonville",
    "lng": -86.59979802323075,
    "lat": 36.30533383991272
  },
  {
    "name": "Hendersonville",
    "lng": -82.50131956870592,
    "lat": 35.34351556371636
  },
  {
    "name": "Hendersonville",
    "lng": -82.47131352098135,
    "lat": 35.303140837666156
  },
  {
    "name": "Hendersonville",
    "lng": -82.46213630022153,
    "lat": 35.342484594984704
  },
  {
    "name": "Hendersonville",
    "lng": -82.45728362797612,
    "lat": 35.32324290860452
  },
  {
    "name": "Hendersonville",
    "lng": -82.43050031123448,
    "lat": 35.342367846329296
  },
  {
    "name": "Hendersonville",
    "lng": -82.43018157506094,
    "lat": 35.29338147918963
  },
  {
    "name": "Hendersonville",
    "lng": -82.42996906088952,
    "lat": 35.28974423272448
  },
  {
    "name": "Hendersonville",
    "lng": -82.42947615848203,
    "lat": 35.291199907053425
  },
  {
    "name": "Hendersonville",
    "lng": -82.42797684711591,
    "lat": 35.292721130810044
  },
  {
    "name": "Hendersonville",
    "lng": -82.42528988697806,
    "lat": 35.343374722923656
  },
  {
    "name": "Hendersonville",
    "lng": -82.41034476243236,
    "lat": 35.29653282120243
  },
  {
    "name": "Hendersonville",
    "lng": -82.40877375850665,
    "lat": 35.29786486072013
  },
  {
    "name": "Hendersonville",
    "lng": -82.40869200466342,
    "lat": 35.29670400195073
  },
  {
    "name": "Hendersonville",
    "lng": -82.40522133129079,
    "lat": 35.2967312190691
  },
  {
    "name": "Hendersonville",
    "lng": -82.4049732019763,
    "lat": 35.29823338541556
  },
  {
    "name": "Hendley",
    "lng": -99.97119165478404,
    "lat": 40.13131590365869
  },
  {
    "name": "Hendricks",
    "lng": -96.42831494763057,
    "lat": 44.50755387374336
  },
  {
    "name": "Hendricks",
    "lng": -79.62989798543859,
    "lat": 39.07542962395179
  },
  {
    "name": "Hendrix",
    "lng": -96.40704580410495,
    "lat": 33.77525741509008
  },
  {
    "name": "Hendrum",
    "lng": -96.81069987678227,
    "lat": 47.26413611775682
  },
  {
    "name": "Henefer",
    "lng": -111.4932409698359,
    "lat": 41.01377727734043
  },
  {
    "name": "Hennepin",
    "lng": -89.3216241905577,
    "lat": 41.25890702883862
  },
  {
    "name": "Hennessey",
    "lng": -97.89878436470993,
    "lat": 36.105751278802785
  },
  {
    "name": "Hennessey",
    "lng": -97.89678113965321,
    "lat": 36.081131456629635
  },
  {
    "name": "Henniker",
    "lng": -71.82232117721531,
    "lat": 43.173621665603
  },
  {
    "name": "Henning",
    "lng": -95.44233272792684,
    "lat": 46.32357336386766
  },
  {
    "name": "Henning",
    "lng": -89.57989096924663,
    "lat": 35.676890051003426
  },
  {
    "name": "Henning",
    "lng": -87.70095621701633,
    "lat": 40.30685613771946
  },
  {
    "name": "Henrietta",
    "lng": -98.19331257887302,
    "lat": 33.8149035980602
  },
  {
    "name": "Henrietta",
    "lng": -98.17199913040116,
    "lat": 33.8064342739998
  },
  {
    "name": "Henrietta",
    "lng": -93.93853175377247,
    "lat": 39.23710879258925
  },
  {
    "name": "Henriette",
    "lng": -93.1196439764497,
    "lat": 45.87128605924812
  },
  {
    "name": "Henrieville",
    "lng": -112.00187387168639,
    "lat": 37.56389300960367
  },
  {
    "name": "Henry",
    "lng": -104.04609179498999,
    "lat": 41.99796258398674
  },
  {
    "name": "Henry",
    "lng": -97.46155688023364,
    "lat": 44.880856888925514
  },
  {
    "name": "Henry",
    "lng": -89.36101719820651,
    "lat": 41.113626997331586
  },
  {
    "name": "Henry",
    "lng": -89.34244979134787,
    "lat": 41.129690580056106
  },
  {
    "name": "Henry",
    "lng": -88.4096616367536,
    "lat": 36.20019656259645
  },
  {
    "name": "Henryetta",
    "lng": -96.01749833416838,
    "lat": 35.40548275062669
  },
  {
    "name": "Henryetta",
    "lng": -95.98540388436692,
    "lat": 35.44354860013097
  },
  {
    "name": "Henryetta",
    "lng": -95.96796549457721,
    "lat": 35.40936282647251
  },
  {
    "name": "Hepburn",
    "lng": -95.01665469299378,
    "lat": 40.84759510424743
  },
  {
    "name": "Hephzibah",
    "lng": -82.09945677339097,
    "lat": 33.2907194205776
  },
  {
    "name": "Hepler",
    "lng": -94.96963959384605,
    "lat": 37.66202595413739
  },
  {
    "name": "Herculaneum",
    "lng": -90.39343057583186,
    "lat": 38.257317155178015
  },
  {
    "name": "Hereford",
    "lng": -102.40034702658693,
    "lat": 34.822657914292826
  },
  {
    "name": "Hereford",
    "lng": -102.36362910845438,
    "lat": 34.82976575873514
  },
  {
    "name": "Herington",
    "lng": -96.94499324015827,
    "lat": 38.673691655755206
  },
  {
    "name": "Herington",
    "lng": -96.92925850658014,
    "lat": 38.68323969105977
  },
  {
    "name": "Heritage Creek",
    "lng": -85.72860278766886,
    "lat": 38.12397461324365
  },
  {
    "name": "Heritage Creek",
    "lng": -85.61265055409407,
    "lat": 38.095240745429464
  },
  {
    "name": "Herkimer",
    "lng": -74.9918493631994,
    "lat": 43.028256214870815
  },
  {
    "name": "Herman",
    "lng": -96.21671853770711,
    "lat": 41.673825277346495
  },
  {
    "name": "Herman",
    "lng": -96.14136152373713,
    "lat": 45.810534193859205
  },
  {
    "name": "Hermann",
    "lng": -91.43417315711675,
    "lat": 38.69829219846533
  },
  {
    "name": "Hermantown",
    "lng": -92.24068874990243,
    "lat": 46.80581269119927
  },
  {
    "name": "Hermitage",
    "lng": -93.32620868536375,
    "lat": 37.944421479592286
  },
  {
    "name": "Hermitage",
    "lng": -92.17145309539245,
    "lat": 33.447430382782684
  },
  {
    "name": "Hermon",
    "lng": -75.23116949711539,
    "lat": 44.46701885246074
  },
  {
    "name": "Hermon",
    "lng": -68.90867505202574,
    "lat": 44.81414597943859
  },
  {
    "name": "Hermosa",
    "lng": -103.19849518239616,
    "lat": 43.83295900032673
  },
  {
    "name": "Hermosa",
    "lng": -103.192637660397,
    "lat": 43.83950679704737
  },
  {
    "name": "Hermosa Beach",
    "lng": -118.41868016621939,
    "lat": 33.86035510390371
  },
  {
    "name": "Hernando",
    "lng": -89.99205509851775,
    "lat": 34.84961641015876
  },
  {
    "name": "Herndon",
    "lng": -100.78607809203503,
    "lat": 39.90896113444174
  },
  {
    "name": "Herndon",
    "lng": -77.3867058827836,
    "lat": 38.96995329641071
  },
  {
    "name": "Heron Lake",
    "lng": -95.32391787375954,
    "lat": 43.79680634773399
  },
  {
    "name": "Heron Lake",
    "lng": -95.28984745195203,
    "lat": 43.807201198610514
  },
  {
    "name": "Herreid",
    "lng": -100.07517182156839,
    "lat": 45.83750080905607
  },
  {
    "name": "Herrick",
    "lng": -99.1882138369533,
    "lat": 43.115017000807164
  },
  {
    "name": "Herrick",
    "lng": -88.98478951508527,
    "lat": 39.21961588510416
  },
  {
    "name": "Herriman",
    "lng": -112.01711492437893,
    "lat": 40.489946450964375
  },
  {
    "name": "Herrin",
    "lng": -89.05943319748978,
    "lat": 37.8069301457149
  },
  {
    "name": "Herrin",
    "lng": -89.03015392149241,
    "lat": 37.79851171629938
  },
  {
    "name": "Herrings",
    "lng": -75.65791435001637,
    "lat": 44.02312734398178
  },
  {
    "name": "Herscher",
    "lng": -88.10054756541088,
    "lat": 41.04925243808438
  },
  {
    "name": "Hersey",
    "lng": -85.44204295816924,
    "lat": 43.85124181243851
  },
  {
    "name": "Hersey",
    "lng": -68.36888153841511,
    "lat": 46.080168375076525
  },
  {
    "name": "Hershey",
    "lng": -101.00088811590457,
    "lat": 41.16037776309251
  },
  {
    "name": "Hershey",
    "lng": -100.9845592019537,
    "lat": 41.15681695841452
  },
  {
    "name": "Hertford",
    "lng": -76.47356736749953,
    "lat": 36.18491497665101
  },
  {
    "name": "Hertford",
    "lng": -76.45274898533263,
    "lat": 36.171819387738324
  },
  {
    "name": "Hesperia",
    "lng": -117.31486794153749,
    "lat": 34.3971970016725
  },
  {
    "name": "Hesperia",
    "lng": -86.04068736931453,
    "lat": 43.569015651255434
  },
  {
    "name": "Hessmer",
    "lng": -92.12118659219502,
    "lat": 31.05403174881106
  },
  {
    "name": "Hesston",
    "lng": -97.42176238653431,
    "lat": 38.140202044191454
  },
  {
    "name": "Hetland",
    "lng": -97.23467033611242,
    "lat": 44.37722958122255
  },
  {
    "name": "Hettick",
    "lng": -90.0374066563693,
    "lat": 39.35573648313036
  },
  {
    "name": "Hettinger",
    "lng": -102.63482700879928,
    "lat": 46.003405115881414
  },
  {
    "name": "Heuvelton",
    "lng": -75.40462009798479,
    "lat": 44.61692136912
  },
  {
    "name": "Hewitt",
    "lng": -97.19624018560157,
    "lat": 31.45120465141393
  },
  {
    "name": "Hewitt",
    "lng": -95.0904138903934,
    "lat": 46.32495816243878
  },
  {
    "name": "Hewitt",
    "lng": -90.10497710187136,
    "lat": 44.64235851624625
  },
  {
    "name": "Hewlett Bay Park",
    "lng": -73.69518324075031,
    "lat": 40.6344123405386
  },
  {
    "name": "Hewlett Harbor",
    "lng": -73.6841842485909,
    "lat": 40.632708836594034
  },
  {
    "name": "Hewlett Neck",
    "lng": -73.69810437470701,
    "lat": 40.6245484679164
  },
  {
    "name": "Heyburn",
    "lng": -113.76223041342665,
    "lat": 42.55995165225233
  },
  {
    "name": "Heyworth",
    "lng": -88.99292239732713,
    "lat": 40.313749991250454
  },
  {
    "name": "Hiawassee",
    "lng": -83.75287596250162,
    "lat": 34.9501438026021
  },
  {
    "name": "Hiawatha",
    "lng": -95.53866155118781,
    "lat": 39.83689318275179
  },
  {
    "name": "Hiawatha",
    "lng": -95.53634788577578,
    "lat": 39.852532514015415
  },
  {
    "name": "Hiawatha",
    "lng": -95.52560198316631,
    "lat": 39.8385255432923
  },
  {
    "name": "Hiawatha",
    "lng": -91.69064883740354,
    "lat": 42.054059510387894
  },
  {
    "name": "Hibbing",
    "lng": -92.94860085433831,
    "lat": 47.398140139594645
  },
  {
    "name": "Hickman",
    "lng": -96.62798214016244,
    "lat": 40.62523782857655
  },
  {
    "name": "Hickman",
    "lng": -89.18297086449125,
    "lat": 36.5649456599602
  },
  {
    "name": "Hickory",
    "lng": -96.85786880952752,
    "lat": 34.557105545085946
  },
  {
    "name": "Hickory",
    "lng": -89.02163420736588,
    "lat": 32.316776366169336
  },
  {
    "name": "Hickory",
    "lng": -81.38365584388283,
    "lat": 35.76103156172677
  },
  {
    "name": "Hickory",
    "lng": -81.38127640960903,
    "lat": 35.774762277371956
  },
  {
    "name": "Hickory",
    "lng": -81.3799763098435,
    "lat": 35.680680925639415
  },
  {
    "name": "Hickory",
    "lng": -81.37771603596697,
    "lat": 35.75940414161895
  },
  {
    "name": "Hickory",
    "lng": -81.37638330661142,
    "lat": 35.685241200682846
  },
  {
    "name": "Hickory",
    "lng": -81.37458272765389,
    "lat": 35.68238860018453
  },
  {
    "name": "Hickory",
    "lng": -81.37316442476347,
    "lat": 35.68568489603086
  },
  {
    "name": "Hickory",
    "lng": -81.3709698807569,
    "lat": 35.686614866139394
  },
  {
    "name": "Hickory",
    "lng": -81.3644020647844,
    "lat": 35.68796072473993
  },
  {
    "name": "Hickory",
    "lng": -81.36372457897922,
    "lat": 35.68817706277306
  },
  {
    "name": "Hickory",
    "lng": -81.36188820888943,
    "lat": 35.69280885428748
  },
  {
    "name": "Hickory",
    "lng": -81.3541546889511,
    "lat": 35.69233230449079
  },
  {
    "name": "Hickory",
    "lng": -81.35219694614301,
    "lat": 35.68369104630806
  },
  {
    "name": "Hickory",
    "lng": -81.34869197671483,
    "lat": 35.686501161676084
  },
  {
    "name": "Hickory",
    "lng": -81.34399136383888,
    "lat": 35.77030148950738
  },
  {
    "name": "Hickory",
    "lng": -81.33677046163939,
    "lat": 35.70127696759633
  },
  {
    "name": "Hickory",
    "lng": -81.32961746009259,
    "lat": 35.67104385707617
  },
  {
    "name": "Hickory",
    "lng": -81.32506354261945,
    "lat": 35.739845484533824
  },
  {
    "name": "Hickory",
    "lng": -81.31911886757014,
    "lat": 35.67472079677543
  },
  {
    "name": "Hickory",
    "lng": -81.30704311425166,
    "lat": 35.80009956622003
  },
  {
    "name": "Hickory",
    "lng": -81.30543443147495,
    "lat": 35.798620399101196
  },
  {
    "name": "Hickory",
    "lng": -81.30036131199269,
    "lat": 35.67666719206989
  },
  {
    "name": "Hickory",
    "lng": -81.29585515779891,
    "lat": 35.77482981090977
  },
  {
    "name": "Hickory",
    "lng": -81.29147460127304,
    "lat": 35.75539323754006
  },
  {
    "name": "Hickory",
    "lng": -81.29137783651011,
    "lat": 35.76001874617262
  },
  {
    "name": "Hickory",
    "lng": -81.28971686619997,
    "lat": 35.75655124197318
  },
  {
    "name": "Hickory",
    "lng": -81.2889878709067,
    "lat": 35.729019568289274
  },
  {
    "name": "Hickory",
    "lng": -81.2885843555513,
    "lat": 35.76164309103529
  },
  {
    "name": "Hickory",
    "lng": -81.28754595684889,
    "lat": 35.66629191797348
  },
  {
    "name": "Hickory",
    "lng": -81.28722123471195,
    "lat": 35.772178866267204
  },
  {
    "name": "Hickory",
    "lng": -81.28713928441542,
    "lat": 35.664299462358365
  },
  {
    "name": "Hickory",
    "lng": -81.2869671055025,
    "lat": 35.66519476154773
  },
  {
    "name": "Hickory",
    "lng": -81.286800261767,
    "lat": 35.66606500143725
  },
  {
    "name": "Hickory",
    "lng": -81.28666316008767,
    "lat": 35.771534480801016
  },
  {
    "name": "Hickory",
    "lng": -81.28567404819633,
    "lat": 35.66446674137729
  },
  {
    "name": "Hickory",
    "lng": -81.28558049676428,
    "lat": 35.772128055466595
  },
  {
    "name": "Hickory",
    "lng": -81.28550005265447,
    "lat": 35.79122144916842
  },
  {
    "name": "Hickory",
    "lng": -81.28545187214978,
    "lat": 35.780602252089885
  },
  {
    "name": "Hickory",
    "lng": -81.28500372988057,
    "lat": 35.76439092762372
  },
  {
    "name": "Hickory",
    "lng": -81.28482815971061,
    "lat": 35.716500000994834
  },
  {
    "name": "Hickory",
    "lng": -81.28375235588724,
    "lat": 35.68483996950271
  },
  {
    "name": "Hickory",
    "lng": -81.28156126020072,
    "lat": 35.71414814468806
  },
  {
    "name": "Hickory",
    "lng": -81.28144349020089,
    "lat": 35.737277345513554
  },
  {
    "name": "Hickory",
    "lng": -81.2810311069009,
    "lat": 35.75416048177819
  },
  {
    "name": "Hickory",
    "lng": -81.28032825046549,
    "lat": 35.75325435478771
  },
  {
    "name": "Hickory",
    "lng": -81.27890870328538,
    "lat": 35.74637756495553
  },
  {
    "name": "Hickory",
    "lng": -81.27817484379692,
    "lat": 35.75405012249058
  },
  {
    "name": "Hickory",
    "lng": -81.27609880058917,
    "lat": 35.75752806792009
  },
  {
    "name": "Hickory",
    "lng": -81.27493820917309,
    "lat": 35.786885413428585
  },
  {
    "name": "Hickory",
    "lng": -81.27477656048931,
    "lat": 35.75829467372679
  },
  {
    "name": "Hickory",
    "lng": -81.2734653505549,
    "lat": 35.778167363783346
  },
  {
    "name": "Hickory",
    "lng": -81.27257887572583,
    "lat": 35.75735289507716
  },
  {
    "name": "Hickory",
    "lng": -81.26995630803967,
    "lat": 35.76359856268025
  },
  {
    "name": "Hickory",
    "lng": -81.26733748270082,
    "lat": 35.72231568709991
  },
  {
    "name": "Hickory",
    "lng": -81.26729745722763,
    "lat": 35.76874729568125
  },
  {
    "name": "Hickory",
    "lng": -81.26478464189256,
    "lat": 35.729686116940464
  },
  {
    "name": "Hickory",
    "lng": -81.25829586501129,
    "lat": 35.75269717871026
  },
  {
    "name": "Hickory",
    "lng": -81.24898742891764,
    "lat": 35.731887295743796
  },
  {
    "name": "Hickory",
    "lng": -81.24803162477986,
    "lat": 35.79296215756296
  },
  {
    "name": "Hickory",
    "lng": -81.24334775759039,
    "lat": 35.80516491697046
  },
  {
    "name": "Hickory Creek",
    "lng": -97.02977847448481,
    "lat": 33.110119521806254
  },
  {
    "name": "Hickory Flat",
    "lng": -89.19140579128683,
    "lat": 34.61591988679098
  },
  {
    "name": "Hickory Grove",
    "lng": -81.41586474683105,
    "lat": 34.98092985626536
  },
  {
    "name": "Hickory Hill",
    "lng": -85.56760349481497,
    "lat": 38.287774296236066
  },
  {
    "name": "Hickory Hills",
    "lng": -87.82808346353191,
    "lat": 41.72477242952947
  },
  {
    "name": "Hickory Ridge",
    "lng": -90.99445686530596,
    "lat": 35.40280414953013
  },
  {
    "name": "Hickory Valley",
    "lng": -89.12599034721396,
    "lat": 35.15482937825648
  },
  {
    "name": "Hicksville",
    "lng": -84.76425157063176,
    "lat": 41.29580203702156
  },
  {
    "name": "Hico",
    "lng": -98.02901565135085,
    "lat": 31.985749876768196
  },
  {
    "name": "Hidalgo",
    "lng": -98.24789061105217,
    "lat": 26.107995861097184
  },
  {
    "name": "Hidalgo",
    "lng": -88.14909616218894,
    "lat": 39.15591697839638
  },
  {
    "name": "Hidden Hills",
    "lng": -118.66121976867669,
    "lat": 34.16375966632202
  },
  {
    "name": "Hidden Springs",
    "lng": -116.25085037448198,
    "lat": 43.720174879662856
  },
  {
    "name": "Hideaway",
    "lng": -95.45738791136614,
    "lat": 32.48948362620184
  },
  {
    "name": "Hideout",
    "lng": -111.40138520058292,
    "lat": 40.64374827489252
  },
  {
    "name": "Higbee",
    "lng": -92.51282961881788,
    "lat": 39.30602078447758
  },
  {
    "name": "Higden",
    "lng": -92.20449712303723,
    "lat": 35.566774989422335
  },
  {
    "name": "Higgins",
    "lng": -100.02743127189225,
    "lat": 36.12098754758483
  },
  {
    "name": "Higginson",
    "lng": -91.71521644374639,
    "lat": 35.196812228447
  },
  {
    "name": "Higginson",
    "lng": -91.71497965470977,
    "lat": 35.1967865212344
  },
  {
    "name": "Higginsport",
    "lng": -83.96771054785405,
    "lat": 38.78992476997686
  },
  {
    "name": "Higginsville",
    "lng": -93.72691836396952,
    "lat": 39.06383168738187
  },
  {
    "name": "Higgston",
    "lng": -82.46691461437126,
    "lat": 32.21629831963875
  },
  {
    "name": "High Hill",
    "lng": -91.3776942622263,
    "lat": 38.8753423618618
  },
  {
    "name": "High Point",
    "lng": -80.066080364335,
    "lat": 35.968509587096776
  },
  {
    "name": "High Point",
    "lng": -80.06452498199931,
    "lat": 35.93561607721791
  },
  {
    "name": "High Point",
    "lng": -80.05772186403813,
    "lat": 35.95778078452282
  },
  {
    "name": "High Point",
    "lng": -80.0558675588753,
    "lat": 36.01127069228155
  },
  {
    "name": "High Point",
    "lng": -80.05424095775206,
    "lat": 35.929044996333836
  },
  {
    "name": "High Point",
    "lng": -80.05343344754773,
    "lat": 36.01974983357363
  },
  {
    "name": "High Point",
    "lng": -80.04886496267135,
    "lat": 36.03395897637909
  },
  {
    "name": "High Point",
    "lng": -80.04740786108142,
    "lat": 36.01480399494449
  },
  {
    "name": "High Point",
    "lng": -80.0464746241168,
    "lat": 35.98217699660063
  },
  {
    "name": "High Point",
    "lng": -80.03717911311715,
    "lat": 35.91587339022105
  },
  {
    "name": "High Point",
    "lng": -80.02965862804741,
    "lat": 35.91817890284719
  },
  {
    "name": "High Point",
    "lng": -80.02032732595892,
    "lat": 36.076725494433646
  },
  {
    "name": "High Point",
    "lng": -80.0142501519918,
    "lat": 36.08429777831917
  },
  {
    "name": "High Point",
    "lng": -80.01422600610405,
    "lat": 36.04988526016275
  },
  {
    "name": "High Point",
    "lng": -80.01141216806899,
    "lat": 36.09198255940118
  },
  {
    "name": "High Point",
    "lng": -80.01107338580178,
    "lat": 36.08713548125309
  },
  {
    "name": "High Point",
    "lng": -80.0090341927406,
    "lat": 36.05882849085265
  },
  {
    "name": "High Point",
    "lng": -80.00152416224294,
    "lat": 36.08009158224558
  },
  {
    "name": "High Point",
    "lng": -79.99715773537383,
    "lat": 36.08660491746852
  },
  {
    "name": "High Point",
    "lng": -79.99257022245813,
    "lat": 35.98739490680243
  },
  {
    "name": "High Point",
    "lng": -79.9878714896512,
    "lat": 36.082785108048256
  },
  {
    "name": "High Point",
    "lng": -79.98729338124903,
    "lat": 36.084692517243035
  },
  {
    "name": "High Point",
    "lng": -79.97701468171898,
    "lat": 36.07606026842696
  },
  {
    "name": "High Point",
    "lng": -79.96683045643492,
    "lat": 36.06574364050609
  },
  {
    "name": "High Point",
    "lng": -79.9587917468652,
    "lat": 36.007472301818666
  },
  {
    "name": "High Point",
    "lng": -79.94548203079208,
    "lat": 35.960780086637996
  },
  {
    "name": "High Point",
    "lng": -79.93993483348834,
    "lat": 35.96023460681507
  },
  {
    "name": "High Point",
    "lng": -79.93928812021294,
    "lat": 35.96245463349466
  },
  {
    "name": "High Point",
    "lng": -79.92653328262232,
    "lat": 35.9687471699419
  },
  {
    "name": "High Point",
    "lng": -79.91931504867216,
    "lat": 35.96951723541757
  },
  {
    "name": "High Ridge",
    "lng": -90.5342975349449,
    "lat": 38.46116119846785
  },
  {
    "name": "High Shoals",
    "lng": -81.20078569293877,
    "lat": 35.39690984311148
  },
  {
    "name": "High Springs",
    "lng": -82.59604300977318,
    "lat": 29.808510597316477
  },
  {
    "name": "Highfill",
    "lng": -94.36340787210709,
    "lat": 36.283908623554034
  },
  {
    "name": "Highfill",
    "lng": -94.32305991068014,
    "lat": 36.27663978164898
  },
  {
    "name": "Highfill",
    "lng": -94.27924963697394,
    "lat": 36.255590518180796
  },
  {
    "name": "Highfill",
    "lng": -94.2740040737782,
    "lat": 36.25214101455436
  },
  {
    "name": "Highland",
    "lng": -117.16753647574149,
    "lat": 34.11126570287101
  },
  {
    "name": "Highland",
    "lng": -111.7956595063582,
    "lat": 40.42755921279808
  },
  {
    "name": "Highland",
    "lng": -95.26506380452028,
    "lat": 39.86038368865516
  },
  {
    "name": "Highland",
    "lng": -91.5187323498554,
    "lat": 36.26556813322653
  },
  {
    "name": "Highland",
    "lng": -90.3801771529949,
    "lat": 43.047387414902104
  },
  {
    "name": "Highland",
    "lng": -89.68108865441808,
    "lat": 38.76000635323298
  },
  {
    "name": "Highland",
    "lng": -87.45876373913184,
    "lat": 41.54841225254595
  },
  {
    "name": "Highland",
    "lng": -83.60007881320281,
    "lat": 39.34394579434886
  },
  {
    "name": "Highland Beach",
    "lng": -80.06408879335355,
    "lat": 26.411346211870455
  },
  {
    "name": "Highland Beach",
    "lng": -76.46620367585213,
    "lat": 38.93100409387246
  },
  {
    "name": "Highland City",
    "lng": -81.87976226670246,
    "lat": 27.959784740798565
  },
  {
    "name": "Highland Falls",
    "lng": -73.96827665908069,
    "lat": 41.36440739609526
  },
  {
    "name": "Highland Haven",
    "lng": -98.39508006971003,
    "lat": 30.607278163695533
  },
  {
    "name": "Highland Heights",
    "lng": -84.45741528987337,
    "lat": 39.03509570799222
  },
  {
    "name": "Highland Heights",
    "lng": -81.46923812732177,
    "lat": 41.55186316283137
  },
  {
    "name": "Highland Hills",
    "lng": -81.5188907440039,
    "lat": 41.45067962447995
  },
  {
    "name": "Highland Lake",
    "lng": -86.42444197841849,
    "lat": 33.88756437684649
  },
  {
    "name": "Highland Park",
    "lng": -96.8012571942003,
    "lat": 32.831042214814076
  },
  {
    "name": "Highland Park",
    "lng": -87.81037372969493,
    "lat": 42.18228531175855
  },
  {
    "name": "Highland Park",
    "lng": -83.09765075323591,
    "lat": 42.405235944581506
  },
  {
    "name": "Highland Park",
    "lng": -77.57068937171616,
    "lat": 40.621629429533286
  },
  {
    "name": "Highland Village",
    "lng": -97.06138546220974,
    "lat": 33.08966100195259
  },
  {
    "name": "Highlands",
    "lng": -83.1974148737161,
    "lat": 35.05395620598507
  },
  {
    "name": "Highlands-Baywood Park",
    "lng": -122.34478602682677,
    "lat": 37.52277701690251
  },
  {
    "name": "Highlandville",
    "lng": -93.28300055269385,
    "lat": 36.94050554970315
  },
  {
    "name": "Highmore",
    "lng": -99.43933543199596,
    "lat": 44.52137245294239
  },
  {
    "name": "Highwood",
    "lng": -87.81296135158654,
    "lat": 42.20601302943646
  },
  {
    "name": "Hilbert",
    "lng": -88.16029925587043,
    "lat": 44.13964592691236
  },
  {
    "name": "Hilda",
    "lng": -81.2466100258455,
    "lat": 33.2739793036749
  },
  {
    "name": "Hildale",
    "lng": -112.97859368200272,
    "lat": 37.01708856728131
  },
  {
    "name": "Hildebran",
    "lng": -81.42107388847064,
    "lat": 35.718210689104914
  },
  {
    "name": "Hildreth",
    "lng": -99.04575092294078,
    "lat": 40.337831808223484
  },
  {
    "name": "Hill",
    "lng": -71.76531035412131,
    "lat": 43.524977354607955
  },
  {
    "name": "Hill City",
    "lng": -103.57048080288868,
    "lat": 43.93193124041681
  },
  {
    "name": "Hill City",
    "lng": -99.84629789673698,
    "lat": 39.36658232241653
  },
  {
    "name": "Hill City",
    "lng": -99.84344048152401,
    "lat": 39.37698789328516
  },
  {
    "name": "Hill City",
    "lng": -93.59785342200713,
    "lat": 46.99192380548784
  },
  {
    "name": "Hill City",
    "lng": -93.59623518170166,
    "lat": 46.973053686491156
  },
  {
    "name": "Hill Country Village",
    "lng": -98.48955718852301,
    "lat": 29.583252633751233
  },
  {
    "name": "Hillburn",
    "lng": -74.17053266964857,
    "lat": 41.126381494970666
  },
  {
    "name": "Hillcrest",
    "lng": -95.22332800602189,
    "lat": 29.392352814810398
  },
  {
    "name": "Hillcrest",
    "lng": -89.06537635345383,
    "lat": 41.96835769910643
  },
  {
    "name": "Hilliard",
    "lng": -83.15457857834434,
    "lat": 40.034740965599525
  },
  {
    "name": "Hilliard",
    "lng": -81.92102569612982,
    "lat": 30.68687042175302
  },
  {
    "name": "Hilliard",
    "lng": -81.90335929106334,
    "lat": 30.683237804759074
  },
  {
    "name": "Hillman",
    "lng": -93.88868784826403,
    "lat": 46.006325446332845
  },
  {
    "name": "Hillman",
    "lng": -83.89753955899893,
    "lat": 45.064442034608724
  },
  {
    "name": "Hillrose",
    "lng": -103.52240211768594,
    "lat": 40.324273454570935
  },
  {
    "name": "Hills",
    "lng": -96.35921629566235,
    "lat": 43.52763564161649
  },
  {
    "name": "Hills",
    "lng": -91.54304900030917,
    "lat": 41.5483843508374
  },
  {
    "name": "Hills",
    "lng": -91.53534113456253,
    "lat": 41.55807544438482
  },
  {
    "name": "Hills and Dales",
    "lng": -85.62466081959273,
    "lat": 38.300307943853824
  },
  {
    "name": "Hills and Dales",
    "lng": -81.44397337333527,
    "lat": 40.82890164400963
  },
  {
    "name": "Hillsboro",
    "lng": -97.20095640616802,
    "lat": 38.35210346217749
  },
  {
    "name": "Hillsboro",
    "lng": -97.1150611796409,
    "lat": 32.0091767042496
  },
  {
    "name": "Hillsboro",
    "lng": -97.07566934366992,
    "lat": 47.40642530225636
  },
  {
    "name": "Hillsboro",
    "lng": -97.06304772555288,
    "lat": 47.402973779375436
  },
  {
    "name": "Hillsboro",
    "lng": -91.71171804513165,
    "lat": 40.837008130381555
  },
  {
    "name": "Hillsboro",
    "lng": -90.56543049796672,
    "lat": 38.23629252581499
  },
  {
    "name": "Hillsboro",
    "lng": -90.33750630379573,
    "lat": 43.654055963034274
  },
  {
    "name": "Hillsboro",
    "lng": -89.47648066744185,
    "lat": 39.14466403502336
  },
  {
    "name": "Hillsboro",
    "lng": -89.46910449409944,
    "lat": 39.200484218989196
  },
  {
    "name": "Hillsboro",
    "lng": -87.18375771967428,
    "lat": 34.6406756519799
  },
  {
    "name": "Hillsboro",
    "lng": -87.15760712542729,
    "lat": 40.10882489683799
  },
  {
    "name": "Hillsboro",
    "lng": -83.61127427316099,
    "lat": 39.21246011464432
  },
  {
    "name": "Hillsboro",
    "lng": -80.21354357628431,
    "lat": 38.13529285564932
  },
  {
    "name": "Hillsboro",
    "lng": -77.723743086121,
    "lat": 39.19880200630675
  },
  {
    "name": "Hillsboro",
    "lng": -75.94182783714756,
    "lat": 38.917035296001565
  },
  {
    "name": "Hillsborough",
    "lng": -122.35872637366569,
    "lat": 37.55723076279899
  },
  {
    "name": "Hillsborough",
    "lng": -79.12056824611449,
    "lat": 36.09610303454307
  },
  {
    "name": "Hillsborough",
    "lng": -79.11928946494726,
    "lat": 36.05742075848583
  },
  {
    "name": "Hillsborough",
    "lng": -79.11421684892811,
    "lat": 36.098939379718395
  },
  {
    "name": "Hillsborough",
    "lng": -79.11358561791454,
    "lat": 36.09944675150686
  },
  {
    "name": "Hillsborough",
    "lng": -79.11285956600383,
    "lat": 36.10002165540157
  },
  {
    "name": "Hillsborough",
    "lng": -79.11103919008933,
    "lat": 36.10141894728217
  },
  {
    "name": "Hillsborough",
    "lng": -79.11091857453009,
    "lat": 36.09958568944151
  },
  {
    "name": "Hillsborough",
    "lng": -79.11028557558308,
    "lat": 36.06233694288634
  },
  {
    "name": "Hillsborough",
    "lng": -79.10956585990681,
    "lat": 36.09970088414586
  },
  {
    "name": "Hillsborough",
    "lng": -79.107524418631,
    "lat": 36.04962832865235
  },
  {
    "name": "Hillsborough",
    "lng": -79.10101397616891,
    "lat": 36.1064892809809
  },
  {
    "name": "Hillsborough",
    "lng": -79.10071945326106,
    "lat": 36.07354968830478
  },
  {
    "name": "Hillsborough",
    "lng": -79.09858022378233,
    "lat": 36.039812310567626
  },
  {
    "name": "Hillsborough",
    "lng": -79.09556789894174,
    "lat": 36.11905444103267
  },
  {
    "name": "Hillsborough",
    "lng": -79.09182918476851,
    "lat": 36.04689471532196
  },
  {
    "name": "Hillsborough",
    "lng": -79.08931978348087,
    "lat": 36.08259286628149
  },
  {
    "name": "Hillsborough",
    "lng": -79.08422018529211,
    "lat": 36.05613745944669
  },
  {
    "name": "Hillsborough",
    "lng": -71.94660092707136,
    "lat": 43.148853384067
  },
  {
    "name": "Hillsdale",
    "lng": -97.99267268428845,
    "lat": 36.56344399431711
  },
  {
    "name": "Hillsdale",
    "lng": -90.28689512735625,
    "lat": 38.68569742608842
  },
  {
    "name": "Hillsdale",
    "lng": -90.17485637550072,
    "lat": 41.61059099142087
  },
  {
    "name": "Hillsdale",
    "lng": -84.63586808412435,
    "lat": 41.927878342450875
  },
  {
    "name": "Hillside",
    "lng": -87.9018048523041,
    "lat": 41.86756015420525
  },
  {
    "name": "Hillsview",
    "lng": -99.56065731878522,
    "lat": 45.66418351812589
  },
  {
    "name": "Hillsville",
    "lng": -80.73712256675842,
    "lat": 36.761314411452766
  },
  {
    "name": "Hilltop",
    "lng": -93.25012437304893,
    "lat": 45.053565342067195
  },
  {
    "name": "Hillview",
    "lng": -90.53857345385119,
    "lat": 39.44955575077064
  },
  {
    "name": "Hillview",
    "lng": -85.69412457026671,
    "lat": 38.07994954286251
  },
  {
    "name": "Hillview",
    "lng": -85.69108408848035,
    "lat": 38.079001830581326
  },
  {
    "name": "Hillview",
    "lng": -85.68406426232306,
    "lat": 38.053522241272375
  },
  {
    "name": "Hillview",
    "lng": -85.68296321033667,
    "lat": 38.05049339016703
  },
  {
    "name": "Hilo CDP",
    "lng": -155.08401551916074,
    "lat": 19.69234867405336
  },
  {
    "name": "Hilshire Village",
    "lng": -95.48852674198363,
    "lat": 29.79077673088292
  },
  {
    "name": "Hilton",
    "lng": -77.79259481102916,
    "lat": 43.28994250237507
  },
  {
    "name": "Hilton Head Island",
    "lng": -80.74093098719923,
    "lat": 32.19802298917554
  },
  {
    "name": "Hiltonia",
    "lng": -81.66127722587493,
    "lat": 32.883395658722264
  },
  {
    "name": "Hinckley",
    "lng": -112.67364653877014,
    "lat": 39.33361149066139
  },
  {
    "name": "Hinckley",
    "lng": -92.92589084488772,
    "lat": 46.01088104237844
  },
  {
    "name": "Hinckley",
    "lng": -92.90850425559861,
    "lat": 46.02432251231517
  },
  {
    "name": "Hinckley",
    "lng": -88.63972623471263,
    "lat": 41.771417491751336
  },
  {
    "name": "Hindman",
    "lng": -82.98345040289507,
    "lat": 37.33476408749822
  },
  {
    "name": "Hindsboro",
    "lng": -88.13979915482088,
    "lat": 39.680473501423485
  },
  {
    "name": "Hindsboro",
    "lng": -88.13465870898601,
    "lat": 39.68483550651645
  },
  {
    "name": "Hindsville",
    "lng": -93.86272518423617,
    "lat": 36.14584940768047
  },
  {
    "name": "Hinesville",
    "lng": -81.66076285313873,
    "lat": 31.852342422770633
  },
  {
    "name": "Hinesville",
    "lng": -81.6544287028993,
    "lat": 31.833251964005733
  },
  {
    "name": "Hinesville",
    "lng": -81.6279723815009,
    "lat": 31.82333770006639
  },
  {
    "name": "Hinesville",
    "lng": -81.61037154224753,
    "lat": 31.82348027701494
  },
  {
    "name": "Hingham",
    "lng": -110.42131331125938,
    "lat": 48.5554353731613
  },
  {
    "name": "Hinsdale",
    "lng": -87.92743971032884,
    "lat": 41.80054954910924
  },
  {
    "name": "Hinsdale",
    "lng": -73.1133749426424,
    "lat": 42.43484264398305
  },
  {
    "name": "Hinton",
    "lng": -98.35841747378035,
    "lat": 35.481285824008005
  },
  {
    "name": "Hinton",
    "lng": -96.29753726287674,
    "lat": 42.623316193457114
  },
  {
    "name": "Hinton",
    "lng": -80.88282534712535,
    "lat": 37.665186843966
  },
  {
    "name": "Hiram",
    "lng": -84.77077710830969,
    "lat": 33.87735542280177
  },
  {
    "name": "Hiram",
    "lng": -81.14327813256706,
    "lat": 41.31019252159365
  },
  {
    "name": "Hiram",
    "lng": -70.82578450903661,
    "lat": 43.8638391413147
  },
  {
    "name": "Hitchcock",
    "lng": -98.40817523473635,
    "lat": 44.629380941071936
  },
  {
    "name": "Hitchcock",
    "lng": -98.34969551018816,
    "lat": 35.96765805688823
  },
  {
    "name": "Hitchcock",
    "lng": -95.01852571808615,
    "lat": 29.28244834965734
  },
  {
    "name": "Hitchita",
    "lng": -95.75180503052067,
    "lat": 35.51980687010059
  },
  {
    "name": "Hitterdal",
    "lng": -96.25836242783828,
    "lat": 46.97783259813144
  },
  {
    "name": "Hixton",
    "lng": -91.01513407322285,
    "lat": 44.38316574088437
  },
  {
    "name": "Hobart",
    "lng": -99.08769708143964,
    "lat": 35.02469538986832
  },
  {
    "name": "Hobart",
    "lng": -88.1601930266374,
    "lat": 44.49662173913438
  },
  {
    "name": "Hobart",
    "lng": -87.27272949762389,
    "lat": 41.513879272217046
  },
  {
    "name": "Hobart",
    "lng": -74.66902146367235,
    "lat": 42.37152524984118
  },
  {
    "name": "Hobart Village",
    "lng": -121.94654299543112,
    "lat": 47.35067955573504
  },
  {
    "name": "Hobbs",
    "lng": -103.16162967317202,
    "lat": 32.730621341225756
  },
  {
    "name": "Hoberg",
    "lng": -93.84925697871692,
    "lat": 37.06819750538681
  },
  {
    "name": "Hobgood",
    "lng": -77.39399410711572,
    "lat": 36.026740897224364
  },
  {
    "name": "Hoboken",
    "lng": -82.13217052230853,
    "lat": 31.181168544881466
  },
  {
    "name": "Hoboken",
    "lng": -74.02795675474044,
    "lat": 40.74521044529645
  },
  {
    "name": "Hobson",
    "lng": -109.88286363794431,
    "lat": 47.0007202761804
  },
  {
    "name": "Hobson",
    "lng": -109.87297617305319,
    "lat": 46.999719113968055
  },
  {
    "name": "Hobson City",
    "lng": -85.87709115506944,
    "lat": 33.60192345289757
  },
  {
    "name": "Hobson City",
    "lng": -85.85113640552773,
    "lat": 33.61811373400482
  },
  {
    "name": "Hodgdon",
    "lng": -67.84462627988326,
    "lat": 46.04801406346068
  },
  {
    "name": "Hodge",
    "lng": -92.73039146141603,
    "lat": 32.271932109003544
  },
  {
    "name": "Hodgenville",
    "lng": -85.73582046784121,
    "lat": 37.56713079878132
  },
  {
    "name": "Hodges",
    "lng": -87.92852881360342,
    "lat": 34.32792101440377
  },
  {
    "name": "Hodges",
    "lng": -82.24792361286167,
    "lat": 34.28762298095819
  },
  {
    "name": "Hodgkins",
    "lng": -87.8601348708756,
    "lat": 41.76690917978982
  },
  {
    "name": "Hoffman",
    "lng": -95.84488247791045,
    "lat": 35.48885627865413
  },
  {
    "name": "Hoffman",
    "lng": -95.78820122351381,
    "lat": 45.83274737540666
  },
  {
    "name": "Hoffman",
    "lng": -89.26434220117109,
    "lat": 38.54048443449796
  },
  {
    "name": "Hoffman",
    "lng": -79.54974068051767,
    "lat": 35.03134497660398
  },
  {
    "name": "Hoffman Estates",
    "lng": -88.24097814172671,
    "lat": 42.05630718467189
  },
  {
    "name": "Hoffman Estates",
    "lng": -88.21698163775879,
    "lat": 42.063596320854806
  },
  {
    "name": "Hoffman Estates",
    "lng": -88.1557821422922,
    "lat": 42.05536886740908
  },
  {
    "name": "Hoffman Estates",
    "lng": -88.1128153543513,
    "lat": 42.087206208902245
  },
  {
    "name": "Hogansville",
    "lng": -84.90285339217155,
    "lat": 33.16749516856555
  },
  {
    "name": "Hohenwald",
    "lng": -87.55486113245348,
    "lat": 35.55222931299634
  },
  {
    "name": "Hoisington",
    "lng": -98.77812083141103,
    "lat": 38.51854174429151
  },
  {
    "name": "Hoisington",
    "lng": -98.77306697689465,
    "lat": 38.509273801873896
  },
  {
    "name": "Hokah",
    "lng": -91.34900862498625,
    "lat": 43.75964770563381
  },
  {
    "name": "Hokes Bluff",
    "lng": -85.86412236936174,
    "lat": 33.9906214983917
  },
  {
    "name": "Hokes Bluff",
    "lng": -85.85927789860186,
    "lat": 33.96415127624601
  },
  {
    "name": "Holbrook",
    "lng": -110.16700608747975,
    "lat": 34.90406642428604
  },
  {
    "name": "Holbrook",
    "lng": -100.00999456175359,
    "lat": 40.30473782870474
  },
  {
    "name": "Holcomb",
    "lng": -100.99334095947751,
    "lat": 37.98591355474281
  },
  {
    "name": "Holcomb",
    "lng": -90.0248357470223,
    "lat": 36.40153076252585
  },
  {
    "name": "Holden",
    "lng": -112.2704022667837,
    "lat": 39.10008819387582
  },
  {
    "name": "Holden",
    "lng": -93.9894858481727,
    "lat": 38.71364497155501
  },
  {
    "name": "Holden",
    "lng": -71.86076637373407,
    "lat": 42.35616380877291
  },
  {
    "name": "Holden",
    "lng": -68.66519116592629,
    "lat": 44.75707436409161
  },
  {
    "name": "Holdenville",
    "lng": -96.40062024390562,
    "lat": 35.08365508255142
  },
  {
    "name": "Holdingford",
    "lng": -94.47159695142163,
    "lat": 45.72980281729594
  },
  {
    "name": "Holdrege",
    "lng": -99.37547443065723,
    "lat": 40.43923172310939
  },
  {
    "name": "Holdrege",
    "lng": -99.35997120730674,
    "lat": 40.453244710560114
  },
  {
    "name": "Holgate",
    "lng": -84.12929062271404,
    "lat": 41.24934807675537
  },
  {
    "name": "Holiday City",
    "lng": -84.53888263787532,
    "lat": 41.61778297857546
  },
  {
    "name": "Holiday Hills",
    "lng": -88.23656684534885,
    "lat": 42.30237537395712
  },
  {
    "name": "Holiday Hills",
    "lng": -88.22799103210536,
    "lat": 42.29669506354533
  },
  {
    "name": "Holiday Lakes",
    "lng": -95.51468833790184,
    "lat": 29.208259244400125
  },
  {
    "name": "Holladay",
    "lng": -111.82261133612008,
    "lat": 40.65999186705091
  },
  {
    "name": "Holland",
    "lng": -97.4032726529027,
    "lat": 30.88383166977454
  },
  {
    "name": "Holland",
    "lng": -96.19234213310108,
    "lat": 44.08980531364494
  },
  {
    "name": "Holland",
    "lng": -92.79899309863484,
    "lat": 42.40016012457124
  },
  {
    "name": "Holland",
    "lng": -92.2770565295828,
    "lat": 35.166026550442595
  },
  {
    "name": "Holland",
    "lng": -89.87053709850136,
    "lat": 36.05704320374591
  },
  {
    "name": "Holland",
    "lng": -87.03804464870501,
    "lat": 38.246118275541015
  },
  {
    "name": "Holland",
    "lng": -86.09852907353515,
    "lat": 42.767687972021314
  },
  {
    "name": "Holland",
    "lng": -83.70766155242858,
    "lat": 41.61939304272169
  },
  {
    "name": "Holland",
    "lng": -72.165941347981,
    "lat": 42.06017581055768
  },
  {
    "name": "Holland Patent",
    "lng": -75.25647695689713,
    "lat": 43.241509283226904
  },
  {
    "name": "Hollandale",
    "lng": -93.20443396367983,
    "lat": 43.75987714187773
  },
  {
    "name": "Hollandale",
    "lng": -93.20439211302049,
    "lat": 43.759836996256816
  },
  {
    "name": "Hollandale",
    "lng": -90.85288975331774,
    "lat": 33.17610855935041
  },
  {
    "name": "Hollandale",
    "lng": -89.93421056134898,
    "lat": 42.875621016243734
  },
  {
    "name": "Hollansburg",
    "lng": -84.79300786639335,
    "lat": 39.99849260610821
  },
  {
    "name": "Hollenberg",
    "lng": -96.99184756445507,
    "lat": 39.980924907163185
  },
  {
    "name": "Holley",
    "lng": -78.02919368452304,
    "lat": 43.224657162331155
  },
  {
    "name": "Holliday",
    "lng": -98.68986488889445,
    "lat": 33.81402997548133
  },
  {
    "name": "Holliday",
    "lng": -92.13125244941965,
    "lat": 39.49358816695327
  },
  {
    "name": "Hollidaysburg",
    "lng": -78.39298373114394,
    "lat": 40.43115547778137
  },
  {
    "name": "Hollis",
    "lng": -99.93338827628035,
    "lat": 34.68358358205151
  },
  {
    "name": "Hollis",
    "lng": -99.9163584586733,
    "lat": 34.686709219924225
  },
  {
    "name": "Hollis",
    "lng": -99.9107576288751,
    "lat": 34.6951093285331
  },
  {
    "name": "Hollis",
    "lng": -99.90976575351684,
    "lat": 34.703363710917046
  },
  {
    "name": "Hollis",
    "lng": -71.58341439476426,
    "lat": 42.74939673858915
  },
  {
    "name": "Hollis",
    "lng": -70.6245379894958,
    "lat": 43.62956085707732
  },
  {
    "name": "Hollister",
    "lng": -121.44486504328435,
    "lat": 36.85381037496473
  },
  {
    "name": "Hollister",
    "lng": -121.39736635706754,
    "lat": 36.856038957524696
  },
  {
    "name": "Hollister",
    "lng": -121.38679745401208,
    "lat": 36.81999202503103
  },
  {
    "name": "Hollister",
    "lng": -114.58384654687634,
    "lat": 42.35291129956965
  },
  {
    "name": "Hollister",
    "lng": -98.87052570651923,
    "lat": 34.341115455237635
  },
  {
    "name": "Hollister",
    "lng": -93.22067447178291,
    "lat": 36.61646247981818
  },
  {
    "name": "Holliston",
    "lng": -71.4449815800171,
    "lat": 42.1977525410107
  },
  {
    "name": "Hollow Creek",
    "lng": -85.62472094339843,
    "lat": 38.15245725488761
  },
  {
    "name": "Hollow Rock",
    "lng": -88.27379389587573,
    "lat": 36.03522883101575
  },
  {
    "name": "Holloway",
    "lng": -95.92209392724688,
    "lat": 45.25433149975465
  },
  {
    "name": "Holloway",
    "lng": -95.90844039496211,
    "lat": 45.24407076673451
  },
  {
    "name": "Holloway",
    "lng": -81.1312767998111,
    "lat": 40.162127180949305
  },
  {
    "name": "Hollowayville",
    "lng": -89.29631664817664,
    "lat": 41.36488835498632
  },
  {
    "name": "Hollowayville",
    "lng": -89.2879972419497,
    "lat": 41.36461541346158
  },
  {
    "name": "Holly",
    "lng": -102.12484340599039,
    "lat": 38.05548727778984
  },
  {
    "name": "Holly",
    "lng": -83.62329455180148,
    "lat": 42.7987701733159
  },
  {
    "name": "Holly Grove",
    "lng": -91.19611559933958,
    "lat": 34.600323015628526
  },
  {
    "name": "Holly Hill",
    "lng": -80.41297138033264,
    "lat": 33.32446319781946
  },
  {
    "name": "Holly Pond",
    "lng": -86.61250193400733,
    "lat": 34.16989655394088
  },
  {
    "name": "Holly Ridge",
    "lng": -77.55717753473817,
    "lat": 34.48991390749379
  },
  {
    "name": "Holly Ridge",
    "lng": -77.52691604873027,
    "lat": 34.48870599804457
  },
  {
    "name": "Holly Ridge",
    "lng": -77.5179927393335,
    "lat": 34.48215181663188
  },
  {
    "name": "Holly Ridge",
    "lng": -77.50032053957632,
    "lat": 34.527066812691444
  },
  {
    "name": "Holly Ridge",
    "lng": -77.49313596215782,
    "lat": 34.49038849427691
  },
  {
    "name": "Holly Springs",
    "lng": -89.44654542687552,
    "lat": 34.77678481670784
  },
  {
    "name": "Holly Springs",
    "lng": -84.50910845850218,
    "lat": 34.145256467160735
  },
  {
    "name": "Holly Springs",
    "lng": -84.48596505965898,
    "lat": 34.168060883486866
  },
  {
    "name": "Holly Springs",
    "lng": -84.42465578671934,
    "lat": 34.16746050507899
  },
  {
    "name": "Holly Springs",
    "lng": -78.90154951709795,
    "lat": 35.672987262093145
  },
  {
    "name": "Holly Springs",
    "lng": -78.89547974514282,
    "lat": 35.62355997759885
  },
  {
    "name": "Holly Springs",
    "lng": -78.89504358363399,
    "lat": 35.6268002160019
  },
  {
    "name": "Holly Springs",
    "lng": -78.88845495712516,
    "lat": 35.60994625249492
  },
  {
    "name": "Holly Springs",
    "lng": -78.88486155683331,
    "lat": 35.603044599476846
  },
  {
    "name": "Holly Springs",
    "lng": -78.88167302868864,
    "lat": 35.62550600084394
  },
  {
    "name": "Holly Springs",
    "lng": -78.88115010202694,
    "lat": 35.649304469291074
  },
  {
    "name": "Holly Springs",
    "lng": -78.8703001547517,
    "lat": 35.63002179933999
  },
  {
    "name": "Holly Springs",
    "lng": -78.86501558540313,
    "lat": 35.681311671431956
  },
  {
    "name": "Holly Springs",
    "lng": -78.83787877627262,
    "lat": 35.6360739100382
  },
  {
    "name": "Holly Springs",
    "lng": -78.83212790452585,
    "lat": 35.65852369245759
  },
  {
    "name": "Holly Springs",
    "lng": -78.79550171832021,
    "lat": 35.6800281217063
  },
  {
    "name": "Holly Springs",
    "lng": -78.79115496837329,
    "lat": 35.66439233840855
  },
  {
    "name": "Hollyvilla",
    "lng": -85.74682864548653,
    "lat": 38.09261796965373
  },
  {
    "name": "Hollywood",
    "lng": -80.21197096768303,
    "lat": 32.750104998019516
  },
  {
    "name": "Hollywood Park",
    "lng": -98.48411626983922,
    "lat": 29.599345736129287
  },
  {
    "name": "Hollywood Seminole Reservation",
    "lng": -80.2123130735253,
    "lat": 26.04254290284084
  },
  {
    "name": "Holmen",
    "lng": -91.28784935512437,
    "lat": 44.01791959941591
  },
  {
    "name": "Holmen",
    "lng": -91.26527680650652,
    "lat": 43.967383826581646
  },
  {
    "name": "Holmen",
    "lng": -91.25994403065683,
    "lat": 43.950016223476155
  },
  {
    "name": "Holmen",
    "lng": -91.2349801676697,
    "lat": 43.92871787661354
  },
  {
    "name": "Holmes Beach",
    "lng": -82.715045481655,
    "lat": 27.50990247764853
  },
  {
    "name": "Holmesville",
    "lng": -81.92335774320152,
    "lat": 40.628960405913155
  },
  {
    "name": "Holstein",
    "lng": -98.65081255940406,
    "lat": 40.464751743685234
  },
  {
    "name": "Holstein",
    "lng": -95.54295022868324,
    "lat": 42.48790285278176
  },
  {
    "name": "Holstein",
    "lng": -95.54145902043521,
    "lat": 42.47343316259603
  },
  {
    "name": "Holt",
    "lng": -96.19446832131831,
    "lat": 48.29295070411602
  },
  {
    "name": "Holt",
    "lng": -94.33758613783189,
    "lat": 39.45556281139648
  },
  {
    "name": "Holton",
    "lng": -95.75187242439681,
    "lat": 39.48043781688366
  },
  {
    "name": "Holton",
    "lng": -95.73973433946065,
    "lat": 39.464416567882424
  },
  {
    "name": "Holton",
    "lng": -95.69025662717893,
    "lat": 39.490555760914255
  },
  {
    "name": "Holton",
    "lng": -85.38464408777368,
    "lat": 39.075221549614795
  },
  {
    "name": "Holts Summit",
    "lng": -92.11481501013543,
    "lat": 38.64502885602155
  },
  {
    "name": "Holts Summit",
    "lng": -92.11324742165924,
    "lat": 38.66341401047694
  },
  {
    "name": "Holtville",
    "lng": -115.3780673564449,
    "lat": 32.81293782782434
  },
  {
    "name": "Holualoa CDP",
    "lng": -155.9313953711603,
    "lat": 19.623005876727024
  },
  {
    "name": "Holy Cross",
    "lng": -159.82416254654086,
    "lat": 62.18713754948062
  },
  {
    "name": "Holy Cross",
    "lng": -90.99620647952835,
    "lat": 42.60109109493367
  },
  {
    "name": "Holyoke",
    "lng": -102.29896590919324,
    "lat": 40.58202403445738
  },
  {
    "name": "Holyoke",
    "lng": -72.64110413055802,
    "lat": 42.21254178332358
  },
  {
    "name": "Holyrood",
    "lng": -98.4126576143268,
    "lat": 38.58718442156721
  },
  {
    "name": "Homecroft",
    "lng": -86.13123929160263,
    "lat": 39.669572094685535
  },
  {
    "name": "Homedale",
    "lng": -116.93641859529868,
    "lat": 43.617848885970915
  },
  {
    "name": "Homeland",
    "lng": -82.02139920578313,
    "lat": 30.860332272527753
  },
  {
    "name": "Homer",
    "lng": -151.51448833859672,
    "lat": 59.64250315729495
  },
  {
    "name": "Homer",
    "lng": -96.49114341755468,
    "lat": 42.32247721218505
  },
  {
    "name": "Homer",
    "lng": -93.05811334424646,
    "lat": 32.792923754101835
  },
  {
    "name": "Homer",
    "lng": -87.95775417017346,
    "lat": 40.03298498285096
  },
  {
    "name": "Homer",
    "lng": -84.81033398976777,
    "lat": 42.14613553031354
  },
  {
    "name": "Homer",
    "lng": -83.4987611366164,
    "lat": 34.334783053769634
  },
  {
    "name": "Homer",
    "lng": -76.18491218668798,
    "lat": 42.63711096448572
  },
  {
    "name": "Homer Glen",
    "lng": -88.00418637692063,
    "lat": 41.62926657168522
  },
  {
    "name": "Homer Glen",
    "lng": -87.94901018090985,
    "lat": 41.60427786385835
  },
  {
    "name": "Homerville",
    "lng": -82.74153924832325,
    "lat": 31.03855708755314
  },
  {
    "name": "Homestead",
    "lng": -94.20052741261192,
    "lat": 39.36323474411665
  },
  {
    "name": "Homestead",
    "lng": -80.4472063442234,
    "lat": 25.466434575298564
  },
  {
    "name": "Homestown",
    "lng": -89.82423268823446,
    "lat": 36.331826333130095
  },
  {
    "name": "Hometown",
    "lng": -87.73112731396536,
    "lat": 41.73123657609476
  },
  {
    "name": "Hometown",
    "lng": -75.98655538881049,
    "lat": 40.82159874626436
  },
  {
    "name": "Homewood",
    "lng": -87.66099180200503,
    "lat": 41.55913544788644
  },
  {
    "name": "Homewood",
    "lng": -86.80921231139467,
    "lat": 33.46175271881667
  },
  {
    "name": "Homewood",
    "lng": -80.32944033760913,
    "lat": 40.81333745248318
  },
  {
    "name": "Hominy",
    "lng": -96.39295194924823,
    "lat": 36.422152191981596
  },
  {
    "name": "Homosassa Springs",
    "lng": -82.53911869577436,
    "lat": 28.811834387586824
  },
  {
    "name": "Honaker",
    "lng": -81.96857871676158,
    "lat": 37.01558236118266
  },
  {
    "name": "Honalo CDP",
    "lng": -155.90201325238687,
    "lat": 19.578711347778604
  },
  {
    "name": "Honaunau-Napoopoo CDP",
    "lng": -155.86309123308718,
    "lat": 19.453490249204844
  },
  {
    "name": "Hondo",
    "lng": -99.16199173125749,
    "lat": 29.353176262538906
  },
  {
    "name": "Honea Path",
    "lng": -82.39513233217421,
    "lat": 34.44763665559446
  },
  {
    "name": "Honea Path",
    "lng": -82.37069310334546,
    "lat": 34.442002080459474
  },
  {
    "name": "Honeoye Falls",
    "lng": -77.59027164694974,
    "lat": 42.955727518552486
  },
  {
    "name": "Honesdale",
    "lng": -75.25276715264589,
    "lat": 41.5776881923787
  },
  {
    "name": "Honey Grove",
    "lng": -95.90899618788352,
    "lat": 33.586664576926545
  },
  {
    "name": "Honeyville",
    "lng": -112.08574293910152,
    "lat": 41.636131210974014
  },
  {
    "name": "Honomu CDP",
    "lng": -155.11219600325407,
    "lat": 19.86955253571496
  },
  {
    "name": "Honor",
    "lng": -86.0198265800713,
    "lat": 44.666396980299346
  },
  {
    "name": "Hooker",
    "lng": -101.21615696709402,
    "lat": 36.86128737051885
  },
  {
    "name": "Hookerton",
    "lng": -77.58927044660265,
    "lat": 35.4230649327591
  },
  {
    "name": "Hooks",
    "lng": -94.28485147336433,
    "lat": 33.47101461934589
  },
  {
    "name": "Hooksett",
    "lng": -71.43648118010414,
    "lat": 43.07095999226149
  },
  {
    "name": "Hoonah",
    "lng": -135.42925833295385,
    "lat": 58.11128300952904
  },
  {
    "name": "Hooper",
    "lng": -112.28602815863808,
    "lat": 41.16036635579193
  },
  {
    "name": "Hooper",
    "lng": -105.87742486147238,
    "lat": 37.7459558019869
  },
  {
    "name": "Hooper",
    "lng": -96.54792500932443,
    "lat": 41.6118233281614
  },
  {
    "name": "Hooper Bay",
    "lng": -166.1114982147241,
    "lat": 61.52763865731295
  },
  {
    "name": "Hoopeston",
    "lng": -87.66354999623356,
    "lat": 40.460824018286615
  },
  {
    "name": "Hoople",
    "lng": -97.63771116881571,
    "lat": 48.53500294915508
  },
  {
    "name": "Hooppole",
    "lng": -89.91396817167154,
    "lat": 41.521848905996904
  },
  {
    "name": "Hoosick Falls",
    "lng": -73.34963948951615,
    "lat": 42.90035037565644
  },
  {
    "name": "Hoot Owl",
    "lng": -95.1214355035416,
    "lat": 36.359997719450384
  },
  {
    "name": "Hoover",
    "lng": -86.91570627884704,
    "lat": 33.29508728663914
  },
  {
    "name": "Hoover",
    "lng": -86.90179581212726,
    "lat": 33.31289909919408
  },
  {
    "name": "Hoover",
    "lng": -86.88612661099268,
    "lat": 33.34861032478535
  },
  {
    "name": "Hoover",
    "lng": -86.84509394006065,
    "lat": 33.362731573853225
  },
  {
    "name": "Hoover",
    "lng": -86.81795444370108,
    "lat": 33.43532154636092
  },
  {
    "name": "Hoover",
    "lng": -86.81606441835656,
    "lat": 33.42248768156194
  },
  {
    "name": "Hoover",
    "lng": -86.81540904625743,
    "lat": 33.422606286722704
  },
  {
    "name": "Hoover",
    "lng": -86.81043490236377,
    "lat": 33.421786712067934
  },
  {
    "name": "Hoover",
    "lng": -86.78985341532672,
    "lat": 33.40617041733458
  },
  {
    "name": "Hoover",
    "lng": -86.782417,
    "lat": 33.38639533303446
  },
  {
    "name": "Hoover",
    "lng": -86.77766830429688,
    "lat": 33.40525502160063
  },
  {
    "name": "Hoover",
    "lng": -86.77764546966257,
    "lat": 33.392258731206894
  },
  {
    "name": "Hoover",
    "lng": -86.77476513725009,
    "lat": 33.34937978815645
  },
  {
    "name": "Hoover",
    "lng": -86.73595721866766,
    "lat": 33.39923410334481
  },
  {
    "name": "Hoover",
    "lng": -86.66221314349156,
    "lat": 33.40901695337118
  },
  {
    "name": "Hoover",
    "lng": -86.63896660468941,
    "lat": 33.43482651566538
  },
  {
    "name": "Hope",
    "lng": -116.30431420385841,
    "lat": 48.24933294335732
  },
  {
    "name": "Hope",
    "lng": -104.73543621831364,
    "lat": 32.8157177218238
  },
  {
    "name": "Hope",
    "lng": -97.71927445708462,
    "lat": 47.32437555784809
  },
  {
    "name": "Hope",
    "lng": -97.07583233598265,
    "lat": 38.691189852414766
  },
  {
    "name": "Hope",
    "lng": -93.58943020602548,
    "lat": 33.66823770454327
  },
  {
    "name": "Hope",
    "lng": -93.58377237272333,
    "lat": 33.688265241869736
  },
  {
    "name": "Hope",
    "lng": -85.76595894030365,
    "lat": 39.29923928063798
  },
  {
    "name": "Hope",
    "lng": -69.1896395026957,
    "lat": 44.25107066415408
  },
  {
    "name": "Hope Mills",
    "lng": -78.9877503562819,
    "lat": 34.97720030861841
  },
  {
    "name": "Hope Mills",
    "lng": -78.98514942690176,
    "lat": 34.977402375455746
  },
  {
    "name": "Hope Mills",
    "lng": -78.98059401634399,
    "lat": 34.98467465442432
  },
  {
    "name": "Hope Mills",
    "lng": -78.97986300721746,
    "lat": 35.00335407590521
  },
  {
    "name": "Hope Mills",
    "lng": -78.97771838809741,
    "lat": 35.00303655112863
  },
  {
    "name": "Hope Mills",
    "lng": -78.95681502372084,
    "lat": 34.9732838426204
  },
  {
    "name": "Hope Mills",
    "lng": -78.95364145691894,
    "lat": 34.98350964491894
  },
  {
    "name": "Hope Mills",
    "lng": -78.9403113963912,
    "lat": 34.9552536280514
  },
  {
    "name": "Hope Mills",
    "lng": -78.92872579121777,
    "lat": 34.93890661986367
  },
  {
    "name": "Hope Mills",
    "lng": -78.92672220889416,
    "lat": 34.943780364335566
  },
  {
    "name": "Hope Mills",
    "lng": -78.92622946318959,
    "lat": 34.985433102785194
  },
  {
    "name": "Hope Mills",
    "lng": -78.92500059873407,
    "lat": 34.93342257119027
  },
  {
    "name": "Hope Mills",
    "lng": -78.92493091436431,
    "lat": 34.99098958647233
  },
  {
    "name": "Hope Mills",
    "lng": -78.92214956406775,
    "lat": 34.99271528651633
  },
  {
    "name": "Hope Mills",
    "lng": -78.92210651514976,
    "lat": 34.943967220867954
  },
  {
    "name": "Hope Mills",
    "lng": -78.9163304256983,
    "lat": 34.93500692874642
  },
  {
    "name": "Hope Mills",
    "lng": -78.91400795682415,
    "lat": 34.944785700870845
  },
  {
    "name": "Hope Mills",
    "lng": -78.9092891780825,
    "lat": 34.94242486331986
  },
  {
    "name": "Hope Mills",
    "lng": -78.90504593481991,
    "lat": 34.9399045304472
  },
  {
    "name": "Hopedale",
    "lng": -89.42037402154446,
    "lat": 40.423781455013255
  },
  {
    "name": "Hopedale",
    "lng": -80.8954134228833,
    "lat": 40.32646364309466
  },
  {
    "name": "Hopedale",
    "lng": -71.53525565302732,
    "lat": 42.124762015526066
  },
  {
    "name": "Hopewell",
    "lng": -89.45711448885669,
    "lat": 40.98423275145603
  },
  {
    "name": "Hopewell",
    "lng": -77.29605489879599,
    "lat": 37.292595358247084
  },
  {
    "name": "Hopkins",
    "lng": -94.81702061765634,
    "lat": 40.551120564859886
  },
  {
    "name": "Hopkins",
    "lng": -93.40566202284512,
    "lat": 44.92598333147091
  },
  {
    "name": "Hopkins",
    "lng": -85.76331548176016,
    "lat": 42.625885880221674
  },
  {
    "name": "Hopkins Park",
    "lng": -87.61397637893516,
    "lat": 41.07085678546396
  },
  {
    "name": "Hopkins Park",
    "lng": -87.60590150887596,
    "lat": 41.08647076017707
  },
  {
    "name": "Hopkins Park",
    "lng": -87.6017250081223,
    "lat": 41.0885674431824
  },
  {
    "name": "Hopkins Park",
    "lng": -87.57959426834421,
    "lat": 41.07670694696222
  },
  {
    "name": "Hopkins Park",
    "lng": -87.56963009701842,
    "lat": 41.05340290930904
  },
  {
    "name": "Hopkinsville",
    "lng": -87.47779111156362,
    "lat": 36.838820399698875
  },
  {
    "name": "Hopkinton",
    "lng": -91.24904617427794,
    "lat": 42.342816001432226
  },
  {
    "name": "Hopkinton",
    "lng": -71.7536806308774,
    "lat": 41.47812877478161
  },
  {
    "name": "Hopkinton",
    "lng": -71.69691219571236,
    "lat": 43.19793107883505
  },
  {
    "name": "Hopkinton",
    "lng": -71.53781179813345,
    "lat": 42.22550998894977
  },
  {
    "name": "Hopland",
    "lng": -123.11833829300537,
    "lat": 38.96879825571861
  },
  {
    "name": "Hoquiam",
    "lng": -123.90276552265156,
    "lat": 46.97727192643005
  },
  {
    "name": "Horace",
    "lng": -101.79041691432379,
    "lat": 38.47680992400859
  },
  {
    "name": "Horace",
    "lng": -96.90545158086412,
    "lat": 46.757961892358296
  },
  {
    "name": "Hordville",
    "lng": -97.89035371964393,
    "lat": 41.07936164757758
  },
  {
    "name": "Horicon",
    "lng": -88.63791764450555,
    "lat": 43.444775461460424
  },
  {
    "name": "Horizon City",
    "lng": -106.19091555067139,
    "lat": 31.679865184511936
  },
  {
    "name": "Horn Hill",
    "lng": -86.32109550630896,
    "lat": 31.238251756683166
  },
  {
    "name": "Horn Lake",
    "lng": -90.05013072360447,
    "lat": 34.951205422532354
  },
  {
    "name": "Hornbeak",
    "lng": -89.29608059030106,
    "lat": 36.33620334088053
  },
  {
    "name": "Hornbeck",
    "lng": -93.39586613012362,
    "lat": 31.326630490750826
  },
  {
    "name": "Hornell",
    "lng": -77.6687081566005,
    "lat": 42.346779038489196
  },
  {
    "name": "Hornell",
    "lng": -77.65988862623641,
    "lat": 42.324101255863276
  },
  {
    "name": "Hornersville",
    "lng": -90.11586718403314,
    "lat": 36.040323098856526
  },
  {
    "name": "Hornick",
    "lng": -96.09717391428381,
    "lat": 42.230492954751405
  },
  {
    "name": "Hornick",
    "lng": -96.0949055991985,
    "lat": 42.22425357172654
  },
  {
    "name": "Hornsby",
    "lng": -88.83023049110312,
    "lat": 35.2295292978411
  },
  {
    "name": "Horntown",
    "lng": -96.24762674121733,
    "lat": 35.08688992228429
  },
  {
    "name": "Horse Cave",
    "lng": -85.91253994946383,
    "lat": 37.16987178429405
  },
  {
    "name": "Horseheads",
    "lng": -76.82979663089078,
    "lat": 42.16888705746217
  },
  {
    "name": "Horseshoe Bay",
    "lng": -98.38042479838583,
    "lat": 30.540442013427697
  },
  {
    "name": "Horseshoe Bend",
    "lng": -116.19971113187063,
    "lat": 43.91645598298288
  },
  {
    "name": "Horseshoe Bend",
    "lng": -91.73958575801751,
    "lat": 36.2218915962249
  },
  {
    "name": "Horseshoe Lake",
    "lng": -90.30799397996768,
    "lat": 34.91356033931376
  },
  {
    "name": "Horton",
    "lng": -95.57349746542394,
    "lat": 39.65462558206486
  },
  {
    "name": "Horton",
    "lng": -95.56786307204004,
    "lat": 39.666766812404774
  },
  {
    "name": "Horton",
    "lng": -95.52564142415731,
    "lat": 39.66389164888929
  },
  {
    "name": "Hortonville",
    "lng": -88.63622672498778,
    "lat": 44.336419791239926
  },
  {
    "name": "Hoschton",
    "lng": -83.76170931835387,
    "lat": 34.09338193762735
  },
  {
    "name": "Hoskins",
    "lng": -97.30438352630658,
    "lat": 42.11263530205104
  },
  {
    "name": "Hosmer",
    "lng": -99.47364277958383,
    "lat": 45.57878751549955
  },
  {
    "name": "Hospers",
    "lng": -95.9035501754625,
    "lat": 43.07248251675121
  },
  {
    "name": "Hosston",
    "lng": -93.8820392880151,
    "lat": 32.884909768859664
  },
  {
    "name": "Hot Springs",
    "lng": -114.67167856935342,
    "lat": 47.60918564627922
  },
  {
    "name": "Hot Springs",
    "lng": -103.48354297475261,
    "lat": 43.4311266945417
  },
  {
    "name": "Hot Springs",
    "lng": -93.12447219135268,
    "lat": 34.49276979942352
  },
  {
    "name": "Hot Springs",
    "lng": -93.08960487217013,
    "lat": 34.43809071629431
  },
  {
    "name": "Hot Springs",
    "lng": -93.05009717758699,
    "lat": 34.48963237670808
  },
  {
    "name": "Hot Springs",
    "lng": -93.0467710263581,
    "lat": 34.447971316726985
  },
  {
    "name": "Hot Springs",
    "lng": -93.04669496303012,
    "lat": 34.435298144606485
  },
  {
    "name": "Hot Springs",
    "lng": -93.04172941114567,
    "lat": 34.43080935360402
  },
  {
    "name": "Hot Springs",
    "lng": -82.82905594759113,
    "lat": 35.89203926898548
  },
  {
    "name": "Hot Sulphur Springs",
    "lng": -106.10248893444275,
    "lat": 40.07483675923059
  },
  {
    "name": "Hotchkiss",
    "lng": -107.72992714726745,
    "lat": 38.80592932054071
  },
  {
    "name": "Hotchkiss",
    "lng": -107.71425012138582,
    "lat": 38.799411280350306
  },
  {
    "name": "Houghton",
    "lng": -91.61096239431927,
    "lat": 40.783940348047544
  },
  {
    "name": "Houghton",
    "lng": -88.57467886568993,
    "lat": 47.11325666287997
  },
  {
    "name": "Houghton",
    "lng": -88.54997649057377,
    "lat": 47.10020576657015
  },
  {
    "name": "Houlton",
    "lng": -67.84276310118062,
    "lat": 46.14033056595093
  },
  {
    "name": "Houma",
    "lng": -90.70593138770973,
    "lat": 29.57988467573579
  },
  {
    "name": "House",
    "lng": -103.90375090376703,
    "lat": 34.64945484570082
  },
  {
    "name": "Houston",
    "lng": -149.80146389981033,
    "lat": 61.63117149750186
  },
  {
    "name": "Houston",
    "lng": -149.7925772221656,
    "lat": 61.618406309495874
  },
  {
    "name": "Houston",
    "lng": -95.37589251289984,
    "lat": 29.776623210102102
  },
  {
    "name": "Houston",
    "lng": -95.24508992472816,
    "lat": 29.96517371864627
  },
  {
    "name": "Houston",
    "lng": -95.23973295218238,
    "lat": 29.96718978953401
  },
  {
    "name": "Houston",
    "lng": -92.69459063901627,
    "lat": 35.03495215615838
  },
  {
    "name": "Houston",
    "lng": -91.96104888037192,
    "lat": 37.32123416893119
  },
  {
    "name": "Houston",
    "lng": -91.57060920807645,
    "lat": 43.7583150138509
  },
  {
    "name": "Houston",
    "lng": -89.0031282287588,
    "lat": 33.89631822859142
  },
  {
    "name": "Houston Acres",
    "lng": -85.61401684412961,
    "lat": 38.21488042238307
  },
  {
    "name": "Houstonia",
    "lng": -93.3593548402582,
    "lat": 38.89936586697499
  },
  {
    "name": "Hoven",
    "lng": -99.77752008649489,
    "lat": 45.241616364240045
  },
  {
    "name": "Howard",
    "lng": -97.52463196907007,
    "lat": 44.01174342291812
  },
  {
    "name": "Howard",
    "lng": -96.26311590241853,
    "lat": 37.469538740506415
  },
  {
    "name": "Howard",
    "lng": -88.09074120504269,
    "lat": 44.57022534433082
  },
  {
    "name": "Howard City",
    "lng": -98.71551475844478,
    "lat": 41.07517737753257
  },
  {
    "name": "Howard City",
    "lng": -85.46818910247622,
    "lat": 43.39458677936756
  },
  {
    "name": "Howard Lake",
    "lng": -94.08885729615264,
    "lat": 45.052771066161945
  },
  {
    "name": "Howard Lake",
    "lng": -94.06515810571221,
    "lat": 45.06289767531066
  },
  {
    "name": "Howards Grove",
    "lng": -87.8233792055161,
    "lat": 43.82628323845424
  },
  {
    "name": "Howardville",
    "lng": -89.59722497310216,
    "lat": 36.568430559470634
  },
  {
    "name": "Howardwick",
    "lng": -100.90861142577417,
    "lat": 35.03546353893761
  },
  {
    "name": "Howe",
    "lng": -96.6172601454406,
    "lat": 33.51311983118722
  },
  {
    "name": "Howe",
    "lng": -94.64036407344244,
    "lat": 34.94967388889202
  },
  {
    "name": "Howell",
    "lng": -112.44531479649095,
    "lat": 41.77334406370127
  },
  {
    "name": "Howell",
    "lng": -83.93478498327286,
    "lat": 42.60852067604859
  },
  {
    "name": "Howells",
    "lng": -97.00438840004733,
    "lat": 41.72441742902681
  },
  {
    "name": "Howey-in-the-Hills",
    "lng": -81.77896002050834,
    "lat": 28.711641838991678
  },
  {
    "name": "Howland",
    "lng": -68.69832785109278,
    "lat": 45.25893629919412
  },
  {
    "name": "Hoxie",
    "lng": -100.43974025280068,
    "lat": 39.35579126703617
  },
  {
    "name": "Hoxie",
    "lng": -90.97353767017509,
    "lat": 36.03602511270053
  },
  {
    "name": "Hoyleton",
    "lng": -89.27210716628956,
    "lat": 38.445710478098825
  },
  {
    "name": "Hoyt",
    "lng": -95.72161382512238,
    "lat": 39.247159943958756
  },
  {
    "name": "Hoyt",
    "lng": -95.70638918884747,
    "lat": 39.2499480579145
  },
  {
    "name": "Hoyt Lakes",
    "lng": -92.11584666430811,
    "lat": 47.55993796546451
  },
  {
    "name": "Hoytville",
    "lng": -83.78443971153297,
    "lat": 41.19000107120604
  },
  {
    "name": "Huachuca City",
    "lng": -110.34158569288688,
    "lat": 31.631455965185253
  },
  {
    "name": "Hubbard",
    "lng": -96.80475165218503,
    "lat": 31.843521396684647
  },
  {
    "name": "Hubbard",
    "lng": -96.59148698652496,
    "lat": 42.38590071831418
  },
  {
    "name": "Hubbard",
    "lng": -93.30207731624499,
    "lat": 42.30579319960565
  },
  {
    "name": "Hubbard",
    "lng": -80.56827830304212,
    "lat": 41.15934676656615
  },
  {
    "name": "Hubbardston",
    "lng": -84.84128009158898,
    "lat": 43.09387514505142
  },
  {
    "name": "Hubbardston",
    "lng": -72.00513742913098,
    "lat": 42.48380626402551
  },
  {
    "name": "Hubbell",
    "lng": -97.49717144470304,
    "lat": 40.0134422617265
  },
  {
    "name": "Hubbell",
    "lng": -97.49657386664872,
    "lat": 40.00825374536844
  },
  {
    "name": "Huber Heights",
    "lng": -84.11297826383677,
    "lat": 39.85942072748878
  },
  {
    "name": "Hudson",
    "lng": -108.58853434431549,
    "lat": 42.90072982637963
  },
  {
    "name": "Hudson",
    "lng": -108.58193567000875,
    "lat": 42.90271503476684
  },
  {
    "name": "Hudson",
    "lng": -104.63065650411689,
    "lat": 40.07495564195217
  },
  {
    "name": "Hudson",
    "lng": -98.66076933809202,
    "lat": 38.104964997563116
  },
  {
    "name": "Hudson",
    "lng": -96.45554665073116,
    "lat": 43.130320921714116
  },
  {
    "name": "Hudson",
    "lng": -94.80138699533455,
    "lat": 31.328638064276873
  },
  {
    "name": "Hudson",
    "lng": -92.73183087452831,
    "lat": 44.963934747527695
  },
  {
    "name": "Hudson",
    "lng": -92.45213461521014,
    "lat": 42.431958629290996
  },
  {
    "name": "Hudson",
    "lng": -88.98930098509344,
    "lat": 40.605411934358784
  },
  {
    "name": "Hudson",
    "lng": -85.08409030171256,
    "lat": 41.531767159405206
  },
  {
    "name": "Hudson",
    "lng": -84.34508980160872,
    "lat": 41.85688113417905
  },
  {
    "name": "Hudson",
    "lng": -81.50308378125281,
    "lat": 35.83339046461547
  },
  {
    "name": "Hudson",
    "lng": -81.4859881848987,
    "lat": 35.84780390153965
  },
  {
    "name": "Hudson",
    "lng": -81.44079383357558,
    "lat": 41.23985340155245
  },
  {
    "name": "Hudson",
    "lng": -73.78588684913527,
    "lat": 42.25151654628876
  },
  {
    "name": "Hudson",
    "lng": -71.546475707182,
    "lat": 42.38867818736451
  },
  {
    "name": "Hudson",
    "lng": -71.40716032026077,
    "lat": 42.76394249829276
  },
  {
    "name": "Hudson",
    "lng": -68.88301505933038,
    "lat": 44.99734916766505
  },
  {
    "name": "Hudson Falls",
    "lng": -73.58187850423857,
    "lat": 43.30420018908875
  },
  {
    "name": "Hudson Oaks",
    "lng": -97.71574099048998,
    "lat": 32.748472855371446
  },
  {
    "name": "Hudson Oaks",
    "lng": -97.69934220408722,
    "lat": 32.750950995510955
  },
  {
    "name": "Hudsonville",
    "lng": -85.8623935666135,
    "lat": 42.86362532316103
  },
  {
    "name": "Huetter",
    "lng": -116.85083815307962,
    "lat": 47.703543947287216
  },
  {
    "name": "Huey",
    "lng": -89.28997281353483,
    "lat": 38.60528496861923
  },
  {
    "name": "Hueytown",
    "lng": -87.0220441080886,
    "lat": 33.42413913489816
  },
  {
    "name": "Hueytown",
    "lng": -87.02193900701612,
    "lat": 33.424161830584254
  },
  {
    "name": "Hueytown",
    "lng": -86.95229694531552,
    "lat": 33.458295445752846
  },
  {
    "name": "Hughes",
    "lng": -154.2370169658612,
    "lat": 66.04472166894533
  },
  {
    "name": "Hughes",
    "lng": -90.47067253189881,
    "lat": 34.94832692315567
  },
  {
    "name": "Hughes Springs",
    "lng": -94.63107862253536,
    "lat": 32.99866597560725
  },
  {
    "name": "Hughesville",
    "lng": -93.29517040451766,
    "lat": 38.8368899669822
  },
  {
    "name": "Hughson",
    "lng": -120.86422327085361,
    "lat": 37.60098028343027
  },
  {
    "name": "Hugo",
    "lng": -103.47355337293853,
    "lat": 39.13584250380445
  },
  {
    "name": "Hugo",
    "lng": -95.51217905514659,
    "lat": 34.012104434275116
  },
  {
    "name": "Hugo",
    "lng": -92.95875178836685,
    "lat": 45.16713542366484
  },
  {
    "name": "Hugoton",
    "lng": -101.34681428476625,
    "lat": 37.175661309997324
  },
  {
    "name": "Hulbert",
    "lng": -95.14368525176073,
    "lat": 35.931480093818756
  },
  {
    "name": "Hulett",
    "lng": -104.59876719630878,
    "lat": 44.68361150066821
  },
  {
    "name": "Hulett",
    "lng": -104.59529637654582,
    "lat": 44.67230357356124
  },
  {
    "name": "Hulett",
    "lng": -104.59411841225915,
    "lat": 44.69450854242532
  },
  {
    "name": "Hull",
    "lng": -96.13392917460303,
    "lat": 43.189543410449794
  },
  {
    "name": "Hull",
    "lng": -91.20315847735648,
    "lat": 39.708643510460895
  },
  {
    "name": "Hull",
    "lng": -83.29394752461512,
    "lat": 34.01540547002965
  },
  {
    "name": "Humansville",
    "lng": -93.57666548765853,
    "lat": 37.7949388599242
  },
  {
    "name": "Humble",
    "lng": -95.26580596062938,
    "lat": 29.99218064850855
  },
  {
    "name": "Humboldt",
    "lng": -97.0946146858957,
    "lat": 48.92151581612037
  },
  {
    "name": "Humboldt",
    "lng": -97.07410426540653,
    "lat": 43.64502827139205
  },
  {
    "name": "Humboldt",
    "lng": -95.94431870526739,
    "lat": 40.16582031056953
  },
  {
    "name": "Humboldt",
    "lng": -95.44902740078554,
    "lat": 37.8102859313517
  },
  {
    "name": "Humboldt",
    "lng": -95.43703538124457,
    "lat": 37.81158894359687
  },
  {
    "name": "Humboldt",
    "lng": -94.2239553262521,
    "lat": 42.72290346914335
  },
  {
    "name": "Humboldt",
    "lng": -88.90479903761641,
    "lat": 35.825152861187505
  },
  {
    "name": "Humboldt",
    "lng": -88.87667201962232,
    "lat": 35.851869719137376
  },
  {
    "name": "Humboldt",
    "lng": -88.31997398215911,
    "lat": 39.60472054798464
  },
  {
    "name": "Hume",
    "lng": -94.58270959571183,
    "lat": 38.090571312533186
  },
  {
    "name": "Hume",
    "lng": -87.86916893970044,
    "lat": 39.79850050019276
  },
  {
    "name": "Humeston",
    "lng": -93.4970409716094,
    "lat": 40.860774747270696
  },
  {
    "name": "Humnoke",
    "lng": -91.76000512064103,
    "lat": 34.54233150971977
  },
  {
    "name": "Humphrey",
    "lng": -97.48728811104851,
    "lat": 41.68870468716087
  },
  {
    "name": "Humphrey",
    "lng": -91.70245717496816,
    "lat": 34.42237091592878
  },
  {
    "name": "Humphreys",
    "lng": -93.31950594474522,
    "lat": 40.12519438948824
  },
  {
    "name": "Hundred",
    "lng": -80.45759753508595,
    "lat": 39.68360253892901
  },
  {
    "name": "Hunnewell",
    "lng": -97.4075169071865,
    "lat": 37.00463721902717
  },
  {
    "name": "Hunnewell",
    "lng": -91.8588851611088,
    "lat": 39.66796054579483
  },
  {
    "name": "Hunter",
    "lng": -98.39634335383677,
    "lat": 39.235019500558074
  },
  {
    "name": "Hunter",
    "lng": -97.66246392243023,
    "lat": 36.56368638399727
  },
  {
    "name": "Hunter",
    "lng": -97.21317436640473,
    "lat": 47.19046567199899
  },
  {
    "name": "Hunter",
    "lng": -91.12467784936089,
    "lat": 35.05419731037805
  },
  {
    "name": "Hunter",
    "lng": -74.21604311510659,
    "lat": 42.21296776667192
  },
  {
    "name": "Hunter's Creek",
    "lng": -81.42853721895524,
    "lat": 28.361283452952502
  },
  {
    "name": "Hunters Creek Village",
    "lng": -95.49870633718986,
    "lat": 29.771527681530646
  },
  {
    "name": "Hunters Hollow",
    "lng": -85.69718069704804,
    "lat": 38.08065465221128
  },
  {
    "name": "Hunters Hollow",
    "lng": -85.69248323343069,
    "lat": 38.077857868395384
  },
  {
    "name": "Huntersville",
    "lng": -80.87972832411991,
    "lat": 35.406021831270074
  },
  {
    "name": "Huntersville",
    "lng": -80.81056432511939,
    "lat": 35.407481808042384
  },
  {
    "name": "Huntersville",
    "lng": -80.79728775610732,
    "lat": 35.425208694379386
  },
  {
    "name": "Huntersville",
    "lng": -80.79518241588102,
    "lat": 35.38934225139531
  },
  {
    "name": "Huntersville",
    "lng": -80.76991683550084,
    "lat": 35.40739598529111
  },
  {
    "name": "Huntersville",
    "lng": -80.75941947161319,
    "lat": 35.421351233691446
  },
  {
    "name": "Huntertown",
    "lng": -85.1763640993161,
    "lat": 41.21311038517796
  },
  {
    "name": "Hunting Island State Park",
    "lng": -80.46318862405482,
    "lat": 32.3666995879374
  },
  {
    "name": "Hunting Valley",
    "lng": -81.41079473098851,
    "lat": 41.48021050195761
  },
  {
    "name": "Huntingburg",
    "lng": -86.96245280051056,
    "lat": 38.3009057052663
  },
  {
    "name": "Huntingdon",
    "lng": -88.4170996363627,
    "lat": 36.00588348155572
  },
  {
    "name": "Huntingdon",
    "lng": -78.00974222108725,
    "lat": 40.50001217257555
  },
  {
    "name": "Huntington",
    "lng": -110.96281498740238,
    "lat": 39.33012200649157
  },
  {
    "name": "Huntington",
    "lng": -94.57692692951369,
    "lat": 31.27967939054956
  },
  {
    "name": "Huntington",
    "lng": -94.26630117308049,
    "lat": 35.08131547129504
  },
  {
    "name": "Huntington",
    "lng": -85.50700970398016,
    "lat": 40.880704230536125
  },
  {
    "name": "Huntington",
    "lng": -85.44362445788289,
    "lat": 40.89706338204036
  },
  {
    "name": "Huntington",
    "lng": -82.43493935861103,
    "lat": 38.411184917349146
  },
  {
    "name": "Huntington",
    "lng": -82.39153486436786,
    "lat": 38.38522107425171
  },
  {
    "name": "Huntington",
    "lng": -82.35371561710738,
    "lat": 38.41762827727403
  },
  {
    "name": "Huntington",
    "lng": -72.85492837670867,
    "lat": 42.284448596048364
  },
  {
    "name": "Huntington Bay",
    "lng": -73.41100372232583,
    "lat": 40.903764797239546
  },
  {
    "name": "Huntington Beach",
    "lng": -118.02516027518055,
    "lat": 33.67187692218582
  },
  {
    "name": "Huntington Woods",
    "lng": -83.16847232060529,
    "lat": 42.48157471573434
  },
  {
    "name": "Huntland",
    "lng": -86.26907338875614,
    "lat": 35.05799350708404
  },
  {
    "name": "Huntleigh",
    "lng": -90.40886607318609,
    "lat": 38.613076423313935
  },
  {
    "name": "Huntley",
    "lng": -99.29052221948788,
    "lat": 40.2104359433903
  },
  {
    "name": "Huntley",
    "lng": -88.47071104479956,
    "lat": 42.13258848795689
  },
  {
    "name": "Huntley",
    "lng": -88.4676160029004,
    "lat": 42.13216907731081
  },
  {
    "name": "Huntley",
    "lng": -88.43292134825036,
    "lat": 42.16005043222651
  },
  {
    "name": "Hunts Point",
    "lng": -122.22899054330726,
    "lat": 47.64208460727132
  },
  {
    "name": "Huntsdale",
    "lng": -92.4721757358306,
    "lat": 38.91153504826053
  },
  {
    "name": "Huntsville",
    "lng": -111.77430869192223,
    "lat": 41.260213415792414
  },
  {
    "name": "Huntsville",
    "lng": -95.55429745668091,
    "lat": 30.704856908137273
  },
  {
    "name": "Huntsville",
    "lng": -93.73629961178466,
    "lat": 36.09860504175785
  },
  {
    "name": "Huntsville",
    "lng": -92.5439936783295,
    "lat": 39.43637589288901
  },
  {
    "name": "Huntsville",
    "lng": -86.83658804650648,
    "lat": 34.5789401502194
  },
  {
    "name": "Huntsville",
    "lng": -86.71004322418847,
    "lat": 34.74666772402641
  },
  {
    "name": "Huntsville",
    "lng": -86.68384732564957,
    "lat": 34.76312139838282
  },
  {
    "name": "Huntsville",
    "lng": -86.62265528985947,
    "lat": 34.69959872828069
  },
  {
    "name": "Huntsville",
    "lng": -86.48130928117811,
    "lat": 34.67687630143859
  },
  {
    "name": "Huntsville",
    "lng": -86.45545860313946,
    "lat": 34.67797166044753
  },
  {
    "name": "Huntsville",
    "lng": -86.44182538999617,
    "lat": 34.66967390793399
  },
  {
    "name": "Huntsville",
    "lng": -84.53415003597193,
    "lat": 36.42765364402888
  },
  {
    "name": "Huntsville",
    "lng": -84.53157974553555,
    "lat": 36.40216679587023
  },
  {
    "name": "Huntsville",
    "lng": -84.50088715756873,
    "lat": 36.41055593644071
  },
  {
    "name": "Huntsville",
    "lng": -83.80461316035444,
    "lat": 40.442357008594996
  },
  {
    "name": "Hurdland",
    "lng": -92.30338828775754,
    "lat": 40.148894293261684
  },
  {
    "name": "Hurdsfield",
    "lng": -99.93054160923354,
    "lat": 47.44683419163516
  },
  {
    "name": "Hurley",
    "lng": -108.13212842460675,
    "lat": 32.698253705672414
  },
  {
    "name": "Hurley",
    "lng": -97.08944900446197,
    "lat": 43.27932485908087
  },
  {
    "name": "Hurley",
    "lng": -93.49953028446143,
    "lat": 36.93057025762144
  },
  {
    "name": "Hurley",
    "lng": -90.19869256146346,
    "lat": 46.44673555603155
  },
  {
    "name": "Hurley",
    "lng": -90.19072480897846,
    "lat": 46.45956748198679
  },
  {
    "name": "Hurlock",
    "lng": -75.86417101863006,
    "lat": 38.62660014210548
  },
  {
    "name": "Huron",
    "lng": -120.0981127423721,
    "lat": 36.203284883322354
  },
  {
    "name": "Huron",
    "lng": -120.07319191981296,
    "lat": 36.21217201149199
  },
  {
    "name": "Huron",
    "lng": -98.21620807702867,
    "lat": 44.36346832594839
  },
  {
    "name": "Huron",
    "lng": -95.35134629895106,
    "lat": 39.63841652790869
  },
  {
    "name": "Huron",
    "lng": -82.56231298868587,
    "lat": 41.397211490520135
  },
  {
    "name": "Hurricane",
    "lng": -113.35152241027559,
    "lat": 37.14869430399617
  },
  {
    "name": "Hurricane",
    "lng": -82.01691070021175,
    "lat": 38.429333339755686
  },
  {
    "name": "Hurst",
    "lng": -97.18081254611272,
    "lat": 32.83526064692467
  },
  {
    "name": "Hurst",
    "lng": -89.14441348604784,
    "lat": 37.834653381736736
  },
  {
    "name": "Hurstbourne",
    "lng": -85.58910124772552,
    "lat": 38.23785626748714
  },
  {
    "name": "Hurstbourne Acres",
    "lng": -85.59103406981698,
    "lat": 38.22004219940265
  },
  {
    "name": "Hurt",
    "lng": -79.30291935962639,
    "lat": 37.09545927542965
  },
  {
    "name": "Hurtsboro",
    "lng": -85.41466581332841,
    "lat": 32.240089739189244
  },
  {
    "name": "Huslia",
    "lng": -156.33823830270632,
    "lat": 65.70189227063408
  },
  {
    "name": "Hustisford",
    "lng": -88.60511627336749,
    "lat": 43.34593516938673
  },
  {
    "name": "Hustler",
    "lng": -90.2656986907388,
    "lat": 43.87727199767317
  },
  {
    "name": "Hustonville",
    "lng": -84.81579174526023,
    "lat": 37.47525145760046
  },
  {
    "name": "Hutchins",
    "lng": -96.70772396202926,
    "lat": 32.64388838849758
  },
  {
    "name": "Hutchinson",
    "lng": -97.90820330217566,
    "lat": 38.06693805941286
  },
  {
    "name": "Hutchinson",
    "lng": -97.88317899511334,
    "lat": 38.00704871519203
  },
  {
    "name": "Hutchinson",
    "lng": -94.37779185413824,
    "lat": 44.88546825746998
  },
  {
    "name": "Hutchinson",
    "lng": -94.33385177357539,
    "lat": 44.89416982559461
  },
  {
    "name": "Hutsonville",
    "lng": -87.66088650718736,
    "lat": 39.108434545459076
  },
  {
    "name": "Huttig",
    "lng": -92.18177422581843,
    "lat": 33.041768421153506
  },
  {
    "name": "Hutto",
    "lng": -97.5743692037848,
    "lat": 30.55827764600182
  },
  {
    "name": "Hutto",
    "lng": -97.56717654590246,
    "lat": 30.54846947223311
  },
  {
    "name": "Hutto",
    "lng": -97.56672362030605,
    "lat": 30.55888006997969
  },
  {
    "name": "Hutto",
    "lng": -97.56642742479356,
    "lat": 30.554609829808893
  },
  {
    "name": "Hutto",
    "lng": -97.56406242899793,
    "lat": 30.55826106443248
  },
  {
    "name": "Hutto",
    "lng": -97.55133625825003,
    "lat": 30.537060159487076
  },
  {
    "name": "Huttonsville",
    "lng": -79.97662043685682,
    "lat": 38.71380429506076
  },
  {
    "name": "Huxley",
    "lng": -93.88534472422035,
    "lat": 31.76539821950943
  },
  {
    "name": "Huxley",
    "lng": -93.59122573369724,
    "lat": 41.89587396736899
  },
  {
    "name": "Hyannis",
    "lng": -101.76201950399486,
    "lat": 42.00048428173192
  },
  {
    "name": "Hydaburg",
    "lng": -132.81872028863125,
    "lat": 55.205255586438874
  },
  {
    "name": "Hyde Park",
    "lng": -111.8120181912724,
    "lat": 41.800838909022026
  },
  {
    "name": "Hyde Park",
    "lng": -72.61301956529029,
    "lat": 44.59723009689361
  },
  {
    "name": "Hyden",
    "lng": -83.37351017127698,
    "lat": 37.16208191147848
  },
  {
    "name": "Hydro",
    "lng": -98.5786896085604,
    "lat": 35.54884844379366
  },
  {
    "name": "Hymera",
    "lng": -87.29892871118226,
    "lat": 39.18587732117022
  },
  {
    "name": "Hypoluxo",
    "lng": -80.05308341951796,
    "lat": 26.563380044608877
  },
  {
    "name": "Hysham",
    "lng": -107.23067394328376,
    "lat": 46.29094210996896
  },
  {
    "name": "IXL",
    "lng": -96.38807617996267,
    "lat": 35.522721041453856
  },
  {
    "name": "Iaeger",
    "lng": -81.81121333172004,
    "lat": 37.46396518096073
  },
  {
    "name": "Iatan",
    "lng": -94.98375984176634,
    "lat": 39.4772918244206
  },
  {
    "name": "Iberia",
    "lng": -92.29499822105268,
    "lat": 38.09781607258802
  },
  {
    "name": "Iberia",
    "lng": -92.29491130629464,
    "lat": 38.088423262957306
  },
  {
    "name": "Ida",
    "lng": -93.89493819868518,
    "lat": 33.000227421123014
  },
  {
    "name": "Ida Grove",
    "lng": -95.4732992619167,
    "lat": 42.34379396841976
  },
  {
    "name": "Idabel",
    "lng": -94.85094612648523,
    "lat": 33.87980996669713
  },
  {
    "name": "Idabel",
    "lng": -94.83278652881161,
    "lat": 33.937181744604906
  },
  {
    "name": "Idabel",
    "lng": -94.82972702805105,
    "lat": 33.90424294243048
  },
  {
    "name": "Idaho",
    "lng": -114.68379396996605,
    "lat": 44.46771767557705
  },
  {
    "name": "Idaho City",
    "lng": -115.83057378496166,
    "lat": 43.82805361529079
  },
  {
    "name": "Idaho Falls",
    "lng": -112.04739920062734,
    "lat": 43.55468641609136
  },
  {
    "name": "Idaho Falls",
    "lng": -112.03648149219659,
    "lat": 43.48462225260513
  },
  {
    "name": "Idaho Falls",
    "lng": -112.03544532709667,
    "lat": 43.54545115460053
  },
  {
    "name": "Idaho Falls",
    "lng": -112.02145410317635,
    "lat": 43.5240439560222
  },
  {
    "name": "Idaho Falls",
    "lng": -111.98957849287974,
    "lat": 43.512232444711834
  },
  {
    "name": "Idaho Springs",
    "lng": -105.57345925820916,
    "lat": 39.71603135150716
  },
  {
    "name": "Idaho Springs",
    "lng": -105.5731478784501,
    "lat": 39.71708864843719
  },
  {
    "name": "Idaho Springs",
    "lng": -105.51142462985511,
    "lat": 39.74528800404002
  },
  {
    "name": "Idaho Springs",
    "lng": -105.5113821782347,
    "lat": 39.745141616312864
  },
  {
    "name": "Idaho Springs",
    "lng": -105.47218523470433,
    "lat": 39.74371354529458
  },
  {
    "name": "Idaho Springs",
    "lng": -105.4691951401421,
    "lat": 39.74994362403401
  },
  {
    "name": "Idalou",
    "lng": -101.68370448509954,
    "lat": 33.66240588061026
  },
  {
    "name": "Ideal",
    "lng": -84.19045658946615,
    "lat": 32.37345736861274
  },
  {
    "name": "Ider",
    "lng": -85.6690396161256,
    "lat": 34.702165294734364
  },
  {
    "name": "Idyllwild-Pine Cove",
    "lng": -116.72581700451589,
    "lat": 33.74426684348314
  },
  {
    "name": "Ignacio",
    "lng": -107.63750882281725,
    "lat": 37.11469235168459
  },
  {
    "name": "Ignacio",
    "lng": -107.63736403297376,
    "lat": 37.11771886139213
  },
  {
    "name": "Ihlen",
    "lng": -96.37007779518922,
    "lat": 43.91108959471169
  },
  {
    "name": "Ila",
    "lng": -83.292926313419,
    "lat": 34.17291919895933
  },
  {
    "name": "Iliff",
    "lng": -103.06612633896194,
    "lat": 40.75833281014517
  },
  {
    "name": "Ilion",
    "lng": -75.03943509740081,
    "lat": 43.01148309651757
  },
  {
    "name": "Illinois",
    "lng": -89.14775498224473,
    "lat": 40.16597499726306
  },
  {
    "name": "Illiopolis",
    "lng": -89.24766900019388,
    "lat": 39.851451160892054
  },
  {
    "name": "Ilwaco",
    "lng": -124.04121864227437,
    "lat": 46.31008114437118
  },
  {
    "name": "Imboden",
    "lng": -91.17764947419107,
    "lat": 36.19973128461037
  },
  {
    "name": "Imlay City",
    "lng": -83.07650131506757,
    "lat": 43.01687999919628
  },
  {
    "name": "Imogene",
    "lng": -95.42923978405119,
    "lat": 40.87769583894105
  },
  {
    "name": "Impact",
    "lng": -99.74652367032824,
    "lat": 32.50017799536408
  },
  {
    "name": "Imperial",
    "lng": -115.57190438759393,
    "lat": 32.83893172252397
  },
  {
    "name": "Imperial",
    "lng": -101.63780633420534,
    "lat": 40.5146502089397
  },
  {
    "name": "Imperial Beach",
    "lng": -117.11858939008654,
    "lat": 32.56916029899244
  },
  {
    "name": "Ina",
    "lng": -88.90401916838377,
    "lat": 38.149901795645576
  },
  {
    "name": "Independence",
    "lng": -111.29585147788109,
    "lat": 40.41111498169713
  },
  {
    "name": "Independence",
    "lng": -95.78021995410431,
    "lat": 37.155405301342164
  },
  {
    "name": "Independence",
    "lng": -95.72159698297939,
    "lat": 37.205984108917676
  },
  {
    "name": "Independence",
    "lng": -95.71566635010934,
    "lat": 37.23167035435476
  },
  {
    "name": "Independence",
    "lng": -94.35015964074404,
    "lat": 39.08700048639885
  },
  {
    "name": "Independence",
    "lng": -91.90227181028436,
    "lat": 42.46245787823929
  },
  {
    "name": "Independence",
    "lng": -91.4187646234209,
    "lat": 44.36220743246711
  },
  {
    "name": "Independence",
    "lng": -90.50557116127803,
    "lat": 30.636101758325033
  },
  {
    "name": "Independence",
    "lng": -84.54981387435261,
    "lat": 38.95114208426658
  },
  {
    "name": "Independence",
    "lng": -84.53094394900825,
    "lat": 38.963709191873775
  },
  {
    "name": "Independence",
    "lng": -84.49677542750226,
    "lat": 38.93398375650908
  },
  {
    "name": "Independence",
    "lng": -81.63854900290063,
    "lat": 41.37778310911126
  },
  {
    "name": "Independence",
    "lng": -81.15179590386055,
    "lat": 36.62625242999299
  },
  {
    "name": "Index",
    "lng": -121.55510162226503,
    "lat": 47.82163894950862
  },
  {
    "name": "Indiahoma",
    "lng": -98.75209539183119,
    "lat": 34.61988005158278
  },
  {
    "name": "Indialantic",
    "lng": -80.57017336265241,
    "lat": 28.087135006469428
  },
  {
    "name": "Indian Beach",
    "lng": -76.8999551965332,
    "lat": 34.68987869971309
  },
  {
    "name": "Indian Beach",
    "lng": -76.87189987326886,
    "lat": 34.6904677597404
  },
  {
    "name": "Indian Creek",
    "lng": -87.97829289769228,
    "lat": 42.22719500546841
  },
  {
    "name": "Indian Harbour Beach",
    "lng": -80.59671485328934,
    "lat": 28.152501127772002
  },
  {
    "name": "Indian Head",
    "lng": -77.1556846163057,
    "lat": 38.59855140587667
  },
  {
    "name": "Indian Head Park",
    "lng": -87.89755619702657,
    "lat": 41.76908572846769
  },
  {
    "name": "Indian Hills",
    "lng": -85.6616419182355,
    "lat": 38.280874290568015
  },
  {
    "name": "Indian Lake",
    "lng": -97.50323915919383,
    "lat": 26.087590344354233
  },
  {
    "name": "Indian Mountain Lake",
    "lng": -75.50587288709993,
    "lat": 41.00030061675996
  },
  {
    "name": "Indian Point",
    "lng": -93.34440827070777,
    "lat": 36.64283523396294
  },
  {
    "name": "Indian River Shores",
    "lng": -80.40442276922379,
    "lat": 27.722131860932983
  },
  {
    "name": "Indian River Shores",
    "lng": -80.40200243575929,
    "lat": 27.732647464461063
  },
  {
    "name": "Indian River Shores",
    "lng": -80.39771805038798,
    "lat": 27.738799548491563
  },
  {
    "name": "Indian River Shores",
    "lng": -80.39645915769438,
    "lat": 27.723832941992722
  },
  {
    "name": "Indian River Shores",
    "lng": -80.3804026416145,
    "lat": 27.703115701685583
  },
  {
    "name": "Indian River Shores",
    "lng": -80.37227023775372,
    "lat": 27.65573925884064
  },
  {
    "name": "Indian River Shores",
    "lng": -80.37119205000037,
    "lat": 27.661457150524804
  },
  {
    "name": "Indian River Shores",
    "lng": -80.3694554448497,
    "lat": 27.665626416218984
  },
  {
    "name": "Indian Rocks Beach",
    "lng": -82.84398401103607,
    "lat": 27.896423418615267
  },
  {
    "name": "Indian Shores",
    "lng": -82.84557048048478,
    "lat": 27.853660872143365
  },
  {
    "name": "Indian Springs Village",
    "lng": -86.75265267360712,
    "lat": 33.3574654039722
  },
  {
    "name": "Indian Springs Village",
    "lng": -86.75224619065645,
    "lat": 33.35767860712272
  },
  {
    "name": "Indian Springs Village",
    "lng": -86.73381779894017,
    "lat": 33.35848485278881
  },
  {
    "name": "Indian Springs Village",
    "lng": -86.72092041332029,
    "lat": 33.37431292500245
  },
  {
    "name": "Indian Trail",
    "lng": -80.6865280622675,
    "lat": 35.08787722151024
  },
  {
    "name": "Indian Trail",
    "lng": -80.67883607486105,
    "lat": 35.03943229982986
  },
  {
    "name": "Indian Trail",
    "lng": -80.67422619914703,
    "lat": 35.04089603813113
  },
  {
    "name": "Indian Trail",
    "lng": -80.64699348505219,
    "lat": 35.06723442545448
  },
  {
    "name": "Indian Trail",
    "lng": -80.6294953040721,
    "lat": 35.117707146171945
  },
  {
    "name": "Indian Village",
    "lng": -116.8740447340413,
    "lat": 36.448924564986726
  },
  {
    "name": "Indian Village",
    "lng": -86.23223745949413,
    "lat": 41.71426838134676
  },
  {
    "name": "Indian Wells",
    "lng": -116.33965718475845,
    "lat": 33.70359954028434
  },
  {
    "name": "Indiana",
    "lng": -86.27907268318695,
    "lat": 39.94527523218142
  },
  {
    "name": "Indiana",
    "lng": -79.155201901646,
    "lat": 40.62199006395913
  },
  {
    "name": "Indianola",
    "lng": -100.41984180326499,
    "lat": 40.23462929776125
  },
  {
    "name": "Indianola",
    "lng": -95.77599826756332,
    "lat": 35.16338448339153
  },
  {
    "name": "Indianola",
    "lng": -93.56512013597816,
    "lat": 41.36294367441623
  },
  {
    "name": "Indianola",
    "lng": -90.64465792990701,
    "lat": 33.44918468850526
  },
  {
    "name": "Indianola",
    "lng": -87.74030211270188,
    "lat": 39.92707667444839
  },
  {
    "name": "Indio",
    "lng": -116.23958105898859,
    "lat": 33.73270514714808
  },
  {
    "name": "Indio",
    "lng": -116.22059785329343,
    "lat": 33.75360515159469
  },
  {
    "name": "Indio",
    "lng": -116.18374766815238,
    "lat": 33.70220488670826
  },
  {
    "name": "Industry",
    "lng": -118.00860308839417,
    "lat": 34.06158980346768
  },
  {
    "name": "Industry",
    "lng": -117.92609115161292,
    "lat": 34.01704217384096
  },
  {
    "name": "Industry",
    "lng": -90.60756371928012,
    "lat": 40.3291837462878
  },
  {
    "name": "Industry",
    "lng": -70.05036146300532,
    "lat": 44.75819123970017
  },
  {
    "name": "Inez",
    "lng": -82.53644837686481,
    "lat": 37.86706214199841
  },
  {
    "name": "Ingalls",
    "lng": -100.45314598679391,
    "lat": 37.82851623029439
  },
  {
    "name": "Ingalls",
    "lng": -85.80374553021291,
    "lat": 39.95708038718107
  },
  {
    "name": "Ingleside",
    "lng": -97.20798952341082,
    "lat": 27.869414346990578
  },
  {
    "name": "Ingleside on the Bay",
    "lng": -97.221683417641,
    "lat": 27.829674976609834
  },
  {
    "name": "Inglewood",
    "lng": -118.37374068784185,
    "lat": 33.981299665549024
  },
  {
    "name": "Inglewood",
    "lng": -118.34431506355584,
    "lat": 33.9564181219366
  },
  {
    "name": "Inglewood",
    "lng": -96.502054404961,
    "lat": 41.41595871688521
  },
  {
    "name": "Inglis",
    "lng": -82.65925148602548,
    "lat": 29.032726429441574
  },
  {
    "name": "Ingram",
    "lng": -99.23815483762085,
    "lat": 30.077255379946997
  },
  {
    "name": "Ingram",
    "lng": -90.8137091219049,
    "lat": 45.50561462671209
  },
  {
    "name": "Inkom",
    "lng": -112.2460799815413,
    "lat": 42.79725624316926
  },
  {
    "name": "Inkster",
    "lng": -97.64416177630069,
    "lat": 48.15147458147822
  },
  {
    "name": "Inkster",
    "lng": -83.31482443776149,
    "lat": 42.293486992934625
  },
  {
    "name": "Inman",
    "lng": -98.52982671830289,
    "lat": 42.38193673392622
  },
  {
    "name": "Inman",
    "lng": -97.77277549751291,
    "lat": 38.230406351644895
  },
  {
    "name": "Inman",
    "lng": -82.09583658147157,
    "lat": 35.038312289134
  },
  {
    "name": "Inman",
    "lng": -82.09216414247555,
    "lat": 35.04784348363038
  },
  {
    "name": "Inman",
    "lng": -82.07419424951826,
    "lat": 35.03839197234303
  },
  {
    "name": "Innsbrook",
    "lng": -91.05520704559673,
    "lat": 38.76289427279317
  },
  {
    "name": "Inola",
    "lng": -95.53837873609035,
    "lat": 36.13111321377504
  },
  {
    "name": "Interior",
    "lng": -101.98364402154068,
    "lat": 43.72681044928778
  },
  {
    "name": "Interlachen",
    "lng": -81.89650150958546,
    "lat": 29.62027870553854
  },
  {
    "name": "Interlachen",
    "lng": -81.87360407519039,
    "lat": 29.607969713058743
  },
  {
    "name": "Interlaken",
    "lng": -111.47527024584295,
    "lat": 40.54114117092203
  },
  {
    "name": "Interlaken",
    "lng": -76.72444609671629,
    "lat": 42.61853139940449
  },
  {
    "name": "International Falls",
    "lng": -93.44341105065439,
    "lat": 48.58324941131541
  },
  {
    "name": "International Falls",
    "lng": -93.40777695558018,
    "lat": 48.58811583084552
  },
  {
    "name": "Inver Grove Heights",
    "lng": -93.05977012092985,
    "lat": 44.82468756151423
  },
  {
    "name": "Inverness",
    "lng": -90.59119972821428,
    "lat": 33.35422318830281
  },
  {
    "name": "Inverness",
    "lng": -88.10202199637898,
    "lat": 42.11520473785793
  },
  {
    "name": "Inverness",
    "lng": -82.3436903619444,
    "lat": 28.839647414409242
  },
  {
    "name": "Inwood",
    "lng": -96.43437653184236,
    "lat": 43.30921646653069
  },
  {
    "name": "Inyokern",
    "lng": -117.82134632401232,
    "lat": 35.65461754155118
  },
  {
    "name": "Iola",
    "lng": -95.41400173493294,
    "lat": 37.9239667258464
  },
  {
    "name": "Iola",
    "lng": -95.40051123511002,
    "lat": 37.92763911490705
  },
  {
    "name": "Iola",
    "lng": -89.12283741423035,
    "lat": 44.50902861693436
  },
  {
    "name": "Iola",
    "lng": -88.62783461021506,
    "lat": 38.83400516996632
  },
  {
    "name": "Iona",
    "lng": -111.93084282659918,
    "lat": 43.5258280593431
  },
  {
    "name": "Iona",
    "lng": -95.7831525486527,
    "lat": 43.91559967920104
  },
  {
    "name": "Iona",
    "lng": -81.96011121264569,
    "lat": 26.516045440513697
  },
  {
    "name": "Ione",
    "lng": -120.94132279975558,
    "lat": 38.361819173078906
  },
  {
    "name": "Ione",
    "lng": -117.42234826671955,
    "lat": 48.74024418897576
  },
  {
    "name": "Ionia",
    "lng": -93.32352531509024,
    "lat": 38.50371285651827
  },
  {
    "name": "Ionia",
    "lng": -92.4583569608393,
    "lat": 43.035866285721376
  },
  {
    "name": "Ionia",
    "lng": -85.0858396190116,
    "lat": 42.984319238423254
  },
  {
    "name": "Ionia",
    "lng": -85.07956112461841,
    "lat": 42.95861261577004
  },
  {
    "name": "Ionia",
    "lng": -85.0737112240057,
    "lat": 42.97969356619462
  },
  {
    "name": "Ionia",
    "lng": -85.07186928809543,
    "lat": 42.960959902996294
  },
  {
    "name": "Ionia",
    "lng": -85.0693111058986,
    "lat": 42.953506781610706
  },
  {
    "name": "Iota",
    "lng": -92.49561361480707,
    "lat": 30.32605508705726
  },
  {
    "name": "Iowa",
    "lng": -93.50217145493951,
    "lat": 42.09057276247485
  },
  {
    "name": "Iowa",
    "lng": -93.01299348070991,
    "lat": 30.239098703289216
  },
  {
    "name": "Iowa City",
    "lng": -91.53033292421027,
    "lat": 41.65589437388093
  },
  {
    "name": "Iowa Falls",
    "lng": -93.26634922998515,
    "lat": 42.51962237740249
  },
  {
    "name": "Iowa Park",
    "lng": -98.68203488041408,
    "lat": 33.96230591768361
  },
  {
    "name": "Ipava",
    "lng": -90.32339042114447,
    "lat": 40.35256907650717
  },
  {
    "name": "Ipswich",
    "lng": -99.0299958308316,
    "lat": 45.443459975061224
  },
  {
    "name": "Iraan",
    "lng": -101.90718212764455,
    "lat": 30.91509022874167
  },
  {
    "name": "Iraan",
    "lng": -101.8998643158265,
    "lat": 30.912841477245987
  },
  {
    "name": "Iredell",
    "lng": -97.87187678015376,
    "lat": 31.986470555884626
  },
  {
    "name": "Irena",
    "lng": -94.38986821364225,
    "lat": 40.53909278201327
  },
  {
    "name": "Irene",
    "lng": -97.15786855510879,
    "lat": 43.08390743323894
  },
  {
    "name": "Ireton",
    "lng": -96.32160373331065,
    "lat": 42.9748953103162
  },
  {
    "name": "Irmo",
    "lng": -81.18880279486731,
    "lat": 34.094920791594085
  },
  {
    "name": "Iron City",
    "lng": -87.58404539088745,
    "lat": 35.02535638173216
  },
  {
    "name": "Iron City",
    "lng": -84.81337566068083,
    "lat": 31.013656890703654
  },
  {
    "name": "Iron Gate",
    "lng": -79.7903811720996,
    "lat": 37.798573665893045
  },
  {
    "name": "Iron Horse",
    "lng": -120.48519103011571,
    "lat": 39.77874080878974
  },
  {
    "name": "Iron Junction",
    "lng": -92.60839161531347,
    "lat": 47.418977295845934
  },
  {
    "name": "Iron Mountain",
    "lng": -88.06160039111145,
    "lat": 45.82770209026878
  },
  {
    "name": "Iron Mountain Lake",
    "lng": -90.61846974782364,
    "lat": 37.68541905951135
  },
  {
    "name": "Iron Ridge",
    "lng": -88.53202329761372,
    "lat": 43.39818871676565
  },
  {
    "name": "Iron River",
    "lng": -88.63920455383986,
    "lat": 46.09742713135887
  },
  {
    "name": "Irondale",
    "lng": -90.67184194259167,
    "lat": 37.83556716408853
  },
  {
    "name": "Irondale",
    "lng": -86.69183950341846,
    "lat": 33.49521209799409
  },
  {
    "name": "Irondale",
    "lng": -86.67829612197214,
    "lat": 33.532866021405326
  },
  {
    "name": "Irondale",
    "lng": -86.66735201100771,
    "lat": 33.55197047760949
  },
  {
    "name": "Irondale",
    "lng": -86.64139262643661,
    "lat": 33.563176358749466
  },
  {
    "name": "Irondale",
    "lng": -80.72595242098909,
    "lat": 40.57280151059591
  },
  {
    "name": "Ironton",
    "lng": -93.99786902960895,
    "lat": 46.48169341480138
  },
  {
    "name": "Ironton",
    "lng": -90.63805955599962,
    "lat": 37.598374771190635
  },
  {
    "name": "Ironton",
    "lng": -90.1436663202174,
    "lat": 43.54535112548379
  },
  {
    "name": "Ironton",
    "lng": -82.67760446834411,
    "lat": 38.53183717906212
  },
  {
    "name": "Ironwood",
    "lng": -90.15043402817234,
    "lat": 46.4522225473902
  },
  {
    "name": "Iroquois",
    "lng": -97.84935030118997,
    "lat": 44.36660672298188
  },
  {
    "name": "Iroquois",
    "lng": -87.58463654185914,
    "lat": 40.82867367721432
  },
  {
    "name": "Irvine",
    "lng": -117.77397140613401,
    "lat": 33.67713378300647
  },
  {
    "name": "Irvine",
    "lng": -83.96831804753302,
    "lat": 37.698171688318254
  },
  {
    "name": "Irving",
    "lng": -96.97026336826,
    "lat": 32.858097184471944
  },
  {
    "name": "Irving",
    "lng": -89.4055947976077,
    "lat": 39.20539959565351
  },
  {
    "name": "Irvington",
    "lng": -89.16011638231409,
    "lat": 38.43860703920374
  },
  {
    "name": "Irvington",
    "lng": -86.28390038039255,
    "lat": 37.87869828491872
  },
  {
    "name": "Irvington",
    "lng": -76.4205808789462,
    "lat": 37.66200798931598
  },
  {
    "name": "Irvington",
    "lng": -73.86566583899406,
    "lat": 41.03462687756586
  },
  {
    "name": "Irwin",
    "lng": -111.27258488293147,
    "lat": 43.396883501635
  },
  {
    "name": "Irwin",
    "lng": -111.23043114805424,
    "lat": 43.36206251025957
  },
  {
    "name": "Irwin",
    "lng": -95.20704971136438,
    "lat": 41.79017283259049
  },
  {
    "name": "Irwin",
    "lng": -87.98408866323784,
    "lat": 41.053666428456076
  },
  {
    "name": "Irwindale",
    "lng": -117.96347790016297,
    "lat": 34.1121632317228
  },
  {
    "name": "Irwinton",
    "lng": -83.17369614903949,
    "lat": 32.81075872592591
  },
  {
    "name": "Isabel",
    "lng": -101.43247530670817,
    "lat": 45.39383513796564
  },
  {
    "name": "Isabel",
    "lng": -98.55152178597345,
    "lat": 37.46715606764165
  },
  {
    "name": "Isanti",
    "lng": -93.24102830743801,
    "lat": 45.49252960006104
  },
  {
    "name": "Ishpeming",
    "lng": -87.6658160858463,
    "lat": 46.486149371124064
  },
  {
    "name": "Islamorada",
    "lng": -80.71810876976419,
    "lat": 24.865743906438826
  },
  {
    "name": "Islamorada",
    "lng": -80.68603628213842,
    "lat": 24.88455467245506
  },
  {
    "name": "Islamorada",
    "lng": -80.6731901959935,
    "lat": 24.8910955913165
  },
  {
    "name": "Islamorada",
    "lng": -80.66117730426238,
    "lat": 24.894934364297097
  },
  {
    "name": "Islamorada",
    "lng": -80.63450598115735,
    "lat": 24.920386105443317
  },
  {
    "name": "Islamorada",
    "lng": -80.59938761790754,
    "lat": 24.950206215749343
  },
  {
    "name": "Islamorada",
    "lng": -80.55683852237647,
    "lat": 24.97848533450449
  },
  {
    "name": "Island",
    "lng": -87.14742364874557,
    "lat": 37.4430076258718
  },
  {
    "name": "Island Falls",
    "lng": -68.2330145324544,
    "lat": 45.990599507378775
  },
  {
    "name": "Island Lake",
    "lng": -88.19985847140501,
    "lat": 42.278358056112246
  },
  {
    "name": "Island Park",
    "lng": -111.3589694964927,
    "lat": 44.528164641035815
  },
  {
    "name": "Island Park",
    "lng": -73.65532299025685,
    "lat": 40.60531696435584
  },
  {
    "name": "Islandia",
    "lng": -73.17870740953363,
    "lat": 40.80567437172356
  },
  {
    "name": "Isle",
    "lng": -93.47648370810755,
    "lat": 46.13161025061838
  },
  {
    "name": "Isle",
    "lng": -93.46294627061968,
    "lat": 46.1541238085034
  },
  {
    "name": "Isle",
    "lng": -93.45970218923175,
    "lat": 46.139832317820286
  },
  {
    "name": "Isle au Haut",
    "lng": -68.61433921140208,
    "lat": 44.039966928989145
  },
  {
    "name": "Isle of Palms",
    "lng": -79.74850767856945,
    "lat": 32.783850908403444
  },
  {
    "name": "Islesboro",
    "lng": -68.91813093672407,
    "lat": 44.29786508444016
  },
  {
    "name": "Isleton",
    "lng": -121.60722934662354,
    "lat": 38.16234205811739
  },
  {
    "name": "Isleton",
    "lng": -121.5925806637914,
    "lat": 38.15797309341342
  },
  {
    "name": "Ismay",
    "lng": -104.79312732003544,
    "lat": 46.500054841919976
  },
  {
    "name": "Isola",
    "lng": -90.5923418055991,
    "lat": 33.26340453705986
  },
  {
    "name": "Issaquah",
    "lng": -122.04445524914068,
    "lat": 47.542019181379
  },
  {
    "name": "Italy",
    "lng": -96.88661107882254,
    "lat": 32.185732348715185
  },
  {
    "name": "Italy",
    "lng": -96.8853273594557,
    "lat": 32.172723408920056
  },
  {
    "name": "Italy",
    "lng": -96.87336554923078,
    "lat": 32.17277004038335
  },
  {
    "name": "Itasca",
    "lng": -97.14776943409423,
    "lat": 32.158550320349995
  },
  {
    "name": "Itasca",
    "lng": -88.01831080673509,
    "lat": 41.97729186635207
  },
  {
    "name": "Itasca",
    "lng": -88.00453238736786,
    "lat": 41.95709797789608
  },
  {
    "name": "Ithaca",
    "lng": -96.53949471632642,
    "lat": 41.15933895260917
  },
  {
    "name": "Ithaca",
    "lng": -84.60006565248007,
    "lat": 43.292063162500966
  },
  {
    "name": "Ithaca",
    "lng": -84.55328413593887,
    "lat": 39.93774805229931
  },
  {
    "name": "Ithaca",
    "lng": -76.5031750158748,
    "lat": 42.44413623277774
  },
  {
    "name": "Itta Bena",
    "lng": -90.32560822451745,
    "lat": 33.50030080261595
  },
  {
    "name": "Iuka",
    "lng": -98.7323914396916,
    "lat": 37.7295127145855
  },
  {
    "name": "Iuka",
    "lng": -88.78808824190402,
    "lat": 38.618129717870715
  },
  {
    "name": "Iuka",
    "lng": -88.19780675050171,
    "lat": 34.80773192486973
  },
  {
    "name": "Iva",
    "lng": -82.66358167521435,
    "lat": 34.307113769586515
  },
  {
    "name": "Ivanhoe",
    "lng": -96.26154132047013,
    "lat": 44.460376973762614
  },
  {
    "name": "Ivanhoe",
    "lng": -96.25095557442117,
    "lat": 44.46558410171785
  },
  {
    "name": "Ivanhoe",
    "lng": -94.41538148459365,
    "lat": 30.679987044252286
  },
  {
    "name": "Ivesdale",
    "lng": -88.45580206211578,
    "lat": 39.94581012165795
  },
  {
    "name": "Ivey",
    "lng": -83.298266945627,
    "lat": 32.90921636622598
  },
  {
    "name": "Ivins",
    "lng": -113.68093585519307,
    "lat": 37.17428352694573
  },
  {
    "name": "Ivor",
    "lng": -76.8977510290411,
    "lat": 36.905148438157624
  },
  {
    "name": "Jacinto City",
    "lng": -95.24088413222816,
    "lat": 29.766351982794326
  },
  {
    "name": "Jackman",
    "lng": -70.19107644637857,
    "lat": 45.60467424419936
  },
  {
    "name": "Jacksboro",
    "lng": -98.15883584831248,
    "lat": 33.2235472687453
  },
  {
    "name": "Jacksboro",
    "lng": -84.19273791861511,
    "lat": 36.335206110453456
  },
  {
    "name": "Jackson",
    "lng": -120.77241449017467,
    "lat": 38.34841881151411
  },
  {
    "name": "Jackson",
    "lng": -110.77436965357683,
    "lat": 43.472110496742374
  },
  {
    "name": "Jackson",
    "lng": -96.57867212646724,
    "lat": 42.45068553427316
  },
  {
    "name": "Jackson",
    "lng": -95.0046258201617,
    "lat": 43.61678450050178
  },
  {
    "name": "Jackson",
    "lng": -94.98917510505875,
    "lat": 43.625748725398424
  },
  {
    "name": "Jackson",
    "lng": -94.97308504299325,
    "lat": 43.62572440529423
  },
  {
    "name": "Jackson",
    "lng": -91.20801064849098,
    "lat": 30.834655265732902
  },
  {
    "name": "Jackson",
    "lng": -90.21539773190808,
    "lat": 32.31325932176445
  },
  {
    "name": "Jackson",
    "lng": -90.07601542166586,
    "lat": 32.31185788378517
  },
  {
    "name": "Jackson",
    "lng": -89.67614381433467,
    "lat": 37.36130372577779
  },
  {
    "name": "Jackson",
    "lng": -89.65427613391408,
    "lat": 37.41103371563914
  },
  {
    "name": "Jackson",
    "lng": -89.65264775239449,
    "lat": 37.3793193028665
  },
  {
    "name": "Jackson",
    "lng": -88.83095607965612,
    "lat": 35.648248463943546
  },
  {
    "name": "Jackson",
    "lng": -88.16708650693853,
    "lat": 43.32426456477149
  },
  {
    "name": "Jackson",
    "lng": -88.15006677543484,
    "lat": 43.33336138874983
  },
  {
    "name": "Jackson",
    "lng": -87.89011161673056,
    "lat": 31.532511464792606
  },
  {
    "name": "Jackson",
    "lng": -84.40380052061518,
    "lat": 42.243078248250086
  },
  {
    "name": "Jackson",
    "lng": -83.96797816560807,
    "lat": 33.29126314408495
  },
  {
    "name": "Jackson",
    "lng": -83.37988026837279,
    "lat": 37.558722782180816
  },
  {
    "name": "Jackson",
    "lng": -82.65286881669194,
    "lat": 39.04824338489239
  },
  {
    "name": "Jackson",
    "lng": -82.63861980014006,
    "lat": 39.03052893614646
  },
  {
    "name": "Jackson",
    "lng": -82.62454204620335,
    "lat": 39.01563040316696
  },
  {
    "name": "Jackson",
    "lng": -82.61616547092217,
    "lat": 39.05559282213174
  },
  {
    "name": "Jackson",
    "lng": -82.61520396458295,
    "lat": 39.05350915194943
  },
  {
    "name": "Jackson",
    "lng": -82.6094603854742,
    "lat": 39.05087359102955
  },
  {
    "name": "Jackson",
    "lng": -82.6023385920869,
    "lat": 39.039926701833
  },
  {
    "name": "Jackson",
    "lng": -81.79266674244049,
    "lat": 33.328435138845045
  },
  {
    "name": "Jackson",
    "lng": -77.41934065893436,
    "lat": 36.39009584574673
  },
  {
    "name": "Jackson",
    "lng": -69.14507858721124,
    "lat": 44.6134375529189
  },
  {
    "name": "Jackson Center",
    "lng": -84.04051044772262,
    "lat": 40.43820449161396
  },
  {
    "name": "Jackson Junction",
    "lng": -92.04623205655918,
    "lat": 43.10134656951693
  },
  {
    "name": "Jacksonburg",
    "lng": -84.50331522174376,
    "lat": 39.53838493038818
  },
  {
    "name": "Jacksonport",
    "lng": -91.30789370943864,
    "lat": 35.642078419878686
  },
  {
    "name": "Jacksons' Gap",
    "lng": -85.81497543742223,
    "lat": 32.88283714459288
  },
  {
    "name": "Jacksonville",
    "lng": -95.26188261404917,
    "lat": 31.964518866343706
  },
  {
    "name": "Jacksonville",
    "lng": -92.47306706257238,
    "lat": 39.5877468312729
  },
  {
    "name": "Jacksonville",
    "lng": -92.13037649032673,
    "lat": 34.88074625174093
  },
  {
    "name": "Jacksonville",
    "lng": -92.07787266005899,
    "lat": 34.868931207747366
  },
  {
    "name": "Jacksonville",
    "lng": -90.23213181316548,
    "lat": 39.72918531537127
  },
  {
    "name": "Jacksonville",
    "lng": -85.78293659657135,
    "lat": 33.827421514754114
  },
  {
    "name": "Jacksonville",
    "lng": -85.75402654609064,
    "lat": 33.808130219081754
  },
  {
    "name": "Jacksonville",
    "lng": -82.97899328337151,
    "lat": 31.812596986557303
  },
  {
    "name": "Jacksonville",
    "lng": -82.0796291377411,
    "lat": 39.476054000512306
  },
  {
    "name": "Jacksonville",
    "lng": -81.65996987422292,
    "lat": 30.337286368720306
  },
  {
    "name": "Jacksonville",
    "lng": -77.39530989063871,
    "lat": 34.73211071572948
  },
  {
    "name": "Jacksonville",
    "lng": -77.35338059270036,
    "lat": 34.77215817397936
  },
  {
    "name": "Jacksonville",
    "lng": -77.32262816620671,
    "lat": 34.71777829038717
  },
  {
    "name": "Jacksonville",
    "lng": -77.32135509276307,
    "lat": 34.72097329682902
  },
  {
    "name": "Jacksonville",
    "lng": -77.31833672177578,
    "lat": 34.72189343339455
  },
  {
    "name": "Jacobus",
    "lng": -76.71212673298926,
    "lat": 39.882457497027374
  },
  {
    "name": "Jakin",
    "lng": -84.98231869160641,
    "lat": 31.090033392641324
  },
  {
    "name": "Jal",
    "lng": -103.18921865073604,
    "lat": 32.115498359285425
  },
  {
    "name": "Jamaica",
    "lng": -94.30705371127503,
    "lat": 41.84536453518477
  },
  {
    "name": "Jamaica Beach",
    "lng": -94.98002592825497,
    "lat": 29.19039666415603
  },
  {
    "name": "Jameson",
    "lng": -93.98780855956399,
    "lat": 40.00584874015702
  },
  {
    "name": "Jamesport",
    "lng": -93.80294860950087,
    "lat": 39.97498055735817
  },
  {
    "name": "Jamestown",
    "lng": -105.39160344347694,
    "lat": 40.11788554701049
  },
  {
    "name": "Jamestown",
    "lng": -98.6920507320504,
    "lat": 46.90671942740774
  },
  {
    "name": "Jamestown",
    "lng": -97.86125216321405,
    "lat": 39.599474021332036
  },
  {
    "name": "Jamestown",
    "lng": -93.20642891934259,
    "lat": 32.339669544415685
  },
  {
    "name": "Jamestown",
    "lng": -92.48013771918934,
    "lat": 38.76669907226597
  },
  {
    "name": "Jamestown",
    "lng": -86.62602642069442,
    "lat": 39.92862938892204
  },
  {
    "name": "Jamestown",
    "lng": -85.07033018910838,
    "lat": 36.99282805491687
  },
  {
    "name": "Jamestown",
    "lng": -84.93403178241364,
    "lat": 36.43187893576586
  },
  {
    "name": "Jamestown",
    "lng": -83.74287754654465,
    "lat": 39.65992687223096
  },
  {
    "name": "Jamestown",
    "lng": -79.95143546192135,
    "lat": 36.01177563258589
  },
  {
    "name": "Jamestown",
    "lng": -79.93314845219166,
    "lat": 35.996744453289566
  },
  {
    "name": "Jamestown",
    "lng": -79.92206835577402,
    "lat": 36.02105625011413
  },
  {
    "name": "Jamestown",
    "lng": -79.9082724754735,
    "lat": 36.00183924678605
  },
  {
    "name": "Jamestown",
    "lng": -79.90582946398887,
    "lat": 36.00085046104605
  },
  {
    "name": "Jamestown",
    "lng": -79.89915378831755,
    "lat": 36.01406935677072
  },
  {
    "name": "Jamestown",
    "lng": -79.69516061638667,
    "lat": 33.285711705042054
  },
  {
    "name": "Jamestown",
    "lng": -79.23666170402807,
    "lat": 42.097491227841964
  },
  {
    "name": "Jamestown",
    "lng": -71.40059048211663,
    "lat": 41.50320695334534
  },
  {
    "name": "Jamestown",
    "lng": -71.37723199965421,
    "lat": 41.51536205745443
  },
  {
    "name": "Jamestown",
    "lng": -71.37212125345899,
    "lat": 41.472608922369275
  },
  {
    "name": "Jamestown",
    "lng": -71.36149095153024,
    "lat": 41.47700764646851
  },
  {
    "name": "Jamestown",
    "lng": -71.35798974334391,
    "lat": 41.483579978004784
  },
  {
    "name": "Jamestown",
    "lng": -71.3568920425867,
    "lat": 41.48272788725094
  },
  {
    "name": "Jamestown",
    "lng": -71.35501109093047,
    "lat": 41.48241086801825
  },
  {
    "name": "Jamestown",
    "lng": -71.35475385662798,
    "lat": 41.47897766884273
  },
  {
    "name": "Jamestown",
    "lng": -71.35423186921788,
    "lat": 41.48151922908029
  },
  {
    "name": "Jamestown",
    "lng": -71.35422679614982,
    "lat": 41.48194845678465
  },
  {
    "name": "Jamestown",
    "lng": -71.35411407918104,
    "lat": 41.482974983609
  },
  {
    "name": "Jamestown",
    "lng": -71.35336955372742,
    "lat": 41.4834561229307
  },
  {
    "name": "Jamestown",
    "lng": -71.35312536952631,
    "lat": 41.482980173087256
  },
  {
    "name": "Jamestown",
    "lng": -71.35308618584327,
    "lat": 41.4822069452868
  },
  {
    "name": "Jamestown",
    "lng": -71.34443672049967,
    "lat": 41.53347580928279
  },
  {
    "name": "Jamesville",
    "lng": -76.9007346438705,
    "lat": 35.81204997775921
  },
  {
    "name": "Jane",
    "lng": -94.30870724210895,
    "lat": 36.54066015678191
  },
  {
    "name": "Jane Lew",
    "lng": -80.40849849328197,
    "lat": 39.11004402213787
  },
  {
    "name": "Janesville",
    "lng": -93.72501315032488,
    "lat": 44.12888086999969
  },
  {
    "name": "Janesville",
    "lng": -93.70924097729743,
    "lat": 44.11999473222668
  },
  {
    "name": "Janesville",
    "lng": -92.46263111920965,
    "lat": 42.646081045925705
  },
  {
    "name": "Janesville",
    "lng": -89.01191652958708,
    "lat": 42.690355639160735
  },
  {
    "name": "Jansen",
    "lng": -97.0833908099249,
    "lat": 40.18612790764352
  },
  {
    "name": "Jarratt",
    "lng": -77.46891061321621,
    "lat": 36.81599005009678
  },
  {
    "name": "Jarrell",
    "lng": -97.61634119354963,
    "lat": 30.83997452380577
  },
  {
    "name": "Jarrell",
    "lng": -97.61496616790949,
    "lat": 30.808399188975823
  },
  {
    "name": "Jasonville",
    "lng": -87.19940553732869,
    "lat": 39.161976137960316
  },
  {
    "name": "Jasper",
    "lng": -96.39948111751434,
    "lat": 43.850213943100066
  },
  {
    "name": "Jasper",
    "lng": -94.30223580996454,
    "lat": 37.33537331936926
  },
  {
    "name": "Jasper",
    "lng": -93.99474828689802,
    "lat": 30.922056781388857
  },
  {
    "name": "Jasper",
    "lng": -93.19485560003503,
    "lat": 36.01618652327969
  },
  {
    "name": "Jasper",
    "lng": -93.1867343451433,
    "lat": 36.015691271816976
  },
  {
    "name": "Jasper",
    "lng": -93.18588078392611,
    "lat": 36.009611803128756
  },
  {
    "name": "Jasper",
    "lng": -87.27346470273089,
    "lat": 33.901591574544156
  },
  {
    "name": "Jasper",
    "lng": -87.27223849167922,
    "lat": 33.85160743514761
  },
  {
    "name": "Jasper",
    "lng": -87.26410518478733,
    "lat": 33.88751442594351
  },
  {
    "name": "Jasper",
    "lng": -86.94040901502753,
    "lat": 38.39343002786006
  },
  {
    "name": "Jasper",
    "lng": -85.62439554271113,
    "lat": 35.06737571076359
  },
  {
    "name": "Jasper",
    "lng": -84.44711874704217,
    "lat": 34.455779179703015
  },
  {
    "name": "Jasper",
    "lng": -84.4322506004837,
    "lat": 34.46162593875087
  },
  {
    "name": "Jasper",
    "lng": -82.9737933333222,
    "lat": 30.49849938362332
  },
  {
    "name": "Jasper",
    "lng": -82.95274485343933,
    "lat": 30.520387065380298
  },
  {
    "name": "Jasper",
    "lng": -82.94118443804656,
    "lat": 30.5262157546412
  },
  {
    "name": "Jasper",
    "lng": -82.93196308547795,
    "lat": 30.446216605980588
  },
  {
    "name": "Java",
    "lng": -99.88387645910491,
    "lat": 45.50381880905025
  },
  {
    "name": "Jay",
    "lng": -94.79653973526294,
    "lat": 36.427011426576975
  },
  {
    "name": "Jay",
    "lng": -70.20937670169752,
    "lat": 44.53063634393835
  },
  {
    "name": "Jean Lafitte",
    "lng": -90.10344891286181,
    "lat": 29.74952084692556
  },
  {
    "name": "Jeanerette",
    "lng": -91.67581055362507,
    "lat": 29.915796658790068
  },
  {
    "name": "Jeannette",
    "lng": -79.61390578318277,
    "lat": 40.32775850805752
  },
  {
    "name": "Jeffers",
    "lng": -95.19532039965611,
    "lat": 44.055904128128546
  },
  {
    "name": "Jefferson",
    "lng": -97.79089444043791,
    "lat": 36.720351552495856
  },
  {
    "name": "Jefferson",
    "lng": -96.56189591671763,
    "lat": 42.603676492632076
  },
  {
    "name": "Jefferson",
    "lng": -94.37926602406405,
    "lat": 42.01658230965559
  },
  {
    "name": "Jefferson",
    "lng": -94.3511547478507,
    "lat": 32.763271652524935
  },
  {
    "name": "Jefferson",
    "lng": -88.80702964797624,
    "lat": 43.005330161062034
  },
  {
    "name": "Jefferson",
    "lng": -83.60216281474304,
    "lat": 34.13646269550709
  },
  {
    "name": "Jefferson",
    "lng": -83.58299339600646,
    "lat": 34.100495948614146
  },
  {
    "name": "Jefferson",
    "lng": -83.58255004210615,
    "lat": 34.10183475882143
  },
  {
    "name": "Jefferson",
    "lng": -83.58236733349847,
    "lat": 34.10236115533715
  },
  {
    "name": "Jefferson",
    "lng": -83.58214169084981,
    "lat": 34.10300889394169
  },
  {
    "name": "Jefferson",
    "lng": -83.58195245657399,
    "lat": 34.10358306798742
  },
  {
    "name": "Jefferson",
    "lng": -83.5805823984878,
    "lat": 34.1007168096098
  },
  {
    "name": "Jefferson",
    "lng": -83.55412776042338,
    "lat": 34.13157378453
  },
  {
    "name": "Jefferson",
    "lng": -81.4889900546957,
    "lat": 36.419984005881055
  },
  {
    "name": "Jefferson",
    "lng": -81.47019763578089,
    "lat": 36.4209436932394
  },
  {
    "name": "Jefferson",
    "lng": -81.450706612931,
    "lat": 36.417691711710184
  },
  {
    "name": "Jefferson",
    "lng": -81.44767359495124,
    "lat": 36.41868800370474
  },
  {
    "name": "Jefferson",
    "lng": -81.42762667227343,
    "lat": 36.417455696879436
  },
  {
    "name": "Jefferson",
    "lng": -81.42364273838206,
    "lat": 36.405659427809
  },
  {
    "name": "Jefferson",
    "lng": -80.76885261375742,
    "lat": 41.738555151273935
  },
  {
    "name": "Jefferson",
    "lng": -80.38663239986748,
    "lat": 34.65121180941513
  },
  {
    "name": "Jefferson",
    "lng": -76.84107658157956,
    "lat": 39.81568750986282
  },
  {
    "name": "Jefferson",
    "lng": -69.5084961513204,
    "lat": 44.184833437409324
  },
  {
    "name": "Jefferson City",
    "lng": -92.17473481842379,
    "lat": 38.567759074581346
  },
  {
    "name": "Jefferson City",
    "lng": -83.4934265870232,
    "lat": 36.10084766070496
  },
  {
    "name": "Jefferson City",
    "lng": -83.48147069226457,
    "lat": 36.12075054432097
  },
  {
    "name": "Jeffersontown",
    "lng": -85.56988319698227,
    "lat": 38.205287111558064
  },
  {
    "name": "Jeffersonville",
    "lng": -88.40397125786188,
    "lat": 38.4425096578519
  },
  {
    "name": "Jeffersonville",
    "lng": -85.70235558507414,
    "lat": 38.331518296895474
  },
  {
    "name": "Jeffersonville",
    "lng": -83.8306740141402,
    "lat": 37.96479390787374
  },
  {
    "name": "Jeffersonville",
    "lng": -83.55634574519571,
    "lat": 39.652175396442225
  },
  {
    "name": "Jeffersonville",
    "lng": -83.34332428783314,
    "lat": 32.686298911190185
  },
  {
    "name": "Jeffersonville",
    "lng": -74.92990020852504,
    "lat": 41.77988746518111
  },
  {
    "name": "Jeffersonville",
    "lng": -72.82953726259028,
    "lat": 44.64296106815989
  },
  {
    "name": "Jeisyville",
    "lng": -89.40806943699022,
    "lat": 39.57920896379932
  },
  {
    "name": "Jellico",
    "lng": -84.12647492206007,
    "lat": 36.578555434122684
  },
  {
    "name": "Jemez Springs",
    "lng": -106.69073465333409,
    "lat": 35.77339559032808
  },
  {
    "name": "Jemison",
    "lng": -86.73377797364378,
    "lat": 32.968391273200815
  },
  {
    "name": "Jemison",
    "lng": -86.73377797364353,
    "lat": 32.968391273200815
  },
  {
    "name": "Jemison",
    "lng": -86.67931946003705,
    "lat": 32.98327027781642
  },
  {
    "name": "Jemison",
    "lng": -86.67931946003704,
    "lat": 32.983270277816416
  },
  {
    "name": "Jena",
    "lng": -92.12985360482611,
    "lat": 31.691411514439167
  },
  {
    "name": "Jenera",
    "lng": -83.72668458555746,
    "lat": 40.90022359426983
  },
  {
    "name": "Jenkins",
    "lng": -94.32998859780609,
    "lat": 46.65288911295324
  },
  {
    "name": "Jenkins",
    "lng": -94.32140026632254,
    "lat": 46.62520168074126
  },
  {
    "name": "Jenkins",
    "lng": -82.63078661145401,
    "lat": 37.18260572490619
  },
  {
    "name": "Jenks",
    "lng": -95.97793566391643,
    "lat": 35.99872726114387
  },
  {
    "name": "Jenks",
    "lng": -95.92216400000002,
    "lat": 35.96190066639879
  },
  {
    "name": "Jennette",
    "lng": -90.40926856429222,
    "lat": 35.163898206149426
  },
  {
    "name": "Jennings",
    "lng": -100.2932045533273,
    "lat": 39.68052262267388
  },
  {
    "name": "Jennings",
    "lng": -96.56897662102004,
    "lat": 36.18229424579077
  },
  {
    "name": "Jennings",
    "lng": -92.65817712161514,
    "lat": 30.223267816298343
  },
  {
    "name": "Jennings",
    "lng": -90.26421315608195,
    "lat": 38.72309370943454
  },
  {
    "name": "Jensen Beach",
    "lng": -80.24206208778455,
    "lat": 27.2440879041283
  },
  {
    "name": "Jericho",
    "lng": -90.2304379746197,
    "lat": 35.287719082754606
  },
  {
    "name": "Jericho",
    "lng": -72.98771345867787,
    "lat": 44.5049620792431
  },
  {
    "name": "Jerico Springs",
    "lng": -94.0113634610458,
    "lat": 37.61957824166274
  },
  {
    "name": "Jerome",
    "lng": -114.51590954452516,
    "lat": 42.71834479169428
  },
  {
    "name": "Jerome",
    "lng": -112.10737497940323,
    "lat": 34.74692870446763
  },
  {
    "name": "Jerome",
    "lng": -91.46987698474769,
    "lat": 33.39861569039989
  },
  {
    "name": "Jerome",
    "lng": -89.67858828641997,
    "lat": 39.767715241467386
  },
  {
    "name": "Jerome",
    "lng": -89.66947851893563,
    "lat": 39.7709208241631
  },
  {
    "name": "Jeromesville",
    "lng": -82.19639981743907,
    "lat": 40.80374806739043
  },
  {
    "name": "Jerry City",
    "lng": -83.60230866940282,
    "lat": 41.25368675077865
  },
  {
    "name": "Jersey City",
    "lng": -74.06479777637918,
    "lat": 40.71164581139398
  },
  {
    "name": "Jersey Shore",
    "lng": -77.2667524143991,
    "lat": 41.20155442282462
  },
  {
    "name": "Jersey Village",
    "lng": -95.57261977030578,
    "lat": 29.89063981623641
  },
  {
    "name": "Jerseyville",
    "lng": -90.32745740052195,
    "lat": 39.11799828801455
  },
  {
    "name": "Jerusalem",
    "lng": -81.09591654437332,
    "lat": 39.85190415306474
  },
  {
    "name": "Jesup",
    "lng": -92.06586084628945,
    "lat": 42.474673749066575
  },
  {
    "name": "Jesup",
    "lng": -81.88975620762339,
    "lat": 31.599119715065353
  },
  {
    "name": "Jet",
    "lng": -98.18134153867032,
    "lat": 36.66663452381051
  },
  {
    "name": "Jetmore",
    "lng": -99.89375137329714,
    "lat": 38.083137966581425
  },
  {
    "name": "Jewell",
    "lng": -98.15222162534717,
    "lat": 39.67159448451971
  },
  {
    "name": "Jewell Junction",
    "lng": -93.63999984774901,
    "lat": 42.310549466099495
  },
  {
    "name": "Jewett",
    "lng": -96.14549654461557,
    "lat": 31.362638857618933
  },
  {
    "name": "Jewett",
    "lng": -88.24306991467584,
    "lat": 39.20770981827256
  },
  {
    "name": "Jewett",
    "lng": -81.00304707643537,
    "lat": 40.36805516519195
  },
  {
    "name": "Joaquin",
    "lng": -94.05045069528471,
    "lat": 31.965828725364183
  },
  {
    "name": "John H. Chafee Nature Preserve",
    "lng": -71.43172497781482,
    "lat": 41.540653278121574
  },
  {
    "name": "Johns Creek",
    "lng": -84.20266404827855,
    "lat": 34.03334040454675
  },
  {
    "name": "Johnsburg",
    "lng": -88.24746981212715,
    "lat": 42.3839803856248
  },
  {
    "name": "Johnson",
    "lng": -96.84175038418084,
    "lat": 35.40561309220208
  },
  {
    "name": "Johnson",
    "lng": -96.29256105574255,
    "lat": 45.5693319013335
  },
  {
    "name": "Johnson",
    "lng": -95.99897767972476,
    "lat": 40.41073086122173
  },
  {
    "name": "Johnson",
    "lng": -94.1756723437342,
    "lat": 36.13279726125251
  },
  {
    "name": "Johnson",
    "lng": -72.67849186001766,
    "lat": 44.63659600860377
  },
  {
    "name": "Johnson City",
    "lng": -101.74439799704437,
    "lat": 37.56974195909447
  },
  {
    "name": "Johnson City",
    "lng": -98.40618865281901,
    "lat": 30.274184436005225
  },
  {
    "name": "Johnson City",
    "lng": -82.42168060365398,
    "lat": 36.285867686690395
  },
  {
    "name": "Johnson City",
    "lng": -82.37921965750667,
    "lat": 36.33983352569783
  },
  {
    "name": "Johnson City",
    "lng": -75.9624036873747,
    "lat": 42.12294354256361
  },
  {
    "name": "Johnson Creek",
    "lng": -88.77021973530643,
    "lat": 43.08081579888212
  },
  {
    "name": "Johnsonville",
    "lng": -88.53823444052395,
    "lat": 38.52087742027097
  },
  {
    "name": "Johnsonville",
    "lng": -79.44433234914376,
    "lat": 33.81486730705914
  },
  {
    "name": "Johnston",
    "lng": -93.7234049209757,
    "lat": 41.690996985793184
  },
  {
    "name": "Johnston",
    "lng": -81.80488209160885,
    "lat": 33.83386198101566
  },
  {
    "name": "Johnston",
    "lng": -71.52001630330287,
    "lat": 41.827403609791254
  },
  {
    "name": "Johnston City",
    "lng": -88.92830424085201,
    "lat": 37.82109606099867
  },
  {
    "name": "Johnstown",
    "lng": -104.94831893688401,
    "lat": 40.367193694955404
  },
  {
    "name": "Johnstown",
    "lng": -104.94598362642189,
    "lat": 40.34787666981932
  },
  {
    "name": "Johnstown",
    "lng": -100.0562657516126,
    "lat": 42.57210525542142
  },
  {
    "name": "Johnstown",
    "lng": -82.6878854368981,
    "lat": 40.149916488283
  },
  {
    "name": "Joice",
    "lng": -93.45808007948438,
    "lat": 43.36471752468018
  },
  {
    "name": "Joiner",
    "lng": -90.14999449347859,
    "lat": 35.505510195114375
  },
  {
    "name": "Joliet",
    "lng": -108.97208673282205,
    "lat": 45.48445738898288
  },
  {
    "name": "Joliet",
    "lng": -88.28995342397613,
    "lat": 41.55503749039225
  },
  {
    "name": "Joliet",
    "lng": -88.22981559635154,
    "lat": 41.562167109904294
  },
  {
    "name": "Joliet",
    "lng": -88.21443518664393,
    "lat": 41.57336649957924
  },
  {
    "name": "Joliet",
    "lng": -88.18276772719005,
    "lat": 41.46199762046217
  },
  {
    "name": "Joliet",
    "lng": -88.17261051157946,
    "lat": 41.55856544039823
  },
  {
    "name": "Joliet",
    "lng": -88.16135176666667,
    "lat": 41.58070230115977
  },
  {
    "name": "Joliet",
    "lng": -88.15995562272442,
    "lat": 41.5678418877954
  },
  {
    "name": "Joliet",
    "lng": -88.15884513085064,
    "lat": 41.59185482405518
  },
  {
    "name": "Joliet",
    "lng": -88.14937197211808,
    "lat": 41.58731383509204
  },
  {
    "name": "Joliet",
    "lng": -88.13753636397074,
    "lat": 41.53217199686011
  },
  {
    "name": "Joliet",
    "lng": -88.12406617551834,
    "lat": 41.48888297283621
  },
  {
    "name": "Joliet",
    "lng": -88.12378662130858,
    "lat": 41.48798300290343
  },
  {
    "name": "Joliet",
    "lng": -88.10981980236977,
    "lat": 41.46736278292782
  },
  {
    "name": "Joliet",
    "lng": -88.10782355400679,
    "lat": 41.449581851553404
  },
  {
    "name": "Joliet",
    "lng": -88.09862634184456,
    "lat": 41.4641691442468
  },
  {
    "name": "Joliet",
    "lng": -88.095313059813,
    "lat": 41.503090972445065
  },
  {
    "name": "Joliet",
    "lng": -88.08727628438514,
    "lat": 41.494631701966384
  },
  {
    "name": "Joliet",
    "lng": -88.08629011258445,
    "lat": 41.49166605470349
  },
  {
    "name": "Joliet",
    "lng": -88.07757249668678,
    "lat": 41.50834663555574
  },
  {
    "name": "Joliet",
    "lng": -88.07670889253019,
    "lat": 41.511213207120036
  },
  {
    "name": "Joliet",
    "lng": -88.07571913043506,
    "lat": 41.512129183388744
  },
  {
    "name": "Joliet",
    "lng": -88.0742816282432,
    "lat": 41.510888768927124
  },
  {
    "name": "Joliet",
    "lng": -88.07385334277586,
    "lat": 41.509170245967624
  },
  {
    "name": "Joliet",
    "lng": -88.07210832578046,
    "lat": 41.50250644608293
  },
  {
    "name": "Joliet",
    "lng": -88.06891418009855,
    "lat": 41.499477558424985
  },
  {
    "name": "Joliet",
    "lng": -88.06772313839885,
    "lat": 41.49832695911353
  },
  {
    "name": "Joliet",
    "lng": -88.06547242728482,
    "lat": 41.501188595212845
  },
  {
    "name": "Joliet",
    "lng": -88.06373004152165,
    "lat": 41.4923606304968
  },
  {
    "name": "Joliet",
    "lng": -88.0626077200243,
    "lat": 41.509734704859895
  },
  {
    "name": "Joliet",
    "lng": -88.06112921566115,
    "lat": 41.512480441993105
  },
  {
    "name": "Joliet",
    "lng": -88.05913146666668,
    "lat": 41.512658133784605
  },
  {
    "name": "Joliet",
    "lng": -88.05890296057302,
    "lat": 41.50108757242652
  },
  {
    "name": "Joliet",
    "lng": -88.05683789713731,
    "lat": 41.514934309953965
  },
  {
    "name": "Joliet",
    "lng": -88.05544422571025,
    "lat": 41.540934646875755
  },
  {
    "name": "Joliet",
    "lng": -88.0547186836898,
    "lat": 41.5396722481412
  },
  {
    "name": "Joliet",
    "lng": -88.05453782136456,
    "lat": 41.51456182663225
  },
  {
    "name": "Joliet",
    "lng": -88.05274222630965,
    "lat": 41.54155203074106
  },
  {
    "name": "Joliet",
    "lng": -88.05079178432749,
    "lat": 41.51079389962956
  },
  {
    "name": "Joliet",
    "lng": -88.05024388184829,
    "lat": 41.533393526565355
  },
  {
    "name": "Joliet",
    "lng": -88.050071562896,
    "lat": 41.52937678196217
  },
  {
    "name": "Joliet",
    "lng": -88.05001967237057,
    "lat": 41.54111708160318
  },
  {
    "name": "Joliet",
    "lng": -88.04902425597591,
    "lat": 41.5414934095236
  },
  {
    "name": "Joliet",
    "lng": -88.04872607508469,
    "lat": 41.533246392016146
  },
  {
    "name": "Joliet",
    "lng": -88.04858487681776,
    "lat": 41.532695369730924
  },
  {
    "name": "Joliet",
    "lng": -88.04840510063724,
    "lat": 41.53480065492258
  },
  {
    "name": "Joliet",
    "lng": -88.04789309618884,
    "lat": 41.53308150745489
  },
  {
    "name": "Joliet",
    "lng": -88.047633781469,
    "lat": 41.53180585888414
  },
  {
    "name": "Joliet",
    "lng": -88.04734939961891,
    "lat": 41.53502400859479
  },
  {
    "name": "Joliet",
    "lng": -88.04686380969933,
    "lat": 41.531962652208975
  },
  {
    "name": "Joliet",
    "lng": -88.04651931371153,
    "lat": 41.53283162584466
  },
  {
    "name": "Joliet",
    "lng": -88.04635032750375,
    "lat": 41.529266504833906
  },
  {
    "name": "Joliet",
    "lng": -88.04544344248832,
    "lat": 41.5191730119275
  },
  {
    "name": "Joliet",
    "lng": -88.04507320199473,
    "lat": 41.53193947940581
  },
  {
    "name": "Joliet",
    "lng": -88.0449192550243,
    "lat": 41.515328883705585
  },
  {
    "name": "Joliet",
    "lng": -88.04374221501855,
    "lat": 41.53486832691646
  },
  {
    "name": "Joliet",
    "lng": -88.04317071838433,
    "lat": 41.53573540356163
  },
  {
    "name": "Joliet",
    "lng": -88.0430972277132,
    "lat": 41.53334467714942
  },
  {
    "name": "Joliet",
    "lng": -88.04300744259078,
    "lat": 41.50195383016581
  },
  {
    "name": "Joliet",
    "lng": -88.04258519274732,
    "lat": 41.53414805885647
  },
  {
    "name": "Joliet",
    "lng": -88.0423148298138,
    "lat": 41.53433361825995
  },
  {
    "name": "Joliet",
    "lng": -88.04217129999999,
    "lat": 41.48137336820739
  },
  {
    "name": "Joliet",
    "lng": -88.04213423983649,
    "lat": 41.535484056425595
  },
  {
    "name": "Joliet",
    "lng": -88.04207182383055,
    "lat": 41.53789357402852
  },
  {
    "name": "Joliet",
    "lng": -88.0419076281864,
    "lat": 41.53311447124779
  },
  {
    "name": "Joliet",
    "lng": -88.04045626268491,
    "lat": 41.53063222839366
  },
  {
    "name": "Joliet",
    "lng": -88.03838699569867,
    "lat": 41.53115613715817
  },
  {
    "name": "Joliet",
    "lng": -88.03597353870127,
    "lat": 41.460085552125435
  },
  {
    "name": "Joliet",
    "lng": -88.03416488549796,
    "lat": 41.54563530526528
  },
  {
    "name": "Joliet",
    "lng": -88.03339725882986,
    "lat": 41.50066260787576
  },
  {
    "name": "Joliet",
    "lng": -88.0315188884555,
    "lat": 41.446295686965
  },
  {
    "name": "Joliet",
    "lng": -88.02821804856003,
    "lat": 41.530654719461495
  },
  {
    "name": "Joliet",
    "lng": -88.02786266097206,
    "lat": 41.485040080299036
  },
  {
    "name": "Joliet",
    "lng": -88.02767170057541,
    "lat": 41.53566426959619
  },
  {
    "name": "Joliet",
    "lng": -88.02748376252853,
    "lat": 41.49020036586854
  },
  {
    "name": "Joliet",
    "lng": -88.02596085167616,
    "lat": 41.53604050149239
  },
  {
    "name": "Joliet",
    "lng": -88.01491659184981,
    "lat": 41.5270132145228
  },
  {
    "name": "Joliet",
    "lng": -88.01338466238256,
    "lat": 41.526726778275034
  },
  {
    "name": "Joliet",
    "lng": -88.01158112206964,
    "lat": 41.53892864144378
  },
  {
    "name": "Jolley",
    "lng": -94.72128334939605,
    "lat": 42.47948423819233
  },
  {
    "name": "Jolo",
    "lng": -81.81236152391641,
    "lat": 37.34221099141282
  },
  {
    "name": "Jones",
    "lng": -97.29008896091558,
    "lat": 35.56620385153183
  },
  {
    "name": "Jones Creek",
    "lng": -95.47163088549603,
    "lat": 28.97530739322208
  },
  {
    "name": "Jones Creek",
    "lng": -95.46771257196954,
    "lat": 28.987413549592556
  },
  {
    "name": "Jonesboro",
    "lng": -92.72730215853818,
    "lat": 32.207981620403594
  },
  {
    "name": "Jonesboro",
    "lng": -92.70809818995829,
    "lat": 32.237317630843755
  },
  {
    "name": "Jonesboro",
    "lng": -90.67916329281344,
    "lat": 35.82119090587926
  },
  {
    "name": "Jonesboro",
    "lng": -89.26583838599504,
    "lat": 37.45045658277498
  },
  {
    "name": "Jonesboro",
    "lng": -85.63032765990936,
    "lat": 40.47973574015818
  },
  {
    "name": "Jonesboro",
    "lng": -84.35413917296205,
    "lat": 33.52111536524193
  },
  {
    "name": "Jonesboro",
    "lng": -67.58665382036392,
    "lat": 44.6652371344774
  },
  {
    "name": "Jonesborough",
    "lng": -82.47514280591939,
    "lat": 36.29409252222584
  },
  {
    "name": "Jonesburg",
    "lng": -91.30569902080116,
    "lat": 38.854117524829825
  },
  {
    "name": "Jonesport",
    "lng": -67.52340859525911,
    "lat": 44.53911961876339
  },
  {
    "name": "Jonestown",
    "lng": -97.94904801554131,
    "lat": 30.47384825722552
  },
  {
    "name": "Jonestown",
    "lng": -97.94593711249372,
    "lat": 30.464339249555724
  },
  {
    "name": "Jonestown",
    "lng": -97.93751571854453,
    "lat": 30.47810574762122
  },
  {
    "name": "Jonestown",
    "lng": -97.9286243897737,
    "lat": 30.475620811054334
  },
  {
    "name": "Jonestown",
    "lng": -97.90732261787178,
    "lat": 30.49547731089416
  },
  {
    "name": "Jonestown",
    "lng": -90.45429951730026,
    "lat": 34.32219143858861
  },
  {
    "name": "Jonestown",
    "lng": -76.48073177431012,
    "lat": 40.41297875817987
  },
  {
    "name": "Jonesville",
    "lng": -91.82991793625668,
    "lat": 31.623854228679658
  },
  {
    "name": "Jonesville",
    "lng": -85.88786857568267,
    "lat": 39.059721431476696
  },
  {
    "name": "Jonesville",
    "lng": -84.66605975705107,
    "lat": 41.97839656244689
  },
  {
    "name": "Jonesville",
    "lng": -83.11530097563627,
    "lat": 36.6873357400662
  },
  {
    "name": "Jonesville",
    "lng": -81.68101143178977,
    "lat": 34.83477927965186
  },
  {
    "name": "Jonesville",
    "lng": -80.83696996412102,
    "lat": 36.23351470549879
  },
  {
    "name": "Joplin",
    "lng": -94.54790687477605,
    "lat": 37.039401807634455
  },
  {
    "name": "Joplin",
    "lng": -94.50549557379118,
    "lat": 37.081171498412274
  },
  {
    "name": "Joplin",
    "lng": -94.48117232194916,
    "lat": 37.03502932783347
  },
  {
    "name": "Joppa",
    "lng": -88.84466381251498,
    "lat": 37.207141272971974
  },
  {
    "name": "Jordan",
    "lng": -106.91018712889259,
    "lat": 47.32094354534505
  },
  {
    "name": "Jordan",
    "lng": -93.63516923677187,
    "lat": 44.664725417142044
  },
  {
    "name": "Jordan",
    "lng": -76.47286758020904,
    "lat": 43.067089422668396
  },
  {
    "name": "Joseph",
    "lng": -112.21982754816852,
    "lat": 38.62484366670738
  },
  {
    "name": "Josephville",
    "lng": -90.78713544555053,
    "lat": 38.82930163246256
  },
  {
    "name": "Josephville",
    "lng": -90.77605372011469,
    "lat": 38.825197798056735
  },
  {
    "name": "Joshua",
    "lng": -97.38508607560311,
    "lat": 32.45841974104099
  },
  {
    "name": "Jourdanton",
    "lng": -98.54104711703788,
    "lat": 28.91381452064595
  },
  {
    "name": "Joy",
    "lng": -90.8787917753724,
    "lat": 41.19692236925156
  },
  {
    "name": "Jud",
    "lng": -98.89775378736005,
    "lat": 46.52585799072495
  },
  {
    "name": "Judith Gap",
    "lng": -109.75536762683325,
    "lat": 46.67923454657059
  },
  {
    "name": "Judsonia",
    "lng": -91.64122971401682,
    "lat": 35.27701780992488
  },
  {
    "name": "Julesburg",
    "lng": -102.26257517477305,
    "lat": 40.98499317759501
  },
  {
    "name": "Juliaetta",
    "lng": -116.70960948934801,
    "lat": 46.57408902280915
  },
  {
    "name": "Julian",
    "lng": -116.58897390516358,
    "lat": 33.07360603195717
  },
  {
    "name": "Julian",
    "lng": -95.86736563470676,
    "lat": 40.51988525542882
  },
  {
    "name": "Jumpertown",
    "lng": -88.66199084265632,
    "lat": 34.70793726783176
  },
  {
    "name": "Junction",
    "lng": -112.2238548909031,
    "lat": 38.2357064004444
  },
  {
    "name": "Junction",
    "lng": -99.77339443171847,
    "lat": 30.510246151746404
  },
  {
    "name": "Junction",
    "lng": -99.77256394535158,
    "lat": 30.490659099101226
  },
  {
    "name": "Junction",
    "lng": -88.23812751889861,
    "lat": 37.723395411719935
  },
  {
    "name": "Junction City",
    "lng": -96.87836827796694,
    "lat": 39.08522504227588
  },
  {
    "name": "Junction City",
    "lng": -96.87434007601847,
    "lat": 39.00939422093509
  },
  {
    "name": "Junction City",
    "lng": -96.86235487701178,
    "lat": 39.0290040271071
  },
  {
    "name": "Junction City",
    "lng": -96.84471468847934,
    "lat": 39.050444879877
  },
  {
    "name": "Junction City",
    "lng": -96.84269906541968,
    "lat": 39.02500608891017
  },
  {
    "name": "Junction City",
    "lng": -96.80609376037182,
    "lat": 39.05398428204419
  },
  {
    "name": "Junction City",
    "lng": -92.72424882123373,
    "lat": 33.02242849632587
  },
  {
    "name": "Junction City",
    "lng": -92.72250177142776,
    "lat": 33.00722038417966
  },
  {
    "name": "Junction City",
    "lng": -92.71081838670942,
    "lat": 33.01545535061645
  },
  {
    "name": "Junction City",
    "lng": -90.29059404687305,
    "lat": 37.57334672083291
  },
  {
    "name": "Junction City",
    "lng": -89.76796225685585,
    "lat": 44.59292240702766
  },
  {
    "name": "Junction City",
    "lng": -89.12577929290389,
    "lat": 38.57732180495077
  },
  {
    "name": "Junction City",
    "lng": -84.78798041969557,
    "lat": 37.5854855615086
  },
  {
    "name": "Junction City",
    "lng": -82.29964993652186,
    "lat": 39.72163979580593
  },
  {
    "name": "June Lake",
    "lng": -119.0812186403528,
    "lat": 37.77961317246171
  },
  {
    "name": "Juneau",
    "lng": -88.7062626596052,
    "lat": 43.38075272926009
  },
  {
    "name": "Juneau",
    "lng": -88.70424521160601,
    "lat": 43.40636540852477
  },
  {
    "name": "Juneau",
    "lng": -88.70334919902336,
    "lat": 43.38104480829472
  },
  {
    "name": "Juniata",
    "lng": -98.51497823680076,
    "lat": 40.59774813251924
  },
  {
    "name": "Juniata",
    "lng": -98.51223909248866,
    "lat": 40.59740264124011
  },
  {
    "name": "Juniata",
    "lng": -98.506775482446,
    "lat": 40.59033614277287
  },
  {
    "name": "Juniata Terrace",
    "lng": -77.57835015602548,
    "lat": 40.585489767911184
  },
  {
    "name": "Junior",
    "lng": -79.95038819637969,
    "lat": 38.97829106786033
  },
  {
    "name": "Juno Beach",
    "lng": -80.05734065101453,
    "lat": 26.8751615082625
  },
  {
    "name": "Jupiter Island",
    "lng": -80.10991429881194,
    "lat": 27.044548658682107
  },
  {
    "name": "Justice",
    "lng": -87.83473189474873,
    "lat": 41.749472362924514
  },
  {
    "name": "Justin",
    "lng": -97.30115954283389,
    "lat": 33.08608715336366
  },
  {
    "name": "Kachina Village",
    "lng": -111.69264255185219,
    "lat": 35.09493032938416
  },
  {
    "name": "Kadoka",
    "lng": -101.50807171220144,
    "lat": 43.83249901142095
  },
  {
    "name": "Kahaluu-Keauhou CDP",
    "lng": -155.96088113250033,
    "lat": 19.57044843482548
  },
  {
    "name": "Kahoka",
    "lng": -91.71885284690144,
    "lat": 40.423734613245415
  },
  {
    "name": "Kahului",
    "lng": -156.4588344696753,
    "lat": 20.873104752699284
  },
  {
    "name": "Kaibito",
    "lng": -111.1071748651536,
    "lat": 36.592014278434895
  },
  {
    "name": "Kake",
    "lng": -133.9275214079103,
    "lat": 56.9715271961196
  },
  {
    "name": "Kaktovik",
    "lng": -143.6255541594844,
    "lat": 70.12255730728769
  },
  {
    "name": "Kalama",
    "lng": -122.83436952692976,
    "lat": 46.007954237460126
  },
  {
    "name": "Kalamazoo",
    "lng": -85.5881899718301,
    "lat": 42.27490168261968
  },
  {
    "name": "Kaleva",
    "lng": -86.01352470959915,
    "lat": 44.37226907981464
  },
  {
    "name": "Kalida",
    "lng": -84.19514548070435,
    "lat": 40.984986232365756
  },
  {
    "name": "Kalispell",
    "lng": -114.36729228038172,
    "lat": 48.213273506080284
  },
  {
    "name": "Kalispell",
    "lng": -114.36310514364453,
    "lat": 48.23310960971938
  },
  {
    "name": "Kalispell",
    "lng": -114.35879561335285,
    "lat": 48.209432872381086
  },
  {
    "name": "Kalispell",
    "lng": -114.35289815827971,
    "lat": 48.219942291524454
  },
  {
    "name": "Kalispell",
    "lng": -114.34965309684836,
    "lat": 48.24598531659831
  },
  {
    "name": "Kalispell",
    "lng": -114.34042935208204,
    "lat": 48.188837663792825
  },
  {
    "name": "Kalispell",
    "lng": -114.33915476824339,
    "lat": 48.27549423775162
  },
  {
    "name": "Kalispell",
    "lng": -114.33819476105921,
    "lat": 48.18324780452773
  },
  {
    "name": "Kalispell",
    "lng": -114.3320263617187,
    "lat": 48.1834102452968
  },
  {
    "name": "Kalispell",
    "lng": -114.32884826483496,
    "lat": 48.24208141861641
  },
  {
    "name": "Kalispell",
    "lng": -114.32481495247603,
    "lat": 48.261353852665785
  },
  {
    "name": "Kalispell",
    "lng": -114.32164836610208,
    "lat": 48.20624371465645
  },
  {
    "name": "Kalispell",
    "lng": -114.30067002566282,
    "lat": 48.230553990334705
  },
  {
    "name": "Kalispell",
    "lng": -114.27391989924651,
    "lat": 48.14121600449964
  },
  {
    "name": "Kalkaska",
    "lng": -85.17806810688532,
    "lat": 44.732351806811145
  },
  {
    "name": "Kalona",
    "lng": -91.7018247107506,
    "lat": 41.48741149042195
  },
  {
    "name": "Kaltag",
    "lng": -158.74156771951795,
    "lat": 64.3252812983964
  },
  {
    "name": "Kamas",
    "lng": -111.27215958078807,
    "lat": 40.64993482126966
  },
  {
    "name": "Kamiah",
    "lng": -116.02822011199063,
    "lat": 46.22683667744736
  },
  {
    "name": "Kampsville",
    "lng": -90.61268816985643,
    "lat": 39.29711977947881
  },
  {
    "name": "Kamrar",
    "lng": -93.72794967313361,
    "lat": 42.389687576740855
  },
  {
    "name": "Kanab",
    "lng": -112.521943471274,
    "lat": 37.026251998429615
  },
  {
    "name": "Kanarraville",
    "lng": -113.18098339457512,
    "lat": 37.537837763411446
  },
  {
    "name": "Kanawha",
    "lng": -93.79327200195851,
    "lat": 42.93419052072345
  },
  {
    "name": "Kandiyohi",
    "lng": -94.93286172848707,
    "lat": 45.131509109801556
  },
  {
    "name": "Kane",
    "lng": -90.35117973080372,
    "lat": 39.19048916093925
  },
  {
    "name": "Kaneohe",
    "lng": -157.79001190006255,
    "lat": 21.408293532209242
  },
  {
    "name": "Kaneville",
    "lng": -88.52167297490863,
    "lat": 41.8336285744247
  },
  {
    "name": "Kangley",
    "lng": -88.87330038529568,
    "lat": 41.14736747404001
  },
  {
    "name": "Kangley",
    "lng": -88.8658415646489,
    "lat": 41.1512851781398
  },
  {
    "name": "Kankakee",
    "lng": -87.8643225070514,
    "lat": 41.102086603169454
  },
  {
    "name": "Kankakee",
    "lng": -87.82520265317636,
    "lat": 41.13291613436152
  },
  {
    "name": "Kanopolis",
    "lng": -98.15801816755027,
    "lat": 38.708158146409794
  },
  {
    "name": "Kanorado",
    "lng": -102.03724561345,
    "lat": 39.33651608128227
  },
  {
    "name": "Kanosh",
    "lng": -112.43735521671609,
    "lat": 38.80170123740821
  },
  {
    "name": "Kansas",
    "lng": -98.38091189479614,
    "lat": 38.500207598247215
  },
  {
    "name": "Kansas",
    "lng": -94.78985961661343,
    "lat": 36.203878721544484
  },
  {
    "name": "Kansas",
    "lng": -87.93943305792327,
    "lat": 39.55444057004049
  },
  {
    "name": "Kansas",
    "lng": -87.55742097593975,
    "lat": 33.90242128573378
  },
  {
    "name": "Kansas City",
    "lng": -94.74397405541734,
    "lat": 39.12356239435464
  },
  {
    "name": "Kansas City",
    "lng": -94.55412778469818,
    "lat": 39.12440965765524
  },
  {
    "name": "Kapaau CDP",
    "lng": -155.80764103991274,
    "lat": 20.228232474009538
  },
  {
    "name": "Kaplan",
    "lng": -92.28387685832614,
    "lat": 30.005995351049
  },
  {
    "name": "Kaplan",
    "lng": -92.28221442277997,
    "lat": 29.99268719998897
  },
  {
    "name": "Kappa",
    "lng": -89.00720765645036,
    "lat": 40.66909707909485
  },
  {
    "name": "Kappa",
    "lng": -89.00595217170306,
    "lat": 40.67574460007623
  },
  {
    "name": "Karlsruhe",
    "lng": -100.61622011552286,
    "lat": 48.09102017743199
  },
  {
    "name": "Karlstad",
    "lng": -96.51635001746077,
    "lat": 48.57830775864076
  },
  {
    "name": "Karnak",
    "lng": -88.97581728857135,
    "lat": 37.29415773049485
  },
  {
    "name": "Karnes City",
    "lng": -97.90019912485603,
    "lat": 28.885720814385245
  },
  {
    "name": "Kasaan",
    "lng": -132.41444927302058,
    "lat": 55.555955149388865
  },
  {
    "name": "Kaser",
    "lng": -74.06858543752492,
    "lat": 41.12122780044555
  },
  {
    "name": "Kaskaskia",
    "lng": -89.91632429090583,
    "lat": 37.92124230177075
  },
  {
    "name": "Kasota",
    "lng": -93.96863138594102,
    "lat": 44.291420936829844
  },
  {
    "name": "Kasson",
    "lng": -92.7484441811465,
    "lat": 44.03443655742296
  },
  {
    "name": "Kasson",
    "lng": -92.7437596240699,
    "lat": 44.01802218870872
  },
  {
    "name": "Kasson",
    "lng": -92.74004130774998,
    "lat": 44.02147555707633
  },
  {
    "name": "Kathryn",
    "lng": -97.97068711952697,
    "lat": 46.68176113115154
  },
  {
    "name": "Katie",
    "lng": -97.33886315187821,
    "lat": 34.58361025960162
  },
  {
    "name": "Katy",
    "lng": -95.83713281854438,
    "lat": 29.79061749283211
  },
  {
    "name": "Kaufman",
    "lng": -96.31584725526645,
    "lat": 32.5769337703959
  },
  {
    "name": "Kaukauna",
    "lng": -88.26495480644712,
    "lat": 44.277222504624724
  },
  {
    "name": "Kaukauna",
    "lng": -88.25221320729099,
    "lat": 44.29927609280902
  },
  {
    "name": "Kaukauna",
    "lng": -88.24581116943385,
    "lat": 44.29956446614448
  },
  {
    "name": "Kaw City",
    "lng": -96.86292517270005,
    "lat": 36.768160577857294
  },
  {
    "name": "Kaycee",
    "lng": -106.6378518241496,
    "lat": 43.70963752723958
  },
  {
    "name": "Kaysville",
    "lng": -111.94516825090693,
    "lat": 41.029254946226494
  },
  {
    "name": "Keachi",
    "lng": -93.91599899072882,
    "lat": 32.17085625575237
  },
  {
    "name": "Kearney",
    "lng": -99.10688075713196,
    "lat": 40.68113021316861
  },
  {
    "name": "Kearney",
    "lng": -99.0841988697831,
    "lat": 40.69860232069752
  },
  {
    "name": "Kearney",
    "lng": -99.06559990600842,
    "lat": 40.74046409046593
  },
  {
    "name": "Kearney",
    "lng": -99.06200106658477,
    "lat": 40.6853506698504
  },
  {
    "name": "Kearney",
    "lng": -94.36788042664577,
    "lat": 39.36166825931962
  },
  {
    "name": "Kearny",
    "lng": -110.90762652469836,
    "lat": 33.056095377601416
  },
  {
    "name": "Kearny",
    "lng": -74.12014742797876,
    "lat": 40.75270017082082
  },
  {
    "name": "Kechi",
    "lng": -97.30858603666962,
    "lat": 37.85647682179121
  },
  {
    "name": "Kechi",
    "lng": -97.29786614681925,
    "lat": 37.79583918449338
  },
  {
    "name": "Kechi",
    "lng": -97.29708494138582,
    "lat": 37.800475391712766
  },
  {
    "name": "Kechi",
    "lng": -97.29172179121878,
    "lat": 37.822622830707246
  },
  {
    "name": "Kechi",
    "lng": -97.27330435280653,
    "lat": 37.797481393011246
  },
  {
    "name": "Keedysville",
    "lng": -77.69898711301327,
    "lat": 39.486386979495705
  },
  {
    "name": "Keego Harbor",
    "lng": -83.34433088867698,
    "lat": 42.60867965415506
  },
  {
    "name": "Keene",
    "lng": -97.32243758256115,
    "lat": 32.39552170939705
  },
  {
    "name": "Keenes",
    "lng": -88.64192168402096,
    "lat": 38.33858989167547
  },
  {
    "name": "Keenesburg",
    "lng": -104.52252886244293,
    "lat": 40.11536949411385
  },
  {
    "name": "Keenesburg",
    "lng": -104.5073134533229,
    "lat": 40.10788254116844
  },
  {
    "name": "Keenesburg",
    "lng": -104.50718022674185,
    "lat": 40.10793387038681
  },
  {
    "name": "Keensburg",
    "lng": -87.86826620625956,
    "lat": 38.351837876104426
  },
  {
    "name": "Keeseville",
    "lng": -73.48209788651407,
    "lat": 44.50467149073532
  },
  {
    "name": "Keewatin",
    "lng": -93.08282271328544,
    "lat": 47.400576417282736
  },
  {
    "name": "Keiser",
    "lng": -90.09588969057668,
    "lat": 35.67398256323982
  },
  {
    "name": "Keithsburg",
    "lng": -90.93533460713684,
    "lat": 41.10033854491109
  },
  {
    "name": "Kelford",
    "lng": -77.2241972645159,
    "lat": 36.18114569361332
  },
  {
    "name": "Kell",
    "lng": -88.903290963599,
    "lat": 38.49130833945526
  },
  {
    "name": "Keller",
    "lng": -97.22568164110004,
    "lat": 32.93348226024035
  },
  {
    "name": "Keller",
    "lng": -75.76433743548742,
    "lat": 37.621145225750716
  },
  {
    "name": "Kellerton",
    "lng": -94.05008858232358,
    "lat": 40.71022929244498
  },
  {
    "name": "Kelley",
    "lng": -93.66540674092211,
    "lat": 41.95107209004416
  },
  {
    "name": "Kelliher",
    "lng": -94.44465610336518,
    "lat": 47.943859922231724
  },
  {
    "name": "Kellnersville",
    "lng": -87.80208256899358,
    "lat": 44.225285103127696
  },
  {
    "name": "Kellogg",
    "lng": -116.14204352250424,
    "lat": 47.53707484968236
  },
  {
    "name": "Kellogg",
    "lng": -92.90659073398648,
    "lat": 41.71721905662999
  },
  {
    "name": "Kellogg",
    "lng": -91.99889571270101,
    "lat": 44.30740340231491
  },
  {
    "name": "Kellyville",
    "lng": -96.23859877784436,
    "lat": 35.92893863559427
  },
  {
    "name": "Kellyville",
    "lng": -96.21579668507188,
    "lat": 35.94400973477547
  },
  {
    "name": "Kellyville",
    "lng": -96.18552883675518,
    "lat": 35.98558023118885
  },
  {
    "name": "Kelso",
    "lng": -122.89071004468796,
    "lat": 46.125146168563816
  },
  {
    "name": "Kelso",
    "lng": -89.55041416222213,
    "lat": 37.19217323990167
  },
  {
    "name": "Kemah",
    "lng": -95.0189905408872,
    "lat": 29.532044641148843
  },
  {
    "name": "Kemmerer",
    "lng": -110.55552605190236,
    "lat": 41.77609147660526
  },
  {
    "name": "Kemp",
    "lng": -96.35436533369078,
    "lat": 33.769511389928674
  },
  {
    "name": "Kemp",
    "lng": -96.22475797317708,
    "lat": 32.43602739848995
  },
  {
    "name": "Kempner",
    "lng": -97.97273416769384,
    "lat": 31.075501542368027
  },
  {
    "name": "Kempton",
    "lng": -88.23625242451632,
    "lat": 40.93546921174636
  },
  {
    "name": "Kempton",
    "lng": -86.22971551531096,
    "lat": 40.287769197567194
  },
  {
    "name": "Kenai",
    "lng": -151.20769638206247,
    "lat": 60.56106799171616
  },
  {
    "name": "Kenansville",
    "lng": -78.0130216366227,
    "lat": 34.97309971950151
  },
  {
    "name": "Kenansville",
    "lng": -77.9638536769733,
    "lat": 34.96040149377626
  },
  {
    "name": "Kenbridge",
    "lng": -78.12867273008476,
    "lat": 36.960894798798314
  },
  {
    "name": "Kendall",
    "lng": -90.36770497377043,
    "lat": 43.79267765208594
  },
  {
    "name": "Kendall",
    "lng": -80.35551654579903,
    "lat": 25.66956489281843
  },
  {
    "name": "Kendallville",
    "lng": -85.25948407169199,
    "lat": 41.472740743470794
  },
  {
    "name": "Kendallville",
    "lng": -85.25768546792487,
    "lat": 41.44258138017855
  },
  {
    "name": "Kendleton",
    "lng": -96.00117102633715,
    "lat": 29.446955220594173
  },
  {
    "name": "Kendrick",
    "lng": -116.65437297574144,
    "lat": 46.61389777648178
  },
  {
    "name": "Kendrick",
    "lng": -96.77510545138644,
    "lat": 35.785361036778674
  },
  {
    "name": "Kenduskeag",
    "lng": -68.92794229104513,
    "lat": 44.91593928032655
  },
  {
    "name": "Kenedy",
    "lng": -97.8517970531897,
    "lat": 28.81749203959224
  },
  {
    "name": "Kenefic",
    "lng": -96.36423510076561,
    "lat": 34.150045410986095
  },
  {
    "name": "Kenefick",
    "lng": -94.85010963834016,
    "lat": 30.106877362955043
  },
  {
    "name": "Kenesaw",
    "lng": -98.65793918650498,
    "lat": 40.619177595560814
  },
  {
    "name": "Kenhorst",
    "lng": -75.94383560753383,
    "lat": 40.3068914454529
  },
  {
    "name": "Kenilworth",
    "lng": -87.71448145347708,
    "lat": 42.088866272037734
  },
  {
    "name": "Kenly",
    "lng": -78.16493168887693,
    "lat": 35.58067210982872
  },
  {
    "name": "Kenly",
    "lng": -78.1640376598352,
    "lat": 35.573087157267906
  },
  {
    "name": "Kenly",
    "lng": -78.153775509838,
    "lat": 35.57564792884588
  },
  {
    "name": "Kenly",
    "lng": -78.14892583324938,
    "lat": 35.58724132303464
  },
  {
    "name": "Kenly",
    "lng": -78.14784912410802,
    "lat": 35.58259808467174
  },
  {
    "name": "Kenly",
    "lng": -78.13983653688021,
    "lat": 35.59406585352046
  },
  {
    "name": "Kenly",
    "lng": -78.12484375167507,
    "lat": 35.59461410257879
  },
  {
    "name": "Kenly",
    "lng": -78.11804553121947,
    "lat": 35.60043770218912
  },
  {
    "name": "Kenmare",
    "lng": -102.07223818461536,
    "lat": 48.673063428173556
  },
  {
    "name": "Kenmore",
    "lng": -122.24614965458063,
    "lat": 47.75323453320194
  },
  {
    "name": "Kenmore",
    "lng": -78.87145691411895,
    "lat": 42.964654418214664
  },
  {
    "name": "Kennan",
    "lng": -90.58683429896399,
    "lat": 45.53007316974144
  },
  {
    "name": "Kennard",
    "lng": -96.20369620679277,
    "lat": 41.47469735763226
  },
  {
    "name": "Kennard",
    "lng": -95.18536140951146,
    "lat": 31.357551418096598
  },
  {
    "name": "Kennard",
    "lng": -85.52221641196373,
    "lat": 39.905417956221065
  },
  {
    "name": "Kennebec",
    "lng": -99.86200226436753,
    "lat": 43.903478062006045
  },
  {
    "name": "Kennebunk",
    "lng": -70.55642953170405,
    "lat": 43.382520225150536
  },
  {
    "name": "Kennebunkport",
    "lng": -70.42985346219734,
    "lat": 43.36525385593746
  },
  {
    "name": "Kennedale",
    "lng": -97.21708159668358,
    "lat": 32.6433361283815
  },
  {
    "name": "Kennedy",
    "lng": -96.91071236120327,
    "lat": 48.64388122966679
  },
  {
    "name": "Kennedy",
    "lng": -87.9861770564857,
    "lat": 33.58088862948648
  },
  {
    "name": "Kenner",
    "lng": -90.25505258037221,
    "lat": 30.01066337144093
  },
  {
    "name": "Kennesaw",
    "lng": -84.6179295908357,
    "lat": 34.02589705926211
  },
  {
    "name": "Kennesaw",
    "lng": -84.59297684980366,
    "lat": 34.04985711509655
  },
  {
    "name": "Kennesaw",
    "lng": -84.58804730483584,
    "lat": 34.05250860668237
  },
  {
    "name": "Kennesaw",
    "lng": -84.58607693527951,
    "lat": 33.9904345033616
  },
  {
    "name": "Kennesaw",
    "lng": -84.58236248823917,
    "lat": 34.04887144444129
  },
  {
    "name": "Kenneth",
    "lng": -96.07263314941045,
    "lat": 43.754320117831995
  },
  {
    "name": "Kenneth City",
    "lng": -82.72086025286802,
    "lat": 27.8212946431906
  },
  {
    "name": "Kenneth City",
    "lng": -82.71610585114038,
    "lat": 27.815512951743145
  },
  {
    "name": "Kennett",
    "lng": -90.04823020986427,
    "lat": 36.240377677406194
  },
  {
    "name": "Kennewick",
    "lng": -119.17621698952095,
    "lat": 46.19964407532829
  },
  {
    "name": "Kenney",
    "lng": -89.08613723998836,
    "lat": 40.09793989596027
  },
  {
    "name": "Kenosha",
    "lng": -87.98636244016737,
    "lat": 42.572519079329616
  },
  {
    "name": "Kenosha",
    "lng": -87.93634111270103,
    "lat": 42.60573263126759
  },
  {
    "name": "Kenosha",
    "lng": -87.86808150632847,
    "lat": 42.58554477730078
  },
  {
    "name": "Kenova",
    "lng": -82.58626178220378,
    "lat": 38.38479916734063
  },
  {
    "name": "Kenova",
    "lng": -82.58249067209015,
    "lat": 38.404068832635794
  },
  {
    "name": "Kensal",
    "lng": -98.73212932753395,
    "lat": 47.30003753677557
  },
  {
    "name": "Kensett",
    "lng": -93.2107423948589,
    "lat": 43.35330457032934
  },
  {
    "name": "Kensett",
    "lng": -91.67078980610337,
    "lat": 35.23535641440965
  },
  {
    "name": "Kensington",
    "lng": -99.03313264191492,
    "lat": 39.766816230195566
  },
  {
    "name": "Kensington",
    "lng": -95.69680473851427,
    "lat": 45.77821853121682
  },
  {
    "name": "Kensington",
    "lng": -77.07371008302353,
    "lat": 39.02683718329415
  },
  {
    "name": "Kensington",
    "lng": -73.72234234353563,
    "lat": 40.793090226852684
  },
  {
    "name": "Kent",
    "lng": -122.26683275232125,
    "lat": 47.36937384849691
  },
  {
    "name": "Kent",
    "lng": -122.21693785269196,
    "lat": 47.38964078383428
  },
  {
    "name": "Kent",
    "lng": -122.17940766320626,
    "lat": 47.33722799044216
  },
  {
    "name": "Kent",
    "lng": -122.10655500278055,
    "lat": 47.354550925050376
  },
  {
    "name": "Kent",
    "lng": -122.04855939706044,
    "lat": 47.34539272100844
  },
  {
    "name": "Kent",
    "lng": -122.00608672920785,
    "lat": 47.361638732282785
  },
  {
    "name": "Kent",
    "lng": -96.68237439389344,
    "lat": 46.438439943016974
  },
  {
    "name": "Kent",
    "lng": -81.36100754721886,
    "lat": 41.14915754953876
  },
  {
    "name": "Kent",
    "lng": -73.45398275623397,
    "lat": 41.72994594061023
  },
  {
    "name": "Kent City",
    "lng": -85.75381071072485,
    "lat": 43.22022719567276
  },
  {
    "name": "Kentland",
    "lng": -87.44510899587871,
    "lat": 40.77297317469241
  },
  {
    "name": "Kenton",
    "lng": -89.01672360129402,
    "lat": 36.20186457395614
  },
  {
    "name": "Kenton",
    "lng": -83.60995638178991,
    "lat": 40.64457577658171
  },
  {
    "name": "Kenton",
    "lng": -83.59758118653474,
    "lat": 40.62787824702603
  },
  {
    "name": "Kenton Vale",
    "lng": -84.51987979510103,
    "lat": 39.05137743549797
  },
  {
    "name": "Kentucky",
    "lng": -89.51727971234645,
    "lat": 36.539420420566366
  },
  {
    "name": "Kentucky",
    "lng": -85.2818351325848,
    "lat": 37.53430483428913
  },
  {
    "name": "Kentwood",
    "lng": -90.51530180130621,
    "lat": 30.933921491242796
  },
  {
    "name": "Kentwood",
    "lng": -85.59255169593924,
    "lat": 42.885221026444775
  },
  {
    "name": "Kenyon",
    "lng": -92.98580990928623,
    "lat": 44.272637134294804
  },
  {
    "name": "Keo",
    "lng": -92.00877798653498,
    "lat": 34.61127955861034
  },
  {
    "name": "Keokuk",
    "lng": -91.40324932424248,
    "lat": 40.40967866514237
  },
  {
    "name": "Keomah Village",
    "lng": -92.53682220243913,
    "lat": 41.28864018116505
  },
  {
    "name": "Keosauqua",
    "lng": -91.96039831704566,
    "lat": 40.73454620119061
  },
  {
    "name": "Keota",
    "lng": -94.92184681151281,
    "lat": 35.25674866698607
  },
  {
    "name": "Keota",
    "lng": -91.95332199036044,
    "lat": 41.365167797535825
  },
  {
    "name": "Kerens",
    "lng": -96.22512497808081,
    "lat": 32.130809837540596
  },
  {
    "name": "Kerkhoven",
    "lng": -95.3200966095195,
    "lat": 45.19242114470906
  },
  {
    "name": "Kerman",
    "lng": -120.06239648395432,
    "lat": 36.72500846438022
  },
  {
    "name": "Kermit",
    "lng": -103.09222308762746,
    "lat": 31.85414957090883
  },
  {
    "name": "Kermit",
    "lng": -82.40855108569342,
    "lat": 37.84245373794776
  },
  {
    "name": "Kernersville",
    "lng": -80.15145558167875,
    "lat": 36.11740789320583
  },
  {
    "name": "Kernersville",
    "lng": -80.11848851546918,
    "lat": 36.1015550291175
  },
  {
    "name": "Kernersville",
    "lng": -80.10590467580487,
    "lat": 36.098754290951995
  },
  {
    "name": "Kernersville",
    "lng": -80.08718052628691,
    "lat": 36.10705293174239
  },
  {
    "name": "Kernersville",
    "lng": -80.07813975565057,
    "lat": 36.09513035949131
  },
  {
    "name": "Kernersville",
    "lng": -80.04916156222535,
    "lat": 36.13814642074581
  },
  {
    "name": "Kernersville",
    "lng": -80.04895075292951,
    "lat": 36.1186734794383
  },
  {
    "name": "Kernersville",
    "lng": -80.03723155523193,
    "lat": 36.11037360569711
  },
  {
    "name": "Kernersville",
    "lng": -80.02980826740944,
    "lat": 36.120775873759584
  },
  {
    "name": "Kerrick",
    "lng": -92.58433260218081,
    "lat": 46.338536516021726
  },
  {
    "name": "Kerrville",
    "lng": -99.13189668532347,
    "lat": 30.039876468415148
  },
  {
    "name": "Kersey",
    "lng": -104.56699071666829,
    "lat": 40.38736859048671
  },
  {
    "name": "Kersey",
    "lng": -104.54646732284696,
    "lat": 40.3929467854409
  },
  {
    "name": "Kershaw",
    "lng": -80.58651459396366,
    "lat": 34.544433018414885
  },
  {
    "name": "Keswick",
    "lng": -92.23836404333814,
    "lat": 41.454573873142735
  },
  {
    "name": "Ketchikan",
    "lng": -131.65308750737583,
    "lat": 55.3488359076823
  },
  {
    "name": "Ketchum",
    "lng": -114.3730495369333,
    "lat": 43.68668014690351
  },
  {
    "name": "Ketchum",
    "lng": -95.02697850405265,
    "lat": 36.52565433072076
  },
  {
    "name": "Kettering",
    "lng": -84.14953271394972,
    "lat": 39.695638884255565
  },
  {
    "name": "Kettle Falls",
    "lng": -118.06158076675949,
    "lat": 48.605602754950425
  },
  {
    "name": "Kettle River",
    "lng": -92.87758447101876,
    "lat": 46.48625238805357
  },
  {
    "name": "Kettlersville",
    "lng": -84.25996082256333,
    "lat": 40.43879246801153
  },
  {
    "name": "Kevil",
    "lng": -88.88288539074523,
    "lat": 37.08421053892578
  },
  {
    "name": "Kevin",
    "lng": -111.96563016389796,
    "lat": 48.74597974517552
  },
  {
    "name": "Kewanee",
    "lng": -89.92631738982804,
    "lat": 41.23990190932437
  },
  {
    "name": "Kewanna",
    "lng": -86.41247315166865,
    "lat": 41.019274570572875
  },
  {
    "name": "Kewaskum",
    "lng": -88.26809101150351,
    "lat": 43.544707791214925
  },
  {
    "name": "Kewaskum",
    "lng": -88.23017699046775,
    "lat": 43.5168532766017
  },
  {
    "name": "Kewaunee",
    "lng": -87.51163709853239,
    "lat": 44.46065692931628
  },
  {
    "name": "Keyes",
    "lng": -102.2516305907137,
    "lat": 36.807928183107094
  },
  {
    "name": "Keyesport",
    "lng": -89.27369694781756,
    "lat": 38.74604758537951
  },
  {
    "name": "Keys Residential Complex",
    "lng": -82.3540738260393,
    "lat": 29.647788773947756
  },
  {
    "name": "Keyser",
    "lng": -78.98207780172231,
    "lat": 39.43971459432188
  },
  {
    "name": "Keystone",
    "lng": -103.42662637429555,
    "lat": 43.89563657657516
  },
  {
    "name": "Keystone",
    "lng": -92.19824895923654,
    "lat": 41.99964078943441
  },
  {
    "name": "Keystone",
    "lng": -81.44616712285006,
    "lat": 37.415809191209625
  },
  {
    "name": "Keystone Heights",
    "lng": -82.0340979445815,
    "lat": 29.781655308527217
  },
  {
    "name": "Keysville",
    "lng": -82.23253963275222,
    "lat": 33.240876726293635
  },
  {
    "name": "Keysville",
    "lng": -78.48036857213916,
    "lat": 37.039009410402
  },
  {
    "name": "Keytesville",
    "lng": -92.9370278739799,
    "lat": 39.43130947414784
  },
  {
    "name": "Kiana",
    "lng": -160.43764720643122,
    "lat": 66.97131964546338
  },
  {
    "name": "Kiawah Island",
    "lng": -80.05780306679053,
    "lat": 32.61615562401582
  },
  {
    "name": "Kibler",
    "lng": -94.23674255095153,
    "lat": 35.426587895811615
  },
  {
    "name": "Kidder",
    "lng": -94.10232689519744,
    "lat": 39.782191588309
  },
  {
    "name": "Kief",
    "lng": -100.52612879649395,
    "lat": 47.85127451132815
  },
  {
    "name": "Kief",
    "lng": -100.51009294965252,
    "lat": 47.86210090523999
  },
  {
    "name": "Kiefer",
    "lng": -96.05219235779062,
    "lat": 35.9437964049287
  },
  {
    "name": "Kiel",
    "lng": -88.02723165814038,
    "lat": 43.91720575796356
  },
  {
    "name": "Kiester",
    "lng": -93.71120284622053,
    "lat": 43.53648262019337
  },
  {
    "name": "Kilbourne",
    "lng": -91.3138877985532,
    "lat": 32.99717131889711
  },
  {
    "name": "Kilbourne",
    "lng": -90.01151845903368,
    "lat": 40.151909108443405
  },
  {
    "name": "Kildare",
    "lng": -97.0501089421606,
    "lat": 36.80862940090948
  },
  {
    "name": "Kildeer",
    "lng": -88.04811186987857,
    "lat": 42.184367914954265
  },
  {
    "name": "Kilgore",
    "lng": -100.95730521321266,
    "lat": 42.93909690467242
  },
  {
    "name": "Kilkenny",
    "lng": -93.57475675932942,
    "lat": 44.31355558198859
  },
  {
    "name": "Kilkenny",
    "lng": -93.57164107730556,
    "lat": 44.32014106919203
  },
  {
    "name": "Kill Devil Hills",
    "lng": -75.66989018074302,
    "lat": 36.01681702819923
  },
  {
    "name": "Killbuck",
    "lng": -81.98288163635843,
    "lat": 40.496619227108525
  },
  {
    "name": "Killdeer",
    "lng": -102.75346320612664,
    "lat": 47.367110862841486
  },
  {
    "name": "Killeen",
    "lng": -97.79037433012368,
    "lat": 31.0673527243105
  },
  {
    "name": "Killeen",
    "lng": -97.72736258035357,
    "lat": 31.085740615494466
  },
  {
    "name": "Killen",
    "lng": -87.52890210128608,
    "lat": 34.860014188209036
  },
  {
    "name": "Killian",
    "lng": -90.57954294018283,
    "lat": 30.353004409922658
  },
  {
    "name": "Killingly",
    "lng": -71.84999786023334,
    "lat": 41.83103260862947
  },
  {
    "name": "Killingly Pond Management Area",
    "lng": -71.79297992332627,
    "lat": 41.859021922331536
  },
  {
    "name": "Killingly Pond Management Area",
    "lng": -71.79126245795527,
    "lat": 41.87186105490271
  },
  {
    "name": "Killingly Pond Management Area",
    "lng": -71.79115866058305,
    "lat": 41.86147956250702
  },
  {
    "name": "Killingly Pond Management Area",
    "lng": -71.77834466195992,
    "lat": 41.87098784008427
  },
  {
    "name": "Killingly Pond Management Area",
    "lng": -71.77833473150517,
    "lat": 41.86312940559474
  },
  {
    "name": "Killingworth",
    "lng": -72.5784593277152,
    "lat": 41.37924758191569
  },
  {
    "name": "Kilmarnock",
    "lng": -76.38359186837891,
    "lat": 37.71154645501582
  },
  {
    "name": "Kilmichael",
    "lng": -89.56722966596976,
    "lat": 33.440367090129484
  },
  {
    "name": "Kim",
    "lng": -103.35292991620814,
    "lat": 37.246593813229694
  },
  {
    "name": "Kimball",
    "lng": -103.6662103268144,
    "lat": 41.219546798054935
  },
  {
    "name": "Kimball",
    "lng": -103.66481705513583,
    "lat": 41.21418437237183
  },
  {
    "name": "Kimball",
    "lng": -103.65846285432865,
    "lat": 41.23331843201832
  },
  {
    "name": "Kimball",
    "lng": -98.95692265605145,
    "lat": 43.74686575020438
  },
  {
    "name": "Kimball",
    "lng": -94.30079977574778,
    "lat": 45.31431142336034
  },
  {
    "name": "Kimball",
    "lng": -85.67350053417476,
    "lat": 35.045628858362214
  },
  {
    "name": "Kimball",
    "lng": -81.50786016982725,
    "lat": 37.42674275808488
  },
  {
    "name": "Kimballton",
    "lng": -95.07436003622824,
    "lat": 41.6265734557372
  },
  {
    "name": "Kimberling City",
    "lng": -93.42430692931238,
    "lat": 36.64350841412124
  },
  {
    "name": "Kimberly",
    "lng": -114.36967035417152,
    "lat": 42.53381662484175
  },
  {
    "name": "Kimberly",
    "lng": -88.3384747727669,
    "lat": 44.26747679169448
  },
  {
    "name": "Kimberly",
    "lng": -86.81050205141725,
    "lat": 33.769749143979766
  },
  {
    "name": "Kimberly",
    "lng": -86.7957586142845,
    "lat": 33.791170584037495
  },
  {
    "name": "Kimberly",
    "lng": -86.78059394881795,
    "lat": 33.763171191522
  },
  {
    "name": "Kimberly",
    "lng": -86.77905858324931,
    "lat": 33.76311980332404
  },
  {
    "name": "Kimberly",
    "lng": -86.76990454773558,
    "lat": 33.7769616315683
  },
  {
    "name": "Kimberly",
    "lng": -86.76723689003396,
    "lat": 33.78297744600613
  },
  {
    "name": "Kimberly",
    "lng": -86.76246506605466,
    "lat": 33.76779476551656
  },
  {
    "name": "Kimberly",
    "lng": -86.75600341127918,
    "lat": 33.759797117470505
  },
  {
    "name": "Kimmswick",
    "lng": -90.36438459756117,
    "lat": 38.36554552255748
  },
  {
    "name": "Kinbrae",
    "lng": -95.4822988204279,
    "lat": 43.826446452494125
  },
  {
    "name": "Kincaid",
    "lng": -95.15527743185939,
    "lat": 38.08127870152098
  },
  {
    "name": "Kincaid",
    "lng": -89.41648719318012,
    "lat": 39.586813146117116
  },
  {
    "name": "Kinde",
    "lng": -82.99620395514916,
    "lat": 43.940452791766404
  },
  {
    "name": "Kinder",
    "lng": -92.85076383579135,
    "lat": 30.48354798287507
  },
  {
    "name": "Kinderhook",
    "lng": -91.15173359811004,
    "lat": 39.703740261901395
  },
  {
    "name": "Kinderhook",
    "lng": -73.7051689504211,
    "lat": 42.391988212887945
  },
  {
    "name": "Kindred",
    "lng": -97.01776582468652,
    "lat": 46.65054547595254
  },
  {
    "name": "King",
    "lng": -80.38171487161077,
    "lat": 36.2616898771989
  },
  {
    "name": "King",
    "lng": -80.38015294573134,
    "lat": 36.31430390067489
  },
  {
    "name": "King",
    "lng": -80.37818414678277,
    "lat": 36.26451997607975
  },
  {
    "name": "King",
    "lng": -80.37504830377932,
    "lat": 36.26382851402146
  },
  {
    "name": "King",
    "lng": -80.37467898851843,
    "lat": 36.28917782979063
  },
  {
    "name": "King",
    "lng": -80.37322738763918,
    "lat": 36.29575415103952
  },
  {
    "name": "King",
    "lng": -80.37223278415149,
    "lat": 36.312644857842585
  },
  {
    "name": "King",
    "lng": -80.36241913432976,
    "lat": 36.31708505709857
  },
  {
    "name": "King",
    "lng": -80.35528332806236,
    "lat": 36.27611590575388
  },
  {
    "name": "King",
    "lng": -80.33169420046069,
    "lat": 36.30137202881023
  },
  {
    "name": "King",
    "lng": -80.33005837572065,
    "lat": 36.276775830692024
  },
  {
    "name": "King",
    "lng": -80.32242397542593,
    "lat": 36.282561598044204
  },
  {
    "name": "King City",
    "lng": -121.15740340524931,
    "lat": 36.224764072530284
  },
  {
    "name": "King City",
    "lng": -121.12723685339725,
    "lat": 36.21455549888437
  },
  {
    "name": "King City",
    "lng": -94.52516732930486,
    "lat": 40.05056711146628
  },
  {
    "name": "King of Prussia",
    "lng": -75.38188947302334,
    "lat": 40.09606993163824
  },
  {
    "name": "Kingdom City",
    "lng": -91.93908329452611,
    "lat": 38.9471163734906
  },
  {
    "name": "Kingfield",
    "lng": -70.19020009485605,
    "lat": 44.983701164705245
  },
  {
    "name": "Kingfisher",
    "lng": -97.93803433075885,
    "lat": 35.843695204901636
  },
  {
    "name": "Kingfisher",
    "lng": -97.93488057818361,
    "lat": 35.87390714291232
  },
  {
    "name": "Kingman",
    "lng": -114.01012037173949,
    "lat": 35.21686978651997
  },
  {
    "name": "Kingman",
    "lng": -98.11702210423053,
    "lat": 37.64723604185918
  },
  {
    "name": "Kingman",
    "lng": -98.10798009905493,
    "lat": 37.65802024628885
  },
  {
    "name": "Kingman",
    "lng": -87.2785492089741,
    "lat": 39.96651915930544
  },
  {
    "name": "Kings Mountain",
    "lng": -81.45571326978325,
    "lat": 35.27565576803861
  },
  {
    "name": "Kings Mountain",
    "lng": -81.44718055473295,
    "lat": 35.27975054438298
  },
  {
    "name": "Kings Mountain",
    "lng": -81.4171725560314,
    "lat": 35.17967331026465
  },
  {
    "name": "Kings Mountain",
    "lng": -81.41413626744865,
    "lat": 35.248403837246805
  },
  {
    "name": "Kings Mountain",
    "lng": -81.40671367238164,
    "lat": 35.24684926167404
  },
  {
    "name": "Kings Mountain",
    "lng": -81.40546727328972,
    "lat": 35.24346853184274
  },
  {
    "name": "Kings Mountain",
    "lng": -81.4000316422002,
    "lat": 35.245882053257674
  },
  {
    "name": "Kings Mountain",
    "lng": -81.39594627544767,
    "lat": 35.23838338045563
  },
  {
    "name": "Kings Mountain",
    "lng": -81.3729420113704,
    "lat": 35.21721686405153
  },
  {
    "name": "Kings Mountain",
    "lng": -81.36395878279974,
    "lat": 35.26763541732731
  },
  {
    "name": "Kings Mountain",
    "lng": -81.35552975573778,
    "lat": 35.279904085583084
  },
  {
    "name": "Kings Mountain",
    "lng": -81.35438920736486,
    "lat": 35.27485559094629
  },
  {
    "name": "Kings Mountain",
    "lng": -81.34603162486334,
    "lat": 35.23542794685234
  },
  {
    "name": "Kings Point",
    "lng": -73.74886617383024,
    "lat": 40.820585139741176
  },
  {
    "name": "Kingsburg",
    "lng": -119.55561708131387,
    "lat": 36.519909755206726
  },
  {
    "name": "Kingsbury",
    "lng": -97.7943334771785,
    "lat": 29.65261579857371
  },
  {
    "name": "Kingsbury",
    "lng": -86.6973510988328,
    "lat": 41.530232797413895
  },
  {
    "name": "Kingsford",
    "lng": -88.09948250763082,
    "lat": 45.80613736167852
  },
  {
    "name": "Kingsford Heights",
    "lng": -86.69268278533004,
    "lat": 41.47856434986524
  },
  {
    "name": "Kingsland",
    "lng": -92.29148552744091,
    "lat": 33.865817227161365
  },
  {
    "name": "Kingsland",
    "lng": -81.74832268190575,
    "lat": 30.808761616306594
  },
  {
    "name": "Kingsland",
    "lng": -81.72214521565418,
    "lat": 30.81926159083165
  },
  {
    "name": "Kingsland",
    "lng": -81.71475375749128,
    "lat": 30.800417138105683
  },
  {
    "name": "Kingsland",
    "lng": -81.6972849360156,
    "lat": 30.784856982070146
  },
  {
    "name": "Kingsland",
    "lng": -81.69312646523176,
    "lat": 30.817560026185298
  },
  {
    "name": "Kingsland",
    "lng": -81.69284012095635,
    "lat": 30.784482236010305
  },
  {
    "name": "Kingsland",
    "lng": -81.69167622076507,
    "lat": 30.830094172498676
  },
  {
    "name": "Kingsland",
    "lng": -81.6912337092097,
    "lat": 30.844901947543242
  },
  {
    "name": "Kingsland",
    "lng": -81.68929312443849,
    "lat": 30.81648350612336
  },
  {
    "name": "Kingsland",
    "lng": -81.67220458177852,
    "lat": 30.838889753666972
  },
  {
    "name": "Kingsland",
    "lng": -81.67117598532924,
    "lat": 30.849875118051653
  },
  {
    "name": "Kingsland",
    "lng": -81.66865400041696,
    "lat": 30.8489739936129
  },
  {
    "name": "Kingsland",
    "lng": -81.66481360913227,
    "lat": 30.827566489896927
  },
  {
    "name": "Kingsland",
    "lng": -81.65369904667821,
    "lat": 30.826577483716616
  },
  {
    "name": "Kingsley",
    "lng": -95.96826436489425,
    "lat": 42.586507513993226
  },
  {
    "name": "Kingsley",
    "lng": -85.6725740802899,
    "lat": 38.22170168670424
  },
  {
    "name": "Kingsley",
    "lng": -85.53355256495712,
    "lat": 44.584957400417814
  },
  {
    "name": "Kingsport",
    "lng": -82.54870548264964,
    "lat": 36.52842733539757
  },
  {
    "name": "Kingston",
    "lng": -112.17925121813,
    "lat": 38.206663802101325
  },
  {
    "name": "Kingston",
    "lng": -96.72525768052641,
    "lat": 34.01326766127095
  },
  {
    "name": "Kingston",
    "lng": -96.71394003442697,
    "lat": 34.000488400263606
  },
  {
    "name": "Kingston",
    "lng": -94.31098046015795,
    "lat": 45.19595762042474
  },
  {
    "name": "Kingston",
    "lng": -94.0383071240963,
    "lat": 39.642454081882946
  },
  {
    "name": "Kingston",
    "lng": -88.76197118322465,
    "lat": 42.100824608339586
  },
  {
    "name": "Kingston",
    "lng": -84.94449253983848,
    "lat": 34.231975152462255
  },
  {
    "name": "Kingston",
    "lng": -84.49533528130124,
    "lat": 35.871535331273854
  },
  {
    "name": "Kingston",
    "lng": -83.18630846909431,
    "lat": 43.412793366630325
  },
  {
    "name": "Kingston",
    "lng": -82.9120069834926,
    "lat": 39.47228119202448
  },
  {
    "name": "Kingston",
    "lng": -73.9967771843407,
    "lat": 41.92950941145659
  },
  {
    "name": "Kingston Mines",
    "lng": -89.77181151080967,
    "lat": 40.55869071999725
  },
  {
    "name": "Kingston Springs",
    "lng": -87.10427080301163,
    "lat": 36.08481235168545
  },
  {
    "name": "Kingstown",
    "lng": -81.620052398011,
    "lat": 35.362107107377
  },
  {
    "name": "Kingstree",
    "lng": -79.82919401951568,
    "lat": 33.66635728773009
  },
  {
    "name": "Kingsville",
    "lng": -97.8609594753133,
    "lat": 27.50953377142221
  },
  {
    "name": "Kingsville",
    "lng": -94.06975657270716,
    "lat": 38.743732310639295
  },
  {
    "name": "Kingwood",
    "lng": -79.68245524144808,
    "lat": 39.47197098056395
  },
  {
    "name": "Kinloch",
    "lng": -90.3249642935143,
    "lat": 38.73843387221044
  },
  {
    "name": "Kinmundy",
    "lng": -88.87522543368425,
    "lat": 38.76792335935054
  },
  {
    "name": "Kinmundy",
    "lng": -88.85379726874886,
    "lat": 38.77262570164309
  },
  {
    "name": "Kinney",
    "lng": -92.71851498254676,
    "lat": 47.51667482140155
  },
  {
    "name": "Kinross",
    "lng": -91.98733193921737,
    "lat": 41.45903108208241
  },
  {
    "name": "Kinsley",
    "lng": -99.41864635695343,
    "lat": 37.91593400592616
  },
  {
    "name": "Kinsley",
    "lng": -99.41129512189583,
    "lat": 37.92252438189361
  },
  {
    "name": "Kinsman",
    "lng": -88.56993482641036,
    "lat": 41.19027749950596
  },
  {
    "name": "Kinston",
    "lng": -86.17054559140269,
    "lat": 31.219951961127947
  },
  {
    "name": "Kinston",
    "lng": -77.64881173343366,
    "lat": 35.28942871952855
  },
  {
    "name": "Kinston",
    "lng": -77.6441769440019,
    "lat": 35.294326178719906
  },
  {
    "name": "Kinston",
    "lng": -77.63674428697728,
    "lat": 35.2368403394139
  },
  {
    "name": "Kinston",
    "lng": -77.6127176442723,
    "lat": 35.22768737752792
  },
  {
    "name": "Kinston",
    "lng": -77.60156452827988,
    "lat": 35.252955529894386
  },
  {
    "name": "Kinston",
    "lng": -77.59162108100175,
    "lat": 35.275072656148815
  },
  {
    "name": "Kinston",
    "lng": -77.54617683786196,
    "lat": 35.23032808136155
  },
  {
    "name": "Kinston",
    "lng": -77.50946218682736,
    "lat": 35.287305214851784
  },
  {
    "name": "Kinta",
    "lng": -95.23782704205617,
    "lat": 35.119296911653905
  },
  {
    "name": "Kiowa",
    "lng": -104.46365993006789,
    "lat": 39.34388164888591
  },
  {
    "name": "Kiowa",
    "lng": -104.4636380837794,
    "lat": 39.34389104182331
  },
  {
    "name": "Kiowa",
    "lng": -104.4303404733291,
    "lat": 39.341950620555515
  },
  {
    "name": "Kiowa",
    "lng": -98.48469754187562,
    "lat": 37.01766816101141
  },
  {
    "name": "Kiowa",
    "lng": -95.90184588482497,
    "lat": 34.722277783594016
  },
  {
    "name": "Kipton",
    "lng": -82.30393008663567,
    "lat": 41.26649075618865
  },
  {
    "name": "Kirby",
    "lng": -108.18097236474489,
    "lat": 43.803284167338
  },
  {
    "name": "Kirby",
    "lng": -83.4194092732285,
    "lat": 40.81349001948764
  },
  {
    "name": "Kirbyville",
    "lng": -93.90008156773794,
    "lat": 30.657782145920507
  },
  {
    "name": "Kirbyville",
    "lng": -93.15759151880287,
    "lat": 36.629512220581994
  },
  {
    "name": "Kirkersville",
    "lng": -82.59823870556372,
    "lat": 39.94992105205133
  },
  {
    "name": "Kirkland",
    "lng": -122.19814149141675,
    "lat": 47.6996292279756
  },
  {
    "name": "Kirkland",
    "lng": -88.84899584617308,
    "lat": 42.090526938269534
  },
  {
    "name": "Kirklin",
    "lng": -86.35879392913141,
    "lat": 40.192871980584705
  },
  {
    "name": "Kirkman",
    "lng": -95.26716982597544,
    "lat": 41.72865358763472
  },
  {
    "name": "Kirksville",
    "lng": -92.5752537712335,
    "lat": 40.19852510485562
  },
  {
    "name": "Kirkville",
    "lng": -92.50351060961057,
    "lat": 41.14226862702038
  },
  {
    "name": "Kirkwood",
    "lng": -90.74855341333654,
    "lat": 40.86769186467907
  },
  {
    "name": "Kirkwood",
    "lng": -90.42025373866312,
    "lat": 38.57878449464191
  },
  {
    "name": "Kiron",
    "lng": -95.32711813476503,
    "lat": 42.194167329630105
  },
  {
    "name": "Kirtland",
    "lng": -81.34064341834828,
    "lat": 41.596882058223336
  },
  {
    "name": "Kirtland Hills",
    "lng": -81.31926326724712,
    "lat": 41.636191528036164
  },
  {
    "name": "Kirwin",
    "lng": -99.12255852841164,
    "lat": 39.669356299824216
  },
  {
    "name": "Kiryas Joel",
    "lng": -74.16671560272172,
    "lat": 41.33981906353861
  },
  {
    "name": "Kis-Lyn",
    "lng": -75.98540648952843,
    "lat": 41.007503134732254
  },
  {
    "name": "Kismet",
    "lng": -100.70181844346286,
    "lat": 37.20448649207412
  },
  {
    "name": "Kissimmee",
    "lng": -81.47484632233555,
    "lat": 28.265855952420104
  },
  {
    "name": "Kissimmee",
    "lng": -81.47126385071293,
    "lat": 28.28675261521984
  },
  {
    "name": "Kissimmee",
    "lng": -81.41572466684214,
    "lat": 28.305796103358556
  },
  {
    "name": "Kissimmee",
    "lng": -81.41090972817238,
    "lat": 28.285065932104793
  },
  {
    "name": "Kissimmee",
    "lng": -81.39836587272927,
    "lat": 28.27820204330006
  },
  {
    "name": "Kit Carson",
    "lng": -102.79549876933093,
    "lat": 38.76288199888405
  },
  {
    "name": "Kite",
    "lng": -82.51352532944352,
    "lat": 32.69220668780661
  },
  {
    "name": "Kittery",
    "lng": -70.63465657459258,
    "lat": 43.042367323893096
  },
  {
    "name": "Kittitas",
    "lng": -120.41991302528695,
    "lat": 46.98428551336975
  },
  {
    "name": "Kittitas",
    "lng": -120.41592599224771,
    "lat": 46.992629953343005
  },
  {
    "name": "Kittitas",
    "lng": -120.41396210233611,
    "lat": 46.96885954329281
  },
  {
    "name": "Kittrell",
    "lng": -78.44119976070787,
    "lat": 36.221819931752
  },
  {
    "name": "Kitty Hawk",
    "lng": -75.71817773564541,
    "lat": 36.071840380041586
  },
  {
    "name": "Kitzmiller",
    "lng": -79.18368454776692,
    "lat": 39.38916813758327
  },
  {
    "name": "Kivalina",
    "lng": -164.5256009218625,
    "lat": 67.73281268178813
  },
  {
    "name": "Klawock",
    "lng": -133.08662964901518,
    "lat": 55.55520232428891
  },
  {
    "name": "Klemme",
    "lng": -93.60136226726341,
    "lat": 43.00722381314703
  },
  {
    "name": "Kline",
    "lng": -81.34256367146058,
    "lat": 33.12514186142543
  },
  {
    "name": "Knapp",
    "lng": -92.07694870512452,
    "lat": 44.95144573163628
  },
  {
    "name": "Knierim",
    "lng": -94.4559232412233,
    "lat": 42.45632364149055
  },
  {
    "name": "Knightdale",
    "lng": -78.540085878933,
    "lat": 35.79328980714107
  },
  {
    "name": "Knightdale",
    "lng": -78.53772930679338,
    "lat": 35.767724494753104
  },
  {
    "name": "Knightdale",
    "lng": -78.53236422607564,
    "lat": 35.76435434696261
  },
  {
    "name": "Knightdale",
    "lng": -78.53128157269519,
    "lat": 35.79007163360511
  },
  {
    "name": "Knightdale",
    "lng": -78.52557744209926,
    "lat": 35.78616141851239
  },
  {
    "name": "Knightdale",
    "lng": -78.52051950733794,
    "lat": 35.79235799656851
  },
  {
    "name": "Knightdale",
    "lng": -78.51918233653623,
    "lat": 35.75699245827815
  },
  {
    "name": "Knightdale",
    "lng": -78.51467092663404,
    "lat": 35.76163005552905
  },
  {
    "name": "Knightdale",
    "lng": -78.504489569628,
    "lat": 35.81940831286694
  },
  {
    "name": "Knightdale",
    "lng": -78.50158779172179,
    "lat": 35.826101227623106
  },
  {
    "name": "Knightdale",
    "lng": -78.49468888520161,
    "lat": 35.7932784575071
  },
  {
    "name": "Knightdale",
    "lng": -78.47337914716053,
    "lat": 35.781367270067825
  },
  {
    "name": "Knightdale",
    "lng": -78.47228904377008,
    "lat": 35.77847422616195
  },
  {
    "name": "Knightdale",
    "lng": -78.44936153107976,
    "lat": 35.79975824430641
  },
  {
    "name": "Knightstown",
    "lng": -85.53123187296137,
    "lat": 39.79597430360968
  },
  {
    "name": "Knightsville",
    "lng": -87.08906184005855,
    "lat": 39.525291133821355
  },
  {
    "name": "Knob Noster",
    "lng": -93.56165102130095,
    "lat": 38.767517626189615
  },
  {
    "name": "Knob Noster",
    "lng": -93.54935934052318,
    "lat": 38.75918564126963
  },
  {
    "name": "Knobel",
    "lng": -90.6021276498715,
    "lat": 36.320082530411035
  },
  {
    "name": "Knowles",
    "lng": -100.19303587188293,
    "lat": 36.87304413788784
  },
  {
    "name": "Knox",
    "lng": -99.69117200419404,
    "lat": 48.34403840927808
  },
  {
    "name": "Knox",
    "lng": -86.62096474467859,
    "lat": 41.29096107791132
  },
  {
    "name": "Knox",
    "lng": -69.21168099055852,
    "lat": 44.51777946683049
  },
  {
    "name": "Knox City",
    "lng": -99.81570339401993,
    "lat": 33.417574007223386
  },
  {
    "name": "Knox City",
    "lng": -92.01019644525653,
    "lat": 40.14415904591238
  },
  {
    "name": "Knoxville",
    "lng": -93.36322302633552,
    "lat": 35.37790201939792
  },
  {
    "name": "Knoxville",
    "lng": -93.36321664071878,
    "lat": 35.37790314731751
  },
  {
    "name": "Knoxville",
    "lng": -93.1027271588686,
    "lat": 41.31892314507528
  },
  {
    "name": "Knoxville",
    "lng": -90.28570010422922,
    "lat": 40.906931150160254
  },
  {
    "name": "Knoxville",
    "lng": -84.08202728774235,
    "lat": 35.88846470136233
  },
  {
    "name": "Knoxville",
    "lng": -84.01269235841748,
    "lat": 35.90578493702654
  },
  {
    "name": "Knoxville",
    "lng": -83.9502529060012,
    "lat": 35.96865100672198
  },
  {
    "name": "Kobuk",
    "lng": -156.8945807160555,
    "lat": 66.93127145749006
  },
  {
    "name": "Kodiak",
    "lng": -152.40805597758703,
    "lat": 57.79137459573439
  },
  {
    "name": "Kohler",
    "lng": -87.77936989429448,
    "lat": 43.736455630106875
  },
  {
    "name": "Kokomo",
    "lng": -86.12753323045413,
    "lat": 40.46385380249413
  },
  {
    "name": "Konawa",
    "lng": -96.75387213966877,
    "lat": 34.95818612571297
  },
  {
    "name": "Kongiganak",
    "lng": -162.89204924715705,
    "lat": 59.95961079968975
  },
  {
    "name": "Koosharem",
    "lng": -111.88190554682808,
    "lat": 38.51335320420036
  },
  {
    "name": "Kooskia",
    "lng": -115.97646980283078,
    "lat": 46.141349890442285
  },
  {
    "name": "Kootenai",
    "lng": -116.51727450667886,
    "lat": 48.311839803727196
  },
  {
    "name": "Kosciusko",
    "lng": -89.58947773845041,
    "lat": 33.05856758321841
  },
  {
    "name": "Koshkonong",
    "lng": -91.64599571755193,
    "lat": 36.59740470020621
  },
  {
    "name": "Kossuth",
    "lng": -88.64276237188385,
    "lat": 34.87259243997232
  },
  {
    "name": "Kotlik",
    "lng": -163.5574548733298,
    "lat": 63.03024166644146
  },
  {
    "name": "Kotzebue",
    "lng": -162.52339150710444,
    "lat": 66.87145170763498
  },
  {
    "name": "Kountze",
    "lng": -94.31592336437069,
    "lat": 30.37252695092245
  },
  {
    "name": "Kouts",
    "lng": -87.02633735082652,
    "lat": 41.31713629045227
  },
  {
    "name": "Koyuk",
    "lng": -161.15127050930013,
    "lat": 64.93960901139903
  },
  {
    "name": "Koyukuk",
    "lng": -157.69539411635822,
    "lat": 64.9012971797609
  },
  {
    "name": "Kramer",
    "lng": -100.70759366551798,
    "lat": 48.69102266564633
  },
  {
    "name": "Kranzburg",
    "lng": -96.90923658924918,
    "lat": 44.88885021931703
  },
  {
    "name": "Krebs",
    "lng": -95.71995222868556,
    "lat": 34.92993560489049
  },
  {
    "name": "Kremlin",
    "lng": -97.83231540799567,
    "lat": 36.54657091119994
  },
  {
    "name": "Kremmling",
    "lng": -106.37746345641939,
    "lat": 40.05602571378914
  },
  {
    "name": "Kronenwetter",
    "lng": -89.5805329479498,
    "lat": 44.816403321172615
  },
  {
    "name": "Krotz Springs",
    "lng": -91.75180885595132,
    "lat": 30.53231759119499
  },
  {
    "name": "Krugerville",
    "lng": -96.98812843013029,
    "lat": 33.27934678568294
  },
  {
    "name": "Krum",
    "lng": -97.22497683840645,
    "lat": 33.265407888228275
  },
  {
    "name": "Kukuihaele CDP",
    "lng": -155.56574774738786,
    "lat": 20.120358295828282
  },
  {
    "name": "Kulm",
    "lng": -98.94860231328337,
    "lat": 46.30199775995153
  },
  {
    "name": "Kuna",
    "lng": -116.45654359790882,
    "lat": 43.488679998588545
  },
  {
    "name": "Kuna",
    "lng": -116.45455670421728,
    "lat": 43.53606532361532
  },
  {
    "name": "Kuna",
    "lng": -116.44859208296353,
    "lat": 43.482944749570834
  },
  {
    "name": "Kuna",
    "lng": -116.44304775447077,
    "lat": 43.53271895340645
  },
  {
    "name": "Kuna",
    "lng": -116.42732318300567,
    "lat": 43.47477901261618
  },
  {
    "name": "Kuna",
    "lng": -116.40478024771465,
    "lat": 43.48892566612818
  },
  {
    "name": "Kuna",
    "lng": -116.39143670625066,
    "lat": 43.53811542122594
  },
  {
    "name": "Kuna",
    "lng": -116.38800952523385,
    "lat": 43.508991714847944
  },
  {
    "name": "Kuna",
    "lng": -116.3394590971597,
    "lat": 43.484594926106794
  },
  {
    "name": "Kuna",
    "lng": -116.32747554554666,
    "lat": 43.47769847626518
  },
  {
    "name": "Kuna",
    "lng": -116.32226919068167,
    "lat": 43.497232443632775
  },
  {
    "name": "Kuna",
    "lng": -116.31135604519206,
    "lat": 43.44505702321898
  },
  {
    "name": "Kuna",
    "lng": -116.30227180359962,
    "lat": 43.45502761643682
  },
  {
    "name": "Kupreanof",
    "lng": -132.99071596921888,
    "lat": 56.80964770758896
  },
  {
    "name": "Kure Beach",
    "lng": -77.90786303714094,
    "lat": 33.99882846015035
  },
  {
    "name": "Kurten",
    "lng": -96.27586518199264,
    "lat": 30.789594603442865
  },
  {
    "name": "Kurtistown CDP",
    "lng": -155.07214670648676,
    "lat": 19.583298224592962
  },
  {
    "name": "Kuttawa",
    "lng": -88.11588004341358,
    "lat": 37.05887630571537
  },
  {
    "name": "Kwethluk",
    "lng": -161.41241064754502,
    "lat": 60.79753737512175
  },
  {
    "name": "Kyle",
    "lng": -97.92451313144335,
    "lat": 30.003059422473612
  },
  {
    "name": "Kyle",
    "lng": -97.91685737219711,
    "lat": 30.006077496670656
  },
  {
    "name": "Kyle",
    "lng": -97.88563329006826,
    "lat": 29.993706698801013
  },
  {
    "name": "La Barge",
    "lng": -110.1969604753263,
    "lat": 42.26052855231304
  },
  {
    "name": "La Belle",
    "lng": -91.91459968953778,
    "lat": 40.1165348174562
  },
  {
    "name": "La Bolt",
    "lng": -96.67516310029104,
    "lat": 45.04903777299875
  },
  {
    "name": "La Cañada Flintridge",
    "lng": -118.20030360161647,
    "lat": 34.20975250973867
  },
  {
    "name": "La Center",
    "lng": -88.97494550718085,
    "lat": 37.07491866341708
  },
  {
    "name": "La Conner",
    "lng": -122.49461225630567,
    "lat": 48.392626922379065
  },
  {
    "name": "La Coste",
    "lng": -98.81163055915128,
    "lat": 29.30993331686991
  },
  {
    "name": "La Crescent",
    "lng": -91.31925100000329,
    "lat": 43.8138677011681
  },
  {
    "name": "La Crescent",
    "lng": -91.30413769198194,
    "lat": 43.830232774140335
  },
  {
    "name": "La Crosse",
    "lng": -117.92335339711629,
    "lat": 46.790929394914556
  },
  {
    "name": "La Crosse",
    "lng": -117.8804438983414,
    "lat": 46.81371903110056
  },
  {
    "name": "La Crosse",
    "lng": -117.87724118651809,
    "lat": 46.82061790107636
  },
  {
    "name": "La Crosse",
    "lng": -99.30952263045268,
    "lat": 38.53197100704065
  },
  {
    "name": "La Crosse",
    "lng": -91.25454190936068,
    "lat": 43.8546124997698
  },
  {
    "name": "La Crosse",
    "lng": -91.22815848366272,
    "lat": 43.824110002807515
  },
  {
    "name": "La Crosse",
    "lng": -91.20886416693939,
    "lat": 43.78994787487097
  },
  {
    "name": "La Crosse",
    "lng": -86.8897418571032,
    "lat": 41.31786602113298
  },
  {
    "name": "La Crosse",
    "lng": -78.094232602074,
    "lat": 36.701454262798904
  },
  {
    "name": "La Cygne",
    "lng": -94.75977810436346,
    "lat": 38.3468215562436
  },
  {
    "name": "La Due",
    "lng": -93.87766908110257,
    "lat": 38.31252307842339
  },
  {
    "name": "La Farge",
    "lng": -90.63808419594743,
    "lat": 43.57819000329577
  },
  {
    "name": "La Fargeville",
    "lng": -75.96267817348284,
    "lat": 44.19597045204575
  },
  {
    "name": "La Fayette",
    "lng": -89.97351448639255,
    "lat": 41.10965847922313
  },
  {
    "name": "La Feria",
    "lng": -97.82579902095131,
    "lat": 26.155364934146455
  },
  {
    "name": "La Feria",
    "lng": -97.82532935292767,
    "lat": 26.102274623420815
  },
  {
    "name": "La Feria",
    "lng": -97.82442522081419,
    "lat": 26.069288436300383
  },
  {
    "name": "La Feria",
    "lng": -97.82418892331089,
    "lat": 26.097093758874767
  },
  {
    "name": "La Feria",
    "lng": -97.82400553627647,
    "lat": 26.075630360763412
  },
  {
    "name": "La Feria",
    "lng": -97.8044284546047,
    "lat": 26.176662734725003
  },
  {
    "name": "La Feria",
    "lng": -97.80189469390143,
    "lat": 26.174546680399054
  },
  {
    "name": "La Feria",
    "lng": -97.80176653491857,
    "lat": 26.171522926712345
  },
  {
    "name": "La Follette",
    "lng": -84.125107280475,
    "lat": 36.37229557139922
  },
  {
    "name": "La Fontaine",
    "lng": -85.72202376426146,
    "lat": 40.6738938579288
  },
  {
    "name": "La Grange",
    "lng": -104.16300321804022,
    "lat": 41.638450206656856
  },
  {
    "name": "La Grange",
    "lng": -96.87680570108417,
    "lat": 29.912920184218514
  },
  {
    "name": "La Grange",
    "lng": -91.50235145640471,
    "lat": 40.04702920840265
  },
  {
    "name": "La Grange",
    "lng": -89.23694955241854,
    "lat": 35.04765334739136
  },
  {
    "name": "La Grange",
    "lng": -87.87415151509472,
    "lat": 41.807230922760155
  },
  {
    "name": "La Grange",
    "lng": -85.37673722098124,
    "lat": 38.39904307629111
  },
  {
    "name": "La Grange",
    "lng": -77.80269977951295,
    "lat": 35.32394101116801
  },
  {
    "name": "La Grange",
    "lng": -77.78945962708494,
    "lat": 35.305517347087736
  },
  {
    "name": "La Grange",
    "lng": -77.77492034806029,
    "lat": 35.32146752562537
  },
  {
    "name": "La Grange",
    "lng": -77.75786423539263,
    "lat": 35.288238152860536
  },
  {
    "name": "La Grange Park",
    "lng": -87.87230109132858,
    "lat": 41.83076264593608
  },
  {
    "name": "La Grulla",
    "lng": -98.64845371256057,
    "lat": 26.272121183488924
  },
  {
    "name": "La Habra",
    "lng": -117.95152692949583,
    "lat": 33.92819661783034
  },
  {
    "name": "La Habra Heights",
    "lng": -117.95097071603394,
    "lat": 33.960258975389564
  },
  {
    "name": "La Harpe",
    "lng": -95.30251642844247,
    "lat": 37.916908983561015
  },
  {
    "name": "La Harpe",
    "lng": -90.96956286568475,
    "lat": 40.58478865705796
  },
  {
    "name": "La Jara",
    "lng": -105.96057760407226,
    "lat": 37.27374849724424
  },
  {
    "name": "La Joya",
    "lng": -98.46996547845377,
    "lat": 26.251836053971587
  },
  {
    "name": "La Joya",
    "lng": -98.45888450725423,
    "lat": 26.244407446470667
  },
  {
    "name": "La Junta",
    "lng": -103.54718496226303,
    "lat": 37.97970281924991
  },
  {
    "name": "La Marque",
    "lng": -94.99548695496198,
    "lat": 29.368855106684016
  },
  {
    "name": "La Mesa",
    "lng": -117.02033898409324,
    "lat": 32.77049580909398
  },
  {
    "name": "La Moille",
    "lng": -89.28327072553103,
    "lat": 41.53015036203958
  },
  {
    "name": "La Monte",
    "lng": -93.42365850903403,
    "lat": 38.77197797997271
  },
  {
    "name": "La Motte",
    "lng": -90.62337616366591,
    "lat": 42.29562853741766
  },
  {
    "name": "La Palma",
    "lng": -118.04067265812365,
    "lat": 33.85036001131567
  },
  {
    "name": "La Paz",
    "lng": -86.30970198502848,
    "lat": 41.45660048065033
  },
  {
    "name": "La Plata",
    "lng": -92.491673730737,
    "lat": 40.023615848390406
  },
  {
    "name": "La Plata",
    "lng": -76.96989693432126,
    "lat": 38.535197685259554
  },
  {
    "name": "La Porte",
    "lng": -95.04917158017193,
    "lat": 29.66756407985514
  },
  {
    "name": "La Porte",
    "lng": -95.00839696731859,
    "lat": 29.616070964309948
  },
  {
    "name": "La Porte",
    "lng": -86.71399406373939,
    "lat": 41.60754156640237
  },
  {
    "name": "La Porte City",
    "lng": -92.19131913415782,
    "lat": 42.31377325594578
  },
  {
    "name": "La Prairie",
    "lng": -93.49138031282139,
    "lat": 47.23010119867237
  },
  {
    "name": "La Prairie",
    "lng": -91.00243816667567,
    "lat": 40.147276957672204
  },
  {
    "name": "La Puente",
    "lng": -117.95406540838465,
    "lat": 34.03273121834735
  },
  {
    "name": "La Quinta",
    "lng": -116.27848241029409,
    "lat": 33.653566803870426
  },
  {
    "name": "La Rose",
    "lng": -89.23493513723082,
    "lat": 40.978734555926216
  },
  {
    "name": "La Rue",
    "lng": -83.38212465172185,
    "lat": 40.578102663705174
  },
  {
    "name": "La Russell",
    "lng": -94.06095442614257,
    "lat": 37.14022315849303
  },
  {
    "name": "La Salle",
    "lng": -104.70584281183162,
    "lat": 40.348936065305395
  },
  {
    "name": "La Salle",
    "lng": -94.57102059396425,
    "lat": 44.07102754713408
  },
  {
    "name": "La Tour",
    "lng": -94.10190219439383,
    "lat": 38.634641443882636
  },
  {
    "name": "La Vergne",
    "lng": -86.55731751947275,
    "lat": 36.02121165194543
  },
  {
    "name": "La Verkin",
    "lng": -113.25054437130385,
    "lat": 37.23154337101587
  },
  {
    "name": "La Vernia",
    "lng": -98.12209512384759,
    "lat": 29.354210219911035
  },
  {
    "name": "La Veta",
    "lng": -105.00848631009376,
    "lat": 37.50856445467132
  },
  {
    "name": "La Villa",
    "lng": -97.92713293714323,
    "lat": 26.296220797424795
  },
  {
    "name": "La Villa",
    "lng": -97.91589381351977,
    "lat": 26.301073626995468
  },
  {
    "name": "La Vista",
    "lng": -96.05935787214315,
    "lat": 41.182364774162245
  },
  {
    "name": "La Ward",
    "lng": -96.46487645303662,
    "lat": 28.846442572810023
  },
  {
    "name": "LaBelle",
    "lng": -81.45067077712874,
    "lat": 26.72165390630397
  },
  {
    "name": "LaFayette",
    "lng": -87.65860562845997,
    "lat": 36.66005005721682
  },
  {
    "name": "LaFayette",
    "lng": -85.40068809406885,
    "lat": 32.89958608004554
  },
  {
    "name": "LaFayette",
    "lng": -85.28153139975376,
    "lat": 34.708647459249384
  },
  {
    "name": "LaGrange",
    "lng": -90.73474680734469,
    "lat": 34.65575856900559
  },
  {
    "name": "LaGrange",
    "lng": -85.0347905263203,
    "lat": 33.03335411097997
  },
  {
    "name": "LaGrange",
    "lng": -82.1181326248024,
    "lat": 41.24011251049902
  },
  {
    "name": "LaGrange",
    "lng": -68.80720720000373,
    "lat": 45.167612480024154
  },
  {
    "name": "LaMoure",
    "lng": -98.29610550449578,
    "lat": 46.35716925450646
  },
  {
    "name": "LaSalle",
    "lng": -89.07189974154164,
    "lat": 41.357399668155345
  },
  {
    "name": "Labette",
    "lng": -95.18355229878703,
    "lat": 37.22999613769773
  },
  {
    "name": "Lac du Flambeau",
    "lng": -89.89934742318768,
    "lat": 45.96960978545077
  },
  {
    "name": "Lacey",
    "lng": -122.79296455405871,
    "lat": 47.045135300992555
  },
  {
    "name": "Lackawanna",
    "lng": -78.83234451358622,
    "lat": 42.818177658215546
  },
  {
    "name": "Laclede",
    "lng": -93.16969920431852,
    "lat": 39.78784709861522
  },
  {
    "name": "Lacon",
    "lng": -89.40613539992609,
    "lat": 41.022728665920354
  },
  {
    "name": "Lacona",
    "lng": -93.3848665654039,
    "lat": 41.18942010621833
  },
  {
    "name": "Lacona",
    "lng": -76.06659377772245,
    "lat": 43.64358348056605
  },
  {
    "name": "Lacona",
    "lng": -76.06658862493326,
    "lat": 43.64358257326305
  },
  {
    "name": "Laconia",
    "lng": -86.08558237351076,
    "lat": 38.031746597853605
  },
  {
    "name": "Lacy-Lakeview",
    "lng": -97.10627702686094,
    "lat": 31.627885717137907
  },
  {
    "name": "Lacy-Lakeview",
    "lng": -97.08912578906528,
    "lat": 31.646039554407743
  },
  {
    "name": "Ladd",
    "lng": -89.21433950185,
    "lat": 41.38153657569207
  },
  {
    "name": "Laddonia",
    "lng": -91.64238085568489,
    "lat": 39.243359938709055
  },
  {
    "name": "Ladera",
    "lng": -122.19876882559457,
    "lat": 37.39969493450141
  },
  {
    "name": "Ladoga",
    "lng": -86.79849249440718,
    "lat": 39.916747520420465
  },
  {
    "name": "Ladonia",
    "lng": -95.9482214997996,
    "lat": 33.428377458969
  },
  {
    "name": "Ladora",
    "lng": -92.18606140628162,
    "lat": 41.75573263075724
  },
  {
    "name": "Ladue",
    "lng": -90.38150132935634,
    "lat": 38.63782083425686
  },
  {
    "name": "Ladysmith",
    "lng": -91.12988537488658,
    "lat": 45.46414664540878
  },
  {
    "name": "Ladysmith",
    "lng": -91.09701200348442,
    "lat": 45.4613736591582
  },
  {
    "name": "Lafayette",
    "lng": -122.11888386022827,
    "lat": 37.891887843066726
  },
  {
    "name": "Lafayette",
    "lng": -105.09954855908235,
    "lat": 39.99356528818867
  },
  {
    "name": "Lafayette",
    "lng": -94.39270619152073,
    "lat": 44.447122770223864
  },
  {
    "name": "Lafayette",
    "lng": -92.07050255076722,
    "lat": 30.281355191702737
  },
  {
    "name": "Lafayette",
    "lng": -92.0321078745876,
    "lat": 30.20819129756588
  },
  {
    "name": "Lafayette",
    "lng": -86.86399455372914,
    "lat": 40.400762613307464
  },
  {
    "name": "Lafayette",
    "lng": -86.03076887819965,
    "lat": 36.524165085868326
  },
  {
    "name": "Lafayette",
    "lng": -83.94990497761837,
    "lat": 40.75891344725211
  },
  {
    "name": "Lafe",
    "lng": -90.50428146370966,
    "lat": 36.20917748820826
  },
  {
    "name": "Lago Vista",
    "lng": -97.99140111871553,
    "lat": 30.451891068099496
  },
  {
    "name": "Lagrange",
    "lng": -85.41781145323709,
    "lat": 41.6489106107296
  },
  {
    "name": "Lagro",
    "lng": -85.72762303383169,
    "lat": 40.83909219699041
  },
  {
    "name": "Laguna Beach",
    "lng": -117.78591300782918,
    "lat": 33.514031710385765
  },
  {
    "name": "Laguna Hills",
    "lng": -117.69920730176426,
    "lat": 33.59199748615071
  },
  {
    "name": "Laguna Niguel",
    "lng": -117.70498340491235,
    "lat": 33.52748703820056
  },
  {
    "name": "Laguna Vista",
    "lng": -97.29682473622918,
    "lat": 26.107490200985417
  },
  {
    "name": "Laguna Woods",
    "lng": -117.72991453305775,
    "lat": 33.60989394293075
  },
  {
    "name": "Lahaina",
    "lng": -156.67471004207638,
    "lat": 20.888168405120496
  },
  {
    "name": "Lahoma",
    "lng": -98.08873674223103,
    "lat": 36.38811052829326
  },
  {
    "name": "Laingsburg",
    "lng": -84.34993371311066,
    "lat": 42.89070036831501
  },
  {
    "name": "Lake",
    "lng": -89.32946915434653,
    "lat": 32.340673251135435
  },
  {
    "name": "Lake Alfred",
    "lng": -81.72943848162863,
    "lat": 28.103531812594113
  },
  {
    "name": "Lake Alfred",
    "lng": -81.69151043013548,
    "lat": 28.1047933065896
  },
  {
    "name": "Lake Alfred",
    "lng": -81.69078569282435,
    "lat": 28.110676993830555
  },
  {
    "name": "Lake Alfred",
    "lng": -81.68268540935209,
    "lat": 28.1077560189987
  },
  {
    "name": "Lake Aluma",
    "lng": -97.448362243147,
    "lat": 35.53335217651656
  },
  {
    "name": "Lake Andes",
    "lng": -98.53653704632332,
    "lat": 43.1559063809795
  },
  {
    "name": "Lake Angelus",
    "lng": -83.32142956666434,
    "lat": 42.69212587222852
  },
  {
    "name": "Lake Ann",
    "lng": -85.84512315384636,
    "lat": 44.725163595976795
  },
  {
    "name": "Lake Annette",
    "lng": -94.50806542918399,
    "lat": 38.65436224222693
  },
  {
    "name": "Lake Arthur",
    "lng": -104.36406480784423,
    "lat": 32.99945634133138
  },
  {
    "name": "Lake Arthur",
    "lng": -92.67734083891791,
    "lat": 30.07951586712056
  },
  {
    "name": "Lake Barrington",
    "lng": -88.16754905877552,
    "lat": 42.21271032232845
  },
  {
    "name": "Lake Benton",
    "lng": -96.28947490470458,
    "lat": 44.264127272509754
  },
  {
    "name": "Lake Benton",
    "lng": -96.26150065251795,
    "lat": 44.26387581772779
  },
  {
    "name": "Lake Bluff",
    "lng": -87.85089596257224,
    "lat": 42.28249474437206
  },
  {
    "name": "Lake Bridgeport",
    "lng": -97.83095483733685,
    "lat": 33.20729886020867
  },
  {
    "name": "Lake Bronson",
    "lng": -96.6640869749418,
    "lat": 48.73222415457172
  },
  {
    "name": "Lake Bungee",
    "lng": -72.06520720850594,
    "lat": 41.95425059469279
  },
  {
    "name": "Lake Butler",
    "lng": -82.33914171303238,
    "lat": 30.021087499066887
  },
  {
    "name": "Lake Butler",
    "lng": -82.32403872563313,
    "lat": 30.022159603450305
  },
  {
    "name": "Lake Chaffee",
    "lng": -72.19600040677953,
    "lat": 41.938882752343176
  },
  {
    "name": "Lake Charles",
    "lng": -93.25117389584673,
    "lat": 30.163612062341006
  },
  {
    "name": "Lake Charles",
    "lng": -93.21456328228611,
    "lat": 30.203384480912387
  },
  {
    "name": "Lake City",
    "lng": -107.31036922107403,
    "lat": 38.03015947750956
  },
  {
    "name": "Lake City",
    "lng": -97.88263952144266,
    "lat": 28.082174348624648
  },
  {
    "name": "Lake City",
    "lng": -97.41384042647893,
    "lat": 45.72495717469934
  },
  {
    "name": "Lake City",
    "lng": -94.73093668998526,
    "lat": 42.26763825335786
  },
  {
    "name": "Lake City",
    "lng": -92.27951981491702,
    "lat": 44.445405238190204
  },
  {
    "name": "Lake City",
    "lng": -90.45415253738832,
    "lat": 35.82023534662629
  },
  {
    "name": "Lake City",
    "lng": -85.20845499385099,
    "lat": 44.3296735482481
  },
  {
    "name": "Lake City",
    "lng": -84.34109824613215,
    "lat": 33.606461097718125
  },
  {
    "name": "Lake City",
    "lng": -82.6455173984534,
    "lat": 30.190983568634373
  },
  {
    "name": "Lake City",
    "lng": -79.7879979579703,
    "lat": 33.86996438181678
  },
  {
    "name": "Lake City",
    "lng": -79.78766186245812,
    "lat": 33.868019815331046
  },
  {
    "name": "Lake City",
    "lng": -79.75530773178659,
    "lat": 33.867641141586056
  },
  {
    "name": "Lake Clarke Shores",
    "lng": -80.07534468085231,
    "lat": 26.64581001165613
  },
  {
    "name": "Lake Crystal",
    "lng": -94.22031865076613,
    "lat": 44.10430045585225
  },
  {
    "name": "Lake Crystal",
    "lng": -94.21624773878005,
    "lat": 44.12252486069355
  },
  {
    "name": "Lake Crystal",
    "lng": -94.20075871354544,
    "lat": 44.116574446807775
  },
  {
    "name": "Lake Delton",
    "lng": -89.78418360111624,
    "lat": 43.593171139876084
  },
  {
    "name": "Lake Elmo",
    "lng": -92.90313345626524,
    "lat": 44.99439506443466
  },
  {
    "name": "Lake Everett",
    "lng": -85.31477634959742,
    "lat": 41.15582385449673
  },
  {
    "name": "Lake Forest",
    "lng": -117.67131469204362,
    "lat": 33.66055494150435
  },
  {
    "name": "Lake Forest",
    "lng": -87.85958551056807,
    "lat": 42.2380727808266
  },
  {
    "name": "Lake Forest Park",
    "lng": -122.28772992377183,
    "lat": 47.75928444015366
  },
  {
    "name": "Lake Geneva",
    "lng": -88.45859821824835,
    "lat": 42.59641719910766
  },
  {
    "name": "Lake Geneva",
    "lng": -88.43026274917001,
    "lat": 42.58351426255474
  },
  {
    "name": "Lake Geneva",
    "lng": -88.40273408230298,
    "lat": 42.55877377949532
  },
  {
    "name": "Lake George Village",
    "lng": -73.71518455079915,
    "lat": 43.42596769273165
  },
  {
    "name": "Lake Grove",
    "lng": -73.1165492180657,
    "lat": 40.85865348305544
  },
  {
    "name": "Lake Hallie",
    "lng": -91.42004351650127,
    "lat": 44.892061175022334
  },
  {
    "name": "Lake Hamilton",
    "lng": -81.62702158530645,
    "lat": 28.046959267195813
  },
  {
    "name": "Lake Havasu City",
    "lng": -114.35980860504884,
    "lat": 34.46083751933547
  },
  {
    "name": "Lake Havasu City",
    "lng": -114.30915065671327,
    "lat": 34.50149654815542
  },
  {
    "name": "Lake Helen",
    "lng": -81.23116323954284,
    "lat": 28.983388194209265
  },
  {
    "name": "Lake Helen",
    "lng": -81.230710305006,
    "lat": 28.962107447815438
  },
  {
    "name": "Lake Helen",
    "lng": -81.22618976933921,
    "lat": 28.958722351116613
  },
  {
    "name": "Lake Helen",
    "lng": -81.21661802038598,
    "lat": 28.967128653360678
  },
  {
    "name": "Lake Henry",
    "lng": -94.7964092143969,
    "lat": 45.46188276158224
  },
  {
    "name": "Lake Isabella",
    "lng": -85.00304168353429,
    "lat": 43.64159181859977
  },
  {
    "name": "Lake Ka-Ho",
    "lng": -89.7463118861335,
    "lat": 39.099800525429394
  },
  {
    "name": "Lake Lafayette",
    "lng": -93.971375758157,
    "lat": 38.948720705412995
  },
  {
    "name": "Lake Lillian",
    "lng": -94.88006307161534,
    "lat": 44.94590288055185
  },
  {
    "name": "Lake Linden",
    "lng": -88.40507601248537,
    "lat": 47.19368132711829
  },
  {
    "name": "Lake Lotawana",
    "lng": -94.25407491953551,
    "lat": 38.927474369338384
  },
  {
    "name": "Lake Lure",
    "lng": -82.24516143037097,
    "lat": 35.438519116020274
  },
  {
    "name": "Lake Lure",
    "lng": -82.20541266606627,
    "lat": 35.441381725460396
  },
  {
    "name": "Lake Mills",
    "lng": -93.53143575815545,
    "lat": 43.417000713977636
  },
  {
    "name": "Lake Mills",
    "lng": -88.91986104808369,
    "lat": 43.0941881531947
  },
  {
    "name": "Lake Mills",
    "lng": -88.90564720441643,
    "lat": 43.07699336502527
  },
  {
    "name": "Lake Mykee Town",
    "lng": -92.10128458755885,
    "lat": 38.67629991353415
  },
  {
    "name": "Lake Nebagamon",
    "lng": -91.69954057629901,
    "lat": 46.51187220399263
  },
  {
    "name": "Lake Norden",
    "lng": -97.20959167047991,
    "lat": 44.579043201243756
  },
  {
    "name": "Lake Odessa",
    "lng": -85.1374238241124,
    "lat": 42.782432227980124
  },
  {
    "name": "Lake Orion",
    "lng": -83.24523126968533,
    "lat": 42.782727982887174
  },
  {
    "name": "Lake Ozark",
    "lng": -92.62462782771192,
    "lat": 38.2033846039502
  },
  {
    "name": "Lake Park",
    "lng": -96.09799201131159,
    "lat": 46.88382075164144
  },
  {
    "name": "Lake Park",
    "lng": -95.32434168610914,
    "lat": 43.447580251581044
  },
  {
    "name": "Lake Park",
    "lng": -83.18758746432765,
    "lat": 30.684970128964267
  },
  {
    "name": "Lake Park",
    "lng": -80.63472495567316,
    "lat": 35.08473049213726
  },
  {
    "name": "Lake Park",
    "lng": -80.06933485211293,
    "lat": 26.799733590056057
  },
  {
    "name": "Lake Placid",
    "lng": -81.37195759867639,
    "lat": 27.296723819577068
  },
  {
    "name": "Lake Placid",
    "lng": -73.98523421674439,
    "lat": 44.28371265647267
  },
  {
    "name": "Lake Preston",
    "lng": -97.37617754713692,
    "lat": 44.3618443777165
  },
  {
    "name": "Lake Providence",
    "lng": -91.1824741215859,
    "lat": 32.81324007477396
  },
  {
    "name": "Lake Quivira",
    "lng": -94.76870041988909,
    "lat": 39.03875468091043
  },
  {
    "name": "Lake Saint Louis",
    "lng": -90.79525457948425,
    "lat": 38.759622205267206
  },
  {
    "name": "Lake Saint Louis",
    "lng": -90.7876436249889,
    "lat": 38.78494142998792
  },
  {
    "name": "Lake Santeetlah",
    "lng": -83.86631533200432,
    "lat": 35.36361076797354
  },
  {
    "name": "Lake Shore",
    "lng": -94.363542936144,
    "lat": 46.50399671425821
  },
  {
    "name": "Lake St. Croix Beach",
    "lng": -92.76645032753173,
    "lat": 44.92438725806614
  },
  {
    "name": "Lake Station",
    "lng": -87.26921924791026,
    "lat": 41.55132399912365
  },
  {
    "name": "Lake Station",
    "lng": -87.26499649666764,
    "lat": 41.55620379563259
  },
  {
    "name": "Lake Station",
    "lng": -87.25997095957999,
    "lat": 41.555911968796345
  },
  {
    "name": "Lake Station",
    "lng": -87.25990834835575,
    "lat": 41.57324596580141
  },
  {
    "name": "Lake Stevens",
    "lng": -122.09252930075222,
    "lat": 48.00173013382332
  },
  {
    "name": "Lake Success",
    "lng": -73.70886668305866,
    "lat": 40.768037354162374
  },
  {
    "name": "Lake Tanglewood",
    "lng": -101.78184252710209,
    "lat": 35.057503417672855
  },
  {
    "name": "Lake Tanglewood",
    "lng": -101.77728963681234,
    "lat": 35.06274277348233
  },
  {
    "name": "Lake Tapawingo",
    "lng": -94.31264786230055,
    "lat": 39.018967564741224
  },
  {
    "name": "Lake View",
    "lng": -95.04108031699896,
    "lat": 42.30478892651815
  },
  {
    "name": "Lake View",
    "lng": -90.81333027788459,
    "lat": 34.421157350216895
  },
  {
    "name": "Lake View",
    "lng": -87.13864828081584,
    "lat": 33.281586940263445
  },
  {
    "name": "Lake View",
    "lng": -79.18253962971555,
    "lat": 34.337378951082925
  },
  {
    "name": "Lake View",
    "lng": -79.1663024671144,
    "lat": 34.34061729673443
  },
  {
    "name": "Lake Villa",
    "lng": -88.11422869233277,
    "lat": 42.41802061101366
  },
  {
    "name": "Lake Villa",
    "lng": -88.08250688704695,
    "lat": 42.418507833494466
  },
  {
    "name": "Lake Village",
    "lng": -91.28470758176643,
    "lat": 33.328648100267536
  },
  {
    "name": "Lake Waccamaw",
    "lng": -78.50629955788331,
    "lat": 34.30757673689138
  },
  {
    "name": "Lake Wilson",
    "lng": -95.9548994508074,
    "lat": 43.995502195129774
  },
  {
    "name": "Lake Winnebago",
    "lng": -94.35961479109349,
    "lat": 38.82373069940087
  },
  {
    "name": "Lake Worth",
    "lng": -97.43067295614817,
    "lat": 32.81300679480629
  },
  {
    "name": "Lake Zurich",
    "lng": -88.08750916005751,
    "lat": 42.1957284448523
  },
  {
    "name": "Lake in the Hills",
    "lng": -88.34743020871954,
    "lat": 42.19135460682417
  },
  {
    "name": "Lakefield",
    "lng": -95.16947909090078,
    "lat": 43.67800182208402
  },
  {
    "name": "Lakeland",
    "lng": -92.77091805564129,
    "lat": 44.950157708241896
  },
  {
    "name": "Lakeland",
    "lng": -89.73072211369396,
    "lat": 35.25851220885938
  },
  {
    "name": "Lakeland",
    "lng": -83.07486295925092,
    "lat": 31.040981961914124
  },
  {
    "name": "Lakeland",
    "lng": -81.95398714526434,
    "lat": 28.05569348016459
  },
  {
    "name": "Lakeland Shores",
    "lng": -92.76185977156466,
    "lat": 44.94801780349325
  },
  {
    "name": "Lakeline",
    "lng": -81.45455960717041,
    "lat": 41.65945824548082
  },
  {
    "name": "Lakemoor",
    "lng": -88.20282189626647,
    "lat": 42.339576856528176
  },
  {
    "name": "Lakemore",
    "lng": -81.42573084914645,
    "lat": 41.02140401097651
  },
  {
    "name": "Lakeport",
    "lng": -122.92275329435795,
    "lat": 39.01512747468938
  },
  {
    "name": "Lakeport",
    "lng": -122.92173103193436,
    "lat": 39.04453077920976
  },
  {
    "name": "Lakeport",
    "lng": -94.70902722943991,
    "lat": 32.40512865820664
  },
  {
    "name": "Lakeshire",
    "lng": -90.33830495243795,
    "lat": 38.53985288748266
  },
  {
    "name": "Lakeside",
    "lng": -105.05795041331777,
    "lat": 39.7802336487696
  },
  {
    "name": "Lakeside",
    "lng": -97.86112412304075,
    "lat": 28.103079506382976
  },
  {
    "name": "Lakeside",
    "lng": -97.48922976882686,
    "lat": 32.8219065470715
  },
  {
    "name": "Lakeside",
    "lng": -95.17742672002657,
    "lat": 42.61746758004002
  },
  {
    "name": "Lakeside",
    "lng": -92.62232236209483,
    "lat": 38.20394189835352
  },
  {
    "name": "Lakeside City",
    "lng": -98.54021445056739,
    "lat": 33.82918116900298
  },
  {
    "name": "Lakeside Park",
    "lng": -84.56721136167727,
    "lat": 39.03386906182439
  },
  {
    "name": "Lakesite",
    "lng": -85.14076331281261,
    "lat": 35.20275795203553
  },
  {
    "name": "Laketown",
    "lng": -111.32417961459994,
    "lat": 41.827513813075385
  },
  {
    "name": "Lakeview",
    "lng": -100.699934887278,
    "lat": 34.668976555267086
  },
  {
    "name": "Lakeview",
    "lng": -100.69869339337313,
    "lat": 34.66898479667574
  },
  {
    "name": "Lakeview",
    "lng": -100.69718899971885,
    "lat": 34.67285479650162
  },
  {
    "name": "Lakeview",
    "lng": -92.5387326454772,
    "lat": 36.37390679975884
  },
  {
    "name": "Lakeview",
    "lng": -85.98640847804613,
    "lat": 34.383611566839
  },
  {
    "name": "Lakeview",
    "lng": -85.97607073810488,
    "lat": 34.39098116657694
  },
  {
    "name": "Lakeview",
    "lng": -85.96940117819642,
    "lat": 34.4000570173873
  },
  {
    "name": "Lakeview",
    "lng": -85.96732376316297,
    "lat": 34.394659483872495
  },
  {
    "name": "Lakeview",
    "lng": -85.96294528638043,
    "lat": 34.39817194635751
  },
  {
    "name": "Lakeview",
    "lng": -85.2732955616812,
    "lat": 43.443186812138144
  },
  {
    "name": "Lakeview",
    "lng": -83.92637623443531,
    "lat": 40.48755389413575
  },
  {
    "name": "Lakeview Heights",
    "lng": -83.50607540473221,
    "lat": 38.15217208346305
  },
  {
    "name": "Lakeville",
    "lng": -93.25177168252884,
    "lat": 44.67709908690693
  },
  {
    "name": "Lakeville",
    "lng": -86.27448398420026,
    "lat": 41.528693195317786
  },
  {
    "name": "Lakeville",
    "lng": -68.1066514342769,
    "lat": 45.30128311126588
  },
  {
    "name": "Lakeway",
    "lng": -97.98659797596498,
    "lat": 30.355003120522465
  },
  {
    "name": "Lakeway",
    "lng": -97.9859086999101,
    "lat": 30.327445585785004
  },
  {
    "name": "Lakeway",
    "lng": -97.95099984337283,
    "lat": 30.38046349619738
  },
  {
    "name": "Lakewood",
    "lng": -122.57919871104679,
    "lat": 47.16487646314681
  },
  {
    "name": "Lakewood",
    "lng": -122.57446700922718,
    "lat": 47.16875402046259
  },
  {
    "name": "Lakewood",
    "lng": -122.52984252106869,
    "lat": 47.162661095522495
  },
  {
    "name": "Lakewood",
    "lng": -118.12211666372168,
    "lat": 33.84709342186113
  },
  {
    "name": "Lakewood",
    "lng": -105.11719868259102,
    "lat": 39.698363261578315
  },
  {
    "name": "Lakewood",
    "lng": -105.11006277405527,
    "lat": 39.63087023071864
  },
  {
    "name": "Lakewood",
    "lng": -105.08150300787271,
    "lat": 39.622250062298384
  },
  {
    "name": "Lakewood",
    "lng": -88.39346981251086,
    "lat": 42.22792586694346
  },
  {
    "name": "Lakewood",
    "lng": -81.80156957077428,
    "lat": 41.4846590874046
  },
  {
    "name": "Lakewood",
    "lng": -79.32009574747367,
    "lat": 42.099160557834544
  },
  {
    "name": "Lakewood Club",
    "lng": -86.25633598119796,
    "lat": 43.37607668434911
  },
  {
    "name": "Lakewood Village",
    "lng": -96.9750114224139,
    "lat": 33.137801839855335
  },
  {
    "name": "Lakin",
    "lng": -101.25872181854328,
    "lat": 37.939739039309295
  },
  {
    "name": "Lakota",
    "lng": -98.34678530405475,
    "lat": 48.04266773285941
  },
  {
    "name": "Lakota",
    "lng": -94.09374178919828,
    "lat": 43.37754289499319
  },
  {
    "name": "Lamar",
    "lng": -102.61776588647797,
    "lat": 38.0803358815053
  },
  {
    "name": "Lamar",
    "lng": -101.979263776666,
    "lat": 40.57247452996465
  },
  {
    "name": "Lamar",
    "lng": -96.12376779578662,
    "lat": 35.09419164940584
  },
  {
    "name": "Lamar",
    "lng": -94.27844614783083,
    "lat": 37.4944481523089
  },
  {
    "name": "Lamar",
    "lng": -93.39508689638816,
    "lat": 35.44381090906979
  },
  {
    "name": "Lamar",
    "lng": -93.38909988185979,
    "lat": 35.429433478403126
  },
  {
    "name": "Lamar",
    "lng": -80.06507088640745,
    "lat": 34.16945893274085
  },
  {
    "name": "Lamar Heights",
    "lng": -94.29554141289059,
    "lat": 37.49378072818959
  },
  {
    "name": "Lambert",
    "lng": -98.42365239337141,
    "lat": 36.683236391339655
  },
  {
    "name": "Lambert",
    "lng": -89.55479070087424,
    "lat": 37.09375742812724
  },
  {
    "name": "Lamberton",
    "lng": -95.28375903054297,
    "lat": 44.23123646548093
  },
  {
    "name": "Lamberton",
    "lng": -95.26585876451136,
    "lat": 44.23057449476266
  },
  {
    "name": "Lambertville",
    "lng": -74.94272390251085,
    "lat": 40.36857200504392
  },
  {
    "name": "Lambs Grove",
    "lng": -93.0795481293261,
    "lat": 41.700776911721256
  },
  {
    "name": "Lame Deer",
    "lng": -106.61342126558561,
    "lat": 45.61767779858399
  },
  {
    "name": "Lamesa",
    "lng": -101.95397428343759,
    "lat": 32.73328191934927
  },
  {
    "name": "Lamesa",
    "lng": -101.95031611710948,
    "lat": 32.75170047280744
  },
  {
    "name": "Lamoine",
    "lng": -68.31438678787855,
    "lat": 44.484055617391306
  },
  {
    "name": "Lamoni",
    "lng": -93.93145277401216,
    "lat": 40.62015303917779
  },
  {
    "name": "Lamoni",
    "lng": -93.89435521876165,
    "lat": 40.624422508113064
  },
  {
    "name": "Lamont",
    "lng": -118.91582341051162,
    "lat": 35.26512951228988
  },
  {
    "name": "Lamont",
    "lng": -117.90512434464013,
    "lat": 47.200507441821244
  },
  {
    "name": "Lamont",
    "lng": -97.5656407832951,
    "lat": 36.692130081836034
  },
  {
    "name": "Lamont",
    "lng": -97.5582038988904,
    "lat": 36.691315829901946
  },
  {
    "name": "Lamont",
    "lng": -91.64037096797387,
    "lat": 42.59854806110653
  },
  {
    "name": "Lampasas",
    "lng": -98.18519908401825,
    "lat": 31.064838075193972
  },
  {
    "name": "Lampasas",
    "lng": -98.14841904565452,
    "lat": 31.053849994945452
  },
  {
    "name": "Lanagan",
    "lng": -94.45172443140031,
    "lat": 36.60616837634425
  },
  {
    "name": "Lanark",
    "lng": -89.83285566835764,
    "lat": 42.10197814805006
  },
  {
    "name": "Lancaster",
    "lng": -118.17556004858572,
    "lat": 34.69338496904378
  },
  {
    "name": "Lancaster",
    "lng": -96.80191807365458,
    "lat": 48.85776217471419
  },
  {
    "name": "Lancaster",
    "lng": -96.78121346344923,
    "lat": 32.59555266144776
  },
  {
    "name": "Lancaster",
    "lng": -95.30371589886963,
    "lat": 39.571273021713324
  },
  {
    "name": "Lancaster",
    "lng": -92.53134114477297,
    "lat": 40.52387630635524
  },
  {
    "name": "Lancaster",
    "lng": -90.70647455100641,
    "lat": 42.84742683132766
  },
  {
    "name": "Lancaster",
    "lng": -84.58133486435084,
    "lat": 37.61523732385969
  },
  {
    "name": "Lancaster",
    "lng": -82.60488413432641,
    "lat": 39.72489626063019
  },
  {
    "name": "Lancaster",
    "lng": -80.80774057304605,
    "lat": 34.71427717393842
  },
  {
    "name": "Lancaster",
    "lng": -80.77994922019712,
    "lat": 34.724628911264666
  },
  {
    "name": "Lancaster",
    "lng": -80.76305590781699,
    "lat": 34.705361805039104
  },
  {
    "name": "Lancaster",
    "lng": -76.30075900847677,
    "lat": 40.04197564493883
  },
  {
    "name": "Lancaster",
    "lng": -71.68082374505613,
    "lat": 42.481729702732885
  },
  {
    "name": "Landa",
    "lng": -100.91206238509746,
    "lat": 48.89586385020356
  },
  {
    "name": "Lander",
    "lng": -108.80801188120827,
    "lat": 42.74933804917262
  },
  {
    "name": "Lander",
    "lng": -108.76075816714857,
    "lat": 42.831198868004755
  },
  {
    "name": "Lander",
    "lng": -108.71732289358907,
    "lat": 42.848735405302975
  },
  {
    "name": "Lander",
    "lng": -108.68635606815788,
    "lat": 42.8471646659886
  },
  {
    "name": "Landfall",
    "lng": -92.97715317315918,
    "lat": 44.950486260860124
  },
  {
    "name": "Landis",
    "lng": -80.63951580165413,
    "lat": 35.58613390540816
  },
  {
    "name": "Landis",
    "lng": -80.60918711783536,
    "lat": 35.54896310007095
  },
  {
    "name": "Landis",
    "lng": -80.60336933944198,
    "lat": 35.538353791101486
  },
  {
    "name": "Landis",
    "lng": -80.59640635608615,
    "lat": 35.53122925308955
  },
  {
    "name": "Landover Hills",
    "lng": -76.89464701860501,
    "lat": 38.94244024446784
  },
  {
    "name": "Landrum",
    "lng": -82.1869162717977,
    "lat": 35.1746932997854
  },
  {
    "name": "Landrum",
    "lng": -82.16134559372796,
    "lat": 35.18425898650898
  },
  {
    "name": "Lane",
    "lng": -98.4246204360918,
    "lat": 44.06968912954445
  },
  {
    "name": "Lane",
    "lng": -95.08131929711506,
    "lat": 38.44019395676745
  },
  {
    "name": "Lane",
    "lng": -79.8796702928808,
    "lat": 33.525137591568374
  },
  {
    "name": "Lanesboro",
    "lng": -94.6924157719481,
    "lat": 42.18273651459384
  },
  {
    "name": "Lanesboro",
    "lng": -91.9707166666477,
    "lat": 43.718248566504975
  },
  {
    "name": "Lanesborough",
    "lng": -73.2394029048712,
    "lat": 42.53174936589989
  },
  {
    "name": "Lanesville",
    "lng": -85.98583950437205,
    "lat": 38.23498379431863
  },
  {
    "name": "Lanett",
    "lng": -85.23609392370835,
    "lat": 32.891517014453584
  },
  {
    "name": "Lanett",
    "lng": -85.23009637085191,
    "lat": 32.811709488838915
  },
  {
    "name": "Lanett",
    "lng": -85.22211267308653,
    "lat": 32.87347099806316
  },
  {
    "name": "Lanett",
    "lng": -85.20774371316728,
    "lat": 32.88847329398655
  },
  {
    "name": "Lanett",
    "lng": -85.20441473476095,
    "lat": 32.86527949485462
  },
  {
    "name": "Lanett",
    "lng": -85.19203770084384,
    "lat": 32.84443343911521
  },
  {
    "name": "Langdon",
    "lng": -98.37313190016015,
    "lat": 48.762357388393625
  },
  {
    "name": "Langdon",
    "lng": -98.36378787847904,
    "lat": 48.76867641060703
  },
  {
    "name": "Langdon",
    "lng": -98.32432052408758,
    "lat": 37.853043544297456
  },
  {
    "name": "Langdon Place",
    "lng": -85.58504498723494,
    "lat": 38.28671434705479
  },
  {
    "name": "Langford",
    "lng": -97.83013332772767,
    "lat": 45.60248220044292
  },
  {
    "name": "Langley",
    "lng": -122.40842919835526,
    "lat": 48.03380224583399
  },
  {
    "name": "Langley",
    "lng": -95.05890461631715,
    "lat": 36.48732104343054
  },
  {
    "name": "Langley",
    "lng": -95.05279838279895,
    "lat": 36.467728572657386
  },
  {
    "name": "Langley",
    "lng": -95.04754593030692,
    "lat": 36.45073154308116
  },
  {
    "name": "Langston",
    "lng": -97.27012678177994,
    "lat": 35.934075181322385
  },
  {
    "name": "Langston",
    "lng": -86.09006051949714,
    "lat": 34.53739357496366
  },
  {
    "name": "Lankin",
    "lng": -97.9213194962579,
    "lat": 48.31449637697322
  },
  {
    "name": "Lannon",
    "lng": -88.1603139565093,
    "lat": 43.152095449404754
  },
  {
    "name": "Lansford",
    "lng": -101.3759590309569,
    "lat": 48.62698974214008
  },
  {
    "name": "Lansford",
    "lng": -75.88491870771674,
    "lat": 40.83316401125812
  },
  {
    "name": "Lansing",
    "lng": -94.95249648818724,
    "lat": 39.26066697752327
  },
  {
    "name": "Lansing",
    "lng": -94.89171245914979,
    "lat": 39.24267639292302
  },
  {
    "name": "Lansing",
    "lng": -91.22546883080064,
    "lat": 43.36065863145
  },
  {
    "name": "Lansing",
    "lng": -87.54767459744755,
    "lat": 41.56797585936074
  },
  {
    "name": "Lansing",
    "lng": -84.68449656470851,
    "lat": 42.692153391234825
  },
  {
    "name": "Lansing",
    "lng": -84.65982917586234,
    "lat": 42.70958739740916
  },
  {
    "name": "Lansing",
    "lng": -84.55732276640013,
    "lat": 42.716252441053875
  },
  {
    "name": "Lansing",
    "lng": -84.45649164988566,
    "lat": 42.69629778882314
  },
  {
    "name": "Lansing",
    "lng": -84.43232238618047,
    "lat": 42.67423383682172
  },
  {
    "name": "Lansing",
    "lng": -81.50985912076602,
    "lat": 36.49889135490202
  },
  {
    "name": "Lapeer",
    "lng": -83.32557567476704,
    "lat": 43.04480805398892
  },
  {
    "name": "Lapel",
    "lng": -85.84058271945726,
    "lat": 40.03338461300259
  },
  {
    "name": "Laporte",
    "lng": -94.75737626821928,
    "lat": 47.21472801227035
  },
  {
    "name": "Lapwai",
    "lng": -116.8041003792133,
    "lat": 46.403368487040765
  },
  {
    "name": "Laramie",
    "lng": -105.60678908773289,
    "lat": 41.31049404672523
  },
  {
    "name": "Larchmont",
    "lng": -73.7529546933846,
    "lat": 40.92569407507974
  },
  {
    "name": "Larchwood",
    "lng": -96.43632648399097,
    "lat": 43.454651486571336
  },
  {
    "name": "Laredo",
    "lng": -99.48703959724972,
    "lat": 27.56167938849586
  },
  {
    "name": "Laredo",
    "lng": -93.44804678916492,
    "lat": 40.02608016279328
  },
  {
    "name": "Largo",
    "lng": -82.79051436133037,
    "lat": 27.90317735569099
  },
  {
    "name": "Largo",
    "lng": -82.78982160083805,
    "lat": 27.903283718887526
  },
  {
    "name": "Largo",
    "lng": -82.77581630961542,
    "lat": 27.90151428666588
  },
  {
    "name": "Largo",
    "lng": -82.77463828556093,
    "lat": 27.908752349424645
  },
  {
    "name": "Largo",
    "lng": -82.76927855077653,
    "lat": 27.902187457630998
  },
  {
    "name": "Largo",
    "lng": -82.741925710461,
    "lat": 27.9344771056611
  },
  {
    "name": "Largo",
    "lng": -82.73086132771478,
    "lat": 27.935852321854988
  },
  {
    "name": "Largo",
    "lng": -82.7228906657723,
    "lat": 27.919151173122135
  },
  {
    "name": "Largo",
    "lng": -82.72045206177535,
    "lat": 27.91507263382532
  },
  {
    "name": "Largo",
    "lng": -82.71715838701967,
    "lat": 27.921174814000995
  },
  {
    "name": "Largo",
    "lng": -82.71707051104451,
    "lat": 27.92030350423142
  },
  {
    "name": "Largo",
    "lng": -82.713974964371,
    "lat": 27.922327934063187
  },
  {
    "name": "Largo",
    "lng": -82.71301763714516,
    "lat": 27.9156502382382
  },
  {
    "name": "Largo",
    "lng": -82.71198976614703,
    "lat": 27.915800983094766
  },
  {
    "name": "Largo",
    "lng": -82.7109297915573,
    "lat": 27.91590986416498
  },
  {
    "name": "Largo",
    "lng": -82.70984151655156,
    "lat": 27.9158685478743
  },
  {
    "name": "Largo",
    "lng": -82.70914440732604,
    "lat": 27.921519622537296
  },
  {
    "name": "Largo",
    "lng": -82.70875900570525,
    "lat": 27.91523564040283
  },
  {
    "name": "Largo",
    "lng": -82.7002106290438,
    "lat": 27.895756900904907
  },
  {
    "name": "Largo",
    "lng": -82.69871599284484,
    "lat": 27.906467479268223
  },
  {
    "name": "Larimore",
    "lng": -97.63551402183138,
    "lat": 47.902754487475804
  },
  {
    "name": "Larimore",
    "lng": -97.62688248129047,
    "lat": 47.90860717088498
  },
  {
    "name": "Larkspur",
    "lng": -122.53125977410119,
    "lat": 37.939091503481144
  },
  {
    "name": "Larkspur",
    "lng": -104.89218707036713,
    "lat": 39.23175253659884
  },
  {
    "name": "Larned",
    "lng": -99.10112600505013,
    "lat": 38.18319214488072
  },
  {
    "name": "Larrabee",
    "lng": -95.544790080472,
    "lat": 42.86148681870907
  },
  {
    "name": "Larsen Bay",
    "lng": -154.00815219916166,
    "lat": 57.539729931972765
  },
  {
    "name": "Larwill",
    "lng": -85.62380528893728,
    "lat": 41.17931522285233
  },
  {
    "name": "Las Cruces",
    "lng": -106.78941116468638,
    "lat": 32.326553063948964
  },
  {
    "name": "Las Vegas",
    "lng": -115.27217437354159,
    "lat": 36.24195275482847
  },
  {
    "name": "Las Vegas",
    "lng": -105.22034787027916,
    "lat": 35.6006256561184
  },
  {
    "name": "Lasker",
    "lng": -77.30574685513758,
    "lat": 36.350221856019495
  },
  {
    "name": "Lastrup",
    "lng": -94.06344009783749,
    "lat": 46.038743011584046
  },
  {
    "name": "Latah",
    "lng": -117.15566155317836,
    "lat": 47.28212269939169
  },
  {
    "name": "Latexo",
    "lng": -95.47242825291099,
    "lat": 31.389126425861278
  },
  {
    "name": "Latham",
    "lng": -96.64268651983937,
    "lat": 37.53546144961941
  },
  {
    "name": "Latham",
    "lng": -89.16233064841025,
    "lat": 39.96700587901586
  },
  {
    "name": "Lathrop",
    "lng": -121.31261710666983,
    "lat": 37.81621663496499
  },
  {
    "name": "Lathrop",
    "lng": -94.3283151916387,
    "lat": 39.55068552750032
  },
  {
    "name": "Lathrup Village",
    "lng": -83.22735778863513,
    "lat": 42.492114076818474
  },
  {
    "name": "Latimer",
    "lng": -96.84580429262297,
    "lat": 38.73863961904577
  },
  {
    "name": "Latimer",
    "lng": -93.36580095362893,
    "lat": 42.76303734022148
  },
  {
    "name": "Latrobe",
    "lng": -79.38259813606686,
    "lat": 40.312436683894795
  },
  {
    "name": "Latta",
    "lng": -79.43363460624086,
    "lat": 34.33899055021645
  },
  {
    "name": "Lattimore",
    "lng": -81.66040044024989,
    "lat": 35.31524598479682
  },
  {
    "name": "Lattingtown",
    "lng": -73.59779533085769,
    "lat": 40.91671622390254
  },
  {
    "name": "Latty",
    "lng": -84.58318259916713,
    "lat": 41.08793787581287
  },
  {
    "name": "Lauderdale",
    "lng": -93.2026285865867,
    "lat": 44.994208419685776
  },
  {
    "name": "Lauderdale-by-the-Sea",
    "lng": -80.09322173165955,
    "lat": 26.198579880556004
  },
  {
    "name": "Laupahoehoe CDP",
    "lng": -155.23760217852885,
    "lat": 19.976126672813642
  },
  {
    "name": "Laura",
    "lng": -84.40842073075716,
    "lat": 39.99508166151939
  },
  {
    "name": "Laurel",
    "lng": -108.77029803492303,
    "lat": 45.673394480731176
  },
  {
    "name": "Laurel",
    "lng": -97.09456773383636,
    "lat": 42.427923507746414
  },
  {
    "name": "Laurel",
    "lng": -92.92231090217376,
    "lat": 41.88408950383887
  },
  {
    "name": "Laurel",
    "lng": -89.1448579214624,
    "lat": 31.69574878938877
  },
  {
    "name": "Laurel",
    "lng": -89.14480947222259,
    "lat": 31.69562015635448
  },
  {
    "name": "Laurel",
    "lng": -89.13381727797629,
    "lat": 31.741095207617903
  },
  {
    "name": "Laurel",
    "lng": -89.13260626332897,
    "lat": 31.740060995586152
  },
  {
    "name": "Laurel",
    "lng": -89.13260626332895,
    "lat": 31.740060995586152
  },
  {
    "name": "Laurel",
    "lng": -85.19205945883317,
    "lat": 39.506157529262204
  },
  {
    "name": "Laurel",
    "lng": -85.18705710182893,
    "lat": 39.500465764008155
  },
  {
    "name": "Laurel",
    "lng": -76.86218734438778,
    "lat": 39.09501985553661
  },
  {
    "name": "Laurel Hollow",
    "lng": -73.4748358517988,
    "lat": 40.856017845811664
  },
  {
    "name": "Laurel Park",
    "lng": -82.50378827363271,
    "lat": 35.311951374599694
  },
  {
    "name": "Laurel Park",
    "lng": -82.477094252393,
    "lat": 35.3010276181827
  },
  {
    "name": "Laureldale",
    "lng": -75.91349383447832,
    "lat": 40.389744892151086
  },
  {
    "name": "Laurelville",
    "lng": -82.7373679499707,
    "lat": 39.4719319739933
  },
  {
    "name": "Laurens",
    "lng": -94.84765582409709,
    "lat": 42.847622669688604
  },
  {
    "name": "Laurens",
    "lng": -82.02488706635799,
    "lat": 34.50076333123076
  },
  {
    "name": "Laurens",
    "lng": -75.08866298997998,
    "lat": 42.53112004735264
  },
  {
    "name": "Laurie",
    "lng": -92.82526076471846,
    "lat": 38.20800737157164
  },
  {
    "name": "Laurinburg",
    "lng": -79.47808350744833,
    "lat": 34.75965491874638
  },
  {
    "name": "Laurium",
    "lng": -88.43817546766383,
    "lat": 47.23511574959789
  },
  {
    "name": "Lava Hot Springs",
    "lng": -112.00976558444536,
    "lat": 42.61975387437488
  },
  {
    "name": "Lavaca",
    "lng": -94.1798781118503,
    "lat": 35.33397557561533
  },
  {
    "name": "Lavaca",
    "lng": -94.17923103364542,
    "lat": 35.324311491122614
  },
  {
    "name": "Laverne",
    "lng": -99.89703301490248,
    "lat": 36.70525209838928
  },
  {
    "name": "Lavina",
    "lng": -108.93877598297848,
    "lat": 46.29573954688011
  },
  {
    "name": "Lavonia",
    "lng": -83.10997831021719,
    "lat": 34.43676476844032
  },
  {
    "name": "Lavonia",
    "lng": -83.09504590328028,
    "lat": 34.41752080421191
  },
  {
    "name": "Lawler",
    "lng": -92.15322515032295,
    "lat": 43.072028563699384
  },
  {
    "name": "Lawn",
    "lng": -99.75009904839776,
    "lat": 32.13656988665219
  },
  {
    "name": "Lawndale",
    "lng": -81.56276723338392,
    "lat": 35.414316344179305
  },
  {
    "name": "Lawrence",
    "lng": -98.25969304108723,
    "lat": 40.29012875276464
  },
  {
    "name": "Lawrence",
    "lng": -95.2961962528762,
    "lat": 38.98924574523362
  },
  {
    "name": "Lawrence",
    "lng": -95.27862910186812,
    "lat": 39.0054545491352
  },
  {
    "name": "Lawrence",
    "lng": -95.27650764583163,
    "lat": 38.99981482572152
  },
  {
    "name": "Lawrence",
    "lng": -95.26695724579103,
    "lat": 38.959840785103495
  },
  {
    "name": "Lawrence",
    "lng": -95.25466584097514,
    "lat": 38.99824193090312
  },
  {
    "name": "Lawrence",
    "lng": -95.23190205641683,
    "lat": 39.001514086172044
  },
  {
    "name": "Lawrence",
    "lng": -95.2309630401582,
    "lat": 39.00499438437488
  },
  {
    "name": "Lawrence",
    "lng": -95.21530416930463,
    "lat": 39.008052848468495
  },
  {
    "name": "Lawrence",
    "lng": -95.20090675079278,
    "lat": 38.91198071834291
  },
  {
    "name": "Lawrence",
    "lng": -86.05315005726617,
    "lat": 42.216206912731906
  },
  {
    "name": "Lawrence",
    "lng": -85.99046030168377,
    "lat": 39.86744790750871
  },
  {
    "name": "Lawrence",
    "lng": -73.7146443274321,
    "lat": 40.60431341386811
  },
  {
    "name": "Lawrence Creek",
    "lng": -96.42674915025829,
    "lat": 36.083809568009
  },
  {
    "name": "Lawrenceburg",
    "lng": -87.33176329903665,
    "lat": 35.249703452297
  },
  {
    "name": "Lawrenceburg",
    "lng": -84.90315592899069,
    "lat": 38.03319064361244
  },
  {
    "name": "Lawrenceburg",
    "lng": -84.8718097002377,
    "lat": 39.09879154677971
  },
  {
    "name": "Lawrenceville",
    "lng": -87.68739957476433,
    "lat": 38.72630368624732
  },
  {
    "name": "Lawrenceville",
    "lng": -84.02154965017267,
    "lat": 33.92499169380043
  },
  {
    "name": "Lawrenceville",
    "lng": -83.99312994716234,
    "lat": 33.952422373623214
  },
  {
    "name": "Lawrenceville",
    "lng": -83.96140579623578,
    "lat": 33.93505661127702
  },
  {
    "name": "Lawrenceville",
    "lng": -83.95649811983036,
    "lat": 33.93489716169684
  },
  {
    "name": "Lawrenceville",
    "lng": -77.85387309587264,
    "lat": 36.75650280251654
  },
  {
    "name": "Lawson",
    "lng": -94.21703428368063,
    "lat": 39.435400644351525
  },
  {
    "name": "Lawton",
    "lng": -98.4202308649862,
    "lat": 34.61711892925805
  },
  {
    "name": "Lawton",
    "lng": -98.3684483170913,
    "lat": 48.303535471925834
  },
  {
    "name": "Lawton",
    "lng": -96.18497555567575,
    "lat": 42.477018111996124
  },
  {
    "name": "Lawton",
    "lng": -85.84576183051078,
    "lat": 42.16785600836211
  },
  {
    "name": "Layton",
    "lng": -111.96224823931776,
    "lat": 41.077044328208906
  },
  {
    "name": "Laytonsville",
    "lng": -77.14135440642794,
    "lat": 39.20912760459936
  },
  {
    "name": "Le Center",
    "lng": -93.73109403514484,
    "lat": 44.38658017225548
  },
  {
    "name": "Le Center",
    "lng": -93.728165164876,
    "lat": 44.39372992621289
  },
  {
    "name": "Le Claire",
    "lng": -90.36850770205405,
    "lat": 41.59629031074592
  },
  {
    "name": "Le Flore",
    "lng": -94.97582359755073,
    "lat": 34.898444073085024
  },
  {
    "name": "Le Grand",
    "lng": -120.25686969461101,
    "lat": 37.21726681774711
  },
  {
    "name": "Le Grand",
    "lng": -92.77562469482542,
    "lat": 42.00660988790383
  },
  {
    "name": "Le Mars",
    "lng": -96.17350374066537,
    "lat": 42.781079706076376
  },
  {
    "name": "Le Roy",
    "lng": -93.59264427821857,
    "lat": 40.8779641200055
  },
  {
    "name": "Le Roy",
    "lng": -92.50809119996926,
    "lat": 43.51339148268516
  },
  {
    "name": "Le Roy",
    "lng": -88.76286591839418,
    "lat": 40.3394321294384
  },
  {
    "name": "Le Roy",
    "lng": -77.98997609335105,
    "lat": 42.97802502051066
  },
  {
    "name": "Le Sueur",
    "lng": -93.90498769669239,
    "lat": 44.468054687823845
  },
  {
    "name": "Le Sueur",
    "lng": -93.86427882528963,
    "lat": 44.50532659181874
  },
  {
    "name": "Le Sueur",
    "lng": -93.8641299739665,
    "lat": 44.49944453475895
  },
  {
    "name": "LeChee",
    "lng": -111.43277734659236,
    "lat": 36.87103180018485
  },
  {
    "name": "LeRoy",
    "lng": -95.63318151310054,
    "lat": 38.08622763846281
  },
  {
    "name": "LeRoy",
    "lng": -85.45373656313458,
    "lat": 44.03820862961582
  },
  {
    "name": "Leachville",
    "lng": -90.2624649386872,
    "lat": 35.903919730572795
  },
  {
    "name": "Lead",
    "lng": -103.76692114193237,
    "lat": 44.35270502502126
  },
  {
    "name": "Lead Hill",
    "lng": -92.9067216269178,
    "lat": 36.4137465324506
  },
  {
    "name": "Leadington",
    "lng": -90.48124702145482,
    "lat": 37.833875622618066
  },
  {
    "name": "Leadington",
    "lng": -90.47759531593499,
    "lat": 37.83623282316187
  },
  {
    "name": "Leadington",
    "lng": -90.47089712155024,
    "lat": 37.83116182607096
  },
  {
    "name": "Leadore",
    "lng": -113.35922060411889,
    "lat": 44.680046130226195
  },
  {
    "name": "Leadville",
    "lng": -106.29348873998731,
    "lat": 39.24672561401874
  },
  {
    "name": "Leadwood",
    "lng": -90.58915491357817,
    "lat": 37.861742197168795
  },
  {
    "name": "Leaf River",
    "lng": -89.40406528799556,
    "lat": 42.123607812493866
  },
  {
    "name": "League City",
    "lng": -95.10840730527907,
    "lat": 29.48755942146779
  },
  {
    "name": "Leakesville",
    "lng": -88.5580096508225,
    "lat": 31.154451471620664
  },
  {
    "name": "Leal",
    "lng": -98.31538481187332,
    "lat": 47.10491376150062
  },
  {
    "name": "Leamington",
    "lng": -112.28533217407937,
    "lat": 39.53120866416424
  },
  {
    "name": "Leander",
    "lng": -97.93103692960632,
    "lat": 30.559415218353568
  },
  {
    "name": "Leander",
    "lng": -97.90879700697658,
    "lat": 30.62274567120526
  },
  {
    "name": "Leander",
    "lng": -97.88471004035469,
    "lat": 30.59379727457913
  },
  {
    "name": "Leander",
    "lng": -97.86098843490664,
    "lat": 30.57320618333576
  },
  {
    "name": "Leander",
    "lng": -97.84363916321072,
    "lat": 30.628270941595662
  },
  {
    "name": "Leander",
    "lng": -97.84143344288903,
    "lat": 30.62611212663126
  },
  {
    "name": "Leander",
    "lng": -97.83025444733885,
    "lat": 30.58773704531305
  },
  {
    "name": "Leander",
    "lng": -97.82228834996097,
    "lat": 30.588552699929892
  },
  {
    "name": "Leander",
    "lng": -97.79439611200084,
    "lat": 30.586464530199407
  },
  {
    "name": "Leander",
    "lng": -97.79028222538793,
    "lat": 30.593858192913586
  },
  {
    "name": "Leander",
    "lng": -97.78675989735514,
    "lat": 30.587265508103005
  },
  {
    "name": "Leander",
    "lng": -97.78018407040126,
    "lat": 30.56864853181291
  },
  {
    "name": "Leander",
    "lng": -97.77644112223902,
    "lat": 30.563004584133424
  },
  {
    "name": "Learned",
    "lng": -90.54865422561038,
    "lat": 32.1972072863528
  },
  {
    "name": "Leary",
    "lng": -94.21891361688735,
    "lat": 33.46894973344663
  },
  {
    "name": "Leary",
    "lng": -84.51352938934541,
    "lat": 31.4842857151781
  },
  {
    "name": "Leasburg",
    "lng": -91.29531796408708,
    "lat": 38.09484893138964
  },
  {
    "name": "Leavenworth",
    "lng": -120.66337157883278,
    "lat": 47.5949111947246
  },
  {
    "name": "Leavenworth",
    "lng": -94.92467309146878,
    "lat": 39.322800410529894
  },
  {
    "name": "Leavenworth",
    "lng": -94.89454647164015,
    "lat": 39.233499910454526
  },
  {
    "name": "Leavenworth",
    "lng": -86.34508939870419,
    "lat": 38.19940174147847
  },
  {
    "name": "Leawood",
    "lng": -94.62576954762277,
    "lat": 38.907645756169245
  },
  {
    "name": "Leawood",
    "lng": -94.50016723130553,
    "lat": 37.03477190614977
  },
  {
    "name": "Leawood",
    "lng": -94.48523876919926,
    "lat": 37.029066600874415
  },
  {
    "name": "Leawood",
    "lng": -94.48125059910997,
    "lat": 37.03872606584366
  },
  {
    "name": "Lebanon",
    "lng": -100.2759439441216,
    "lat": 40.04879241539417
  },
  {
    "name": "Lebanon",
    "lng": -99.76636319514543,
    "lat": 45.06878302775187
  },
  {
    "name": "Lebanon",
    "lng": -98.5573353585756,
    "lat": 39.81025669776916
  },
  {
    "name": "Lebanon",
    "lng": -92.66035357851617,
    "lat": 37.67166831527087
  },
  {
    "name": "Lebanon",
    "lng": -89.81229917849036,
    "lat": 38.60236201839119
  },
  {
    "name": "Lebanon",
    "lng": -86.47711347296006,
    "lat": 40.02462609375578
  },
  {
    "name": "Lebanon",
    "lng": -86.47677916317949,
    "lat": 40.02626907573228
  },
  {
    "name": "Lebanon",
    "lng": -86.47434209337978,
    "lat": 40.050492959506926
  },
  {
    "name": "Lebanon",
    "lng": -86.44046637089062,
    "lat": 36.24760780763971
  },
  {
    "name": "Lebanon",
    "lng": -86.34426812513598,
    "lat": 36.20330140524416
  },
  {
    "name": "Lebanon",
    "lng": -85.25603110711093,
    "lat": 37.569131273795726
  },
  {
    "name": "Lebanon",
    "lng": -84.2499330498907,
    "lat": 39.38584195062422
  },
  {
    "name": "Lebanon",
    "lng": -84.21313626213104,
    "lat": 39.42514105304755
  },
  {
    "name": "Lebanon",
    "lng": -82.0782458199217,
    "lat": 36.8991669725298
  },
  {
    "name": "Lebanon",
    "lng": -76.42273923232574,
    "lat": 40.34120264812567
  },
  {
    "name": "Lebanon",
    "lng": -72.25257080850889,
    "lat": 43.635217084472444
  },
  {
    "name": "Lebanon",
    "lng": -72.24028497247932,
    "lat": 41.631269587304224
  },
  {
    "name": "Lebanon",
    "lng": -70.91149001084639,
    "lat": 43.403382107980875
  },
  {
    "name": "Lebanon Junction",
    "lng": -85.72486086131158,
    "lat": 37.83512256987547
  },
  {
    "name": "Lebo",
    "lng": -95.88736243946346,
    "lat": 38.42341492611669
  },
  {
    "name": "Lebo",
    "lng": -95.8720683617194,
    "lat": 38.41419387947818
  },
  {
    "name": "Lebo",
    "lng": -95.85723712888012,
    "lat": 38.4144348043177
  },
  {
    "name": "Lecompte",
    "lng": -92.39987735126589,
    "lat": 31.088161967920094
  },
  {
    "name": "Lecompton",
    "lng": -95.39284287792441,
    "lat": 39.03647822873996
  },
  {
    "name": "Ledyard",
    "lng": -94.15858936121582,
    "lat": 43.42059749291024
  },
  {
    "name": "Ledyard",
    "lng": -72.01665129588511,
    "lat": 41.44003094322913
  },
  {
    "name": "Lee",
    "lng": -88.94094691737295,
    "lat": 41.7955944223791
  },
  {
    "name": "Lee",
    "lng": -73.23411502330447,
    "lat": 42.3020103885861
  },
  {
    "name": "Lee",
    "lng": -68.29090342149699,
    "lat": 45.37243253247352
  },
  {
    "name": "Lee's Summit",
    "lng": -94.38137524665211,
    "lat": 38.917180585532655
  },
  {
    "name": "Leedey",
    "lng": -99.34506424907748,
    "lat": 35.86865284741237
  },
  {
    "name": "Leeds",
    "lng": -113.34703520168522,
    "lat": 37.23720837207362
  },
  {
    "name": "Leeds",
    "lng": -99.43951633704175,
    "lat": 48.289047732707104
  },
  {
    "name": "Leeds",
    "lng": -86.6171150838257,
    "lat": 33.506253180571264
  },
  {
    "name": "Leeds",
    "lng": -86.58526037412517,
    "lat": 33.49806173483314
  },
  {
    "name": "Leeds",
    "lng": -86.56370181911326,
    "lat": 33.543153755183965
  },
  {
    "name": "Leeds",
    "lng": -86.56364772013055,
    "lat": 33.54336096461313
  },
  {
    "name": "Leeds",
    "lng": -70.1305936140151,
    "lat": 44.29482784713536
  },
  {
    "name": "Leesburg",
    "lng": -85.84889388363712,
    "lat": 41.33071850907064
  },
  {
    "name": "Leesburg",
    "lng": -84.1687295440354,
    "lat": 31.7324790041387
  },
  {
    "name": "Leesburg",
    "lng": -83.55194744211782,
    "lat": 39.34184172395749
  },
  {
    "name": "Leesburg",
    "lng": -77.5544271368062,
    "lat": 39.10572462797914
  },
  {
    "name": "Leesport",
    "lng": -75.96863356003735,
    "lat": 40.44515134507123
  },
  {
    "name": "Leesville",
    "lng": -93.29226778287563,
    "lat": 31.14638623438529
  },
  {
    "name": "Leesville",
    "lng": -93.27428817664534,
    "lat": 31.13946932933354
  },
  {
    "name": "Leesville",
    "lng": -81.20958040631801,
    "lat": 40.4520566813903
  },
  {
    "name": "Leeton",
    "lng": -93.69522396170736,
    "lat": 38.58324821047364
  },
  {
    "name": "Leetonia",
    "lng": -80.76235926526206,
    "lat": 40.87855549166968
  },
  {
    "name": "Lefors",
    "lng": -100.80392698085244,
    "lat": 35.4396087434282
  },
  {
    "name": "Leggett",
    "lng": -77.58005204670023,
    "lat": 35.989619822942174
  },
  {
    "name": "Lehi",
    "lng": -111.87252994224214,
    "lat": 40.41355570769256
  },
  {
    "name": "Lehigh",
    "lng": -97.30255845585538,
    "lat": 38.37420399432833
  },
  {
    "name": "Lehigh",
    "lng": -96.22089455122972,
    "lat": 34.47073675723147
  },
  {
    "name": "Lehigh",
    "lng": -94.0535370216539,
    "lat": 42.35751714992517
  },
  {
    "name": "Lehighton",
    "lng": -75.71613970883647,
    "lat": 40.83046763913699
  },
  {
    "name": "Lehr",
    "lng": -99.35287501088227,
    "lat": 46.28242501941205
  },
  {
    "name": "Leicester",
    "lng": -77.89707029370429,
    "lat": 42.77096408979302
  },
  {
    "name": "Leicester",
    "lng": -71.9120167079304,
    "lat": 42.240004683623624
  },
  {
    "name": "Leigh",
    "lng": -97.23996256651408,
    "lat": 41.70237356570925
  },
  {
    "name": "Leighton",
    "lng": -92.7863704855174,
    "lat": 41.33881517087482
  },
  {
    "name": "Leighton",
    "lng": -87.52911549424462,
    "lat": 34.698625311696055
  },
  {
    "name": "Leipsic",
    "lng": -83.98979767960353,
    "lat": 41.09024772382055
  },
  {
    "name": "Leipsic",
    "lng": -83.96700396749412,
    "lat": 41.10886845174846
  },
  {
    "name": "Leitchfield",
    "lng": -86.28408978512283,
    "lat": 37.48649168102032
  },
  {
    "name": "Leland",
    "lng": -93.63513404535757,
    "lat": 43.3318761131468
  },
  {
    "name": "Leland",
    "lng": -90.89977201116157,
    "lat": 33.40372825612124
  },
  {
    "name": "Leland",
    "lng": -88.79830341339279,
    "lat": 41.61596423358522
  },
  {
    "name": "Leland",
    "lng": -78.09846299011325,
    "lat": 34.19901543290334
  },
  {
    "name": "Leland",
    "lng": -78.07413436772467,
    "lat": 34.18319141933947
  },
  {
    "name": "Leland",
    "lng": -78.07356260091541,
    "lat": 34.26752709414649
  },
  {
    "name": "Leland",
    "lng": -78.05687013950876,
    "lat": 34.2083930291122
  },
  {
    "name": "Leland",
    "lng": -78.0240132344043,
    "lat": 34.20199733834822
  },
  {
    "name": "Leland",
    "lng": -78.01868796431717,
    "lat": 34.21521348696056
  },
  {
    "name": "Leland Grove",
    "lng": -89.68396191708636,
    "lat": 39.77871173039551
  },
  {
    "name": "Lemmon",
    "lng": -102.17166649269858,
    "lat": 45.932797710769506
  },
  {
    "name": "Lemmon",
    "lng": -102.15864922201997,
    "lat": 45.938582995858596
  },
  {
    "name": "Lemon Grove",
    "lng": -117.03434734236255,
    "lat": 32.73308674782913
  },
  {
    "name": "Lemont",
    "lng": -88.0339148287785,
    "lat": 41.68387536031545
  },
  {
    "name": "Lemont",
    "lng": -88.02473769695995,
    "lat": 41.66591086076218
  },
  {
    "name": "Lemont",
    "lng": -88.00731583845855,
    "lat": 41.71090608321716
  },
  {
    "name": "Lemont",
    "lng": -88.00527840360735,
    "lat": 41.70778369093856
  },
  {
    "name": "Lemont",
    "lng": -87.99091430830534,
    "lat": 41.69004643818266
  },
  {
    "name": "Lemont",
    "lng": -87.9862897983719,
    "lat": 41.670324290343956
  },
  {
    "name": "Lemont",
    "lng": -87.96694761220745,
    "lat": 41.65185553786396
  },
  {
    "name": "Lemont",
    "lng": -87.95629608197876,
    "lat": 41.68727480050264
  },
  {
    "name": "Lemont",
    "lng": -87.93905446879631,
    "lat": 41.65642204903691
  },
  {
    "name": "Lemont",
    "lng": -87.93901386370288,
    "lat": 41.652311705179166
  },
  {
    "name": "Lemont",
    "lng": -87.93737935257944,
    "lat": 41.696780214869044
  },
  {
    "name": "Lemont",
    "lng": -87.93556479395191,
    "lat": 41.6877166864792
  },
  {
    "name": "Lemont",
    "lng": -87.93322839996509,
    "lat": 41.649899710324654
  },
  {
    "name": "Lena",
    "lng": -89.82162555695285,
    "lat": 42.378298365114944
  },
  {
    "name": "Lena",
    "lng": -89.5948134815535,
    "lat": 32.59397266600017
  },
  {
    "name": "Lena",
    "lng": -88.04935904256503,
    "lat": 44.95265510901019
  },
  {
    "name": "Lenapah",
    "lng": -95.63607438360297,
    "lat": 36.851743717816376
  },
  {
    "name": "Lenexa",
    "lng": -94.8021664542059,
    "lat": 38.96080246469431
  },
  {
    "name": "Lengby",
    "lng": -95.63441195352597,
    "lat": 47.51458539522956
  },
  {
    "name": "Lennon",
    "lng": -83.93253018451111,
    "lat": 42.985441049849605
  },
  {
    "name": "Lennox",
    "lng": -96.89464259177518,
    "lat": 43.35050818028338
  },
  {
    "name": "Lenoir",
    "lng": -81.59345673464657,
    "lat": 35.87253106691845
  },
  {
    "name": "Lenoir",
    "lng": -81.568606355993,
    "lat": 35.89965658326974
  },
  {
    "name": "Lenoir",
    "lng": -81.56775071110991,
    "lat": 35.89830516110523
  },
  {
    "name": "Lenoir",
    "lng": -81.56674235033103,
    "lat": 35.91150159154762
  },
  {
    "name": "Lenoir",
    "lng": -81.56109159904743,
    "lat": 35.907887256415904
  },
  {
    "name": "Lenoir",
    "lng": -81.56096131179426,
    "lat": 35.908471642024544
  },
  {
    "name": "Lenoir",
    "lng": -81.55841086986973,
    "lat": 35.88397008977315
  },
  {
    "name": "Lenoir",
    "lng": -81.5559093970151,
    "lat": 35.8853184452462
  },
  {
    "name": "Lenoir",
    "lng": -81.54603664481762,
    "lat": 35.94547427154722
  },
  {
    "name": "Lenoir",
    "lng": -81.54523547565255,
    "lat": 35.94338447449254
  },
  {
    "name": "Lenoir",
    "lng": -81.5378922776541,
    "lat": 35.946555372336846
  },
  {
    "name": "Lenoir",
    "lng": -81.53582670748533,
    "lat": 35.949319262464506
  },
  {
    "name": "Lenoir",
    "lng": -81.52983025452636,
    "lat": 35.949024212721525
  },
  {
    "name": "Lenoir",
    "lng": -81.52747018941983,
    "lat": 35.903304998384364
  },
  {
    "name": "Lenoir",
    "lng": -81.52142131374949,
    "lat": 35.946141955647434
  },
  {
    "name": "Lenoir",
    "lng": -81.51820008160337,
    "lat": 35.96265195273808
  },
  {
    "name": "Lenoir",
    "lng": -81.48625144474548,
    "lat": 35.93800727255334
  },
  {
    "name": "Lenoir",
    "lng": -81.4817821887666,
    "lat": 35.785671271799046
  },
  {
    "name": "Lenoir",
    "lng": -81.46508972556884,
    "lat": 35.84447285304526
  },
  {
    "name": "Lenoir City",
    "lng": -84.28174025586647,
    "lat": 35.81133190091871
  },
  {
    "name": "Lenora",
    "lng": -100.00133173378309,
    "lat": 39.61090310538651
  },
  {
    "name": "Lenox",
    "lng": -94.558263788149,
    "lat": 40.88350003107677
  },
  {
    "name": "Lenox",
    "lng": -83.46732037214544,
    "lat": 31.26730870214689
  },
  {
    "name": "Lenox",
    "lng": -73.27598859582291,
    "lat": 42.37164955554752
  },
  {
    "name": "Lenwood",
    "lng": -117.10693202737485,
    "lat": 34.885619456948085
  },
  {
    "name": "Lenzburg",
    "lng": -89.81835383624274,
    "lat": 38.28516397159948
  },
  {
    "name": "Leo-Cedarville",
    "lng": -85.01917183524685,
    "lat": 41.21980544610194
  },
  {
    "name": "Leola",
    "lng": -98.93888905122024,
    "lat": 45.721047038830406
  },
  {
    "name": "Leola",
    "lng": -92.59018526823617,
    "lat": 34.17059808307671
  },
  {
    "name": "Leominster",
    "lng": -71.77167085894861,
    "lat": 42.520890969903874
  },
  {
    "name": "Leon",
    "lng": -97.4290454496963,
    "lat": 33.8773453023201
  },
  {
    "name": "Leon",
    "lng": -96.78346421175776,
    "lat": 37.68801093924737
  },
  {
    "name": "Leon",
    "lng": -93.75452353579514,
    "lat": 40.74075236382306
  },
  {
    "name": "Leon",
    "lng": -81.95544307916498,
    "lat": 38.74718156842707
  },
  {
    "name": "Leon Valley",
    "lng": -98.6143102019485,
    "lat": 29.495330105330897
  },
  {
    "name": "Leona",
    "lng": -95.3215449558346,
    "lat": 39.786132553657836
  },
  {
    "name": "Leonard",
    "lng": -97.24993123905489,
    "lat": 46.65231672233132
  },
  {
    "name": "Leonard",
    "lng": -96.24651636096536,
    "lat": 33.38321783489378
  },
  {
    "name": "Leonard",
    "lng": -95.26868757002185,
    "lat": 47.65368173862995
  },
  {
    "name": "Leonard",
    "lng": -92.18196083644096,
    "lat": 39.894409389845606
  },
  {
    "name": "Leonard",
    "lng": -83.14265581036835,
    "lat": 42.865214151268326
  },
  {
    "name": "Leonardtown",
    "lng": -76.64112910071088,
    "lat": 38.30138337409868
  },
  {
    "name": "Leonardville",
    "lng": -96.85959467934474,
    "lat": 39.36406764472736
  },
  {
    "name": "Leonidas",
    "lng": -92.56744872470817,
    "lat": 47.467845044074764
  },
  {
    "name": "Leonore",
    "lng": -88.98227400271941,
    "lat": 41.188901624729986
  },
  {
    "name": "Leonville",
    "lng": -91.98100471407187,
    "lat": 30.466162971267455
  },
  {
    "name": "Leoti",
    "lng": -101.35756690021186,
    "lat": 38.48324787536748
  },
  {
    "name": "Leoti",
    "lng": -101.3485585821678,
    "lat": 38.48069870933454
  },
  {
    "name": "Leoti",
    "lng": -101.34346966976376,
    "lat": 38.48625752219869
  },
  {
    "name": "Lepanto",
    "lng": -90.33337653564506,
    "lat": 35.607952479084
  },
  {
    "name": "Lerna",
    "lng": -88.28878502943819,
    "lat": 39.41788546685607
  },
  {
    "name": "Leroy",
    "lng": -97.02070941467922,
    "lat": 31.733532319266637
  },
  {
    "name": "Leshara",
    "lng": -96.42920669465933,
    "lat": 41.329782756876476
  },
  {
    "name": "Leslie",
    "lng": -92.55629356369245,
    "lat": 35.829432224154566
  },
  {
    "name": "Leslie",
    "lng": -91.23095009771527,
    "lat": 38.41790677230203
  },
  {
    "name": "Leslie",
    "lng": -84.43343617077781,
    "lat": 42.45066339132663
  },
  {
    "name": "Leslie",
    "lng": -84.08632516032809,
    "lat": 31.95438543450099
  },
  {
    "name": "Lester",
    "lng": -96.3314165352379,
    "lat": 43.44029397445889
  },
  {
    "name": "Lester",
    "lng": -87.15151664286938,
    "lat": 34.98771791970447
  },
  {
    "name": "Lester",
    "lng": -81.30128669598616,
    "lat": 37.735299017168785
  },
  {
    "name": "Lester Prairie",
    "lng": -94.03967018325989,
    "lat": 44.88170145335912
  },
  {
    "name": "Lester Prairie",
    "lng": -94.02660936054976,
    "lat": 44.886525686996904
  },
  {
    "name": "Lester Prairie",
    "lng": -94.02596532412886,
    "lat": 44.88381654928524
  },
  {
    "name": "Lesterville",
    "lng": -97.59061118593492,
    "lat": 43.03868456662065
  },
  {
    "name": "Letcher",
    "lng": -98.14421064401641,
    "lat": 43.8995488846666
  },
  {
    "name": "Letona",
    "lng": -91.82954736370039,
    "lat": 35.36350223521127
  },
  {
    "name": "Letts",
    "lng": -91.23526120122321,
    "lat": 41.32997073456829
  },
  {
    "name": "Leupp",
    "lng": -111.00275822817432,
    "lat": 35.2966326072827
  },
  {
    "name": "Levan",
    "lng": -111.86053559085184,
    "lat": 39.55612433127866
  },
  {
    "name": "Levant",
    "lng": -68.99767871289919,
    "lat": 44.88649766011438
  },
  {
    "name": "Levasy",
    "lng": -94.13051433861918,
    "lat": 39.136208604369635
  },
  {
    "name": "Level Plains",
    "lng": -85.76823399299357,
    "lat": 31.307345393367655
  },
  {
    "name": "Level Plains",
    "lng": -85.7682160933204,
    "lat": 31.335129543149687
  },
  {
    "name": "Levelland",
    "lng": -102.36349616160328,
    "lat": 33.58061527288166
  },
  {
    "name": "Leverett",
    "lng": -72.48745997520831,
    "lat": 42.47362077776386
  },
  {
    "name": "Lewellen",
    "lng": -102.14370317768281,
    "lat": 41.33053095418077
  },
  {
    "name": "Lewis",
    "lng": -99.25455718943562,
    "lat": 37.93696790080881
  },
  {
    "name": "Lewis",
    "lng": -95.08414266014749,
    "lat": 41.3064598042141
  },
  {
    "name": "Lewis and Clark Village",
    "lng": -95.0504974719959,
    "lat": 39.537691776899244
  },
  {
    "name": "Lewisberry",
    "lng": -76.860941364637,
    "lat": 40.13562991991876
  },
  {
    "name": "Lewisburg",
    "lng": -86.95030431154045,
    "lat": 36.986633103017425
  },
  {
    "name": "Lewisburg",
    "lng": -86.88099504723866,
    "lat": 35.4418975365686
  },
  {
    "name": "Lewisburg",
    "lng": -86.78701016179704,
    "lat": 35.449558218435385
  },
  {
    "name": "Lewisburg",
    "lng": -84.54344427341604,
    "lat": 39.85076751629936
  },
  {
    "name": "Lewisburg",
    "lng": -80.43263344619568,
    "lat": 37.80960727650463
  },
  {
    "name": "Lewisburg",
    "lng": -76.88991573765124,
    "lat": 40.96416673271007
  },
  {
    "name": "Lewisport",
    "lng": -86.90169635066066,
    "lat": 37.93051864213113
  },
  {
    "name": "Lewiston",
    "lng": -116.9932523528642,
    "lat": 46.393430707173955
  },
  {
    "name": "Lewiston",
    "lng": -111.87963012898047,
    "lat": 41.9614752425647
  },
  {
    "name": "Lewiston",
    "lng": -96.40744870751129,
    "lat": 40.24287034003584
  },
  {
    "name": "Lewiston",
    "lng": -91.88477051889271,
    "lat": 43.96835301067858
  },
  {
    "name": "Lewiston",
    "lng": -91.8680107132873,
    "lat": 43.98372480322326
  },
  {
    "name": "Lewiston",
    "lng": -79.03991567958892,
    "lat": 43.17210730043892
  },
  {
    "name": "Lewiston",
    "lng": -70.16808335294893,
    "lat": 44.09149920162899
  },
  {
    "name": "Lewiston Woodville",
    "lng": -77.1811737621044,
    "lat": 36.114121118598106
  },
  {
    "name": "Lewistown",
    "lng": -109.47091751259934,
    "lat": 47.04267122527491
  },
  {
    "name": "Lewistown",
    "lng": -109.43179517422516,
    "lat": 47.06375033973233
  },
  {
    "name": "Lewistown",
    "lng": -109.41701280519638,
    "lat": 47.05600268405145
  },
  {
    "name": "Lewistown",
    "lng": -109.4159016931154,
    "lat": 47.057775732350365
  },
  {
    "name": "Lewistown",
    "lng": -109.40426994343602,
    "lat": 47.06902579191169
  },
  {
    "name": "Lewistown",
    "lng": -109.40019578083269,
    "lat": 47.07058146690114
  },
  {
    "name": "Lewistown",
    "lng": -91.81314351632243,
    "lat": 40.084732720338636
  },
  {
    "name": "Lewistown",
    "lng": -90.15671325991067,
    "lat": 40.41597024073655
  },
  {
    "name": "Lewistown",
    "lng": -90.15550844187187,
    "lat": 40.39659578577082
  },
  {
    "name": "Lewistown",
    "lng": -90.1444653724295,
    "lat": 40.38253561309577
  },
  {
    "name": "Lewistown",
    "lng": -77.57300206075193,
    "lat": 40.59631789908943
  },
  {
    "name": "Lewisville",
    "lng": -112.01323289752912,
    "lat": 43.69520805111142
  },
  {
    "name": "Lewisville",
    "lng": -96.97921690668204,
    "lat": 33.044866340361416
  },
  {
    "name": "Lewisville",
    "lng": -94.4343994163514,
    "lat": 43.924106530550354
  },
  {
    "name": "Lewisville",
    "lng": -93.57511397282606,
    "lat": 33.36419398977127
  },
  {
    "name": "Lewisville",
    "lng": -85.35287592185766,
    "lat": 39.80679028955282
  },
  {
    "name": "Lewisville",
    "lng": -81.21829678502148,
    "lat": 39.767114574562456
  },
  {
    "name": "Lewisville",
    "lng": -80.41660049820085,
    "lat": 36.13134542405134
  },
  {
    "name": "Lewisville",
    "lng": -80.41655416874923,
    "lat": 36.1026073535056
  },
  {
    "name": "Lexa",
    "lng": -90.75188226834732,
    "lat": 34.59825885053806
  },
  {
    "name": "Lexington",
    "lng": -99.74957135150235,
    "lat": 40.79766072638007
  },
  {
    "name": "Lexington",
    "lng": -99.74945464988046,
    "lat": 40.79579674123747
  },
  {
    "name": "Lexington",
    "lng": -99.74604140835368,
    "lat": 40.77851591913332
  },
  {
    "name": "Lexington",
    "lng": -99.73495767763352,
    "lat": 40.762940003481816
  },
  {
    "name": "Lexington",
    "lng": -97.33619611063659,
    "lat": 35.01804250106086
  },
  {
    "name": "Lexington",
    "lng": -93.86940975389675,
    "lat": 39.17980564362162
  },
  {
    "name": "Lexington",
    "lng": -93.17638941512212,
    "lat": 45.146976646531975
  },
  {
    "name": "Lexington",
    "lng": -93.17112632192897,
    "lat": 45.137741684666786
  },
  {
    "name": "Lexington",
    "lng": -90.04968793775488,
    "lat": 33.116309367163375
  },
  {
    "name": "Lexington",
    "lng": -88.78563219889386,
    "lat": 40.64655981853592
  },
  {
    "name": "Lexington",
    "lng": -88.40253169859947,
    "lat": 35.6844595487063
  },
  {
    "name": "Lexington",
    "lng": -88.39649434334711,
    "lat": 35.65926674176063
  },
  {
    "name": "Lexington",
    "lng": -87.37351529423378,
    "lat": 34.96522548122457
  },
  {
    "name": "Lexington",
    "lng": -83.11024996909732,
    "lat": 33.87060914794231
  },
  {
    "name": "Lexington",
    "lng": -82.5786704122405,
    "lat": 40.67960365481863
  },
  {
    "name": "Lexington",
    "lng": -82.53713407955931,
    "lat": 43.26727718042064
  },
  {
    "name": "Lexington",
    "lng": -81.25933375530384,
    "lat": 33.983691106653836
  },
  {
    "name": "Lexington",
    "lng": -81.24192267468806,
    "lat": 33.96560013282138
  },
  {
    "name": "Lexington",
    "lng": -81.2359783351142,
    "lat": 33.993109936922586
  },
  {
    "name": "Lexington",
    "lng": -81.22852108664428,
    "lat": 33.9847898816158
  },
  {
    "name": "Lexington",
    "lng": -81.22148319384996,
    "lat": 33.96892860021038
  },
  {
    "name": "Lexington",
    "lng": -81.17506749732831,
    "lat": 34.008025120418964
  },
  {
    "name": "Lexington",
    "lng": -80.2843679253013,
    "lat": 35.84159867962557
  },
  {
    "name": "Lexington",
    "lng": -80.27876987975876,
    "lat": 35.84168775114249
  },
  {
    "name": "Lexington",
    "lng": -80.26800177146211,
    "lat": 35.8010871213097
  },
  {
    "name": "Lexington",
    "lng": -80.23433985019938,
    "lat": 35.83479017341703
  },
  {
    "name": "Lexington",
    "lng": -80.22606256962635,
    "lat": 35.839565021266345
  },
  {
    "name": "Lexington",
    "lng": -80.22081436640484,
    "lat": 35.79960795431243
  },
  {
    "name": "Lexington",
    "lng": -79.44411278086729,
    "lat": 37.782451844640526
  },
  {
    "name": "Lexington",
    "lng": -71.23072171970816,
    "lat": 42.44567253885742
  },
  {
    "name": "Lexington Park",
    "lng": -76.48972907072547,
    "lat": 38.27913722772103
  },
  {
    "name": "Leyden",
    "lng": -72.6264687896097,
    "lat": 42.69582659029319
  },
  {
    "name": "Libby",
    "lng": -115.57194052317959,
    "lat": 48.392591932850195
  },
  {
    "name": "Libby",
    "lng": -115.56864872160354,
    "lat": 48.39203438150237
  },
  {
    "name": "Libby",
    "lng": -115.56083716139166,
    "lat": 48.399939431745025
  },
  {
    "name": "Libby",
    "lng": -115.55576386274167,
    "lat": 48.383873472192576
  },
  {
    "name": "Libby",
    "lng": -115.55184767284089,
    "lat": 48.402445178882324
  },
  {
    "name": "Liberal",
    "lng": -100.92919153187997,
    "lat": 37.04655027804984
  },
  {
    "name": "Liberal",
    "lng": -94.51615793662565,
    "lat": 37.559017825014365
  },
  {
    "name": "Liberty",
    "lng": -96.4829983159999,
    "lat": 40.085508938133685
  },
  {
    "name": "Liberty",
    "lng": -96.01110623722268,
    "lat": 35.85322107794698
  },
  {
    "name": "Liberty",
    "lng": -95.97520738952835,
    "lat": 35.86094895618091
  },
  {
    "name": "Liberty",
    "lng": -95.94113234815958,
    "lat": 35.84580247364755
  },
  {
    "name": "Liberty",
    "lng": -95.59795929045309,
    "lat": 37.15658126791025
  },
  {
    "name": "Liberty",
    "lng": -94.79024717006209,
    "lat": 30.049063638079424
  },
  {
    "name": "Liberty",
    "lng": -94.41896470665385,
    "lat": 39.23997727215322
  },
  {
    "name": "Liberty",
    "lng": -91.10843412695253,
    "lat": 39.88425556133657
  },
  {
    "name": "Liberty",
    "lng": -90.80066784849518,
    "lat": 31.161127325477608
  },
  {
    "name": "Liberty",
    "lng": -85.97796852086638,
    "lat": 36.00530693126943
  },
  {
    "name": "Liberty",
    "lng": -84.92928015540316,
    "lat": 37.31932057915923
  },
  {
    "name": "Liberty",
    "lng": -84.92603269616416,
    "lat": 39.63484445720627
  },
  {
    "name": "Liberty",
    "lng": -79.58266038304434,
    "lat": 35.88154224475162
  },
  {
    "name": "Liberty",
    "lng": -79.57331200608517,
    "lat": 35.82128478311768
  },
  {
    "name": "Liberty",
    "lng": -79.56794244549194,
    "lat": 35.855536782501055
  },
  {
    "name": "Liberty",
    "lng": -79.56343388081868,
    "lat": 35.826746563016115
  },
  {
    "name": "Liberty",
    "lng": -79.55860738899133,
    "lat": 35.8216582720463
  },
  {
    "name": "Liberty",
    "lng": -74.74363130410146,
    "lat": 41.79661779939599
  },
  {
    "name": "Liberty",
    "lng": -74.73065854655148,
    "lat": 41.790204771163054
  },
  {
    "name": "Liberty",
    "lng": -69.34035625906867,
    "lat": 44.367208903089164
  },
  {
    "name": "Liberty Center",
    "lng": -84.00775861111798,
    "lat": 41.44354443590084
  },
  {
    "name": "Liberty Hill",
    "lng": -97.90168455802014,
    "lat": 30.660976242669776
  },
  {
    "name": "Liberty Hill",
    "lng": -97.90108426696384,
    "lat": 30.660759713140994
  },
  {
    "name": "Liberty Hill",
    "lng": -97.86269871203243,
    "lat": 30.63336453353292
  },
  {
    "name": "Liberty Hill",
    "lng": -97.84561403671677,
    "lat": 30.643692477392175
  },
  {
    "name": "Liberty Lake",
    "lng": -117.10347559480978,
    "lat": 47.668489381895235
  },
  {
    "name": "Libertyville",
    "lng": -92.04968642237036,
    "lat": 40.95831621168985
  },
  {
    "name": "Libertyville",
    "lng": -87.96737549818991,
    "lat": 42.28678686151024
  },
  {
    "name": "Libertyville",
    "lng": -86.46000621624503,
    "lat": 31.242777633510435
  },
  {
    "name": "Licking",
    "lng": -91.86172349301279,
    "lat": 37.49920497704956
  },
  {
    "name": "Lidderdale",
    "lng": -94.78369691120803,
    "lat": 42.12245496532132
  },
  {
    "name": "Lidgerwood",
    "lng": -97.1442809564955,
    "lat": 46.0729821430889
  },
  {
    "name": "Liebenthal",
    "lng": -99.32014672090988,
    "lat": 38.65447354237168
  },
  {
    "name": "Lignite",
    "lng": -102.56430284838423,
    "lat": 48.87746191567575
  },
  {
    "name": "Ligonier",
    "lng": -85.5946637347387,
    "lat": 41.465090398083284
  },
  {
    "name": "Lilbourn",
    "lng": -89.61319139231327,
    "lat": 36.59074152922911
  },
  {
    "name": "Lilburn",
    "lng": -84.13790323888162,
    "lat": 33.888696605979014
  },
  {
    "name": "Lilesville",
    "lng": -79.98453329959324,
    "lat": 34.96749486608487
  },
  {
    "name": "Lillie",
    "lng": -92.6613380361597,
    "lat": 32.922047144755254
  },
  {
    "name": "Lillington",
    "lng": -78.84970304204205,
    "lat": 35.343563055855824
  },
  {
    "name": "Lillington",
    "lng": -78.84263289839392,
    "lat": 35.389743887863325
  },
  {
    "name": "Lillington",
    "lng": -78.82865914314004,
    "lat": 35.40955939466676
  },
  {
    "name": "Lillington",
    "lng": -78.81054061652854,
    "lat": 35.402949194905894
  },
  {
    "name": "Lillington",
    "lng": -78.79821096904766,
    "lat": 35.42995702025175
  },
  {
    "name": "Lillington",
    "lng": -78.7957135340696,
    "lat": 35.42777917435935
  },
  {
    "name": "Lillington",
    "lng": -78.79226502702453,
    "lat": 35.45940442417443
  },
  {
    "name": "Lillington",
    "lng": -78.79180870263473,
    "lat": 35.460698134505
  },
  {
    "name": "Lilly",
    "lng": -83.87746033437165,
    "lat": 32.14697530228893
  },
  {
    "name": "Lily",
    "lng": -97.68347957472636,
    "lat": 45.18174931740609
  },
  {
    "name": "Lily Lake",
    "lng": -88.47427361872644,
    "lat": 41.951782474299925
  },
  {
    "name": "Lilydale",
    "lng": -93.13040683936006,
    "lat": 44.909549387145425
  },
  {
    "name": "Lima",
    "lng": -112.59195771356437,
    "lat": 44.63842407493416
  },
  {
    "name": "Lima",
    "lng": -96.59846817068762,
    "lat": 35.173730462326404
  },
  {
    "name": "Lima",
    "lng": -91.37830868530358,
    "lat": 40.17679105781825
  },
  {
    "name": "Lima",
    "lng": -91.37796166323942,
    "lat": 40.18453136960667
  },
  {
    "name": "Lima",
    "lng": -84.11213127909649,
    "lat": 40.74085074156784
  },
  {
    "name": "Lima",
    "lng": -77.61231721474958,
    "lat": 42.906625694793696
  },
  {
    "name": "Limaville",
    "lng": -81.14834609908469,
    "lat": 40.98405288704969
  },
  {
    "name": "Lime Ridge",
    "lng": -90.15782209735583,
    "lat": 43.467564650962615
  },
  {
    "name": "Lime Springs",
    "lng": -92.28236595910818,
    "lat": 43.450546829280626
  },
  {
    "name": "Limerick",
    "lng": -70.78643279865615,
    "lat": 43.68862703461639
  },
  {
    "name": "Limestone",
    "lng": -87.96082715771752,
    "lat": 41.12918653473279
  },
  {
    "name": "Limestone",
    "lng": -87.95555769451342,
    "lat": 41.15442579369023
  },
  {
    "name": "Limestone",
    "lng": -78.6324512398763,
    "lat": 42.02283793520672
  },
  {
    "name": "Limestone",
    "lng": -67.85956867659156,
    "lat": 46.913826306073574
  },
  {
    "name": "Limington",
    "lng": -70.70262063309961,
    "lat": 43.735666480030844
  },
  {
    "name": "Limon",
    "lng": -103.69212087671399,
    "lat": 39.26716624959577
  },
  {
    "name": "Lincoln",
    "lng": -121.34320319289573,
    "lat": 38.86144590025004
  },
  {
    "name": "Lincoln",
    "lng": -121.29274332910903,
    "lat": 38.87611063160796
  },
  {
    "name": "Lincoln",
    "lng": -100.70170416485745,
    "lat": 46.76738094004902
  },
  {
    "name": "Lincoln",
    "lng": -96.67750149781311,
    "lat": 40.80909784041811
  },
  {
    "name": "Lincoln",
    "lng": -94.41738521552881,
    "lat": 35.94891911245957
  },
  {
    "name": "Lincoln",
    "lng": -93.33139184094212,
    "lat": 38.39397156091167
  },
  {
    "name": "Lincoln",
    "lng": -92.69233977236885,
    "lat": 42.26278569971679
  },
  {
    "name": "Lincoln",
    "lng": -89.37152486893741,
    "lat": 40.150637667556424
  },
  {
    "name": "Lincoln",
    "lng": -86.22930314789978,
    "lat": 33.49444900110491
  },
  {
    "name": "Lincoln",
    "lng": -86.2119793843713,
    "lat": 33.58343568033067
  },
  {
    "name": "Lincoln",
    "lng": -86.19150365989785,
    "lat": 33.55700798099844
  },
  {
    "name": "Lincoln",
    "lng": -86.1834908301239,
    "lat": 33.545094031961725
  },
  {
    "name": "Lincoln",
    "lng": -86.17777240608116,
    "lat": 33.60056339282636
  },
  {
    "name": "Lincoln",
    "lng": -86.16889681819431,
    "lat": 33.600917826448445
  },
  {
    "name": "Lincoln",
    "lng": -86.16866212689249,
    "lat": 33.59776851660594
  },
  {
    "name": "Lincoln",
    "lng": -86.16814188663959,
    "lat": 33.59848563378218
  },
  {
    "name": "Lincoln",
    "lng": -86.14327170414977,
    "lat": 33.56070659445762
  },
  {
    "name": "Lincoln",
    "lng": -86.14322644869013,
    "lat": 33.56178651880138
  },
  {
    "name": "Lincoln",
    "lng": -86.14052090910248,
    "lat": 33.558343900430415
  },
  {
    "name": "Lincoln",
    "lng": -86.13709238191694,
    "lat": 33.59268059652635
  },
  {
    "name": "Lincoln",
    "lng": -86.11593013433989,
    "lat": 33.66174958257518
  },
  {
    "name": "Lincoln",
    "lng": -86.11277144016866,
    "lat": 33.64799291851525
  },
  {
    "name": "Lincoln",
    "lng": -86.11277144016864,
    "lat": 33.64799291851524
  },
  {
    "name": "Lincoln",
    "lng": -86.10676142470547,
    "lat": 33.658071913422354
  },
  {
    "name": "Lincoln",
    "lng": -86.0923195257857,
    "lat": 33.63553165216865
  },
  {
    "name": "Lincoln",
    "lng": -86.09119201315133,
    "lat": 33.58516627159918
  },
  {
    "name": "Lincoln",
    "lng": -86.07185962515608,
    "lat": 33.64287289379957
  },
  {
    "name": "Lincoln",
    "lng": -86.02163572021546,
    "lat": 33.5635913286481
  },
  {
    "name": "Lincoln",
    "lng": -83.41325669978039,
    "lat": 44.6842137951365
  },
  {
    "name": "Lincoln",
    "lng": -71.45018698245096,
    "lat": 41.91704797230913
  },
  {
    "name": "Lincoln",
    "lng": -71.30863821612323,
    "lat": 42.42663072887726
  },
  {
    "name": "Lincoln",
    "lng": -68.45591467438543,
    "lat": 45.35495508479675
  },
  {
    "name": "Lincoln Center",
    "lng": -98.14695892088677,
    "lat": 39.042422247563394
  },
  {
    "name": "Lincoln Center",
    "lng": -98.13468725956227,
    "lat": 39.03869769934405
  },
  {
    "name": "Lincoln Heights",
    "lng": -84.45676969761057,
    "lat": 39.24346975278665
  },
  {
    "name": "Lincoln Park",
    "lng": -96.97272937427434,
    "lat": 33.22204569958368
  },
  {
    "name": "Lincoln Park",
    "lng": -96.97262944709902,
    "lat": 33.22531161082927
  },
  {
    "name": "Lincoln Park",
    "lng": -83.18108112321873,
    "lat": 42.243308799272455
  },
  {
    "name": "Lincolnshire",
    "lng": -87.91799650380788,
    "lat": 42.1960417765545
  },
  {
    "name": "Lincolnshire",
    "lng": -85.62161425286995,
    "lat": 38.22368133650791
  },
  {
    "name": "Lincolnton",
    "lng": -82.47765038376157,
    "lat": 33.7930883900045
  },
  {
    "name": "Lincolnton",
    "lng": -81.28197900309335,
    "lat": 35.48011238793405
  },
  {
    "name": "Lincolnton",
    "lng": -81.26538946591872,
    "lat": 35.52180666709112
  },
  {
    "name": "Lincolnton",
    "lng": -81.2577731798221,
    "lat": 35.50361774375218
  },
  {
    "name": "Lincolnton",
    "lng": -81.24569401398497,
    "lat": 35.51081115006945
  },
  {
    "name": "Lincolnton",
    "lng": -81.23791813308665,
    "lat": 35.47332072259697
  },
  {
    "name": "Lincolnville",
    "lng": -96.96151092621058,
    "lat": 38.49437742254766
  },
  {
    "name": "Lincolnville",
    "lng": -80.15598358048446,
    "lat": 33.007593901130605
  },
  {
    "name": "Lincolnville",
    "lng": -69.0695311602426,
    "lat": 44.295018936628
  },
  {
    "name": "Lincolnwood",
    "lng": -87.73296078375687,
    "lat": 42.00543379765905
  },
  {
    "name": "Lind",
    "lng": -118.61475332751051,
    "lat": 46.97100809037786
  },
  {
    "name": "Linda",
    "lng": -121.55607001748511,
    "lat": 39.12114739687238
  },
  {
    "name": "Lindale",
    "lng": -95.40819806990456,
    "lat": 32.49040814825981
  },
  {
    "name": "Lindale",
    "lng": -95.39842144778443,
    "lat": 32.49763309898355
  },
  {
    "name": "Linden",
    "lng": -94.36263071410553,
    "lat": 33.010961312196514
  },
  {
    "name": "Linden",
    "lng": -94.26999610959788,
    "lat": 41.64258398951588
  },
  {
    "name": "Linden",
    "lng": -90.27417105429832,
    "lat": 42.91850104925902
  },
  {
    "name": "Linden",
    "lng": -87.86578793398944,
    "lat": 35.593446219512586
  },
  {
    "name": "Linden",
    "lng": -87.84350087176044,
    "lat": 35.61160878810379
  },
  {
    "name": "Linden",
    "lng": -87.79134642929981,
    "lat": 32.300332389783634
  },
  {
    "name": "Linden",
    "lng": -86.90245116095431,
    "lat": 40.190518176152054
  },
  {
    "name": "Linden",
    "lng": -83.78105996633695,
    "lat": 42.81936388624763
  },
  {
    "name": "Linden",
    "lng": -78.74841281717873,
    "lat": 35.25461945303926
  },
  {
    "name": "Linden",
    "lng": -74.23740078062295,
    "lat": 40.6247969856048
  },
  {
    "name": "Lindenhurst",
    "lng": -88.02729806794235,
    "lat": 42.41968990303271
  },
  {
    "name": "Lindenhurst",
    "lng": -88.02412230622895,
    "lat": 42.39543646285843
  },
  {
    "name": "Lindenhurst",
    "lng": -73.37345645318779,
    "lat": 40.68847803666309
  },
  {
    "name": "Lindon",
    "lng": -111.71886069581853,
    "lat": 40.341351668070665
  },
  {
    "name": "Lindsay",
    "lng": -119.09014839261965,
    "lat": 36.20786105798843
  },
  {
    "name": "Lindsay",
    "lng": -97.6941708692393,
    "lat": 41.700515425732554
  },
  {
    "name": "Lindsay",
    "lng": -97.61035000012406,
    "lat": 34.83940553400721
  },
  {
    "name": "Lindsay",
    "lng": -97.21855466823631,
    "lat": 33.64093068417435
  },
  {
    "name": "Lindsborg",
    "lng": -97.67444896137071,
    "lat": 38.576587031267444
  },
  {
    "name": "Lindsey",
    "lng": -83.2208048114079,
    "lat": 41.42165695100839
  },
  {
    "name": "Lindström",
    "lng": -92.86542997182245,
    "lat": 45.41139479634252
  },
  {
    "name": "Lindström",
    "lng": -92.85730560786274,
    "lat": 45.35817572971388
  },
  {
    "name": "Lindström",
    "lng": -92.84535871527432,
    "lat": 45.38309822260796
  },
  {
    "name": "Lindström",
    "lng": -92.82578737368037,
    "lat": 45.36855792963461
  },
  {
    "name": "Lindström",
    "lng": -92.82058474573061,
    "lat": 45.36206476193986
  },
  {
    "name": "Lineville",
    "lng": -93.5231647497847,
    "lat": 40.58706981271846
  },
  {
    "name": "Lineville",
    "lng": -85.75080003533913,
    "lat": 33.31218069349862
  },
  {
    "name": "Lingle",
    "lng": -104.34610507303685,
    "lat": 42.138665485677606
  },
  {
    "name": "Linglestown",
    "lng": -76.79482585958527,
    "lat": 40.34435341291824
  },
  {
    "name": "Linn",
    "lng": -97.08636287236571,
    "lat": 39.679543765572056
  },
  {
    "name": "Linn",
    "lng": -97.08018398076233,
    "lat": 39.67960257302132
  },
  {
    "name": "Linn",
    "lng": -91.84487152035041,
    "lat": 38.47875475663073
  },
  {
    "name": "Linn Creek",
    "lng": -92.70432029910664,
    "lat": 38.04890171902139
  },
  {
    "name": "Linn Grove",
    "lng": -95.24235618858178,
    "lat": 42.89270131638168
  },
  {
    "name": "Linn Valley",
    "lng": -94.71114098586823,
    "lat": 38.37617817532251
  },
  {
    "name": "Linndale",
    "lng": -81.76675063049997,
    "lat": 41.44499974196201
  },
  {
    "name": "Linneus",
    "lng": -93.18680017008035,
    "lat": 39.876643432116666
  },
  {
    "name": "Linneus",
    "lng": -67.97488511793263,
    "lat": 46.044562964286904
  },
  {
    "name": "Lino Lakes",
    "lng": -93.08302280370091,
    "lat": 45.16788812481355
  },
  {
    "name": "Linton",
    "lng": -100.23336986251937,
    "lat": 46.26854970134836
  },
  {
    "name": "Linton",
    "lng": -87.15885232391075,
    "lat": 39.035842242555056
  },
  {
    "name": "Linwood",
    "lng": -96.93228282699216,
    "lat": 41.412192385914835
  },
  {
    "name": "Linwood",
    "lng": -95.03393867745798,
    "lat": 39.000936547404194
  },
  {
    "name": "Linwood",
    "lng": -74.5706570554001,
    "lat": 39.34351295386518
  },
  {
    "name": "Lipscomb",
    "lng": -86.92869413537443,
    "lat": 33.41703032037434
  },
  {
    "name": "Lipscomb",
    "lng": -86.92601850563024,
    "lat": 33.42607626384702
  },
  {
    "name": "Lisbon",
    "lng": -97.6840608212407,
    "lat": 46.43826062206076
  },
  {
    "name": "Lisbon",
    "lng": -92.8671979132624,
    "lat": 32.78937721889837
  },
  {
    "name": "Lisbon",
    "lng": -91.3916112296556,
    "lat": 41.92040123386485
  },
  {
    "name": "Lisbon",
    "lng": -88.45989169545109,
    "lat": 41.4793946211476
  },
  {
    "name": "Lisbon",
    "lng": -88.43876835780364,
    "lat": 41.48621221025604
  },
  {
    "name": "Lisbon",
    "lng": -80.76269813902157,
    "lat": 40.77499615580047
  },
  {
    "name": "Lisbon",
    "lng": -72.0141186798435,
    "lat": 41.60146643428282
  },
  {
    "name": "Lisbon",
    "lng": -70.09000929870416,
    "lat": 44.026509246695035
  },
  {
    "name": "Liscomb",
    "lng": -93.00651271886049,
    "lat": 42.1913343946895
  },
  {
    "name": "Lisle",
    "lng": -88.085230314764,
    "lat": 41.79069183902459
  },
  {
    "name": "Lisle",
    "lng": -76.00552207136667,
    "lat": 42.3490553316009
  },
  {
    "name": "Lisman",
    "lng": -88.30011984931423,
    "lat": 32.179169773373914
  },
  {
    "name": "Lismore",
    "lng": -95.94808401212916,
    "lat": 43.7487661250213
  },
  {
    "name": "Litchfield",
    "lng": -99.15307791811335,
    "lat": 41.15605276851241
  },
  {
    "name": "Litchfield",
    "lng": -94.52544629246104,
    "lat": 45.12199296272811
  },
  {
    "name": "Litchfield",
    "lng": -89.62886647159718,
    "lat": 39.19621315795484
  },
  {
    "name": "Litchfield",
    "lng": -84.75683984665447,
    "lat": 42.04101498846517
  },
  {
    "name": "Litchfield",
    "lng": -73.19312473362899,
    "lat": 41.74133111049504
  },
  {
    "name": "Litchfield",
    "lng": -71.45489507055152,
    "lat": 42.843010558604135
  },
  {
    "name": "Litchfield",
    "lng": -69.9394160198522,
    "lat": 44.162763503832274
  },
  {
    "name": "Litchville",
    "lng": -98.19182466202696,
    "lat": 46.6576031339605
  },
  {
    "name": "Lithium",
    "lng": -89.884744613832,
    "lat": 37.832850153953245
  },
  {
    "name": "Lithonia",
    "lng": -84.10598671866896,
    "lat": 33.712785988408505
  },
  {
    "name": "Lithopolis",
    "lng": -82.81576168001749,
    "lat": 39.81127740124617
  },
  {
    "name": "Lithopolis",
    "lng": -82.80398688717304,
    "lat": 39.799128515510596
  },
  {
    "name": "Little Canada",
    "lng": -93.08630616409577,
    "lat": 45.024403203499396
  },
  {
    "name": "Little Chute",
    "lng": -88.31845233584498,
    "lat": 44.289004111869346
  },
  {
    "name": "Little Compton",
    "lng": -71.2024232076446,
    "lat": 41.45316521765858
  },
  {
    "name": "Little Compton",
    "lng": -71.19928499822369,
    "lat": 41.45371889925684
  },
  {
    "name": "Little Compton",
    "lng": -71.1988215990467,
    "lat": 41.453577808724944
  },
  {
    "name": "Little Compton",
    "lng": -71.19847355242162,
    "lat": 41.45307976783774
  },
  {
    "name": "Little Compton",
    "lng": -71.19841076623364,
    "lat": 41.450278959570944
  },
  {
    "name": "Little Compton",
    "lng": -71.19826124243933,
    "lat": 41.452908689799266
  },
  {
    "name": "Little Compton",
    "lng": -71.19802251424127,
    "lat": 41.448957431102386
  },
  {
    "name": "Little Compton",
    "lng": -71.19787930564799,
    "lat": 41.45417898178767
  },
  {
    "name": "Little Compton",
    "lng": -71.19542423858432,
    "lat": 41.45269080815039
  },
  {
    "name": "Little Compton",
    "lng": -71.19509263957359,
    "lat": 41.452792325865175
  },
  {
    "name": "Little Compton",
    "lng": -71.19409409775163,
    "lat": 41.44813734309057
  },
  {
    "name": "Little Compton",
    "lng": -71.19400409144883,
    "lat": 41.44907293110453
  },
  {
    "name": "Little Compton",
    "lng": -71.19282994265583,
    "lat": 41.45126192524817
  },
  {
    "name": "Little Compton",
    "lng": -71.16454758068886,
    "lat": 41.515510944999306
  },
  {
    "name": "Little Elm",
    "lng": -96.92621660094515,
    "lat": 33.183044871544915
  },
  {
    "name": "Little Falls",
    "lng": -94.35965622435522,
    "lat": 45.98288404680339
  },
  {
    "name": "Little Falls",
    "lng": -94.3501810027667,
    "lat": 46.01488468493257
  },
  {
    "name": "Little Falls",
    "lng": -94.34194235366363,
    "lat": 46.02423931405546
  },
  {
    "name": "Little Ferry",
    "lng": -74.03859073899979,
    "lat": 40.8463086852096
  },
  {
    "name": "Little Flock",
    "lng": -94.13637602099236,
    "lat": 36.38536670731719
  },
  {
    "name": "Little Flock",
    "lng": -94.11664054862426,
    "lat": 36.37586284914245
  },
  {
    "name": "Little Mountain",
    "lng": -81.41358417349551,
    "lat": 34.195600027414855
  },
  {
    "name": "Little Ocmulgee State Park & Lodge",
    "lng": -82.89613896271538,
    "lat": 32.096863221503476
  },
  {
    "name": "Little River",
    "lng": -98.01239928903463,
    "lat": 38.397785784334665
  },
  {
    "name": "Little River-Academy",
    "lng": -97.354118511314,
    "lat": 30.988191213838604
  },
  {
    "name": "Little Rock",
    "lng": -92.35771588552895,
    "lat": 34.72563753470479
  },
  {
    "name": "Little Rock",
    "lng": -88.5770543010058,
    "lat": 41.71768512375825
  },
  {
    "name": "Little Rock, Arkansas",
    "lng": -95.88043638294089,
    "lat": 43.44714397954227
  },
  {
    "name": "Little Sioux",
    "lng": -96.02698905255122,
    "lat": 41.80813242252516
  },
  {
    "name": "Little Valley",
    "lng": -78.79875149430008,
    "lat": 42.24936495841284
  },
  {
    "name": "Little York",
    "lng": -90.74634771592865,
    "lat": 41.010834164021
  },
  {
    "name": "Little York",
    "lng": -85.90455647287556,
    "lat": 38.699219678628864
  },
  {
    "name": "Littlefield",
    "lng": -102.33489375263973,
    "lat": 33.919090901005546
  },
  {
    "name": "Littlefork",
    "lng": -93.55841915306372,
    "lat": 48.39620122959296
  },
  {
    "name": "Littlefork",
    "lng": -93.54869128253968,
    "lat": 48.40149646658158
  },
  {
    "name": "Littlefork",
    "lng": -93.54536306702893,
    "lat": 48.40147589506974
  },
  {
    "name": "Littleton",
    "lng": -105.10442825754701,
    "lat": 39.540665194395885
  },
  {
    "name": "Littleton",
    "lng": -105.01540251433863,
    "lat": 39.59350165100565
  },
  {
    "name": "Littleton",
    "lng": -104.98774897579064,
    "lat": 39.60207300210631
  },
  {
    "name": "Littleton",
    "lng": -90.6223166246086,
    "lat": 40.23399992732745
  },
  {
    "name": "Littleton",
    "lng": -77.91103717514709,
    "lat": 36.43422118889806
  },
  {
    "name": "Littleton",
    "lng": -71.48910128375024,
    "lat": 42.535030317687955
  },
  {
    "name": "Littleton",
    "lng": -67.84363777195914,
    "lat": 46.23300588220382
  },
  {
    "name": "Live Oak",
    "lng": -121.66530258679609,
    "lat": 39.27379962149122
  },
  {
    "name": "Live Oak",
    "lng": -98.34042150942577,
    "lat": 29.554527215423484
  },
  {
    "name": "Live Oak",
    "lng": -82.9886416877204,
    "lat": 30.31517487630056
  },
  {
    "name": "Live Oak",
    "lng": -82.98466369635454,
    "lat": 30.295957283765027
  },
  {
    "name": "Livermore",
    "lng": -121.76070093623332,
    "lat": 37.68684932242977
  },
  {
    "name": "Livermore",
    "lng": -94.18420916184407,
    "lat": 42.86805981995873
  },
  {
    "name": "Livermore",
    "lng": -87.1339154300491,
    "lat": 37.49181953578507
  },
  {
    "name": "Livermore",
    "lng": -87.12156546375797,
    "lat": 37.49246264524909
  },
  {
    "name": "Livermore",
    "lng": -70.21502334044459,
    "lat": 44.407922248441864
  },
  {
    "name": "Livermore Falls",
    "lng": -70.14263994943042,
    "lat": 44.43251485764862
  },
  {
    "name": "Liverpool",
    "lng": -95.27443036681657,
    "lat": 29.30165084945917
  },
  {
    "name": "Liverpool",
    "lng": -90.00231644960286,
    "lat": 40.390227192717994
  },
  {
    "name": "Liverpool",
    "lng": -76.20936659569064,
    "lat": 43.106144639349694
  },
  {
    "name": "Livingston",
    "lng": -120.75598812623319,
    "lat": 37.391890791808585
  },
  {
    "name": "Livingston",
    "lng": -120.72474085412472,
    "lat": 37.38782362443363
  },
  {
    "name": "Livingston",
    "lng": -110.56651174457726,
    "lat": 45.661553809870696
  },
  {
    "name": "Livingston",
    "lng": -110.5293881382203,
    "lat": 45.673662318391614
  },
  {
    "name": "Livingston",
    "lng": -94.93767082545835,
    "lat": 30.709845671511403
  },
  {
    "name": "Livingston",
    "lng": -90.74644554456803,
    "lat": 30.495467187372284
  },
  {
    "name": "Livingston",
    "lng": -90.43368326839081,
    "lat": 42.90010630660163
  },
  {
    "name": "Livingston",
    "lng": -89.76426487657262,
    "lat": 38.96813411972589
  },
  {
    "name": "Livingston",
    "lng": -88.19048168116797,
    "lat": 32.59691589513958
  },
  {
    "name": "Livingston",
    "lng": -85.32750505107772,
    "lat": 36.389427278747206
  },
  {
    "name": "Livingston",
    "lng": -84.21562683786419,
    "lat": 37.297953406350324
  },
  {
    "name": "Livingston",
    "lng": -81.11994794944918,
    "lat": 33.55327885449688
  },
  {
    "name": "Livonia",
    "lng": -92.70048429700557,
    "lat": 40.492034064555156
  },
  {
    "name": "Livonia",
    "lng": -91.55011479879626,
    "lat": 30.56224755958115
  },
  {
    "name": "Livonia",
    "lng": -86.27772819342678,
    "lat": 38.55486404112203
  },
  {
    "name": "Livonia",
    "lng": -83.37330625176988,
    "lat": 42.397174941169084
  },
  {
    "name": "Livonia",
    "lng": -77.66879569677823,
    "lat": 42.82180888544904
  },
  {
    "name": "Lizton",
    "lng": -86.54465268285661,
    "lat": 39.87762574540468
  },
  {
    "name": "Lizton",
    "lng": -86.54173008016316,
    "lat": 39.88520589674291
  },
  {
    "name": "Llano",
    "lng": -98.6762937393805,
    "lat": 30.75090191025582
  },
  {
    "name": "Lloyd Harbor",
    "lng": -73.46882406013133,
    "lat": 40.94073189107962
  },
  {
    "name": "Loa",
    "lng": -111.64514940496325,
    "lat": 38.40375413042387
  },
  {
    "name": "Loachapoka",
    "lng": -85.59478140826491,
    "lat": 32.60493726988344
  },
  {
    "name": "Loami",
    "lng": -89.84759034086925,
    "lat": 39.674899283206436
  },
  {
    "name": "Lobelville",
    "lng": -87.7954092175291,
    "lat": 35.75023964435415
  },
  {
    "name": "Loch Arbour",
    "lng": -74.00086850575907,
    "lat": 40.23223461536544
  },
  {
    "name": "Loch Lloyd",
    "lng": -94.59769414863331,
    "lat": 38.83067110145179
  },
  {
    "name": "Loch Lynn Heights",
    "lng": -79.37291712446702,
    "lat": 39.39186790098699
  },
  {
    "name": "Lochbuie",
    "lng": -104.72630254648541,
    "lat": 40.01156674665645
  },
  {
    "name": "Lochsloy",
    "lng": -122.03975459629979,
    "lat": 48.054614781814486
  },
  {
    "name": "Lock Haven",
    "lng": -77.45204912482303,
    "lat": 41.13655293304238
  },
  {
    "name": "Lock Springs",
    "lng": -93.77615871489434,
    "lat": 39.848806428809354
  },
  {
    "name": "Lockbourne",
    "lng": -82.98339315455517,
    "lat": 39.80876250568609
  },
  {
    "name": "Lockhart",
    "lng": -97.68315245510638,
    "lat": 29.878599506544788
  },
  {
    "name": "Lockhart",
    "lng": -86.35051190040205,
    "lat": 31.01277358416956
  },
  {
    "name": "Lockhart",
    "lng": -81.46096583188593,
    "lat": 34.79188901968758
  },
  {
    "name": "Lockington",
    "lng": -84.23578132295543,
    "lat": 40.207513637966144
  },
  {
    "name": "Lockland",
    "lng": -84.45646077479498,
    "lat": 39.22778016095405
  },
  {
    "name": "Lockney",
    "lng": -101.4424472701998,
    "lat": 34.12305458387748
  },
  {
    "name": "Lockport",
    "lng": -90.53765134885796,
    "lat": 29.64177675333069
  },
  {
    "name": "Lockport",
    "lng": -88.05387216534244,
    "lat": 41.5979035072943
  },
  {
    "name": "Lockport",
    "lng": -88.05252384507226,
    "lat": 41.60054089090526
  },
  {
    "name": "Lockport",
    "lng": -88.05062346643463,
    "lat": 41.60311418956988
  },
  {
    "name": "Lockport",
    "lng": -88.04822987056808,
    "lat": 41.60404047261005
  },
  {
    "name": "Lockport",
    "lng": -88.04799072046323,
    "lat": 41.61137891035745
  },
  {
    "name": "Lockport",
    "lng": -88.04114619188469,
    "lat": 41.61246984132883
  },
  {
    "name": "Lockport",
    "lng": -88.02952935686396,
    "lat": 41.62259887277304
  },
  {
    "name": "Lockport",
    "lng": -88.02914315420573,
    "lat": 41.589021214772096
  },
  {
    "name": "Lockport",
    "lng": -88.02204155702124,
    "lat": 41.63125906952131
  },
  {
    "name": "Lockport",
    "lng": -88.0185014514008,
    "lat": 41.63640750408723
  },
  {
    "name": "Lockport",
    "lng": -78.69561476504876,
    "lat": 43.16980579078285
  },
  {
    "name": "Lockridge",
    "lng": -91.74991360847156,
    "lat": 40.993599879114214
  },
  {
    "name": "Lockwood",
    "lng": -93.95790748272913,
    "lat": 37.38673698150345
  },
  {
    "name": "Loco",
    "lng": -97.6806541672482,
    "lat": 34.329529032714696
  },
  {
    "name": "Locust",
    "lng": -80.45100052377127,
    "lat": 35.30460102387205
  },
  {
    "name": "Locust",
    "lng": -80.44552364251162,
    "lat": 35.293785441812744
  },
  {
    "name": "Locust",
    "lng": -80.436528188946,
    "lat": 35.26430581440395
  },
  {
    "name": "Locust",
    "lng": -80.40954575982354,
    "lat": 35.28479757981889
  },
  {
    "name": "Locust",
    "lng": -80.40577033143644,
    "lat": 35.286910509605555
  },
  {
    "name": "Locust",
    "lng": -80.39405287493969,
    "lat": 35.26141043751149
  },
  {
    "name": "Locust",
    "lng": -80.3904527035118,
    "lat": 35.26328688056091
  },
  {
    "name": "Locust Fork",
    "lng": -86.63652827912773,
    "lat": 33.87682885225552
  },
  {
    "name": "Locust Fork",
    "lng": -86.63248610709655,
    "lat": 33.88620980196699
  },
  {
    "name": "Locust Fork",
    "lng": -86.62931989680514,
    "lat": 33.90185810268612
  },
  {
    "name": "Locust Fork",
    "lng": -86.60642602424569,
    "lat": 33.91521315266424
  },
  {
    "name": "Locust Grove",
    "lng": -95.16852244821764,
    "lat": 36.19772460253445
  },
  {
    "name": "Locust Grove",
    "lng": -95.14997646492172,
    "lat": 36.19786568391533
  },
  {
    "name": "Locust Grove",
    "lng": -84.10918879917115,
    "lat": 33.344692870458786
  },
  {
    "name": "Locust Grove",
    "lng": -84.06095468014817,
    "lat": 33.32401334254968
  },
  {
    "name": "Loda",
    "lng": -88.07588122535266,
    "lat": 40.516188682399786
  },
  {
    "name": "Lodge",
    "lng": -80.95369837931652,
    "lat": 33.06781097562559
  },
  {
    "name": "Lodge Grass",
    "lng": -107.36772525490309,
    "lat": 45.31419618398231
  },
  {
    "name": "Lodge Grass",
    "lng": -107.36337016666668,
    "lat": 45.31752244124075
  },
  {
    "name": "Lodgepole",
    "lng": -102.63864040799777,
    "lat": 41.148931531608156
  },
  {
    "name": "Lodi",
    "lng": -121.38789996615554,
    "lat": 38.09697272823331
  },
  {
    "name": "Lodi",
    "lng": -121.38748144680856,
    "lat": 38.08981288799655
  },
  {
    "name": "Lodi",
    "lng": -121.28143203063554,
    "lat": 38.125848897319145
  },
  {
    "name": "Lodi",
    "lng": -89.53782384471138,
    "lat": 43.3152302734108
  },
  {
    "name": "Lodi",
    "lng": -82.01228701448478,
    "lat": 41.034818207178425
  },
  {
    "name": "Lodi",
    "lng": -76.82254512613446,
    "lat": 42.61346485387558
  },
  {
    "name": "Loess Hills State Forest",
    "lng": -95.96145092663564,
    "lat": 41.73246498490849
  },
  {
    "name": "Log Cabin",
    "lng": -96.03135781554894,
    "lat": 32.220509543614554
  },
  {
    "name": "Log Cabin",
    "lng": -96.03003304254709,
    "lat": 32.217134347979915
  },
  {
    "name": "Log Cabin",
    "lng": -96.02170341350056,
    "lat": 32.2235173128597
  },
  {
    "name": "Log Lane Village",
    "lng": -103.82938325471721,
    "lat": 40.27023514472838
  },
  {
    "name": "Logan",
    "lng": -111.84208130713463,
    "lat": 41.74008510031172
  },
  {
    "name": "Logan",
    "lng": -99.56720469953324,
    "lat": 39.66160882418357
  },
  {
    "name": "Logan",
    "lng": -95.80421556531006,
    "lat": 41.65028510976035
  },
  {
    "name": "Logan",
    "lng": -95.79099476281266,
    "lat": 41.64523616413591
  },
  {
    "name": "Logan",
    "lng": -82.40732029395002,
    "lat": 39.53789640991838
  },
  {
    "name": "Logan",
    "lng": -81.9850505903598,
    "lat": 37.85066780111185
  },
  {
    "name": "Logansport",
    "lng": -93.9934402816465,
    "lat": 31.9754241464581
  },
  {
    "name": "Logansport",
    "lng": -86.37507682690536,
    "lat": 40.7138852662239
  },
  {
    "name": "Logansport",
    "lng": -86.35462415650784,
    "lat": 40.75235133441215
  },
  {
    "name": "Loganville",
    "lng": -90.03836563011009,
    "lat": 43.43970275630522
  },
  {
    "name": "Loganville",
    "lng": -83.89700621278809,
    "lat": 33.858513422079334
  },
  {
    "name": "Loganville",
    "lng": -83.89573445824733,
    "lat": 33.835097850630554
  },
  {
    "name": "Loganville",
    "lng": -76.70787112226012,
    "lat": 39.85592871286996
  },
  {
    "name": "Lohman",
    "lng": -92.36404722676635,
    "lat": 38.54303163479912
  },
  {
    "name": "Lohrville",
    "lng": -94.55160818746029,
    "lat": 42.266767170902185
  },
  {
    "name": "Lohrville",
    "lng": -89.1202439326284,
    "lat": 44.038259287326184
  },
  {
    "name": "Loma",
    "lng": -98.52474182817953,
    "lat": 48.64178621389879
  },
  {
    "name": "Loma Linda",
    "lng": -117.2520477500012,
    "lat": 34.07562599992335
  },
  {
    "name": "Loma Linda",
    "lng": -117.25005892897686,
    "lat": 34.075491335218146
  },
  {
    "name": "Loma Linda",
    "lng": -117.24803641938449,
    "lat": 34.04285261409798
  },
  {
    "name": "Loma Linda",
    "lng": -117.2462728869491,
    "lat": 34.075470549781166
  },
  {
    "name": "Loma Linda",
    "lng": -94.59219992706281,
    "lat": 36.98583871490565
  },
  {
    "name": "Lomax",
    "lng": -91.07618836186839,
    "lat": 40.67888906487912
  },
  {
    "name": "Lombard",
    "lng": -88.01590371191806,
    "lat": 41.873872183598685
  },
  {
    "name": "Lometa",
    "lng": -98.39259436182104,
    "lat": 31.217478746514644
  },
  {
    "name": "Lomira",
    "lng": -88.44241449541636,
    "lat": 43.59430769432941
  },
  {
    "name": "Lomita",
    "lng": -118.31755711384854,
    "lat": 33.793347930634106
  },
  {
    "name": "Lompoc",
    "lng": -120.49543455933255,
    "lat": 34.58614402880583
  },
  {
    "name": "Lompoc",
    "lng": -120.47138213846296,
    "lat": 34.66195652644134
  },
  {
    "name": "Lompoc",
    "lng": -120.44358856316651,
    "lat": 34.66734914367839
  },
  {
    "name": "Lonaconing",
    "lng": -78.97919814080882,
    "lat": 39.56590516781125
  },
  {
    "name": "London",
    "lng": -119.44402288670575,
    "lat": 36.48096510955003
  },
  {
    "name": "London",
    "lng": -93.24177961285665,
    "lat": 35.328054412975156
  },
  {
    "name": "London",
    "lng": -84.07982915363237,
    "lat": 37.12078594661073
  },
  {
    "name": "London",
    "lng": -83.43678217364533,
    "lat": 39.89519788196303
  },
  {
    "name": "London Mills",
    "lng": -90.26615319849246,
    "lat": 40.710576928652245
  },
  {
    "name": "Londonderry",
    "lng": -71.38727488816983,
    "lat": 42.87969706491073
  },
  {
    "name": "Lone Elm",
    "lng": -95.24309242962815,
    "lat": 38.07965831665794
  },
  {
    "name": "Lone Grove",
    "lng": -97.25515092949456,
    "lat": 34.183448591785684
  },
  {
    "name": "Lone Jack",
    "lng": -94.2071772027162,
    "lat": 38.873570441878364
  },
  {
    "name": "Lone Jack",
    "lng": -94.20090420481013,
    "lat": 38.87207308179405
  },
  {
    "name": "Lone Jack",
    "lng": -94.17412253892357,
    "lat": 38.87254215886966
  },
  {
    "name": "Lone Oak",
    "lng": -95.9409054395681,
    "lat": 32.99605429895151
  },
  {
    "name": "Lone Oak",
    "lng": -88.66549086334645,
    "lat": 37.036257459821414
  },
  {
    "name": "Lone Rock",
    "lng": -94.3254998592604,
    "lat": 43.2208068423745
  },
  {
    "name": "Lone Rock",
    "lng": -90.20122821715061,
    "lat": 43.18668583209003
  },
  {
    "name": "Lone Tree",
    "lng": -104.87103290567869,
    "lat": 39.530840390795674
  },
  {
    "name": "Lone Tree",
    "lng": -91.42665562172263,
    "lat": 41.48587605971248
  },
  {
    "name": "Lone Wolf",
    "lng": -99.24564765388871,
    "lat": 34.990418327917574
  },
  {
    "name": "Long Beach",
    "lng": -124.05606481225588,
    "lat": 46.354889446084364
  },
  {
    "name": "Long Beach",
    "lng": -95.42977593282161,
    "lat": 45.650880841127815
  },
  {
    "name": "Long Beach",
    "lng": -89.16413374114613,
    "lat": 30.359918297076664
  },
  {
    "name": "Long Beach",
    "lng": -86.85269093069586,
    "lat": 41.743239014851014
  },
  {
    "name": "Long Beach",
    "lng": -73.66550706296171,
    "lat": 40.58558725146245
  },
  {
    "name": "Long Branch",
    "lng": -73.98976006964412,
    "lat": 40.296275180522194
  },
  {
    "name": "Long Creek",
    "lng": -88.84742697785066,
    "lat": 39.805326830136075
  },
  {
    "name": "Long Grove",
    "lng": -90.5810131366813,
    "lat": 41.69226740743304
  },
  {
    "name": "Long Grove",
    "lng": -90.5645714877853,
    "lat": 41.69094915080185
  },
  {
    "name": "Long Grove",
    "lng": -88.00570626144655,
    "lat": 42.19680072064388
  },
  {
    "name": "Long Hill",
    "lng": -72.05172103282324,
    "lat": 41.35359452172128
  },
  {
    "name": "Long Island",
    "lng": -99.53401358499734,
    "lat": 39.94618565395947
  },
  {
    "name": "Long Island",
    "lng": -70.1122909958669,
    "lat": 43.63780883215916
  },
  {
    "name": "Long Lake",
    "lng": -99.2057871026004,
    "lat": 45.85666203368445
  },
  {
    "name": "Long Lake",
    "lng": -93.56908381319214,
    "lat": 44.984029809760266
  },
  {
    "name": "Long Pine",
    "lng": -99.70269798763053,
    "lat": 42.53525290364103
  },
  {
    "name": "Long Point",
    "lng": -88.8929755774607,
    "lat": 41.005250898334985
  },
  {
    "name": "Long Prairie",
    "lng": -94.86257492463324,
    "lat": 45.97773627797133
  },
  {
    "name": "Long Prairie",
    "lng": -94.85664750299654,
    "lat": 45.95258058622207
  },
  {
    "name": "Long View",
    "lng": -81.38532702426832,
    "lat": 35.72145763113209
  },
  {
    "name": "Longboat Key",
    "lng": -82.63645554606444,
    "lat": 27.390930906347315
  },
  {
    "name": "Longdale",
    "lng": -98.55118799833859,
    "lat": 36.13373896705762
  },
  {
    "name": "Longford",
    "lng": -97.3290605657609,
    "lat": 39.1721919496396
  },
  {
    "name": "Longmeadow",
    "lng": -72.57190667534587,
    "lat": 42.0475637940836
  },
  {
    "name": "Longmont",
    "lng": -105.10461906356166,
    "lat": 40.170322059322686
  },
  {
    "name": "Longstreet",
    "lng": -93.95051730875583,
    "lat": 32.09762290421621
  },
  {
    "name": "Longton",
    "lng": -96.08228545942222,
    "lat": 37.377722981335815
  },
  {
    "name": "Longtown",
    "lng": -89.77413692789543,
    "lat": 37.67030601198261
  },
  {
    "name": "Longview",
    "lng": -122.9638451994035,
    "lat": 46.14687659857616
  },
  {
    "name": "Longview",
    "lng": -94.7621304847614,
    "lat": 32.51925258162625
  },
  {
    "name": "Longview",
    "lng": -88.06621046439841,
    "lat": 39.885917970616134
  },
  {
    "name": "Longville",
    "lng": -94.2122259579933,
    "lat": 46.98763120541959
  },
  {
    "name": "Longwood Village",
    "lng": -95.64048566313775,
    "lat": 29.986523958863653
  },
  {
    "name": "Lonoke",
    "lng": -91.9072213885146,
    "lat": 34.7900120980023
  },
  {
    "name": "Lonsdale",
    "lng": -93.4223272741771,
    "lat": 44.477547545412115
  },
  {
    "name": "Lonsdale",
    "lng": -92.81107688738493,
    "lat": 34.545313367937354
  },
  {
    "name": "Loogootee",
    "lng": -86.91426589897581,
    "lat": 38.67591028323355
  },
  {
    "name": "Lookeba",
    "lng": -98.3638307360713,
    "lat": 35.36302872825512
  },
  {
    "name": "Lookout Mountain",
    "lng": -85.36219240147275,
    "lat": 34.96640772267132
  },
  {
    "name": "Lookout Mountain",
    "lng": -85.35120030022352,
    "lat": 34.99435565563547
  },
  {
    "name": "Loomis",
    "lng": -121.19531097516406,
    "lat": 38.80938613611364
  },
  {
    "name": "Loomis",
    "lng": -99.50768231842325,
    "lat": 40.47828288033963
  },
  {
    "name": "Lorain",
    "lng": -82.18387725089524,
    "lat": 41.440996081949265
  },
  {
    "name": "Loraine",
    "lng": -101.56788960849808,
    "lat": 48.86835463108469
  },
  {
    "name": "Loraine",
    "lng": -100.71252251879413,
    "lat": 32.408654982116374
  },
  {
    "name": "Loraine",
    "lng": -91.22150913704432,
    "lat": 40.15257091534948
  },
  {
    "name": "Lorane",
    "lng": -75.84750334989928,
    "lat": 40.2921567923624
  },
  {
    "name": "Lordsburg",
    "lng": -108.7024355446495,
    "lat": 32.34382040775985
  },
  {
    "name": "Lordstown",
    "lng": -80.85899374331952,
    "lat": 41.16839931038648
  },
  {
    "name": "Lore City",
    "lng": -81.46007672148913,
    "lat": 39.98320348602224
  },
  {
    "name": "Loreauville",
    "lng": -91.73776384360089,
    "lat": 30.060176477619855
  },
  {
    "name": "Lorena",
    "lng": -97.21279606225468,
    "lat": 31.38163280428962
  },
  {
    "name": "Lorenzo",
    "lng": -101.53607801121747,
    "lat": 33.67034078196509
  },
  {
    "name": "Loretto",
    "lng": -93.63532637723364,
    "lat": 45.054783391005714
  },
  {
    "name": "Loretto",
    "lng": -87.44063682938354,
    "lat": 35.08027804447644
  },
  {
    "name": "Loretto",
    "lng": -85.39878574018009,
    "lat": 37.634900598530535
  },
  {
    "name": "Lorimor",
    "lng": -94.05638193663445,
    "lat": 41.12625249011972
  },
  {
    "name": "Loris",
    "lng": -78.88529648722589,
    "lat": 34.05716166586428
  },
  {
    "name": "Lorraine",
    "lng": -98.31722076042414,
    "lat": 38.56901122128874
  },
  {
    "name": "Lorton",
    "lng": -96.02411455891662,
    "lat": 40.59729564307581
  },
  {
    "name": "Los Altos",
    "lng": -122.09629234745996,
    "lat": 37.368128881643784
  },
  {
    "name": "Los Altos Hills",
    "lng": -122.13953180531463,
    "lat": 37.36745235554394
  },
  {
    "name": "Los Angeles",
    "lng": -118.40732603610421,
    "lat": 34.08997144462206
  },
  {
    "name": "Los Angeles Eco Village",
    "lng": -118.29059069848545,
    "lat": 34.072323836977894
  },
  {
    "name": "Los Banos",
    "lng": -120.84071606800812,
    "lat": 37.0626812186001
  },
  {
    "name": "Los Fresnos",
    "lng": -97.48783068617706,
    "lat": 26.074999344475984
  },
  {
    "name": "Los Gatos",
    "lng": -121.95688851854848,
    "lat": 37.230879052318855
  },
  {
    "name": "Los Indios",
    "lng": -97.73513807069186,
    "lat": 26.049300705938556
  },
  {
    "name": "Los Lunas",
    "lng": -106.68240355175055,
    "lat": 34.79293657430617
  },
  {
    "name": "Los Ranchos de Albuquerque",
    "lng": -106.66404170831356,
    "lat": 35.13924997285983
  },
  {
    "name": "Los Ranchos de Albuquerque",
    "lng": -106.64793214794592,
    "lat": 35.16341476704623
  },
  {
    "name": "Los Trancos Woods",
    "lng": -122.19725583279964,
    "lat": 37.34198775275291
  },
  {
    "name": "Losantville",
    "lng": -85.1833387260031,
    "lat": 40.023527315272
  },
  {
    "name": "Lost Creek",
    "lng": -80.34844352432184,
    "lat": 39.16276761999925
  },
  {
    "name": "Lost Hills",
    "lng": -119.67885761222763,
    "lat": 35.62540291456328
  },
  {
    "name": "Lost Nation",
    "lng": -90.81815050023886,
    "lat": 41.96756311721403
  },
  {
    "name": "Lost River",
    "lng": -113.6278920456059,
    "lat": 43.69118626470107
  },
  {
    "name": "Lost River",
    "lng": -113.49744907674777,
    "lat": 43.7670385609326
  },
  {
    "name": "Lost River",
    "lng": -113.43093338247705,
    "lat": 43.81898437275786
  },
  {
    "name": "Lost Springs",
    "lng": -104.92549200734508,
    "lat": 42.76516553468373
  },
  {
    "name": "Lost Springs",
    "lng": -96.9656366598857,
    "lat": 38.566593321729535
  },
  {
    "name": "Lostant",
    "lng": -89.06682005402457,
    "lat": 41.12517502470675
  },
  {
    "name": "Lotsee",
    "lng": -96.20918364703425,
    "lat": 36.13329330713334
  },
  {
    "name": "Lott",
    "lng": -97.03303208221891,
    "lat": 31.205793458322898
  },
  {
    "name": "Loudon",
    "lng": -84.3719542535958,
    "lat": 35.703650355119215
  },
  {
    "name": "Loudon",
    "lng": -84.36345267862846,
    "lat": 35.74092024975909
  },
  {
    "name": "Loudon",
    "lng": -71.44134002727844,
    "lat": 43.32115454177166
  },
  {
    "name": "Loudonville",
    "lng": -82.23417421201354,
    "lat": 40.63385849747211
  },
  {
    "name": "Louin",
    "lng": -89.26261625590634,
    "lat": 32.072194977326106
  },
  {
    "name": "Louisa",
    "lng": -82.60681621665698,
    "lat": 38.10794009249501
  },
  {
    "name": "Louisa",
    "lng": -77.9984860561478,
    "lat": 38.02118756766877
  },
  {
    "name": "Louisburg",
    "lng": -96.17085000520686,
    "lat": 45.16423681982182
  },
  {
    "name": "Louisburg",
    "lng": -94.70125407562837,
    "lat": 38.64612517708156
  },
  {
    "name": "Louisburg",
    "lng": -94.68966924593323,
    "lat": 38.59674641699849
  },
  {
    "name": "Louisburg",
    "lng": -94.67703276380861,
    "lat": 38.62127617677254
  },
  {
    "name": "Louisburg",
    "lng": -94.67527633883749,
    "lat": 38.50693506321876
  },
  {
    "name": "Louisburg",
    "lng": -94.6625505318669,
    "lat": 38.59551770942575
  },
  {
    "name": "Louisburg",
    "lng": -94.64101373561112,
    "lat": 38.59997009664882
  },
  {
    "name": "Louisburg",
    "lng": -93.14104654846346,
    "lat": 37.75638999417908
  },
  {
    "name": "Louisburg",
    "lng": -78.30075118350203,
    "lat": 36.09786031909871
  },
  {
    "name": "Louise",
    "lng": -90.59045380326627,
    "lat": 32.98139659406465
  },
  {
    "name": "Louisiana",
    "lng": -91.82211112002399,
    "lat": 30.92906152843949
  },
  {
    "name": "Louisiana",
    "lng": -91.0629989990736,
    "lat": 39.44189907555963
  },
  {
    "name": "Louisville",
    "lng": -105.14404682334104,
    "lat": 39.970882488220575
  },
  {
    "name": "Louisville",
    "lng": -96.31443286030812,
    "lat": 39.24983296715632
  },
  {
    "name": "Louisville",
    "lng": -96.16054851126275,
    "lat": 40.9975330982375
  },
  {
    "name": "Louisville",
    "lng": -89.05535793878069,
    "lat": 33.12240421935767
  },
  {
    "name": "Louisville",
    "lng": -88.5068573904433,
    "lat": 38.769406304727475
  },
  {
    "name": "Louisville",
    "lng": -85.55833363507163,
    "lat": 31.781049392822563
  },
  {
    "name": "Louisville",
    "lng": -84.05519828744781,
    "lat": 35.824241988496404
  },
  {
    "name": "Louisville",
    "lng": -84.04831910218834,
    "lat": 35.84856895858823
  },
  {
    "name": "Louisville",
    "lng": -82.40002553963139,
    "lat": 32.99588636464649
  },
  {
    "name": "Louisville",
    "lng": -81.26448918235923,
    "lat": 40.8367213203785
  },
  {
    "name": "Loup City",
    "lng": -98.9679163903147,
    "lat": 41.277027860678814
  },
  {
    "name": "Loup City",
    "lng": -98.96768655630298,
    "lat": 41.26473202170099
  },
  {
    "name": "Love Valley",
    "lng": -80.98907312563152,
    "lat": 35.98628737694085
  },
  {
    "name": "Lovejoy",
    "lng": -84.34295793271231,
    "lat": 33.44888084315047
  },
  {
    "name": "Lovejoy",
    "lng": -84.33870929040818,
    "lat": 33.44825142522605
  },
  {
    "name": "Lovejoy",
    "lng": -84.33617813218046,
    "lat": 33.468180570587165
  },
  {
    "name": "Lovejoy",
    "lng": -84.33022720963638,
    "lat": 33.44750348554685
  },
  {
    "name": "Lovejoy",
    "lng": -84.32831594276338,
    "lat": 33.4515956880291
  },
  {
    "name": "Lovejoy",
    "lng": -84.32597478784054,
    "lat": 33.453723914582525
  },
  {
    "name": "Lovejoy",
    "lng": -84.31891859103204,
    "lat": 33.47748117909222
  },
  {
    "name": "Lovejoy",
    "lng": -84.31726750306792,
    "lat": 33.440614050819846
  },
  {
    "name": "Lovejoy",
    "lng": -84.31171693779775,
    "lat": 33.45935032992631
  },
  {
    "name": "Lovejoy",
    "lng": -84.29868801835374,
    "lat": 33.47661943927097
  },
  {
    "name": "Lovelady",
    "lng": -95.44562589931492,
    "lat": 31.127872707578646
  },
  {
    "name": "Loveland",
    "lng": -105.06184191629826,
    "lat": 40.417730031354395
  },
  {
    "name": "Loveland",
    "lng": -98.77081043060329,
    "lat": 34.30462135702724
  },
  {
    "name": "Loveland",
    "lng": -84.27348327632448,
    "lat": 39.26762861814979
  },
  {
    "name": "Lovell",
    "lng": -108.39174956992991,
    "lat": 44.83603383888088
  },
  {
    "name": "Lovell",
    "lng": -70.88465067011838,
    "lat": 44.18933657066056
  },
  {
    "name": "Lovelock",
    "lng": -118.47753927743986,
    "lat": 40.1787616823684
  },
  {
    "name": "Loves Park",
    "lng": -88.99755021792438,
    "lat": 42.336492503387966
  },
  {
    "name": "Loves Park",
    "lng": -88.98275968842793,
    "lat": 42.33983311371306
  },
  {
    "name": "Lovettsville",
    "lng": -77.63987784003157,
    "lat": 39.27322429298475
  },
  {
    "name": "Lovilia",
    "lng": -92.90802743436016,
    "lat": 41.13485248303749
  },
  {
    "name": "Loving",
    "lng": -104.09699821228061,
    "lat": 32.28660929499279
  },
  {
    "name": "Lovington",
    "lng": -103.35047402056009,
    "lat": 32.945670509912915
  },
  {
    "name": "Lovington",
    "lng": -88.6318269815242,
    "lat": 39.71439374539582
  },
  {
    "name": "Low Moor",
    "lng": -90.35494860400647,
    "lat": 41.802968813792916
  },
  {
    "name": "Lowden",
    "lng": -90.92291382879422,
    "lat": 41.85919494326364
  },
  {
    "name": "Lowell",
    "lng": -94.1532197145164,
    "lat": 36.25609978455894
  },
  {
    "name": "Lowell",
    "lng": -88.81840355186449,
    "lat": 43.336354294694836
  },
  {
    "name": "Lowell",
    "lng": -87.42657593246481,
    "lat": 41.30789462326903
  },
  {
    "name": "Lowell",
    "lng": -87.41354186491662,
    "lat": 41.294792620427856
  },
  {
    "name": "Lowell",
    "lng": -85.34574257028737,
    "lat": 42.93507467979379
  },
  {
    "name": "Lowell",
    "lng": -81.50681982427933,
    "lat": 39.52928270131353
  },
  {
    "name": "Lowell",
    "lng": -81.10138077306736,
    "lat": 35.26920829591292
  },
  {
    "name": "Lowell",
    "lng": -71.32171552465536,
    "lat": 42.63887521789003
  },
  {
    "name": "Lowell",
    "lng": -68.49241928107797,
    "lat": 45.218656070923245
  },
  {
    "name": "Lowellville",
    "lng": -80.54631258901055,
    "lat": 41.039025156050734
  },
  {
    "name": "Lower Burrell",
    "lng": -79.71402607148572,
    "lat": 40.58183716443023
  },
  {
    "name": "Lower Kalskag",
    "lng": -160.35681851238027,
    "lat": 61.516482627388505
  },
  {
    "name": "Lower Salem",
    "lng": -81.39422454362487,
    "lat": 39.56338380153443
  },
  {
    "name": "Lowndesville",
    "lng": -82.64760873062339,
    "lat": 34.20990519807889
  },
  {
    "name": "Lowry",
    "lng": -99.98115757176501,
    "lat": 45.31558282168276
  },
  {
    "name": "Lowry",
    "lng": -95.51913597707912,
    "lat": 45.70496089966831
  },
  {
    "name": "Lowry City",
    "lng": -93.72735480242021,
    "lat": 38.140217213342524
  },
  {
    "name": "Lowrys",
    "lng": -81.23722224722232,
    "lat": 34.80401067285427
  },
  {
    "name": "Lowville",
    "lng": -75.48740361080205,
    "lat": 43.7866145997467
  },
  {
    "name": "Loxahatchee Groves",
    "lng": -80.27628801249205,
    "lat": 26.710827418895615
  },
  {
    "name": "Loxley",
    "lng": -87.74999089106527,
    "lat": 30.67716207124795
  },
  {
    "name": "Loxley",
    "lng": -87.66928502125702,
    "lat": 30.67256182081399
  },
  {
    "name": "Loxley",
    "lng": -87.65517102857383,
    "lat": 30.6589925107565
  },
  {
    "name": "Loyal",
    "lng": -98.11839405155798,
    "lat": 35.9727836384471
  },
  {
    "name": "Loyal",
    "lng": -90.49593536481964,
    "lat": 44.73648384268098
  },
  {
    "name": "Loyalhanna",
    "lng": -79.35609175175207,
    "lat": 40.31443699112752
  },
  {
    "name": "Loyall",
    "lng": -83.3508975832802,
    "lat": 36.85261091498677
  },
  {
    "name": "Loyalton",
    "lng": -120.24477054879355,
    "lat": 39.67696732505247
  },
  {
    "name": "Lu Verne",
    "lng": -94.08359236934572,
    "lat": 42.909462432149226
  },
  {
    "name": "Luana",
    "lng": -91.4556889253686,
    "lat": 43.059424405588324
  },
  {
    "name": "Lubbock",
    "lng": -101.95527159347121,
    "lat": 33.619840388395815
  },
  {
    "name": "Lubbock",
    "lng": -101.88773124371889,
    "lat": 33.56592733363952
  },
  {
    "name": "Lubec",
    "lng": -67.01685607427136,
    "lat": 44.81791230100369
  },
  {
    "name": "Lublin",
    "lng": -90.72366551411736,
    "lat": 45.074917204937556
  },
  {
    "name": "Lucama",
    "lng": -78.0071894985819,
    "lat": 35.64466179408441
  },
  {
    "name": "Lucan",
    "lng": -95.41340068877163,
    "lat": 44.410033985548544
  },
  {
    "name": "Lucas",
    "lng": -98.53895767690314,
    "lat": 39.058425297268215
  },
  {
    "name": "Lucas",
    "lng": -98.52663607961132,
    "lat": 39.06051585385171
  },
  {
    "name": "Lucas",
    "lng": -93.46093883652297,
    "lat": 41.032651119309655
  },
  {
    "name": "Lucas",
    "lng": -82.42242760233462,
    "lat": 40.70424300555164
  },
  {
    "name": "Lucasville",
    "lng": -82.99336349559228,
    "lat": 38.87832355658828
  },
  {
    "name": "Lucedale",
    "lng": -88.59589639721695,
    "lat": 30.93118599148222
  },
  {
    "name": "Lucerne",
    "lng": -93.29117118581986,
    "lat": 40.463983113853985
  },
  {
    "name": "Luck",
    "lng": -92.46646493032267,
    "lat": 45.57144671125143
  },
  {
    "name": "Luckey",
    "lng": -83.48386753642299,
    "lat": 41.45245012323653
  },
  {
    "name": "Lucky",
    "lng": -93.01580970967726,
    "lat": 32.244366875612165
  },
  {
    "name": "Ludden",
    "lng": -98.12549831213836,
    "lat": 46.00811063234337
  },
  {
    "name": "Ludington",
    "lng": -86.44328662065458,
    "lat": 43.95673080812236
  },
  {
    "name": "Ludlow",
    "lng": -93.70259028461707,
    "lat": 39.65427041866206
  },
  {
    "name": "Ludlow",
    "lng": -88.12586408338152,
    "lat": 40.38661939261179
  },
  {
    "name": "Ludlow",
    "lng": -84.54980790712825,
    "lat": 39.09070104657768
  },
  {
    "name": "Ludlow",
    "lng": -72.4580461231538,
    "lat": 42.19211444571086
  },
  {
    "name": "Ludlow",
    "lng": -67.97202931065996,
    "lat": 46.164268185422316
  },
  {
    "name": "Ludlow Falls",
    "lng": -84.33916850581889,
    "lat": 39.99810924102602
  },
  {
    "name": "Ludowici",
    "lng": -81.74571468006195,
    "lat": 31.70964652632579
  },
  {
    "name": "Lufkin",
    "lng": -94.727721561116,
    "lat": 31.321568229216147
  },
  {
    "name": "Lula",
    "lng": -90.47787859562538,
    "lat": 34.45450694106884
  },
  {
    "name": "Lula",
    "lng": -83.67900789938203,
    "lat": 34.404240142866996
  },
  {
    "name": "Lula",
    "lng": -83.67232889868109,
    "lat": 34.40822068044128
  },
  {
    "name": "Lula",
    "lng": -83.66655030611348,
    "lat": 34.41188744904839
  },
  {
    "name": "Lula",
    "lng": -83.66596854923873,
    "lat": 34.39177562752681
  },
  {
    "name": "Lula",
    "lng": -83.65576702917667,
    "lat": 34.427054241094524
  },
  {
    "name": "Lumber Bridge",
    "lng": -79.07237845029393,
    "lat": 34.889862847987075
  },
  {
    "name": "Lumber City",
    "lng": -82.68291164802815,
    "lat": 31.933081553935892
  },
  {
    "name": "Lumberport",
    "lng": -80.34832821224913,
    "lat": 39.37416233061824
  },
  {
    "name": "Lumberton",
    "lng": -94.20705021980089,
    "lat": 30.256209450128857
  },
  {
    "name": "Lumberton",
    "lng": -89.46126900783116,
    "lat": 31.006478559968173
  },
  {
    "name": "Lumberton",
    "lng": -79.04488738960063,
    "lat": 34.58373047907103
  },
  {
    "name": "Lumberton",
    "lng": -79.01781887493878,
    "lat": 34.6304562993857
  },
  {
    "name": "Lumberton",
    "lng": -79.01639259557933,
    "lat": 34.686326388364144
  },
  {
    "name": "Lumberton",
    "lng": -79.01576215594024,
    "lat": 34.68203210358642
  },
  {
    "name": "Lumberton",
    "lng": -79.01165651935173,
    "lat": 34.69997109657331
  },
  {
    "name": "Lumpkin",
    "lng": -84.79825881499812,
    "lat": 32.04804292750124
  },
  {
    "name": "Luna Pier",
    "lng": -83.44188279533307,
    "lat": 41.80688115906659
  },
  {
    "name": "Lunenburg",
    "lng": -71.71991082881144,
    "lat": 42.58969449937057
  },
  {
    "name": "Lupus",
    "lng": -92.45400626299512,
    "lat": 38.845972587918624
  },
  {
    "name": "Luray",
    "lng": -98.6988259446207,
    "lat": 39.119448699734804
  },
  {
    "name": "Luray",
    "lng": -98.6917403510154,
    "lat": 39.114449547059245
  },
  {
    "name": "Luray",
    "lng": -98.68280878021937,
    "lat": 39.11377539406902
  },
  {
    "name": "Luray",
    "lng": -91.88411357771264,
    "lat": 40.4523816583363
  },
  {
    "name": "Luray",
    "lng": -81.24026495128632,
    "lat": 32.81420796498409
  },
  {
    "name": "Luray",
    "lng": -78.45468765788203,
    "lat": 38.66494168895166
  },
  {
    "name": "Lushton",
    "lng": -97.72364803625545,
    "lat": 40.724030226811365
  },
  {
    "name": "Lusk",
    "lng": -104.45840271626771,
    "lat": 42.76126067914344
  },
  {
    "name": "Lutcher",
    "lng": -90.71245717039216,
    "lat": 30.06475800954128
  },
  {
    "name": "Luther",
    "lng": -97.19081409612046,
    "lat": 35.66807893350671
  },
  {
    "name": "Luther",
    "lng": -93.81765907522443,
    "lat": 41.96694168474753
  },
  {
    "name": "Luther",
    "lng": -85.68264098290331,
    "lat": 44.03838818890462
  },
  {
    "name": "Luthersville",
    "lng": -84.74426547565382,
    "lat": 33.20958441519775
  },
  {
    "name": "Luttrell",
    "lng": -83.74769812132142,
    "lat": 36.20988978263583
  },
  {
    "name": "Lutz",
    "lng": -82.45244921817158,
    "lat": 28.140225380223907
  },
  {
    "name": "Luverne",
    "lng": -97.93487668172348,
    "lat": 47.25139408454309
  },
  {
    "name": "Luverne",
    "lng": -96.21421178858232,
    "lat": 43.65345572474479
  },
  {
    "name": "Luverne",
    "lng": -86.2686686239137,
    "lat": 31.718643744656266
  },
  {
    "name": "Luxemburg",
    "lng": -91.07251717475012,
    "lat": 42.60400082274718
  },
  {
    "name": "Luxemburg",
    "lng": -87.7061619957582,
    "lat": 44.54488453433993
  },
  {
    "name": "Luxora",
    "lng": -89.9298765393607,
    "lat": 35.75747794548559
  },
  {
    "name": "Luzerne",
    "lng": -92.18033804032699,
    "lat": 41.90559636913696
  },
  {
    "name": "Lyerly",
    "lng": -85.40405469476023,
    "lat": 34.40343456678402
  },
  {
    "name": "Lyford",
    "lng": -97.78942976746971,
    "lat": 26.414664484663778
  },
  {
    "name": "Lyle",
    "lng": -92.9412851023219,
    "lat": 43.50583317717306
  },
  {
    "name": "Lyman",
    "lng": -122.06338307679694,
    "lat": 48.5230353309573
  },
  {
    "name": "Lyman",
    "lng": -111.58933962174873,
    "lat": 38.39662070188775
  },
  {
    "name": "Lyman",
    "lng": -110.33789679129255,
    "lat": 41.32167072369514
  },
  {
    "name": "Lyman",
    "lng": -110.29799236972192,
    "lat": 41.327336848619495
  },
  {
    "name": "Lyman",
    "lng": -110.2596550713534,
    "lat": 41.33889534024824
  },
  {
    "name": "Lyman",
    "lng": -104.03736557456224,
    "lat": 41.91764520092905
  },
  {
    "name": "Lyman",
    "lng": -82.16457796660879,
    "lat": 34.97695056337918
  },
  {
    "name": "Lyman",
    "lng": -82.11538253149837,
    "lat": 34.95822268552903
  },
  {
    "name": "Lyman",
    "lng": -70.64357309232665,
    "lat": 43.50257916906678
  },
  {
    "name": "Lyme",
    "lng": -72.3437443924859,
    "lat": 41.39653827074279
  },
  {
    "name": "Lynbrook",
    "lng": -73.67417818652694,
    "lat": 40.65786404971997
  },
  {
    "name": "Lynch",
    "lng": -98.46685337969565,
    "lat": 42.831215122469985
  },
  {
    "name": "Lynch",
    "lng": -82.91228949714551,
    "lat": 36.96399737161616
  },
  {
    "name": "Lynchburg",
    "lng": -83.78828577320546,
    "lat": 39.2401605738461
  },
  {
    "name": "Lynchburg",
    "lng": -80.07697403450737,
    "lat": 34.06000496158026
  },
  {
    "name": "Lynchburg",
    "lng": -79.19082442398025,
    "lat": 37.400386214874246
  },
  {
    "name": "Lynd",
    "lng": -95.88141886637099,
    "lat": 44.39728319748787
  },
  {
    "name": "Lyndeborough",
    "lng": -71.77466887525895,
    "lat": 42.902873305702705
  },
  {
    "name": "Lynden",
    "lng": -122.45433851088036,
    "lat": 48.950311557267234
  },
  {
    "name": "Lyndhurst",
    "lng": -81.4922523286676,
    "lat": 41.51722808667903
  },
  {
    "name": "Lyndon",
    "lng": -95.68461796454721,
    "lat": 38.61158214257928
  },
  {
    "name": "Lyndon",
    "lng": -89.9238180684023,
    "lat": 41.71740837972401
  },
  {
    "name": "Lyndon",
    "lng": -85.58910035925913,
    "lat": 38.26442356248408
  },
  {
    "name": "Lyndon Station",
    "lng": -89.89467314107493,
    "lat": 43.70926339913204
  },
  {
    "name": "Lyndonville",
    "lng": -78.38886920844726,
    "lat": 43.32134603414706
  },
  {
    "name": "Lyndonville",
    "lng": -72.00181310212108,
    "lat": 44.53516702416033
  },
  {
    "name": "Lynn",
    "lng": -91.25197833978078,
    "lat": 36.004284929560036
  },
  {
    "name": "Lynn",
    "lng": -87.55547407193717,
    "lat": 34.05479458986743
  },
  {
    "name": "Lynn",
    "lng": -84.94246029299332,
    "lat": 40.0487172794514
  },
  {
    "name": "Lynn Haven",
    "lng": -85.63719153389397,
    "lat": 30.233995919762354
  },
  {
    "name": "Lynn Haven",
    "lng": -85.6213257770118,
    "lat": 30.23261162806728
  },
  {
    "name": "Lynn Haven",
    "lng": -85.61995321403616,
    "lat": 30.232757697022823
  },
  {
    "name": "Lynn Haven",
    "lng": -85.6046757740983,
    "lat": 30.24340937095288
  },
  {
    "name": "Lynndyl",
    "lng": -112.39344486907295,
    "lat": 39.50861813388044
  },
  {
    "name": "Lynnview",
    "lng": -85.71108706580152,
    "lat": 38.179192619322265
  },
  {
    "name": "Lynnville",
    "lng": -92.78695461360877,
    "lat": 41.57277446486419
  },
  {
    "name": "Lynnville",
    "lng": -90.34595134215063,
    "lat": 39.68571063182404
  },
  {
    "name": "Lynnville",
    "lng": -87.31449589548728,
    "lat": 38.19890991737274
  },
  {
    "name": "Lynnville",
    "lng": -87.00610559124931,
    "lat": 35.378103727140555
  },
  {
    "name": "Lynnwood",
    "lng": -122.33950212702048,
    "lat": 47.84621235504759
  },
  {
    "name": "Lynnwood",
    "lng": -122.3032191814067,
    "lat": 47.82847820720365
  },
  {
    "name": "Lynwood",
    "lng": -87.54748754903665,
    "lat": 41.52443486282734
  },
  {
    "name": "Lynxville",
    "lng": -91.04632288971415,
    "lat": 43.24923075478655
  },
  {
    "name": "Lyon",
    "lng": -90.54269494370077,
    "lat": 34.21692763328605
  },
  {
    "name": "Lyons",
    "lng": -105.26858030404927,
    "lat": 40.22289519545078
  },
  {
    "name": "Lyons",
    "lng": -98.20276489197182,
    "lat": 38.34657934620062
  },
  {
    "name": "Lyons",
    "lng": -96.47160861301666,
    "lat": 41.936044779577564
  },
  {
    "name": "Lyons",
    "lng": -87.81917160954261,
    "lat": 41.812003342579665
  },
  {
    "name": "Lyons",
    "lng": -87.08256125204389,
    "lat": 38.974240388851584
  },
  {
    "name": "Lyons",
    "lng": -87.08121838984063,
    "lat": 38.98853931383833
  },
  {
    "name": "Lyons",
    "lng": -84.94559394379827,
    "lat": 42.9833109009171
  },
  {
    "name": "Lyons",
    "lng": -84.07179465771813,
    "lat": 41.70035906519231
  },
  {
    "name": "Lyons",
    "lng": -82.32146310527652,
    "lat": 32.205812522779645
  },
  {
    "name": "Lyons Falls",
    "lng": -75.3618956371286,
    "lat": 43.61715073405951
  },
  {
    "name": "Lytle",
    "lng": -98.7943108892335,
    "lat": 29.233862789005098
  },
  {
    "name": "Lytton",
    "lng": -94.86039317830335,
    "lat": 42.423641839622846
  },
  {
    "name": "MDOT Grand Rapids Transportation Service Center",
    "lng": -85.60375272224266,
    "lat": 42.983768211416475
  },
  {
    "name": "Mabank",
    "lng": -96.11328184771882,
    "lat": 32.36822730504395
  },
  {
    "name": "Mabel",
    "lng": -91.76804849183894,
    "lat": 43.51983836979813
  },
  {
    "name": "Maben",
    "lng": -89.08053413694626,
    "lat": 33.55440072788106
  },
  {
    "name": "Mabscott",
    "lng": -81.21329955919033,
    "lat": 37.769478244284386
  },
  {
    "name": "Mabscott",
    "lng": -81.2046119880307,
    "lat": 37.76519065273063
  },
  {
    "name": "Mabton",
    "lng": -119.99683604514904,
    "lat": 46.21239559326419
  },
  {
    "name": "Macclenny",
    "lng": -82.14101639934478,
    "lat": 30.284674611296957
  },
  {
    "name": "Macclenny",
    "lng": -82.12565072760698,
    "lat": 30.281653112841237
  },
  {
    "name": "Macclenny",
    "lng": -82.107376841784,
    "lat": 30.264543553112027
  },
  {
    "name": "Macclesfield",
    "lng": -77.67034647885875,
    "lat": 35.75221324090589
  },
  {
    "name": "Macedon",
    "lng": -77.303734088129,
    "lat": 43.06830161110519
  },
  {
    "name": "Macedonia",
    "lng": -95.4264086111316,
    "lat": 41.192360111228844
  },
  {
    "name": "Macedonia",
    "lng": -88.70537894072046,
    "lat": 38.05250539234623
  },
  {
    "name": "Macedonia",
    "lng": -81.49888205567109,
    "lat": 41.31452302725255
  },
  {
    "name": "Machesney Park",
    "lng": -89.02653070726456,
    "lat": 42.36666846883896
  },
  {
    "name": "Machias",
    "lng": -67.45823612822021,
    "lat": 44.691693498727474
  },
  {
    "name": "Machiasport",
    "lng": -67.37802015746077,
    "lat": 44.62763801001853
  },
  {
    "name": "Mackay",
    "lng": -113.61272909695388,
    "lat": 43.91199608441763
  },
  {
    "name": "Mackenzie",
    "lng": -90.31637614293709,
    "lat": 38.58099992336416
  },
  {
    "name": "Mackey",
    "lng": -87.39113417519515,
    "lat": 38.25088925391575
  },
  {
    "name": "Mackinac Island",
    "lng": -84.62915752664141,
    "lat": 45.86436711439416
  },
  {
    "name": "Mackinac Island",
    "lng": -84.60066289939341,
    "lat": 45.82845798317833
  },
  {
    "name": "Mackinaw",
    "lng": -89.35555324030594,
    "lat": 40.53398691672915
  },
  {
    "name": "Mackinaw City",
    "lng": -84.74816010605613,
    "lat": 45.775724970587824
  },
  {
    "name": "Mackinaw City",
    "lng": -84.7086811736306,
    "lat": 45.76216292985715
  },
  {
    "name": "Macks Creek",
    "lng": -92.97162499152574,
    "lat": 37.96545439355174
  },
  {
    "name": "Macksburg",
    "lng": -94.1850590429186,
    "lat": 41.214701591001706
  },
  {
    "name": "Macksburg",
    "lng": -81.45621543470703,
    "lat": 39.631449815929976
  },
  {
    "name": "Macksville",
    "lng": -98.96883369285361,
    "lat": 37.95723401492553
  },
  {
    "name": "Mackville",
    "lng": -85.0696192144193,
    "lat": 37.73499145107386
  },
  {
    "name": "Macomb",
    "lng": -97.00865202000958,
    "lat": 35.14789735926433
  },
  {
    "name": "Macomb",
    "lng": -90.68124845728954,
    "lat": 40.47176758832522
  },
  {
    "name": "Macon",
    "lng": -92.49878134756061,
    "lat": 39.76259217117672
  },
  {
    "name": "Macon",
    "lng": -92.49780945708247,
    "lat": 39.76041515929372
  },
  {
    "name": "Macon",
    "lng": -92.47122045214272,
    "lat": 39.74233689713296
  },
  {
    "name": "Macon",
    "lng": -88.99656109873068,
    "lat": 39.71167035197409
  },
  {
    "name": "Macon",
    "lng": -88.55644413445707,
    "lat": 33.12160251859045
  },
  {
    "name": "Macon",
    "lng": -83.69739436863688,
    "lat": 32.80655230369297
  },
  {
    "name": "Macon",
    "lng": -78.08393343231357,
    "lat": 36.43959911089862
  },
  {
    "name": "Macy",
    "lng": -86.12943969153976,
    "lat": 40.958173293089665
  },
  {
    "name": "Madawaska",
    "lng": -68.24967128026223,
    "lat": 47.29253568873693
  },
  {
    "name": "Maddock",
    "lng": -99.52924457512447,
    "lat": 47.96244950987074
  },
  {
    "name": "Madeira",
    "lng": -84.37328968865401,
    "lat": 39.185607956094465
  },
  {
    "name": "Madeira Beach",
    "lng": -82.79220619878326,
    "lat": 27.79592959675614
  },
  {
    "name": "Madelia",
    "lng": -94.4206316964295,
    "lat": 44.047857311388164
  },
  {
    "name": "Madelia",
    "lng": -94.3909042648554,
    "lat": 44.06246111452852
  },
  {
    "name": "Madera",
    "lng": -120.1686028389656,
    "lat": 36.941703547165105
  },
  {
    "name": "Madera",
    "lng": -120.0767916275721,
    "lat": 36.96506902207464
  },
  {
    "name": "Madill",
    "lng": -96.77515919619483,
    "lat": 34.08849645043395
  },
  {
    "name": "Madill",
    "lng": -96.73561045790842,
    "lat": 34.04067073899197
  },
  {
    "name": "Madison",
    "lng": -97.4568186542874,
    "lat": 41.828527112895486
  },
  {
    "name": "Madison",
    "lng": -97.10848843904438,
    "lat": 44.00621946647096
  },
  {
    "name": "Madison",
    "lng": -96.18877164355423,
    "lat": 45.01305001936132
  },
  {
    "name": "Madison",
    "lng": -96.13719741604864,
    "lat": 38.13393533019282
  },
  {
    "name": "Madison",
    "lng": -92.21170212726093,
    "lat": 39.47353580473856
  },
  {
    "name": "Madison",
    "lng": -90.73198608315815,
    "lat": 35.01928640323802
  },
  {
    "name": "Madison",
    "lng": -90.14243920210147,
    "lat": 38.70013885913379
  },
  {
    "name": "Madison",
    "lng": -90.1300849743268,
    "lat": 32.47376419716658
  },
  {
    "name": "Madison",
    "lng": -89.39074952548776,
    "lat": 43.08222531157953
  },
  {
    "name": "Madison",
    "lng": -89.27988924193059,
    "lat": 43.11679870282398
  },
  {
    "name": "Madison",
    "lng": -86.76182316963616,
    "lat": 34.71243523777535
  },
  {
    "name": "Madison",
    "lng": -86.7364273901763,
    "lat": 34.75374216090578
  },
  {
    "name": "Madison",
    "lng": -86.71327481522718,
    "lat": 34.719110855991886
  },
  {
    "name": "Madison",
    "lng": -86.70732052381693,
    "lat": 34.71512884351316
  },
  {
    "name": "Madison",
    "lng": -86.70728409794656,
    "lat": 34.71423961767862
  },
  {
    "name": "Madison",
    "lng": -85.39757344833026,
    "lat": 38.75761989569536
  },
  {
    "name": "Madison",
    "lng": -83.47583105249133,
    "lat": 33.5790750124865
  },
  {
    "name": "Madison",
    "lng": -83.4304573375488,
    "lat": 30.460031187555074
  },
  {
    "name": "Madison",
    "lng": -83.41437301886899,
    "lat": 30.468216846349353
  },
  {
    "name": "Madison",
    "lng": -81.80490658463468,
    "lat": 38.0597841743942
  },
  {
    "name": "Madison",
    "lng": -81.05300727518575,
    "lat": 41.77226949062033
  },
  {
    "name": "Madison",
    "lng": -79.97317571916837,
    "lat": 36.386501522677555
  },
  {
    "name": "Madison",
    "lng": -79.67486125101348,
    "lat": 40.245557993138625
  },
  {
    "name": "Madison",
    "lng": -78.258629196476,
    "lat": 38.37906854511739
  },
  {
    "name": "Madison",
    "lng": -75.51216675935738,
    "lat": 42.89754332445536
  },
  {
    "name": "Madison",
    "lng": -69.80560670966098,
    "lat": 44.83246680332609
  },
  {
    "name": "Madison Heights",
    "lng": -83.10336777779851,
    "lat": 42.50731415312125
  },
  {
    "name": "Madisonville",
    "lng": -95.90892979048506,
    "lat": 30.954116177271313
  },
  {
    "name": "Madisonville",
    "lng": -90.16553392450155,
    "lat": 30.40048529982892
  },
  {
    "name": "Madisonville",
    "lng": -87.50388329112246,
    "lat": 37.34154667558103
  },
  {
    "name": "Madisonville",
    "lng": -84.39701024735102,
    "lat": 35.520146978486174
  },
  {
    "name": "Madisonville",
    "lng": -84.36202393904712,
    "lat": 35.52335572444498
  },
  {
    "name": "Madisonville",
    "lng": -84.34880441958956,
    "lat": 35.55115264447495
  },
  {
    "name": "Madrid",
    "lng": -101.52782225462997,
    "lat": 40.85245425438007
  },
  {
    "name": "Madrid",
    "lng": -93.82054770291603,
    "lat": 41.87570061610269
  },
  {
    "name": "Maeystown",
    "lng": -90.23307016400042,
    "lat": 38.22669060419295
  },
  {
    "name": "Magazine",
    "lng": -93.80643804691861,
    "lat": 35.15361766595529
  },
  {
    "name": "Magdalena",
    "lng": -107.23163703852377,
    "lat": 34.10950696817154
  },
  {
    "name": "Magee",
    "lng": -89.73387542989337,
    "lat": 31.872495036261405
  },
  {
    "name": "Maggie Valley",
    "lng": -83.09252878116949,
    "lat": 35.54944044179645
  },
  {
    "name": "Maggie Valley",
    "lng": -83.09027880491672,
    "lat": 35.51139139291027
  },
  {
    "name": "Maggie Valley",
    "lng": -83.08967351074244,
    "lat": 35.563078059147834
  },
  {
    "name": "Maggie Valley",
    "lng": -83.08278963867086,
    "lat": 35.51841405773788
  },
  {
    "name": "Maggie Valley",
    "lng": -83.07177856151151,
    "lat": 35.52083157574608
  },
  {
    "name": "Maggie Valley",
    "lng": -83.05491370650489,
    "lat": 35.529928630663626
  },
  {
    "name": "Maggie Valley",
    "lng": -83.04308543728744,
    "lat": 35.54027711761378
  },
  {
    "name": "Maggie Valley",
    "lng": -83.04013258132969,
    "lat": 35.525363087001196
  },
  {
    "name": "Maggie Valley",
    "lng": -83.03098752012828,
    "lat": 35.53201524906316
  },
  {
    "name": "Maggie Valley",
    "lng": -83.03017813292064,
    "lat": 35.52801005909924
  },
  {
    "name": "Maggie Valley",
    "lng": -83.02824279700884,
    "lat": 35.535219446651915
  },
  {
    "name": "Maggie Valley",
    "lng": -83.02777694914732,
    "lat": 35.53643621519623
  },
  {
    "name": "Maggie Valley",
    "lng": -83.02318924511323,
    "lat": 35.53403557773163
  },
  {
    "name": "Maggie Valley",
    "lng": -83.0222529033506,
    "lat": 35.52290647663503
  },
  {
    "name": "Maggie Valley",
    "lng": -83.0202322510857,
    "lat": 35.522876836831536
  },
  {
    "name": "Maggie Valley",
    "lng": -83.01863679631563,
    "lat": 35.52443963367562
  },
  {
    "name": "Maggie Valley",
    "lng": -83.01841557840139,
    "lat": 35.52345010997126
  },
  {
    "name": "Maggie Valley",
    "lng": -83.01383575819835,
    "lat": 35.525154685430316
  },
  {
    "name": "Maggie Valley",
    "lng": -83.01259165965028,
    "lat": 35.56233120036276
  },
  {
    "name": "Maggie Valley",
    "lng": -83.01243554145776,
    "lat": 35.53009483355054
  },
  {
    "name": "Maggie Valley",
    "lng": -83.01103324761878,
    "lat": 35.52350489460045
  },
  {
    "name": "Maggie Valley",
    "lng": -83.00888430822572,
    "lat": 35.527559410080926
  },
  {
    "name": "Maggie Valley",
    "lng": -83.00797574026602,
    "lat": 35.52682224131032
  },
  {
    "name": "Maggie Valley",
    "lng": -83.00743716387635,
    "lat": 35.53004761645668
  },
  {
    "name": "Magness",
    "lng": -91.4818915849157,
    "lat": 35.702575507626214
  },
  {
    "name": "Magnet",
    "lng": -97.46945352745688,
    "lat": 42.45614756790045
  },
  {
    "name": "Magnet Cove",
    "lng": -92.83748794385205,
    "lat": 34.43832520566201
  },
  {
    "name": "Magnetic Springs",
    "lng": -83.2627588262744,
    "lat": 40.353513677238475
  },
  {
    "name": "Magnolia",
    "lng": -96.07530148939162,
    "lat": 43.64418985400929
  },
  {
    "name": "Magnolia",
    "lng": -95.8745782113614,
    "lat": 41.69156712886347
  },
  {
    "name": "Magnolia",
    "lng": -95.74459490599473,
    "lat": 30.21019433771166
  },
  {
    "name": "Magnolia",
    "lng": -95.70599608381927,
    "lat": 30.218867810546037
  },
  {
    "name": "Magnolia",
    "lng": -93.26416338586861,
    "lat": 33.268665080172674
  },
  {
    "name": "Magnolia",
    "lng": -93.22607090219206,
    "lat": 33.277476076821685
  },
  {
    "name": "Magnolia",
    "lng": -90.46315620373441,
    "lat": 31.154159682376378
  },
  {
    "name": "Magnolia",
    "lng": -89.1957562509406,
    "lat": 41.11408549553607
  },
  {
    "name": "Magnolia",
    "lng": -81.29175573740605,
    "lat": 40.65321328092757
  },
  {
    "name": "Magnolia",
    "lng": -78.0547079121573,
    "lat": 34.89494752748397
  },
  {
    "name": "Magnolia Springs",
    "lng": -87.77489964749326,
    "lat": 30.40024757230788
  },
  {
    "name": "Maharishi Vedic City",
    "lng": -92.01461790737366,
    "lat": 41.0544793412483
  },
  {
    "name": "Mahaska",
    "lng": -97.35376041268044,
    "lat": 39.98767552756113
  },
  {
    "name": "Mahnomen",
    "lng": -95.9681991894226,
    "lat": 47.32346466721707
  },
  {
    "name": "Mahnomen",
    "lng": -95.96743517743747,
    "lat": 47.31467188302992
  },
  {
    "name": "Mahnomen",
    "lng": -95.9586411679263,
    "lat": 47.309104964481655
  },
  {
    "name": "Mahomet",
    "lng": -88.3913798547158,
    "lat": 40.18725299859335
  },
  {
    "name": "Mahomet",
    "lng": -88.36825099666834,
    "lat": 40.202212047374545
  },
  {
    "name": "Mahomet",
    "lng": -88.36815651245882,
    "lat": 40.218568372286235
  },
  {
    "name": "Mahtomedi",
    "lng": -92.96618815007601,
    "lat": 45.06167667288302
  },
  {
    "name": "Mahtomedi",
    "lng": -92.9542010317674,
    "lat": 45.057288428896804
  },
  {
    "name": "Maiden",
    "lng": -81.25875931820488,
    "lat": 35.593077715664705
  },
  {
    "name": "Maiden",
    "lng": -81.25247513078631,
    "lat": 35.60593671014795
  },
  {
    "name": "Maiden",
    "lng": -81.2193235739083,
    "lat": 35.61487869906074
  },
  {
    "name": "Maiden",
    "lng": -81.21811439738386,
    "lat": 35.583313806167304
  },
  {
    "name": "Maiden",
    "lng": -81.21722828386231,
    "lat": 35.55450425134024
  },
  {
    "name": "Maiden",
    "lng": -81.21590038943086,
    "lat": 35.60680362065473
  },
  {
    "name": "Maiden",
    "lng": -81.20528045321024,
    "lat": 35.623413153081565
  },
  {
    "name": "Maiden",
    "lng": -81.19338015660442,
    "lat": 35.5921788028338
  },
  {
    "name": "Maiden",
    "lng": -81.1887354586375,
    "lat": 35.57079673974897
  },
  {
    "name": "Maiden",
    "lng": -81.18610470350804,
    "lat": 35.58718831568246
  },
  {
    "name": "Maiden Rock",
    "lng": -92.30747706644564,
    "lat": 44.56608474365244
  },
  {
    "name": "Maine",
    "lng": -69.19766929108987,
    "lat": 45.301377379554104
  },
  {
    "name": "Maine",
    "lng": -68.1276770832411,
    "lat": 43.968706984662646
  },
  {
    "name": "Maine",
    "lng": -67.10398525346078,
    "lat": 44.51857268800976
  },
  {
    "name": "Maineville",
    "lng": -84.21768558302708,
    "lat": 39.3142930733092
  },
  {
    "name": "Maitland",
    "lng": -95.07793274264084,
    "lat": 40.2022202535694
  },
  {
    "name": "Maize",
    "lng": -97.46917417861827,
    "lat": 37.76052174210482
  },
  {
    "name": "Maize",
    "lng": -97.4616673094539,
    "lat": 37.7845015222982
  },
  {
    "name": "Maize",
    "lng": -97.44706465602714,
    "lat": 37.772220095522876
  },
  {
    "name": "Majestic Oaks",
    "lng": -95.56848018451146,
    "lat": 30.002057192208085
  },
  {
    "name": "Makanda",
    "lng": -89.23563216553619,
    "lat": 37.62101020328552
  },
  {
    "name": "Makoti",
    "lng": -101.8044313877055,
    "lat": 47.96016877086873
  },
  {
    "name": "Malabar",
    "lng": -80.58474865565653,
    "lat": 27.987573207510966
  },
  {
    "name": "Malabon",
    "lng": -123.2371124156043,
    "lat": 44.0894512026437
  },
  {
    "name": "Malad City",
    "lng": -112.2499971913243,
    "lat": 42.19027494729438
  },
  {
    "name": "Malakoff",
    "lng": -96.01576087209885,
    "lat": 32.17283987860415
  },
  {
    "name": "Malcolm",
    "lng": -96.86587868076171,
    "lat": 40.908987652459935
  },
  {
    "name": "Malcom",
    "lng": -92.5556720848769,
    "lat": 41.7070565622702
  },
  {
    "name": "Malden",
    "lng": -117.47321437230612,
    "lat": 47.22959177781431
  },
  {
    "name": "Malden",
    "lng": -89.9781715944986,
    "lat": 36.58471894411363
  },
  {
    "name": "Malden",
    "lng": -89.36980334608675,
    "lat": 41.42490087344882
  },
  {
    "name": "Malden",
    "lng": -71.05761789190838,
    "lat": 42.43046485083782
  },
  {
    "name": "Malinta",
    "lng": -84.03536006458141,
    "lat": 41.31959319915155
  },
  {
    "name": "Mallard",
    "lng": -94.68364162577069,
    "lat": 42.939550306152846
  },
  {
    "name": "Mallard",
    "lng": -94.67776308660889,
    "lat": 42.936466990766256
  },
  {
    "name": "Malmo",
    "lng": -96.72092259378492,
    "lat": 41.266454708402605
  },
  {
    "name": "Malone",
    "lng": -96.89474030818144,
    "lat": 31.917027867920385
  },
  {
    "name": "Malone",
    "lng": -85.16213916234493,
    "lat": 30.958955007585473
  },
  {
    "name": "Malone",
    "lng": -74.28890787720688,
    "lat": 44.848904013143894
  },
  {
    "name": "Maloy",
    "lng": -94.41198181783888,
    "lat": 40.672912786702554
  },
  {
    "name": "Malta",
    "lng": -113.36935168899373,
    "lat": 42.307450749636594
  },
  {
    "name": "Malta",
    "lng": -107.87092576059821,
    "lat": 48.35518917562483
  },
  {
    "name": "Malta",
    "lng": -88.86929720055537,
    "lat": 41.927121197419986
  },
  {
    "name": "Malta",
    "lng": -88.8624128165416,
    "lat": 41.93338388333496
  },
  {
    "name": "Malta",
    "lng": -81.86384524840611,
    "lat": 39.65078848834482
  },
  {
    "name": "Malta Bend",
    "lng": -93.36350001329568,
    "lat": 39.19398587603048
  },
  {
    "name": "Malvern",
    "lng": -95.58508754321882,
    "lat": 41.0073210131463
  },
  {
    "name": "Malvern",
    "lng": -92.84191961302628,
    "lat": 34.351754573015384
  },
  {
    "name": "Malvern",
    "lng": -92.82052095229768,
    "lat": 34.373649211383295
  },
  {
    "name": "Malvern",
    "lng": -85.5209933186552,
    "lat": 31.14279059631869
  },
  {
    "name": "Malvern",
    "lng": -81.18164164399231,
    "lat": 40.68962606446282
  },
  {
    "name": "Malvern",
    "lng": -75.51414005586045,
    "lat": 40.032890697060374
  },
  {
    "name": "Malverne",
    "lng": -73.67210146197593,
    "lat": 40.67454207223898
  },
  {
    "name": "Mamaroneck",
    "lng": -73.75544783833931,
    "lat": 40.91807502150811
  },
  {
    "name": "Mamaroneck",
    "lng": -73.74489912380258,
    "lat": 40.92815528590656
  },
  {
    "name": "Mamaroneck",
    "lng": -73.72627557247547,
    "lat": 40.9332550568893
  },
  {
    "name": "Mammoth",
    "lng": -110.64442230033966,
    "lat": 32.723022686804285
  },
  {
    "name": "Mammoth Lakes",
    "lng": -118.99146910615629,
    "lat": 37.62723179232089
  },
  {
    "name": "Mammoth Lakes",
    "lng": -118.8451010987416,
    "lat": 37.62682881854568
  },
  {
    "name": "Mammoth Spring",
    "lng": -91.54147435305896,
    "lat": 36.49235589580084
  },
  {
    "name": "Mamou",
    "lng": -92.41792690475658,
    "lat": 30.634913598577608
  },
  {
    "name": "Man",
    "lng": -81.8743655570924,
    "lat": 37.7422855116657
  },
  {
    "name": "Manassa",
    "lng": -105.93727491717745,
    "lat": 37.17384159593042
  },
  {
    "name": "Manassas",
    "lng": -82.01994739991314,
    "lat": 32.16035575091793
  },
  {
    "name": "Manassas",
    "lng": -77.48388250149385,
    "lat": 38.747863813866104
  },
  {
    "name": "Manassas Park",
    "lng": -77.44296173464079,
    "lat": 38.77116110999487
  },
  {
    "name": "Manassas Park",
    "lng": -77.43824748135536,
    "lat": 38.752722944550214
  },
  {
    "name": "Manawa",
    "lng": -88.92023782693911,
    "lat": 44.46112721017736
  },
  {
    "name": "Mancelona",
    "lng": -85.06122561398203,
    "lat": 44.902381026519436
  },
  {
    "name": "Manchester",
    "lng": -98.03509119699768,
    "lat": 36.994433021136736
  },
  {
    "name": "Manchester",
    "lng": -97.32134322152426,
    "lat": 39.092048067292374
  },
  {
    "name": "Manchester",
    "lng": -93.4507491763888,
    "lat": 43.72549040015871
  },
  {
    "name": "Manchester",
    "lng": -91.45571241758866,
    "lat": 42.485252106467186
  },
  {
    "name": "Manchester",
    "lng": -90.50633242872594,
    "lat": 38.58294770442868
  },
  {
    "name": "Manchester",
    "lng": -90.33029853071788,
    "lat": 39.5423133520723
  },
  {
    "name": "Manchester",
    "lng": -86.07753879772426,
    "lat": 35.463869548408724
  },
  {
    "name": "Manchester",
    "lng": -84.6330141251298,
    "lat": 32.856554922567874
  },
  {
    "name": "Manchester",
    "lng": -84.03467316273888,
    "lat": 42.14864080781759
  },
  {
    "name": "Manchester",
    "lng": -83.76893693661575,
    "lat": 37.147192221079536
  },
  {
    "name": "Manchester",
    "lng": -83.60905952199707,
    "lat": 38.68939919402761
  },
  {
    "name": "Manchester",
    "lng": -76.88803871220308,
    "lat": 39.658484512697754
  },
  {
    "name": "Manchester",
    "lng": -76.71956897505407,
    "lat": 40.06146295572294
  },
  {
    "name": "Manchester",
    "lng": -73.07095894067304,
    "lat": 43.1610030746996
  },
  {
    "name": "Manchester",
    "lng": -72.52420477392874,
    "lat": 41.775282552442256
  },
  {
    "name": "Manchester",
    "lng": -71.44486976324981,
    "lat": 42.98480369269316
  },
  {
    "name": "Manchester",
    "lng": -69.86076830421482,
    "lat": 44.331799963010546
  },
  {
    "name": "Manchester Village",
    "lng": -77.23121914945449,
    "lat": 42.968368225627906
  },
  {
    "name": "Mancos",
    "lng": -108.29356452835175,
    "lat": 37.346554928725524
  },
  {
    "name": "Mandan",
    "lng": -100.8874424968031,
    "lat": 46.829123727826165
  },
  {
    "name": "Manderson",
    "lng": -107.96402202967569,
    "lat": 44.26990669373474
  },
  {
    "name": "Mandeville",
    "lng": -90.09087841164026,
    "lat": 30.375082912105
  },
  {
    "name": "Mandeville",
    "lng": -90.08998862091907,
    "lat": 30.382186867755415
  },
  {
    "name": "Mandeville",
    "lng": -90.08993720937372,
    "lat": 30.383998610782232
  },
  {
    "name": "Mandeville",
    "lng": -90.06658631187759,
    "lat": 30.37048153311961
  },
  {
    "name": "Mandeville",
    "lng": -90.03854068053215,
    "lat": 30.367402631123934
  },
  {
    "name": "Mangham",
    "lng": -91.77947484098236,
    "lat": 32.3090260266702
  },
  {
    "name": "Mangonia Park",
    "lng": -80.07612100136093,
    "lat": 26.758579980697128
  },
  {
    "name": "Mangum",
    "lng": -99.50394876054989,
    "lat": 34.87838696376273
  },
  {
    "name": "Manhattan",
    "lng": -111.33096617752797,
    "lat": 45.85834211819713
  },
  {
    "name": "Manhattan",
    "lng": -111.33095062967948,
    "lat": 45.85827472034191
  },
  {
    "name": "Manhattan",
    "lng": -96.67542972269467,
    "lat": 39.13891738840505
  },
  {
    "name": "Manhattan",
    "lng": -96.65236018490691,
    "lat": 39.151959593347506
  },
  {
    "name": "Manhattan",
    "lng": -96.61342030338918,
    "lat": 39.15861089001539
  },
  {
    "name": "Manhattan",
    "lng": -96.59949385753548,
    "lat": 39.19183509768133
  },
  {
    "name": "Manhattan",
    "lng": -96.59187893443199,
    "lat": 39.14335846581065
  },
  {
    "name": "Manhattan",
    "lng": -96.58215032225792,
    "lat": 39.223271020088156
  },
  {
    "name": "Manhattan",
    "lng": -96.57489260074118,
    "lat": 39.222403622690386
  },
  {
    "name": "Manhattan",
    "lng": -96.52417799417053,
    "lat": 39.194768892202056
  },
  {
    "name": "Manhattan",
    "lng": -88.0092350233462,
    "lat": 41.441707815149734
  },
  {
    "name": "Manhattan",
    "lng": -87.98388817502594,
    "lat": 41.43078379000495
  },
  {
    "name": "Manhattan",
    "lng": -87.95984870619542,
    "lat": 41.39397015526546
  },
  {
    "name": "Manhattan",
    "lng": -87.94931281487256,
    "lat": 41.45061440356784
  },
  {
    "name": "Manhattan Beach",
    "lng": -118.42447961514759,
    "lat": 33.88551228459097
  },
  {
    "name": "Manhattan Beach",
    "lng": -94.13966315164978,
    "lat": 46.737100396782445
  },
  {
    "name": "Manhattan Beach",
    "lng": -94.13281273795114,
    "lat": 46.71850318940689
  },
  {
    "name": "Manila",
    "lng": -109.72103780879809,
    "lat": 40.99216450960966
  },
  {
    "name": "Manila",
    "lng": -90.16507301021088,
    "lat": 35.884803602178756
  },
  {
    "name": "Manilla",
    "lng": -95.23446019843,
    "lat": 41.89264025779239
  },
  {
    "name": "Manistee",
    "lng": -86.32442828031553,
    "lat": 44.24400330957611
  },
  {
    "name": "Manistique",
    "lng": -86.25062973711113,
    "lat": 45.95895023481274
  },
  {
    "name": "Manito",
    "lng": -89.78093789747055,
    "lat": 40.421335157489125
  },
  {
    "name": "Manitou",
    "lng": -98.98091074131534,
    "lat": 34.50711280636094
  },
  {
    "name": "Manitou Springs",
    "lng": -104.91298595340484,
    "lat": 38.857431692829635
  },
  {
    "name": "Manitowoc",
    "lng": -87.71719658206652,
    "lat": 44.0607367001394
  },
  {
    "name": "Manitowoc",
    "lng": -87.68066938742648,
    "lat": 44.09925846581962
  },
  {
    "name": "Manitowoc",
    "lng": -87.66663454783054,
    "lat": 44.05636358547714
  },
  {
    "name": "Manitowoc",
    "lng": -87.65935386181415,
    "lat": 44.05058199205429
  },
  {
    "name": "Manitowoc",
    "lng": -87.62467391843849,
    "lat": 44.155064050108486
  },
  {
    "name": "Mankato",
    "lng": -98.20820985542102,
    "lat": 39.78740208303368
  },
  {
    "name": "Mankato",
    "lng": -94.02370573873169,
    "lat": 44.19794145372819
  },
  {
    "name": "Mankato",
    "lng": -93.98261336196653,
    "lat": 44.16696127965927
  },
  {
    "name": "Mankato",
    "lng": -93.94567183807783,
    "lat": 44.19832443355395
  },
  {
    "name": "Mankato",
    "lng": -93.93206934042762,
    "lat": 44.23406410094808
  },
  {
    "name": "Mankato",
    "lng": -93.92830353434782,
    "lat": 44.19910785119374
  },
  {
    "name": "Mankato",
    "lng": -93.91990582252282,
    "lat": 44.22110481245093
  },
  {
    "name": "Mankato",
    "lng": -93.91550653680314,
    "lat": 44.15632345489611
  },
  {
    "name": "Manley",
    "lng": -96.1659833652217,
    "lat": 40.9194401866626
  },
  {
    "name": "Manlius",
    "lng": -89.66852097906018,
    "lat": 41.455302155514715
  },
  {
    "name": "Manlius",
    "lng": -75.98185648511891,
    "lat": 43.001533143056385
  },
  {
    "name": "Manly",
    "lng": -93.20073783541467,
    "lat": 43.28830051695533
  },
  {
    "name": "Mannford",
    "lng": -96.41988782311988,
    "lat": 36.10985371917319
  },
  {
    "name": "Mannford",
    "lng": -96.39390535064835,
    "lat": 36.07607499923547
  },
  {
    "name": "Mannford",
    "lng": -96.36772291782064,
    "lat": 36.13444290692796
  },
  {
    "name": "Mannford",
    "lng": -96.33225597348881,
    "lat": 36.13048197618661
  },
  {
    "name": "Manning",
    "lng": -95.06526446889065,
    "lat": 41.90938992367826
  },
  {
    "name": "Manning",
    "lng": -80.21631714997194,
    "lat": 33.692671176650705
  },
  {
    "name": "Mannington",
    "lng": -80.34078458781003,
    "lat": 39.52730043233245
  },
  {
    "name": "Mannsville",
    "lng": -96.87985292019268,
    "lat": 34.186734592097984
  },
  {
    "name": "Mannsville",
    "lng": -76.06509229175073,
    "lat": 43.71569159018009
  },
  {
    "name": "Manokotak",
    "lng": -159.00194614954512,
    "lat": 58.995737905542704
  },
  {
    "name": "Manor",
    "lng": -97.53286900944894,
    "lat": 30.36354730567743
  },
  {
    "name": "Manor",
    "lng": -97.52412404238001,
    "lat": 30.3559829009524
  },
  {
    "name": "Manor",
    "lng": -97.46912282431015,
    "lat": 30.369592472301857
  },
  {
    "name": "Manor Creek",
    "lng": -85.5872845943583,
    "lat": 38.29825870126985
  },
  {
    "name": "Manorhaven",
    "lng": -73.7139584582247,
    "lat": 40.83534550080501
  },
  {
    "name": "Mansfield",
    "lng": -119.63836409509487,
    "lat": 47.81178848737937
  },
  {
    "name": "Mansfield",
    "lng": -97.12099204313469,
    "lat": 32.56925207030742
  },
  {
    "name": "Mansfield",
    "lng": -94.23732615518519,
    "lat": 35.06349609736377
  },
  {
    "name": "Mansfield",
    "lng": -93.70041401484707,
    "lat": 32.03555848605224
  },
  {
    "name": "Mansfield",
    "lng": -92.58013203719581,
    "lat": 37.11006318029024
  },
  {
    "name": "Mansfield",
    "lng": -88.5081824268986,
    "lat": 40.21233980937604
  },
  {
    "name": "Mansfield",
    "lng": -83.73535822523708,
    "lat": 33.517812336715636
  },
  {
    "name": "Mansfield",
    "lng": -83.71518951594733,
    "lat": 33.52007576625477
  },
  {
    "name": "Mansfield",
    "lng": -82.52744784826605,
    "lat": 40.76559677109948
  },
  {
    "name": "Mansfield",
    "lng": -77.07854439295896,
    "lat": 41.80607273466081
  },
  {
    "name": "Mansfield",
    "lng": -72.22869706733714,
    "lat": 41.78922014769617
  },
  {
    "name": "Manson",
    "lng": -94.53999938618558,
    "lat": 42.52866776432831
  },
  {
    "name": "Mansura",
    "lng": -92.05283544669506,
    "lat": 31.067338570525784
  },
  {
    "name": "Mantachie",
    "lng": -88.49610492798683,
    "lat": 34.325103994173546
  },
  {
    "name": "Mantador",
    "lng": -96.97783398344474,
    "lat": 46.165476093307376
  },
  {
    "name": "Manteca",
    "lng": -121.22603661142469,
    "lat": 37.79321879141365
  },
  {
    "name": "Mantee",
    "lng": -89.06114292717376,
    "lat": 33.73120837261919
  },
  {
    "name": "Manteno",
    "lng": -87.84566243900731,
    "lat": 41.2471641915782
  },
  {
    "name": "Manteo",
    "lng": -75.6723168731091,
    "lat": 35.90673916880625
  },
  {
    "name": "Manteo",
    "lng": -75.66684641314679,
    "lat": 35.89657176113678
  },
  {
    "name": "Manteo",
    "lng": -75.66574178143044,
    "lat": 35.91095580541491
  },
  {
    "name": "Manteo",
    "lng": -75.64974459180516,
    "lat": 35.89786748901553
  },
  {
    "name": "Manter",
    "lng": -101.88332295056492,
    "lat": 37.524478907120184
  },
  {
    "name": "Manti",
    "lng": -111.63504097562556,
    "lat": 39.26686444983575
  },
  {
    "name": "Manton",
    "lng": -121.85398720899454,
    "lat": 40.42019342244164
  },
  {
    "name": "Manton",
    "lng": -85.40103491609601,
    "lat": 44.411430732449226
  },
  {
    "name": "Mantorville",
    "lng": -92.75369632967559,
    "lat": 44.0656062871133
  },
  {
    "name": "Mantua",
    "lng": -111.9343066454676,
    "lat": 41.50069885869649
  },
  {
    "name": "Mantua",
    "lng": -81.22218692008134,
    "lat": 41.281971993835754
  },
  {
    "name": "Manvel",
    "lng": -97.17794785768076,
    "lat": 48.072703563408304
  },
  {
    "name": "Manvel",
    "lng": -95.41512612629423,
    "lat": 29.50138655195243
  },
  {
    "name": "Manvel",
    "lng": -95.41234760879503,
    "lat": 29.489759892184136
  },
  {
    "name": "Manvel",
    "lng": -95.410408562023,
    "lat": 29.49361047077584
  },
  {
    "name": "Manvel",
    "lng": -95.36571134163508,
    "lat": 29.479258669648253
  },
  {
    "name": "Manville",
    "lng": -104.61745073215796,
    "lat": 42.77944632425351
  },
  {
    "name": "Many",
    "lng": -93.47792354980288,
    "lat": 31.56650539532738
  },
  {
    "name": "Many",
    "lng": -93.46888356316761,
    "lat": 31.548472242426183
  },
  {
    "name": "Manzanola",
    "lng": -103.8663992910665,
    "lat": 38.1089719279015
  },
  {
    "name": "Maple Bluff",
    "lng": -89.37211230500269,
    "lat": 43.11423920579669
  },
  {
    "name": "Maple Bluff",
    "lng": -89.36838359765056,
    "lat": 43.099479421586764
  },
  {
    "name": "Maple Grove",
    "lng": -93.46259653509874,
    "lat": 45.10896616074984
  },
  {
    "name": "Maple Heights",
    "lng": -81.56249606484032,
    "lat": 41.40945595691523
  },
  {
    "name": "Maple Hill",
    "lng": -96.02809906339785,
    "lat": 39.085317974645065
  },
  {
    "name": "Maple Lake",
    "lng": -94.0056825641046,
    "lat": 45.23335361992841
  },
  {
    "name": "Maple Park",
    "lng": -88.61877275943093,
    "lat": 41.91881693671081
  },
  {
    "name": "Maple Park",
    "lng": -88.60492792411635,
    "lat": 41.9074907234712
  },
  {
    "name": "Maple Park",
    "lng": -88.60172330525566,
    "lat": 41.90812405074603
  },
  {
    "name": "Maple Plain",
    "lng": -93.66175520125749,
    "lat": 45.00882980275785
  },
  {
    "name": "Maple Rapids",
    "lng": -84.68973630916555,
    "lat": 43.10769834251033
  },
  {
    "name": "Maple Valley",
    "lng": -122.03796899501505,
    "lat": 47.366506820549176
  },
  {
    "name": "Mapleton",
    "lng": -111.57422405901342,
    "lat": 40.118852416827565
  },
  {
    "name": "Mapleton",
    "lng": -97.05304133963007,
    "lat": 46.89126155144782
  },
  {
    "name": "Mapleton",
    "lng": -95.79097850364754,
    "lat": 42.16727450833741
  },
  {
    "name": "Mapleton",
    "lng": -94.88331100925888,
    "lat": 38.015564159710706
  },
  {
    "name": "Mapleton",
    "lng": -93.95439882381896,
    "lat": 43.926356824992666
  },
  {
    "name": "Mapleton",
    "lng": -89.72946529856578,
    "lat": 40.56993170736268
  },
  {
    "name": "Mapleton",
    "lng": -89.72421615635623,
    "lat": 40.57321847079
  },
  {
    "name": "Mapleton",
    "lng": -68.11359086228765,
    "lat": 46.70525718255636
  },
  {
    "name": "Mapleview",
    "lng": -92.97408391897235,
    "lat": 43.68995766339649
  },
  {
    "name": "Maplewood",
    "lng": -93.02476150526562,
    "lat": 44.98410690671556
  },
  {
    "name": "Maplewood",
    "lng": -90.32396563141715,
    "lat": 38.61211755893206
  },
  {
    "name": "Maquoketa",
    "lng": -90.66495999996559,
    "lat": 42.059000788574956
  },
  {
    "name": "Maquon",
    "lng": -90.16317584313992,
    "lat": 40.798029135359755
  },
  {
    "name": "Maramec",
    "lng": -96.68049583517887,
    "lat": 36.24197036870214
  },
  {
    "name": "Marana",
    "lng": -111.15407594896135,
    "lat": 32.436066251390976
  },
  {
    "name": "Marathon",
    "lng": -94.98288488281092,
    "lat": 42.860189743063906
  },
  {
    "name": "Marathon",
    "lng": -81.1150091583198,
    "lat": 24.708951304246543
  },
  {
    "name": "Marathon",
    "lng": -81.11232966692928,
    "lat": 24.712398721212775
  },
  {
    "name": "Marathon",
    "lng": -81.10926207974538,
    "lat": 24.712703441317462
  },
  {
    "name": "Marathon",
    "lng": -81.07881870715974,
    "lat": 24.710957467338382
  },
  {
    "name": "Marathon",
    "lng": -81.07587176310845,
    "lat": 24.72653118322252
  },
  {
    "name": "Marathon",
    "lng": -81.0503105383436,
    "lat": 24.736414327642155
  },
  {
    "name": "Marathon",
    "lng": -81.03617746922464,
    "lat": 24.740593983768928
  },
  {
    "name": "Marathon",
    "lng": -81.00303064794971,
    "lat": 24.755110157963813
  },
  {
    "name": "Marathon",
    "lng": -80.98251607766022,
    "lat": 24.74670852288481
  },
  {
    "name": "Marathon",
    "lng": -80.93009099124097,
    "lat": 24.768102521402
  },
  {
    "name": "Marathon",
    "lng": -80.92816641633524,
    "lat": 24.765775863567992
  },
  {
    "name": "Marathon",
    "lng": -76.03506291357468,
    "lat": 42.44396657012349
  },
  {
    "name": "Marathon City",
    "lng": -89.84357145120705,
    "lat": 44.93804260655822
  },
  {
    "name": "Marble",
    "lng": -107.19046637198743,
    "lat": 39.071816781893176
  },
  {
    "name": "Marble",
    "lng": -93.29401662970321,
    "lat": 47.3279775768415
  },
  {
    "name": "Marble City",
    "lng": -94.81862349976466,
    "lat": 35.5823700763304
  },
  {
    "name": "Marble Cliff",
    "lng": -83.06129864280672,
    "lat": 39.98549832008076
  },
  {
    "name": "Marble Falls",
    "lng": -98.27581569242808,
    "lat": 30.5667570513319
  },
  {
    "name": "Marble Hill",
    "lng": -89.98084269299588,
    "lat": 37.30304609732341
  },
  {
    "name": "Marble Rock",
    "lng": -92.86795894990297,
    "lat": 42.96476440942856
  },
  {
    "name": "Marblehead",
    "lng": -82.72630202710108,
    "lat": 41.52346347104242
  },
  {
    "name": "Marbleton",
    "lng": -110.10066667310497,
    "lat": 42.556938758582
  },
  {
    "name": "Marbleton",
    "lng": -110.09630914780418,
    "lat": 42.56688715101726
  },
  {
    "name": "Marceline",
    "lng": -92.94727251505266,
    "lat": 39.71664864651136
  },
  {
    "name": "Marcellus",
    "lng": -85.8132089015409,
    "lat": 42.02586874604364
  },
  {
    "name": "Marcellus",
    "lng": -85.80196975603829,
    "lat": 42.033530516772636
  },
  {
    "name": "Marcellus",
    "lng": -76.34019679536489,
    "lat": 42.98376109484864
  },
  {
    "name": "Marco Island",
    "lng": -81.7011534767379,
    "lat": 25.933283991576488
  },
  {
    "name": "Marcus",
    "lng": -118.05961644463774,
    "lat": 48.664077406866554
  },
  {
    "name": "Marcus",
    "lng": -95.80683282476367,
    "lat": 42.82239077786175
  },
  {
    "name": "Mardela Springs",
    "lng": -75.75646604331448,
    "lat": 38.45892980457579
  },
  {
    "name": "Marengo",
    "lng": -92.06758276050482,
    "lat": 41.79614554515988
  },
  {
    "name": "Marengo",
    "lng": -88.6160354431924,
    "lat": 42.17350934705263
  },
  {
    "name": "Marengo",
    "lng": -88.6153197032707,
    "lat": 42.23266978784588
  },
  {
    "name": "Marengo",
    "lng": -86.34430438267331,
    "lat": 38.37124063898088
  },
  {
    "name": "Marengo",
    "lng": -82.81099367026926,
    "lat": 40.40081943126197
  },
  {
    "name": "Marfa",
    "lng": -104.02546724491721,
    "lat": 30.310787822374053
  },
  {
    "name": "Margaret",
    "lng": -86.46704648754152,
    "lat": 33.6736354590996
  },
  {
    "name": "Margaretville",
    "lng": -74.65028201874435,
    "lat": 42.145801256593884
  },
  {
    "name": "Margate City",
    "lng": -74.50450833002604,
    "lat": 39.32692066256383
  },
  {
    "name": "Marianna",
    "lng": -90.76762430285154,
    "lat": 34.774150784956845
  },
  {
    "name": "Marianna",
    "lng": -85.26171550943747,
    "lat": 30.79287488249
  },
  {
    "name": "Marianna",
    "lng": -85.24683088781565,
    "lat": 30.792717184220322
  },
  {
    "name": "Marianna",
    "lng": -85.24640634386817,
    "lat": 30.793262123345954
  },
  {
    "name": "Marianna",
    "lng": -85.2461607665743,
    "lat": 30.79142838518045
  },
  {
    "name": "Marianna",
    "lng": -85.24597081840602,
    "lat": 30.791822554495045
  },
  {
    "name": "Marianna",
    "lng": -85.2426301851503,
    "lat": 30.780099053780646
  },
  {
    "name": "Marianna",
    "lng": -85.20690703130856,
    "lat": 30.77056743095113
  },
  {
    "name": "Marianna",
    "lng": -85.18288318813363,
    "lat": 30.832083912353518
  },
  {
    "name": "Mariaville",
    "lng": -68.38908505367007,
    "lat": 44.74540821464248
  },
  {
    "name": "Maribel",
    "lng": -87.8022394858935,
    "lat": 44.27742967689615
  },
  {
    "name": "Maricopa",
    "lng": -119.40529113312427,
    "lat": 35.051704174468895
  },
  {
    "name": "Maricopa",
    "lng": -112.01650042586897,
    "lat": 33.04475301453438
  },
  {
    "name": "Marie",
    "lng": -90.08296303714395,
    "lat": 35.612933014824165
  },
  {
    "name": "Mariemont",
    "lng": -84.37857519675089,
    "lat": 39.142759238292555
  },
  {
    "name": "Marietta",
    "lng": -97.12416764785436,
    "lat": 33.935578660164595
  },
  {
    "name": "Marietta",
    "lng": -96.4190804793678,
    "lat": 45.010380594140194
  },
  {
    "name": "Marietta",
    "lng": -94.54253102312144,
    "lat": 33.17354844516958
  },
  {
    "name": "Marietta",
    "lng": -90.39301607899195,
    "lat": 40.49944985698805
  },
  {
    "name": "Marietta",
    "lng": -88.47257724805692,
    "lat": 34.499972410617985
  },
  {
    "name": "Marietta",
    "lng": -84.61136396712708,
    "lat": 33.985211348537995
  },
  {
    "name": "Marietta",
    "lng": -84.60906673639872,
    "lat": 33.97432903660844
  },
  {
    "name": "Marietta",
    "lng": -84.60167081504761,
    "lat": 33.94976885478398
  },
  {
    "name": "Marietta",
    "lng": -84.60147615760526,
    "lat": 33.97647120020993
  },
  {
    "name": "Marietta",
    "lng": -84.60081810810371,
    "lat": 33.96517493825701
  },
  {
    "name": "Marietta",
    "lng": -84.59774796832758,
    "lat": 33.96208970111813
  },
  {
    "name": "Marietta",
    "lng": -84.5957731175433,
    "lat": 33.96586556403576
  },
  {
    "name": "Marietta",
    "lng": -84.59576053317627,
    "lat": 33.982006916724835
  },
  {
    "name": "Marietta",
    "lng": -84.59093638214853,
    "lat": 33.974742868631715
  },
  {
    "name": "Marietta",
    "lng": -84.58347474782309,
    "lat": 33.91940158529557
  },
  {
    "name": "Marietta",
    "lng": -84.55675122222338,
    "lat": 33.98233311123404
  },
  {
    "name": "Marietta",
    "lng": -84.55611532266946,
    "lat": 33.92804140947749
  },
  {
    "name": "Marietta",
    "lng": -84.5537058170601,
    "lat": 33.985804324546244
  },
  {
    "name": "Marietta",
    "lng": -84.53840794333527,
    "lat": 33.952112576905165
  },
  {
    "name": "Marietta",
    "lng": -84.53192929584863,
    "lat": 33.99368975279242
  },
  {
    "name": "Marietta",
    "lng": -84.5310527241912,
    "lat": 33.99353087535251
  },
  {
    "name": "Marietta",
    "lng": -84.52998191008565,
    "lat": 33.99120508411161
  },
  {
    "name": "Marietta",
    "lng": -84.52929230008692,
    "lat": 33.99184135609314
  },
  {
    "name": "Marietta",
    "lng": -84.50339184108455,
    "lat": 33.92368941947968
  },
  {
    "name": "Marietta",
    "lng": -84.50166273767513,
    "lat": 33.92380207954021
  },
  {
    "name": "Marietta",
    "lng": -84.49804646673132,
    "lat": 33.941444694237106
  },
  {
    "name": "Marietta",
    "lng": -84.47910497857134,
    "lat": 33.901978187871364
  },
  {
    "name": "Marietta",
    "lng": -84.47797787808993,
    "lat": 33.901772664637654
  },
  {
    "name": "Marietta",
    "lng": -84.47425582009612,
    "lat": 33.92961785369542
  },
  {
    "name": "Marietta",
    "lng": -81.44631685381785,
    "lat": 39.4239524480266
  },
  {
    "name": "Marietta",
    "lng": -79.12485785931774,
    "lat": 34.36819896417835
  },
  {
    "name": "Marina",
    "lng": -121.7915662201383,
    "lat": 36.68320821032243
  },
  {
    "name": "Marine",
    "lng": -89.77886067735099,
    "lat": 38.78677691388735
  },
  {
    "name": "Marine City",
    "lng": -82.50228503436858,
    "lat": 42.71461841533088
  },
  {
    "name": "Marine Corps Base Camp Lejeune",
    "lng": -77.53824187995171,
    "lat": 34.601677501550185
  },
  {
    "name": "Marine Corps Base Camp Lejeune",
    "lng": -77.35423522353695,
    "lat": 34.64682243823536
  },
  {
    "name": "Marine Corps Base Camp Pendleton",
    "lng": -117.42362257285792,
    "lat": 33.362721369713654
  },
  {
    "name": "Marine on St. Croix",
    "lng": -92.77837026046357,
    "lat": 45.19820843716128
  },
  {
    "name": "Marinette",
    "lng": -87.66136886535129,
    "lat": 45.082582205563185
  },
  {
    "name": "Marinette",
    "lng": -87.65864260501938,
    "lat": 45.08588661345479
  },
  {
    "name": "Marinette",
    "lng": -87.62667817005803,
    "lat": 45.086490969876486
  },
  {
    "name": "Maringouin",
    "lng": -91.5186169797831,
    "lat": 30.490745621735734
  },
  {
    "name": "Marion",
    "lng": -98.33750935916912,
    "lat": 46.60910622449629
  },
  {
    "name": "Marion",
    "lng": -98.14168898876171,
    "lat": 29.569751702996317
  },
  {
    "name": "Marion",
    "lng": -97.26054958227975,
    "lat": 43.42357129244231
  },
  {
    "name": "Marion",
    "lng": -97.0109389547416,
    "lat": 38.356443128245566
  },
  {
    "name": "Marion",
    "lng": -96.99202755322105,
    "lat": 38.3380417329295
  },
  {
    "name": "Marion",
    "lng": -92.24049177931424,
    "lat": 32.89977561740724
  },
  {
    "name": "Marion",
    "lng": -91.58524086812362,
    "lat": 42.04482962880403
  },
  {
    "name": "Marion",
    "lng": -91.5473197644922,
    "lat": 42.07946480197505
  },
  {
    "name": "Marion",
    "lng": -90.2569601938807,
    "lat": 35.180350645180454
  },
  {
    "name": "Marion",
    "lng": -90.18366105311502,
    "lat": 35.213956097924545
  },
  {
    "name": "Marion",
    "lng": -88.97046275798785,
    "lat": 37.73599845462768
  },
  {
    "name": "Marion",
    "lng": -88.94517808010568,
    "lat": 37.74745814520136
  },
  {
    "name": "Marion",
    "lng": -88.92967700185876,
    "lat": 37.71472125339652
  },
  {
    "name": "Marion",
    "lng": -88.92753116591287,
    "lat": 37.7156972477254
  },
  {
    "name": "Marion",
    "lng": -88.90517891003702,
    "lat": 37.7057222872261
  },
  {
    "name": "Marion",
    "lng": -88.88881179623795,
    "lat": 44.67413829276053
  },
  {
    "name": "Marion",
    "lng": -88.88355069431363,
    "lat": 37.74845474984957
  },
  {
    "name": "Marion",
    "lng": -88.8809403956857,
    "lat": 37.74900406085252
  },
  {
    "name": "Marion",
    "lng": -88.87646060684027,
    "lat": 37.70806729844245
  },
  {
    "name": "Marion",
    "lng": -88.86704022693336,
    "lat": 37.696626765708906
  },
  {
    "name": "Marion",
    "lng": -88.65017075022969,
    "lat": 32.42718171802871
  },
  {
    "name": "Marion",
    "lng": -88.07935215228441,
    "lat": 37.33228046426814
  },
  {
    "name": "Marion",
    "lng": -87.31716449482506,
    "lat": 32.6318216064938
  },
  {
    "name": "Marion",
    "lng": -85.72694214787217,
    "lat": 40.563869506002796
  },
  {
    "name": "Marion",
    "lng": -85.68003759266524,
    "lat": 40.48887959379694
  },
  {
    "name": "Marion",
    "lng": -85.65861279849574,
    "lat": 40.55275106532648
  },
  {
    "name": "Marion",
    "lng": -85.65170555295455,
    "lat": 40.514644050451146
  },
  {
    "name": "Marion",
    "lng": -85.54855610150501,
    "lat": 40.554448336645024
  },
  {
    "name": "Marion",
    "lng": -85.54056423933534,
    "lat": 40.55093963814774
  },
  {
    "name": "Marion",
    "lng": -85.14311808958617,
    "lat": 44.10057403499025
  },
  {
    "name": "Marion",
    "lng": -83.1235062982256,
    "lat": 40.59320066486115
  },
  {
    "name": "Marion",
    "lng": -82.04418006447408,
    "lat": 35.68581859878655
  },
  {
    "name": "Marion",
    "lng": -82.03036490342043,
    "lat": 35.70725144749298
  },
  {
    "name": "Marion",
    "lng": -82.0083519775091,
    "lat": 35.68289796552398
  },
  {
    "name": "Marion",
    "lng": -81.96824243986693,
    "lat": 35.65572822986839
  },
  {
    "name": "Marion",
    "lng": -81.96425502570929,
    "lat": 35.658122927297455
  },
  {
    "name": "Marion",
    "lng": -81.9604901904247,
    "lat": 35.65392399405832
  },
  {
    "name": "Marion",
    "lng": -81.95669482731789,
    "lat": 35.652178526907505
  },
  {
    "name": "Marion",
    "lng": -81.51551913937855,
    "lat": 36.83789641969044
  },
  {
    "name": "Marion",
    "lng": -79.397767623555,
    "lat": 34.17850463421191
  },
  {
    "name": "Marion",
    "lng": -79.37301159164953,
    "lat": 34.18148993425558
  },
  {
    "name": "Marion",
    "lng": -79.34441900642427,
    "lat": 34.18376378479952
  },
  {
    "name": "Marion Center",
    "lng": -79.04721043856485,
    "lat": 40.77051882128441
  },
  {
    "name": "Marionville",
    "lng": -93.63717191918263,
    "lat": 37.000066643944876
  },
  {
    "name": "Marissa",
    "lng": -89.75831951475617,
    "lat": 38.25346120298949
  },
  {
    "name": "Mark",
    "lng": -89.25502820983333,
    "lat": 41.26235714075601
  },
  {
    "name": "Marked Tree",
    "lng": -90.42515786666274,
    "lat": 35.52505817531776
  },
  {
    "name": "Markesan",
    "lng": -88.99076839177982,
    "lat": 43.70971700256163
  },
  {
    "name": "Markesan",
    "lng": -88.9823471934241,
    "lat": 43.73004153454809
  },
  {
    "name": "Markham",
    "lng": -87.69061916324718,
    "lat": 41.60007792018567
  },
  {
    "name": "Markle",
    "lng": -85.33922277973427,
    "lat": 40.82756156298137
  },
  {
    "name": "Marklesburg",
    "lng": -78.16970289925146,
    "lat": 40.382077545546565
  },
  {
    "name": "Markleville",
    "lng": -85.61731789683498,
    "lat": 39.9766399627244
  },
  {
    "name": "Marks",
    "lng": -90.27223465317847,
    "lat": 34.253479927727646
  },
  {
    "name": "Marksville",
    "lng": -92.06524913278355,
    "lat": 31.12467273187658
  },
  {
    "name": "Marland",
    "lng": -97.15290898062047,
    "lat": 36.561118718196624
  },
  {
    "name": "Marlborough",
    "lng": -90.33955084499081,
    "lat": 38.5681811981593
  },
  {
    "name": "Marlborough",
    "lng": -72.45455992231494,
    "lat": 41.63293857638984
  },
  {
    "name": "Marlborough",
    "lng": -71.54681851950608,
    "lat": 42.349399160971174
  },
  {
    "name": "Marlette",
    "lng": -83.0810260166115,
    "lat": 43.3268925732834
  },
  {
    "name": "Marlin",
    "lng": -118.99060548516242,
    "lat": 47.403846716478505
  },
  {
    "name": "Marlin",
    "lng": -118.98862829856643,
    "lat": 47.41053224324492
  },
  {
    "name": "Marlin",
    "lng": -96.89334086805464,
    "lat": 31.308521490176876
  },
  {
    "name": "Marlinton",
    "lng": -80.09106341840196,
    "lat": 38.22437578302407
  },
  {
    "name": "Marlow",
    "lng": -97.97053846756509,
    "lat": 34.66147168113876
  },
  {
    "name": "Marlow",
    "lng": -97.9578615608205,
    "lat": 34.629325157219164
  },
  {
    "name": "Marlow",
    "lng": -97.94022925785734,
    "lat": 34.60933758630187
  },
  {
    "name": "Marmaduke",
    "lng": -90.38884901030238,
    "lat": 36.19830692513706
  },
  {
    "name": "Marmaduke",
    "lng": -90.38625676609574,
    "lat": 36.19016109767918
  },
  {
    "name": "Marmarth",
    "lng": -103.93317881858134,
    "lat": 46.296018586406696
  },
  {
    "name": "Marmet",
    "lng": -81.5711420254092,
    "lat": 38.245712762261455
  },
  {
    "name": "Marne",
    "lng": -95.11086852699684,
    "lat": 41.448904875465495
  },
  {
    "name": "Maroa",
    "lng": -88.95617444868947,
    "lat": 40.03644899608297
  },
  {
    "name": "Marquand",
    "lng": -90.16705773722984,
    "lat": 37.428684753966664
  },
  {
    "name": "Marquette",
    "lng": -98.00975068364384,
    "lat": 41.00633489093304
  },
  {
    "name": "Marquette",
    "lng": -97.8337987367456,
    "lat": 38.554875324358036
  },
  {
    "name": "Marquette",
    "lng": -91.19226144709066,
    "lat": 43.044199990249254
  },
  {
    "name": "Marquette",
    "lng": -87.40834736197424,
    "lat": 46.54386490940136
  },
  {
    "name": "Marquette",
    "lng": -87.40355972093437,
    "lat": 46.5935794038119
  },
  {
    "name": "Marquette",
    "lng": -87.4014475110995,
    "lat": 46.59463464389339
  },
  {
    "name": "Marquette",
    "lng": -87.40037743744293,
    "lat": 46.59040270032704
  },
  {
    "name": "Marquette",
    "lng": -87.39977949691453,
    "lat": 46.590663376514215
  },
  {
    "name": "Marquette",
    "lng": -87.39882811569919,
    "lat": 46.59177382257655
  },
  {
    "name": "Marquette",
    "lng": -87.3987142227501,
    "lat": 46.59552166887048
  },
  {
    "name": "Marquette",
    "lng": -87.36501632260729,
    "lat": 46.59264373331091
  },
  {
    "name": "Marquette",
    "lng": -87.36494775756796,
    "lat": 46.592282112995235
  },
  {
    "name": "Marquette",
    "lng": -87.36474959494859,
    "lat": 46.58986393575825
  },
  {
    "name": "Marquette",
    "lng": -87.36472998506098,
    "lat": 46.58958438116462
  },
  {
    "name": "Marquette",
    "lng": -87.36469214034733,
    "lat": 46.590289265315825
  },
  {
    "name": "Marquette",
    "lng": -87.36468492972487,
    "lat": 46.59005905935908
  },
  {
    "name": "Marquette",
    "lng": -87.36449612150315,
    "lat": 46.59189916295593
  },
  {
    "name": "Marquette",
    "lng": -87.36402372965597,
    "lat": 46.59045595572508
  },
  {
    "name": "Marquette",
    "lng": -87.36398845005782,
    "lat": 46.59076787030104
  },
  {
    "name": "Marquette Heights",
    "lng": -89.60476330078428,
    "lat": 40.6182660971464
  },
  {
    "name": "Marquez",
    "lng": -96.25743437030826,
    "lat": 31.236141457985756
  },
  {
    "name": "Marriott Resort & Convention Center",
    "lng": -81.50877636215853,
    "lat": 28.361221401280677
  },
  {
    "name": "Marriott-Slaterville",
    "lng": -112.03677753356435,
    "lat": 41.262432428379284
  },
  {
    "name": "Mars",
    "lng": -80.01393887169237,
    "lat": 40.696827066327664
  },
  {
    "name": "Mars Hill",
    "lng": -82.54974304453157,
    "lat": 35.828089807815466
  },
  {
    "name": "Mars Hill",
    "lng": -82.54523346284856,
    "lat": 35.83472834221465
  },
  {
    "name": "Mars Hill",
    "lng": -82.54083768286094,
    "lat": 35.82221011066835
  },
  {
    "name": "Mars Hill",
    "lng": -82.54012845604073,
    "lat": 35.820515334671605
  },
  {
    "name": "Mars Hill",
    "lng": -82.53984096799485,
    "lat": 35.82147763732996
  },
  {
    "name": "Mars Hill",
    "lng": -82.53918451836932,
    "lat": 35.82354156285502
  },
  {
    "name": "Mars Hill",
    "lng": -82.53754543449736,
    "lat": 35.820290587216874
  },
  {
    "name": "Mars Hill",
    "lng": -82.53741313028874,
    "lat": 35.832815085064276
  },
  {
    "name": "Mars Hill",
    "lng": -82.53062113561852,
    "lat": 35.83087946947619
  },
  {
    "name": "Mars Hill",
    "lng": -82.52918226723281,
    "lat": 35.82837466214505
  },
  {
    "name": "Mars Hill",
    "lng": -82.528821104022,
    "lat": 35.83360543410345
  },
  {
    "name": "Mars Hill",
    "lng": -82.52601916789604,
    "lat": 35.83278804412609
  },
  {
    "name": "Mars Hill",
    "lng": -82.5187545665959,
    "lat": 35.839429449735256
  },
  {
    "name": "Mars Hill",
    "lng": -82.51860366911932,
    "lat": 35.83887825993538
  },
  {
    "name": "Mars Hill",
    "lng": -82.51759973237891,
    "lat": 35.83970948279275
  },
  {
    "name": "Mars Hill",
    "lng": -82.51579160323301,
    "lat": 35.83788468754297
  },
  {
    "name": "Mars Hill",
    "lng": -67.84722631270778,
    "lat": 46.5542715984624
  },
  {
    "name": "Marseilles",
    "lng": -88.69569474890807,
    "lat": 41.35098196812643
  },
  {
    "name": "Marseilles",
    "lng": -88.68492388613345,
    "lat": 41.30984423215703
  },
  {
    "name": "Marseilles",
    "lng": -83.39234568359804,
    "lat": 40.700969673054686
  },
  {
    "name": "Marshall",
    "lng": -162.05038816358703,
    "lat": 61.876782133433075
  },
  {
    "name": "Marshall",
    "lng": -97.62573430108395,
    "lat": 36.155409125504775
  },
  {
    "name": "Marshall",
    "lng": -95.78964525749862,
    "lat": 44.44895751789458
  },
  {
    "name": "Marshall",
    "lng": -94.35118252761315,
    "lat": 32.53682422663953
  },
  {
    "name": "Marshall",
    "lng": -93.20122659448512,
    "lat": 39.11457713167575
  },
  {
    "name": "Marshall",
    "lng": -93.17780328640272,
    "lat": 39.09906181118704
  },
  {
    "name": "Marshall",
    "lng": -92.6445906764003,
    "lat": 35.90942257785151
  },
  {
    "name": "Marshall",
    "lng": -89.06493305060708,
    "lat": 43.17276963627308
  },
  {
    "name": "Marshall",
    "lng": -87.69004512894371,
    "lat": 39.39884138251427
  },
  {
    "name": "Marshall",
    "lng": -87.18678460495502,
    "lat": 39.84767976176881
  },
  {
    "name": "Marshall",
    "lng": -84.96747870170262,
    "lat": 42.293062103673236
  },
  {
    "name": "Marshall",
    "lng": -84.96020329542573,
    "lat": 42.261267848986705
  },
  {
    "name": "Marshall",
    "lng": -84.92913814337548,
    "lat": 42.27980814273968
  },
  {
    "name": "Marshall",
    "lng": -82.69301580923002,
    "lat": 35.78494070986097
  },
  {
    "name": "Marshall",
    "lng": -82.68407823253389,
    "lat": 35.79651646838081
  },
  {
    "name": "Marshall",
    "lng": -82.68013021281776,
    "lat": 35.81514106775002
  },
  {
    "name": "Marshall",
    "lng": -82.6709861336686,
    "lat": 35.810880612984214
  },
  {
    "name": "Marshall",
    "lng": -82.67031674126461,
    "lat": 35.81224550681255
  },
  {
    "name": "Marshall",
    "lng": -82.66852501653369,
    "lat": 35.807033405730614
  },
  {
    "name": "Marshall",
    "lng": -82.66510659015678,
    "lat": 35.80492736975441
  },
  {
    "name": "Marshall",
    "lng": -82.66285561086116,
    "lat": 35.80086668728261
  },
  {
    "name": "Marshall",
    "lng": -82.6599666525578,
    "lat": 35.79805428661152
  },
  {
    "name": "Marshall",
    "lng": -82.65962371312041,
    "lat": 35.8046947314559
  },
  {
    "name": "Marshall",
    "lng": -82.65837247351762,
    "lat": 35.80814447701813
  },
  {
    "name": "Marshall",
    "lng": -82.65728091253504,
    "lat": 35.80125395522194
  },
  {
    "name": "Marshall",
    "lng": -82.65654876960251,
    "lat": 35.798813530121464
  },
  {
    "name": "Marshall",
    "lng": -82.65584525502994,
    "lat": 35.79963227963267
  },
  {
    "name": "Marshall",
    "lng": -82.65474899844826,
    "lat": 35.808760336306555
  },
  {
    "name": "Marshall",
    "lng": -82.65347675911958,
    "lat": 35.79563226756756
  },
  {
    "name": "Marshall",
    "lng": -82.6531712086952,
    "lat": 35.790410222485995
  },
  {
    "name": "Marshall",
    "lng": -82.65274475731276,
    "lat": 35.80462358329367
  },
  {
    "name": "Marshall",
    "lng": -82.6480462299805,
    "lat": 35.79528484148968
  },
  {
    "name": "Marshalltown",
    "lng": -92.90672078128068,
    "lat": 42.03428300657976
  },
  {
    "name": "Marshallville",
    "lng": -83.9407086482611,
    "lat": 32.45642933306122
  },
  {
    "name": "Marshallville",
    "lng": -81.73281974650789,
    "lat": 40.89985683211442
  },
  {
    "name": "Marshfield",
    "lng": -92.9104598091273,
    "lat": 37.34108378803393
  },
  {
    "name": "Marshfield",
    "lng": -90.17550965563127,
    "lat": 44.63620871779724
  },
  {
    "name": "Marshfield",
    "lng": -90.17300646686722,
    "lat": 44.663340544199755
  },
  {
    "name": "Marshfield",
    "lng": -67.49323439263101,
    "lat": 44.76156049963831
  },
  {
    "name": "Marshville",
    "lng": -80.39703612230973,
    "lat": 34.96874619845325
  },
  {
    "name": "Marshville",
    "lng": -80.3695057809638,
    "lat": 34.986821425007285
  },
  {
    "name": "Marsing",
    "lng": -116.80943631332534,
    "lat": 43.547351651068844
  },
  {
    "name": "Marston",
    "lng": -89.6062493350824,
    "lat": 36.517748690364336
  },
  {
    "name": "Mart",
    "lng": -96.83029399844423,
    "lat": 31.542094803924215
  },
  {
    "name": "Martelle",
    "lng": -91.35834244760879,
    "lat": 42.020261536156426
  },
  {
    "name": "Martensdale",
    "lng": -93.73871312927925,
    "lat": 41.37381759463649
  },
  {
    "name": "Martha",
    "lng": -99.38665268633753,
    "lat": 34.72600066757726
  },
  {
    "name": "Marthasville",
    "lng": -91.05721144858991,
    "lat": 38.63087567367988
  },
  {
    "name": "Martin",
    "lng": -101.73211860150917,
    "lat": 43.175039231337166
  },
  {
    "name": "Martin",
    "lng": -100.11513377064102,
    "lat": 47.82657491389209
  },
  {
    "name": "Martin",
    "lng": -93.21930445866168,
    "lat": 32.09823985816878
  },
  {
    "name": "Martin",
    "lng": -88.851779548793,
    "lat": 36.339073840703755
  },
  {
    "name": "Martin",
    "lng": -85.63904682218404,
    "lat": 42.5369663725559
  },
  {
    "name": "Martin",
    "lng": -83.1858187571521,
    "lat": 34.48559141864969
  },
  {
    "name": "Martin",
    "lng": -82.75867392416048,
    "lat": 37.568533626908746
  },
  {
    "name": "Martin's Additions",
    "lng": -77.06927124257311,
    "lat": 38.97939417847521
  },
  {
    "name": "Martindale",
    "lng": -97.83916636501128,
    "lat": 29.8445366040052
  },
  {
    "name": "Martinez",
    "lng": -122.11617281395749,
    "lat": 37.998908153597306
  },
  {
    "name": "Martinez",
    "lng": -122.11269011180033,
    "lat": 38.013041787075785
  },
  {
    "name": "Martins Ferry",
    "lng": -80.72863454709247,
    "lat": 40.100175555741195
  },
  {
    "name": "Martins Ferry",
    "lng": -80.71423707913105,
    "lat": 40.107515376695176
  },
  {
    "name": "Martinsburg",
    "lng": -96.8387606124752,
    "lat": 42.51105699832601
  },
  {
    "name": "Martinsburg",
    "lng": -96.83695387948423,
    "lat": 42.511290233584106
  },
  {
    "name": "Martinsburg",
    "lng": -96.83156770996275,
    "lat": 42.50817096594583
  },
  {
    "name": "Martinsburg",
    "lng": -92.25188580241523,
    "lat": 41.17866734714222
  },
  {
    "name": "Martinsburg",
    "lng": -91.6473731785933,
    "lat": 39.10098417791301
  },
  {
    "name": "Martinsburg",
    "lng": -82.35433989624335,
    "lat": 40.26959204454046
  },
  {
    "name": "Martinsburg",
    "lng": -77.9776539136306,
    "lat": 39.45804778177871
  },
  {
    "name": "Martinsville",
    "lng": -87.8810966796359,
    "lat": 39.338320426810974
  },
  {
    "name": "Martinsville",
    "lng": -86.4269383193033,
    "lat": 39.41932264712709
  },
  {
    "name": "Martinsville",
    "lng": -83.81085478454261,
    "lat": 39.32242285453083
  },
  {
    "name": "Martinsville",
    "lng": -79.86363420841903,
    "lat": 36.682665162263085
  },
  {
    "name": "Martinton",
    "lng": -87.72624090984924,
    "lat": 40.91561994927199
  },
  {
    "name": "Marvell",
    "lng": -90.91534717467528,
    "lat": 34.556318018486884
  },
  {
    "name": "Marvin",
    "lng": -96.9153865990052,
    "lat": 45.260507224129995
  },
  {
    "name": "Marvin",
    "lng": -80.81847393662684,
    "lat": 35.00227237401
  },
  {
    "name": "Marvin",
    "lng": -80.80924136798221,
    "lat": 34.97297325580615
  },
  {
    "name": "Marvin",
    "lng": -80.80595520631958,
    "lat": 34.97417244273285
  },
  {
    "name": "Marvin",
    "lng": -80.78469832103579,
    "lat": 34.96577014333457
  },
  {
    "name": "Marvin",
    "lng": -80.77083099999997,
    "lat": 34.98363033346362
  },
  {
    "name": "Mary Esther",
    "lng": -86.66179404284026,
    "lat": 30.4134058801101
  },
  {
    "name": "Maryhill Estates",
    "lng": -85.65304102725798,
    "lat": 38.266726941138224
  },
  {
    "name": "Maryland",
    "lng": -76.69138926975185,
    "lat": 38.95257769353788
  },
  {
    "name": "Maryland Heights",
    "lng": -90.47564808862124,
    "lat": 38.71918774333862
  },
  {
    "name": "Marysvale",
    "lng": -112.25448055857119,
    "lat": 38.438334010691676
  },
  {
    "name": "Marysville",
    "lng": -122.28514381979289,
    "lat": 48.15477337923626
  },
  {
    "name": "Marysville",
    "lng": -122.15609914202545,
    "lat": 48.0809281435454
  },
  {
    "name": "Marysville",
    "lng": -122.1195049394023,
    "lat": 48.083811624841076
  },
  {
    "name": "Marysville",
    "lng": -121.5834895081407,
    "lat": 39.15178102043745
  },
  {
    "name": "Marysville",
    "lng": -96.64820614470509,
    "lat": 39.85990561771398
  },
  {
    "name": "Marysville",
    "lng": -96.64099503610115,
    "lat": 39.84329035368807
  },
  {
    "name": "Marysville",
    "lng": -96.62219589738176,
    "lat": 39.85281640973998
  },
  {
    "name": "Marysville",
    "lng": -96.6055150277812,
    "lat": 39.83202437100674
  },
  {
    "name": "Marysville",
    "lng": -92.95380078410685,
    "lat": 41.180825615813006
  },
  {
    "name": "Marysville",
    "lng": -83.35875741680336,
    "lat": 40.22730751055247
  },
  {
    "name": "Marysville",
    "lng": -82.48053935162334,
    "lat": 42.90899820662384
  },
  {
    "name": "Maryville",
    "lng": -94.87049357785091,
    "lat": 40.343751489975446
  },
  {
    "name": "Maryville",
    "lng": -89.96455623590856,
    "lat": 38.72608325984964
  },
  {
    "name": "Maryville",
    "lng": -83.97911324659351,
    "lat": 35.746848430298805
  },
  {
    "name": "Maryville",
    "lng": -83.91723206452174,
    "lat": 35.769559064549505
  },
  {
    "name": "Masardis",
    "lng": -68.37054030289396,
    "lat": 46.515939105289014
  },
  {
    "name": "Mascotte",
    "lng": -81.94403189807828,
    "lat": 28.64128242326999
  },
  {
    "name": "Mascotte",
    "lng": -81.92627284328321,
    "lat": 28.575152027714907
  },
  {
    "name": "Mascotte",
    "lng": -81.92185528718336,
    "lat": 28.641654790573384
  },
  {
    "name": "Mascotte",
    "lng": -81.92066424320669,
    "lat": 28.584470202301123
  },
  {
    "name": "Mascotte",
    "lng": -81.91984285622839,
    "lat": 28.584632305255447
  },
  {
    "name": "Mascotte",
    "lng": -81.91919080607555,
    "lat": 28.58445754498166
  },
  {
    "name": "Mascotte",
    "lng": -81.91255023486792,
    "lat": 28.590871696910938
  },
  {
    "name": "Mascotte",
    "lng": -81.9008095438519,
    "lat": 28.65401985736257
  },
  {
    "name": "Mascotte",
    "lng": -81.87811925522495,
    "lat": 28.621122992392255
  },
  {
    "name": "Mascoutah",
    "lng": -89.80454583356925,
    "lat": 38.51918984061724
  },
  {
    "name": "Mashantucket",
    "lng": -71.9978286801477,
    "lat": 41.4676572507928
  },
  {
    "name": "Mashantucket",
    "lng": -71.99695730419394,
    "lat": 41.466802776007235
  },
  {
    "name": "Mashantucket",
    "lng": -71.99344324778286,
    "lat": 41.47001320787506
  },
  {
    "name": "Mashantucket",
    "lng": -71.99263926836382,
    "lat": 41.464982151642864
  },
  {
    "name": "Mashantucket",
    "lng": -71.98883666367402,
    "lat": 41.46718319300872
  },
  {
    "name": "Mashantucket",
    "lng": -71.98408942569635,
    "lat": 41.47322417917779
  },
  {
    "name": "Mashantucket",
    "lng": -71.97240451552177,
    "lat": 41.46489242621903
  },
  {
    "name": "Mashantucket",
    "lng": -71.96946701159312,
    "lat": 41.44977412991554
  },
  {
    "name": "Maskell",
    "lng": -96.98089972364423,
    "lat": 42.69041791669622
  },
  {
    "name": "Mason",
    "lng": -99.22874864760901,
    "lat": 30.748018274544922
  },
  {
    "name": "Mason",
    "lng": -91.05994841077981,
    "lat": 46.43547440000061
  },
  {
    "name": "Mason",
    "lng": -89.5457250576281,
    "lat": 35.410792101561704
  },
  {
    "name": "Mason",
    "lng": -88.62590558805641,
    "lat": 38.95368757187361
  },
  {
    "name": "Mason",
    "lng": -84.44271611057167,
    "lat": 42.58053587942592
  },
  {
    "name": "Mason",
    "lng": -84.30451698138421,
    "lat": 39.3556977135349
  },
  {
    "name": "Mason",
    "lng": -82.03174077083852,
    "lat": 39.01805888024684
  },
  {
    "name": "Mason",
    "lng": -71.74910111864075,
    "lat": 42.747455649471846
  },
  {
    "name": "Mason City",
    "lng": -99.29887871614558,
    "lat": 41.222177805338134
  },
  {
    "name": "Mason City",
    "lng": -93.1996466292086,
    "lat": 43.14878080598312
  },
  {
    "name": "Mason City",
    "lng": -89.69668953970978,
    "lat": 40.20218466982619
  },
  {
    "name": "Masontown",
    "lng": -79.80028855837786,
    "lat": 39.551159738430755
  },
  {
    "name": "Masonville",
    "lng": -91.59156673220508,
    "lat": 42.47980588107518
  },
  {
    "name": "Massachusetts",
    "lng": -71.50053814159776,
    "lat": 42.16021305604249
  },
  {
    "name": "Massena",
    "lng": -94.7698201701257,
    "lat": 41.250644053552264
  },
  {
    "name": "Massena",
    "lng": -74.89204441227858,
    "lat": 44.92905290801023
  },
  {
    "name": "Massillon",
    "lng": -81.52582420179516,
    "lat": 40.784201520351836
  },
  {
    "name": "Matador",
    "lng": -100.82109197411009,
    "lat": 34.01508179169834
  },
  {
    "name": "Matamoras",
    "lng": -81.06851919489719,
    "lat": 39.52033090564125
  },
  {
    "name": "Matewan",
    "lng": -82.16925106325532,
    "lat": 37.62515955981127
  },
  {
    "name": "Matfield Green",
    "lng": -96.56253696276515,
    "lat": 38.159486428468774
  },
  {
    "name": "Matherville",
    "lng": -90.6054669592024,
    "lat": 41.259443446087374
  },
  {
    "name": "Mathis",
    "lng": -97.81706098011324,
    "lat": 28.090921937453267
  },
  {
    "name": "Mathiston",
    "lng": -89.12727502692225,
    "lat": 33.53919647441919
  },
  {
    "name": "Matinecock",
    "lng": -73.5817381380718,
    "lat": 40.86346333899555
  },
  {
    "name": "Matlock",
    "lng": -95.93490565617758,
    "lat": 43.2444076454053
  },
  {
    "name": "Matoaka",
    "lng": -81.24180168877268,
    "lat": 37.41820048181919
  },
  {
    "name": "Mattawa",
    "lng": -119.89716273309052,
    "lat": 46.737133417125335
  },
  {
    "name": "Mattawamkeag",
    "lng": -68.31026113968804,
    "lat": 45.54751640509943
  },
  {
    "name": "Mattawan",
    "lng": -85.78559506629296,
    "lat": 42.21621260036412
  },
  {
    "name": "Matteson",
    "lng": -87.74692959485246,
    "lat": 41.509377160075786
  },
  {
    "name": "Matteson",
    "lng": -87.72061440435648,
    "lat": 41.52488482171112
  },
  {
    "name": "Matthews",
    "lng": -89.56899987683471,
    "lat": 36.75839165231854
  },
  {
    "name": "Matthews",
    "lng": -85.49777813240262,
    "lat": 40.3874569860685
  },
  {
    "name": "Mattoon",
    "lng": -89.04136698480434,
    "lat": 45.00456773532416
  },
  {
    "name": "Mattoon",
    "lng": -88.36230567131665,
    "lat": 39.47740329983491
  },
  {
    "name": "Mauckport",
    "lng": -86.19970604728917,
    "lat": 38.02573491818383
  },
  {
    "name": "Maud",
    "lng": -96.77835863801634,
    "lat": 35.133366520876386
  },
  {
    "name": "Maud",
    "lng": -94.34614464610706,
    "lat": 33.329892200654974
  },
  {
    "name": "Mauldin",
    "lng": -82.30049454836096,
    "lat": 34.78645359745987
  },
  {
    "name": "Mauldin",
    "lng": -82.27752599558238,
    "lat": 34.77187744655516
  },
  {
    "name": "Maumelle",
    "lng": -92.39957539992265,
    "lat": 34.853127086191435
  },
  {
    "name": "Maunie",
    "lng": -88.04572138349154,
    "lat": 38.035358828931926
  },
  {
    "name": "Maurice",
    "lng": -96.18229130590771,
    "lat": 42.96718758835667
  },
  {
    "name": "Maurice",
    "lng": -92.12114299730766,
    "lat": 30.104056142038317
  },
  {
    "name": "Maury City",
    "lng": -89.22386820052976,
    "lat": 35.81436387955111
  },
  {
    "name": "Mauston",
    "lng": -90.07869590792099,
    "lat": 43.799235161768664
  },
  {
    "name": "Max",
    "lng": -101.29148257141425,
    "lat": 47.8205652865442
  },
  {
    "name": "Maxbass",
    "lng": -101.14228169152628,
    "lat": 48.72214970934966
  },
  {
    "name": "Maxeys",
    "lng": -83.1736993915196,
    "lat": 33.7554829761603
  },
  {
    "name": "Maxfield",
    "lng": -68.7553860064133,
    "lat": 45.29913984238401
  },
  {
    "name": "Maxton",
    "lng": -79.36874617117466,
    "lat": 34.72895394582663
  },
  {
    "name": "Maxton",
    "lng": -79.35301613304132,
    "lat": 34.736563480187705
  },
  {
    "name": "Maxton",
    "lng": -79.33052718556974,
    "lat": 34.72816986068119
  },
  {
    "name": "Maxton",
    "lng": -79.33001709254555,
    "lat": 34.73649403349352
  },
  {
    "name": "Maxwell",
    "lng": -104.54329471702395,
    "lat": 36.54105714256739
  },
  {
    "name": "Maxwell",
    "lng": -100.52573481680265,
    "lat": 41.078437911141336
  },
  {
    "name": "Maxwell",
    "lng": -93.39595936080319,
    "lat": 41.89146378320596
  },
  {
    "name": "May",
    "lng": -99.74879315628283,
    "lat": 36.61689176894588
  },
  {
    "name": "Maybee",
    "lng": -83.51526659435027,
    "lat": 42.00627013919693
  },
  {
    "name": "Maybrook",
    "lng": -74.21302966041284,
    "lat": 41.4879830473667
  },
  {
    "name": "Mayer",
    "lng": -93.89021524021877,
    "lat": 44.88683584519981
  },
  {
    "name": "Mayersville",
    "lng": -91.04667033568319,
    "lat": 32.89645773896066
  },
  {
    "name": "Mayesville",
    "lng": -80.20445495115257,
    "lat": 33.984919215758836
  },
  {
    "name": "Mayetta",
    "lng": -95.72111637885116,
    "lat": 39.338545373520226
  },
  {
    "name": "Mayfield",
    "lng": -111.70800823226175,
    "lat": 39.11919812153065
  },
  {
    "name": "Mayfield",
    "lng": -97.54622954719345,
    "lat": 37.26266291758889
  },
  {
    "name": "Mayfield",
    "lng": -88.64512470833348,
    "lat": 36.73589206110698
  },
  {
    "name": "Mayfield",
    "lng": -81.436835271686,
    "lat": 41.550003868571046
  },
  {
    "name": "Mayfield",
    "lng": -74.26566189550114,
    "lat": 43.10215392141318
  },
  {
    "name": "Mayfield Heights",
    "lng": -81.45341553141543,
    "lat": 41.51745512934409
  },
  {
    "name": "Mayflower",
    "lng": -92.4217057748794,
    "lat": 34.969207293479364
  },
  {
    "name": "Maynard",
    "lng": -95.46875279784841,
    "lat": 44.905739570390615
  },
  {
    "name": "Maynard",
    "lng": -95.46238164999828,
    "lat": 44.89938385064114
  },
  {
    "name": "Maynard",
    "lng": -95.46138913243976,
    "lat": 44.89899726948933
  },
  {
    "name": "Maynard",
    "lng": -91.87737319992549,
    "lat": 42.773822495844826
  },
  {
    "name": "Maynard",
    "lng": -90.9017994680549,
    "lat": 36.42152092064171
  },
  {
    "name": "Maynard",
    "lng": -71.45610886163398,
    "lat": 42.42640284101396
  },
  {
    "name": "Maynardville",
    "lng": -83.80643361983346,
    "lat": 36.24663975543884
  },
  {
    "name": "Mayo",
    "lng": -83.17569540212853,
    "lat": 30.05063469451604
  },
  {
    "name": "Mayodan",
    "lng": -79.99418807433034,
    "lat": 36.432743670486424
  },
  {
    "name": "Mayodan",
    "lng": -79.97281394362386,
    "lat": 36.41392318251141
  },
  {
    "name": "Mayodan",
    "lng": -79.95536502663855,
    "lat": 36.400655762665906
  },
  {
    "name": "Mayodan",
    "lng": -79.95519982461911,
    "lat": 36.42512173051548
  },
  {
    "name": "Mayodan",
    "lng": -79.95187528372875,
    "lat": 36.43516032342096
  },
  {
    "name": "Mayodan",
    "lng": -79.95139038401967,
    "lat": 36.42831412030995
  },
  {
    "name": "Mayodan",
    "lng": -79.94323459630817,
    "lat": 36.41367256480899
  },
  {
    "name": "Mayodan",
    "lng": -79.94056364988892,
    "lat": 36.427346150314804
  },
  {
    "name": "Mayodan",
    "lng": -79.93995820368183,
    "lat": 36.43623715831612
  },
  {
    "name": "Mayodan",
    "lng": -79.939546261869,
    "lat": 36.41410532377879
  },
  {
    "name": "Mayodan",
    "lng": -79.93483807139603,
    "lat": 36.42014129133573
  },
  {
    "name": "Maypearl",
    "lng": -97.00766405032229,
    "lat": 32.313628512027535
  },
  {
    "name": "Maysville",
    "lng": -97.4140766380492,
    "lat": 34.81094745389955
  },
  {
    "name": "Maysville",
    "lng": -97.41301374841602,
    "lat": 34.809850644925
  },
  {
    "name": "Maysville",
    "lng": -97.41127154910647,
    "lat": 34.817047452971984
  },
  {
    "name": "Maysville",
    "lng": -94.36334827329196,
    "lat": 39.88585948466651
  },
  {
    "name": "Maysville",
    "lng": -90.71841098316102,
    "lat": 41.649116636395156
  },
  {
    "name": "Maysville",
    "lng": -83.7912134558538,
    "lat": 38.64541974328068
  },
  {
    "name": "Maysville",
    "lng": -83.55280372393688,
    "lat": 34.25556641413589
  },
  {
    "name": "Maysville",
    "lng": -77.23055287164507,
    "lat": 34.903987151281754
  },
  {
    "name": "Maytown",
    "lng": -86.99987592745731,
    "lat": 33.549013739297685
  },
  {
    "name": "Mayview",
    "lng": -93.83329692449497,
    "lat": 39.05276637972889
  },
  {
    "name": "Mayville",
    "lng": -97.32616790508274,
    "lat": 47.49805457632411
  },
  {
    "name": "Mayville",
    "lng": -88.55802110327086,
    "lat": 43.510283278702815
  },
  {
    "name": "Mayville",
    "lng": -88.55430146616138,
    "lat": 43.46159486229232
  },
  {
    "name": "Mayville",
    "lng": -88.54743561816416,
    "lat": 43.49873396786133
  },
  {
    "name": "Mayville",
    "lng": -83.35262324437346,
    "lat": 43.33685389414412
  },
  {
    "name": "Mayville",
    "lng": -79.50311834007138,
    "lat": 42.2531858649153
  },
  {
    "name": "Maywood",
    "lng": -100.6221395994723,
    "lat": 40.65799869450715
  },
  {
    "name": "Maywood",
    "lng": -87.84418785719433,
    "lat": 41.87976764250208
  },
  {
    "name": "Mazeppa",
    "lng": -92.54019774620838,
    "lat": 44.27368803024649
  },
  {
    "name": "Mazomanie",
    "lng": -89.79506924472179,
    "lat": 43.17288892441634
  },
  {
    "name": "Mazon",
    "lng": -88.42338555236859,
    "lat": 41.241727397454845
  },
  {
    "name": "McAdenville",
    "lng": -81.08013253426108,
    "lat": 35.26498524608812
  },
  {
    "name": "McAlester",
    "lng": -95.77114980358154,
    "lat": 34.926971151343295
  },
  {
    "name": "McAlester",
    "lng": -95.7575575294811,
    "lat": 34.96530433278963
  },
  {
    "name": "McAllen",
    "lng": -98.24669670549997,
    "lat": 26.23257383331055
  },
  {
    "name": "McArthur",
    "lng": -82.47867854123594,
    "lat": 39.246592175745796
  },
  {
    "name": "McBain",
    "lng": -85.21537155653604,
    "lat": 44.19603283870063
  },
  {
    "name": "McBaine",
    "lng": -92.45502118041664,
    "lat": 38.88317469785428
  },
  {
    "name": "McBee",
    "lng": -80.25721325322041,
    "lat": 34.467067730105875
  },
  {
    "name": "McBride",
    "lng": -85.04405149456643,
    "lat": 43.352075536722076
  },
  {
    "name": "McCall",
    "lng": -116.11244702397373,
    "lat": 44.9076388508247
  },
  {
    "name": "McCallsburg",
    "lng": -93.39062452638805,
    "lat": 42.16485632925502
  },
  {
    "name": "McCamey",
    "lng": -102.22005176219204,
    "lat": 31.132476113427682
  },
  {
    "name": "McCammon",
    "lng": -112.19006963384699,
    "lat": 42.65036008778135
  },
  {
    "name": "McCaskill",
    "lng": -93.64163541718399,
    "lat": 33.918732045966514
  },
  {
    "name": "McCausland",
    "lng": -90.4461006326057,
    "lat": 41.743395456797664
  },
  {
    "name": "McCaysville",
    "lng": -84.37065690707045,
    "lat": 34.98107772964754
  },
  {
    "name": "McCleary",
    "lng": -123.2724428669324,
    "lat": 47.05837392964228
  },
  {
    "name": "McClelland",
    "lng": -95.68346948529316,
    "lat": 41.328411973479334
  },
  {
    "name": "McClellanville",
    "lng": -79.4681300992576,
    "lat": 33.086471556517424
  },
  {
    "name": "McClure",
    "lng": -89.43316390368025,
    "lat": 37.3147097684181
  },
  {
    "name": "McClure",
    "lng": -83.94129100771185,
    "lat": 41.370966711202136
  },
  {
    "name": "McClusky",
    "lng": -100.442713141601,
    "lat": 47.48404640651426
  },
  {
    "name": "McColl",
    "lng": -79.54478560727958,
    "lat": 34.666475363518906
  },
  {
    "name": "McComb",
    "lng": -90.47137046732689,
    "lat": 31.244857789898493
  },
  {
    "name": "McComb",
    "lng": -83.78938574892275,
    "lat": 41.10736389622261
  },
  {
    "name": "McConnells",
    "lng": -81.2267011809931,
    "lat": 34.86933741938049
  },
  {
    "name": "McConnelsville",
    "lng": -81.84694899623874,
    "lat": 39.65663281686663
  },
  {
    "name": "McCook",
    "lng": -100.6219646400418,
    "lat": 40.204503683120834
  },
  {
    "name": "McCook",
    "lng": -87.84125893226799,
    "lat": 41.79587977839368
  },
  {
    "name": "McCool",
    "lng": -89.34181339359931,
    "lat": 33.199516286040094
  },
  {
    "name": "McCool Junction",
    "lng": -97.60040418186077,
    "lat": 40.74395420473837
  },
  {
    "name": "McCord Bend",
    "lng": -93.50332069095562,
    "lat": 36.7874430666926
  },
  {
    "name": "McCordsville",
    "lng": -85.92144263755836,
    "lat": 39.895742359358174
  },
  {
    "name": "McCormick",
    "lng": -82.29310193983855,
    "lat": 33.91090843266036
  },
  {
    "name": "McCormick",
    "lng": -82.25824984284046,
    "lat": 33.92312928280884
  },
  {
    "name": "McCracken",
    "lng": -99.56904697600206,
    "lat": 38.58203121669806
  },
  {
    "name": "McCrory",
    "lng": -91.19759577770814,
    "lat": 35.25834248848046
  },
  {
    "name": "McCullom Lake",
    "lng": -88.29800738019088,
    "lat": 42.37402478618884
  },
  {
    "name": "McCullom Lake",
    "lng": -88.29654344730292,
    "lat": 42.36766507184878
  },
  {
    "name": "McCune",
    "lng": -95.01904293773936,
    "lat": 37.354122787561295
  },
  {
    "name": "McCurtain",
    "lng": -94.96689464576544,
    "lat": 35.150873452550584
  },
  {
    "name": "McDermott",
    "lng": -83.0586211045951,
    "lat": 38.8343499416455
  },
  {
    "name": "McDonald",
    "lng": -101.37022433540353,
    "lat": 39.78512556160927
  },
  {
    "name": "McDonald",
    "lng": -80.72310024130653,
    "lat": 41.16338612894827
  },
  {
    "name": "McDonald",
    "lng": -79.17625256167007,
    "lat": 34.553745249126045
  },
  {
    "name": "McDonough",
    "lng": -84.15368452574621,
    "lat": 33.438600841043936
  },
  {
    "name": "McDonough",
    "lng": -84.14741146504537,
    "lat": 33.47864373617238
  },
  {
    "name": "McDonough",
    "lng": -84.11115004892758,
    "lat": 33.45673977023271
  },
  {
    "name": "McDougal",
    "lng": -90.39073959749562,
    "lat": 36.43647628102969
  },
  {
    "name": "McEwen",
    "lng": -87.63569512045862,
    "lat": 36.10984361182394
  },
  {
    "name": "McFall",
    "lng": -94.2223017484612,
    "lat": 40.11158417444238
  },
  {
    "name": "McFarlan",
    "lng": -79.97662573477774,
    "lat": 34.81481564331667
  },
  {
    "name": "McFarland",
    "lng": -119.24146223685756,
    "lat": 35.67814710803476
  },
  {
    "name": "McFarland",
    "lng": -96.23791991141627,
    "lat": 39.0545468705135
  },
  {
    "name": "McFarland",
    "lng": -89.28537642451462,
    "lat": 43.01986229812989
  },
  {
    "name": "McGehee",
    "lng": -91.395290561479,
    "lat": 33.62795245480088
  },
  {
    "name": "McGrath",
    "lng": -155.56712607374834,
    "lat": 62.942345462635416
  },
  {
    "name": "McGrath",
    "lng": -93.27490567910411,
    "lat": 46.24217547024828
  },
  {
    "name": "McGraw",
    "lng": -76.09749254134746,
    "lat": 42.593810067924174
  },
  {
    "name": "McGregor",
    "lng": -97.42608417005427,
    "lat": 31.419860871051245
  },
  {
    "name": "McGregor",
    "lng": -93.30551748720887,
    "lat": 46.608697377619976
  },
  {
    "name": "McGregor",
    "lng": -91.18218517245131,
    "lat": 43.02415995526842
  },
  {
    "name": "McGrew",
    "lng": -103.4176600861877,
    "lat": 41.74780625400661
  },
  {
    "name": "McGuffey",
    "lng": -83.78600390417662,
    "lat": 40.692599623983355
  },
  {
    "name": "McHenry",
    "lng": -98.59116758466965,
    "lat": 47.576423595587826
  },
  {
    "name": "McHenry",
    "lng": -88.29306781246117,
    "lat": 42.33879739870648
  },
  {
    "name": "McHenry",
    "lng": -86.92297671976658,
    "lat": 37.379780532432115
  },
  {
    "name": "McIntire",
    "lng": -92.59364981280117,
    "lat": 43.43594947300373
  },
  {
    "name": "McIntosh",
    "lng": -101.3505824799291,
    "lat": 45.92283633113199
  },
  {
    "name": "McIntosh",
    "lng": -101.34357747964343,
    "lat": 45.91575926699669
  },
  {
    "name": "McIntosh",
    "lng": -95.88637417497822,
    "lat": 47.636928308988765
  },
  {
    "name": "McIntosh",
    "lng": -82.22107301170172,
    "lat": 29.449276747840198
  },
  {
    "name": "McIntyre",
    "lng": -83.20109453237369,
    "lat": 32.84657149368165
  },
  {
    "name": "McKee",
    "lng": -83.98795666185681,
    "lat": 37.430716207244245
  },
  {
    "name": "McKenney",
    "lng": -77.72190953315165,
    "lat": 36.98536709426365
  },
  {
    "name": "McKenzie",
    "lng": -88.50833964475956,
    "lat": 36.13791990031987
  },
  {
    "name": "McKinley",
    "lng": -92.40554672500488,
    "lat": 47.50899870811535
  },
  {
    "name": "McKinleyville",
    "lng": -124.08534017761893,
    "lat": 40.9480825508922
  },
  {
    "name": "McKittrick",
    "lng": -91.4431159992913,
    "lat": 38.73569828550114
  },
  {
    "name": "McLaughlin",
    "lng": -100.81068151995574,
    "lat": 45.813307074194384
  },
  {
    "name": "McLean",
    "lng": -100.60020267356708,
    "lat": 35.232318108353724
  },
  {
    "name": "McLean",
    "lng": -97.4689281478598,
    "lat": 42.389428960739934
  },
  {
    "name": "McLean",
    "lng": -97.46813454943586,
    "lat": 42.386163180636565
  },
  {
    "name": "McLean",
    "lng": -89.17031721535106,
    "lat": 40.31550111760886
  },
  {
    "name": "McLeansboro",
    "lng": -88.5386770006518,
    "lat": 38.09018489356281
  },
  {
    "name": "McLemoresville",
    "lng": -88.58121854851599,
    "lat": 35.987051009781375
  },
  {
    "name": "McLendon-Chisholm",
    "lng": -96.39049748608535,
    "lat": 32.84817353864518
  },
  {
    "name": "McLoud",
    "lng": -97.09990355879675,
    "lat": 35.406705224183
  },
  {
    "name": "McLouth",
    "lng": -95.2083516537652,
    "lat": 39.196057338486746
  },
  {
    "name": "McMechen",
    "lng": -80.73339968487214,
    "lat": 39.9853036777291
  },
  {
    "name": "McMinnville",
    "lng": -85.78101606440346,
    "lat": 35.68533301172181
  },
  {
    "name": "McMullen",
    "lng": -88.17540570189959,
    "lat": 33.14821269511843
  },
  {
    "name": "McNab",
    "lng": -93.83226401521799,
    "lat": 33.66089298265338
  },
  {
    "name": "McNabb",
    "lng": -89.20974821190781,
    "lat": 41.1770579593171
  },
  {
    "name": "McNary",
    "lng": -92.57945489231992,
    "lat": 30.989491135013303
  },
  {
    "name": "McNeil",
    "lng": -93.20861437853996,
    "lat": 33.347921156294085
  },
  {
    "name": "McPherson",
    "lng": -97.66137383765617,
    "lat": 38.37190870507617
  },
  {
    "name": "McPherson",
    "lng": -97.61735976110512,
    "lat": 38.371712380865375
  },
  {
    "name": "McRae",
    "lng": -91.82533940492735,
    "lat": 35.11178334457355
  },
  {
    "name": "McRae",
    "lng": -82.89100581667655,
    "lat": 32.076787249507476
  },
  {
    "name": "McRae",
    "lng": -82.88998647824744,
    "lat": 32.05540753307335
  },
  {
    "name": "McVille",
    "lng": -98.1755116536633,
    "lat": 47.76521776220666
  },
  {
    "name": "Mead",
    "lng": -104.99388071753043,
    "lat": 40.24526836943648
  },
  {
    "name": "Mead",
    "lng": -104.98639275434941,
    "lat": 40.22388682693416
  },
  {
    "name": "Mead",
    "lng": -104.98635542154196,
    "lat": 40.22341487941258
  },
  {
    "name": "Mead",
    "lng": -104.97606234328677,
    "lat": 40.202240618271254
  },
  {
    "name": "Mead",
    "lng": -96.51095760173085,
    "lat": 34.00069024869376
  },
  {
    "name": "Mead",
    "lng": -96.48882068040677,
    "lat": 41.228722391004645
  },
  {
    "name": "Mead",
    "lng": -96.47590255640596,
    "lat": 41.198107148234705
  },
  {
    "name": "Meade",
    "lng": -100.33777435303664,
    "lat": 37.28548679548568
  },
  {
    "name": "Meadow",
    "lng": -112.40714315101425,
    "lat": 38.88622621369559
  },
  {
    "name": "Meadow",
    "lng": -102.20585694127763,
    "lat": 33.33772605880104
  },
  {
    "name": "Meadow Bridge",
    "lng": -80.85616375425077,
    "lat": 37.861309278426155
  },
  {
    "name": "Meadow Grove",
    "lng": -97.73601964057862,
    "lat": 42.029006305588375
  },
  {
    "name": "Meadow Vale",
    "lng": -85.57274117775276,
    "lat": 38.2834197206392
  },
  {
    "name": "Meadow Woods",
    "lng": -81.35681305396211,
    "lat": 28.36401632908815
  },
  {
    "name": "Meadowbrook Farm",
    "lng": -85.57489605657553,
    "lat": 38.27831663428995
  },
  {
    "name": "Meadowlakes",
    "lng": -98.29534786147326,
    "lat": 30.563847597856824
  },
  {
    "name": "Meadowlands",
    "lng": -92.73129847394607,
    "lat": 47.073128553938645
  },
  {
    "name": "Meadows Place",
    "lng": -95.58733854266175,
    "lat": 29.65122118290456
  },
  {
    "name": "Meadows at Imperial Oaks",
    "lng": -95.39779937700978,
    "lat": 30.155258225400672
  },
  {
    "name": "Meadowview Estates",
    "lng": -85.63554144826665,
    "lat": 38.22285049075456
  },
  {
    "name": "Meadville",
    "lng": -93.30299650915623,
    "lat": 39.78797779424038
  },
  {
    "name": "Meadville",
    "lng": -90.89294265223941,
    "lat": 31.472117728999923
  },
  {
    "name": "Meadville",
    "lng": -80.14679691206223,
    "lat": 41.64754806768197
  },
  {
    "name": "Mebane",
    "lng": -79.31593563063241,
    "lat": 36.07460620710863
  },
  {
    "name": "Mebane",
    "lng": -79.28328417940058,
    "lat": 36.06072925939804
  },
  {
    "name": "Mebane",
    "lng": -79.27553900029191,
    "lat": 36.092322065721156
  },
  {
    "name": "Mebane",
    "lng": -79.25603658762836,
    "lat": 36.07372810381698
  },
  {
    "name": "Mebane",
    "lng": -79.2264864523522,
    "lat": 36.07821246833113
  },
  {
    "name": "Mecca",
    "lng": -116.04904753786468,
    "lat": 33.55870623427772
  },
  {
    "name": "Mecca",
    "lng": -87.33166495035073,
    "lat": 39.72709246988571
  },
  {
    "name": "Mechanic Falls",
    "lng": -70.40738037459532,
    "lat": 44.102936052928314
  },
  {
    "name": "Mechanicsburg",
    "lng": -89.40648940897576,
    "lat": 39.80303949584278
  },
  {
    "name": "Mechanicsburg",
    "lng": -83.55705634600282,
    "lat": 40.073657040932574
  },
  {
    "name": "Mechanicsville",
    "lng": -91.25334310651374,
    "lat": 41.90548703995048
  },
  {
    "name": "Mechanicsville",
    "lng": -76.1802361768501,
    "lat": 40.69096778951786
  },
  {
    "name": "Mechanicsville",
    "lng": -71.88517844610061,
    "lat": 41.954477221455065
  },
  {
    "name": "Mecosta",
    "lng": -85.23041019955254,
    "lat": 43.62100044638195
  },
  {
    "name": "Medaryville",
    "lng": -86.89053536567218,
    "lat": 41.08037519706762
  },
  {
    "name": "Meddybemps",
    "lng": -67.35377141633292,
    "lat": 45.03909238780959
  },
  {
    "name": "Medford",
    "lng": -97.73783971922232,
    "lat": 36.80366042818796
  },
  {
    "name": "Medford",
    "lng": -93.26187714173156,
    "lat": 44.1615107048616
  },
  {
    "name": "Medford",
    "lng": -93.2490005111426,
    "lat": 44.17866707397495
  },
  {
    "name": "Medford",
    "lng": -93.24703896528396,
    "lat": 44.17302489216187
  },
  {
    "name": "Medford",
    "lng": -93.24547085505434,
    "lat": 44.164979703671754
  },
  {
    "name": "Medford",
    "lng": -93.24265352159642,
    "lat": 44.15471921325548
  },
  {
    "name": "Medford",
    "lng": -93.23448339422784,
    "lat": 44.1703419083066
  },
  {
    "name": "Medford",
    "lng": -90.34599972542028,
    "lat": 45.129562333143504
  },
  {
    "name": "Medford",
    "lng": -90.34594731161296,
    "lat": 45.13806374031129
  },
  {
    "name": "Medford",
    "lng": -90.34388744881232,
    "lat": 45.15608584863638
  },
  {
    "name": "Medford",
    "lng": -90.32504530233561,
    "lat": 45.13376323208726
  },
  {
    "name": "Medford",
    "lng": -71.1087356645324,
    "lat": 42.42347014375258
  },
  {
    "name": "Medford",
    "lng": -68.85697147232499,
    "lat": 45.27576968641251
  },
  {
    "name": "Media",
    "lng": -90.83265099887767,
    "lat": 40.769449717769156
  },
  {
    "name": "Mediapolis",
    "lng": -91.16370691043699,
    "lat": 41.007878772515404
  },
  {
    "name": "Medical Lake",
    "lng": -117.6896342667494,
    "lat": 47.571237747895005
  },
  {
    "name": "Medicine Bow",
    "lng": -106.20191719926952,
    "lat": 41.89954012530582
  },
  {
    "name": "Medicine Lake",
    "lng": -104.50153947971533,
    "lat": 48.502148040733324
  },
  {
    "name": "Medicine Lake",
    "lng": -93.41985757101295,
    "lat": 44.99794852754846
  },
  {
    "name": "Medicine Lodge",
    "lng": -98.58189541864924,
    "lat": 37.28487120930598
  },
  {
    "name": "Medicine Lodge",
    "lng": -98.56975252693921,
    "lat": 37.291342174850975
  },
  {
    "name": "Medicine Park",
    "lng": -98.47728474080691,
    "lat": 34.72867411655283
  },
  {
    "name": "Medina",
    "lng": -122.24288000824455,
    "lat": 47.62651300695814
  },
  {
    "name": "Medina",
    "lng": -99.2998727401448,
    "lat": 46.89522537010512
  },
  {
    "name": "Medina",
    "lng": -93.58334872727985,
    "lat": 45.03265679293639
  },
  {
    "name": "Medina",
    "lng": -88.79948915250286,
    "lat": 35.83069734248793
  },
  {
    "name": "Medina",
    "lng": -88.78456202469522,
    "lat": 35.80555745605362
  },
  {
    "name": "Medina",
    "lng": -81.86902829212646,
    "lat": 41.135948471779045
  },
  {
    "name": "Medina",
    "lng": -78.38880822573803,
    "lat": 43.21966091090783
  },
  {
    "name": "Medley",
    "lng": -80.35241389288622,
    "lat": 25.846165387128938
  },
  {
    "name": "Medley",
    "lng": -80.35113670755193,
    "lat": 25.864438393770538
  },
  {
    "name": "Medon",
    "lng": -88.86447846511203,
    "lat": 35.456138077055904
  },
  {
    "name": "Medora",
    "lng": -103.524936196996,
    "lat": 46.91288362981744
  },
  {
    "name": "Medora",
    "lng": -90.14138771484141,
    "lat": 39.176520740156654
  },
  {
    "name": "Medora",
    "lng": -86.17153080039868,
    "lat": 38.824760250696805
  },
  {
    "name": "Medway",
    "lng": -68.49837965415419,
    "lat": 45.6182907348358
  },
  {
    "name": "Meeker",
    "lng": -107.89500384400583,
    "lat": 40.04971307068306
  },
  {
    "name": "Meeker",
    "lng": -107.86715678834271,
    "lat": 40.061806727064756
  },
  {
    "name": "Meeker",
    "lng": -96.89514847273577,
    "lat": 35.485299346408404
  },
  {
    "name": "Meeteetse",
    "lng": -108.8611847461549,
    "lat": 44.15430728455728
  },
  {
    "name": "Megargel",
    "lng": -98.9298383528753,
    "lat": 33.45359688509263
  },
  {
    "name": "Megargel",
    "lng": -98.92337410560357,
    "lat": 33.44669895015937
  },
  {
    "name": "Meggett",
    "lng": -80.34059288212799,
    "lat": 32.68921535472518
  },
  {
    "name": "Meggett",
    "lng": -80.32095195127208,
    "lat": 32.67240633452595
  },
  {
    "name": "Meggett",
    "lng": -80.32053809806104,
    "lat": 32.68811534616565
  },
  {
    "name": "Meggett",
    "lng": -80.31279432838708,
    "lat": 32.72491864188552
  },
  {
    "name": "Meggett",
    "lng": -80.3114101714454,
    "lat": 32.661003617701056
  },
  {
    "name": "Meggett",
    "lng": -80.30713950616621,
    "lat": 32.671979628947525
  },
  {
    "name": "Meggett",
    "lng": -80.30324628965019,
    "lat": 32.66509010287946
  },
  {
    "name": "Meggett",
    "lng": -80.29487524655485,
    "lat": 32.70570265123945
  },
  {
    "name": "Meggett",
    "lng": -80.29072534084638,
    "lat": 32.72092806166035
  },
  {
    "name": "Meggett",
    "lng": -80.29059251789039,
    "lat": 32.6677126385095
  },
  {
    "name": "Meggett",
    "lng": -80.28961737343391,
    "lat": 32.66582760679479
  },
  {
    "name": "Meggett",
    "lng": -80.28696316025824,
    "lat": 32.66393158175046
  },
  {
    "name": "Meggett",
    "lng": -80.28538892804079,
    "lat": 32.69240872727686
  },
  {
    "name": "Meggett",
    "lng": -80.28495564397383,
    "lat": 32.67218812037266
  },
  {
    "name": "Meggett",
    "lng": -80.28448751048985,
    "lat": 32.67133416418033
  },
  {
    "name": "Meggett",
    "lng": -80.28352481391138,
    "lat": 32.64776660649828
  },
  {
    "name": "Meggett",
    "lng": -80.28194139812541,
    "lat": 32.670904744163316
  },
  {
    "name": "Meggett",
    "lng": -80.28130821185205,
    "lat": 32.65604095460014
  },
  {
    "name": "Meggett",
    "lng": -80.2805619934333,
    "lat": 32.655319876998085
  },
  {
    "name": "Meggett",
    "lng": -80.28055071643682,
    "lat": 32.65134190448319
  },
  {
    "name": "Meggett",
    "lng": -80.28037199286325,
    "lat": 32.65334223596935
  },
  {
    "name": "Meggett",
    "lng": -80.2789656795502,
    "lat": 32.654661644639255
  },
  {
    "name": "Meggett",
    "lng": -80.2787754387617,
    "lat": 32.67027067323047
  },
  {
    "name": "Meggett",
    "lng": -80.25405212127664,
    "lat": 32.69616448942585
  },
  {
    "name": "Meggett",
    "lng": -80.21285790444978,
    "lat": 32.716375357220684
  },
  {
    "name": "Meggett",
    "lng": -80.1947115459854,
    "lat": 32.7272507123957
  },
  {
    "name": "Meggett",
    "lng": -80.17480959898143,
    "lat": 32.73083817823415
  },
  {
    "name": "Meggett",
    "lng": -80.17464129653307,
    "lat": 32.71694351549402
  },
  {
    "name": "Meigs",
    "lng": -84.09255528197491,
    "lat": 31.07094631588869
  },
  {
    "name": "Meire Grove",
    "lng": -94.86931842305043,
    "lat": 45.62617213215167
  },
  {
    "name": "Mekoryuk",
    "lng": -166.26410947549314,
    "lat": 60.37156061657596
  },
  {
    "name": "Melba",
    "lng": -116.53223196821051,
    "lat": 43.37248721563568
  },
  {
    "name": "Melbeta",
    "lng": -103.51694282205568,
    "lat": 41.781967988856366
  },
  {
    "name": "Melbourne",
    "lng": -93.10220064934309,
    "lat": 41.941649596950946
  },
  {
    "name": "Melbourne",
    "lng": -91.89986668626575,
    "lat": 36.043622130020964
  },
  {
    "name": "Melbourne",
    "lng": -91.87926131275135,
    "lat": 36.063585247825955
  },
  {
    "name": "Melbourne",
    "lng": -84.37117431133878,
    "lat": 39.03119636911123
  },
  {
    "name": "Melbourne",
    "lng": -80.72880380649731,
    "lat": 28.14735759600081
  },
  {
    "name": "Melbourne",
    "lng": -80.68065938292726,
    "lat": 28.131590337306942
  },
  {
    "name": "Melbourne",
    "lng": -80.67213977957941,
    "lat": 28.13236873347771
  },
  {
    "name": "Melbourne",
    "lng": -80.65937437846523,
    "lat": 28.11443876762043
  },
  {
    "name": "Melbourne",
    "lng": -80.5896991047407,
    "lat": 28.135917301319964
  },
  {
    "name": "Melbourne",
    "lng": -80.58346928562442,
    "lat": 28.1216583252408
  },
  {
    "name": "Melbourne",
    "lng": -80.57893859041357,
    "lat": 28.127918942010115
  },
  {
    "name": "Melbourne Beach",
    "lng": -80.56112097771178,
    "lat": 28.066765151390566
  },
  {
    "name": "Melcher-Dallas",
    "lng": -93.24162569507921,
    "lat": 41.227044263238255
  },
  {
    "name": "Melfa",
    "lng": -75.740793461433,
    "lat": 37.649057169525065
  },
  {
    "name": "Mellen",
    "lng": -90.66046454744325,
    "lat": 46.32398931599162
  },
  {
    "name": "Mellette",
    "lng": -98.49834704808737,
    "lat": 45.15436123440444
  },
  {
    "name": "Mellott",
    "lng": -87.14891109486085,
    "lat": 40.1637253215871
  },
  {
    "name": "Melrose",
    "lng": -103.62959573425836,
    "lat": 34.42898793490589
  },
  {
    "name": "Melrose",
    "lng": -94.81279340165443,
    "lat": 45.675681669771414
  },
  {
    "name": "Melrose",
    "lng": -93.05083199331732,
    "lat": 40.979040873087555
  },
  {
    "name": "Melrose",
    "lng": -90.99718391544866,
    "lat": 44.13157630878519
  },
  {
    "name": "Melrose",
    "lng": -84.4200347402909,
    "lat": 41.08871944340219
  },
  {
    "name": "Melrose",
    "lng": -71.05900812838105,
    "lat": 42.45561668782382
  },
  {
    "name": "Melrose Park",
    "lng": -87.86367673448538,
    "lat": 41.90302605660875
  },
  {
    "name": "Melstone",
    "lng": -107.86845682507348,
    "lat": 46.59870297984589
  },
  {
    "name": "Melvern",
    "lng": -95.63766350781059,
    "lat": 38.50745816286264
  },
  {
    "name": "Melville",
    "lng": -91.74843607360097,
    "lat": 30.691707085043344
  },
  {
    "name": "Melvin",
    "lng": -99.58089969088316,
    "lat": 31.19925680104033
  },
  {
    "name": "Melvin",
    "lng": -95.60865819426948,
    "lat": 43.286735801891744
  },
  {
    "name": "Melvin",
    "lng": -95.60423999759315,
    "lat": 43.280216727775034
  },
  {
    "name": "Melvin",
    "lng": -88.24699956773891,
    "lat": 40.57096457402008
  },
  {
    "name": "Melvin",
    "lng": -82.86164443515649,
    "lat": 43.18648754375565
  },
  {
    "name": "Melvina",
    "lng": -90.78131541770895,
    "lat": 43.80254549570905
  },
  {
    "name": "Melvindale",
    "lng": -83.17808228139234,
    "lat": 42.28023721823465
  },
  {
    "name": "Memphis",
    "lng": -100.5416643907154,
    "lat": 34.72673351255459
  },
  {
    "name": "Memphis",
    "lng": -96.43313658464895,
    "lat": 41.09456384563861
  },
  {
    "name": "Memphis",
    "lng": -92.1704157393753,
    "lat": 40.461356905590726
  },
  {
    "name": "Memphis",
    "lng": -89.97935028471292,
    "lat": 35.1045746737265
  },
  {
    "name": "Memphis",
    "lng": -88.30077582247685,
    "lat": 33.13688516185379
  },
  {
    "name": "Memphis",
    "lng": -82.76872797172396,
    "lat": 42.8956436473152
  },
  {
    "name": "Mena",
    "lng": -94.23735532270481,
    "lat": 34.58096267492325
  },
  {
    "name": "Menahga",
    "lng": -95.10119641193195,
    "lat": 46.747136443256366
  },
  {
    "name": "Menan",
    "lng": -111.99235273805093,
    "lat": 43.721791551148115
  },
  {
    "name": "Menasha",
    "lng": -88.42772343924624,
    "lat": 44.21282708703931
  },
  {
    "name": "Menasha",
    "lng": -88.40107958650633,
    "lat": 44.2131706764657
  },
  {
    "name": "Mendenhall",
    "lng": -89.86922856419388,
    "lat": 31.960875958296185
  },
  {
    "name": "Mendon",
    "lng": -111.97773317029036,
    "lat": 41.71027257823865
  },
  {
    "name": "Mendon",
    "lng": -93.13395417502537,
    "lat": 39.59059665758328
  },
  {
    "name": "Mendon",
    "lng": -91.28578381695772,
    "lat": 40.08918192256951
  },
  {
    "name": "Mendon",
    "lng": -85.45479198851324,
    "lat": 42.008922357432525
  },
  {
    "name": "Mendon",
    "lng": -84.51756545982401,
    "lat": 40.673311398719314
  },
  {
    "name": "Mendon",
    "lng": -71.55147320240575,
    "lat": 42.09302327844564
  },
  {
    "name": "Mendota",
    "lng": -120.38021536278104,
    "lat": 36.75155071550644
  },
  {
    "name": "Mendota",
    "lng": -93.16102527366778,
    "lat": 44.887491263961714
  },
  {
    "name": "Mendota",
    "lng": -89.10415296187635,
    "lat": 41.55532303352948
  },
  {
    "name": "Mendota Heights",
    "lng": -93.1498703820576,
    "lat": 44.89385860897595
  },
  {
    "name": "Mendota Heights",
    "lng": -93.14001207616602,
    "lat": 44.88145274296216
  },
  {
    "name": "Menifee",
    "lng": -92.55446603722639,
    "lat": 35.14933044348833
  },
  {
    "name": "Menlo",
    "lng": -100.72430184833938,
    "lat": 39.35611577671141
  },
  {
    "name": "Menlo",
    "lng": -94.40441820141086,
    "lat": 41.52164179124626
  },
  {
    "name": "Menlo",
    "lng": -85.4776381835978,
    "lat": 34.48345172304531
  },
  {
    "name": "Menlo Oaks",
    "lng": -122.16746457539276,
    "lat": 37.466170012005556
  },
  {
    "name": "Menlo Park",
    "lng": -122.14873109105685,
    "lat": 37.48052348562137
  },
  {
    "name": "Menno",
    "lng": -97.57800213552602,
    "lat": 43.238652932687216
  },
  {
    "name": "Meno",
    "lng": -98.17796017893593,
    "lat": 36.388173480586126
  },
  {
    "name": "Menominee",
    "lng": -90.54289315917656,
    "lat": 42.47468583669968
  },
  {
    "name": "Menominee",
    "lng": -87.62339249559233,
    "lat": 45.12197254479918
  },
  {
    "name": "Menomonee Falls",
    "lng": -88.122717835469,
    "lat": 43.14887584269873
  },
  {
    "name": "Menomonie",
    "lng": -91.90872234302124,
    "lat": 44.88915033348224
  },
  {
    "name": "Mentone",
    "lng": -86.03816560008264,
    "lat": 41.174012747816064
  },
  {
    "name": "Mentone",
    "lng": -85.58513108930389,
    "lat": 34.56620416344079
  },
  {
    "name": "Mentor",
    "lng": -96.14407716344135,
    "lat": 47.6969729710341
  },
  {
    "name": "Mentor",
    "lng": -84.24591981657349,
    "lat": 38.88937284119169
  },
  {
    "name": "Mentor",
    "lng": -81.33615799706774,
    "lat": 41.689430103479175
  },
  {
    "name": "Mentor-on-the-Lake",
    "lng": -81.36488572730075,
    "lat": 41.713600595363786
  },
  {
    "name": "Mequon",
    "lng": -87.98318289803623,
    "lat": 43.235137002877174
  },
  {
    "name": "Mer Rouge",
    "lng": -91.79555922421589,
    "lat": 32.777087726089455
  },
  {
    "name": "Merced",
    "lng": -120.47753056388618,
    "lat": 37.30599284895902
  },
  {
    "name": "Mercedes",
    "lng": -97.91295965839593,
    "lat": 26.152699069589943
  },
  {
    "name": "Mercedes",
    "lng": -97.90520352477219,
    "lat": 26.19333502829062
  },
  {
    "name": "Mercer",
    "lng": -100.71051692553807,
    "lat": 47.49043237212764
  },
  {
    "name": "Mercer",
    "lng": -93.52990934243377,
    "lat": 40.510635379981714
  },
  {
    "name": "Mercer",
    "lng": -69.90951305483249,
    "lat": 44.66224699466899
  },
  {
    "name": "Mercer Island",
    "lng": -122.23213855545565,
    "lat": 47.567831317950535
  },
  {
    "name": "Meredosia",
    "lng": -90.55707599216622,
    "lat": 39.831097401985176
  },
  {
    "name": "Meriden",
    "lng": -95.63368337218894,
    "lat": 42.79438334082503
  },
  {
    "name": "Meriden",
    "lng": -95.56818530790942,
    "lat": 39.189814560296945
  },
  {
    "name": "Meriden",
    "lng": -72.79436183715323,
    "lat": 41.536766394240736
  },
  {
    "name": "Meridian",
    "lng": -116.45127795185248,
    "lat": 43.60768943699441
  },
  {
    "name": "Meridian",
    "lng": -116.39795874867367,
    "lat": 43.611500845739826
  },
  {
    "name": "Meridian",
    "lng": -116.362555064999,
    "lat": 43.63220214798141
  },
  {
    "name": "Meridian",
    "lng": -97.65024036473557,
    "lat": 31.925986060493518
  },
  {
    "name": "Meridian",
    "lng": -97.24570885637289,
    "lat": 35.844002784816254
  },
  {
    "name": "Meridian",
    "lng": -88.69735581842909,
    "lat": 32.377056203879874
  },
  {
    "name": "Meridian",
    "lng": -76.53818193103544,
    "lat": 43.16296372559563
  },
  {
    "name": "Meridian Hills",
    "lng": -86.15674182646512,
    "lat": 39.88743070419785
  },
  {
    "name": "Merigold",
    "lng": -90.72639252490916,
    "lat": 33.83914205322912
  },
  {
    "name": "Merino",
    "lng": -103.35363158479737,
    "lat": 40.484826473346345
  },
  {
    "name": "Merkel",
    "lng": -100.01273346934482,
    "lat": 32.46812558399166
  },
  {
    "name": "Mermentau",
    "lng": -92.58396794368903,
    "lat": 30.187416648890576
  },
  {
    "name": "Merna",
    "lng": -99.7610437946508,
    "lat": 41.48518645132528
  },
  {
    "name": "Merom",
    "lng": -87.56722019572892,
    "lat": 39.055964812695414
  },
  {
    "name": "Merriam",
    "lng": -94.69339645124174,
    "lat": 39.01863619999206
  },
  {
    "name": "Merriam Woods",
    "lng": -93.17081563971644,
    "lat": 36.7178044592848
  },
  {
    "name": "Merrill",
    "lng": -96.25047147279278,
    "lat": 42.720863211202115
  },
  {
    "name": "Merrill",
    "lng": -89.70041430955058,
    "lat": 45.18180078979274
  },
  {
    "name": "Merrill",
    "lng": -84.33457526598555,
    "lat": 43.409086317861835
  },
  {
    "name": "Merrill",
    "lng": -68.23505963818636,
    "lat": 46.16541556005376
  },
  {
    "name": "Merrillan",
    "lng": -90.83561069575826,
    "lat": 44.45064686674315
  },
  {
    "name": "Merrillville",
    "lng": -87.31968645932066,
    "lat": 41.47278040524937
  },
  {
    "name": "Merrimac",
    "lng": -89.64865343729808,
    "lat": 43.37926709154329
  },
  {
    "name": "Merrimac",
    "lng": -89.62697525156128,
    "lat": 43.374088470375746
  },
  {
    "name": "Merrimack",
    "lng": -71.51889904649792,
    "lat": 42.85477045499337
  },
  {
    "name": "Merriman",
    "lng": -101.69970329836757,
    "lat": 42.922985564942785
  },
  {
    "name": "Merrionette Park",
    "lng": -87.70123465399723,
    "lat": 41.681110202835484
  },
  {
    "name": "Merryville",
    "lng": -93.52456909896884,
    "lat": 30.75709144927772
  },
  {
    "name": "Mertens",
    "lng": -96.89372779765287,
    "lat": 32.05883325080344
  },
  {
    "name": "Merton",
    "lng": -88.30909484615988,
    "lat": 43.14217414807276
  },
  {
    "name": "Mertzon",
    "lng": -100.82080808067488,
    "lat": 31.261983245086242
  },
  {
    "name": "Merwin",
    "lng": -94.59079670095271,
    "lat": 38.404092972991236
  },
  {
    "name": "Mesa",
    "lng": -119.00099126419114,
    "lat": 46.57345145983598
  },
  {
    "name": "Meservey",
    "lng": -93.4732818189546,
    "lat": 42.91564829261349
  },
  {
    "name": "Mesic",
    "lng": -76.6449464034337,
    "lat": 35.203413408731706
  },
  {
    "name": "Mesick",
    "lng": -85.71710968516972,
    "lat": 44.40404226334939
  },
  {
    "name": "Mesilla",
    "lng": -106.8083663643629,
    "lat": 32.26944755134566
  },
  {
    "name": "Mesquite",
    "lng": -114.13308544589678,
    "lat": 36.80332168013145
  },
  {
    "name": "Meta",
    "lng": -92.16606874182419,
    "lat": 38.312719513716125
  },
  {
    "name": "Metaline",
    "lng": -117.38996640321211,
    "lat": 48.853846607160115
  },
  {
    "name": "Metaline Falls",
    "lng": -117.37058728506531,
    "lat": 48.861931024611785
  },
  {
    "name": "Metamora",
    "lng": -89.36884693567102,
    "lat": 40.79578797587142
  },
  {
    "name": "Metamora",
    "lng": -83.90539929312645,
    "lat": 41.71127561111727
  },
  {
    "name": "Metamora",
    "lng": -83.2911456364516,
    "lat": 42.94166701411168
  },
  {
    "name": "Metcalf",
    "lng": -87.8094379632361,
    "lat": 39.79975408794799
  },
  {
    "name": "Metcalfe",
    "lng": -91.00183096788176,
    "lat": 33.450590443219
  },
  {
    "name": "MetroWest",
    "lng": -81.47546995962348,
    "lat": 28.517252513191625
  },
  {
    "name": "Metropolis",
    "lng": -88.70834152792837,
    "lat": 37.156725607717135
  },
  {
    "name": "Mettawa",
    "lng": -87.92012510520016,
    "lat": 42.24503853836871
  },
  {
    "name": "Metter",
    "lng": -82.06186431932792,
    "lat": 32.395865636435246
  },
  {
    "name": "Metz",
    "lng": -94.44296482765733,
    "lat": 37.99649681999251
  },
  {
    "name": "Mexia",
    "lng": -96.6292345415079,
    "lat": 31.30698826357357
  },
  {
    "name": "Mexia",
    "lng": -96.4832927085928,
    "lat": 31.68086068181756
  },
  {
    "name": "Mexico",
    "lng": -91.87109122367121,
    "lat": 39.162652905747784
  },
  {
    "name": "Mexico",
    "lng": -77.35717040715483,
    "lat": 40.54340070740683
  },
  {
    "name": "Mexico",
    "lng": -76.23257420052201,
    "lat": 43.46521944588271
  },
  {
    "name": "Mexico",
    "lng": -70.51360130187673,
    "lat": 44.573477624251204
  },
  {
    "name": "Mexico Beach",
    "lng": -85.41590460617635,
    "lat": 29.94810275813477
  },
  {
    "name": "Meyers Lake",
    "lng": -81.41645765043187,
    "lat": 40.81589304770676
  },
  {
    "name": "Meyersdale",
    "lng": -79.02773919546517,
    "lat": 39.813340090553815
  },
  {
    "name": "Miami",
    "lng": -110.87231701935859,
    "lat": 33.39496098541885
  },
  {
    "name": "Miami",
    "lng": -100.64156825397029,
    "lat": 35.692310468291566
  },
  {
    "name": "Miami",
    "lng": -94.87221345867984,
    "lat": 36.8876422341871
  },
  {
    "name": "Miami",
    "lng": -94.8440737999346,
    "lat": 36.90366017572024
  },
  {
    "name": "Miami",
    "lng": -94.84064674673326,
    "lat": 36.87580088902908
  },
  {
    "name": "Miami",
    "lng": -93.22574525677851,
    "lat": 39.322641037499444
  },
  {
    "name": "Miami",
    "lng": -80.20981478743715,
    "lat": 25.775354139079404
  },
  {
    "name": "Miami Beach",
    "lng": -80.13666416290951,
    "lat": 25.815393370474254
  },
  {
    "name": "Miami Shores",
    "lng": -80.17774745107758,
    "lat": 25.866987341812703
  },
  {
    "name": "Miamisburg",
    "lng": -84.27246068058052,
    "lat": 39.632358400602236
  },
  {
    "name": "Michiana",
    "lng": -86.8112026698564,
    "lat": 41.76370993171279
  },
  {
    "name": "Michiana Shores",
    "lng": -86.81811799661172,
    "lat": 41.756318132492275
  },
  {
    "name": "Michie",
    "lng": -88.42571691828148,
    "lat": 35.06028538528017
  },
  {
    "name": "Michigan",
    "lng": -85.76485648466583,
    "lat": 44.953937177340585
  },
  {
    "name": "Michigan City",
    "lng": -98.1200087778971,
    "lat": 48.02459522029164
  },
  {
    "name": "Michigan City",
    "lng": -86.87076423105164,
    "lat": 41.70992066008601
  },
  {
    "name": "Michigan State Capitol",
    "lng": -84.55547210079631,
    "lat": 42.733607816006156
  },
  {
    "name": "Michigantown",
    "lng": -86.3916023502398,
    "lat": 40.3279560484486
  },
  {
    "name": "Micro",
    "lng": -78.20414398442406,
    "lat": 35.56273232621898
  },
  {
    "name": "Middle Point",
    "lng": -84.44624507026117,
    "lat": 40.85576367425163
  },
  {
    "name": "Middle River",
    "lng": -96.16169984619077,
    "lat": 48.43480565470734
  },
  {
    "name": "Middlebourne",
    "lng": -80.9070603695015,
    "lat": 39.494563978173986
  },
  {
    "name": "Middleburg",
    "lng": -78.32415226726421,
    "lat": 36.398163803937564
  },
  {
    "name": "Middleburg",
    "lng": -77.74287684861578,
    "lat": 38.97149728192541
  },
  {
    "name": "Middleburg Heights",
    "lng": -81.81509275472604,
    "lat": 41.369538004628104
  },
  {
    "name": "Middleburgh",
    "lng": -74.32973194584251,
    "lat": 42.59797574895543
  },
  {
    "name": "Middlebury",
    "lng": -85.70795317120145,
    "lat": 41.671099430399245
  },
  {
    "name": "Middlebury",
    "lng": -73.12284556545355,
    "lat": 41.52709939514314
  },
  {
    "name": "Middlefield",
    "lng": -81.07354379006094,
    "lat": 41.46040116191231
  },
  {
    "name": "Middlefield",
    "lng": -73.0157562455782,
    "lat": 42.351409122005606
  },
  {
    "name": "Middlefield",
    "lng": -72.71491471245672,
    "lat": 41.51756245274584
  },
  {
    "name": "Middleport",
    "lng": -82.06429592609604,
    "lat": 38.994848993424014
  },
  {
    "name": "Middleport",
    "lng": -78.47576738261809,
    "lat": 43.21169763588309
  },
  {
    "name": "Middlesborough",
    "lng": -83.72228439164647,
    "lat": 36.613292189103724
  },
  {
    "name": "Middlesex",
    "lng": -78.20352290414235,
    "lat": 35.78942884515405
  },
  {
    "name": "Middleton",
    "lng": -116.61569739583892,
    "lat": 43.711357526627346
  },
  {
    "name": "Middleton",
    "lng": -89.50646601916137,
    "lat": 43.10568444436733
  },
  {
    "name": "Middleton",
    "lng": -89.47730590873925,
    "lat": 43.14169699844354
  },
  {
    "name": "Middleton",
    "lng": -88.88972203322632,
    "lat": 35.062045848823864
  },
  {
    "name": "Middleton",
    "lng": -71.06927086151317,
    "lat": 43.48227029214804
  },
  {
    "name": "Middletown",
    "lng": -122.62835594144397,
    "lat": 38.755647315157276
  },
  {
    "name": "Middletown",
    "lng": -91.41384591804835,
    "lat": 39.12865545956902
  },
  {
    "name": "Middletown",
    "lng": -91.26203581749165,
    "lat": 40.82762385863408
  },
  {
    "name": "Middletown",
    "lng": -89.59121654242371,
    "lat": 40.100979510737425
  },
  {
    "name": "Middletown",
    "lng": -85.54215517261031,
    "lat": 40.05953033460754
  },
  {
    "name": "Middletown",
    "lng": -85.5221719489518,
    "lat": 38.24080697074433
  },
  {
    "name": "Middletown",
    "lng": -84.36675301295324,
    "lat": 39.503231814666485
  },
  {
    "name": "Middletown",
    "lng": -84.14452581097456,
    "lat": 42.98683863688045
  },
  {
    "name": "Middletown",
    "lng": -78.278119777744,
    "lat": 39.02884287296864
  },
  {
    "name": "Middletown",
    "lng": -77.53553879535305,
    "lat": 39.440314233940434
  },
  {
    "name": "Middletown",
    "lng": -74.42361809235965,
    "lat": 41.446052832785625
  },
  {
    "name": "Middletown",
    "lng": -72.65488999917922,
    "lat": 41.5476547293158
  },
  {
    "name": "Middletown",
    "lng": -71.27660968557723,
    "lat": 41.51719484656689
  },
  {
    "name": "Middleville",
    "lng": -85.46906975459129,
    "lat": 42.713389669763274
  },
  {
    "name": "Middleville",
    "lng": -74.97095660327896,
    "lat": 43.138437297980126
  },
  {
    "name": "Midfield",
    "lng": -86.92251546591471,
    "lat": 33.455238922012086
  },
  {
    "name": "Midland",
    "lng": -101.15542484702847,
    "lat": 44.07107023584905
  },
  {
    "name": "Midland",
    "lng": -94.35313137062437,
    "lat": 35.092732346853374
  },
  {
    "name": "Midland",
    "lng": -84.23176615942346,
    "lat": 43.62398861950544
  },
  {
    "name": "Midland",
    "lng": -83.91034788491196,
    "lat": 39.30672457229371
  },
  {
    "name": "Midland",
    "lng": -80.58539735335782,
    "lat": 35.24693855600277
  },
  {
    "name": "Midland",
    "lng": -80.56169054535177,
    "lat": 35.24732790178151
  },
  {
    "name": "Midland",
    "lng": -80.52645472282522,
    "lat": 35.30853639290298
  },
  {
    "name": "Midland",
    "lng": -80.5258855344601,
    "lat": 35.19728303500667
  },
  {
    "name": "Midland",
    "lng": -80.5220681329909,
    "lat": 35.24377891972831
  },
  {
    "name": "Midland",
    "lng": -80.51778897294038,
    "lat": 35.19454536253296
  },
  {
    "name": "Midland",
    "lng": -78.94865634751623,
    "lat": 39.589619398317616
  },
  {
    "name": "Midland City",
    "lng": -85.49358246079133,
    "lat": 31.315613526706386
  },
  {
    "name": "Midlothian",
    "lng": -96.98918206713442,
    "lat": 32.46449619491608
  },
  {
    "name": "Midlothian",
    "lng": -96.96069611971683,
    "lat": 32.454273423602366
  },
  {
    "name": "Midlothian",
    "lng": -96.9602539473996,
    "lat": 32.45555608913693
  },
  {
    "name": "Midlothian",
    "lng": -96.95894234120205,
    "lat": 32.45675166387548
  },
  {
    "name": "Midlothian",
    "lng": -96.95260341414117,
    "lat": 32.454945045254604
  },
  {
    "name": "Midlothian",
    "lng": -87.72440676085502,
    "lat": 41.625405177659445
  },
  {
    "name": "Midvale",
    "lng": -116.75790858072838,
    "lat": 44.46080091103345
  },
  {
    "name": "Midvale",
    "lng": -116.74678200660735,
    "lat": 44.48475018220253
  },
  {
    "name": "Midvale",
    "lng": -116.74212429120595,
    "lat": 44.45949457985386
  },
  {
    "name": "Midvale",
    "lng": -111.89289758915703,
    "lat": 40.61468119710983
  },
  {
    "name": "Midvale",
    "lng": -81.3719957691368,
    "lat": 40.43654536102211
  },
  {
    "name": "Midville",
    "lng": -82.2346099672796,
    "lat": 32.821751974831834
  },
  {
    "name": "Midway",
    "lng": -111.47446552967126,
    "lat": 40.518281521490785
  },
  {
    "name": "Midway",
    "lng": -95.75384293583443,
    "lat": 31.025172231386545
  },
  {
    "name": "Midway",
    "lng": -92.9610370660062,
    "lat": 34.26020798090998
  },
  {
    "name": "Midway",
    "lng": -85.52048031406851,
    "lat": 32.07634168838096
  },
  {
    "name": "Midway",
    "lng": -84.67827864128951,
    "lat": 38.15423802813928
  },
  {
    "name": "Midway",
    "lng": -84.46491251537341,
    "lat": 30.50169116926807
  },
  {
    "name": "Midway",
    "lng": -84.41530887432636,
    "lat": 30.48205787698729
  },
  {
    "name": "Midway",
    "lng": -83.4764778644465,
    "lat": 39.73286557692589
  },
  {
    "name": "Midway",
    "lng": -81.41913458246331,
    "lat": 31.80040810490064
  },
  {
    "name": "Midway",
    "lng": -80.21995971407148,
    "lat": 35.97497514524904
  },
  {
    "name": "Midway",
    "lng": -77.00522364606144,
    "lat": 39.80393683850732
  },
  {
    "name": "Midwest",
    "lng": -106.27702031064683,
    "lat": 43.411032315797286
  },
  {
    "name": "Midwest City",
    "lng": -97.3714758184074,
    "lat": 35.46328408590205
  },
  {
    "name": "Miesville",
    "lng": -92.8195917906102,
    "lat": 44.59901174548438
  },
  {
    "name": "Mifflin",
    "lng": -82.36377985199675,
    "lat": 40.77356308871732
  },
  {
    "name": "Mifflinville",
    "lng": -76.29958025841171,
    "lat": 41.030686007236426
  },
  {
    "name": "Milaca",
    "lng": -93.65293675349828,
    "lat": 45.754968848613736
  },
  {
    "name": "Milaca",
    "lng": -93.63202558195412,
    "lat": 45.76637994756037
  },
  {
    "name": "Milaca",
    "lng": -93.6313525185693,
    "lat": 45.75449597893843
  },
  {
    "name": "Milan",
    "lng": -107.89264177533114,
    "lat": 35.188520403957476
  },
  {
    "name": "Milan",
    "lng": -97.6739266361637,
    "lat": 37.25751461060109
  },
  {
    "name": "Milan",
    "lng": -95.91391789035953,
    "lat": 45.11131350274167
  },
  {
    "name": "Milan",
    "lng": -93.12394210535143,
    "lat": 40.20304327636816
  },
  {
    "name": "Milan",
    "lng": -90.55958484682523,
    "lat": 41.44157368175421
  },
  {
    "name": "Milan",
    "lng": -90.5257204331461,
    "lat": 41.45477751479296
  },
  {
    "name": "Milan",
    "lng": -88.7555872319029,
    "lat": 35.91273924474111
  },
  {
    "name": "Milan",
    "lng": -88.74371962040182,
    "lat": 35.87290643281075
  },
  {
    "name": "Milan",
    "lng": -85.12734540795525,
    "lat": 39.125860264176126
  },
  {
    "name": "Milan",
    "lng": -83.68469445870225,
    "lat": 42.081992167588936
  },
  {
    "name": "Milan",
    "lng": -83.06307233365254,
    "lat": 32.01948093400396
  },
  {
    "name": "Milan",
    "lng": -82.59941903252712,
    "lat": 41.28813155383135
  },
  {
    "name": "Milano",
    "lng": -96.8591951717211,
    "lat": 30.707828398706656
  },
  {
    "name": "Milbank",
    "lng": -96.63443443786124,
    "lat": 45.21919352763622
  },
  {
    "name": "Milbridge",
    "lng": -67.85893728696358,
    "lat": 44.45108993031084
  },
  {
    "name": "Milburn",
    "lng": -96.55202368209895,
    "lat": 34.24006957732205
  },
  {
    "name": "Mildred",
    "lng": -96.34945361077531,
    "lat": 32.04084490003236
  },
  {
    "name": "Mildred",
    "lng": -95.17469910126371,
    "lat": 38.02449982200211
  },
  {
    "name": "Miles",
    "lng": -90.31452280527164,
    "lat": 42.04814846819458
  },
  {
    "name": "Miles City",
    "lng": -105.83894480651526,
    "lat": 46.405921242699286
  },
  {
    "name": "Milford",
    "lng": -113.01207393349686,
    "lat": 38.394458992520136
  },
  {
    "name": "Milford",
    "lng": -97.05296465327285,
    "lat": 40.772561766567904
  },
  {
    "name": "Milford",
    "lng": -96.94964970761494,
    "lat": 32.12103214258452
  },
  {
    "name": "Milford",
    "lng": -96.9094321166875,
    "lat": 39.174524201328886
  },
  {
    "name": "Milford",
    "lng": -95.14764805933514,
    "lat": 43.333301240826685
  },
  {
    "name": "Milford",
    "lng": -95.14374459173048,
    "lat": 43.34779233880216
  },
  {
    "name": "Milford",
    "lng": -94.15817313428275,
    "lat": 37.5862675398233
  },
  {
    "name": "Milford",
    "lng": -87.69635869368435,
    "lat": 40.627702054347445
  },
  {
    "name": "Milford",
    "lng": -87.69488152719826,
    "lat": 40.61910471231245
  },
  {
    "name": "Milford",
    "lng": -85.85556822671869,
    "lat": 41.41271767433517
  },
  {
    "name": "Milford",
    "lng": -85.84760209366993,
    "lat": 41.41113329646838
  },
  {
    "name": "Milford",
    "lng": -84.2812395430388,
    "lat": 39.16978864454271
  },
  {
    "name": "Milford",
    "lng": -83.60103024826537,
    "lat": 42.58699253587866
  },
  {
    "name": "Milford",
    "lng": -74.94723359589199,
    "lat": 42.590740210352024
  },
  {
    "name": "Milford",
    "lng": -73.05895599481619,
    "lat": 41.22380310740728
  },
  {
    "name": "Milford",
    "lng": -71.6737503599398,
    "lat": 42.8179826565543
  },
  {
    "name": "Milford",
    "lng": -71.5187938592378,
    "lat": 42.15633694934904
  },
  {
    "name": "Milford",
    "lng": -68.5729753451003,
    "lat": 44.9807376619238
  },
  {
    "name": "Milford Center",
    "lng": -83.43808831502564,
    "lat": 40.179080313080895
  },
  {
    "name": "Mill Creek",
    "lng": -122.20354163919583,
    "lat": 47.86317337185782
  },
  {
    "name": "Mill Creek",
    "lng": -96.82510866506598,
    "lat": 34.402026614731085
  },
  {
    "name": "Mill Creek",
    "lng": -89.25348749444427,
    "lat": 37.341059949002265
  },
  {
    "name": "Mill Creek",
    "lng": -79.9724917064364,
    "lat": 38.73194142710773
  },
  {
    "name": "Mill Creek",
    "lng": -77.93083475110348,
    "lat": 40.437155335324
  },
  {
    "name": "Mill Neck",
    "lng": -73.55544084857291,
    "lat": 40.88574662281104
  },
  {
    "name": "Mill Shoals",
    "lng": -88.34555918945381,
    "lat": 38.24944632791921
  },
  {
    "name": "Mill Spring",
    "lng": -90.68105948418005,
    "lat": 37.06505040030032
  },
  {
    "name": "Mill Valley",
    "lng": -122.54222493174802,
    "lat": 37.90862205584734
  },
  {
    "name": "Milladore",
    "lng": -89.85483456015724,
    "lat": 44.605719486211505
  },
  {
    "name": "Millard",
    "lng": -92.54596237228998,
    "lat": 40.107490435411705
  },
  {
    "name": "Millbrook",
    "lng": -88.5626417400634,
    "lat": 41.614125328907264
  },
  {
    "name": "Millbrook",
    "lng": -88.55016302240118,
    "lat": 41.6149910780652
  },
  {
    "name": "Millbrook",
    "lng": -88.54866967840339,
    "lat": 41.59452563001715
  },
  {
    "name": "Millbrook",
    "lng": -88.54375483912052,
    "lat": 41.611718210092036
  },
  {
    "name": "Millbrook",
    "lng": -86.41519692140301,
    "lat": 32.50489269551071
  },
  {
    "name": "Millbrook",
    "lng": -86.41332567401192,
    "lat": 32.50118252926413
  },
  {
    "name": "Millbrook",
    "lng": -86.39986114247287,
    "lat": 32.49433903634781
  },
  {
    "name": "Millbrook",
    "lng": -86.38826438587071,
    "lat": 32.5453540107044
  },
  {
    "name": "Millbrook",
    "lng": -86.3878178273942,
    "lat": 32.50298664996494
  },
  {
    "name": "Millbrook",
    "lng": -86.38550124805475,
    "lat": 32.506814808545684
  },
  {
    "name": "Millbrook",
    "lng": -86.38503661246214,
    "lat": 32.505624810066564
  },
  {
    "name": "Millbrook",
    "lng": -86.37268971732462,
    "lat": 32.5491684158308
  },
  {
    "name": "Millbrook",
    "lng": -86.36905273614221,
    "lat": 32.503827300198864
  },
  {
    "name": "Millbrook",
    "lng": -86.36571462887963,
    "lat": 32.55286195293552
  },
  {
    "name": "Millbrook",
    "lng": -86.35095176335412,
    "lat": 32.48918479108305
  },
  {
    "name": "Millbrook",
    "lng": -86.34076052325456,
    "lat": 32.47891340661933
  },
  {
    "name": "Millbrook",
    "lng": -73.6936386399331,
    "lat": 41.78406401626776
  },
  {
    "name": "Millbury",
    "lng": -83.42552959894013,
    "lat": 41.56470259327904
  },
  {
    "name": "Millbury",
    "lng": -71.77407493265852,
    "lat": 42.19245922981836
  },
  {
    "name": "Millcreek",
    "lng": -111.82937669266482,
    "lat": 40.68918248948814
  },
  {
    "name": "Milledgeville",
    "lng": -89.77522319648548,
    "lat": 41.96354387366021
  },
  {
    "name": "Milledgeville",
    "lng": -88.3661803271672,
    "lat": 35.3778544182915
  },
  {
    "name": "Milledgeville",
    "lng": -83.58747011147047,
    "lat": 39.59390114203298
  },
  {
    "name": "Milledgeville",
    "lng": -83.24142997117562,
    "lat": 33.08738531069415
  },
  {
    "name": "Millen",
    "lng": -81.94233003243944,
    "lat": 32.8070540312185
  },
  {
    "name": "Miller",
    "lng": -99.38996355190444,
    "lat": 40.927605932424136
  },
  {
    "name": "Miller",
    "lng": -98.98691934300646,
    "lat": 44.520473484864176
  },
  {
    "name": "Miller",
    "lng": -93.84058321550124,
    "lat": 37.214792306620964
  },
  {
    "name": "Miller City",
    "lng": -84.13248508204983,
    "lat": 41.102128152493876
  },
  {
    "name": "Miller Place",
    "lng": -72.970880475105,
    "lat": 40.950918652974444
  },
  {
    "name": "Miller's Cove",
    "lng": -95.11479424068274,
    "lat": 33.15590787027083
  },
  {
    "name": "Millersburg",
    "lng": -92.15925788847058,
    "lat": 41.57316539911062
  },
  {
    "name": "Millersburg",
    "lng": -85.69629541917259,
    "lat": 41.52639281466084
  },
  {
    "name": "Millersburg",
    "lng": -84.14483818402114,
    "lat": 38.30408808052936
  },
  {
    "name": "Millersburg",
    "lng": -84.06005389427821,
    "lat": 45.33433017125643
  },
  {
    "name": "Millersburg",
    "lng": -81.91410695548045,
    "lat": 40.55271171052164
  },
  {
    "name": "Millersburg",
    "lng": -76.95537290632059,
    "lat": 40.542552711207286
  },
  {
    "name": "Millersport",
    "lng": -82.53947003821479,
    "lat": 39.89808216971993
  },
  {
    "name": "Millersville",
    "lng": -86.7109016488966,
    "lat": 36.396524520829466
  },
  {
    "name": "Millersville",
    "lng": -76.3523747354701,
    "lat": 40.004600888204166
  },
  {
    "name": "Millerton",
    "lng": -95.03794565388165,
    "lat": 33.99428549292924
  },
  {
    "name": "Millerton",
    "lng": -95.01763107318949,
    "lat": 33.983157008190204
  },
  {
    "name": "Millerton",
    "lng": -93.30691189807315,
    "lat": 40.84939146856455
  },
  {
    "name": "Millerton",
    "lng": -73.51159831268652,
    "lat": 41.95321414905835
  },
  {
    "name": "Millerville",
    "lng": -95.55629421045096,
    "lat": 46.06917412965437
  },
  {
    "name": "Millhousen",
    "lng": -85.43161148903835,
    "lat": 39.212093379727875
  },
  {
    "name": "Millican",
    "lng": -96.20350536914681,
    "lat": 30.467001903071825
  },
  {
    "name": "Milligan",
    "lng": -97.38834138514025,
    "lat": 40.500184086886364
  },
  {
    "name": "Milliken",
    "lng": -104.8818138160774,
    "lat": 40.30237289035655
  },
  {
    "name": "Milliken",
    "lng": -104.8644544404701,
    "lat": 40.353065929680334
  },
  {
    "name": "Milliken",
    "lng": -104.85516453206245,
    "lat": 40.30438227105831
  },
  {
    "name": "Millington",
    "lng": -89.89060854738204,
    "lat": 35.33742365704911
  },
  {
    "name": "Millington",
    "lng": -88.60118154305242,
    "lat": 41.56189286038825
  },
  {
    "name": "Millington",
    "lng": -83.52732664181825,
    "lat": 43.278607279688096
  },
  {
    "name": "Millington",
    "lng": -75.83495888943477,
    "lat": 39.264254426076846
  },
  {
    "name": "Millinocket",
    "lng": -68.68513593903752,
    "lat": 45.65209349779694
  },
  {
    "name": "Millport",
    "lng": -88.08194798300323,
    "lat": 33.558496525162724
  },
  {
    "name": "Millport",
    "lng": -76.83624440299398,
    "lat": 42.26809917567352
  },
  {
    "name": "Millry",
    "lng": -88.31901541456693,
    "lat": 31.62561484863377
  },
  {
    "name": "Mills",
    "lng": -106.38191651790353,
    "lat": 42.84730932348568
  },
  {
    "name": "Mills River",
    "lng": -82.58653611598851,
    "lat": 35.384020591203196
  },
  {
    "name": "Millsap",
    "lng": -98.00912369543798,
    "lat": 32.74863341062493
  },
  {
    "name": "Millstadt",
    "lng": -90.08381264668307,
    "lat": 38.459431077360186
  },
  {
    "name": "Milltown",
    "lng": -92.50128447889003,
    "lat": 45.526434350853364
  },
  {
    "name": "Milltown",
    "lng": -86.27490471365373,
    "lat": 38.342457939511284
  },
  {
    "name": "Millville",
    "lng": -122.16955415940296,
    "lat": 40.55755283236478
  },
  {
    "name": "Millville",
    "lng": -111.82114774543781,
    "lat": 41.685046924156964
  },
  {
    "name": "Millville",
    "lng": -92.29602865792464,
    "lat": 44.24445999044902
  },
  {
    "name": "Millville",
    "lng": -91.0763877434796,
    "lat": 42.70363986328447
  },
  {
    "name": "Millville",
    "lng": -84.65290104603608,
    "lat": 39.39237825549285
  },
  {
    "name": "Millville",
    "lng": -75.05616921386516,
    "lat": 39.39031800975869
  },
  {
    "name": "Millwood",
    "lng": -117.28062599598326,
    "lat": 47.685571491083685
  },
  {
    "name": "Milner",
    "lng": -84.19261010421802,
    "lat": 33.11768806902823
  },
  {
    "name": "Milnor",
    "lng": -97.45733152513658,
    "lat": 46.260941768157934
  },
  {
    "name": "Milo",
    "lng": -94.30522287433175,
    "lat": 37.755416622910055
  },
  {
    "name": "Milo",
    "lng": -93.44046663410975,
    "lat": 41.28926688697418
  },
  {
    "name": "Milo",
    "lng": -68.98326893732644,
    "lat": 45.261463186309776
  },
  {
    "name": "Milpitas",
    "lng": -121.89209704112294,
    "lat": 37.43381802586318
  },
  {
    "name": "Milroy",
    "lng": -95.55355751405968,
    "lat": 44.41777467068302
  },
  {
    "name": "Milroy",
    "lng": -77.58787535570463,
    "lat": 40.71535836410606
  },
  {
    "name": "Milton",
    "lng": -122.31428965653261,
    "lat": 47.25159936214439
  },
  {
    "name": "Milton",
    "lng": -98.0443407325759,
    "lat": 48.62733057621486
  },
  {
    "name": "Milton",
    "lng": -92.16194423612217,
    "lat": 40.67156821163579
  },
  {
    "name": "Milton",
    "lng": -90.65008261189283,
    "lat": 39.564548759826195
  },
  {
    "name": "Milton",
    "lng": -88.94299572860287,
    "lat": 42.77499477924242
  },
  {
    "name": "Milton",
    "lng": -87.05356866799434,
    "lat": 30.628183192532163
  },
  {
    "name": "Milton",
    "lng": -85.37487824403148,
    "lat": 38.713597379628894
  },
  {
    "name": "Milton",
    "lng": -85.15617533741646,
    "lat": 39.786426014915484
  },
  {
    "name": "Milton",
    "lng": -84.31366562677623,
    "lat": 34.13528017055954
  },
  {
    "name": "Milton",
    "lng": -82.13733758649856,
    "lat": 38.4353371815178
  },
  {
    "name": "Milton",
    "lng": -79.20804333122396,
    "lat": 36.53720377236281
  },
  {
    "name": "Milton",
    "lng": -76.85085247786786,
    "lat": 41.008714071018176
  },
  {
    "name": "Milton",
    "lng": -73.11309402539368,
    "lat": 44.63626992627583
  },
  {
    "name": "Milton",
    "lng": -71.00733157173583,
    "lat": 43.44955570029398
  },
  {
    "name": "Milton Center",
    "lng": -83.82966104093131,
    "lat": 41.30073953080505
  },
  {
    "name": "Miltona",
    "lng": -95.29285110035632,
    "lat": 46.045829259109325
  },
  {
    "name": "Miltonsburg",
    "lng": -81.16450357181239,
    "lat": 39.83128987638761
  },
  {
    "name": "Miltonvale",
    "lng": -97.45308944596907,
    "lat": 39.35006986084651
  },
  {
    "name": "Miltonvale",
    "lng": -97.44229824055004,
    "lat": 39.341300062110804
  },
  {
    "name": "Milwaukee",
    "lng": -88.06482423396295,
    "lat": 43.19379999402691
  },
  {
    "name": "Milwaukee",
    "lng": -87.96486572361154,
    "lat": 43.063130132150384
  },
  {
    "name": "Minatare",
    "lng": -103.50204538102005,
    "lat": 41.810946974632685
  },
  {
    "name": "Minburn",
    "lng": -94.02839656704232,
    "lat": 41.7575311223626
  },
  {
    "name": "Minco",
    "lng": -97.95137925659247,
    "lat": 35.31860333585051
  },
  {
    "name": "Minco",
    "lng": -97.9496843016119,
    "lat": 35.33791760864953
  },
  {
    "name": "Minden",
    "lng": -99.0742253662932,
    "lat": 40.48334801078498
  },
  {
    "name": "Minden",
    "lng": -98.9518674063219,
    "lat": 40.50014888519474
  },
  {
    "name": "Minden",
    "lng": -98.93025095207977,
    "lat": 40.50548039509271
  },
  {
    "name": "Minden",
    "lng": -95.54113832130338,
    "lat": 41.46889422964018
  },
  {
    "name": "Minden",
    "lng": -93.27620296031661,
    "lat": 32.618674869611276
  },
  {
    "name": "Minden City",
    "lng": -82.77572803012106,
    "lat": 43.67146461140844
  },
  {
    "name": "Mindenmines",
    "lng": -94.58883581898442,
    "lat": 37.47284340546349
  },
  {
    "name": "Mineola",
    "lng": -95.48749324362126,
    "lat": 32.66655531150478
  },
  {
    "name": "Mineola",
    "lng": -95.46462352993487,
    "lat": 32.62246487971855
  },
  {
    "name": "Mineola",
    "lng": -73.63910761049827,
    "lat": 40.74703960682055
  },
  {
    "name": "Miner",
    "lng": -89.53536882011822,
    "lat": 36.896621533586696
  },
  {
    "name": "Miner",
    "lng": -89.51706064881455,
    "lat": 36.902193719110194
  },
  {
    "name": "Mineral",
    "lng": -89.83996192742032,
    "lat": 41.39162393814982
  },
  {
    "name": "Mineral",
    "lng": -89.83805325101709,
    "lat": 41.38255642594692
  },
  {
    "name": "Mineral",
    "lng": -77.90600198169442,
    "lat": 38.006682424285316
  },
  {
    "name": "Mineral City",
    "lng": -81.36121586777898,
    "lat": 40.60172478589389
  },
  {
    "name": "Mineral City",
    "lng": -81.34943593496746,
    "lat": 40.6030237794935
  },
  {
    "name": "Mineral Point",
    "lng": -90.7248425645301,
    "lat": 37.94561501283564
  },
  {
    "name": "Mineral Point",
    "lng": -90.18364104190363,
    "lat": 42.862273589061196
  },
  {
    "name": "Mineral Springs",
    "lng": -93.92210824075177,
    "lat": 33.87628139117707
  },
  {
    "name": "Mineral Springs",
    "lng": -80.68516032715176,
    "lat": 34.9592509764625
  },
  {
    "name": "Mineral Springs",
    "lng": -80.6843805514698,
    "lat": 34.94110106155243
  },
  {
    "name": "Mineral Wells",
    "lng": -98.07764163409959,
    "lat": 32.81692502366526
  },
  {
    "name": "Minersville",
    "lng": -112.96062995719363,
    "lat": 38.233513119207366
  },
  {
    "name": "Minerva",
    "lng": -81.10235712483316,
    "lat": 40.730755341533715
  },
  {
    "name": "Minerva Park",
    "lng": -82.94084467188769,
    "lat": 40.07700286748602
  },
  {
    "name": "Minford",
    "lng": -82.85514897357238,
    "lat": 38.86163905882444
  },
  {
    "name": "Mingo",
    "lng": -93.2828327838794,
    "lat": 41.76599698163643
  },
  {
    "name": "Mingo",
    "lng": -93.27279178369612,
    "lat": 41.76736058745218
  },
  {
    "name": "Mingo Junction",
    "lng": -80.61816988657922,
    "lat": 40.32531744173136
  },
  {
    "name": "Minidoka",
    "lng": -113.48995547859433,
    "lat": 42.753970147457245
  },
  {
    "name": "Minneapolis",
    "lng": -97.7228796681772,
    "lat": 39.130290489549324
  },
  {
    "name": "Minneapolis",
    "lng": -97.7022187416631,
    "lat": 39.12438744199916
  },
  {
    "name": "Minneapolis",
    "lng": -93.26783040633634,
    "lat": 44.963619572165534
  },
  {
    "name": "Minneiska",
    "lng": -91.87468226873249,
    "lat": 44.196944458373274
  },
  {
    "name": "Minneola",
    "lng": -100.01307783032392,
    "lat": 37.442018366187206
  },
  {
    "name": "Minneola",
    "lng": -81.73607003470133,
    "lat": 28.580421379617842
  },
  {
    "name": "Minneola",
    "lng": -81.73216010670474,
    "lat": 28.606709085120322
  },
  {
    "name": "Minneota",
    "lng": -95.98258287022419,
    "lat": 44.56255013382125
  },
  {
    "name": "Minnesota",
    "lng": -94.19880753888076,
    "lat": 46.416643955537204
  },
  {
    "name": "Minnesota City",
    "lng": -91.75000255783038,
    "lat": 44.092286444550346
  },
  {
    "name": "Minnesota Lake",
    "lng": -93.82791719907442,
    "lat": 43.840847616225865
  },
  {
    "name": "Minnesott Beach",
    "lng": -76.82363730072358,
    "lat": 34.98594783101856
  },
  {
    "name": "Minnetonka",
    "lng": -93.46015878202145,
    "lat": 44.93239255800227
  },
  {
    "name": "Minnetonka Beach",
    "lng": -93.58914792807604,
    "lat": 44.939449150608276
  },
  {
    "name": "Minnetrista",
    "lng": -93.710626552363,
    "lat": 44.93561140852674
  },
  {
    "name": "Minnewaukan",
    "lng": -99.25034916731911,
    "lat": 48.07022383242867
  },
  {
    "name": "Minoa",
    "lng": -76.00844154259113,
    "lat": 43.074681806043024
  },
  {
    "name": "Minong",
    "lng": -91.82621280531762,
    "lat": 46.09798635398467
  },
  {
    "name": "Minonk",
    "lng": -89.03815766044997,
    "lat": 40.910372367757816
  },
  {
    "name": "Minooka",
    "lng": -88.31846567986939,
    "lat": 41.44050847059925
  },
  {
    "name": "Minooka",
    "lng": -88.27866611827754,
    "lat": 41.446920974009004
  },
  {
    "name": "Minooka",
    "lng": -88.26626467836279,
    "lat": 41.47980408968624
  },
  {
    "name": "Minooka",
    "lng": -88.25335673333332,
    "lat": 41.47453904682712
  },
  {
    "name": "Minor Hill",
    "lng": -87.16939822438013,
    "lat": 35.035250007065265
  },
  {
    "name": "Minot",
    "lng": -101.29417802129396,
    "lat": 48.23414498422835
  },
  {
    "name": "Minot",
    "lng": -70.33796819897209,
    "lat": 44.148322264962225
  },
  {
    "name": "Minster",
    "lng": -84.37892070480073,
    "lat": 40.39586385764099
  },
  {
    "name": "Mint Hill",
    "lng": -80.65465709331761,
    "lat": 35.17794328127687
  },
  {
    "name": "Minto",
    "lng": -97.37301582244001,
    "lat": 48.29239409165015
  },
  {
    "name": "Minturn",
    "lng": -106.42725510244816,
    "lat": 39.588307229389706
  },
  {
    "name": "Minturn",
    "lng": -91.02723959203684,
    "lat": 35.976301793976035
  },
  {
    "name": "Miramiguoa Park",
    "lng": -91.06875643045363,
    "lat": 38.23894053384937
  },
  {
    "name": "Misenheimer",
    "lng": -80.28670220530962,
    "lat": 35.48145413740748
  },
  {
    "name": "Mishawaka",
    "lng": -86.16732774801112,
    "lat": 41.67072301603726
  },
  {
    "name": "Mishicot",
    "lng": -87.64264142765222,
    "lat": 44.23018700763892
  },
  {
    "name": "Mission",
    "lng": -100.66078002263247,
    "lat": 43.30623513544433
  },
  {
    "name": "Mission",
    "lng": -94.65672776470302,
    "lat": 39.026847675157114
  },
  {
    "name": "Mission Canyon",
    "lng": -119.71257499631324,
    "lat": 34.45337042570495
  },
  {
    "name": "Mission Hill",
    "lng": -97.27904089215258,
    "lat": 42.920756886649365
  },
  {
    "name": "Mission Hills",
    "lng": -94.6171143209349,
    "lat": 39.01392112837271
  },
  {
    "name": "Mission Viejo",
    "lng": -117.65510129068137,
    "lat": 33.60956384084999
  },
  {
    "name": "Mission Woods",
    "lng": -94.61089826413976,
    "lat": 39.033749098059
  },
  {
    "name": "Mississippi",
    "lng": -89.65212887276545,
    "lat": 32.74054288796117
  },
  {
    "name": "Missoula",
    "lng": -114.0991940004918,
    "lat": 46.89125255636357
  },
  {
    "name": "Missoula",
    "lng": -114.09554245191137,
    "lat": 46.889770878051316
  },
  {
    "name": "Missoula",
    "lng": -114.0880529931168,
    "lat": 46.882053080134966
  },
  {
    "name": "Missoula",
    "lng": -114.08790330202174,
    "lat": 46.88751030241903
  },
  {
    "name": "Missoula",
    "lng": -114.08510298240863,
    "lat": 46.885089892085354
  },
  {
    "name": "Missoula",
    "lng": -114.08230348028027,
    "lat": 46.88764900904204
  },
  {
    "name": "Missoula",
    "lng": -114.06501077623919,
    "lat": 46.87147364168105
  },
  {
    "name": "Missoula",
    "lng": -114.05801836886265,
    "lat": 46.873545907720455
  },
  {
    "name": "Missoula",
    "lng": -114.05636360541985,
    "lat": 46.87204388887082
  },
  {
    "name": "Missoula",
    "lng": -114.04605477827148,
    "lat": 46.869675240458854
  },
  {
    "name": "Missoula",
    "lng": -114.0231364703225,
    "lat": 46.874824729897774
  },
  {
    "name": "Missoula",
    "lng": -114.00000322380592,
    "lat": 46.903677969812406
  },
  {
    "name": "Missoula",
    "lng": -113.93527407386189,
    "lat": 46.88003668837198
  },
  {
    "name": "Missoula",
    "lng": -113.9230195160041,
    "lat": 46.88016948275484
  },
  {
    "name": "Missoula",
    "lng": -113.9053755174095,
    "lat": 46.87543109042729
  },
  {
    "name": "Missouri",
    "lng": -92.48714720842791,
    "lat": 38.39807982463879
  },
  {
    "name": "Missouri City",
    "lng": -95.53754851702323,
    "lat": 29.633075762764005
  },
  {
    "name": "Missouri City",
    "lng": -95.53730668370711,
    "lat": 29.563634213160473
  },
  {
    "name": "Missouri City",
    "lng": -94.300538609519,
    "lat": 39.238713240688355
  },
  {
    "name": "Missouri Valley",
    "lng": -95.92368069461554,
    "lat": 41.55300466112612
  },
  {
    "name": "Missouri Valley",
    "lng": -95.92261239311823,
    "lat": 41.54708273086445
  },
  {
    "name": "Missouri Valley",
    "lng": -95.89950373572424,
    "lat": 41.5607097088945
  },
  {
    "name": "Mitchell",
    "lng": -103.80983864845471,
    "lat": 41.94266977497432
  },
  {
    "name": "Mitchell",
    "lng": -98.03346679140638,
    "lat": 43.729462191199914
  },
  {
    "name": "Mitchell",
    "lng": -97.98825440214053,
    "lat": 43.70799256049777
  },
  {
    "name": "Mitchell",
    "lng": -92.87141344330415,
    "lat": 43.320252352983786
  },
  {
    "name": "Mitchell",
    "lng": -86.47462506081736,
    "lat": 38.73633792816064
  },
  {
    "name": "Mitchell",
    "lng": -82.70334884219805,
    "lat": 33.21980107020758
  },
  {
    "name": "Mitchell Heights",
    "lng": -81.98659779592614,
    "lat": 37.90869680002702
  },
  {
    "name": "Mitchellville",
    "lng": -93.36514270453897,
    "lat": 41.66819778348193
  },
  {
    "name": "Mitchellville",
    "lng": -91.49839016120195,
    "lat": 33.905222656398884
  },
  {
    "name": "Mitchellville",
    "lng": -86.54001791004787,
    "lat": 36.634036502910334
  },
  {
    "name": "Mize",
    "lng": -89.55409001651745,
    "lat": 31.867193971717256
  },
  {
    "name": "Mizpah",
    "lng": -94.21321061626958,
    "lat": 47.92086058864589
  },
  {
    "name": "Moab",
    "lng": -109.54785924660371,
    "lat": 38.57012446973231
  },
  {
    "name": "Mobeetie",
    "lng": -100.43934181494478,
    "lat": 35.52913697331656
  },
  {
    "name": "Moberly",
    "lng": -92.43645260079259,
    "lat": 39.41727881631911
  },
  {
    "name": "Mobile",
    "lng": -88.0896744753917,
    "lat": 30.674191384842295
  },
  {
    "name": "Mobile City",
    "lng": -96.41115691139245,
    "lat": 32.922862006390275
  },
  {
    "name": "Mobridge",
    "lng": -100.43638678010754,
    "lat": 45.54107176163775
  },
  {
    "name": "Mockingbird Valley",
    "lng": -85.68152222314497,
    "lat": 38.27015108675727
  },
  {
    "name": "Mocksville",
    "lng": -80.60590878771546,
    "lat": 35.93874371626958
  },
  {
    "name": "Mocksville",
    "lng": -80.60405011617846,
    "lat": 35.928565237688986
  },
  {
    "name": "Mocksville",
    "lng": -80.60208435921007,
    "lat": 35.93468603169484
  },
  {
    "name": "Mocksville",
    "lng": -80.6017055772708,
    "lat": 35.919939510513686
  },
  {
    "name": "Mocksville",
    "lng": -80.59001743594956,
    "lat": 35.928210290661994
  },
  {
    "name": "Mocksville",
    "lng": -80.5898349130953,
    "lat": 35.92094726273914
  },
  {
    "name": "Mocksville",
    "lng": -80.58513989162279,
    "lat": 35.9202926200905
  },
  {
    "name": "Mocksville",
    "lng": -80.55803334652576,
    "lat": 35.876340385835505
  },
  {
    "name": "Mocksville",
    "lng": -80.55609150219908,
    "lat": 35.89711149113506
  },
  {
    "name": "Mocksville",
    "lng": -80.55583307966364,
    "lat": 35.869751527977996
  },
  {
    "name": "Mocksville",
    "lng": -80.55313374968598,
    "lat": 35.880276735313984
  },
  {
    "name": "Mocksville",
    "lng": -80.55188202885981,
    "lat": 35.87055386653647
  },
  {
    "name": "Modale",
    "lng": -96.01209207286173,
    "lat": 41.618170041319836
  },
  {
    "name": "Modesto",
    "lng": -121.0844441772876,
    "lat": 37.522943406488096
  },
  {
    "name": "Modesto",
    "lng": -121.08095425435181,
    "lat": 37.535980180515736
  },
  {
    "name": "Modesto",
    "lng": -121.04405413712028,
    "lat": 37.62162875719627
  },
  {
    "name": "Modesto",
    "lng": -121.03405145495607,
    "lat": 37.64207551420349
  },
  {
    "name": "Modesto",
    "lng": -120.99248577034489,
    "lat": 37.61284707537706
  },
  {
    "name": "Modesto",
    "lng": -120.9888855581945,
    "lat": 37.66151202898049
  },
  {
    "name": "Modesto",
    "lng": -120.94778587149356,
    "lat": 37.69307358445534
  },
  {
    "name": "Modesto",
    "lng": -120.91084134264726,
    "lat": 37.63996188107868
  },
  {
    "name": "Modesto",
    "lng": -120.90544730107523,
    "lat": 37.63871517264095
  },
  {
    "name": "Modesto",
    "lng": -120.90148570520488,
    "lat": 37.63672497738841
  },
  {
    "name": "Modesto",
    "lng": -89.98033224170538,
    "lat": 39.47886300213454
  },
  {
    "name": "Modoc",
    "lng": -85.12623673452221,
    "lat": 40.045471220737255
  },
  {
    "name": "Moenkopi",
    "lng": -111.22123566871174,
    "lat": 36.11248081212409
  },
  {
    "name": "Moffat",
    "lng": -105.9052396520399,
    "lat": 38.00184229562753
  },
  {
    "name": "Moffett",
    "lng": -94.44654973622401,
    "lat": 35.389896678051734
  },
  {
    "name": "Mogadore",
    "lng": -81.39936780672123,
    "lat": 41.051948016548764
  },
  {
    "name": "Mohall",
    "lng": -101.5169963710975,
    "lat": 48.766089691908505
  },
  {
    "name": "Mohawk",
    "lng": -75.00725066290245,
    "lat": 43.01038971482022
  },
  {
    "name": "Moingona",
    "lng": -93.93321439063965,
    "lat": 42.01663158880117
  },
  {
    "name": "Mojave",
    "lng": -118.18946529765226,
    "lat": 35.01387712534903
  },
  {
    "name": "Mokane",
    "lng": -91.87246418733079,
    "lat": 38.67444942028159
  },
  {
    "name": "Mokena",
    "lng": -87.9061114017431,
    "lat": 41.50251654711007
  },
  {
    "name": "Mokena",
    "lng": -87.89994805136716,
    "lat": 41.53795598931856
  },
  {
    "name": "Mokena",
    "lng": -87.89776561424192,
    "lat": 41.49483984538061
  },
  {
    "name": "Mokena",
    "lng": -87.8968721374789,
    "lat": 41.527542637496985
  },
  {
    "name": "Mokena",
    "lng": -87.89640130475976,
    "lat": 41.53878952039793
  },
  {
    "name": "Mokena",
    "lng": -87.8959134817357,
    "lat": 41.50516738418029
  },
  {
    "name": "Mokena",
    "lng": -87.89481324496946,
    "lat": 41.539239867027476
  },
  {
    "name": "Mokena",
    "lng": -87.89289738938717,
    "lat": 41.55514499450544
  },
  {
    "name": "Mokena",
    "lng": -87.88805086319498,
    "lat": 41.50352739631383
  },
  {
    "name": "Mokena",
    "lng": -87.87641412522481,
    "lat": 41.536518321840376
  },
  {
    "name": "Mokena",
    "lng": -87.81438387713165,
    "lat": 41.54901415242669
  },
  {
    "name": "Molena",
    "lng": -84.50013938787467,
    "lat": 33.01187037238649
  },
  {
    "name": "Moline",
    "lng": -96.30243226227186,
    "lat": 37.36363456790433
  },
  {
    "name": "Moline",
    "lng": -90.49796932524636,
    "lat": 41.45680099693432
  },
  {
    "name": "Moline",
    "lng": -90.4917678554082,
    "lat": 41.4826150643054
  },
  {
    "name": "Moline",
    "lng": -83.50816043548907,
    "lat": 41.55889182555039
  },
  {
    "name": "Moline Acres",
    "lng": -90.2429933509724,
    "lat": 38.745630482918635
  },
  {
    "name": "Momence",
    "lng": -87.66277290892647,
    "lat": 41.163824945948754
  },
  {
    "name": "Momeyer",
    "lng": -78.05110136167696,
    "lat": 35.95919264281203
  },
  {
    "name": "Mona",
    "lng": -111.85020560883791,
    "lat": 39.8121425328463
  },
  {
    "name": "Monahans",
    "lng": -103.10516350520636,
    "lat": 31.647221699219656
  },
  {
    "name": "Monahans",
    "lng": -102.92054571462037,
    "lat": 31.595084820731532
  },
  {
    "name": "Monango",
    "lng": -98.5951495957748,
    "lat": 46.173344596854626
  },
  {
    "name": "Moncks Corner",
    "lng": -79.99973844249293,
    "lat": 33.19608815260854
  },
  {
    "name": "Mondamin",
    "lng": -96.02121481679718,
    "lat": 41.7100075604368
  },
  {
    "name": "Mondovi",
    "lng": -91.66643523348169,
    "lat": 44.57131814416233
  },
  {
    "name": "Monee",
    "lng": -87.7505608134935,
    "lat": 41.41836896192944
  },
  {
    "name": "Monee",
    "lng": -87.73308778821058,
    "lat": 41.41073787231062
  },
  {
    "name": "Monee",
    "lng": -87.71486715335939,
    "lat": 41.41290581262036
  },
  {
    "name": "Monessen",
    "lng": -79.88278610081905,
    "lat": 40.15189791145726
  },
  {
    "name": "Monett",
    "lng": -94.01211480771511,
    "lat": 36.90721020934107
  },
  {
    "name": "Monett",
    "lng": -93.94798606708486,
    "lat": 36.90510926643469
  },
  {
    "name": "Monett",
    "lng": -93.92250228250813,
    "lat": 36.922668207388384
  },
  {
    "name": "Monetta",
    "lng": -81.60964051018411,
    "lat": 33.849722144803884
  },
  {
    "name": "Monette",
    "lng": -90.35041164466884,
    "lat": 35.92028902582964
  },
  {
    "name": "Monette",
    "lng": -90.34442514372331,
    "lat": 35.89258584766753
  },
  {
    "name": "Monette",
    "lng": -90.3438433521156,
    "lat": 35.89291545751108
  },
  {
    "name": "Monmouth",
    "lng": -90.88226575811265,
    "lat": 42.07516382663481
  },
  {
    "name": "Monmouth",
    "lng": -90.64194398272652,
    "lat": 40.91424744361176
  },
  {
    "name": "Monmouth",
    "lng": -90.62902000980183,
    "lat": 40.91708471979819
  },
  {
    "name": "Monmouth",
    "lng": -70.01295232009232,
    "lat": 44.23380571371631
  },
  {
    "name": "Monon",
    "lng": -86.87870889750211,
    "lat": 40.86414237843881
  },
  {
    "name": "Monona",
    "lng": -91.3911842229289,
    "lat": 43.05131647469394
  },
  {
    "name": "Monona",
    "lng": -89.33319246946698,
    "lat": 43.05396512989986
  },
  {
    "name": "Monongah",
    "lng": -80.21824070950078,
    "lat": 39.459660724695844
  },
  {
    "name": "Monongahela",
    "lng": -79.92220376875133,
    "lat": 40.19566792017236
  },
  {
    "name": "Monowi",
    "lng": -98.32944435904325,
    "lat": 42.82957012708987
  },
  {
    "name": "Monroe",
    "lng": -121.98555751047049,
    "lat": 47.859529193537554
  },
  {
    "name": "Monroe",
    "lng": -121.97338039574248,
    "lat": 47.87810090756047
  },
  {
    "name": "Monroe",
    "lng": -121.94652401413613,
    "lat": 47.87210566872969
  },
  {
    "name": "Monroe",
    "lng": -121.93423851971644,
    "lat": 47.87758945172822
  },
  {
    "name": "Monroe",
    "lng": -112.1196103907464,
    "lat": 38.62299440375598
  },
  {
    "name": "Monroe",
    "lng": -97.5999144012793,
    "lat": 41.47404016553412
  },
  {
    "name": "Monroe",
    "lng": -97.21667110250651,
    "lat": 43.48653335651628
  },
  {
    "name": "Monroe",
    "lng": -93.10366603042623,
    "lat": 41.51893834838007
  },
  {
    "name": "Monroe",
    "lng": -92.10144872084608,
    "lat": 32.43959028683249
  },
  {
    "name": "Monroe",
    "lng": -92.07673818177302,
    "lat": 32.520626100767565
  },
  {
    "name": "Monroe",
    "lng": -89.64367826984228,
    "lat": 42.600947501419775
  },
  {
    "name": "Monroe",
    "lng": -84.94109857009458,
    "lat": 40.74504124874556
  },
  {
    "name": "Monroe",
    "lng": -84.36642405882613,
    "lat": 39.44596772251588
  },
  {
    "name": "Monroe",
    "lng": -83.71611762513821,
    "lat": 33.79923313703186
  },
  {
    "name": "Monroe",
    "lng": -83.38517613041962,
    "lat": 41.91570513464706
  },
  {
    "name": "Monroe",
    "lng": -80.61433282549105,
    "lat": 34.98696653070463
  },
  {
    "name": "Monroe",
    "lng": -80.61102879243437,
    "lat": 34.98992617285776
  },
  {
    "name": "Monroe",
    "lng": -80.57827910576778,
    "lat": 35.078846639343794
  },
  {
    "name": "Monroe",
    "lng": -80.56529772844101,
    "lat": 35.07368353916301
  },
  {
    "name": "Monroe",
    "lng": -80.5589379168125,
    "lat": 35.00491375060599
  },
  {
    "name": "Monroe",
    "lng": -80.5378538536067,
    "lat": 34.945931322133326
  },
  {
    "name": "Monroe",
    "lng": -80.52780517421357,
    "lat": 35.00829703838226
  },
  {
    "name": "Monroe",
    "lng": -80.47752477142696,
    "lat": 35.03560715852947
  },
  {
    "name": "Monroe",
    "lng": -74.18476613473015,
    "lat": 41.319793340566
  },
  {
    "name": "Monroe",
    "lng": -73.22496797492761,
    "lat": 41.33793929879212
  },
  {
    "name": "Monroe",
    "lng": -72.98263570713281,
    "lat": 42.72265187771551
  },
  {
    "name": "Monroe",
    "lng": -69.04575042392004,
    "lat": 44.60718610924601
  },
  {
    "name": "Monroe Center",
    "lng": -88.99466417408644,
    "lat": 42.10012605064206
  },
  {
    "name": "Monroe City",
    "lng": -91.73265117368867,
    "lat": 39.654223187871374
  },
  {
    "name": "Monroe City",
    "lng": -87.35326523218805,
    "lat": 38.614493900650665
  },
  {
    "name": "Monroeville",
    "lng": -87.33213011002276,
    "lat": 31.476347052181602
  },
  {
    "name": "Monroeville",
    "lng": -87.32794190461154,
    "lat": 31.517004730731582
  },
  {
    "name": "Monroeville",
    "lng": -84.8675925976997,
    "lat": 40.97352631450159
  },
  {
    "name": "Monroeville",
    "lng": -82.70194603250214,
    "lat": 41.24405345144252
  },
  {
    "name": "Monrovia",
    "lng": -117.99211221414672,
    "lat": 34.165013515493435
  },
  {
    "name": "Monrovia",
    "lng": -86.47881281337398,
    "lat": 39.584630238469906
  },
  {
    "name": "Monson",
    "lng": -72.32611582972244,
    "lat": 42.09340258932384
  },
  {
    "name": "Monson",
    "lng": -69.50312537192573,
    "lat": 45.289607655663865
  },
  {
    "name": "Mont Belvieu",
    "lng": -94.9154348311025,
    "lat": 29.87719388214055
  },
  {
    "name": "Mont Belvieu",
    "lng": -94.87727428075715,
    "lat": 29.851598266696122
  },
  {
    "name": "Mont Vernon",
    "lng": -71.68378228911415,
    "lat": 42.90261467030744
  },
  {
    "name": "Montague",
    "lng": -122.53035313612449,
    "lat": 41.72754857508508
  },
  {
    "name": "Montague",
    "lng": -86.36350584156943,
    "lat": 43.41287241828326
  },
  {
    "name": "Montague",
    "lng": -72.51767275365599,
    "lat": 42.55488108899376
  },
  {
    "name": "Montana",
    "lng": -109.65124145781148,
    "lat": 47.07280823347866
  },
  {
    "name": "Montara",
    "lng": -122.5039716242818,
    "lat": 37.54046664905184
  },
  {
    "name": "Montclair",
    "lng": -117.69811274099662,
    "lat": 34.0715720119451
  },
  {
    "name": "Monte Sereno",
    "lng": -121.98814369728332,
    "lat": 37.240374259111185
  },
  {
    "name": "Monte Vista",
    "lng": -106.15037612323273,
    "lat": 37.57885194935414
  },
  {
    "name": "Monteagle",
    "lng": -85.84958302842841,
    "lat": 35.23588882896663
  },
  {
    "name": "Montebello",
    "lng": -74.11336043338792,
    "lat": 41.13157878828616
  },
  {
    "name": "Montecito",
    "lng": -119.62863062118896,
    "lat": 34.43801467567356
  },
  {
    "name": "Montello",
    "lng": -89.33347823739,
    "lat": 43.793630163194194
  },
  {
    "name": "Montello",
    "lng": -89.32454616625395,
    "lat": 43.80467413277235
  },
  {
    "name": "Monterey",
    "lng": -121.88200942516195,
    "lat": 36.60046610628992
  },
  {
    "name": "Monterey",
    "lng": -121.86681174779055,
    "lat": 36.58440565897826
  },
  {
    "name": "Monterey",
    "lng": -86.48259065122527,
    "lat": 41.156081567277255
  },
  {
    "name": "Monterey",
    "lng": -86.48038480858413,
    "lat": 41.15999597675286
  },
  {
    "name": "Monterey",
    "lng": -85.26492905741569,
    "lat": 36.1448432349482
  },
  {
    "name": "Monterey",
    "lng": -84.87283379153853,
    "lat": 38.423620562519986
  },
  {
    "name": "Monterey",
    "lng": -79.58088282096642,
    "lat": 38.41155861898194
  },
  {
    "name": "Monterey",
    "lng": -73.22737448363809,
    "lat": 42.19058230560338
  },
  {
    "name": "Montesano",
    "lng": -123.63799251572625,
    "lat": 46.96187742139484
  },
  {
    "name": "Montesano",
    "lng": -123.62790616061466,
    "lat": 46.97283488720429
  },
  {
    "name": "Montesano",
    "lng": -123.62461627640333,
    "lat": 46.97170755514914
  },
  {
    "name": "Montesano",
    "lng": -123.62376141751827,
    "lat": 46.9790547346146
  },
  {
    "name": "Montesano",
    "lng": -123.58604408781729,
    "lat": 47.01147004766067
  },
  {
    "name": "Montesano",
    "lng": -123.56162178565911,
    "lat": 46.98388775100412
  },
  {
    "name": "Montevallo",
    "lng": -86.89258203366202,
    "lat": 33.10529725791246
  },
  {
    "name": "Montevallo",
    "lng": -86.8613065148669,
    "lat": 33.12825027348443
  },
  {
    "name": "Montevallo",
    "lng": -86.85659071740417,
    "lat": 33.13695967112895
  },
  {
    "name": "Montevallo",
    "lng": -86.84715873169284,
    "lat": 33.1254205266922
  },
  {
    "name": "Montevallo",
    "lng": -86.82202409384888,
    "lat": 33.150757476460896
  },
  {
    "name": "Montevideo",
    "lng": -95.71787672063542,
    "lat": 44.97110661904234
  },
  {
    "name": "Montevideo",
    "lng": -95.71528805265228,
    "lat": 44.95040406120533
  },
  {
    "name": "Montezuma",
    "lng": -105.86807073456038,
    "lat": 39.581706427380034
  },
  {
    "name": "Montezuma",
    "lng": -100.46441789295967,
    "lat": 37.592242612708
  },
  {
    "name": "Montezuma",
    "lng": -100.44259111349893,
    "lat": 37.5957438713063
  },
  {
    "name": "Montezuma",
    "lng": -92.52768632172538,
    "lat": 41.583372195825
  },
  {
    "name": "Montezuma",
    "lng": -87.36939485100926,
    "lat": 39.79113561481645
  },
  {
    "name": "Montezuma",
    "lng": -84.54960041118925,
    "lat": 40.489116336611005
  },
  {
    "name": "Montezuma",
    "lng": -84.02464705039556,
    "lat": 32.300170820306356
  },
  {
    "name": "Montezuma",
    "lng": -84.00555502649735,
    "lat": 32.2899752844646
  },
  {
    "name": "Montfort",
    "lng": -90.43310407680235,
    "lat": 42.9697706644836
  },
  {
    "name": "Montgomery",
    "lng": -95.72347316890458,
    "lat": 30.384629522942134
  },
  {
    "name": "Montgomery",
    "lng": -95.72045191458056,
    "lat": 30.392467671683725
  },
  {
    "name": "Montgomery",
    "lng": -95.69467004534559,
    "lat": 30.392567405604304
  },
  {
    "name": "Montgomery",
    "lng": -93.59808731830309,
    "lat": 44.45902432600378
  },
  {
    "name": "Montgomery",
    "lng": -93.58838412876847,
    "lat": 44.457411879417116
  },
  {
    "name": "Montgomery",
    "lng": -93.58779420625719,
    "lat": 44.45646624111305
  },
  {
    "name": "Montgomery",
    "lng": -93.5874747500505,
    "lat": 44.42739069013595
  },
  {
    "name": "Montgomery",
    "lng": -93.58582984697904,
    "lat": 44.42085519518476
  },
  {
    "name": "Montgomery",
    "lng": -93.58492813206513,
    "lat": 44.46734740729729
  },
  {
    "name": "Montgomery",
    "lng": -93.57906757742938,
    "lat": 44.44488225354364
  },
  {
    "name": "Montgomery",
    "lng": -92.8876042803983,
    "lat": 31.667611623116432
  },
  {
    "name": "Montgomery",
    "lng": -88.36404003142265,
    "lat": 41.72370174103789
  },
  {
    "name": "Montgomery",
    "lng": -87.04718298772032,
    "lat": 38.665016197567326
  },
  {
    "name": "Montgomery",
    "lng": -86.2673972402049,
    "lat": 32.348052414946864
  },
  {
    "name": "Montgomery",
    "lng": -84.80646748053648,
    "lat": 41.77763677082199
  },
  {
    "name": "Montgomery",
    "lng": -84.34574054456864,
    "lat": 39.249271660997955
  },
  {
    "name": "Montgomery",
    "lng": -81.32407390780392,
    "lat": 38.17411385852187
  },
  {
    "name": "Montgomery",
    "lng": -74.23879255856473,
    "lat": 41.52159371140904
  },
  {
    "name": "Montgomery",
    "lng": -72.8203319836249,
    "lat": 42.20906375360609
  },
  {
    "name": "Montgomery City",
    "lng": -91.50314701907503,
    "lat": 38.97513149680669
  },
  {
    "name": "Monticello",
    "lng": -109.3381237096976,
    "lat": 37.86836574886363
  },
  {
    "name": "Monticello",
    "lng": -93.80093368445439,
    "lat": 45.29943587822646
  },
  {
    "name": "Monticello",
    "lng": -93.73711221316077,
    "lat": 45.29022881487836
  },
  {
    "name": "Monticello",
    "lng": -91.79316019106017,
    "lat": 33.62521544085305
  },
  {
    "name": "Monticello",
    "lng": -91.71305372154343,
    "lat": 40.11896150707361
  },
  {
    "name": "Monticello",
    "lng": -91.1840132371349,
    "lat": 42.23076939972671
  },
  {
    "name": "Monticello",
    "lng": -90.11545935163123,
    "lat": 31.552589388869773
  },
  {
    "name": "Monticello",
    "lng": -90.08486002327412,
    "lat": 31.550936241594446
  },
  {
    "name": "Monticello",
    "lng": -89.58982291161553,
    "lat": 42.7455476939205
  },
  {
    "name": "Monticello",
    "lng": -88.57303588343444,
    "lat": 40.033980649073634
  },
  {
    "name": "Monticello",
    "lng": -88.55190308807538,
    "lat": 40.05995767278426
  },
  {
    "name": "Monticello",
    "lng": -86.76693652055255,
    "lat": 40.745487763139174
  },
  {
    "name": "Monticello",
    "lng": -84.85058062074506,
    "lat": 36.84027957484119
  },
  {
    "name": "Monticello",
    "lng": -83.87216382781043,
    "lat": 30.54236653263977
  },
  {
    "name": "Monticello",
    "lng": -83.68591686692767,
    "lat": 33.30707508033632
  },
  {
    "name": "Monticello",
    "lng": -74.69778565363974,
    "lat": 41.66843380455441
  },
  {
    "name": "Monticello",
    "lng": -74.68670448809033,
    "lat": 41.65167337077026
  },
  {
    "name": "Monticello",
    "lng": -74.66175651485831,
    "lat": 41.64676951746948
  },
  {
    "name": "Monticello",
    "lng": -67.84518442634254,
    "lat": 46.32586801364973
  },
  {
    "name": "Montour",
    "lng": -92.71487879282789,
    "lat": 41.98057443968157
  },
  {
    "name": "Montour Falls",
    "lng": -76.8483378077732,
    "lat": 42.34994416534798
  },
  {
    "name": "Montpelier",
    "lng": -111.29879373149853,
    "lat": 42.325978665391474
  },
  {
    "name": "Montpelier",
    "lng": -98.58907670308136,
    "lat": 46.69900811705891
  },
  {
    "name": "Montpelier",
    "lng": -90.65452346603183,
    "lat": 30.682242880985896
  },
  {
    "name": "Montpelier",
    "lng": -85.28599803762567,
    "lat": 40.55113782629191
  },
  {
    "name": "Montpelier",
    "lng": -84.59700731586888,
    "lat": 41.581647362776074
  },
  {
    "name": "Montpelier",
    "lng": -72.57172897179461,
    "lat": 44.265945670199365
  },
  {
    "name": "Montreal",
    "lng": -90.23829293657121,
    "lat": 46.431298017029405
  },
  {
    "name": "Montreat",
    "lng": -82.29993199599257,
    "lat": 35.647801374233026
  },
  {
    "name": "Montrose",
    "lng": -107.92170006694371,
    "lat": 38.51269113830638
  },
  {
    "name": "Montrose",
    "lng": -107.86135242017512,
    "lat": 38.46902706449789
  },
  {
    "name": "Montrose",
    "lng": -107.8087901553267,
    "lat": 38.455889390114294
  },
  {
    "name": "Montrose",
    "lng": -97.18437768540576,
    "lat": 43.69998591671716
  },
  {
    "name": "Montrose",
    "lng": -93.98275261819946,
    "lat": 38.258668369947195
  },
  {
    "name": "Montrose",
    "lng": -93.92163973576052,
    "lat": 45.048979958323685
  },
  {
    "name": "Montrose",
    "lng": -93.92046602699635,
    "lat": 45.071084180968725
  },
  {
    "name": "Montrose",
    "lng": -91.49644807873325,
    "lat": 33.29897040407921
  },
  {
    "name": "Montrose",
    "lng": -91.41537683708252,
    "lat": 40.52584629910914
  },
  {
    "name": "Montrose",
    "lng": -89.2343864144505,
    "lat": 32.12566546922208
  },
  {
    "name": "Montrose",
    "lng": -88.37763460867183,
    "lat": 39.165952647936265
  },
  {
    "name": "Montrose",
    "lng": -83.89303174821008,
    "lat": 43.176392073948115
  },
  {
    "name": "Montrose",
    "lng": -83.15348483045166,
    "lat": 32.55944749565075
  },
  {
    "name": "Montrose",
    "lng": -79.81432121935683,
    "lat": 39.06725487533029
  },
  {
    "name": "Montrose",
    "lng": -75.87604081296854,
    "lat": 41.83345478117828
  },
  {
    "name": "Montross",
    "lng": -76.82305896987592,
    "lat": 38.09404074277881
  },
  {
    "name": "Montville",
    "lng": -72.15705797925825,
    "lat": 41.46359560237944
  },
  {
    "name": "Montville",
    "lng": -69.278310552149,
    "lat": 44.448341042415564
  },
  {
    "name": "Monument",
    "lng": -104.88010395202974,
    "lat": 39.092427219985325
  },
  {
    "name": "Monument",
    "lng": -104.86527778514498,
    "lat": 39.09528575871198
  },
  {
    "name": "Monument",
    "lng": -104.8623652260489,
    "lat": 39.06731628001668
  },
  {
    "name": "Monument",
    "lng": -104.85183864694226,
    "lat": 39.04929138155576
  },
  {
    "name": "Moody",
    "lng": -97.3608820980446,
    "lat": 31.30860346158559
  },
  {
    "name": "Moody",
    "lng": -86.49708316097855,
    "lat": 33.598463603807794
  },
  {
    "name": "Moorcroft",
    "lng": -104.97305657363927,
    "lat": 44.2690023295606
  },
  {
    "name": "Moorcroft",
    "lng": -104.94995182554506,
    "lat": 44.264069752377125
  },
  {
    "name": "Moorcroft",
    "lng": -104.84020517069926,
    "lat": 44.282656872938944
  },
  {
    "name": "Moore",
    "lng": -113.36734053960397,
    "lat": 43.73440994090828
  },
  {
    "name": "Moore",
    "lng": -109.69910348988651,
    "lat": 46.977409447605936
  },
  {
    "name": "Moore",
    "lng": -109.69434467178957,
    "lat": 46.97461389195647
  },
  {
    "name": "Moore",
    "lng": -97.47653295597848,
    "lat": 35.329892781192974
  },
  {
    "name": "Moore Haven",
    "lng": -81.09848143481463,
    "lat": 26.83368182696171
  },
  {
    "name": "Moore Station",
    "lng": -95.57041606876005,
    "lat": 32.19056209330663
  },
  {
    "name": "Moorefield",
    "lng": -100.40032730447986,
    "lat": 40.6899171749984
  },
  {
    "name": "Moorefield",
    "lng": -91.57081173064776,
    "lat": 35.76721420682285
  },
  {
    "name": "Moorefield",
    "lng": -78.96382054410618,
    "lat": 39.06586088421029
  },
  {
    "name": "Mooreland",
    "lng": -99.20442046034194,
    "lat": 36.43819973786972
  },
  {
    "name": "Mooreland",
    "lng": -99.20440232555464,
    "lat": 36.42740091683848
  },
  {
    "name": "Mooreland",
    "lng": -85.25131827466082,
    "lat": 39.99740419692094
  },
  {
    "name": "Moores Hill",
    "lng": -85.09040650192752,
    "lat": 39.11163177566383
  },
  {
    "name": "Mooresboro",
    "lng": -81.69866558965765,
    "lat": 35.29938322592463
  },
  {
    "name": "Mooresville",
    "lng": -93.72090526153293,
    "lat": 39.74666017956605
  },
  {
    "name": "Mooresville",
    "lng": -86.88382306690842,
    "lat": 34.62576374851826
  },
  {
    "name": "Mooresville",
    "lng": -86.87996477323419,
    "lat": 34.626026059892936
  },
  {
    "name": "Mooresville",
    "lng": -86.36758644105674,
    "lat": 39.603888109997925
  },
  {
    "name": "Mooresville",
    "lng": -80.91005074281956,
    "lat": 35.60483264885072
  },
  {
    "name": "Mooresville",
    "lng": -80.89850853073706,
    "lat": 35.59898832946454
  },
  {
    "name": "Mooresville",
    "lng": -80.89808474978668,
    "lat": 35.6010249671687
  },
  {
    "name": "Mooresville",
    "lng": -80.89100937770715,
    "lat": 35.60303980065703
  },
  {
    "name": "Mooresville",
    "lng": -80.88602626812695,
    "lat": 35.57858016605906
  },
  {
    "name": "Mooresville",
    "lng": -80.88254983489755,
    "lat": 35.59663092234777
  },
  {
    "name": "Mooresville",
    "lng": -80.88163955084002,
    "lat": 35.58004706563057
  },
  {
    "name": "Mooresville",
    "lng": -80.87252317713589,
    "lat": 35.58169068015164
  },
  {
    "name": "Mooresville",
    "lng": -80.87017512088117,
    "lat": 35.57814376005966
  },
  {
    "name": "Mooresville",
    "lng": -80.86749579274297,
    "lat": 35.56977211935117
  },
  {
    "name": "Mooresville",
    "lng": -80.86627477279012,
    "lat": 35.573028017537666
  },
  {
    "name": "Mooresville",
    "lng": -80.86515791403221,
    "lat": 35.61797724322176
  },
  {
    "name": "Mooresville",
    "lng": -80.86093882454853,
    "lat": 35.556360473415985
  },
  {
    "name": "Mooresville",
    "lng": -80.85556071249097,
    "lat": 35.557600784796435
  },
  {
    "name": "Mooresville",
    "lng": -80.85250827813057,
    "lat": 35.56600094109378
  },
  {
    "name": "Mooresville",
    "lng": -80.85242460773593,
    "lat": 35.56476456393412
  },
  {
    "name": "Mooresville",
    "lng": -80.85076119275952,
    "lat": 35.56073842873672
  },
  {
    "name": "Mooresville",
    "lng": -80.85032338251098,
    "lat": 35.561068772728476
  },
  {
    "name": "Mooresville",
    "lng": -80.82725833825012,
    "lat": 35.54418201642822
  },
  {
    "name": "Mooresville",
    "lng": -80.82687051506777,
    "lat": 35.57888922805583
  },
  {
    "name": "Mooresville",
    "lng": -80.82539829833412,
    "lat": 35.560483490459795
  },
  {
    "name": "Mooresville",
    "lng": -80.82194013591422,
    "lat": 35.62299609530436
  },
  {
    "name": "Mooresville",
    "lng": -80.8164972033881,
    "lat": 35.61931625337145
  },
  {
    "name": "Mooresville",
    "lng": -80.81032946417545,
    "lat": 35.626803342079405
  },
  {
    "name": "Mooresville",
    "lng": -80.80291279203075,
    "lat": 35.60728824462037
  },
  {
    "name": "Mooresville",
    "lng": -80.79221202065796,
    "lat": 35.60768965969552
  },
  {
    "name": "Mooresville",
    "lng": -80.79204836801763,
    "lat": 35.605726982307864
  },
  {
    "name": "Mooresville",
    "lng": -80.79095234173982,
    "lat": 35.585743500380275
  },
  {
    "name": "Mooresville",
    "lng": -80.79093703801608,
    "lat": 35.54898925050381
  },
  {
    "name": "Mooresville",
    "lng": -80.79081678830427,
    "lat": 35.58927772433138
  },
  {
    "name": "Mooresville",
    "lng": -80.79020057870667,
    "lat": 35.58658667297653
  },
  {
    "name": "Mooresville",
    "lng": -80.79006786284145,
    "lat": 35.5881753871567
  },
  {
    "name": "Mooresville",
    "lng": -80.78730279712843,
    "lat": 35.59113893880724
  },
  {
    "name": "Mooresville",
    "lng": -80.78328199716994,
    "lat": 35.62903805381358
  },
  {
    "name": "Mooresville",
    "lng": -80.77380232083183,
    "lat": 35.600890847278336
  },
  {
    "name": "Mooresville",
    "lng": -80.77348567276772,
    "lat": 35.614103385906056
  },
  {
    "name": "Mooreton",
    "lng": -96.87593454371583,
    "lat": 46.26888116442605
  },
  {
    "name": "Moorhead",
    "lng": -96.74593485059407,
    "lat": 46.86742433560466
  },
  {
    "name": "Moorhead",
    "lng": -95.85147079149826,
    "lat": 41.92340599467397
  },
  {
    "name": "Moorhead",
    "lng": -90.50626727195026,
    "lat": 33.44941111891456
  },
  {
    "name": "Mooringsport",
    "lng": -93.96068893329586,
    "lat": 32.6825107340395
  },
  {
    "name": "Moorland",
    "lng": -94.2954716245888,
    "lat": 42.441556455463136
  },
  {
    "name": "Moorland",
    "lng": -85.58013461326514,
    "lat": 38.2728183078199
  },
  {
    "name": "Moorpark",
    "lng": -118.87684664536735,
    "lat": 34.28610421773756
  },
  {
    "name": "Moose Creek",
    "lng": -147.16418661992188,
    "lat": 64.71406996664345
  },
  {
    "name": "Moose Lake",
    "lng": -92.77624472269943,
    "lat": 46.44313027595838
  },
  {
    "name": "Moose Lake",
    "lng": -92.76316541681199,
    "lat": 46.445376392562096
  },
  {
    "name": "Moose River",
    "lng": -70.21499084366326,
    "lat": 45.696988320598855
  },
  {
    "name": "Moosic",
    "lng": -75.70255938746563,
    "lat": 41.35851314517904
  },
  {
    "name": "Moosup",
    "lng": -71.87585597408648,
    "lat": 41.71727694638089
  },
  {
    "name": "Mora",
    "lng": -93.29175876462908,
    "lat": 45.87678836381267
  },
  {
    "name": "Mora",
    "lng": -93.28383072063174,
    "lat": 45.85900199545097
  },
  {
    "name": "Moraine",
    "lng": -84.24583524524749,
    "lat": 39.69829571660833
  },
  {
    "name": "Moran",
    "lng": -95.17148614776345,
    "lat": 37.91649931019674
  },
  {
    "name": "Moravia",
    "lng": -92.8186666495138,
    "lat": 40.891903362138486
  },
  {
    "name": "Moravia",
    "lng": -76.42193169031589,
    "lat": 42.71196705458241
  },
  {
    "name": "Moreauville",
    "lng": -91.9817131321369,
    "lat": 31.034176843763557
  },
  {
    "name": "Morehead",
    "lng": -83.47882843966565,
    "lat": 38.20393476707367
  },
  {
    "name": "Morehead",
    "lng": -83.44550741015959,
    "lat": 38.19015148377726
  },
  {
    "name": "Morehead City",
    "lng": -76.83708824905789,
    "lat": 34.74740438651181
  },
  {
    "name": "Morehead City",
    "lng": -76.82226106221627,
    "lat": 34.74415820840291
  },
  {
    "name": "Morehead City",
    "lng": -76.81877298032448,
    "lat": 34.742105908951956
  },
  {
    "name": "Morehead City",
    "lng": -76.81530346078522,
    "lat": 34.740832279790176
  },
  {
    "name": "Morehead City",
    "lng": -76.80236128978142,
    "lat": 34.744321301260875
  },
  {
    "name": "Morehead City",
    "lng": -76.8008858641268,
    "lat": 34.7348693242615
  },
  {
    "name": "Morehead City",
    "lng": -76.79304141850406,
    "lat": 34.73927847674444
  },
  {
    "name": "Morehead City",
    "lng": -76.76829095647518,
    "lat": 34.72980512471826
  },
  {
    "name": "Morehead City",
    "lng": -76.75813965975206,
    "lat": 34.741991329075994
  },
  {
    "name": "Morehead City",
    "lng": -76.75126956571279,
    "lat": 34.73297252296273
  },
  {
    "name": "Morehead City",
    "lng": -76.74647838986894,
    "lat": 34.73398217671458
  },
  {
    "name": "Morehead City",
    "lng": -76.74576839030516,
    "lat": 34.73089964529344
  },
  {
    "name": "Morehead City",
    "lng": -76.723369102527,
    "lat": 34.730413546175356
  },
  {
    "name": "Morehead City",
    "lng": -76.72133764701682,
    "lat": 34.75706039586676
  },
  {
    "name": "Morehead City",
    "lng": -76.71032074534449,
    "lat": 34.71747998940262
  },
  {
    "name": "Morehead City",
    "lng": -76.6882283739842,
    "lat": 34.717864860110666
  },
  {
    "name": "Morehead City",
    "lng": -76.6855461571706,
    "lat": 34.71213054729591
  },
  {
    "name": "Morehead City",
    "lng": -76.68517895162753,
    "lat": 34.71713602623664
  },
  {
    "name": "Morehead City",
    "lng": -76.68113706495784,
    "lat": 34.71404460977961
  },
  {
    "name": "Morehouse",
    "lng": -89.69136590431661,
    "lat": 36.84631868342141
  },
  {
    "name": "Moreland",
    "lng": -84.76995371091508,
    "lat": 33.286085294943845
  },
  {
    "name": "Moreland Hills",
    "lng": -81.42930157569754,
    "lat": 41.442239148593025
  },
  {
    "name": "Morenci",
    "lng": -84.21690777052666,
    "lat": 41.722776055574464
  },
  {
    "name": "Moreno Valley",
    "lng": -117.20510419648649,
    "lat": 33.92443128853953
  },
  {
    "name": "Morgan",
    "lng": -111.68018893972817,
    "lat": 41.04142803458492
  },
  {
    "name": "Morgan",
    "lng": -97.60610550006918,
    "lat": 32.015545153306
  },
  {
    "name": "Morgan",
    "lng": -94.92496514951215,
    "lat": 44.41593215010498
  },
  {
    "name": "Morgan",
    "lng": -84.60342189033047,
    "lat": 31.538169961394917
  },
  {
    "name": "Morgan City",
    "lng": -91.19205333185091,
    "lat": 29.70403600804973
  },
  {
    "name": "Morgan City",
    "lng": -90.34615777279579,
    "lat": 33.378647292248054
  },
  {
    "name": "Morgan's Point",
    "lng": -95.00265680313045,
    "lat": 29.676492270900447
  },
  {
    "name": "Morganfield",
    "lng": -87.90616855057159,
    "lat": 37.68487176351996
  },
  {
    "name": "Morgans Point Resort",
    "lng": -97.45881363892106,
    "lat": 31.152132752959588
  },
  {
    "name": "Morganton",
    "lng": -84.24431432980211,
    "lat": 34.87530154692749
  },
  {
    "name": "Morganton",
    "lng": -81.72916960666889,
    "lat": 35.75023959379891
  },
  {
    "name": "Morganton",
    "lng": -81.70346042451051,
    "lat": 35.69785366289881
  },
  {
    "name": "Morganton",
    "lng": -81.70333273468106,
    "lat": 35.710853691642654
  },
  {
    "name": "Morganton",
    "lng": -81.70071594184608,
    "lat": 35.74124576209216
  },
  {
    "name": "Morganton",
    "lng": -81.66943921971223,
    "lat": 35.70438382603711
  },
  {
    "name": "Morganton",
    "lng": -81.64875076724559,
    "lat": 35.73687925275428
  },
  {
    "name": "Morganton",
    "lng": -81.64824603268218,
    "lat": 35.754713988516365
  },
  {
    "name": "Morgantown",
    "lng": -86.70018599414607,
    "lat": 37.217576990756044
  },
  {
    "name": "Morgantown",
    "lng": -86.25863844360475,
    "lat": 39.37288752954757
  },
  {
    "name": "Morgantown",
    "lng": -79.94834523170977,
    "lat": 39.63412253790465
  },
  {
    "name": "Morgantown",
    "lng": -79.93246402270155,
    "lat": 39.663164244784966
  },
  {
    "name": "Morganville",
    "lng": -97.20360817509226,
    "lat": 39.46657571266451
  },
  {
    "name": "Morganza",
    "lng": -91.59196297672838,
    "lat": 30.738300989204188
  },
  {
    "name": "Moriarty",
    "lng": -106.04925129512621,
    "lat": 35.00695591422938
  },
  {
    "name": "Morland",
    "lng": -100.07428394186911,
    "lat": 39.34892846861245
  },
  {
    "name": "Morley",
    "lng": -91.24584948925582,
    "lat": 42.00618743676769
  },
  {
    "name": "Morley",
    "lng": -89.61284507904242,
    "lat": 37.04325024228314
  },
  {
    "name": "Morley",
    "lng": -85.44537392441813,
    "lat": 43.49095039403335
  },
  {
    "name": "Mormon Grove",
    "lng": -96.69847672226297,
    "lat": 33.561779154703636
  },
  {
    "name": "Morning Sun",
    "lng": -91.2506079784264,
    "lat": 41.09414695763752
  },
  {
    "name": "Morningside",
    "lng": -76.88936833449554,
    "lat": 38.82679530927031
  },
  {
    "name": "Moro",
    "lng": -90.98804481430044,
    "lat": 34.79200547804696
  },
  {
    "name": "Morocco",
    "lng": -87.45019109799672,
    "lat": 40.94392279750016
  },
  {
    "name": "Moroni",
    "lng": -111.58306726745342,
    "lat": 39.52702804913641
  },
  {
    "name": "Morral",
    "lng": -83.20969659412337,
    "lat": 40.691394061902116
  },
  {
    "name": "Morrice",
    "lng": -84.183321328378,
    "lat": 42.83615459654149
  },
  {
    "name": "Morrice",
    "lng": -84.18036945099631,
    "lat": 42.84554398901815
  },
  {
    "name": "Morrill",
    "lng": -103.92444303727973,
    "lat": 41.963835768090725
  },
  {
    "name": "Morrill",
    "lng": -95.69477875600244,
    "lat": 39.92909190800847
  },
  {
    "name": "Morrill",
    "lng": -69.16615789483666,
    "lat": 44.43434814777441
  },
  {
    "name": "Morrilton",
    "lng": -92.7387178865373,
    "lat": 35.15583686966725
  },
  {
    "name": "Morris",
    "lng": -95.90459094324069,
    "lat": 45.58568038344313
  },
  {
    "name": "Morris",
    "lng": -95.8620434577098,
    "lat": 35.61533643185071
  },
  {
    "name": "Morris",
    "lng": -88.42996356516575,
    "lat": 41.37484607048688
  },
  {
    "name": "Morris",
    "lng": -86.80396696732832,
    "lat": 33.74666476860787
  },
  {
    "name": "Morris",
    "lng": -75.24524387348734,
    "lat": 42.54819031784532
  },
  {
    "name": "Morris",
    "lng": -73.20781790789897,
    "lat": 41.6898053107347
  },
  {
    "name": "Morrison",
    "lng": -105.19394358850599,
    "lat": 39.6426124731461
  },
  {
    "name": "Morrison",
    "lng": -97.03625863182138,
    "lat": 36.28535239248089
  },
  {
    "name": "Morrison",
    "lng": -97.0282026745764,
    "lat": 36.294086255572275
  },
  {
    "name": "Morrison",
    "lng": -92.67330263030412,
    "lat": 42.3429693047004
  },
  {
    "name": "Morrison",
    "lng": -91.63333899447909,
    "lat": 38.671528196890485
  },
  {
    "name": "Morrison",
    "lng": -89.96174933870225,
    "lat": 41.8076877224935
  },
  {
    "name": "Morrison",
    "lng": -85.9120536920704,
    "lat": 35.60472039237876
  },
  {
    "name": "Morrison Bluff",
    "lng": -93.52280210036643,
    "lat": 35.38288373989622
  },
  {
    "name": "Morrisonville",
    "lng": -89.47690399993691,
    "lat": 39.431155673148915
  },
  {
    "name": "Morrisonville",
    "lng": -89.45830490926407,
    "lat": 39.42129695827311
  },
  {
    "name": "Morristown",
    "lng": -101.71881289959852,
    "lat": 45.938617312745016
  },
  {
    "name": "Morristown",
    "lng": -93.46189109628509,
    "lat": 44.22873206552706
  },
  {
    "name": "Morristown",
    "lng": -93.44449967116515,
    "lat": 44.22403948388375
  },
  {
    "name": "Morristown",
    "lng": -85.69788480566805,
    "lat": 39.6736076582562
  },
  {
    "name": "Morristown",
    "lng": -83.30145037165711,
    "lat": 36.2028931471672
  },
  {
    "name": "Morristown",
    "lng": -81.07053946624063,
    "lat": 40.062807352536424
  },
  {
    "name": "Morristown",
    "lng": -75.64533119909542,
    "lat": 44.58417769494857
  },
  {
    "name": "Morristown",
    "lng": -74.47704026384359,
    "lat": 40.796594174499035
  },
  {
    "name": "Morrisville",
    "lng": -93.42823204365526,
    "lat": 37.48041736489618
  },
  {
    "name": "Morrisville",
    "lng": -80.16778998665329,
    "lat": 39.90068216442657
  },
  {
    "name": "Morrisville",
    "lng": -78.85225314618157,
    "lat": 35.83240521060971
  },
  {
    "name": "Morrisville",
    "lng": -78.83465946283978,
    "lat": 35.836693668943724
  },
  {
    "name": "Morrisville",
    "lng": -78.82472505456218,
    "lat": 35.85625543097577
  },
  {
    "name": "Morrisville",
    "lng": -78.82017646091202,
    "lat": 35.82544999126789
  },
  {
    "name": "Morrisville",
    "lng": -75.64481140646211,
    "lat": 42.897825935295025
  },
  {
    "name": "Morrisville",
    "lng": -72.59368839431832,
    "lat": 44.55904487260853
  },
  {
    "name": "Morro Bay",
    "lng": -120.88294517184146,
    "lat": 35.37608340768064
  },
  {
    "name": "Morrow",
    "lng": -84.33921435578304,
    "lat": 33.58155615791742
  },
  {
    "name": "Morrow",
    "lng": -84.12360520929661,
    "lat": 39.34948816577694
  },
  {
    "name": "Morrowville",
    "lng": -97.17277259996312,
    "lat": 39.845069957162885
  },
  {
    "name": "Morse",
    "lng": -92.498435076796,
    "lat": 30.122097726364647
  },
  {
    "name": "Morse Bluff",
    "lng": -96.76651290142773,
    "lat": 41.43094631053488
  },
  {
    "name": "Morton",
    "lng": -122.28376396796578,
    "lat": 46.557773873736664
  },
  {
    "name": "Morton",
    "lng": -122.26602763482413,
    "lat": 46.56853251600641
  },
  {
    "name": "Morton",
    "lng": -102.75921076566125,
    "lat": 33.72482073759867
  },
  {
    "name": "Morton",
    "lng": -94.9849619194351,
    "lat": 44.55337704619845
  },
  {
    "name": "Morton",
    "lng": -94.97314760890768,
    "lat": 44.55252968558429
  },
  {
    "name": "Morton",
    "lng": -89.65409785098824,
    "lat": 32.349834038880516
  },
  {
    "name": "Morton",
    "lng": -89.46700376846985,
    "lat": 40.613294148255136
  },
  {
    "name": "Morton Grove",
    "lng": -87.78865303075985,
    "lat": 42.04363550655712
  },
  {
    "name": "Mortons Gap",
    "lng": -87.46605073143432,
    "lat": 37.24064207673034
  },
  {
    "name": "Morven",
    "lng": -83.50035866122226,
    "lat": 30.943321653205302
  },
  {
    "name": "Morven",
    "lng": -80.01309183378373,
    "lat": 34.88428945115565
  },
  {
    "name": "Morven",
    "lng": -80.00012426986692,
    "lat": 34.86367320572418
  },
  {
    "name": "Mosby",
    "lng": -94.30225435648359,
    "lat": 39.31887097728378
  },
  {
    "name": "Moscow",
    "lng": -116.998745846504,
    "lat": 46.730708026616206
  },
  {
    "name": "Moscow",
    "lng": -101.20665266795677,
    "lat": 37.32518992012086
  },
  {
    "name": "Moscow",
    "lng": -89.38894500635014,
    "lat": 35.06085845855649
  },
  {
    "name": "Moscow",
    "lng": -84.22801792875224,
    "lat": 38.859867764984635
  },
  {
    "name": "Moscow",
    "lng": -69.87140194430815,
    "lat": 45.120976647609126
  },
  {
    "name": "Moscow Mills",
    "lng": -90.92409392594722,
    "lat": 38.947281438266714
  },
  {
    "name": "Moses Lake",
    "lng": -119.33125874993576,
    "lat": 47.18656342500027
  },
  {
    "name": "Moses Lake",
    "lng": -119.32076995000016,
    "lat": 47.183886700411904
  },
  {
    "name": "Moses Lake",
    "lng": -119.27653512111235,
    "lat": 47.12943021432518
  },
  {
    "name": "Moses Lake",
    "lng": -119.2526058904508,
    "lat": 47.06501199334581
  },
  {
    "name": "Moses Lake",
    "lng": -119.19289689298641,
    "lat": 47.13102283403036
  },
  {
    "name": "Mosheim",
    "lng": -82.97669496306165,
    "lat": 36.1784802197776
  },
  {
    "name": "Mosheim",
    "lng": -82.96828470206846,
    "lat": 36.197406975477406
  },
  {
    "name": "Mosheim",
    "lng": -82.94086848299264,
    "lat": 36.20346684907807
  },
  {
    "name": "Mosheim",
    "lng": -82.93144560103676,
    "lat": 36.180396626995964
  },
  {
    "name": "Mosheim",
    "lng": -82.89600493901713,
    "lat": 36.183739103530506
  },
  {
    "name": "Mosheim",
    "lng": -82.89247008515271,
    "lat": 36.17884332266606
  },
  {
    "name": "Mosinee",
    "lng": -89.68384722335523,
    "lat": 44.78741937254786
  },
  {
    "name": "Mosquero",
    "lng": -103.95441265867615,
    "lat": 35.77431023724956
  },
  {
    "name": "Moss Beach",
    "lng": -122.5129170396994,
    "lat": 37.528053274219666
  },
  {
    "name": "Moss Landing",
    "lng": -121.78743348837024,
    "lat": 36.80129130388734
  },
  {
    "name": "Moss Point",
    "lng": -88.52858504496028,
    "lat": 30.42430054459865
  },
  {
    "name": "Mosses",
    "lng": -86.68953845845259,
    "lat": 32.18038897072956
  },
  {
    "name": "Mossyrock",
    "lng": -122.48343046364529,
    "lat": 46.530023777011934
  },
  {
    "name": "Motley",
    "lng": -94.64243593369788,
    "lat": 46.33480185421909
  },
  {
    "name": "Mott",
    "lng": -102.32511194751865,
    "lat": 46.374072775053705
  },
  {
    "name": "Mott",
    "lng": -102.30243592582349,
    "lat": 46.37432332519963
  },
  {
    "name": "Moulton",
    "lng": -92.67633245543412,
    "lat": 40.68559921985063
  },
  {
    "name": "Moulton",
    "lng": -87.2831149744096,
    "lat": 34.48430791151659
  },
  {
    "name": "Moultrie",
    "lng": -83.76984760700307,
    "lat": 31.15990225101596
  },
  {
    "name": "Mound",
    "lng": -93.65982528276231,
    "lat": 44.93254099181016
  },
  {
    "name": "Mound",
    "lng": -91.02556270012388,
    "lat": 32.333411729879245
  },
  {
    "name": "Mound Bayou",
    "lng": -90.72803660258806,
    "lat": 33.88081501796514
  },
  {
    "name": "Mound City",
    "lng": -100.0688329283432,
    "lat": 45.72615956999325
  },
  {
    "name": "Mound City",
    "lng": -95.23397930632333,
    "lat": 40.13652708068953
  },
  {
    "name": "Mound City",
    "lng": -94.89257297394705,
    "lat": 38.12205368087725
  },
  {
    "name": "Mound City",
    "lng": -94.81657613168206,
    "lat": 38.13385492942957
  },
  {
    "name": "Mound City",
    "lng": -94.8124398291636,
    "lat": 38.1422736019393
  },
  {
    "name": "Mound City",
    "lng": -94.79868688752134,
    "lat": 38.14796897749655
  },
  {
    "name": "Mound City",
    "lng": -89.16286975265906,
    "lat": 37.08526146793513
  },
  {
    "name": "Mound Station",
    "lng": -90.87372877091971,
    "lat": 40.00659640076547
  },
  {
    "name": "Mound Valley",
    "lng": -95.40471126132455,
    "lat": 37.207178018359905
  },
  {
    "name": "Moundridge",
    "lng": -97.51732007281981,
    "lat": 38.191172447251034
  },
  {
    "name": "Moundridge",
    "lng": -97.51683195024027,
    "lat": 38.18646032975153
  },
  {
    "name": "Moundridge",
    "lng": -97.51609167395098,
    "lat": 38.205607150333186
  },
  {
    "name": "Moundridge",
    "lng": -97.51273677538809,
    "lat": 38.1945240278367
  },
  {
    "name": "Mounds",
    "lng": -96.06755251062869,
    "lat": 35.87931477447495
  },
  {
    "name": "Mounds",
    "lng": -89.20236734496515,
    "lat": 37.114493151064636
  },
  {
    "name": "Mounds View",
    "lng": -93.20775615359241,
    "lat": 45.10708845780548
  },
  {
    "name": "Moundsville",
    "lng": -80.74173277953761,
    "lat": 39.9220020689629
  },
  {
    "name": "Moundville",
    "lng": -94.45094725670182,
    "lat": 37.76468348486934
  },
  {
    "name": "Moundville",
    "lng": -87.62758467584612,
    "lat": 32.996596910904074
  },
  {
    "name": "Mount Airy",
    "lng": -83.4974608646228,
    "lat": 34.52181595286627
  },
  {
    "name": "Mount Airy",
    "lng": -80.65266214126667,
    "lat": 36.4961729347742
  },
  {
    "name": "Mount Airy",
    "lng": -80.64838370023338,
    "lat": 36.47322633788705
  },
  {
    "name": "Mount Airy",
    "lng": -80.63858852144132,
    "lat": 36.46014096206704
  },
  {
    "name": "Mount Airy",
    "lng": -80.63506946878073,
    "lat": 36.45977492816176
  },
  {
    "name": "Mount Airy",
    "lng": -80.63462474765883,
    "lat": 36.45451343637128
  },
  {
    "name": "Mount Airy",
    "lng": -80.62312200742895,
    "lat": 36.495659178971366
  },
  {
    "name": "Mount Airy",
    "lng": -80.61940833332787,
    "lat": 36.548744590226214
  },
  {
    "name": "Mount Airy",
    "lng": -80.61173151854696,
    "lat": 36.50356519446978
  },
  {
    "name": "Mount Airy",
    "lng": -80.56464412839736,
    "lat": 36.456529767616075
  },
  {
    "name": "Mount Airy",
    "lng": -80.56393075544682,
    "lat": 36.459397044621916
  },
  {
    "name": "Mount Airy",
    "lng": -80.56136439003818,
    "lat": 36.45813745134548
  },
  {
    "name": "Mount Airy",
    "lng": -80.55913738291491,
    "lat": 36.46183992038727
  },
  {
    "name": "Mount Auburn",
    "lng": -92.09385428564559,
    "lat": 42.25742211172754
  },
  {
    "name": "Mount Auburn",
    "lng": -89.26079161919873,
    "lat": 39.76489260838775
  },
  {
    "name": "Mount Auburn",
    "lng": -85.19059549507479,
    "lat": 39.81235873275113
  },
  {
    "name": "Mount Ayr",
    "lng": -94.23752687038424,
    "lat": 40.71415692287892
  },
  {
    "name": "Mount Ayr",
    "lng": -87.29864289913002,
    "lat": 40.95211903855991
  },
  {
    "name": "Mount Blanchard",
    "lng": -83.55681621802825,
    "lat": 40.89851807771329
  },
  {
    "name": "Mount Calm",
    "lng": -96.88174557311224,
    "lat": 31.75655953071136
  },
  {
    "name": "Mount Calvary",
    "lng": -88.24604117253804,
    "lat": 43.826269646311076
  },
  {
    "name": "Mount Carmel",
    "lng": -87.76941030128833,
    "lat": 38.41871199684157
  },
  {
    "name": "Mount Carmel",
    "lng": -84.87534684103164,
    "lat": 39.40758920033023
  },
  {
    "name": "Mount Carmel",
    "lng": -82.66165928648198,
    "lat": 36.56104942711741
  },
  {
    "name": "Mount Carroll",
    "lng": -89.97711407869562,
    "lat": 42.09482809463212
  },
  {
    "name": "Mount Chase",
    "lng": -68.50007690718381,
    "lat": 46.080136827971366
  },
  {
    "name": "Mount Clare",
    "lng": -89.82534628631308,
    "lat": 39.10021360330652
  },
  {
    "name": "Mount Clemens",
    "lng": -82.882061778442,
    "lat": 42.59759270398271
  },
  {
    "name": "Mount Cory",
    "lng": -83.82349708403957,
    "lat": 40.93479883051583
  },
  {
    "name": "Mount Crawford",
    "lng": -78.94127827940954,
    "lat": 38.36004239775362
  },
  {
    "name": "Mount Crested Butte",
    "lng": -106.96060474573201,
    "lat": 38.90836274547443
  },
  {
    "name": "Mount Croghan",
    "lng": -80.22638914956315,
    "lat": 34.76974213605609
  },
  {
    "name": "Mount Desert",
    "lng": -68.33208707726072,
    "lat": 44.333678885095715
  },
  {
    "name": "Mount Eaton",
    "lng": -81.7025747695285,
    "lat": 40.6951300592576
  },
  {
    "name": "Mount Enterprise",
    "lng": -94.68270026638429,
    "lat": 31.911710565983565
  },
  {
    "name": "Mount Erie",
    "lng": -88.23239617309595,
    "lat": 38.51464805322004
  },
  {
    "name": "Mount Etna",
    "lng": -85.56205615526065,
    "lat": 40.74171034649037
  },
  {
    "name": "Mount Etna",
    "lng": -85.55565683897436,
    "lat": 40.735187700709986
  },
  {
    "name": "Mount Gilead",
    "lng": -82.82785790227138,
    "lat": 40.55321204977294
  },
  {
    "name": "Mount Gilead",
    "lng": -80.00289722512211,
    "lat": 35.21496454458231
  },
  {
    "name": "Mount Healthy",
    "lng": -84.54739856091221,
    "lat": 39.233563432324374
  },
  {
    "name": "Mount Holly",
    "lng": -81.0526909908506,
    "lat": 35.30126859077652
  },
  {
    "name": "Mount Holly",
    "lng": -81.04955872065489,
    "lat": 35.31218442057071
  },
  {
    "name": "Mount Holly",
    "lng": -81.01895145093589,
    "lat": 35.34102233125888
  },
  {
    "name": "Mount Holly",
    "lng": -81.01277153071844,
    "lat": 35.30476976597126
  },
  {
    "name": "Mount Hope",
    "lng": -97.6629781145348,
    "lat": 37.88147081411596
  },
  {
    "name": "Mount Hope",
    "lng": -97.66271492147882,
    "lat": 37.8727900198059
  },
  {
    "name": "Mount Hope",
    "lng": -97.65544542100416,
    "lat": 37.88380954663245
  },
  {
    "name": "Mount Hope",
    "lng": -97.64470266004568,
    "lat": 37.878541635829315
  },
  {
    "name": "Mount Hope",
    "lng": -90.85910693443189,
    "lat": 42.96958529886412
  },
  {
    "name": "Mount Hope",
    "lng": -81.17204615443815,
    "lat": 37.900327189660224
  },
  {
    "name": "Mount Horeb",
    "lng": -89.73116079887238,
    "lat": 43.005949047670555
  },
  {
    "name": "Mount Jackson",
    "lng": -78.64990026282862,
    "lat": 38.739532480867204
  },
  {
    "name": "Mount Joy",
    "lng": -76.50766482309257,
    "lat": 40.11050792853439
  },
  {
    "name": "Mount Juliet",
    "lng": -86.51708484806387,
    "lat": 36.20301409657994
  },
  {
    "name": "Mount Kisco",
    "lng": -73.72821399402093,
    "lat": 41.20186873002856
  },
  {
    "name": "Mount Lebanon",
    "lng": -93.0499232059217,
    "lat": 32.505249596315586
  },
  {
    "name": "Mount Leonard",
    "lng": -93.39451543832617,
    "lat": 39.12544694968771
  },
  {
    "name": "Mount Moriah",
    "lng": -93.79706496940301,
    "lat": 40.32925568337229
  },
  {
    "name": "Mount Morris",
    "lng": -89.42976905738485,
    "lat": 42.048839838254395
  },
  {
    "name": "Mount Morris",
    "lng": -89.41097411773431,
    "lat": 42.04189917074007
  },
  {
    "name": "Mount Morris",
    "lng": -83.69886298619134,
    "lat": 43.11734347486359
  },
  {
    "name": "Mount Morris",
    "lng": -77.87522183893192,
    "lat": 42.72397076485063
  },
  {
    "name": "Mount Olive",
    "lng": -89.750064795679,
    "lat": 39.07297465924955
  },
  {
    "name": "Mount Olive",
    "lng": -89.74963220942215,
    "lat": 39.072328038911216
  },
  {
    "name": "Mount Olive",
    "lng": -89.74810035571912,
    "lat": 39.07251618513619
  },
  {
    "name": "Mount Olive",
    "lng": -89.72768502161608,
    "lat": 39.07266167261778
  },
  {
    "name": "Mount Olive",
    "lng": -89.66087270702012,
    "lat": 31.76082946217563
  },
  {
    "name": "Mount Olive",
    "lng": -78.06783473404758,
    "lat": 35.1977916520164
  },
  {
    "name": "Mount Olivet",
    "lng": -84.03721357299526,
    "lat": 38.531755736370116
  },
  {
    "name": "Mount Orab",
    "lng": -83.9239442763695,
    "lat": 39.027990336976835
  },
  {
    "name": "Mount Penn",
    "lng": -75.88972452879325,
    "lat": 40.32858247647741
  },
  {
    "name": "Mount Pleasant",
    "lng": -111.455820325925,
    "lat": 39.540714005969505
  },
  {
    "name": "Mount Pleasant",
    "lng": -94.97168706372231,
    "lat": 33.161264913350585
  },
  {
    "name": "Mount Pleasant",
    "lng": -91.7770936472534,
    "lat": 35.981850097469874
  },
  {
    "name": "Mount Pleasant",
    "lng": -91.58518280806022,
    "lat": 40.97938506417917
  },
  {
    "name": "Mount Pleasant",
    "lng": -91.54475586184611,
    "lat": 40.961978755604726
  },
  {
    "name": "Mount Pleasant",
    "lng": -87.88376838149638,
    "lat": 42.711662793342654
  },
  {
    "name": "Mount Pleasant",
    "lng": -87.83203349718747,
    "lat": 42.697221703296904
  },
  {
    "name": "Mount Pleasant",
    "lng": -87.82028798727143,
    "lat": 42.75350408073881
  },
  {
    "name": "Mount Pleasant",
    "lng": -87.81612919987711,
    "lat": 42.692578584420666
  },
  {
    "name": "Mount Pleasant",
    "lng": -87.18817091927711,
    "lat": 35.54839139018404
  },
  {
    "name": "Mount Pleasant",
    "lng": -84.77578011883499,
    "lat": 43.59659962704127
  },
  {
    "name": "Mount Pleasant",
    "lng": -80.79958140335981,
    "lat": 40.17571167004545
  },
  {
    "name": "Mount Pleasant",
    "lng": -80.45363131263494,
    "lat": 35.40553345551992
  },
  {
    "name": "Mount Pleasant",
    "lng": -80.4445572096772,
    "lat": 35.409379670658325
  },
  {
    "name": "Mount Pleasant",
    "lng": -80.43389095283473,
    "lat": 35.393851657583845
  },
  {
    "name": "Mount Pleasant",
    "lng": -79.8477683333333,
    "lat": 32.81641266738194
  },
  {
    "name": "Mount Pleasant",
    "lng": -79.83046650263545,
    "lat": 32.85028119280051
  },
  {
    "name": "Mount Pleasant",
    "lng": -79.54336259900562,
    "lat": 40.150932499212516
  },
  {
    "name": "Mount Prospect",
    "lng": -87.93698158668667,
    "lat": 42.06531801911004
  },
  {
    "name": "Mount Pulaski",
    "lng": -89.28380488220634,
    "lat": 40.01010736937195
  },
  {
    "name": "Mount Rainier",
    "lng": -76.9644524331002,
    "lat": 38.942401963296135
  },
  {
    "name": "Mount Sterling",
    "lng": -91.93674684201457,
    "lat": 40.6181035674174
  },
  {
    "name": "Mount Sterling",
    "lng": -90.92921343736133,
    "lat": 43.31624439101601
  },
  {
    "name": "Mount Sterling",
    "lng": -90.76411475985195,
    "lat": 39.985353505560006
  },
  {
    "name": "Mount Sterling",
    "lng": -83.94728955649441,
    "lat": 38.06487398504773
  },
  {
    "name": "Mount Sterling",
    "lng": -83.26833176890183,
    "lat": 39.71820644227273
  },
  {
    "name": "Mount Summit",
    "lng": -85.38613121230163,
    "lat": 40.00345864646915
  },
  {
    "name": "Mount Union",
    "lng": -91.39099410711378,
    "lat": 41.057760358391405
  },
  {
    "name": "Mount Vernon",
    "lng": -122.31147712453456,
    "lat": 48.420324623835526
  },
  {
    "name": "Mount Vernon",
    "lng": -98.26094664070897,
    "lat": 43.71327262647203
  },
  {
    "name": "Mount Vernon",
    "lng": -95.22532013688293,
    "lat": 33.17591578010509
  },
  {
    "name": "Mount Vernon",
    "lng": -93.81888954457916,
    "lat": 37.10463864796157
  },
  {
    "name": "Mount Vernon",
    "lng": -93.81215705983524,
    "lat": 37.08768153708022
  },
  {
    "name": "Mount Vernon",
    "lng": -92.12406481029103,
    "lat": 35.22359133777838
  },
  {
    "name": "Mount Vernon",
    "lng": -91.42428775683872,
    "lat": 41.92314457336434
  },
  {
    "name": "Mount Vernon",
    "lng": -88.91731113134927,
    "lat": 38.314024669883224
  },
  {
    "name": "Mount Vernon",
    "lng": -88.906409,
    "lat": 38.2950063338301
  },
  {
    "name": "Mount Vernon",
    "lng": -88.01170320238194,
    "lat": 31.091035527278233
  },
  {
    "name": "Mount Vernon",
    "lng": -87.89537876902668,
    "lat": 37.93672948009072
  },
  {
    "name": "Mount Vernon",
    "lng": -84.34307677437305,
    "lat": 37.369609639288406
  },
  {
    "name": "Mount Vernon",
    "lng": -82.59534091560147,
    "lat": 32.183187326916894
  },
  {
    "name": "Mount Vernon",
    "lng": -82.49666513052547,
    "lat": 40.40894808959335
  },
  {
    "name": "Mount Vernon",
    "lng": -82.47332900518506,
    "lat": 40.384864881840755
  },
  {
    "name": "Mount Vernon",
    "lng": -73.82908386037857,
    "lat": 40.91361629944006
  },
  {
    "name": "Mount Vernon",
    "lng": -69.9622614668654,
    "lat": 44.46480428294765
  },
  {
    "name": "Mount Victory",
    "lng": -83.52027610621163,
    "lat": 40.53351726685095
  },
  {
    "name": "Mount Washington",
    "lng": -85.58816875056853,
    "lat": 38.03950317560202
  },
  {
    "name": "Mount Washington",
    "lng": -85.54948893452251,
    "lat": 38.04494013124422
  },
  {
    "name": "Mount Washington",
    "lng": -73.46622356823349,
    "lat": 42.09397454940669
  },
  {
    "name": "Mount Wolf",
    "lng": -76.7052751412604,
    "lat": 40.061659045570714
  },
  {
    "name": "Mount Zion",
    "lng": -88.88315364322682,
    "lat": 39.77931988705593
  },
  {
    "name": "Mount Zion",
    "lng": -85.18007464834571,
    "lat": 33.64078381416403
  },
  {
    "name": "Mountain",
    "lng": -97.86491549859325,
    "lat": 48.68408625747773
  },
  {
    "name": "Mountain Brook",
    "lng": -86.7401892922736,
    "lat": 33.487034641552924
  },
  {
    "name": "Mountain City",
    "lng": -97.8906987681459,
    "lat": 30.039039836823285
  },
  {
    "name": "Mountain City",
    "lng": -83.38537002962485,
    "lat": 34.919631654746375
  },
  {
    "name": "Mountain City",
    "lng": -81.80493568390797,
    "lat": 36.46936295646791
  },
  {
    "name": "Mountain Grove",
    "lng": -92.2660355994924,
    "lat": 37.13410761923104
  },
  {
    "name": "Mountain Home",
    "lng": -115.6969745402197,
    "lat": 43.132437096864
  },
  {
    "name": "Mountain Home",
    "lng": -115.68709935454196,
    "lat": 43.11503240048797
  },
  {
    "name": "Mountain Home",
    "lng": -92.39523522385153,
    "lat": 36.36656701889458
  },
  {
    "name": "Mountain Home",
    "lng": -92.38437418424441,
    "lat": 36.33439815262389
  },
  {
    "name": "Mountain Home",
    "lng": -92.38435340896477,
    "lat": 36.33440474735141
  },
  {
    "name": "Mountain Home",
    "lng": -92.34501448063841,
    "lat": 36.35833219612453
  },
  {
    "name": "Mountain Iron",
    "lng": -92.62540455488546,
    "lat": 47.54777177828628
  },
  {
    "name": "Mountain Lake",
    "lng": -94.92777868759669,
    "lat": 43.94067029926743
  },
  {
    "name": "Mountain Lake Park",
    "lng": -79.38101776497989,
    "lat": 39.400520067697805
  },
  {
    "name": "Mountain Park",
    "lng": -98.95230608935486,
    "lat": 34.698841852881735
  },
  {
    "name": "Mountain Park",
    "lng": -84.41322520573216,
    "lat": 34.08304027309494
  },
  {
    "name": "Mountain Pine",
    "lng": -93.17256175850433,
    "lat": 34.56930964977965
  },
  {
    "name": "Mountain Springs",
    "lng": -115.50540038829618,
    "lat": 36.01904945255351
  },
  {
    "name": "Mountain View",
    "lng": -122.08814300156637,
    "lat": 37.36296430924178
  },
  {
    "name": "Mountain View",
    "lng": -122.07956516373055,
    "lat": 37.40051242820895
  },
  {
    "name": "Mountain View",
    "lng": -110.33408488198732,
    "lat": 41.27232519283677
  },
  {
    "name": "Mountain View",
    "lng": -105.05673714953997,
    "lat": 39.77476565112425
  },
  {
    "name": "Mountain View",
    "lng": -98.75019089842553,
    "lat": 35.09901618250944
  },
  {
    "name": "Mountain View",
    "lng": -92.10428287281962,
    "lat": 35.86327048690462
  },
  {
    "name": "Mountain View",
    "lng": -92.10199709227996,
    "lat": 35.8865017279281
  },
  {
    "name": "Mountain View",
    "lng": -91.70181158731616,
    "lat": 36.993613080878106
  },
  {
    "name": "Mountain View CDP",
    "lng": -155.1598379544031,
    "lat": 19.535699821493697
  },
  {
    "name": "Mountain Village",
    "lng": -163.7153393180507,
    "lat": 62.0944468701911
  },
  {
    "name": "Mountain Village",
    "lng": -107.85790345966642,
    "lat": 37.93281920749534
  },
  {
    "name": "Mountainair",
    "lng": -106.24320397710518,
    "lat": 34.51977728669025
  },
  {
    "name": "Mountainaire",
    "lng": -111.64932631181799,
    "lat": 35.09207408709106
  },
  {
    "name": "Mountainburg",
    "lng": -94.16805798360704,
    "lat": 35.63611190216598
  },
  {
    "name": "Mountainburg",
    "lng": -94.16802393069803,
    "lat": 35.636161839934104
  },
  {
    "name": "Mountainhome",
    "lng": -75.26336180037335,
    "lat": 41.17781548524534
  },
  {
    "name": "Mountlake Terrace",
    "lng": -122.30765707157273,
    "lat": 47.792094191323756
  },
  {
    "name": "Moville",
    "lng": -96.06793921975428,
    "lat": 42.490570368939125
  },
  {
    "name": "Moville",
    "lng": -96.06327549645218,
    "lat": 42.48093851825438
  },
  {
    "name": "Moweaqua",
    "lng": -89.0380045906866,
    "lat": 39.62483059066038
  },
  {
    "name": "Moweaqua",
    "lng": -89.01895393088017,
    "lat": 39.626476501165136
  },
  {
    "name": "Mowrystown",
    "lng": -83.75181035656712,
    "lat": 39.04056614991019
  },
  {
    "name": "Moxee",
    "lng": -120.39491758460032,
    "lat": 46.5627126147218
  },
  {
    "name": "Moyie Springs",
    "lng": -116.19541935151572,
    "lat": 48.72474724010285
  },
  {
    "name": "Moyock",
    "lng": -76.17435677055688,
    "lat": 36.52392203539358
  },
  {
    "name": "Mud Lake",
    "lng": -112.47950344610751,
    "lat": 43.84285503424846
  },
  {
    "name": "Muddy",
    "lng": -88.51380421428526,
    "lat": 37.7655584728142
  },
  {
    "name": "Muenster",
    "lng": -97.38734285745758,
    "lat": 33.65854336660887
  },
  {
    "name": "Muir",
    "lng": -84.93592084023288,
    "lat": 42.99717008446343
  },
  {
    "name": "Mukilteo",
    "lng": -122.31048509534332,
    "lat": 47.91619637379757
  },
  {
    "name": "Mukwonago",
    "lng": -88.36178569420439,
    "lat": 42.86271752901462
  },
  {
    "name": "Mukwonago",
    "lng": -88.32732630899794,
    "lat": 42.856728044223196
  },
  {
    "name": "Mukwonago",
    "lng": -88.30707991826033,
    "lat": 42.839058956490376
  },
  {
    "name": "Mukwonago",
    "lng": -88.29477005835965,
    "lat": 42.873964570355504
  },
  {
    "name": "Mulberry",
    "lng": -94.623678490751,
    "lat": 37.55578158497133
  },
  {
    "name": "Mulberry",
    "lng": -94.07328748651734,
    "lat": 35.510236531346
  },
  {
    "name": "Mulberry",
    "lng": -86.66745475049404,
    "lat": 40.34581744925387
  },
  {
    "name": "Mulberry",
    "lng": -81.98765216286378,
    "lat": 27.90594175020275
  },
  {
    "name": "Mulberry Grove",
    "lng": -89.26619541452982,
    "lat": 38.92451219733638
  },
  {
    "name": "Muldraugh",
    "lng": -85.99146050402022,
    "lat": 37.93655896046076
  },
  {
    "name": "Muldrow",
    "lng": -94.6086849071715,
    "lat": 35.39060026200015
  },
  {
    "name": "Muldrow",
    "lng": -94.60152041569035,
    "lat": 35.40384827506567
  },
  {
    "name": "Mule Barn",
    "lng": -96.31206666845503,
    "lat": 36.21773570008303
  },
  {
    "name": "Muleshoe",
    "lng": -102.72833745914386,
    "lat": 34.22923218905962
  },
  {
    "name": "Mulga",
    "lng": -86.979438025503,
    "lat": 33.55530090107398
  },
  {
    "name": "Mulga",
    "lng": -86.97322557102665,
    "lat": 33.55286514022334
  },
  {
    "name": "Mulhall",
    "lng": -97.36113458295154,
    "lat": 36.092460834473314
  },
  {
    "name": "Mulhall",
    "lng": -97.33442796839553,
    "lat": 36.11738674180377
  },
  {
    "name": "Mullan",
    "lng": -115.79635130500552,
    "lat": 47.468759109818414
  },
  {
    "name": "Mullen",
    "lng": -101.04445234164181,
    "lat": 42.043213995062985
  },
  {
    "name": "Mullens",
    "lng": -81.3857545880581,
    "lat": 37.580671678330205
  },
  {
    "name": "Mulliken",
    "lng": -84.89636232655634,
    "lat": 42.7629865691479
  },
  {
    "name": "Mullins",
    "lng": -79.25349123819598,
    "lat": 34.204224025732685
  },
  {
    "name": "Mullinville",
    "lng": -99.47555005559812,
    "lat": 37.5889934350072
  },
  {
    "name": "Mulvane",
    "lng": -97.24169501568441,
    "lat": 37.483839045123325
  },
  {
    "name": "Mulvane",
    "lng": -97.23675725820073,
    "lat": 37.506141466338505
  },
  {
    "name": "Mulvane",
    "lng": -97.22227091655886,
    "lat": 37.489279340472265
  },
  {
    "name": "Mulvane",
    "lng": -97.22219907878647,
    "lat": 37.49132989649729
  },
  {
    "name": "Muncie",
    "lng": -87.8429686369674,
    "lat": 40.11605429712278
  },
  {
    "name": "Muncie",
    "lng": -85.38998596633634,
    "lat": 40.19822579632563
  },
  {
    "name": "Muncy",
    "lng": -76.78534290950613,
    "lat": 41.20201917469409
  },
  {
    "name": "Munday",
    "lng": -99.62390543202626,
    "lat": 33.44711406123799
  },
  {
    "name": "Mundelein",
    "lng": -88.05397816916535,
    "lat": 42.262881473278355
  },
  {
    "name": "Mundelein",
    "lng": -88.00647028710505,
    "lat": 42.269336253842184
  },
  {
    "name": "Mundelein",
    "lng": -87.9414342193654,
    "lat": 42.253103111384895
  },
  {
    "name": "Munden",
    "lng": -97.5384106280789,
    "lat": 39.91272531230569
  },
  {
    "name": "Munds Park",
    "lng": -111.63177263222373,
    "lat": 34.938232552045264
  },
  {
    "name": "Munford",
    "lng": -89.81316531764917,
    "lat": 35.443943992399944
  },
  {
    "name": "Munford",
    "lng": -85.95475402899054,
    "lat": 33.52641480104995
  },
  {
    "name": "Munford",
    "lng": -85.93989578659047,
    "lat": 33.54600375958625
  },
  {
    "name": "Munfordville",
    "lng": -85.89966189788328,
    "lat": 37.27979974710488
  },
  {
    "name": "Munich",
    "lng": -98.83213187445956,
    "lat": 48.669732707221385
  },
  {
    "name": "Munising",
    "lng": -86.64445017196115,
    "lat": 46.41709356439339
  },
  {
    "name": "Munnsville",
    "lng": -75.58680544412078,
    "lat": 42.9765840039132
  },
  {
    "name": "Munroe Falls",
    "lng": -81.43425294965691,
    "lat": 41.13867792217836
  },
  {
    "name": "Munsey Park",
    "lng": -73.67986276321356,
    "lat": 40.799006418769906
  },
  {
    "name": "Munster",
    "lng": -87.50405898022571,
    "lat": 41.546913640708766
  },
  {
    "name": "Murchison",
    "lng": -95.75606839359493,
    "lat": 32.27517263731073
  },
  {
    "name": "Murdo",
    "lng": -100.71414949552764,
    "lat": 43.88936082409575
  },
  {
    "name": "Murdock",
    "lng": -96.28091069040265,
    "lat": 40.926121749332914
  },
  {
    "name": "Murdock",
    "lng": -95.39392401921697,
    "lat": 45.22340111521205
  },
  {
    "name": "Murfreesboro",
    "lng": -93.68330175785363,
    "lat": 34.053926270662366
  },
  {
    "name": "Murfreesboro",
    "lng": -93.6765755015745,
    "lat": 34.06565067652605
  },
  {
    "name": "Murfreesboro",
    "lng": -86.43863572465999,
    "lat": 35.864668244715354
  },
  {
    "name": "Murfreesboro",
    "lng": -77.09835013672415,
    "lat": 36.44030457687517
  },
  {
    "name": "Murfreesboro",
    "lng": -77.079964525674,
    "lat": 36.46344266466161
  },
  {
    "name": "Murfreesboro",
    "lng": -77.07545253377623,
    "lat": 36.43896563283295
  },
  {
    "name": "Murphy",
    "lng": -90.48588254413448,
    "lat": 38.492056981923355
  },
  {
    "name": "Murphy",
    "lng": -84.02784828126275,
    "lat": 35.09246690985435
  },
  {
    "name": "Murphy",
    "lng": -84.01447955024706,
    "lat": 35.07221377668591
  },
  {
    "name": "Murphysboro",
    "lng": -89.33209988435745,
    "lat": 37.767896097713376
  },
  {
    "name": "Murray",
    "lng": -111.88738757287835,
    "lat": 40.64974852175266
  },
  {
    "name": "Murray",
    "lng": -95.9267867159636,
    "lat": 40.91632118367263
  },
  {
    "name": "Murray",
    "lng": -95.92665481368752,
    "lat": 40.91346888698708
  },
  {
    "name": "Murray",
    "lng": -93.94882018717163,
    "lat": 41.041345035599235
  },
  {
    "name": "Murray",
    "lng": -88.32047815180599,
    "lat": 36.613780845834874
  },
  {
    "name": "Murray City",
    "lng": -82.16772309610457,
    "lat": 39.509695726237865
  },
  {
    "name": "Murray Hill",
    "lng": -85.58691486224672,
    "lat": 38.29074411612121
  },
  {
    "name": "Murrayville",
    "lng": -90.2516817335981,
    "lat": 39.58188811770934
  },
  {
    "name": "Murrells Inlet",
    "lng": -79.05883757452,
    "lat": 33.55590716021598
  },
  {
    "name": "Murrieta",
    "lng": -117.19090345063948,
    "lat": 33.57190056503658
  },
  {
    "name": "Murtaugh",
    "lng": -114.16177118697735,
    "lat": 42.49188468804957
  },
  {
    "name": "Muscatine",
    "lng": -91.06869790745301,
    "lat": 41.41983973438381
  },
  {
    "name": "Muscatine",
    "lng": -91.01396874949698,
    "lat": 41.43895143184105
  },
  {
    "name": "Muscle Shoals",
    "lng": -87.63376116603898,
    "lat": 34.743478504508886
  },
  {
    "name": "Muscle Shoals",
    "lng": -87.60897630883798,
    "lat": 34.793196948777855
  },
  {
    "name": "Muscle Shoals",
    "lng": -87.60897630883797,
    "lat": 34.79319694877783
  },
  {
    "name": "Muscoda",
    "lng": -90.43370414292154,
    "lat": 43.18687069964106
  },
  {
    "name": "Muscotah",
    "lng": -95.52033896509992,
    "lat": 39.55347560310363
  },
  {
    "name": "Muskego",
    "lng": -88.12897850282131,
    "lat": 42.88593870023207
  },
  {
    "name": "Muskegon",
    "lng": -86.25644197416332,
    "lat": 43.22819234642164
  },
  {
    "name": "Muskegon Heights",
    "lng": -86.24208927601154,
    "lat": 43.20236459185904
  },
  {
    "name": "Muskogee",
    "lng": -95.35739880004608,
    "lat": 35.74332431492534
  },
  {
    "name": "Mustang",
    "lng": -97.72460045295196,
    "lat": 35.39168472231338
  },
  {
    "name": "Mustang",
    "lng": -96.43043133507923,
    "lat": 32.013401964258435
  },
  {
    "name": "Mustang Ridge",
    "lng": -97.68507658057237,
    "lat": 30.05743615539565
  },
  {
    "name": "Muttontown",
    "lng": -73.53641091919818,
    "lat": 40.82540491258017
  },
  {
    "name": "Mutual",
    "lng": -99.16793254584265,
    "lat": 36.23043058282025
  },
  {
    "name": "Mutual",
    "lng": -83.63700360244115,
    "lat": 40.07944737224039
  },
  {
    "name": "Myersville",
    "lng": -77.56876813797449,
    "lat": 39.506447019372835
  },
  {
    "name": "Mylo",
    "lng": -99.61783963632743,
    "lat": 48.63592673683616
  },
  {
    "name": "Myrtle",
    "lng": -93.16302041405807,
    "lat": 43.563256019249195
  },
  {
    "name": "Myrtle",
    "lng": -89.11786533352742,
    "lat": 34.560519923129874
  },
  {
    "name": "Myrtle Beach",
    "lng": -78.9460131971275,
    "lat": 33.689298032542084
  },
  {
    "name": "Myrtle Beach",
    "lng": -78.91792525001475,
    "lat": 33.71432599973838
  },
  {
    "name": "Myrtle Beach",
    "lng": -78.91655945680951,
    "lat": 33.71532018569644
  },
  {
    "name": "Myrtle Beach",
    "lng": -78.87491010832848,
    "lat": 33.707315275091986
  },
  {
    "name": "Myrtlewood",
    "lng": -87.94926094130159,
    "lat": 32.25208368060648
  },
  {
    "name": "Mystic",
    "lng": -92.94446786603862,
    "lat": 40.779139692064085
  },
  {
    "name": "Mystic",
    "lng": -71.95487504952841,
    "lat": 41.35738100780148
  },
  {
    "name": "Myton",
    "lng": -110.06253185848419,
    "lat": 40.19326634237489
  },
  {
    "name": "Naalehu CDP",
    "lng": -155.5750950146516,
    "lat": 19.069827671461578
  },
  {
    "name": "Naches",
    "lng": -120.69429920835873,
    "lat": 46.72728393040451
  },
  {
    "name": "Nacogdoches",
    "lng": -94.66474564468405,
    "lat": 31.672140178859973
  },
  {
    "name": "Nacogdoches",
    "lng": -94.65281898171634,
    "lat": 31.61338039533412
  },
  {
    "name": "Nags Head",
    "lng": -75.62670794471647,
    "lat": 35.947025668657595
  },
  {
    "name": "Nahunta",
    "lng": -81.97869792823833,
    "lat": 31.21048757809717
  },
  {
    "name": "Nampa",
    "lng": -116.6359416535874,
    "lat": 43.596836143378574
  },
  {
    "name": "Nampa",
    "lng": -116.61277656368651,
    "lat": 43.57683006521428
  },
  {
    "name": "Nampa",
    "lng": -116.61242457104619,
    "lat": 43.57812678633856
  },
  {
    "name": "Nampa",
    "lng": -116.5624496489036,
    "lat": 43.584258579521034
  },
  {
    "name": "Nampa",
    "lng": -116.53244442769392,
    "lat": 43.552996571647256
  },
  {
    "name": "Nampa",
    "lng": -116.5241743122179,
    "lat": 43.56266043341196
  },
  {
    "name": "Nanawale Estates CDP",
    "lng": -154.9119304222962,
    "lat": 19.504457424808592
  },
  {
    "name": "Nanticoke",
    "lng": -76.00020282020475,
    "lat": 41.20031964774872
  },
  {
    "name": "Napa",
    "lng": -122.34728113308353,
    "lat": 38.31797061461047
  },
  {
    "name": "Napa",
    "lng": -122.33893918446454,
    "lat": 38.324284901912044
  },
  {
    "name": "Napa",
    "lng": -122.30469178482502,
    "lat": 38.26551105371615
  },
  {
    "name": "Napa",
    "lng": -122.30102035947307,
    "lat": 38.298053333080176
  },
  {
    "name": "Napa",
    "lng": -122.27638894464219,
    "lat": 38.32622976631056
  },
  {
    "name": "Napa",
    "lng": -122.22432980318031,
    "lat": 38.22378374288801
  },
  {
    "name": "Napakiak",
    "lng": -161.9955860383237,
    "lat": 60.688883198031135
  },
  {
    "name": "Napaskiak",
    "lng": -161.75824578653098,
    "lat": 60.701976430967775
  },
  {
    "name": "Napavine",
    "lng": -122.90455911993301,
    "lat": 46.5852837849076
  },
  {
    "name": "Naper",
    "lng": -99.09700040560257,
    "lat": 42.96416810608915
  },
  {
    "name": "Naperville",
    "lng": -88.18524607807308,
    "lat": 41.71469869926487
  },
  {
    "name": "Naperville",
    "lng": -88.17014424307935,
    "lat": 41.79959562602959
  },
  {
    "name": "Naperville",
    "lng": -88.16741266313863,
    "lat": 41.799474498933684
  },
  {
    "name": "Naperville",
    "lng": -88.16581308510389,
    "lat": 41.748193159796195
  },
  {
    "name": "Naperville",
    "lng": -88.0967147179853,
    "lat": 41.75660284686018
  },
  {
    "name": "Napier Field",
    "lng": -85.45490145720744,
    "lat": 31.314870078075263
  },
  {
    "name": "Naplate",
    "lng": -88.87825927233413,
    "lat": 41.33207371828935
  },
  {
    "name": "Naples",
    "lng": -109.49126637485041,
    "lat": 40.43173871252997
  },
  {
    "name": "Naples",
    "lng": -97.51303812617296,
    "lat": 44.77164501960295
  },
  {
    "name": "Naples",
    "lng": -94.67771235401149,
    "lat": 33.20294234694825
  },
  {
    "name": "Naples",
    "lng": -90.60690787492794,
    "lat": 39.75345668313694
  },
  {
    "name": "Naples",
    "lng": -81.79350288644628,
    "lat": 26.150569466293486
  },
  {
    "name": "Naples",
    "lng": -77.40225437187412,
    "lat": 42.617483727766285
  },
  {
    "name": "Naples",
    "lng": -70.61440775708823,
    "lat": 43.97066090471916
  },
  {
    "name": "Naples Manor",
    "lng": -81.7252983068306,
    "lat": 26.089160139937093
  },
  {
    "name": "Naples Park",
    "lng": -81.80944492095824,
    "lat": 26.263353896491203
  },
  {
    "name": "Napoleon",
    "lng": -99.76834855080006,
    "lat": 46.50393187460633
  },
  {
    "name": "Napoleon",
    "lng": -94.08565943493004,
    "lat": 39.129450263464264
  },
  {
    "name": "Napoleon",
    "lng": -85.32722411998493,
    "lat": 39.204377692677724
  },
  {
    "name": "Napoleon",
    "lng": -84.12433197723459,
    "lat": 41.39781125483023
  },
  {
    "name": "Napoleonville",
    "lng": -91.02636109701099,
    "lat": 29.938266747540617
  },
  {
    "name": "Naponee",
    "lng": -99.13862695874371,
    "lat": 40.074733982246485
  },
  {
    "name": "Nappanee",
    "lng": -85.99662327052101,
    "lat": 41.44468391381843
  },
  {
    "name": "Nappanee",
    "lng": -85.93718361865666,
    "lat": 41.445634623683176
  },
  {
    "name": "Narka",
    "lng": -97.42689898971935,
    "lat": 39.95999331568728
  },
  {
    "name": "Narragansett",
    "lng": -71.51964159811047,
    "lat": 41.37950141416031
  },
  {
    "name": "Narragansett",
    "lng": -71.51885458895069,
    "lat": 41.38074872223176
  },
  {
    "name": "Narragansett",
    "lng": -71.51742652560777,
    "lat": 41.37733347897251
  },
  {
    "name": "Narragansett",
    "lng": -71.50633465778098,
    "lat": 41.35687977092099
  },
  {
    "name": "Narragansett",
    "lng": -71.50432289806774,
    "lat": 41.39315853636311
  },
  {
    "name": "Narragansett",
    "lng": -71.50321487680003,
    "lat": 41.39062226333556
  },
  {
    "name": "Narragansett",
    "lng": -71.50273599887687,
    "lat": 41.40363115659262
  },
  {
    "name": "Narragansett",
    "lng": -71.50248255576254,
    "lat": 41.40018762880716
  },
  {
    "name": "Narragansett",
    "lng": -71.50195521777873,
    "lat": 41.402189719305895
  },
  {
    "name": "Narragansett",
    "lng": -71.50022526778324,
    "lat": 41.40220364100463
  },
  {
    "name": "Narragansett",
    "lng": -71.49843916599532,
    "lat": 41.403650312196625
  },
  {
    "name": "Narragansett",
    "lng": -71.49763211775199,
    "lat": 41.39208821326486
  },
  {
    "name": "Narragansett",
    "lng": -71.47762067585853,
    "lat": 41.407714477239786
  },
  {
    "name": "Narragansett",
    "lng": -71.44116741915285,
    "lat": 41.43945331705557
  },
  {
    "name": "Narragansett",
    "lng": -71.44069242606402,
    "lat": 41.440918301310106
  },
  {
    "name": "Narragansett",
    "lng": -71.44068100843215,
    "lat": 41.43853998844789
  },
  {
    "name": "Narragansett",
    "lng": -71.44033880743739,
    "lat": 41.44102486430991
  },
  {
    "name": "Narragansett",
    "lng": -71.43920294943196,
    "lat": 41.43972295631268
  },
  {
    "name": "Narragansett",
    "lng": -71.43894588050746,
    "lat": 41.43940419111857
  },
  {
    "name": "Narragansett",
    "lng": -71.43664136864214,
    "lat": 41.44268416757755
  },
  {
    "name": "Narragansett",
    "lng": -71.43639680285739,
    "lat": 41.44148272921957
  },
  {
    "name": "Narragansett",
    "lng": -71.43587090756937,
    "lat": 41.475752814354344
  },
  {
    "name": "Narragansett",
    "lng": -71.4356480296173,
    "lat": 41.442715696956085
  },
  {
    "name": "Narragansett",
    "lng": -71.43514816493389,
    "lat": 41.44224739401856
  },
  {
    "name": "Narrows",
    "lng": -80.80826746421512,
    "lat": 37.331557903906486
  },
  {
    "name": "Nash",
    "lng": -98.05207822085082,
    "lat": 36.664989853282236
  },
  {
    "name": "Nash",
    "lng": -94.12825932947435,
    "lat": 33.44223299401911
  },
  {
    "name": "Nashotah",
    "lng": -88.40372814546838,
    "lat": 43.093824245511705
  },
  {
    "name": "Nashua",
    "lng": -106.35748150696381,
    "lat": 48.13385411419565
  },
  {
    "name": "Nashua",
    "lng": -96.30587593455519,
    "lat": 46.037714226868125
  },
  {
    "name": "Nashua",
    "lng": -92.54166778925428,
    "lat": 42.95029753179979
  },
  {
    "name": "Nashua",
    "lng": -71.49089422362715,
    "lat": 42.7491506885001
  },
  {
    "name": "Nashville",
    "lng": -98.42267916881758,
    "lat": 37.438150988420645
  },
  {
    "name": "Nashville",
    "lng": -93.85156597663092,
    "lat": 33.94178936058851
  },
  {
    "name": "Nashville",
    "lng": -89.37705378239126,
    "lat": 38.35151335207196
  },
  {
    "name": "Nashville",
    "lng": -86.23757074327249,
    "lat": 39.20700604562251
  },
  {
    "name": "Nashville",
    "lng": -86.22867308582454,
    "lat": 39.1943255286337
  },
  {
    "name": "Nashville",
    "lng": -85.09409528240981,
    "lat": 42.603072304256905
  },
  {
    "name": "Nashville",
    "lng": -83.27039244033148,
    "lat": 31.185956082017842
  },
  {
    "name": "Nashville",
    "lng": -83.26870795940216,
    "lat": 31.184464837526175
  },
  {
    "name": "Nashville",
    "lng": -83.25068660322822,
    "lat": 31.22578453717706
  },
  {
    "name": "Nashville",
    "lng": -83.24832901287174,
    "lat": 31.20543228537688
  },
  {
    "name": "Nashville",
    "lng": -82.11299649969042,
    "lat": 40.59578493704259
  },
  {
    "name": "Nashville",
    "lng": -77.96614504196923,
    "lat": 35.98880628610724
  },
  {
    "name": "Nashville",
    "lng": -77.95611573961199,
    "lat": 35.96893353575764
  },
  {
    "name": "Nashville",
    "lng": -77.95399510086062,
    "lat": 35.95274995416257
  },
  {
    "name": "Nashville",
    "lng": -77.93160659604374,
    "lat": 35.973594575788965
  },
  {
    "name": "Nashville",
    "lng": -76.85214661427591,
    "lat": 39.89908852424283
  },
  {
    "name": "Nashwauk",
    "lng": -93.16653322736568,
    "lat": 47.380412404126155
  },
  {
    "name": "Nason",
    "lng": -88.96621250249538,
    "lat": 38.17597518343956
  },
  {
    "name": "Nassau",
    "lng": -96.44179109031712,
    "lat": 45.0677209317463
  },
  {
    "name": "Nassau",
    "lng": -73.6107494297766,
    "lat": 42.51396360183454
  },
  {
    "name": "Nassau Bay",
    "lng": -95.08646109063183,
    "lat": 29.544565234318956
  },
  {
    "name": "Nassawadox",
    "lng": -75.85834775383033,
    "lat": 37.47733731187259
  },
  {
    "name": "Natalia",
    "lng": -98.85236863974511,
    "lat": 29.187710725983248
  },
  {
    "name": "Natchez",
    "lng": -93.04560337650376,
    "lat": 31.674331808958424
  },
  {
    "name": "Natchez",
    "lng": -91.38805418633157,
    "lat": 31.548556746753555
  },
  {
    "name": "Natchitoches",
    "lng": -93.09826535327595,
    "lat": 31.731274047578736
  },
  {
    "name": "Natchitoches",
    "lng": -93.05600732220807,
    "lat": 31.75767171436469
  },
  {
    "name": "Natick",
    "lng": -71.34970928984201,
    "lat": 42.284722520700704
  },
  {
    "name": "National City",
    "lng": -117.09817763424066,
    "lat": 32.66538096887868
  },
  {
    "name": "Natoma",
    "lng": -99.0246195269893,
    "lat": 39.18864311675967
  },
  {
    "name": "Natural Bridge",
    "lng": -87.60115935459415,
    "lat": 34.09025776765472
  },
  {
    "name": "Naturita",
    "lng": -108.56834795168596,
    "lat": 38.218476795765206
  },
  {
    "name": "Naturita",
    "lng": -108.55485214099559,
    "lat": 38.22052336109002
  },
  {
    "name": "Naugatuck",
    "lng": -73.0519218657143,
    "lat": 41.48905084177757
  },
  {
    "name": "Nauvoo",
    "lng": -91.38284869198458,
    "lat": 40.54563241452796
  },
  {
    "name": "Nauvoo",
    "lng": -87.48753338325123,
    "lat": 33.98734514562491
  },
  {
    "name": "Navarre",
    "lng": -81.51488633293033,
    "lat": 40.72442383326246
  },
  {
    "name": "Navarro",
    "lng": -96.38253364510716,
    "lat": 32.00080071081732
  },
  {
    "name": "Navasota",
    "lng": -96.08958420380051,
    "lat": 30.38743043345588
  },
  {
    "name": "Navassa",
    "lng": -78.08938537950715,
    "lat": 34.295886684800024
  },
  {
    "name": "Navassa",
    "lng": -78.02320943700543,
    "lat": 34.28181221712471
  },
  {
    "name": "Naylor",
    "lng": -90.60557531412378,
    "lat": 36.57446963309233
  },
  {
    "name": "Nazareth",
    "lng": -102.10523041410606,
    "lat": 34.535952703611265
  },
  {
    "name": "Nazareth",
    "lng": -102.10228273866902,
    "lat": 34.54202406709041
  },
  {
    "name": "Nebo",
    "lng": -90.78807246494806,
    "lat": 39.44208568894833
  },
  {
    "name": "Nebo",
    "lng": -87.64166781872214,
    "lat": 37.38333290954458
  },
  {
    "name": "Nebraska",
    "lng": -99.81888079658687,
    "lat": 41.54326270119825
  },
  {
    "name": "Nebraska City",
    "lng": -95.86135810033561,
    "lat": 40.67553178721511
  },
  {
    "name": "Necedah",
    "lng": -90.07164259898174,
    "lat": 44.02390390833117
  },
  {
    "name": "Neche",
    "lng": -97.55162729641766,
    "lat": 48.98303706121708
  },
  {
    "name": "Neck City",
    "lng": -94.44399412528162,
    "lat": 37.2567499441878
  },
  {
    "name": "Nectar",
    "lng": -86.64586471121143,
    "lat": 33.96796965057631
  },
  {
    "name": "Nectar",
    "lng": -86.64139703213597,
    "lat": 33.975315408031456
  },
  {
    "name": "Nectar",
    "lng": -86.6340892693633,
    "lat": 33.968178456964466
  },
  {
    "name": "Nederland",
    "lng": -105.50650424350768,
    "lat": 39.96303971646782
  },
  {
    "name": "Nederland",
    "lng": -94.00101751536711,
    "lat": 29.971045737809476
  },
  {
    "name": "Needham",
    "lng": -88.33656267227941,
    "lat": 31.987000334029354
  },
  {
    "name": "Needles",
    "lng": -114.61852201524493,
    "lat": 34.81261556156735
  },
  {
    "name": "Needville",
    "lng": -95.83868252416168,
    "lat": 29.395695687163542
  },
  {
    "name": "Neelyville",
    "lng": -90.51364704721857,
    "lat": 36.556328521630945
  },
  {
    "name": "Neenah",
    "lng": -88.51912158037514,
    "lat": 44.13916852471901
  },
  {
    "name": "Neenah",
    "lng": -88.51735115489367,
    "lat": 44.16791470564847
  },
  {
    "name": "Neenah",
    "lng": -88.47522383542267,
    "lat": 44.16767441323976
  },
  {
    "name": "Neenah",
    "lng": -88.44311387679254,
    "lat": 44.156112163206366
  },
  {
    "name": "Neeses",
    "lng": -81.12454463248305,
    "lat": 33.536635652508444
  },
  {
    "name": "Negaunee",
    "lng": -87.59643895570161,
    "lat": 46.49657344504132
  },
  {
    "name": "Nehawka",
    "lng": -95.99000673752046,
    "lat": 40.82961124585282
  },
  {
    "name": "Neihart",
    "lng": -110.7360561199081,
    "lat": 46.93521311193421
  },
  {
    "name": "Neillsville",
    "lng": -90.59040129279758,
    "lat": 44.560465575077785
  },
  {
    "name": "Nekoma",
    "lng": -98.37520473213252,
    "lat": 48.57621318852015
  },
  {
    "name": "Nekoosa",
    "lng": -89.9074486312146,
    "lat": 44.313439546511326
  },
  {
    "name": "Neligh",
    "lng": -98.03014617531838,
    "lat": 42.12881429400245
  },
  {
    "name": "Nellie",
    "lng": -82.07074519024899,
    "lat": 40.33850739900189
  },
  {
    "name": "Nelliston",
    "lng": -74.60992606621278,
    "lat": 42.93174035693568
  },
  {
    "name": "Nelson",
    "lng": -98.06869655800098,
    "lat": 40.201191225675366
  },
  {
    "name": "Nelson",
    "lng": -95.26496660762204,
    "lat": 45.88653052793238
  },
  {
    "name": "Nelson",
    "lng": -93.03085829756606,
    "lat": 38.99454329487857
  },
  {
    "name": "Nelson",
    "lng": -92.00437129831312,
    "lat": 44.42134050584913
  },
  {
    "name": "Nelson",
    "lng": -89.6035349041565,
    "lat": 41.796093822866375
  },
  {
    "name": "Nelson",
    "lng": -84.37078335005229,
    "lat": 34.379099102148345
  },
  {
    "name": "Nelsonville",
    "lng": -89.3091545150484,
    "lat": 44.49377664604849
  },
  {
    "name": "Nelsonville",
    "lng": -82.2216045966671,
    "lat": 39.455925024103585
  },
  {
    "name": "Nelsonville",
    "lng": -73.94655880636459,
    "lat": 41.42909015727142
  },
  {
    "name": "Nemaha",
    "lng": -95.67596535633578,
    "lat": 40.33880104849364
  },
  {
    "name": "Nemaha",
    "lng": -95.08708455273646,
    "lat": 42.51539339059198
  },
  {
    "name": "Nenana",
    "lng": -149.08511367918229,
    "lat": 64.53928424186539
  },
  {
    "name": "Nenzel",
    "lng": -101.10180370415688,
    "lat": 42.9273542764566
  },
  {
    "name": "Neodesha",
    "lng": -95.70838985159496,
    "lat": 37.407393904960884
  },
  {
    "name": "Neodesha",
    "lng": -95.68191029866465,
    "lat": 37.42511683976966
  },
  {
    "name": "Neodesha",
    "lng": -95.67029846126984,
    "lat": 37.41021933622079
  },
  {
    "name": "Neoga",
    "lng": -88.45069346674629,
    "lat": 39.32143225288596
  },
  {
    "name": "Neoga",
    "lng": -88.43126623684718,
    "lat": 39.32943089805293
  },
  {
    "name": "Neola",
    "lng": -95.6172639128712,
    "lat": 41.44968473747665
  },
  {
    "name": "Neosho",
    "lng": -88.52643666666665,
    "lat": 43.305748667209414
  },
  {
    "name": "Neosho",
    "lng": -88.51925206192979,
    "lat": 43.31014202473186
  },
  {
    "name": "Neosho Falls",
    "lng": -95.55544980858483,
    "lat": 38.005636322030426
  },
  {
    "name": "Neosho Rapids",
    "lng": -95.99189202544295,
    "lat": 38.368471771023394
  },
  {
    "name": "Nephi",
    "lng": -111.83221140494118,
    "lat": 39.707307900132946
  },
  {
    "name": "Neponset",
    "lng": -89.78944860278834,
    "lat": 41.296801700551306
  },
  {
    "name": "Neptune Beach",
    "lng": -81.38187437654176,
    "lat": 30.31615929520565
  },
  {
    "name": "Nerstrand",
    "lng": -93.06380689475712,
    "lat": 44.342956010436794
  },
  {
    "name": "Nesbitt",
    "lng": -94.44686876671736,
    "lat": 32.58859920447008
  },
  {
    "name": "Nescopeck",
    "lng": -76.21177212429917,
    "lat": 41.052235513011304
  },
  {
    "name": "Neshkoro",
    "lng": -89.2137126311266,
    "lat": 43.965030191162846
  },
  {
    "name": "Nespelem",
    "lng": -118.97232040524747,
    "lat": 48.166940957871496
  },
  {
    "name": "Ness City",
    "lng": -99.90614224113487,
    "lat": 38.45379998598357
  },
  {
    "name": "Netawaka",
    "lng": -95.71888269073358,
    "lat": 39.60294705013601
  },
  {
    "name": "Nettleton",
    "lng": -88.62485794431869,
    "lat": 34.08522197506448
  },
  {
    "name": "Neuse Forest",
    "lng": -76.9378613431115,
    "lat": 34.96344976426984
  },
  {
    "name": "Nevada",
    "lng": -116.6669278689936,
    "lat": 39.41428287221935
  },
  {
    "name": "Nevada",
    "lng": -96.38049566305921,
    "lat": 33.046540265962726
  },
  {
    "name": "Nevada",
    "lng": -94.34931266722882,
    "lat": 37.844585605765566
  },
  {
    "name": "Nevada",
    "lng": -93.46354162089507,
    "lat": 42.01864753157876
  },
  {
    "name": "Nevada",
    "lng": -83.13118630536495,
    "lat": 40.818783666839096
  },
  {
    "name": "Nevada City",
    "lng": -121.03307945673593,
    "lat": 39.27608062946356
  },
  {
    "name": "Nevada City",
    "lng": -121.02211960976472,
    "lat": 39.25887629980089
  },
  {
    "name": "Neville",
    "lng": -84.21257731814883,
    "lat": 38.80984069278944
  },
  {
    "name": "Nevis",
    "lng": -94.84236419234321,
    "lat": 46.96463729171714
  },
  {
    "name": "New Albany",
    "lng": -95.93584605478762,
    "lat": 37.56740384396617
  },
  {
    "name": "New Albany",
    "lng": -89.02009397711807,
    "lat": 34.490888495193175
  },
  {
    "name": "New Albany",
    "lng": -85.82465585299492,
    "lat": 38.30631996135936
  },
  {
    "name": "New Albany",
    "lng": -85.80788050631445,
    "lat": 38.36564926365183
  },
  {
    "name": "New Albany",
    "lng": -82.78424827244032,
    "lat": 40.08037209971236
  },
  {
    "name": "New Albin",
    "lng": -91.28788115025326,
    "lat": 43.496983918521906
  },
  {
    "name": "New Alexandria",
    "lng": -80.67516685007467,
    "lat": 40.29156047158506
  },
  {
    "name": "New Alluwe",
    "lng": -95.48871260399022,
    "lat": 36.61007248630586
  },
  {
    "name": "New Amsterdam",
    "lng": -86.27523045139883,
    "lat": 38.10262875340478
  },
  {
    "name": "New Ashford",
    "lng": -73.22402321271474,
    "lat": 42.60867966608789
  },
  {
    "name": "New Athens",
    "lng": -89.87748849844161,
    "lat": 38.31862406679056
  },
  {
    "name": "New Athens",
    "lng": -80.99497446213094,
    "lat": 40.184419600686034
  },
  {
    "name": "New Auburn",
    "lng": -94.23202735867272,
    "lat": 44.672820669656474
  },
  {
    "name": "New Auburn",
    "lng": -91.56649596545668,
    "lat": 45.19876402789992
  },
  {
    "name": "New Auburn",
    "lng": -91.5611715461243,
    "lat": 45.20738312607144
  },
  {
    "name": "New Baden",
    "lng": -89.7071951207549,
    "lat": 38.53676402083977
  },
  {
    "name": "New Baltimore",
    "lng": -82.73844536297085,
    "lat": 42.683986291334286
  },
  {
    "name": "New Bavaria",
    "lng": -84.16755779467478,
    "lat": 41.20367265723622
  },
  {
    "name": "New Bedford",
    "lng": -89.71837297536503,
    "lat": 41.51120472752059
  },
  {
    "name": "New Berlin",
    "lng": -98.1029627557825,
    "lat": 29.465346362519547
  },
  {
    "name": "New Berlin",
    "lng": -89.91343882953184,
    "lat": 39.72591582336194
  },
  {
    "name": "New Berlin",
    "lng": -88.129136461,
    "lat": 42.97257856652581
  },
  {
    "name": "New Berlin",
    "lng": -75.33531694959484,
    "lat": 42.62398375912466
  },
  {
    "name": "New Bern",
    "lng": -77.07300332966376,
    "lat": 35.09648840332244
  },
  {
    "name": "New Bern",
    "lng": -77.01854006241032,
    "lat": 35.1031786368863
  },
  {
    "name": "New Bern",
    "lng": -77.0140211769803,
    "lat": 35.10881201685741
  },
  {
    "name": "New Bern",
    "lng": -77.00734283442357,
    "lat": 35.117999383137295
  },
  {
    "name": "New Bloomfield",
    "lng": -92.09145795982259,
    "lat": 38.716837994854686
  },
  {
    "name": "New Bloomington",
    "lng": -83.31252380624862,
    "lat": 40.583696546936594
  },
  {
    "name": "New Boston",
    "lng": -94.41769871653048,
    "lat": 33.46069984329539
  },
  {
    "name": "New Boston",
    "lng": -90.99989738311282,
    "lat": 41.169750790874765
  },
  {
    "name": "New Boston",
    "lng": -71.68584072790645,
    "lat": 42.97756992252914
  },
  {
    "name": "New Braintree",
    "lng": -72.12794293974825,
    "lat": 42.31931054644473
  },
  {
    "name": "New Braunfels",
    "lng": -98.11495702106703,
    "lat": 29.699921910196544
  },
  {
    "name": "New Bremen",
    "lng": -84.37769202040205,
    "lat": 40.43476662474163
  },
  {
    "name": "New Brighton",
    "lng": -93.20608469989114,
    "lat": 45.06581658889671
  },
  {
    "name": "New Britain",
    "lng": -72.78613761333477,
    "lat": 41.67591103121913
  },
  {
    "name": "New Brockton",
    "lng": -85.92280045208265,
    "lat": 31.378426442010984
  },
  {
    "name": "New Brunswick",
    "lng": -74.4449772903344,
    "lat": 40.487009076032
  },
  {
    "name": "New Buffalo",
    "lng": -86.74178928804383,
    "lat": 41.79241449434354
  },
  {
    "name": "New Burnside",
    "lng": -88.77268423574097,
    "lat": 37.578655668457976
  },
  {
    "name": "New Cambria",
    "lng": -97.50671999970274,
    "lat": 38.878802551914475
  },
  {
    "name": "New Cambria",
    "lng": -92.75147573826453,
    "lat": 39.77645534991551
  },
  {
    "name": "New Canaan",
    "lng": -73.49915531435741,
    "lat": 41.15923192227181
  },
  {
    "name": "New Canada",
    "lng": -68.50500074587258,
    "lat": 47.151881698152735
  },
  {
    "name": "New Canton",
    "lng": -91.09824592920091,
    "lat": 39.637343175960844
  },
  {
    "name": "New Carlisle",
    "lng": -86.49172732850052,
    "lat": 41.70177851991725
  },
  {
    "name": "New Carlisle",
    "lng": -84.02602191969916,
    "lat": 39.94363476468728
  },
  {
    "name": "New Castle",
    "lng": -107.52643202244998,
    "lat": 39.57866068319647
  },
  {
    "name": "New Castle",
    "lng": -107.51122635727134,
    "lat": 39.56410166028984
  },
  {
    "name": "New Castle",
    "lng": -107.50221241916593,
    "lat": 39.571836892101174
  },
  {
    "name": "New Castle",
    "lng": -85.36978459202629,
    "lat": 39.918878332129324
  },
  {
    "name": "New Castle",
    "lng": -85.16761050711527,
    "lat": 38.432942168598885
  },
  {
    "name": "New Castle",
    "lng": -80.11184690335921,
    "lat": 37.50125525141324
  },
  {
    "name": "New Chapel Hill",
    "lng": -95.16744322454045,
    "lat": 32.301134010534426
  },
  {
    "name": "New Chicago",
    "lng": -87.2799130206162,
    "lat": 41.55033935937367
  },
  {
    "name": "New Chicago",
    "lng": -87.2715568708218,
    "lat": 41.55898900366438
  },
  {
    "name": "New Concord",
    "lng": -81.73789403929423,
    "lat": 39.99483620620356
  },
  {
    "name": "New Cordell",
    "lng": -98.98162067160654,
    "lat": 35.297285637524354
  },
  {
    "name": "New Cumberland",
    "lng": -80.61164957279482,
    "lat": 40.50738260264471
  },
  {
    "name": "New Deal",
    "lng": -101.83921409707803,
    "lat": 33.72908706176296
  },
  {
    "name": "New Douglas",
    "lng": -89.66630738953624,
    "lat": 38.96961513931664
  },
  {
    "name": "New Durham",
    "lng": -71.14197442859134,
    "lat": 43.46362316335188
  },
  {
    "name": "New Effington",
    "lng": -96.91734862878329,
    "lat": 45.85588395356483
  },
  {
    "name": "New Ellenton",
    "lng": -81.6770441828069,
    "lat": 33.42045206726772
  },
  {
    "name": "New England",
    "lng": -102.86701040790017,
    "lat": 46.540742295939
  },
  {
    "name": "New Era",
    "lng": -86.34769411291784,
    "lat": 43.5593299716157
  },
  {
    "name": "New Fairfield",
    "lng": -73.48825950670368,
    "lat": 41.4880345297148
  },
  {
    "name": "New Fairview",
    "lng": -97.46223883982124,
    "lat": 33.1159366375751
  },
  {
    "name": "New Fairview",
    "lng": -97.40451844975641,
    "lat": 33.10394464245338
  },
  {
    "name": "New Florence",
    "lng": -91.4526483677123,
    "lat": 38.909430788832495
  },
  {
    "name": "New Franklin",
    "lng": -92.7405859740648,
    "lat": 39.01747947286596
  },
  {
    "name": "New Franklin",
    "lng": -81.58386102937229,
    "lat": 40.95248619551302
  },
  {
    "name": "New Freedom",
    "lng": -76.69669816919848,
    "lat": 39.735303351060566
  },
  {
    "name": "New Germany",
    "lng": -93.97196706510914,
    "lat": 44.8829418074914
  },
  {
    "name": "New Glarus",
    "lng": -89.63360456286146,
    "lat": 42.813005194066534
  },
  {
    "name": "New Gloucester",
    "lng": -70.29588013613264,
    "lat": 43.964098011448314
  },
  {
    "name": "New Grand Chain",
    "lng": -89.0164772900605,
    "lat": 37.255199662620946
  },
  {
    "name": "New Hampshire",
    "lng": -71.56968195058445,
    "lat": 43.69089347004158
  },
  {
    "name": "New Hampton",
    "lng": -94.19524377028544,
    "lat": 40.26518599182287
  },
  {
    "name": "New Hampton",
    "lng": -92.31504861859514,
    "lat": 43.05590470813247
  },
  {
    "name": "New Harmony",
    "lng": -113.30973020498506,
    "lat": 37.48032023971717
  },
  {
    "name": "New Harmony",
    "lng": -87.93239800997377,
    "lat": 38.12894455649557
  },
  {
    "name": "New Hartford",
    "lng": -92.62320508472376,
    "lat": 42.5668593551013
  },
  {
    "name": "New Hartford",
    "lng": -73.00551497229576,
    "lat": 41.844278734250416
  },
  {
    "name": "New Haven",
    "lng": -91.2181873567675,
    "lat": 38.604808201167856
  },
  {
    "name": "New Haven",
    "lng": -88.12789393930589,
    "lat": 37.90247247455406
  },
  {
    "name": "New Haven",
    "lng": -85.58907826616355,
    "lat": 37.65905869522955
  },
  {
    "name": "New Haven",
    "lng": -85.02888930337525,
    "lat": 41.067570604566605
  },
  {
    "name": "New Haven",
    "lng": -82.79526309340297,
    "lat": 42.73057780692102
  },
  {
    "name": "New Haven",
    "lng": -81.965992288083,
    "lat": 38.98773978381587
  },
  {
    "name": "New Haven",
    "lng": -72.92462652733127,
    "lat": 41.31133942708401
  },
  {
    "name": "New Hebron",
    "lng": -89.98332610922036,
    "lat": 31.733218702070086
  },
  {
    "name": "New Hempstead",
    "lng": -74.04843934877687,
    "lat": 41.14888984964151
  },
  {
    "name": "New Holland",
    "lng": -89.58304629489669,
    "lat": 40.183442968569906
  },
  {
    "name": "New Holland",
    "lng": -83.25942759219949,
    "lat": 39.554441517109204
  },
  {
    "name": "New Holland",
    "lng": -76.08998066916429,
    "lat": 40.100723572752266
  },
  {
    "name": "New Holstein",
    "lng": -88.09417596998553,
    "lat": 43.94948674842352
  },
  {
    "name": "New Home",
    "lng": -101.91172169105357,
    "lat": 33.32693881025218
  },
  {
    "name": "New Hope",
    "lng": -93.38693116670281,
    "lat": 45.03745080294773
  },
  {
    "name": "New Hope",
    "lng": -93.37035250413419,
    "lat": 45.054548577270225
  },
  {
    "name": "New Hope",
    "lng": -85.65970292346938,
    "lat": 35.00447622875537
  },
  {
    "name": "New Houlka",
    "lng": -89.02107963460034,
    "lat": 34.03625086473528
  },
  {
    "name": "New Hyde Park",
    "lng": -73.68575100004693,
    "lat": 40.73239220835199
  },
  {
    "name": "New Iberia",
    "lng": -91.82012022625744,
    "lat": 30.004829661482567
  },
  {
    "name": "New Iberia",
    "lng": -91.82004055315963,
    "lat": 30.005059723405466
  },
  {
    "name": "New Ipswich",
    "lng": -71.87476759604583,
    "lat": 42.74907501901787
  },
  {
    "name": "New Jersey",
    "lng": -74.65631687302576,
    "lat": 40.11741557590784
  },
  {
    "name": "New Johnsonville",
    "lng": -87.96942628455747,
    "lat": 36.01623990925582
  },
  {
    "name": "New Kensington",
    "lng": -79.75208828305419,
    "lat": 40.57110417485629
  },
  {
    "name": "New Knoxville",
    "lng": -84.31640007024762,
    "lat": 40.49423971558428
  },
  {
    "name": "New Lebanon",
    "lng": -84.3946211803095,
    "lat": 39.74431005095926
  },
  {
    "name": "New Leipzig",
    "lng": -101.95166830121576,
    "lat": 46.37581297886595
  },
  {
    "name": "New Lenox",
    "lng": -88.00595496940764,
    "lat": 41.49344792188148
  },
  {
    "name": "New Lenox",
    "lng": -87.9976254011997,
    "lat": 41.54826138330997
  },
  {
    "name": "New Lenox",
    "lng": -87.99046557119208,
    "lat": 41.521007653249825
  },
  {
    "name": "New Lenox",
    "lng": -87.98854520985329,
    "lat": 41.47812484541448
  },
  {
    "name": "New Lenox",
    "lng": -87.97700791019815,
    "lat": 41.519704293672035
  },
  {
    "name": "New Lenox",
    "lng": -87.97132079477636,
    "lat": 41.522974509374066
  },
  {
    "name": "New Lenox",
    "lng": -87.96634372674772,
    "lat": 41.54907915076773
  },
  {
    "name": "New Lenox",
    "lng": -87.9648968468897,
    "lat": 41.50135664117325
  },
  {
    "name": "New Lenox",
    "lng": -87.95734112716828,
    "lat": 41.50456308285912
  },
  {
    "name": "New Lenox",
    "lng": -87.95348563368108,
    "lat": 41.481171867670085
  },
  {
    "name": "New Lenox",
    "lng": -87.94616724534791,
    "lat": 41.53985545488363
  },
  {
    "name": "New Lenox",
    "lng": -87.92354112784913,
    "lat": 41.48414716316064
  },
  {
    "name": "New Lexington",
    "lng": -82.20799190638918,
    "lat": 39.71601459291525
  },
  {
    "name": "New Liberty",
    "lng": -90.87807643855265,
    "lat": 41.716077865058665
  },
  {
    "name": "New Limerick",
    "lng": -67.97136463278403,
    "lat": 46.118413775841645
  },
  {
    "name": "New Lisbon",
    "lng": -90.16280391318816,
    "lat": 43.87801394444101
  },
  {
    "name": "New Llano",
    "lng": -93.2767183578801,
    "lat": 31.09703564733091
  },
  {
    "name": "New London",
    "lng": -94.95348553934271,
    "lat": 45.29480394655329
  },
  {
    "name": "New London",
    "lng": -94.95033675192848,
    "lat": 45.298950692402556
  },
  {
    "name": "New London",
    "lng": -94.93848791469033,
    "lat": 45.30578881569467
  },
  {
    "name": "New London",
    "lng": -94.9376050440768,
    "lat": 45.305363034868904
  },
  {
    "name": "New London",
    "lng": -94.93734861899596,
    "lat": 45.302346710933506
  },
  {
    "name": "New London",
    "lng": -94.93724872016149,
    "lat": 45.30587695433416
  },
  {
    "name": "New London",
    "lng": -94.93495483744293,
    "lat": 45.29001401031616
  },
  {
    "name": "New London",
    "lng": -94.93460446887401,
    "lat": 45.29884760465752
  },
  {
    "name": "New London",
    "lng": -94.92996866530353,
    "lat": 32.268597256261465
  },
  {
    "name": "New London",
    "lng": -91.40715317442331,
    "lat": 39.578592115474436
  },
  {
    "name": "New London",
    "lng": -91.40122334261389,
    "lat": 40.92300031230598
  },
  {
    "name": "New London",
    "lng": -91.39851905219095,
    "lat": 39.58459279347798
  },
  {
    "name": "New London",
    "lng": -88.75962728112107,
    "lat": 44.375453152011055
  },
  {
    "name": "New London",
    "lng": -88.73915013068377,
    "lat": 44.395013415049995
  },
  {
    "name": "New London",
    "lng": -82.40660656568966,
    "lat": 41.07963355752902
  },
  {
    "name": "New London",
    "lng": -80.21935425668732,
    "lat": 35.43326192636605
  },
  {
    "name": "New London",
    "lng": -72.09597141510227,
    "lat": 41.33257770093862
  },
  {
    "name": "New London",
    "lng": -71.99050182272127,
    "lat": 43.41814392585879
  },
  {
    "name": "New Lothrop",
    "lng": -83.96883754925682,
    "lat": 43.116495868206144
  },
  {
    "name": "New Madison",
    "lng": -84.70806459164986,
    "lat": 39.9677897813537
  },
  {
    "name": "New Madrid",
    "lng": -89.55045257603824,
    "lat": 36.587843799798954
  },
  {
    "name": "New Madrid",
    "lng": -89.54901196305673,
    "lat": 36.58211252913356
  },
  {
    "name": "New Market",
    "lng": -94.90047568982581,
    "lat": 40.73240173934751
  },
  {
    "name": "New Market",
    "lng": -86.92214792644378,
    "lat": 39.95307979197144
  },
  {
    "name": "New Market",
    "lng": -83.54976931205324,
    "lat": 36.10902326595265
  },
  {
    "name": "New Market",
    "lng": -78.67095531421474,
    "lat": 38.64589240183678
  },
  {
    "name": "New Market",
    "lng": -76.85640806952861,
    "lat": 40.222979794788344
  },
  {
    "name": "New Marlborough",
    "lng": -73.24000892981906,
    "lat": 42.1009115746915
  },
  {
    "name": "New Martinsville",
    "lng": -80.85687024014531,
    "lat": 39.66346414904
  },
  {
    "name": "New Meadows",
    "lng": -116.28575817566058,
    "lat": 44.97114175819166
  },
  {
    "name": "New Melle",
    "lng": -90.88446249766042,
    "lat": 38.718586785514155
  },
  {
    "name": "New Mexico",
    "lng": -106.10646635542598,
    "lat": 34.461471624723465
  },
  {
    "name": "New Miami",
    "lng": -84.53963868303072,
    "lat": 39.43198200255002
  },
  {
    "name": "New Middletown",
    "lng": -86.05101243298779,
    "lat": 38.16445913558784
  },
  {
    "name": "New Middletown",
    "lng": -80.5597177701636,
    "lat": 40.964765080930434
  },
  {
    "name": "New Milford",
    "lng": -89.0714431422669,
    "lat": 42.191249994117975
  },
  {
    "name": "New Milford",
    "lng": -89.06503568846517,
    "lat": 42.177551342696106
  },
  {
    "name": "New Milford",
    "lng": -73.42131393055604,
    "lat": 41.60426075060941
  },
  {
    "name": "New Minden",
    "lng": -89.37030431892582,
    "lat": 38.43794351844733
  },
  {
    "name": "New Munich",
    "lng": -94.75249695559411,
    "lat": 45.63074399908592
  },
  {
    "name": "New Orleans",
    "lng": -89.9299371920475,
    "lat": 30.068916026193676
  },
  {
    "name": "New Palestine",
    "lng": -85.89085061714115,
    "lat": 39.723328332091334
  },
  {
    "name": "New Paltz",
    "lng": -74.08113935042813,
    "lat": 41.74943531702527
  },
  {
    "name": "New Paris",
    "lng": -84.79218634203619,
    "lat": 39.856519823192095
  },
  {
    "name": "New Pekin",
    "lng": -86.01521592655085,
    "lat": 38.502871028835436
  },
  {
    "name": "New Philadelphia",
    "lng": -81.44198312071447,
    "lat": 40.519379261926396
  },
  {
    "name": "New Philadelphia",
    "lng": -81.44045963277495,
    "lat": 40.486351677371644
  },
  {
    "name": "New Plymouth",
    "lng": -116.81878067951047,
    "lat": 43.97041796933377
  },
  {
    "name": "New Point",
    "lng": -85.3302641391164,
    "lat": 39.30902753533196
  },
  {
    "name": "New Portland",
    "lng": -70.07262961379891,
    "lat": 44.9054794553463
  },
  {
    "name": "New Prague",
    "lng": -93.57604323237474,
    "lat": 44.54594847116908
  },
  {
    "name": "New Preston",
    "lng": -73.35223174164345,
    "lat": 41.68129122928928
  },
  {
    "name": "New Providence",
    "lng": -93.17165320844663,
    "lat": 42.28132170714772
  },
  {
    "name": "New Richland",
    "lng": -93.49399231189874,
    "lat": 43.89387390574857
  },
  {
    "name": "New Richland",
    "lng": -93.48583829692873,
    "lat": 43.892141188146596
  },
  {
    "name": "New Richmond",
    "lng": -92.55789013248265,
    "lat": 45.11907960588208
  },
  {
    "name": "New Richmond",
    "lng": -92.55716395243557,
    "lat": 45.11967449286005
  },
  {
    "name": "New Richmond",
    "lng": -92.53782554707458,
    "lat": 45.12487163541391
  },
  {
    "name": "New Richmond",
    "lng": -86.97814618884703,
    "lat": 40.19415947035415
  },
  {
    "name": "New Richmond",
    "lng": -86.9770668951716,
    "lat": 40.19438273052525
  },
  {
    "name": "New Richmond",
    "lng": -84.2785908017523,
    "lat": 38.962182376396655
  },
  {
    "name": "New Riegel",
    "lng": -83.31900830893511,
    "lat": 41.05188637751412
  },
  {
    "name": "New Roads",
    "lng": -91.45379297667482,
    "lat": 30.69592086806757
  },
  {
    "name": "New Rochelle",
    "lng": -73.77994739511699,
    "lat": 40.92309627670854
  },
  {
    "name": "New Rockford",
    "lng": -99.13480317312509,
    "lat": 47.6790829055886
  },
  {
    "name": "New Ross",
    "lng": -86.71416822805043,
    "lat": 39.96444318412716
  },
  {
    "name": "New Salem",
    "lng": -101.41780917773626,
    "lat": 46.84322784100726
  },
  {
    "name": "New Salem",
    "lng": -90.84784051282821,
    "lat": 39.707611714934316
  },
  {
    "name": "New Salem",
    "lng": -76.79131229457364,
    "lat": 39.90369639457408
  },
  {
    "name": "New Salem",
    "lng": -72.32674356272369,
    "lat": 42.4525273551415
  },
  {
    "name": "New Sharon",
    "lng": -92.65105225264807,
    "lat": 41.470108588748104
  },
  {
    "name": "New Sharon",
    "lng": -70.00955229265362,
    "lat": 44.64138649304917
  },
  {
    "name": "New Shoreham",
    "lng": -71.57872305556884,
    "lat": 41.176220706890696
  },
  {
    "name": "New Shoreham",
    "lng": -71.57605362698997,
    "lat": 41.20205688735687
  },
  {
    "name": "New Site",
    "lng": -85.78013917689107,
    "lat": 33.028222630289946
  },
  {
    "name": "New Square",
    "lng": -74.02936177256369,
    "lat": 41.14101492103515
  },
  {
    "name": "New Straitsville",
    "lng": -82.23435773281412,
    "lat": 39.57925885758574
  },
  {
    "name": "New Strawn",
    "lng": -95.7426138650308,
    "lat": 38.262308739056735
  },
  {
    "name": "New Stuyahok",
    "lng": -157.27073754333168,
    "lat": 59.47597759499261
  },
  {
    "name": "New Summerfield",
    "lng": -95.1158330080543,
    "lat": 31.9807213564359
  },
  {
    "name": "New Sweden",
    "lng": -68.1211292426197,
    "lat": 46.97588031335146
  },
  {
    "name": "New Tazewell",
    "lng": -83.60569878660846,
    "lat": 36.438371942130864
  },
  {
    "name": "New Town",
    "lng": -102.48752386538729,
    "lat": 47.98269972815938
  },
  {
    "name": "New Trier",
    "lng": -92.93279023251424,
    "lat": 44.603966671939965
  },
  {
    "name": "New Ulm",
    "lng": -94.49233711917596,
    "lat": 44.30603310753696
  },
  {
    "name": "New Ulm",
    "lng": -94.46827578914782,
    "lat": 44.31241094133655
  },
  {
    "name": "New Underwood",
    "lng": -102.83679280169575,
    "lat": 44.09667760274231
  },
  {
    "name": "New Underwood",
    "lng": -102.83402447311809,
    "lat": 44.10760186244256
  },
  {
    "name": "New Vienna",
    "lng": -91.11384342510927,
    "lat": 42.54772681366506
  },
  {
    "name": "New Vienna",
    "lng": -83.69368588110284,
    "lat": 39.32560374415657
  },
  {
    "name": "New Vineyard",
    "lng": -70.112142808087,
    "lat": 44.80536240916619
  },
  {
    "name": "New Virginia",
    "lng": -93.73114321526468,
    "lat": 41.18174925374434
  },
  {
    "name": "New Washington",
    "lng": -82.85448993126138,
    "lat": 40.961084716532504
  },
  {
    "name": "New Waterford",
    "lng": -80.61902308298755,
    "lat": 40.84801090143773
  },
  {
    "name": "New Waverly",
    "lng": -95.48230909506673,
    "lat": 30.53724336135031
  },
  {
    "name": "New Weston",
    "lng": -84.64360955353176,
    "lat": 40.33702426107742
  },
  {
    "name": "New Whiteland",
    "lng": -86.0991999686356,
    "lat": 39.56177906198338
  },
  {
    "name": "New Wilmington",
    "lng": -80.33223201284055,
    "lat": 41.117396866096925
  },
  {
    "name": "New Windsor",
    "lng": -77.10219823497606,
    "lat": 39.544248443122214
  },
  {
    "name": "New Witten",
    "lng": -100.08215381123772,
    "lat": 43.440581418210854
  },
  {
    "name": "New Woodville",
    "lng": -96.65494067923704,
    "lat": 33.96870306315953
  },
  {
    "name": "New York",
    "lng": -75.59907629940288,
    "lat": 42.946931367374994
  },
  {
    "name": "New York",
    "lng": -74.04526452433927,
    "lat": 40.68986279065575
  },
  {
    "name": "New York",
    "lng": -74.03956496286654,
    "lat": 40.699325826711814
  },
  {
    "name": "New York",
    "lng": -73.93851982588998,
    "lat": 40.66394799713625
  },
  {
    "name": "New York Mills",
    "lng": -95.37240870029336,
    "lat": 46.52106898720666
  },
  {
    "name": "New-Way",
    "lng": -82.57361985041376,
    "lat": 40.15542915742266
  },
  {
    "name": "Newark",
    "lng": -122.03084179077331,
    "lat": 37.520018006425374
  },
  {
    "name": "Newark",
    "lng": -97.4845441881579,
    "lat": 33.00448924634549
  },
  {
    "name": "Newark",
    "lng": -91.97336759920505,
    "lat": 39.99461542681352
  },
  {
    "name": "Newark",
    "lng": -91.44338618578335,
    "lat": 35.70762796996205
  },
  {
    "name": "Newark",
    "lng": -88.58034807938704,
    "lat": 41.53679106490787
  },
  {
    "name": "Newark",
    "lng": -82.47431874888738,
    "lat": 40.0649602667565
  },
  {
    "name": "Newark",
    "lng": -82.42508292591614,
    "lat": 40.070452210186865
  },
  {
    "name": "Newark",
    "lng": -77.09302953378176,
    "lat": 43.04177373968004
  },
  {
    "name": "Newark",
    "lng": -74.17234647617352,
    "lat": 40.724209175353444
  },
  {
    "name": "Newark Valley",
    "lng": -76.1867849872041,
    "lat": 42.22286406530844
  },
  {
    "name": "Newaygo",
    "lng": -85.80173681133832,
    "lat": 43.41704218393113
  },
  {
    "name": "Newbern",
    "lng": -89.2713406049089,
    "lat": 36.11693945540215
  },
  {
    "name": "Newbern",
    "lng": -87.53321277109958,
    "lat": 32.59195174233379
  },
  {
    "name": "Newberry",
    "lng": -87.01906910814286,
    "lat": 38.92372378570289
  },
  {
    "name": "Newberry",
    "lng": -85.50978769023872,
    "lat": 46.35378384603545
  },
  {
    "name": "Newberry",
    "lng": -82.64801796426053,
    "lat": 29.56895046279484
  },
  {
    "name": "Newberry",
    "lng": -82.64419889750957,
    "lat": 29.729552787254946
  },
  {
    "name": "Newberry",
    "lng": -82.63841597897128,
    "lat": 29.573353926949824
  },
  {
    "name": "Newberry",
    "lng": -82.6027962169819,
    "lat": 29.632265236018334
  },
  {
    "name": "Newberry",
    "lng": -82.5807438940368,
    "lat": 29.652451925855765
  },
  {
    "name": "Newberry",
    "lng": -82.5257898236396,
    "lat": 29.612333057253785
  },
  {
    "name": "Newberry",
    "lng": -81.60225983576251,
    "lat": 34.28174639750645
  },
  {
    "name": "Newborn",
    "lng": -83.69586252138478,
    "lat": 33.516339439414885
  },
  {
    "name": "Newburg",
    "lng": -100.91262032001266,
    "lat": 48.71407126678537
  },
  {
    "name": "Newburg",
    "lng": -91.90014482377703,
    "lat": 37.916801701925614
  },
  {
    "name": "Newburg",
    "lng": -88.04766494937147,
    "lat": 43.43204751150919
  },
  {
    "name": "Newburg",
    "lng": -79.85368092265743,
    "lat": 39.38870818538573
  },
  {
    "name": "Newburgh",
    "lng": -87.40586582307282,
    "lat": 37.94950006116631
  },
  {
    "name": "Newburgh",
    "lng": -87.35625974823292,
    "lat": 37.969774698311554
  },
  {
    "name": "Newburgh",
    "lng": -87.34898720430394,
    "lat": 37.94216889191712
  },
  {
    "name": "Newburgh",
    "lng": -74.02086280127988,
    "lat": 41.503816701727004
  },
  {
    "name": "Newburgh",
    "lng": -69.01657342369651,
    "lat": 44.71442818375724
  },
  {
    "name": "Newburgh Heights",
    "lng": -81.66271075742209,
    "lat": 41.45094963876818
  },
  {
    "name": "Newbury",
    "lng": -72.0601551779574,
    "lat": 44.0809320794529
  },
  {
    "name": "Newbury",
    "lng": -72.02804365734741,
    "lat": 43.3149261755548
  },
  {
    "name": "Newcastle",
    "lng": -122.16334347138167,
    "lat": 47.530372863364725
  },
  {
    "name": "Newcastle",
    "lng": -104.21863124647378,
    "lat": 43.84142651186931
  },
  {
    "name": "Newcastle",
    "lng": -104.21852276135935,
    "lat": 43.84429433533602
  },
  {
    "name": "Newcastle",
    "lng": -104.21292585210516,
    "lat": 43.850396407142405
  },
  {
    "name": "Newcastle",
    "lng": -104.2096003904994,
    "lat": 43.84326153767936
  },
  {
    "name": "Newcastle",
    "lng": -104.19487728358106,
    "lat": 43.86115070361875
  },
  {
    "name": "Newcastle",
    "lng": -104.19332456843641,
    "lat": 43.86007245194693
  },
  {
    "name": "Newcastle",
    "lng": -104.19305022729533,
    "lat": 43.861340689981496
  },
  {
    "name": "Newcastle",
    "lng": -104.18607314867471,
    "lat": 43.87806988601913
  },
  {
    "name": "Newcastle",
    "lng": -97.59742102611987,
    "lat": 35.23803712307326
  },
  {
    "name": "Newcastle",
    "lng": -96.87403013772602,
    "lat": 42.652413176733376
  },
  {
    "name": "Newcastle",
    "lng": -69.57145525525806,
    "lat": 44.047909021882916
  },
  {
    "name": "Newcomerstown",
    "lng": -81.59753257090883,
    "lat": 40.27547185198263
  },
  {
    "name": "Newdale",
    "lng": -111.60388777910525,
    "lat": 43.88638528866234
  },
  {
    "name": "Newell",
    "lng": -103.41832416988052,
    "lat": 44.71746242487613
  },
  {
    "name": "Newell",
    "lng": -95.00392901087505,
    "lat": 42.6097425625218
  },
  {
    "name": "Newellton",
    "lng": -91.2470485155162,
    "lat": 32.062966619018525
  },
  {
    "name": "Newellton",
    "lng": -91.23878962346974,
    "lat": 32.07275211870168
  },
  {
    "name": "Newfield",
    "lng": -70.90496295229305,
    "lat": 43.64047001755234
  },
  {
    "name": "Newfields",
    "lng": -70.96651588866436,
    "lat": 43.03941404410458
  },
  {
    "name": "Newfolden",
    "lng": -96.32972895004707,
    "lat": 48.356733412993854
  },
  {
    "name": "Newhalen",
    "lng": -154.89373068659302,
    "lat": 59.726759910783535
  },
  {
    "name": "Newhall",
    "lng": -91.96727617867678,
    "lat": 41.993255517607125
  },
  {
    "name": "Newington",
    "lng": -81.50632122892198,
    "lat": 32.5887953985963
  },
  {
    "name": "Newington",
    "lng": -72.73093175879661,
    "lat": 41.6870426738218
  },
  {
    "name": "Newkirk",
    "lng": -97.0678899908171,
    "lat": 36.884213126412035
  },
  {
    "name": "Newkirk",
    "lng": -97.05489046192848,
    "lat": 36.88186231783903
  },
  {
    "name": "Newland",
    "lng": -81.9270587344482,
    "lat": 36.08809307876038
  },
  {
    "name": "Newman",
    "lng": -121.02114386165758,
    "lat": 37.31574331721944
  },
  {
    "name": "Newman",
    "lng": -87.98772742217146,
    "lat": 39.79695773661918
  },
  {
    "name": "Newman Grove",
    "lng": -97.77689375139917,
    "lat": 41.74696595993131
  },
  {
    "name": "Newmanstown",
    "lng": -76.21174591207252,
    "lat": 40.351340369289076
  },
  {
    "name": "Newnan",
    "lng": -84.81368966428073,
    "lat": 33.39783822548602
  },
  {
    "name": "Newnan",
    "lng": -84.7757208381001,
    "lat": 33.376309983723864
  },
  {
    "name": "Newport",
    "lng": -117.05140382811253,
    "lat": 48.180545634245355
  },
  {
    "name": "Newport",
    "lng": -99.33354379225564,
    "lat": 42.607743462860135
  },
  {
    "name": "Newport",
    "lng": -99.32811621168007,
    "lat": 42.59998817954286
  },
  {
    "name": "Newport",
    "lng": -92.9974683576522,
    "lat": 44.87431789102516
  },
  {
    "name": "Newport",
    "lng": -91.23218594967412,
    "lat": 35.62347275750378
  },
  {
    "name": "Newport",
    "lng": -87.40718322338113,
    "lat": 39.884064676842016
  },
  {
    "name": "Newport",
    "lng": -83.19933627061192,
    "lat": 35.96179052372764
  },
  {
    "name": "Newport",
    "lng": -76.87686270556256,
    "lat": 34.76986696043538
  },
  {
    "name": "Newport",
    "lng": -75.0152344997391,
    "lat": 43.186005240841986
  },
  {
    "name": "Newport",
    "lng": -71.34139600534573,
    "lat": 41.45150064317761
  },
  {
    "name": "Newport",
    "lng": -71.34111547687098,
    "lat": 41.49642219926889
  },
  {
    "name": "Newport",
    "lng": -71.33571620455382,
    "lat": 41.4517039069405
  },
  {
    "name": "Newport",
    "lng": -71.33487030073752,
    "lat": 41.45170000251355
  },
  {
    "name": "Newport",
    "lng": -71.33307250609778,
    "lat": 41.50255019956375
  },
  {
    "name": "Newport",
    "lng": -71.32754568624576,
    "lat": 41.488082392831885
  },
  {
    "name": "Newport",
    "lng": -71.32743554997826,
    "lat": 41.50981274131488
  },
  {
    "name": "Newport",
    "lng": -71.3259295504023,
    "lat": 41.45346099907299
  },
  {
    "name": "Newport",
    "lng": -71.32569662679539,
    "lat": 41.47738759101726
  },
  {
    "name": "Newport",
    "lng": -71.32385929481777,
    "lat": 41.45541558026777
  },
  {
    "name": "Newport",
    "lng": -71.3198134597355,
    "lat": 41.47968904341297
  },
  {
    "name": "Newport",
    "lng": -71.31202161130976,
    "lat": 41.45042926018139
  },
  {
    "name": "Newport",
    "lng": -71.30667508400295,
    "lat": 41.45106818848043
  },
  {
    "name": "Newport",
    "lng": -69.23292440749583,
    "lat": 44.86028936191295
  },
  {
    "name": "Newport Beach",
    "lng": -117.95485275339482,
    "lat": 33.634077670387356
  },
  {
    "name": "Newport Beach",
    "lng": -117.94349516815099,
    "lat": 33.64554638863356
  },
  {
    "name": "Newport Beach",
    "lng": -117.89292173130391,
    "lat": 33.595517954602784
  },
  {
    "name": "Newport City",
    "lng": -72.20893528638412,
    "lat": 44.93722959091446
  },
  {
    "name": "Newport News",
    "lng": -76.51626620366257,
    "lat": 37.075924968338676
  },
  {
    "name": "Newry",
    "lng": -70.82964493455425,
    "lat": 44.515491522326315
  },
  {
    "name": "Newsoms",
    "lng": -77.12483679707536,
    "lat": 36.62620818890868
  },
  {
    "name": "Newton",
    "lng": -111.99075293317516,
    "lat": 41.86112441822728
  },
  {
    "name": "Newton",
    "lng": -97.4609616592485,
    "lat": 38.001567334124125
  },
  {
    "name": "Newton",
    "lng": -97.34787893062179,
    "lat": 38.03761222912708
  },
  {
    "name": "Newton",
    "lng": -93.75368338987042,
    "lat": 30.8502334712055
  },
  {
    "name": "Newton",
    "lng": -93.04050082901537,
    "lat": 41.69631886773538
  },
  {
    "name": "Newton",
    "lng": -89.15340226121977,
    "lat": 32.32960520023573
  },
  {
    "name": "Newton",
    "lng": -88.1711527541726,
    "lat": 38.99620774971974
  },
  {
    "name": "Newton",
    "lng": -88.16434241827217,
    "lat": 38.987230032919165
  },
  {
    "name": "Newton",
    "lng": -85.58936709881324,
    "lat": 31.342342322963983
  },
  {
    "name": "Newton",
    "lng": -84.33756347171665,
    "lat": 31.31721779435511
  },
  {
    "name": "Newton",
    "lng": -81.31057435911787,
    "lat": 35.63379394140432
  },
  {
    "name": "Newton",
    "lng": -81.30172199834729,
    "lat": 35.63799031275752
  },
  {
    "name": "Newton",
    "lng": -81.30050024382385,
    "lat": 35.65744630387083
  },
  {
    "name": "Newton",
    "lng": -81.23877928706698,
    "lat": 35.62857096705041
  },
  {
    "name": "Newton",
    "lng": -81.23079247846675,
    "lat": 35.66586481141233
  },
  {
    "name": "Newton",
    "lng": -81.21837100383236,
    "lat": 35.62987985828802
  },
  {
    "name": "Newton",
    "lng": -81.21181809633607,
    "lat": 35.63101255364423
  },
  {
    "name": "Newton",
    "lng": -81.19374921750916,
    "lat": 35.67213375547942
  },
  {
    "name": "Newton",
    "lng": -81.18450107799778,
    "lat": 35.644614403061
  },
  {
    "name": "Newton",
    "lng": -74.75328574536299,
    "lat": 41.0536599192768
  },
  {
    "name": "Newton",
    "lng": -71.20843024528418,
    "lat": 42.33161219113656
  },
  {
    "name": "Newton Falls",
    "lng": -80.97011918755246,
    "lat": 41.188608784188986
  },
  {
    "name": "Newton Grove",
    "lng": -78.37943468858683,
    "lat": 35.24830803971063
  },
  {
    "name": "Newton Grove",
    "lng": -78.35494549191655,
    "lat": 35.252269910400976
  },
  {
    "name": "Newtonia",
    "lng": -94.18368276222083,
    "lat": 36.879345123159595
  },
  {
    "name": "Newtonsville",
    "lng": -84.0884825082558,
    "lat": 39.18154180524368
  },
  {
    "name": "Newtown",
    "lng": -93.33357937895885,
    "lat": 40.3762674711175
  },
  {
    "name": "Newtown",
    "lng": -87.14823869115665,
    "lat": 40.20440658498381
  },
  {
    "name": "Newtown",
    "lng": -84.35136135422954,
    "lat": 39.1240763002393
  },
  {
    "name": "Newtown",
    "lng": -73.2929009019343,
    "lat": 41.3986659291732
  },
  {
    "name": "Newville",
    "lng": -85.33740498339948,
    "lat": 31.42030979472979
  },
  {
    "name": "Ney",
    "lng": -84.52084370489315,
    "lat": 41.38057720635409
  },
  {
    "name": "Neylandville",
    "lng": -96.00560604261209,
    "lat": 33.2005412516458
  },
  {
    "name": "Nezperce",
    "lng": -116.24058970637594,
    "lat": 46.233968769776126
  },
  {
    "name": "Niagara",
    "lng": -97.87167004408091,
    "lat": 47.998301001185034
  },
  {
    "name": "Niagara",
    "lng": -87.99915274976675,
    "lat": 45.78017121828751
  },
  {
    "name": "Niagara Falls",
    "lng": -79.01360731934206,
    "lat": 43.09190263778399
  },
  {
    "name": "Niangua",
    "lng": -92.8304460646843,
    "lat": 37.388583999343666
  },
  {
    "name": "Niantic",
    "lng": -89.16487059897678,
    "lat": 39.85436906056998
  },
  {
    "name": "Nibley",
    "lng": -111.84538462860304,
    "lat": 41.67253607063995
  },
  {
    "name": "Nicholasville",
    "lng": -84.61750888851915,
    "lat": 37.887338879862355
  },
  {
    "name": "Nicholasville",
    "lng": -84.56602994884938,
    "lat": 37.89592240440081
  },
  {
    "name": "Nicholls",
    "lng": -82.63717152029918,
    "lat": 31.518852609032358
  },
  {
    "name": "Nichols",
    "lng": -91.3079893067194,
    "lat": 41.479707030404775
  },
  {
    "name": "Nichols",
    "lng": -88.46782257211854,
    "lat": 44.567082269369585
  },
  {
    "name": "Nichols",
    "lng": -79.14920954636203,
    "lat": 34.23528322116636
  },
  {
    "name": "Nichols",
    "lng": -76.3704012780892,
    "lat": 42.0200169557223
  },
  {
    "name": "Nichols Hills",
    "lng": -97.54453601794857,
    "lat": 35.54686148331319
  },
  {
    "name": "Nicholson",
    "lng": -83.42885465922198,
    "lat": 34.11733603816999
  },
  {
    "name": "Nickelsville",
    "lng": -82.41603688079358,
    "lat": 36.75166385883134
  },
  {
    "name": "Nickerson",
    "lng": -98.08806828557424,
    "lat": 38.148801140330576
  },
  {
    "name": "Nickerson",
    "lng": -96.47071445464684,
    "lat": 41.53490478938826
  },
  {
    "name": "Nicollet",
    "lng": -94.18799666092282,
    "lat": 44.274692118258756
  },
  {
    "name": "Nicoma Park",
    "lng": -97.32535445705358,
    "lat": 35.491248946221944
  },
  {
    "name": "Niederwald",
    "lng": -97.75081594612966,
    "lat": 30.00567709137236
  },
  {
    "name": "Nielsville",
    "lng": -96.81638783672547,
    "lat": 47.53013095169235
  },
  {
    "name": "Nightmute",
    "lng": -164.8267235022473,
    "lat": 60.4914332209809
  },
  {
    "name": "Nikolai",
    "lng": -154.38084522347572,
    "lat": 63.00799243075097
  },
  {
    "name": "Niles",
    "lng": -87.81002931851923,
    "lat": 42.02783429188132
  },
  {
    "name": "Niles",
    "lng": -86.24708169948624,
    "lat": 41.83471083734535
  },
  {
    "name": "Niles",
    "lng": -80.75349451377555,
    "lat": 41.18766197558656
  },
  {
    "name": "Nilwood",
    "lng": -89.80773592767966,
    "lat": 39.39950686897554
  },
  {
    "name": "Nimmons",
    "lng": -90.09506297782625,
    "lat": 36.306231140008336
  },
  {
    "name": "Nimrod",
    "lng": -94.88044741927132,
    "lat": 46.63741051678977
  },
  {
    "name": "Ninety Six",
    "lng": -82.02330955133007,
    "lat": 34.1718514130132
  },
  {
    "name": "Ninnekah",
    "lng": -97.94479162995938,
    "lat": 34.96413855180661
  },
  {
    "name": "Niobrara",
    "lng": -98.03137260348718,
    "lat": 42.749996899877615
  },
  {
    "name": "Niota",
    "lng": -84.54913935455505,
    "lat": 35.515709811989616
  },
  {
    "name": "Niotaze",
    "lng": -96.01474019933755,
    "lat": 37.06776492174344
  },
  {
    "name": "Niskayuna",
    "lng": -73.87303135262655,
    "lat": 42.80310000489144
  },
  {
    "name": "Nisland",
    "lng": -103.5532144512795,
    "lat": 44.67299861889243
  },
  {
    "name": "Nissequogue",
    "lng": -73.20091370440956,
    "lat": 40.9589361795391
  },
  {
    "name": "Nisswa",
    "lng": -94.29768911663697,
    "lat": 46.50034256182175
  },
  {
    "name": "Nitro",
    "lng": -81.8281739032297,
    "lat": 38.418837553923595
  },
  {
    "name": "Nixa",
    "lng": -93.29518308580175,
    "lat": 37.045366648237994
  },
  {
    "name": "Nixon",
    "lng": -97.76681132954006,
    "lat": 29.26940019054813
  },
  {
    "name": "Noank",
    "lng": -71.99889196880477,
    "lat": 41.33508601272752
  },
  {
    "name": "Noble",
    "lng": -97.37240391884154,
    "lat": 35.1384666212812
  },
  {
    "name": "Noble",
    "lng": -93.68321550006466,
    "lat": 31.689908390664325
  },
  {
    "name": "Noble",
    "lng": -88.2232917197355,
    "lat": 38.69740224322916
  },
  {
    "name": "Nobleboro",
    "lng": -69.47688635102418,
    "lat": 44.10598911440443
  },
  {
    "name": "Noblesville",
    "lng": -86.0061119075377,
    "lat": 40.0356146060205
  },
  {
    "name": "Nocatee",
    "lng": -81.43490634114436,
    "lat": 30.062063864727058
  },
  {
    "name": "Nocatee",
    "lng": -81.41446503521071,
    "lat": 30.094116253143802
  },
  {
    "name": "Nocona",
    "lng": -97.73016996146293,
    "lat": 33.78355723026919
  },
  {
    "name": "Nodaway",
    "lng": -94.89740039852082,
    "lat": 40.936114408739044
  },
  {
    "name": "Noel",
    "lng": -94.4886095246703,
    "lat": 36.54360565057999
  },
  {
    "name": "Nogales",
    "lng": -110.93356577389802,
    "lat": 31.36266296348749
  },
  {
    "name": "Nokomis",
    "lng": -89.28518121210466,
    "lat": 39.30006887870309
  },
  {
    "name": "Nolanville",
    "lng": -97.61082922907869,
    "lat": 31.078224440377667
  },
  {
    "name": "Nolensville",
    "lng": -86.66201009836699,
    "lat": 35.95358295992592
  },
  {
    "name": "Nome",
    "lng": -165.41380464804683,
    "lat": 64.50699253813714
  },
  {
    "name": "Nome",
    "lng": -97.83264328078671,
    "lat": 46.67339516552528
  },
  {
    "name": "Nome",
    "lng": -97.82366799759052,
    "lat": 46.67168894641483
  },
  {
    "name": "Nome",
    "lng": -97.8129315359627,
    "lat": 46.677603501622194
  },
  {
    "name": "Nome",
    "lng": -94.40610497975814,
    "lat": 30.03648411628943
  },
  {
    "name": "Nondalton",
    "lng": -154.86061741406408,
    "lat": 59.97227334280271
  },
  {
    "name": "Nooksack",
    "lng": -122.31961908058194,
    "lat": 48.92743787107085
  },
  {
    "name": "Noonan",
    "lng": -103.01012890679344,
    "lat": 48.890215569917814
  },
  {
    "name": "Noonday",
    "lng": -95.41272216139204,
    "lat": 32.229289831946936
  },
  {
    "name": "Noonday",
    "lng": -95.41212131947917,
    "lat": 32.22438088126847
  },
  {
    "name": "Noonday",
    "lng": -95.40796734632472,
    "lat": 32.24189003628684
  },
  {
    "name": "Noonday",
    "lng": -95.40665733333331,
    "lat": 32.23560066642781
  },
  {
    "name": "Noonday",
    "lng": -95.40658500467987,
    "lat": 32.25481541342253
  },
  {
    "name": "Noonday",
    "lng": -95.40390288270773,
    "lat": 32.230971999933494
  },
  {
    "name": "Noonday",
    "lng": -95.39986483450457,
    "lat": 32.23963944278417
  },
  {
    "name": "Noonday",
    "lng": -95.39827688273397,
    "lat": 32.25082002318953
  },
  {
    "name": "Noonday",
    "lng": -95.39646794208025,
    "lat": 32.23677156707097
  },
  {
    "name": "Noonday",
    "lng": -95.3914917353201,
    "lat": 32.24672374278838
  },
  {
    "name": "Noonday",
    "lng": -95.39130686045675,
    "lat": 32.25833511732195
  },
  {
    "name": "Noonday",
    "lng": -95.38698335185062,
    "lat": 32.262386201367754
  },
  {
    "name": "Noonday",
    "lng": -95.38397105002318,
    "lat": 32.26201118962754
  },
  {
    "name": "Noonday",
    "lng": -95.38366743681922,
    "lat": 32.25522412743558
  },
  {
    "name": "Noonday",
    "lng": -95.38177947955643,
    "lat": 32.25967434114382
  },
  {
    "name": "Noonday",
    "lng": -95.38051787772642,
    "lat": 32.2662671521118
  },
  {
    "name": "Noorvik",
    "lng": -161.03535109060124,
    "lat": 66.82931582865005
  },
  {
    "name": "Nora",
    "lng": -97.97378678693558,
    "lat": 40.16414076672506
  },
  {
    "name": "Nora",
    "lng": -89.94590944470322,
    "lat": 42.45648304787784
  },
  {
    "name": "Nora Springs",
    "lng": -93.00940409627812,
    "lat": 43.1444684411937
  },
  {
    "name": "Norborne",
    "lng": -93.67597206177749,
    "lat": 39.30242659366889
  },
  {
    "name": "Norbourne Estates",
    "lng": -85.6466311190726,
    "lat": 38.2466280769376
  },
  {
    "name": "Norcatur",
    "lng": -100.18876518986094,
    "lat": 39.834748750965915
  },
  {
    "name": "Norco",
    "lng": -117.5500223106938,
    "lat": 33.92532186075199
  },
  {
    "name": "Norcross",
    "lng": -96.19632906481093,
    "lat": 45.86868510785584
  },
  {
    "name": "Norcross",
    "lng": -84.20645568020517,
    "lat": 33.93787608032004
  },
  {
    "name": "Nordheim",
    "lng": -97.61407611567537,
    "lat": 28.923790787091917
  },
  {
    "name": "Norfolk",
    "lng": -97.42095260009566,
    "lat": 42.0348557072996
  },
  {
    "name": "Norfolk",
    "lng": -76.26330724205823,
    "lat": 36.89632359151016
  },
  {
    "name": "Norfolk",
    "lng": -73.19635054183973,
    "lat": 41.980079502870126
  },
  {
    "name": "Norfork",
    "lng": -92.2800674033759,
    "lat": 36.209408547659166
  },
  {
    "name": "Norge",
    "lng": -97.99615011356238,
    "lat": 34.987998291322604
  },
  {
    "name": "Norlina",
    "lng": -78.19518054155861,
    "lat": 36.44516465270072
  },
  {
    "name": "Normal",
    "lng": -88.98877849977615,
    "lat": 40.52125186604145
  },
  {
    "name": "Norman",
    "lng": -98.7921020833131,
    "lat": 40.479154046774
  },
  {
    "name": "Norman",
    "lng": -97.48415850418064,
    "lat": 35.18529608298574
  },
  {
    "name": "Norman",
    "lng": -97.34701962505531,
    "lat": 35.23350441473031
  },
  {
    "name": "Norman",
    "lng": -93.67918690645264,
    "lat": 34.458432599747304
  },
  {
    "name": "Norman",
    "lng": -79.72317890562546,
    "lat": 35.16954881042664
  },
  {
    "name": "Norman Park",
    "lng": -83.68422395171721,
    "lat": 31.26931192912107
  },
  {
    "name": "Normandy",
    "lng": -90.30083166307368,
    "lat": 38.70703893951305
  },
  {
    "name": "Normandy",
    "lng": -86.25807089929806,
    "lat": 35.452227953813114
  },
  {
    "name": "Normandy Park",
    "lng": -122.34343605520651,
    "lat": 47.434238518507385
  },
  {
    "name": "Normangee",
    "lng": -96.11609775131997,
    "lat": 31.030028681591947
  },
  {
    "name": "Norridge",
    "lng": -87.8229917743341,
    "lat": 41.96362185210808
  },
  {
    "name": "Norridgewock",
    "lng": -69.81321336159054,
    "lat": 44.7265413929636
  },
  {
    "name": "Norris",
    "lng": -90.03211031153698,
    "lat": 40.62579616193144
  },
  {
    "name": "Norris",
    "lng": -90.02354946599642,
    "lat": 40.624522525831104
  },
  {
    "name": "Norris",
    "lng": -82.75253467215788,
    "lat": 34.766117625486935
  },
  {
    "name": "Norris City",
    "lng": -88.32788700351784,
    "lat": 37.978782874802285
  },
  {
    "name": "North",
    "lng": -81.10272978433441,
    "lat": 33.61696927304611
  },
  {
    "name": "North Adams",
    "lng": -84.52525122487097,
    "lat": 41.97087848536176
  },
  {
    "name": "North Adams",
    "lng": -73.1166113178739,
    "lat": 42.684400825223285
  },
  {
    "name": "North Augusta",
    "lng": -81.9547952781914,
    "lat": 33.52127513686482
  },
  {
    "name": "North Aurora",
    "lng": -88.34208404468703,
    "lat": 41.808398278477256
  },
  {
    "name": "North Baltimore",
    "lng": -83.67075156303551,
    "lat": 41.180085819476105
  },
  {
    "name": "North Baltimore",
    "lng": -83.64838030085961,
    "lat": 41.18582517744462
  },
  {
    "name": "North Barrington",
    "lng": -88.13133419992229,
    "lat": 42.20722499846333
  },
  {
    "name": "North Bay",
    "lng": -87.78026041245191,
    "lat": 42.76455805033303
  },
  {
    "name": "North Bay Village",
    "lng": -80.15372700595137,
    "lat": 25.848738353069727
  },
  {
    "name": "North Beach",
    "lng": -76.53473786233394,
    "lat": 38.707928057497725
  },
  {
    "name": "North Bend",
    "lng": -121.78803172088824,
    "lat": 47.49510972287199
  },
  {
    "name": "North Bend",
    "lng": -96.78307177292572,
    "lat": 41.46477111745202
  },
  {
    "name": "North Bend",
    "lng": -84.74017618545479,
    "lat": 39.15061756113792
  },
  {
    "name": "North Bennington",
    "lng": -73.24074276576513,
    "lat": 42.92355680687596
  },
  {
    "name": "North Berwick",
    "lng": -70.7779934749632,
    "lat": 43.34503747525208
  },
  {
    "name": "North Bonneville",
    "lng": -121.96912026060727,
    "lat": 45.64241014130064
  },
  {
    "name": "North Branch",
    "lng": -92.96016292614006,
    "lat": 45.51375049672779
  },
  {
    "name": "North Branch",
    "lng": -83.19075381397634,
    "lat": 43.22910147054315
  },
  {
    "name": "North Brentwood",
    "lng": -76.95100174210202,
    "lat": 38.94503694463371
  },
  {
    "name": "North Brookfield",
    "lng": -72.07794455188933,
    "lat": 42.26950976473652
  },
  {
    "name": "North Buena Vista",
    "lng": -90.95838837861076,
    "lat": 42.67779433142007
  },
  {
    "name": "North Canaan",
    "lng": -73.29355351031383,
    "lat": 42.02230689735477
  },
  {
    "name": "North Canton",
    "lng": -81.39705435831368,
    "lat": 40.87421862667419
  },
  {
    "name": "North Carolina",
    "lng": -79.13159323287344,
    "lat": 35.53278676952997
  },
  {
    "name": "North Carrollton",
    "lng": -89.9193994380221,
    "lat": 33.518634607168536
  },
  {
    "name": "North Charleroi",
    "lng": -79.9081531639828,
    "lat": 40.15010038586947
  },
  {
    "name": "North Charleston",
    "lng": -80.23538433837253,
    "lat": 32.918985088511285
  },
  {
    "name": "North Charleston",
    "lng": -80.09217914383396,
    "lat": 32.9841825363224
  },
  {
    "name": "North Charleston",
    "lng": -80.06595314574696,
    "lat": 32.9083697644584
  },
  {
    "name": "North Charleston",
    "lng": -80.05630285037996,
    "lat": 32.86719357650527
  },
  {
    "name": "North Charleston",
    "lng": -80.05593486694559,
    "lat": 32.868109073822055
  },
  {
    "name": "North Charleston",
    "lng": -80.05570201086849,
    "lat": 32.86885507113315
  },
  {
    "name": "North Charleston",
    "lng": -80.03393440685191,
    "lat": 32.92118218705185
  },
  {
    "name": "North Chevy Chase",
    "lng": -77.07400679997693,
    "lat": 39.002135784188624
  },
  {
    "name": "North Chicago",
    "lng": -87.85921659203166,
    "lat": 42.31735216233112
  },
  {
    "name": "North City",
    "lng": -89.06347999026092,
    "lat": 37.991707386863524
  },
  {
    "name": "North Cleveland",
    "lng": -95.10033199362088,
    "lat": 30.357297556463575
  },
  {
    "name": "North College Hill",
    "lng": -84.55195578065661,
    "lat": 39.21741778776306
  },
  {
    "name": "North Collins",
    "lng": -78.93694195649213,
    "lat": 42.594822185995106
  },
  {
    "name": "North Crows Nest",
    "lng": -86.162466143853,
    "lat": 39.866078986880304
  },
  {
    "name": "North Dakota",
    "lng": -100.47105378579806,
    "lat": 47.46858480229933
  },
  {
    "name": "North East",
    "lng": -75.94128406900475,
    "lat": 39.60787524240531
  },
  {
    "name": "North English",
    "lng": -92.07792907426503,
    "lat": 41.516823674345666
  },
  {
    "name": "North Enid",
    "lng": -97.86332057456158,
    "lat": 36.44576488337365
  },
  {
    "name": "North Fair Oaks",
    "lng": -122.2034170733024,
    "lat": 37.4754505121581
  },
  {
    "name": "North Fairfield",
    "lng": -82.61373797206205,
    "lat": 41.10447868889435
  },
  {
    "name": "North Fond du Lac",
    "lng": -88.48646441122844,
    "lat": 43.810715897007725
  },
  {
    "name": "North Fond du Lac",
    "lng": -88.48222005957011,
    "lat": 43.82179240177307
  },
  {
    "name": "North Freedom",
    "lng": -89.86057519540927,
    "lat": 43.45875160887632
  },
  {
    "name": "North Grosvenordale",
    "lng": -71.90331049358421,
    "lat": 41.98622381994929
  },
  {
    "name": "North Hampton",
    "lng": -83.94415173995476,
    "lat": 39.988417282605305
  },
  {
    "name": "North Haven",
    "lng": -72.31340435357566,
    "lat": 41.02113344732405
  },
  {
    "name": "North Haven",
    "lng": -68.91337977596389,
    "lat": 44.15193189261419
  },
  {
    "name": "North Henderson",
    "lng": -90.47482081780693,
    "lat": 41.09037812022042
  },
  {
    "name": "North High Shoals",
    "lng": -83.50154099260214,
    "lat": 33.827342552873134
  },
  {
    "name": "North Hills",
    "lng": -81.50879206540016,
    "lat": 39.31487918887902
  },
  {
    "name": "North Hills",
    "lng": -73.67817659881506,
    "lat": 40.77656425461288
  },
  {
    "name": "North Hodge",
    "lng": -92.71722611080392,
    "lat": 32.28451482506672
  },
  {
    "name": "North Hornell",
    "lng": -77.67096924838916,
    "lat": 42.35196195507135
  },
  {
    "name": "North Hornell",
    "lng": -77.66090531336994,
    "lat": 42.34512483601381
  },
  {
    "name": "North Hudson",
    "lng": -92.75668479993992,
    "lat": 44.99712819390192
  },
  {
    "name": "North Johns",
    "lng": -87.10089575861167,
    "lat": 33.36803150656744
  },
  {
    "name": "North Judson",
    "lng": -86.77704515871052,
    "lat": 41.21606742353812
  },
  {
    "name": "North Kansas City",
    "lng": -94.56422429706953,
    "lat": 39.13963225619968
  },
  {
    "name": "North Kingstown",
    "lng": -71.46378180622018,
    "lat": 41.56861568749462
  },
  {
    "name": "North Kingstown",
    "lng": -71.45419949986452,
    "lat": 41.580689717134
  },
  {
    "name": "North Kingstown",
    "lng": -71.44659370134244,
    "lat": 41.579571243246555
  },
  {
    "name": "North Kingstown",
    "lng": -71.43685602181083,
    "lat": 41.57262613731357
  },
  {
    "name": "North Kingsville",
    "lng": -80.672346457972,
    "lat": 41.91887205589854
  },
  {
    "name": "North Las Vegas",
    "lng": -115.12455905884995,
    "lat": 36.27038638350348
  },
  {
    "name": "North Lewisburg",
    "lng": -83.55965764214689,
    "lat": 40.22062714266812
  },
  {
    "name": "North Liberty",
    "lng": -91.64737786254074,
    "lat": 41.74870330117748
  },
  {
    "name": "North Liberty",
    "lng": -91.61042387619771,
    "lat": 41.74359646969239
  },
  {
    "name": "North Liberty",
    "lng": -86.42818214664337,
    "lat": 41.532469656448605
  },
  {
    "name": "North Lilbourn",
    "lng": -89.62198885755353,
    "lat": 36.60181403037702
  },
  {
    "name": "North Little Rock",
    "lng": -92.23712930597046,
    "lat": 34.7807160535319
  },
  {
    "name": "North Logan",
    "lng": -111.80672238825441,
    "lat": 41.77589906474521
  },
  {
    "name": "North Loup",
    "lng": -98.77591826108373,
    "lat": 41.49045607510497
  },
  {
    "name": "North Loup",
    "lng": -98.77260518221566,
    "lat": 41.49556907481911
  },
  {
    "name": "North Manchester",
    "lng": -85.77505382582385,
    "lat": 41.0044068541221
  },
  {
    "name": "North Mankato",
    "lng": -94.03887745692033,
    "lat": 44.18099439364186
  },
  {
    "name": "North Miami",
    "lng": -94.8796771625737,
    "lat": 36.92014173315875
  },
  {
    "name": "North Middletown",
    "lng": -84.1099403969236,
    "lat": 38.14243086717726
  },
  {
    "name": "North Muskegon",
    "lng": -86.27154861995137,
    "lat": 43.251434449477344
  },
  {
    "name": "North Myrtle Beach",
    "lng": -78.68234873284834,
    "lat": 33.8194806114625
  },
  {
    "name": "North Newton",
    "lng": -97.34749235450367,
    "lat": 38.07674468899379
  },
  {
    "name": "North Oaks",
    "lng": -93.0881781361096,
    "lat": 45.10021092236054
  },
  {
    "name": "North Ogden",
    "lng": -111.95841072236749,
    "lat": 41.31232357860813
  },
  {
    "name": "North Olmsted",
    "lng": -81.91899370788614,
    "lat": 41.415034877658705
  },
  {
    "name": "North Pekin",
    "lng": -89.62258523748447,
    "lat": 40.61133610520179
  },
  {
    "name": "North Perry",
    "lng": -81.12344542922035,
    "lat": 41.80019376319398
  },
  {
    "name": "North Platte",
    "lng": -100.76391150251995,
    "lat": 41.12700291300121
  },
  {
    "name": "North Port",
    "lng": -82.30223367688144,
    "lat": 27.0050630893466
  },
  {
    "name": "North Port",
    "lng": -82.2683076,
    "lat": 27.005032900091305
  },
  {
    "name": "North Port",
    "lng": -82.2595631624693,
    "lat": 27.003327552566535
  },
  {
    "name": "North Port",
    "lng": -82.16144017267574,
    "lat": 27.075823188800932
  },
  {
    "name": "North Prairie",
    "lng": -88.40391792891464,
    "lat": 42.93576629022997
  },
  {
    "name": "North Prairie",
    "lng": -88.3969361394225,
    "lat": 42.92310088273702
  },
  {
    "name": "North Prairie",
    "lng": -88.38922934673592,
    "lat": 42.9443424095814
  },
  {
    "name": "North Prairie",
    "lng": -88.3767026105556,
    "lat": 42.935343864176645
  },
  {
    "name": "North Providence",
    "lng": -71.45739676078911,
    "lat": 41.861588355812586
  },
  {
    "name": "North Randall",
    "lng": -81.52904647194966,
    "lat": 41.43266849655696
  },
  {
    "name": "North Reading",
    "lng": -71.08756986999488,
    "lat": 42.58156203255726
  },
  {
    "name": "North Redington Beach",
    "lng": -82.82247666373539,
    "lat": 27.819256830659608
  },
  {
    "name": "North Richland Hills",
    "lng": -97.2179781807078,
    "lat": 32.86044342563785
  },
  {
    "name": "North Ridgeville",
    "lng": -82.01942908483461,
    "lat": 41.38516963222995
  },
  {
    "name": "North River",
    "lng": -96.8019342056068,
    "lat": 46.95034427245641
  },
  {
    "name": "North River Shores",
    "lng": -80.2736653602429,
    "lat": 27.222367998224804
  },
  {
    "name": "North Riverside",
    "lng": -87.82629088138913,
    "lat": 41.84613109486567
  },
  {
    "name": "North Robinson",
    "lng": -82.85682526239553,
    "lat": 40.79244168992589
  },
  {
    "name": "North Royalton",
    "lng": -81.74514561281293,
    "lat": 41.31383253526597
  },
  {
    "name": "North Salem",
    "lng": -86.64434564138345,
    "lat": 39.85951391784033
  },
  {
    "name": "North Salt Lake",
    "lng": -111.91919617601684,
    "lat": 40.844004155671364
  },
  {
    "name": "North Sioux City",
    "lng": -96.4996325160075,
    "lat": 42.53529061458323
  },
  {
    "name": "North Smithfield",
    "lng": -71.55140800318361,
    "lat": 41.97302306376175
  },
  {
    "name": "North St. Paul",
    "lng": -92.99950152288982,
    "lat": 45.01368779993924
  },
  {
    "name": "North Star",
    "lng": -84.56916323919697,
    "lat": 40.32444902560964
  },
  {
    "name": "North Stonington",
    "lng": -71.87540625900232,
    "lat": 41.46971153497097
  },
  {
    "name": "North Syracuse",
    "lng": -76.13057044174903,
    "lat": 43.13385418175161
  },
  {
    "name": "North Tonawanda",
    "lng": -78.86601126162307,
    "lat": 43.04577972325844
  },
  {
    "name": "North Topsail Beach",
    "lng": -77.43147421098485,
    "lat": 34.494232467538374
  },
  {
    "name": "North Troy",
    "lng": -72.40516187833674,
    "lat": 44.99513485405124
  },
  {
    "name": "North Utica",
    "lng": -89.01084545602468,
    "lat": 41.353707244998546
  },
  {
    "name": "North Vernon",
    "lng": -85.62690374841263,
    "lat": 39.01631756636225
  },
  {
    "name": "North Washington",
    "lng": -92.41526222996852,
    "lat": 43.11711639729646
  },
  {
    "name": "North Webster",
    "lng": -85.6978695641046,
    "lat": 41.32400083096439
  },
  {
    "name": "North Webster",
    "lng": -85.6937830054741,
    "lat": 41.32477251223254
  },
  {
    "name": "North Wildwood",
    "lng": -74.79729863417104,
    "lat": 39.00512662614402
  },
  {
    "name": "North Wilkesboro",
    "lng": -81.21044472555117,
    "lat": 36.180922690718106
  },
  {
    "name": "North Wilkesboro",
    "lng": -81.1725689247311,
    "lat": 36.164075683712426
  },
  {
    "name": "North Wilkesboro",
    "lng": -81.15431917845046,
    "lat": 36.19708898894812
  },
  {
    "name": "North Wilkesboro",
    "lng": -81.15265879198662,
    "lat": 36.19425462745145
  },
  {
    "name": "North Wilkesboro",
    "lng": -81.15211273149065,
    "lat": 36.18771260925956
  },
  {
    "name": "North Wilkesboro",
    "lng": -81.15129876072804,
    "lat": 36.187826549728904
  },
  {
    "name": "North Wilkesboro",
    "lng": -81.138491997171,
    "lat": 36.17037617418392
  },
  {
    "name": "North Wilkesboro",
    "lng": -81.11554816680386,
    "lat": 36.15485790952632
  },
  {
    "name": "North Wilkesboro",
    "lng": -81.11415332388387,
    "lat": 36.15437886162801
  },
  {
    "name": "North Wilkesboro",
    "lng": -81.10696525327165,
    "lat": 36.136776440375904
  },
  {
    "name": "North Wilkesboro",
    "lng": -81.10506177682686,
    "lat": 36.14463495269155
  },
  {
    "name": "North Wilkesboro",
    "lng": -81.10453138912361,
    "lat": 36.194857884556036
  },
  {
    "name": "North Wilkesboro",
    "lng": -81.09845781128242,
    "lat": 36.197481633038194
  },
  {
    "name": "North Wilkesboro",
    "lng": -81.09599363288322,
    "lat": 36.198860741859974
  },
  {
    "name": "North Wilkesboro",
    "lng": -81.09311553986005,
    "lat": 36.20000469412977
  },
  {
    "name": "North Yarmouth",
    "lng": -70.23634969671845,
    "lat": 43.85122940188053
  },
  {
    "name": "North York",
    "lng": -76.73083376572109,
    "lat": 39.977967311353964
  },
  {
    "name": "Northampton",
    "lng": -72.67453683304376,
    "lat": 42.32664740241059
  },
  {
    "name": "Northboro",
    "lng": -95.29241010474799,
    "lat": 40.607379541241954
  },
  {
    "name": "Northborough",
    "lng": -71.64621386889654,
    "lat": 42.3231530441893
  },
  {
    "name": "Northbridge",
    "lng": -71.65472793814135,
    "lat": 42.13000840500919
  },
  {
    "name": "Northdale",
    "lng": -82.5259044480393,
    "lat": 28.105521714388114
  },
  {
    "name": "Northfield",
    "lng": -93.18122907524449,
    "lat": 44.423711400425525
  },
  {
    "name": "Northfield",
    "lng": -93.16977398736394,
    "lat": 44.45507255189235
  },
  {
    "name": "Northfield",
    "lng": -87.77900985639701,
    "lat": 42.10233681889016
  },
  {
    "name": "Northfield",
    "lng": -85.63612928810664,
    "lat": 38.28574094075718
  },
  {
    "name": "Northfield",
    "lng": -81.52853833124398,
    "lat": 41.342553551988836
  },
  {
    "name": "Northfield",
    "lng": -74.5556130130943,
    "lat": 39.37235622573432
  },
  {
    "name": "Northfield",
    "lng": -72.65573263611677,
    "lat": 44.153061230574664
  },
  {
    "name": "Northfield",
    "lng": -72.44597661624384,
    "lat": 42.67580247268947
  },
  {
    "name": "Northfield",
    "lng": -71.57249036300628,
    "lat": 43.41115644846745
  },
  {
    "name": "Northfield",
    "lng": -67.62318072915706,
    "lat": 44.82596993651123
  },
  {
    "name": "Northfork",
    "lng": -81.42744300572565,
    "lat": 37.41957908275476
  },
  {
    "name": "Northglenn",
    "lng": -104.98193603772278,
    "lat": 39.896046402435836
  },
  {
    "name": "Northlake",
    "lng": -97.254895456294,
    "lat": 33.078374119620875
  },
  {
    "name": "Northlake",
    "lng": -97.25155887690114,
    "lat": 33.109644987960834
  },
  {
    "name": "Northmoor",
    "lng": -94.60541978940097,
    "lat": 39.18453476913727
  },
  {
    "name": "Northome",
    "lng": -94.26752415461608,
    "lat": 47.874769949922246
  },
  {
    "name": "Northport",
    "lng": -117.77999419314705,
    "lat": 48.91581910505278
  },
  {
    "name": "Northport",
    "lng": -87.67282474144147,
    "lat": 33.24492447012646
  },
  {
    "name": "Northport",
    "lng": -87.67282474144147,
    "lat": 33.24492447012648
  },
  {
    "name": "Northport",
    "lng": -87.59794882465407,
    "lat": 33.25838328065346
  },
  {
    "name": "Northport",
    "lng": -87.59405765183693,
    "lat": 33.28835348287102
  },
  {
    "name": "Northport",
    "lng": -87.5940576518369,
    "lat": 33.28835348287101
  },
  {
    "name": "Northport",
    "lng": -87.58978943047018,
    "lat": 33.273510474597174
  },
  {
    "name": "Northport",
    "lng": -87.58978943047016,
    "lat": 33.27351047459716
  },
  {
    "name": "Northport",
    "lng": -85.61727579551386,
    "lat": 45.130054316056885
  },
  {
    "name": "Northport",
    "lng": -73.3449276262447,
    "lat": 40.90397611456552
  },
  {
    "name": "Northport",
    "lng": -68.98690608284633,
    "lat": 44.34874879238806
  },
  {
    "name": "Northrop",
    "lng": -94.43615617417146,
    "lat": 43.73599374892434
  },
  {
    "name": "Northville",
    "lng": -98.58035926533688,
    "lat": 45.15533598586277
  },
  {
    "name": "Northville",
    "lng": -83.4889278085733,
    "lat": 42.4354224372525
  },
  {
    "name": "Northville",
    "lng": -74.17031832228851,
    "lat": 43.22387533226471
  },
  {
    "name": "Northwest",
    "lng": -78.14763459676371,
    "lat": 34.314097321745656
  },
  {
    "name": "Northwest Park",
    "lng": -95.48862880825125,
    "lat": 29.92880439670802
  },
  {
    "name": "Northwood",
    "lng": -97.57083264649255,
    "lat": 47.73887851637247
  },
  {
    "name": "Northwood",
    "lng": -93.21597665725744,
    "lat": 43.444083764596336
  },
  {
    "name": "Northwood",
    "lng": -83.48360859832889,
    "lat": 41.60890521578159
  },
  {
    "name": "Northwood",
    "lng": -71.20947661170112,
    "lat": 43.21317893333968
  },
  {
    "name": "Northwoods",
    "lng": -90.2823653452874,
    "lat": 38.703537434455676
  },
  {
    "name": "Norton",
    "lng": -99.89171515165903,
    "lat": 39.83647223457142
  },
  {
    "name": "Norton",
    "lng": -82.62620942235266,
    "lat": 36.93146831873798
  },
  {
    "name": "Norton",
    "lng": -81.64607796312693,
    "lat": 41.02938591429558
  },
  {
    "name": "Norton Shores",
    "lng": -86.25197159061267,
    "lat": 43.162029177179505
  },
  {
    "name": "Nortonville",
    "lng": -95.33048040160384,
    "lat": 39.41573291659198
  },
  {
    "name": "Nortonville",
    "lng": -87.45601690255748,
    "lat": 37.1856500963529
  },
  {
    "name": "Norwalk",
    "lng": -118.08292059733142,
    "lat": 33.906940341786594
  },
  {
    "name": "Norwalk",
    "lng": -93.69104065331761,
    "lat": 41.49005658084365
  },
  {
    "name": "Norwalk",
    "lng": -90.62675204505055,
    "lat": 43.83402881456016
  },
  {
    "name": "Norwalk",
    "lng": -82.61037486860485,
    "lat": 41.244781662729935
  },
  {
    "name": "Norwalk",
    "lng": -82.56681865609455,
    "lat": 41.24710848708213
  },
  {
    "name": "Norwalk",
    "lng": -73.41945059499835,
    "lat": 41.09295179935076
  },
  {
    "name": "Norway",
    "lng": -91.9229011844737,
    "lat": 41.90236990636338
  },
  {
    "name": "Norway",
    "lng": -87.91332714364627,
    "lat": 45.801825952744615
  },
  {
    "name": "Norway",
    "lng": -81.12685118120547,
    "lat": 33.45021781162411
  },
  {
    "name": "Norway",
    "lng": -70.60644845686984,
    "lat": 44.2284935757481
  },
  {
    "name": "Norwich",
    "lng": -97.84791390534681,
    "lat": 37.457389805856735
  },
  {
    "name": "Norwich",
    "lng": -97.83361491903912,
    "lat": 37.45370062035062
  },
  {
    "name": "Norwich",
    "lng": -81.79236382100031,
    "lat": 39.98431762817192
  },
  {
    "name": "Norwich",
    "lng": -72.08832131754085,
    "lat": 41.549494844721565
  },
  {
    "name": "Norwood",
    "lng": -108.29170450893216,
    "lat": 38.12884204558631
  },
  {
    "name": "Norwood",
    "lng": -92.4189307689897,
    "lat": 37.107192969739934
  },
  {
    "name": "Norwood",
    "lng": -91.11258465798608,
    "lat": 30.968191097336746
  },
  {
    "name": "Norwood",
    "lng": -89.700448101106,
    "lat": 40.707473629358894
  },
  {
    "name": "Norwood",
    "lng": -85.61097401598076,
    "lat": 38.25211954058048
  },
  {
    "name": "Norwood",
    "lng": -84.45349709599866,
    "lat": 39.160508877268995
  },
  {
    "name": "Norwood",
    "lng": -82.70548455018607,
    "lat": 33.46248044251887
  },
  {
    "name": "Norwood",
    "lng": -80.1619791727462,
    "lat": 35.233638174929766
  },
  {
    "name": "Norwood",
    "lng": -80.11497749976009,
    "lat": 35.231952686242145
  },
  {
    "name": "Norwood",
    "lng": -80.11180773769605,
    "lat": 35.19540678649219
  },
  {
    "name": "Norwood",
    "lng": -80.10325175446194,
    "lat": 35.288497674167125
  },
  {
    "name": "Norwood",
    "lng": -80.09933137140044,
    "lat": 35.27870810115891
  },
  {
    "name": "Norwood",
    "lng": -74.99700951558172,
    "lat": 44.7481047341826
  },
  {
    "name": "Norwood Court",
    "lng": -90.28965229610793,
    "lat": 38.71456469559436
  },
  {
    "name": "Norwood Young America",
    "lng": -93.91963787427164,
    "lat": 44.772191697554085
  },
  {
    "name": "Norwood Young America",
    "lng": -93.9021733780649,
    "lat": 44.76735582698679
  },
  {
    "name": "Notasulga",
    "lng": -85.66951551474577,
    "lat": 32.55693034562446
  },
  {
    "name": "Nottingham",
    "lng": -71.12098074231828,
    "lat": 43.12426097198444
  },
  {
    "name": "Notus",
    "lng": -116.79995273515,
    "lat": 43.72658647733046
  },
  {
    "name": "Novato",
    "lng": -122.55710308389223,
    "lat": 38.092010506506114
  },
  {
    "name": "Novelty",
    "lng": -92.20756808216883,
    "lat": 40.01243772181399
  },
  {
    "name": "Novelty",
    "lng": -92.19446574558623,
    "lat": 40.01321035388193
  },
  {
    "name": "Novi",
    "lng": -83.4892733064379,
    "lat": 42.478612024809614
  },
  {
    "name": "Novinger",
    "lng": -92.70663708686453,
    "lat": 40.234109117203545
  },
  {
    "name": "Nowata",
    "lng": -95.63916892083296,
    "lat": 36.69723871506936
  },
  {
    "name": "Nowata",
    "lng": -95.62508344815369,
    "lat": 36.72112538805527
  },
  {
    "name": "Nowthen",
    "lng": -93.44948830385592,
    "lat": 45.34211749843076
  },
  {
    "name": "Nucla",
    "lng": -108.54871660806272,
    "lat": 38.26651558698384
  },
  {
    "name": "Nuevo Progreso",
    "lng": -97.95132435888871,
    "lat": 26.050864487227763
  },
  {
    "name": "Nuiqsut",
    "lng": -150.99554710139773,
    "lat": 70.21711471963602
  },
  {
    "name": "Nulato",
    "lng": -158.25142778944755,
    "lat": 64.70260912258884
  },
  {
    "name": "Numa",
    "lng": -92.98000335966056,
    "lat": 40.6856853368935
  },
  {
    "name": "Nunam Iqua",
    "lng": -164.90066324225555,
    "lat": 62.50993058216833
  },
  {
    "name": "Nunapitchuk",
    "lng": -162.46041832965656,
    "lat": 60.877237264731455
  },
  {
    "name": "Nunda",
    "lng": -97.0193938997328,
    "lat": 44.15985259432446
  },
  {
    "name": "Nunda",
    "lng": -77.93784911156752,
    "lat": 42.57975923677011
  },
  {
    "name": "Nunez",
    "lng": -82.34648030116503,
    "lat": 32.49207774907038
  },
  {
    "name": "Nunn",
    "lng": -104.81520668665672,
    "lat": 40.71408828683623
  },
  {
    "name": "Nunn",
    "lng": -104.79022766333819,
    "lat": 40.70469937472662
  },
  {
    "name": "Nunn",
    "lng": -104.7902229225216,
    "lat": 40.70470068883058
  },
  {
    "name": "Nunn",
    "lng": -104.77970443983942,
    "lat": 40.7334427096847
  },
  {
    "name": "Nutter Fort",
    "lng": -80.32608973345592,
    "lat": 39.26025384955212
  },
  {
    "name": "Nyack",
    "lng": -73.91301450414578,
    "lat": 41.092976015260085
  },
  {
    "name": "O\"Brien",
    "lng": -99.84372057939987,
    "lat": 33.380263030181766
  },
  {
    "name": "O\"Kean",
    "lng": -90.8183560628567,
    "lat": 36.171484370336955
  },
  {
    "name": "O'Donnell",
    "lng": -101.83084685948525,
    "lat": 32.9652035310374
  },
  {
    "name": "O'Fallon",
    "lng": -89.91678436359325,
    "lat": 38.596441169983486
  },
  {
    "name": "O'Neill",
    "lng": -98.6464934944217,
    "lat": 42.46103019433232
  },
  {
    "name": "Oacoma",
    "lng": -99.37205790014517,
    "lat": 43.80386607132815
  },
  {
    "name": "Oak",
    "lng": -97.90329496546316,
    "lat": 40.23817320489043
  },
  {
    "name": "Oak Brook",
    "lng": -87.95136602475574,
    "lat": 41.83725301710048
  },
  {
    "name": "Oak City",
    "lng": -112.33899650157483,
    "lat": 39.376007626452655
  },
  {
    "name": "Oak City",
    "lng": -77.30404426255241,
    "lat": 35.961681967044484
  },
  {
    "name": "Oak Creek",
    "lng": -106.95708419493218,
    "lat": 40.27398459546518
  },
  {
    "name": "Oak Creek",
    "lng": -87.89601264509777,
    "lat": 42.87942404282132
  },
  {
    "name": "Oak Forest",
    "lng": -87.75362923821761,
    "lat": 41.60611346710405
  },
  {
    "name": "Oak Forest",
    "lng": -87.7471387539538,
    "lat": 41.62412548877834
  },
  {
    "name": "Oak Forest",
    "lng": -87.73998063819403,
    "lat": 41.577323703625396
  },
  {
    "name": "Oak Grove",
    "lng": -96.33839183239927,
    "lat": 36.20557938150207
  },
  {
    "name": "Oak Grove",
    "lng": -96.31855749840011,
    "lat": 32.53317015766878
  },
  {
    "name": "Oak Grove",
    "lng": -94.1283184879779,
    "lat": 39.00752415051246
  },
  {
    "name": "Oak Grove",
    "lng": -94.10811581251036,
    "lat": 39.006584646153726
  },
  {
    "name": "Oak Grove",
    "lng": -93.4302199629769,
    "lat": 36.464269229565076
  },
  {
    "name": "Oak Grove",
    "lng": -93.43015170484212,
    "lat": 36.46395352728092
  },
  {
    "name": "Oak Grove",
    "lng": -93.32646199171381,
    "lat": 45.34092525909008
  },
  {
    "name": "Oak Grove",
    "lng": -91.39105219588598,
    "lat": 32.861954454836116
  },
  {
    "name": "Oak Grove",
    "lng": -90.57417983506146,
    "lat": 41.411980648156444
  },
  {
    "name": "Oak Grove",
    "lng": -87.42196794611263,
    "lat": 36.66866945991849
  },
  {
    "name": "Oak Grove Heights",
    "lng": -90.50851333995884,
    "lat": 36.12704535630284
  },
  {
    "name": "Oak Grove Village",
    "lng": -91.15018687077527,
    "lat": 38.22706780953847
  },
  {
    "name": "Oak Harbor",
    "lng": -122.63419607112411,
    "lat": 48.29592083854418
  },
  {
    "name": "Oak Harbor",
    "lng": -83.144955748416,
    "lat": 41.52457947351539
  },
  {
    "name": "Oak Harbor",
    "lng": -83.14316558893454,
    "lat": 41.51232673136769
  },
  {
    "name": "Oak Harbor",
    "lng": -83.12648859011219,
    "lat": 41.51599655832077
  },
  {
    "name": "Oak Hill",
    "lng": -97.34290200719312,
    "lat": 39.246598900695716
  },
  {
    "name": "Oak Hill",
    "lng": -87.08509698994199,
    "lat": 31.921510148607545
  },
  {
    "name": "Oak Hill",
    "lng": -86.78554838011736,
    "lat": 36.0734909137247
  },
  {
    "name": "Oak Hill",
    "lng": -82.56909956026922,
    "lat": 38.89619977657755
  },
  {
    "name": "Oak Hill",
    "lng": -81.16208973428597,
    "lat": 37.99344914047989
  },
  {
    "name": "Oak Hill",
    "lng": -81.1517646412542,
    "lat": 37.984445642827346
  },
  {
    "name": "Oak Hill",
    "lng": -80.86779705796215,
    "lat": 28.887392823921974
  },
  {
    "name": "Oak Hill",
    "lng": -80.85676473490535,
    "lat": 28.9021913624474
  },
  {
    "name": "Oak Hill",
    "lng": -80.84961693215496,
    "lat": 28.845086805196896
  },
  {
    "name": "Oak Hill",
    "lng": -80.83431312829269,
    "lat": 28.877936763928457
  },
  {
    "name": "Oak Island",
    "lng": -78.12849011624327,
    "lat": 33.91887638096064
  },
  {
    "name": "Oak Island",
    "lng": -78.05925409942921,
    "lat": 33.94082618428905
  },
  {
    "name": "Oak Lawn",
    "lng": -87.75278198547451,
    "lat": 41.71388720617153
  },
  {
    "name": "Oak Leaf",
    "lng": -96.85646191437009,
    "lat": 32.51372317068181
  },
  {
    "name": "Oak Park",
    "lng": -121.62951238721098,
    "lat": 36.7461755417964
  },
  {
    "name": "Oak Park",
    "lng": -87.78993824154385,
    "lat": 41.88716498321697
  },
  {
    "name": "Oak Park",
    "lng": -83.18242644720299,
    "lat": 42.46492888858692
  },
  {
    "name": "Oak Park",
    "lng": -82.31526533803458,
    "lat": 32.38215729910934
  },
  {
    "name": "Oak Park Heights",
    "lng": -92.81017942064686,
    "lat": 45.03234830694644
  },
  {
    "name": "Oak Park Heights",
    "lng": -92.80602686240832,
    "lat": 45.04212281481265
  },
  {
    "name": "Oak Point",
    "lng": -96.9912565936808,
    "lat": 33.18001472687735
  },
  {
    "name": "Oak Ridge",
    "lng": -96.25984111098592,
    "lat": 32.64895122106469
  },
  {
    "name": "Oak Ridge",
    "lng": -91.77312483478102,
    "lat": 32.62435353393838
  },
  {
    "name": "Oak Ridge",
    "lng": -89.7294837908897,
    "lat": 37.4986133724616
  },
  {
    "name": "Oak Ridge",
    "lng": -84.29384336085113,
    "lat": 35.963806017301586
  },
  {
    "name": "Oak Ridge",
    "lng": -79.98991798516663,
    "lat": 36.173715330554046
  },
  {
    "name": "Oak Ridge North",
    "lng": -95.44221164691305,
    "lat": 30.156954829259913
  },
  {
    "name": "Oak Valley",
    "lng": -96.51163543497422,
    "lat": 32.03309403200726
  },
  {
    "name": "Oakboro",
    "lng": -80.3708824854348,
    "lat": 35.234511368871445
  },
  {
    "name": "Oakboro",
    "lng": -80.36358114358765,
    "lat": 35.24594254693518
  },
  {
    "name": "Oakboro",
    "lng": -80.35592424529946,
    "lat": 35.256508749615584
  },
  {
    "name": "Oakboro",
    "lng": -80.33930995129948,
    "lat": 35.27207584321754
  },
  {
    "name": "Oakboro",
    "lng": -80.33129528291934,
    "lat": 35.22722027813594
  },
  {
    "name": "Oakbrook Terrace",
    "lng": -87.97180745522266,
    "lat": 41.85527712631261
  },
  {
    "name": "Oakbrook Terrace",
    "lng": -87.96856064184269,
    "lat": 41.853714184709176
  },
  {
    "name": "Oakdale",
    "lng": -120.85063603378237,
    "lat": 37.763792435973244
  },
  {
    "name": "Oakdale",
    "lng": -120.81993487875813,
    "lat": 37.78723985265015
  },
  {
    "name": "Oakdale",
    "lng": -120.79907973631794,
    "lat": 37.756627351537084
  },
  {
    "name": "Oakdale",
    "lng": -97.967158878433,
    "lat": 42.07008446757859
  },
  {
    "name": "Oakdale",
    "lng": -92.9640495055518,
    "lat": 44.98749434783784
  },
  {
    "name": "Oakdale",
    "lng": -92.67355897064884,
    "lat": 30.790237642908266
  },
  {
    "name": "Oakdale",
    "lng": -92.65386209437712,
    "lat": 30.816131094776075
  },
  {
    "name": "Oakdale",
    "lng": -90.37890128951298,
    "lat": 43.96195795193288
  },
  {
    "name": "Oakdale",
    "lng": -89.50349275544073,
    "lat": 38.2648397799244
  },
  {
    "name": "Oakdale",
    "lng": -84.5575561843931,
    "lat": 35.98950955452054
  },
  {
    "name": "Oakes",
    "lng": -98.08770562867464,
    "lat": 46.139382259451075
  },
  {
    "name": "Oakesdale",
    "lng": -117.24654806377139,
    "lat": 47.130419102972326
  },
  {
    "name": "Oakfield",
    "lng": -88.54762845120597,
    "lat": 43.684256692007914
  },
  {
    "name": "Oakfield",
    "lng": -78.2710024690002,
    "lat": 43.06494400905458
  },
  {
    "name": "Oakfield",
    "lng": -68.10371912586103,
    "lat": 46.080702912980506
  },
  {
    "name": "Oakford",
    "lng": -89.96526233184923,
    "lat": 40.10090539979371
  },
  {
    "name": "Oakham",
    "lng": -72.04606115907636,
    "lat": 42.35003785448108
  },
  {
    "name": "Oakhaven",
    "lng": -93.62071073778444,
    "lat": 33.72939165306772
  },
  {
    "name": "Oakland",
    "lng": -96.79363482828076,
    "lat": 34.100035383567025
  },
  {
    "name": "Oakland",
    "lng": -96.46689997113728,
    "lat": 41.835420633211875
  },
  {
    "name": "Oakland",
    "lng": -95.39518449505505,
    "lat": 41.31162556419677
  },
  {
    "name": "Oakland",
    "lng": -90.3848873532979,
    "lat": 38.5768958321428
  },
  {
    "name": "Oakland",
    "lng": -89.91508202361918,
    "lat": 34.055301314619086
  },
  {
    "name": "Oakland",
    "lng": -89.895787693211,
    "lat": 34.07711639458057
  },
  {
    "name": "Oakland",
    "lng": -89.53640931152914,
    "lat": 35.22538985729897
  },
  {
    "name": "Oakland",
    "lng": -88.02721562477747,
    "lat": 39.65764749270156
  },
  {
    "name": "Oakland",
    "lng": -86.25078906579685,
    "lat": 37.04735921491717
  },
  {
    "name": "Oakland",
    "lng": -81.647067802404,
    "lat": 28.558832210662445
  },
  {
    "name": "Oakland",
    "lng": -81.64588698612515,
    "lat": 28.544751148008086
  },
  {
    "name": "Oakland",
    "lng": -81.63526393814327,
    "lat": 28.545636729172884
  },
  {
    "name": "Oakland",
    "lng": -81.63341094231394,
    "lat": 28.5559581868561
  },
  {
    "name": "Oakland",
    "lng": -81.62387953088403,
    "lat": 28.54948805684567
  },
  {
    "name": "Oakland",
    "lng": -79.40200155658079,
    "lat": 39.41634770825708
  },
  {
    "name": "Oakland",
    "lng": -69.73274354295252,
    "lat": 44.559561316059465
  },
  {
    "name": "Oakland Acres",
    "lng": -92.82112330092234,
    "lat": 41.720446932621954
  },
  {
    "name": "Oakland City",
    "lng": -87.34831988178638,
    "lat": 38.338719378678704
  },
  {
    "name": "Oakley",
    "lng": -113.88241575001236,
    "lat": 42.24167054848639
  },
  {
    "name": "Oakley",
    "lng": -111.27891392659902,
    "lat": 40.72563696146904
  },
  {
    "name": "Oakley",
    "lng": -100.86933819230921,
    "lat": 39.127407732727555
  },
  {
    "name": "Oakley",
    "lng": -100.85192939717363,
    "lat": 39.12534426615391
  },
  {
    "name": "Oakley",
    "lng": -100.81556693585696,
    "lat": 39.113566991835874
  },
  {
    "name": "Oakman",
    "lng": -87.38904604873423,
    "lat": 33.7141367591481
  },
  {
    "name": "Oaks",
    "lng": -94.85261790370035,
    "lat": 36.16957628172433
  },
  {
    "name": "Oaks",
    "lng": -94.57181434213443,
    "lat": 39.197091045881436
  },
  {
    "name": "Oaktown",
    "lng": -87.44128594410986,
    "lat": 38.87134872542371
  },
  {
    "name": "Oakvale",
    "lng": -80.97038407485695,
    "lat": 37.33231519175714
  },
  {
    "name": "Oakview",
    "lng": -94.5703339022448,
    "lat": 39.20860436920668
  },
  {
    "name": "Oakville",
    "lng": -123.23497535083783,
    "lat": 46.840264474216625
  },
  {
    "name": "Oakville",
    "lng": -91.0436358722203,
    "lat": 41.09760306239752
  },
  {
    "name": "Oakwood",
    "lng": -98.70309574245714,
    "lat": 35.93162500931522
  },
  {
    "name": "Oakwood",
    "lng": -95.84999003706383,
    "lat": 31.584640921597643
  },
  {
    "name": "Oakwood",
    "lng": -94.570506693235,
    "lat": 39.20054104269002
  },
  {
    "name": "Oakwood",
    "lng": -87.77691881387335,
    "lat": 40.1101148134654
  },
  {
    "name": "Oakwood",
    "lng": -84.376645166378,
    "lat": 41.09299524990328
  },
  {
    "name": "Oakwood",
    "lng": -84.17324884106084,
    "lat": 39.72018397683236
  },
  {
    "name": "Oakwood",
    "lng": -83.90109583683387,
    "lat": 34.209266917081834
  },
  {
    "name": "Oakwood",
    "lng": -83.89174159018766,
    "lat": 34.26576510238033
  },
  {
    "name": "Oakwood",
    "lng": -83.88473402130012,
    "lat": 34.22297325367242
  },
  {
    "name": "Oakwood",
    "lng": -83.86763940215093,
    "lat": 34.23087171671318
  },
  {
    "name": "Oakwood",
    "lng": -81.50354216054386,
    "lat": 41.36680850239341
  },
  {
    "name": "Oakwood Hills",
    "lng": -88.23951473421326,
    "lat": 42.24776012772898
  },
  {
    "name": "Oakwood Park",
    "lng": -94.57401643960176,
    "lat": 39.204681939371646
  },
  {
    "name": "Oberlin",
    "lng": -100.53098263216438,
    "lat": 39.82297995185671
  },
  {
    "name": "Oberlin",
    "lng": -100.50930956078982,
    "lat": 39.82647993136884
  },
  {
    "name": "Oberlin",
    "lng": -92.7724337928502,
    "lat": 30.60689773210561
  },
  {
    "name": "Oberlin",
    "lng": -82.21960112884345,
    "lat": 41.28697081369248
  },
  {
    "name": "Oberon",
    "lng": -99.20590800978748,
    "lat": 47.92408757436078
  },
  {
    "name": "Obert",
    "lng": -97.02722355874717,
    "lat": 42.6895092490155
  },
  {
    "name": "Obetz",
    "lng": -82.945388467933,
    "lat": 39.867409387786935
  },
  {
    "name": "Obion",
    "lng": -89.19483985386988,
    "lat": 36.26444434951083
  },
  {
    "name": "Oblong",
    "lng": -87.91061047919513,
    "lat": 39.002238622456424
  },
  {
    "name": "Ocala",
    "lng": -82.15012981492701,
    "lat": 29.181442437381413
  },
  {
    "name": "Ocala",
    "lng": -82.13841256412549,
    "lat": 29.160622054970617
  },
  {
    "name": "Ocala",
    "lng": -82.13173397690399,
    "lat": 29.159500995163743
  },
  {
    "name": "Ocala",
    "lng": -82.12569490147051,
    "lat": 29.157405755522596
  },
  {
    "name": "Ocala",
    "lng": -82.12471725191348,
    "lat": 29.143791862542177
  },
  {
    "name": "Occoquan",
    "lng": -77.26074701044263,
    "lat": 38.682234394963885
  },
  {
    "name": "Ocean Beach",
    "lng": -73.15662743211195,
    "lat": 40.64649319549683
  },
  {
    "name": "Ocean City",
    "lng": -74.60120718850953,
    "lat": 39.26623428111574
  },
  {
    "name": "Ocean Isle Beach",
    "lng": -78.4557879542071,
    "lat": 33.92497062576986
  },
  {
    "name": "Ocean Isle Beach",
    "lng": -78.43397560571913,
    "lat": 33.89288848985657
  },
  {
    "name": "Ocean Ridge",
    "lng": -80.0461427840886,
    "lat": 26.530052838176175
  },
  {
    "name": "Ocean Shores",
    "lng": -124.15197949739712,
    "lat": 46.97014388825896
  },
  {
    "name": "Ocean Springs",
    "lng": -88.79494850335955,
    "lat": 30.406347201127254
  },
  {
    "name": "Oceana",
    "lng": -81.63148959104026,
    "lat": 37.69279496319216
  },
  {
    "name": "Oceanside",
    "lng": -117.3302147035242,
    "lat": 33.21202273411261
  },
  {
    "name": "Ochelata",
    "lng": -95.9812295055008,
    "lat": 36.60091179156971
  },
  {
    "name": "Ocheyedan",
    "lng": -95.53665639193572,
    "lat": 43.41824736646203
  },
  {
    "name": "Ochlocknee",
    "lng": -84.05179095789585,
    "lat": 30.975151468770694
  },
  {
    "name": "Ocilla",
    "lng": -83.25036828714074,
    "lat": 31.6001214426161
  },
  {
    "name": "Oconee",
    "lng": -89.1066101551971,
    "lat": 39.286305592089725
  },
  {
    "name": "Oconee",
    "lng": -82.95767541509085,
    "lat": 32.8534525723873
  },
  {
    "name": "Oconomowoc",
    "lng": -88.49517879977428,
    "lat": 43.099208623846806
  },
  {
    "name": "Oconomowoc Lake",
    "lng": -88.45199402254089,
    "lat": 43.09730865787854
  },
  {
    "name": "Oconto",
    "lng": -99.76193816146467,
    "lat": 41.141659316087285
  },
  {
    "name": "Oconto",
    "lng": -87.86861142813254,
    "lat": 44.89178523550854
  },
  {
    "name": "Oconto",
    "lng": -87.8576665942464,
    "lat": 44.85866012443327
  },
  {
    "name": "Oconto Falls",
    "lng": -88.15678502824844,
    "lat": 44.885888380266216
  },
  {
    "name": "Oconto Falls",
    "lng": -88.14430311505699,
    "lat": 44.87379782540167
  },
  {
    "name": "Ocracoke",
    "lng": -75.97686529042323,
    "lat": 35.112471368466466
  },
  {
    "name": "Octa",
    "lng": -83.61054999609425,
    "lat": 39.61344682589197
  },
  {
    "name": "Octavia",
    "lng": -97.0591222559942,
    "lat": 41.34745276184439
  },
  {
    "name": "Odebolt",
    "lng": -95.25449027807616,
    "lat": 42.311842747837986
  },
  {
    "name": "Odell",
    "lng": -96.80168050059902,
    "lat": 40.05038389994281
  },
  {
    "name": "Odell",
    "lng": -88.52225363755792,
    "lat": 41.00215468394321
  },
  {
    "name": "Odem",
    "lng": -97.58647497773136,
    "lat": 27.946415923504784
  },
  {
    "name": "Oden",
    "lng": -93.78618944024238,
    "lat": 34.621495379851694
  },
  {
    "name": "Odenville",
    "lng": -86.415215946083,
    "lat": 33.668312306801724
  },
  {
    "name": "Odessa",
    "lng": -118.68926896549195,
    "lat": 47.3326393638454
  },
  {
    "name": "Odessa",
    "lng": -96.33347125833558,
    "lat": 45.26216509332821
  },
  {
    "name": "Odessa",
    "lng": -93.97254700000002,
    "lat": 39.009796000203856
  },
  {
    "name": "Odessa",
    "lng": -93.96624581880286,
    "lat": 38.99950290057842
  },
  {
    "name": "Odessa",
    "lng": -82.55298875514157,
    "lat": 28.182063078726213
  },
  {
    "name": "Odessa",
    "lng": -76.78811615378524,
    "lat": 42.33499973862531
  },
  {
    "name": "Odin",
    "lng": -94.74275876812749,
    "lat": 43.86724245381737
  },
  {
    "name": "Odin",
    "lng": -89.05408866580345,
    "lat": 38.61587130925257
  },
  {
    "name": "Odon",
    "lng": -86.98925620408302,
    "lat": 38.84259342596565
  },
  {
    "name": "Odum",
    "lng": -82.02666861510544,
    "lat": 31.6661765040345
  },
  {
    "name": "Oelrichs",
    "lng": -103.23387167612384,
    "lat": 43.18218572006531
  },
  {
    "name": "Oelwein",
    "lng": -91.91325171709146,
    "lat": 42.671575152605115
  },
  {
    "name": "Offerle",
    "lng": -99.56049311917278,
    "lat": 37.89078833871621
  },
  {
    "name": "Offerman",
    "lng": -82.1134358075275,
    "lat": 31.408518628871203
  },
  {
    "name": "Ogallala",
    "lng": -101.72083580802723,
    "lat": 41.129373637865214
  },
  {
    "name": "Ogden",
    "lng": -111.96774875775976,
    "lat": 41.22814320924045
  },
  {
    "name": "Ogden",
    "lng": -96.71118927779895,
    "lat": 39.10261701568489
  },
  {
    "name": "Ogden",
    "lng": -96.70221583398627,
    "lat": 39.11350964152656
  },
  {
    "name": "Ogden",
    "lng": -96.69875472249188,
    "lat": 39.123385577399425
  },
  {
    "name": "Ogden",
    "lng": -96.69548334898678,
    "lat": 39.11993558724809
  },
  {
    "name": "Ogden",
    "lng": -94.0468696905536,
    "lat": 33.58578769901687
  },
  {
    "name": "Ogden",
    "lng": -94.02829888429045,
    "lat": 42.03948417644303
  },
  {
    "name": "Ogden",
    "lng": -87.95632761961602,
    "lat": 40.11507865857218
  },
  {
    "name": "Ogden Dunes",
    "lng": -87.19349386974255,
    "lat": 41.62301420874243
  },
  {
    "name": "Ogdensburg",
    "lng": -89.03161118991186,
    "lat": 44.45351930714994
  },
  {
    "name": "Ogdensburg",
    "lng": -75.47144459587396,
    "lat": 44.708737066448506
  },
  {
    "name": "Ogema",
    "lng": -95.9249178061897,
    "lat": 47.102944884747465
  },
  {
    "name": "Oglesby",
    "lng": -97.51133385562396,
    "lat": 31.418596459332058
  },
  {
    "name": "Oglesby",
    "lng": -89.06928488781574,
    "lat": 41.29596114435912
  },
  {
    "name": "Oglethorpe",
    "lng": -84.06247118653596,
    "lat": 32.29355457618955
  },
  {
    "name": "Ogunquit",
    "lng": -70.56579142415633,
    "lat": 43.243392613618255
  },
  {
    "name": "Ohatchee",
    "lng": -86.02812027723282,
    "lat": 33.78925774567047
  },
  {
    "name": "Ohio",
    "lng": -89.46052906348851,
    "lat": 41.55595860604286
  },
  {
    "name": "Ohio",
    "lng": -82.70652812075564,
    "lat": 40.43265492198361
  },
  {
    "name": "Ohio City",
    "lng": -84.61595519153877,
    "lat": 40.77014306778545
  },
  {
    "name": "Ohiowa",
    "lng": -97.45237994799145,
    "lat": 40.41364032957668
  },
  {
    "name": "Ohlman",
    "lng": -89.21913818828887,
    "lat": 39.34336969741244
  },
  {
    "name": "Oil City",
    "lng": -93.97575905418209,
    "lat": 32.745296602569255
  },
  {
    "name": "Oil City",
    "lng": -79.7035927109603,
    "lat": 41.42813622710445
  },
  {
    "name": "Oil Trough",
    "lng": -91.6274477779878,
    "lat": 35.55405058389212
  },
  {
    "name": "Oil Trough",
    "lng": -91.46121340234552,
    "lat": 35.62914767896645
  },
  {
    "name": "Oilton",
    "lng": -96.5817603834948,
    "lat": 36.08232129797317
  },
  {
    "name": "Ojai",
    "lng": -119.2470228833041,
    "lat": 34.44862916306934
  },
  {
    "name": "Okabena",
    "lng": -95.31856065239126,
    "lat": 43.73919294569397
  },
  {
    "name": "Okanogan",
    "lng": -119.60668808405727,
    "lat": 48.35583813050084
  },
  {
    "name": "Okanogan",
    "lng": -119.59448339617333,
    "lat": 48.383868772096235
  },
  {
    "name": "Okanogan",
    "lng": -119.59278759030886,
    "lat": 48.372078435649286
  },
  {
    "name": "Okanogan",
    "lng": -119.5785957186346,
    "lat": 48.368169277538684
  },
  {
    "name": "Okanogan",
    "lng": -119.56531324658562,
    "lat": 48.36915032327025
  },
  {
    "name": "Okanogan",
    "lng": -119.56470337861222,
    "lat": 48.361846417502164
  },
  {
    "name": "Okanogan",
    "lng": -119.55686768677069,
    "lat": 48.38999001800441
  },
  {
    "name": "Okarche",
    "lng": -97.9771597407223,
    "lat": 35.72833815437692
  },
  {
    "name": "Okarche",
    "lng": -97.97256908688674,
    "lat": 35.71750776594287
  },
  {
    "name": "Okawville",
    "lng": -89.54798332540743,
    "lat": 38.434425474208936
  },
  {
    "name": "Okay",
    "lng": -95.31513038714782,
    "lat": 35.857664676198894
  },
  {
    "name": "Okay",
    "lng": -95.31412571100405,
    "lat": 35.85385268435436
  },
  {
    "name": "Okeechobee",
    "lng": -80.82972566980293,
    "lat": 27.241298537931467
  },
  {
    "name": "Okeene",
    "lng": -98.31647356176104,
    "lat": 36.117065210365716
  },
  {
    "name": "Okemah",
    "lng": -96.323450571037,
    "lat": 35.427528230828436
  },
  {
    "name": "Okemah",
    "lng": -96.30040921446121,
    "lat": 35.42967214602179
  },
  {
    "name": "Oketo",
    "lng": -96.5989083541823,
    "lat": 39.96321636804041
  },
  {
    "name": "Oklahoma",
    "lng": -97.5161645240478,
    "lat": 35.59976043800343
  },
  {
    "name": "Oklahoma City",
    "lng": -97.51347345794592,
    "lat": 35.467475320758815
  },
  {
    "name": "Oklee",
    "lng": -95.8494803178273,
    "lat": 47.83866496580885
  },
  {
    "name": "Okoboji",
    "lng": -95.13899861501683,
    "lat": 43.390855129086646
  },
  {
    "name": "Okolona",
    "lng": -93.33763463926925,
    "lat": 34.00049434627949
  },
  {
    "name": "Okolona",
    "lng": -88.74983682607275,
    "lat": 34.00500784554719
  },
  {
    "name": "Okolona",
    "lng": -84.21701655477258,
    "lat": 41.35533250474832
  },
  {
    "name": "Oktaha",
    "lng": -95.48329591360692,
    "lat": 35.58162622619599
  },
  {
    "name": "Oktaha",
    "lng": -95.477512855515,
    "lat": 35.57734882366169
  },
  {
    "name": "Ola",
    "lng": -93.22435797991315,
    "lat": 35.031014795096205
  },
  {
    "name": "Olanta",
    "lng": -79.93195858246683,
    "lat": 33.9357039244942
  },
  {
    "name": "Olar",
    "lng": -81.18534393235801,
    "lat": 33.17986419908987
  },
  {
    "name": "Olathe",
    "lng": -107.98337430479474,
    "lat": 38.60923669770295
  },
  {
    "name": "Olathe",
    "lng": -107.97428313454081,
    "lat": 38.59313325698002
  },
  {
    "name": "Olathe",
    "lng": -94.92467520195426,
    "lat": 38.9840416176204
  },
  {
    "name": "Olathe",
    "lng": -94.91881471395727,
    "lat": 38.981484125609235
  },
  {
    "name": "Olathe",
    "lng": -94.8986990492933,
    "lat": 38.97504877336165
  },
  {
    "name": "Olathe",
    "lng": -94.83921284801646,
    "lat": 38.808143503200746
  },
  {
    "name": "Olathe",
    "lng": -94.8187019486431,
    "lat": 38.884603662045926
  },
  {
    "name": "Old Appleton",
    "lng": -89.71070508740041,
    "lat": 37.59390718195942
  },
  {
    "name": "Old Bennington",
    "lng": -73.21383300771154,
    "lat": 42.88401382744154
  },
  {
    "name": "Old Brookville",
    "lng": -73.6040627341622,
    "lat": 40.83305393783876
  },
  {
    "name": "Old Brownsboro Place",
    "lng": -85.61297014382086,
    "lat": 38.289688652164955
  },
  {
    "name": "Old Field",
    "lng": -73.12880770291557,
    "lat": 40.96157721064377
  },
  {
    "name": "Old Fort",
    "lng": -82.17782203939126,
    "lat": 35.63037907977437
  },
  {
    "name": "Old Harbor",
    "lng": -153.33860061643148,
    "lat": 57.21301669163226
  },
  {
    "name": "Old Hopland",
    "lng": -123.0993988535484,
    "lat": 38.97660115668881
  },
  {
    "name": "Old Jamestown",
    "lng": -90.28469852119727,
    "lat": 38.83937073060734
  },
  {
    "name": "Old Lyme",
    "lng": -72.30274021346317,
    "lat": 41.31509113745137
  },
  {
    "name": "Old Mill Creek",
    "lng": -87.97810963481905,
    "lat": 42.43056568661824
  },
  {
    "name": "Old Monroe",
    "lng": -90.75715432639038,
    "lat": 38.93231847362251
  },
  {
    "name": "Old Monroe",
    "lng": -90.74684182984558,
    "lat": 38.937960095493565
  },
  {
    "name": "Old Orchard Beach",
    "lng": -70.3405039623467,
    "lat": 43.506155386926324
  },
  {
    "name": "Old Ripley",
    "lng": -89.5720867264708,
    "lat": 38.89274368571033
  },
  {
    "name": "Old River-Winfree",
    "lng": -94.82677943213129,
    "lat": 29.87445302721884
  },
  {
    "name": "Old Saybrook",
    "lng": -72.38361030047544,
    "lat": 41.29475044814943
  },
  {
    "name": "Old Shawneetown",
    "lng": -88.13893402555725,
    "lat": 37.69710331494504
  },
  {
    "name": "Old Town",
    "lng": -68.7225719795778,
    "lat": 44.94991981449021
  },
  {
    "name": "Old Washington",
    "lng": -81.44426729565424,
    "lat": 40.03761671649627
  },
  {
    "name": "Old Westbury",
    "lng": -73.59584564445257,
    "lat": 40.786540204978294
  },
  {
    "name": "Oldenburg",
    "lng": -85.20166606603449,
    "lat": 39.337633469566406
  },
  {
    "name": "Oldham",
    "lng": -97.30964323476893,
    "lat": 44.22809020442568
  },
  {
    "name": "Oldham",
    "lng": -97.30483772976955,
    "lat": 44.23268361826257
  },
  {
    "name": "Olds",
    "lng": -91.54404807187531,
    "lat": 41.134215547501476
  },
  {
    "name": "Oldtown",
    "lng": -117.02316101329113,
    "lat": 48.18427187558847
  },
  {
    "name": "Oldtown",
    "lng": -116.9925645961042,
    "lat": 48.179507616300725
  },
  {
    "name": "Oldtown",
    "lng": -116.96621887977189,
    "lat": 48.17959782786337
  },
  {
    "name": "Olean",
    "lng": -92.53000258899868,
    "lat": 38.41035657118765
  },
  {
    "name": "Olean",
    "lng": -78.43213942004186,
    "lat": 42.08185374863098
  },
  {
    "name": "Olga",
    "lng": -81.69504324547438,
    "lat": 26.71136717624438
  },
  {
    "name": "Olin",
    "lng": -91.14182934982901,
    "lat": 41.99761207327778
  },
  {
    "name": "Olive Branch",
    "lng": -89.84845973281558,
    "lat": 34.96072422270735
  },
  {
    "name": "Olive Hill",
    "lng": -83.16866242789834,
    "lat": 38.303488224672385
  },
  {
    "name": "Olivehurst",
    "lng": -121.54757662051081,
    "lat": 39.078691862132516
  },
  {
    "name": "Oliver",
    "lng": -92.18852643342959,
    "lat": 46.64933832454888
  },
  {
    "name": "Oliver",
    "lng": -81.52945149695385,
    "lat": 32.52113792389384
  },
  {
    "name": "Oliver Springs",
    "lng": -84.32822019188743,
    "lat": 36.038668953201885
  },
  {
    "name": "Olivet",
    "lng": -97.67432634258125,
    "lat": 43.24121454328568
  },
  {
    "name": "Olivet",
    "lng": -95.75207594283387,
    "lat": 38.48140319474224
  },
  {
    "name": "Olivet",
    "lng": -84.92464712435216,
    "lat": 42.44401456690783
  },
  {
    "name": "Olivette",
    "lng": -90.37837525552129,
    "lat": 38.67248333792082
  },
  {
    "name": "Olivia",
    "lng": -94.99718924937109,
    "lat": 44.777152157940876
  },
  {
    "name": "Olivia",
    "lng": -94.99406795061029,
    "lat": 44.76602904721231
  },
  {
    "name": "Olla",
    "lng": -92.25780534855487,
    "lat": 31.904318558060314
  },
  {
    "name": "Olla",
    "lng": -92.24078368989656,
    "lat": 31.898698844416828
  },
  {
    "name": "Olla",
    "lng": -92.23276566753967,
    "lat": 31.875626171342496
  },
  {
    "name": "Olla",
    "lng": -92.22987093253484,
    "lat": 31.88160600882195
  },
  {
    "name": "Olla",
    "lng": -92.22085786290896,
    "lat": 31.90999971210853
  },
  {
    "name": "Ollie",
    "lng": -92.09230564409684,
    "lat": 41.19914812276868
  },
  {
    "name": "Olmitz",
    "lng": -98.93694040165899,
    "lat": 38.516596477620226
  },
  {
    "name": "Olmos Park",
    "lng": -98.48655549767246,
    "lat": 29.47482084817545
  },
  {
    "name": "Olmsted",
    "lng": -89.08418748255171,
    "lat": 37.18167158512283
  },
  {
    "name": "Olmsted Falls",
    "lng": -81.9041763314371,
    "lat": 41.36574928845474
  },
  {
    "name": "Olney",
    "lng": -98.75794664631334,
    "lat": 33.364939752385574
  },
  {
    "name": "Olney",
    "lng": -88.083889803604,
    "lat": 38.728434411613584
  },
  {
    "name": "Olney Springs",
    "lng": -103.94452611196105,
    "lat": 38.166247117868636
  },
  {
    "name": "Olpe",
    "lng": -96.18426008684717,
    "lat": 38.247982890528725
  },
  {
    "name": "Olpe",
    "lng": -96.16591680436414,
    "lat": 38.262059335613614
  },
  {
    "name": "Olsburg",
    "lng": -96.61660075824408,
    "lat": 39.42697248867664
  },
  {
    "name": "Olsburg",
    "lng": -96.61500957165782,
    "lat": 39.43200695713055
  },
  {
    "name": "Olsburg",
    "lng": -96.60861419106428,
    "lat": 39.429213064629295
  },
  {
    "name": "Olton",
    "lng": -102.13703957408508,
    "lat": 34.18019982077129
  },
  {
    "name": "Olustee",
    "lng": -99.42400964928456,
    "lat": 34.54751033464424
  },
  {
    "name": "Olympia",
    "lng": -122.8962425097277,
    "lat": 47.04182847251765
  },
  {
    "name": "Olympia",
    "lng": -122.89098802802667,
    "lat": 47.01876288603016
  },
  {
    "name": "Olympia Fields",
    "lng": -87.69237427157724,
    "lat": 41.51733150994643
  },
  {
    "name": "Olympian Village",
    "lng": -90.45835955046189,
    "lat": 38.134657971686124
  },
  {
    "name": "Omaha",
    "lng": -96.03780449873683,
    "lat": 41.264011169459906
  },
  {
    "name": "Omaha",
    "lng": -94.75488212431978,
    "lat": 33.17983525661262
  },
  {
    "name": "Omaha",
    "lng": -94.74034058082526,
    "lat": 33.18214104590485
  },
  {
    "name": "Omaha",
    "lng": -94.73659423715935,
    "lat": 33.19233988488489
  },
  {
    "name": "Omaha",
    "lng": -93.19098442496143,
    "lat": 36.45829888017176
  },
  {
    "name": "Omaha",
    "lng": -88.30529295568728,
    "lat": 37.890113346188414
  },
  {
    "name": "Omak",
    "lng": -119.51880979354114,
    "lat": 48.41402477379122
  },
  {
    "name": "Omak",
    "lng": -119.51734376848536,
    "lat": 48.46364846994385
  },
  {
    "name": "Omega",
    "lng": -83.59496012823925,
    "lat": 31.338853274573314
  },
  {
    "name": "Omer",
    "lng": -83.85618974212623,
    "lat": 44.04851882872485
  },
  {
    "name": "Omro",
    "lng": -88.73895425269258,
    "lat": 44.039225863230406
  },
  {
    "name": "Onaga",
    "lng": -96.17011479348075,
    "lat": 39.489300347041606
  },
  {
    "name": "Onaka",
    "lng": -99.46589789190429,
    "lat": 45.191292464932516
  },
  {
    "name": "Onalaska",
    "lng": -95.11105793737465,
    "lat": 30.820983624306578
  },
  {
    "name": "Onalaska",
    "lng": -91.20865699134079,
    "lat": 43.88801929510164
  },
  {
    "name": "Onamia",
    "lng": -93.66730143184932,
    "lat": 46.070153671395836
  },
  {
    "name": "Onamia",
    "lng": -93.6537816541902,
    "lat": 46.07889435897014
  },
  {
    "name": "Onancock",
    "lng": -75.7429625700143,
    "lat": 37.71005118830166
  },
  {
    "name": "Onarga",
    "lng": -88.00739928646888,
    "lat": 40.714945793663276
  },
  {
    "name": "Onawa",
    "lng": -96.09098490794908,
    "lat": 42.02642557488228
  },
  {
    "name": "Onaway",
    "lng": -116.88962137198544,
    "lat": 46.92821105342436
  },
  {
    "name": "Onaway",
    "lng": -84.22786796273293,
    "lat": 45.359154349533775
  },
  {
    "name": "Oneida",
    "lng": -95.93986429877266,
    "lat": 39.86661662538232
  },
  {
    "name": "Oneida",
    "lng": -90.2247054610715,
    "lat": 41.07216960867943
  },
  {
    "name": "Oneida",
    "lng": -84.54918003680892,
    "lat": 36.48984980615475
  },
  {
    "name": "Oneida",
    "lng": -84.51023736698741,
    "lat": 36.51735096471208
  },
  {
    "name": "Oneida",
    "lng": -84.45660728050741,
    "lat": 36.54614114602501
  },
  {
    "name": "Oneida Castle",
    "lng": -75.63335564468098,
    "lat": 43.081432589351536
  },
  {
    "name": "Onekama",
    "lng": -86.20222158239937,
    "lat": 44.36563187885064
  },
  {
    "name": "Oneonta",
    "lng": -86.49321537300729,
    "lat": 33.93923400235097
  },
  {
    "name": "Oneonta",
    "lng": -86.49317590375824,
    "lat": 33.93924536690081
  },
  {
    "name": "Ong",
    "lng": -97.83921125681714,
    "lat": 40.398327809105105
  },
  {
    "name": "Onida",
    "lng": -100.06766902260935,
    "lat": 44.70446526974957
  },
  {
    "name": "Onley",
    "lng": -75.71769297854532,
    "lat": 37.69150259487163
  },
  {
    "name": "Onslow",
    "lng": -91.0153453007344,
    "lat": 42.106602242492514
  },
  {
    "name": "Onsted",
    "lng": -84.18927179294043,
    "lat": 42.006659263269015
  },
  {
    "name": "Ontario",
    "lng": -117.60636482053566,
    "lat": 34.039212805416426
  },
  {
    "name": "Ontario",
    "lng": -90.59411991904848,
    "lat": 43.72217058317111
  },
  {
    "name": "Ontario",
    "lng": -82.6104825099968,
    "lat": 40.77102625085698
  },
  {
    "name": "Ontonagon",
    "lng": -89.31242639616106,
    "lat": 46.86655826330317
  },
  {
    "name": "Onward",
    "lng": -86.1957005448774,
    "lat": 40.694312704745215
  },
  {
    "name": "Onycha",
    "lng": -86.27854916156937,
    "lat": 31.224670482619615
  },
  {
    "name": "Oolitic",
    "lng": -86.52530634303388,
    "lat": 38.89258156575138
  },
  {
    "name": "Oologah",
    "lng": -95.71070949519934,
    "lat": 36.44457549033599
  },
  {
    "name": "Oostburg",
    "lng": -87.78908540338168,
    "lat": 43.623744871594276
  },
  {
    "name": "Opal",
    "lng": -110.3240780827065,
    "lat": 41.768055041594856
  },
  {
    "name": "Opdyke West",
    "lng": -102.30062191790381,
    "lat": 33.592704998722446
  },
  {
    "name": "Opelika",
    "lng": -85.45564243896824,
    "lat": 32.653532959776086
  },
  {
    "name": "Opelika",
    "lng": -85.39249453938403,
    "lat": 32.72686824166133
  },
  {
    "name": "Opelika",
    "lng": -85.39206888895721,
    "lat": 32.72869042519996
  },
  {
    "name": "Opelika",
    "lng": -85.39206888895721,
    "lat": 32.728690425199964
  },
  {
    "name": "Opelika",
    "lng": -85.3917477257031,
    "lat": 32.72740134707092
  },
  {
    "name": "Opelika",
    "lng": -85.39174772570307,
    "lat": 32.727401347070916
  },
  {
    "name": "Opelika",
    "lng": -85.38024186012719,
    "lat": 32.65999015940609
  },
  {
    "name": "Opelika",
    "lng": -85.30587531022859,
    "lat": 32.661023122349526
  },
  {
    "name": "Opelousas",
    "lng": -92.0850234412885,
    "lat": 30.52779053163828
  },
  {
    "name": "Opheim",
    "lng": -106.40818348910085,
    "lat": 48.857082104507384
  },
  {
    "name": "Ophir",
    "lng": -112.25760721453766,
    "lat": 40.36856382851346
  },
  {
    "name": "Ophir",
    "lng": -107.83061792352197,
    "lat": 37.85679004888194
  },
  {
    "name": "Opp",
    "lng": -86.25698084954158,
    "lat": 31.28483537761395
  },
  {
    "name": "Oppelo",
    "lng": -92.77347289050384,
    "lat": 35.100303352921664
  },
  {
    "name": "Oppelo",
    "lng": -92.77347289050381,
    "lat": 35.10030335292164
  },
  {
    "name": "Oppelo",
    "lng": -92.75167710173268,
    "lat": 35.108616692379115
  },
  {
    "name": "Optima",
    "lng": -101.34985109644246,
    "lat": 36.76191063854439
  },
  {
    "name": "Oquawka",
    "lng": -90.94986571982564,
    "lat": 40.93780376680398
  },
  {
    "name": "Oquossoc",
    "lng": -70.7765134537029,
    "lat": 44.964823247717945
  },
  {
    "name": "Oran",
    "lng": -89.65320694766015,
    "lat": 37.08567343222429
  },
  {
    "name": "Orange",
    "lng": -117.82092512659185,
    "lat": 33.80383801916314
  },
  {
    "name": "Orange",
    "lng": -93.76291804692849,
    "lat": 30.11850968694136
  },
  {
    "name": "Orange",
    "lng": -93.72589619922113,
    "lat": 30.192718103313997
  },
  {
    "name": "Orange",
    "lng": -92.35955795942046,
    "lat": 42.42838850743567
  },
  {
    "name": "Orange",
    "lng": -81.47423811105304,
    "lat": 41.441435069768424
  },
  {
    "name": "Orange",
    "lng": -78.11247904272612,
    "lat": 38.24847581146862
  },
  {
    "name": "Orange",
    "lng": -73.02716743349431,
    "lat": 41.28270219102185
  },
  {
    "name": "Orange",
    "lng": -72.2930619678104,
    "lat": 42.60659323519826
  },
  {
    "name": "Orange Beach",
    "lng": -87.59573925088333,
    "lat": 30.286055788881423
  },
  {
    "name": "Orange Beach",
    "lng": -87.5759560447689,
    "lat": 30.308311395567753
  },
  {
    "name": "Orange Beach",
    "lng": -87.54717114446989,
    "lat": 30.323419091445366
  },
  {
    "name": "Orange Beach",
    "lng": -87.53824440093376,
    "lat": 30.27776020065642
  },
  {
    "name": "Orange City",
    "lng": -96.05634896652303,
    "lat": 43.00243859367644
  },
  {
    "name": "Orange City",
    "lng": -81.308594193058,
    "lat": 28.943709801721774
  },
  {
    "name": "Orange City",
    "lng": -81.3062805569582,
    "lat": 28.92985015320703
  },
  {
    "name": "Orange City",
    "lng": -81.29875417256525,
    "lat": 28.96644901175272
  },
  {
    "name": "Orange City",
    "lng": -81.29819411124242,
    "lat": 28.925905599084608
  },
  {
    "name": "Orange City",
    "lng": -81.28835314463707,
    "lat": 28.933785871352953
  },
  {
    "name": "Orange Cove",
    "lng": -119.33445062329689,
    "lat": 36.60702708985844
  },
  {
    "name": "Orange Cove",
    "lng": -119.31741874125771,
    "lat": 36.62241803858762
  },
  {
    "name": "Orange Grove",
    "lng": -97.93855234584471,
    "lat": 27.956203193748866
  },
  {
    "name": "Orange Park",
    "lng": -81.7098679967209,
    "lat": 30.169761528359434
  },
  {
    "name": "Orangeburg",
    "lng": -80.86533569821057,
    "lat": 33.49145388106715
  },
  {
    "name": "Orangeville",
    "lng": -111.05918374133783,
    "lat": 39.23085166938976
  },
  {
    "name": "Orangeville",
    "lng": -89.64683725247248,
    "lat": 42.46580566684201
  },
  {
    "name": "Orangeville",
    "lng": -80.52758085209801,
    "lat": 41.3422288219327
  },
  {
    "name": "Orchard",
    "lng": -98.24105409463563,
    "lat": 42.33677377734326
  },
  {
    "name": "Orchard",
    "lng": -95.9693642672167,
    "lat": 29.600608865410695
  },
  {
    "name": "Orchard",
    "lng": -92.77437849174683,
    "lat": 43.22723187875584
  },
  {
    "name": "Orchard Grass Hills",
    "lng": -85.52359417355855,
    "lat": 38.323368628428334
  },
  {
    "name": "Orchard Hill",
    "lng": -84.21225200157905,
    "lat": 33.18509959602209
  },
  {
    "name": "Orchard Lake",
    "lng": -83.3752179326849,
    "lat": 42.58619327727333
  },
  {
    "name": "Orchard Park",
    "lng": -78.7437680755172,
    "lat": 42.76264903381627
  },
  {
    "name": "Orchidlands Estates CDP",
    "lng": -155.0137004528361,
    "lat": 19.55649179199994
  },
  {
    "name": "Ord",
    "lng": -98.93009489225987,
    "lat": 41.60340848949974
  },
  {
    "name": "Orderville",
    "lng": -112.65738643191867,
    "lat": 37.25396955421428
  },
  {
    "name": "Ordway",
    "lng": -103.75662717139116,
    "lat": 38.22087279157908
  },
  {
    "name": "Ore City",
    "lng": -94.71782859956186,
    "lat": 32.80091382867143
  },
  {
    "name": "Oreana",
    "lng": -88.86848016397329,
    "lat": 39.937824136649866
  },
  {
    "name": "Oregon",
    "lng": -120.6024107573814,
    "lat": 43.973278500556816
  },
  {
    "name": "Oregon",
    "lng": -95.14302973755771,
    "lat": 39.98559727401778
  },
  {
    "name": "Oregon",
    "lng": -89.38870539453406,
    "lat": 42.92517524879774
  },
  {
    "name": "Oregon",
    "lng": -89.33558696217642,
    "lat": 42.013187652437324
  },
  {
    "name": "Oregon",
    "lng": -83.42660410866905,
    "lat": 41.666063520485274
  },
  {
    "name": "Orem",
    "lng": -111.69942504436166,
    "lat": 40.29803283717752
  },
  {
    "name": "Orestes",
    "lng": -85.7252247436689,
    "lat": 40.27156572319949
  },
  {
    "name": "Orfordville",
    "lng": -89.25698385105501,
    "lat": 42.629193903280985
  },
  {
    "name": "Orient",
    "lng": -99.08887321002632,
    "lat": 44.90166227735834
  },
  {
    "name": "Orient",
    "lng": -94.41728697903706,
    "lat": 41.20331070933989
  },
  {
    "name": "Orient",
    "lng": -88.977320991011,
    "lat": 37.91757267365932
  },
  {
    "name": "Orient",
    "lng": -83.15211854241377,
    "lat": 39.8052603000681
  },
  {
    "name": "Orient",
    "lng": -67.84894709373093,
    "lat": 45.82214094451167
  },
  {
    "name": "Oriental",
    "lng": -76.69120666666666,
    "lat": 35.04501800194397
  },
  {
    "name": "Oriental",
    "lng": -76.68568189750918,
    "lat": 35.04674384661256
  },
  {
    "name": "Oriental",
    "lng": -76.68182159376201,
    "lat": 35.047324683079864
  },
  {
    "name": "Oriental",
    "lng": -76.68175276543963,
    "lat": 35.03468823705993
  },
  {
    "name": "Orion",
    "lng": -90.3765412228317,
    "lat": 41.351516463401246
  },
  {
    "name": "Oriska",
    "lng": -97.79075637915996,
    "lat": 46.93153314395861
  },
  {
    "name": "Oriska",
    "lng": -97.78441591391797,
    "lat": 46.922552699039265
  },
  {
    "name": "Oriskany",
    "lng": -75.33385192201774,
    "lat": 43.15729779853696
  },
  {
    "name": "Oriskany Falls",
    "lng": -75.46342333965359,
    "lat": 42.938034080211104
  },
  {
    "name": "Orland",
    "lng": -122.1853532929449,
    "lat": 39.74628238316402
  },
  {
    "name": "Orland",
    "lng": -85.17236556544451,
    "lat": 41.73055705446051
  },
  {
    "name": "Orland",
    "lng": -68.67769923318376,
    "lat": 44.573183136699306
  },
  {
    "name": "Orland Hills",
    "lng": -87.85922303360648,
    "lat": 41.58884050579866
  },
  {
    "name": "Orland Hills",
    "lng": -87.84065626380239,
    "lat": 41.5906090317594
  },
  {
    "name": "Orland Park",
    "lng": -87.8620571109198,
    "lat": 41.60723628619283
  },
  {
    "name": "Orland Park",
    "lng": -87.84962350829701,
    "lat": 41.58241862920425
  },
  {
    "name": "Orlando",
    "lng": -97.36468639331147,
    "lat": 36.13553977443617
  },
  {
    "name": "Orlando",
    "lng": -81.48700493071664,
    "lat": 28.508357948057117
  },
  {
    "name": "Orlando",
    "lng": -81.43172392849739,
    "lat": 28.551108459406258
  },
  {
    "name": "Orlando",
    "lng": -81.42332924302275,
    "lat": 28.507382424568842
  },
  {
    "name": "Orlando",
    "lng": -81.41314580922192,
    "lat": 28.546328463721615
  },
  {
    "name": "Orlando",
    "lng": -81.41109882058149,
    "lat": 28.49667758916059
  },
  {
    "name": "Orlando",
    "lng": -81.40440366639493,
    "lat": 28.575339666955934
  },
  {
    "name": "Orlando",
    "lng": -81.40245850974928,
    "lat": 28.577999074520164
  },
  {
    "name": "Orlando",
    "lng": -81.39418537624299,
    "lat": 28.58837932350391
  },
  {
    "name": "Orlando",
    "lng": -81.39417457952584,
    "lat": 28.587450388730222
  },
  {
    "name": "Orlando",
    "lng": -81.37762987653996,
    "lat": 28.589951539561447
  },
  {
    "name": "Orlando",
    "lng": -81.37661018295097,
    "lat": 28.59157649754434
  },
  {
    "name": "Orlando",
    "lng": -81.36261226395789,
    "lat": 28.50978947145859
  },
  {
    "name": "Orlando",
    "lng": -81.36214714348647,
    "lat": 28.5133790529094
  },
  {
    "name": "Orlando",
    "lng": -81.36103638797663,
    "lat": 28.51265711193113
  },
  {
    "name": "Orlando",
    "lng": -81.35808051982514,
    "lat": 28.422185311787427
  },
  {
    "name": "Orlando",
    "lng": -81.35559798442009,
    "lat": 28.42571076205104
  },
  {
    "name": "Orlando",
    "lng": -81.35049144448342,
    "lat": 28.480704082139525
  },
  {
    "name": "Orlando",
    "lng": -81.33485281680213,
    "lat": 28.462209809063317
  },
  {
    "name": "Orlando",
    "lng": -81.32782116597261,
    "lat": 28.39449384853232
  },
  {
    "name": "Orlando",
    "lng": -81.32715251972638,
    "lat": 28.39172032804614
  },
  {
    "name": "Orlando",
    "lng": -81.28553148428065,
    "lat": 28.506111740479884
  },
  {
    "name": "Orlando",
    "lng": -81.2741165335864,
    "lat": 28.462445007570192
  },
  {
    "name": "Orlando",
    "lng": -81.27374702872918,
    "lat": 28.461724674855812
  },
  {
    "name": "Orlando",
    "lng": -81.2502640778614,
    "lat": 28.470956210893064
  },
  {
    "name": "Orlando",
    "lng": -81.2281405406208,
    "lat": 28.483790686211123
  },
  {
    "name": "Orleans",
    "lng": -99.45509970034327,
    "lat": 40.13172013825944
  },
  {
    "name": "Orleans",
    "lng": -95.09917913187864,
    "lat": 43.4517406466358
  },
  {
    "name": "Orleans",
    "lng": -86.45248896245263,
    "lat": 38.661675554234215
  },
  {
    "name": "Orleans",
    "lng": -72.2004958087941,
    "lat": 44.810367759212305
  },
  {
    "name": "Orlinda",
    "lng": -86.69956879868555,
    "lat": 36.593530767077866
  },
  {
    "name": "Orme",
    "lng": -85.80481380642038,
    "lat": 35.01469920354289
  },
  {
    "name": "Ormond Beach",
    "lng": -81.12881183214331,
    "lat": 29.26740052461311
  },
  {
    "name": "Ormond Beach",
    "lng": -81.11146273374945,
    "lat": 29.25061287068731
  },
  {
    "name": "Ormond Beach",
    "lng": -81.10951989860823,
    "lat": 29.247426813467502
  },
  {
    "name": "Ormond Beach",
    "lng": -81.1077593339242,
    "lat": 29.293728665052125
  },
  {
    "name": "Ormond Beach",
    "lng": -81.04426293371604,
    "lat": 29.291651459798064
  },
  {
    "name": "Ormsby",
    "lng": -94.69859586183142,
    "lat": 43.850377375825175
  },
  {
    "name": "Oro Valley",
    "lng": -110.97663463708602,
    "lat": 32.42079169617786
  },
  {
    "name": "Orofino",
    "lng": -116.25698270903575,
    "lat": 46.486613214672516
  },
  {
    "name": "Orono",
    "lng": -93.59046989750968,
    "lat": 44.964675437471804
  },
  {
    "name": "Orono",
    "lng": -68.7165782652626,
    "lat": 44.88668840087429
  },
  {
    "name": "Oronoco",
    "lng": -92.54010922695272,
    "lat": 44.15981780883292
  },
  {
    "name": "Oronogo",
    "lng": -94.46426986561895,
    "lat": 37.191677397147856
  },
  {
    "name": "Orosi",
    "lng": -119.29025967051827,
    "lat": 36.54340069823858
  },
  {
    "name": "Oroville",
    "lng": -121.56542940576732,
    "lat": 39.499814126143995
  },
  {
    "name": "Oroville",
    "lng": -119.44171945274807,
    "lat": 48.96521837645645
  },
  {
    "name": "Oroville",
    "lng": -119.4339919784261,
    "lat": 48.936622261270685
  },
  {
    "name": "Oroville",
    "lng": -119.42769866073536,
    "lat": 48.92611890369097
  },
  {
    "name": "Oroville",
    "lng": -119.42659257944527,
    "lat": 48.94597587811929
  },
  {
    "name": "Oroville",
    "lng": -119.41082871268088,
    "lat": 48.96054853447465
  },
  {
    "name": "Orr",
    "lng": -92.82454505860562,
    "lat": 48.059986223916646
  },
  {
    "name": "Orrick",
    "lng": -94.12539124648887,
    "lat": 39.2132929913404
  },
  {
    "name": "Orrington",
    "lng": -68.77901374406649,
    "lat": 44.71500879317178
  },
  {
    "name": "Orrum",
    "lng": -79.00894512605842,
    "lat": 34.466727088931016
  },
  {
    "name": "Orrville",
    "lng": -87.2457093981243,
    "lat": 32.306630386657936
  },
  {
    "name": "Orrville",
    "lng": -81.77471311233717,
    "lat": 40.84812980334646
  },
  {
    "name": "Orrville",
    "lng": -81.74969033333332,
    "lat": 40.868578668415125
  },
  {
    "name": "Orting",
    "lng": -122.21141615015478,
    "lat": 47.096901420577744
  },
  {
    "name": "Ortley",
    "lng": -97.20494574126373,
    "lat": 45.33494652741051
  },
  {
    "name": "Ortonville",
    "lng": -96.44285845010907,
    "lat": 45.301538556268746
  },
  {
    "name": "Ortonville",
    "lng": -96.4252256642566,
    "lat": 45.304051519940856
  },
  {
    "name": "Ortonville",
    "lng": -83.44262508444255,
    "lat": 42.851004648535465
  },
  {
    "name": "Orwell",
    "lng": -80.85950009933747,
    "lat": 41.536432417732115
  },
  {
    "name": "Osage",
    "lng": -96.4239061312064,
    "lat": 36.29765294835864
  },
  {
    "name": "Osage",
    "lng": -96.41605552925742,
    "lat": 36.29396099845043
  },
  {
    "name": "Osage",
    "lng": -92.81130454520988,
    "lat": 43.28266000072529
  },
  {
    "name": "Osage Beach",
    "lng": -92.64774517409201,
    "lat": 38.13560877297399
  },
  {
    "name": "Osage City",
    "lng": -95.84146477958753,
    "lat": 38.65244599251495
  },
  {
    "name": "Osage City",
    "lng": -95.8220976207155,
    "lat": 38.63323375762307
  },
  {
    "name": "Osakis",
    "lng": -95.15183070586691,
    "lat": 45.86489100286476
  },
  {
    "name": "Osawatomie",
    "lng": -94.99611111407974,
    "lat": 38.52969907091222
  },
  {
    "name": "Osawatomie",
    "lng": -94.97385991996218,
    "lat": 38.49150902423942
  },
  {
    "name": "Osawatomie",
    "lng": -94.95878146771646,
    "lat": 38.46382123519946
  },
  {
    "name": "Osawatomie",
    "lng": -94.95816812315663,
    "lat": 38.4775696900842
  },
  {
    "name": "Osawatomie",
    "lng": -94.9550947732534,
    "lat": 38.48060668913248
  },
  {
    "name": "Osawatomie",
    "lng": -94.94595660755634,
    "lat": 38.50443675394223
  },
  {
    "name": "Osawatomie",
    "lng": -94.93899260387435,
    "lat": 38.536663431781896
  },
  {
    "name": "Osawatomie",
    "lng": -94.92661061034231,
    "lat": 38.50114972080869
  },
  {
    "name": "Osawatomie",
    "lng": -94.9244152932648,
    "lat": 38.497619796247264
  },
  {
    "name": "Osawatomie",
    "lng": -94.90292042956594,
    "lat": 38.53159963931599
  },
  {
    "name": "Osborn",
    "lng": -94.35677824890486,
    "lat": 39.74958022015187
  },
  {
    "name": "Osborn",
    "lng": -68.2528880265439,
    "lat": 44.78116507163328
  },
  {
    "name": "Osborne",
    "lng": -98.69927993866548,
    "lat": 39.44029933335874
  },
  {
    "name": "Osburn",
    "lng": -116.00093124360117,
    "lat": 47.50550766319503
  },
  {
    "name": "Osceola",
    "lng": -97.54977332296647,
    "lat": 41.17816787157181
  },
  {
    "name": "Osceola",
    "lng": -93.78423061664975,
    "lat": 41.030492081402734
  },
  {
    "name": "Osceola",
    "lng": -93.69832548476947,
    "lat": 38.046014590501755
  },
  {
    "name": "Osceola",
    "lng": -92.69372934489742,
    "lat": 45.319694511975634
  },
  {
    "name": "Osceola",
    "lng": -89.99343565046608,
    "lat": 35.69416766493992
  },
  {
    "name": "Osceola",
    "lng": -86.0788136982777,
    "lat": 41.66478455861167
  },
  {
    "name": "Osgood",
    "lng": -93.3499170933201,
    "lat": 40.19721751245455
  },
  {
    "name": "Osgood",
    "lng": -85.29199654459192,
    "lat": 39.12907329300257
  },
  {
    "name": "Osgood",
    "lng": -84.49562616164417,
    "lat": 40.33939563455283
  },
  {
    "name": "Oshkosh",
    "lng": -102.34515721669018,
    "lat": 41.408016074326255
  },
  {
    "name": "Oskaloosa",
    "lng": -95.3139190752258,
    "lat": 39.216149861280165
  },
  {
    "name": "Oskaloosa",
    "lng": -92.64104593834189,
    "lat": 41.292605451899945
  },
  {
    "name": "Oskaloosa",
    "lng": -92.59453117607816,
    "lat": 41.26191293398218
  },
  {
    "name": "Oslo",
    "lng": -97.13184501140385,
    "lat": 48.1953202960501
  },
  {
    "name": "Osmond",
    "lng": -97.59849819348017,
    "lat": 42.35858236270569
  },
  {
    "name": "Osnabrock",
    "lng": -98.1488603752141,
    "lat": 48.66991034833556
  },
  {
    "name": "Osseo",
    "lng": -93.39935338952586,
    "lat": 45.11777266515471
  },
  {
    "name": "Osseo",
    "lng": -91.21192764484161,
    "lat": 44.57923170979999
  },
  {
    "name": "Ossian",
    "lng": -91.76453584720441,
    "lat": 43.14700400320757
  },
  {
    "name": "Ossian",
    "lng": -85.16803484586437,
    "lat": 40.878222595228024
  },
  {
    "name": "Ossining",
    "lng": -73.87226332079211,
    "lat": 41.160307281834385
  },
  {
    "name": "Ossipee",
    "lng": -79.51455913317551,
    "lat": 36.16828770105745
  },
  {
    "name": "Osterdock",
    "lng": -91.159088028315,
    "lat": 42.73162390958681
  },
  {
    "name": "Ostrander",
    "lng": -92.42863987653652,
    "lat": 43.61421911338976
  },
  {
    "name": "Ostrander",
    "lng": -83.208536550726,
    "lat": 40.266979570391534
  },
  {
    "name": "Oswego",
    "lng": -95.11196251833667,
    "lat": 37.16719432375822
  },
  {
    "name": "Oswego",
    "lng": -88.38504503114244,
    "lat": 41.702702104404864
  },
  {
    "name": "Oswego",
    "lng": -88.36803533574128,
    "lat": 41.68209957280781
  },
  {
    "name": "Oswego",
    "lng": -88.36686955629133,
    "lat": 41.67773375023568
  },
  {
    "name": "Oswego",
    "lng": -88.3530331674612,
    "lat": 41.68790884381156
  },
  {
    "name": "Oswego",
    "lng": -88.34836656147382,
    "lat": 41.70350834752658
  },
  {
    "name": "Oswego",
    "lng": -88.33953398439759,
    "lat": 41.679623324905805
  },
  {
    "name": "Oswego",
    "lng": -88.30268831099117,
    "lat": 41.71337792997927
  },
  {
    "name": "Oswego",
    "lng": -88.2758041153768,
    "lat": 41.71209264302559
  },
  {
    "name": "Oswego",
    "lng": -76.50118087374265,
    "lat": 43.452252269287904
  },
  {
    "name": "Osyka",
    "lng": -90.47179673889485,
    "lat": 31.00719879325923
  },
  {
    "name": "Otego",
    "lng": -75.1800672105277,
    "lat": 42.392739865641374
  },
  {
    "name": "Othello",
    "lng": -119.1651262705127,
    "lat": 46.82266350090986
  },
  {
    "name": "Otho",
    "lng": -94.1495089517216,
    "lat": 42.4221480283041
  },
  {
    "name": "Otis",
    "lng": -102.96214134564595,
    "lat": 40.15011651481803
  },
  {
    "name": "Otis",
    "lng": -99.05350843704294,
    "lat": 38.534853545574975
  },
  {
    "name": "Otis",
    "lng": -73.08564389977555,
    "lat": 42.203782395611455
  },
  {
    "name": "Otis",
    "lng": -68.4738124377406,
    "lat": 44.70900873828806
  },
  {
    "name": "Otisfield",
    "lng": -70.55291685337374,
    "lat": 44.082212144452924
  },
  {
    "name": "Otisville",
    "lng": -83.52462563914915,
    "lat": 43.16602721420001
  },
  {
    "name": "Otisville",
    "lng": -74.53943323899801,
    "lat": 41.47142163636308
  },
  {
    "name": "Oto",
    "lng": -95.89443255770998,
    "lat": 42.28190151379811
  },
  {
    "name": "Otoe",
    "lng": -96.12062675369855,
    "lat": 40.72409100886766
  },
  {
    "name": "Otsego",
    "lng": -93.6191478287217,
    "lat": 45.26599398792597
  },
  {
    "name": "Otsego",
    "lng": -85.69749591998857,
    "lat": 42.457037367393866
  },
  {
    "name": "Ottawa",
    "lng": -95.26569576692495,
    "lat": 38.60588920821123
  },
  {
    "name": "Ottawa",
    "lng": -95.25024125656415,
    "lat": 38.53887952151785
  },
  {
    "name": "Ottawa",
    "lng": -95.24937386824517,
    "lat": 38.64643049690319
  },
  {
    "name": "Ottawa",
    "lng": -95.24588073821702,
    "lat": 38.650546313359
  },
  {
    "name": "Ottawa",
    "lng": -88.83078258296747,
    "lat": 41.35326724874024
  },
  {
    "name": "Ottawa",
    "lng": -84.03542969217277,
    "lat": 41.02021773383092
  },
  {
    "name": "Ottawa Hills",
    "lng": -83.64306072127785,
    "lat": 41.668199872982164
  },
  {
    "name": "Otter Lake",
    "lng": -83.45965038760137,
    "lat": 43.21384465600414
  },
  {
    "name": "Otterbein",
    "lng": -87.09430423325362,
    "lat": 40.48921547466372
  },
  {
    "name": "Ottertail",
    "lng": -95.61448440280891,
    "lat": 46.39496392307175
  },
  {
    "name": "Ottertail",
    "lng": -95.55929257260347,
    "lat": 46.42658709149405
  },
  {
    "name": "Otterville",
    "lng": -93.00276913328142,
    "lat": 38.70292498886883
  },
  {
    "name": "Otterville",
    "lng": -90.39844540498521,
    "lat": 39.05078785859398
  },
  {
    "name": "Ottosen",
    "lng": -94.37676991221849,
    "lat": 42.897119925799274
  },
  {
    "name": "Ottoville",
    "lng": -84.33808295573827,
    "lat": 40.93378753971205
  },
  {
    "name": "Ottumwa",
    "lng": -92.41863361662911,
    "lat": 41.01943012172866
  },
  {
    "name": "Otway",
    "lng": -83.1886266159583,
    "lat": 38.865020359137745
  },
  {
    "name": "Outlook",
    "lng": -104.78507804653339,
    "lat": 48.88874019706956
  },
  {
    "name": "Ouzinkie",
    "lng": -152.4706736331336,
    "lat": 57.922813965811876
  },
  {
    "name": "Overbrook",
    "lng": -95.55690879737823,
    "lat": 38.779424719921565
  },
  {
    "name": "Overland",
    "lng": -90.36892773066342,
    "lat": 38.696707379342335
  },
  {
    "name": "Overland Park",
    "lng": -94.74210623793893,
    "lat": 38.80308993735864
  },
  {
    "name": "Overland Park",
    "lng": -94.68513670271817,
    "lat": 38.90436923542588
  },
  {
    "name": "Overly",
    "lng": -100.1509814771452,
    "lat": 48.68106852246733
  },
  {
    "name": "Overton",
    "lng": -99.5374101244182,
    "lat": 40.74057021044132
  },
  {
    "name": "Overton",
    "lng": -94.97256308425865,
    "lat": 32.27592907518047
  },
  {
    "name": "Ovid",
    "lng": -102.38837742275182,
    "lat": 40.96051819439529
  },
  {
    "name": "Ovid",
    "lng": -84.37341602969252,
    "lat": 43.002124129481935
  },
  {
    "name": "Ovid",
    "lng": -76.82289755925878,
    "lat": 42.6756488868939
  },
  {
    "name": "Ovilla",
    "lng": -96.89074157555906,
    "lat": 32.55843471536391
  },
  {
    "name": "Ovilla",
    "lng": -96.88993065172086,
    "lat": 32.53516624858551
  },
  {
    "name": "Owaneco",
    "lng": -89.1948650044582,
    "lat": 39.48142578726632
  },
  {
    "name": "Owasa",
    "lng": -93.20469900661779,
    "lat": 42.43173925028117
  },
  {
    "name": "Owasso",
    "lng": -95.87381878393997,
    "lat": 36.27130360920436
  },
  {
    "name": "Owasso",
    "lng": -95.82270347220727,
    "lat": 36.29802666998345
  },
  {
    "name": "Owatonna",
    "lng": -93.23023066466455,
    "lat": 44.09160058462126
  },
  {
    "name": "Owen",
    "lng": -90.63527483735369,
    "lat": 44.92769246850466
  },
  {
    "name": "Owen",
    "lng": -90.56742577948035,
    "lat": 44.94986459120621
  },
  {
    "name": "Owendale",
    "lng": -83.26712687842104,
    "lat": 43.72730929009699
  },
  {
    "name": "Owens Cross Roads",
    "lng": -86.47838692790454,
    "lat": 34.56980930327028
  },
  {
    "name": "Owens Cross Roads",
    "lng": -86.45399363962605,
    "lat": 34.589210966444696
  },
  {
    "name": "Owensboro",
    "lng": -87.1778591463142,
    "lat": 37.78783513044912
  },
  {
    "name": "Owensboro",
    "lng": -87.11617191574292,
    "lat": 37.754552011425034
  },
  {
    "name": "Owensboro",
    "lng": -87.0583275642962,
    "lat": 37.79048664309313
  },
  {
    "name": "Owensville",
    "lng": -91.49890359751676,
    "lat": 38.34755482676808
  },
  {
    "name": "Owensville",
    "lng": -87.69085470040784,
    "lat": 38.272016600553805
  },
  {
    "name": "Owensville",
    "lng": -84.13628418596107,
    "lat": 39.12440578890504
  },
  {
    "name": "Owenton",
    "lng": -84.84419131357393,
    "lat": 38.54389443115021
  },
  {
    "name": "Owingsville",
    "lng": -83.7628772755586,
    "lat": 38.13792671817642
  },
  {
    "name": "Owls Head",
    "lng": -69.07338870113897,
    "lat": 44.067944986284175
  },
  {
    "name": "Owosso",
    "lng": -84.2091405206644,
    "lat": 42.99341883362432
  },
  {
    "name": "Owosso",
    "lng": -84.17566714283048,
    "lat": 42.995604448526386
  },
  {
    "name": "Oxbow",
    "lng": -96.80185749792781,
    "lat": 46.67200966959677
  },
  {
    "name": "Oxford",
    "lng": -112.01786925128148,
    "lat": 42.259831571449695
  },
  {
    "name": "Oxford",
    "lng": -99.63275213028979,
    "lat": 40.252935660051754
  },
  {
    "name": "Oxford",
    "lng": -97.16968818043232,
    "lat": 37.27469246113414
  },
  {
    "name": "Oxford",
    "lng": -91.9182635393683,
    "lat": 36.209046540948236
  },
  {
    "name": "Oxford",
    "lng": -91.79130317533583,
    "lat": 41.72273359459833
  },
  {
    "name": "Oxford",
    "lng": -89.56246214036305,
    "lat": 43.77971952851386
  },
  {
    "name": "Oxford",
    "lng": -89.52849189881867,
    "lat": 34.3613481133726
  },
  {
    "name": "Oxford",
    "lng": -87.24846742570078,
    "lat": 40.5213954271836
  },
  {
    "name": "Oxford",
    "lng": -86.0038039148015,
    "lat": 33.5864466471734
  },
  {
    "name": "Oxford",
    "lng": -85.94246008466453,
    "lat": 33.583938632526845
  },
  {
    "name": "Oxford",
    "lng": -85.91428551460649,
    "lat": 33.58536397300581
  },
  {
    "name": "Oxford",
    "lng": -85.90829202964899,
    "lat": 33.582407109052724
  },
  {
    "name": "Oxford",
    "lng": -85.87136088411637,
    "lat": 33.59662962820775
  },
  {
    "name": "Oxford",
    "lng": -85.84862266666664,
    "lat": 33.57021468268099
  },
  {
    "name": "Oxford",
    "lng": -85.77246692243521,
    "lat": 33.5834989167778
  },
  {
    "name": "Oxford",
    "lng": -85.75444281178692,
    "lat": 33.59532803016312
  },
  {
    "name": "Oxford",
    "lng": -84.74400180177338,
    "lat": 39.505258690457595
  },
  {
    "name": "Oxford",
    "lng": -83.87981994420988,
    "lat": 33.62586564297747
  },
  {
    "name": "Oxford",
    "lng": -83.87936724049094,
    "lat": 33.62565248439057
  },
  {
    "name": "Oxford",
    "lng": -83.87207695259495,
    "lat": 33.627686804822126
  },
  {
    "name": "Oxford",
    "lng": -83.25655874716686,
    "lat": 42.821725307412066
  },
  {
    "name": "Oxford",
    "lng": -78.60881467088471,
    "lat": 36.30727730437487
  },
  {
    "name": "Oxford",
    "lng": -78.60450731031476,
    "lat": 36.2912110131842
  },
  {
    "name": "Oxford",
    "lng": -78.58865624163192,
    "lat": 36.310876077548926
  },
  {
    "name": "Oxford",
    "lng": -78.56522123647254,
    "lat": 36.344977450120965
  },
  {
    "name": "Oxford",
    "lng": -76.17034002678028,
    "lat": 38.683457679778336
  },
  {
    "name": "Oxford",
    "lng": -75.59597910319133,
    "lat": 42.44119175224384
  },
  {
    "name": "Oxford",
    "lng": -71.86653494320922,
    "lat": 42.12857542896011
  },
  {
    "name": "Oxford",
    "lng": -70.47619034465433,
    "lat": 44.14432239975249
  },
  {
    "name": "Oxford Junction",
    "lng": -90.95377062646067,
    "lat": 41.98423829988281
  },
  {
    "name": "Oxnard",
    "lng": -119.21239315977273,
    "lat": 34.2002371697803
  },
  {
    "name": "Oxoboxo River",
    "lng": -72.12504467670296,
    "lat": 41.44414934688825
  },
  {
    "name": "Oyens",
    "lng": -96.05799554898937,
    "lat": 42.819830846461734
  },
  {
    "name": "Oyster Bay Cove",
    "lng": -73.50525295107789,
    "lat": 40.862160794331366
  },
  {
    "name": "Ozan",
    "lng": -93.72034473262272,
    "lat": 33.847997951155484
  },
  {
    "name": "Ozark",
    "lng": -93.84228216819233,
    "lat": 35.500645581435656
  },
  {
    "name": "Ozark",
    "lng": -93.81962740683525,
    "lat": 35.471955850694854
  },
  {
    "name": "Ozark",
    "lng": -93.21522592937647,
    "lat": 37.035733749641125
  },
  {
    "name": "Ozark",
    "lng": -85.64714918948741,
    "lat": 31.45051928020725
  },
  {
    "name": "Ozawkie",
    "lng": -95.46583821462313,
    "lat": 39.235401873382195
  },
  {
    "name": "O’Fallon",
    "lng": -90.72233659924231,
    "lat": 38.78494164973539
  },
  {
    "name": "O’Fallon",
    "lng": -90.71778178884416,
    "lat": 38.785178002761114
  },
  {
    "name": "O’Fallon",
    "lng": -90.71385639856068,
    "lat": 38.79235107645079
  },
  {
    "name": "O’Fallon",
    "lng": -90.71130704886082,
    "lat": 38.77587732095963
  },
  {
    "name": "O’Fallon",
    "lng": -90.7113027185628,
    "lat": 38.776434835083656
  },
  {
    "name": "O’Fallon",
    "lng": -90.71097784790528,
    "lat": 38.77738409542984
  },
  {
    "name": "Paauilo CDP",
    "lng": -155.36984610804285,
    "lat": 20.039552003449668
  },
  {
    "name": "Pace",
    "lng": -90.8593307090315,
    "lat": 33.792226078940075
  },
  {
    "name": "Pachuta",
    "lng": -88.88439323769549,
    "lat": 32.04410918348486
  },
  {
    "name": "Pacific",
    "lng": -122.25068287869175,
    "lat": 47.261181789240396
  },
  {
    "name": "Pacific",
    "lng": -90.75490810069824,
    "lat": 38.48042418485726
  },
  {
    "name": "Pacific Grove",
    "lng": -121.93222656780586,
    "lat": 36.645461564393926
  },
  {
    "name": "Pacific Junction",
    "lng": -95.79944561561639,
    "lat": 41.01805025551725
  },
  {
    "name": "Packwood",
    "lng": -92.08255668100908,
    "lat": 41.132683713865895
  },
  {
    "name": "Pacolet",
    "lng": -81.76341075069772,
    "lat": 34.902670132555876
  },
  {
    "name": "Paddock Lake",
    "lng": -88.10219089610192,
    "lat": 42.571477427951436
  },
  {
    "name": "Paden",
    "lng": -96.56659111805536,
    "lat": 35.507597197544904
  },
  {
    "name": "Paden",
    "lng": -88.26267495286523,
    "lat": 34.651243790697485
  },
  {
    "name": "Paden",
    "lng": -88.26114345736063,
    "lat": 34.66071295035129
  },
  {
    "name": "Paden City",
    "lng": -80.93501215899512,
    "lat": 39.603481873640476
  },
  {
    "name": "Paducah",
    "lng": -100.3037768876501,
    "lat": 34.014426763893816
  },
  {
    "name": "Paducah",
    "lng": -88.64456401757627,
    "lat": 37.07116249891422
  },
  {
    "name": "Page",
    "lng": -111.45979576037514,
    "lat": 36.90265636142244
  },
  {
    "name": "Page",
    "lng": -98.4176676666065,
    "lat": 42.40001079313633
  },
  {
    "name": "Page",
    "lng": -97.570544602873,
    "lat": 47.157941601917756
  },
  {
    "name": "Pagedale",
    "lng": -90.30807560374816,
    "lat": 38.680071084829976
  },
  {
    "name": "Pageland",
    "lng": -80.38991161717088,
    "lat": 34.77195789164948
  },
  {
    "name": "Pahala CDP",
    "lng": -155.47929780356463,
    "lat": 19.199485527842604
  },
  {
    "name": "Pahoa CDP",
    "lng": -154.94531729929213,
    "lat": 19.495832005999862
  },
  {
    "name": "Pahokee",
    "lng": -80.6909016638526,
    "lat": 26.810373934657967
  },
  {
    "name": "Pahokee",
    "lng": -80.66159147478945,
    "lat": 26.82006476542392
  },
  {
    "name": "Painesville",
    "lng": -81.25338946516602,
    "lat": 41.72409730718754
  },
  {
    "name": "Paint Rock",
    "lng": -99.92531797789763,
    "lat": 31.510078384992145
  },
  {
    "name": "Painted Post",
    "lng": -77.09244890933503,
    "lat": 42.16341536853944
  },
  {
    "name": "Painter",
    "lng": -75.78395924307235,
    "lat": 37.58512343572726
  },
  {
    "name": "Paintsville",
    "lng": -82.81004089237149,
    "lat": 37.81779757828853
  },
  {
    "name": "Pajaro",
    "lng": -121.74161542289531,
    "lat": 36.901633093359976
  },
  {
    "name": "Palacios",
    "lng": -96.23575087812817,
    "lat": 28.717162335748533
  },
  {
    "name": "Palatine",
    "lng": -88.06262004710385,
    "lat": 42.13840311989767
  },
  {
    "name": "Palatine",
    "lng": -88.0607674463498,
    "lat": 42.13875236309429
  },
  {
    "name": "Palatine",
    "lng": -88.04299086900794,
    "lat": 42.117923989534255
  },
  {
    "name": "Palatine Bridge",
    "lng": -74.57461077762599,
    "lat": 42.91449886763932
  },
  {
    "name": "Palatka",
    "lng": -81.68112158366408,
    "lat": 29.63791119306651
  },
  {
    "name": "Palatka",
    "lng": -81.68081392682318,
    "lat": 29.6349541371367
  },
  {
    "name": "Palatka",
    "lng": -81.67433077938219,
    "lat": 29.64101136186083
  },
  {
    "name": "Palatka",
    "lng": -81.6733197686035,
    "lat": 29.64206760631856
  },
  {
    "name": "Palatka",
    "lng": -81.67303017269894,
    "lat": 29.660027209216675
  },
  {
    "name": "Palatka",
    "lng": -81.6730063251125,
    "lat": 29.662383186757364
  },
  {
    "name": "Palatka",
    "lng": -81.67203707234397,
    "lat": 29.6410337926835
  },
  {
    "name": "Palatka",
    "lng": -81.67107815994977,
    "lat": 29.650513245909515
  },
  {
    "name": "Palatka",
    "lng": -81.66925077759875,
    "lat": 29.640535358853427
  },
  {
    "name": "Palatka",
    "lng": -81.66728161116058,
    "lat": 29.659373090241925
  },
  {
    "name": "Palatka",
    "lng": -81.66558819193979,
    "lat": 29.661438364938473
  },
  {
    "name": "Palatka",
    "lng": -81.66274545069975,
    "lat": 29.64864335441031
  },
  {
    "name": "Palatka",
    "lng": -81.66219135436972,
    "lat": 29.65700894167272
  },
  {
    "name": "Palatka",
    "lng": -81.66133221196993,
    "lat": 29.66930173870303
  },
  {
    "name": "Palatka",
    "lng": -81.65987245994,
    "lat": 29.64685897460052
  },
  {
    "name": "Palatka",
    "lng": -81.65846788335794,
    "lat": 29.650171939862982
  },
  {
    "name": "Palatka",
    "lng": -81.65526762123963,
    "lat": 29.663343268702775
  },
  {
    "name": "Palatka",
    "lng": -81.65420942120198,
    "lat": 29.611802318093243
  },
  {
    "name": "Palco",
    "lng": -99.56360235716839,
    "lat": 39.253150385814095
  },
  {
    "name": "Palermo",
    "lng": -102.23005787864233,
    "lat": 48.33791427167944
  },
  {
    "name": "Palermo",
    "lng": -69.42710998126451,
    "lat": 44.396066868331594
  },
  {
    "name": "Palestine",
    "lng": -95.64711423203687,
    "lat": 31.754398198871193
  },
  {
    "name": "Palestine",
    "lng": -90.90513194138596,
    "lat": 34.96807302527527
  },
  {
    "name": "Palestine",
    "lng": -87.61258473059277,
    "lat": 39.001621597438245
  },
  {
    "name": "Palestine",
    "lng": -84.74432087846822,
    "lat": 40.05015196987822
  },
  {
    "name": "Palisade",
    "lng": -108.3583699277876,
    "lat": 39.10748279144209
  },
  {
    "name": "Palisade",
    "lng": -101.10745999974048,
    "lat": 40.34839639303676
  },
  {
    "name": "Palisade",
    "lng": -93.48931367975172,
    "lat": 46.71235269928178
  },
  {
    "name": "Palisades",
    "lng": -101.80324427666801,
    "lat": 35.06137646300445
  },
  {
    "name": "Palm Bay",
    "lng": -80.66634562067978,
    "lat": 27.84742457625596
  },
  {
    "name": "Palm Bay",
    "lng": -80.66574521874956,
    "lat": 27.963948937584274
  },
  {
    "name": "Palm Bay",
    "lng": -80.62005422017259,
    "lat": 27.952289473128353
  },
  {
    "name": "Palm Beach",
    "lng": -80.03864480306362,
    "lat": 26.69147986372776
  },
  {
    "name": "Palm Beach Shores",
    "lng": -80.03598988823028,
    "lat": 26.777143734991984
  },
  {
    "name": "Palm City",
    "lng": -80.28601786185983,
    "lat": 27.173509222194763
  },
  {
    "name": "Palm Coast",
    "lng": -81.24679411032628,
    "lat": 29.539652928726234
  },
  {
    "name": "Palm Coast",
    "lng": -81.23410529999181,
    "lat": 29.434176550215533
  },
  {
    "name": "Palm Desert",
    "lng": -116.3694694865266,
    "lat": 33.737848916053856
  },
  {
    "name": "Palm Springs",
    "lng": -116.53795079648125,
    "lat": 33.801498673803444
  },
  {
    "name": "Palmdale",
    "lng": -118.10537480267351,
    "lat": 34.594495869633626
  },
  {
    "name": "Palmer",
    "lng": -98.25975498297946,
    "lat": 41.22240768409926
  },
  {
    "name": "Palmer",
    "lng": -97.13987887734085,
    "lat": 39.63338246750312
  },
  {
    "name": "Palmer",
    "lng": -96.67360040352334,
    "lat": 32.42575002605025
  },
  {
    "name": "Palmer",
    "lng": -94.59882324638065,
    "lat": 42.62979619469442
  },
  {
    "name": "Palmer",
    "lng": -89.40792315512746,
    "lat": 39.458677098561225
  },
  {
    "name": "Palmer",
    "lng": -85.56558053174207,
    "lat": 35.35748978293972
  },
  {
    "name": "Palmer",
    "lng": -72.31115545453197,
    "lat": 42.18880590396532
  },
  {
    "name": "Palmetto",
    "lng": -91.90816607271091,
    "lat": 30.717720795700906
  },
  {
    "name": "Palmetto",
    "lng": -84.66789707814984,
    "lat": 33.53108283031837
  },
  {
    "name": "Palmhurst",
    "lng": -98.29541533073639,
    "lat": 26.258254271135925
  },
  {
    "name": "Palmona Park",
    "lng": -81.89467845887687,
    "lat": 26.689170187189536
  },
  {
    "name": "Palmview",
    "lng": -98.37912381958458,
    "lat": 26.230225386390746
  },
  {
    "name": "Palmyra",
    "lng": -96.39214066494654,
    "lat": 40.705773662028484
  },
  {
    "name": "Palmyra",
    "lng": -91.52778571991776,
    "lat": 39.798921489804364
  },
  {
    "name": "Palmyra",
    "lng": -89.99591334118979,
    "lat": 39.434497119802934
  },
  {
    "name": "Palmyra",
    "lng": -88.58783761182457,
    "lat": 42.87852281434983
  },
  {
    "name": "Palmyra",
    "lng": -86.10909576198969,
    "lat": 38.40818060656065
  },
  {
    "name": "Palmyra",
    "lng": -77.22966886748397,
    "lat": 43.06085921298793
  },
  {
    "name": "Palmyra",
    "lng": -76.5942239454768,
    "lat": 40.309938677977556
  },
  {
    "name": "Palmyra",
    "lng": -69.36271645459578,
    "lat": 44.84563543143168
  },
  {
    "name": "Palo",
    "lng": -91.80051514792713,
    "lat": 42.06507947028713
  },
  {
    "name": "Palo Alto",
    "lng": -122.14341675291757,
    "lat": 37.39493511306765
  },
  {
    "name": "Palo Cedro",
    "lng": -122.23633005383098,
    "lat": 40.55118668073083
  },
  {
    "name": "Palos Heights",
    "lng": -87.79585938635225,
    "lat": 41.66369639799542
  },
  {
    "name": "Palos Hills",
    "lng": -87.82661485817746,
    "lat": 41.69858818223514
  },
  {
    "name": "Palos Park",
    "lng": -87.95351590827647,
    "lat": 41.67805400945129
  },
  {
    "name": "Palos Park",
    "lng": -87.91960646299509,
    "lat": 41.66865935815905
  },
  {
    "name": "Palos Park",
    "lng": -87.89393214759392,
    "lat": 41.661645261985846
  },
  {
    "name": "Palos Park",
    "lng": -87.89238825894866,
    "lat": 41.652635891542204
  },
  {
    "name": "Palos Park",
    "lng": -87.88983645260042,
    "lat": 41.65250300634525
  },
  {
    "name": "Palos Park",
    "lng": -87.8565331961954,
    "lat": 41.65824892834002
  },
  {
    "name": "Palos Park",
    "lng": -87.83041776943433,
    "lat": 41.66823598280961
  },
  {
    "name": "Palouse",
    "lng": -117.07530657100189,
    "lat": 46.91033254419178
  },
  {
    "name": "Pampa",
    "lng": -100.96519784974606,
    "lat": 35.546828277333695
  },
  {
    "name": "Pamplico",
    "lng": -79.5691423820767,
    "lat": 33.996638602310995
  },
  {
    "name": "Pamplin City",
    "lng": -78.68105619548156,
    "lat": 37.26302388221378
  },
  {
    "name": "Pana",
    "lng": -89.06432865175309,
    "lat": 39.38276871219378
  },
  {
    "name": "Panama",
    "lng": -96.5111709397893,
    "lat": 40.59980597610274
  },
  {
    "name": "Panama",
    "lng": -95.4753227930973,
    "lat": 41.72610935474171
  },
  {
    "name": "Panama",
    "lng": -94.6703788916505,
    "lat": 35.17075534227992
  },
  {
    "name": "Panama",
    "lng": -89.52465991979523,
    "lat": 39.02912293755196
  },
  {
    "name": "Panama",
    "lng": -79.48498003329253,
    "lat": 42.07465339652606
  },
  {
    "name": "Panama City",
    "lng": -85.73540923471268,
    "lat": 30.177264754407204
  },
  {
    "name": "Panama City",
    "lng": -85.71986129519009,
    "lat": 30.19218254314015
  },
  {
    "name": "Panama City",
    "lng": -85.70630986440354,
    "lat": 30.202586585676183
  },
  {
    "name": "Panama City",
    "lng": -85.703714762115,
    "lat": 30.203342416380966
  },
  {
    "name": "Panama City",
    "lng": -85.70285534257845,
    "lat": 30.203298313744334
  },
  {
    "name": "Panama City",
    "lng": -85.70280531124551,
    "lat": 30.20680346556645
  },
  {
    "name": "Panama City",
    "lng": -85.70174808777848,
    "lat": 30.203292776382753
  },
  {
    "name": "Panama City",
    "lng": -85.70013563585545,
    "lat": 30.203268088995802
  },
  {
    "name": "Panama City",
    "lng": -85.69900749999921,
    "lat": 30.204438799968205
  },
  {
    "name": "Panama City",
    "lng": -85.69807010005748,
    "lat": 30.205081668352314
  },
  {
    "name": "Panama City",
    "lng": -85.69641825378241,
    "lat": 30.20476062854038
  },
  {
    "name": "Panama City",
    "lng": -85.6962986283692,
    "lat": 30.207276823849863
  },
  {
    "name": "Panama City",
    "lng": -85.69532114988318,
    "lat": 30.207313603197708
  },
  {
    "name": "Panama City",
    "lng": -85.69438294229967,
    "lat": 30.193139478165996
  },
  {
    "name": "Panama City",
    "lng": -85.69325562222038,
    "lat": 30.191986456961146
  },
  {
    "name": "Panama City",
    "lng": -85.69217220800786,
    "lat": 30.1951270482918
  },
  {
    "name": "Panama City",
    "lng": -85.69120183112328,
    "lat": 30.19390866711701
  },
  {
    "name": "Panama City",
    "lng": -85.69112004787088,
    "lat": 30.19666824423025
  },
  {
    "name": "Panama City",
    "lng": -85.69086329809664,
    "lat": 30.190973769868716
  },
  {
    "name": "Panama City",
    "lng": -85.69065009026188,
    "lat": 30.197120374793215
  },
  {
    "name": "Panama City",
    "lng": -85.68899468315703,
    "lat": 30.196305786250214
  },
  {
    "name": "Panama City",
    "lng": -85.68869391022292,
    "lat": 30.194233749817673
  },
  {
    "name": "Panama City",
    "lng": -85.68801470651881,
    "lat": 30.1946997465363
  },
  {
    "name": "Panama City",
    "lng": -85.68728799062917,
    "lat": 30.192867037637463
  },
  {
    "name": "Panama City",
    "lng": -85.68703033749655,
    "lat": 30.200020654350087
  },
  {
    "name": "Panama City",
    "lng": -85.68659718992976,
    "lat": 30.197111957546493
  },
  {
    "name": "Panama City",
    "lng": -85.68373884321069,
    "lat": 30.19664642104321
  },
  {
    "name": "Panama City",
    "lng": -85.68309471841904,
    "lat": 30.192784546186136
  },
  {
    "name": "Panama City",
    "lng": -85.68279109771974,
    "lat": 30.200363729317125
  },
  {
    "name": "Panama City",
    "lng": -85.6825930508779,
    "lat": 30.19140612057237
  },
  {
    "name": "Panama City",
    "lng": -85.6822963093687,
    "lat": 30.190926722766346
  },
  {
    "name": "Panama City",
    "lng": -85.68220548414415,
    "lat": 30.19753911170181
  },
  {
    "name": "Panama City",
    "lng": -85.68181383914488,
    "lat": 30.19396770315863
  },
  {
    "name": "Panama City",
    "lng": -85.68139984560874,
    "lat": 30.199758189057274
  },
  {
    "name": "Panama City",
    "lng": -85.68037810933173,
    "lat": 30.194246007678323
  },
  {
    "name": "Panama City",
    "lng": -85.67996173044588,
    "lat": 30.19479691602317
  },
  {
    "name": "Panama City",
    "lng": -85.67992955272956,
    "lat": 30.195743037389974
  },
  {
    "name": "Panama City",
    "lng": -85.67992667675286,
    "lat": 30.191727487051157
  },
  {
    "name": "Panama City",
    "lng": -85.67916966255234,
    "lat": 30.194314553933893
  },
  {
    "name": "Panama City",
    "lng": -85.67234091866631,
    "lat": 30.23461265608285
  },
  {
    "name": "Panama City",
    "lng": -85.6690895748586,
    "lat": 30.17876215516538
  },
  {
    "name": "Panama City",
    "lng": -85.65897263694261,
    "lat": 30.206355622989182
  },
  {
    "name": "Panama City",
    "lng": -85.62400949163666,
    "lat": 30.143581354710918
  },
  {
    "name": "Panama City",
    "lng": -85.6066053480739,
    "lat": 30.214882664532933
  },
  {
    "name": "Panama City",
    "lng": -85.5789483128748,
    "lat": 30.23464118794044
  },
  {
    "name": "Panama City",
    "lng": -85.50616322158993,
    "lat": 30.230636410048636
  },
  {
    "name": "Panama City Beach",
    "lng": -85.87739900718648,
    "lat": 30.23731525932839
  },
  {
    "name": "Pandora",
    "lng": -83.96147514337675,
    "lat": 40.94806722679419
  },
  {
    "name": "Pangburn",
    "lng": -91.83940263304922,
    "lat": 35.42612469779285
  },
  {
    "name": "Panguitch",
    "lng": -112.43475431599865,
    "lat": 37.82253229203081
  },
  {
    "name": "Panhandle",
    "lng": -101.38043111456061,
    "lat": 35.346071802676796
  },
  {
    "name": "Panola",
    "lng": -89.02025254687429,
    "lat": 40.78284931452913
  },
  {
    "name": "Panora",
    "lng": -94.37450781608163,
    "lat": 41.69093211275874
  },
  {
    "name": "Panora",
    "lng": -94.36237125295676,
    "lat": 41.6920711479855
  },
  {
    "name": "Panorama Park",
    "lng": -90.45373935159604,
    "lat": 41.55571418296064
  },
  {
    "name": "Panorama Village",
    "lng": -95.49429623870024,
    "lat": 30.38076756569232
  },
  {
    "name": "Pantego",
    "lng": -97.1540654287781,
    "lat": 32.71504618224785
  },
  {
    "name": "Pantego",
    "lng": -76.65944146641519,
    "lat": 35.58738062916154
  },
  {
    "name": "Paola",
    "lng": -94.8719240890491,
    "lat": 38.59797001230387
  },
  {
    "name": "Paola",
    "lng": -94.86251000347472,
    "lat": 38.57782110546282
  },
  {
    "name": "Paoli",
    "lng": -102.4720569949896,
    "lat": 40.612870093837984
  },
  {
    "name": "Paoli",
    "lng": -97.26162186519555,
    "lat": 34.82628927553525
  },
  {
    "name": "Paoli",
    "lng": -97.24693148092558,
    "lat": 34.82282309329189
  },
  {
    "name": "Paoli",
    "lng": -86.46992549974271,
    "lat": 38.557156237409714
  },
  {
    "name": "Paonia",
    "lng": -107.60356398355704,
    "lat": 38.86815649353339
  },
  {
    "name": "Paonia",
    "lng": -107.59037310539509,
    "lat": 38.86960732003988
  },
  {
    "name": "Papaikou CDP",
    "lng": -155.0941143506094,
    "lat": 19.7918444123702
  },
  {
    "name": "Papillion",
    "lng": -96.04433625559453,
    "lat": 41.15299271057998
  },
  {
    "name": "Parachute",
    "lng": -108.05386817403922,
    "lat": 39.447732872418904
  },
  {
    "name": "Paradise",
    "lng": -121.60634124413785,
    "lat": 39.75578004934234
  },
  {
    "name": "Paradise",
    "lng": -111.83335679146249,
    "lat": 41.56819346485458
  },
  {
    "name": "Paradise",
    "lng": -98.91848910286899,
    "lat": 39.114558047855034
  },
  {
    "name": "Paradise",
    "lng": -97.68893447758043,
    "lat": 33.15037530181806
  },
  {
    "name": "Paradise Hill",
    "lng": -95.07021314944355,
    "lat": 35.613849524728295
  },
  {
    "name": "Paragon",
    "lng": -86.56255927966885,
    "lat": 39.3836045729049
  },
  {
    "name": "Paragon",
    "lng": -86.56186118823679,
    "lat": 39.39461056488143
  },
  {
    "name": "Paragonah",
    "lng": -112.7715586527792,
    "lat": 37.88531036340806
  },
  {
    "name": "Paragould",
    "lng": -90.51503283593291,
    "lat": 36.05544892132177
  },
  {
    "name": "Parchment",
    "lng": -85.56657579910917,
    "lat": 42.32765998968007
  },
  {
    "name": "Pardeeville",
    "lng": -89.30109306902122,
    "lat": 43.53587292096473
  },
  {
    "name": "Paris",
    "lng": -111.4032190131684,
    "lat": 42.227322840002635
  },
  {
    "name": "Paris",
    "lng": -95.55300909691668,
    "lat": 33.670881875422154
  },
  {
    "name": "Paris",
    "lng": -95.51357676080313,
    "lat": 33.69175649779067
  },
  {
    "name": "Paris",
    "lng": -95.45028161629342,
    "lat": 33.63963851422489
  },
  {
    "name": "Paris",
    "lng": -93.72326746517659,
    "lat": 35.289901356022455
  },
  {
    "name": "Paris",
    "lng": -92.0040098005003,
    "lat": 39.477259388223
  },
  {
    "name": "Paris",
    "lng": -88.30506524150157,
    "lat": 36.29767943997686
  },
  {
    "name": "Paris",
    "lng": -87.69153578902763,
    "lat": 39.61494488943758
  },
  {
    "name": "Paris",
    "lng": -87.65314212023418,
    "lat": 39.609761205079266
  },
  {
    "name": "Paris",
    "lng": -84.27236366888117,
    "lat": 38.20106280831901
  },
  {
    "name": "Paris",
    "lng": -70.48833443182363,
    "lat": 44.24452047318876
  },
  {
    "name": "Parish",
    "lng": -76.12638045872171,
    "lat": 43.4058151788642
  },
  {
    "name": "Park",
    "lng": -100.36144800730155,
    "lat": 39.111829286983586
  },
  {
    "name": "Park City",
    "lng": -111.50130597317862,
    "lat": 40.6502305473043
  },
  {
    "name": "Park City",
    "lng": -97.34845510388567,
    "lat": 37.80408888903601
  },
  {
    "name": "Park City",
    "lng": -97.3258760068312,
    "lat": 37.80958213548334
  },
  {
    "name": "Park City",
    "lng": -97.29745152558893,
    "lat": 37.81483087760708
  },
  {
    "name": "Park City",
    "lng": -87.89156448808465,
    "lat": 42.3520581622908
  },
  {
    "name": "Park City",
    "lng": -86.04855389519105,
    "lat": 37.09340055478617
  },
  {
    "name": "Park Falls",
    "lng": -90.44630375624267,
    "lat": 45.93481611348521
  },
  {
    "name": "Park Forest",
    "lng": -87.68669324666295,
    "lat": 41.481972676132024
  },
  {
    "name": "Park Hills",
    "lng": -90.50577672893984,
    "lat": 37.82115723179041
  },
  {
    "name": "Park Hills",
    "lng": -84.53138098532635,
    "lat": 39.06901365104514
  },
  {
    "name": "Park Rapids",
    "lng": -95.07078945120496,
    "lat": 46.940150181350184
  },
  {
    "name": "Park Rapids",
    "lng": -95.06756169756592,
    "lat": 46.94241856059749
  },
  {
    "name": "Park Rapids",
    "lng": -95.05726321566891,
    "lat": 46.916263641864624
  },
  {
    "name": "Park Ridge",
    "lng": -89.54622115681674,
    "lat": 44.520038535417854
  },
  {
    "name": "Park Ridge",
    "lng": -87.8436716953884,
    "lat": 42.01259311924973
  },
  {
    "name": "Park River",
    "lng": -97.78026134401505,
    "lat": 48.394990203667206
  },
  {
    "name": "Park River",
    "lng": -97.74315968010934,
    "lat": 48.39589513264774
  },
  {
    "name": "Park River",
    "lng": -97.73619310796194,
    "lat": 48.38206322707513
  },
  {
    "name": "Park River",
    "lng": -97.71438011765693,
    "lat": 48.3744324861132
  },
  {
    "name": "Park View",
    "lng": -90.53882385149633,
    "lat": 41.69385279389054
  },
  {
    "name": "Parkdale",
    "lng": -91.54640073583931,
    "lat": 33.121191427429714
  },
  {
    "name": "Parker",
    "lng": -114.29042360650075,
    "lat": 34.14970646492708
  },
  {
    "name": "Parker",
    "lng": -114.21713253444244,
    "lat": 34.019070499209505
  },
  {
    "name": "Parker",
    "lng": -111.758544876383,
    "lat": 43.958533901046394
  },
  {
    "name": "Parker",
    "lng": -97.13562020517513,
    "lat": 43.39670032964254
  },
  {
    "name": "Parker",
    "lng": -94.98951066157997,
    "lat": 38.32897056418035
  },
  {
    "name": "Parker",
    "lng": -85.6001283065059,
    "lat": 30.12998841621174
  },
  {
    "name": "Parker City",
    "lng": -85.2038748922051,
    "lat": 40.18979041254618
  },
  {
    "name": "Parkerfield",
    "lng": -96.9954238533877,
    "lat": 37.06803355975374
  },
  {
    "name": "Parkers Crossroads",
    "lng": -88.39289388878036,
    "lat": 35.78421674160623
  },
  {
    "name": "Parkers Prairie",
    "lng": -95.32906783028092,
    "lat": 46.15314113670245
  },
  {
    "name": "Parkersburg",
    "lng": -92.77875461008597,
    "lat": 42.573913578378374
  },
  {
    "name": "Parkersburg",
    "lng": -88.05683349155358,
    "lat": 38.58913512642771
  },
  {
    "name": "Parkersburg",
    "lng": -81.54183117912979,
    "lat": 39.2623165654961
  },
  {
    "name": "Parkerville",
    "lng": -96.66171794420846,
    "lat": 38.76373933104943
  },
  {
    "name": "Parkin",
    "lng": -90.55270344439904,
    "lat": 35.263052015425295
  },
  {
    "name": "Parkman",
    "lng": -69.44099844715718,
    "lat": 45.109058106316404
  },
  {
    "name": "Parks",
    "lng": -91.82725973722627,
    "lat": 30.217000182821558
  },
  {
    "name": "Parks",
    "lng": -91.82584394412193,
    "lat": 30.226700404253545
  },
  {
    "name": "Parksley",
    "lng": -75.6538883987507,
    "lat": 37.78636167350039
  },
  {
    "name": "Parkston",
    "lng": -97.98635247236604,
    "lat": 43.39363335934281
  },
  {
    "name": "Parksville",
    "lng": -82.21760176902444,
    "lat": 33.7858833991803
  },
  {
    "name": "Parkton",
    "lng": -79.00976047597361,
    "lat": 34.90284280468608
  },
  {
    "name": "Parkville",
    "lng": -94.72213694975574,
    "lat": 39.200637768905366
  },
  {
    "name": "Parkville",
    "lng": -94.71674859011142,
    "lat": 39.22091797887701
  },
  {
    "name": "Parkville",
    "lng": -94.69390778195152,
    "lat": 39.2109544206478
  },
  {
    "name": "Parkville",
    "lng": -76.96853868821678,
    "lat": 39.78591895442655
  },
  {
    "name": "Parkway",
    "lng": -90.97193745561465,
    "lat": 38.33695924443125
  },
  {
    "name": "Parkway",
    "lng": -90.96443705999793,
    "lat": 38.3338116458926
  },
  {
    "name": "Parkway Village",
    "lng": -85.73786957547371,
    "lat": 38.2112721111723
  },
  {
    "name": "Parlier",
    "lng": -119.5704808407964,
    "lat": 36.59584193008817
  },
  {
    "name": "Parlier",
    "lng": -119.54168106116396,
    "lat": 36.608867606252325
  },
  {
    "name": "Parlier",
    "lng": -119.52158247084945,
    "lat": 36.59830013488906
  },
  {
    "name": "Parlier",
    "lng": -119.5198903796194,
    "lat": 36.60071034246803
  },
  {
    "name": "Parma",
    "lng": -116.94290681056323,
    "lat": 43.786360083901755
  },
  {
    "name": "Parma",
    "lng": -89.81804813319938,
    "lat": 36.61164915686398
  },
  {
    "name": "Parma",
    "lng": -84.60874880645763,
    "lat": 42.27147671652821
  },
  {
    "name": "Parma",
    "lng": -84.59825061865364,
    "lat": 42.25733458144153
  },
  {
    "name": "Parma",
    "lng": -84.55267389626795,
    "lat": 42.26477391330707
  },
  {
    "name": "Parma",
    "lng": -81.72857220674479,
    "lat": 41.38426175054933
  },
  {
    "name": "Parma Heights",
    "lng": -81.76368672574067,
    "lat": 41.38651933946732
  },
  {
    "name": "Parmele",
    "lng": -77.31460356709329,
    "lat": 35.818951724511486
  },
  {
    "name": "Parnell",
    "lng": -94.62180815605728,
    "lat": 40.43858730728782
  },
  {
    "name": "Parnell",
    "lng": -92.00411319718599,
    "lat": 41.58371322187204
  },
  {
    "name": "Parowan",
    "lng": -112.82958227762025,
    "lat": 37.83262851476594
  },
  {
    "name": "Parral",
    "lng": -81.49496950583936,
    "lat": 40.56094448843029
  },
  {
    "name": "Parrish",
    "lng": -87.2801238816459,
    "lat": 33.73233413185058
  },
  {
    "name": "Parrott",
    "lng": -84.51175678742386,
    "lat": 31.89438523573285
  },
  {
    "name": "Parrottsville",
    "lng": -83.08592791513132,
    "lat": 36.01029299698067
  },
  {
    "name": "Parshall",
    "lng": -102.13420652196776,
    "lat": 47.955524877088756
  },
  {
    "name": "Parsons",
    "lng": -95.5096872349007,
    "lat": 37.33161712196197
  },
  {
    "name": "Parsons",
    "lng": -95.26712897132826,
    "lat": 37.34035895597512
  },
  {
    "name": "Parsons",
    "lng": -95.26300697299098,
    "lat": 37.30626369956588
  },
  {
    "name": "Parsons",
    "lng": -95.25490264157527,
    "lat": 37.38095267475071
  },
  {
    "name": "Parsons",
    "lng": -88.12336950305507,
    "lat": 35.650082583540176
  },
  {
    "name": "Parsons",
    "lng": -79.67852791496888,
    "lat": 39.09417837604186
  },
  {
    "name": "Parsonsfield",
    "lng": -70.91152016785306,
    "lat": 43.73565855206234
  },
  {
    "name": "Partridge",
    "lng": -98.09398022779823,
    "lat": 37.96660295925271
  },
  {
    "name": "Pasadena",
    "lng": -118.13909118176527,
    "lat": 34.15986276052737
  },
  {
    "name": "Pasadena",
    "lng": -95.14579865087083,
    "lat": 29.65679665836648
  },
  {
    "name": "Pasadena Hills",
    "lng": -90.29208242393463,
    "lat": 38.708363474737475
  },
  {
    "name": "Pasadena Park",
    "lng": -90.29745844487492,
    "lat": 38.71100840184079
  },
  {
    "name": "Pascagoula",
    "lng": -88.55899257297258,
    "lat": 30.35768794132668
  },
  {
    "name": "Pasco",
    "lng": -119.1319172468695,
    "lat": 46.25108566641371
  },
  {
    "name": "Pascola",
    "lng": -89.82581139180319,
    "lat": 36.26726296137521
  },
  {
    "name": "Paso Robles",
    "lng": -120.65682750771902,
    "lat": 35.63926345888602
  },
  {
    "name": "Paso Robles",
    "lng": -120.53166247946334,
    "lat": 35.66307980846271
  },
  {
    "name": "Pass Christian",
    "lng": -89.23888767368388,
    "lat": 30.318877832413374
  },
  {
    "name": "Passadumkeag",
    "lng": -68.58807375227398,
    "lat": 45.18428627591948
  },
  {
    "name": "Passaic",
    "lng": -94.34855337133554,
    "lat": 38.32202612494922
  },
  {
    "name": "Passaic",
    "lng": -74.12811184088326,
    "lat": 40.85746052745397
  },
  {
    "name": "Patagonia",
    "lng": -110.74772559059718,
    "lat": 31.543599320673025
  },
  {
    "name": "Pataskala",
    "lng": -82.76995175933686,
    "lat": 39.97883040833617
  },
  {
    "name": "Pataskala",
    "lng": -82.76607522785922,
    "lat": 39.97813998669928
  },
  {
    "name": "Pataskala",
    "lng": -82.71566276716335,
    "lat": 40.011042540578046
  },
  {
    "name": "Patch Grove",
    "lng": -90.97228987948037,
    "lat": 42.94041262035628
  },
  {
    "name": "Patchogue",
    "lng": -73.02289197170295,
    "lat": 40.76397370369129
  },
  {
    "name": "Pateros",
    "lng": -119.90032268641909,
    "lat": 48.0554019689981
  },
  {
    "name": "Paterson",
    "lng": -74.1624173718557,
    "lat": 40.91469087814584
  },
  {
    "name": "Patmos",
    "lng": -93.56701685614132,
    "lat": 33.511359328435624
  },
  {
    "name": "Patoka",
    "lng": -89.0957982707296,
    "lat": 38.75398956953833
  },
  {
    "name": "Patoka",
    "lng": -87.58887948231005,
    "lat": 38.401738211430064
  },
  {
    "name": "Paton",
    "lng": -94.25503927196203,
    "lat": 42.16429056049819
  },
  {
    "name": "Patrick",
    "lng": -80.04465500683983,
    "lat": 34.575253083159986
  },
  {
    "name": "Patriot",
    "lng": -84.82817060515185,
    "lat": 38.83795045381128
  },
  {
    "name": "Patten",
    "lng": -68.49540567037245,
    "lat": 45.98995627474465
  },
  {
    "name": "Patterson",
    "lng": -121.14864015512882,
    "lat": 37.469513641843655
  },
  {
    "name": "Patterson",
    "lng": -121.093832181177,
    "lat": 37.50061733941594
  },
  {
    "name": "Patterson",
    "lng": -93.87770819954761,
    "lat": 41.34789026062125
  },
  {
    "name": "Patterson",
    "lng": -91.30953891379595,
    "lat": 29.690886958163137
  },
  {
    "name": "Patterson",
    "lng": -91.2350127749604,
    "lat": 35.25897292040461
  },
  {
    "name": "Patterson",
    "lng": -83.52602999172171,
    "lat": 40.78229442191001
  },
  {
    "name": "Patterson",
    "lng": -82.13694139490322,
    "lat": 31.38530422454594
  },
  {
    "name": "Patterson Springs",
    "lng": -81.51618847374769,
    "lat": 35.2329979259592
  },
  {
    "name": "Pattison",
    "lng": -95.97574625884656,
    "lat": 29.81905864104131
  },
  {
    "name": "Patton Village",
    "lng": -95.16877331611451,
    "lat": 30.194799077046188
  },
  {
    "name": "Patton Village",
    "lng": -95.13551230598043,
    "lat": 30.194369470990075
  },
  {
    "name": "Pattonsburg",
    "lng": -94.10601590559318,
    "lat": 40.071431906083426
  },
  {
    "name": "Paukaa CDP",
    "lng": -155.09387262968065,
    "lat": 19.76241581339859
  },
  {
    "name": "Paul",
    "lng": -113.78470388831313,
    "lat": 42.605190647278725
  },
  {
    "name": "Paulding",
    "lng": -84.58238404555047,
    "lat": 41.142384481001756
  },
  {
    "name": "Paullina",
    "lng": -95.68371928622415,
    "lat": 42.979254657549085
  },
  {
    "name": "Paullina",
    "lng": -95.68179950394943,
    "lat": 42.983453894120636
  },
  {
    "name": "Paullina",
    "lng": -95.67647541022481,
    "lat": 42.98486427629114
  },
  {
    "name": "Pauls Valley",
    "lng": -97.22895900821004,
    "lat": 34.72328538244337
  },
  {
    "name": "Pavillion",
    "lng": -108.68902050479139,
    "lat": 43.244004098607704
  },
  {
    "name": "Pavo",
    "lng": -83.73788979638614,
    "lat": 30.959770368922577
  },
  {
    "name": "Paw Paw",
    "lng": -88.9808552784964,
    "lat": 41.6875654975411
  },
  {
    "name": "Paw Paw",
    "lng": -85.89202497048662,
    "lat": 42.21477269774734
  },
  {
    "name": "Paw Paw",
    "lng": -78.45522114411406,
    "lat": 39.53140326393875
  },
  {
    "name": "Pawcatuck",
    "lng": -71.85148055109002,
    "lat": 41.37634528074128
  },
  {
    "name": "Pawhuska",
    "lng": -96.35454403649767,
    "lat": 36.65940372767827
  },
  {
    "name": "Pawhuska",
    "lng": -96.33158313675314,
    "lat": 36.66931056531065
  },
  {
    "name": "Pawhuska",
    "lng": -96.32299019071097,
    "lat": 36.694630960929764
  },
  {
    "name": "Pawhuska",
    "lng": -96.31641020702389,
    "lat": 36.64750328896376
  },
  {
    "name": "Pawhuska Indian Village",
    "lng": -96.31811986632687,
    "lat": 36.67645388891812
  },
  {
    "name": "Pawleys Island",
    "lng": -79.11945751885483,
    "lat": 33.42128230428423
  },
  {
    "name": "Pawling",
    "lng": -73.59874050199612,
    "lat": 41.56332926038424
  },
  {
    "name": "Pawnee",
    "lng": -96.80186329767207,
    "lat": 36.336355296734375
  },
  {
    "name": "Pawnee",
    "lng": -89.60364953167495,
    "lat": 39.58840501253699
  },
  {
    "name": "Pawnee",
    "lng": -89.60280472326055,
    "lat": 39.594494188328945
  },
  {
    "name": "Pawnee",
    "lng": -89.58094998926568,
    "lat": 39.593566181383984
  },
  {
    "name": "Pawnee City",
    "lng": -96.15296798525706,
    "lat": 40.11062477293629
  },
  {
    "name": "Pawnee Rock",
    "lng": -98.98232143492199,
    "lat": 38.26496018251661
  },
  {
    "name": "Pawtucket",
    "lng": -71.37422553100133,
    "lat": 41.87443735830399
  },
  {
    "name": "Pax",
    "lng": -81.2647850218097,
    "lat": 37.90982518661662
  },
  {
    "name": "Paxico",
    "lng": -96.16710918722104,
    "lat": 39.06889727577223
  },
  {
    "name": "Paxton",
    "lng": -101.35559867017643,
    "lat": 41.12290175816309
  },
  {
    "name": "Paxton",
    "lng": -88.10200304077091,
    "lat": 40.456343638727716
  },
  {
    "name": "Paxton",
    "lng": -71.9330204236288,
    "lat": 42.31094537106617
  },
  {
    "name": "Paxtonia",
    "lng": -76.7884645083163,
    "lat": 40.31663277443455
  },
  {
    "name": "Paxville",
    "lng": -80.35856513345975,
    "lat": 33.7390271964053
  },
  {
    "name": "Payette",
    "lng": -116.92553231833826,
    "lat": 44.07882376279664
  },
  {
    "name": "Payette",
    "lng": -116.92417555205728,
    "lat": 44.059553627554486
  },
  {
    "name": "Payne",
    "lng": -84.72787890663142,
    "lat": 41.079798991677315
  },
  {
    "name": "Payne Springs",
    "lng": -96.08949221330218,
    "lat": 32.28758374211687
  },
  {
    "name": "Paynesville",
    "lng": -94.73373566730673,
    "lat": 45.384356998833624
  },
  {
    "name": "Paynesville",
    "lng": -94.72148011545318,
    "lat": 45.378329654564176
  },
  {
    "name": "Paynesville",
    "lng": -90.90029476360759,
    "lat": 39.262477454229135
  },
  {
    "name": "Payson",
    "lng": -111.73936411310645,
    "lat": 40.03607234562092
  },
  {
    "name": "Payson",
    "lng": -111.31945513723507,
    "lat": 34.24316519560695
  },
  {
    "name": "Payson",
    "lng": -91.2443217543913,
    "lat": 39.81694088228789
  },
  {
    "name": "Pe Ell",
    "lng": -123.29832080689243,
    "lat": 46.571475903443165
  },
  {
    "name": "Pea Ridge",
    "lng": -94.12117871692655,
    "lat": 36.44905670759172
  },
  {
    "name": "Peabody",
    "lng": -97.10678556958284,
    "lat": 38.16879733717251
  },
  {
    "name": "Peabody",
    "lng": -97.07454430140719,
    "lat": 38.167349956484074
  },
  {
    "name": "Peach Orchard",
    "lng": -90.66079677782729,
    "lat": 36.28125766636978
  },
  {
    "name": "Peachland",
    "lng": -80.26596457858336,
    "lat": 34.99312189025462
  },
  {
    "name": "Peachtree City",
    "lng": -84.57109218827034,
    "lat": 33.39422925466059
  },
  {
    "name": "Peachtree Corners",
    "lng": -84.2319441146796,
    "lat": 33.9670202550954
  },
  {
    "name": "Peak",
    "lng": -81.32935877736223,
    "lat": 34.23636593962827
  },
  {
    "name": "Pearisburg",
    "lng": -80.72761219089683,
    "lat": 37.328887669601784
  },
  {
    "name": "Pearl",
    "lng": -90.62372838864626,
    "lat": 39.459737459946815
  },
  {
    "name": "Pearl City",
    "lng": -89.82710315977165,
    "lat": 42.26681784822454
  },
  {
    "name": "Pearl River",
    "lng": -89.76113454917567,
    "lat": 30.36221396860609
  },
  {
    "name": "Pearl River",
    "lng": -89.75062310077946,
    "lat": 30.358170656838794
  },
  {
    "name": "Pearl River",
    "lng": -89.74936316595496,
    "lat": 30.368596356761127
  },
  {
    "name": "Pearl River",
    "lng": -89.73698380404988,
    "lat": 30.386593756381963
  },
  {
    "name": "Pearl River",
    "lng": -89.73042406741884,
    "lat": 30.399920395171126
  },
  {
    "name": "Pearland",
    "lng": -95.41645877242853,
    "lat": 29.51969422679961
  },
  {
    "name": "Pearland",
    "lng": -95.32115288388358,
    "lat": 29.55869774154981
  },
  {
    "name": "Pearsall",
    "lng": -99.09876746693162,
    "lat": 28.888443211981244
  },
  {
    "name": "Pearson",
    "lng": -82.85435157517487,
    "lat": 31.297016824321158
  },
  {
    "name": "Pease",
    "lng": -93.64907593667928,
    "lat": 45.69957404014546
  },
  {
    "name": "Pecan Gap",
    "lng": -95.85187077562364,
    "lat": 33.43819774127439
  },
  {
    "name": "Pecan Hill",
    "lng": -96.7818843714001,
    "lat": 32.490567078162584
  },
  {
    "name": "Pecatonica",
    "lng": -89.35789481170195,
    "lat": 42.30858202439095
  },
  {
    "name": "Peck",
    "lng": -116.42619552217126,
    "lat": 46.47469589779595
  },
  {
    "name": "Peck",
    "lng": -82.81681690597043,
    "lat": 43.25888390223206
  },
  {
    "name": "Pecos",
    "lng": -105.67867780513168,
    "lat": 35.575456507881334
  },
  {
    "name": "Pecos",
    "lng": -103.53248631972447,
    "lat": 31.41252296153149
  },
  {
    "name": "Pecos",
    "lng": -103.50539271828609,
    "lat": 31.40448345012938
  },
  {
    "name": "Pecos",
    "lng": -103.49459711954438,
    "lat": 31.395858423464013
  },
  {
    "name": "Pecos",
    "lng": -103.49072335974009,
    "lat": 31.39737175671196
  },
  {
    "name": "Pecos",
    "lng": -103.47609908169431,
    "lat": 31.405916231670783
  },
  {
    "name": "Peculiar",
    "lng": -94.47482427989551,
    "lat": 38.73127865224269
  },
  {
    "name": "Peculiar",
    "lng": -94.43331986997467,
    "lat": 38.703703800296054
  },
  {
    "name": "Peebles",
    "lng": -83.40914336685121,
    "lat": 38.94628997198195
  },
  {
    "name": "Peekskill",
    "lng": -73.92271107458376,
    "lat": 41.288325667403335
  },
  {
    "name": "Peetz",
    "lng": -103.11489126939527,
    "lat": 40.961997289982186
  },
  {
    "name": "Peetz",
    "lng": -103.10854782521815,
    "lat": 40.96244735693466
  },
  {
    "name": "Peever",
    "lng": -96.9561651099071,
    "lat": 45.542301739287296
  },
  {
    "name": "Pegram",
    "lng": -87.05629624106474,
    "lat": 36.104758184847604
  },
  {
    "name": "Pekin",
    "lng": -98.32783210156504,
    "lat": 47.79192136968176
  },
  {
    "name": "Pekin",
    "lng": -89.62636792856986,
    "lat": 40.56772685697212
  },
  {
    "name": "Pelahatchie",
    "lng": -89.80342746574806,
    "lat": 32.31338418509872
  },
  {
    "name": "Peletier",
    "lng": -77.07414151980895,
    "lat": 34.726018695748735
  },
  {
    "name": "Pelham",
    "lng": -86.81000352490751,
    "lat": 33.340868728335316
  },
  {
    "name": "Pelham",
    "lng": -86.80851468535529,
    "lat": 33.344666841337244
  },
  {
    "name": "Pelham",
    "lng": -86.80851017284225,
    "lat": 33.3424937644542
  },
  {
    "name": "Pelham",
    "lng": -86.79082834774897,
    "lat": 33.29778350171127
  },
  {
    "name": "Pelham",
    "lng": -86.78244612224483,
    "lat": 33.31146466864247
  },
  {
    "name": "Pelham",
    "lng": -86.75631521037646,
    "lat": 33.311711115888855
  },
  {
    "name": "Pelham",
    "lng": -84.15145804875667,
    "lat": 31.12664037204614
  },
  {
    "name": "Pelham",
    "lng": -73.80742252027768,
    "lat": 40.911685112807234
  },
  {
    "name": "Pelham",
    "lng": -72.41641202599507,
    "lat": 42.38081660166088
  },
  {
    "name": "Pelham",
    "lng": -71.32418551935004,
    "lat": 42.733520639830104
  },
  {
    "name": "Pelham Manor",
    "lng": -73.80573504113383,
    "lat": 40.893019317793005
  },
  {
    "name": "Pelican",
    "lng": -136.2193851730243,
    "lat": 57.9580874134938
  },
  {
    "name": "Pelican Bay",
    "lng": -97.51892134584003,
    "lat": 32.92275041864563
  },
  {
    "name": "Pelican Bay",
    "lng": -81.81062639884308,
    "lat": 26.2325642751039
  },
  {
    "name": "Pelican Rapids",
    "lng": -96.08594728744431,
    "lat": 46.57008532371231
  },
  {
    "name": "Pelion",
    "lng": -81.2502239184024,
    "lat": 33.775371445003934
  },
  {
    "name": "Pell City",
    "lng": -86.26754633280648,
    "lat": 33.561069135163144
  },
  {
    "name": "Pell City",
    "lng": -86.24150557387898,
    "lat": 33.60714363335894
  },
  {
    "name": "Pell City",
    "lng": -86.23081722241297,
    "lat": 33.524792935009714
  },
  {
    "name": "Pella",
    "lng": -92.91755971529214,
    "lat": 41.40505777029045
  },
  {
    "name": "Pellston",
    "lng": -84.78300150620451,
    "lat": 45.55169441913146
  },
  {
    "name": "Pelzer",
    "lng": -82.46047872170075,
    "lat": 34.64488892062652
  },
  {
    "name": "Pemberton",
    "lng": -93.78370213728154,
    "lat": 44.00836057471217
  },
  {
    "name": "Pemberville",
    "lng": -83.45838654211607,
    "lat": 41.40977486183538
  },
  {
    "name": "Pembina",
    "lng": -97.24745478842554,
    "lat": 48.96658160399301
  },
  {
    "name": "Pembroke",
    "lng": -87.3571053798873,
    "lat": 36.77459821519763
  },
  {
    "name": "Pembroke",
    "lng": -81.61868175421895,
    "lat": 32.1453602148048
  },
  {
    "name": "Pembroke",
    "lng": -81.6089288360427,
    "lat": 32.16529062408032
  },
  {
    "name": "Pembroke",
    "lng": -80.63649984071584,
    "lat": 37.32186190064702
  },
  {
    "name": "Pembroke",
    "lng": -79.22804815247646,
    "lat": 34.68222469854252
  },
  {
    "name": "Pembroke",
    "lng": -79.20478003828708,
    "lat": 34.66822968382386
  },
  {
    "name": "Pembroke",
    "lng": -79.1971789553701,
    "lat": 34.67948974839328
  },
  {
    "name": "Pembroke",
    "lng": -79.18217999999997,
    "lat": 34.67198899963892
  },
  {
    "name": "Pembroke",
    "lng": -79.16991468695738,
    "lat": 34.663636583319814
  },
  {
    "name": "Pembroke",
    "lng": -71.44976416598651,
    "lat": 43.18007962447461
  },
  {
    "name": "Pembroke",
    "lng": -67.17222688819164,
    "lat": 44.94195873048699
  },
  {
    "name": "Penalosa",
    "lng": -98.31984705101756,
    "lat": 37.71611382803515
  },
  {
    "name": "Pender",
    "lng": -96.71151614663911,
    "lat": 42.110614915595946
  },
  {
    "name": "Pendergrass",
    "lng": -83.68274232234471,
    "lat": 34.164684768561095
  },
  {
    "name": "Pendleton",
    "lng": -91.2321484380253,
    "lat": 38.82627378006533
  },
  {
    "name": "Pendleton",
    "lng": -85.77256159192079,
    "lat": 40.007611011914115
  },
  {
    "name": "Pendleton",
    "lng": -82.78263398647334,
    "lat": 34.65037444659331
  },
  {
    "name": "Penelope",
    "lng": -96.92813463685393,
    "lat": 31.85851540429731
  },
  {
    "name": "Penermon",
    "lng": -89.83008191066463,
    "lat": 36.790958201226665
  },
  {
    "name": "Peninsula",
    "lng": -81.552019681683,
    "lat": 41.2377669695541
  },
  {
    "name": "Penitas",
    "lng": -98.44257312650488,
    "lat": 26.250765461892154
  },
  {
    "name": "Penn Estates",
    "lng": -75.24183603908713,
    "lat": 41.03451534450494
  },
  {
    "name": "Penn Yan",
    "lng": -77.05353192827288,
    "lat": 42.66075892745594
  },
  {
    "name": "Pennington",
    "lng": -88.0493826451079,
    "lat": 32.19934062668101
  },
  {
    "name": "Pennington Gap",
    "lng": -83.0300063714357,
    "lat": 36.75934460051033
  },
  {
    "name": "Pennock",
    "lng": -95.1750346689627,
    "lat": 45.14585541197062
  },
  {
    "name": "Pennsboro",
    "lng": -80.9745029119889,
    "lat": 39.28108818414438
  },
  {
    "name": "Pennsylvania",
    "lng": -77.83977698075833,
    "lat": 40.906907560824244
  },
  {
    "name": "Pennsylvania Game Commission Southeast Regional Headquarters",
    "lng": -75.91110131132758,
    "lat": 40.44081026405681
  },
  {
    "name": "Pennville",
    "lng": -85.1467179158472,
    "lat": 40.49192883276902
  },
  {
    "name": "Pennville",
    "lng": -76.990725354812,
    "lat": 39.788169123414065
  },
  {
    "name": "Penobscot",
    "lng": -68.70831840688277,
    "lat": 44.47487171698826
  },
  {
    "name": "Pensacola",
    "lng": -95.12919285899424,
    "lat": 36.4555739797412
  },
  {
    "name": "Pensacola",
    "lng": -87.2228042051446,
    "lat": 30.50124284328163
  },
  {
    "name": "Pensacola",
    "lng": -87.20200305641605,
    "lat": 30.45119545743349
  },
  {
    "name": "Pensacola",
    "lng": -87.19565279254495,
    "lat": 30.410607116717177
  },
  {
    "name": "Pentwater",
    "lng": -86.43044001670228,
    "lat": 43.77989966024065
  },
  {
    "name": "Peoria",
    "lng": -112.30017624508827,
    "lat": 33.78603455973733
  },
  {
    "name": "Peoria",
    "lng": -94.66980753974427,
    "lat": 36.91524189085898
  },
  {
    "name": "Peoria",
    "lng": -89.64917260733387,
    "lat": 40.81576432323693
  },
  {
    "name": "Peoria",
    "lng": -89.61507419851911,
    "lat": 40.75119231177501
  },
  {
    "name": "Peoria Heights",
    "lng": -89.55738274883609,
    "lat": 40.762769068968254
  },
  {
    "name": "Peosta",
    "lng": -90.84430573402066,
    "lat": 42.44771180875814
  },
  {
    "name": "Peotone",
    "lng": -87.7966938565519,
    "lat": 41.33081225451514
  },
  {
    "name": "Pepin",
    "lng": -92.14836337937932,
    "lat": 44.44238082514676
  },
  {
    "name": "Pepper Pike",
    "lng": -81.46176441169101,
    "lat": 41.48004622448723
  },
  {
    "name": "Pepperell",
    "lng": -71.60418671738387,
    "lat": 42.67122459211355
  },
  {
    "name": "Pequot Lakes",
    "lng": -94.29834801565868,
    "lat": 46.58752539368305
  },
  {
    "name": "Peralta",
    "lng": -106.6864279587311,
    "lat": 34.82856771666199
  },
  {
    "name": "Percy",
    "lng": -89.61863093240368,
    "lat": 38.0153644990169
  },
  {
    "name": "Perham",
    "lng": -95.60533741698278,
    "lat": 46.61081065551322
  },
  {
    "name": "Perham",
    "lng": -95.58145607487887,
    "lat": 46.58224254943728
  },
  {
    "name": "Perham",
    "lng": -95.5756070499069,
    "lat": 46.613060093498554
  },
  {
    "name": "Perham",
    "lng": -95.57560074990694,
    "lat": 46.61437209349478
  },
  {
    "name": "Perham",
    "lng": -95.5743437096035,
    "lat": 46.59894555459533
  },
  {
    "name": "Perham",
    "lng": -68.2391138184398,
    "lat": 46.88166943474884
  },
  {
    "name": "Perkasie",
    "lng": -75.29205402613051,
    "lat": 40.37201409179528
  },
  {
    "name": "Perkins",
    "lng": -97.0311659733539,
    "lat": 35.97759968848794
  },
  {
    "name": "Perla",
    "lng": -92.77649929813921,
    "lat": 34.36477227394965
  },
  {
    "name": "Perla",
    "lng": -92.77645683665051,
    "lat": 34.36476058423983
  },
  {
    "name": "Perla",
    "lng": -92.7589368264007,
    "lat": 34.36284052771364
  },
  {
    "name": "Perley",
    "lng": -96.80149105640758,
    "lat": 47.17609762222806
  },
  {
    "name": "Pernitas Point",
    "lng": -97.90382911126017,
    "lat": 28.05980740060181
  },
  {
    "name": "Perrinton",
    "lng": -84.67917266859841,
    "lat": 43.181589397317865
  },
  {
    "name": "Perris",
    "lng": -117.22326178364148,
    "lat": 33.789825572162606
  },
  {
    "name": "Perry",
    "lng": -112.04004216300852,
    "lat": 41.46467509953857
  },
  {
    "name": "Perry",
    "lng": -97.34419685678432,
    "lat": 36.2463152453841
  },
  {
    "name": "Perry",
    "lng": -97.3032944258983,
    "lat": 36.28921722410301
  },
  {
    "name": "Perry",
    "lng": -97.27602036054125,
    "lat": 36.267696819747
  },
  {
    "name": "Perry",
    "lng": -95.3868289000716,
    "lat": 39.07307071719114
  },
  {
    "name": "Perry",
    "lng": -94.09380448495726,
    "lat": 41.83871872170844
  },
  {
    "name": "Perry",
    "lng": -92.79436088594593,
    "lat": 35.04556829120072
  },
  {
    "name": "Perry",
    "lng": -91.6664553099888,
    "lat": 39.43002801394446
  },
  {
    "name": "Perry",
    "lng": -90.74702652152617,
    "lat": 39.78227581646404
  },
  {
    "name": "Perry",
    "lng": -84.23122108033962,
    "lat": 42.82020132596415
  },
  {
    "name": "Perry",
    "lng": -83.72845220900813,
    "lat": 32.47151579338134
  },
  {
    "name": "Perry",
    "lng": -83.5822507956212,
    "lat": 30.10901853649172
  },
  {
    "name": "Perry",
    "lng": -81.30857051602513,
    "lat": 33.62758033439949
  },
  {
    "name": "Perry",
    "lng": -81.1435584528222,
    "lat": 41.76382579337681
  },
  {
    "name": "Perry",
    "lng": -78.00640930360561,
    "lat": 42.71738490454996
  },
  {
    "name": "Perry",
    "lng": -67.09842666797864,
    "lat": 44.9822793437664
  },
  {
    "name": "Perrysburg",
    "lng": -83.6414283682976,
    "lat": 41.5382229722874
  },
  {
    "name": "Perrysburg",
    "lng": -79.0008335018511,
    "lat": 42.45893451976174
  },
  {
    "name": "Perrysville",
    "lng": -87.43601095349254,
    "lat": 40.05322996686678
  },
  {
    "name": "Perrysville",
    "lng": -82.31445311145775,
    "lat": 40.65841307453445
  },
  {
    "name": "Perryton",
    "lng": -100.79747058079194,
    "lat": 36.393894653486875
  },
  {
    "name": "Perryton",
    "lng": -100.77709234927363,
    "lat": 36.368981510142895
  },
  {
    "name": "Perryton",
    "lng": -100.77566343996094,
    "lat": 36.37621907094919
  },
  {
    "name": "Perrytown",
    "lng": -93.53392866816847,
    "lat": 33.69731217953942
  },
  {
    "name": "Perryville",
    "lng": -92.80350940694383,
    "lat": 35.01193462653302
  },
  {
    "name": "Perryville",
    "lng": -89.87604203030978,
    "lat": 37.72531167818111
  },
  {
    "name": "Perryville",
    "lng": -84.95103399736901,
    "lat": 37.6496131690817
  },
  {
    "name": "Perryville",
    "lng": -76.06976226152706,
    "lat": 39.573291323880404
  },
  {
    "name": "Perryville",
    "lng": -76.04924591815191,
    "lat": 39.57529808462539
  },
  {
    "name": "Persia",
    "lng": -95.56993938578339,
    "lat": 41.57912314302779
  },
  {
    "name": "Perth",
    "lng": -99.45802467941694,
    "lat": 48.71487910358726
  },
  {
    "name": "Peru",
    "lng": -96.09567410871071,
    "lat": 37.08150610465314
  },
  {
    "name": "Peru",
    "lng": -95.7315890328444,
    "lat": 40.47798850319187
  },
  {
    "name": "Peru",
    "lng": -89.13677358115982,
    "lat": 41.3491002578552
  },
  {
    "name": "Peru",
    "lng": -86.07144992777323,
    "lat": 40.75831285039029
  },
  {
    "name": "Peru",
    "lng": -73.04000686699791,
    "lat": 42.42932045227426
  },
  {
    "name": "Peru",
    "lng": -70.4506493637525,
    "lat": 44.47357460906995
  },
  {
    "name": "Peshtigo",
    "lng": -87.7476532496636,
    "lat": 45.055540485773626
  },
  {
    "name": "Pesotum",
    "lng": -88.2744409315254,
    "lat": 39.91297875523952
  },
  {
    "name": "Petal",
    "lng": -89.23587147499119,
    "lat": 31.347963269304252
  },
  {
    "name": "Petaluma",
    "lng": -122.6265419883126,
    "lat": 38.24186151744219
  },
  {
    "name": "Petaluma",
    "lng": -122.6137439008311,
    "lat": 38.295319554767914
  },
  {
    "name": "Petaluma",
    "lng": -122.60616769160657,
    "lat": 38.269984077355474
  },
  {
    "name": "Petaluma",
    "lng": -122.57716720208573,
    "lat": 38.297690316719645
  },
  {
    "name": "Petersburg",
    "lng": -101.59729089774562,
    "lat": 33.87105541713037
  },
  {
    "name": "Petersburg",
    "lng": -98.081063213029,
    "lat": 41.85357124033757
  },
  {
    "name": "Petersburg",
    "lng": -98.00039173516203,
    "lat": 48.013187643278094
  },
  {
    "name": "Petersburg",
    "lng": -89.84533037371814,
    "lat": 40.01428872758533
  },
  {
    "name": "Petersburg",
    "lng": -87.2925698492482,
    "lat": 38.4934308001984
  },
  {
    "name": "Petersburg",
    "lng": -87.28116472661321,
    "lat": 38.49174097399609
  },
  {
    "name": "Petersburg",
    "lng": -86.64088043653427,
    "lat": 35.31745028430807
  },
  {
    "name": "Petersburg",
    "lng": -83.71355645745065,
    "lat": 41.89921650608416
  },
  {
    "name": "Petersburg",
    "lng": -79.12768957996715,
    "lat": 38.995699828966934
  },
  {
    "name": "Petersburg",
    "lng": -77.39132686499872,
    "lat": 37.20430891929878
  },
  {
    "name": "Petersham",
    "lng": -72.22104977339075,
    "lat": 42.45973617643303
  },
  {
    "name": "Peterson",
    "lng": -95.3413522197976,
    "lat": 42.91873262969885
  },
  {
    "name": "Peterson",
    "lng": -91.83327666346366,
    "lat": 43.786844389568884
  },
  {
    "name": "Peterstown",
    "lng": -80.79501480890784,
    "lat": 37.39897958850578
  },
  {
    "name": "Petoskey",
    "lng": -85.04935201603897,
    "lat": 45.36295689709227
  },
  {
    "name": "Petoskey",
    "lng": -84.95566334280352,
    "lat": 45.36610351804961
  },
  {
    "name": "Petrey",
    "lng": -86.20568192315719,
    "lat": 31.84947193314194
  },
  {
    "name": "Petrolia",
    "lng": -98.23132549541748,
    "lat": 34.01321799153828
  },
  {
    "name": "Petronila",
    "lng": -97.63619818876381,
    "lat": 27.674080167351164
  },
  {
    "name": "Pettibone",
    "lng": -99.52126114517108,
    "lat": 47.11370856997687
  },
  {
    "name": "Pettibone",
    "lng": -99.5204686039833,
    "lat": 47.1192324370008
  },
  {
    "name": "Pevely",
    "lng": -90.39945062206615,
    "lat": 38.28714476715496
  },
  {
    "name": "Pewamo",
    "lng": -84.8472897473107,
    "lat": 43.00194584323157
  },
  {
    "name": "Pewee Valley",
    "lng": -85.48957469991576,
    "lat": 38.31048967436321
  },
  {
    "name": "Pflugerville",
    "lng": -97.60469058514481,
    "lat": 30.453455229506517
  },
  {
    "name": "Pflugerville",
    "lng": -97.54903557163748,
    "lat": 30.458467591681618
  },
  {
    "name": "Pflugerville",
    "lng": -97.54722131376795,
    "lat": 30.46379130990198
  },
  {
    "name": "Pflugerville",
    "lng": -97.54336548284022,
    "lat": 30.386339399300034
  },
  {
    "name": "Pflugerville",
    "lng": -97.53193866363037,
    "lat": 30.43315864797458
  },
  {
    "name": "Pflugerville",
    "lng": -97.50834852974096,
    "lat": 30.46794106764757
  },
  {
    "name": "Pflugerville",
    "lng": -97.50518291660356,
    "lat": 30.40851175477927
  },
  {
    "name": "Phelps",
    "lng": -77.06210859555765,
    "lat": 42.95813006769831
  },
  {
    "name": "Phenix",
    "lng": -78.74849951319166,
    "lat": 37.08105950758435
  },
  {
    "name": "Phenix City",
    "lng": -85.02315812084393,
    "lat": 32.46264000029661
  },
  {
    "name": "Phil Campbell",
    "lng": -87.7063882127739,
    "lat": 34.35194004843093
  },
  {
    "name": "Philadelphia",
    "lng": -84.39977665924154,
    "lat": 35.67891539302751
  },
  {
    "name": "Philadelphia",
    "lng": -75.70953474218597,
    "lat": 44.154205458408846
  },
  {
    "name": "Philadelphia",
    "lng": -75.13402911764156,
    "lat": 40.00770581350038
  },
  {
    "name": "Philip",
    "lng": -101.6645594874097,
    "lat": 44.040831177041795
  },
  {
    "name": "Philippi",
    "lng": -80.04244366501855,
    "lat": 39.149291040091526
  },
  {
    "name": "Philipsburg",
    "lng": -113.30947515571239,
    "lat": 46.33589098970532
  },
  {
    "name": "Philipsburg",
    "lng": -113.3076688708782,
    "lat": 46.334564674156525
  },
  {
    "name": "Philipsburg",
    "lng": -113.29535639531815,
    "lat": 46.33241873963482
  },
  {
    "name": "Phillips",
    "lng": -98.21424754170619,
    "lat": 40.8982539735874
  },
  {
    "name": "Phillips",
    "lng": -96.22313137809076,
    "lat": 34.50417391097355
  },
  {
    "name": "Phillips",
    "lng": -90.40179878143422,
    "lat": 45.69581752680855
  },
  {
    "name": "Phillips",
    "lng": -70.382534795411,
    "lat": 44.84021099079542
  },
  {
    "name": "Phillipsburg",
    "lng": -99.32066311024566,
    "lat": 39.754591824838194
  },
  {
    "name": "Phillipsburg",
    "lng": -99.31852296327457,
    "lat": 39.73742710260499
  },
  {
    "name": "Phillipsburg",
    "lng": -92.78711311541468,
    "lat": 37.55250099455947
  },
  {
    "name": "Phillipsburg",
    "lng": -84.40150164812188,
    "lat": 39.90405189461771
  },
  {
    "name": "Phillipsburg",
    "lng": -75.18216706365337,
    "lat": 40.68929841324034
  },
  {
    "name": "Phillipston",
    "lng": -72.13546776078735,
    "lat": 42.55279225826611
  },
  {
    "name": "Phillipstown",
    "lng": -88.01811811144312,
    "lat": 38.14285153747806
  },
  {
    "name": "Philmont",
    "lng": -73.64632086623463,
    "lat": 42.24767975273203
  },
  {
    "name": "Philo",
    "lng": -123.44145894974667,
    "lat": 39.06529595171689
  },
  {
    "name": "Philo",
    "lng": -88.15638789025402,
    "lat": 40.003333157864525
  },
  {
    "name": "Philo",
    "lng": -81.90990736486813,
    "lat": 39.860925717551545
  },
  {
    "name": "Phippsburg",
    "lng": -69.83155952190828,
    "lat": 43.74483623278672
  },
  {
    "name": "Phoenix",
    "lng": -87.6308241254955,
    "lat": 41.61161444650561
  },
  {
    "name": "Phoenix",
    "lng": -76.29597824150353,
    "lat": 43.231464333550576
  },
  {
    "name": "Phoenixville",
    "lng": -75.52006323591985,
    "lat": 40.135836444931634
  },
  {
    "name": "Picayune",
    "lng": -89.67763685247586,
    "lat": 30.528062183660683
  },
  {
    "name": "Pickens",
    "lng": -89.96976661172383,
    "lat": 32.891325445419554
  },
  {
    "name": "Pickens",
    "lng": -82.70963793082402,
    "lat": 34.883891460677496
  },
  {
    "name": "Pickensville",
    "lng": -88.27446329557905,
    "lat": 33.23362133513553
  },
  {
    "name": "Pickering",
    "lng": -94.84152700577226,
    "lat": 40.450262493437315
  },
  {
    "name": "Pickerington",
    "lng": -82.7676824602904,
    "lat": 39.88951158891602
  },
  {
    "name": "Pickrell",
    "lng": -96.72911529803537,
    "lat": 40.378323943164524
  },
  {
    "name": "Pickstown",
    "lng": -98.53007167440444,
    "lat": 43.067800780252504
  },
  {
    "name": "Piedmont",
    "lng": -97.8302659638185,
    "lat": 35.67051834292006
  },
  {
    "name": "Piedmont",
    "lng": -97.8230447222639,
    "lat": 35.73690096633753
  },
  {
    "name": "Piedmont",
    "lng": -97.75205847006073,
    "lat": 35.67808672252247
  },
  {
    "name": "Piedmont",
    "lng": -90.69694531639855,
    "lat": 37.149009342750794
  },
  {
    "name": "Piedmont",
    "lng": -85.62862559742881,
    "lat": 33.90589668562598
  },
  {
    "name": "Piedmont",
    "lng": -85.6157112258863,
    "lat": 33.92671046374463
  },
  {
    "name": "Piedmont",
    "lng": -79.04631066536027,
    "lat": 39.47784307631034
  },
  {
    "name": "Pierce",
    "lng": -115.79949560710638,
    "lat": 46.49310043308468
  },
  {
    "name": "Pierce",
    "lng": -104.75509206262946,
    "lat": 40.634102205884346
  },
  {
    "name": "Pierce",
    "lng": -97.52782838636193,
    "lat": 42.19899122225031
  },
  {
    "name": "Pierce City",
    "lng": -94.00338094476595,
    "lat": 36.94670645175685
  },
  {
    "name": "Pierceton",
    "lng": -85.70301386145,
    "lat": 41.19904773621005
  },
  {
    "name": "Piermont",
    "lng": -73.9148083195178,
    "lat": 41.042376144286884
  },
  {
    "name": "Pierpont",
    "lng": -97.83208441773158,
    "lat": 45.495331877844656
  },
  {
    "name": "Pierpont",
    "lng": -92.31316038542097,
    "lat": 38.86336303335454
  },
  {
    "name": "Pierre",
    "lng": -100.32042390904873,
    "lat": 44.37479174920072
  },
  {
    "name": "Pierron",
    "lng": -89.56449179487194,
    "lat": 38.77723090594161
  },
  {
    "name": "Pierson",
    "lng": -95.86662936641464,
    "lat": 42.543497406227495
  },
  {
    "name": "Pierson",
    "lng": -85.49746117076667,
    "lat": 43.31962276251935
  },
  {
    "name": "Pierson",
    "lng": -81.47084092176668,
    "lat": 29.262006418508918
  },
  {
    "name": "Pierson",
    "lng": -81.45692970087697,
    "lat": 29.23979685569941
  },
  {
    "name": "Pierson",
    "lng": -81.4564370738805,
    "lat": 29.21483361256415
  },
  {
    "name": "Pierson",
    "lng": -81.45485038917197,
    "lat": 29.211571314481308
  },
  {
    "name": "Pierson",
    "lng": -81.40974109574022,
    "lat": 29.257304119456304
  },
  {
    "name": "Pierz",
    "lng": -94.10162889871798,
    "lat": 45.97792130069678
  },
  {
    "name": "Pigeon",
    "lng": -83.26983471496246,
    "lat": 43.82983288560126
  },
  {
    "name": "Pigeon Falls",
    "lng": -91.20877672331672,
    "lat": 44.42490475554151
  },
  {
    "name": "Pigeon Forge",
    "lng": -83.56211623725903,
    "lat": 35.797580707417836
  },
  {
    "name": "Pigeon Forge",
    "lng": -83.53011795863257,
    "lat": 35.78305907314737
  },
  {
    "name": "Piggott",
    "lng": -90.20042499321703,
    "lat": 36.385807159053755
  },
  {
    "name": "Pike",
    "lng": -78.15422159064457,
    "lat": 42.555364336092396
  },
  {
    "name": "Pike Road",
    "lng": -86.14411924468946,
    "lat": 32.27227561531664
  },
  {
    "name": "Pike Road",
    "lng": -86.12927748515489,
    "lat": 32.27937048999361
  },
  {
    "name": "Pike Road",
    "lng": -86.11854884521418,
    "lat": 32.2533045054097
  },
  {
    "name": "Piketon",
    "lng": -83.00038802664237,
    "lat": 39.0657437769088
  },
  {
    "name": "Pikeville",
    "lng": -85.19815846726952,
    "lat": 35.60275544902864
  },
  {
    "name": "Pikeville",
    "lng": -82.5275609984113,
    "lat": 37.479952759754646
  },
  {
    "name": "Pikeville",
    "lng": -77.98539412901182,
    "lat": 35.49815347074365
  },
  {
    "name": "Pikeville",
    "lng": -77.98256548923598,
    "lat": 35.50619014138605
  },
  {
    "name": "Pilger",
    "lng": -97.05487566982625,
    "lat": 42.00749511809716
  },
  {
    "name": "Pillager",
    "lng": -94.47963775526476,
    "lat": 46.32964069297697
  },
  {
    "name": "Pillsbury",
    "lng": -97.79574129938372,
    "lat": 47.207093218421925
  },
  {
    "name": "Pilot Grove",
    "lng": -92.91260982802186,
    "lat": 38.873624403947375
  },
  {
    "name": "Pilot Knob",
    "lng": -90.64537114276497,
    "lat": 37.62373835754254
  },
  {
    "name": "Pilot Mound",
    "lng": -94.01830329988911,
    "lat": 42.15922983801975
  },
  {
    "name": "Pilot Mountain",
    "lng": -80.47255640185945,
    "lat": 36.385992662548624
  },
  {
    "name": "Pilot Point",
    "lng": -157.74598137102225,
    "lat": 57.58002302635666
  },
  {
    "name": "Pilot Point",
    "lng": -96.95229036160588,
    "lat": 33.39637993103258
  },
  {
    "name": "Pilot Station",
    "lng": -162.8829582406846,
    "lat": 61.94081753043934
  },
  {
    "name": "Pima",
    "lng": -109.8429951218534,
    "lat": 32.89057580790984
  },
  {
    "name": "Pinckney",
    "lng": -83.94536023830894,
    "lat": 42.454285847257694
  },
  {
    "name": "Pinckneyville",
    "lng": -89.37417797110764,
    "lat": 38.08426780126389
  },
  {
    "name": "Pinckneyville",
    "lng": -89.37217336388035,
    "lat": 38.085072852058204
  },
  {
    "name": "Pindall",
    "lng": -92.8711568022201,
    "lat": 36.0635924543017
  },
  {
    "name": "Pine Apple",
    "lng": -86.98905936228265,
    "lat": 31.872156820651004
  },
  {
    "name": "Pine Bluff",
    "lng": -92.01778701856522,
    "lat": 34.21156861341104
  },
  {
    "name": "Pine Bluffs",
    "lng": -104.0684461885832,
    "lat": 41.18391583629651
  },
  {
    "name": "Pine Forest",
    "lng": -94.03671121770344,
    "lat": 30.175645833251366
  },
  {
    "name": "Pine Grove",
    "lng": -80.6824616367362,
    "lat": 39.564222144338004
  },
  {
    "name": "Pine Grove",
    "lng": -76.38608961808212,
    "lat": 40.55350038844081
  },
  {
    "name": "Pine Haven",
    "lng": -104.81294975298964,
    "lat": 44.35458236489483
  },
  {
    "name": "Pine Hill",
    "lng": -87.58747968879544,
    "lat": 31.985956182445346
  },
  {
    "name": "Pine Island",
    "lng": -96.02490070576845,
    "lat": 30.05568930728881
  },
  {
    "name": "Pine Island",
    "lng": -92.64359686266343,
    "lat": 44.20304463098176
  },
  {
    "name": "Pine Island",
    "lng": -92.60562120462565,
    "lat": 44.18451350506648
  },
  {
    "name": "Pine Island",
    "lng": -92.56677040408736,
    "lat": 44.18686684793318
  },
  {
    "name": "Pine Knoll Shores",
    "lng": -76.82024482520028,
    "lat": 34.69736179793887
  },
  {
    "name": "Pine Lake",
    "lng": -84.20607994367279,
    "lat": 33.79047552225058
  },
  {
    "name": "Pine Lawn",
    "lng": -90.27557287441567,
    "lat": 38.6952904303666
  },
  {
    "name": "Pine Level",
    "lng": -78.27324538290583,
    "lat": 35.504735465986705
  },
  {
    "name": "Pine Level",
    "lng": -78.2601577440281,
    "lat": 35.4863858032866
  },
  {
    "name": "Pine Level",
    "lng": -78.25544598259509,
    "lat": 35.5031605905657
  },
  {
    "name": "Pine Level",
    "lng": -78.24502968538972,
    "lat": 35.509028713741394
  },
  {
    "name": "Pine Level",
    "lng": -78.23677867392567,
    "lat": 35.4917770072207
  },
  {
    "name": "Pine Manor",
    "lng": -81.87739567402201,
    "lat": 26.572669790245715
  },
  {
    "name": "Pine Mountain",
    "lng": -84.85306534301364,
    "lat": 32.85238131744249
  },
  {
    "name": "Pine Mountain Club",
    "lng": -119.15613724662418,
    "lat": 34.84683043577435
  },
  {
    "name": "Pine Prairie",
    "lng": -92.42235736003187,
    "lat": 30.781790881727474
  },
  {
    "name": "Pine Ridge",
    "lng": -85.7805662509661,
    "lat": 34.44882925526359
  },
  {
    "name": "Pine Ridge",
    "lng": -81.09744949457514,
    "lat": 33.908323012550206
  },
  {
    "name": "Pine River",
    "lng": -94.41028137483535,
    "lat": 46.71694168653962
  },
  {
    "name": "Pine River",
    "lng": -94.39734140378424,
    "lat": 46.72253913726334
  },
  {
    "name": "Pine River",
    "lng": -94.38618419664864,
    "lat": 46.71887593267452
  },
  {
    "name": "Pine Springs",
    "lng": -92.95433562150112,
    "lat": 45.03679345410431
  },
  {
    "name": "Pine Village",
    "lng": -87.25343650861387,
    "lat": 40.44953868423546
  },
  {
    "name": "Pinebluff",
    "lng": -79.47138594504885,
    "lat": 35.108766622861864
  },
  {
    "name": "Pinebluff",
    "lng": -79.46022728900876,
    "lat": 35.09557171390446
  },
  {
    "name": "Pinedale",
    "lng": -109.87075977570525,
    "lat": 42.8678965422461
  },
  {
    "name": "Pinedale",
    "lng": -109.85898106888601,
    "lat": 42.85160862023095
  },
  {
    "name": "Pinehurst",
    "lng": -116.23289206188554,
    "lat": 47.53635306223988
  },
  {
    "name": "Pinehurst",
    "lng": -93.77037660045823,
    "lat": 30.10868045948643
  },
  {
    "name": "Pinehurst",
    "lng": -83.75963105340777,
    "lat": 32.195562958802995
  },
  {
    "name": "Pinehurst",
    "lng": -79.51189591015354,
    "lat": 35.18242873731969
  },
  {
    "name": "Pinehurst",
    "lng": -79.50806408919286,
    "lat": 35.18076766976147
  },
  {
    "name": "Pinehurst",
    "lng": -79.50399154883222,
    "lat": 35.18730331642048
  },
  {
    "name": "Pinehurst",
    "lng": -79.47471059961882,
    "lat": 35.22912191858886
  },
  {
    "name": "Pinehurst",
    "lng": -79.46832229855225,
    "lat": 35.19168789992676
  },
  {
    "name": "Pinehurst",
    "lng": -79.44174906341522,
    "lat": 35.242152814230465
  },
  {
    "name": "Pineland",
    "lng": -93.97447180595512,
    "lat": 31.24665031124966
  },
  {
    "name": "Pinesdale",
    "lng": -114.2228938407105,
    "lat": 46.33396532963264
  },
  {
    "name": "Pinetops",
    "lng": -77.63781448970322,
    "lat": 35.79125593566856
  },
  {
    "name": "Pineview",
    "lng": -83.5030003966506,
    "lat": 32.108481576032204
  },
  {
    "name": "Pineville",
    "lng": -94.39315310095303,
    "lat": 36.57247497833214
  },
  {
    "name": "Pineville",
    "lng": -94.37311671910338,
    "lat": 36.58124154370637
  },
  {
    "name": "Pineville",
    "lng": -92.42020180249365,
    "lat": 31.31234226061265
  },
  {
    "name": "Pineville",
    "lng": -92.42002677580695,
    "lat": 31.312030533110427
  },
  {
    "name": "Pineville",
    "lng": -92.4195155634872,
    "lat": 31.313237142649555
  },
  {
    "name": "Pineville",
    "lng": -92.41108620006719,
    "lat": 31.374425317354714
  },
  {
    "name": "Pineville",
    "lng": -92.40930203315797,
    "lat": 31.34130199695574
  },
  {
    "name": "Pineville",
    "lng": -92.37839736626142,
    "lat": 31.345878976656834
  },
  {
    "name": "Pineville",
    "lng": -92.37564233019532,
    "lat": 31.352295900473692
  },
  {
    "name": "Pineville",
    "lng": -92.3755557806574,
    "lat": 31.34611750286848
  },
  {
    "name": "Pineville",
    "lng": -92.10644474658953,
    "lat": 36.161200494660356
  },
  {
    "name": "Pineville",
    "lng": -83.69955850102842,
    "lat": 36.75994985118588
  },
  {
    "name": "Pineville",
    "lng": -81.53489180697684,
    "lat": 37.584117248462135
  },
  {
    "name": "Pineville",
    "lng": -80.88854517331625,
    "lat": 35.0801793526002
  },
  {
    "name": "Pinewood",
    "lng": -80.46200319179134,
    "lat": 33.739611209028666
  },
  {
    "name": "Piney Point Village",
    "lng": -95.51551976805746,
    "lat": 29.758640472558525
  },
  {
    "name": "Pingree",
    "lng": -98.90894999565172,
    "lat": 47.16380215902567
  },
  {
    "name": "Pingree Grove",
    "lng": -88.43626593925447,
    "lat": 42.08578512969154
  },
  {
    "name": "Pinhook",
    "lng": -89.26948014503995,
    "lat": 36.73803948804955
  },
  {
    "name": "Pink",
    "lng": -97.10026253169443,
    "lat": 35.22793341531739
  },
  {
    "name": "Pink",
    "lng": -97.05874634130734,
    "lat": 35.27058033142487
  },
  {
    "name": "Pink Hill",
    "lng": -77.743427219331,
    "lat": 35.05620978463944
  },
  {
    "name": "Pinson",
    "lng": -86.69082820678148,
    "lat": 33.66930261921004
  },
  {
    "name": "Pinson",
    "lng": -86.66447705861684,
    "lat": 33.70322423850769
  },
  {
    "name": "Pioneer",
    "lng": -94.39093491740032,
    "lat": 42.653492578879444
  },
  {
    "name": "Pioneer",
    "lng": -91.43812636430346,
    "lat": 32.73810092585054
  },
  {
    "name": "Pioneer",
    "lng": -84.55343747097245,
    "lat": 41.67752665244179
  },
  {
    "name": "Pioneer Village",
    "lng": -85.67981190719931,
    "lat": 38.0598726444863
  },
  {
    "name": "Piper City",
    "lng": -88.18893370899669,
    "lat": 40.75613973540901
  },
  {
    "name": "Piperton",
    "lng": -89.61379724718361,
    "lat": 35.056556302853416
  },
  {
    "name": "Pipestone",
    "lng": -96.33388690005198,
    "lat": 44.009502299081625
  },
  {
    "name": "Pipestone",
    "lng": -96.31179001300622,
    "lat": 43.995843666737855
  },
  {
    "name": "Pipestone",
    "lng": -96.3044810385822,
    "lat": 44.00304712193569
  },
  {
    "name": "Pippa Passes",
    "lng": -82.87257763356638,
    "lat": 37.33457419948895
  },
  {
    "name": "Piqua",
    "lng": -84.24369449157913,
    "lat": 40.150237886948624
  },
  {
    "name": "Pisek",
    "lng": -97.71091847637466,
    "lat": 48.311001618604735
  },
  {
    "name": "Pisgah",
    "lng": -95.9261564280073,
    "lat": 41.82983350174262
  },
  {
    "name": "Pisgah",
    "lng": -85.84541689130212,
    "lat": 34.684482666641166
  },
  {
    "name": "Pismo Beach",
    "lng": -120.68304467140797,
    "lat": 35.13834629837421
  },
  {
    "name": "Pitkin",
    "lng": -106.51646579667636,
    "lat": 38.60861193502737
  },
  {
    "name": "Pitsburg",
    "lng": -84.48788329102527,
    "lat": 39.98705241871636
  },
  {
    "name": "Pittman Center",
    "lng": -83.38845988415567,
    "lat": 35.75946179174987
  },
  {
    "name": "Pitts",
    "lng": -83.54180873082674,
    "lat": 31.946155547733063
  },
  {
    "name": "Pittsboro",
    "lng": -89.33729070034758,
    "lat": 33.94000286011702
  },
  {
    "name": "Pittsboro",
    "lng": -86.46685581528617,
    "lat": 39.86843629305491
  },
  {
    "name": "Pittsboro",
    "lng": -79.1727488150694,
    "lat": 35.756513253103435
  },
  {
    "name": "Pittsboro",
    "lng": -79.17228648599821,
    "lat": 35.72493837115372
  },
  {
    "name": "Pittsboro",
    "lng": -79.15529954567484,
    "lat": 35.766313053675745
  },
  {
    "name": "Pittsboro",
    "lng": -79.14579301612082,
    "lat": 35.722399097973046
  },
  {
    "name": "Pittsboro",
    "lng": -79.13850985114918,
    "lat": 35.71990042407735
  },
  {
    "name": "Pittsboro",
    "lng": -79.13791736447098,
    "lat": 35.680841503256275
  },
  {
    "name": "Pittsburg",
    "lng": -95.85017681602324,
    "lat": 34.71159518521629
  },
  {
    "name": "Pittsburg",
    "lng": -94.96676214345256,
    "lat": 32.999631539109934
  },
  {
    "name": "Pittsburg",
    "lng": -94.7125300048912,
    "lat": 37.38640556744372
  },
  {
    "name": "Pittsburg",
    "lng": -94.71163749324506,
    "lat": 37.37168672387021
  },
  {
    "name": "Pittsburg",
    "lng": -94.69821505745364,
    "lat": 37.41458995341557
  },
  {
    "name": "Pittsburg",
    "lng": -88.84994359568627,
    "lat": 37.777079653675756
  },
  {
    "name": "Pittsburgh",
    "lng": -79.97645643360721,
    "lat": 40.43962959324768
  },
  {
    "name": "Pittsfield",
    "lng": -90.80448865074248,
    "lat": 39.61494159421557
  },
  {
    "name": "Pittsfield",
    "lng": -90.75352640574955,
    "lat": 39.63844461174507
  },
  {
    "name": "Pittsfield",
    "lng": -73.26052026774504,
    "lat": 42.45172047400956
  },
  {
    "name": "Pittsfield",
    "lng": -71.31018545635398,
    "lat": 43.29383670221905
  },
  {
    "name": "Pittsfield",
    "lng": -69.43865843722924,
    "lat": 44.771432962777936
  },
  {
    "name": "Pittsford",
    "lng": -77.51657916385133,
    "lat": 43.09003622567331
  },
  {
    "name": "Pittston",
    "lng": -75.78852437016364,
    "lat": 41.32734630304829
  },
  {
    "name": "Pittston",
    "lng": -69.70841671066574,
    "lat": 44.18022356508147
  },
  {
    "name": "Pittsville",
    "lng": -90.13006227385131,
    "lat": 44.43908516571979
  },
  {
    "name": "Pittsville",
    "lng": -75.40882280652364,
    "lat": 38.39472528521499
  },
  {
    "name": "Pixley",
    "lng": -119.28893909458564,
    "lat": 35.976896596948976
  },
  {
    "name": "Placentia",
    "lng": -117.85536706635385,
    "lat": 33.880653912755676
  },
  {
    "name": "Placerville",
    "lng": -120.79786111373221,
    "lat": 38.73082896923107
  },
  {
    "name": "Placerville",
    "lng": -115.94560429219403,
    "lat": 43.94298289448325
  },
  {
    "name": "Plain",
    "lng": -90.04176022400624,
    "lat": 43.277749759859645
  },
  {
    "name": "Plain City",
    "lng": -112.08766015005396,
    "lat": 41.30699753711176
  },
  {
    "name": "Plain City",
    "lng": -83.27081487347509,
    "lat": 40.10772515871124
  },
  {
    "name": "Plain Dealing",
    "lng": -93.69912931024622,
    "lat": 32.90745432912829
  },
  {
    "name": "Plainfield",
    "lng": -92.5356794145349,
    "lat": 42.84477389409674
  },
  {
    "name": "Plainfield",
    "lng": -89.5071418211482,
    "lat": 44.23564176966465
  },
  {
    "name": "Plainfield",
    "lng": -89.49495446828712,
    "lat": 44.214036339079065
  },
  {
    "name": "Plainfield",
    "lng": -88.23743115802138,
    "lat": 41.67009586265304
  },
  {
    "name": "Plainfield",
    "lng": -88.22669483153989,
    "lat": 41.62024570682044
  },
  {
    "name": "Plainfield",
    "lng": -88.19086624156277,
    "lat": 41.58135112520492
  },
  {
    "name": "Plainfield",
    "lng": -88.18073417879164,
    "lat": 41.57594946748896
  },
  {
    "name": "Plainfield",
    "lng": -88.16556175819804,
    "lat": 41.59968058830772
  },
  {
    "name": "Plainfield",
    "lng": -86.37366731821005,
    "lat": 39.7001206153128
  },
  {
    "name": "Plainfield",
    "lng": -81.71821155363439,
    "lat": 40.205139062069776
  },
  {
    "name": "Plainfield",
    "lng": -74.41587747969717,
    "lat": 40.615281350953396
  },
  {
    "name": "Plainfield",
    "lng": -72.92411735770725,
    "lat": 42.51799294591546
  },
  {
    "name": "Plainfield",
    "lng": -71.89870399396612,
    "lat": 41.699287605413616
  },
  {
    "name": "Plainfield Village",
    "lng": -71.92484250808651,
    "lat": 41.676652562525206
  },
  {
    "name": "Plains",
    "lng": -114.88450056865886,
    "lat": 47.46019142097862
  },
  {
    "name": "Plains",
    "lng": -100.58975207318319,
    "lat": 37.264232419368916
  },
  {
    "name": "Plains",
    "lng": -84.39450069401704,
    "lat": 32.03339849965699
  },
  {
    "name": "Plainview",
    "lng": -101.72344240911201,
    "lat": 34.19090761777503
  },
  {
    "name": "Plainview",
    "lng": -97.7871433701105,
    "lat": 42.35334596923622
  },
  {
    "name": "Plainview",
    "lng": -93.2979478236023,
    "lat": 34.989874369346346
  },
  {
    "name": "Plainview",
    "lng": -92.16976035826355,
    "lat": 44.16484988440791
  },
  {
    "name": "Plainview",
    "lng": -83.79330300999688,
    "lat": 36.181037859567944
  },
  {
    "name": "Plainville",
    "lng": -99.30403251792126,
    "lat": 39.19136860822646
  },
  {
    "name": "Plainville",
    "lng": -99.30122813484687,
    "lat": 39.23356489370441
  },
  {
    "name": "Plainville",
    "lng": -91.18164857577949,
    "lat": 39.78476525661373
  },
  {
    "name": "Plainville",
    "lng": -87.15153318533848,
    "lat": 38.804556246566975
  },
  {
    "name": "Plainville",
    "lng": -85.03148883762313,
    "lat": 34.405264417649946
  },
  {
    "name": "Plainville",
    "lng": -72.8574537210179,
    "lat": 41.674192372610115
  },
  {
    "name": "Plainwell",
    "lng": -85.643482410963,
    "lat": 42.44571709027215
  },
  {
    "name": "Planada",
    "lng": -120.32515691173703,
    "lat": 37.28917570015365
  },
  {
    "name": "Plandome",
    "lng": -73.70018634816519,
    "lat": 40.806953857313076
  },
  {
    "name": "Plandome Heights",
    "lng": -73.70552587475966,
    "lat": 40.80213612278096
  },
  {
    "name": "Plandome Manor",
    "lng": -73.70085359819944,
    "lat": 40.81485076105611
  },
  {
    "name": "Plankinton",
    "lng": -98.48367947512882,
    "lat": 43.714795078861606
  },
  {
    "name": "Plano",
    "lng": -93.04690434532827,
    "lat": 40.755472256512014
  },
  {
    "name": "Plano",
    "lng": -88.53337461197555,
    "lat": 41.67567649792207
  },
  {
    "name": "Plano",
    "lng": -88.49954271860821,
    "lat": 41.678055290311846
  },
  {
    "name": "Plano",
    "lng": -88.49123384442925,
    "lat": 41.68815352847567
  },
  {
    "name": "Plantation",
    "lng": -85.59352048336258,
    "lat": 38.28376202854834
  },
  {
    "name": "Plantersville",
    "lng": -88.6654316161884,
    "lat": 34.21026629422629
  },
  {
    "name": "Plaquemine",
    "lng": -91.24299341405602,
    "lat": 30.283374200474846
  },
  {
    "name": "Platinum",
    "lng": -161.72206270806063,
    "lat": 58.977899320991156
  },
  {
    "name": "Plato",
    "lng": -94.03959622673672,
    "lat": 44.772441465875204
  },
  {
    "name": "Plato",
    "lng": -92.22002839226411,
    "lat": 37.50287190926555
  },
  {
    "name": "Platte",
    "lng": -98.8435771290978,
    "lat": 43.38682995359204
  },
  {
    "name": "Platte Center",
    "lng": -97.48847472588969,
    "lat": 41.53742758743102
  },
  {
    "name": "Platte City",
    "lng": -94.76845727417606,
    "lat": 39.358804061372346
  },
  {
    "name": "Platteville",
    "lng": -104.83562472065668,
    "lat": 40.219009345283176
  },
  {
    "name": "Platteville",
    "lng": -90.47084802130276,
    "lat": 42.733274135168166
  },
  {
    "name": "Plattsburg",
    "lng": -94.46158171547093,
    "lat": 39.56399979263339
  },
  {
    "name": "Plattsburgh",
    "lng": -73.45352168548935,
    "lat": 44.6943318279713
  },
  {
    "name": "Plattsmouth",
    "lng": -95.89414619296556,
    "lat": 41.005311697289414
  },
  {
    "name": "Plattville",
    "lng": -88.3836007072943,
    "lat": 41.5342549569269
  },
  {
    "name": "Plaucheville",
    "lng": -91.9834896384772,
    "lat": 30.965850637485097
  },
  {
    "name": "Plaza",
    "lng": -101.95928085136195,
    "lat": 48.0258781838767
  },
  {
    "name": "Pleak",
    "lng": -95.8098098108762,
    "lat": 29.48503025101076
  },
  {
    "name": "Pleasant City",
    "lng": -81.54380764106797,
    "lat": 39.90327422096647
  },
  {
    "name": "Pleasant Dale",
    "lng": -96.93278988405174,
    "lat": 40.79119256375422
  },
  {
    "name": "Pleasant Gap",
    "lng": -77.74384243040265,
    "lat": 40.86735239655777
  },
  {
    "name": "Pleasant Garden",
    "lng": -79.75960651698306,
    "lat": 35.9590461559629
  },
  {
    "name": "Pleasant Grove",
    "lng": -111.74128001213124,
    "lat": 40.37155667869356
  },
  {
    "name": "Pleasant Grove",
    "lng": -86.97744750006527,
    "lat": 33.49359757237896
  },
  {
    "name": "Pleasant Groves",
    "lng": -86.20504524151657,
    "lat": 34.735495255176964
  },
  {
    "name": "Pleasant Hill",
    "lng": -94.26541437738116,
    "lat": 38.80608173540903
  },
  {
    "name": "Pleasant Hill",
    "lng": -94.26514355478209,
    "lat": 38.805906199556674
  },
  {
    "name": "Pleasant Hill",
    "lng": -94.2466636639261,
    "lat": 38.79392728763741
  },
  {
    "name": "Pleasant Hill",
    "lng": -93.51640853050549,
    "lat": 31.815368537244105
  },
  {
    "name": "Pleasant Hill",
    "lng": -93.49485125823988,
    "lat": 41.587517842511886
  },
  {
    "name": "Pleasant Hill",
    "lng": -93.48321670780629,
    "lat": 41.59385006071282
  },
  {
    "name": "Pleasant Hill",
    "lng": -90.87288506705129,
    "lat": 39.443198380761345
  },
  {
    "name": "Pleasant Hill",
    "lng": -85.19936648032078,
    "lat": 35.976621876263216
  },
  {
    "name": "Pleasant Hill",
    "lng": -84.34623400008209,
    "lat": 40.05036504331702
  },
  {
    "name": "Pleasant Hope",
    "lng": -93.27333825956738,
    "lat": 37.461383933835364
  },
  {
    "name": "Pleasant Plain",
    "lng": -91.85963276665181,
    "lat": 41.14718075633408
  },
  {
    "name": "Pleasant Plain",
    "lng": -84.11202728864251,
    "lat": 39.27860581687223
  },
  {
    "name": "Pleasant Plains",
    "lng": -91.6274477779878,
    "lat": 35.55405058389212
  },
  {
    "name": "Pleasant Plains",
    "lng": -89.91869640970053,
    "lat": 39.87455601264918
  },
  {
    "name": "Pleasant Prairie",
    "lng": -87.88873840986184,
    "lat": 42.52654138734799
  },
  {
    "name": "Pleasant Ridge",
    "lng": -83.14433104205922,
    "lat": 42.471324291433326
  },
  {
    "name": "Pleasant Valley",
    "lng": -98.59827078283392,
    "lat": 33.93725184087594
  },
  {
    "name": "Pleasant Valley",
    "lng": -94.48096851656491,
    "lat": 39.21721280800611
  },
  {
    "name": "Pleasant Valley",
    "lng": -80.15517711262085,
    "lat": 39.4504581303296
  },
  {
    "name": "Pleasant View",
    "lng": -112.00073676753176,
    "lat": 41.32493122859853
  },
  {
    "name": "Pleasant View",
    "lng": -87.04549085664463,
    "lat": 36.38934192299309
  },
  {
    "name": "Pleasanton",
    "lng": -99.0875703652963,
    "lat": 40.96979148284936
  },
  {
    "name": "Pleasanton",
    "lng": -98.49563861205674,
    "lat": 28.964328862927793
  },
  {
    "name": "Pleasanton",
    "lng": -94.71515520897266,
    "lat": 38.175702914398606
  },
  {
    "name": "Pleasanton",
    "lng": -94.70910891085298,
    "lat": 38.15790641208048
  },
  {
    "name": "Pleasanton",
    "lng": -94.70025464207674,
    "lat": 38.17925026887247
  },
  {
    "name": "Pleasanton",
    "lng": -94.69174429186266,
    "lat": 38.19246026200024
  },
  {
    "name": "Pleasanton",
    "lng": -93.74272460587072,
    "lat": 40.58240928723574
  },
  {
    "name": "Pleasantville",
    "lng": -93.27316738800648,
    "lat": 41.386798560870886
  },
  {
    "name": "Pleasantville",
    "lng": -82.52236883611585,
    "lat": 39.809802595648094
  },
  {
    "name": "Pleasantville",
    "lng": -74.51678562732917,
    "lat": 39.38977259334174
  },
  {
    "name": "Pleasantville",
    "lng": -73.78281444073389,
    "lat": 41.137660040633804
  },
  {
    "name": "Pleasureville",
    "lng": -85.11201297817432,
    "lat": 38.35113253706566
  },
  {
    "name": "Plentywood",
    "lng": -104.55648955240517,
    "lat": 48.774969739352926
  },
  {
    "name": "Plevna",
    "lng": -104.51842568673096,
    "lat": 46.41678890940494
  },
  {
    "name": "Plevna",
    "lng": -98.30957029110851,
    "lat": 37.97178147298234
  },
  {
    "name": "Plover",
    "lng": -94.62232182038555,
    "lat": 42.87657327844135
  },
  {
    "name": "Plum Branch",
    "lng": -82.2587443914486,
    "lat": 33.84816688047445
  },
  {
    "name": "Plum City",
    "lng": -92.19210606068118,
    "lat": 44.63372764562668
  },
  {
    "name": "Plum Grove",
    "lng": -95.09581026225679,
    "lat": 30.200750720377588
  },
  {
    "name": "Plum Springs",
    "lng": -86.38230180235372,
    "lat": 37.01935310330174
  },
  {
    "name": "Plumerville",
    "lng": -92.64256103129581,
    "lat": 35.159975047511
  },
  {
    "name": "Plummer",
    "lng": -116.88490858900961,
    "lat": 47.330137073233914
  },
  {
    "name": "Plummer",
    "lng": -96.04152073864542,
    "lat": 47.915152601990314
  },
  {
    "name": "Plymouth",
    "lng": -120.84497154899371,
    "lat": 38.47799781945625
  },
  {
    "name": "Plymouth",
    "lng": -112.14676670879886,
    "lat": 41.87353846787594
  },
  {
    "name": "Plymouth",
    "lng": -96.98804210636128,
    "lat": 40.30299661363035
  },
  {
    "name": "Plymouth",
    "lng": -93.46155301685661,
    "lat": 45.02242129100775
  },
  {
    "name": "Plymouth",
    "lng": -93.12310313127763,
    "lat": 43.24638256194943
  },
  {
    "name": "Plymouth",
    "lng": -90.91630305432237,
    "lat": 40.29212102065446
  },
  {
    "name": "Plymouth",
    "lng": -87.96574340553796,
    "lat": 43.74539239798782
  },
  {
    "name": "Plymouth",
    "lng": -86.31791299770971,
    "lat": 41.34812214846958
  },
  {
    "name": "Plymouth",
    "lng": -83.4679714779211,
    "lat": 42.37180424720732
  },
  {
    "name": "Plymouth",
    "lng": -82.66708345302742,
    "lat": 40.996484858588964
  },
  {
    "name": "Plymouth",
    "lng": -76.76365633070981,
    "lat": 35.83152602669262
  },
  {
    "name": "Plymouth",
    "lng": -76.74807145320503,
    "lat": 35.860309135502376
  },
  {
    "name": "Plymouth",
    "lng": -76.74592631478119,
    "lat": 35.846498220371615
  },
  {
    "name": "Plymouth",
    "lng": -73.02630960461741,
    "lat": 41.66418803265126
  },
  {
    "name": "Plymouth",
    "lng": -69.22458185643724,
    "lat": 44.770216164573235
  },
  {
    "name": "Poca",
    "lng": -81.81247648027343,
    "lat": 38.47298406290649
  },
  {
    "name": "Pocahontas",
    "lng": -94.67672838815164,
    "lat": 42.747565561791646
  },
  {
    "name": "Pocahontas",
    "lng": -94.66611249962216,
    "lat": 42.73692139691959
  },
  {
    "name": "Pocahontas",
    "lng": -90.99932140359361,
    "lat": 36.29264847289868
  },
  {
    "name": "Pocahontas",
    "lng": -90.970525161715,
    "lat": 36.26339147133145
  },
  {
    "name": "Pocahontas",
    "lng": -89.63959022577309,
    "lat": 37.50195114888741
  },
  {
    "name": "Pocahontas",
    "lng": -89.53935726018256,
    "lat": 38.823390263524345
  },
  {
    "name": "Pocahontas",
    "lng": -81.34355023789296,
    "lat": 37.30700118555435
  },
  {
    "name": "Pocasset",
    "lng": -97.9545837347939,
    "lat": 35.19515309458727
  },
  {
    "name": "Pocatello",
    "lng": -112.59333489942523,
    "lat": 42.909993569464945
  },
  {
    "name": "Pocatello",
    "lng": -112.43975436943535,
    "lat": 42.86508639218799
  },
  {
    "name": "Pocola",
    "lng": -94.47718800878123,
    "lat": 35.24552808514313
  },
  {
    "name": "Pocomoke City",
    "lng": -75.55994271636298,
    "lat": 38.06589602457708
  },
  {
    "name": "Pocomoke City",
    "lng": -75.54462693431867,
    "lat": 38.04312100583744
  },
  {
    "name": "Pocomoke City",
    "lng": -75.54053261077271,
    "lat": 38.066945835501926
  },
  {
    "name": "Pocomoke City",
    "lng": -75.535467329232,
    "lat": 38.0746891850717
  },
  {
    "name": "Pocono Pines",
    "lng": -75.46036031430897,
    "lat": 41.11541041952874
  },
  {
    "name": "Point",
    "lng": -95.86890740019874,
    "lat": 32.93016422071877
  },
  {
    "name": "Point Arena",
    "lng": -123.7250507555771,
    "lat": 38.918276636933555
  },
  {
    "name": "Point Arena",
    "lng": -123.69536189540804,
    "lat": 38.91226648470635
  },
  {
    "name": "Point Blank",
    "lng": -95.2151139580605,
    "lat": 30.7462664040695
  },
  {
    "name": "Point Comfort",
    "lng": -96.5593284868463,
    "lat": 28.67003606368094
  },
  {
    "name": "Point Edward",
    "lng": -82.40970186774771,
    "lat": 42.993548539506484
  },
  {
    "name": "Point Hope",
    "lng": -166.67826063229933,
    "lat": 68.34414897583962
  },
  {
    "name": "Point Pleasant",
    "lng": -82.13055597859626,
    "lat": 38.85248983831111
  },
  {
    "name": "Point Pleasant",
    "lng": -82.11686831179041,
    "lat": 38.874541572346274
  },
  {
    "name": "Point Sur State Historic Park",
    "lng": -121.88222945604282,
    "lat": 36.31556928059994
  },
  {
    "name": "Point Venture",
    "lng": -98.00106899400363,
    "lat": 30.38274810476599
  },
  {
    "name": "Poland",
    "lng": -80.61566235068437,
    "lat": 41.02197621557991
  },
  {
    "name": "Poland",
    "lng": -70.38996466371579,
    "lat": 44.04635343001875
  },
  {
    "name": "Polk",
    "lng": -97.78346783766884,
    "lat": 41.07545438194973
  },
  {
    "name": "Polk",
    "lng": -82.21467238555748,
    "lat": 40.94488833576315
  },
  {
    "name": "Polk",
    "lng": -79.93114991745567,
    "lat": 41.369055766960045
  },
  {
    "name": "Polk City",
    "lng": -93.71326482208956,
    "lat": 41.77735109116525
  },
  {
    "name": "Polk City",
    "lng": -81.85522156663586,
    "lat": 28.174009036857832
  },
  {
    "name": "Polk City",
    "lng": -81.85089410429597,
    "lat": 28.178737808243337
  },
  {
    "name": "Polk City",
    "lng": -81.84061415076538,
    "lat": 28.180013012658723
  },
  {
    "name": "Polk City",
    "lng": -81.83657615945516,
    "lat": 28.155125229039722
  },
  {
    "name": "Polk City",
    "lng": -81.83494996759448,
    "lat": 28.174715318856226
  },
  {
    "name": "Polk City",
    "lng": -81.83361004274111,
    "lat": 28.17356291879083
  },
  {
    "name": "Polk City",
    "lng": -81.83204477515069,
    "lat": 28.177589865021265
  },
  {
    "name": "Polk City",
    "lng": -81.83203019999982,
    "lat": 28.176459750214338
  },
  {
    "name": "Polk City",
    "lng": -81.83169809799685,
    "lat": 28.17695659102784
  },
  {
    "name": "Polk City",
    "lng": -81.82853115059312,
    "lat": 28.182060962727483
  },
  {
    "name": "Polk City",
    "lng": -81.82374763820032,
    "lat": 28.15993083621292
  },
  {
    "name": "Polk City",
    "lng": -81.82122942966758,
    "lat": 28.179555364197064
  },
  {
    "name": "Polkton",
    "lng": -80.19629687234348,
    "lat": 35.00296640206001
  },
  {
    "name": "Polkton",
    "lng": -80.19551938192535,
    "lat": 35.023681980973
  },
  {
    "name": "Polkville",
    "lng": -89.69596637977628,
    "lat": 32.190930470590516
  },
  {
    "name": "Polkville",
    "lng": -81.64414876608163,
    "lat": 35.41634468908303
  },
  {
    "name": "Pollard",
    "lng": -90.26713196655477,
    "lat": 36.42956663410501
  },
  {
    "name": "Pollard",
    "lng": -87.17360566326997,
    "lat": 31.023537010848013
  },
  {
    "name": "Pollock",
    "lng": -100.28852531470227,
    "lat": 45.900515454302344
  },
  {
    "name": "Pollock",
    "lng": -93.08372045094458,
    "lat": 40.35833250769421
  },
  {
    "name": "Pollock",
    "lng": -92.4432130095434,
    "lat": 31.498467961881605
  },
  {
    "name": "Pollocksville",
    "lng": -77.22111372133672,
    "lat": 35.00562087640252
  },
  {
    "name": "Polo",
    "lng": -94.03950795996188,
    "lat": 39.54950836963903
  },
  {
    "name": "Polo",
    "lng": -89.5787878609946,
    "lat": 41.98469031691719
  },
  {
    "name": "Polson",
    "lng": -114.1817396203014,
    "lat": 47.69728836300617
  },
  {
    "name": "Polson",
    "lng": -114.16021474467087,
    "lat": 47.679314481477974
  },
  {
    "name": "Polson",
    "lng": -114.14700215135221,
    "lat": 47.68629734743251
  },
  {
    "name": "Polson",
    "lng": -114.1468812411797,
    "lat": 47.68571543156751
  },
  {
    "name": "Polson",
    "lng": -114.1398268183755,
    "lat": 47.68856800539263
  },
  {
    "name": "Polson",
    "lng": -114.11717639856386,
    "lat": 47.68986073772001
  },
  {
    "name": "Pomaria",
    "lng": -81.41956367484197,
    "lat": 34.268119421314765
  },
  {
    "name": "Pomeroy",
    "lng": -117.59719406954673,
    "lat": 46.47393247098106
  },
  {
    "name": "Pomeroy",
    "lng": -94.67789298315876,
    "lat": 42.55193569782489
  },
  {
    "name": "Pomeroy",
    "lng": -82.03408667665259,
    "lat": 39.02916326155063
  },
  {
    "name": "Pomfret",
    "lng": -71.98349462323499,
    "lat": 41.86714514190897
  },
  {
    "name": "Pomona",
    "lng": -117.76260115805778,
    "lat": 34.058326959332476
  },
  {
    "name": "Pomona",
    "lng": -95.45308735421779,
    "lat": 38.61046887407023
  },
  {
    "name": "Pomona",
    "lng": -74.05424535857303,
    "lat": 41.18914329658834
  },
  {
    "name": "Pompano Beach",
    "lng": -80.13111595104408,
    "lat": 26.242872647831632
  },
  {
    "name": "Ponca",
    "lng": -96.70949862157505,
    "lat": 42.564843841829465
  },
  {
    "name": "Ponca City",
    "lng": -97.06845044258183,
    "lat": 36.72354552291706
  },
  {
    "name": "Ponca City",
    "lng": -97.01961070503512,
    "lat": 36.70920019196358
  },
  {
    "name": "Ponca City",
    "lng": -96.99578399893122,
    "lat": 36.6930583980567
  },
  {
    "name": "Ponce Inlet",
    "lng": -80.97500704120338,
    "lat": 29.083912172494063
  },
  {
    "name": "Ponce Inlet",
    "lng": -80.97263341700707,
    "lat": 29.088058667878382
  },
  {
    "name": "Ponce Inlet",
    "lng": -80.96985794191613,
    "lat": 29.103820319227527
  },
  {
    "name": "Ponce Inlet",
    "lng": -80.95914325395579,
    "lat": 29.096367324281424
  },
  {
    "name": "Ponce Inlet",
    "lng": -80.94028844782571,
    "lat": 29.0980082195787
  },
  {
    "name": "Ponce Inlet",
    "lng": -80.93995725724906,
    "lat": 29.079638301080312
  },
  {
    "name": "Poncha Springs",
    "lng": -106.0906893486488,
    "lat": 38.51433569921433
  },
  {
    "name": "Ponchatoula",
    "lng": -90.44270930665128,
    "lat": 30.44020896902439
  },
  {
    "name": "Pond Creek",
    "lng": -97.80291902454736,
    "lat": 36.66756260145819
  },
  {
    "name": "Ponder",
    "lng": -97.29088124650619,
    "lat": 33.17748202726086
  },
  {
    "name": "Ponderay",
    "lng": -116.53902692059314,
    "lat": 48.30917811302731
  },
  {
    "name": "Poneto",
    "lng": -85.22243576876173,
    "lat": 40.65675822759133
  },
  {
    "name": "Pontiac",
    "lng": -88.64147479670973,
    "lat": 40.8881325475205
  },
  {
    "name": "Pontiac",
    "lng": -83.28780822381904,
    "lat": 42.64930077182426
  },
  {
    "name": "Pontoon Beach",
    "lng": -90.09416599990853,
    "lat": 38.754877802224314
  },
  {
    "name": "Pontoon Beach",
    "lng": -90.05954161501029,
    "lat": 38.72020972327536
  },
  {
    "name": "Pontoosuc",
    "lng": -91.21118188796792,
    "lat": 40.628394756789895
  },
  {
    "name": "Pontoosuc",
    "lng": -73.24524248906812,
    "lat": 42.50031534268006
  },
  {
    "name": "Pontotoc",
    "lng": -89.00905906351639,
    "lat": 34.25314057912227
  },
  {
    "name": "Pooler",
    "lng": -81.25678782151738,
    "lat": 32.10435242105674
  },
  {
    "name": "Pope",
    "lng": -89.94819812551884,
    "lat": 34.214425268487204
  },
  {
    "name": "Popejoy",
    "lng": -93.42782431583926,
    "lat": 42.59376588919414
  },
  {
    "name": "Poplar",
    "lng": -105.19916108861912,
    "lat": 48.11088343557368
  },
  {
    "name": "Poplar",
    "lng": -105.18891315929122,
    "lat": 48.10911268973432
  },
  {
    "name": "Poplar",
    "lng": -91.78361366709892,
    "lat": 46.582842601199054
  },
  {
    "name": "Poplar Bluff",
    "lng": -90.41210267824326,
    "lat": 36.763053262771315
  },
  {
    "name": "Poplar Grove",
    "lng": -88.8352976411691,
    "lat": 42.351626556377525
  },
  {
    "name": "Poplar Hills",
    "lng": -85.69288250223462,
    "lat": 38.17788183093174
  },
  {
    "name": "Poplar-Cotton Center",
    "lng": -119.14927456814233,
    "lat": 36.05634539697355
  },
  {
    "name": "Poplarville",
    "lng": -89.53662621741806,
    "lat": 30.840841608288684
  },
  {
    "name": "Poquetanuck",
    "lng": -72.03885632993197,
    "lat": 41.48584573503572
  },
  {
    "name": "Poquonock Bridge",
    "lng": -72.01758694230689,
    "lat": 41.33785916906391
  },
  {
    "name": "Poquoson",
    "lng": -76.28556330953447,
    "lat": 37.15411759983703
  },
  {
    "name": "Poquott",
    "lng": -73.08921767238876,
    "lat": 40.95451044185843
  },
  {
    "name": "Port Alexander",
    "lng": -134.64249892937138,
    "lat": 56.22549392045109
  },
  {
    "name": "Port Allen",
    "lng": -91.20978563815578,
    "lat": 30.44551411082925
  },
  {
    "name": "Port Angeles",
    "lng": -123.46991088021515,
    "lat": 48.17226376630516
  },
  {
    "name": "Port Aransas",
    "lng": -97.08378650790657,
    "lat": 27.808324976711976
  },
  {
    "name": "Port Austin",
    "lng": -82.99455437361806,
    "lat": 44.043071072579636
  },
  {
    "name": "Port Barre",
    "lng": -91.95649024737148,
    "lat": 30.556592848475898
  },
  {
    "name": "Port Barrington",
    "lng": -88.19381552595428,
    "lat": 42.243475079010345
  },
  {
    "name": "Port Byron",
    "lng": -90.32731483360024,
    "lat": 41.61657523012198
  },
  {
    "name": "Port Byron",
    "lng": -76.62610220120455,
    "lat": 43.03652475213469
  },
  {
    "name": "Port Chester",
    "lng": -73.66793819402149,
    "lat": 41.00533761521119
  },
  {
    "name": "Port Clinton",
    "lng": -82.93807117654546,
    "lat": 41.509406915797555
  },
  {
    "name": "Port Deposit",
    "lng": -76.09896816134365,
    "lat": 39.61059231619105
  },
  {
    "name": "Port Dickinson",
    "lng": -75.89295723122456,
    "lat": 42.13650428348673
  },
  {
    "name": "Port Edwards",
    "lng": -89.84947560822089,
    "lat": 44.34802495821323
  },
  {
    "name": "Port Gibson",
    "lng": -90.98343339653432,
    "lat": 31.95580450166977
  },
  {
    "name": "Port Heiden",
    "lng": -158.59891047374126,
    "lat": 56.94234338538326
  },
  {
    "name": "Port Henry",
    "lng": -73.45999602381842,
    "lat": 44.04641894759081
  },
  {
    "name": "Port Hope",
    "lng": -82.7147351470236,
    "lat": 43.93938368475161
  },
  {
    "name": "Port Hueneme",
    "lng": -119.20365263968621,
    "lat": 34.16186555997728
  },
  {
    "name": "Port Huron",
    "lng": -82.4343095108429,
    "lat": 42.99333530970547
  },
  {
    "name": "Port Isabel",
    "lng": -97.24400906723312,
    "lat": 26.05965011769397
  },
  {
    "name": "Port Jefferson",
    "lng": -84.09181603656869,
    "lat": 40.33005670354589
  },
  {
    "name": "Port Jefferson",
    "lng": -73.05743403876153,
    "lat": 40.947017846716136
  },
  {
    "name": "Port Jervis",
    "lng": -74.69051319132642,
    "lat": 41.378440807416986
  },
  {
    "name": "Port La Belle",
    "lng": -81.3876828647021,
    "lat": 26.749394176601847
  },
  {
    "name": "Port Lavaca",
    "lng": -96.62784549192303,
    "lat": 28.618075545317755
  },
  {
    "name": "Port Leyden",
    "lng": -75.34143122904446,
    "lat": 43.58383694019821
  },
  {
    "name": "Port Lions",
    "lng": -152.86431382382264,
    "lat": 57.87603578162502
  },
  {
    "name": "Port Matilda",
    "lng": -78.05252524957292,
    "lat": 40.79933063839577
  },
  {
    "name": "Port Neches",
    "lng": -93.94591407336196,
    "lat": 29.97648900585128
  },
  {
    "name": "Port Orchard",
    "lng": -122.66110564551153,
    "lat": 47.51632671844766
  },
  {
    "name": "Port Republic",
    "lng": -74.48518176200052,
    "lat": 39.532735047606174
  },
  {
    "name": "Port Richey",
    "lng": -82.74021508393166,
    "lat": 28.27502062971937
  },
  {
    "name": "Port Richey",
    "lng": -82.7382557279967,
    "lat": 28.274091682294447
  },
  {
    "name": "Port Richey",
    "lng": -82.72659880736275,
    "lat": 28.2759394701945
  },
  {
    "name": "Port Richey",
    "lng": -82.7132773833323,
    "lat": 28.282431953604295
  },
  {
    "name": "Port Richey",
    "lng": -82.71241813656754,
    "lat": 28.286782536118743
  },
  {
    "name": "Port Richey",
    "lng": -82.70498890909289,
    "lat": 28.279571264031503
  },
  {
    "name": "Port Royal",
    "lng": -80.83867513076552,
    "lat": 32.369169685800195
  },
  {
    "name": "Port Royal",
    "lng": -80.79534790858618,
    "lat": 32.35031905559132
  },
  {
    "name": "Port Royal",
    "lng": -80.78939286569015,
    "lat": 32.35508222038296
  },
  {
    "name": "Port Royal",
    "lng": -80.77045563219214,
    "lat": 32.39594686257049
  },
  {
    "name": "Port Royal",
    "lng": -80.76415748740695,
    "lat": 32.397169434328106
  },
  {
    "name": "Port Royal",
    "lng": -80.76411331932172,
    "lat": 32.39963591883584
  },
  {
    "name": "Port Royal",
    "lng": -80.7634225604746,
    "lat": 32.39274377965045
  },
  {
    "name": "Port Royal",
    "lng": -80.76122617120829,
    "lat": 32.39610501110079
  },
  {
    "name": "Port Royal",
    "lng": -80.75955014144165,
    "lat": 32.40754257553174
  },
  {
    "name": "Port Royal",
    "lng": -80.75109306387309,
    "lat": 32.386835608990204
  },
  {
    "name": "Port Royal",
    "lng": -80.73389789354194,
    "lat": 32.374031495176354
  },
  {
    "name": "Port Royal",
    "lng": -80.7295922896543,
    "lat": 32.37415211176042
  },
  {
    "name": "Port Royal",
    "lng": -80.69890578856953,
    "lat": 32.354926060158355
  },
  {
    "name": "Port Royal",
    "lng": -77.19123335689234,
    "lat": 38.16963087075769
  },
  {
    "name": "Port Salerno",
    "lng": -80.1893844764578,
    "lat": 27.146198361242508
  },
  {
    "name": "Port Sanilac",
    "lng": -82.54471929144395,
    "lat": 43.428557985983495
  },
  {
    "name": "Port St. Joe",
    "lng": -85.33791372872003,
    "lat": 29.87253457467519
  },
  {
    "name": "Port St. Joe",
    "lng": -85.31815523635186,
    "lat": 29.839886974901468
  },
  {
    "name": "Port St. Joe",
    "lng": -85.29237799651158,
    "lat": 29.805033604118595
  },
  {
    "name": "Port St. Lucie",
    "lng": -80.3832636876202,
    "lat": 27.281056907475314
  },
  {
    "name": "Port St. Lucie",
    "lng": -80.26088631479632,
    "lat": 27.309794968531914
  },
  {
    "name": "Port Tobacco Village",
    "lng": -77.02105669879933,
    "lat": 38.512181727222554
  },
  {
    "name": "Port Townsend",
    "lng": -122.78278989767124,
    "lat": 48.1236322725183
  },
  {
    "name": "Port Vincent",
    "lng": -90.84194142075006,
    "lat": 30.337911176819198
  },
  {
    "name": "Port Washington",
    "lng": -87.88493015235346,
    "lat": 43.38350530923722
  },
  {
    "name": "Port Washington",
    "lng": -87.88114593672921,
    "lat": 43.41032748842176
  },
  {
    "name": "Port Washington",
    "lng": -87.8727236761217,
    "lat": 43.422656264220336
  },
  {
    "name": "Port Washington",
    "lng": -87.86548138703046,
    "lat": 43.42230727566826
  },
  {
    "name": "Port Washington",
    "lng": -81.5191140051901,
    "lat": 40.3267721874055
  },
  {
    "name": "Port Washington North",
    "lng": -73.70133132073475,
    "lat": 40.84363052118128
  },
  {
    "name": "Port Wentworth",
    "lng": -81.19844943711666,
    "lat": 32.194193647911796
  },
  {
    "name": "Port William",
    "lng": -83.78540856685733,
    "lat": 39.55231337030478
  },
  {
    "name": "Portage",
    "lng": -112.24050814466852,
    "lat": 41.97561189894649
  },
  {
    "name": "Portage",
    "lng": -89.46608701745653,
    "lat": 43.54872792722481
  },
  {
    "name": "Portage",
    "lng": -89.45245846065426,
    "lat": 43.57931630216826
  },
  {
    "name": "Portage",
    "lng": -87.17965822751373,
    "lat": 41.58571562660164
  },
  {
    "name": "Portage",
    "lng": -85.59065331702422,
    "lat": 42.19999037761575
  },
  {
    "name": "Portage",
    "lng": -85.55157080000203,
    "lat": 42.22884930325278
  },
  {
    "name": "Portage",
    "lng": -83.64680056474845,
    "lat": 41.32321867392125
  },
  {
    "name": "Portage Des Sioux",
    "lng": -90.34249136213609,
    "lat": 38.92694572178601
  },
  {
    "name": "Portage Lake",
    "lng": -68.5016564436963,
    "lat": 46.7875024506634
  },
  {
    "name": "Portageville",
    "lng": -89.69949020002295,
    "lat": 36.429441534551266
  },
  {
    "name": "Portal",
    "lng": -102.54743980285114,
    "lat": 48.995665310294434
  },
  {
    "name": "Portal",
    "lng": -81.93031445344364,
    "lat": 32.53635712645674
  },
  {
    "name": "Portales",
    "lng": -103.35475027101084,
    "lat": 34.17678993484769
  },
  {
    "name": "Portales",
    "lng": -103.31912339614283,
    "lat": 34.20382275872723
  },
  {
    "name": "Porter",
    "lng": -96.16788076875147,
    "lat": 44.64277628762515
  },
  {
    "name": "Porter",
    "lng": -95.52014705516163,
    "lat": 35.86791236488637
  },
  {
    "name": "Porter",
    "lng": -87.08007406542251,
    "lat": 41.62581970187278
  },
  {
    "name": "Porter",
    "lng": -70.9408741792159,
    "lat": 43.84000246267049
  },
  {
    "name": "Porterdale",
    "lng": -83.91268579536654,
    "lat": 33.58681702856064
  },
  {
    "name": "Porterdale",
    "lng": -83.9071462282583,
    "lat": 33.5903628092002
  },
  {
    "name": "Porterdale",
    "lng": -83.89146357051702,
    "lat": 33.57047790539525
  },
  {
    "name": "Porterdale",
    "lng": -83.88238935267636,
    "lat": 33.579011890207155
  },
  {
    "name": "Porterdale",
    "lng": -83.87799351319318,
    "lat": 33.5606947125252
  },
  {
    "name": "Portersville",
    "lng": -80.14761541293115,
    "lat": 40.9239278331287
  },
  {
    "name": "Porterville",
    "lng": -119.09297846233765,
    "lat": 36.02577946582829
  },
  {
    "name": "Porterville",
    "lng": -119.0899255204491,
    "lat": 36.01204473647382
  },
  {
    "name": "Porterville",
    "lng": -119.03125727610399,
    "lat": 36.066591290340995
  },
  {
    "name": "Portia",
    "lng": -91.07104317007098,
    "lat": 36.08486548034809
  },
  {
    "name": "Portis",
    "lng": -98.69164839753847,
    "lat": 39.5636692805495
  },
  {
    "name": "Portland",
    "lng": -97.3693083015773,
    "lat": 47.49925825869578
  },
  {
    "name": "Portland",
    "lng": -97.3296796192822,
    "lat": 27.886025825861246
  },
  {
    "name": "Portland",
    "lng": -91.511046964878,
    "lat": 33.22777050841696
  },
  {
    "name": "Portland",
    "lng": -91.51099970168478,
    "lat": 33.238239392274544
  },
  {
    "name": "Portland",
    "lng": -91.51099970168477,
    "lat": 33.238239392274544
  },
  {
    "name": "Portland",
    "lng": -86.52446657488093,
    "lat": 36.59283137472494
  },
  {
    "name": "Portland",
    "lng": -84.98272048853678,
    "lat": 40.4372236765371
  },
  {
    "name": "Portland",
    "lng": -84.89860613586548,
    "lat": 42.86993861010919
  },
  {
    "name": "Portland",
    "lng": -72.58922846977869,
    "lat": 41.5989829056261
  },
  {
    "name": "Portland",
    "lng": -70.21935424106562,
    "lat": 43.64875732756304
  },
  {
    "name": "Portland",
    "lng": -70.05825345615423,
    "lat": 43.64363342201636
  },
  {
    "name": "Portola",
    "lng": -120.47342397416872,
    "lat": 39.824135403083545
  },
  {
    "name": "Portola",
    "lng": -120.47342397416872,
    "lat": 39.8241354030836
  },
  {
    "name": "Portola",
    "lng": -120.46709253000401,
    "lat": 39.81932287405361
  },
  {
    "name": "Portola",
    "lng": -120.46709253000401,
    "lat": 39.81932287405363
  },
  {
    "name": "Portola Valley",
    "lng": -122.22015860616955,
    "lat": 37.37117135915989
  },
  {
    "name": "Portsmouth",
    "lng": -95.51850134673315,
    "lat": 41.650764268524796
  },
  {
    "name": "Portsmouth",
    "lng": -82.94442326557753,
    "lat": 38.75388901054685
  },
  {
    "name": "Portsmouth",
    "lng": -71.37244562872917,
    "lat": 41.59675645822303
  },
  {
    "name": "Portsmouth",
    "lng": -71.3709678515093,
    "lat": 41.595672766194895
  },
  {
    "name": "Portsmouth",
    "lng": -71.37064105042813,
    "lat": 41.60613495780968
  },
  {
    "name": "Portsmouth",
    "lng": -71.37038966071663,
    "lat": 41.60308059405542
  },
  {
    "name": "Portsmouth",
    "lng": -71.36947550246651,
    "lat": 41.59672571633076
  },
  {
    "name": "Portsmouth",
    "lng": -71.36946950021243,
    "lat": 41.59697989336151
  },
  {
    "name": "Portsmouth",
    "lng": -71.36765113847882,
    "lat": 41.60215950145983
  },
  {
    "name": "Portsmouth",
    "lng": -71.36507965982689,
    "lat": 41.6001142406896
  },
  {
    "name": "Portsmouth",
    "lng": -71.36471909411918,
    "lat": 41.60016180340058
  },
  {
    "name": "Portsmouth",
    "lng": -71.36262657868207,
    "lat": 41.606992729279106
  },
  {
    "name": "Portsmouth",
    "lng": -71.36225968662448,
    "lat": 41.60653393315871
  },
  {
    "name": "Portsmouth",
    "lng": -71.36141262859469,
    "lat": 41.60778521702127
  },
  {
    "name": "Portsmouth",
    "lng": -71.35991598897388,
    "lat": 41.65640663502118
  },
  {
    "name": "Portsmouth",
    "lng": -71.358374307065,
    "lat": 41.6058464654442
  },
  {
    "name": "Portsmouth",
    "lng": -71.32591238002964,
    "lat": 41.614895344783186
  },
  {
    "name": "Portsmouth",
    "lng": -71.3015875756675,
    "lat": 41.577452118671424
  },
  {
    "name": "Portsmouth",
    "lng": -71.30157771790824,
    "lat": 41.577489489588146
  },
  {
    "name": "Portsmouth",
    "lng": -71.30150465898376,
    "lat": 41.57786903289598
  },
  {
    "name": "Portsmouth",
    "lng": -71.30102812264747,
    "lat": 41.57826647650336
  },
  {
    "name": "Portsmouth",
    "lng": -71.29950443939171,
    "lat": 41.58246516477446
  },
  {
    "name": "Portsmouth",
    "lng": -71.28783854160704,
    "lat": 41.64483737647893
  },
  {
    "name": "Portsmouth",
    "lng": -71.28612097671596,
    "lat": 41.646504683239414
  },
  {
    "name": "Portsmouth",
    "lng": -71.28370173398083,
    "lat": 41.64591797301312
  },
  {
    "name": "Portsmouth",
    "lng": -71.28030569655914,
    "lat": 41.641809600541876
  },
  {
    "name": "Portsmouth",
    "lng": -71.25634360416306,
    "lat": 41.582023315217704
  },
  {
    "name": "Portville",
    "lng": -78.33725932095145,
    "lat": 42.03670949207297
  },
  {
    "name": "Porum",
    "lng": -95.26335213715254,
    "lat": 35.35680411811116
  },
  {
    "name": "Posen",
    "lng": -87.68579110676264,
    "lat": 41.62904215158166
  },
  {
    "name": "Posen",
    "lng": -83.69883824015038,
    "lat": 45.262246778622476
  },
  {
    "name": "Poseyville",
    "lng": -87.7833143727452,
    "lat": 38.169935211464455
  },
  {
    "name": "Post",
    "lng": -101.38145985223704,
    "lat": 33.19099188495711
  },
  {
    "name": "Post Falls",
    "lng": -116.93872031879748,
    "lat": 47.72125977034432
  },
  {
    "name": "Post Oak Bend City",
    "lng": -96.31855516732169,
    "lat": 32.634351633144846
  },
  {
    "name": "Postville",
    "lng": -91.56740906072139,
    "lat": 43.08460538440773
  },
  {
    "name": "Poteau",
    "lng": -94.63582918822364,
    "lat": 35.03868063253863
  },
  {
    "name": "Poteau",
    "lng": -94.63379717878,
    "lat": 35.18675763339727
  },
  {
    "name": "Poteau",
    "lng": -94.5919717254219,
    "lat": 35.067206193723266
  },
  {
    "name": "Poteet",
    "lng": -98.57276422542867,
    "lat": 29.037280030095467
  },
  {
    "name": "Poth",
    "lng": -98.08093045738147,
    "lat": 29.072589811337078
  },
  {
    "name": "Potlatch",
    "lng": -116.8977564493039,
    "lat": 46.923560422309016
  },
  {
    "name": "Potomac",
    "lng": -87.79759515627185,
    "lat": 40.306692525131034
  },
  {
    "name": "Potosi",
    "lng": -90.77457160935295,
    "lat": 37.93373245417562
  },
  {
    "name": "Potosi",
    "lng": -90.71040678874698,
    "lat": 42.68733457250337
  },
  {
    "name": "Potsdam",
    "lng": -84.41482411658734,
    "lat": 39.963709307103706
  },
  {
    "name": "Potsdam",
    "lng": -74.98562026568825,
    "lat": 44.66917065876534
  },
  {
    "name": "Pottawattamie Park",
    "lng": -86.86713370564445,
    "lat": 41.723288404591464
  },
  {
    "name": "Potter",
    "lng": -103.31365195412769,
    "lat": 41.218491921335875
  },
  {
    "name": "Potter",
    "lng": -88.1003096404783,
    "lat": 44.12009247493191
  },
  {
    "name": "Potter Valley",
    "lng": -123.11224590870508,
    "lat": 39.319067440041486
  },
  {
    "name": "Potterville",
    "lng": -84.74379158677351,
    "lat": 42.629786371006816
  },
  {
    "name": "Potts Camp",
    "lng": -89.30642537305597,
    "lat": 34.649211635886026
  },
  {
    "name": "Pottsboro",
    "lng": -96.67058586961858,
    "lat": 33.77133550130615
  },
  {
    "name": "Pottstown",
    "lng": -75.64444264278441,
    "lat": 40.25073907327733
  },
  {
    "name": "Pottsville",
    "lng": -93.0647740326469,
    "lat": 35.23032860582622
  },
  {
    "name": "Pottsville",
    "lng": -76.20918619882035,
    "lat": 40.67981893477152
  },
  {
    "name": "Potwin",
    "lng": -97.01840940804364,
    "lat": 37.9386350464106
  },
  {
    "name": "Potwin",
    "lng": -97.0155994914966,
    "lat": 37.9344973810222
  },
  {
    "name": "Poughkeepsie",
    "lng": -73.92104985202901,
    "lat": 41.69492296336338
  },
  {
    "name": "Poulan",
    "lng": -83.78974805723458,
    "lat": 31.514501715770802
  },
  {
    "name": "Poulsbo",
    "lng": -122.64837839586603,
    "lat": 47.77519769647874
  },
  {
    "name": "Poulsbo",
    "lng": -122.64280293167725,
    "lat": 47.76989956494971
  },
  {
    "name": "Poulsbo",
    "lng": -122.642322364631,
    "lat": 47.7713828333251
  },
  {
    "name": "Poulsbo",
    "lng": -122.64091376045396,
    "lat": 47.74159236025193
  },
  {
    "name": "Poulsbo",
    "lng": -122.61459191177654,
    "lat": 47.75040665599415
  },
  {
    "name": "Poulsbo",
    "lng": -122.6106812040021,
    "lat": 47.7173710031918
  },
  {
    "name": "Poultney",
    "lng": -73.23596415568002,
    "lat": 43.51806187229657
  },
  {
    "name": "Pound",
    "lng": -88.03330865441247,
    "lat": 45.095437206109125
  },
  {
    "name": "Pound",
    "lng": -82.60704217943766,
    "lat": 37.12595985756861
  },
  {
    "name": "Poway",
    "lng": -117.02034971289528,
    "lat": 32.987144058106246
  },
  {
    "name": "Powder Springs",
    "lng": -84.68560873978036,
    "lat": 33.865667753151214
  },
  {
    "name": "Powder Springs",
    "lng": -84.67278318994154,
    "lat": 33.87466135421118
  },
  {
    "name": "Powder Springs",
    "lng": -84.66614113172643,
    "lat": 33.84983831707277
  },
  {
    "name": "Powder Springs",
    "lng": -84.6514673158006,
    "lat": 33.871744599923055
  },
  {
    "name": "Powderly",
    "lng": -87.15899278845593,
    "lat": 37.23755992027302
  },
  {
    "name": "Powell",
    "lng": -108.79044825194241,
    "lat": 44.86717891280672
  },
  {
    "name": "Powell",
    "lng": -108.76882677986946,
    "lat": 44.746619592360254
  },
  {
    "name": "Powell",
    "lng": -108.75873121196267,
    "lat": 44.755250252914486
  },
  {
    "name": "Powell",
    "lng": -108.73578097895101,
    "lat": 44.787314180002866
  },
  {
    "name": "Powell",
    "lng": -96.32370278431807,
    "lat": 32.11293238748996
  },
  {
    "name": "Powell",
    "lng": -85.8979291113845,
    "lat": 34.532493638092234
  },
  {
    "name": "Powell",
    "lng": -83.0796752581163,
    "lat": 40.16791737942539
  },
  {
    "name": "Powells Crossroads",
    "lng": -85.48383479221258,
    "lat": 35.18417120904847
  },
  {
    "name": "Powellsville",
    "lng": -76.93157311360363,
    "lat": 36.225250696015245
  },
  {
    "name": "Powers",
    "lng": -87.5268613347976,
    "lat": 45.688315364040996
  },
  {
    "name": "Powers Lake",
    "lng": -102.64337361669413,
    "lat": 48.56316810156998
  },
  {
    "name": "Powersville",
    "lng": -93.30024540374468,
    "lat": 40.54956685989596
  },
  {
    "name": "Powhatan",
    "lng": -93.20323959243788,
    "lat": 31.8737751448314
  },
  {
    "name": "Powhatan",
    "lng": -91.12137471623028,
    "lat": 36.083865790728126
  },
  {
    "name": "Powhatan Point",
    "lng": -80.80985974465452,
    "lat": 39.86243165186487
  },
  {
    "name": "Powhattan",
    "lng": -95.63380063877341,
    "lat": 39.76141607110669
  },
  {
    "name": "Pownal",
    "lng": -70.18185117186802,
    "lat": 43.89916524733453
  },
  {
    "name": "Poyen",
    "lng": -92.64196567174955,
    "lat": 34.32418600697941
  },
  {
    "name": "Poynette",
    "lng": -89.40584596935601,
    "lat": 43.3921105638387
  },
  {
    "name": "Poynor",
    "lng": -95.59237222556493,
    "lat": 32.079342804659944
  },
  {
    "name": "Prague",
    "lng": -96.80795994307243,
    "lat": 41.30994770996197
  },
  {
    "name": "Prague",
    "lng": -96.6906881086202,
    "lat": 35.488334285582646
  },
  {
    "name": "Prairie City",
    "lng": -93.23926627682387,
    "lat": 41.5954269935652
  },
  {
    "name": "Prairie City",
    "lng": -90.46491150156746,
    "lat": 40.62103471711376
  },
  {
    "name": "Prairie Farm",
    "lng": -91.98040697425293,
    "lat": 45.236836453803356
  },
  {
    "name": "Prairie Grove",
    "lng": -94.30492740930544,
    "lat": 35.98573544640554
  },
  {
    "name": "Prairie Grove",
    "lng": -88.2699392194583,
    "lat": 42.277174568521495
  },
  {
    "name": "Prairie Grove",
    "lng": -88.23593041671913,
    "lat": 42.26968426985187
  },
  {
    "name": "Prairie Home",
    "lng": -92.59062135315838,
    "lat": 38.81360510157458
  },
  {
    "name": "Prairie Rose",
    "lng": -96.83538131261082,
    "lat": 46.81720432556329
  },
  {
    "name": "Prairie View",
    "lng": -99.57342038697924,
    "lat": 39.83201131934765
  },
  {
    "name": "Prairie View",
    "lng": -95.98972360481093,
    "lat": 30.084964758123157
  },
  {
    "name": "Prairie Village",
    "lng": -94.63611402670936,
    "lat": 38.98732746235588
  },
  {
    "name": "Prairie du Chien",
    "lng": -91.1388574957591,
    "lat": 43.04358809662384
  },
  {
    "name": "Prairie du Chien",
    "lng": -91.11450023887329,
    "lat": 43.02386260900174
  },
  {
    "name": "Prairie du Rocher",
    "lng": -90.0975654013066,
    "lat": 38.081708471119654
  },
  {
    "name": "Prairieburg",
    "lng": -91.42575048127632,
    "lat": 42.23752076923406
  },
  {
    "name": "Prathersville",
    "lng": -94.27380259251716,
    "lat": 39.31508489928133
  },
  {
    "name": "Pratt",
    "lng": -98.75729958470953,
    "lat": 37.70460264295261
  },
  {
    "name": "Pratt",
    "lng": -98.73591562677312,
    "lat": 37.645847733086086
  },
  {
    "name": "Pratt",
    "lng": -98.71229874323491,
    "lat": 37.65623571445154
  },
  {
    "name": "Pratt",
    "lng": -98.70822290333564,
    "lat": 37.64375588528272
  },
  {
    "name": "Pratt",
    "lng": -81.38675847194338,
    "lat": 38.20822776299274
  },
  {
    "name": "Prattsville",
    "lng": -92.54286953701435,
    "lat": 34.31716366284888
  },
  {
    "name": "Prattville",
    "lng": -86.45972706016842,
    "lat": 32.460385431059244
  },
  {
    "name": "Prattville",
    "lng": -86.4264446357879,
    "lat": 32.43481087684929
  },
  {
    "name": "Preble Town",
    "lng": -76.1320572272455,
    "lat": 42.74298639526031
  },
  {
    "name": "Premont",
    "lng": -98.1244180626633,
    "lat": 27.358602874051254
  },
  {
    "name": "Prentice",
    "lng": -90.28976124497242,
    "lat": 45.5408557999324
  },
  {
    "name": "Prentiss",
    "lng": -89.87121082559261,
    "lat": 31.597137463405026
  },
  {
    "name": "Prescott",
    "lng": -118.3135554457378,
    "lat": 46.29867726139077
  },
  {
    "name": "Prescott",
    "lng": -94.70044912803542,
    "lat": 38.05786097808967
  },
  {
    "name": "Prescott",
    "lng": -94.69593098137831,
    "lat": 38.06373773067215
  },
  {
    "name": "Prescott",
    "lng": -94.6788925240208,
    "lat": 38.06032839114806
  },
  {
    "name": "Prescott",
    "lng": -94.61287220182781,
    "lat": 41.02366960779628
  },
  {
    "name": "Prescott",
    "lng": -93.39144717231295,
    "lat": 33.80562000887134
  },
  {
    "name": "Prescott",
    "lng": -92.78757509615731,
    "lat": 44.75172702226475
  },
  {
    "name": "Prescott",
    "lng": -83.9322120057904,
    "lat": 44.191401041248945
  },
  {
    "name": "Presho",
    "lng": -100.05875268662994,
    "lat": 43.906813628142004
  },
  {
    "name": "Presidio",
    "lng": -104.36532236771421,
    "lat": 29.560357642343092
  },
  {
    "name": "Presque Isle",
    "lng": -67.98735210948337,
    "lat": 46.68677843922152
  },
  {
    "name": "Preston",
    "lng": -111.87997697874385,
    "lat": 42.09892206839019
  },
  {
    "name": "Preston",
    "lng": -98.55547975516987,
    "lat": 37.75827847074066
  },
  {
    "name": "Preston",
    "lng": -95.51810868389624,
    "lat": 40.03458852250659
  },
  {
    "name": "Preston",
    "lng": -93.21270705537432,
    "lat": 37.94096294218653
  },
  {
    "name": "Preston",
    "lng": -92.09675423881323,
    "lat": 43.685833816535435
  },
  {
    "name": "Preston",
    "lng": -92.08323350852088,
    "lat": 43.67252002634993
  },
  {
    "name": "Preston",
    "lng": -90.39713290101008,
    "lat": 42.04896014539459
  },
  {
    "name": "Preston",
    "lng": -90.38785553065371,
    "lat": 42.054831730395414
  },
  {
    "name": "Preston",
    "lng": -90.38514406589243,
    "lat": 42.05409495993029
  },
  {
    "name": "Preston",
    "lng": -75.90868094762348,
    "lat": 38.71113212556189
  },
  {
    "name": "Preston",
    "lng": -72.00107685530607,
    "lat": 41.52237796221595
  },
  {
    "name": "Prestonsburg",
    "lng": -82.76081812056299,
    "lat": 37.684352259670966
  },
  {
    "name": "Prestonville",
    "lng": -85.19340796832287,
    "lat": 38.679054016648806
  },
  {
    "name": "Pretty Prairie",
    "lng": -98.02316199468564,
    "lat": 37.7801354230822
  },
  {
    "name": "Pretty Prairie",
    "lng": -98.02308330258205,
    "lat": 37.77506540865446
  },
  {
    "name": "Pretty Prairie",
    "lng": -98.01009541828856,
    "lat": 37.78353062277909
  },
  {
    "name": "Price",
    "lng": -110.80037703052658,
    "lat": 39.60400760896795
  },
  {
    "name": "Priceville",
    "lng": -86.8858155213177,
    "lat": 34.524036082277306
  },
  {
    "name": "Prichard",
    "lng": -88.13005632083701,
    "lat": 30.77359181695044
  },
  {
    "name": "Priest River",
    "lng": -116.88498222567436,
    "lat": 48.178401977437346
  },
  {
    "name": "Priest River",
    "lng": -116.82564062791707,
    "lat": 48.1678034051922
  },
  {
    "name": "Primera",
    "lng": -97.75284555769034,
    "lat": 26.2236670292883
  },
  {
    "name": "Primghar",
    "lng": -95.62076532600975,
    "lat": 43.08642671128742
  },
  {
    "name": "Primrose",
    "lng": -98.23739457497574,
    "lat": 41.62379807765991
  },
  {
    "name": "Prince Frederick",
    "lng": -76.58949534916168,
    "lat": 38.54570271821108
  },
  {
    "name": "Princes Lakes",
    "lng": -86.11000967964398,
    "lat": 39.351588621736234
  },
  {
    "name": "Princess Anne",
    "lng": -75.69694000136151,
    "lat": 38.20549136587106
  },
  {
    "name": "Princess Anne",
    "lng": -75.68882237166035,
    "lat": 38.18844391655452
  },
  {
    "name": "Princeton",
    "lng": -95.27172384145202,
    "lat": 38.488578988706664
  },
  {
    "name": "Princeton",
    "lng": -95.26518613701215,
    "lat": 38.483229460192476
  },
  {
    "name": "Princeton",
    "lng": -93.60686858007165,
    "lat": 45.57138954175539
  },
  {
    "name": "Princeton",
    "lng": -93.59018912745474,
    "lat": 45.54753245848092
  },
  {
    "name": "Princeton",
    "lng": -93.58990562967398,
    "lat": 45.56905694531427
  },
  {
    "name": "Princeton",
    "lng": -93.58846079252012,
    "lat": 40.396560822637404
  },
  {
    "name": "Princeton",
    "lng": -93.58796723524772,
    "lat": 45.553381896989066
  },
  {
    "name": "Princeton",
    "lng": -90.35715492924011,
    "lat": 41.67411080831696
  },
  {
    "name": "Princeton",
    "lng": -89.48849762672515,
    "lat": 41.36626533477283
  },
  {
    "name": "Princeton",
    "lng": -89.48290456823378,
    "lat": 41.37409795122662
  },
  {
    "name": "Princeton",
    "lng": -89.47818377363848,
    "lat": 41.386628667148685
  },
  {
    "name": "Princeton",
    "lng": -89.46638438716543,
    "lat": 41.37902226139086
  },
  {
    "name": "Princeton",
    "lng": -89.44795551797664,
    "lat": 41.362835148068726
  },
  {
    "name": "Princeton",
    "lng": -89.44628780854133,
    "lat": 41.403024068467396
  },
  {
    "name": "Princeton",
    "lng": -87.88542215955658,
    "lat": 37.1067499167205
  },
  {
    "name": "Princeton",
    "lng": -87.57832395997494,
    "lat": 38.35528019609993
  },
  {
    "name": "Princeton",
    "lng": -81.10734689328203,
    "lat": 37.384166563839166
  },
  {
    "name": "Princeton",
    "lng": -81.09569152895591,
    "lat": 37.368395514084575
  },
  {
    "name": "Princeton",
    "lng": -78.16003721613876,
    "lat": 35.46548931064219
  },
  {
    "name": "Princeton",
    "lng": -71.88127621969001,
    "lat": 42.45651067954444
  },
  {
    "name": "Princeton",
    "lng": -67.54535224314063,
    "lat": 45.17836063359779
  },
  {
    "name": "Princeville",
    "lng": -89.75467668904042,
    "lat": 40.93503424295665
  },
  {
    "name": "Princeville",
    "lng": -77.52652454735801,
    "lat": 35.87952637718334
  },
  {
    "name": "Princeville",
    "lng": -77.51935764524207,
    "lat": 35.88614301587335
  },
  {
    "name": "Pringle",
    "lng": -103.5946237061357,
    "lat": 43.60900999756298
  },
  {
    "name": "Prinsburg",
    "lng": -95.18700305790735,
    "lat": 44.934915196167466
  },
  {
    "name": "Prior Lake",
    "lng": -93.44185366994313,
    "lat": 44.72492372246968
  },
  {
    "name": "Pritchett",
    "lng": -102.85874485495195,
    "lat": 37.37000506767808
  },
  {
    "name": "Proctor",
    "lng": -92.22753344702213,
    "lat": 46.74246196467035
  },
  {
    "name": "Proctorville",
    "lng": -82.38074680607616,
    "lat": 38.43788522877279
  },
  {
    "name": "Proctorville",
    "lng": -79.03703898611134,
    "lat": 34.47517914768144
  },
  {
    "name": "Progreso",
    "lng": -97.95658758698673,
    "lat": 26.09614743810651
  },
  {
    "name": "Progreso Lakes",
    "lng": -97.95880391928478,
    "lat": 26.071255857810296
  },
  {
    "name": "Promise City",
    "lng": -93.14980719816393,
    "lat": 40.74738562292004
  },
  {
    "name": "Prophetstown",
    "lng": -89.93480947210823,
    "lat": 41.670263359704016
  },
  {
    "name": "Prospect",
    "lng": -85.60947265559912,
    "lat": 38.34716394431808
  },
  {
    "name": "Prospect",
    "lng": -83.18480400366913,
    "lat": 40.453917721451454
  },
  {
    "name": "Prospect",
    "lng": -75.15154807314612,
    "lat": 43.30430952490841
  },
  {
    "name": "Prospect",
    "lng": -68.86741631928844,
    "lat": 44.56092648105782
  },
  {
    "name": "Prospect Heights",
    "lng": -87.92653344075802,
    "lat": 42.10377093079223
  },
  {
    "name": "Prosperity",
    "lng": -81.53357599657944,
    "lat": 34.211892372304426
  },
  {
    "name": "Prosser",
    "lng": -119.76687223665887,
    "lat": 46.20675179937595
  },
  {
    "name": "Prosser",
    "lng": -98.57798855920065,
    "lat": 40.68734866818478
  },
  {
    "name": "Protection",
    "lng": -99.48097177076258,
    "lat": 37.20022253248497
  },
  {
    "name": "Protivin",
    "lng": -92.08895694556642,
    "lat": 43.21674102562215
  },
  {
    "name": "Provencal",
    "lng": -93.19978112093011,
    "lat": 31.654639379994393
  },
  {
    "name": "Providence",
    "lng": -111.81228956351549,
    "lat": 41.703413100995704
  },
  {
    "name": "Providence",
    "lng": -87.77611491998127,
    "lat": 32.34238801249288
  },
  {
    "name": "Providence",
    "lng": -87.75058864693915,
    "lat": 37.3995421558382
  },
  {
    "name": "Providence",
    "lng": -71.42093878958103,
    "lat": 41.82398784573678
  },
  {
    "name": "Provo",
    "lng": -111.64572946151839,
    "lat": 40.24573007301011
  },
  {
    "name": "Prue",
    "lng": -96.26670443913716,
    "lat": 36.24956828758853
  },
  {
    "name": "Puckett",
    "lng": -89.7821605795356,
    "lat": 32.08761419670797
  },
  {
    "name": "Pueblo",
    "lng": -104.6661414656085,
    "lat": 38.27588605626269
  },
  {
    "name": "Pueblo",
    "lng": -104.66291539223025,
    "lat": 38.3131222896494
  },
  {
    "name": "Pueblo",
    "lng": -104.65642553776027,
    "lat": 38.30597869413866
  },
  {
    "name": "Pueblo",
    "lng": -104.61350453587355,
    "lat": 38.269850561646074
  },
  {
    "name": "Pueblo",
    "lng": -104.58018170488499,
    "lat": 38.20426484471342
  },
  {
    "name": "Pueblo",
    "lng": -104.46334676653571,
    "lat": 38.27944766558442
  },
  {
    "name": "Pueblo County",
    "lng": -104.52851391781378,
    "lat": 38.23094961508337
  },
  {
    "name": "Pukwana",
    "lng": -99.18426250611019,
    "lat": 43.77917914059916
  },
  {
    "name": "Pulaski",
    "lng": -92.27392229986788,
    "lat": 40.69415130781482
  },
  {
    "name": "Pulaski",
    "lng": -89.20801554589849,
    "lat": 37.215981859730185
  },
  {
    "name": "Pulaski",
    "lng": -88.23559608943505,
    "lat": 44.668105767582894
  },
  {
    "name": "Pulaski",
    "lng": -87.03528922902825,
    "lat": 35.19342572081685
  },
  {
    "name": "Pulaski",
    "lng": -81.95654808554467,
    "lat": 32.39038431680561
  },
  {
    "name": "Pulaski",
    "lng": -80.76259536491908,
    "lat": 37.05282771395517
  },
  {
    "name": "Pulaski",
    "lng": -76.12677556747066,
    "lat": 43.565361573030366
  },
  {
    "name": "Pullman",
    "lng": -117.16586361768401,
    "lat": 46.733964624707404
  },
  {
    "name": "Pullman",
    "lng": -80.9489947584881,
    "lat": 39.188014622108376
  },
  {
    "name": "Punaluu",
    "lng": -157.89121594516007,
    "lat": 21.588240924427545
  },
  {
    "name": "Punta Gorda",
    "lng": -82.05282500726614,
    "lat": 26.891352162693146
  },
  {
    "name": "Punta Gorda",
    "lng": -82.03993401463818,
    "lat": 26.838532159842455
  },
  {
    "name": "Punta Gorda",
    "lng": -82.03240880297953,
    "lat": 26.946520419610597
  },
  {
    "name": "Punta Rassa",
    "lng": -82.00337254909105,
    "lat": 26.50106611785582
  },
  {
    "name": "Purcell",
    "lng": -97.39415980978497,
    "lat": 35.042490258511094
  },
  {
    "name": "Purcell",
    "lng": -97.37092277735196,
    "lat": 35.024737066821054
  },
  {
    "name": "Purcell",
    "lng": -94.43947863137144,
    "lat": 37.24330828218457
  },
  {
    "name": "Purcell",
    "lng": -94.43090673248311,
    "lat": 37.23835997485238
  },
  {
    "name": "Purcellville",
    "lng": -77.71091158290027,
    "lat": 39.13773234342907
  },
  {
    "name": "Purdin",
    "lng": -93.16634617620147,
    "lat": 39.95088571614545
  },
  {
    "name": "Purdy",
    "lng": -93.92057397822049,
    "lat": 36.81877188873439
  },
  {
    "name": "Puryear",
    "lng": -88.33262928318116,
    "lat": 36.44216387923098
  },
  {
    "name": "Put-in-Bay",
    "lng": -82.81790467921246,
    "lat": 41.65136065288249
  },
  {
    "name": "Putnam",
    "lng": -99.19549531552433,
    "lat": 32.370270117241944
  },
  {
    "name": "Putnam",
    "lng": -98.96794135106167,
    "lat": 35.855891492654045
  },
  {
    "name": "Putnam District",
    "lng": -71.91087477372778,
    "lat": 41.91788840341923
  },
  {
    "name": "Puxico",
    "lng": -90.15890403858482,
    "lat": 36.95109861636009
  },
  {
    "name": "Puyallup",
    "lng": -122.28987358447195,
    "lat": 47.17943270794009
  },
  {
    "name": "Pyatt",
    "lng": -92.84432045051193,
    "lat": 36.25091848036162
  },
  {
    "name": "Pymatuning Central",
    "lng": -80.47954593470541,
    "lat": 41.58558331218936
  },
  {
    "name": "Pymatuning South",
    "lng": -80.47018952349126,
    "lat": 41.50677771348174
  },
  {
    "name": "Pyote",
    "lng": -103.12248364114005,
    "lat": 31.538066628090565
  },
  {
    "name": "Quaker City",
    "lng": -81.29741787003626,
    "lat": 39.96965503587483
  },
  {
    "name": "Quakertown",
    "lng": -75.34569550449031,
    "lat": 40.43981338484602
  },
  {
    "name": "Quamba",
    "lng": -93.1752989379344,
    "lat": 45.91553663840291
  },
  {
    "name": "Quanah",
    "lng": -99.74261729866203,
    "lat": 34.29530592751298
  },
  {
    "name": "Quantico",
    "lng": -77.29015977691262,
    "lat": 38.52243189563322
  },
  {
    "name": "Quapaw",
    "lng": -94.79042469313427,
    "lat": 36.9527062460106
  },
  {
    "name": "Quapaw",
    "lng": -94.78333246735967,
    "lat": 36.955089428546195
  },
  {
    "name": "Quartzsite",
    "lng": -114.2172537353623,
    "lat": 33.66753132970496
  },
  {
    "name": "Quasqueton",
    "lng": -91.75774798087568,
    "lat": 42.394787129829204
  },
  {
    "name": "Quasset Lake",
    "lng": -71.9839137107328,
    "lat": 41.922823533609225
  },
  {
    "name": "Queen Anne",
    "lng": -75.9534481708226,
    "lat": 38.919447834858765
  },
  {
    "name": "Queen City",
    "lng": -94.15611029392244,
    "lat": 33.17427107783845
  },
  {
    "name": "Queen City",
    "lng": -94.15241932772751,
    "lat": 33.15057213319274
  },
  {
    "name": "Queen City",
    "lng": -92.56627232294484,
    "lat": 40.412578994460425
  },
  {
    "name": "Queens Gate",
    "lng": -76.70109659288094,
    "lat": 39.93049504759643
  },
  {
    "name": "Queenstown",
    "lng": -76.15679404573176,
    "lat": 38.98877277443746
  },
  {
    "name": "Quenemo",
    "lng": -95.5266687794319,
    "lat": 38.58027089136149
  },
  {
    "name": "Quentin",
    "lng": -76.43522044464315,
    "lat": 40.27929569496727
  },
  {
    "name": "Questa",
    "lng": -105.59352950551686,
    "lat": 36.71142777159308
  },
  {
    "name": "Quimby",
    "lng": -95.64418730503384,
    "lat": 42.62904483712833
  },
  {
    "name": "Quinby",
    "lng": -79.73340185114321,
    "lat": 34.22990748028366
  },
  {
    "name": "Quincy",
    "lng": -119.88970829447278,
    "lat": 47.21584993366302
  },
  {
    "name": "Quincy",
    "lng": -119.84966257844314,
    "lat": 47.2363930403714
  },
  {
    "name": "Quincy",
    "lng": -119.83718697122353,
    "lat": 47.21074509840914
  },
  {
    "name": "Quincy",
    "lng": -91.37888978233208,
    "lat": 39.93331964711201
  },
  {
    "name": "Quincy",
    "lng": -84.88450774109428,
    "lat": 41.943228919744065
  },
  {
    "name": "Quincy",
    "lng": -84.58581421725476,
    "lat": 30.575972179118676
  },
  {
    "name": "Quincy",
    "lng": -84.57057040488708,
    "lat": 30.56543606840496
  },
  {
    "name": "Quincy",
    "lng": -84.55725727931419,
    "lat": 30.580808930930907
  },
  {
    "name": "Quincy",
    "lng": -84.5555267082163,
    "lat": 30.556830521655947
  },
  {
    "name": "Quincy",
    "lng": -84.55536848947699,
    "lat": 30.582510424703184
  },
  {
    "name": "Quincy",
    "lng": -84.55041338434447,
    "lat": 30.563346527381377
  },
  {
    "name": "Quincy",
    "lng": -84.54858040429635,
    "lat": 30.582253023843865
  },
  {
    "name": "Quincy",
    "lng": -83.9686316498128,
    "lat": 40.29540088201611
  },
  {
    "name": "Quinebaug",
    "lng": -71.9369185950152,
    "lat": 42.010659864615356
  },
  {
    "name": "Quinhagak",
    "lng": -161.9020682213353,
    "lat": 59.75129673077191
  },
  {
    "name": "Quinlan",
    "lng": -96.13126928772895,
    "lat": 32.908981004177846
  },
  {
    "name": "Quinn",
    "lng": -102.1287668701206,
    "lat": 43.98708421325928
  },
  {
    "name": "Quinnesec",
    "lng": -87.99833856130107,
    "lat": 45.80061040121515
  },
  {
    "name": "Quintana",
    "lng": -95.31451893323415,
    "lat": 28.919710021509786
  },
  {
    "name": "Quinter",
    "lng": -100.24446269488209,
    "lat": 39.057241416248786
  },
  {
    "name": "Quinter",
    "lng": -100.2347330814692,
    "lat": 39.06691123066758
  },
  {
    "name": "Quinton",
    "lng": -95.36760448076781,
    "lat": 35.124841527523344
  },
  {
    "name": "Quinwood",
    "lng": -80.7035536515665,
    "lat": 38.058290369348015
  },
  {
    "name": "Quitaque",
    "lng": -101.05559757711404,
    "lat": 34.36741004820201
  },
  {
    "name": "Quitman",
    "lng": -95.44422921969277,
    "lat": 32.795072070469374
  },
  {
    "name": "Quitman",
    "lng": -95.07664066055872,
    "lat": 40.37333970073863
  },
  {
    "name": "Quitman",
    "lng": -92.72358998416865,
    "lat": 32.3507071407804
  },
  {
    "name": "Quitman",
    "lng": -92.21475264215984,
    "lat": 35.38171104068872
  },
  {
    "name": "Quitman",
    "lng": -88.72024053868026,
    "lat": 32.04168804049248
  },
  {
    "name": "Quitman",
    "lng": -83.56505184173771,
    "lat": 30.813788682257346
  },
  {
    "name": "Quitman",
    "lng": -83.56027768767706,
    "lat": 30.784126220752675
  },
  {
    "name": "Qulin",
    "lng": -90.25209800455475,
    "lat": 36.59771747642962
  },
  {
    "name": "Quogue",
    "lng": -72.59872974304301,
    "lat": 40.821352091085174
  },
  {
    "name": "Raceland",
    "lng": -82.73411795141807,
    "lat": 38.53711365101402
  },
  {
    "name": "Racine",
    "lng": -92.4809718473656,
    "lat": 43.77554974923099
  },
  {
    "name": "Racine",
    "lng": -87.86307853544525,
    "lat": 42.779928021824226
  },
  {
    "name": "Racine",
    "lng": -87.85611721579316,
    "lat": 42.772107912621095
  },
  {
    "name": "Racine",
    "lng": -87.84474598727577,
    "lat": 42.72476175584467
  },
  {
    "name": "Racine",
    "lng": -87.80568698540968,
    "lat": 42.72735530105102
  },
  {
    "name": "Racine",
    "lng": -81.91244267095287,
    "lat": 38.968790624403994
  },
  {
    "name": "Radcliff",
    "lng": -85.9361411413319,
    "lat": 37.82026589074819
  },
  {
    "name": "Radcliffe",
    "lng": -93.43430518778148,
    "lat": 42.31771110879272
  },
  {
    "name": "Radford City",
    "lng": -80.55874224839712,
    "lat": 37.12296133942784
  },
  {
    "name": "Radisson",
    "lng": -91.21791488765717,
    "lat": 45.7683208496646
  },
  {
    "name": "Radium",
    "lng": -98.89432478579266,
    "lat": 38.173701383726524
  },
  {
    "name": "Radom",
    "lng": -89.19212468283119,
    "lat": 38.27982524584433
  },
  {
    "name": "Raeford",
    "lng": -79.23943633333334,
    "lat": 34.984693040688406
  },
  {
    "name": "Raeford",
    "lng": -79.23791133392793,
    "lat": 34.972896637261606
  },
  {
    "name": "Raeford",
    "lng": -79.2377446134562,
    "lat": 34.98471194847073
  },
  {
    "name": "Raeford",
    "lng": -79.228286872716,
    "lat": 34.98090197084583
  },
  {
    "name": "Raeford",
    "lng": -79.22444797329126,
    "lat": 34.99394177091514
  },
  {
    "name": "Ragan",
    "lng": -99.28964767978403,
    "lat": 40.31075262620529
  },
  {
    "name": "Ragland",
    "lng": -86.1384111145011,
    "lat": 33.746819090846586
  },
  {
    "name": "Rahway",
    "lng": -74.28080776288641,
    "lat": 40.60777048416032
  },
  {
    "name": "Rainbow City",
    "lng": -86.12225346982144,
    "lat": 33.96126148141372
  },
  {
    "name": "Rainbow City",
    "lng": -86.12225346982143,
    "lat": 33.96126148141372
  },
  {
    "name": "Rainbow City",
    "lng": -86.10256209272576,
    "lat": 33.89706597260604
  },
  {
    "name": "Rainbow City",
    "lng": -86.09166840885983,
    "lat": 33.93434892532791
  },
  {
    "name": "Rainelle",
    "lng": -80.77159691183705,
    "lat": 37.96786350630803
  },
  {
    "name": "Rainier",
    "lng": -122.68638097214566,
    "lat": 46.8913013560041
  },
  {
    "name": "Rainsville",
    "lng": -85.84442564316141,
    "lat": 34.49414537009496
  },
  {
    "name": "Rainy River",
    "lng": -94.56889058004215,
    "lat": 48.72209374786526
  },
  {
    "name": "Rake",
    "lng": -93.91963746005543,
    "lat": 43.48057529742429
  },
  {
    "name": "Raleigh",
    "lng": -89.52470034237143,
    "lat": 32.03191315737263
  },
  {
    "name": "Raleigh",
    "lng": -88.53035423965365,
    "lat": 37.82621775734182
  },
  {
    "name": "Raleigh",
    "lng": -78.76109068779144,
    "lat": 35.92161428161026
  },
  {
    "name": "Raleigh",
    "lng": -78.75403672863061,
    "lat": 35.919937019278365
  },
  {
    "name": "Raleigh",
    "lng": -78.73690868897079,
    "lat": 35.7476040233185
  },
  {
    "name": "Raleigh",
    "lng": -78.7347923242233,
    "lat": 35.867226479224264
  },
  {
    "name": "Raleigh",
    "lng": -78.72735232159643,
    "lat": 35.90611373080516
  },
  {
    "name": "Raleigh",
    "lng": -78.72657912128533,
    "lat": 35.88647070580356
  },
  {
    "name": "Raleigh",
    "lng": -78.72628291444502,
    "lat": 35.908546618784264
  },
  {
    "name": "Raleigh",
    "lng": -78.72599945446404,
    "lat": 35.88052595531673
  },
  {
    "name": "Raleigh",
    "lng": -78.7253161468762,
    "lat": 35.90423986249178
  },
  {
    "name": "Raleigh",
    "lng": -78.7246611618477,
    "lat": 35.884705190378405
  },
  {
    "name": "Raleigh",
    "lng": -78.71854907857066,
    "lat": 35.750358051232176
  },
  {
    "name": "Raleigh",
    "lng": -78.71776987544388,
    "lat": 35.9101203396711
  },
  {
    "name": "Raleigh",
    "lng": -78.71770150407626,
    "lat": 35.895225175099625
  },
  {
    "name": "Raleigh",
    "lng": -78.71072277418814,
    "lat": 35.800019796741175
  },
  {
    "name": "Raleigh",
    "lng": -78.70818206038602,
    "lat": 35.900067785173185
  },
  {
    "name": "Raleigh",
    "lng": -78.7078059163494,
    "lat": 35.90654715833629
  },
  {
    "name": "Raleigh",
    "lng": -78.70109252002251,
    "lat": 35.90524799709415
  },
  {
    "name": "Raleigh",
    "lng": -78.69979496490406,
    "lat": 35.911276053351315
  },
  {
    "name": "Raleigh",
    "lng": -78.69601587632398,
    "lat": 35.90793250516388
  },
  {
    "name": "Raleigh",
    "lng": -78.69054734669379,
    "lat": 35.90354777364983
  },
  {
    "name": "Raleigh",
    "lng": -78.68762509762232,
    "lat": 35.90259316275643
  },
  {
    "name": "Raleigh",
    "lng": -78.68544879293117,
    "lat": 35.90411708697647
  },
  {
    "name": "Raleigh",
    "lng": -78.68502435225363,
    "lat": 35.742386164881765
  },
  {
    "name": "Raleigh",
    "lng": -78.66156742232977,
    "lat": 35.91157213644968
  },
  {
    "name": "Raleigh",
    "lng": -78.65678858693872,
    "lat": 35.911085491526826
  },
  {
    "name": "Raleigh",
    "lng": -78.64692326766148,
    "lat": 35.83325292949319
  },
  {
    "name": "Raleigh",
    "lng": -78.64285694174484,
    "lat": 35.89544281876847
  },
  {
    "name": "Raleigh",
    "lng": -78.59425240551313,
    "lat": 35.74307160399115
  },
  {
    "name": "Raleigh",
    "lng": -78.59229569354328,
    "lat": 35.746883892505785
  },
  {
    "name": "Raleigh",
    "lng": -78.58970377478796,
    "lat": 35.750702565538106
  },
  {
    "name": "Raleigh",
    "lng": -78.58673741763923,
    "lat": 35.74723182678961
  },
  {
    "name": "Raleigh",
    "lng": -78.58058864906607,
    "lat": 35.934726513110775
  },
  {
    "name": "Raleigh",
    "lng": -78.57858471796699,
    "lat": 35.93809194245864
  },
  {
    "name": "Raleigh",
    "lng": -78.57848791162786,
    "lat": 35.80438067219282
  },
  {
    "name": "Raleigh",
    "lng": -78.57840992854759,
    "lat": 35.88047733285329
  },
  {
    "name": "Raleigh",
    "lng": -78.57817044745046,
    "lat": 35.743485256662616
  },
  {
    "name": "Raleigh",
    "lng": -78.5780397891965,
    "lat": 35.72310320988538
  },
  {
    "name": "Raleigh",
    "lng": -78.57529386512886,
    "lat": 35.72964739298574
  },
  {
    "name": "Raleigh",
    "lng": -78.57476615819608,
    "lat": 35.87923802433467
  },
  {
    "name": "Raleigh",
    "lng": -78.57460058962769,
    "lat": 35.880304731722966
  },
  {
    "name": "Raleigh",
    "lng": -78.57439064005807,
    "lat": 35.728641200728056
  },
  {
    "name": "Raleigh",
    "lng": -78.57146223961226,
    "lat": 35.758440282354705
  },
  {
    "name": "Raleigh",
    "lng": -78.56999647775551,
    "lat": 35.765954138320346
  },
  {
    "name": "Raleigh",
    "lng": -78.56949966700766,
    "lat": 35.721925344083715
  },
  {
    "name": "Raleigh",
    "lng": -78.568746314073,
    "lat": 35.76461830641568
  },
  {
    "name": "Raleigh",
    "lng": -78.56585068508099,
    "lat": 35.76250227303067
  },
  {
    "name": "Raleigh",
    "lng": -78.5644034167576,
    "lat": 35.76220382647623
  },
  {
    "name": "Raleigh",
    "lng": -78.56332012656371,
    "lat": 35.76200914422647
  },
  {
    "name": "Raleigh",
    "lng": -78.56200932112199,
    "lat": 35.76194642981988
  },
  {
    "name": "Raleigh",
    "lng": -78.55789622549793,
    "lat": 35.85503340133194
  },
  {
    "name": "Raleigh",
    "lng": -78.54502615778462,
    "lat": 35.75773745496983
  },
  {
    "name": "Raleigh",
    "lng": -78.54122971750573,
    "lat": 35.744773687429785
  },
  {
    "name": "Raleigh",
    "lng": -78.54027858892715,
    "lat": 35.84809922295139
  },
  {
    "name": "Raleigh",
    "lng": -78.53941296452066,
    "lat": 35.83110210580311
  },
  {
    "name": "Raleigh",
    "lng": -78.53927454070943,
    "lat": 35.754237454532074
  },
  {
    "name": "Raleigh",
    "lng": -78.5360226361896,
    "lat": 35.84025434749181
  },
  {
    "name": "Raleigh",
    "lng": -78.52793838366703,
    "lat": 35.82142460088448
  },
  {
    "name": "Raleigh",
    "lng": -78.52363267437613,
    "lat": 35.80914451149265
  },
  {
    "name": "Raleigh",
    "lng": -78.52354919936397,
    "lat": 35.85486124645762
  },
  {
    "name": "Raleigh",
    "lng": -78.52189960460716,
    "lat": 35.830188821438455
  },
  {
    "name": "Raleigh",
    "lng": -78.51817756311951,
    "lat": 35.834819614881475
  },
  {
    "name": "Raleigh",
    "lng": -78.51600671352709,
    "lat": 35.870624862870415
  },
  {
    "name": "Raleigh",
    "lng": -78.51555378308026,
    "lat": 35.89897533626026
  },
  {
    "name": "Raleigh",
    "lng": -78.51319611491516,
    "lat": 35.86413532136443
  },
  {
    "name": "Raleigh",
    "lng": -78.5130729438275,
    "lat": 35.71933333213749
  },
  {
    "name": "Raleigh",
    "lng": -78.51297359788212,
    "lat": 35.88977254250991
  },
  {
    "name": "Raleigh",
    "lng": -78.50942503498536,
    "lat": 35.88385021116033
  },
  {
    "name": "Raleigh",
    "lng": -78.50886482579689,
    "lat": 35.83887832830389
  },
  {
    "name": "Raleigh",
    "lng": -78.50827451403713,
    "lat": 35.84866312281906
  },
  {
    "name": "Raleigh",
    "lng": -78.50662739638764,
    "lat": 35.88951262388053
  },
  {
    "name": "Raleigh",
    "lng": -78.47985605107539,
    "lat": 35.84421408243902
  },
  {
    "name": "Ralls",
    "lng": -101.3845910451524,
    "lat": 33.67877479596362
  },
  {
    "name": "Ralston",
    "lng": -96.73754106433759,
    "lat": 36.50353726396147
  },
  {
    "name": "Ralston",
    "lng": -96.03542329574393,
    "lat": 41.20053281252563
  },
  {
    "name": "Ralston",
    "lng": -94.62931637653541,
    "lat": 42.04187448902326
  },
  {
    "name": "Ramah",
    "lng": -104.16730586676634,
    "lat": 39.122165156382216
  },
  {
    "name": "Ramer",
    "lng": -88.61524175584323,
    "lat": 35.07167391488956
  },
  {
    "name": "Ramona",
    "lng": -97.21541703544206,
    "lat": 44.11987918624875
  },
  {
    "name": "Ramona",
    "lng": -97.06378228871723,
    "lat": 38.59772165257988
  },
  {
    "name": "Ramona",
    "lng": -95.92581951135374,
    "lat": 36.53218595265575
  },
  {
    "name": "Ramseur",
    "lng": -79.67701642687078,
    "lat": 35.739579745174204
  },
  {
    "name": "Ramseur",
    "lng": -79.67135196733321,
    "lat": 35.75995145439341
  },
  {
    "name": "Ramseur",
    "lng": -79.65167328318924,
    "lat": 35.73319107683417
  },
  {
    "name": "Ramseur",
    "lng": -79.64005362663413,
    "lat": 35.728579704654166
  },
  {
    "name": "Ramseur",
    "lng": -79.62888717568732,
    "lat": 35.744766585345296
  },
  {
    "name": "Ramsey",
    "lng": -93.44943712456688,
    "lat": 45.261731935597304
  },
  {
    "name": "Ramsey",
    "lng": -89.11049314387672,
    "lat": 39.144244727432906
  },
  {
    "name": "Ranburne",
    "lng": -85.33901156671918,
    "lat": 33.52585337555176
  },
  {
    "name": "Ranchester",
    "lng": -107.16732288359576,
    "lat": 44.90759988917771
  },
  {
    "name": "Rancho Cordova",
    "lng": -121.25218806918774,
    "lat": 38.57248908399159
  },
  {
    "name": "Rancho Cucamonga",
    "lng": -117.56202815345348,
    "lat": 34.13039025276744
  },
  {
    "name": "Rancho Mirage",
    "lng": -116.42727062776771,
    "lat": 33.761941506636035
  },
  {
    "name": "Rancho Santa Margarita",
    "lng": -117.59882549694171,
    "lat": 33.631838353865014
  },
  {
    "name": "Rancho Tehama Reserve",
    "lng": -122.47183356193037,
    "lat": 39.99642623717218
  },
  {
    "name": "Rancho Viejo",
    "lng": -97.55764526221128,
    "lat": 26.036389195753078
  },
  {
    "name": "Randalia",
    "lng": -91.88677609322342,
    "lat": 42.863373595992975
  },
  {
    "name": "Randall",
    "lng": -98.04546840850881,
    "lat": 39.641581720106814
  },
  {
    "name": "Randall",
    "lng": -94.5007818564071,
    "lat": 46.08866165145931
  },
  {
    "name": "Randall",
    "lng": -93.60252763441842,
    "lat": 42.23698549351542
  },
  {
    "name": "Randleman",
    "lng": -79.82969893009309,
    "lat": 35.81641903305404
  },
  {
    "name": "Randleman",
    "lng": -79.82327706170793,
    "lat": 35.82716329877887
  },
  {
    "name": "Randleman",
    "lng": -79.80869862625642,
    "lat": 35.81537255255952
  },
  {
    "name": "Randleman",
    "lng": -79.80675338542243,
    "lat": 35.80701995020705
  },
  {
    "name": "Randleman",
    "lng": -79.80267306600061,
    "lat": 35.83275495569512
  },
  {
    "name": "Randleman",
    "lng": -79.78989277917428,
    "lat": 35.8317530001532
  },
  {
    "name": "Randleman",
    "lng": -79.78392060665561,
    "lat": 35.85288959400874
  },
  {
    "name": "Randleman",
    "lng": -79.782541033651,
    "lat": 35.82259959420142
  },
  {
    "name": "Randleman",
    "lng": -79.78136936645633,
    "lat": 35.83387163669626
  },
  {
    "name": "Randleman",
    "lng": -79.78012574218967,
    "lat": 35.801854482601875
  },
  {
    "name": "Randlett",
    "lng": -98.46392365579212,
    "lat": 34.1773480810062
  },
  {
    "name": "Randolph",
    "lng": -111.18462734451165,
    "lat": 41.665540616365746
  },
  {
    "name": "Randolph",
    "lng": -97.357670755988,
    "lat": 42.377499194516425
  },
  {
    "name": "Randolph",
    "lng": -96.75964770631418,
    "lat": 39.42888895971636
  },
  {
    "name": "Randolph",
    "lng": -95.56475752091207,
    "lat": 40.87313199240657
  },
  {
    "name": "Randolph",
    "lng": -94.49726357543523,
    "lat": 39.1615154644903
  },
  {
    "name": "Randolph",
    "lng": -94.49281173982513,
    "lat": 39.15584617189803
  },
  {
    "name": "Randolph",
    "lng": -93.01984907691278,
    "lat": 44.52596378087417
  },
  {
    "name": "Randolph",
    "lng": -89.00256814138945,
    "lat": 43.53928311816058
  },
  {
    "name": "Randolph",
    "lng": -78.98019002407555,
    "lat": 42.16116461320317
  },
  {
    "name": "Randolph",
    "lng": -69.75065266192232,
    "lat": 44.234709906718436
  },
  {
    "name": "Random Lake",
    "lng": -87.95489648258207,
    "lat": 43.554394842558956
  },
  {
    "name": "Rangeley",
    "lng": -70.72652896487034,
    "lat": 44.97638770023344
  },
  {
    "name": "Rangely",
    "lng": -108.7775860402287,
    "lat": 40.086270935142814
  },
  {
    "name": "Ranger",
    "lng": -98.6761863067771,
    "lat": 32.46912017109271
  },
  {
    "name": "Ranger",
    "lng": -84.71089411463612,
    "lat": 34.50111719811639
  },
  {
    "name": "Rangerville",
    "lng": -97.73728655420405,
    "lat": 26.10504930755285
  },
  {
    "name": "Ranier",
    "lng": -93.35336105929662,
    "lat": 48.60647177746196
  },
  {
    "name": "Rankin",
    "lng": -101.93942371302226,
    "lat": 31.225356605243736
  },
  {
    "name": "Rankin",
    "lng": -87.8959340017435,
    "lat": 40.46433497930974
  },
  {
    "name": "Ranlo",
    "lng": -81.13006731319243,
    "lat": 35.28850515386017
  },
  {
    "name": "Ranlo",
    "lng": -81.11950060174269,
    "lat": 35.315725009683796
  },
  {
    "name": "Ransom",
    "lng": -99.93272970504734,
    "lat": 38.63616173732469
  },
  {
    "name": "Ransom",
    "lng": -99.92708649449305,
    "lat": 38.63904605047127
  },
  {
    "name": "Ransom",
    "lng": -88.65417825115199,
    "lat": 41.15854960532823
  },
  {
    "name": "Ransom Canyon",
    "lng": -101.68386507335758,
    "lat": 33.53035558988001
  },
  {
    "name": "Ranson",
    "lng": -77.88173919775596,
    "lat": 39.3707284021506
  },
  {
    "name": "Ranson",
    "lng": -77.87476908813514,
    "lat": 39.37509461365507
  },
  {
    "name": "Ranson",
    "lng": -77.86547390569115,
    "lat": 39.31860332592818
  },
  {
    "name": "Ranson",
    "lng": -77.83753157740252,
    "lat": 39.3205955016707
  },
  {
    "name": "Rantoul",
    "lng": -95.10102057245135,
    "lat": 38.5480371009708
  },
  {
    "name": "Rantoul",
    "lng": -88.15497510589687,
    "lat": 40.30306452668997
  },
  {
    "name": "Rapid Valley",
    "lng": -103.17486166731992,
    "lat": 44.06086219290142
  },
  {
    "name": "Rapid Valley",
    "lng": -103.15039193709859,
    "lat": 44.07375230992517
  },
  {
    "name": "Rapid Valley",
    "lng": -103.13607744268106,
    "lat": 44.091171573164765
  },
  {
    "name": "Rapid Valley",
    "lng": -103.12057057882826,
    "lat": 44.0677638231708
  },
  {
    "name": "Rapids City",
    "lng": -90.33937887865625,
    "lat": 41.57874610360539
  },
  {
    "name": "Rarden",
    "lng": -83.24241322575737,
    "lat": 38.92331518143375
  },
  {
    "name": "Raritan",
    "lng": -90.82504660765723,
    "lat": 40.69583383025555
  },
  {
    "name": "Ratcliff",
    "lng": -93.88860417639584,
    "lat": 35.306582516174984
  },
  {
    "name": "Rathbun",
    "lng": -92.88803331846978,
    "lat": 40.80191482248266
  },
  {
    "name": "Rathdrum",
    "lng": -116.89490373118068,
    "lat": 47.79445757245175
  },
  {
    "name": "Ratliff City",
    "lng": -97.51555080928833,
    "lat": 34.44915401817174
  },
  {
    "name": "Raton",
    "lng": -104.44019264186699,
    "lat": 36.8852663298486
  },
  {
    "name": "Rattan",
    "lng": -95.41311877438882,
    "lat": 34.200375183137616
  },
  {
    "name": "Ravena",
    "lng": -73.81143701871476,
    "lat": 42.47591336109546
  },
  {
    "name": "Ravenden",
    "lng": -91.25110875121752,
    "lat": 36.241703182334454
  },
  {
    "name": "Ravenden Springs",
    "lng": -91.22326467996317,
    "lat": 36.31270622871974
  },
  {
    "name": "Ravenel",
    "lng": -80.23080555351659,
    "lat": 32.775299060222046
  },
  {
    "name": "Ravenel",
    "lng": -80.16352131971753,
    "lat": 32.78088987765006
  },
  {
    "name": "Ravenna",
    "lng": -98.9138320816878,
    "lat": 41.027277030330225
  },
  {
    "name": "Ravenna",
    "lng": -85.94182293469582,
    "lat": 43.188783180415975
  },
  {
    "name": "Ravenna",
    "lng": -83.9495841662601,
    "lat": 37.68764296209623
  },
  {
    "name": "Ravenna",
    "lng": -81.24188633329274,
    "lat": 41.161423635642
  },
  {
    "name": "Ravenswood",
    "lng": -81.76188228431282,
    "lat": 38.955990482945595
  },
  {
    "name": "Ravenwood",
    "lng": -94.67166435797785,
    "lat": 40.352611558661344
  },
  {
    "name": "Ravia",
    "lng": -96.76080485460584,
    "lat": 34.245873684116944
  },
  {
    "name": "Ravia",
    "lng": -96.75639650217884,
    "lat": 34.24048294112738
  },
  {
    "name": "Ravinia",
    "lng": -98.42683217489063,
    "lat": 43.13648996452446
  },
  {
    "name": "Rawlins",
    "lng": -107.22938233806576,
    "lat": 41.78438080011069
  },
  {
    "name": "Rawlins",
    "lng": -107.18561751754476,
    "lat": 41.79191010073412
  },
  {
    "name": "Rawson",
    "lng": -83.78494141364338,
    "lat": 40.956932915356155
  },
  {
    "name": "Ray",
    "lng": -103.16316202813768,
    "lat": 48.342384092827665
  },
  {
    "name": "Ray City",
    "lng": -83.19738099951833,
    "lat": 31.075443500953998
  },
  {
    "name": "Rayland",
    "lng": -80.69014367335878,
    "lat": 40.18455862944051
  },
  {
    "name": "Raymer",
    "lng": -103.84371960825378,
    "lat": 40.60756369586989
  },
  {
    "name": "Raymond",
    "lng": -123.75130329023558,
    "lat": 46.71311507261757
  },
  {
    "name": "Raymond",
    "lng": -123.73694776878362,
    "lat": 46.68247211920679
  },
  {
    "name": "Raymond",
    "lng": -98.41450558634068,
    "lat": 38.27800705934249
  },
  {
    "name": "Raymond",
    "lng": -97.93725808276434,
    "lat": 44.910547485295155
  },
  {
    "name": "Raymond",
    "lng": -96.78145679399113,
    "lat": 40.956823435915084
  },
  {
    "name": "Raymond",
    "lng": -95.24528714571107,
    "lat": 45.02572321398546
  },
  {
    "name": "Raymond",
    "lng": -95.2437202617562,
    "lat": 45.01936862806456
  },
  {
    "name": "Raymond",
    "lng": -95.24323066020878,
    "lat": 45.022123678663384
  },
  {
    "name": "Raymond",
    "lng": -95.23586504659465,
    "lat": 45.01606574744538
  },
  {
    "name": "Raymond",
    "lng": -92.22949697747794,
    "lat": 42.46937386472816
  },
  {
    "name": "Raymond",
    "lng": -90.40827548119401,
    "lat": 32.26091107102549
  },
  {
    "name": "Raymond",
    "lng": -89.5939643047738,
    "lat": 39.3191798075205
  },
  {
    "name": "Raymond",
    "lng": -89.57485546114138,
    "lat": 39.319866830916155
  },
  {
    "name": "Raymond",
    "lng": -70.45892735652598,
    "lat": 43.926707068964646
  },
  {
    "name": "Raymondville",
    "lng": -97.77692488888785,
    "lat": 26.47589902473963
  },
  {
    "name": "Raymondville",
    "lng": -91.83660039011318,
    "lat": 37.339782437418165
  },
  {
    "name": "Raymore",
    "lng": -94.4584373464176,
    "lat": 38.802802677141145
  },
  {
    "name": "Rayne",
    "lng": -92.2669224456201,
    "lat": 30.240313579212064
  },
  {
    "name": "Raynham",
    "lng": -79.19085653226615,
    "lat": 34.576090110140235
  },
  {
    "name": "Raytown",
    "lng": -94.46409468925239,
    "lat": 38.99435985052725
  },
  {
    "name": "Rayville",
    "lng": -94.06472835919804,
    "lat": 39.34776683365536
  },
  {
    "name": "Rayville",
    "lng": -91.77493505872351,
    "lat": 32.473139357171824
  },
  {
    "name": "Rayville",
    "lng": -91.76856061886102,
    "lat": 32.478823804273894
  },
  {
    "name": "Rayville",
    "lng": -91.7630863868003,
    "lat": 32.459990552612865
  },
  {
    "name": "Rayville",
    "lng": -91.75623255306361,
    "lat": 32.47051391420744
  },
  {
    "name": "Raywick",
    "lng": -85.43142529871426,
    "lat": 37.560253996725514
  },
  {
    "name": "Rea",
    "lng": -94.7645292614501,
    "lat": 40.061635322621065
  },
  {
    "name": "Readfield",
    "lng": -69.94908104557047,
    "lat": 44.38222305490715
  },
  {
    "name": "Reading",
    "lng": -95.9574752303264,
    "lat": 38.51914240077284
  },
  {
    "name": "Reading",
    "lng": -84.7478301434402,
    "lat": 41.83958372880528
  },
  {
    "name": "Reading",
    "lng": -84.43337218827115,
    "lat": 39.22412930838329
  },
  {
    "name": "Reading",
    "lng": -75.92660346306938,
    "lat": 40.339941219959584
  },
  {
    "name": "Reading",
    "lng": -71.10557362855381,
    "lat": 42.53505935380315
  },
  {
    "name": "Readlyn",
    "lng": -92.2250044325975,
    "lat": 42.7036027239922
  },
  {
    "name": "Readstown",
    "lng": -90.75891652503573,
    "lat": 43.44837321626145
  },
  {
    "name": "Reardan",
    "lng": -117.87806639712102,
    "lat": 47.669797101759436
  },
  {
    "name": "Reasnor",
    "lng": -93.02257711849833,
    "lat": 41.5788148024155
  },
  {
    "name": "Rebecca",
    "lng": -83.4866982330076,
    "lat": 31.806889073316768
  },
  {
    "name": "Rector",
    "lng": -90.29351663499217,
    "lat": 36.26415167927986
  },
  {
    "name": "Red Bay",
    "lng": -88.15541992975398,
    "lat": 34.41028163577732
  },
  {
    "name": "Red Bay",
    "lng": -88.13522619157375,
    "lat": 34.436926315129625
  },
  {
    "name": "Red Bluff",
    "lng": -122.24108441857946,
    "lat": 40.1735771717371
  },
  {
    "name": "Red Boiling Springs",
    "lng": -85.84809494233724,
    "lat": 36.52990578061575
  },
  {
    "name": "Red Bud",
    "lng": -89.99978001973946,
    "lat": 38.20959925785485
  },
  {
    "name": "Red Bud",
    "lng": -89.97747491956153,
    "lat": 38.21106900283696
  },
  {
    "name": "Red Cliff",
    "lng": -106.37002790928376,
    "lat": 39.5091420221912
  },
  {
    "name": "Red Cloud",
    "lng": -98.52223591910156,
    "lat": 40.08632143930284
  },
  {
    "name": "Red Creek",
    "lng": -76.7225214390488,
    "lat": 43.248784969830645
  },
  {
    "name": "Red Cross",
    "lng": -80.362100071082,
    "lat": 35.266359076571966
  },
  {
    "name": "Red Hook",
    "lng": -73.8769164847651,
    "lat": 41.996070392868354
  },
  {
    "name": "Red Lake Falls",
    "lng": -96.27273277693752,
    "lat": 47.88362759964554
  },
  {
    "name": "Red Lake Falls",
    "lng": -96.26532163866938,
    "lat": 47.900067285739034
  },
  {
    "name": "Red Lick",
    "lng": -94.16866771368933,
    "lat": 33.4766757154704
  },
  {
    "name": "Red Lion",
    "lng": -76.6221712314564,
    "lat": 39.895769520340835
  },
  {
    "name": "Red Lion",
    "lng": -76.60773458381618,
    "lat": 39.89859824290077
  },
  {
    "name": "Red Lodge",
    "lng": -109.25029786801981,
    "lat": 45.194248131639
  },
  {
    "name": "Red Oak",
    "lng": -96.78694155556732,
    "lat": 32.5213496889355
  },
  {
    "name": "Red Oak",
    "lng": -96.78011628801579,
    "lat": 32.546613133927835
  },
  {
    "name": "Red Oak",
    "lng": -96.77024549126233,
    "lat": 32.54567488634363
  },
  {
    "name": "Red Oak",
    "lng": -96.72433450878907,
    "lat": 32.48760991861807
  },
  {
    "name": "Red Oak",
    "lng": -95.22482975343462,
    "lat": 41.01418882960446
  },
  {
    "name": "Red Oak",
    "lng": -95.08150113713499,
    "lat": 34.95088186513556
  },
  {
    "name": "Red Oak",
    "lng": -77.90643793691554,
    "lat": 36.04155510476852
  },
  {
    "name": "Red River",
    "lng": -105.4065679359133,
    "lat": 36.70413747954773
  },
  {
    "name": "Red Rock",
    "lng": -97.17962346574053,
    "lat": 36.46004583910113
  },
  {
    "name": "Red Springs",
    "lng": -79.1829972467797,
    "lat": 34.815582416818486
  },
  {
    "name": "Red Wing",
    "lng": -92.60309147730608,
    "lat": 44.58145686646601
  },
  {
    "name": "Redbird",
    "lng": -95.58723092157808,
    "lat": 35.88713046719854
  },
  {
    "name": "Reddick",
    "lng": -88.24854526983451,
    "lat": 41.09839966596231
  },
  {
    "name": "Redding",
    "lng": -122.38613180536508,
    "lat": 40.502970405248185
  },
  {
    "name": "Redding",
    "lng": -122.3663311696907,
    "lat": 40.57097726367958
  },
  {
    "name": "Redding",
    "lng": -122.2831249758868,
    "lat": 40.47378791774814
  },
  {
    "name": "Redding",
    "lng": -94.3862423495605,
    "lat": 40.6068085359581
  },
  {
    "name": "Redding",
    "lng": -73.39185748182385,
    "lat": 41.30503017460175
  },
  {
    "name": "Redfield",
    "lng": -98.52860478967318,
    "lat": 44.8606696270512
  },
  {
    "name": "Redfield",
    "lng": -98.5163511274975,
    "lat": 44.875163070087076
  },
  {
    "name": "Redfield",
    "lng": -94.88143249251904,
    "lat": 37.83647380658821
  },
  {
    "name": "Redfield",
    "lng": -94.19615923737108,
    "lat": 41.59045617649775
  },
  {
    "name": "Redfield",
    "lng": -92.1843442215824,
    "lat": 34.44289507044114
  },
  {
    "name": "Redgranite",
    "lng": -89.1052866794644,
    "lat": 44.05105917887924
  },
  {
    "name": "Redings Mill",
    "lng": -94.51668157178871,
    "lat": 37.01929781586124
  },
  {
    "name": "Redington Beach",
    "lng": -82.81267811190665,
    "lat": 27.809821042376658
  },
  {
    "name": "Redington Shores",
    "lng": -82.83281834361131,
    "lat": 27.82967363483635
  },
  {
    "name": "Redkey",
    "lng": -85.15239886869786,
    "lat": 40.3480758218704
  },
  {
    "name": "Redlands",
    "lng": -117.24877913871009,
    "lat": 34.069704535802096
  },
  {
    "name": "Redlands",
    "lng": -117.17092645471371,
    "lat": 34.0512174954624
  },
  {
    "name": "Redlands",
    "lng": -117.12513591134211,
    "lat": 34.07676529042881
  },
  {
    "name": "Redlands",
    "lng": -117.12343008950258,
    "lat": 34.045772889241775
  },
  {
    "name": "Redlands",
    "lng": -117.12098108717598,
    "lat": 34.07154618050305
  },
  {
    "name": "Redlands",
    "lng": -117.09950522290474,
    "lat": 34.07071638664861
  },
  {
    "name": "Redlands",
    "lng": -117.09341699999999,
    "lat": 34.07266199997303
  },
  {
    "name": "Redmon",
    "lng": -87.86145043220763,
    "lat": 39.64483063810026
  },
  {
    "name": "Redmond",
    "lng": -122.12410343170993,
    "lat": 47.67510524485805
  },
  {
    "name": "Redmond",
    "lng": -122.0813198004368,
    "lat": 47.69531583870335
  },
  {
    "name": "Redmond",
    "lng": -122.04736586243637,
    "lat": 47.707168743601514
  },
  {
    "name": "Redmond",
    "lng": -111.86695987424234,
    "lat": 39.005667135595516
  },
  {
    "name": "Redondo Beach",
    "lng": -118.4025363183519,
    "lat": 33.84208901671156
  },
  {
    "name": "Redwater",
    "lng": -94.25511064458425,
    "lat": 33.35856118385699
  },
  {
    "name": "Redwood Falls",
    "lng": -95.10309787465272,
    "lat": 44.54703168330343
  },
  {
    "name": "Redwood Falls",
    "lng": -95.09933709696787,
    "lat": 44.52816637319513
  },
  {
    "name": "Redwood Falls",
    "lng": -95.0817293406585,
    "lat": 44.55380165025036
  },
  {
    "name": "Redwood Valley",
    "lng": -123.20237387491736,
    "lat": 39.26891835923497
  },
  {
    "name": "Ree Heights",
    "lng": -99.20052500845867,
    "lat": 44.51582330085194
  },
  {
    "name": "Reece City",
    "lng": -86.0344543079003,
    "lat": 34.077316246486355
  },
  {
    "name": "Reed City",
    "lng": -85.50677955981116,
    "lat": 43.87228131123984
  },
  {
    "name": "Reed Point",
    "lng": -109.54729685191437,
    "lat": 45.70708607827475
  },
  {
    "name": "Reeder",
    "lng": -102.94254157269388,
    "lat": 46.107054003272324
  },
  {
    "name": "Reedley",
    "lng": -119.45077701924542,
    "lat": 36.66949074203355
  },
  {
    "name": "Reedley",
    "lng": -119.44829448791187,
    "lat": 36.59706898636986
  },
  {
    "name": "Reeds",
    "lng": -94.16824218765822,
    "lat": 37.11646999923341
  },
  {
    "name": "Reeds Spring",
    "lng": -93.37945479663725,
    "lat": 36.74045397814369
  },
  {
    "name": "Reedsburg",
    "lng": -89.99751851288305,
    "lat": 43.53442828958061
  },
  {
    "name": "Reedsville",
    "lng": -87.95168014316792,
    "lat": 44.152468648395825
  },
  {
    "name": "Reedsville",
    "lng": -79.80055937113919,
    "lat": 39.510288364712245
  },
  {
    "name": "Reedsville",
    "lng": -77.61139400875949,
    "lat": 40.649823137232644
  },
  {
    "name": "Reedy",
    "lng": -81.42634712450828,
    "lat": 38.89969167332062
  },
  {
    "name": "Reese",
    "lng": -83.69953877762795,
    "lat": 43.46028834862478
  },
  {
    "name": "Reese",
    "lng": -83.69845567902213,
    "lat": 43.46299114717069
  },
  {
    "name": "Reese",
    "lng": -83.69614633064171,
    "lat": 43.465367184940426
  },
  {
    "name": "Reese",
    "lng": -83.68931450766947,
    "lat": 43.45269583738102
  },
  {
    "name": "Reese",
    "lng": -83.68003356351159,
    "lat": 43.46326109866365
  },
  {
    "name": "Reeseville",
    "lng": -88.84454287439902,
    "lat": 43.30615474858204
  },
  {
    "name": "Reeves",
    "lng": -93.04281242679716,
    "lat": 30.521546091873244
  },
  {
    "name": "Reevesville",
    "lng": -80.64543474740339,
    "lat": 33.20172903444079
  },
  {
    "name": "Refugio",
    "lng": -97.27498746189795,
    "lat": 28.307455555019857
  },
  {
    "name": "Regal",
    "lng": -94.83962124474196,
    "lat": 45.4053874957037
  },
  {
    "name": "Regan",
    "lng": -100.52725958922714,
    "lat": 47.15676173166883
  },
  {
    "name": "Regent",
    "lng": -102.55824537396403,
    "lat": 46.422192052614136
  },
  {
    "name": "Register",
    "lng": -81.8848088024815,
    "lat": 32.365925731934745
  },
  {
    "name": "Rehobeth",
    "lng": -85.4390190263271,
    "lat": 31.1239770557743
  },
  {
    "name": "Rehobeth",
    "lng": -85.42907713149927,
    "lat": 31.154718409061424
  },
  {
    "name": "Rehobeth",
    "lng": -85.41838823303057,
    "lat": 31.162205377036546
  },
  {
    "name": "Rehobeth",
    "lng": -85.41574617209146,
    "lat": 31.166793797781214
  },
  {
    "name": "Reidsville",
    "lng": -82.15109406033041,
    "lat": 32.05906836185115
  },
  {
    "name": "Reidsville",
    "lng": -82.14963076241553,
    "lat": 32.106402979939006
  },
  {
    "name": "Reidsville",
    "lng": -82.1184938486691,
    "lat": 32.08587037268385
  },
  {
    "name": "Reidsville",
    "lng": -79.69760292541638,
    "lat": 36.30148573759426
  },
  {
    "name": "Reidsville",
    "lng": -79.67110814342492,
    "lat": 36.39287236047167
  },
  {
    "name": "Reidsville",
    "lng": -79.66866424891411,
    "lat": 36.342267652883194
  },
  {
    "name": "Reidsville",
    "lng": -79.65870306728151,
    "lat": 36.380742785920155
  },
  {
    "name": "Reidsville",
    "lng": -79.65379947399613,
    "lat": 36.370165302551584
  },
  {
    "name": "Reidsville",
    "lng": -79.65360207775265,
    "lat": 36.3705778080291
  },
  {
    "name": "Reidsville",
    "lng": -79.6530548579312,
    "lat": 36.37307744207917
  },
  {
    "name": "Reidsville",
    "lng": -79.65215129790063,
    "lat": 36.38094624355167
  },
  {
    "name": "Reidsville",
    "lng": -79.6520724412044,
    "lat": 36.37062166424927
  },
  {
    "name": "Reidsville",
    "lng": -79.65151714965478,
    "lat": 36.34551993077824
  },
  {
    "name": "Reidsville",
    "lng": -79.65135430268543,
    "lat": 36.33893070500366
  },
  {
    "name": "Reidsville",
    "lng": -79.65081498793064,
    "lat": 36.391955162874794
  },
  {
    "name": "Reidsville",
    "lng": -79.64679335815048,
    "lat": 36.34849756616417
  },
  {
    "name": "Reidsville",
    "lng": -79.64660267666008,
    "lat": 36.38714974233224
  },
  {
    "name": "Reidsville",
    "lng": -79.61849081393791,
    "lat": 36.32015540991539
  },
  {
    "name": "Reidsville",
    "lng": -79.61734146754812,
    "lat": 36.32055721533767
  },
  {
    "name": "Reidville",
    "lng": -82.10385898352898,
    "lat": 34.8647360323109
  },
  {
    "name": "Reiffton",
    "lng": -75.86355577061796,
    "lat": 40.3127453195534
  },
  {
    "name": "Reile\"s Acres",
    "lng": -96.86648464560538,
    "lat": 46.926913383904285
  },
  {
    "name": "Reinbeck",
    "lng": -92.59426278553072,
    "lat": 42.32249447457105
  },
  {
    "name": "Reklaw",
    "lng": -94.98335911999311,
    "lat": 31.866436051321426
  },
  {
    "name": "Reliance",
    "lng": -99.60173446840504,
    "lat": 43.87999239132176
  },
  {
    "name": "Rembrandt",
    "lng": -95.16563101176364,
    "lat": 42.825713351109776
  },
  {
    "name": "Remer",
    "lng": -93.91358798147208,
    "lat": 47.05763995244174
  },
  {
    "name": "Remer",
    "lng": -93.89847561021311,
    "lat": 47.0464191343523
  },
  {
    "name": "Remerton",
    "lng": -83.30846423519522,
    "lat": 30.84413649809317
  },
  {
    "name": "Reminderville",
    "lng": -81.40188600135332,
    "lat": 41.33380867863173
  },
  {
    "name": "Remington",
    "lng": -87.15243314712097,
    "lat": 40.76538597724669
  },
  {
    "name": "Remington",
    "lng": -77.80823772614966,
    "lat": 38.53465257664498
  },
  {
    "name": "Remsen",
    "lng": -95.97276928554992,
    "lat": 42.81590474184685
  },
  {
    "name": "Remsen",
    "lng": -75.18694697422228,
    "lat": 43.32823252213231
  },
  {
    "name": "Rendville",
    "lng": -82.08995206307745,
    "lat": 39.619922173263916
  },
  {
    "name": "Renfrow",
    "lng": -97.65705384489426,
    "lat": 36.924839290645224
  },
  {
    "name": "Renick",
    "lng": -92.41091622617525,
    "lat": 39.341928521686725
  },
  {
    "name": "Rennert",
    "lng": -79.0790978120461,
    "lat": 34.81411304277077
  },
  {
    "name": "Reno",
    "lng": -119.99817700971762,
    "lat": 39.520863845845476
  },
  {
    "name": "Reno",
    "lng": -119.98039839711682,
    "lat": 39.502488072267624
  },
  {
    "name": "Reno",
    "lng": -119.97608432917615,
    "lat": 39.64850924891228
  },
  {
    "name": "Reno",
    "lng": -119.93748925931119,
    "lat": 39.709952067583096
  },
  {
    "name": "Reno",
    "lng": -119.93502192146124,
    "lat": 39.671530681501004
  },
  {
    "name": "Reno",
    "lng": -119.85478140359946,
    "lat": 39.60316887517806
  },
  {
    "name": "Reno",
    "lng": -119.83314175047987,
    "lat": 39.538004064155466
  },
  {
    "name": "Reno",
    "lng": -97.5766538350991,
    "lat": 32.94480379208212
  },
  {
    "name": "Reno",
    "lng": -95.47684001800283,
    "lat": 33.66586175804004
  },
  {
    "name": "Reno",
    "lng": -95.47301369509569,
    "lat": 33.68032998471821
  },
  {
    "name": "Renova",
    "lng": -90.72316658600215,
    "lat": 33.780403103158534
  },
  {
    "name": "Rensselaer",
    "lng": -91.53870123891383,
    "lat": 39.66923050765935
  },
  {
    "name": "Rensselaer",
    "lng": -87.17959115460243,
    "lat": 40.923872737755325
  },
  {
    "name": "Rensselaer",
    "lng": -87.16606372863095,
    "lat": 40.934492124476414
  },
  {
    "name": "Rensselaer",
    "lng": -87.14993944798951,
    "lat": 40.93395807615696
  },
  {
    "name": "Rensselaer",
    "lng": -73.732849642367,
    "lat": 42.646458635517604
  },
  {
    "name": "Rensselaer Falls",
    "lng": -75.31894928699475,
    "lat": 44.59090649224533
  },
  {
    "name": "Rentiesville",
    "lng": -95.48511392567737,
    "lat": 35.532878275643355
  },
  {
    "name": "Rentiesville",
    "lng": -95.47083200000002,
    "lat": 35.54848351950678
  },
  {
    "name": "Renton",
    "lng": -122.23084710407772,
    "lat": 47.489905166177266
  },
  {
    "name": "Renton",
    "lng": -122.19058044671469,
    "lat": 47.47610052814265
  },
  {
    "name": "Rentz",
    "lng": -82.99165403880671,
    "lat": 32.383662092915706
  },
  {
    "name": "Renville",
    "lng": -95.20482057146401,
    "lat": 44.7907476315168
  },
  {
    "name": "Renwick",
    "lng": -93.98133472418431,
    "lat": 42.826928409238405
  },
  {
    "name": "Repton",
    "lng": -87.24052151225715,
    "lat": 31.408304381467616
  },
  {
    "name": "Republic",
    "lng": -118.73295636483334,
    "lat": 48.64822624689934
  },
  {
    "name": "Republic",
    "lng": -97.82473779713241,
    "lat": 39.9240281391872
  },
  {
    "name": "Republic",
    "lng": -93.44473672850303,
    "lat": 37.145256989586336
  },
  {
    "name": "Republic",
    "lng": -83.01665604549729,
    "lat": 41.1250147655241
  },
  {
    "name": "Republican City",
    "lng": -99.222253587774,
    "lat": 40.098748013147
  },
  {
    "name": "Resaca",
    "lng": -84.94435569079813,
    "lat": 34.57893302048143
  },
  {
    "name": "Reserve",
    "lng": -108.76142906429996,
    "lat": 33.70900056993008
  },
  {
    "name": "Reserve",
    "lng": -95.5651721942798,
    "lat": 39.97700616215668
  },
  {
    "name": "Reserve at Cypress Creek",
    "lng": -95.65432673962415,
    "lat": 29.996938510004135
  },
  {
    "name": "Retreat",
    "lng": -96.47868453773803,
    "lat": 32.05067738857031
  },
  {
    "name": "Reubens",
    "lng": -116.54286059545053,
    "lat": 46.32292352698088
  },
  {
    "name": "Revere",
    "lng": -95.36121332581828,
    "lat": 44.22159013198361
  },
  {
    "name": "Revere",
    "lng": -91.67629883383373,
    "lat": 40.49437027560501
  },
  {
    "name": "Revillo",
    "lng": -96.5711041724907,
    "lat": 45.015314984379316
  },
  {
    "name": "Rewey",
    "lng": -90.39621724008435,
    "lat": 42.84166760111388
  },
  {
    "name": "Rexburg",
    "lng": -111.79237609452596,
    "lat": 43.82225495290428
  },
  {
    "name": "Rexford",
    "lng": -115.17146637752569,
    "lat": 48.8999576413891
  },
  {
    "name": "Rexford",
    "lng": -100.74392447975777,
    "lat": 39.4704576872147
  },
  {
    "name": "Reydon",
    "lng": -99.92370221534895,
    "lat": 35.65022675535759
  },
  {
    "name": "Reyno",
    "lng": -90.75910304113236,
    "lat": 36.36086813982922
  },
  {
    "name": "Reynolds",
    "lng": -97.33594354932232,
    "lat": 40.05980990851112
  },
  {
    "name": "Reynolds",
    "lng": -97.10888441675483,
    "lat": 47.6701160186282
  },
  {
    "name": "Reynolds",
    "lng": -90.67214521492068,
    "lat": 41.33190146375258
  },
  {
    "name": "Reynolds",
    "lng": -86.87386927054274,
    "lat": 40.749143923381325
  },
  {
    "name": "Reynolds",
    "lng": -84.09448357034103,
    "lat": 32.560801559605515
  },
  {
    "name": "Reynoldsburg",
    "lng": -82.7940947476803,
    "lat": 39.95868157346201
  },
  {
    "name": "Rhame",
    "lng": -103.65516292001111,
    "lat": 46.23461292111245
  },
  {
    "name": "Rhine",
    "lng": -83.19999211438676,
    "lat": 31.98908514321295
  },
  {
    "name": "Rhinebeck",
    "lng": -73.90872277502285,
    "lat": 41.92965374878455
  },
  {
    "name": "Rhineland",
    "lng": -91.51759493972261,
    "lat": 38.72047597345994
  },
  {
    "name": "Rhinelander",
    "lng": -89.48907010175496,
    "lat": 45.61199327326818
  },
  {
    "name": "Rhinelander",
    "lng": -89.45620649405014,
    "lat": 45.62132829902744
  },
  {
    "name": "Rhinelander",
    "lng": -89.45285481253013,
    "lat": 45.598340769763574
  },
  {
    "name": "Rhinelander",
    "lng": -89.44894723788497,
    "lat": 45.622418101956974
  },
  {
    "name": "Rhinelander",
    "lng": -89.4228763225351,
    "lat": 45.63802620901857
  },
  {
    "name": "Rhinelander",
    "lng": -89.408499839666,
    "lat": 45.626393295213965
  },
  {
    "name": "Rhinelander",
    "lng": -89.40580461822162,
    "lat": 45.62402724824914
  },
  {
    "name": "Rhinelander",
    "lng": -89.3790128585524,
    "lat": 45.64080833004061
  },
  {
    "name": "Rhode Island",
    "lng": -71.57493365681455,
    "lat": 41.188176707570754
  },
  {
    "name": "Rhode Island",
    "lng": -71.52096401037515,
    "lat": 41.62637993266556
  },
  {
    "name": "Rhodell",
    "lng": -81.30505042034676,
    "lat": 37.609516758945546
  },
  {
    "name": "Rhodes",
    "lng": -93.18379122855528,
    "lat": 41.92779463298704
  },
  {
    "name": "Rhodhiss",
    "lng": -81.4313344897475,
    "lat": 35.76895243384216
  },
  {
    "name": "Rhodhiss",
    "lng": -81.4282991929033,
    "lat": 35.750910138743734
  },
  {
    "name": "Rhome",
    "lng": -97.48513753440541,
    "lat": 33.0752542757767
  },
  {
    "name": "Rib Lake",
    "lng": -90.20299190487474,
    "lat": 45.31951721686863
  },
  {
    "name": "Rib Mountain",
    "lng": -89.67705877413597,
    "lat": 44.92047296054254
  },
  {
    "name": "Rice",
    "lng": -96.49782440929243,
    "lat": 32.23880745053752
  },
  {
    "name": "Rice",
    "lng": -94.23176827021527,
    "lat": 45.75252709843643
  },
  {
    "name": "Rice Lake",
    "lng": -91.77257430376264,
    "lat": 45.42128828778473
  },
  {
    "name": "Rice Lake",
    "lng": -91.7405102205656,
    "lat": 45.496307500435336
  },
  {
    "name": "Riceboro",
    "lng": -81.44378437229433,
    "lat": 31.726434051899727
  },
  {
    "name": "Riceville",
    "lng": -92.55401446731337,
    "lat": 43.36199075059554
  },
  {
    "name": "Rich Creek",
    "lng": -80.82064304287229,
    "lat": 37.38337896683259
  },
  {
    "name": "Rich Hill",
    "lng": -94.36332209486386,
    "lat": 38.09589310609032
  },
  {
    "name": "Rich Square",
    "lng": -77.28390643768205,
    "lat": 36.27394332792376
  },
  {
    "name": "Richards",
    "lng": -94.55730810895149,
    "lat": 37.91042010016893
  },
  {
    "name": "Richardton",
    "lng": -102.3156740614879,
    "lat": 46.88436708731422
  },
  {
    "name": "Richburg",
    "lng": -81.01936925720881,
    "lat": 34.71621460463398
  },
  {
    "name": "Richburg",
    "lng": -78.15651151915478,
    "lat": 42.088765464600066
  },
  {
    "name": "Richey",
    "lng": -105.06903118727764,
    "lat": 47.64431701040594
  },
  {
    "name": "Richfield",
    "lng": -114.15568387982513,
    "lat": 43.05209299372364
  },
  {
    "name": "Richfield",
    "lng": -112.0925479998571,
    "lat": 38.76281891683818
  },
  {
    "name": "Richfield",
    "lng": -101.7827820537124,
    "lat": 37.26527070484525
  },
  {
    "name": "Richfield",
    "lng": -93.28267436745395,
    "lat": 44.87623681483825
  },
  {
    "name": "Richfield",
    "lng": -81.64135735042451,
    "lat": 41.234490658522105
  },
  {
    "name": "Richfield",
    "lng": -80.25665677431182,
    "lat": 35.4707745784723
  },
  {
    "name": "Richfield Springs",
    "lng": -74.98609748465007,
    "lat": 42.853701643919656
  },
  {
    "name": "Richgrove",
    "lng": -119.1067412183977,
    "lat": 35.79656329016808
  },
  {
    "name": "Richland",
    "lng": -119.29375966054866,
    "lat": 46.28253163790622
  },
  {
    "name": "Richland",
    "lng": -97.2149766407197,
    "lat": 41.43688552508547
  },
  {
    "name": "Richland",
    "lng": -96.4246653053528,
    "lat": 31.91642143293211
  },
  {
    "name": "Richland",
    "lng": -92.39933634320843,
    "lat": 37.86098598544007
  },
  {
    "name": "Richland",
    "lng": -91.99360130738347,
    "lat": 41.18552499181094
  },
  {
    "name": "Richland",
    "lng": -85.45516811168405,
    "lat": 42.376251268575494
  },
  {
    "name": "Richland",
    "lng": -84.67595682702142,
    "lat": 32.08297874632795
  },
  {
    "name": "Richland Center",
    "lng": -90.38920070951575,
    "lat": 43.32338193972395
  },
  {
    "name": "Richland Center",
    "lng": -90.3835314511634,
    "lat": 43.338279332961164
  },
  {
    "name": "Richland Hills",
    "lng": -97.22721702379516,
    "lat": 32.80944030029894
  },
  {
    "name": "Richlands",
    "lng": -81.80809086950941,
    "lat": 37.08776647171763
  },
  {
    "name": "Richlands",
    "lng": -77.54908900953382,
    "lat": 34.88677266440811
  },
  {
    "name": "Richlands",
    "lng": -77.54263150131767,
    "lat": 34.90022029524675
  },
  {
    "name": "Richlawn",
    "lng": -85.64108636848674,
    "lat": 38.254755715877934
  },
  {
    "name": "Richmond",
    "lng": -122.36576278734704,
    "lat": 37.96114509154862
  },
  {
    "name": "Richmond",
    "lng": -122.36050922529797,
    "lat": 37.95199124253949
  },
  {
    "name": "Richmond",
    "lng": -111.80755923027695,
    "lat": 41.92304891761775
  },
  {
    "name": "Richmond",
    "lng": -95.76272237868191,
    "lat": 29.58193773990528
  },
  {
    "name": "Richmond",
    "lng": -95.69547983197668,
    "lat": 29.562381092671732
  },
  {
    "name": "Richmond",
    "lng": -95.25346840736174,
    "lat": 38.401267829688194
  },
  {
    "name": "Richmond",
    "lng": -94.53092926192961,
    "lat": 45.45261040867677
  },
  {
    "name": "Richmond",
    "lng": -94.51411773842395,
    "lat": 45.45501113626314
  },
  {
    "name": "Richmond",
    "lng": -94.51191412818835,
    "lat": 45.449239946587795
  },
  {
    "name": "Richmond",
    "lng": -94.50562079232952,
    "lat": 45.45088241358212
  },
  {
    "name": "Richmond",
    "lng": -93.97344224865034,
    "lat": 39.27631671813273
  },
  {
    "name": "Richmond",
    "lng": -91.18042714297455,
    "lat": 32.38840600630757
  },
  {
    "name": "Richmond",
    "lng": -88.32898702101909,
    "lat": 42.44933576669113
  },
  {
    "name": "Richmond",
    "lng": -88.30871383584524,
    "lat": 42.46384619139257
  },
  {
    "name": "Richmond",
    "lng": -84.89228173466685,
    "lat": 39.834711431599146
  },
  {
    "name": "Richmond",
    "lng": -84.87459980888727,
    "lat": 39.877762365371545
  },
  {
    "name": "Richmond",
    "lng": -84.84273516488567,
    "lat": 39.75596302945709
  },
  {
    "name": "Richmond",
    "lng": -84.29278045355457,
    "lat": 37.724018471849334
  },
  {
    "name": "Richmond",
    "lng": -82.75164816513426,
    "lat": 42.80934672565589
  },
  {
    "name": "Richmond",
    "lng": -80.77190328284964,
    "lat": 40.43282662301788
  },
  {
    "name": "Richmond",
    "lng": -77.47555684869386,
    "lat": 37.529548744193896
  },
  {
    "name": "Richmond",
    "lng": -73.35910981708159,
    "lat": 42.386814090712356
  },
  {
    "name": "Richmond",
    "lng": -71.66074635655256,
    "lat": 41.498286047011554
  },
  {
    "name": "Richmond",
    "lng": -69.82881604381642,
    "lat": 44.123684314150786
  },
  {
    "name": "Richmond Heights",
    "lng": -90.33311239811637,
    "lat": 38.63091685481057
  },
  {
    "name": "Richmond Heights",
    "lng": -81.50297007997524,
    "lat": 41.558884698560604
  },
  {
    "name": "Richmond Hill",
    "lng": -81.34991926046335,
    "lat": 31.86955647619515
  },
  {
    "name": "Richmond Hill",
    "lng": -81.338757575651,
    "lat": 31.87879391665469
  },
  {
    "name": "Richmond Hill",
    "lng": -81.3353048437614,
    "lat": 31.85569767483647
  },
  {
    "name": "Richmond Hill",
    "lng": -81.32964067095264,
    "lat": 31.86552816403313
  },
  {
    "name": "Richmond Hill",
    "lng": -81.30587583547816,
    "lat": 31.931817502355617
  },
  {
    "name": "Richmond Hill",
    "lng": -81.27020037915304,
    "lat": 31.90895902993985
  },
  {
    "name": "Richmondville",
    "lng": -74.5628208484587,
    "lat": 42.63345626827778
  },
  {
    "name": "Richton",
    "lng": -88.94073856299626,
    "lat": 31.349481159751456
  },
  {
    "name": "Richton Park",
    "lng": -87.7354505356164,
    "lat": 41.482156813815415
  },
  {
    "name": "Richview",
    "lng": -89.181325986664,
    "lat": 38.37457602289791
  },
  {
    "name": "Richview",
    "lng": -89.16684881763605,
    "lat": 38.39248858289251
  },
  {
    "name": "Richville",
    "lng": -95.62624132917388,
    "lat": 46.506023334419034
  },
  {
    "name": "Richville",
    "lng": -75.39235383347535,
    "lat": 44.415056395855366
  },
  {
    "name": "Richwood",
    "lng": -95.40645450986261,
    "lat": 29.077446830772473
  },
  {
    "name": "Richwood",
    "lng": -92.08926250046856,
    "lat": 32.46413682835187
  },
  {
    "name": "Richwood",
    "lng": -92.08872642146542,
    "lat": 32.46943247222906
  },
  {
    "name": "Richwood",
    "lng": -92.07732437198035,
    "lat": 32.44867024911871
  },
  {
    "name": "Richwood",
    "lng": -83.29551739086796,
    "lat": 40.42760456098315
  },
  {
    "name": "Richwood",
    "lng": -80.53628218486423,
    "lat": 38.222105601363175
  },
  {
    "name": "Rickardsville",
    "lng": -90.87633662951194,
    "lat": 42.58158988880253
  },
  {
    "name": "Ricketts",
    "lng": -95.57488797096204,
    "lat": 42.127932518342945
  },
  {
    "name": "Rico",
    "lng": -108.03143142511777,
    "lat": 37.6885665001636
  },
  {
    "name": "Ridge Farm",
    "lng": -87.6520191868909,
    "lat": 39.89567383110449
  },
  {
    "name": "Ridge Spring",
    "lng": -81.66169586508119,
    "lat": 33.84527305962134
  },
  {
    "name": "Ridgecrest",
    "lng": -117.66195838400212,
    "lat": 35.63087223893178
  },
  {
    "name": "Ridgecrest",
    "lng": -91.53042077624954,
    "lat": 31.601805434332846
  },
  {
    "name": "Ridgefield",
    "lng": -73.50241696704225,
    "lat": 41.306591374321634
  },
  {
    "name": "Ridgefield Park",
    "lng": -74.01989913145418,
    "lat": 40.854295840772316
  },
  {
    "name": "Ridgeland",
    "lng": -91.89675146490006,
    "lat": 45.20255182649557
  },
  {
    "name": "Ridgeland",
    "lng": -90.14493497335819,
    "lat": 32.42219283665972
  },
  {
    "name": "Ridgeland",
    "lng": -80.97748118816351,
    "lat": 32.48603823086741
  },
  {
    "name": "Ridgeley",
    "lng": -78.77125169805333,
    "lat": 39.64293663310189
  },
  {
    "name": "Ridgely",
    "lng": -94.64023667048201,
    "lat": 39.453663692519676
  },
  {
    "name": "Ridgely",
    "lng": -89.48098059133581,
    "lat": 36.26497232171431
  },
  {
    "name": "Ridgely",
    "lng": -75.88268725420487,
    "lat": 38.953001040376336
  },
  {
    "name": "Ridgeside",
    "lng": -85.24688871653001,
    "lat": 35.035101033119055
  },
  {
    "name": "Ridgetop",
    "lng": -86.76838078528873,
    "lat": 36.40381708432186
  },
  {
    "name": "Ridgeville",
    "lng": -86.10297059520244,
    "lat": 34.05729158074009
  },
  {
    "name": "Ridgeville",
    "lng": -85.02841162336271,
    "lat": 40.29202835640313
  },
  {
    "name": "Ridgeville",
    "lng": -80.30699665753686,
    "lat": 33.089945699957276
  },
  {
    "name": "Ridgeway",
    "lng": -93.93776606281776,
    "lat": 40.37820135104137
  },
  {
    "name": "Ridgeway",
    "lng": -91.99233758431191,
    "lat": 43.29867152857349
  },
  {
    "name": "Ridgeway",
    "lng": -89.99318598699054,
    "lat": 42.99853882967384
  },
  {
    "name": "Ridgeway",
    "lng": -83.56883628113611,
    "lat": 40.51238616521978
  },
  {
    "name": "Ridgeway",
    "lng": -80.96014203298364,
    "lat": 34.3065635867757
  },
  {
    "name": "Ridgeway",
    "lng": -79.85829899186082,
    "lat": 36.5781770232516
  },
  {
    "name": "Ridgewood",
    "lng": -74.11266279787678,
    "lat": 40.982117629593965
  },
  {
    "name": "Ridgway",
    "lng": -107.753087516615,
    "lat": 38.15912292483914
  },
  {
    "name": "Ridgway",
    "lng": -88.26059447044427,
    "lat": 37.797901609465306
  },
  {
    "name": "Ridley Park",
    "lng": -75.32513507714374,
    "lat": 39.87837511975226
  },
  {
    "name": "Ridott",
    "lng": -89.47711990802121,
    "lat": 42.29670878035131
  },
  {
    "name": "Rienzi",
    "lng": -88.53397404840084,
    "lat": 34.7633154985008
  },
  {
    "name": "Riesel",
    "lng": -96.9314502421732,
    "lat": 31.475612664936
  },
  {
    "name": "Rigby",
    "lng": -111.91256406257547,
    "lat": 43.67346891969687
  },
  {
    "name": "Riggins",
    "lng": -116.31790632866701,
    "lat": 45.42037299586882
  },
  {
    "name": "Riley",
    "lng": -96.83173113106805,
    "lat": 39.30513079593487
  },
  {
    "name": "Riley",
    "lng": -96.82760078875272,
    "lat": 39.29891316446503
  },
  {
    "name": "Riley",
    "lng": -87.30021226545162,
    "lat": 39.38976503796165
  },
  {
    "name": "Riley Township",
    "lng": -88.61728132588348,
    "lat": 42.19905371224905
  },
  {
    "name": "Rinard",
    "lng": -94.48491859000534,
    "lat": 42.33967772290826
  },
  {
    "name": "Rincon",
    "lng": -81.23612703215802,
    "lat": 32.29378527494772
  },
  {
    "name": "Ringgold",
    "lng": -93.28371093359145,
    "lat": 32.326035652354776
  },
  {
    "name": "Ringgold",
    "lng": -93.26867459845246,
    "lat": 32.32500055142236
  },
  {
    "name": "Ringgold",
    "lng": -85.18179931822914,
    "lat": 34.93115759700641
  },
  {
    "name": "Ringgold",
    "lng": -85.11945855993838,
    "lat": 34.91440338356283
  },
  {
    "name": "Ringling",
    "lng": -97.59136577924794,
    "lat": 34.17774668544825
  },
  {
    "name": "Ringsted",
    "lng": -94.50733844749142,
    "lat": 43.29511730207417
  },
  {
    "name": "Ringwood",
    "lng": -98.24110157529076,
    "lat": 36.3817522362456
  },
  {
    "name": "Ringwood",
    "lng": -88.30496713685417,
    "lat": 42.39572346836245
  },
  {
    "name": "Rio",
    "lng": -90.39883718134469,
    "lat": 41.10930583363833
  },
  {
    "name": "Rio",
    "lng": -89.23934416012833,
    "lat": 43.45015382988644
  },
  {
    "name": "Rio Bravo",
    "lng": -99.48202626768297,
    "lat": 27.364785293674405
  },
  {
    "name": "Rio Communities",
    "lng": -106.71649677448393,
    "lat": 34.64142347714661
  },
  {
    "name": "Rio Dell",
    "lng": -124.10800268010925,
    "lat": 40.50031303826456
  },
  {
    "name": "Rio Grande",
    "lng": -82.37830385022154,
    "lat": 38.8796493699703
  },
  {
    "name": "Rio Grande City",
    "lng": -98.82149013277689,
    "lat": 26.381007615194516
  },
  {
    "name": "Rio Hondo",
    "lng": -97.58163760313427,
    "lat": 26.234561499308942
  },
  {
    "name": "Rio Rancho",
    "lng": -106.6983407876294,
    "lat": 35.28737094369381
  },
  {
    "name": "Rio Vista",
    "lng": -121.7034885515687,
    "lat": 38.17669532711432
  },
  {
    "name": "Rio Vista",
    "lng": -97.37464646139361,
    "lat": 32.23528739462305
  },
  {
    "name": "Ripley",
    "lng": -96.90461747645259,
    "lat": 36.01681046327622
  },
  {
    "name": "Ripley",
    "lng": -90.63795664665346,
    "lat": 40.025187761149425
  },
  {
    "name": "Ripley",
    "lng": -89.53574294950853,
    "lat": 35.744864048834266
  },
  {
    "name": "Ripley",
    "lng": -88.94477049423384,
    "lat": 34.73198267972465
  },
  {
    "name": "Ripley",
    "lng": -83.83999090542625,
    "lat": 38.73848163417473
  },
  {
    "name": "Ripley",
    "lng": -81.70856480525175,
    "lat": 38.81986781040171
  },
  {
    "name": "Ripley",
    "lng": -69.39644193314194,
    "lat": 45.00735080526645
  },
  {
    "name": "Ripon",
    "lng": -88.83871039943939,
    "lat": 43.84352174706215
  },
  {
    "name": "Rippey",
    "lng": -94.20082469499063,
    "lat": 41.933964972640865
  },
  {
    "name": "Ririe",
    "lng": -111.77140922362115,
    "lat": 43.63237303602072
  },
  {
    "name": "Risco",
    "lng": -89.81858528803127,
    "lat": 36.55123195062295
  },
  {
    "name": "Rising City",
    "lng": -97.29734378851418,
    "lat": 41.198299294075504
  },
  {
    "name": "Rising Star",
    "lng": -98.96651971765735,
    "lat": 32.09763426857228
  },
  {
    "name": "Rising Sun",
    "lng": -84.85335859813509,
    "lat": 38.95356637424868
  },
  {
    "name": "Rising Sun",
    "lng": -76.07480279755715,
    "lat": 39.70375774835782
  },
  {
    "name": "Rising Sun",
    "lng": -76.06188011657039,
    "lat": 39.701434011717545
  },
  {
    "name": "Risingsun",
    "lng": -83.42690438710791,
    "lat": 41.26706357450333
  },
  {
    "name": "Ritchey",
    "lng": -94.18576822093618,
    "lat": 36.944011731384805
  },
  {
    "name": "Rittman",
    "lng": -81.7845514429108,
    "lat": 40.97373242032365
  },
  {
    "name": "Ritzville",
    "lng": -118.38183890056348,
    "lat": 47.12579933848755
  },
  {
    "name": "River Bend",
    "lng": -94.39411256161408,
    "lat": 39.18398751234275
  },
  {
    "name": "River Bend",
    "lng": -77.15067851090342,
    "lat": 35.072073669062476
  },
  {
    "name": "River Bluff",
    "lng": -85.60291201978616,
    "lat": 38.37191727512353
  },
  {
    "name": "River Falls",
    "lng": -92.64315016935531,
    "lat": 44.831818062798106
  },
  {
    "name": "River Falls",
    "lng": -92.62441916155886,
    "lat": 44.86134106005291
  },
  {
    "name": "River Forest",
    "lng": -87.8193905366945,
    "lat": 41.89497286666592
  },
  {
    "name": "River Forest",
    "lng": -85.7294551376487,
    "lat": 40.11059089522327
  },
  {
    "name": "River Grove",
    "lng": -87.8379276463413,
    "lat": 41.92428309568926
  },
  {
    "name": "River Heights",
    "lng": -111.81916933451431,
    "lat": 41.72214879082936
  },
  {
    "name": "River Hills",
    "lng": -87.93532444821989,
    "lat": 43.171769177659016
  },
  {
    "name": "River Oaks",
    "lng": -97.39843902849597,
    "lat": 32.776768018040556
  },
  {
    "name": "River Rouge",
    "lng": -83.12366699983095,
    "lat": 42.2732005315378
  },
  {
    "name": "Riverbank",
    "lng": -120.943093811074,
    "lat": 37.72690882124641
  },
  {
    "name": "Riverbend",
    "lng": -121.7490661914179,
    "lat": 47.46513936178963
  },
  {
    "name": "Riverdale",
    "lng": -119.86717907042267,
    "lat": 36.43040529411009
  },
  {
    "name": "Riverdale",
    "lng": -112.00225742588816,
    "lat": 41.17323813987283
  },
  {
    "name": "Riverdale",
    "lng": -101.36542589858784,
    "lat": 47.497079860703096
  },
  {
    "name": "Riverdale",
    "lng": -99.16045929018595,
    "lat": 40.783886541138905
  },
  {
    "name": "Riverdale",
    "lng": -90.4647914471591,
    "lat": 41.53885921745072
  },
  {
    "name": "Riverdale",
    "lng": -87.63642001521126,
    "lat": 41.6441884771617
  },
  {
    "name": "Riverdale",
    "lng": -84.41025246557163,
    "lat": 33.56377558540296
  },
  {
    "name": "Riverdale Park",
    "lng": -121.04284928958377,
    "lat": 37.60226230481291
  },
  {
    "name": "Riverlea",
    "lng": -83.02517998317894,
    "lat": 40.080382157443225
  },
  {
    "name": "Riverside",
    "lng": -119.51086380477155,
    "lat": 48.50447255476006
  },
  {
    "name": "Riverside",
    "lng": -106.78134831669301,
    "lat": 41.215421852814494
  },
  {
    "name": "Riverside",
    "lng": -95.39858192604954,
    "lat": 30.847495737318376
  },
  {
    "name": "Riverside",
    "lng": -94.63061030486314,
    "lat": 39.17015433211408
  },
  {
    "name": "Riverside",
    "lng": -91.55592248880197,
    "lat": 41.489318954021726
  },
  {
    "name": "Riverside",
    "lng": -87.81697958945506,
    "lat": 41.83098297564726
  },
  {
    "name": "Riverside",
    "lng": -86.20420891520405,
    "lat": 33.617056754255486
  },
  {
    "name": "Riverside",
    "lng": -84.14998759851258,
    "lat": 39.82361684797826
  },
  {
    "name": "Riverside",
    "lng": -84.13607998224384,
    "lat": 39.82158030843555
  },
  {
    "name": "Riverside",
    "lng": -84.13007196014873,
    "lat": 39.80428324659959
  },
  {
    "name": "Riverside",
    "lng": -84.12135613452536,
    "lat": 39.82272538052059
  },
  {
    "name": "Riverside",
    "lng": -84.11408486598906,
    "lat": 39.764782153187184
  },
  {
    "name": "Riverside",
    "lng": -84.10127910428183,
    "lat": 39.82305337706558
  },
  {
    "name": "Riverside",
    "lng": -77.08004128347498,
    "lat": 42.155377057591366
  },
  {
    "name": "Riverside Drive Village",
    "lng": -69.75059795868619,
    "lat": 44.33749647321204
  },
  {
    "name": "Riverton",
    "lng": -111.96355355232613,
    "lat": 40.51757661220093
  },
  {
    "name": "Riverton",
    "lng": -108.48287200822452,
    "lat": 43.0686586020766
  },
  {
    "name": "Riverton",
    "lng": -108.4746127186621,
    "lat": 43.05207132957562
  },
  {
    "name": "Riverton",
    "lng": -108.46206698968527,
    "lat": 43.063206855466774
  },
  {
    "name": "Riverton",
    "lng": -108.45363123639484,
    "lat": 43.03057840153855
  },
  {
    "name": "Riverton",
    "lng": -108.43979598876899,
    "lat": 43.044900399931976
  },
  {
    "name": "Riverton",
    "lng": -108.39154349304273,
    "lat": 43.033360489817824
  },
  {
    "name": "Riverton",
    "lng": -108.36256509651788,
    "lat": 43.05010824088402
  },
  {
    "name": "Riverton",
    "lng": -98.75965294263536,
    "lat": 40.08977267948174
  },
  {
    "name": "Riverton",
    "lng": -95.56865454838292,
    "lat": 40.68675040119337
  },
  {
    "name": "Riverton",
    "lng": -94.04890251320379,
    "lat": 46.4583956676721
  },
  {
    "name": "Riverton",
    "lng": -89.53944697023374,
    "lat": 39.84546093896143
  },
  {
    "name": "Riverview",
    "lng": -90.21107388859058,
    "lat": 38.744124549248426
  },
  {
    "name": "Riverview",
    "lng": -87.05770928833175,
    "lat": 31.05802221501248
  },
  {
    "name": "Riverview",
    "lng": -83.19282230262674,
    "lat": 42.17301977173381
  },
  {
    "name": "Riverview Estates",
    "lng": -94.52520664805358,
    "lat": 38.748727067153915
  },
  {
    "name": "Riverwood",
    "lng": -85.66123295984232,
    "lat": 38.28284679485078
  },
  {
    "name": "Riverwoods",
    "lng": -87.8949053536532,
    "lat": 42.172253334709545
  },
  {
    "name": "Rives",
    "lng": -90.01157084725796,
    "lat": 36.0945758480513
  },
  {
    "name": "Rives",
    "lng": -89.04959241436218,
    "lat": 36.356207079343314
  },
  {
    "name": "Rivesville",
    "lng": -80.12053688397624,
    "lat": 39.53186719676644
  },
  {
    "name": "Roachdale",
    "lng": -86.80047046923335,
    "lat": 39.84916837012082
  },
  {
    "name": "Roaming Shores",
    "lng": -80.82492564089279,
    "lat": 41.636576597150544
  },
  {
    "name": "Roann",
    "lng": -85.92446864816867,
    "lat": 40.910980762997745
  },
  {
    "name": "Roanoke",
    "lng": -97.22750894184675,
    "lat": 33.01438189864184
  },
  {
    "name": "Roanoke",
    "lng": -89.20234118105823,
    "lat": 40.7968664076533
  },
  {
    "name": "Roanoke",
    "lng": -89.18851276666666,
    "lat": 40.800692267987664
  },
  {
    "name": "Roanoke",
    "lng": -85.37537052744702,
    "lat": 40.963091079845526
  },
  {
    "name": "Roanoke",
    "lng": -85.3694736905762,
    "lat": 33.14525083614052
  },
  {
    "name": "Roanoke",
    "lng": -85.36947047936313,
    "lat": 33.145253418127034
  },
  {
    "name": "Roanoke",
    "lng": -85.34404964273372,
    "lat": 33.183881525030515
  },
  {
    "name": "Roanoke",
    "lng": -79.95813407399969,
    "lat": 37.27851368454764
  },
  {
    "name": "Roanoke Rapids",
    "lng": -77.69018707435811,
    "lat": 36.46767384928851
  },
  {
    "name": "Roanoke Rapids",
    "lng": -77.68875215601804,
    "lat": 36.46724733993176
  },
  {
    "name": "Roanoke Rapids",
    "lng": -77.6843665938791,
    "lat": 36.46942197576908
  },
  {
    "name": "Roanoke Rapids",
    "lng": -77.65178170664355,
    "lat": 36.4515786386249
  },
  {
    "name": "Roanoke Rapids",
    "lng": -77.6309000683298,
    "lat": 36.40618300083689
  },
  {
    "name": "Roaring Spring",
    "lng": -78.39596751812464,
    "lat": 40.33478711861073
  },
  {
    "name": "Roaring Springs",
    "lng": -100.85614145682713,
    "lat": 33.89918290505092
  },
  {
    "name": "Robards",
    "lng": -87.53303535756305,
    "lat": 37.67702731940606
  },
  {
    "name": "Robbins",
    "lng": -87.70786630955544,
    "lat": 41.64315748125796
  },
  {
    "name": "Robbins",
    "lng": -79.5855077256001,
    "lat": 35.432088501262385
  },
  {
    "name": "Robbins",
    "lng": -79.5823838399073,
    "lat": 35.42019833407393
  },
  {
    "name": "Robbins",
    "lng": -79.5801037911938,
    "lat": 35.40365555440572
  },
  {
    "name": "Robbins",
    "lng": -79.57855181786994,
    "lat": 35.417642665945024
  },
  {
    "name": "Robbins",
    "lng": -79.5774626420051,
    "lat": 35.41917700682317
  },
  {
    "name": "Robbins",
    "lng": -79.57704800768629,
    "lat": 35.41555194844702
  },
  {
    "name": "Robbins",
    "lng": -79.57660884318486,
    "lat": 35.41017175978959
  },
  {
    "name": "Robbins",
    "lng": -79.57565960884703,
    "lat": 35.4039662500501
  },
  {
    "name": "Robbins",
    "lng": -79.57526414094168,
    "lat": 35.40684009277455
  },
  {
    "name": "Robbins",
    "lng": -79.57363756230818,
    "lat": 35.40492392238722
  },
  {
    "name": "Robbins",
    "lng": -79.57321310963954,
    "lat": 35.42302236885266
  },
  {
    "name": "Robbins",
    "lng": -79.56616348219887,
    "lat": 35.4293990406547
  },
  {
    "name": "Robbinsdale",
    "lng": -93.33314491913127,
    "lat": 45.026072612689134
  },
  {
    "name": "Robbinston",
    "lng": -67.15985116945913,
    "lat": 45.06583972672181
  },
  {
    "name": "Robbinsville",
    "lng": -83.80703161738032,
    "lat": 35.32311781286811
  },
  {
    "name": "Robbinsville",
    "lng": -83.79616685234276,
    "lat": 35.320972550967284
  },
  {
    "name": "Robeline",
    "lng": -93.308091214472,
    "lat": 31.67737661584663
  },
  {
    "name": "Robeline",
    "lng": -93.30308237712265,
    "lat": 31.692197237712875
  },
  {
    "name": "Robersonville",
    "lng": -77.25293829500812,
    "lat": 35.8244577591563
  },
  {
    "name": "Robert Lee",
    "lng": -100.48562866961885,
    "lat": 31.89540903431379
  },
  {
    "name": "Roberta",
    "lng": -84.01042418513798,
    "lat": 32.71960920386148
  },
  {
    "name": "Roberts",
    "lng": -112.12828711872105,
    "lat": 43.72069687831947
  },
  {
    "name": "Roberts",
    "lng": -92.55060725283235,
    "lat": 44.973195700419794
  },
  {
    "name": "Roberts",
    "lng": -88.18420989356798,
    "lat": 40.61482324771819
  },
  {
    "name": "Robertsdale",
    "lng": -87.70585610442183,
    "lat": 30.55332170614748
  },
  {
    "name": "Robins",
    "lng": -91.6761973536963,
    "lat": 42.079449007616674
  },
  {
    "name": "Robins",
    "lng": -91.67381209163979,
    "lat": 42.106236575120825
  },
  {
    "name": "Robinson",
    "lng": -99.78027836140693,
    "lat": 47.14225533022924
  },
  {
    "name": "Robinson",
    "lng": -97.12016772654984,
    "lat": 31.450096921794326
  },
  {
    "name": "Robinson",
    "lng": -95.41158175327797,
    "lat": 39.81512365031448
  },
  {
    "name": "Robinson",
    "lng": -87.73329864831433,
    "lat": 39.00892999809171
  },
  {
    "name": "Robstown",
    "lng": -97.66856980274089,
    "lat": 27.788645556231675
  },
  {
    "name": "Roca",
    "lng": -96.66143258804561,
    "lat": 40.65845539998423
  },
  {
    "name": "Rochelle",
    "lng": -89.0635432729557,
    "lat": 41.9198965016589
  },
  {
    "name": "Rochelle",
    "lng": -83.45688682685144,
    "lat": 31.948768246783988
  },
  {
    "name": "Rocheport",
    "lng": -92.56338817679313,
    "lat": 38.97935130883927
  },
  {
    "name": "Rochester",
    "lng": -99.85650297444647,
    "lat": 33.31420552619537
  },
  {
    "name": "Rochester",
    "lng": -92.4777293926393,
    "lat": 44.01544430260638
  },
  {
    "name": "Rochester",
    "lng": -89.54590459837728,
    "lat": 39.749041308811194
  },
  {
    "name": "Rochester",
    "lng": -88.24874815409751,
    "lat": 42.733756417130955
  },
  {
    "name": "Rochester",
    "lng": -86.89155467088679,
    "lat": 37.20971471699289
  },
  {
    "name": "Rochester",
    "lng": -86.19601980388755,
    "lat": 41.059128432029205
  },
  {
    "name": "Rochester",
    "lng": -83.11968013150188,
    "lat": 42.68670395955047
  },
  {
    "name": "Rochester",
    "lng": -82.3055894774293,
    "lat": 41.12444693698456
  },
  {
    "name": "Rochester",
    "lng": -77.66829075305411,
    "lat": 43.121947411651966
  },
  {
    "name": "Rochester",
    "lng": -77.61431714030888,
    "lat": 43.16994006495756
  },
  {
    "name": "Rochester",
    "lng": -70.97898552126001,
    "lat": 43.299083866643706
  },
  {
    "name": "Rochester Hills",
    "lng": -83.15629105335907,
    "lat": 42.66456463985879
  },
  {
    "name": "Rock City",
    "lng": -89.47104772614675,
    "lat": 42.412979388416176
  },
  {
    "name": "Rock Creek",
    "lng": -95.6468880220504,
    "lat": 30.003111586194628
  },
  {
    "name": "Rock Creek",
    "lng": -92.90859629384242,
    "lat": 45.76052637573604
  },
  {
    "name": "Rock Creek",
    "lng": -80.85428011982174,
    "lat": 41.660443756383195
  },
  {
    "name": "Rock Falls",
    "lng": -93.08675425293616,
    "lat": 43.20716022673327
  },
  {
    "name": "Rock Falls",
    "lng": -89.73398412912614,
    "lat": 41.77655829446344
  },
  {
    "name": "Rock Falls",
    "lng": -89.6891388774417,
    "lat": 41.7720372756203
  },
  {
    "name": "Rock Hall",
    "lng": -76.24137869301703,
    "lat": 39.13923132339258
  },
  {
    "name": "Rock Hill",
    "lng": -90.36740641437207,
    "lat": 38.60907760921044
  },
  {
    "name": "Rock Hill",
    "lng": -81.02767907683734,
    "lat": 34.93902416657142
  },
  {
    "name": "Rock Island",
    "lng": -120.13784343601296,
    "lat": 47.37307253852725
  },
  {
    "name": "Rock Island",
    "lng": -94.48137617641179,
    "lat": 35.183112808824106
  },
  {
    "name": "Rock Island",
    "lng": -90.60660259426615,
    "lat": 41.4391247929444
  },
  {
    "name": "Rock Island",
    "lng": -90.5908470145358,
    "lat": 41.463648358198235
  },
  {
    "name": "Rock Island",
    "lng": -90.5690983349282,
    "lat": 41.48740483479828
  },
  {
    "name": "Rock Port",
    "lng": -95.53308631407262,
    "lat": 40.41091099740413
  },
  {
    "name": "Rock Rapids",
    "lng": -96.16622674624655,
    "lat": 43.42727667136467
  },
  {
    "name": "Rock River",
    "lng": -106.00512835957328,
    "lat": 41.7303119174897
  },
  {
    "name": "Rock River",
    "lng": -105.97543761528976,
    "lat": 41.73153057629369
  },
  {
    "name": "Rock River",
    "lng": -105.96272617860096,
    "lat": 41.75488973474816
  },
  {
    "name": "Rock River",
    "lng": -105.94425096972009,
    "lat": 41.73018817516744
  },
  {
    "name": "Rock Springs",
    "lng": -109.23826289245595,
    "lat": 41.594982341488866
  },
  {
    "name": "Rock Springs",
    "lng": -109.06618018309548,
    "lat": 41.595002937167976
  },
  {
    "name": "Rock Springs",
    "lng": -89.91746776761268,
    "lat": 43.47910058074008
  },
  {
    "name": "Rock Valley",
    "lng": -96.29103450462902,
    "lat": 43.20420026502975
  },
  {
    "name": "Rockaway Beach",
    "lng": -93.16228864302438,
    "lat": 36.70201536768393
  },
  {
    "name": "Rockbridge",
    "lng": -90.21817533333332,
    "lat": 39.28391700018249
  },
  {
    "name": "Rockbridge",
    "lng": -90.20655123915792,
    "lat": 39.26927651042236
  },
  {
    "name": "Rockdale",
    "lng": -97.0088590172882,
    "lat": 30.654251449287436
  },
  {
    "name": "Rockdale",
    "lng": -89.03298622283232,
    "lat": 42.97116834107763
  },
  {
    "name": "Rockdale",
    "lng": -88.14414873412153,
    "lat": 41.507777574212525
  },
  {
    "name": "Rockdale",
    "lng": -88.12432392118671,
    "lat": 41.505102083720615
  },
  {
    "name": "Rockford",
    "lng": -117.13202435956978,
    "lat": 47.442127066610595
  },
  {
    "name": "Rockford",
    "lng": -117.13066299002895,
    "lat": 47.45156294722228
  },
  {
    "name": "Rockford",
    "lng": -93.77237757581825,
    "lat": 45.09526864006963
  },
  {
    "name": "Rockford",
    "lng": -93.7626244118054,
    "lat": 45.103212049460815
  },
  {
    "name": "Rockford",
    "lng": -93.74296720116004,
    "lat": 45.09107492663966
  },
  {
    "name": "Rockford",
    "lng": -92.94765787219708,
    "lat": 43.05243874530868
  },
  {
    "name": "Rockford",
    "lng": -89.13443220066438,
    "lat": 42.26436378761425
  },
  {
    "name": "Rockford",
    "lng": -89.1112132594841,
    "lat": 42.15454209050005
  },
  {
    "name": "Rockford",
    "lng": -89.11118389900284,
    "lat": 42.30936810974466
  },
  {
    "name": "Rockford",
    "lng": -89.09046397711657,
    "lat": 42.33286514564571
  },
  {
    "name": "Rockford",
    "lng": -89.08824442060569,
    "lat": 42.16194731791069
  },
  {
    "name": "Rockford",
    "lng": -89.08720279234582,
    "lat": 42.164582690986826
  },
  {
    "name": "Rockford",
    "lng": -89.06320814833278,
    "lat": 42.26309440810368
  },
  {
    "name": "Rockford",
    "lng": -89.02119570294332,
    "lat": 42.29685949858256
  },
  {
    "name": "Rockford",
    "lng": -89.0196576782962,
    "lat": 42.250530751761595
  },
  {
    "name": "Rockford",
    "lng": -88.954672328597,
    "lat": 42.315576121603726
  },
  {
    "name": "Rockford",
    "lng": -88.94308460225422,
    "lat": 42.28141030069305
  },
  {
    "name": "Rockford",
    "lng": -88.94243601624125,
    "lat": 42.274723591355986
  },
  {
    "name": "Rockford",
    "lng": -86.21501199094962,
    "lat": 32.88957154103362
  },
  {
    "name": "Rockford",
    "lng": -85.55824995966624,
    "lat": 43.12683325344793
  },
  {
    "name": "Rockford",
    "lng": -84.64722633148571,
    "lat": 40.69072968995724
  },
  {
    "name": "Rockford",
    "lng": -83.93555552769266,
    "lat": 35.83211681254816
  },
  {
    "name": "Rockham",
    "lng": -98.82340339779648,
    "lat": 44.90427934827479
  },
  {
    "name": "Rockingham",
    "lng": -79.79571240216644,
    "lat": 34.96220863856056
  },
  {
    "name": "Rockingham",
    "lng": -79.78972961266942,
    "lat": 34.910864144727924
  },
  {
    "name": "Rockingham",
    "lng": -79.76038181924936,
    "lat": 34.93850974308494
  },
  {
    "name": "Rockingham",
    "lng": -79.74294895302972,
    "lat": 34.97075339327696
  },
  {
    "name": "Rockingham",
    "lng": -79.73946811724464,
    "lat": 34.981483675857945
  },
  {
    "name": "Rocklake",
    "lng": -99.24608442859004,
    "lat": 48.7901658138195
  },
  {
    "name": "Rockland",
    "lng": -112.87453043461174,
    "lat": 42.57315647777401
  },
  {
    "name": "Rockland",
    "lng": -90.92387802019282,
    "lat": 43.91191012768959
  },
  {
    "name": "Rockland",
    "lng": -90.91536257219946,
    "lat": 43.90686862954875
  },
  {
    "name": "Rockland",
    "lng": -69.13155804106383,
    "lat": 44.12503449988151
  },
  {
    "name": "Rocklin",
    "lng": -121.24883756668403,
    "lat": 38.80778440184031
  },
  {
    "name": "Rockmart",
    "lng": -85.07716601987215,
    "lat": 33.99742007063246
  },
  {
    "name": "Rockmart",
    "lng": -85.04567320465478,
    "lat": 34.009941563690745
  },
  {
    "name": "Rockmart",
    "lng": -85.01622137276682,
    "lat": 33.99015949320899
  },
  {
    "name": "Rockmart",
    "lng": -85.0025895310796,
    "lat": 33.982047698499564
  },
  {
    "name": "Rockport",
    "lng": -97.05891927123349,
    "lat": 28.034599408402784
  },
  {
    "name": "Rockport",
    "lng": -92.81151783506122,
    "lat": 34.42437931238965
  },
  {
    "name": "Rockport",
    "lng": -92.80785516937529,
    "lat": 34.40651593167622
  },
  {
    "name": "Rockport",
    "lng": -87.0543913554566,
    "lat": 37.88553151596368
  },
  {
    "name": "Rockport",
    "lng": -86.99141573756852,
    "lat": 37.336121814834435
  },
  {
    "name": "Rockport",
    "lng": -69.09983175700276,
    "lat": 44.16941756535851
  },
  {
    "name": "Rocksprings",
    "lng": -100.21284299977847,
    "lat": 30.017165720532997
  },
  {
    "name": "Rockton",
    "lng": -89.06311957942734,
    "lat": 42.45007572108786
  },
  {
    "name": "Rockvale",
    "lng": -105.16478660152927,
    "lat": 38.364244626817495
  },
  {
    "name": "Rockville",
    "lng": -113.05477481815825,
    "lat": 37.14782638221276
  },
  {
    "name": "Rockville",
    "lng": -98.83106356350113,
    "lat": 41.11846964114562
  },
  {
    "name": "Rockville",
    "lng": -94.32198973452242,
    "lat": 45.465213978454194
  },
  {
    "name": "Rockville",
    "lng": -94.28942949675444,
    "lat": 45.5138260270745
  },
  {
    "name": "Rockville",
    "lng": -94.08039010852903,
    "lat": 38.07126795104
  },
  {
    "name": "Rockville",
    "lng": -87.2294618685104,
    "lat": 39.766458786935
  },
  {
    "name": "Rockville",
    "lng": -80.19354842799908,
    "lat": 32.60239241806455
  },
  {
    "name": "Rockville",
    "lng": -77.15526636726769,
    "lat": 39.08339299177934
  },
  {
    "name": "Rockville Centre",
    "lng": -73.63827818488076,
    "lat": 40.66427379945203
  },
  {
    "name": "Rockwall",
    "lng": -96.43715090774492,
    "lat": 32.91659917557636
  },
  {
    "name": "Rockwall",
    "lng": -96.42547336346287,
    "lat": 32.8583850346154
  },
  {
    "name": "Rockwell",
    "lng": -93.18721742221896,
    "lat": 42.97996997059332
  },
  {
    "name": "Rockwell",
    "lng": -80.42424940934487,
    "lat": 35.547903079636264
  },
  {
    "name": "Rockwell",
    "lng": -80.40656286704622,
    "lat": 35.55391990942213
  },
  {
    "name": "Rockwell City",
    "lng": -94.62888882592338,
    "lat": 42.398132580769975
  },
  {
    "name": "Rockwood",
    "lng": -89.69669244327665,
    "lat": 37.84053338266222
  },
  {
    "name": "Rockwood",
    "lng": -84.6733160838274,
    "lat": 35.86914595477414
  },
  {
    "name": "Rockwood",
    "lng": -83.24291723786143,
    "lat": 42.07065388300452
  },
  {
    "name": "Rocky",
    "lng": -99.05936433384683,
    "lat": 35.156102706096384
  },
  {
    "name": "Rocky Ford",
    "lng": -103.72247039953703,
    "lat": 38.05010268145388
  },
  {
    "name": "Rocky Ford",
    "lng": -81.82974038995012,
    "lat": 32.6637238224921
  },
  {
    "name": "Rocky Hill",
    "lng": -72.66323077539651,
    "lat": 41.65717373226884
  },
  {
    "name": "Rocky Mound",
    "lng": -95.02977413770681,
    "lat": 33.02791536783289
  },
  {
    "name": "Rocky Mount",
    "lng": -79.8855067086083,
    "lat": 37.00453128545521
  },
  {
    "name": "Rocky Mount",
    "lng": -77.89539716057072,
    "lat": 35.95594710220206
  },
  {
    "name": "Rocky Mount",
    "lng": -77.89389737239252,
    "lat": 35.966960906281514
  },
  {
    "name": "Rocky Mount",
    "lng": -77.85051423456092,
    "lat": 35.93133411911864
  },
  {
    "name": "Rocky Mount",
    "lng": -77.82911813872609,
    "lat": 35.99486718880736
  },
  {
    "name": "Rocky Mount",
    "lng": -77.8181647513409,
    "lat": 36.06310645028317
  },
  {
    "name": "Rocky Mount",
    "lng": -77.81807750001555,
    "lat": 36.04844849995007
  },
  {
    "name": "Rocky Mount",
    "lng": -77.81271584714888,
    "lat": 35.90278318317373
  },
  {
    "name": "Rocky Mount",
    "lng": -77.80767924520723,
    "lat": 35.966690339202685
  },
  {
    "name": "Rocky Mount",
    "lng": -77.77209594609491,
    "lat": 36.0487124778943
  },
  {
    "name": "Rocky Ridge",
    "lng": -111.82576999599213,
    "lat": 39.9198282600437
  },
  {
    "name": "Rocky Ridge",
    "lng": -83.21260894351298,
    "lat": 41.530916110536786
  },
  {
    "name": "Rocky Ripple",
    "lng": -86.17310871014209,
    "lat": 39.84840635569671
  },
  {
    "name": "Rocky River",
    "lng": -81.85334530452576,
    "lat": 41.47309794414522
  },
  {
    "name": "Rocky Top",
    "lng": -84.15508201834645,
    "lat": 36.22420132272204
  },
  {
    "name": "Rodessa",
    "lng": -93.99684575694434,
    "lat": 32.973496304930684
  },
  {
    "name": "Rodman",
    "lng": -94.5262600123075,
    "lat": 43.02766976389111
  },
  {
    "name": "Rodney",
    "lng": -95.95087082669276,
    "lat": 42.204485498506536
  },
  {
    "name": "Roe",
    "lng": -91.38596856389118,
    "lat": 34.63110152695056
  },
  {
    "name": "Roeland Park",
    "lng": -94.63737455248025,
    "lat": 39.0358712940146
  },
  {
    "name": "Roff",
    "lng": -96.84128472396394,
    "lat": 34.62864833105233
  },
  {
    "name": "Rogers",
    "lng": -98.20312164226166,
    "lat": 47.074007991187045
  },
  {
    "name": "Rogers",
    "lng": -97.22901918902157,
    "lat": 30.929829818546377
  },
  {
    "name": "Rogers",
    "lng": -96.91586334921259,
    "lat": 41.464322892838354
  },
  {
    "name": "Rogers",
    "lng": -94.1527391799832,
    "lat": 36.317072184350906
  },
  {
    "name": "Rogers",
    "lng": -94.08121836932986,
    "lat": 36.307809129644546
  },
  {
    "name": "Rogers",
    "lng": -93.57823833741669,
    "lat": 45.186544166786184
  },
  {
    "name": "Rogers",
    "lng": -80.62746363382396,
    "lat": 40.78966971658633
  },
  {
    "name": "Rogers City",
    "lng": -83.8183581484553,
    "lat": 45.41514150696862
  },
  {
    "name": "Rogersville",
    "lng": -93.06980032137955,
    "lat": 37.11475681668102
  },
  {
    "name": "Rogersville",
    "lng": -87.28214567228027,
    "lat": 34.82504931434906
  },
  {
    "name": "Rogersville",
    "lng": -83.00469510381927,
    "lat": 36.40750333501964
  },
  {
    "name": "Rogersville",
    "lng": -82.99339662935748,
    "lat": 36.400867216560776
  },
  {
    "name": "Roland",
    "lng": -94.51310530726487,
    "lat": 35.41503813187468
  },
  {
    "name": "Roland",
    "lng": -93.5029104929023,
    "lat": 42.166093545711654
  },
  {
    "name": "Rolesville",
    "lng": -78.50241222024117,
    "lat": 35.9116858790864
  },
  {
    "name": "Rolesville",
    "lng": -78.50000563421527,
    "lat": 35.931840613396396
  },
  {
    "name": "Rolesville",
    "lng": -78.46381565471962,
    "lat": 35.92510649383315
  },
  {
    "name": "Rolesville",
    "lng": -78.4535254333309,
    "lat": 35.888235068193396
  },
  {
    "name": "Rolette",
    "lng": -99.84175055246614,
    "lat": 48.66083176574397
  },
  {
    "name": "Rolfe",
    "lng": -94.53132948996372,
    "lat": 42.81290675155347
  },
  {
    "name": "Rolla",
    "lng": -101.6319233995344,
    "lat": 37.11784747110235
  },
  {
    "name": "Rolla",
    "lng": -99.61435694916212,
    "lat": 48.853169371792376
  },
  {
    "name": "Rolla",
    "lng": -91.76107899771183,
    "lat": 37.94592440715458
  },
  {
    "name": "Rolling Fields",
    "lng": -85.67081500395608,
    "lat": 38.26887682696177
  },
  {
    "name": "Rolling Fork",
    "lng": -90.87715390707358,
    "lat": 32.90759190194891
  },
  {
    "name": "Rolling Hills",
    "lng": -105.84620385632009,
    "lat": 42.900389751961065
  },
  {
    "name": "Rolling Hills",
    "lng": -105.8372742348554,
    "lat": 42.9096919456336
  },
  {
    "name": "Rolling Hills",
    "lng": -85.57852759854411,
    "lat": 38.282239600104056
  },
  {
    "name": "Rolling Meadows",
    "lng": -88.02504091008448,
    "lat": 42.074617177202654
  },
  {
    "name": "Rollingstone",
    "lng": -91.81860195569561,
    "lat": 44.099393425098974
  },
  {
    "name": "Rollingwood",
    "lng": -97.78679645706531,
    "lat": 30.27355911904976
  },
  {
    "name": "Rollinsford",
    "lng": -70.84271766957255,
    "lat": 43.2209977744069
  },
  {
    "name": "Roma",
    "lng": -99.0056094764908,
    "lat": 26.416013500487843
  },
  {
    "name": "Roman Forest",
    "lng": -95.15604203432204,
    "lat": 30.181062678116078
  },
  {
    "name": "Rome",
    "lng": -91.68169191459583,
    "lat": 40.98193815958549
  },
  {
    "name": "Rome",
    "lng": -85.18748769312616,
    "lat": 34.26722151850464
  },
  {
    "name": "Rome",
    "lng": -85.13139560663714,
    "lat": 34.21033958201105
  },
  {
    "name": "Rome",
    "lng": -83.37882987060289,
    "lat": 38.66512124168757
  },
  {
    "name": "Rome",
    "lng": -69.88751745126632,
    "lat": 44.57220126056039
  },
  {
    "name": "Rome City",
    "lng": -85.36083443116719,
    "lat": 41.489245739946
  },
  {
    "name": "Romeo",
    "lng": -105.98541256526157,
    "lat": 37.171819867017646
  },
  {
    "name": "Romeo",
    "lng": -83.00397989114418,
    "lat": 42.80491520334574
  },
  {
    "name": "Romeoville",
    "lng": -88.10148701730671,
    "lat": 41.63285927220544
  },
  {
    "name": "Romeoville",
    "lng": -88.06801383138254,
    "lat": 41.614085564010125
  },
  {
    "name": "Romeoville",
    "lng": -88.03647527343809,
    "lat": 41.64276141838066
  },
  {
    "name": "Romney",
    "lng": -78.75637817896943,
    "lat": 39.345527313136245
  },
  {
    "name": "Romulus",
    "lng": -83.36598490599792,
    "lat": 42.22370973081989
  },
  {
    "name": "Ronan",
    "lng": -114.10528506241712,
    "lat": 47.542098674089615
  },
  {
    "name": "Ronan",
    "lng": -114.10039563587014,
    "lat": 47.527547964232795
  },
  {
    "name": "Ronceverte",
    "lng": -80.47148214019077,
    "lat": 37.75026984042031
  },
  {
    "name": "Ronda",
    "lng": -80.94324845127942,
    "lat": 36.221729061549624
  },
  {
    "name": "Rondo",
    "lng": -90.82088391903017,
    "lat": 34.657506146916425
  },
  {
    "name": "Roodhouse",
    "lng": -90.37403488759624,
    "lat": 39.48405241351293
  },
  {
    "name": "Roopville",
    "lng": -85.13040734504595,
    "lat": 33.45663880459981
  },
  {
    "name": "Roosevelt",
    "lng": -110.00931441085046,
    "lat": 40.29236710886487
  },
  {
    "name": "Roosevelt",
    "lng": -95.1014800492175,
    "lat": 48.80718947047253
  },
  {
    "name": "Roosevelt",
    "lng": -95.08837859277725,
    "lat": 48.79817293076723
  },
  {
    "name": "Roosevelt Park",
    "lng": -86.27335535133219,
    "lat": 43.198153378887575
  },
  {
    "name": "Roper",
    "lng": -76.61705884639765,
    "lat": 35.878640722089685
  },
  {
    "name": "Ropesville",
    "lng": -102.1551013105589,
    "lat": 33.41333411598171
  },
  {
    "name": "Roque Bluffs",
    "lng": -67.48230815838474,
    "lat": 44.62298954863956
  },
  {
    "name": "Rosa",
    "lng": -86.5035956688226,
    "lat": 33.988528669395876
  },
  {
    "name": "Rosalia",
    "lng": -117.42072629858447,
    "lat": 47.2371756005893
  },
  {
    "name": "Rosalia",
    "lng": -117.36874299255078,
    "lat": 47.23636632895976
  },
  {
    "name": "Rosalie",
    "lng": -96.51276357498335,
    "lat": 42.05734659954137
  },
  {
    "name": "Rosamond",
    "lng": -118.21548441467765,
    "lat": 34.865765318319056
  },
  {
    "name": "Roscoe",
    "lng": -100.53322243378169,
    "lat": 32.44173069098607
  },
  {
    "name": "Roscoe",
    "lng": -99.33610304067477,
    "lat": 45.45065997425338
  },
  {
    "name": "Roscoe",
    "lng": -94.63653537224124,
    "lat": 45.43246945784776
  },
  {
    "name": "Roscoe",
    "lng": -93.8138515747941,
    "lat": 37.977358017596
  },
  {
    "name": "Roscoe",
    "lng": -89.00870857977213,
    "lat": 42.42552805630486
  },
  {
    "name": "Roscommon",
    "lng": -84.59187553943282,
    "lat": 44.49177053308834
  },
  {
    "name": "Rose Bud",
    "lng": -92.08124521899757,
    "lat": 35.32492259473439
  },
  {
    "name": "Rose City",
    "lng": -94.05049999833155,
    "lat": 30.104842010393757
  },
  {
    "name": "Rose City",
    "lng": -84.11585257535573,
    "lat": 44.42087437987935
  },
  {
    "name": "Rose Creek",
    "lng": -92.82863236963323,
    "lat": 43.604407118168275
  },
  {
    "name": "Rose Hill",
    "lng": -97.14789113190852,
    "lat": 37.552448505174084
  },
  {
    "name": "Rose Hill",
    "lng": -97.13960463104512,
    "lat": 37.60298542703235
  },
  {
    "name": "Rose Hill",
    "lng": -97.13596388823595,
    "lat": 37.59047973047452
  },
  {
    "name": "Rose Hill",
    "lng": -97.13179935049142,
    "lat": 37.56479593052115
  },
  {
    "name": "Rose Hill",
    "lng": -97.12568164229909,
    "lat": 37.57821163771357
  },
  {
    "name": "Rose Hill",
    "lng": -97.12265499753899,
    "lat": 37.58663955091074
  },
  {
    "name": "Rose Hill",
    "lng": -92.46358051762088,
    "lat": 41.320396022711925
  },
  {
    "name": "Rose Hill",
    "lng": -88.14988734298608,
    "lat": 39.104302650647135
  },
  {
    "name": "Rose Hill",
    "lng": -78.02721470528819,
    "lat": 34.8258072152184
  },
  {
    "name": "Rose Hill",
    "lng": -78.02436576073626,
    "lat": 34.83762052416298
  },
  {
    "name": "Rose Hill",
    "lng": -77.99863218840409,
    "lat": 34.82288311612235
  },
  {
    "name": "Rose Hill",
    "lng": -77.99602705997373,
    "lat": 34.839790965106786
  },
  {
    "name": "Rose Hill Acres",
    "lng": -94.19311022327949,
    "lat": 30.193292757619187
  },
  {
    "name": "Roseau",
    "lng": -95.76226421285791,
    "lat": 48.84449057517985
  },
  {
    "name": "Roseau",
    "lng": -95.74574499205848,
    "lat": 48.83827601815013
  },
  {
    "name": "Roseau",
    "lng": -95.74511089944471,
    "lat": 48.84646549232108
  },
  {
    "name": "Roseau",
    "lng": -95.74506410792353,
    "lat": 48.8472335058805
  },
  {
    "name": "Roseboro",
    "lng": -78.51177477538019,
    "lat": 34.95380295823745
  },
  {
    "name": "Rosebud",
    "lng": -96.97507677086571,
    "lat": 31.07546480733696
  },
  {
    "name": "Rosebud",
    "lng": -91.40272324300136,
    "lat": 38.38649133558029
  },
  {
    "name": "Rosebush",
    "lng": -84.76605593631655,
    "lat": 43.69984897178319
  },
  {
    "name": "Rosedale",
    "lng": -97.18550605470088,
    "lat": 34.91882058344939
  },
  {
    "name": "Rosedale",
    "lng": -91.4601189310981,
    "lat": 30.442899494936178
  },
  {
    "name": "Rosedale",
    "lng": -91.03401190530354,
    "lat": 33.85260816260645
  },
  {
    "name": "Rosedale",
    "lng": -87.2815889812955,
    "lat": 39.622461143872165
  },
  {
    "name": "Roseland",
    "lng": -98.55832754947042,
    "lat": 40.469909622790986
  },
  {
    "name": "Roseland",
    "lng": -94.84408977505745,
    "lat": 37.280325678116704
  },
  {
    "name": "Roseland",
    "lng": -90.51142484452079,
    "lat": 30.76460085874884
  },
  {
    "name": "Roseland",
    "lng": -86.25059737124194,
    "lat": 41.71762625119582
  },
  {
    "name": "Roselle",
    "lng": -88.08614573895083,
    "lat": 41.98064986832252
  },
  {
    "name": "Rosemont",
    "lng": -77.62141559127573,
    "lat": 39.33168631844457
  },
  {
    "name": "Rosemount",
    "lng": -93.06606290775777,
    "lat": 44.746568076624634
  },
  {
    "name": "Rosenberg",
    "lng": -95.82216983227748,
    "lat": 29.546021941339596
  },
  {
    "name": "Rosendale",
    "lng": -94.82343224857765,
    "lat": 40.040712237393315
  },
  {
    "name": "Rosendale",
    "lng": -88.6731857716195,
    "lat": 43.80792200387585
  },
  {
    "name": "Rosepine",
    "lng": -93.28571055613887,
    "lat": 30.920766932890018
  },
  {
    "name": "Roseville",
    "lng": -93.15448890330713,
    "lat": 45.01551150134854
  },
  {
    "name": "Roseville",
    "lng": -90.66389238033743,
    "lat": 40.73075660209025
  },
  {
    "name": "Roseville",
    "lng": -82.93698494778661,
    "lat": 42.50742472838791
  },
  {
    "name": "Roseville",
    "lng": -82.07556834644407,
    "lat": 39.80653354254829
  },
  {
    "name": "Rosholt",
    "lng": -96.73688990646784,
    "lat": 45.861879963168526
  },
  {
    "name": "Rosholt",
    "lng": -96.73174147931473,
    "lat": 45.86622946226736
  },
  {
    "name": "Rosholt",
    "lng": -89.30517610705874,
    "lat": 44.63013074289116
  },
  {
    "name": "Rosiclare",
    "lng": -88.35032738742028,
    "lat": 37.424864792167504
  },
  {
    "name": "Roslyn",
    "lng": -120.99458091238948,
    "lat": 47.22523516090027
  },
  {
    "name": "Roslyn",
    "lng": -97.49320432826282,
    "lat": 45.49652405378268
  },
  {
    "name": "Roslyn",
    "lng": -73.64928236189462,
    "lat": 40.79920012055875
  },
  {
    "name": "Roslyn Estates",
    "lng": -73.66122272638836,
    "lat": 40.79364049514882
  },
  {
    "name": "Roslyn Harbor",
    "lng": -73.63949956764405,
    "lat": 40.81562302050296
  },
  {
    "name": "Rosman",
    "lng": -82.82218667981833,
    "lat": 35.143892771662244
  },
  {
    "name": "Rosman",
    "lng": -82.80594643107459,
    "lat": 35.15629010274432
  },
  {
    "name": "Ross",
    "lng": -102.54363198044379,
    "lat": 48.31285997660396
  },
  {
    "name": "Ross",
    "lng": -97.11284215925757,
    "lat": 31.727623696803136
  },
  {
    "name": "Rossburg",
    "lng": -84.6382802552187,
    "lat": 40.280202739116156
  },
  {
    "name": "Rosser",
    "lng": -96.4430778850266,
    "lat": 32.464757569384595
  },
  {
    "name": "Rossford",
    "lng": -83.56923579310424,
    "lat": 41.58323999803487
  },
  {
    "name": "Rossie",
    "lng": -95.18858625250651,
    "lat": 43.01362983222349
  },
  {
    "name": "Rosston",
    "lng": -99.9341457211135,
    "lat": 36.814038233797255
  },
  {
    "name": "Rosston",
    "lng": -93.27975006837377,
    "lat": 33.587752483827195
  },
  {
    "name": "Rossville",
    "lng": -95.95053859036501,
    "lat": 39.1363787449817
  },
  {
    "name": "Rossville",
    "lng": -89.55772870430614,
    "lat": 35.04703009048755
  },
  {
    "name": "Rossville",
    "lng": -87.66992518667223,
    "lat": 40.38148327245257
  },
  {
    "name": "Rossville",
    "lng": -86.5956589484726,
    "lat": 40.41999380924315
  },
  {
    "name": "Rossville",
    "lng": -85.28985012029572,
    "lat": 34.97467580098701
  },
  {
    "name": "Roswell",
    "lng": -104.52933360554525,
    "lat": 33.372865901925536
  },
  {
    "name": "Roswell",
    "lng": -97.69714908780654,
    "lat": 44.0012528740248
  },
  {
    "name": "Roswell",
    "lng": -84.35126663689897,
    "lat": 34.03917320094118
  },
  {
    "name": "Roswell",
    "lng": -81.34810043005528,
    "lat": 40.475937206079344
  },
  {
    "name": "Rotan",
    "lng": -100.46552074234616,
    "lat": 32.85404965822963
  },
  {
    "name": "Rothbury",
    "lng": -86.3476190042226,
    "lat": 43.50745773431388
  },
  {
    "name": "Rothsay",
    "lng": -96.28069820572797,
    "lat": 46.47172346572696
  },
  {
    "name": "Rothschild",
    "lng": -89.61711793473961,
    "lat": 44.87619208575209
  },
  {
    "name": "Rothville",
    "lng": -93.06090429814722,
    "lat": 39.65379397499949
  },
  {
    "name": "Round Hill",
    "lng": -77.76803536825892,
    "lat": 39.13191262202581
  },
  {
    "name": "Round Lake",
    "lng": -95.48212503466006,
    "lat": 43.53514328665649
  },
  {
    "name": "Round Lake",
    "lng": -95.4654520292213,
    "lat": 43.538119581256275
  },
  {
    "name": "Round Lake",
    "lng": -88.10674978553426,
    "lat": 42.34527943565657
  },
  {
    "name": "Round Lake",
    "lng": -88.09334402007981,
    "lat": 42.30995006517758
  },
  {
    "name": "Round Lake",
    "lng": -88.08820358254039,
    "lat": 42.30745554678912
  },
  {
    "name": "Round Lake",
    "lng": -73.79586094443313,
    "lat": 42.937597736979754
  },
  {
    "name": "Round Lake Beach",
    "lng": -88.08107144013681,
    "lat": 42.37914084112783
  },
  {
    "name": "Round Lake Heights",
    "lng": -88.10367725965276,
    "lat": 42.38551753085317
  },
  {
    "name": "Round Lake Park",
    "lng": -88.0753467028482,
    "lat": 42.331065983451516
  },
  {
    "name": "Round Mountain",
    "lng": -98.36037674651133,
    "lat": 30.430203881003663
  },
  {
    "name": "Round Rock",
    "lng": -97.6638190277229,
    "lat": 30.52743802756199
  },
  {
    "name": "Roundup",
    "lng": -108.54013091319565,
    "lat": 46.44837239191879
  },
  {
    "name": "Rouses Point",
    "lng": -73.36238954425001,
    "lat": 44.99237079110225
  },
  {
    "name": "Rowan",
    "lng": -93.55035821920117,
    "lat": 42.73974210508328
  },
  {
    "name": "Rowe",
    "lng": -72.91288131604392,
    "lat": 42.69871021703144
  },
  {
    "name": "Rowesville",
    "lng": -80.83626570899523,
    "lat": 33.37195112153887
  },
  {
    "name": "Rowland",
    "lng": -79.3074945294922,
    "lat": 34.50570517492717
  },
  {
    "name": "Rowland",
    "lng": -79.30732434876376,
    "lat": 34.54961654657465
  },
  {
    "name": "Rowland",
    "lng": -79.3055729492943,
    "lat": 34.5053976033753
  },
  {
    "name": "Rowland",
    "lng": -79.30299634856712,
    "lat": 34.51977081548199
  },
  {
    "name": "Rowland",
    "lng": -79.29603240677785,
    "lat": 34.530091874378485
  },
  {
    "name": "Rowland",
    "lng": -79.2925246464946,
    "lat": 34.53725204113447
  },
  {
    "name": "Rowland",
    "lng": -79.28012301844718,
    "lat": 34.52846509442299
  },
  {
    "name": "Rowlesburg",
    "lng": -79.6763132417624,
    "lat": 39.34818853294526
  },
  {
    "name": "Rowlett",
    "lng": -96.54881497059593,
    "lat": 32.91662397867084
  },
  {
    "name": "Rowlett",
    "lng": -96.51579001290513,
    "lat": 32.878615854631086
  },
  {
    "name": "Rowley",
    "lng": -91.84429060330783,
    "lat": 42.36842246137219
  },
  {
    "name": "Roxana",
    "lng": -90.04664098836776,
    "lat": 38.83175638385052
  },
  {
    "name": "Roxboro",
    "lng": -79.00401531615951,
    "lat": 36.4140876975851
  },
  {
    "name": "Roxboro",
    "lng": -78.98216306598266,
    "lat": 36.388173498177395
  },
  {
    "name": "Roxboro",
    "lng": -78.97633153982858,
    "lat": 36.445793198434544
  },
  {
    "name": "Roxboro",
    "lng": -78.97193569278174,
    "lat": 36.33516832749609
  },
  {
    "name": "Roxboro",
    "lng": -78.94765017029758,
    "lat": 36.455948586248276
  },
  {
    "name": "Roxbury",
    "lng": -70.60540163985449,
    "lat": 44.648627790758084
  },
  {
    "name": "Roxie",
    "lng": -91.06751448037899,
    "lat": 31.50463497017547
  },
  {
    "name": "Roxobel",
    "lng": -77.23980432897832,
    "lat": 36.201528128794756
  },
  {
    "name": "Roxton",
    "lng": -95.72477096062946,
    "lat": 33.54535496656201
  },
  {
    "name": "Roy",
    "lng": -122.54305501084623,
    "lat": 47.002484094840575
  },
  {
    "name": "Roy",
    "lng": -112.04843137401144,
    "lat": 41.17138775266201
  },
  {
    "name": "Roy",
    "lng": -104.19628820465367,
    "lat": 35.9450955114972
  },
  {
    "name": "Royal",
    "lng": -98.12504668189858,
    "lat": 42.3329407712393
  },
  {
    "name": "Royal",
    "lng": -95.2837093910255,
    "lat": 43.064453364096096
  },
  {
    "name": "Royal",
    "lng": -87.97225046470116,
    "lat": 40.19313406592635
  },
  {
    "name": "Royal Center",
    "lng": -86.50041107250273,
    "lat": 40.86473280587828
  },
  {
    "name": "Royal City",
    "lng": -119.62872407508509,
    "lat": 46.904154358836486
  },
  {
    "name": "Royal City",
    "lng": -119.57361820314262,
    "lat": 46.88745032080557
  },
  {
    "name": "Royal City",
    "lng": -119.5624263810526,
    "lat": 46.88602188473681
  },
  {
    "name": "Royal Lakes",
    "lng": -89.96066048414102,
    "lat": 39.11169906144505
  },
  {
    "name": "Royal Oak",
    "lng": -83.1538770501474,
    "lat": 42.50832572557757
  },
  {
    "name": "Royalston",
    "lng": -72.18608808054748,
    "lat": 42.68091773192147
  },
  {
    "name": "Royalton",
    "lng": -94.29311911045004,
    "lat": 45.83027683048618
  },
  {
    "name": "Royalton",
    "lng": -94.28368541499285,
    "lat": 45.82073188090223
  },
  {
    "name": "Royalton",
    "lng": -89.11422718757797,
    "lat": 37.87740892681118
  },
  {
    "name": "Royse City",
    "lng": -96.31844087766929,
    "lat": 32.97678971596724
  },
  {
    "name": "Royston",
    "lng": -83.10969081563057,
    "lat": 34.28586544016688
  },
  {
    "name": "Rozel",
    "lng": -99.40157594965568,
    "lat": 38.19561810769311
  },
  {
    "name": "Ruby",
    "lng": -155.50142408208126,
    "lat": 64.72236871113054
  },
  {
    "name": "Ruby",
    "lng": -80.1775974525737,
    "lat": 34.74487955082271
  },
  {
    "name": "Rudd",
    "lng": -92.90479837734345,
    "lat": 43.12624917153995
  },
  {
    "name": "Rudolph",
    "lng": -89.80175774671392,
    "lat": 44.49707279931074
  },
  {
    "name": "Rudy",
    "lng": -94.24263750379497,
    "lat": 35.52738642654456
  },
  {
    "name": "Rudy",
    "lng": -94.24263750379495,
    "lat": 35.527386426544595
  },
  {
    "name": "Rudy",
    "lng": -94.22467975207118,
    "lat": 35.54047862240102
  },
  {
    "name": "Rugby",
    "lng": -99.99211083846306,
    "lat": 48.36664167680358
  },
  {
    "name": "Ruidoso",
    "lng": -105.66596454067825,
    "lat": 33.34500252685473
  },
  {
    "name": "Ruidoso",
    "lng": -105.53196552431656,
    "lat": 33.46264830915271
  },
  {
    "name": "Ruidoso Downs",
    "lng": -105.59665346480435,
    "lat": 33.33123287279859
  },
  {
    "name": "Rule",
    "lng": -99.893297637974,
    "lat": 33.18194469895948
  },
  {
    "name": "Ruleville",
    "lng": -90.54998679176163,
    "lat": 33.72469207296529
  },
  {
    "name": "Rulo",
    "lng": -95.4306511353401,
    "lat": 40.051645005309744
  },
  {
    "name": "Ruma",
    "lng": -90.00033052544374,
    "lat": 38.130630616120065
  },
  {
    "name": "Rumford",
    "lng": -70.61543532875719,
    "lat": 44.53474888962648
  },
  {
    "name": "Runaway Bay",
    "lng": -97.87067570219763,
    "lat": 33.17728383898628
  },
  {
    "name": "Runge",
    "lng": -97.71320958374952,
    "lat": 28.88568110898146
  },
  {
    "name": "Runnells",
    "lng": -93.36019438452912,
    "lat": 41.51394931433024
  },
  {
    "name": "Rupert",
    "lng": -113.67396690107812,
    "lat": 42.61893787813768
  },
  {
    "name": "Rupert",
    "lng": -80.68689986298345,
    "lat": 37.96494794586384
  },
  {
    "name": "Rural Hall",
    "lng": -80.31069856003045,
    "lat": 36.24204578208524
  },
  {
    "name": "Rural Hall",
    "lng": -80.29686052417377,
    "lat": 36.22469656355946
  },
  {
    "name": "Rural Retreat",
    "lng": -81.27630162042446,
    "lat": 36.90079489332203
  },
  {
    "name": "Rush Center",
    "lng": -99.31080549014375,
    "lat": 38.46482671935348
  },
  {
    "name": "Rush City",
    "lng": -92.96574702404568,
    "lat": 45.68744397499568
  },
  {
    "name": "Rush Hill",
    "lng": -91.72479896452283,
    "lat": 39.21024258520432
  },
  {
    "name": "Rush Springs",
    "lng": -97.96741135376696,
    "lat": 34.78444378223829
  },
  {
    "name": "Rush Springs",
    "lng": -97.9635320276432,
    "lat": 34.76664708745258
  },
  {
    "name": "Rush Springs",
    "lng": -97.95558908245745,
    "lat": 34.7805583627907
  },
  {
    "name": "Rush Valley",
    "lng": -112.4502023676211,
    "lat": 40.3616898923797
  },
  {
    "name": "Rushford",
    "lng": -91.75835832147686,
    "lat": 43.821578826317214
  },
  {
    "name": "Rushford",
    "lng": -91.75354382545652,
    "lat": 43.81316923122464
  },
  {
    "name": "Rushford Village",
    "lng": -91.79149079457451,
    "lat": 43.80336665003352
  },
  {
    "name": "Rushmore",
    "lng": -95.79895289623259,
    "lat": 43.619689598547986
  },
  {
    "name": "Rushsylvania",
    "lng": -83.671144312481,
    "lat": 40.46121661445328
  },
  {
    "name": "Rushville",
    "lng": -102.46680664128154,
    "lat": 42.71339526998675
  },
  {
    "name": "Rushville",
    "lng": -95.02272681789243,
    "lat": 39.58776615872885
  },
  {
    "name": "Rushville",
    "lng": -90.5666065617218,
    "lat": 40.119931193179845
  },
  {
    "name": "Rushville",
    "lng": -85.44638107535961,
    "lat": 39.61546217025527
  },
  {
    "name": "Rushville",
    "lng": -82.43034834624167,
    "lat": 39.76435461370297
  },
  {
    "name": "Rushville",
    "lng": -77.22735822812166,
    "lat": 42.76074371908101
  },
  {
    "name": "Rusk",
    "lng": -95.1491332900141,
    "lat": 31.797750078094676
  },
  {
    "name": "Ruskin",
    "lng": -97.86704054724177,
    "lat": 40.144006787619716
  },
  {
    "name": "Ruso",
    "lng": -100.93435456828792,
    "lat": 47.83727751010889
  },
  {
    "name": "Russell",
    "lng": -98.85450912347105,
    "lat": 38.88862437050483
  },
  {
    "name": "Russell",
    "lng": -98.81613983675217,
    "lat": 38.884793532736914
  },
  {
    "name": "Russell",
    "lng": -98.81187288630176,
    "lat": 38.872321114499066
  },
  {
    "name": "Russell",
    "lng": -95.94743347375592,
    "lat": 44.31986918571496
  },
  {
    "name": "Russell",
    "lng": -93.20062879632442,
    "lat": 40.98022882263351
  },
  {
    "name": "Russell",
    "lng": -91.50995871120034,
    "lat": 35.362106349025034
  },
  {
    "name": "Russell",
    "lng": -82.70108180012274,
    "lat": 38.509237883660866
  },
  {
    "name": "Russell",
    "lng": -72.85682622416483,
    "lat": 42.16228613403579
  },
  {
    "name": "Russell Gardens",
    "lng": -73.72554061094569,
    "lat": 40.78090733334647
  },
  {
    "name": "Russell Springs",
    "lng": -101.17586695416824,
    "lat": 38.912655303163
  },
  {
    "name": "Russell Springs",
    "lng": -85.09021370002405,
    "lat": 37.02882437109277
  },
  {
    "name": "Russell Springs",
    "lng": -85.07808836129036,
    "lat": 37.05073025857608
  },
  {
    "name": "Russells Point",
    "lng": -83.89374885420752,
    "lat": 40.468095864229056
  },
  {
    "name": "Russellville",
    "lng": -93.13831232640156,
    "lat": 35.27625568183441
  },
  {
    "name": "Russellville",
    "lng": -92.43856638737388,
    "lat": 38.51293747646219
  },
  {
    "name": "Russellville",
    "lng": -87.72791913863686,
    "lat": 34.50470155130236
  },
  {
    "name": "Russellville",
    "lng": -87.52997000437341,
    "lat": 38.819779741837
  },
  {
    "name": "Russellville",
    "lng": -86.98327150690119,
    "lat": 39.856960626330554
  },
  {
    "name": "Russellville",
    "lng": -86.89552968572387,
    "lat": 36.839701773501424
  },
  {
    "name": "Russellville",
    "lng": -83.78733163802603,
    "lat": 38.86838982871872
  },
  {
    "name": "Russia",
    "lng": -84.40862572184739,
    "lat": 40.23420732513765
  },
  {
    "name": "Russian Mission",
    "lng": -161.35895678307375,
    "lat": 61.78439601557881
  },
  {
    "name": "Russiaville",
    "lng": -86.27311421050776,
    "lat": 40.41855148997478
  },
  {
    "name": "Ruston",
    "lng": -122.51040031216988,
    "lat": 47.298139590731786
  },
  {
    "name": "Ruston",
    "lng": -92.6374035719891,
    "lat": 32.532115828213925
  },
  {
    "name": "Ruth",
    "lng": -81.94513930238014,
    "lat": 35.38268446772064
  },
  {
    "name": "Rutherford",
    "lng": -88.99249841592702,
    "lat": 36.125746389981174
  },
  {
    "name": "Rutherford College",
    "lng": -81.52692401975601,
    "lat": 35.75067448171831
  },
  {
    "name": "Rutherfordton",
    "lng": -81.98625022335696,
    "lat": 35.399521905289575
  },
  {
    "name": "Rutherfordton",
    "lng": -81.9605885900097,
    "lat": 35.363009786319545
  },
  {
    "name": "Ruthton",
    "lng": -96.10348754149423,
    "lat": 44.17758871162874
  },
  {
    "name": "Ruthven",
    "lng": -94.89956019128839,
    "lat": 43.13023025358206
  },
  {
    "name": "Rutland",
    "lng": -97.50683558107728,
    "lat": 46.05432628163919
  },
  {
    "name": "Rutland",
    "lng": -94.29515904571481,
    "lat": 42.76142526285116
  },
  {
    "name": "Rutland",
    "lng": -89.04132673458861,
    "lat": 40.98379543526268
  },
  {
    "name": "Rutland",
    "lng": -82.12792831884659,
    "lat": 39.04102046317669
  },
  {
    "name": "Rutland",
    "lng": -71.96725867083467,
    "lat": 42.38483672397612
  },
  {
    "name": "Rutland City",
    "lng": -72.97821823198599,
    "lat": 43.60921213915995
  },
  {
    "name": "Rutledge",
    "lng": -92.8696294612096,
    "lat": 46.25701548017198
  },
  {
    "name": "Rutledge",
    "lng": -92.08758729361163,
    "lat": 40.31398337185773
  },
  {
    "name": "Rutledge",
    "lng": -86.30596606138684,
    "lat": 31.734586238288095
  },
  {
    "name": "Rutledge",
    "lng": -83.61127239317968,
    "lat": 33.62629840656602
  },
  {
    "name": "Rutledge",
    "lng": -83.53336439219147,
    "lat": 36.27470566264224
  },
  {
    "name": "Ryan",
    "lng": -97.95411256106003,
    "lat": 34.021234637027234
  },
  {
    "name": "Ryan",
    "lng": -91.48486125347065,
    "lat": 42.35231636909003
  },
  {
    "name": "Ryder",
    "lng": -101.67365234106711,
    "lat": 47.91766654059348
  },
  {
    "name": "Rye",
    "lng": -104.93212679882623,
    "lat": 37.92127459389223
  },
  {
    "name": "Rye",
    "lng": -73.67530034965962,
    "lat": 40.949338674853124
  },
  {
    "name": "Rye Brook",
    "lng": -73.68630503864476,
    "lat": 41.03016102624568
  },
  {
    "name": "Ryegate",
    "lng": -109.25334231401226,
    "lat": 46.29857297970445
  },
  {
    "name": "Ryland Heights",
    "lng": -84.46048042468662,
    "lat": 38.97003967064612
  },
  {
    "name": "Sabattus",
    "lng": -70.0662089554009,
    "lat": 44.09506311116019
  },
  {
    "name": "Sabetha",
    "lng": -95.79511649510049,
    "lat": 39.90238386700694
  },
  {
    "name": "Sabetha",
    "lng": -95.79241475071093,
    "lat": 39.93199454894422
  },
  {
    "name": "Sabetha",
    "lng": -95.77951785519028,
    "lat": 39.94279717430946
  },
  {
    "name": "Sabetha",
    "lng": -95.7774838900188,
    "lat": 39.88944142085182
  },
  {
    "name": "Sabin",
    "lng": -96.65504587065652,
    "lat": 46.78146408417254
  },
  {
    "name": "Sabina",
    "lng": -83.63372333863352,
    "lat": 39.49012642660993
  },
  {
    "name": "Sabinal",
    "lng": -99.46954342046422,
    "lat": 29.32133042312619
  },
  {
    "name": "Sabula",
    "lng": -90.1766982670213,
    "lat": 42.066728767347286
  },
  {
    "name": "Sac City",
    "lng": -94.99772582088784,
    "lat": 42.42163545413851
  },
  {
    "name": "Sackets Harbor",
    "lng": -76.11863697300609,
    "lat": 43.94200905067949
  },
  {
    "name": "Saco",
    "lng": -107.34090845955404,
    "lat": 48.45686516768798
  },
  {
    "name": "Saco",
    "lng": -70.42422615400089,
    "lat": 43.52077984596945
  },
  {
    "name": "Saco",
    "lng": -70.31784051905396,
    "lat": 43.50905713386295
  },
  {
    "name": "Saco",
    "lng": -70.31555522448902,
    "lat": 43.502626100799795
  },
  {
    "name": "Saco",
    "lng": -70.31109692097611,
    "lat": 43.50544964442808
  },
  {
    "name": "Sacramento",
    "lng": -87.26894592006596,
    "lat": 37.42197732692337
  },
  {
    "name": "Sacramento",
    "lng": -87.26725967804593,
    "lat": 37.41663808133158
  },
  {
    "name": "Sacred Heart",
    "lng": -95.35072662718198,
    "lat": 44.781734403426405
  },
  {
    "name": "Saddle Rock",
    "lng": -73.75370342844101,
    "lat": 40.79465505000001
  },
  {
    "name": "Saddlebrooke",
    "lng": -93.2119359277766,
    "lat": 36.82514890259034
  },
  {
    "name": "Sadieville",
    "lng": -84.5378611896731,
    "lat": 38.39299436432127
  },
  {
    "name": "Sadorus",
    "lng": -88.34514585212355,
    "lat": 39.966891829653896
  },
  {
    "name": "Safford",
    "lng": -109.7193541598484,
    "lat": 32.8216707921867
  },
  {
    "name": "Safford",
    "lng": -109.71469265845279,
    "lat": 32.87391108856362
  },
  {
    "name": "Safford",
    "lng": -109.66782644034801,
    "lat": 32.84521218530322
  },
  {
    "name": "Safford",
    "lng": -109.63540405824844,
    "lat": 32.85329756823899
  },
  {
    "name": "Sag Harbor",
    "lng": -72.29140472415443,
    "lat": 40.997362501733356
  },
  {
    "name": "Sagaponack",
    "lng": -72.27243315786427,
    "lat": 40.92971593308951
  },
  {
    "name": "Sageville",
    "lng": -90.71639242956603,
    "lat": 42.56642139549436
  },
  {
    "name": "Sageville",
    "lng": -90.70454361187218,
    "lat": 42.54599924231854
  },
  {
    "name": "Saginaw",
    "lng": -97.3651927730767,
    "lat": 32.865393804788724
  },
  {
    "name": "Saginaw",
    "lng": -94.47249122844059,
    "lat": 37.02595915663354
  },
  {
    "name": "Saginaw",
    "lng": -76.67402081164468,
    "lat": 40.0647414754629
  },
  {
    "name": "Saguache",
    "lng": -106.14092149070521,
    "lat": 38.08627971213617
  },
  {
    "name": "Sahuarita",
    "lng": -110.96493188829221,
    "lat": 31.931892946037085
  },
  {
    "name": "Sailor Springs",
    "lng": -88.3602284825169,
    "lat": 38.76446206768336
  },
  {
    "name": "Saint Agatha",
    "lng": -68.3278443496946,
    "lat": 47.233419546279166
  },
  {
    "name": "Saint Albans",
    "lng": -73.0848689170441,
    "lat": 44.811896977239286
  },
  {
    "name": "Saint Albans",
    "lng": -69.3921977370744,
    "lat": 44.931913432496806
  },
  {
    "name": "Saint Ansgar",
    "lng": -92.91777538766317,
    "lat": 43.37987025903387
  },
  {
    "name": "Saint Bernard",
    "lng": -84.49571319670447,
    "lat": 39.171164533678294
  },
  {
    "name": "Saint Charles",
    "lng": -93.80748310795444,
    "lat": 41.28715177459342
  },
  {
    "name": "Saint Charles",
    "lng": -90.51576800585454,
    "lat": 38.79668970803657
  },
  {
    "name": "Saint Clair Shores",
    "lng": -82.89220477630285,
    "lat": 42.49330615651485
  },
  {
    "name": "Saint Edward",
    "lng": -97.8613070940175,
    "lat": 41.571552114026545
  },
  {
    "name": "Saint Francis",
    "lng": -87.87295874330097,
    "lat": 42.971638890097914
  },
  {
    "name": "Saint Francis",
    "lng": -68.87856017778991,
    "lat": 47.1427098780951
  },
  {
    "name": "Saint George",
    "lng": -69.24175534124701,
    "lat": 43.91246089345661
  },
  {
    "name": "Saint Ignace",
    "lng": -84.72555868953482,
    "lat": 45.869172043574146
  },
  {
    "name": "Saint Ignatius",
    "lng": -114.09554708745685,
    "lat": 47.31844237397107
  },
  {
    "name": "Saint Joe",
    "lng": -92.80774124991275,
    "lat": 36.02901912603672
  },
  {
    "name": "Saint John",
    "lng": -90.34624057797629,
    "lat": 38.714766849349324
  },
  {
    "name": "Saint Joseph",
    "lng": -86.48425295092603,
    "lat": 42.09825198769649
  },
  {
    "name": "Saint Leon",
    "lng": -84.96385678487401,
    "lat": 39.29335478002121
  },
  {
    "name": "Saint Louis",
    "lng": -96.85961856174656,
    "lat": 35.081230966942286
  },
  {
    "name": "Saint Louis",
    "lng": -84.61170413325708,
    "lat": 43.40828190693864
  },
  {
    "name": "Saint Louis Park",
    "lng": -93.36495599132951,
    "lat": 44.948751694760254
  },
  {
    "name": "Saint Maries",
    "lng": -116.5722885086621,
    "lat": 47.31456488501336
  },
  {
    "name": "Saint Mary's Point",
    "lng": -92.77024858623443,
    "lat": 44.912817114228865
  },
  {
    "name": "Saint Marys",
    "lng": -84.39408181654761,
    "lat": 40.547227820448605
  },
  {
    "name": "Saint Marys",
    "lng": -78.53417699070701,
    "lat": 41.45749554158459
  },
  {
    "name": "Saint Paul",
    "lng": -98.46011952253414,
    "lat": 41.21403779137436
  },
  {
    "name": "Saint Paul",
    "lng": -93.10396551581569,
    "lat": 44.947846211051306
  },
  {
    "name": "Saint Paul",
    "lng": -90.76989570366742,
    "lat": 38.85381923759995
  },
  {
    "name": "Saint Paul",
    "lng": -90.73954877359358,
    "lat": 38.84794348845579
  },
  {
    "name": "Saint Peters",
    "lng": -90.60599186988662,
    "lat": 38.782504994113
  },
  {
    "name": "Saint Petersburg",
    "lng": -82.6429520321403,
    "lat": 27.761472873650703
  },
  {
    "name": "Saint Robert",
    "lng": -92.15890432749454,
    "lat": 37.80116916232153
  },
  {
    "name": "Saint Robert",
    "lng": -92.15060493879274,
    "lat": 37.82544056670361
  },
  {
    "name": "Saint Rose",
    "lng": -89.55360739690356,
    "lat": 38.68261644189429
  },
  {
    "name": "Saint-Leonard",
    "lng": -67.92002716766932,
    "lat": 47.171472832007154
  },
  {
    "name": "SaintClair",
    "lng": -82.49298733190085,
    "lat": 42.82615405909278
  },
  {
    "name": "Sainte Genevieve",
    "lng": -90.04788086284094,
    "lat": 37.97441382891277
  },
  {
    "name": "Salado",
    "lng": -97.52648400092541,
    "lat": 30.948330233513694
  },
  {
    "name": "Salamanca",
    "lng": -78.72327635901394,
    "lat": 42.16312077853635
  },
  {
    "name": "Salamonia",
    "lng": -84.86606307320267,
    "lat": 40.381799452620896
  },
  {
    "name": "Sale City",
    "lng": -84.02137737933472,
    "lat": 31.262558228412658
  },
  {
    "name": "Salem",
    "lng": -111.67165340623743,
    "lat": 40.053738038713824
  },
  {
    "name": "Salem",
    "lng": -97.38937323863692,
    "lat": 43.72279497578732
  },
  {
    "name": "Salem",
    "lng": -95.7292878441054,
    "lat": 40.076779467931175
  },
  {
    "name": "Salem",
    "lng": -95.71535347245153,
    "lat": 40.07817760604694
  },
  {
    "name": "Salem",
    "lng": -91.82257229518181,
    "lat": 36.36975611592607
  },
  {
    "name": "Salem",
    "lng": -91.62063199098519,
    "lat": 40.8518475475319
  },
  {
    "name": "Salem",
    "lng": -91.53410011196969,
    "lat": 37.63979863264372
  },
  {
    "name": "Salem",
    "lng": -88.95807931392808,
    "lat": 38.62773148260168
  },
  {
    "name": "Salem",
    "lng": -88.93869370691668,
    "lat": 38.65418177504455
  },
  {
    "name": "Salem",
    "lng": -88.24143737013766,
    "lat": 37.26547368984033
  },
  {
    "name": "Salem",
    "lng": -86.13413902444546,
    "lat": 38.611354282057924
  },
  {
    "name": "Salem",
    "lng": -86.09774250023821,
    "lat": 38.604781980240155
  },
  {
    "name": "Salem",
    "lng": -82.97551617804295,
    "lat": 34.8870629670824
  },
  {
    "name": "Salem",
    "lng": -80.8491364206306,
    "lat": 40.9048969004117
  },
  {
    "name": "Salem",
    "lng": -80.56450193513847,
    "lat": 39.28493721439017
  },
  {
    "name": "Salem",
    "lng": -80.05543163335476,
    "lat": 37.28625312818476
  },
  {
    "name": "Salem",
    "lng": -75.47240107037437,
    "lat": 39.56808655749326
  },
  {
    "name": "Salem",
    "lng": -73.32660886637443,
    "lat": 43.17258767349681
  },
  {
    "name": "Salem",
    "lng": -72.26639374386048,
    "lat": 41.48539447858668
  },
  {
    "name": "Salem",
    "lng": -71.22023439793935,
    "lat": 42.79009165896186
  },
  {
    "name": "Salemburg",
    "lng": -78.5028457549055,
    "lat": 35.015270516544106
  },
  {
    "name": "Salesville",
    "lng": -92.27684071745182,
    "lat": 36.240816081216394
  },
  {
    "name": "Salida",
    "lng": -105.99872046728981,
    "lat": 38.530578071347485
  },
  {
    "name": "Salina",
    "lng": -111.8652056570417,
    "lat": 38.93874966459067
  },
  {
    "name": "Salina",
    "lng": -97.69647778258121,
    "lat": 38.76194319150449
  },
  {
    "name": "Salina",
    "lng": -97.63977253455313,
    "lat": 38.84259868657195
  },
  {
    "name": "Salina",
    "lng": -97.62584076790196,
    "lat": 38.85377808158087
  },
  {
    "name": "Salina",
    "lng": -97.61220708655782,
    "lat": 38.81565375263939
  },
  {
    "name": "Salina",
    "lng": -97.6023209488413,
    "lat": 38.86385592681568
  },
  {
    "name": "Salina",
    "lng": -97.5931251157971,
    "lat": 38.877626128282564
  },
  {
    "name": "Salina",
    "lng": -97.59291393441676,
    "lat": 38.862776176725724
  },
  {
    "name": "Salina",
    "lng": -97.58089650910547,
    "lat": 38.858476993777906
  },
  {
    "name": "Salina",
    "lng": -97.57284355221041,
    "lat": 38.794737433274705
  },
  {
    "name": "Salina",
    "lng": -97.56680544445548,
    "lat": 38.85113863421968
  },
  {
    "name": "Salina",
    "lng": -95.15170499236106,
    "lat": 36.29098822239084
  },
  {
    "name": "Salinas",
    "lng": -121.69180314958606,
    "lat": 36.658747184534505
  },
  {
    "name": "Salinas",
    "lng": -121.68411536696543,
    "lat": 36.66060120321075
  },
  {
    "name": "Salinas",
    "lng": -121.63649908132436,
    "lat": 36.68441925996582
  },
  {
    "name": "Saline",
    "lng": -92.97677782343291,
    "lat": 32.16312403940166
  },
  {
    "name": "Saline",
    "lng": -83.77810347621867,
    "lat": 42.17340147934049
  },
  {
    "name": "Salineville",
    "lng": -80.83442952932529,
    "lat": 40.6207221038539
  },
  {
    "name": "Salisbury",
    "lng": -92.80234959616801,
    "lat": 39.42332274141228
  },
  {
    "name": "Salisbury",
    "lng": -80.53043667402086,
    "lat": 35.65615727422814
  },
  {
    "name": "Salisbury",
    "lng": -80.52782885021061,
    "lat": 35.62036633111826
  },
  {
    "name": "Salisbury",
    "lng": -80.50849428356138,
    "lat": 35.62553353107092
  },
  {
    "name": "Salisbury",
    "lng": -80.48803252265863,
    "lat": 35.66631853687802
  },
  {
    "name": "Salisbury",
    "lng": -80.46447757563797,
    "lat": 35.62318819140536
  },
  {
    "name": "Salisbury",
    "lng": -80.46119897694521,
    "lat": 35.746315819644984
  },
  {
    "name": "Salisbury",
    "lng": -75.58685918346934,
    "lat": 38.37556051519257
  },
  {
    "name": "Salisbury",
    "lng": -73.5604718054716,
    "lat": 40.74533238862189
  },
  {
    "name": "Salisbury",
    "lng": -73.42559519469785,
    "lat": 41.98604122082356
  },
  {
    "name": "Salisbury",
    "lng": -71.76355184297137,
    "lat": 43.381130707529245
  },
  {
    "name": "Salix",
    "lng": -96.31769366704052,
    "lat": 42.33456439538974
  },
  {
    "name": "Salix",
    "lng": -96.31405221160931,
    "lat": 42.31965144840215
  },
  {
    "name": "Salix",
    "lng": -96.28822311070066,
    "lat": 42.30843005605651
  },
  {
    "name": "Salley",
    "lng": -81.30396229863801,
    "lat": 33.56652637483975
  },
  {
    "name": "Sallis",
    "lng": -89.76473465050904,
    "lat": 33.02155303403041
  },
  {
    "name": "Sallisaw",
    "lng": -94.80663059278943,
    "lat": 35.46185139101189
  },
  {
    "name": "Salmon",
    "lng": -113.89488816832642,
    "lat": 45.1742610058582
  },
  {
    "name": "Salt Lick",
    "lng": -83.61558135523238,
    "lat": 38.1193531631962
  },
  {
    "name": "Saltaire",
    "lng": -73.1949058406783,
    "lat": 40.638764117497644
  },
  {
    "name": "Saltillo",
    "lng": -88.69388736330902,
    "lat": 34.37895909929532
  },
  {
    "name": "Saltillo",
    "lng": -88.2151277834461,
    "lat": 35.37997329339507
  },
  {
    "name": "Saltillo",
    "lng": -86.29749610305373,
    "lat": 38.666233220839544
  },
  {
    "name": "Salton City",
    "lng": -115.96091747290293,
    "lat": 33.29940463219561
  },
  {
    "name": "Saltville",
    "lng": -81.76419859785813,
    "lat": 36.875098108511224
  },
  {
    "name": "Saluda",
    "lng": -82.36109986073816,
    "lat": 35.22904183544333
  },
  {
    "name": "Saluda",
    "lng": -82.34675462010422,
    "lat": 35.23769409888846
  },
  {
    "name": "Saluda",
    "lng": -82.33820475440544,
    "lat": 35.23028336630241
  },
  {
    "name": "Saluda",
    "lng": -82.33494104678743,
    "lat": 35.24618605360409
  },
  {
    "name": "Saluda",
    "lng": -82.32703011619563,
    "lat": 35.2497580722232
  },
  {
    "name": "Saluda",
    "lng": -82.32654916930623,
    "lat": 35.25048345149211
  },
  {
    "name": "Saluda",
    "lng": -81.77124296855915,
    "lat": 34.001083298605806
  },
  {
    "name": "Salyersville",
    "lng": -83.06399838516676,
    "lat": 37.746652780076296
  },
  {
    "name": "Samburg",
    "lng": -89.35328780705598,
    "lat": 36.38230838613779
  },
  {
    "name": "Sammamish",
    "lng": -122.03501845660807,
    "lat": 47.601129849708805
  },
  {
    "name": "Sammons Point",
    "lng": -87.85843145588807,
    "lat": 41.03099251147788
  },
  {
    "name": "Samson",
    "lng": -86.04634090596194,
    "lat": 31.112377410157215
  },
  {
    "name": "San Angelo",
    "lng": -100.45016763252826,
    "lat": 31.442658002063677
  },
  {
    "name": "San Antonio",
    "lng": -82.28817700582555,
    "lat": 28.330031066353484
  },
  {
    "name": "San Antonio",
    "lng": -82.27854632300831,
    "lat": 28.339872523901896
  },
  {
    "name": "San Augustine",
    "lng": -94.11084757279926,
    "lat": 31.529644375203752
  },
  {
    "name": "San Benito",
    "lng": -97.6382327505319,
    "lat": 26.13440634004173
  },
  {
    "name": "San Benito",
    "lng": -97.60743967677621,
    "lat": 26.14741448229641
  },
  {
    "name": "San Buenaventura (Ventura)",
    "lng": -119.31059984575685,
    "lat": 34.364170893770556
  },
  {
    "name": "San Buenaventura (Ventura)",
    "lng": -119.27791133333335,
    "lat": 34.310824999613736
  },
  {
    "name": "San Carlos",
    "lng": -122.2677268617063,
    "lat": 37.49825013205734
  },
  {
    "name": "San Carlos Park",
    "lng": -81.8192321336295,
    "lat": 26.476529976362922
  },
  {
    "name": "San Clemente",
    "lng": -117.62253369059232,
    "lat": 33.417887970338214
  },
  {
    "name": "San Diego",
    "lng": -117.13321399393627,
    "lat": 32.817479570796884
  },
  {
    "name": "San Diego",
    "lng": -98.23891321730385,
    "lat": 27.76089662864536
  },
  {
    "name": "San Dimas",
    "lng": -117.83557374009861,
    "lat": 34.08146192088222
  },
  {
    "name": "San Felipe",
    "lng": -96.10514123463173,
    "lat": 29.79653039717129
  },
  {
    "name": "San Gabriel",
    "lng": -118.09907116129422,
    "lat": 34.09483728215568
  },
  {
    "name": "San Joaquin",
    "lng": -120.2168646257444,
    "lat": 36.599007057900955
  },
  {
    "name": "San Joaquin",
    "lng": -120.18730797845097,
    "lat": 36.606563723744486
  },
  {
    "name": "San Jon",
    "lng": -103.33562095641581,
    "lat": 35.111979236560565
  },
  {
    "name": "San Jose",
    "lng": -89.60476371704706,
    "lat": 40.3051508330715
  },
  {
    "name": "San Juan",
    "lng": -98.15202232729627,
    "lat": 26.19038749196784
  },
  {
    "name": "San Juan Bautista",
    "lng": -121.53824427605191,
    "lat": 36.84559722824395
  },
  {
    "name": "San Juan Capistrano",
    "lng": -117.65440738268153,
    "lat": 33.50090341237854
  },
  {
    "name": "San Leandro",
    "lng": -122.16303561819508,
    "lat": 37.70477513236177
  },
  {
    "name": "San Leanna",
    "lng": -97.81946351420302,
    "lat": 30.144104350899056
  },
  {
    "name": "San Luis",
    "lng": -114.70569324499674,
    "lat": 32.48964962705559
  },
  {
    "name": "San Luis",
    "lng": -105.4227020654398,
    "lat": 37.20188803821592
  },
  {
    "name": "San Luis Obispo",
    "lng": -120.66908617833583,
    "lat": 35.26712148543854
  },
  {
    "name": "San Marcos",
    "lng": -117.1744012160191,
    "lat": 33.135007260798574
  },
  {
    "name": "San Marcos",
    "lng": -97.99811091085651,
    "lat": 29.83611538505521
  },
  {
    "name": "San Marcos",
    "lng": -97.93218261511404,
    "lat": 29.877222773481048
  },
  {
    "name": "San Mateo",
    "lng": -122.3109023694738,
    "lat": 37.559367142031896
  },
  {
    "name": "San Mateo Highlands",
    "lng": -122.34436433357446,
    "lat": 37.5259573224867
  },
  {
    "name": "San Patricio",
    "lng": -97.7764518406502,
    "lat": 27.97710914570578
  },
  {
    "name": "San Perlita",
    "lng": -97.63963630414979,
    "lat": 26.500991376157494
  },
  {
    "name": "San Saba",
    "lng": -98.72827630125337,
    "lat": 31.1959758440466
  },
  {
    "name": "San Saba",
    "lng": -98.72824928449127,
    "lat": 31.195972587375863
  },
  {
    "name": "San Saba",
    "lng": -98.72265022781203,
    "lat": 31.183723258192984
  },
  {
    "name": "San Saba",
    "lng": -98.70301613644378,
    "lat": 31.195670752046627
  },
  {
    "name": "San Saba",
    "lng": -98.68820658859038,
    "lat": 31.20078070751293
  },
  {
    "name": "San Ysidro",
    "lng": -106.77324647136865,
    "lat": 35.560488151948526
  },
  {
    "name": "Sanborn",
    "lng": -98.22395931879679,
    "lat": 46.9421144960227
  },
  {
    "name": "Sanborn",
    "lng": -95.65532827285861,
    "lat": 43.181463439148565
  },
  {
    "name": "Sanborn",
    "lng": -95.12968845072486,
    "lat": 44.209734569370106
  },
  {
    "name": "Sanctuary",
    "lng": -97.58787693363519,
    "lat": 32.91307274640983
  },
  {
    "name": "Sand City",
    "lng": -121.859198911214,
    "lat": 36.629620389099884
  },
  {
    "name": "Sand Fork",
    "lng": -80.74765323138327,
    "lat": 38.91542317373769
  },
  {
    "name": "Sand Lake",
    "lng": -85.51911673880132,
    "lat": 43.289959881710104
  },
  {
    "name": "Sand Point",
    "lng": -160.5097138417081,
    "lat": 55.31693368994049
  },
  {
    "name": "Sand Rock",
    "lng": -85.77152607363874,
    "lat": 34.23040620066705
  },
  {
    "name": "Sand Springs",
    "lng": -96.23312528509582,
    "lat": 36.15427286752748
  },
  {
    "name": "Sand Springs",
    "lng": -96.21780414909945,
    "lat": 36.15645723001563
  },
  {
    "name": "Sand Springs",
    "lng": -96.12628351873295,
    "lat": 36.137410979757554
  },
  {
    "name": "Sand Springs",
    "lng": -96.08190786008447,
    "lat": 36.14178876730876
  },
  {
    "name": "Sandborn",
    "lng": -87.18477485903891,
    "lat": 38.89670151060181
  },
  {
    "name": "Sanders",
    "lng": -84.94644456927107,
    "lat": 38.65498853824424
  },
  {
    "name": "Sandersville",
    "lng": -89.03616448693778,
    "lat": 31.78785984652033
  },
  {
    "name": "Sandersville",
    "lng": -82.8092697221151,
    "lat": 32.98260098043815
  },
  {
    "name": "Sandisfield",
    "lng": -73.12287156438664,
    "lat": 42.10839903861652
  },
  {
    "name": "Sandoval",
    "lng": -89.11927399605257,
    "lat": 38.61222548113313
  },
  {
    "name": "Sandown",
    "lng": -71.18331703922097,
    "lat": 42.933204275361014
  },
  {
    "name": "Sandpoint",
    "lng": -116.56121533354299,
    "lat": 48.28222625433051
  },
  {
    "name": "Sands Point",
    "lng": -73.71022386622373,
    "lat": 40.86097002892269
  },
  {
    "name": "Sandstone",
    "lng": -92.89077860089543,
    "lat": 46.126338065552765
  },
  {
    "name": "Sandstone",
    "lng": -92.86433548358535,
    "lat": 46.12921733881178
  },
  {
    "name": "Sandstone",
    "lng": -92.84659549556096,
    "lat": 46.13335538627242
  },
  {
    "name": "Sandusky",
    "lng": -82.83338978397796,
    "lat": 43.42100447767149
  },
  {
    "name": "Sandusky",
    "lng": -82.71421345573026,
    "lat": 41.456377999250336
  },
  {
    "name": "Sandwich",
    "lng": -88.65200719213969,
    "lat": 41.65354566992585
  },
  {
    "name": "Sandwich",
    "lng": -88.61749662332925,
    "lat": 41.64973623008181
  },
  {
    "name": "Sandy",
    "lng": -111.85100228625274,
    "lat": 40.57056579024429
  },
  {
    "name": "Sandy Creek",
    "lng": -78.1571045272299,
    "lat": 34.2854235380633
  },
  {
    "name": "Sandy Creek",
    "lng": -76.08643667647956,
    "lat": 43.6434618872814
  },
  {
    "name": "Sandy Creek",
    "lng": -76.08643232512068,
    "lat": 43.64346363330232
  },
  {
    "name": "Sandy Hook",
    "lng": -83.12417350655836,
    "lat": 38.092943843820834
  },
  {
    "name": "Sandy Oaks",
    "lng": -98.4081430870494,
    "lat": 29.182157510501046
  },
  {
    "name": "Sandy Springs",
    "lng": -84.370312506063,
    "lat": 33.93667898385479
  },
  {
    "name": "Sandyfield",
    "lng": -78.30006892621424,
    "lat": 34.373993133373
  },
  {
    "name": "Sandyville",
    "lng": -93.38628086675871,
    "lat": 41.37096723138403
  },
  {
    "name": "Sanford",
    "lng": -105.90071703060862,
    "lat": 37.257435385052844
  },
  {
    "name": "Sanford",
    "lng": -101.53177112181709,
    "lat": 35.70262364840206
  },
  {
    "name": "Sanford",
    "lng": -86.39655374703493,
    "lat": 31.30050126406413
  },
  {
    "name": "Sanford",
    "lng": -84.38039228446696,
    "lat": 43.67760309761057
  },
  {
    "name": "Sanford",
    "lng": -81.34212365703927,
    "lat": 28.804199310297175
  },
  {
    "name": "Sanford",
    "lng": -81.33787151732035,
    "lat": 28.79505110455377
  },
  {
    "name": "Sanford",
    "lng": -81.33507129251626,
    "lat": 28.795933739405235
  },
  {
    "name": "Sanford",
    "lng": -81.32705271355647,
    "lat": 28.78975635099662
  },
  {
    "name": "Sanford",
    "lng": -81.32373381416802,
    "lat": 28.816568621502533
  },
  {
    "name": "Sanford",
    "lng": -81.32103850022801,
    "lat": 28.82241000466435
  },
  {
    "name": "Sanford",
    "lng": -81.32009363315872,
    "lat": 28.813821233985028
  },
  {
    "name": "Sanford",
    "lng": -81.31934643926704,
    "lat": 28.827857454200853
  },
  {
    "name": "Sanford",
    "lng": -81.31527088090154,
    "lat": 28.817887446994753
  },
  {
    "name": "Sanford",
    "lng": -81.31390374923687,
    "lat": 28.80793643749498
  },
  {
    "name": "Sanford",
    "lng": -81.308071312953,
    "lat": 28.796574880475497
  },
  {
    "name": "Sanford",
    "lng": -81.30435940035497,
    "lat": 28.787255590319404
  },
  {
    "name": "Sanford",
    "lng": -81.30387780672201,
    "lat": 28.822088962433874
  },
  {
    "name": "Sanford",
    "lng": -81.30023597458967,
    "lat": 28.809722198254345
  },
  {
    "name": "Sanford",
    "lng": -81.29961689449824,
    "lat": 28.806471472003384
  },
  {
    "name": "Sanford",
    "lng": -81.29859983941692,
    "lat": 28.74033219850936
  },
  {
    "name": "Sanford",
    "lng": -81.29728431169113,
    "lat": 28.814067126549762
  },
  {
    "name": "Sanford",
    "lng": -81.29721398509115,
    "lat": 28.812005995960195
  },
  {
    "name": "Sanford",
    "lng": -81.29649419475183,
    "lat": 28.785436537317835
  },
  {
    "name": "Sanford",
    "lng": -81.29232984958311,
    "lat": 28.810405405726026
  },
  {
    "name": "Sanford",
    "lng": -81.2898839465671,
    "lat": 28.749826337844933
  },
  {
    "name": "Sanford",
    "lng": -81.27815725209086,
    "lat": 28.78517357581685
  },
  {
    "name": "Sanford",
    "lng": -81.27811271062072,
    "lat": 28.784653914935653
  },
  {
    "name": "Sanford",
    "lng": -81.27043216435418,
    "lat": 28.782416719162224
  },
  {
    "name": "Sanford",
    "lng": -81.27003616153293,
    "lat": 28.75700658951628
  },
  {
    "name": "Sanford",
    "lng": -81.2675292436354,
    "lat": 28.777128414429306
  },
  {
    "name": "Sanford",
    "lng": -81.26751244187591,
    "lat": 28.779307280187158
  },
  {
    "name": "Sanford",
    "lng": -81.2665222317526,
    "lat": 28.76729775803812
  },
  {
    "name": "Sanford",
    "lng": -81.26587962996003,
    "lat": 28.76911718305772
  },
  {
    "name": "Sanford",
    "lng": -81.26531911319044,
    "lat": 28.774579707222962
  },
  {
    "name": "Sanford",
    "lng": -81.26526814257797,
    "lat": 28.77719124619935
  },
  {
    "name": "Sanford",
    "lng": -81.26524103977005,
    "lat": 28.77352773543181
  },
  {
    "name": "Sanford",
    "lng": -81.26517821455258,
    "lat": 28.778258913458476
  },
  {
    "name": "Sanford",
    "lng": -81.26506858582505,
    "lat": 28.754491892120075
  },
  {
    "name": "Sanford",
    "lng": -81.26502122564237,
    "lat": 28.752287549576206
  },
  {
    "name": "Sanford",
    "lng": -81.26405608812267,
    "lat": 28.754427723128913
  },
  {
    "name": "Sanford",
    "lng": -81.26379685824702,
    "lat": 28.78025375051492
  },
  {
    "name": "Sanford",
    "lng": -81.2634722626747,
    "lat": 28.758386372606417
  },
  {
    "name": "Sanford",
    "lng": -81.26317399703022,
    "lat": 28.78028754455357
  },
  {
    "name": "Sanford",
    "lng": -81.26292202682775,
    "lat": 28.760982387642798
  },
  {
    "name": "Sanford",
    "lng": -81.26219999999891,
    "lat": 28.778821501504048
  },
  {
    "name": "Sanford",
    "lng": -81.25671697080696,
    "lat": 28.77021791365616
  },
  {
    "name": "Sanford",
    "lng": -81.25486472868225,
    "lat": 28.744824716694726
  },
  {
    "name": "Sanford",
    "lng": -81.24978687903871,
    "lat": 28.808937894611507
  },
  {
    "name": "Sanford",
    "lng": -81.24903705012254,
    "lat": 28.808951886329183
  },
  {
    "name": "Sanford",
    "lng": -81.24790809956941,
    "lat": 28.80893065012461
  },
  {
    "name": "Sanford",
    "lng": -81.24782275944395,
    "lat": 28.797289588025027
  },
  {
    "name": "Sanford",
    "lng": -81.24729026081614,
    "lat": 28.795594471314388
  },
  {
    "name": "Sanford",
    "lng": -81.24726349510927,
    "lat": 28.79608682021058
  },
  {
    "name": "Sanford",
    "lng": -81.24698469181433,
    "lat": 28.794556063996644
  },
  {
    "name": "Sanford",
    "lng": -81.24697874909279,
    "lat": 28.795251587346222
  },
  {
    "name": "Sanford",
    "lng": -81.24641048368557,
    "lat": 28.769339077295886
  },
  {
    "name": "Sanford",
    "lng": -81.2368162879299,
    "lat": 28.75314694496687
  },
  {
    "name": "Sanford",
    "lng": -81.23468265281967,
    "lat": 28.755659696152858
  },
  {
    "name": "Sanford",
    "lng": -81.2217967338798,
    "lat": 28.78867483044625
  },
  {
    "name": "Sanford",
    "lng": -81.2190482119205,
    "lat": 28.774256841388677
  },
  {
    "name": "Sanford",
    "lng": -79.23544833237958,
    "lat": 35.47510963785632
  },
  {
    "name": "Sanford",
    "lng": -79.22984262285418,
    "lat": 35.46706807034369
  },
  {
    "name": "Sanford",
    "lng": -79.22269967862985,
    "lat": 35.54603612305176
  },
  {
    "name": "Sanford",
    "lng": -79.21960695364278,
    "lat": 35.54748922147034
  },
  {
    "name": "Sanford",
    "lng": -79.21651273230988,
    "lat": 35.4361244408518
  },
  {
    "name": "Sanford",
    "lng": -79.21292541974579,
    "lat": 35.440066290163415
  },
  {
    "name": "Sanford",
    "lng": -79.19677193240601,
    "lat": 35.53801872190086
  },
  {
    "name": "Sanford",
    "lng": -79.18881754320202,
    "lat": 35.4509033288602
  },
  {
    "name": "Sanford",
    "lng": -79.18533027410389,
    "lat": 35.43419614613013
  },
  {
    "name": "Sanford",
    "lng": -79.18495895598873,
    "lat": 35.52771193110706
  },
  {
    "name": "Sanford",
    "lng": -79.17809411814913,
    "lat": 35.55450794100907
  },
  {
    "name": "Sanford",
    "lng": -79.17738831628796,
    "lat": 35.55044935289224
  },
  {
    "name": "Sanford",
    "lng": -79.17723348058206,
    "lat": 35.475511313842794
  },
  {
    "name": "Sanford",
    "lng": -79.1760761810109,
    "lat": 35.557270936014085
  },
  {
    "name": "Sanford",
    "lng": -79.17556167090969,
    "lat": 35.53923375749128
  },
  {
    "name": "Sanford",
    "lng": -79.17497377319157,
    "lat": 35.58051601548938
  },
  {
    "name": "Sanford",
    "lng": -79.1743238440821,
    "lat": 35.44579189330697
  },
  {
    "name": "Sanford",
    "lng": -79.17215795446758,
    "lat": 35.4459916955832
  },
  {
    "name": "Sanford",
    "lng": -79.17023884417277,
    "lat": 35.447885923683536
  },
  {
    "name": "Sanford",
    "lng": -79.16764559104615,
    "lat": 35.4443115372951
  },
  {
    "name": "Sanford",
    "lng": -79.16455487964723,
    "lat": 35.556078713873916
  },
  {
    "name": "Sanford",
    "lng": -79.16209685548884,
    "lat": 35.44351888214575
  },
  {
    "name": "Sanford",
    "lng": -79.15289271988676,
    "lat": 35.57083800406972
  },
  {
    "name": "Sanford",
    "lng": -79.14888907613397,
    "lat": 35.436958174581825
  },
  {
    "name": "Sanford",
    "lng": -79.14728842477378,
    "lat": 35.564555829187846
  },
  {
    "name": "Sanford",
    "lng": -79.13363363654452,
    "lat": 35.47841829074887
  },
  {
    "name": "Sanford",
    "lng": -79.09675453048669,
    "lat": 35.4641316796292
  },
  {
    "name": "Sanford",
    "lng": -79.09598068774507,
    "lat": 35.45936376239449
  },
  {
    "name": "Sanford",
    "lng": -70.7573389114735,
    "lat": 43.42442061641328
  },
  {
    "name": "Sanger",
    "lng": -119.55802714605652,
    "lat": 36.700906168828176
  },
  {
    "name": "Sanger",
    "lng": -97.16892443347376,
    "lat": 33.371097563799296
  },
  {
    "name": "Sangerville",
    "lng": -69.30688715847734,
    "lat": 45.12758645118084
  },
  {
    "name": "Sanibel",
    "lng": -82.09988568167218,
    "lat": 26.453166664920523
  },
  {
    "name": "Sansom Park",
    "lng": -97.40203396214075,
    "lat": 32.80274904264964
  },
  {
    "name": "Santa Ana",
    "lng": -117.88188479454608,
    "lat": 33.736709662302914
  },
  {
    "name": "Santa Anna",
    "lng": -99.32573187302165,
    "lat": 31.73698365265537
  },
  {
    "name": "Santa Barbara",
    "lng": -119.84236393878008,
    "lat": 34.42662442274817
  },
  {
    "name": "Santa Barbara",
    "lng": -119.70713599660837,
    "lat": 34.39877790953191
  },
  {
    "name": "Santa Clara",
    "lng": -121.96803523570448,
    "lat": 37.36463570958227
  },
  {
    "name": "Santa Clara",
    "lng": -113.65592868682954,
    "lat": 37.131257196996415
  },
  {
    "name": "Santa Clara",
    "lng": -108.15395798501547,
    "lat": 32.77556697213306
  },
  {
    "name": "Santa Clara",
    "lng": -98.15924845463189,
    "lat": 29.593245651865125
  },
  {
    "name": "Santa Clarita",
    "lng": -118.59474620831223,
    "lat": 34.456021470018065
  },
  {
    "name": "Santa Clarita",
    "lng": -118.56032651033658,
    "lat": 34.35837313255264
  },
  {
    "name": "Santa Clarita",
    "lng": -118.5561543902941,
    "lat": 34.357307345386246
  },
  {
    "name": "Santa Clarita",
    "lng": -118.49640318186347,
    "lat": 34.41757134975271
  },
  {
    "name": "Santa Claus",
    "lng": -86.92901009776294,
    "lat": 38.11661981065912
  },
  {
    "name": "Santa Claus",
    "lng": -82.32913140495917,
    "lat": 32.17108498696319
  },
  {
    "name": "Santa Cruz",
    "lng": -122.15062059826926,
    "lat": 37.02461949092968
  },
  {
    "name": "Santa Cruz",
    "lng": -122.10604510094048,
    "lat": 36.97633249282612
  },
  {
    "name": "Santa Cruz",
    "lng": -122.03454821060448,
    "lat": 36.97383190784094
  },
  {
    "name": "Santa Cruz",
    "lng": -122.03331719046783,
    "lat": 37.00095381148987
  },
  {
    "name": "Santa Fe",
    "lng": -105.98180592090843,
    "lat": 35.66179283512064
  },
  {
    "name": "Santa Fe",
    "lng": -95.10296282216802,
    "lat": 29.386480934025812
  },
  {
    "name": "Santa Fe Springs",
    "lng": -118.062503343531,
    "lat": 33.933091224231916
  },
  {
    "name": "Santa Maria",
    "lng": -120.44451695417018,
    "lat": 34.93328393835926
  },
  {
    "name": "Santa Monica",
    "lng": -118.49970401542993,
    "lat": 34.00968707085448
  },
  {
    "name": "Santa Paula",
    "lng": -119.06524171890918,
    "lat": 34.354760338086436
  },
  {
    "name": "Santa Rosa",
    "lng": -122.82761978797498,
    "lat": 38.44686858602938
  },
  {
    "name": "Santa Rosa",
    "lng": -122.8273361188987,
    "lat": 38.42807595517007
  },
  {
    "name": "Santa Rosa",
    "lng": -122.82290834424778,
    "lat": 38.427239192741254
  },
  {
    "name": "Santa Rosa",
    "lng": -122.82127443333334,
    "lat": 38.429806174718706
  },
  {
    "name": "Santa Rosa",
    "lng": -122.80969267842246,
    "lat": 38.40489422699563
  },
  {
    "name": "Santa Rosa",
    "lng": -122.80630686872207,
    "lat": 38.41600240889744
  },
  {
    "name": "Santa Rosa",
    "lng": -122.80532396765592,
    "lat": 38.40183881433193
  },
  {
    "name": "Santa Rosa",
    "lng": -122.80015772016661,
    "lat": 38.40871222382972
  },
  {
    "name": "Santa Rosa",
    "lng": -122.79646229713717,
    "lat": 38.41060596066705
  },
  {
    "name": "Santa Rosa",
    "lng": -122.79422652431026,
    "lat": 38.40536847286837
  },
  {
    "name": "Santa Rosa",
    "lng": -122.79019584170372,
    "lat": 38.38819939016354
  },
  {
    "name": "Santa Rosa",
    "lng": -122.78938334022213,
    "lat": 38.44411579120487
  },
  {
    "name": "Santa Rosa",
    "lng": -122.78769078901671,
    "lat": 38.40144935942131
  },
  {
    "name": "Santa Rosa",
    "lng": -122.77972401869464,
    "lat": 38.39636593522588
  },
  {
    "name": "Santa Rosa",
    "lng": -122.77350670909522,
    "lat": 38.37035547416736
  },
  {
    "name": "Santa Rosa",
    "lng": -122.76594256959865,
    "lat": 38.36876850379527
  },
  {
    "name": "Santa Rosa",
    "lng": -122.76097848195484,
    "lat": 38.37079840330448
  },
  {
    "name": "Santa Rosa",
    "lng": -122.76077626423907,
    "lat": 38.37330985079157
  },
  {
    "name": "Santa Rosa",
    "lng": -122.76033853605009,
    "lat": 38.369104357927846
  },
  {
    "name": "Santa Rosa",
    "lng": -122.70006889100532,
    "lat": 38.4490106145441
  },
  {
    "name": "Santa Rosa",
    "lng": -104.67735846210773,
    "lat": 34.93608669832588
  },
  {
    "name": "Santa Rosa",
    "lng": -97.82510461554115,
    "lat": 26.256111957294852
  },
  {
    "name": "Santaquin",
    "lng": -111.7932929609992,
    "lat": 39.97154078592772
  },
  {
    "name": "Santee",
    "lng": -116.9848631471904,
    "lat": 32.8555260268716
  },
  {
    "name": "Santee",
    "lng": -97.84946950927672,
    "lat": 42.83869344634596
  },
  {
    "name": "Santee",
    "lng": -80.48693420549705,
    "lat": 33.480066069450054
  },
  {
    "name": "Santee",
    "lng": -80.46848240207491,
    "lat": 33.48045852289831
  },
  {
    "name": "Sapulpa",
    "lng": -96.11629897506727,
    "lat": 36.00838657229737
  },
  {
    "name": "Sarahsville",
    "lng": -81.46965083665391,
    "lat": 39.807521240019454
  },
  {
    "name": "Saraland",
    "lng": -88.09806817098477,
    "lat": 30.831097147918026
  },
  {
    "name": "Saranac",
    "lng": -85.21053135974641,
    "lat": 42.92944800256317
  },
  {
    "name": "Saranac Lake",
    "lng": -74.1310225831542,
    "lat": 44.32464833325791
  },
  {
    "name": "Sarasota",
    "lng": -82.57580969087091,
    "lat": 27.317783536770797
  },
  {
    "name": "Sarasota",
    "lng": -82.57001936935711,
    "lat": 27.321798843819693
  },
  {
    "name": "Sarasota",
    "lng": -82.56011188224164,
    "lat": 27.319284957219274
  },
  {
    "name": "Sarasota",
    "lng": -82.5540217162015,
    "lat": 27.301922993582476
  },
  {
    "name": "Sarasota",
    "lng": -82.54160125296949,
    "lat": 27.379904980847215
  },
  {
    "name": "Sarasota",
    "lng": -82.52733037228975,
    "lat": 27.341417181758377
  },
  {
    "name": "Saratoga",
    "lng": -122.0250961738072,
    "lat": 37.26841906952041
  },
  {
    "name": "Saratoga",
    "lng": -106.81095227949783,
    "lat": 41.4515899272824
  },
  {
    "name": "Saratoga",
    "lng": -84.91583768320442,
    "lat": 40.236819787758066
  },
  {
    "name": "Saratoga",
    "lng": -77.77533453886087,
    "lat": 35.6536823051805
  },
  {
    "name": "Saratoga Springs",
    "lng": -111.91522738099223,
    "lat": 40.34511477460099
  },
  {
    "name": "Sarcoxie",
    "lng": -94.12252183177489,
    "lat": 37.06753126714602
  },
  {
    "name": "Sardinia",
    "lng": -83.79935833000027,
    "lat": 39.00849491111244
  },
  {
    "name": "Sardis",
    "lng": -89.91135491846721,
    "lat": 34.435505457276044
  },
  {
    "name": "Sardis",
    "lng": -88.29180241975074,
    "lat": 35.44271180722902
  },
  {
    "name": "Sardis",
    "lng": -83.9573201107527,
    "lat": 38.53479757044413
  },
  {
    "name": "Sardis",
    "lng": -81.76212219986007,
    "lat": 32.97558209788444
  },
  {
    "name": "Sardis City",
    "lng": -86.11258358570687,
    "lat": 34.17491332456706
  },
  {
    "name": "Sarepta",
    "lng": -93.45130856948774,
    "lat": 32.89628754997376
  },
  {
    "name": "Sargeant",
    "lng": -92.80052729398832,
    "lat": 43.80564713451377
  },
  {
    "name": "Sargent",
    "lng": -99.3704155715775,
    "lat": 41.6413082562496
  },
  {
    "name": "Sarles",
    "lng": -98.99572834008421,
    "lat": 48.94505239722405
  },
  {
    "name": "Saronville",
    "lng": -97.93884718088805,
    "lat": 40.602841322628485
  },
  {
    "name": "Sartell",
    "lng": -94.22088671274531,
    "lat": 45.618999189833175
  },
  {
    "name": "Sartell",
    "lng": -94.2109540499918,
    "lat": 45.64477950059765
  },
  {
    "name": "Sartell",
    "lng": -94.19282634228301,
    "lat": 45.59889064161056
  },
  {
    "name": "Sartell",
    "lng": -94.19258720125325,
    "lat": 45.59747340422428
  },
  {
    "name": "Sartell",
    "lng": -94.19160385689989,
    "lat": 45.598170910162665
  },
  {
    "name": "Sartell",
    "lng": -94.18821804676385,
    "lat": 45.59544989207919
  },
  {
    "name": "Sartell",
    "lng": -94.18576899875566,
    "lat": 45.59547697181828
  },
  {
    "name": "Sartell",
    "lng": -94.18491669852605,
    "lat": 45.594457159149094
  },
  {
    "name": "Sartell",
    "lng": -94.18368590602901,
    "lat": 45.59423423452273
  },
  {
    "name": "Sartell",
    "lng": -94.1769850755368,
    "lat": 45.589938772519666
  },
  {
    "name": "Sartell",
    "lng": -94.17680513270942,
    "lat": 45.59093228476735
  },
  {
    "name": "Sartell",
    "lng": -94.17237737837634,
    "lat": 45.62828844732895
  },
  {
    "name": "Sasakwa",
    "lng": -96.52533462991398,
    "lat": 34.94683675877959
  },
  {
    "name": "Sasser",
    "lng": -84.34781549745576,
    "lat": 31.719811034685065
  },
  {
    "name": "Satanta",
    "lng": -100.988247799099,
    "lat": 37.43652326316786
  },
  {
    "name": "Satartia",
    "lng": -90.54452151925803,
    "lat": 32.67195865997722
  },
  {
    "name": "Satellite Beach",
    "lng": -80.59930843542786,
    "lat": 28.17734782510098
  },
  {
    "name": "Satsuma",
    "lng": -88.06050048259914,
    "lat": 30.854898733036016
  },
  {
    "name": "Sauder Village",
    "lng": -84.29952870111381,
    "lat": 41.540747842006304
  },
  {
    "name": "Saugatuck",
    "lng": -86.2062705166774,
    "lat": 42.65938027974982
  },
  {
    "name": "Saugerties",
    "lng": -73.94839184215043,
    "lat": 42.075034894139215
  },
  {
    "name": "Sauget",
    "lng": -90.16487898474901,
    "lat": 38.587422510408054
  },
  {
    "name": "Sauget",
    "lng": -90.11766206230051,
    "lat": 38.576673336116045
  },
  {
    "name": "Sauk Centre",
    "lng": -94.95277974478881,
    "lat": 45.737184378548854
  },
  {
    "name": "Sauk Centre",
    "lng": -94.94807585024836,
    "lat": 45.716944218591124
  },
  {
    "name": "Sauk Centre",
    "lng": -94.93867963148524,
    "lat": 45.74992073420748
  },
  {
    "name": "Sauk City",
    "lng": -89.73090908537723,
    "lat": 43.27236827213546
  },
  {
    "name": "Sauk Rapids",
    "lng": -94.15718951961063,
    "lat": 45.59826256682893
  },
  {
    "name": "Sauk Rapids",
    "lng": -94.13464325044791,
    "lat": 45.57481445108358
  },
  {
    "name": "Sauk Rapids",
    "lng": -94.13027619481589,
    "lat": 45.60233225608004
  },
  {
    "name": "Sauk Rapids",
    "lng": -94.12873644612836,
    "lat": 45.60309066375546
  },
  {
    "name": "Sauk Rapids",
    "lng": -94.12748531966393,
    "lat": 45.59443486802377
  },
  {
    "name": "Sauk Rapids",
    "lng": -94.12429882090206,
    "lat": 45.57783093979949
  },
  {
    "name": "Sauk Rapids",
    "lng": -94.09342742148617,
    "lat": 45.5861961020999
  },
  {
    "name": "Sauk Village",
    "lng": -87.59868576287526,
    "lat": 41.49574528418574
  },
  {
    "name": "Sauk Village",
    "lng": -87.59155607287533,
    "lat": 41.488512312251835
  },
  {
    "name": "Sauk Village",
    "lng": -87.58939064539275,
    "lat": 41.48084860825623
  },
  {
    "name": "Sauk Village",
    "lng": -87.5683091419511,
    "lat": 41.49115681058864
  },
  {
    "name": "Sauk Village",
    "lng": -87.55939454629171,
    "lat": 41.475100856112256
  },
  {
    "name": "Sauk Village",
    "lng": -87.55501279003167,
    "lat": 41.477657875496654
  },
  {
    "name": "Saukville",
    "lng": -87.96584809537464,
    "lat": 43.389066875096304
  },
  {
    "name": "Saukville",
    "lng": -87.94414866323712,
    "lat": 43.384540184225834
  },
  {
    "name": "Saulsbury",
    "lng": -89.08771650713757,
    "lat": 35.04762020052171
  },
  {
    "name": "Sault Ste. Marie",
    "lng": -84.36812013123289,
    "lat": 46.47823401650747
  },
  {
    "name": "Sault Ste. Marie",
    "lng": -84.30167401373059,
    "lat": 46.480636774225324
  },
  {
    "name": "Sault Ste. Marie",
    "lng": -84.30146849903093,
    "lat": 46.4814418149549
  },
  {
    "name": "Sault Ste. Marie",
    "lng": -84.30111281588856,
    "lat": 46.481547626158324
  },
  {
    "name": "Sault Ste. Marie",
    "lng": -84.3008201158172,
    "lat": 46.48373379176797
  },
  {
    "name": "Sault Ste. Marie",
    "lng": -84.30072932157233,
    "lat": 46.47640095213735
  },
  {
    "name": "Sault Ste. Marie",
    "lng": -84.30029821096204,
    "lat": 46.480098846363006
  },
  {
    "name": "Sault Ste. Marie",
    "lng": -84.29991377995516,
    "lat": 46.481543571278706
  },
  {
    "name": "Sault Ste. Marie",
    "lng": -84.29970272303794,
    "lat": 46.48283152682016
  },
  {
    "name": "Sault Ste. Marie",
    "lng": -84.29955839172428,
    "lat": 46.482731576863166
  },
  {
    "name": "Sault Ste. Marie",
    "lng": -84.29881796874082,
    "lat": 46.480756139784106
  },
  {
    "name": "Sault Ste. Marie",
    "lng": -84.29754328482987,
    "lat": 46.47576807488547
  },
  {
    "name": "Sault Ste. Marie",
    "lng": -84.29583158286373,
    "lat": 46.477069660959124
  },
  {
    "name": "Sault Ste. Marie",
    "lng": -84.2955400091324,
    "lat": 46.476929384832715
  },
  {
    "name": "Sault Ste. Marie",
    "lng": -84.29289099808365,
    "lat": 46.46987969740661
  },
  {
    "name": "Sault Ste. Marie",
    "lng": -84.29252065799373,
    "lat": 46.473211141934094
  },
  {
    "name": "Saunemin",
    "lng": -88.4057450786556,
    "lat": 40.89271859775747
  },
  {
    "name": "Savage",
    "lng": -93.36319358754534,
    "lat": 44.75451618945152
  },
  {
    "name": "Savanna",
    "lng": -95.83776231513637,
    "lat": 34.82095063786873
  },
  {
    "name": "Savanna",
    "lng": -95.83586306321585,
    "lat": 34.83516201629439
  },
  {
    "name": "Savanna",
    "lng": -90.1396036817813,
    "lat": 42.09057248471348
  },
  {
    "name": "Savannah",
    "lng": -94.82792272903508,
    "lat": 39.93907736011219
  },
  {
    "name": "Savannah",
    "lng": -88.23566176544006,
    "lat": 35.221059385687916
  },
  {
    "name": "Savannah",
    "lng": -82.36615643476361,
    "lat": 40.96675426438125
  },
  {
    "name": "Savannah",
    "lng": -81.3291835294713,
    "lat": 32.06901558512963
  },
  {
    "name": "Savannah",
    "lng": -81.2963198213718,
    "lat": 32.00897686853455
  },
  {
    "name": "Savannah",
    "lng": -81.28155180505567,
    "lat": 31.99641255544382
  },
  {
    "name": "Savannah",
    "lng": -81.26469795000347,
    "lat": 31.98124770794416
  },
  {
    "name": "Savannah",
    "lng": -81.2394205556417,
    "lat": 31.93815283518445
  },
  {
    "name": "Savannah",
    "lng": -81.23661398787326,
    "lat": 31.984720724334302
  },
  {
    "name": "Savannah",
    "lng": -81.22488115890056,
    "lat": 32.0551620909573
  },
  {
    "name": "Savannah",
    "lng": -81.21912027524073,
    "lat": 32.113615550739006
  },
  {
    "name": "Savannah",
    "lng": -81.16869988668208,
    "lat": 32.03260732225382
  },
  {
    "name": "Savannah",
    "lng": -81.14376623554227,
    "lat": 32.03930326115806
  },
  {
    "name": "Savannah",
    "lng": -81.04151084749748,
    "lat": 32.047438300733646
  },
  {
    "name": "Savona",
    "lng": -77.22386092548298,
    "lat": 42.283036955701284
  },
  {
    "name": "Savonburg",
    "lng": -95.14289227057708,
    "lat": 37.74894127632458
  },
  {
    "name": "Savoonga",
    "lng": -170.48276233692678,
    "lat": 63.68076667735606
  },
  {
    "name": "Savoy",
    "lng": -96.3661132898805,
    "lat": 33.601819024748465
  },
  {
    "name": "Savoy",
    "lng": -88.25532864971977,
    "lat": 40.06020413554392
  },
  {
    "name": "Savoy",
    "lng": -73.01957962592121,
    "lat": 42.59628756637269
  },
  {
    "name": "Sawmills",
    "lng": -81.48440227226808,
    "lat": 35.78884183066328
  },
  {
    "name": "Sawmills",
    "lng": -81.47778467235035,
    "lat": 35.81671915072405
  },
  {
    "name": "Sawpit",
    "lng": -108.00124647454058,
    "lat": 37.99453563898663
  },
  {
    "name": "Sawyer",
    "lng": -101.05335572936822,
    "lat": 48.08944232611016
  },
  {
    "name": "Sawyer",
    "lng": -98.68959050701298,
    "lat": 37.500114407384046
  },
  {
    "name": "Sawyer",
    "lng": -98.68208962708616,
    "lat": 37.49787196555674
  },
  {
    "name": "Sawyer",
    "lng": -95.36394520813798,
    "lat": 34.01896380333052
  },
  {
    "name": "Sawyerville",
    "lng": -89.80201285348306,
    "lat": 39.07850214860185
  },
  {
    "name": "Saxis",
    "lng": -75.7230061522987,
    "lat": 37.926143166410654
  },
  {
    "name": "Saxman",
    "lng": -131.58896921284716,
    "lat": 55.322820823393776
  },
  {
    "name": "Saybrook",
    "lng": -88.52621779438756,
    "lat": 40.42804797811261
  },
  {
    "name": "Saylorsburg",
    "lng": -75.31789006766734,
    "lat": 40.899798253245294
  },
  {
    "name": "Sayre",
    "lng": -99.63585074296213,
    "lat": 35.29330340862122
  },
  {
    "name": "Sayre",
    "lng": -99.61368035351036,
    "lat": 35.30407499518664
  },
  {
    "name": "Sayre",
    "lng": -76.52075688302993,
    "lat": 41.985689636797886
  },
  {
    "name": "Scales Mound",
    "lng": -90.25262603019499,
    "lat": 42.47483253483904
  },
  {
    "name": "Scammon",
    "lng": -94.82293989168416,
    "lat": 37.278237815640296
  },
  {
    "name": "Scammon Bay",
    "lng": -165.5832675110884,
    "lat": 61.842632167545894
  },
  {
    "name": "Scandia",
    "lng": -97.78397630899661,
    "lat": 39.796813396283135
  },
  {
    "name": "Scandia",
    "lng": -92.82795968876309,
    "lat": 45.25387579554927
  },
  {
    "name": "Scandinavia",
    "lng": -89.14617799744649,
    "lat": 44.46069175397846
  },
  {
    "name": "Scanlon",
    "lng": -92.42988602408163,
    "lat": 46.706502252977366
  },
  {
    "name": "Scarborough",
    "lng": -70.33558708281137,
    "lat": 43.567875173422905
  },
  {
    "name": "Scarsdale",
    "lng": -73.77731450140628,
    "lat": 40.99019904589508
  },
  {
    "name": "Scarville",
    "lng": -93.61522646733069,
    "lat": 43.47064968166458
  },
  {
    "name": "Schaefferstown",
    "lng": -76.293851285595,
    "lat": 40.296849340150914
  },
  {
    "name": "Schaghticoke",
    "lng": -73.58773793027348,
    "lat": 42.899153157716675
  },
  {
    "name": "Schaller",
    "lng": -95.29668021149551,
    "lat": 42.49652526784791
  },
  {
    "name": "Schaumburg",
    "lng": -88.08403616328314,
    "lat": 42.03028940478106
  },
  {
    "name": "Schell City",
    "lng": -94.11666162168991,
    "lat": 38.01917317478344
  },
  {
    "name": "Schenectady",
    "lng": -73.92763840773219,
    "lat": 42.80247311379668
  },
  {
    "name": "Schererville",
    "lng": -87.44441843337644,
    "lat": 41.4870449872715
  },
  {
    "name": "Schertz",
    "lng": -98.25364577137987,
    "lat": 29.56500645519886
  },
  {
    "name": "Schertz",
    "lng": -98.23514531609804,
    "lat": 29.543524999289072
  },
  {
    "name": "Schertz",
    "lng": -98.22972574351871,
    "lat": 29.54609902717065
  },
  {
    "name": "Schertz",
    "lng": -98.1996029568766,
    "lat": 29.50897533612759
  },
  {
    "name": "Schiller Park",
    "lng": -87.86892886062681,
    "lat": 41.95818352874739
  },
  {
    "name": "Schlater",
    "lng": -90.34951740000382,
    "lat": 33.63972928731495
  },
  {
    "name": "Schleswig",
    "lng": -95.43520607945675,
    "lat": 42.16161556323955
  },
  {
    "name": "Schneider",
    "lng": -87.44619607546667,
    "lat": 41.19286192361363
  },
  {
    "name": "Schoenchen",
    "lng": -99.33174687433525,
    "lat": 38.71283757354188
  },
  {
    "name": "Schofield",
    "lng": -89.61423692951057,
    "lat": 44.91543936777159
  },
  {
    "name": "Schoharie",
    "lng": -74.31374009380463,
    "lat": 42.66638287305632
  },
  {
    "name": "Schoolcraft",
    "lng": -85.6338317764377,
    "lat": 42.116634680614936
  },
  {
    "name": "Schram City",
    "lng": -89.46088098859829,
    "lat": 39.16323855767331
  },
  {
    "name": "Schulter",
    "lng": -95.9565903803125,
    "lat": 35.510916872101404
  },
  {
    "name": "Schuyler",
    "lng": -97.06143482135857,
    "lat": 41.44857430684003
  },
  {
    "name": "Schuylerville",
    "lng": -73.58072601448514,
    "lat": 43.10145243044556
  },
  {
    "name": "Science Hill",
    "lng": -84.63423442346513,
    "lat": 37.175914512106644
  },
  {
    "name": "Science Village",
    "lng": -118.11634102294009,
    "lat": 34.14239582002465
  },
  {
    "name": "Scio",
    "lng": -81.0881732894412,
    "lat": 40.398983277473896
  },
  {
    "name": "Sciota",
    "lng": -90.74743299358366,
    "lat": 40.562636643317745
  },
  {
    "name": "Scipio",
    "lng": -112.10438348316498,
    "lat": 39.24917322190765
  },
  {
    "name": "Scituate",
    "lng": -71.62018714647118,
    "lat": 41.79261504238037
  },
  {
    "name": "Scobey",
    "lng": -105.42070692143866,
    "lat": 48.79042320484229
  },
  {
    "name": "Scofield",
    "lng": -111.16226593416165,
    "lat": 39.71979648988136
  },
  {
    "name": "Scooba",
    "lng": -88.47711815468736,
    "lat": 32.830179895079915
  },
  {
    "name": "Scotia",
    "lng": -98.70241501753625,
    "lat": 41.46777845521004
  },
  {
    "name": "Scotia",
    "lng": -81.24327304585069,
    "lat": 32.68049936692464
  },
  {
    "name": "Scotia",
    "lng": -73.96061322210653,
    "lat": 42.83208219451591
  },
  {
    "name": "Scotland",
    "lng": -98.47291542888716,
    "lat": 33.648205667193466
  },
  {
    "name": "Scotland",
    "lng": -98.43037995452764,
    "lat": 33.65012390085374
  },
  {
    "name": "Scotland",
    "lng": -97.72036773016136,
    "lat": 43.1483306919118
  },
  {
    "name": "Scotland",
    "lng": -82.81467424698025,
    "lat": 32.04681237301734
  },
  {
    "name": "Scotland",
    "lng": -72.08796909974758,
    "lat": 41.6978717011299
  },
  {
    "name": "Scotland Neck",
    "lng": -77.4213824540337,
    "lat": 36.13079939936405
  },
  {
    "name": "Scotsdale",
    "lng": -90.59082355512616,
    "lat": 38.39120719738399
  },
  {
    "name": "Scott",
    "lng": -92.12159093671387,
    "lat": 30.22165078286355
  },
  {
    "name": "Scott",
    "lng": -92.09455133132312,
    "lat": 30.24003916669673
  },
  {
    "name": "Scott",
    "lng": -84.58368007731775,
    "lat": 40.98895749003995
  },
  {
    "name": "Scott City",
    "lng": -100.90007501141332,
    "lat": 38.4783499135804
  },
  {
    "name": "Scott City",
    "lng": -89.53636212782686,
    "lat": 37.22435070439864
  },
  {
    "name": "Scott Town",
    "lng": -76.2279590604897,
    "lat": 42.73477953126814
  },
  {
    "name": "Scotts Hill",
    "lng": -88.25410627089943,
    "lat": 35.519514551611415
  },
  {
    "name": "Scotts Valley",
    "lng": -122.01179712815016,
    "lat": 37.055505255389015
  },
  {
    "name": "Scottsbluff",
    "lng": -103.69486916666665,
    "lat": 41.86618086714259
  },
  {
    "name": "Scottsbluff",
    "lng": -103.67896060234996,
    "lat": 41.88691245714979
  },
  {
    "name": "Scottsbluff",
    "lng": -103.66146934935303,
    "lat": 41.86812346322883
  },
  {
    "name": "Scottsboro",
    "lng": -86.04943754516226,
    "lat": 34.64328923746762
  },
  {
    "name": "Scottsboro",
    "lng": -86.03582976881953,
    "lat": 34.715113958184766
  },
  {
    "name": "Scottsburg",
    "lng": -85.78313992964205,
    "lat": 38.68647872355192
  },
  {
    "name": "Scottsburg",
    "lng": -78.79200098947422,
    "lat": 36.758729578433986
  },
  {
    "name": "Scottsville",
    "lng": -97.95230773826968,
    "lat": 39.54258340856403
  },
  {
    "name": "Scottsville",
    "lng": -94.24651597047723,
    "lat": 32.53216176042373
  },
  {
    "name": "Scottsville",
    "lng": -86.20190261780226,
    "lat": 36.75212288712972
  },
  {
    "name": "Scottsville",
    "lng": -78.4918751077957,
    "lat": 37.806266400186146
  },
  {
    "name": "Scottsville",
    "lng": -77.75557014904967,
    "lat": 43.02213317963395
  },
  {
    "name": "Scottville",
    "lng": -90.10378492746281,
    "lat": 39.47771480204788
  },
  {
    "name": "Scottville",
    "lng": -86.28083040488455,
    "lat": 43.96725407221355
  },
  {
    "name": "Scottville",
    "lng": -86.27955201956611,
    "lat": 43.950985878064174
  },
  {
    "name": "Scranton",
    "lng": -103.14271679489786,
    "lat": 46.14669518108404
  },
  {
    "name": "Scranton",
    "lng": -95.74042226592408,
    "lat": 38.77769769898799
  },
  {
    "name": "Scranton",
    "lng": -94.54906297091027,
    "lat": 42.01915759405909
  },
  {
    "name": "Scranton",
    "lng": -93.53934024030053,
    "lat": 35.360619615364854
  },
  {
    "name": "Scranton",
    "lng": -79.74418199382468,
    "lat": 33.91736861583226
  },
  {
    "name": "Scranton",
    "lng": -75.66463048145751,
    "lat": 41.40434491260457
  },
  {
    "name": "Screven",
    "lng": -82.01710272967713,
    "lat": 31.48400592115953
  },
  {
    "name": "Scribner",
    "lng": -96.66527837639742,
    "lat": 41.665187813480365
  },
  {
    "name": "Scurry",
    "lng": -96.38508630424236,
    "lat": 32.512571791424755
  },
  {
    "name": "Sea Cliff",
    "lng": -73.64720618505955,
    "lat": 40.84470751834828
  },
  {
    "name": "Sea Isle City",
    "lng": -74.69940781603451,
    "lat": 39.14797014380589
  },
  {
    "name": "SeaTac",
    "lng": -122.29858860720482,
    "lat": 47.444505053411525
  },
  {
    "name": "Seaboard",
    "lng": -77.45088833333334,
    "lat": 36.484142999826155
  },
  {
    "name": "Seaboard",
    "lng": -77.44196580258266,
    "lat": 36.49069008923902
  },
  {
    "name": "Seabrook",
    "lng": -95.02302524077798,
    "lat": 29.575204769632318
  },
  {
    "name": "Seabrook Island",
    "lng": -80.17317972653515,
    "lat": 32.582407098043625
  },
  {
    "name": "Seadrift",
    "lng": -96.71600203660064,
    "lat": 28.41329588042845
  },
  {
    "name": "Seaforth",
    "lng": -95.33101912873867,
    "lat": 44.47630256723229
  },
  {
    "name": "Seagraves",
    "lng": -102.56569267490289,
    "lat": 32.94188794776316
  },
  {
    "name": "Seagraves",
    "lng": -102.56275243267312,
    "lat": 32.94148991197421
  },
  {
    "name": "Seagrove",
    "lng": -79.79163358089343,
    "lat": 35.53312082133403
  },
  {
    "name": "Seagrove",
    "lng": -79.78910255193996,
    "lat": 35.53796429174516
  },
  {
    "name": "Seagrove",
    "lng": -79.78888298748637,
    "lat": 35.52808167910374
  },
  {
    "name": "Seagrove",
    "lng": -79.7784677823663,
    "lat": 35.53928533450312
  },
  {
    "name": "Seagrove",
    "lng": -79.77342879622702,
    "lat": 35.556858402127766
  },
  {
    "name": "Seal Beach",
    "lng": -118.08880573900264,
    "lat": 33.73843602176773
  },
  {
    "name": "Sealy",
    "lng": -96.1679011033135,
    "lat": 29.76739912060335
  },
  {
    "name": "Seaman",
    "lng": -83.5748829066405,
    "lat": 38.936891964544905
  },
  {
    "name": "Searcy",
    "lng": -91.73503947100627,
    "lat": 35.24176255976785
  },
  {
    "name": "Searsboro",
    "lng": -92.70426911481005,
    "lat": 41.57919782588768
  },
  {
    "name": "Searsmont",
    "lng": -69.19295282344143,
    "lat": 44.362334908729764
  },
  {
    "name": "Searsport",
    "lng": -68.92485003745638,
    "lat": 44.47290965057575
  },
  {
    "name": "Seaside",
    "lng": -121.81975295200891,
    "lat": 36.62239712336498
  },
  {
    "name": "Seaton",
    "lng": -90.79911312138607,
    "lat": 41.10232352700393
  },
  {
    "name": "Seatonville",
    "lng": -89.27408640408164,
    "lat": 41.364696288972375
  },
  {
    "name": "Seattle",
    "lng": -122.35127255396364,
    "lat": 47.61970192121777
  },
  {
    "name": "Sebago",
    "lng": -70.65957290197902,
    "lat": 43.89489286291161
  },
  {
    "name": "Sebastian",
    "lng": -80.48233201410905,
    "lat": 27.786798805003784
  },
  {
    "name": "Sebastian",
    "lng": -80.46872175482088,
    "lat": 27.827835523359706
  },
  {
    "name": "Sebastian",
    "lng": -80.4618343523673,
    "lat": 27.82408347950736
  },
  {
    "name": "Sebastian",
    "lng": -80.46009171144978,
    "lat": 27.8104706110142
  },
  {
    "name": "Sebastian",
    "lng": -80.45873266466783,
    "lat": 27.808923591924362
  },
  {
    "name": "Sebastian",
    "lng": -80.45789634588184,
    "lat": 27.81704927667948
  },
  {
    "name": "Sebastopol",
    "lng": -122.8331437869768,
    "lat": 38.38448812956629
  },
  {
    "name": "Sebastopol",
    "lng": -122.82796413639356,
    "lat": 38.39980816055503
  },
  {
    "name": "Sebastopol",
    "lng": -89.33352892936057,
    "lat": 32.5679456569975
  },
  {
    "name": "Sebec",
    "lng": -69.1042181790865,
    "lat": 45.24296063766952
  },
  {
    "name": "Sebeka",
    "lng": -95.087809983622,
    "lat": 46.62905071042395
  },
  {
    "name": "Sebree",
    "lng": -87.52743822066822,
    "lat": 37.604544869155205
  },
  {
    "name": "Sebring",
    "lng": -81.49484006633722,
    "lat": 27.511485338548642
  },
  {
    "name": "Sebring",
    "lng": -81.45314416600057,
    "lat": 27.476180317184824
  },
  {
    "name": "Sebring",
    "lng": -81.42609043333331,
    "lat": 27.427411666724584
  },
  {
    "name": "Sebring",
    "lng": -81.02308769494837,
    "lat": 40.920669530215385
  },
  {
    "name": "Secaucus",
    "lng": -74.06580632833473,
    "lat": 40.78099219144192
  },
  {
    "name": "Secor",
    "lng": -89.1362027908437,
    "lat": 40.73842654054077
  },
  {
    "name": "Secor",
    "lng": -89.1350138484124,
    "lat": 40.74179270071046
  },
  {
    "name": "Secretary",
    "lng": -75.94684162864685,
    "lat": 38.60823451158039
  },
  {
    "name": "Sedalia",
    "lng": -93.27570334799336,
    "lat": 38.70256502646538
  },
  {
    "name": "Sedalia",
    "lng": -93.23406962899966,
    "lat": 38.704577450239555
  },
  {
    "name": "Sedalia",
    "lng": -79.6213874013661,
    "lat": 36.076212745332505
  },
  {
    "name": "Sedan",
    "lng": -96.1841919822233,
    "lat": 37.12780123938309
  },
  {
    "name": "Sedan",
    "lng": -95.24517323011136,
    "lat": 45.57816042768627
  },
  {
    "name": "Sedgewickville",
    "lng": -89.90601842389911,
    "lat": 37.51461432036133
  },
  {
    "name": "Sedgwick",
    "lng": -102.52565912952304,
    "lat": 40.93511876495215
  },
  {
    "name": "Sedgwick",
    "lng": -97.42143683752843,
    "lat": 37.916111096195024
  },
  {
    "name": "Sedgwick",
    "lng": -90.86785320865796,
    "lat": 35.975200710894846
  },
  {
    "name": "Sedgwick",
    "lng": -68.64623648231296,
    "lat": 44.338419866712414
  },
  {
    "name": "Sedro-Woolley",
    "lng": -122.23501660627285,
    "lat": 48.509165794992015
  },
  {
    "name": "Sedro-Woolley",
    "lng": -122.21424138973634,
    "lat": 48.509047085870215
  },
  {
    "name": "Seelyville",
    "lng": -87.26678658008035,
    "lat": 39.493632849848396
  },
  {
    "name": "Seelyville",
    "lng": -87.25154147741034,
    "lat": 39.49362629595016
  },
  {
    "name": "Seguin",
    "lng": -98.0564169938437,
    "lat": 29.560612671828817
  },
  {
    "name": "Seguin",
    "lng": -97.96594213971355,
    "lat": 29.588983673811175
  },
  {
    "name": "Seibert",
    "lng": -102.86913189449152,
    "lat": 39.2983345545818
  },
  {
    "name": "Seiling",
    "lng": -98.92555297232262,
    "lat": 36.151572515237824
  },
  {
    "name": "Selah",
    "lng": -120.53870936059546,
    "lat": 46.64741847562149
  },
  {
    "name": "Selawik",
    "lng": -160.0067282995384,
    "lat": 66.59829956313294
  },
  {
    "name": "Selby",
    "lng": -100.03309116068193,
    "lat": 45.50465624882495
  },
  {
    "name": "Selden",
    "lng": -100.56719004805502,
    "lat": 39.54145393837845
  },
  {
    "name": "Seldovia",
    "lng": -151.71227583080534,
    "lat": 59.43948973069505
  },
  {
    "name": "Selfridge",
    "lng": -100.92421049145901,
    "lat": 46.04200200512175
  },
  {
    "name": "Seligman",
    "lng": -93.93880897055179,
    "lat": 36.52324367198452
  },
  {
    "name": "Selinsgrove",
    "lng": -76.86465376176481,
    "lat": 40.80023210676389
  },
  {
    "name": "Sellers",
    "lng": -79.47224706904072,
    "lat": 34.28231540609911
  },
  {
    "name": "Sellersburg",
    "lng": -85.76013426984143,
    "lat": 38.3897993017482
  },
  {
    "name": "Sellersville",
    "lng": -75.3083653144624,
    "lat": 40.36002634593376
  },
  {
    "name": "Selma",
    "lng": -119.61522081904597,
    "lat": 36.57456041855407
  },
  {
    "name": "Selma",
    "lng": -98.3133362716707,
    "lat": 29.586596548255727
  },
  {
    "name": "Selma",
    "lng": -87.0335045467717,
    "lat": 32.41655310952068
  },
  {
    "name": "Selma",
    "lng": -85.27581092119469,
    "lat": 40.18871612619801
  },
  {
    "name": "Selma",
    "lng": -78.29882058470197,
    "lat": 35.54134941427413
  },
  {
    "name": "Selma",
    "lng": -78.29679708908085,
    "lat": 35.54382438042914
  },
  {
    "name": "Selma",
    "lng": -78.28362435683367,
    "lat": 35.5186224683425
  },
  {
    "name": "Selma",
    "lng": -78.27252199881343,
    "lat": 35.528522815793366
  },
  {
    "name": "Selma",
    "lng": -78.27194667444294,
    "lat": 35.527237965345314
  },
  {
    "name": "Selma",
    "lng": -78.26519896463478,
    "lat": 35.55397864752182
  },
  {
    "name": "Selma",
    "lng": -78.2631005834276,
    "lat": 35.54073524115289
  },
  {
    "name": "Selma",
    "lng": -78.26141452393541,
    "lat": 35.55481027497847
  },
  {
    "name": "Selma",
    "lng": -78.25512550834566,
    "lat": 35.538497670498906
  },
  {
    "name": "Selma",
    "lng": -78.25470229044744,
    "lat": 35.530501453172214
  },
  {
    "name": "Selmer",
    "lng": -88.5958115404569,
    "lat": 35.17074490675452
  },
  {
    "name": "Seminary",
    "lng": -89.4941292649098,
    "lat": 31.55842951355279
  },
  {
    "name": "Seminole",
    "lng": -102.6501318088502,
    "lat": 32.720288290803595
  },
  {
    "name": "Seminole",
    "lng": -96.65015295559793,
    "lat": 35.234594849392266
  },
  {
    "name": "Senath",
    "lng": -90.16135786884648,
    "lat": 36.13400577827898
  },
  {
    "name": "Senatobia",
    "lng": -89.97584866334748,
    "lat": 34.60706560397325
  },
  {
    "name": "Seneca",
    "lng": -100.83101426821887,
    "lat": 42.04192372196005
  },
  {
    "name": "Seneca",
    "lng": -100.83079624309308,
    "lat": 42.04471028130584
  },
  {
    "name": "Seneca",
    "lng": -99.50916539954966,
    "lat": 45.060810913474384
  },
  {
    "name": "Seneca",
    "lng": -96.06874801812742,
    "lat": 39.83758590365895
  },
  {
    "name": "Seneca",
    "lng": -94.60833056990913,
    "lat": 36.83908419386569
  },
  {
    "name": "Seneca",
    "lng": -94.60384414490346,
    "lat": 36.82782693104266
  },
  {
    "name": "Seneca",
    "lng": -88.5881820210974,
    "lat": 41.32102035855322
  },
  {
    "name": "Seneca",
    "lng": -82.98603040803741,
    "lat": 34.692850292063596
  },
  {
    "name": "Seneca",
    "lng": -82.96046355385306,
    "lat": 34.68089228240022
  },
  {
    "name": "Seneca Gardens",
    "lng": -85.67713621452218,
    "lat": 38.22752341395012
  },
  {
    "name": "Senecaville",
    "lng": -81.45964487103633,
    "lat": 39.93490707140759
  },
  {
    "name": "Senoia",
    "lng": -84.55466638982291,
    "lat": 33.30934379921716
  },
  {
    "name": "Sentinel",
    "lng": -99.17347708345154,
    "lat": 35.1570894857039
  },
  {
    "name": "Sentinel Butte",
    "lng": -103.84042905786605,
    "lat": 46.91941954833928
  },
  {
    "name": "Sequim",
    "lng": -123.09391979466638,
    "lat": 48.0747506953739
  },
  {
    "name": "Sequim",
    "lng": -123.06234367707265,
    "lat": 48.08501073235474
  },
  {
    "name": "Sergeant Bluff",
    "lng": -96.36426361250301,
    "lat": 42.41189783143251
  },
  {
    "name": "Sergeant Bluff",
    "lng": -96.35137244945885,
    "lat": 42.39772716771503
  },
  {
    "name": "Sesser",
    "lng": -89.05092380948616,
    "lat": 38.090459461255435
  },
  {
    "name": "Seven Devils",
    "lng": -81.8111928982735,
    "lat": 36.14999097377357
  },
  {
    "name": "Seven Devils",
    "lng": -81.79814245735574,
    "lat": 36.1459520417828
  },
  {
    "name": "Seven Devils",
    "lng": -81.79664674957289,
    "lat": 36.14665343340152
  },
  {
    "name": "Seven Hills",
    "lng": -81.67364823637521,
    "lat": 41.38040543540061
  },
  {
    "name": "Seven Mile",
    "lng": -84.55297338115211,
    "lat": 39.4863010865842
  },
  {
    "name": "Seven Oaks",
    "lng": -94.85787671810643,
    "lat": 30.851846747555655
  },
  {
    "name": "Seven Points",
    "lng": -96.21328547920865,
    "lat": 32.32899042213627
  },
  {
    "name": "Seven Springs",
    "lng": -77.84512767873424,
    "lat": 35.224743105941215
  },
  {
    "name": "Severance",
    "lng": -104.90632629926824,
    "lat": 40.5214941587155
  },
  {
    "name": "Severance",
    "lng": -104.90279459656604,
    "lat": 40.53882274362368
  },
  {
    "name": "Severance",
    "lng": -104.89563406762947,
    "lat": 40.52630310927422
  },
  {
    "name": "Severance",
    "lng": -104.8544949336768,
    "lat": 40.539798684557965
  },
  {
    "name": "Severance",
    "lng": -95.24878890865097,
    "lat": 39.76698535026159
  },
  {
    "name": "Severn",
    "lng": -77.18928778237556,
    "lat": 36.51420375848863
  },
  {
    "name": "Severy",
    "lng": -96.22750945744367,
    "lat": 37.621981237740954
  },
  {
    "name": "Sevierville",
    "lng": -83.57025878512707,
    "lat": 35.888339123910605
  },
  {
    "name": "Seville",
    "lng": -81.86712628745853,
    "lat": 41.02090527892302
  },
  {
    "name": "Sewall's Point",
    "lng": -80.19823012010113,
    "lat": 27.19706805889421
  },
  {
    "name": "Seward",
    "lng": -149.39412167729773,
    "lat": 60.11023701838657
  },
  {
    "name": "Seward",
    "lng": -98.79402110417855,
    "lat": 38.17785792307669
  },
  {
    "name": "Seward",
    "lng": -97.1125796241907,
    "lat": 40.903679783487455
  },
  {
    "name": "Seward",
    "lng": -97.09537473875531,
    "lat": 40.91091419386984
  },
  {
    "name": "Seymour",
    "lng": -99.2580927318131,
    "lat": 33.59568623156744
  },
  {
    "name": "Seymour",
    "lng": -93.12112472055797,
    "lat": 40.68218112156826
  },
  {
    "name": "Seymour",
    "lng": -92.76936167536002,
    "lat": 37.14844390108163
  },
  {
    "name": "Seymour",
    "lng": -88.34479581255145,
    "lat": 44.51479543462253
  },
  {
    "name": "Seymour",
    "lng": -88.3289097336905,
    "lat": 44.514359945081225
  },
  {
    "name": "Seymour",
    "lng": -88.30334425803422,
    "lat": 44.511360199535794
  },
  {
    "name": "Seymour",
    "lng": -85.89203065046588,
    "lat": 38.94666076862604
  },
  {
    "name": "Shabbona",
    "lng": -88.88464066870078,
    "lat": 41.76534182319903
  },
  {
    "name": "Shadeland",
    "lng": -86.96221665514543,
    "lat": 40.350923158394544
  },
  {
    "name": "Shady Dale",
    "lng": -83.58809341300963,
    "lat": 33.400791284794344
  },
  {
    "name": "Shady Grove",
    "lng": -96.28792873758903,
    "lat": 36.1897201463885
  },
  {
    "name": "Shady Point",
    "lng": -94.66871163765465,
    "lat": 35.12784373442527
  },
  {
    "name": "Shady Shores",
    "lng": -97.040687804855,
    "lat": 33.16326646936037
  },
  {
    "name": "Shadyside",
    "lng": -80.75104457915192,
    "lat": 39.97162622405256
  },
  {
    "name": "Shafer",
    "lng": -92.75298969074743,
    "lat": 45.390025939790384
  },
  {
    "name": "Shafter",
    "lng": -119.34583028488106,
    "lat": 35.44929933763178
  },
  {
    "name": "Shafter",
    "lng": -119.28535471650787,
    "lat": 35.46917013685658
  },
  {
    "name": "Shafter",
    "lng": -119.1944342705283,
    "lat": 35.4783236615465
  },
  {
    "name": "Shageluk",
    "lng": -159.56016237919894,
    "lat": 62.667115473889375
  },
  {
    "name": "Shaker Heights",
    "lng": -81.54961153352056,
    "lat": 41.4744154767974
  },
  {
    "name": "Shakopee",
    "lng": -93.4772102065326,
    "lat": 44.77450792456106
  },
  {
    "name": "Shaktoolik",
    "lng": -161.2011306930763,
    "lat": 64.35909422893457
  },
  {
    "name": "Shalimar",
    "lng": -86.58316062294027,
    "lat": 30.443066633405202
  },
  {
    "name": "Shallotte",
    "lng": -78.4595615948307,
    "lat": 33.95329703990623
  },
  {
    "name": "Shallotte",
    "lng": -78.45941800576688,
    "lat": 33.955528263176305
  },
  {
    "name": "Shallotte",
    "lng": -78.4307933571718,
    "lat": 33.956474217141206
  },
  {
    "name": "Shallotte",
    "lng": -78.41527217499494,
    "lat": 33.942042315546374
  },
  {
    "name": "Shallotte",
    "lng": -78.40487475584284,
    "lat": 33.961531398150164
  },
  {
    "name": "Shallotte",
    "lng": -78.40298165200718,
    "lat": 33.96187437720099
  },
  {
    "name": "Shallotte",
    "lng": -78.39722504352375,
    "lat": 33.931054601905366
  },
  {
    "name": "Shallotte",
    "lng": -78.39682626354096,
    "lat": 33.98996967154755
  },
  {
    "name": "Shallotte",
    "lng": -78.39545985779777,
    "lat": 33.94600274334228
  },
  {
    "name": "Shallotte",
    "lng": -78.3949607322559,
    "lat": 33.988418019738454
  },
  {
    "name": "Shallotte",
    "lng": -78.39053085627837,
    "lat": 33.975415319468524
  },
  {
    "name": "Shallotte",
    "lng": -78.38932045300433,
    "lat": 33.93769513048709
  },
  {
    "name": "Shallotte",
    "lng": -78.38342250969986,
    "lat": 33.93898415293728
  },
  {
    "name": "Shallotte",
    "lng": -78.37660125016096,
    "lat": 33.92971311463598
  },
  {
    "name": "Shallotte",
    "lng": -78.3763021336911,
    "lat": 33.934034681075275
  },
  {
    "name": "Shallotte",
    "lng": -78.37346347743977,
    "lat": 33.914955525060094
  },
  {
    "name": "Shallotte",
    "lng": -78.3730916894789,
    "lat": 33.94742886939897
  },
  {
    "name": "Shallotte",
    "lng": -78.37247444250467,
    "lat": 33.92019484210868
  },
  {
    "name": "Shallotte",
    "lng": -78.36953132020177,
    "lat": 33.93213374532114
  },
  {
    "name": "Shallotte",
    "lng": -78.36733912416062,
    "lat": 33.97825547939727
  },
  {
    "name": "Shallotte",
    "lng": -78.36431188789167,
    "lat": 33.97923498905371
  },
  {
    "name": "Shallotte",
    "lng": -78.36290483496614,
    "lat": 33.98536360770409
  },
  {
    "name": "Shallotte",
    "lng": -78.36257520755696,
    "lat": 33.97052882494484
  },
  {
    "name": "Shallotte",
    "lng": -78.3593247864479,
    "lat": 33.98681761097153
  },
  {
    "name": "Shallotte",
    "lng": -78.35721819674306,
    "lat": 33.97770967990842
  },
  {
    "name": "Shallotte",
    "lng": -78.34900141941065,
    "lat": 33.99416670581136
  },
  {
    "name": "Shallotte",
    "lng": -78.34430755731722,
    "lat": 33.99323562993868
  },
  {
    "name": "Shallotte",
    "lng": -78.32856654478505,
    "lat": 33.98715196799167
  },
  {
    "name": "Shallotte",
    "lng": -78.32201168347402,
    "lat": 34.00779988797048
  },
  {
    "name": "Shallotte",
    "lng": -78.31484449923786,
    "lat": 34.0014620457632
  },
  {
    "name": "Shallowater",
    "lng": -101.98937725751658,
    "lat": 33.690085537125555
  },
  {
    "name": "Shambaugh",
    "lng": -95.03528381410817,
    "lat": 40.65743271487496
  },
  {
    "name": "Shamokin",
    "lng": -76.55496017150871,
    "lat": 40.78822557993444
  },
  {
    "name": "Shamrock",
    "lng": -100.24606632449674,
    "lat": 35.21550976598747
  },
  {
    "name": "Shamrock",
    "lng": -96.57736809708234,
    "lat": 35.91135866112047
  },
  {
    "name": "Shamrock Lakes",
    "lng": -85.4261371248754,
    "lat": 40.41165925023149
  },
  {
    "name": "Shannon",
    "lng": -89.74013676774969,
    "lat": 42.15243826169949
  },
  {
    "name": "Shannon",
    "lng": -88.69308259243708,
    "lat": 34.114377957174
  },
  {
    "name": "Shannon City",
    "lng": -94.26395592054992,
    "lat": 40.899019796912974
  },
  {
    "name": "Shannon Hills",
    "lng": -92.42161797961927,
    "lat": 34.61577710577695
  },
  {
    "name": "Shanty Town",
    "lng": -85.52975520456383,
    "lat": 39.053644677872505
  },
  {
    "name": "Shapleigh",
    "lng": -70.83787015028335,
    "lat": 43.55255350642159
  },
  {
    "name": "Sharon",
    "lng": -99.33819633541442,
    "lat": 36.27581579315062
  },
  {
    "name": "Sharon",
    "lng": -98.41795772588999,
    "lat": 37.25004536618938
  },
  {
    "name": "Sharon",
    "lng": -98.41143478306704,
    "lat": 37.249674435051205
  },
  {
    "name": "Sharon",
    "lng": -97.89967644867475,
    "lat": 47.597451033122105
  },
  {
    "name": "Sharon",
    "lng": -88.82505516633263,
    "lat": 36.23371512605801
  },
  {
    "name": "Sharon",
    "lng": -88.72962483223394,
    "lat": 42.50111332204672
  },
  {
    "name": "Sharon",
    "lng": -81.34340742603744,
    "lat": 34.95226308377659
  },
  {
    "name": "Sharon",
    "lng": -80.49986074826587,
    "lat": 41.23395916545982
  },
  {
    "name": "Sharon",
    "lng": -73.43753378954739,
    "lat": 41.85751796733139
  },
  {
    "name": "Sharon",
    "lng": -71.93440483192761,
    "lat": 42.816452669778705
  },
  {
    "name": "Sharon Springs",
    "lng": -101.75103640544255,
    "lat": 38.89448869601502
  },
  {
    "name": "Sharon Springs",
    "lng": -74.61283548645737,
    "lat": 42.79300708111946
  },
  {
    "name": "Sharonville",
    "lng": -84.407144282867,
    "lat": 39.28245739325388
  },
  {
    "name": "Sharpsburg",
    "lng": -94.64054450964377,
    "lat": 40.802957061407064
  },
  {
    "name": "Sharpsburg",
    "lng": -84.65047107378231,
    "lat": 33.33721718441706
  },
  {
    "name": "Sharpsburg",
    "lng": -83.93082524595835,
    "lat": 38.203917024075785
  },
  {
    "name": "Sharpsburg",
    "lng": -83.92792373716813,
    "lat": 38.20175735580746
  },
  {
    "name": "Sharpsburg",
    "lng": -77.8310955306527,
    "lat": 35.86641437394487
  },
  {
    "name": "Sharpsburg",
    "lng": -77.81904757149927,
    "lat": 35.86321959178724
  },
  {
    "name": "Sharpsburg",
    "lng": -77.74977605312866,
    "lat": 39.45746694619783
  },
  {
    "name": "Sharpsville",
    "lng": -86.08686129806776,
    "lat": 40.37953599704673
  },
  {
    "name": "Sharptown",
    "lng": -75.7191515463708,
    "lat": 38.538929450869865
  },
  {
    "name": "Shasta Lake",
    "lng": -122.37750089213692,
    "lat": 40.678972540131404
  },
  {
    "name": "Shattuck",
    "lng": -99.87736987824196,
    "lat": 36.27285521583357
  },
  {
    "name": "Shavano Park",
    "lng": -98.55638119928126,
    "lat": 29.58610458709642
  },
  {
    "name": "Shaw",
    "lng": -90.77198782148199,
    "lat": 33.60149250484726
  },
  {
    "name": "Shawano",
    "lng": -88.58437974467599,
    "lat": 44.775031456763976
  },
  {
    "name": "Shawnee",
    "lng": -97.07963470262658,
    "lat": 35.339212675540615
  },
  {
    "name": "Shawnee",
    "lng": -96.92115191132328,
    "lat": 35.357490378649096
  },
  {
    "name": "Shawnee",
    "lng": -94.84065999459885,
    "lat": 38.97635244623672
  },
  {
    "name": "Shawnee",
    "lng": -94.80761278787334,
    "lat": 39.01592775130792
  },
  {
    "name": "Shawnee",
    "lng": -82.2073483729683,
    "lat": 39.609436615411376
  },
  {
    "name": "Shawnee Hills",
    "lng": -83.13563283057542,
    "lat": 40.16004068430838
  },
  {
    "name": "Shawneetown",
    "lng": -88.18650535734523,
    "lat": 37.71578693590121
  },
  {
    "name": "Sheboygan",
    "lng": -87.757663170898,
    "lat": 43.7507010905861
  },
  {
    "name": "Sheboygan",
    "lng": -87.75677712942611,
    "lat": 43.750422839870744
  },
  {
    "name": "Sheboygan",
    "lng": -87.73180541559839,
    "lat": 43.74507479609855
  },
  {
    "name": "Sheboygan",
    "lng": -87.71844358987549,
    "lat": 43.69533501918256
  },
  {
    "name": "Sheboygan Falls",
    "lng": -87.82650551681694,
    "lat": 43.728875711135046
  },
  {
    "name": "Sheffield",
    "lng": -93.20864392928667,
    "lat": 42.89293368081891
  },
  {
    "name": "Sheffield",
    "lng": -89.73719156997815,
    "lat": 41.357171085659154
  },
  {
    "name": "Sheffield",
    "lng": -82.09118573394389,
    "lat": 41.45582753904317
  },
  {
    "name": "Sheffield",
    "lng": -73.35996467583077,
    "lat": 42.102062545320564
  },
  {
    "name": "Sheffield Lake",
    "lng": -82.0977008372847,
    "lat": 41.48838892400031
  },
  {
    "name": "Shelbina",
    "lng": -92.03953223753471,
    "lat": 39.69308880000458
  },
  {
    "name": "Shelburn",
    "lng": -87.39746567593015,
    "lat": 39.179308193700955
  },
  {
    "name": "Shelburn",
    "lng": -87.39403425510905,
    "lat": 39.18580929166218
  },
  {
    "name": "Shelburne",
    "lng": -72.68341952431075,
    "lat": 42.60344992783231
  },
  {
    "name": "Shelby",
    "lng": -111.90979310557408,
    "lat": 48.42931215558832
  },
  {
    "name": "Shelby",
    "lng": -111.90510519209022,
    "lat": 48.42575463819667
  },
  {
    "name": "Shelby",
    "lng": -111.8698887171981,
    "lat": 48.42614933851894
  },
  {
    "name": "Shelby",
    "lng": -111.85841191574127,
    "lat": 48.50763274273085
  },
  {
    "name": "Shelby",
    "lng": -111.84502831300877,
    "lat": 48.49785119525457
  },
  {
    "name": "Shelby",
    "lng": -111.83909281674153,
    "lat": 48.50144871757075
  },
  {
    "name": "Shelby",
    "lng": -97.42611299977533,
    "lat": 41.193910228910745
  },
  {
    "name": "Shelby",
    "lng": -95.45055235125845,
    "lat": 41.50674305357926
  },
  {
    "name": "Shelby",
    "lng": -90.7653440382292,
    "lat": 33.95080327671663
  },
  {
    "name": "Shelby",
    "lng": -86.36484399016294,
    "lat": 43.61124711615552
  },
  {
    "name": "Shelby",
    "lng": -82.65765017172248,
    "lat": 40.88450673854402
  },
  {
    "name": "Shelby",
    "lng": -81.63011267935978,
    "lat": 35.325817547356145
  },
  {
    "name": "Shelby",
    "lng": -81.61566972747173,
    "lat": 35.29492322247793
  },
  {
    "name": "Shelby",
    "lng": -81.60808714295979,
    "lat": 35.278020302906086
  },
  {
    "name": "Shelby",
    "lng": -81.60099081760741,
    "lat": 35.25466135026521
  },
  {
    "name": "Shelby",
    "lng": -81.5987571718651,
    "lat": 35.277976905615965
  },
  {
    "name": "Shelby",
    "lng": -81.57059845481248,
    "lat": 35.243040943256
  },
  {
    "name": "Shelby",
    "lng": -81.53886804927126,
    "lat": 35.28960903286976
  },
  {
    "name": "Shelby",
    "lng": -81.5096429757862,
    "lat": 35.3288368159015
  },
  {
    "name": "Shelby",
    "lng": -81.49527532165713,
    "lat": 35.262623034077194
  },
  {
    "name": "Shelbyville",
    "lng": -92.0403727230235,
    "lat": 39.80716206916057
  },
  {
    "name": "Shelbyville",
    "lng": -88.82007372674903,
    "lat": 39.39065582354396
  },
  {
    "name": "Shelbyville",
    "lng": -88.7985189731427,
    "lat": 39.40991940218887
  },
  {
    "name": "Shelbyville",
    "lng": -86.45165904644294,
    "lat": 35.49922656777715
  },
  {
    "name": "Shelbyville",
    "lng": -85.81050495983501,
    "lat": 39.58603066360147
  },
  {
    "name": "Shelbyville",
    "lng": -85.78972611104103,
    "lat": 39.536483781812635
  },
  {
    "name": "Shelbyville",
    "lng": -85.78096819607812,
    "lat": 39.55825151883717
  },
  {
    "name": "Shelbyville",
    "lng": -85.77377987842347,
    "lat": 39.55335192552141
  },
  {
    "name": "Shelbyville",
    "lng": -85.7684917798702,
    "lat": 39.52106122176887
  },
  {
    "name": "Shelbyville",
    "lng": -85.24517104275735,
    "lat": 38.20300632076247
  },
  {
    "name": "Shelbyville",
    "lng": -85.23912421919843,
    "lat": 38.203744157348865
  },
  {
    "name": "Shelbyville",
    "lng": -85.22995567264684,
    "lat": 38.20601361329087
  },
  {
    "name": "Sheldahl",
    "lng": -93.69548000368177,
    "lat": 41.86379385413332
  },
  {
    "name": "Sheldon",
    "lng": -97.49250793788423,
    "lat": 46.58727356532597
  },
  {
    "name": "Sheldon",
    "lng": -95.84388014990265,
    "lat": 43.18021058435327
  },
  {
    "name": "Sheldon",
    "lng": -94.29571001587959,
    "lat": 37.658363058903205
  },
  {
    "name": "Sheldon",
    "lng": -90.95666508568581,
    "lat": 45.311904137950116
  },
  {
    "name": "Sheldon",
    "lng": -87.56580746227198,
    "lat": 40.771012641159054
  },
  {
    "name": "Shell Lake",
    "lng": -91.9033423259413,
    "lat": 45.73227873718077
  },
  {
    "name": "Shell Rock",
    "lng": -92.58142213118285,
    "lat": 42.71288272783642
  },
  {
    "name": "Shelley",
    "lng": -112.1260605598231,
    "lat": 43.37949807772136
  },
  {
    "name": "Shellman",
    "lng": -84.61637739849265,
    "lat": 31.75701338995992
  },
  {
    "name": "Shellsburg",
    "lng": -91.87092016541595,
    "lat": 42.092987759419366
  },
  {
    "name": "Shelly",
    "lng": -96.82501599688817,
    "lat": 47.45604736365786
  },
  {
    "name": "Shelly",
    "lng": -96.82393324999855,
    "lat": 47.45553615009211
  },
  {
    "name": "Shelly",
    "lng": -96.81958113431418,
    "lat": 47.4580827644679
  },
  {
    "name": "Shelton",
    "lng": -123.11228241125599,
    "lat": 47.21851074495375
  },
  {
    "name": "Shelton",
    "lng": -98.73063811906232,
    "lat": 40.77880674742595
  },
  {
    "name": "Shelton",
    "lng": -73.13831487318869,
    "lat": 41.30600470857068
  },
  {
    "name": "Shenandoah",
    "lng": -95.4556602075999,
    "lat": 30.18401568921637
  },
  {
    "name": "Shenandoah",
    "lng": -95.39761856016402,
    "lat": 40.75519891608772
  },
  {
    "name": "Shenandoah",
    "lng": -95.38859291474628,
    "lat": 40.74915004586013
  },
  {
    "name": "Shenandoah",
    "lng": -95.38620295460288,
    "lat": 40.767478078732395
  },
  {
    "name": "Shenandoah",
    "lng": -95.38603616923903,
    "lat": 40.75934406519223
  },
  {
    "name": "Shenandoah",
    "lng": -95.3693070732547,
    "lat": 40.75908996584778
  },
  {
    "name": "Shenandoah",
    "lng": -78.61679810873245,
    "lat": 38.487658041861884
  },
  {
    "name": "Shepherd",
    "lng": -95.00206537962347,
    "lat": 30.49096346306054
  },
  {
    "name": "Shepherd",
    "lng": -84.69268136363948,
    "lat": 43.52346576747246
  },
  {
    "name": "Shepherdstown",
    "lng": -77.80481207234438,
    "lat": 39.43193907503037
  },
  {
    "name": "Shepherdsville",
    "lng": -85.70307375797593,
    "lat": 37.98971391992709
  },
  {
    "name": "Sherborn",
    "lng": -71.3753217838585,
    "lat": 42.23417744878531
  },
  {
    "name": "Sherburn",
    "lng": -94.72732239630696,
    "lat": 43.65476414005121
  },
  {
    "name": "Sherburne",
    "lng": -75.4964990669603,
    "lat": 42.68064894463604
  },
  {
    "name": "Sheridan",
    "lng": -112.19463373666063,
    "lat": 45.45883011694322
  },
  {
    "name": "Sheridan",
    "lng": -107.0193624988141,
    "lat": 44.79315727564903
  },
  {
    "name": "Sheridan",
    "lng": -106.98809362868838,
    "lat": 44.786383846702506
  },
  {
    "name": "Sheridan",
    "lng": -106.98707546002686,
    "lat": 44.788714312816595
  },
  {
    "name": "Sheridan",
    "lng": -106.98695703644208,
    "lat": 44.778603546298335
  },
  {
    "name": "Sheridan",
    "lng": -106.98044847889902,
    "lat": 44.78282429488315
  },
  {
    "name": "Sheridan",
    "lng": -106.97745297270677,
    "lat": 44.79401146562563
  },
  {
    "name": "Sheridan",
    "lng": -106.97494825704054,
    "lat": 44.84446608173319
  },
  {
    "name": "Sheridan",
    "lng": -106.97472511468425,
    "lat": 44.83323735373924
  },
  {
    "name": "Sheridan",
    "lng": -106.96572528384888,
    "lat": 44.755684839742585
  },
  {
    "name": "Sheridan",
    "lng": -106.96432249309522,
    "lat": 44.79205610669284
  },
  {
    "name": "Sheridan",
    "lng": -106.96103860448929,
    "lat": 44.83039488354261
  },
  {
    "name": "Sheridan",
    "lng": -106.95884842404315,
    "lat": 44.762235006518054
  },
  {
    "name": "Sheridan",
    "lng": -106.95623190603558,
    "lat": 44.82528828796977
  },
  {
    "name": "Sheridan",
    "lng": -106.95384633258432,
    "lat": 44.82020227220841
  },
  {
    "name": "Sheridan",
    "lng": -106.9533621085335,
    "lat": 44.8227757946834
  },
  {
    "name": "Sheridan",
    "lng": -106.95191211340727,
    "lat": 44.74649120252296
  },
  {
    "name": "Sheridan",
    "lng": -106.93210362931461,
    "lat": 44.766087416742685
  },
  {
    "name": "Sheridan",
    "lng": -106.92791571824883,
    "lat": 44.80174205297623
  },
  {
    "name": "Sheridan",
    "lng": -106.9240754609395,
    "lat": 44.793220635563905
  },
  {
    "name": "Sheridan",
    "lng": -105.01812909392895,
    "lat": 39.64688469048782
  },
  {
    "name": "Sheridan",
    "lng": -94.61460190059262,
    "lat": 40.51717432251122
  },
  {
    "name": "Sheridan",
    "lng": -92.4237341298771,
    "lat": 34.299705606565375
  },
  {
    "name": "Sheridan",
    "lng": -88.68410530181218,
    "lat": 41.52479652834503
  },
  {
    "name": "Sheridan",
    "lng": -88.66287207864235,
    "lat": 41.539981918853194
  },
  {
    "name": "Sheridan",
    "lng": -86.2198505699415,
    "lat": 40.132059830859625
  },
  {
    "name": "Sheridan",
    "lng": -85.0738285051035,
    "lat": 43.20993634515382
  },
  {
    "name": "Sheridan Lake",
    "lng": -102.29415300006856,
    "lat": 38.466689063111296
  },
  {
    "name": "Sherman",
    "lng": -96.62177208318562,
    "lat": 33.627280379139236
  },
  {
    "name": "Sherman",
    "lng": -96.47624544270893,
    "lat": 43.75772189055426
  },
  {
    "name": "Sherman",
    "lng": -89.60655939105123,
    "lat": 39.88765320321899
  },
  {
    "name": "Sherman",
    "lng": -88.85788923311881,
    "lat": 34.37333759114418
  },
  {
    "name": "Sherman",
    "lng": -88.83784210277415,
    "lat": 34.36055422473317
  },
  {
    "name": "Sherman",
    "lng": -88.83782214716328,
    "lat": 34.36054755489701
  },
  {
    "name": "Sherman",
    "lng": -79.59462593443934,
    "lat": 42.1589198872005
  },
  {
    "name": "Sherman",
    "lng": -73.49876078552072,
    "lat": 41.580315499446705
  },
  {
    "name": "Sherman",
    "lng": -68.36149927127958,
    "lat": 45.89748671859554
  },
  {
    "name": "Sherrard",
    "lng": -90.50516780656467,
    "lat": 41.31896298233653
  },
  {
    "name": "Sherrill",
    "lng": -91.95182950167768,
    "lat": 34.38507213745484
  },
  {
    "name": "Sherrill",
    "lng": -90.78422280187885,
    "lat": 42.6045814269471
  },
  {
    "name": "Sherrodsville",
    "lng": -81.24409822187286,
    "lat": 40.49449926137434
  },
  {
    "name": "Sherwood",
    "lng": -101.63288487793213,
    "lat": 48.96185700167485
  },
  {
    "name": "Sherwood",
    "lng": -92.20283430738876,
    "lat": 34.850878654647616
  },
  {
    "name": "Sherwood",
    "lng": -85.23929782088831,
    "lat": 41.99962927095519
  },
  {
    "name": "Sherwood",
    "lng": -84.55307559051421,
    "lat": 41.28882781089211
  },
  {
    "name": "Shevlin",
    "lng": -95.25952858891388,
    "lat": 47.53015260989252
  },
  {
    "name": "Sheyenne",
    "lng": -99.11704121410457,
    "lat": 47.82755243206702
  },
  {
    "name": "Sheyenne",
    "lng": -99.11550737322861,
    "lat": 47.82285872786432
  },
  {
    "name": "Shickley",
    "lng": -97.72403170901171,
    "lat": 40.41636227420375
  },
  {
    "name": "Shidler",
    "lng": -96.65963330121247,
    "lat": 36.78076042090798
  },
  {
    "name": "Shillington",
    "lng": -75.96696659976793,
    "lat": 40.30290172162702
  },
  {
    "name": "Shiloh",
    "lng": -89.942807311517,
    "lat": 38.547363270562904
  },
  {
    "name": "Shiloh",
    "lng": -89.94082738033619,
    "lat": 38.552706410090806
  },
  {
    "name": "Shiloh",
    "lng": -89.93622176233582,
    "lat": 38.54952057134755
  },
  {
    "name": "Shiloh",
    "lng": -89.93443692453697,
    "lat": 38.5357148754777
  },
  {
    "name": "Shiloh",
    "lng": -89.91490858910782,
    "lat": 38.55366381089084
  },
  {
    "name": "Shiloh",
    "lng": -85.88885661583177,
    "lat": 34.47478494927906
  },
  {
    "name": "Shiloh",
    "lng": -85.87506203009337,
    "lat": 34.460788278859305
  },
  {
    "name": "Shiloh",
    "lng": -84.69896011609654,
    "lat": 32.81198556022264
  },
  {
    "name": "Shiloh",
    "lng": -82.60151684861619,
    "lat": 40.96937846635857
  },
  {
    "name": "Shiloh",
    "lng": -76.79199435391546,
    "lat": 39.973378372772686
  },
  {
    "name": "Shiner",
    "lng": -97.17338620084438,
    "lat": 29.433230363445844
  },
  {
    "name": "Shingletown",
    "lng": -121.86012333886569,
    "lat": 40.50336161578772
  },
  {
    "name": "Shinnston",
    "lng": -80.29856302058056,
    "lat": 39.392601091969475
  },
  {
    "name": "Shiocton",
    "lng": -88.57666190664102,
    "lat": 44.44483081606303
  },
  {
    "name": "Shipman",
    "lng": -90.04524980704335,
    "lat": 39.12026175820348
  },
  {
    "name": "Shipshewana",
    "lng": -85.57656368196548,
    "lat": 41.675459586127204
  },
  {
    "name": "Shirley",
    "lng": -92.31376366708028,
    "lat": 35.64423218890162
  },
  {
    "name": "Shirley",
    "lng": -85.58009702992734,
    "lat": 39.890999622706154
  },
  {
    "name": "Shirley",
    "lng": -71.64709112794425,
    "lat": 42.572525429230915
  },
  {
    "name": "Shirley",
    "lng": -69.62653012728839,
    "lat": 45.352964887139024
  },
  {
    "name": "Shishmaref",
    "lng": -166.1117021381757,
    "lat": 66.24034846827459
  },
  {
    "name": "Shively",
    "lng": -85.81378649728583,
    "lat": 38.1968668788503
  },
  {
    "name": "Shoal Creek Drive",
    "lng": -94.52287753721144,
    "lat": 37.03705794482161
  },
  {
    "name": "Shoal Creek Estates",
    "lng": -94.49196762351836,
    "lat": 37.017190206705195
  },
  {
    "name": "Shoals",
    "lng": -86.79400166257831,
    "lat": 38.666725465046056
  },
  {
    "name": "Shoemakersville",
    "lng": -75.96995901974361,
    "lat": 40.500703128309745
  },
  {
    "name": "Sholes",
    "lng": -97.29461952431893,
    "lat": 42.33481313138241
  },
  {
    "name": "Shongaloo",
    "lng": -93.29550107097032,
    "lat": 32.941558068148765
  },
  {
    "name": "Shoreacres",
    "lng": -95.01640776244236,
    "lat": 29.620533569960767
  },
  {
    "name": "Shoreham",
    "lng": -86.51104990203642,
    "lat": 42.0605590878302
  },
  {
    "name": "Shoreham",
    "lng": -72.90719634625898,
    "lat": 40.95716692137218
  },
  {
    "name": "Shoreline",
    "lng": -122.34236842210248,
    "lat": 47.75636726009872
  },
  {
    "name": "Shoreview",
    "lng": -93.1358574771903,
    "lat": 45.08423759852137
  },
  {
    "name": "Shorewood",
    "lng": -93.5743742226442,
    "lat": 44.91612563732572
  },
  {
    "name": "Shorewood",
    "lng": -88.24167477748072,
    "lat": 41.50003187392454
  },
  {
    "name": "Shorewood",
    "lng": -88.24052445598166,
    "lat": 41.539435372611706
  },
  {
    "name": "Shorewood",
    "lng": -88.21307514121595,
    "lat": 41.518040312045
  },
  {
    "name": "Shorewood",
    "lng": -87.88564401938899,
    "lat": 43.09122433002164
  },
  {
    "name": "Shorewood Hills",
    "lng": -89.44686783766325,
    "lat": 43.07880911218973
  },
  {
    "name": "Shorter",
    "lng": -85.96967604904444,
    "lat": 32.39650411313456
  },
  {
    "name": "Shorter",
    "lng": -85.9501742483899,
    "lat": 32.405801230931736
  },
  {
    "name": "Shorter",
    "lng": -85.92507825294119,
    "lat": 32.39718479689985
  },
  {
    "name": "Shortsville",
    "lng": -77.22352357748218,
    "lat": 42.955474157673876
  },
  {
    "name": "Shoshone",
    "lng": -114.40552273156315,
    "lat": 42.93693678032586
  },
  {
    "name": "Shoshoni",
    "lng": -108.16171939518792,
    "lat": 43.240557389650796
  },
  {
    "name": "Shoshoni",
    "lng": -108.13793488024952,
    "lat": 43.23527365890375
  },
  {
    "name": "Shoshoni",
    "lng": -108.10461627813177,
    "lat": 43.238147722613476
  },
  {
    "name": "Show Low",
    "lng": -110.04253864625834,
    "lat": 34.241887631647884
  },
  {
    "name": "Shreve",
    "lng": -82.0217621188795,
    "lat": 40.6814381364544
  },
  {
    "name": "Shreveport",
    "lng": -93.90210708041394,
    "lat": 32.38856193715021
  },
  {
    "name": "Shreveport",
    "lng": -93.84906363136143,
    "lat": 32.47480888240168
  },
  {
    "name": "Shreveport",
    "lng": -93.79594157122091,
    "lat": 32.46601226578242
  },
  {
    "name": "Shrewsbury",
    "lng": -90.32817420048222,
    "lat": 38.58657905955223
  },
  {
    "name": "Shrewsbury",
    "lng": -76.67988047349556,
    "lat": 39.77132183510189
  },
  {
    "name": "Shrewsbury",
    "lng": -71.71543472606226,
    "lat": 42.28421085403184
  },
  {
    "name": "Shubert",
    "lng": -95.68361424358645,
    "lat": 40.235918359073935
  },
  {
    "name": "Shubuta",
    "lng": -88.70205040176629,
    "lat": 31.862037512399795
  },
  {
    "name": "Shueyville",
    "lng": -91.6513018592795,
    "lat": 41.846192647372256
  },
  {
    "name": "Shullsburg",
    "lng": -90.23485585933751,
    "lat": 42.57324985752784
  },
  {
    "name": "Shumway",
    "lng": -88.65320642981273,
    "lat": 39.18343309616014
  },
  {
    "name": "Shungnak",
    "lng": -157.15166017319592,
    "lat": 66.88132548163803
  },
  {
    "name": "Shuqualak",
    "lng": -88.56987062714731,
    "lat": 32.979604187636205
  },
  {
    "name": "Shutesbury",
    "lng": -72.41929808975613,
    "lat": 42.456635240256524
  },
  {
    "name": "Sibley",
    "lng": -97.9652936133037,
    "lat": 47.21867450773195
  },
  {
    "name": "Sibley",
    "lng": -95.74319789460931,
    "lat": 43.40148144227832
  },
  {
    "name": "Sibley",
    "lng": -94.20088305737004,
    "lat": 39.17582327932544
  },
  {
    "name": "Sibley",
    "lng": -93.29468636810276,
    "lat": 32.54227969201938
  },
  {
    "name": "Sibley",
    "lng": -88.3786613407771,
    "lat": 40.58739487656787
  },
  {
    "name": "Sicily Island",
    "lng": -91.65978195255326,
    "lat": 31.84939715566646
  },
  {
    "name": "Sidell",
    "lng": -87.82359133968268,
    "lat": 39.90970844016953
  },
  {
    "name": "Sidney",
    "lng": -104.1992179861438,
    "lat": 47.722119465464424
  },
  {
    "name": "Sidney",
    "lng": -104.18617161980448,
    "lat": 47.725240570895906
  },
  {
    "name": "Sidney",
    "lng": -104.18458528036669,
    "lat": 47.71738904578557
  },
  {
    "name": "Sidney",
    "lng": -104.16091925062418,
    "lat": 47.709986797447044
  },
  {
    "name": "Sidney",
    "lng": -104.15112155240033,
    "lat": 47.727076340393396
  },
  {
    "name": "Sidney",
    "lng": -102.96858250125219,
    "lat": 41.134390186846446
  },
  {
    "name": "Sidney",
    "lng": -95.6443169313785,
    "lat": 40.746255026135984
  },
  {
    "name": "Sidney",
    "lng": -91.65825950813537,
    "lat": 36.00435328221349
  },
  {
    "name": "Sidney",
    "lng": -88.07205239490578,
    "lat": 40.0249676657126
  },
  {
    "name": "Sidney",
    "lng": -85.7426320494721,
    "lat": 41.1049876277281
  },
  {
    "name": "Sidney",
    "lng": -84.16727147928636,
    "lat": 40.288846606909345
  },
  {
    "name": "Sidney",
    "lng": -75.398302590651,
    "lat": 42.30661040451324
  },
  {
    "name": "Sidney",
    "lng": -69.75210739795257,
    "lat": 44.44624188418461
  },
  {
    "name": "Sidon",
    "lng": -90.20807731134828,
    "lat": 33.40793119235633
  },
  {
    "name": "Sierra Madre",
    "lng": -118.05038271426243,
    "lat": 34.168755179017595
  },
  {
    "name": "Sierra View",
    "lng": -75.44762228036237,
    "lat": 41.00082047553253
  },
  {
    "name": "Sierra Vista",
    "lng": -110.27247766880755,
    "lat": 31.520855214728524
  },
  {
    "name": "Sigel",
    "lng": -88.49447901821563,
    "lat": 39.225663295102464
  },
  {
    "name": "Signal Hill",
    "lng": -118.16834884637032,
    "lat": 33.803014095539076
  },
  {
    "name": "Signal Mountain",
    "lng": -85.34655133972758,
    "lat": 35.14302170962498
  },
  {
    "name": "Sigourney",
    "lng": -92.20444240934737,
    "lat": 41.33402462624913
  },
  {
    "name": "Sigurd",
    "lng": -111.96786824324568,
    "lat": 38.84939025650136
  },
  {
    "name": "Sikes",
    "lng": -92.48626999937801,
    "lat": 32.078981816629465
  },
  {
    "name": "Sikeston",
    "lng": -89.58735812583254,
    "lat": 36.88785634724201
  },
  {
    "name": "Silas",
    "lng": -88.32592539039432,
    "lat": 31.7678056041712
  },
  {
    "name": "Siler City",
    "lng": -79.48936933765687,
    "lat": 35.75211005907089
  },
  {
    "name": "Siler City",
    "lng": -79.46949398827687,
    "lat": 35.740918072102524
  },
  {
    "name": "Siler City",
    "lng": -79.46108326782361,
    "lat": 35.72376213590354
  },
  {
    "name": "Siler City",
    "lng": -79.45345010233595,
    "lat": 35.75094069930233
  },
  {
    "name": "Siler City",
    "lng": -79.41969349741342,
    "lat": 35.72354008424026
  },
  {
    "name": "Silerton",
    "lng": -88.80353152596817,
    "lat": 35.340512982314905
  },
  {
    "name": "Silex",
    "lng": -91.05773863041497,
    "lat": 39.12524715495876
  },
  {
    "name": "Silo",
    "lng": -96.47494830874874,
    "lat": 34.03576754096707
  },
  {
    "name": "Siloam",
    "lng": -83.08122523016661,
    "lat": 33.536589266747214
  },
  {
    "name": "Siloam Springs",
    "lng": -94.53932889195798,
    "lat": 36.21087349198635
  },
  {
    "name": "Siloam Springs",
    "lng": -94.53652291738052,
    "lat": 36.22458232715116
  },
  {
    "name": "Siloam Springs",
    "lng": -94.53568192270784,
    "lat": 36.22200016042642
  },
  {
    "name": "Siloam Springs",
    "lng": -94.53549593787737,
    "lat": 36.2279118346599
  },
  {
    "name": "Siloam Springs",
    "lng": -94.5339489749523,
    "lat": 36.221917536513565
  },
  {
    "name": "Siloam Springs",
    "lng": -94.53177403628304,
    "lat": 36.183795539227475
  },
  {
    "name": "Siloam Springs",
    "lng": -94.51691716378753,
    "lat": 36.12617314257545
  },
  {
    "name": "Silsbee",
    "lng": -94.1764119814544,
    "lat": 30.34564456731896
  },
  {
    "name": "Silt",
    "lng": -107.65219170055202,
    "lat": 39.549733369961125
  },
  {
    "name": "Silt",
    "lng": -107.65218970973928,
    "lat": 39.54973120205187
  },
  {
    "name": "Silt",
    "lng": -107.64041094886952,
    "lat": 39.54583076887927
  },
  {
    "name": "Silt",
    "lng": -107.63800632441433,
    "lat": 39.53234272266856
  },
  {
    "name": "Silver Bay",
    "lng": -91.27750963297721,
    "lat": 47.29325297734348
  },
  {
    "name": "Silver City",
    "lng": -108.26986892969829,
    "lat": 32.778299253014
  },
  {
    "name": "Silver City",
    "lng": -95.6379491171292,
    "lat": 41.111419701344005
  },
  {
    "name": "Silver City",
    "lng": -90.49675335333286,
    "lat": 33.09516306742764
  },
  {
    "name": "Silver Cliff",
    "lng": -105.4099284557333,
    "lat": 38.1200906785237
  },
  {
    "name": "Silver Creek",
    "lng": -97.66517071442622,
    "lat": 41.315390014526464
  },
  {
    "name": "Silver Creek",
    "lng": -94.46916839033152,
    "lat": 37.03938959411551
  },
  {
    "name": "Silver Creek",
    "lng": -90.00231563277862,
    "lat": 31.604792202270062
  },
  {
    "name": "Silver Creek",
    "lng": -79.16764433583951,
    "lat": 42.542598363445066
  },
  {
    "name": "Silver Grove",
    "lng": -84.39843033562732,
    "lat": 39.04095116715
  },
  {
    "name": "Silver Lake",
    "lng": -121.04764157404784,
    "lat": 43.12559072706544
  },
  {
    "name": "Silver Lake",
    "lng": -95.85615360711506,
    "lat": 39.09942238307304
  },
  {
    "name": "Silver Lake",
    "lng": -94.20779988719809,
    "lat": 44.905718838639146
  },
  {
    "name": "Silver Lake",
    "lng": -94.19907722313215,
    "lat": 44.90785367304404
  },
  {
    "name": "Silver Lake",
    "lng": -94.19822717565255,
    "lat": 44.90411041377927
  },
  {
    "name": "Silver Lake",
    "lng": -85.89311294768028,
    "lat": 41.07418127157279
  },
  {
    "name": "Silver Lake",
    "lng": -81.46051300297877,
    "lat": 41.15934755431983
  },
  {
    "name": "Silver Plume",
    "lng": -105.72674484889808,
    "lat": 39.69576032157937
  },
  {
    "name": "Silver Spring",
    "lng": -77.02049593446962,
    "lat": 39.00285451569659
  },
  {
    "name": "Silver Springs",
    "lng": -78.08551092910443,
    "lat": 42.66050884985709
  },
  {
    "name": "Silverhill",
    "lng": -87.74949718890312,
    "lat": 30.545597103557974
  },
  {
    "name": "Silverstreet",
    "lng": -81.71415648663698,
    "lat": 34.21725844055809
  },
  {
    "name": "Silverthorne",
    "lng": -106.08699270335845,
    "lat": 39.65654895463879
  },
  {
    "name": "Silverton",
    "lng": -107.66464249526399,
    "lat": 37.811092838099526
  },
  {
    "name": "Silverton",
    "lng": -101.3040483004675,
    "lat": 34.471377489705375
  },
  {
    "name": "Silverton",
    "lng": -84.40089430219817,
    "lat": 39.18821276425012
  },
  {
    "name": "Silvis",
    "lng": -90.41282649847876,
    "lat": 41.50175232767554
  },
  {
    "name": "Silvis",
    "lng": -90.3965495107568,
    "lat": 41.4767021545188
  },
  {
    "name": "Simi Valley",
    "lng": -118.74898276552977,
    "lat": 34.26625259072225
  },
  {
    "name": "Simla",
    "lng": -104.08186825026206,
    "lat": 39.140983316250235
  },
  {
    "name": "Simmesport",
    "lng": -91.8141018449717,
    "lat": 30.980466731080625
  },
  {
    "name": "Simmons Mill State Management Area",
    "lng": -71.1485599376479,
    "lat": 41.542690131715155
  },
  {
    "name": "Simmons Mill State Management Area",
    "lng": -71.14388928932195,
    "lat": 41.53525592711335
  },
  {
    "name": "Simmons Mill State Management Area",
    "lng": -71.14234645691434,
    "lat": 41.54482304907941
  },
  {
    "name": "Simmons Mill State Management Area",
    "lng": -71.14188490342056,
    "lat": 41.54266515619015
  },
  {
    "name": "Simonton",
    "lng": -95.99085089138208,
    "lat": 29.682483387321202
  },
  {
    "name": "Simpson",
    "lng": -97.93400408646689,
    "lat": 39.38603845811939
  },
  {
    "name": "Simpson",
    "lng": -93.01936170415205,
    "lat": 31.258302876448997
  },
  {
    "name": "Simpson",
    "lng": -88.75572109424243,
    "lat": 37.46723632907722
  },
  {
    "name": "Simpson",
    "lng": -77.27881362848845,
    "lat": 35.575200733103124
  },
  {
    "name": "Simpsonville",
    "lng": -85.35264010583047,
    "lat": 38.21903185142558
  },
  {
    "name": "Simpsonville",
    "lng": -82.25664171895772,
    "lat": 34.73003496108195
  },
  {
    "name": "Sims",
    "lng": -88.53546656463753,
    "lat": 38.361851435841466
  },
  {
    "name": "Sims",
    "lng": -78.05934185606917,
    "lat": 35.76072894543853
  },
  {
    "name": "Simsboro",
    "lng": -92.78391916789414,
    "lat": 32.53224945658153
  },
  {
    "name": "Simsbury",
    "lng": -72.82580666725447,
    "lat": 41.87302213076271
  },
  {
    "name": "Sinai",
    "lng": -97.04403449982944,
    "lat": 44.24449781064464
  },
  {
    "name": "Sinclair",
    "lng": -107.11993949249926,
    "lat": 41.776084631320714
  },
  {
    "name": "Sinclairville",
    "lng": -79.25905853906596,
    "lat": 42.26536057477415
  },
  {
    "name": "Sinking Spring",
    "lng": -83.38731742728018,
    "lat": 39.074719448371575
  },
  {
    "name": "Sinking Spring",
    "lng": -76.02344340881216,
    "lat": 40.324137817002345
  },
  {
    "name": "Sinton",
    "lng": -97.5358623086298,
    "lat": 28.061298476725693
  },
  {
    "name": "Sinton",
    "lng": -97.52757614910259,
    "lat": 28.069185627215138
  },
  {
    "name": "Sinton",
    "lng": -97.51336345712397,
    "lat": 28.048411148317296
  },
  {
    "name": "Sinton",
    "lng": -97.51033095592724,
    "lat": 28.034423283612288
  },
  {
    "name": "Sioux Center",
    "lng": -96.1708351249547,
    "lat": 43.07497073656433
  },
  {
    "name": "Sioux City",
    "lng": -96.39009459649631,
    "lat": 42.496047710181855
  },
  {
    "name": "Sioux Falls",
    "lng": -96.73855877291408,
    "lat": 43.540146290928945
  },
  {
    "name": "Sioux Rapids",
    "lng": -95.14844996321229,
    "lat": 42.891993352507306
  },
  {
    "name": "Sipsey",
    "lng": -87.0833722998409,
    "lat": 33.822530708627575
  },
  {
    "name": "Siren",
    "lng": -92.39389517727551,
    "lat": 45.782756566143085
  },
  {
    "name": "Siren",
    "lng": -92.3805313349219,
    "lat": 45.7833930875257
  },
  {
    "name": "Sisseton",
    "lng": -97.04543954353579,
    "lat": 45.66250799667277
  },
  {
    "name": "Sister Bay",
    "lng": -87.12768746843506,
    "lat": 45.184994141507715
  },
  {
    "name": "Sistersville",
    "lng": -80.99869724354359,
    "lat": 39.55982173843619
  },
  {
    "name": "Six Mile",
    "lng": -82.81664183348876,
    "lat": 34.80791420345668
  },
  {
    "name": "Six Mile",
    "lng": -82.79934518438073,
    "lat": 34.8112703608342
  },
  {
    "name": "Skaneateles",
    "lng": -76.42818726942922,
    "lat": 42.945790259409
  },
  {
    "name": "Skedee",
    "lng": -96.70396201283754,
    "lat": 36.38036878705919
  },
  {
    "name": "Skellytown",
    "lng": -101.17266160425797,
    "lat": 35.57083529015296
  },
  {
    "name": "Skiatook",
    "lng": -96.21105307632172,
    "lat": 36.38205279704127
  },
  {
    "name": "Skiatook",
    "lng": -95.97437707843697,
    "lat": 36.36689117978628
  },
  {
    "name": "Skidmore",
    "lng": -95.07948509101354,
    "lat": 40.28774804409305
  },
  {
    "name": "Skokie",
    "lng": -87.74000797760922,
    "lat": 42.03597900711875
  },
  {
    "name": "Skowhegan",
    "lng": -69.66573709626455,
    "lat": 44.75557320948055
  },
  {
    "name": "Sky Lake",
    "lng": -81.39080792829388,
    "lat": 28.468580739701313
  },
  {
    "name": "Sky Lake",
    "lng": -81.38917801105198,
    "lat": 28.4590689985808
  },
  {
    "name": "Sky Londa",
    "lng": -122.25837875808676,
    "lat": 37.36751519316808
  },
  {
    "name": "Sky Valley",
    "lng": -83.33136382448453,
    "lat": 34.984062961382385
  },
  {
    "name": "Skykomish",
    "lng": -121.35847750672397,
    "lat": 47.71055479903546
  },
  {
    "name": "Skyline",
    "lng": -94.03402578244064,
    "lat": 44.140574720869964
  },
  {
    "name": "Skyline",
    "lng": -86.12274328059482,
    "lat": 34.7980201027926
  },
  {
    "name": "Skyline View",
    "lng": -76.72556444784698,
    "lat": 40.337628908455095
  },
  {
    "name": "Slate Springs",
    "lng": -89.37539876600509,
    "lat": 33.74174840674647
  },
  {
    "name": "Slater",
    "lng": -93.68631836151314,
    "lat": 41.87929513879895
  },
  {
    "name": "Slater",
    "lng": -93.06501959084423,
    "lat": 39.22268178896442
  },
  {
    "name": "Slaton",
    "lng": -101.64727444455447,
    "lat": 33.44245526848805
  },
  {
    "name": "Slaughter",
    "lng": -91.13933148126807,
    "lat": 30.72187249429674
  },
  {
    "name": "Slaughters",
    "lng": -87.50181040240213,
    "lat": 37.49067266713734
  },
  {
    "name": "Slaughterville",
    "lng": -97.28838256519678,
    "lat": 35.090960397953474
  },
  {
    "name": "Slaughterville",
    "lng": -97.2364155419821,
    "lat": 35.12885618060945
  },
  {
    "name": "Slayden",
    "lng": -87.46780747672064,
    "lat": 36.293127531243655
  },
  {
    "name": "Slayton",
    "lng": -95.78235516533218,
    "lat": 43.98663726755158
  },
  {
    "name": "Slayton",
    "lng": -95.77017507342599,
    "lat": 43.99187727506158
  },
  {
    "name": "Slayton",
    "lng": -95.76610302513,
    "lat": 43.9928332461286
  },
  {
    "name": "Slayton",
    "lng": -95.76590837498733,
    "lat": 43.97947726254469
  },
  {
    "name": "Slayton",
    "lng": -95.75850558878528,
    "lat": 44.00380827639881
  },
  {
    "name": "Slayton",
    "lng": -95.75559460476478,
    "lat": 43.98875573886332
  },
  {
    "name": "Sledge",
    "lng": -90.22129806067284,
    "lat": 34.43269280878953
  },
  {
    "name": "Sleepy Eye",
    "lng": -94.72356635081445,
    "lat": 44.299037585638345
  },
  {
    "name": "Sleepy Hollow",
    "lng": -88.31367746718394,
    "lat": 42.091286053761685
  },
  {
    "name": "Sleepy Hollow",
    "lng": -73.87120725458014,
    "lat": 41.09358290248485
  },
  {
    "name": "Slick",
    "lng": -96.26711905295939,
    "lat": 35.7782204428672
  },
  {
    "name": "Slidell",
    "lng": -89.81529741907175,
    "lat": 30.308211983023536
  },
  {
    "name": "Slidell",
    "lng": -89.7825600662734,
    "lat": 30.28820417221884
  },
  {
    "name": "Slidell",
    "lng": -89.75829605186483,
    "lat": 30.25748459911192
  },
  {
    "name": "Slinger",
    "lng": -88.31205738196415,
    "lat": 43.34763175747518
  },
  {
    "name": "Slinger",
    "lng": -88.27961160393347,
    "lat": 43.32118958587885
  },
  {
    "name": "Slinger",
    "lng": -88.27457427159197,
    "lat": 43.32926460291614
  },
  {
    "name": "Sloan",
    "lng": -96.23616947013186,
    "lat": 42.22748887179634
  },
  {
    "name": "Sloan",
    "lng": -96.22439781831153,
    "lat": 42.23364525550879
  },
  {
    "name": "Sloan",
    "lng": -78.7917647582869,
    "lat": 42.89209882411008
  },
  {
    "name": "Sloatsburg",
    "lng": -74.19022057426442,
    "lat": 41.162089833542865
  },
  {
    "name": "Slocomb",
    "lng": -85.5952751301845,
    "lat": 31.110236424991914
  },
  {
    "name": "Smackover",
    "lng": -92.73142974256946,
    "lat": 33.363590702961076
  },
  {
    "name": "Smiley",
    "lng": -97.63669745413287,
    "lat": 29.271205202866298
  },
  {
    "name": "Smith Center",
    "lng": -98.78333857337057,
    "lat": 39.77939280045109
  },
  {
    "name": "Smith Village",
    "lng": -97.45700589965361,
    "lat": 35.45079020181856
  },
  {
    "name": "Smithboro",
    "lng": -89.34924295044483,
    "lat": 38.90094329350631
  },
  {
    "name": "Smithboro",
    "lng": -89.33972095916737,
    "lat": 38.89443520741792
  },
  {
    "name": "Smithers",
    "lng": -81.30483038669415,
    "lat": 38.176941187774126
  },
  {
    "name": "Smithfield",
    "lng": -111.8266527628897,
    "lat": 41.8347646584402
  },
  {
    "name": "Smithfield",
    "lng": -99.7409369206041,
    "lat": 40.573300846272105
  },
  {
    "name": "Smithfield",
    "lng": -90.29487216946251,
    "lat": 40.47442739876951
  },
  {
    "name": "Smithfield",
    "lng": -85.25679299658151,
    "lat": 38.38662903616803
  },
  {
    "name": "Smithfield",
    "lng": -80.77726981967947,
    "lat": 40.27155753789858
  },
  {
    "name": "Smithfield",
    "lng": -80.55549965305508,
    "lat": 39.5009358392968
  },
  {
    "name": "Smithfield",
    "lng": -78.39606795220135,
    "lat": 35.47288630444882
  },
  {
    "name": "Smithfield",
    "lng": -78.38894193264876,
    "lat": 35.47268810945516
  },
  {
    "name": "Smithfield",
    "lng": -78.34896732190794,
    "lat": 35.51442197531686
  },
  {
    "name": "Smithfield",
    "lng": -76.61525125537632,
    "lat": 36.97543629571146
  },
  {
    "name": "Smithfield",
    "lng": -71.53075866091721,
    "lat": 41.901796205979466
  },
  {
    "name": "Smithfield",
    "lng": -69.80790728212583,
    "lat": 44.63468790551689
  },
  {
    "name": "Smithland",
    "lng": -95.93158157164139,
    "lat": 42.228891961029525
  },
  {
    "name": "Smithland",
    "lng": -88.40378058842632,
    "lat": 37.13785414705802
  },
  {
    "name": "Smiths Grove",
    "lng": -86.21024861359741,
    "lat": 37.03845641261707
  },
  {
    "name": "Smiths Grove",
    "lng": -86.20798824922437,
    "lat": 37.050238464426364
  },
  {
    "name": "Smiths Station",
    "lng": -85.10874277829302,
    "lat": 32.53429220886208
  },
  {
    "name": "Smiths Station",
    "lng": -85.09705354012607,
    "lat": 32.517538878959336
  },
  {
    "name": "Smiths Station",
    "lng": -85.09286967580788,
    "lat": 32.53028296671005
  },
  {
    "name": "Smiths Station",
    "lng": -85.08274448759693,
    "lat": 32.52499569020041
  },
  {
    "name": "Smiths Station",
    "lng": -85.08095125271227,
    "lat": 32.53602051931224
  },
  {
    "name": "Smiths Station",
    "lng": -85.08006268670384,
    "lat": 32.51088118333742
  },
  {
    "name": "Smiths Station",
    "lng": -85.06916782653897,
    "lat": 32.53854155019268
  },
  {
    "name": "Smiths Station",
    "lng": -85.0629705761985,
    "lat": 32.51173275865891
  },
  {
    "name": "Smiths Station",
    "lng": -85.06269319545211,
    "lat": 32.50173667187277
  },
  {
    "name": "Smithsburg",
    "lng": -77.57640821797668,
    "lat": 39.65625033179662
  },
  {
    "name": "Smithton",
    "lng": -93.09280897925244,
    "lat": 38.68122792976411
  },
  {
    "name": "Smithton",
    "lng": -89.9916644639672,
    "lat": 38.413811379391205
  },
  {
    "name": "Smithville",
    "lng": -97.15077517901678,
    "lat": 30.00693773019355
  },
  {
    "name": "Smithville",
    "lng": -94.64612258252272,
    "lat": 34.46891008542814
  },
  {
    "name": "Smithville",
    "lng": -94.57538744925098,
    "lat": 39.391059384857904
  },
  {
    "name": "Smithville",
    "lng": -91.30259124459756,
    "lat": 36.08012028409073
  },
  {
    "name": "Smithville",
    "lng": -88.39824791044421,
    "lat": 34.068026430292406
  },
  {
    "name": "Smithville",
    "lng": -85.82109928414829,
    "lat": 35.95838997093237
  },
  {
    "name": "Smithville",
    "lng": -84.25517071541931,
    "lat": 31.90103830733728
  },
  {
    "name": "Smithville",
    "lng": -81.86016612804289,
    "lat": 40.86231789296183
  },
  {
    "name": "Smoaks",
    "lng": -80.81420982606666,
    "lat": 33.08945354494513
  },
  {
    "name": "Smolan",
    "lng": -97.68408803688322,
    "lat": 38.73777845483159
  },
  {
    "name": "Smyer",
    "lng": -102.1635010287317,
    "lat": 33.587381484507944
  },
  {
    "name": "Smyrna",
    "lng": -86.59138306584686,
    "lat": 35.93873165973959
  },
  {
    "name": "Smyrna",
    "lng": -86.52506303448193,
    "lat": 35.972120562975185
  },
  {
    "name": "Smyrna",
    "lng": -84.51631272145536,
    "lat": 33.862135098387625
  },
  {
    "name": "Smyrna",
    "lng": -81.40929664817591,
    "lat": 35.041705471515016
  },
  {
    "name": "Smyrna",
    "lng": -75.56868852059087,
    "lat": 42.68692493291724
  },
  {
    "name": "Smyrna",
    "lng": -68.10369667456743,
    "lat": 46.165081145879405
  },
  {
    "name": "Snead",
    "lng": -86.38925612604955,
    "lat": 34.11631492712026
  },
  {
    "name": "Sneads",
    "lng": -84.92317196952607,
    "lat": 30.708350561646608
  },
  {
    "name": "Sneedville",
    "lng": -83.21003387204989,
    "lat": 36.53702415573197
  },
  {
    "name": "Snelling",
    "lng": -81.4569107353947,
    "lat": 33.241204826703544
  },
  {
    "name": "Snellville",
    "lng": -84.04183925470045,
    "lat": 33.859222876040015
  },
  {
    "name": "Snellville",
    "lng": -84.00378313191287,
    "lat": 33.855998469039335
  },
  {
    "name": "Snohomish",
    "lng": -122.09679195579226,
    "lat": 47.92680610022841
  },
  {
    "name": "Snohomish",
    "lng": -122.06811154376915,
    "lat": 47.936348257450064
  },
  {
    "name": "Snook",
    "lng": -96.46710505940534,
    "lat": 30.490708543976787
  },
  {
    "name": "Snoqualmie",
    "lng": -121.84435457675227,
    "lat": 47.52909204707401
  },
  {
    "name": "Snoqualmie",
    "lng": -121.83775111118422,
    "lat": 47.50953188804039
  },
  {
    "name": "Snoqualmie",
    "lng": -121.82374391311417,
    "lat": 47.508034055586755
  },
  {
    "name": "Snow Hill",
    "lng": -77.67639103942972,
    "lat": 35.45052149944579
  },
  {
    "name": "Snow Hill",
    "lng": -77.66269088865039,
    "lat": 35.427066723403456
  },
  {
    "name": "Snow Hill",
    "lng": -77.65535290209849,
    "lat": 35.435926925609245
  },
  {
    "name": "Snow Hill",
    "lng": -75.38993581520253,
    "lat": 38.171908126362496
  },
  {
    "name": "Snow Lake Shores",
    "lng": -89.23847480901922,
    "lat": 34.82394995933739
  },
  {
    "name": "Snowflake",
    "lng": -110.09128457145637,
    "lat": 34.5222950158291
  },
  {
    "name": "Snowmass Village",
    "lng": -106.9415576458166,
    "lat": 39.21758650262351
  },
  {
    "name": "Snowville",
    "lng": -112.71639707579013,
    "lat": 41.972769855229636
  },
  {
    "name": "Snyder",
    "lng": -100.91120702737426,
    "lat": 32.71381312886633
  },
  {
    "name": "Snyder",
    "lng": -98.95350643084372,
    "lat": 34.655020797661294
  },
  {
    "name": "Snyder",
    "lng": -96.78662015389583,
    "lat": 41.70495718487255
  },
  {
    "name": "Soap Lake",
    "lng": -119.50264054500397,
    "lat": 47.389766172227155
  },
  {
    "name": "Soap Lake",
    "lng": -119.50143620400706,
    "lat": 47.402609189718966
  },
  {
    "name": "Soap Lake",
    "lng": -119.49091995324912,
    "lat": 47.388486401086496
  },
  {
    "name": "Sobieski",
    "lng": -94.48204589545212,
    "lat": 45.92302605713584
  },
  {
    "name": "Social Circle",
    "lng": -83.71157888066776,
    "lat": 33.650596089276114
  },
  {
    "name": "Society Hill",
    "lng": -79.8538919843385,
    "lat": 34.509892373436045
  },
  {
    "name": "Socorro",
    "lng": -106.90642725380786,
    "lat": 34.05440963626855
  },
  {
    "name": "Socorro",
    "lng": -106.26007302438411,
    "lat": 31.63824011684434
  },
  {
    "name": "Soda Springs",
    "lng": -111.58777229349069,
    "lat": 42.658958457819296
  },
  {
    "name": "Soddy-Daisy",
    "lng": -85.17363972129868,
    "lat": 35.257913393161424
  },
  {
    "name": "Sodus",
    "lng": -77.06275252700804,
    "lat": 43.23710250961942
  },
  {
    "name": "Sodus Point",
    "lng": -76.99531975479199,
    "lat": 43.263917823121425
  },
  {
    "name": "Solana Beach",
    "lng": -117.25778557196358,
    "lat": 32.99476352369715
  },
  {
    "name": "Soldier",
    "lng": -95.96548197786475,
    "lat": 39.53735560793559
  },
  {
    "name": "Soldier",
    "lng": -95.78047751594691,
    "lat": 41.984359376700034
  },
  {
    "name": "Soldiers Grove",
    "lng": -90.77347176715881,
    "lat": 43.392225614369096
  },
  {
    "name": "Soldotna",
    "lng": -151.06721349507868,
    "lat": 60.48621060335436
  },
  {
    "name": "Soledad",
    "lng": -121.3763473260275,
    "lat": 36.471747482463684
  },
  {
    "name": "Soledad",
    "lng": -121.35020121273323,
    "lat": 36.42091114781955
  },
  {
    "name": "Soledad",
    "lng": -121.33466511604753,
    "lat": 36.46649373478825
  },
  {
    "name": "Soledad",
    "lng": -121.32148287559832,
    "lat": 36.430487029923746
  },
  {
    "name": "Soledad",
    "lng": -121.31922423731821,
    "lat": 36.403397054217756
  },
  {
    "name": "Solen",
    "lng": -100.79568127902331,
    "lat": 46.387725613736315
  },
  {
    "name": "Solomon",
    "lng": -97.38205528537601,
    "lat": 38.917910936397796
  },
  {
    "name": "Solomon",
    "lng": -97.37087339847882,
    "lat": 38.92004113407562
  },
  {
    "name": "Solon",
    "lng": -91.49603847984545,
    "lat": 41.80548244688252
  },
  {
    "name": "Solon",
    "lng": -81.44003232823805,
    "lat": 41.38641831592253
  },
  {
    "name": "Solon",
    "lng": -69.79716293921624,
    "lat": 44.941263392113285
  },
  {
    "name": "Solon Springs",
    "lng": -91.8206725527978,
    "lat": 46.349512717530466
  },
  {
    "name": "Solvang",
    "lng": -120.14019550639377,
    "lat": 34.59328493195076
  },
  {
    "name": "Solvay",
    "lng": -76.21234982501332,
    "lat": 43.057074425575095
  },
  {
    "name": "Solway",
    "lng": -95.13019720265194,
    "lat": 47.52101776835611
  },
  {
    "name": "Somers",
    "lng": -94.43073283061216,
    "lat": 42.37861371062357
  },
  {
    "name": "Somers",
    "lng": -72.45300718328518,
    "lat": 41.99502172298129
  },
  {
    "name": "Somers Point",
    "lng": -74.60700137489026,
    "lat": 39.316639134066655
  },
  {
    "name": "Somerset",
    "lng": -98.65831291311322,
    "lat": 29.228068493649207
  },
  {
    "name": "Somerset",
    "lng": -92.67579849396685,
    "lat": 45.12679660140216
  },
  {
    "name": "Somerset",
    "lng": -84.60947693963755,
    "lat": 37.08060492726264
  },
  {
    "name": "Somerset",
    "lng": -82.29956363506408,
    "lat": 39.80637332870953
  },
  {
    "name": "Somerset",
    "lng": -79.07785786081322,
    "lat": 40.00502734229096
  },
  {
    "name": "Somerset",
    "lng": -77.09621765634884,
    "lat": 38.96665257665625
  },
  {
    "name": "Somerville",
    "lng": -96.53109300096884,
    "lat": 30.346004388322296
  },
  {
    "name": "Somerville",
    "lng": -89.38034335031537,
    "lat": 35.23402647342075
  },
  {
    "name": "Somerville",
    "lng": -87.37711053212904,
    "lat": 38.277875408197346
  },
  {
    "name": "Somerville",
    "lng": -86.79574469510989,
    "lat": 34.469304583303575
  },
  {
    "name": "Somerville",
    "lng": -84.63951623456224,
    "lat": 39.56343024680768
  },
  {
    "name": "Somerville",
    "lng": -71.10121157389965,
    "lat": 42.39077969517977
  },
  {
    "name": "Somerville",
    "lng": -69.47770666266644,
    "lat": 44.28956166965121
  },
  {
    "name": "Somonauk",
    "lng": -88.67325316785535,
    "lat": 41.64199903739805
  },
  {
    "name": "Sonoma",
    "lng": -122.45981238802888,
    "lat": 38.29031260885685
  },
  {
    "name": "Sonoma",
    "lng": -122.43972648159708,
    "lat": 38.28076780584355
  },
  {
    "name": "Sonoma",
    "lng": -122.43412457422262,
    "lat": 38.28665826044885
  },
  {
    "name": "Sonoma",
    "lng": -122.42105578381441,
    "lat": 38.28481393732269
  },
  {
    "name": "Sonoma",
    "lng": -122.4164160226844,
    "lat": 38.29545654441846
  },
  {
    "name": "Sonora",
    "lng": -120.38215751843697,
    "lat": 37.98137254084453
  },
  {
    "name": "Sonora",
    "lng": -100.64396158062978,
    "lat": 30.570757872743993
  },
  {
    "name": "Sonora",
    "lng": -85.89539672715964,
    "lat": 37.52835097520741
  },
  {
    "name": "Soper",
    "lng": -95.69633805397649,
    "lat": 34.03223501892343
  },
  {
    "name": "Soperton",
    "lng": -82.5944446115134,
    "lat": 32.378199259198766
  },
  {
    "name": "Sophia",
    "lng": -81.25245777275636,
    "lat": 37.71147858483892
  },
  {
    "name": "Sorento",
    "lng": -89.5748414293343,
    "lat": 39.000788677368796
  },
  {
    "name": "Sorrento",
    "lng": -90.86742867614709,
    "lat": 30.18281191892526
  },
  {
    "name": "Sorrento",
    "lng": -68.18089226157423,
    "lat": 44.47552458478707
  },
  {
    "name": "Soso",
    "lng": -89.27611888549896,
    "lat": 31.753300294276336
  },
  {
    "name": "Sour Lake",
    "lng": -94.40454989431991,
    "lat": 30.13779152755514
  },
  {
    "name": "Souris",
    "lng": -100.68260100479303,
    "lat": 48.91003442144395
  },
  {
    "name": "South Amherst",
    "lng": -82.23941336038762,
    "lat": 41.35135839560165
  },
  {
    "name": "South Barrington",
    "lng": -88.15778215785657,
    "lat": 42.08799906879994
  },
  {
    "name": "South Bay",
    "lng": -80.71797264137662,
    "lat": 26.665996022533257
  },
  {
    "name": "South Beloit",
    "lng": -89.02413097536837,
    "lat": 42.48190765458045
  },
  {
    "name": "South Bend",
    "lng": -123.8034576299103,
    "lat": 46.669275072672974
  },
  {
    "name": "South Bend",
    "lng": -96.24697261061306,
    "lat": 41.002305715000496
  },
  {
    "name": "South Bend",
    "lng": -86.26892400573432,
    "lat": 41.676852805214
  },
  {
    "name": "South Bend",
    "lng": -86.26232807375854,
    "lat": 41.74359759140284
  },
  {
    "name": "South Berwick",
    "lng": -70.7477084081309,
    "lat": 43.23881917475455
  },
  {
    "name": "South Bloomfield",
    "lng": -82.99319833583152,
    "lat": 39.71707805238253
  },
  {
    "name": "South Blooming Grove",
    "lng": -74.17907502638886,
    "lat": 41.37346545518341
  },
  {
    "name": "South Boston",
    "lng": -78.91343844036902,
    "lat": 36.713175825716895
  },
  {
    "name": "South Bristol",
    "lng": -69.55076746811875,
    "lat": 43.88095806594828
  },
  {
    "name": "South Brooksville",
    "lng": -82.39361131726939,
    "lat": 28.54273216956368
  },
  {
    "name": "South Burlington",
    "lng": -73.22011890367659,
    "lat": 44.45997995284416
  },
  {
    "name": "South Carolina",
    "lng": -80.88423189804185,
    "lat": 33.89670915697075
  },
  {
    "name": "South Carrollton",
    "lng": -87.140196685023,
    "lat": 37.335860556511875
  },
  {
    "name": "South Carthage",
    "lng": -85.95748549049033,
    "lat": 36.24094151411608
  },
  {
    "name": "South Charleston",
    "lng": -83.64136853152883,
    "lat": 39.824980721972466
  },
  {
    "name": "South Charleston",
    "lng": -81.73662083686945,
    "lat": 38.30394386451697
  },
  {
    "name": "South Charleston",
    "lng": -81.70710781875437,
    "lat": 38.35338361926182
  },
  {
    "name": "South Chicago Heights",
    "lng": -87.6373157003266,
    "lat": 41.48311334253916
  },
  {
    "name": "South Cle Elum",
    "lng": -120.95211397984,
    "lat": 47.18597603408158
  },
  {
    "name": "South Coffeyville",
    "lng": -95.62224396399208,
    "lat": 36.99361951362927
  },
  {
    "name": "South Congaree",
    "lng": -81.13751514112222,
    "lat": 33.90936681351906
  },
  {
    "name": "South Corning",
    "lng": -77.03556013419879,
    "lat": 42.126182459886245
  },
  {
    "name": "South Dakota",
    "lng": -100.23218574097467,
    "lat": 44.45532200577053
  },
  {
    "name": "South Dayton",
    "lng": -79.05099293756663,
    "lat": 42.36282698445086
  },
  {
    "name": "South Dos Palos",
    "lng": -120.64727083091947,
    "lat": 36.97125543582979
  },
  {
    "name": "South Elgin",
    "lng": -88.35727709507802,
    "lat": 41.98109684321399
  },
  {
    "name": "South Elgin",
    "lng": -88.30998848279047,
    "lat": 41.99200092880049
  },
  {
    "name": "South English",
    "lng": -92.09005815916566,
    "lat": 41.451588892102116
  },
  {
    "name": "South Euclid",
    "lng": -81.52442263842994,
    "lat": 41.5240490743117
  },
  {
    "name": "South Floral Park",
    "lng": -73.70027048052181,
    "lat": 40.71356540978169
  },
  {
    "name": "South Fork",
    "lng": -106.64446994779426,
    "lat": 37.66897965394606
  },
  {
    "name": "South Fulton",
    "lng": -88.88321220731234,
    "lat": 36.49263556102563
  },
  {
    "name": "South Gate",
    "lng": -118.19252005532658,
    "lat": 33.944689868441266
  },
  {
    "name": "South Gifford",
    "lng": -92.68311521163233,
    "lat": 40.02574362852544
  },
  {
    "name": "South Glens Falls",
    "lng": -73.63498582705341,
    "lat": 43.295218370525724
  },
  {
    "name": "South Gorin",
    "lng": -92.02453048299812,
    "lat": 40.3597271944879
  },
  {
    "name": "South Greenfield",
    "lng": -93.8434738240755,
    "lat": 37.3752016386627
  },
  {
    "name": "South Hadley",
    "lng": -72.57930725810996,
    "lat": 42.256708396079965
  },
  {
    "name": "South Haven",
    "lng": -97.40088958348194,
    "lat": 37.0497401617845
  },
  {
    "name": "South Haven",
    "lng": -94.21741035024374,
    "lat": 45.29275986106956
  },
  {
    "name": "South Haven",
    "lng": -86.2677262166816,
    "lat": 42.40135765649148
  },
  {
    "name": "South Heart",
    "lng": -102.98922665193895,
    "lat": 46.86775682467816
  },
  {
    "name": "South Highpoint",
    "lng": -82.71364706939812,
    "lat": 27.907501187971665
  },
  {
    "name": "South Hill",
    "lng": -78.1263254870188,
    "lat": 36.726057271162745
  },
  {
    "name": "South Holland",
    "lng": -87.60209579571973,
    "lat": 41.59764030273275
  },
  {
    "name": "South Houston",
    "lng": -95.22842402676471,
    "lat": 29.661149782280425
  },
  {
    "name": "South Hutchinson",
    "lng": -97.94271139549187,
    "lat": 38.02757966921336
  },
  {
    "name": "South Jacksonville",
    "lng": -90.22919625266242,
    "lat": 39.702563565116236
  },
  {
    "name": "South Jordan",
    "lng": -111.97820812133199,
    "lat": 40.55707550029891
  },
  {
    "name": "South Kingstown",
    "lng": -71.62200602805342,
    "lat": 41.37075116709425
  },
  {
    "name": "South Kingstown",
    "lng": -71.6217133302281,
    "lat": 41.36780869292182
  },
  {
    "name": "South Kingstown",
    "lng": -71.62061793892478,
    "lat": 41.371428110064464
  },
  {
    "name": "South Kingstown",
    "lng": -71.61733831387751,
    "lat": 41.373568370261104
  },
  {
    "name": "South Kingstown",
    "lng": -71.61267406696362,
    "lat": 41.36873145587425
  },
  {
    "name": "South Kingstown",
    "lng": -71.6124019353039,
    "lat": 41.37737263041624
  },
  {
    "name": "South Kingstown",
    "lng": -71.61200467933446,
    "lat": 41.36727359073101
  },
  {
    "name": "South Kingstown",
    "lng": -71.60947310464812,
    "lat": 41.36752930384163
  },
  {
    "name": "South Kingstown",
    "lng": -71.60768534331218,
    "lat": 41.37084076593918
  },
  {
    "name": "South Kingstown",
    "lng": -71.6045829787533,
    "lat": 41.370066264867624
  },
  {
    "name": "South Kingstown",
    "lng": -71.54436932967131,
    "lat": 41.448051650261625
  },
  {
    "name": "South Kingstown",
    "lng": -71.52407675258588,
    "lat": 41.38474840711408
  },
  {
    "name": "South Kingstown",
    "lng": -71.51995788727828,
    "lat": 41.38118475631605
  },
  {
    "name": "South Kingstown",
    "lng": -71.51880950252895,
    "lat": 41.38354055713136
  },
  {
    "name": "South Kingstown",
    "lng": -71.51282874339526,
    "lat": 41.39730439934121
  },
  {
    "name": "South Kingstown",
    "lng": -71.50883535972201,
    "lat": 41.40442548118853
  },
  {
    "name": "South Kingstown",
    "lng": -71.50673995256349,
    "lat": 41.40691402019346
  },
  {
    "name": "South Kingstown",
    "lng": -71.50570116915648,
    "lat": 41.41873560828474
  },
  {
    "name": "South Kingstown",
    "lng": -71.50388986966763,
    "lat": 41.40711540727993
  },
  {
    "name": "South Kingstown",
    "lng": -71.5036476950002,
    "lat": 41.42047914748698
  },
  {
    "name": "South Kingstown",
    "lng": -71.50313532123482,
    "lat": 41.42121776729729
  },
  {
    "name": "South Lake Tahoe",
    "lng": -119.98234498268998,
    "lat": 38.939452921832824
  },
  {
    "name": "South Lead Hill",
    "lng": -92.90623339199529,
    "lat": 36.39568054115716
  },
  {
    "name": "South Lebanon",
    "lng": -84.21995229747515,
    "lat": 39.37372698953846
  },
  {
    "name": "South Lebanon",
    "lng": -84.21663073307963,
    "lat": 39.369570038845374
  },
  {
    "name": "South Lineville",
    "lng": -93.52405150110155,
    "lat": 40.5785604515111
  },
  {
    "name": "South Lyon",
    "lng": -83.65258719584453,
    "lat": 42.461289487336835
  },
  {
    "name": "South Mansfield",
    "lng": -93.72368629720276,
    "lat": 32.01805593389625
  },
  {
    "name": "South Milwaukee",
    "lng": -87.8624979358635,
    "lat": 42.91188697407144
  },
  {
    "name": "South Mountain",
    "lng": -97.67926687393934,
    "lat": 31.43982914006128
  },
  {
    "name": "South Nyack",
    "lng": -73.91016589046356,
    "lat": 41.08020023541794
  },
  {
    "name": "South Ogden",
    "lng": -111.95679660773304,
    "lat": 41.17149217378877
  },
  {
    "name": "South Padre Island",
    "lng": -97.1702146330068,
    "lat": 26.120352581628417
  },
  {
    "name": "South Palm Beach",
    "lng": -80.03599428681763,
    "lat": 26.590083080563964
  },
  {
    "name": "South Park View",
    "lng": -85.72021152326766,
    "lat": 38.118256784005574
  },
  {
    "name": "South Pasadena",
    "lng": -82.73945369713518,
    "lat": 27.752394332808375
  },
  {
    "name": "South Pekin",
    "lng": -89.65354669474691,
    "lat": 40.49399561460943
  },
  {
    "name": "South Pittsburg",
    "lng": -85.71222879682071,
    "lat": 35.01594570783455
  },
  {
    "name": "South Point",
    "lng": -82.57708019068946,
    "lat": 38.42163312177255
  },
  {
    "name": "South Portland",
    "lng": -70.28618348116268,
    "lat": 43.631801095597616
  },
  {
    "name": "South Prairie",
    "lng": -122.09402275074633,
    "lat": 47.137344700701625
  },
  {
    "name": "South Range",
    "lng": -88.6439215116806,
    "lat": 47.070332898373906
  },
  {
    "name": "South Rockwood",
    "lng": -83.25903499589359,
    "lat": 42.061383416278034
  },
  {
    "name": "South Roxana",
    "lng": -90.06114011530966,
    "lat": 38.81196675204946
  },
  {
    "name": "South Russell",
    "lng": -81.33478113274269,
    "lat": 41.43188900786833
  },
  {
    "name": "South Salem",
    "lng": -83.30695619700539,
    "lat": 39.33737154761269
  },
  {
    "name": "South Salt Lake",
    "lng": -111.89861808133693,
    "lat": 40.70564237319412
  },
  {
    "name": "South Shore",
    "lng": -96.93011108187154,
    "lat": 45.101510394929676
  },
  {
    "name": "South Sioux City",
    "lng": -96.41455053846312,
    "lat": 42.4636971051151
  },
  {
    "name": "South Solon",
    "lng": -83.61287459787421,
    "lat": 39.7371683112425
  },
  {
    "name": "South St. Paul",
    "lng": -93.0406684275573,
    "lat": 44.88737814508811
  },
  {
    "name": "South Temple",
    "lng": -75.92230574507016,
    "lat": 40.39902672693665
  },
  {
    "name": "South Thomaston",
    "lng": -69.1361811318687,
    "lat": 44.03687814919574
  },
  {
    "name": "South Tucson",
    "lng": -110.96907489942619,
    "lat": 32.19542818856112
  },
  {
    "name": "South Vienna",
    "lng": -83.61331393341868,
    "lat": 39.9290826961417
  },
  {
    "name": "South Vinemont",
    "lng": -86.86460611701435,
    "lat": 34.235417644475326
  },
  {
    "name": "South Wayne",
    "lng": -89.87624044004856,
    "lat": 42.56727062996405
  },
  {
    "name": "South Weber",
    "lng": -111.93965350356443,
    "lat": 41.13353986394941
  },
  {
    "name": "South Webster",
    "lng": -82.7282109561374,
    "lat": 38.81701685671704
  },
  {
    "name": "South Whitley",
    "lng": -85.6295853520179,
    "lat": 41.086791958664804
  },
  {
    "name": "South Whitley",
    "lng": -85.62376968564227,
    "lat": 41.07797608559847
  },
  {
    "name": "South Wilmington",
    "lng": -88.28137981876135,
    "lat": 41.174476725498934
  },
  {
    "name": "South Windsor",
    "lng": -72.5733628626339,
    "lat": 41.835254963643706
  },
  {
    "name": "South Woodstock",
    "lng": -71.95544632206476,
    "lat": 41.931338943704745
  },
  {
    "name": "South Zanesville",
    "lng": -82.0179428360942,
    "lat": 39.90361928451508
  },
  {
    "name": "Southampton",
    "lng": -72.73858136518173,
    "lat": 42.23084422190907
  },
  {
    "name": "Southampton",
    "lng": -72.40429734348369,
    "lat": 40.87719988497462
  },
  {
    "name": "Southaven",
    "lng": -89.9764268369215,
    "lat": 34.95287608261649
  },
  {
    "name": "Southborough",
    "lng": -71.52966083890756,
    "lat": 42.30118371734539
  },
  {
    "name": "Southbridge",
    "lng": -72.03377323030736,
    "lat": 42.06044053982106
  },
  {
    "name": "Southchase",
    "lng": -81.39036711612425,
    "lat": 28.386329481489934
  },
  {
    "name": "Southern Pines",
    "lng": -79.42426058594108,
    "lat": 35.24841565707202
  },
  {
    "name": "Southern Pines",
    "lng": -79.41374163734416,
    "lat": 35.25399546617879
  },
  {
    "name": "Southern Pines",
    "lng": -79.41006282573206,
    "lat": 35.249624939693845
  },
  {
    "name": "Southern Pines",
    "lng": -79.40828710696951,
    "lat": 35.24115196952174
  },
  {
    "name": "Southern Pines",
    "lng": -79.40709940337793,
    "lat": 35.23418416177158
  },
  {
    "name": "Southern Pines",
    "lng": -79.40546962203007,
    "lat": 35.238395163066684
  },
  {
    "name": "Southern Pines",
    "lng": -79.4049760925357,
    "lat": 35.18934408247439
  },
  {
    "name": "Southern Pines",
    "lng": -79.40293752491024,
    "lat": 35.2364596358018
  },
  {
    "name": "Southern Pines",
    "lng": -79.39782609620782,
    "lat": 35.242762143285624
  },
  {
    "name": "Southern Pines",
    "lng": -79.39636743775596,
    "lat": 35.22676649197433
  },
  {
    "name": "Southern Pines",
    "lng": -79.38334692599359,
    "lat": 35.23082024616687
  },
  {
    "name": "Southern Pines",
    "lng": -79.3828113198681,
    "lat": 35.22320587700195
  },
  {
    "name": "Southern Pines",
    "lng": -79.37653691184101,
    "lat": 35.13359208162523
  },
  {
    "name": "Southern Pines",
    "lng": -79.3741593798755,
    "lat": 35.17696941841168
  },
  {
    "name": "Southern Pines",
    "lng": -79.36937743959443,
    "lat": 35.143748934732066
  },
  {
    "name": "Southern Pines",
    "lng": -79.36736298825186,
    "lat": 35.22582219463218
  },
  {
    "name": "Southern Pines",
    "lng": -79.3647074771582,
    "lat": 35.221301855205276
  },
  {
    "name": "Southern Shores",
    "lng": -75.73253923030748,
    "lat": 36.120289321984714
  },
  {
    "name": "Southern View",
    "lng": -89.65115352711419,
    "lat": 39.755939741306825
  },
  {
    "name": "Southfield",
    "lng": -83.26053087101057,
    "lat": 42.47651777497743
  },
  {
    "name": "Southgate",
    "lng": -84.47146884096485,
    "lat": 39.06328885670417
  },
  {
    "name": "Southgate",
    "lng": -83.20577885343693,
    "lat": 42.20471195151651
  },
  {
    "name": "Southington",
    "lng": -72.88001385014066,
    "lat": 41.604918796890836
  },
  {
    "name": "Southlake",
    "lng": -97.15025008130267,
    "lat": 32.9545646777275
  },
  {
    "name": "Southmont",
    "lng": -78.93269965150245,
    "lat": 40.31082632767157
  },
  {
    "name": "Southport",
    "lng": -86.11711492245385,
    "lat": 39.659986572024195
  },
  {
    "name": "Southport",
    "lng": -78.04573721435119,
    "lat": 33.952226396057505
  },
  {
    "name": "Southport",
    "lng": -78.0431428331617,
    "lat": 33.95063624567255
  },
  {
    "name": "Southport",
    "lng": -78.03947691576857,
    "lat": 33.95559012257629
  },
  {
    "name": "Southport",
    "lng": -78.03404732938084,
    "lat": 33.94875662906581
  },
  {
    "name": "Southport",
    "lng": -78.03052050831637,
    "lat": 33.94322886669003
  },
  {
    "name": "Southport",
    "lng": -78.02681119483205,
    "lat": 33.940175999937054
  },
  {
    "name": "Southport",
    "lng": -78.02537629218537,
    "lat": 33.93796047766558
  },
  {
    "name": "Southport",
    "lng": -78.02469122972963,
    "lat": 33.93660196410839
  },
  {
    "name": "Southport",
    "lng": -78.02370662126958,
    "lat": 33.93428462563514
  },
  {
    "name": "Southport",
    "lng": -78.01983773339722,
    "lat": 33.9843101645397
  },
  {
    "name": "Southport",
    "lng": -78.01893833995364,
    "lat": 33.927644883222406
  },
  {
    "name": "Southport",
    "lng": -78.00729901104897,
    "lat": 33.98099740388617
  },
  {
    "name": "Southport",
    "lng": -69.66152096229915,
    "lat": 43.795509162822725
  },
  {
    "name": "Southside",
    "lng": -91.63297620260805,
    "lat": 35.715029248164136
  },
  {
    "name": "Southside",
    "lng": -86.02390123206112,
    "lat": 33.900938534917266
  },
  {
    "name": "Southside Place",
    "lng": -95.4366613608734,
    "lat": 29.708956712490345
  },
  {
    "name": "Southwest City",
    "lng": -94.61773746990362,
    "lat": 36.500533178688315
  },
  {
    "name": "Southwest City",
    "lng": -94.61000405121338,
    "lat": 36.52076890582612
  },
  {
    "name": "Southwest Harbor",
    "lng": -68.31916531640242,
    "lat": 44.26224561671085
  },
  {
    "name": "Southwick",
    "lng": -72.77855273443396,
    "lat": 42.05441375268024
  },
  {
    "name": "Spalding",
    "lng": -98.36254802417834,
    "lat": 41.689281461120835
  },
  {
    "name": "Spangle",
    "lng": -117.38132303124482,
    "lat": 47.42971767422552
  },
  {
    "name": "Spanish Fork",
    "lng": -111.640827889285,
    "lat": 40.109902157949406
  },
  {
    "name": "Spanish Fort",
    "lng": -87.93469536073353,
    "lat": 30.67432044196651
  },
  {
    "name": "Spanish Fort",
    "lng": -87.93466090590324,
    "lat": 30.67431360699738
  },
  {
    "name": "Spanish Fort",
    "lng": -87.84664463828959,
    "lat": 30.68584477257644
  },
  {
    "name": "Spanish Fort",
    "lng": -87.84664227360177,
    "lat": 30.68586100053361
  },
  {
    "name": "Sparkman",
    "lng": -92.84999461698558,
    "lat": 33.917167678182686
  },
  {
    "name": "Sparks",
    "lng": -119.71472829323004,
    "lat": 39.57095840912005
  },
  {
    "name": "Sparks",
    "lng": -96.81910572293856,
    "lat": 35.61101529851643
  },
  {
    "name": "Sparks",
    "lng": -83.44035838565186,
    "lat": 31.16927178814322
  },
  {
    "name": "Sparland",
    "lng": -89.44118067723086,
    "lat": 41.02960904578713
  },
  {
    "name": "Sparta",
    "lng": -93.0986746266436,
    "lat": 36.999866655627365
  },
  {
    "name": "Sparta",
    "lng": -93.08371740605267,
    "lat": 37.000940736164374
  },
  {
    "name": "Sparta",
    "lng": -90.85179426922703,
    "lat": 43.93530228414631
  },
  {
    "name": "Sparta",
    "lng": -90.85025300520267,
    "lat": 43.93574936713492
  },
  {
    "name": "Sparta",
    "lng": -90.84156852828431,
    "lat": 43.93749071450585
  },
  {
    "name": "Sparta",
    "lng": -90.81562460111988,
    "lat": 43.93711467874275
  },
  {
    "name": "Sparta",
    "lng": -90.81432861945395,
    "lat": 43.90699154478633
  },
  {
    "name": "Sparta",
    "lng": -90.78688096487049,
    "lat": 43.93875275421281
  },
  {
    "name": "Sparta",
    "lng": -90.77193111074652,
    "lat": 43.9611271249442
  },
  {
    "name": "Sparta",
    "lng": -89.71856178063636,
    "lat": 38.151383960581285
  },
  {
    "name": "Sparta",
    "lng": -89.7170138028828,
    "lat": 38.12023536285831
  },
  {
    "name": "Sparta",
    "lng": -85.70919709481812,
    "lat": 43.157619160044234
  },
  {
    "name": "Sparta",
    "lng": -85.47243700338402,
    "lat": 35.93481894022378
  },
  {
    "name": "Sparta",
    "lng": -84.89726489102206,
    "lat": 38.68673210545869
  },
  {
    "name": "Sparta",
    "lng": -82.9705505908573,
    "lat": 33.27667080280598
  },
  {
    "name": "Sparta",
    "lng": -82.69964751449947,
    "lat": 40.39443325392443
  },
  {
    "name": "Sparta",
    "lng": -81.12118212883644,
    "lat": 36.50359747451356
  },
  {
    "name": "Sparta",
    "lng": -81.09823544634683,
    "lat": 36.48201234034082
  },
  {
    "name": "Spartanburg",
    "lng": -81.92588299549685,
    "lat": 34.94357703137818
  },
  {
    "name": "Spartanburg",
    "lng": -81.87011523263911,
    "lat": 34.962065977968386
  },
  {
    "name": "Spartanburg",
    "lng": -81.86039883860319,
    "lat": 34.96459723238062
  },
  {
    "name": "Spaulding",
    "lng": -96.44125446298507,
    "lat": 35.01021200957089
  },
  {
    "name": "Spaulding",
    "lng": -89.54457165855692,
    "lat": 39.866451089277014
  },
  {
    "name": "Spavinaw",
    "lng": -95.05014237102982,
    "lat": 36.39268784414238
  },
  {
    "name": "Spearfish",
    "lng": -103.8161208548546,
    "lat": 44.4909340168023
  },
  {
    "name": "Spearman",
    "lng": -101.19588878043507,
    "lat": 36.22052006161346
  },
  {
    "name": "Spearman",
    "lng": -101.19403272756158,
    "lat": 36.19465796698926
  },
  {
    "name": "Spearsville",
    "lng": -92.6022058820598,
    "lat": 32.9338765181135
  },
  {
    "name": "Spearville",
    "lng": -99.7546025565731,
    "lat": 37.847902351634325
  },
  {
    "name": "Speculator",
    "lng": -74.51444720437054,
    "lat": 43.77512650135292
  },
  {
    "name": "Speculator",
    "lng": -74.35668872484437,
    "lat": 43.55960670453038
  },
  {
    "name": "Speed",
    "lng": -99.42107680859553,
    "lat": 39.67655066802354
  },
  {
    "name": "Speed",
    "lng": -77.44425712687418,
    "lat": 35.96849993455312
  },
  {
    "name": "Speedway",
    "lng": -86.2479088741951,
    "lat": 39.79372153915071
  },
  {
    "name": "Spencer",
    "lng": -112.18972277626665,
    "lat": 44.37697644910616
  },
  {
    "name": "Spencer",
    "lng": -98.70030324160237,
    "lat": 42.87479494896422
  },
  {
    "name": "Spencer",
    "lng": -97.59111032832743,
    "lat": 43.72746610739616
  },
  {
    "name": "Spencer",
    "lng": -97.37158060482116,
    "lat": 35.51075354486025
  },
  {
    "name": "Spencer",
    "lng": -95.15335716926667,
    "lat": 43.146464968773195
  },
  {
    "name": "Spencer",
    "lng": -90.2983359244989,
    "lat": 44.754349413908756
  },
  {
    "name": "Spencer",
    "lng": -86.76946117468655,
    "lat": 39.28613109679675
  },
  {
    "name": "Spencer",
    "lng": -85.45571145968385,
    "lat": 35.739225573264456
  },
  {
    "name": "Spencer",
    "lng": -82.12260472399743,
    "lat": 41.098223236105525
  },
  {
    "name": "Spencer",
    "lng": -81.35471733145322,
    "lat": 38.80236531511909
  },
  {
    "name": "Spencer",
    "lng": -80.4304785221413,
    "lat": 35.695775614264065
  },
  {
    "name": "Spencer",
    "lng": -80.40237479492103,
    "lat": 35.69981155361792
  },
  {
    "name": "Spencer",
    "lng": -80.39916673029589,
    "lat": 35.70679274605958
  },
  {
    "name": "Spencer",
    "lng": -76.49638620058926,
    "lat": 42.21443560258986
  },
  {
    "name": "Spencer",
    "lng": -71.99189320976458,
    "lat": 42.2471806699966
  },
  {
    "name": "Spencer Mountain",
    "lng": -81.1101234177262,
    "lat": 35.30726772758426
  },
  {
    "name": "Spencerport",
    "lng": -77.80622497943793,
    "lat": 43.18850771566022
  },
  {
    "name": "Spencerville",
    "lng": -84.35272732643436,
    "lat": 40.707978956199604
  },
  {
    "name": "Sperry",
    "lng": -95.98703362754662,
    "lat": 36.29702606670621
  },
  {
    "name": "Sperry",
    "lng": -95.98222448637208,
    "lat": 36.29845055930874
  },
  {
    "name": "Spiceland",
    "lng": -85.43777427448235,
    "lat": 39.83737093597587
  },
  {
    "name": "Spicer",
    "lng": -94.9405017206919,
    "lat": 45.232817343297725
  },
  {
    "name": "Spickard",
    "lng": -93.59224035955265,
    "lat": 40.24316078271401
  },
  {
    "name": "Spillertown",
    "lng": -88.91972260714718,
    "lat": 37.764126811963735
  },
  {
    "name": "Spillville",
    "lng": -91.95307521762477,
    "lat": 43.20344819121204
  },
  {
    "name": "Spillville",
    "lng": -91.94397399459764,
    "lat": 43.1997016968334
  },
  {
    "name": "Spindale",
    "lng": -81.92297861675524,
    "lat": 35.3597882297525
  },
  {
    "name": "Spirit Lake",
    "lng": -116.8700083356068,
    "lat": 47.96594326045976
  },
  {
    "name": "Spirit Lake",
    "lng": -95.14949625391745,
    "lat": 43.42481124272658
  },
  {
    "name": "Spirit Lake",
    "lng": -95.12965089196148,
    "lat": 43.39956502326622
  },
  {
    "name": "Spirit Lake",
    "lng": -95.12838889584857,
    "lat": 43.40340547411544
  },
  {
    "name": "Spirit Lake",
    "lng": -95.10870198707045,
    "lat": 43.41740795669878
  },
  {
    "name": "Spiritwood Lake",
    "lng": -98.5866094573825,
    "lat": 47.07398355943547
  },
  {
    "name": "Spiro",
    "lng": -94.65784609881972,
    "lat": 35.23756888662088
  },
  {
    "name": "Spiro",
    "lng": -94.6215426073915,
    "lat": 35.24095582224448
  },
  {
    "name": "Spivey",
    "lng": -98.16582416637677,
    "lat": 37.448437693918045
  },
  {
    "name": "Splendora",
    "lng": -95.16104633632905,
    "lat": 30.232573635637827
  },
  {
    "name": "Splendora",
    "lng": -95.13795156436544,
    "lat": 30.25535931332709
  },
  {
    "name": "Spofford",
    "lng": -100.4112552686163,
    "lat": 29.173122021820664
  },
  {
    "name": "Spokane",
    "lng": -117.43296271803187,
    "lat": 47.6671013706788
  },
  {
    "name": "Spokane Valley",
    "lng": -117.23473772324512,
    "lat": 47.66234630987944
  },
  {
    "name": "Spooner",
    "lng": -91.8860663386219,
    "lat": 45.82709526634371
  },
  {
    "name": "Sportsmen Acres",
    "lng": -95.25120351574617,
    "lat": 36.24632402480982
  },
  {
    "name": "Sprague",
    "lng": -117.9757451176681,
    "lat": 47.30028493098197
  },
  {
    "name": "Sprague",
    "lng": -96.74467486533254,
    "lat": 40.626554490287425
  },
  {
    "name": "Sprague",
    "lng": -72.07583447097797,
    "lat": 41.63045275496268
  },
  {
    "name": "Spragueville",
    "lng": -90.43229906225446,
    "lat": 42.07155496950152
  },
  {
    "name": "Spring Bay",
    "lng": -89.52847072400358,
    "lat": 40.82065557977664
  },
  {
    "name": "Spring City",
    "lng": -111.49176266104402,
    "lat": 39.47986412021325
  },
  {
    "name": "Spring City",
    "lng": -84.86359708915363,
    "lat": 35.68713866189392
  },
  {
    "name": "Spring Green",
    "lng": -90.07035614050643,
    "lat": 43.1782439968109
  },
  {
    "name": "Spring Grove",
    "lng": -91.63797469164636,
    "lat": 43.56053456855546
  },
  {
    "name": "Spring Grove",
    "lng": -91.61324151275535,
    "lat": 43.566299701494955
  },
  {
    "name": "Spring Grove",
    "lng": -88.24175727878979,
    "lat": 42.453541167022266
  },
  {
    "name": "Spring Grove",
    "lng": -84.89142396030094,
    "lat": 39.84717076729798
  },
  {
    "name": "Spring Grove",
    "lng": -76.86401340526025,
    "lat": 39.880840873397986
  },
  {
    "name": "Spring Hill",
    "lng": -94.8317278986492,
    "lat": 45.52328543399535
  },
  {
    "name": "Spring Hill",
    "lng": -94.81978552914794,
    "lat": 38.75659991085122
  },
  {
    "name": "Spring Hill",
    "lng": -94.81844675598292,
    "lat": 38.720655109334544
  },
  {
    "name": "Spring Hill",
    "lng": -94.81704518413866,
    "lat": 38.7655280736292
  },
  {
    "name": "Spring Hill",
    "lng": -94.81431773362544,
    "lat": 38.715961834254635
  },
  {
    "name": "Spring Hill",
    "lng": -94.80973687505602,
    "lat": 38.740387569433835
  },
  {
    "name": "Spring Hill",
    "lng": -86.91168958018466,
    "lat": 35.74335023328042
  },
  {
    "name": "Spring Hill",
    "lng": -86.19281396970298,
    "lat": 39.834785101897815
  },
  {
    "name": "Spring Hope",
    "lng": -78.10964467161249,
    "lat": 35.94495698910802
  },
  {
    "name": "Spring Hope",
    "lng": -78.10036647174658,
    "lat": 35.938014874260716
  },
  {
    "name": "Spring Lake",
    "lng": -86.19154813277513,
    "lat": 43.07477711369966
  },
  {
    "name": "Spring Lake",
    "lng": -85.85364962258124,
    "lat": 39.77668757574788
  },
  {
    "name": "Spring Lake",
    "lng": -85.85363882326725,
    "lat": 39.776701980871415
  },
  {
    "name": "Spring Lake",
    "lng": -78.97876526390628,
    "lat": 35.181512341835244
  },
  {
    "name": "Spring Lake",
    "lng": -78.965220698935,
    "lat": 35.183134402024606
  },
  {
    "name": "Spring Lake Park",
    "lng": -93.24508921018922,
    "lat": 45.116069072009786
  },
  {
    "name": "Spring Mill",
    "lng": -85.63133900743206,
    "lat": 38.14383035358684
  },
  {
    "name": "Spring Park",
    "lng": -93.6319446875415,
    "lat": 44.93673905186667
  },
  {
    "name": "Spring Valley",
    "lng": -95.5040563946526,
    "lat": 29.789571675530972
  },
  {
    "name": "Spring Valley",
    "lng": -92.39009551738702,
    "lat": 43.686679263480436
  },
  {
    "name": "Spring Valley",
    "lng": -92.2442938027503,
    "lat": 44.84992014574563
  },
  {
    "name": "Spring Valley",
    "lng": -89.20344258246979,
    "lat": 41.33592394617275
  },
  {
    "name": "Spring Valley",
    "lng": -85.61092257643112,
    "lat": 38.296918941300696
  },
  {
    "name": "Spring Valley",
    "lng": -84.0064300870067,
    "lat": 39.6100215155638
  },
  {
    "name": "Spring Valley",
    "lng": -74.04860539844516,
    "lat": 41.1154317256417
  },
  {
    "name": "Springboro",
    "lng": -84.23492002598742,
    "lat": 39.561056822879536
  },
  {
    "name": "Springbrook",
    "lng": -103.46131068163022,
    "lat": 48.25194707094214
  },
  {
    "name": "Springbrook",
    "lng": -90.47914985412386,
    "lat": 42.165567029460256
  },
  {
    "name": "Springdale",
    "lng": -117.74874570767535,
    "lat": 48.06060653792861
  },
  {
    "name": "Springdale",
    "lng": -113.00498296987521,
    "lat": 37.181683172597424
  },
  {
    "name": "Springdale",
    "lng": -94.15728901433819,
    "lat": 36.189813601670544
  },
  {
    "name": "Springdale",
    "lng": -84.4759731087407,
    "lat": 39.29089444966811
  },
  {
    "name": "Springdale",
    "lng": -81.12118457091829,
    "lat": 33.95907638229551
  },
  {
    "name": "Springer",
    "lng": -104.59316344020489,
    "lat": 36.36572894738255
  },
  {
    "name": "Springer",
    "lng": -97.12556668655418,
    "lat": 34.29098419688412
  },
  {
    "name": "Springerton",
    "lng": -88.35473850722848,
    "lat": 38.178697623138696
  },
  {
    "name": "Springerville",
    "lng": -109.2989364751077,
    "lat": 34.15600051112047
  },
  {
    "name": "Springfield",
    "lng": -102.61889845107416,
    "lat": 37.40491599175455
  },
  {
    "name": "Springfield",
    "lng": -97.90105845939391,
    "lat": 42.880880831292686
  },
  {
    "name": "Springfield",
    "lng": -97.89493829140747,
    "lat": 42.85567666619983
  },
  {
    "name": "Springfield",
    "lng": -96.13212937927165,
    "lat": 41.083260263577394
  },
  {
    "name": "Springfield",
    "lng": -94.98186267409814,
    "lat": 44.23721252086208
  },
  {
    "name": "Springfield",
    "lng": -93.29202837838895,
    "lat": 37.194490300111475
  },
  {
    "name": "Springfield",
    "lng": -90.54439913990082,
    "lat": 30.42553580301978
  },
  {
    "name": "Springfield",
    "lng": -89.66343668841841,
    "lat": 39.76492724381165
  },
  {
    "name": "Springfield",
    "lng": -89.65921919202094,
    "lat": 39.84783674633133
  },
  {
    "name": "Springfield",
    "lng": -89.65388373038648,
    "lat": 39.77170789774356
  },
  {
    "name": "Springfield",
    "lng": -89.62250952819902,
    "lat": 39.692745949837935
  },
  {
    "name": "Springfield",
    "lng": -89.61446350142559,
    "lat": 39.80193788330296
  },
  {
    "name": "Springfield",
    "lng": -89.6138497662526,
    "lat": 39.80219899235678
  },
  {
    "name": "Springfield",
    "lng": -86.87151093387811,
    "lat": 36.49525881234768
  },
  {
    "name": "Springfield",
    "lng": -85.60909106168661,
    "lat": 30.170955285585087
  },
  {
    "name": "Springfield",
    "lng": -85.58826140424803,
    "lat": 30.17571515024243
  },
  {
    "name": "Springfield",
    "lng": -85.23699689683805,
    "lat": 42.324574808221485
  },
  {
    "name": "Springfield",
    "lng": -85.21579277436454,
    "lat": 37.69171004071025
  },
  {
    "name": "Springfield",
    "lng": -83.79675673204802,
    "lat": 39.93103771458151
  },
  {
    "name": "Springfield",
    "lng": -81.30897335934831,
    "lat": 32.36600389094638
  },
  {
    "name": "Springfield",
    "lng": -81.27916407392419,
    "lat": 33.49624642487131
  },
  {
    "name": "Springfield",
    "lng": -72.5395147028074,
    "lat": 42.11551494088152
  },
  {
    "name": "Springfield",
    "lng": -68.1650136855308,
    "lat": 45.39267553010743
  },
  {
    "name": "Springhill",
    "lng": -93.46132046393673,
    "lat": 33.001996495095106
  },
  {
    "name": "Springlake",
    "lng": -102.30595612112052,
    "lat": 34.231817387255916
  },
  {
    "name": "Springport",
    "lng": -85.39256105584556,
    "lat": 40.04719512172565
  },
  {
    "name": "Springport",
    "lng": -84.69647618843885,
    "lat": 42.37833566781465
  },
  {
    "name": "Springtown",
    "lng": -97.68045650349549,
    "lat": 32.96935702150559
  },
  {
    "name": "Springtown",
    "lng": -94.42382109861182,
    "lat": 36.260781923749974
  },
  {
    "name": "Springview",
    "lng": -99.74753947893856,
    "lat": 42.82487986240319
  },
  {
    "name": "Springville",
    "lng": -111.62050650042602,
    "lat": 40.16380839758336
  },
  {
    "name": "Springville",
    "lng": -91.45444671406966,
    "lat": 42.0470943053735
  },
  {
    "name": "Springville",
    "lng": -86.51091964714621,
    "lat": 33.77535758046145
  },
  {
    "name": "Springville",
    "lng": -86.51070051734918,
    "lat": 33.77182359760984
  },
  {
    "name": "Springville",
    "lng": -86.50687835105394,
    "lat": 33.77361414858038
  },
  {
    "name": "Springville",
    "lng": -86.50567266218826,
    "lat": 33.76989910888126
  },
  {
    "name": "Springville",
    "lng": -86.50399367084145,
    "lat": 33.76210380609247
  },
  {
    "name": "Springville",
    "lng": -86.50390852674487,
    "lat": 33.800081218325474
  },
  {
    "name": "Springville",
    "lng": -86.49416610837726,
    "lat": 33.80386176707667
  },
  {
    "name": "Springville",
    "lng": -86.4893638038933,
    "lat": 33.802291983720984
  },
  {
    "name": "Springville",
    "lng": -86.48770163838736,
    "lat": 33.73495549934472
  },
  {
    "name": "Springville",
    "lng": -86.48512683955748,
    "lat": 33.80747295662022
  },
  {
    "name": "Springville",
    "lng": -86.4833072638183,
    "lat": 33.81447760996897
  },
  {
    "name": "Springville",
    "lng": -86.48160833034105,
    "lat": 33.736104136917064
  },
  {
    "name": "Springville",
    "lng": -86.47940640847803,
    "lat": 33.8078052585425
  },
  {
    "name": "Springville",
    "lng": -86.47471386632955,
    "lat": 33.762973649830904
  },
  {
    "name": "Springville",
    "lng": -86.47174699797971,
    "lat": 33.810880233185415
  },
  {
    "name": "Springville",
    "lng": -86.47076287339708,
    "lat": 33.78539775773624
  },
  {
    "name": "Springville",
    "lng": -86.46911223361627,
    "lat": 33.81046812423705
  },
  {
    "name": "Springville",
    "lng": -86.46674003313234,
    "lat": 33.81084046837122
  },
  {
    "name": "Springville",
    "lng": -86.4594265777783,
    "lat": 33.7455500245359
  },
  {
    "name": "Springville",
    "lng": -86.43959143691004,
    "lat": 33.75673691346132
  },
  {
    "name": "Springville",
    "lng": -86.43077170488921,
    "lat": 33.788743617751244
  },
  {
    "name": "Springville",
    "lng": -86.42748223668829,
    "lat": 33.74351866941194
  },
  {
    "name": "Springville",
    "lng": -86.42735979992898,
    "lat": 33.74953740029743
  },
  {
    "name": "Springville",
    "lng": -86.42502699289284,
    "lat": 33.74870708705186
  },
  {
    "name": "Springville",
    "lng": -86.42390233083175,
    "lat": 33.79006160343052
  },
  {
    "name": "Springville",
    "lng": -86.40989651160234,
    "lat": 33.794841127550235
  },
  {
    "name": "Springville",
    "lng": -86.40681316846265,
    "lat": 33.73361890547889
  },
  {
    "name": "Springville",
    "lng": -78.6696220374013,
    "lat": 42.508398240751184
  },
  {
    "name": "Spruce Pine",
    "lng": -82.07035826473833,
    "lat": 35.91476932982516
  },
  {
    "name": "Spruce Pine",
    "lng": -82.06851510925223,
    "lat": 35.89762322589214
  },
  {
    "name": "Spruce Pine",
    "lng": -82.06821662764338,
    "lat": 35.896795922434926
  },
  {
    "name": "Spruce Pine",
    "lng": -82.06583736687314,
    "lat": 35.88975616128609
  },
  {
    "name": "Spruce Pine",
    "lng": -82.0449744487358,
    "lat": 35.870680121649556
  },
  {
    "name": "Spruce Pine",
    "lng": -82.04049153282776,
    "lat": 35.92343251085689
  },
  {
    "name": "Spruce Pine",
    "lng": -81.99394630585887,
    "lat": 35.90514115203392
  },
  {
    "name": "Spry",
    "lng": -76.68634602156753,
    "lat": 39.912523871600804
  },
  {
    "name": "Spur",
    "lng": -100.85565884675081,
    "lat": 33.470113755428
  },
  {
    "name": "Spurgeon",
    "lng": -87.25874218197583,
    "lat": 38.25528325525286
  },
  {
    "name": "Squaw Lake",
    "lng": -94.13814844289091,
    "lat": 47.62742418237377
  },
  {
    "name": "St. Albans",
    "lng": -81.81731631694205,
    "lat": 38.376931946433
  },
  {
    "name": "St. Ann",
    "lng": -90.38720326015773,
    "lat": 38.72662704161813
  },
  {
    "name": "St. Anne",
    "lng": -87.71838923940786,
    "lat": 41.02327356948183
  },
  {
    "name": "St. Anthony",
    "lng": -111.68419097303116,
    "lat": 43.96476462946237
  },
  {
    "name": "St. Anthony",
    "lng": -94.61313322502588,
    "lat": 45.68895661212193
  },
  {
    "name": "St. Anthony",
    "lng": -93.21740402111939,
    "lat": 45.027780376084074
  },
  {
    "name": "St. Anthony",
    "lng": -93.19746316624,
    "lat": 42.12344796703502
  },
  {
    "name": "St. Augusta",
    "lng": -94.19954382710193,
    "lat": 45.4497100226035
  },
  {
    "name": "St. Augustine",
    "lng": -90.40692783453264,
    "lat": 40.72127306449686
  },
  {
    "name": "St. Augustine Beach",
    "lng": -81.27131099906728,
    "lat": 29.84135067644918
  },
  {
    "name": "St. Augustine Shores",
    "lng": -81.30922884774611,
    "lat": 29.808447664561317
  },
  {
    "name": "St. Augustine South",
    "lng": -81.31550992216395,
    "lat": 29.84425911578649
  },
  {
    "name": "St. Bonifacius",
    "lng": -93.7475507850454,
    "lat": 44.90541180663929
  },
  {
    "name": "St. Charles",
    "lng": -111.39039694007234,
    "lat": 42.11251274318528
  },
  {
    "name": "St. Charles",
    "lng": -92.05951986022052,
    "lat": 43.96923324438659
  },
  {
    "name": "St. Charles",
    "lng": -92.05876751910321,
    "lat": 43.988896845320546
  },
  {
    "name": "St. Charles",
    "lng": -92.02452170778334,
    "lat": 43.96723220353038
  },
  {
    "name": "St. Charles",
    "lng": -91.13736307797804,
    "lat": 34.37380198542786
  },
  {
    "name": "St. Charles",
    "lng": -88.36919567724442,
    "lat": 41.90853200501112
  },
  {
    "name": "St. Charles",
    "lng": -88.34700730589846,
    "lat": 41.91888438033083
  },
  {
    "name": "St. Charles",
    "lng": -88.33337666399706,
    "lat": 41.931866922922474
  },
  {
    "name": "St. Charles",
    "lng": -88.30239596104335,
    "lat": 41.9208555802606
  },
  {
    "name": "St. Charles",
    "lng": -87.55526751283216,
    "lat": 37.187700943162106
  },
  {
    "name": "St. Charles",
    "lng": -83.05774239106604,
    "lat": 36.80445235750066
  },
  {
    "name": "St. Clair",
    "lng": -93.85793612311984,
    "lat": 44.082015980779836
  },
  {
    "name": "St. Clair",
    "lng": -90.98851877464504,
    "lat": 38.34927010228242
  },
  {
    "name": "St. Clairsville",
    "lng": -80.89962156881798,
    "lat": 40.07921101643633
  },
  {
    "name": "St. Cloud",
    "lng": -94.18202615820854,
    "lat": 45.53168484576832
  },
  {
    "name": "St. Cloud",
    "lng": -94.08866552095085,
    "lat": 45.57156161096934
  },
  {
    "name": "St. Cloud",
    "lng": -94.06953990836415,
    "lat": 45.582237611143896
  },
  {
    "name": "St. Cloud",
    "lng": -94.06346681964021,
    "lat": 45.54646436826489
  },
  {
    "name": "St. Cloud",
    "lng": -94.0515000875419,
    "lat": 45.56101916225419
  },
  {
    "name": "St. Cloud",
    "lng": -94.03909166243788,
    "lat": 45.561669374903175
  },
  {
    "name": "St. Cloud",
    "lng": -91.21273450397875,
    "lat": 38.173202883311234
  },
  {
    "name": "St. Cloud",
    "lng": -88.16865490763965,
    "lat": 43.82444547974921
  },
  {
    "name": "St. Croix Falls",
    "lng": -92.62448622255259,
    "lat": 45.41011246486529
  },
  {
    "name": "St. David",
    "lng": -90.05193002219049,
    "lat": 40.492051150430804
  },
  {
    "name": "St. Donatus",
    "lng": -90.54215182353289,
    "lat": 42.362616796942
  },
  {
    "name": "St. Elizabeth",
    "lng": -92.26619608631671,
    "lat": 38.25671064471068
  },
  {
    "name": "St. Elmo",
    "lng": -88.85864593433233,
    "lat": 39.008344896541736
  },
  {
    "name": "St. Elmo",
    "lng": -88.85220333038146,
    "lat": 39.024484829649445
  },
  {
    "name": "St. Florian",
    "lng": -87.62445327338685,
    "lat": 34.86976031206993
  },
  {
    "name": "St. Francis",
    "lng": -101.80143899928501,
    "lat": 39.771439434131565
  },
  {
    "name": "St. Francis",
    "lng": -100.90278603015625,
    "lat": 43.14260502623265
  },
  {
    "name": "St. Francis",
    "lng": -93.389758905218,
    "lat": 45.399089945670006
  },
  {
    "name": "St. Francis",
    "lng": -90.14240709342242,
    "lat": 36.45537468373675
  },
  {
    "name": "St. Francisville",
    "lng": -91.37868119711638,
    "lat": 30.785129022904204
  },
  {
    "name": "St. Francisville",
    "lng": -87.6474489374035,
    "lat": 38.59185193122479
  },
  {
    "name": "St. Gabriel",
    "lng": -91.10131927775538,
    "lat": 30.253625442843205
  },
  {
    "name": "St. George",
    "lng": -169.628176344118,
    "lat": 56.58999888050982
  },
  {
    "name": "St. George",
    "lng": -113.57712929737556,
    "lat": 37.07701613821105
  },
  {
    "name": "St. George",
    "lng": -96.41792313039639,
    "lat": 39.191409010439266
  },
  {
    "name": "St. George",
    "lng": -90.31319444299949,
    "lat": 38.53717692733896
  },
  {
    "name": "St. George",
    "lng": -80.57948296208791,
    "lat": 33.186053227669
  },
  {
    "name": "St. Hedwig",
    "lng": -98.20342651469534,
    "lat": 29.41919664433048
  },
  {
    "name": "St. Helena",
    "lng": -122.4680664831339,
    "lat": 38.50429762842163
  },
  {
    "name": "St. Helena",
    "lng": -97.2491241052425,
    "lat": 42.8108434646985
  },
  {
    "name": "St. Helena",
    "lng": -77.91739281970496,
    "lat": 34.51662473504656
  },
  {
    "name": "St. Henry",
    "lng": -84.63251121271941,
    "lat": 40.42092103770402
  },
  {
    "name": "St. Hilaire",
    "lng": -96.2138240638883,
    "lat": 48.01359176591129
  },
  {
    "name": "St. Jacob",
    "lng": -89.76775667192997,
    "lat": 38.71970228634347
  },
  {
    "name": "St. James",
    "lng": -94.64046643278134,
    "lat": 43.98327579638995
  },
  {
    "name": "St. James",
    "lng": -94.62501963931973,
    "lat": 43.98342307455671
  },
  {
    "name": "St. James",
    "lng": -91.61511307359062,
    "lat": 38.001502677041714
  },
  {
    "name": "St. James",
    "lng": -78.11405020521424,
    "lat": 33.945329016387156
  },
  {
    "name": "St. Joe",
    "lng": -92.80774124991275,
    "lat": 36.02901912603672
  },
  {
    "name": "St. Joe",
    "lng": -84.9027053174787,
    "lat": 41.31483595545882
  },
  {
    "name": "St. John",
    "lng": -117.58850983613576,
    "lat": 47.09114445239931
  },
  {
    "name": "St. John",
    "lng": -99.71081092858466,
    "lat": 48.942672148277495
  },
  {
    "name": "St. John",
    "lng": -98.7606706415022,
    "lat": 38.00088457353906
  },
  {
    "name": "St. John",
    "lng": -87.4740157438498,
    "lat": 41.443713654437296
  },
  {
    "name": "St. Johns",
    "lng": -109.37833317134844,
    "lat": 34.50179423509398
  },
  {
    "name": "St. Johns",
    "lng": -89.2398278357111,
    "lat": 38.03341435843958
  },
  {
    "name": "St. Johns",
    "lng": -84.55568087877946,
    "lat": 43.00086870406154
  },
  {
    "name": "St. Johnsville",
    "lng": -74.67816525007265,
    "lat": 43.00107547968172
  },
  {
    "name": "St. Joseph",
    "lng": -94.82079804044729,
    "lat": 39.75989732144296
  },
  {
    "name": "St. Joseph",
    "lng": -94.34039220261687,
    "lat": 45.553921531063104
  },
  {
    "name": "St. Joseph",
    "lng": -94.3074924671789,
    "lat": 45.561048796845334
  },
  {
    "name": "St. Joseph",
    "lng": -91.24004038002903,
    "lat": 31.920284199980266
  },
  {
    "name": "St. Joseph",
    "lng": -88.05000358881583,
    "lat": 40.10543225104553
  },
  {
    "name": "St. Joseph",
    "lng": -88.03532462665868,
    "lat": 40.114158082715626
  },
  {
    "name": "St. Joseph",
    "lng": -87.50159885539871,
    "lat": 35.03256934827119
  },
  {
    "name": "St. Lawrence",
    "lng": -98.94030426198191,
    "lat": 44.517221893430005
  },
  {
    "name": "St. Lawrence",
    "lng": -75.86588603058264,
    "lat": 40.326033331769686
  },
  {
    "name": "St. Leo",
    "lng": -96.05258956739843,
    "lat": 44.71731343532661
  },
  {
    "name": "St. Leo",
    "lng": -82.25850445817126,
    "lat": 28.33589657154278
  },
  {
    "name": "St. Libory",
    "lng": -89.71267790777748,
    "lat": 38.363396783955906
  },
  {
    "name": "St. Louis",
    "lng": -90.24503552969341,
    "lat": 38.636438557371534
  },
  {
    "name": "St. Louisville",
    "lng": -82.41796685360576,
    "lat": 40.17113992155049
  },
  {
    "name": "St. Lucas",
    "lng": -91.93412291195403,
    "lat": 43.06661134680959
  },
  {
    "name": "St. Martin",
    "lng": -94.66764827946659,
    "lat": 45.50278303200971
  },
  {
    "name": "St. Martin",
    "lng": -83.88727503007796,
    "lat": 39.21491499923425
  },
  {
    "name": "St. Martins",
    "lng": -92.33289343054004,
    "lat": 38.59506202114447
  },
  {
    "name": "St. Martins",
    "lng": -92.3086612890069,
    "lat": 38.58249418775106
  },
  {
    "name": "St. Martinville",
    "lng": -91.83185639023803,
    "lat": 30.12635926396489
  },
  {
    "name": "St. Mary",
    "lng": -89.949673479909,
    "lat": 37.8735848566716
  },
  {
    "name": "St. Mary's",
    "lng": -163.2737933698343,
    "lat": 62.0706947683654
  },
  {
    "name": "St. Marys",
    "lng": -96.07553528621654,
    "lat": 39.187427389120344
  },
  {
    "name": "St. Marys",
    "lng": -96.07485295953211,
    "lat": 39.18628883892735
  },
  {
    "name": "St. Marys",
    "lng": -96.06464215451255,
    "lat": 39.194272989577044
  },
  {
    "name": "St. Marys",
    "lng": -93.73396858980094,
    "lat": 41.3076676816163
  },
  {
    "name": "St. Marys",
    "lng": -81.57225266204657,
    "lat": 30.75679330013316
  },
  {
    "name": "St. Marys",
    "lng": -81.19490641325277,
    "lat": 39.402412168641774
  },
  {
    "name": "St. Matthews",
    "lng": -85.63834543162864,
    "lat": 38.24961801876744
  },
  {
    "name": "St. Matthews",
    "lng": -80.7779962096925,
    "lat": 33.66402625610048
  },
  {
    "name": "St. Michael",
    "lng": -162.1074086396924,
    "lat": 63.46947418837133
  },
  {
    "name": "St. Michael",
    "lng": -93.69200662851647,
    "lat": 45.201376882536756
  },
  {
    "name": "St. Michaels",
    "lng": -76.22492394015318,
    "lat": 38.78838038041171
  },
  {
    "name": "St. Nazianz",
    "lng": -87.92439124611069,
    "lat": 44.006504240247146
  },
  {
    "name": "St. Olaf",
    "lng": -91.3880183736123,
    "lat": 42.926917425417216
  },
  {
    "name": "St. Paris",
    "lng": -83.95813040884165,
    "lat": 40.126183066760234
  },
  {
    "name": "St. Paul",
    "lng": -170.29286073992628,
    "lat": 57.16475715696413
  },
  {
    "name": "St. Paul",
    "lng": -169.95035762713195,
    "lat": 57.184127693881
  },
  {
    "name": "St. Paul",
    "lng": -95.17482425700453,
    "lat": 37.51828208017545
  },
  {
    "name": "St. Paul",
    "lng": -93.7641544101723,
    "lat": 35.82442904957633
  },
  {
    "name": "St. Paul",
    "lng": -91.51641783018779,
    "lat": 40.767830273586505
  },
  {
    "name": "St. Paul",
    "lng": -85.62890445788177,
    "lat": 39.42786869222252
  },
  {
    "name": "St. Paul",
    "lng": -82.31632386236863,
    "lat": 36.906847461503
  },
  {
    "name": "St. Paul Park",
    "lng": -92.99491308934284,
    "lat": 44.835868865740686
  },
  {
    "name": "St. Pauls",
    "lng": -78.98878762286593,
    "lat": 34.8119800940842
  },
  {
    "name": "St. Pauls",
    "lng": -78.97833006585009,
    "lat": 34.80303710489813
  },
  {
    "name": "St. Pauls",
    "lng": -78.97832336595503,
    "lat": 34.81986311409312
  },
  {
    "name": "St. Pauls",
    "lng": -78.97813645281556,
    "lat": 34.821239474389095
  },
  {
    "name": "St. Pauls",
    "lng": -78.97753344024974,
    "lat": 34.819023663792684
  },
  {
    "name": "St. Pauls",
    "lng": -78.9734914911072,
    "lat": 34.80690557015503
  },
  {
    "name": "St. Pauls",
    "lng": -78.96560243464863,
    "lat": 34.809918044599634
  },
  {
    "name": "St. Pete Beach",
    "lng": -82.76499328012106,
    "lat": 27.707393072748015
  },
  {
    "name": "St. Peter",
    "lng": -93.9667180512417,
    "lat": 44.32908613570472
  },
  {
    "name": "St. Peter",
    "lng": -88.85038603666533,
    "lat": 38.867559107174195
  },
  {
    "name": "St. Regis Park",
    "lng": -85.61570788746155,
    "lat": 38.22898939380979
  },
  {
    "name": "St. Rosa",
    "lng": -94.7160640462669,
    "lat": 45.72865293409864
  },
  {
    "name": "St. Stephen",
    "lng": -94.27418306170493,
    "lat": 45.70104321164794
  },
  {
    "name": "St. Stephen",
    "lng": -79.92490648695163,
    "lat": 33.405117316090596
  },
  {
    "name": "St. Thomas",
    "lng": -97.4470988667305,
    "lat": 48.61937032880692
  },
  {
    "name": "St. Thomas",
    "lng": -92.21583416048568,
    "lat": 38.36670830529661
  },
  {
    "name": "St. Vincent",
    "lng": -97.22633773767805,
    "lat": 48.96930573082521
  },
  {
    "name": "Stable Gate",
    "lng": -95.65818341816572,
    "lat": 30.001819030849155
  },
  {
    "name": "Stacy",
    "lng": -92.99422015230034,
    "lat": 45.38383392613218
  },
  {
    "name": "Stacyville",
    "lng": -92.7840857399924,
    "lat": 43.43915927321423
  },
  {
    "name": "Stacyville",
    "lng": -68.49243961038779,
    "lat": 45.89967462205013
  },
  {
    "name": "Stafford",
    "lng": -98.59933615487127,
    "lat": 37.96235193085129
  },
  {
    "name": "Stafford",
    "lng": -95.5654217830021,
    "lat": 29.62721282910038
  },
  {
    "name": "Stafford",
    "lng": -81.27657987744024,
    "lat": 39.712412316419595
  },
  {
    "name": "Stafford",
    "lng": -72.31201022872574,
    "lat": 41.98764585408436
  },
  {
    "name": "Stagecoach",
    "lng": -95.70717650571353,
    "lat": 30.14365610898631
  },
  {
    "name": "Staley",
    "lng": -79.55175019941237,
    "lat": 35.79795657769935
  },
  {
    "name": "Stallings",
    "lng": -80.72781827205618,
    "lat": 35.06281556708762
  },
  {
    "name": "Stallings",
    "lng": -80.7182863407088,
    "lat": 35.066945276828775
  },
  {
    "name": "Stallings",
    "lng": -80.6592382146664,
    "lat": 35.109095584270136
  },
  {
    "name": "Stallion Springs",
    "lng": -118.64772267093791,
    "lat": 35.09347954533935
  },
  {
    "name": "Stamford",
    "lng": -99.667379795808,
    "lat": 33.01758742761541
  },
  {
    "name": "Stamford",
    "lng": -99.59443850571127,
    "lat": 40.13124347426757
  },
  {
    "name": "Stamford",
    "lng": -74.61696754326636,
    "lat": 42.40903627883725
  },
  {
    "name": "Stamford",
    "lng": -73.54967970829559,
    "lat": 41.08004044134541
  },
  {
    "name": "Stamping Ground",
    "lng": -84.68384044875043,
    "lat": 38.269490116624034
  },
  {
    "name": "Stamps",
    "lng": -93.49557902297525,
    "lat": 33.35672323205031
  },
  {
    "name": "Stanardsville",
    "lng": -78.43720854799717,
    "lat": 38.2985517896793
  },
  {
    "name": "Stanberry",
    "lng": -94.53753257333173,
    "lat": 40.21679591625394
  },
  {
    "name": "Standard",
    "lng": -89.18174065628916,
    "lat": 41.25577503742932
  },
  {
    "name": "Standard City",
    "lng": -89.78360047258064,
    "lat": 39.35036647570795
  },
  {
    "name": "Standish",
    "lng": -83.96312083566227,
    "lat": 43.97926960326462
  },
  {
    "name": "Standish",
    "lng": -70.5684386353556,
    "lat": 43.78118784595208
  },
  {
    "name": "Stanfield",
    "lng": -80.43060508981036,
    "lat": 35.23388071566179
  },
  {
    "name": "Stanford",
    "lng": -110.21843524149976,
    "lat": 47.151823316627244
  },
  {
    "name": "Stanford",
    "lng": -89.22084061636599,
    "lat": 40.43180223502451
  },
  {
    "name": "Stanford",
    "lng": -84.66113627138552,
    "lat": 37.534375185464796
  },
  {
    "name": "Stanhope",
    "lng": -93.79600175177978,
    "lat": 42.288964488463634
  },
  {
    "name": "Stanley",
    "lng": -114.93634029789722,
    "lat": 44.2150243816838
  },
  {
    "name": "Stanley",
    "lng": -102.38767421559461,
    "lat": 48.31673149681155
  },
  {
    "name": "Stanley",
    "lng": -93.8995187520164,
    "lat": 31.960851664491265
  },
  {
    "name": "Stanley",
    "lng": -91.81228424363127,
    "lat": 42.641882192354345
  },
  {
    "name": "Stanley",
    "lng": -91.80376673139598,
    "lat": 42.64442542668179
  },
  {
    "name": "Stanley",
    "lng": -90.94087163872517,
    "lat": 44.95974764293286
  },
  {
    "name": "Stanley",
    "lng": -81.11023463073893,
    "lat": 35.37279314265367
  },
  {
    "name": "Stanley",
    "lng": -81.1086589550384,
    "lat": 35.37585409690594
  },
  {
    "name": "Stanley",
    "lng": -81.0963787194066,
    "lat": 35.35658277586079
  },
  {
    "name": "Stanley",
    "lng": -81.0726134519398,
    "lat": 35.334811999385536
  },
  {
    "name": "Stanley",
    "lng": -78.50283032904724,
    "lat": 38.576887823640426
  },
  {
    "name": "Stanton",
    "lng": -117.99348922432884,
    "lat": 33.800207508295635
  },
  {
    "name": "Stanton",
    "lng": -101.79255311004802,
    "lat": 32.131506533404846
  },
  {
    "name": "Stanton",
    "lng": -101.38439031728893,
    "lat": 47.31687087366786
  },
  {
    "name": "Stanton",
    "lng": -97.22272568148088,
    "lat": 41.94875983823144
  },
  {
    "name": "Stanton",
    "lng": -97.20278791856687,
    "lat": 41.94304503342487
  },
  {
    "name": "Stanton",
    "lng": -95.10270926529321,
    "lat": 40.98128075796193
  },
  {
    "name": "Stanton",
    "lng": -89.4014353078524,
    "lat": 35.46141137346765
  },
  {
    "name": "Stanton",
    "lng": -85.08411230143264,
    "lat": 43.304939300200004
  },
  {
    "name": "Stanton",
    "lng": -85.078852136573,
    "lat": 43.29274693636842
  },
  {
    "name": "Stanton",
    "lng": -83.857665384605,
    "lat": 37.848439628440275
  },
  {
    "name": "Stantonsburg",
    "lng": -77.82101771858075,
    "lat": 35.60558557400315
  },
  {
    "name": "Stantonville",
    "lng": -88.42983752789713,
    "lat": 35.15691048068653
  },
  {
    "name": "Stanwood",
    "lng": -122.343287855583,
    "lat": 48.244925907322504
  },
  {
    "name": "Stanwood",
    "lng": -91.14888098224829,
    "lat": 41.89298098993733
  },
  {
    "name": "Stanwood",
    "lng": -85.44817411321688,
    "lat": 43.58040896292798
  },
  {
    "name": "Staplehurst",
    "lng": -97.17319233338745,
    "lat": 40.974670785430064
  },
  {
    "name": "Staples",
    "lng": -97.82809908416095,
    "lat": 29.76711609669056
  },
  {
    "name": "Staples",
    "lng": -94.810446305327,
    "lat": 46.35285275638766
  },
  {
    "name": "Staples",
    "lng": -94.80286526479254,
    "lat": 46.36939743356876
  },
  {
    "name": "Staples",
    "lng": -94.75085084403545,
    "lat": 46.35181912479255
  },
  {
    "name": "Stapleton",
    "lng": -100.51256598700087,
    "lat": 41.48019854974869
  },
  {
    "name": "Stapleton",
    "lng": -82.46724039214979,
    "lat": 33.21689042305636
  },
  {
    "name": "Star",
    "lng": -116.50403816897187,
    "lat": 43.725188473209556
  },
  {
    "name": "Star",
    "lng": -116.49070401329385,
    "lat": 43.70300335596965
  },
  {
    "name": "Star",
    "lng": -79.78347433200572,
    "lat": 35.399083442971104
  },
  {
    "name": "Star City",
    "lng": -91.83988612079762,
    "lat": 33.94071120110312
  },
  {
    "name": "Star City",
    "lng": -79.98680758089047,
    "lat": 39.658799587943946
  },
  {
    "name": "Star Harbor",
    "lng": -96.05419664731178,
    "lat": 32.192781039238845
  },
  {
    "name": "Star Prairie",
    "lng": -92.53213466788056,
    "lat": 45.19755856841399
  },
  {
    "name": "Star Valley",
    "lng": -111.23808710655773,
    "lat": 34.26128630130614
  },
  {
    "name": "Star Valley Ranch",
    "lng": -110.98413448583217,
    "lat": 42.9641224789327
  },
  {
    "name": "Star Valley Ranch",
    "lng": -110.97458618264454,
    "lat": 43.0023982849697
  },
  {
    "name": "Star Valley Ranch",
    "lng": -110.9570711014391,
    "lat": 42.975028634735516
  },
  {
    "name": "Starbuck",
    "lng": -118.12539012685133,
    "lat": 46.51858062067696
  },
  {
    "name": "Starbuck",
    "lng": -95.53257329670113,
    "lat": 45.61189257525219
  },
  {
    "name": "Stark",
    "lng": -95.14381620392993,
    "lat": 37.68965157039478
  },
  {
    "name": "Stark City",
    "lng": -94.18809427012489,
    "lat": 36.8602914694242
  },
  {
    "name": "Starke",
    "lng": -82.11297143245343,
    "lat": 29.947280792249373
  },
  {
    "name": "Starks",
    "lng": -69.95209560214343,
    "lat": 44.73833089870642
  },
  {
    "name": "Starkville",
    "lng": -104.52323730718845,
    "lat": 37.11677298910057
  },
  {
    "name": "Starkville",
    "lng": -88.82962460964487,
    "lat": 33.46117692528673
  },
  {
    "name": "Starkweather",
    "lng": -98.8787693760353,
    "lat": 48.45225127415611
  },
  {
    "name": "Starr",
    "lng": -82.69561246423764,
    "lat": 34.37797644140619
  },
  {
    "name": "State Center",
    "lng": -93.16531848220602,
    "lat": 42.01484578242522
  },
  {
    "name": "State College",
    "lng": -77.8568506849471,
    "lat": 40.790933176986705
  },
  {
    "name": "State Line",
    "lng": -88.47384568336393,
    "lat": 31.43598769695001
  },
  {
    "name": "State Line City",
    "lng": -87.52701567436365,
    "lat": 40.19738067955559
  },
  {
    "name": "Stateline",
    "lng": -117.0366291608323,
    "lat": 47.705341374467295
  },
  {
    "name": "Statesboro",
    "lng": -81.77524961217321,
    "lat": 32.44071019883258
  },
  {
    "name": "Statesville",
    "lng": -80.95332820585975,
    "lat": 35.762030367288595
  },
  {
    "name": "Statesville",
    "lng": -80.94685550138827,
    "lat": 35.80068533232162
  },
  {
    "name": "Statesville",
    "lng": -80.89060168538171,
    "lat": 35.75758404619203
  },
  {
    "name": "Statesville",
    "lng": -80.87716440591116,
    "lat": 35.818557571380055
  },
  {
    "name": "Statesville",
    "lng": -80.87329134880014,
    "lat": 35.83039429267032
  },
  {
    "name": "Statesville",
    "lng": -80.87136484930308,
    "lat": 35.78830939230512
  },
  {
    "name": "Statesville",
    "lng": -80.87041112294084,
    "lat": 35.7474855554171
  },
  {
    "name": "Statesville",
    "lng": -80.84380397629523,
    "lat": 35.771976790053415
  },
  {
    "name": "Statesville",
    "lng": -80.82632839202446,
    "lat": 35.82398380847141
  },
  {
    "name": "Statesville",
    "lng": -80.82304279652016,
    "lat": 35.76139115803445
  },
  {
    "name": "Statesville",
    "lng": -80.78906417263258,
    "lat": 35.77265744863666
  },
  {
    "name": "Statesville",
    "lng": -80.78457514042174,
    "lat": 35.752582784222575
  },
  {
    "name": "Statham",
    "lng": -83.6023865777104,
    "lat": 33.96466394457596
  },
  {
    "name": "Staunton",
    "lng": -89.79042596608981,
    "lat": 39.01178999623177
  },
  {
    "name": "Staunton",
    "lng": -87.18901775911485,
    "lat": 39.486402825052885
  },
  {
    "name": "Staunton",
    "lng": -79.06111601932528,
    "lat": 38.15930693357569
  },
  {
    "name": "Ste. Marie",
    "lng": -88.02775536927479,
    "lat": 38.93004874705716
  },
  {
    "name": "Steamboat Rock",
    "lng": -93.06577287195458,
    "lat": 42.40839777081933
  },
  {
    "name": "Stebbins",
    "lng": -162.26227004771704,
    "lat": 63.46778997473323
  },
  {
    "name": "Stedman",
    "lng": -78.7233256110023,
    "lat": 35.030963022915124
  },
  {
    "name": "Stedman",
    "lng": -78.7039687944848,
    "lat": 34.9961346288805
  },
  {
    "name": "Stedman",
    "lng": -78.69840011001881,
    "lat": 35.01173167346214
  },
  {
    "name": "Steele",
    "lng": -99.91746904042277,
    "lat": 46.855932916312426
  },
  {
    "name": "Steele",
    "lng": -89.84588619826494,
    "lat": 36.09036906888054
  },
  {
    "name": "Steele",
    "lng": -86.2009897635576,
    "lat": 33.93682644256157
  },
  {
    "name": "Steele City",
    "lng": -97.02297834734448,
    "lat": 40.03680824495867
  },
  {
    "name": "Steeleville",
    "lng": -89.6615651553356,
    "lat": 38.007668516600454
  },
  {
    "name": "Steelville",
    "lng": -91.35400740727916,
    "lat": 37.96864336867492
  },
  {
    "name": "Steen",
    "lng": -96.2630824094797,
    "lat": 43.51430715892467
  },
  {
    "name": "Steger",
    "lng": -87.61764475378064,
    "lat": 41.47226539417574
  },
  {
    "name": "Steilacoom",
    "lng": -122.59370695185743,
    "lat": 47.16999638834614
  },
  {
    "name": "Steinauer",
    "lng": -96.23289193640616,
    "lat": 40.2072997035434
  },
  {
    "name": "Stella",
    "lng": -95.77341114030628,
    "lat": 40.23220733399283
  },
  {
    "name": "Stella",
    "lng": -94.19089489768521,
    "lat": 36.762345232293484
  },
  {
    "name": "Stem",
    "lng": -78.74021114724346,
    "lat": 36.18224128672031
  },
  {
    "name": "Stem",
    "lng": -78.72236165921841,
    "lat": 36.20050208065423
  },
  {
    "name": "Stem",
    "lng": -78.70966061688792,
    "lat": 36.18859750015827
  },
  {
    "name": "Stephen",
    "lng": -96.87787184309602,
    "lat": 48.45132866597486
  },
  {
    "name": "Stephen",
    "lng": -96.86622872639451,
    "lat": 48.46102758983756
  },
  {
    "name": "Stephens",
    "lng": -93.06691648001251,
    "lat": 33.422096088226525
  },
  {
    "name": "Stephens City",
    "lng": -78.22312459656074,
    "lat": 39.09027496284112
  },
  {
    "name": "Stephenson",
    "lng": -87.60904269938939,
    "lat": 45.413666973116165
  },
  {
    "name": "Stephenville Country Club Lake",
    "lng": -98.18027263937087,
    "lat": 32.31330823150874
  },
  {
    "name": "Sterling",
    "lng": -111.69040232437166,
    "lat": 39.193309522055955
  },
  {
    "name": "Sterling",
    "lng": -103.19450689699612,
    "lat": 40.622526770278995
  },
  {
    "name": "Sterling",
    "lng": -98.20684272697024,
    "lat": 38.208993360949805
  },
  {
    "name": "Sterling",
    "lng": -98.19667032592842,
    "lat": 38.21764146943177
  },
  {
    "name": "Sterling",
    "lng": -98.17290559822469,
    "lat": 34.74981807441627
  },
  {
    "name": "Sterling",
    "lng": -96.37814949825719,
    "lat": 40.461435972971515
  },
  {
    "name": "Sterling",
    "lng": -96.37508675875269,
    "lat": 40.46787467277667
  },
  {
    "name": "Sterling",
    "lng": -89.69446085788591,
    "lat": 41.79949860476174
  },
  {
    "name": "Sterling",
    "lng": -89.66844776177639,
    "lat": 41.810759344491366
  },
  {
    "name": "Sterling",
    "lng": -84.0193708021375,
    "lat": 44.03249448907666
  },
  {
    "name": "Sterling",
    "lng": -71.81849188016481,
    "lat": 41.702607066735325
  },
  {
    "name": "Sterling",
    "lng": -71.77297390027022,
    "lat": 42.44125830103597
  },
  {
    "name": "Sterling City",
    "lng": -100.98587775693487,
    "lat": 31.83917533720303
  },
  {
    "name": "Sterling Heights",
    "lng": -83.03036898495321,
    "lat": 42.580994492419414
  },
  {
    "name": "Sterlington",
    "lng": -92.06276182081128,
    "lat": 32.71415787025285
  },
  {
    "name": "Sterlington",
    "lng": -92.06030209341918,
    "lat": 32.68846933354014
  },
  {
    "name": "Stetson",
    "lng": -69.11137588774302,
    "lat": 44.87594620163089
  },
  {
    "name": "Stetsonville",
    "lng": -90.31430626208854,
    "lat": 45.077140529044804
  },
  {
    "name": "Steuben",
    "lng": -90.85690026702528,
    "lat": 43.18327906526182
  },
  {
    "name": "Steuben",
    "lng": -67.93843150990976,
    "lat": 44.48291103264589
  },
  {
    "name": "Steubenville",
    "lng": -80.66984616919821,
    "lat": 40.34075658080196
  },
  {
    "name": "Steubenville",
    "lng": -80.6497977290141,
    "lat": 40.368262760025395
  },
  {
    "name": "Stevens Point",
    "lng": -89.55483600816468,
    "lat": 44.525215535737615
  },
  {
    "name": "Stevenson",
    "lng": -121.89318823626962,
    "lat": 45.69436976135589
  },
  {
    "name": "Stevensville",
    "lng": -114.09180637276766,
    "lat": 46.507410201289645
  },
  {
    "name": "Stevensville",
    "lng": -86.52430891827558,
    "lat": 42.01392555264331
  },
  {
    "name": "Steward",
    "lng": -89.01659197639496,
    "lat": 41.84884444947051
  },
  {
    "name": "Stewardson",
    "lng": -88.63018418672537,
    "lat": 39.263966715803946
  },
  {
    "name": "Stewardson",
    "lng": -88.61848074597594,
    "lat": 39.26236802419907
  },
  {
    "name": "Stewart",
    "lng": -94.48682101987897,
    "lat": 44.723515997271434
  },
  {
    "name": "Stewart Manor",
    "lng": -73.6853003863774,
    "lat": 40.72017216487981
  },
  {
    "name": "Stewartstown",
    "lng": -76.59249439696956,
    "lat": 39.752719001509924
  },
  {
    "name": "Stewartsville",
    "lng": -94.4987334628989,
    "lat": 39.75511059716713
  },
  {
    "name": "Stewartville",
    "lng": -92.48900415002362,
    "lat": 43.860231494471016
  },
  {
    "name": "Stickney",
    "lng": -98.43763561091666,
    "lat": 43.5893036245541
  },
  {
    "name": "Stidham",
    "lng": -95.700844511564,
    "lat": 35.36862240851698
  },
  {
    "name": "Stigler",
    "lng": -95.12013103436779,
    "lat": 35.25777612562204
  },
  {
    "name": "Stilesville",
    "lng": -86.63436601690455,
    "lat": 39.6370810761241
  },
  {
    "name": "Stillaguamish Village",
    "lng": -122.15763535836274,
    "lat": 48.213083304953315
  },
  {
    "name": "Stillman Valley",
    "lng": -89.17997805542085,
    "lat": 42.104110134278166
  },
  {
    "name": "Stillmore",
    "lng": -82.21436860943115,
    "lat": 32.44149500839753
  },
  {
    "name": "Stillwater",
    "lng": -97.0744215924075,
    "lat": 36.13109706093445
  },
  {
    "name": "Stillwater",
    "lng": -92.86069589133639,
    "lat": 45.03356858298776
  },
  {
    "name": "Stillwater",
    "lng": -92.83121999675026,
    "lat": 45.05728062006063
  },
  {
    "name": "Stillwater",
    "lng": -73.6439257044072,
    "lat": 42.946584144121054
  },
  {
    "name": "Stilwell",
    "lng": -94.63164164228257,
    "lat": 35.81432507447849
  },
  {
    "name": "Stinesville",
    "lng": -86.64986208394677,
    "lat": 39.29994531889963
  },
  {
    "name": "Stinnett",
    "lng": -101.44351248474587,
    "lat": 35.82336023172372
  },
  {
    "name": "Stites",
    "lng": -115.9761870309372,
    "lat": 46.092132152200406
  },
  {
    "name": "Stockbridge",
    "lng": -88.3102704344596,
    "lat": 44.07166092075063
  },
  {
    "name": "Stockbridge",
    "lng": -84.2293669074718,
    "lat": 33.52648010114965
  },
  {
    "name": "Stockbridge",
    "lng": -84.21942968045988,
    "lat": 33.48158552317879
  },
  {
    "name": "Stockbridge",
    "lng": -84.17565837818431,
    "lat": 42.448343731609405
  },
  {
    "name": "Stockbridge",
    "lng": -73.32450246733397,
    "lat": 42.3025956280041
  },
  {
    "name": "Stockdale",
    "lng": -97.96336871288975,
    "lat": 29.23575399937387
  },
  {
    "name": "Stockham",
    "lng": -97.94336544398824,
    "lat": 40.716410997399684
  },
  {
    "name": "Stockholm",
    "lng": -96.79980199532726,
    "lat": 45.10179393360264
  },
  {
    "name": "Stockholm",
    "lng": -92.26313146704193,
    "lat": 44.48504462679632
  },
  {
    "name": "Stockholm",
    "lng": -68.12089027889192,
    "lat": 47.06676951308792
  },
  {
    "name": "Stockport",
    "lng": -91.8333604460408,
    "lat": 40.857286225477566
  },
  {
    "name": "Stockport",
    "lng": -81.79439684172446,
    "lat": 39.54887032249306
  },
  {
    "name": "Stockton",
    "lng": -121.30915579923564,
    "lat": 37.97668705216855
  },
  {
    "name": "Stockton",
    "lng": -112.36738883379353,
    "lat": 40.44959088950611
  },
  {
    "name": "Stockton",
    "lng": -99.29295488057187,
    "lat": 39.37661891364412
  },
  {
    "name": "Stockton",
    "lng": -99.27218483859669,
    "lat": 39.43695956226118
  },
  {
    "name": "Stockton",
    "lng": -93.79592176229023,
    "lat": 37.69729468153736
  },
  {
    "name": "Stockton",
    "lng": -91.76963797134715,
    "lat": 44.027247583504945
  },
  {
    "name": "Stockton",
    "lng": -90.85685013379243,
    "lat": 41.59063889192717
  },
  {
    "name": "Stockton",
    "lng": -90.00191683568879,
    "lat": 42.352505135575555
  },
  {
    "name": "Stockton Springs",
    "lng": -68.84354060835709,
    "lat": 44.49495579238616
  },
  {
    "name": "Stockville",
    "lng": -100.38442615162563,
    "lat": 40.53362881945836
  },
  {
    "name": "Stoddard",
    "lng": -91.21816024243117,
    "lat": 43.66212864895219
  },
  {
    "name": "Stokesdale",
    "lng": -79.98330571326049,
    "lat": 36.23183370618353
  },
  {
    "name": "Stone Creek",
    "lng": -81.55808237863583,
    "lat": 40.399753436831574
  },
  {
    "name": "Stone Gate",
    "lng": -95.67199493051402,
    "lat": 29.93471258826978
  },
  {
    "name": "Stone Gate",
    "lng": -95.67127070449696,
    "lat": 29.943155146888454
  },
  {
    "name": "Stone Gate",
    "lng": -95.67094461487675,
    "lat": 29.938065673577423
  },
  {
    "name": "Stone Gate",
    "lng": -95.6709355023161,
    "lat": 29.9322887827505
  },
  {
    "name": "Stone Gate",
    "lng": -95.67047539217496,
    "lat": 29.921104994029598
  },
  {
    "name": "Stone Gate",
    "lng": -95.67039071475892,
    "lat": 29.92831465893154
  },
  {
    "name": "Stone Gate",
    "lng": -95.67013318269356,
    "lat": 29.946656305265495
  },
  {
    "name": "Stone Gate",
    "lng": -95.66917798119891,
    "lat": 29.934789052145696
  },
  {
    "name": "Stone Gate",
    "lng": -95.66852363075951,
    "lat": 29.939663635490096
  },
  {
    "name": "Stone Gate",
    "lng": -95.66804601344822,
    "lat": 29.926946779574383
  },
  {
    "name": "Stone Gate",
    "lng": -95.6675369525497,
    "lat": 29.94461373105546
  },
  {
    "name": "Stone Gate",
    "lng": -95.66660694729906,
    "lat": 29.940027714042778
  },
  {
    "name": "Stone Gate",
    "lng": -95.6651893230998,
    "lat": 29.944465499467277
  },
  {
    "name": "Stone Gate",
    "lng": -95.66470692243334,
    "lat": 29.921641451209318
  },
  {
    "name": "Stone Gate",
    "lng": -95.66418081581485,
    "lat": 29.930389822892472
  },
  {
    "name": "Stone Gate",
    "lng": -95.66308393225832,
    "lat": 29.94293488876007
  },
  {
    "name": "Stone Gate",
    "lng": -95.6629866868568,
    "lat": 29.939254379851366
  },
  {
    "name": "Stone Gate",
    "lng": -95.66165898350432,
    "lat": 29.935449835157794
  },
  {
    "name": "Stone Gate",
    "lng": -95.66150205904762,
    "lat": 29.923818960610028
  },
  {
    "name": "Stone Mountain",
    "lng": -84.17226834120702,
    "lat": 33.80349688797818
  },
  {
    "name": "Stone Park",
    "lng": -87.88075825534742,
    "lat": 41.90316642425716
  },
  {
    "name": "Stonecrest",
    "lng": -84.13716202822044,
    "lat": 33.68417159404254
  },
  {
    "name": "Stonefort",
    "lng": -88.70516370825122,
    "lat": 37.61773582485463
  },
  {
    "name": "Stoneham",
    "lng": -71.09716498998188,
    "lat": 42.474090339417465
  },
  {
    "name": "Stoneham",
    "lng": -70.88834864018403,
    "lat": 44.26438857638401
  },
  {
    "name": "Stoneville",
    "lng": -79.92074869364535,
    "lat": 36.45994335583174
  },
  {
    "name": "Stoneville",
    "lng": -79.90608791632954,
    "lat": 36.46631399715288
  },
  {
    "name": "Stonewall",
    "lng": -96.53124972480612,
    "lat": 34.64638908755816
  },
  {
    "name": "Stonewall",
    "lng": -96.52538421755605,
    "lat": 34.65175212241717
  },
  {
    "name": "Stonewall",
    "lng": -93.8099203853055,
    "lat": 32.271549866027655
  },
  {
    "name": "Stonewall",
    "lng": -88.7957544756624,
    "lat": 32.13630916464576
  },
  {
    "name": "Stonewall",
    "lng": -76.7406518123103,
    "lat": 35.13710809222135
  },
  {
    "name": "Stonewood",
    "lng": -80.30544356879376,
    "lat": 39.25012200916053
  },
  {
    "name": "Stonington",
    "lng": -89.1920437720102,
    "lat": 39.63851737486299
  },
  {
    "name": "Stonington",
    "lng": -71.9077547327172,
    "lat": 41.36546309014273
  },
  {
    "name": "Stonington",
    "lng": -68.64450011995467,
    "lat": 44.15089863446034
  },
  {
    "name": "Stony Creek",
    "lng": -77.4000886294091,
    "lat": 36.94801910074687
  },
  {
    "name": "Stonybrook",
    "lng": -76.63171829306242,
    "lat": 39.97989538962906
  },
  {
    "name": "Storden",
    "lng": -95.31724034257628,
    "lat": 44.03964683945974
  },
  {
    "name": "Storm Lake",
    "lng": -95.19723707345408,
    "lat": 42.64301773397801
  },
  {
    "name": "Story City",
    "lng": -93.59145785298716,
    "lat": 42.186671831589116
  },
  {
    "name": "Story City",
    "lng": -93.56383178498047,
    "lat": 42.18598542890868
  },
  {
    "name": "Stotesbury",
    "lng": -94.56447165267143,
    "lat": 37.974349166116774
  },
  {
    "name": "Stotts City",
    "lng": -93.94843733732526,
    "lat": 37.101727338612115
  },
  {
    "name": "Stoughton",
    "lng": -89.22331847816812,
    "lat": 42.92379512522572
  },
  {
    "name": "Stout",
    "lng": -92.71164396410718,
    "lat": 42.52682802973387
  },
  {
    "name": "Stoutland",
    "lng": -92.51425339147602,
    "lat": 37.81356400964395
  },
  {
    "name": "Stoutsville",
    "lng": -91.86146892969053,
    "lat": 39.552204842143865
  },
  {
    "name": "Stoutsville",
    "lng": -91.84740482009792,
    "lat": 39.54786361728704
  },
  {
    "name": "Stoutsville",
    "lng": -82.8233477278702,
    "lat": 39.604976782852376
  },
  {
    "name": "Stovall",
    "lng": -78.56889227875307,
    "lat": 36.4475477206957
  },
  {
    "name": "Stover",
    "lng": -92.9901268178767,
    "lat": 38.44174245171489
  },
  {
    "name": "Stow",
    "lng": -81.4343638811267,
    "lat": 41.17649121895552
  },
  {
    "name": "Stow",
    "lng": -71.51148254145004,
    "lat": 42.429847020005674
  },
  {
    "name": "Stow",
    "lng": -70.98081250598563,
    "lat": 44.19531402733417
  },
  {
    "name": "Stoy",
    "lng": -87.83355930123858,
    "lat": 38.996791602930145
  },
  {
    "name": "Strafford",
    "lng": -93.1209870667213,
    "lat": 37.26863913937267
  },
  {
    "name": "Strafford",
    "lng": -71.14850720916299,
    "lat": 43.280153422370375
  },
  {
    "name": "Strandburg",
    "lng": -96.76044139798489,
    "lat": 45.0440669859235
  },
  {
    "name": "Strandquist",
    "lng": -96.4479253260948,
    "lat": 48.489448892387585
  },
  {
    "name": "Strang",
    "lng": -97.58709222942477,
    "lat": 40.41487479177574
  },
  {
    "name": "Strang",
    "lng": -95.13440025501174,
    "lat": 36.41222225310978
  },
  {
    "name": "Strasburg",
    "lng": -100.16202837536878,
    "lat": 46.13377917246223
  },
  {
    "name": "Strasburg",
    "lng": -94.16483768069253,
    "lat": 38.7598882529708
  },
  {
    "name": "Strasburg",
    "lng": -88.62209333592872,
    "lat": 39.35003058989911
  },
  {
    "name": "Strasburg",
    "lng": -88.61123666868079,
    "lat": 39.34575774717018
  },
  {
    "name": "Strasburg",
    "lng": -81.52984912271725,
    "lat": 40.60079579364507
  },
  {
    "name": "Strasburg",
    "lng": -78.35491132084474,
    "lat": 38.996070591151714
  },
  {
    "name": "Stratford",
    "lng": -102.0734658343126,
    "lat": 36.336638323542296
  },
  {
    "name": "Stratford",
    "lng": -98.30434002411369,
    "lat": 45.31744887131258
  },
  {
    "name": "Stratford",
    "lng": -96.960261691931,
    "lat": 34.79521357570483
  },
  {
    "name": "Stratford",
    "lng": -93.92743825493902,
    "lat": 42.26963332774024
  },
  {
    "name": "Stratford",
    "lng": -90.07220295860891,
    "lat": 44.80087219037892
  },
  {
    "name": "Stratford",
    "lng": -73.12995784804042,
    "lat": 41.204568493102244
  },
  {
    "name": "Strathcona",
    "lng": -96.16717718738305,
    "lat": 48.553726761873904
  },
  {
    "name": "Strathmoor Manor",
    "lng": -85.68379665382969,
    "lat": 38.21897857867883
  },
  {
    "name": "Strathmoor Village",
    "lng": -85.67770915546627,
    "lat": 38.2206546508805
  },
  {
    "name": "Stratton",
    "lng": -102.60441944067259,
    "lat": 39.30292346299484
  },
  {
    "name": "Stratton",
    "lng": -101.22783238269605,
    "lat": 40.15043213154348
  },
  {
    "name": "Stratton",
    "lng": -80.63142956947537,
    "lat": 40.52545371961891
  },
  {
    "name": "Straughn",
    "lng": -85.29083902637754,
    "lat": 39.808367443782615
  },
  {
    "name": "Strausstown",
    "lng": -76.18409214421274,
    "lat": 40.491664948606996
  },
  {
    "name": "Strawberry",
    "lng": -91.32114919057439,
    "lat": 35.96652345993588
  },
  {
    "name": "Strawberry Point",
    "lng": -91.53484406304257,
    "lat": 42.680465671419235
  },
  {
    "name": "Strawn",
    "lng": -98.4979924946769,
    "lat": 32.55071200296639
  },
  {
    "name": "Strawn",
    "lng": -88.39995114171418,
    "lat": 40.653721167854926
  },
  {
    "name": "Streamwood",
    "lng": -88.17787576729147,
    "lat": 42.02072197200644
  },
  {
    "name": "Streator",
    "lng": -88.82956198942315,
    "lat": 41.124219613214116
  },
  {
    "name": "Streator",
    "lng": -88.81465471079397,
    "lat": 41.13350207318862
  },
  {
    "name": "Streator",
    "lng": -88.80409838637149,
    "lat": 41.12009352824587
  },
  {
    "name": "Streeter",
    "lng": -99.3569946523719,
    "lat": 46.65757807298006
  },
  {
    "name": "Streetman",
    "lng": -96.33065866702853,
    "lat": 31.878710243031186
  },
  {
    "name": "Streetsboro",
    "lng": -81.3455813059129,
    "lat": 41.23960223151238
  },
  {
    "name": "Stringtown",
    "lng": -96.05394238319042,
    "lat": 34.46772112928753
  },
  {
    "name": "Stromsburg",
    "lng": -97.59110080874578,
    "lat": 41.11594230039979
  },
  {
    "name": "Strong",
    "lng": -92.35557067167733,
    "lat": 33.107956786347565
  },
  {
    "name": "Strong",
    "lng": -70.20466254609015,
    "lat": 44.79415606451878
  },
  {
    "name": "Strong City",
    "lng": -99.60029186850726,
    "lat": 35.66992952742221
  },
  {
    "name": "Strong City",
    "lng": -96.53684703446896,
    "lat": 38.39547582659292
  },
  {
    "name": "Stronghurst",
    "lng": -90.90931502961973,
    "lat": 40.746363015073655
  },
  {
    "name": "Strongsville",
    "lng": -81.83132587500549,
    "lat": 41.312805002688606
  },
  {
    "name": "Stroud",
    "lng": -96.67799585041946,
    "lat": 35.80573212869812
  },
  {
    "name": "Stroud",
    "lng": -96.64723672219601,
    "lat": 35.76800329938544
  },
  {
    "name": "Stroud",
    "lng": -96.63611326532939,
    "lat": 35.81857468333849
  },
  {
    "name": "Struble",
    "lng": -96.19430243973761,
    "lat": 42.89434449146707
  },
  {
    "name": "Strum",
    "lng": -91.38672875165068,
    "lat": 44.553064150022486
  },
  {
    "name": "Struthers",
    "lng": -80.59203284309824,
    "lat": 41.05090973247232
  },
  {
    "name": "Stryker",
    "lng": -84.41804853966619,
    "lat": 41.50245145953519
  },
  {
    "name": "Stuart",
    "lng": -99.14109914353314,
    "lat": 42.600486023872506
  },
  {
    "name": "Stuart",
    "lng": -96.09936453696348,
    "lat": 34.901074985247625
  },
  {
    "name": "Stuart",
    "lng": -94.31851227921972,
    "lat": 41.50089045320431
  },
  {
    "name": "Stuart",
    "lng": -80.26859772266901,
    "lat": 36.64036865279007
  },
  {
    "name": "Stuckey",
    "lng": -79.51312257908056,
    "lat": 33.732189755047955
  },
  {
    "name": "Sturbridge",
    "lng": -72.09040738736756,
    "lat": 42.107618050740065
  },
  {
    "name": "Sturgeon",
    "lng": -92.28139553305058,
    "lat": 39.23361457242008
  },
  {
    "name": "Sturgeon Lake",
    "lng": -92.84484415399454,
    "lat": 46.36980655667015
  },
  {
    "name": "Sturgeon Lake",
    "lng": -92.82423133797577,
    "lat": 46.38606392348672
  },
  {
    "name": "Sturgeon Lake",
    "lng": -92.79230182282285,
    "lat": 46.37612690439668
  },
  {
    "name": "Sturgis",
    "lng": -103.50739541371702,
    "lat": 44.41064299510418
  },
  {
    "name": "Sturgis",
    "lng": -89.04574924126219,
    "lat": 33.34515907223309
  },
  {
    "name": "Sturgis",
    "lng": -87.98738853800127,
    "lat": 37.54657449334171
  },
  {
    "name": "Sturgis",
    "lng": -85.41920220823646,
    "lat": 41.78467795923579
  },
  {
    "name": "Sturgis",
    "lng": -85.41886090821382,
    "lat": 41.801448001909165
  },
  {
    "name": "Sturgis",
    "lng": -85.41513554951115,
    "lat": 41.76530755628564
  },
  {
    "name": "Sturgis",
    "lng": -85.40278549449812,
    "lat": 41.798083424400474
  },
  {
    "name": "Sturtevant",
    "lng": -87.90151178008603,
    "lat": 42.70052646217635
  },
  {
    "name": "Stuttgart",
    "lng": -91.54870781437248,
    "lat": 34.495513355988585
  },
  {
    "name": "Stuttgart",
    "lng": -91.53742603596841,
    "lat": 34.47322093733546
  },
  {
    "name": "Suamico",
    "lng": -88.06726752795565,
    "lat": 44.635285618421136
  },
  {
    "name": "Subiaco",
    "lng": -93.6385809267543,
    "lat": 35.29826694263417
  },
  {
    "name": "Sublette",
    "lng": -100.84633078288431,
    "lat": 37.48060537136683
  },
  {
    "name": "Sublette",
    "lng": -89.23118201852456,
    "lat": 41.64370324798413
  },
  {
    "name": "Success",
    "lng": -90.72262560090819,
    "lat": 36.45452106008851
  },
  {
    "name": "Sudan",
    "lng": -102.52420332033648,
    "lat": 34.06642106587294
  },
  {
    "name": "Sudbury",
    "lng": -71.42337705599911,
    "lat": 42.384719486535595
  },
  {
    "name": "Sudbury",
    "lng": -71.38956871399417,
    "lat": 42.36643221853712
  },
  {
    "name": "Sudlersville",
    "lng": -75.85582027888478,
    "lat": 39.18384831245421
  },
  {
    "name": "Suffern",
    "lng": -74.14208187724218,
    "lat": 41.11380053061025
  },
  {
    "name": "Suffield",
    "lng": -72.67897278162539,
    "lat": 41.9945166893667
  },
  {
    "name": "Suffolk",
    "lng": -76.63403294127336,
    "lat": 36.702006561390895
  },
  {
    "name": "Sugar Bush Knolls",
    "lng": -81.34704483444085,
    "lat": 41.20371172225679
  },
  {
    "name": "Sugar City",
    "lng": -111.76734641232412,
    "lat": 43.86345598927737
  },
  {
    "name": "Sugar City",
    "lng": -111.76029572799119,
    "lat": 43.86107768569759
  },
  {
    "name": "Sugar City",
    "lng": -111.74971372576255,
    "lat": 43.877283579985104
  },
  {
    "name": "Sugar City",
    "lng": -103.66332321871425,
    "lat": 38.23278088539119
  },
  {
    "name": "Sugar Creek",
    "lng": -94.40147475625778,
    "lat": 39.142457304137814
  },
  {
    "name": "Sugar Grove",
    "lng": -88.44805858870012,
    "lat": 41.77578420934455
  },
  {
    "name": "Sugar Grove",
    "lng": -88.42826453793609,
    "lat": 41.7809728124931
  },
  {
    "name": "Sugar Grove",
    "lng": -88.42626763992293,
    "lat": 41.780353008619635
  },
  {
    "name": "Sugar Grove",
    "lng": -82.54557270908342,
    "lat": 39.62797121602802
  },
  {
    "name": "Sugar Hill",
    "lng": -84.0832096073599,
    "lat": 34.1286077504146
  },
  {
    "name": "Sugar Hill",
    "lng": -84.08077400434202,
    "lat": 34.1034351022379
  },
  {
    "name": "Sugar Hill",
    "lng": -84.07261469134285,
    "lat": 34.12630018399863
  },
  {
    "name": "Sugar Hill",
    "lng": -84.06867817373475,
    "lat": 34.11127350967029
  },
  {
    "name": "Sugar Hill",
    "lng": -84.06808344947179,
    "lat": 34.133291021616934
  },
  {
    "name": "Sugar Hill",
    "lng": -84.06658543892722,
    "lat": 34.09856528312855
  },
  {
    "name": "Sugar Hill",
    "lng": -84.06426148424153,
    "lat": 34.11499355726389
  },
  {
    "name": "Sugar Hill",
    "lng": -84.04398132719315,
    "lat": 34.1004441965865
  },
  {
    "name": "Sugar Hill",
    "lng": -84.03898211861328,
    "lat": 34.07859090024293
  },
  {
    "name": "Sugar Hill",
    "lng": -84.01697203464872,
    "lat": 34.09458121453147
  },
  {
    "name": "Sugar Land",
    "lng": -95.63569510461767,
    "lat": 29.593533960118137
  },
  {
    "name": "Sugar Mountain",
    "lng": -81.86500175430817,
    "lat": 36.12701900639482
  },
  {
    "name": "Sugar Mountain",
    "lng": -81.84581101724793,
    "lat": 36.136258590070675
  },
  {
    "name": "Sugarcreek",
    "lng": -81.6408968534961,
    "lat": 40.507619339937975
  },
  {
    "name": "Sugden",
    "lng": -97.97881217986728,
    "lat": 34.08248979640267
  },
  {
    "name": "Suisun City",
    "lng": -122.00960224643693,
    "lat": 38.24712446509
  },
  {
    "name": "Suisun City",
    "lng": -121.97222574455418,
    "lat": 38.2469987748666
  },
  {
    "name": "Sulligent",
    "lng": -88.12663151217163,
    "lat": 33.89119159172942
  },
  {
    "name": "Sullivan",
    "lng": -91.16371285716804,
    "lat": 38.212733889470215
  },
  {
    "name": "Sullivan",
    "lng": -88.60853190365123,
    "lat": 39.59538391576452
  },
  {
    "name": "Sullivan",
    "lng": -88.59037397432388,
    "lat": 43.01180226886696
  },
  {
    "name": "Sullivan",
    "lng": -87.4073225400227,
    "lat": 39.09728970369084
  },
  {
    "name": "Sullivan",
    "lng": -68.15724384796813,
    "lat": 44.53503185310559
  },
  {
    "name": "Sullivan City",
    "lng": -98.564441279376,
    "lat": 26.27521854937079
  },
  {
    "name": "Sullivan\"s Island",
    "lng": -79.83626443814563,
    "lat": 32.767923373464626
  },
  {
    "name": "Sully",
    "lng": -92.84677990637262,
    "lat": 41.57800778791479
  },
  {
    "name": "Sulphur",
    "lng": -96.99277350523153,
    "lat": 34.50001452375141
  },
  {
    "name": "Sulphur",
    "lng": -96.97790870538043,
    "lat": 34.48697683928376
  },
  {
    "name": "Sulphur",
    "lng": -96.962113419428,
    "lat": 34.494941367451624
  },
  {
    "name": "Sulphur",
    "lng": -96.95392555942541,
    "lat": 34.50067110397148
  },
  {
    "name": "Sulphur",
    "lng": -93.37091155762465,
    "lat": 30.201204701501805
  },
  {
    "name": "Sulphur",
    "lng": -93.36678431554301,
    "lat": 30.211913001401918
  },
  {
    "name": "Sulphur",
    "lng": -93.36441660012436,
    "lat": 30.213558228279993
  },
  {
    "name": "Sulphur",
    "lng": -93.35529667340452,
    "lat": 30.230214987808655
  },
  {
    "name": "Sulphur Rock",
    "lng": -91.50030511585204,
    "lat": 35.751819595087866
  },
  {
    "name": "Sulphur Springs",
    "lng": -95.61248752293447,
    "lat": 33.142082901270115
  },
  {
    "name": "Sulphur Springs",
    "lng": -94.45916236978002,
    "lat": 36.483697634850905
  },
  {
    "name": "Sulphur Springs",
    "lng": -85.44317515508861,
    "lat": 40.00490604562648
  },
  {
    "name": "Sultan",
    "lng": -121.8036886110412,
    "lat": 47.87100225411093
  },
  {
    "name": "Sumas",
    "lng": -122.26966847424262,
    "lat": 48.995524790706654
  },
  {
    "name": "Sumiton",
    "lng": -87.04841324007106,
    "lat": 33.75036558457831
  },
  {
    "name": "Summerfield",
    "lng": -96.34928155321515,
    "lat": 39.99691476100636
  },
  {
    "name": "Summerfield",
    "lng": -89.74687969234871,
    "lat": 38.596557489960745
  },
  {
    "name": "Summerfield",
    "lng": -81.33550529908241,
    "lat": 39.79690899092559
  },
  {
    "name": "Summerfield",
    "lng": -79.89999276132946,
    "lat": 36.197395978430315
  },
  {
    "name": "Summerfield",
    "lng": -79.89854253314006,
    "lat": 36.23935542076244
  },
  {
    "name": "Summerfield",
    "lng": -79.8981010474397,
    "lat": 36.23930266050611
  },
  {
    "name": "Summerset",
    "lng": -103.33971001994901,
    "lat": 44.191433739350984
  },
  {
    "name": "Summersville",
    "lng": -91.65769096814081,
    "lat": 37.17854751767204
  },
  {
    "name": "Summersville",
    "lng": -80.84154605625442,
    "lat": 38.28433646008442
  },
  {
    "name": "Summerton",
    "lng": -80.35248226850875,
    "lat": 33.60288189901491
  },
  {
    "name": "Summertown",
    "lng": -82.27638449571661,
    "lat": 32.745814975692674
  },
  {
    "name": "Summerville",
    "lng": -85.34907887261507,
    "lat": 34.478710721182786
  },
  {
    "name": "Summerville",
    "lng": -80.18079436899663,
    "lat": 33.00502243927084
  },
  {
    "name": "Summerville",
    "lng": -80.17249992714319,
    "lat": 33.000088779006056
  },
  {
    "name": "Summerville",
    "lng": -80.16191487431976,
    "lat": 32.998918001715964
  },
  {
    "name": "Summerville",
    "lng": -79.18807679982734,
    "lat": 41.11630161310818
  },
  {
    "name": "Summit",
    "lng": -97.03667696419743,
    "lat": 45.30377484592375
  },
  {
    "name": "Summit",
    "lng": -95.42159443338544,
    "lat": 35.66815959439901
  },
  {
    "name": "Summit",
    "lng": -92.68886454684754,
    "lat": 36.25234592513158
  },
  {
    "name": "Summit",
    "lng": -90.46696190271803,
    "lat": 31.280820227758102
  },
  {
    "name": "Summit",
    "lng": -87.81457130791078,
    "lat": 41.787695556604184
  },
  {
    "name": "Summit",
    "lng": -81.42252605285121,
    "lat": 33.92448181847722
  },
  {
    "name": "Summit",
    "lng": -74.36489925348042,
    "lat": 40.71540962229328
  },
  {
    "name": "Summit Hill",
    "lng": -75.84479427039663,
    "lat": 40.825188442608756
  },
  {
    "name": "Summitville",
    "lng": -85.64290988449906,
    "lat": 40.33780498898404
  },
  {
    "name": "Summitville",
    "lng": -80.88752975172589,
    "lat": 40.675896953158784
  },
  {
    "name": "Sumner",
    "lng": -122.26520602298103,
    "lat": 47.20338899202688
  },
  {
    "name": "Sumner",
    "lng": -122.25609214683216,
    "lat": 47.199448256097845
  },
  {
    "name": "Sumner",
    "lng": -122.23366715621725,
    "lat": 47.2192778126282
  },
  {
    "name": "Sumner",
    "lng": -122.19789657201521,
    "lat": 47.19086319264741
  },
  {
    "name": "Sumner",
    "lng": -99.50811368326896,
    "lat": 40.94902745876592
  },
  {
    "name": "Sumner",
    "lng": -93.24340620768926,
    "lat": 39.65606915311676
  },
  {
    "name": "Sumner",
    "lng": -92.09692032464089,
    "lat": 42.850007210288055
  },
  {
    "name": "Sumner",
    "lng": -90.36927445224016,
    "lat": 33.96931769450626
  },
  {
    "name": "Sumner",
    "lng": -87.8725241928037,
    "lat": 38.71965935330641
  },
  {
    "name": "Sumner",
    "lng": -83.73925937544578,
    "lat": 31.51396173205509
  },
  {
    "name": "Sumner",
    "lng": -70.44694196018091,
    "lat": 44.37409562341301
  },
  {
    "name": "Sumrall",
    "lng": -89.54285850040738,
    "lat": 31.41724382027911
  },
  {
    "name": "Sumter",
    "lng": -80.39389299568425,
    "lat": 33.93989070142921
  },
  {
    "name": "Sumter",
    "lng": -80.3620461986724,
    "lat": 33.86354537258424
  },
  {
    "name": "Sumter",
    "lng": -80.3537963741912,
    "lat": 33.8751888404804
  },
  {
    "name": "Sumter",
    "lng": -80.35263088059384,
    "lat": 33.97502374543974
  },
  {
    "name": "Sumêg Village",
    "lng": -124.15208290606189,
    "lat": 41.13599490522187
  },
  {
    "name": "Sun",
    "lng": -89.90568295091958,
    "lat": 30.649947768685593
  },
  {
    "name": "Sun City",
    "lng": -98.91657207288895,
    "lat": 37.378524142703
  },
  {
    "name": "Sun River Terrace",
    "lng": -87.73368574609346,
    "lat": 41.12535298524534
  },
  {
    "name": "Sun Valley",
    "lng": -114.33370104515743,
    "lat": 43.68366010742079
  },
  {
    "name": "Sun Valley",
    "lng": -95.42875387211654,
    "lat": 33.67112613599476
  },
  {
    "name": "Sun Valley",
    "lng": -75.46878771539392,
    "lat": 40.980043671773885
  },
  {
    "name": "Sunbright",
    "lng": -84.68068727568688,
    "lat": 36.243279971162636
  },
  {
    "name": "Sunburg",
    "lng": -95.24017376596332,
    "lat": 45.347455500149835
  },
  {
    "name": "Sunburst",
    "lng": -111.9065247648247,
    "lat": 48.87831543964185
  },
  {
    "name": "Sunbury",
    "lng": -82.86598502653813,
    "lat": 40.247198170342465
  },
  {
    "name": "Sunbury",
    "lng": -76.78732787602414,
    "lat": 40.861998786841326
  },
  {
    "name": "Sundance",
    "lng": -104.36289968830525,
    "lat": 44.40474018671715
  },
  {
    "name": "Sunderland",
    "lng": -72.55461460430134,
    "lat": 42.46512263798474
  },
  {
    "name": "Sundown",
    "lng": -102.49080773525539,
    "lat": 33.45754737707095
  },
  {
    "name": "Sunfield",
    "lng": -84.99438376428819,
    "lat": 42.761129492103365
  },
  {
    "name": "Sunfish Lake",
    "lng": -93.09420117517435,
    "lat": 44.8722316464326
  },
  {
    "name": "Sunflower",
    "lng": -90.53730691268316,
    "lat": 33.544284820344394
  },
  {
    "name": "Sunland Park",
    "lng": -106.58419025230846,
    "lat": 31.809734453114743
  },
  {
    "name": "Sunman",
    "lng": -85.09167844277565,
    "lat": 39.238831396426654
  },
  {
    "name": "Sunnyside",
    "lng": -120.00544134433626,
    "lat": 46.31456533403314
  },
  {
    "name": "Sunnyvale",
    "lng": -96.55763807949559,
    "lat": 32.79737387580705
  },
  {
    "name": "Sunray",
    "lng": -101.82450148816541,
    "lat": 36.01820799727821
  },
  {
    "name": "Sunrise Beach",
    "lng": -92.7790405413891,
    "lat": 38.169395795432095
  },
  {
    "name": "Sunrise Beach",
    "lng": -92.76556173039322,
    "lat": 38.2020846498623
  },
  {
    "name": "Sunrise Beach Village",
    "lng": -98.4187357297841,
    "lat": 30.58615829868229
  },
  {
    "name": "Sunset",
    "lng": -112.0284298975214,
    "lat": 41.13915646667708
  },
  {
    "name": "Sunset",
    "lng": -92.06404401430605,
    "lat": 30.4083507790788
  },
  {
    "name": "Sunset",
    "lng": -90.20558596886946,
    "lat": 35.22392689754052
  },
  {
    "name": "Sunset Beach",
    "lng": -118.07309510095975,
    "lat": 33.71698269840441
  },
  {
    "name": "Sunset Beach",
    "lng": -78.5113846865887,
    "lat": 33.89228810075048
  },
  {
    "name": "Sunset Hills",
    "lng": -90.40870013718443,
    "lat": 38.53081845353459
  },
  {
    "name": "Sunset Valley",
    "lng": -97.81573257288294,
    "lat": 30.225815297484157
  },
  {
    "name": "Supai",
    "lng": -112.69271417779113,
    "lat": 36.2301476509955
  },
  {
    "name": "Superior",
    "lng": -114.89238866486649,
    "lat": 47.19354223965172
  },
  {
    "name": "Superior",
    "lng": -111.11030127992969,
    "lat": 33.285147899140796
  },
  {
    "name": "Superior",
    "lng": -108.96423575448938,
    "lat": 41.75950935227979
  },
  {
    "name": "Superior",
    "lng": -98.06626756345202,
    "lat": 40.02297612529757
  },
  {
    "name": "Superior",
    "lng": -94.94677528624287,
    "lat": 43.433734119754895
  },
  {
    "name": "Surf City",
    "lng": -77.61566903266949,
    "lat": 34.451105247815455
  },
  {
    "name": "Surf City",
    "lng": -77.61548418392772,
    "lat": 34.44974178763567
  },
  {
    "name": "Surf City",
    "lng": -77.60493111492998,
    "lat": 34.43583253250169
  },
  {
    "name": "Surf City",
    "lng": -77.599626717074,
    "lat": 34.44662139490463
  },
  {
    "name": "Surf City",
    "lng": -77.59946182972055,
    "lat": 34.42632931991146
  },
  {
    "name": "Surf City",
    "lng": -77.5951192812285,
    "lat": 34.443358312973466
  },
  {
    "name": "Surf City",
    "lng": -77.59499180801978,
    "lat": 34.454836795185685
  },
  {
    "name": "Surf City",
    "lng": -77.58400732719724,
    "lat": 34.44530723613826
  },
  {
    "name": "Surf City",
    "lng": -77.58161668330615,
    "lat": 34.450168317361495
  },
  {
    "name": "Surf City",
    "lng": -77.58069440971082,
    "lat": 34.44924317070757
  },
  {
    "name": "Surf City",
    "lng": -77.57536305319911,
    "lat": 34.4727087905991
  },
  {
    "name": "Surf City",
    "lng": -77.57408663354074,
    "lat": 34.46264924530344
  },
  {
    "name": "Surf City",
    "lng": -77.57008255237596,
    "lat": 34.46749624578526
  },
  {
    "name": "Surf City",
    "lng": -77.56820846839797,
    "lat": 34.44886437092525
  },
  {
    "name": "Surf City",
    "lng": -77.56489435180055,
    "lat": 34.45619431629462
  },
  {
    "name": "Surf City",
    "lng": -77.56477073479846,
    "lat": 34.46043518165494
  },
  {
    "name": "Surf City",
    "lng": -77.56300970124015,
    "lat": 34.45110507771391
  },
  {
    "name": "Surf City",
    "lng": -77.5611800605317,
    "lat": 34.462007522491795
  },
  {
    "name": "Surf City",
    "lng": -77.56107712773407,
    "lat": 34.46297779574195
  },
  {
    "name": "Surf City",
    "lng": -77.5579943711809,
    "lat": 34.45706533014558
  },
  {
    "name": "Surf City",
    "lng": -77.55712122887041,
    "lat": 34.42845985717216
  },
  {
    "name": "Surf City",
    "lng": -77.54695943376828,
    "lat": 34.45550863026814
  },
  {
    "name": "Surfside",
    "lng": -80.12329129853516,
    "lat": 25.87885275913889
  },
  {
    "name": "Surfside Beach",
    "lng": -95.28330736309492,
    "lat": 28.955901921981766
  },
  {
    "name": "Surfside Beach",
    "lng": -78.97111299358409,
    "lat": 33.60518992465141
  },
  {
    "name": "Surgoinsville",
    "lng": -82.86070791491866,
    "lat": 36.47345500128307
  },
  {
    "name": "Suring",
    "lng": -88.38994339881832,
    "lat": 45.0006008900926
  },
  {
    "name": "Suring",
    "lng": -88.36844237986755,
    "lat": 45.0007272303714
  },
  {
    "name": "Surprise",
    "lng": -97.30859430361699,
    "lat": 41.10465401413876
  },
  {
    "name": "Surrency",
    "lng": -82.19871804086614,
    "lat": 31.72157222723791
  },
  {
    "name": "Surrey",
    "lng": -101.13362375050902,
    "lat": 48.236472034853634
  },
  {
    "name": "Surry",
    "lng": -76.83327497286436,
    "lat": 37.137071042066985
  },
  {
    "name": "Surry",
    "lng": -68.51328817625446,
    "lat": 44.478537427923605
  },
  {
    "name": "Susan Moore",
    "lng": -86.4548699602982,
    "lat": 34.048233767493265
  },
  {
    "name": "Susan Moore",
    "lng": -86.42349312697758,
    "lat": 34.07973875042656
  },
  {
    "name": "Susank",
    "lng": -98.77452087448111,
    "lat": 38.64054814489327
  },
  {
    "name": "Susanville",
    "lng": -120.65657285801291,
    "lat": 40.36657227042544
  },
  {
    "name": "Susanville",
    "lng": -120.64034355022329,
    "lat": 40.42689641979366
  },
  {
    "name": "Susanville",
    "lng": -120.50924561598438,
    "lat": 40.40462507567995
  },
  {
    "name": "Susanville",
    "lng": -120.49770273239936,
    "lat": 40.39742530222231
  },
  {
    "name": "Susquehanna Depot",
    "lng": -75.60389362035511,
    "lat": 41.943390512026824
  },
  {
    "name": "Susquehanna Trails",
    "lng": -76.36838972057396,
    "lat": 39.75831105974444
  },
  {
    "name": "Sussex",
    "lng": -88.22582238357441,
    "lat": 43.16107444899831
  },
  {
    "name": "Sussex",
    "lng": -88.22205835027552,
    "lat": 43.13435497405142
  },
  {
    "name": "Sutherland",
    "lng": -101.12653736473636,
    "lat": 41.15894733178526
  },
  {
    "name": "Sutherland",
    "lng": -101.09008261052055,
    "lat": 41.16094967591034
  },
  {
    "name": "Sutherland",
    "lng": -95.49561402250717,
    "lat": 42.9725704278844
  },
  {
    "name": "Sutter Creek",
    "lng": -120.81154385312381,
    "lat": 38.3827085720107
  },
  {
    "name": "Sutton",
    "lng": -97.85832892188634,
    "lat": 40.607115956701676
  },
  {
    "name": "Sutton",
    "lng": -80.71149999926752,
    "lat": 38.666301776902074
  },
  {
    "name": "Sutton",
    "lng": -71.92981867189972,
    "lat": 43.340387996303356
  },
  {
    "name": "Sutton",
    "lng": -71.75028632906042,
    "lat": 42.13372717502462
  },
  {
    "name": "Suttons Bay",
    "lng": -85.65076682646519,
    "lat": 44.979572594337625
  },
  {
    "name": "Suwanee",
    "lng": -84.06870805139508,
    "lat": 34.05059885636906
  },
  {
    "name": "Swainsboro",
    "lng": -82.33447184907956,
    "lat": 32.58654921764992
  },
  {
    "name": "Swaledale",
    "lng": -93.3154730713347,
    "lat": 42.97645256168107
  },
  {
    "name": "Swan",
    "lng": -93.30951913520165,
    "lat": 41.46660045062163
  },
  {
    "name": "Swan",
    "lng": -93.3075185800775,
    "lat": 41.46337588988596
  },
  {
    "name": "Swan Valley",
    "lng": -111.32865526139848,
    "lat": 43.4444576956294
  },
  {
    "name": "Swan's Island",
    "lng": -68.45017646147606,
    "lat": 44.125527889981846
  },
  {
    "name": "Swansboro",
    "lng": -77.17714829957816,
    "lat": 34.67888802142932
  },
  {
    "name": "Swansboro",
    "lng": -77.14367512447096,
    "lat": 34.683838207402786
  },
  {
    "name": "Swansboro",
    "lng": -77.13992742775578,
    "lat": 34.6959371708447
  },
  {
    "name": "Swansboro",
    "lng": -77.12201942208165,
    "lat": 34.705712056459255
  },
  {
    "name": "Swansboro",
    "lng": -77.12004464220416,
    "lat": 34.69927249397112
  },
  {
    "name": "Swansboro",
    "lng": -77.11935151551333,
    "lat": 34.69990833233519
  },
  {
    "name": "Swansboro",
    "lng": -77.11871490981717,
    "lat": 34.70073877064295
  },
  {
    "name": "Swansboro",
    "lng": -77.11764586264016,
    "lat": 34.70956476674339
  },
  {
    "name": "Swansboro",
    "lng": -77.11646764002604,
    "lat": 34.70489848750817
  },
  {
    "name": "Swansboro",
    "lng": -77.11490369309247,
    "lat": 34.70438871973245
  },
  {
    "name": "Swansea",
    "lng": -89.98592923252598,
    "lat": 38.55026752796405
  },
  {
    "name": "Swansea",
    "lng": -81.10530277358389,
    "lat": 33.741027819857884
  },
  {
    "name": "Swansea",
    "lng": -81.10346901298806,
    "lat": 33.71335905185389
  },
  {
    "name": "Swansea",
    "lng": -81.09894311169101,
    "lat": 33.722045473700675
  },
  {
    "name": "Swansea",
    "lng": -81.09810430656178,
    "lat": 33.74458765354947
  },
  {
    "name": "Swanton",
    "lng": -97.07982145499142,
    "lat": 40.379602672816496
  },
  {
    "name": "Swanton",
    "lng": -83.8911711476541,
    "lat": 41.583699929670225
  },
  {
    "name": "Swanton Village",
    "lng": -73.12001231980416,
    "lat": 44.92134572267161
  },
  {
    "name": "Swanville",
    "lng": -94.64462183430784,
    "lat": 45.91212943121576
  },
  {
    "name": "Swanville",
    "lng": -94.63861709678783,
    "lat": 45.916112441863625
  },
  {
    "name": "Swanville",
    "lng": -69.01182137667244,
    "lat": 44.52122049306694
  },
  {
    "name": "Swartz Creek",
    "lng": -83.8257963574667,
    "lat": 42.962654889838156
  },
  {
    "name": "Swayzee",
    "lng": -85.82451808476984,
    "lat": 40.506607844867375
  },
  {
    "name": "Swea City",
    "lng": -94.31091978626728,
    "lat": 43.38330009806092
  },
  {
    "name": "Sweden",
    "lng": -70.81357492671738,
    "lat": 44.12613909740007
  },
  {
    "name": "Sweeny",
    "lng": -95.71648127778312,
    "lat": 29.040764747974016
  },
  {
    "name": "Sweeny",
    "lng": -95.69845801416719,
    "lat": 29.046500582352923
  },
  {
    "name": "Sweet Springs",
    "lng": -93.41518141433336,
    "lat": 38.96490816017585
  },
  {
    "name": "Sweet Water",
    "lng": -87.86108330436245,
    "lat": 32.10499865923496
  },
  {
    "name": "Sweetser",
    "lng": -85.768958916664,
    "lat": 40.5686357936463
  },
  {
    "name": "Sweetwater",
    "lng": -100.40854809972049,
    "lat": 32.46987288867296
  },
  {
    "name": "Sweetwater",
    "lng": -99.91154261672519,
    "lat": 35.42221885409338
  },
  {
    "name": "Sweetwater",
    "lng": -84.47119067481881,
    "lat": 35.60199310097158
  },
  {
    "name": "Swepsonville",
    "lng": -79.35414990034734,
    "lat": 36.02914346598618
  },
  {
    "name": "Swifton",
    "lng": -91.12945679260679,
    "lat": 35.82432197982894
  },
  {
    "name": "Swink",
    "lng": -103.6291748258186,
    "lat": 38.013761344817304
  },
  {
    "name": "Swink",
    "lng": -103.6229199463584,
    "lat": 38.01565516089733
  },
  {
    "name": "Swisher",
    "lng": -91.69380732393599,
    "lat": 41.84409799245781
  },
  {
    "name": "Switz City",
    "lng": -87.0533976971637,
    "lat": 39.034925918835945
  },
  {
    "name": "Sycamore",
    "lng": -88.68206783945057,
    "lat": 41.995694134885895
  },
  {
    "name": "Sycamore",
    "lng": -85.5610146263965,
    "lat": 38.24686178503835
  },
  {
    "name": "Sycamore",
    "lng": -83.63550230741593,
    "lat": 31.670730670017537
  },
  {
    "name": "Sycamore",
    "lng": -83.62149642853896,
    "lat": 31.680985828834203
  },
  {
    "name": "Sycamore",
    "lng": -83.6199199113022,
    "lat": 31.68288596083914
  },
  {
    "name": "Sycamore",
    "lng": -83.17096980463263,
    "lat": 40.95167307796095
  },
  {
    "name": "Sycamore",
    "lng": -81.22229301524679,
    "lat": 33.03621655738246
  },
  {
    "name": "Sycamore Hills",
    "lng": -90.34895532010633,
    "lat": 38.70131903618045
  },
  {
    "name": "Sykeston",
    "lng": -99.39930138337088,
    "lat": 47.466214809336385
  },
  {
    "name": "Sykesville",
    "lng": -76.97160307046337,
    "lat": 39.37149647602792
  },
  {
    "name": "Sylacauga",
    "lng": -86.35230758142315,
    "lat": 33.17894435301146
  },
  {
    "name": "Sylacauga",
    "lng": -86.33439062287064,
    "lat": 33.22255299768505
  },
  {
    "name": "Sylacauga",
    "lng": -86.33245038936782,
    "lat": 33.16331315483846
  },
  {
    "name": "Sylacauga",
    "lng": -86.32917003615798,
    "lat": 33.22205292631821
  },
  {
    "name": "Sylacauga",
    "lng": -86.32860559974523,
    "lat": 33.21990035664919
  },
  {
    "name": "Sylacauga",
    "lng": -86.32164549354468,
    "lat": 33.215975579762436
  },
  {
    "name": "Sylacauga",
    "lng": -86.32160587470467,
    "lat": 33.222364388924426
  },
  {
    "name": "Sylacauga",
    "lng": -86.3152368220083,
    "lat": 33.21020700455904
  },
  {
    "name": "Sylacauga",
    "lng": -86.31523135853733,
    "lat": 33.208188945685954
  },
  {
    "name": "Sylacauga",
    "lng": -86.30753539755904,
    "lat": 33.21550686622486
  },
  {
    "name": "Sylacauga",
    "lng": -86.3037832039662,
    "lat": 33.221309390935
  },
  {
    "name": "Sylacauga",
    "lng": -86.30164247407012,
    "lat": 33.21046062781641
  },
  {
    "name": "Sylacauga",
    "lng": -86.2959558224496,
    "lat": 33.20875152574798
  },
  {
    "name": "Sylacauga",
    "lng": -86.29128916881126,
    "lat": 33.19777497568183
  },
  {
    "name": "Sylacauga",
    "lng": -86.29124270194285,
    "lat": 33.193657363424535
  },
  {
    "name": "Sylacauga",
    "lng": -86.2893605432298,
    "lat": 33.19530605503456
  },
  {
    "name": "Sylacauga",
    "lng": -86.28788621656399,
    "lat": 33.19405616831775
  },
  {
    "name": "Sylacauga",
    "lng": -86.28671055008621,
    "lat": 33.18870065586933
  },
  {
    "name": "Sylacauga",
    "lng": -86.28567248698705,
    "lat": 33.193367793935955
  },
  {
    "name": "Sylacauga",
    "lng": -86.28550027208973,
    "lat": 33.18666861127909
  },
  {
    "name": "Sylacauga",
    "lng": -86.2854080332658,
    "lat": 33.11805729066674
  },
  {
    "name": "Sylacauga",
    "lng": -86.28467183862975,
    "lat": 33.15557325301214
  },
  {
    "name": "Sylacauga",
    "lng": -86.28445733144638,
    "lat": 33.18613848313505
  },
  {
    "name": "Sylacauga",
    "lng": -86.2842795005629,
    "lat": 33.192946219432486
  },
  {
    "name": "Sylacauga",
    "lng": -86.28403806203879,
    "lat": 33.190052234310635
  },
  {
    "name": "Sylacauga",
    "lng": -86.28258342060502,
    "lat": 33.189656140484566
  },
  {
    "name": "Sylacauga",
    "lng": -86.2820021207191,
    "lat": 33.15508120594591
  },
  {
    "name": "Sylacauga",
    "lng": -86.28167002644106,
    "lat": 33.18975283674582
  },
  {
    "name": "Sylacauga",
    "lng": -86.28165287405021,
    "lat": 33.18341992940605
  },
  {
    "name": "Sylacauga",
    "lng": -86.2813848243185,
    "lat": 33.19239049788859
  },
  {
    "name": "Sylacauga",
    "lng": -86.27930396086751,
    "lat": 33.19250499082821
  },
  {
    "name": "Sylacauga",
    "lng": -86.27816124760965,
    "lat": 33.21169576244309
  },
  {
    "name": "Sylacauga",
    "lng": -86.27643608811611,
    "lat": 33.208905334431144
  },
  {
    "name": "Sylacauga",
    "lng": -86.27638675031879,
    "lat": 33.197403827247236
  },
  {
    "name": "Sylacauga",
    "lng": -86.27598768311607,
    "lat": 33.11848879427115
  },
  {
    "name": "Sylacauga",
    "lng": -86.27508899613738,
    "lat": 33.18696157021269
  },
  {
    "name": "Sylacauga",
    "lng": -86.2749016233024,
    "lat": 33.209310854820586
  },
  {
    "name": "Sylacauga",
    "lng": -86.27418496928296,
    "lat": 33.186505400509496
  },
  {
    "name": "Sylacauga",
    "lng": -86.273948097565,
    "lat": 33.1205456563822
  },
  {
    "name": "Sylacauga",
    "lng": -86.27323100171823,
    "lat": 33.18492413298625
  },
  {
    "name": "Sylacauga",
    "lng": -86.27302736642733,
    "lat": 33.118731195215894
  },
  {
    "name": "Sylacauga",
    "lng": -86.27301417538573,
    "lat": 33.117015205328286
  },
  {
    "name": "Sylacauga",
    "lng": -86.27102537529905,
    "lat": 33.19095422882674
  },
  {
    "name": "Sylacauga",
    "lng": -86.26764490741967,
    "lat": 33.214305311947186
  },
  {
    "name": "Sylacauga",
    "lng": -86.26759024628178,
    "lat": 33.212933552127836
  },
  {
    "name": "Sylacauga",
    "lng": -86.26758453186198,
    "lat": 33.18366553931437
  },
  {
    "name": "Sylacauga",
    "lng": -86.26675264964689,
    "lat": 33.214618261485526
  },
  {
    "name": "Sylacauga",
    "lng": -86.2666137654307,
    "lat": 33.21334231253554
  },
  {
    "name": "Sylacauga",
    "lng": -86.26343286719595,
    "lat": 33.171791510529715
  },
  {
    "name": "Sylacauga",
    "lng": -86.25990423257886,
    "lat": 33.21117364735528
  },
  {
    "name": "Sylacauga",
    "lng": -86.25990423257883,
    "lat": 33.211173647355274
  },
  {
    "name": "Sylacauga",
    "lng": -86.25800643296768,
    "lat": 33.124981263030186
  },
  {
    "name": "Sylacauga",
    "lng": -86.25655593773813,
    "lat": 33.1802165224438
  },
  {
    "name": "Sylacauga",
    "lng": -86.25600425238957,
    "lat": 33.179777199665345
  },
  {
    "name": "Sylacauga",
    "lng": -86.25536822194204,
    "lat": 33.1148543397111
  },
  {
    "name": "Sylacauga",
    "lng": -86.25534383095476,
    "lat": 33.10760928675486
  },
  {
    "name": "Sylacauga",
    "lng": -86.25371117603889,
    "lat": 33.1086930724439
  },
  {
    "name": "Sylacauga",
    "lng": -86.2531481503543,
    "lat": 33.10932675629737
  },
  {
    "name": "Sylacauga",
    "lng": -86.25204881447485,
    "lat": 33.118509651749406
  },
  {
    "name": "Sylacauga",
    "lng": -86.24997899214506,
    "lat": 33.22647998424116
  },
  {
    "name": "Sylacauga",
    "lng": -86.24969354510803,
    "lat": 33.10685339983303
  },
  {
    "name": "Sylacauga",
    "lng": -86.24893737440402,
    "lat": 33.10993599943811
  },
  {
    "name": "Sylacauga",
    "lng": -86.2466089800682,
    "lat": 33.28173658464542
  },
  {
    "name": "Sylacauga",
    "lng": -86.24136301989577,
    "lat": 33.25422945028939
  },
  {
    "name": "Sylacauga",
    "lng": -86.23395153100573,
    "lat": 33.26160786650994
  },
  {
    "name": "Sylacauga",
    "lng": -86.2331448774637,
    "lat": 33.23422733346772
  },
  {
    "name": "Sylacauga",
    "lng": -86.23285602693323,
    "lat": 33.2601432475143
  },
  {
    "name": "Sylacauga",
    "lng": -86.23224927605874,
    "lat": 33.188646830837754
  },
  {
    "name": "Sylacauga",
    "lng": -86.23140839912385,
    "lat": 33.24521196401166
  },
  {
    "name": "Sylacauga",
    "lng": -86.23139595536473,
    "lat": 33.26026057143976
  },
  {
    "name": "Sylacauga",
    "lng": -86.22741121186029,
    "lat": 33.24718067468766
  },
  {
    "name": "Sylacauga",
    "lng": -86.22731574725769,
    "lat": 33.19210981898641
  },
  {
    "name": "Sylacauga",
    "lng": -86.22671864239442,
    "lat": 33.18871626476174
  },
  {
    "name": "Sylacauga",
    "lng": -86.22634796898265,
    "lat": 33.24009449023529
  },
  {
    "name": "Sylacauga",
    "lng": -86.22531722848025,
    "lat": 33.233638014696325
  },
  {
    "name": "Sylacauga",
    "lng": -86.2234805684316,
    "lat": 33.20400639584954
  },
  {
    "name": "Sylacauga",
    "lng": -86.22152434836893,
    "lat": 33.19644986295011
  },
  {
    "name": "Sylacauga",
    "lng": -86.22084361609772,
    "lat": 33.20118417401259
  },
  {
    "name": "Sylacauga",
    "lng": -86.219411648121,
    "lat": 33.19768994317914
  },
  {
    "name": "Sylacauga",
    "lng": -86.21938846300358,
    "lat": 33.209370457784125
  },
  {
    "name": "Sylacauga",
    "lng": -86.21880266794749,
    "lat": 33.20036873672872
  },
  {
    "name": "Sylacauga",
    "lng": -86.2179452701688,
    "lat": 33.19833507060551
  },
  {
    "name": "Sylacauga",
    "lng": -86.21783362873718,
    "lat": 33.20112668287062
  },
  {
    "name": "Sylacauga",
    "lng": -86.21778194628367,
    "lat": 33.20329969597978
  },
  {
    "name": "Sylacauga",
    "lng": -86.21770386659328,
    "lat": 33.205320841511515
  },
  {
    "name": "Sylacauga",
    "lng": -86.21731483527073,
    "lat": 33.202380818432026
  },
  {
    "name": "Sylacauga",
    "lng": -86.21723460376242,
    "lat": 33.25747164420776
  },
  {
    "name": "Sylacauga",
    "lng": -86.2168287847923,
    "lat": 33.260583580493886
  },
  {
    "name": "Sylacauga",
    "lng": -86.21657602799212,
    "lat": 33.16596670949922
  },
  {
    "name": "Sylacauga",
    "lng": -86.21636354171363,
    "lat": 33.1993937131448
  },
  {
    "name": "Sylacauga",
    "lng": -86.21588068546157,
    "lat": 33.207160089418686
  },
  {
    "name": "Sylacauga",
    "lng": -86.21574505756185,
    "lat": 33.25154096054971
  },
  {
    "name": "Sylacauga",
    "lng": -86.21531305854162,
    "lat": 33.20113054866655
  },
  {
    "name": "Sylacauga",
    "lng": -86.21440091616891,
    "lat": 33.27123876257217
  },
  {
    "name": "Sylacauga",
    "lng": -86.21431465372682,
    "lat": 33.27308239021056
  },
  {
    "name": "Sylacauga",
    "lng": -86.21216473842085,
    "lat": 33.20369191897395
  },
  {
    "name": "Sylacauga",
    "lng": -86.21140131053998,
    "lat": 33.20288179496538
  },
  {
    "name": "Sylacauga",
    "lng": -86.21048579993389,
    "lat": 33.26522019542412
  },
  {
    "name": "Sylacauga",
    "lng": -86.21040021041486,
    "lat": 33.25337164085349
  },
  {
    "name": "Sylacauga",
    "lng": -86.2102093903955,
    "lat": 33.11742330149029
  },
  {
    "name": "Sylacauga",
    "lng": -86.20994781135057,
    "lat": 33.25205035287843
  },
  {
    "name": "Sylacauga",
    "lng": -86.20980025873146,
    "lat": 33.25436606883694
  },
  {
    "name": "Sylacauga",
    "lng": -86.20909488232572,
    "lat": 33.25310636290279
  },
  {
    "name": "Sylacauga",
    "lng": -86.20858655538318,
    "lat": 33.115526401625
  },
  {
    "name": "Sylacauga",
    "lng": -86.20853065577705,
    "lat": 33.27588754331895
  },
  {
    "name": "Sylacauga",
    "lng": -86.20789883704984,
    "lat": 33.11458011584689
  },
  {
    "name": "Sylacauga",
    "lng": -86.20706217483996,
    "lat": 33.11826678456325
  },
  {
    "name": "Sylacauga",
    "lng": -86.20664984984366,
    "lat": 33.2541114060286
  },
  {
    "name": "Sylacauga",
    "lng": -86.20622210093431,
    "lat": 33.11751172672314
  },
  {
    "name": "Sylacauga",
    "lng": -86.20621711751423,
    "lat": 33.21196412497295
  },
  {
    "name": "Sylacauga",
    "lng": -86.20608902490018,
    "lat": 33.11287575518899
  },
  {
    "name": "Sylacauga",
    "lng": -86.20607553206409,
    "lat": 33.11995369662987
  },
  {
    "name": "Sylacauga",
    "lng": -86.20585994306107,
    "lat": 33.25157372129615
  },
  {
    "name": "Sylacauga",
    "lng": -86.20559414284813,
    "lat": 33.25783399540306
  },
  {
    "name": "Sylacauga",
    "lng": -86.20552879193701,
    "lat": 33.11533295777104
  },
  {
    "name": "Sylacauga",
    "lng": -86.20539075688684,
    "lat": 33.118417338875346
  },
  {
    "name": "Sylacauga",
    "lng": -86.20517814838084,
    "lat": 33.1169822054891
  },
  {
    "name": "Sylacauga",
    "lng": -86.20442682762557,
    "lat": 33.25825467260148
  },
  {
    "name": "Sylacauga",
    "lng": -86.20425520784197,
    "lat": 33.11350142953646
  },
  {
    "name": "Sylacauga",
    "lng": -86.2042492121615,
    "lat": 33.11970030066909
  },
  {
    "name": "Sylacauga",
    "lng": -86.20370286982168,
    "lat": 33.11661991685441
  },
  {
    "name": "Sylacauga",
    "lng": -86.20346664850123,
    "lat": 33.21589761990325
  },
  {
    "name": "Sylacauga",
    "lng": -86.20332099515744,
    "lat": 33.25930474226802
  },
  {
    "name": "Sylacauga",
    "lng": -86.20298584121115,
    "lat": 33.25537979097135
  },
  {
    "name": "Sylacauga",
    "lng": -86.20277123146896,
    "lat": 33.11976176157781
  },
  {
    "name": "Sylacauga",
    "lng": -86.2025645489963,
    "lat": 33.247643236866
  },
  {
    "name": "Sylacauga",
    "lng": -86.20203710976848,
    "lat": 33.25568058747471
  },
  {
    "name": "Sylacauga",
    "lng": -86.20096933955614,
    "lat": 33.247715506209495
  },
  {
    "name": "Sylacauga",
    "lng": -86.2007200012054,
    "lat": 33.247230626366644
  },
  {
    "name": "Sylacauga",
    "lng": -86.19950836825423,
    "lat": 33.10917031901578
  },
  {
    "name": "Sylacauga",
    "lng": -86.1976106140928,
    "lat": 33.250577281824015
  },
  {
    "name": "Sylacauga",
    "lng": -86.19700891907995,
    "lat": 33.22082993516185
  },
  {
    "name": "Sylacauga",
    "lng": -86.19664117462496,
    "lat": 33.22594677407306
  },
  {
    "name": "Sylacauga",
    "lng": -86.19603321180409,
    "lat": 33.216907523824
  },
  {
    "name": "Sylacauga",
    "lng": -86.19312025106825,
    "lat": 33.203040731029084
  },
  {
    "name": "Sylacauga City Limits",
    "lng": -86.26118045574896,
    "lat": 33.18143749429903
  },
  {
    "name": "Sylacauga City Limits",
    "lng": -86.26076684780236,
    "lat": 33.179400812278374
  },
  {
    "name": "Sylva",
    "lng": -83.24360323653865,
    "lat": 35.37137607774191
  },
  {
    "name": "Sylva",
    "lng": -83.2414216963447,
    "lat": 35.36546490446926
  },
  {
    "name": "Sylva",
    "lng": -83.21824788109,
    "lat": 35.37538700478269
  },
  {
    "name": "Sylvan Beach",
    "lng": -75.72327534732665,
    "lat": 43.20611460164352
  },
  {
    "name": "Sylvan Grove",
    "lng": -98.39448416819491,
    "lat": 39.01219310826806
  },
  {
    "name": "Sylvan Lake",
    "lng": -83.33305496780962,
    "lat": 42.61695779712396
  },
  {
    "name": "Sylvan Shores",
    "lng": -81.35784080713644,
    "lat": 27.301685606448743
  },
  {
    "name": "Sylvan Shores",
    "lng": -81.34560243591818,
    "lat": 27.317838281198423
  },
  {
    "name": "Sylvan Springs",
    "lng": -87.0312080663832,
    "lat": 33.5286366059003
  },
  {
    "name": "Sylvania",
    "lng": -85.81901905425028,
    "lat": 34.57437346506869
  },
  {
    "name": "Sylvania",
    "lng": -85.81416915375705,
    "lat": 34.54151282407245
  },
  {
    "name": "Sylvania",
    "lng": -85.81416915375704,
    "lat": 34.541512824072456
  },
  {
    "name": "Sylvania",
    "lng": -85.81276249833677,
    "lat": 34.56694164822113
  },
  {
    "name": "Sylvania",
    "lng": -85.80270729723253,
    "lat": 34.537066102745854
  },
  {
    "name": "Sylvania",
    "lng": -85.79248649331684,
    "lat": 34.58920900397277
  },
  {
    "name": "Sylvania",
    "lng": -85.77072532239593,
    "lat": 34.54732332542841
  },
  {
    "name": "Sylvania",
    "lng": -85.77072532239588,
    "lat": 34.54732332542839
  },
  {
    "name": "Sylvania",
    "lng": -83.70807177398133,
    "lat": 41.71018189541008
  },
  {
    "name": "Sylvania",
    "lng": -81.63794395182991,
    "lat": 32.75027994375207
  },
  {
    "name": "Sylvarena",
    "lng": -89.38177530129289,
    "lat": 32.01438544492436
  },
  {
    "name": "Sylvester",
    "lng": -83.83570404875198,
    "lat": 31.529370167048064
  },
  {
    "name": "Sylvester",
    "lng": -81.56223414239683,
    "lat": 38.00800117152407
  },
  {
    "name": "Sylvia",
    "lng": -98.40877426216298,
    "lat": 37.959171250087756
  },
  {
    "name": "Symerton",
    "lng": -88.05457238782645,
    "lat": 41.32788542418511
  },
  {
    "name": "Symerton",
    "lng": -88.05264484600684,
    "lat": 41.32468911234079
  },
  {
    "name": "Syracuse",
    "lng": -112.06981841870706,
    "lat": 41.08593253864701
  },
  {
    "name": "Syracuse",
    "lng": -101.79154297009613,
    "lat": 37.95308963650512
  },
  {
    "name": "Syracuse",
    "lng": -101.75096237512636,
    "lat": 37.97991605412472
  },
  {
    "name": "Syracuse",
    "lng": -101.73740182694048,
    "lat": 37.98723432561499
  },
  {
    "name": "Syracuse",
    "lng": -96.18340681762947,
    "lat": 40.66162268915597
  },
  {
    "name": "Syracuse",
    "lng": -92.87632926137657,
    "lat": 38.6695681168554
  },
  {
    "name": "Syracuse",
    "lng": -85.7493941857328,
    "lat": 41.421977074869844
  },
  {
    "name": "Syracuse",
    "lng": -81.96913755372222,
    "lat": 38.99855071304995
  },
  {
    "name": "Syracuse",
    "lng": -76.1437724951776,
    "lat": 43.04084127118624
  },
  {
    "name": "Tabiona",
    "lng": -110.7094823869165,
    "lat": 40.35387988147579
  },
  {
    "name": "Table Grove",
    "lng": -90.42524733357658,
    "lat": 40.3650278663593
  },
  {
    "name": "Table Rock",
    "lng": -96.0888055272118,
    "lat": 40.178269925399
  },
  {
    "name": "Tabor",
    "lng": -97.65936595447656,
    "lat": 42.94775952529031
  },
  {
    "name": "Tabor",
    "lng": -95.67366010950782,
    "lat": 40.89488205098716
  },
  {
    "name": "Tabor City",
    "lng": -78.90880843416176,
    "lat": 34.18063562358971
  },
  {
    "name": "Tabor City",
    "lng": -78.87169590352715,
    "lat": 34.15212916856932
  },
  {
    "name": "Tacoma",
    "lng": -122.51017032365645,
    "lat": 47.226303637999685
  },
  {
    "name": "Tacoma",
    "lng": -122.45431411131399,
    "lat": 47.242351250294924
  },
  {
    "name": "Taconite",
    "lng": -93.4143092555143,
    "lat": 47.285557585581685
  },
  {
    "name": "Taconite",
    "lng": -93.37528163030586,
    "lat": 47.332128493862534
  },
  {
    "name": "Taft",
    "lng": -119.42693124505443,
    "lat": 35.12682335148692
  },
  {
    "name": "Taft",
    "lng": -97.39121524072299,
    "lat": 27.98009498886313
  },
  {
    "name": "Taft",
    "lng": -95.55834727945648,
    "lat": 35.76507686069474
  },
  {
    "name": "Taft",
    "lng": -95.54380707495434,
    "lat": 35.76020799314469
  },
  {
    "name": "Taftville",
    "lng": -72.0531023925482,
    "lat": 41.56548837879926
  },
  {
    "name": "Tahlequah",
    "lng": -94.97765152898587,
    "lat": 35.91167131134274
  },
  {
    "name": "Tahlequah",
    "lng": -94.94345013459633,
    "lat": 35.9270590145716
  },
  {
    "name": "Tahoka",
    "lng": -101.7949007402125,
    "lat": 33.163988879292695
  },
  {
    "name": "Takoma Park",
    "lng": -77.00293585047561,
    "lat": 38.981065272008
  },
  {
    "name": "Talala",
    "lng": -95.69962871354178,
    "lat": 36.5300737424905
  },
  {
    "name": "Talbotton",
    "lng": -84.54056931618267,
    "lat": 32.67726350847597
  },
  {
    "name": "Talco",
    "lng": -95.10332873533386,
    "lat": 33.36326128162221
  },
  {
    "name": "Talihina",
    "lng": -95.04077692142998,
    "lat": 34.752756824882816
  },
  {
    "name": "Talking Rock",
    "lng": -84.49409214012849,
    "lat": 34.52764703235774
  },
  {
    "name": "Talladega",
    "lng": -86.09900488652777,
    "lat": 33.430715806337346
  },
  {
    "name": "Talladega Springs",
    "lng": -86.44201187096692,
    "lat": 33.11908148591672
  },
  {
    "name": "Tallahassee",
    "lng": -84.31003420664638,
    "lat": 30.48788708941514
  },
  {
    "name": "Tallahassee",
    "lng": -84.3041371182636,
    "lat": 30.484621024661966
  },
  {
    "name": "Tallahassee",
    "lng": -84.25246485284546,
    "lat": 30.45494087849069
  },
  {
    "name": "Tallapoosa",
    "lng": -89.81700320273056,
    "lat": 36.506060022019255
  },
  {
    "name": "Tallapoosa",
    "lng": -85.28598211444384,
    "lat": 33.73371799639778
  },
  {
    "name": "Tallassee",
    "lng": -85.8919447859246,
    "lat": 32.5349867724939
  },
  {
    "name": "Tallmadge",
    "lng": -81.42155069983932,
    "lat": 41.10237414913227
  },
  {
    "name": "Tallula",
    "lng": -89.93661187638675,
    "lat": 39.945724965169354
  },
  {
    "name": "Tallulah",
    "lng": -91.19175442968881,
    "lat": 32.4067163317517
  },
  {
    "name": "Tallulah",
    "lng": -91.18149648717188,
    "lat": 32.39335532566119
  },
  {
    "name": "Tallulah Falls",
    "lng": -83.38156175090515,
    "lat": 34.7257067152092
  },
  {
    "name": "Talmadge",
    "lng": -67.76677820976674,
    "lat": 45.33683332566948
  },
  {
    "name": "Talmage",
    "lng": -123.1647712650598,
    "lat": 39.13138441120257
  },
  {
    "name": "Talmage",
    "lng": -96.02382354699677,
    "lat": 40.53160982464929
  },
  {
    "name": "Taloga",
    "lng": -98.96434079269171,
    "lat": 36.0403886012608
  },
  {
    "name": "Talty",
    "lng": -96.40076315626318,
    "lat": 32.69444430888216
  },
  {
    "name": "Tama",
    "lng": -92.57397360960225,
    "lat": 41.963474609025305
  },
  {
    "name": "Tamaha",
    "lng": -95.01799486375376,
    "lat": 35.40588073854677
  },
  {
    "name": "Tamaqua",
    "lng": -75.93461096000544,
    "lat": 40.80384455864864
  },
  {
    "name": "Tamarack",
    "lng": -93.12502091408467,
    "lat": 46.65295594422243
  },
  {
    "name": "Tamaroa",
    "lng": -89.2292722311221,
    "lat": 38.13634566329553
  },
  {
    "name": "Tamms",
    "lng": -89.27132937272563,
    "lat": 37.24154317205711
  },
  {
    "name": "Tampa",
    "lng": -97.15428633485718,
    "lat": 38.54753419473764
  },
  {
    "name": "Tampa",
    "lng": -82.44421316420824,
    "lat": 27.995413086887766
  },
  {
    "name": "Tampa",
    "lng": -82.38651371526893,
    "lat": 27.948692451854573
  },
  {
    "name": "Tampico",
    "lng": -89.78541680200368,
    "lat": 41.630672072940705
  },
  {
    "name": "Tanana",
    "lng": -152.0696014825835,
    "lat": 65.17463854571619
  },
  {
    "name": "Taneytown",
    "lng": -77.16772616146122,
    "lat": 39.6557146816886
  },
  {
    "name": "Taneyville",
    "lng": -93.03524543115326,
    "lat": 36.73798182779745
  },
  {
    "name": "Tangier",
    "lng": -75.99248024240103,
    "lat": 37.824580957979435
  },
  {
    "name": "Tangipahoa",
    "lng": -90.51379501942516,
    "lat": 30.875532303421803
  },
  {
    "name": "Tanner",
    "lng": -121.71135748759833,
    "lat": 47.47998318962282
  },
  {
    "name": "Tannersville",
    "lng": -74.13536371169678,
    "lat": 42.193648540752896
  },
  {
    "name": "Taopi",
    "lng": -92.64043686779695,
    "lat": 43.55750967905794
  },
  {
    "name": "Taos",
    "lng": -105.58032979836192,
    "lat": 36.38686696620908
  },
  {
    "name": "Taos",
    "lng": -92.07815985486336,
    "lat": 38.4996315034084
  },
  {
    "name": "Taos Ski Valley",
    "lng": -105.43761118204667,
    "lat": 36.590683586273116
  },
  {
    "name": "Tappahannock",
    "lng": -76.86663807096079,
    "lat": 37.91872846513625
  },
  {
    "name": "Tappen",
    "lng": -99.6271207264403,
    "lat": 46.87161299446471
  },
  {
    "name": "Tar Heel",
    "lng": -78.79541057930189,
    "lat": 34.73474059821439
  },
  {
    "name": "Tar Heel",
    "lng": -78.79006551519429,
    "lat": 34.73267343509082
  },
  {
    "name": "Tarboro",
    "lng": -77.55632380070028,
    "lat": 35.904429357583446
  },
  {
    "name": "Tarboro",
    "lng": -77.54319918590646,
    "lat": 35.936678656605274
  },
  {
    "name": "Tarkio",
    "lng": -95.38350862993468,
    "lat": 40.44298148371902
  },
  {
    "name": "Tarlton",
    "lng": -82.7781118246156,
    "lat": 39.55432214509106
  },
  {
    "name": "Tarnov",
    "lng": -97.50251592996145,
    "lat": 41.61472143076087
  },
  {
    "name": "Tarpon Springs",
    "lng": -82.75915515879754,
    "lat": 28.146635873139555
  },
  {
    "name": "Tarrant",
    "lng": -86.76800120255005,
    "lat": 33.59453828952507
  },
  {
    "name": "Tarrants",
    "lng": -91.18370360410923,
    "lat": 39.35760936303603
  },
  {
    "name": "Tarrytown",
    "lng": -82.55955214174591,
    "lat": 32.31871591740591
  },
  {
    "name": "Tarrytown",
    "lng": -73.86598177825253,
    "lat": 41.06456828413397
  },
  {
    "name": "Tatum",
    "lng": -103.31114897961967,
    "lat": 33.2552650959786
  },
  {
    "name": "Tatum",
    "lng": -94.51888393081892,
    "lat": 32.31588859394636
  },
  {
    "name": "Tatum",
    "lng": -79.5882690135068,
    "lat": 34.64255362121178
  },
  {
    "name": "Tatums",
    "lng": -97.4660204410842,
    "lat": 34.47785275011958
  },
  {
    "name": "Taunton",
    "lng": -96.06293948005015,
    "lat": 44.59427458312079
  },
  {
    "name": "Tawas City",
    "lng": -83.52459601240753,
    "lat": 44.267833447550316
  },
  {
    "name": "Taylor",
    "lng": -110.10219215403859,
    "lat": 34.44402547249986
  },
  {
    "name": "Taylor",
    "lng": -102.42221172408303,
    "lat": 46.901824211284385
  },
  {
    "name": "Taylor",
    "lng": -99.38138429760603,
    "lat": 41.76960428098133
  },
  {
    "name": "Taylor",
    "lng": -97.4233752385864,
    "lat": 30.574314381750693
  },
  {
    "name": "Taylor",
    "lng": -93.4618022575571,
    "lat": 33.09983448946766
  },
  {
    "name": "Taylor",
    "lng": -91.12042717123036,
    "lat": 44.32172237758996
  },
  {
    "name": "Taylor",
    "lng": -89.57931700370546,
    "lat": 34.27016016516566
  },
  {
    "name": "Taylor",
    "lng": -85.46972785258464,
    "lat": 31.165210486307064
  },
  {
    "name": "Taylor",
    "lng": -83.26882103085656,
    "lat": 42.22608234823885
  },
  {
    "name": "Taylor Lake Village",
    "lng": -95.05516774187514,
    "lat": 29.577079806691252
  },
  {
    "name": "Taylor Landing",
    "lng": -94.14295077849016,
    "lat": 29.873254596120102
  },
  {
    "name": "Taylor Landing",
    "lng": -94.13364604131552,
    "lat": 29.862844492159546
  },
  {
    "name": "Taylor Mill",
    "lng": -84.4983154882662,
    "lat": 39.00894578394028
  },
  {
    "name": "Taylor Springs",
    "lng": -89.495017720269,
    "lat": 39.13097247995144
  },
  {
    "name": "Taylor Springs",
    "lng": -89.48980352947585,
    "lat": 39.137588082706145
  },
  {
    "name": "Taylors Falls",
    "lng": -92.69094216415598,
    "lat": 45.40099674272403
  },
  {
    "name": "Taylors Falls",
    "lng": -92.66399668309347,
    "lat": 45.41226744789706
  },
  {
    "name": "Taylorsville",
    "lng": -111.9493196166758,
    "lat": 40.656940668942
  },
  {
    "name": "Taylorsville",
    "lng": -89.43272963618529,
    "lat": 31.831627561051608
  },
  {
    "name": "Taylorsville",
    "lng": -84.98756601641578,
    "lat": 34.08598602699763
  },
  {
    "name": "Taylorsville",
    "lng": -81.1840899362742,
    "lat": 35.9256054949247
  },
  {
    "name": "Taylorsville",
    "lng": -81.17950635000773,
    "lat": 35.934244002309335
  },
  {
    "name": "Taylorsville",
    "lng": -81.17893844882786,
    "lat": 35.90513409468244
  },
  {
    "name": "Taylorsville",
    "lng": -81.17874164997882,
    "lat": 35.90021816646829
  },
  {
    "name": "Taylorsville",
    "lng": -81.17798703517312,
    "lat": 35.90280009716912
  },
  {
    "name": "Taylorsville",
    "lng": -81.17639388128269,
    "lat": 35.91868848635561
  },
  {
    "name": "Taylortown",
    "lng": -79.50949730237723,
    "lat": 35.21958054979024
  },
  {
    "name": "Taylortown",
    "lng": -79.4896311035099,
    "lat": 35.21434261025024
  },
  {
    "name": "Taylortown",
    "lng": -79.48321935569591,
    "lat": 35.223952194901265
  },
  {
    "name": "Taylorville",
    "lng": -89.2803189348424,
    "lat": 39.53284526890734
  },
  {
    "name": "Tazewell",
    "lng": -83.56525955601575,
    "lat": 36.46318776194615
  },
  {
    "name": "Tazewell",
    "lng": -81.51334583121947,
    "lat": 37.12681401026704
  },
  {
    "name": "Tchula",
    "lng": -90.22307756684364,
    "lat": 33.1833396097271
  },
  {
    "name": "Tea",
    "lng": -96.83466209536607,
    "lat": 43.45172598072508
  },
  {
    "name": "Teachey",
    "lng": -78.00785178083643,
    "lat": 34.76829381128986
  },
  {
    "name": "Teague",
    "lng": -96.32875384371128,
    "lat": 31.64376771069885
  },
  {
    "name": "Teague",
    "lng": -96.27751961669723,
    "lat": 31.62880837650448
  },
  {
    "name": "Teasdale",
    "lng": -111.47235633935169,
    "lat": 38.2850947673343
  },
  {
    "name": "Tecumseh",
    "lng": -96.93363739739539,
    "lat": 35.263808180612685
  },
  {
    "name": "Tecumseh",
    "lng": -96.18887233995164,
    "lat": 40.371858925457
  },
  {
    "name": "Tecumseh",
    "lng": -83.94473323635522,
    "lat": 42.00658247830814
  },
  {
    "name": "Tega Cay",
    "lng": -81.01437360628285,
    "lat": 35.036595173966326
  },
  {
    "name": "Tehachapi",
    "lng": -118.570408668294,
    "lat": 35.11289836111216
  },
  {
    "name": "Tehachapi",
    "lng": -118.44091142671775,
    "lat": 35.132247079734235
  },
  {
    "name": "Tehama",
    "lng": -122.12687954673467,
    "lat": 40.021804160612504
  },
  {
    "name": "Tehuacana",
    "lng": -96.54362450650753,
    "lat": 31.741031794063492
  },
  {
    "name": "Tekamah",
    "lng": -96.2223497637902,
    "lat": 41.77612168735877
  },
  {
    "name": "Tekoa",
    "lng": -117.09650036463627,
    "lat": 47.23276831515951
  },
  {
    "name": "Tekoa",
    "lng": -117.0763395250824,
    "lat": 47.21168609735249
  },
  {
    "name": "Tekoa",
    "lng": -117.07439213531327,
    "lat": 47.22523028631192
  },
  {
    "name": "Tekoa",
    "lng": -117.04331133412751,
    "lat": 47.235860267840955
  },
  {
    "name": "Tekonsha",
    "lng": -84.98841231714007,
    "lat": 42.094524963955884
  },
  {
    "name": "Tekonsha",
    "lng": -84.98614400212072,
    "lat": 42.10554225128677
  },
  {
    "name": "Tell City",
    "lng": -86.75945946629396,
    "lat": 37.95284508843374
  },
  {
    "name": "Teller",
    "lng": -166.3388602063053,
    "lat": 65.25212521967231
  },
  {
    "name": "Tellico Plains",
    "lng": -84.29912497993456,
    "lat": 35.36684529565135
  },
  {
    "name": "Telluride",
    "lng": -107.83214680370445,
    "lat": 37.94232502309408
  },
  {
    "name": "Temecula",
    "lng": -117.13143875154537,
    "lat": 33.492935799677035
  },
  {
    "name": "Temple",
    "lng": -98.24700118606404,
    "lat": 34.27657225621194
  },
  {
    "name": "Temple",
    "lng": -98.2348214868208,
    "lat": 34.27286378507395
  },
  {
    "name": "Temple",
    "lng": -97.37773852827961,
    "lat": 31.102680475657674
  },
  {
    "name": "Temple",
    "lng": -85.02890530025444,
    "lat": 33.73416908820043
  },
  {
    "name": "Temple",
    "lng": -75.92185253007689,
    "lat": 40.40827898463416
  },
  {
    "name": "Temple",
    "lng": -71.855078361874,
    "lat": 42.828419585811424
  },
  {
    "name": "Temple",
    "lng": -70.28051690712735,
    "lat": 44.69649545387456
  },
  {
    "name": "Temple City",
    "lng": -118.05790908843511,
    "lat": 34.10218014253926
  },
  {
    "name": "Templeton",
    "lng": -94.94139479785218,
    "lat": 41.918402813891916
  },
  {
    "name": "Templeton",
    "lng": -72.07358057385832,
    "lat": 42.56864788161798
  },
  {
    "name": "Templeville",
    "lng": -75.76696837101257,
    "lat": 39.136291775480736
  },
  {
    "name": "Ten Broeck",
    "lng": -85.58081637586992,
    "lat": 38.29748194225892
  },
  {
    "name": "Ten Sleep",
    "lng": -107.44832897608042,
    "lat": 44.035008729766304
  },
  {
    "name": "Tenaha",
    "lng": -94.24508648927403,
    "lat": 31.944111217675395
  },
  {
    "name": "Tenakee Springs",
    "lng": -135.19292947034654,
    "lat": 57.79160437871496
  },
  {
    "name": "Tenino",
    "lng": -122.86568566666664,
    "lat": 46.84462933353404
  },
  {
    "name": "Tenino",
    "lng": -122.84894187963825,
    "lat": 46.85694927064198
  },
  {
    "name": "Tennessee",
    "lng": -90.83649347856334,
    "lat": 40.41060690688511
  },
  {
    "name": "Tennessee",
    "lng": -86.33976403066536,
    "lat": 35.847033156543446
  },
  {
    "name": "Tennessee Ridge",
    "lng": -87.76352059857824,
    "lat": 36.31949624296932
  },
  {
    "name": "Tennille",
    "lng": -82.81688510945926,
    "lat": 32.95246735772808
  },
  {
    "name": "Tennille",
    "lng": -82.81335384859469,
    "lat": 32.93534481406114
  },
  {
    "name": "Tennyson",
    "lng": -90.68680699005337,
    "lat": 42.690640990075785
  },
  {
    "name": "Tennyson",
    "lng": -87.1180055967863,
    "lat": 38.082191394604706
  },
  {
    "name": "Tensed",
    "lng": -116.92379491633706,
    "lat": 47.159611699119345
  },
  {
    "name": "Tenstrike",
    "lng": -94.68430296103305,
    "lat": 47.66044080743834
  },
  {
    "name": "Tequesta",
    "lng": -80.10126012897405,
    "lat": 26.961836972908493
  },
  {
    "name": "Terlton",
    "lng": -96.49035996299507,
    "lat": 36.18569622698168
  },
  {
    "name": "Terra Alta",
    "lng": -79.54356870685669,
    "lat": 39.444217108048235
  },
  {
    "name": "Terra Bella",
    "lng": -119.03898443533237,
    "lat": 35.95952386480148
  },
  {
    "name": "Terrace Park",
    "lng": -84.31116140920817,
    "lat": 39.158206889980995
  },
  {
    "name": "Terral",
    "lng": -97.93823265117038,
    "lat": 33.896637379042545
  },
  {
    "name": "Terre Haute",
    "lng": -87.40957832445515,
    "lat": 39.41315844619021
  },
  {
    "name": "Terre Haute",
    "lng": -87.37749563344097,
    "lat": 39.4647660149915
  },
  {
    "name": "Terrell",
    "lng": -96.31460894885258,
    "lat": 32.702383477318385
  },
  {
    "name": "Terrell",
    "lng": -96.29803625962062,
    "lat": 32.70594479442707
  },
  {
    "name": "Terrell",
    "lng": -96.29517034196067,
    "lat": 32.70412290709551
  },
  {
    "name": "Terrell",
    "lng": -96.2920155162488,
    "lat": 32.732888212886195
  },
  {
    "name": "Terrell",
    "lng": -96.2881198490197,
    "lat": 32.770412574223386
  },
  {
    "name": "Terrell",
    "lng": -96.28045732752119,
    "lat": 32.77147561020623
  },
  {
    "name": "Terrell Hills",
    "lng": -98.44708911709671,
    "lat": 29.477095396575095
  },
  {
    "name": "Terril",
    "lng": -94.96893870526044,
    "lat": 43.305799484187894
  },
  {
    "name": "Terry",
    "lng": -105.31252600798148,
    "lat": 46.79205572349576
  },
  {
    "name": "Terrytown",
    "lng": -103.67036218635512,
    "lat": 41.84688421542022
  },
  {
    "name": "Tescott",
    "lng": -97.87815494296738,
    "lat": 39.01279163781236
  },
  {
    "name": "Teton",
    "lng": -111.67236510175127,
    "lat": 43.887180182885345
  },
  {
    "name": "Tetonia",
    "lng": -111.15863847261235,
    "lat": 43.81459810564242
  },
  {
    "name": "Teutopolis",
    "lng": -88.48130557618767,
    "lat": 39.13135702725538
  },
  {
    "name": "Tewksbury",
    "lng": -71.22784156490141,
    "lat": 42.61204584077626
  },
  {
    "name": "Texarkana",
    "lng": -94.08148435844808,
    "lat": 33.448748470084055
  },
  {
    "name": "Texarkana",
    "lng": -93.99601830253599,
    "lat": 33.43603406908964
  },
  {
    "name": "Texas",
    "lng": -99.30429297249123,
    "lat": 31.497620107861838
  },
  {
    "name": "Texas City",
    "lng": -94.89494482796913,
    "lat": 29.447853236326594
  },
  {
    "name": "Texas Department of Public Safety - Brenham",
    "lng": -96.40370595381859,
    "lat": 30.144784655053726
  },
  {
    "name": "Texhoma",
    "lng": -101.79321911460231,
    "lat": 36.49381609833335
  },
  {
    "name": "Texhoma",
    "lng": -101.7876033639711,
    "lat": 36.505395255323734
  },
  {
    "name": "Texico",
    "lng": -103.0512580868581,
    "lat": 34.389629685856846
  },
  {
    "name": "Texline",
    "lng": -103.01892140396596,
    "lat": 36.376644179010455
  },
  {
    "name": "Texola",
    "lng": -99.99279668068415,
    "lat": 35.222234207829935
  },
  {
    "name": "Thackerville",
    "lng": -97.14258912608203,
    "lat": 33.79342055244892
  },
  {
    "name": "Thatcher",
    "lng": -109.75742803246969,
    "lat": 32.835832767997104
  },
  {
    "name": "Thawville",
    "lng": -88.1132887285261,
    "lat": 40.67344695618995
  },
  {
    "name": "Thaxton",
    "lng": -89.17450774027242,
    "lat": 34.307116483601924
  },
  {
    "name": "Thayer",
    "lng": -97.49664926056266,
    "lat": 40.97030863013307
  },
  {
    "name": "Thayer",
    "lng": -95.49565688879811,
    "lat": 37.48178327068926
  },
  {
    "name": "Thayer",
    "lng": -95.47403788720025,
    "lat": 37.488253234162634
  },
  {
    "name": "Thayer",
    "lng": -94.05026709120605,
    "lat": 41.02894135893782
  },
  {
    "name": "Thayer",
    "lng": -91.54115594496103,
    "lat": 36.52507388051435
  },
  {
    "name": "Thayer",
    "lng": -89.75792655807867,
    "lat": 39.54036627848423
  },
  {
    "name": "Thayne",
    "lng": -110.99703072701259,
    "lat": 42.91943941923354
  },
  {
    "name": "The Plains",
    "lng": -77.77411391140022,
    "lat": 38.86224590822554
  },
  {
    "name": "The Preserve",
    "lng": -95.54668018629371,
    "lat": 30.128475031753332
  },
  {
    "name": "The Ridges",
    "lng": -69.79110279287704,
    "lat": 44.29412376080166
  },
  {
    "name": "The Village",
    "lng": -97.55653812426421,
    "lat": 35.57051345572194
  },
  {
    "name": "The Village of Indian Hill",
    "lng": -84.33444074443031,
    "lat": 39.19155505175374
  },
  {
    "name": "The Villages",
    "lng": -81.98674415317738,
    "lat": 28.872542025298404
  },
  {
    "name": "The Woodlands",
    "lng": -95.51558190341399,
    "lat": 30.172276519767802
  },
  {
    "name": "The Woodlands",
    "lng": -95.44616932543505,
    "lat": 30.21885600238912
  },
  {
    "name": "The Woods",
    "lng": -85.79961288265315,
    "lat": 36.258479077410676
  },
  {
    "name": "Thebes",
    "lng": -89.45438343025856,
    "lat": 37.21397795729922
  },
  {
    "name": "Thedford",
    "lng": -100.57522236035813,
    "lat": 41.978634579316115
  },
  {
    "name": "Theodosia",
    "lng": -92.66379823383262,
    "lat": 36.580670891267445
  },
  {
    "name": "Theresa",
    "lng": -88.45402806803868,
    "lat": 43.516495014597645
  },
  {
    "name": "Theresa",
    "lng": -75.79672514715179,
    "lat": 44.21519419419879
  },
  {
    "name": "Thermopolis",
    "lng": -108.21391829043687,
    "lat": 43.64788190813297
  },
  {
    "name": "Thibodaux",
    "lng": -90.81683914823805,
    "lat": 29.79439167816503
  },
  {
    "name": "Thief River Falls",
    "lng": -96.17940057987364,
    "lat": 48.1309342335272
  },
  {
    "name": "Thief River Falls",
    "lng": -96.17772926212913,
    "lat": 48.11097023542255
  },
  {
    "name": "Thiensville",
    "lng": -87.9785564729546,
    "lat": 43.23632761241345
  },
  {
    "name": "Third Lake",
    "lng": -88.03324073881736,
    "lat": 42.387216629003255
  },
  {
    "name": "Third Lake",
    "lng": -88.00821130823616,
    "lat": 42.368260453848556
  },
  {
    "name": "Third Lake",
    "lng": -88.00486978755158,
    "lat": 42.38257869039518
  },
  {
    "name": "Thomas",
    "lng": -98.74983110922147,
    "lat": 35.74772544503808
  },
  {
    "name": "Thomas",
    "lng": -79.49651006971136,
    "lat": 39.14870070419885
  },
  {
    "name": "Thomasboro",
    "lng": -88.18768990545732,
    "lat": 40.24391908205045
  },
  {
    "name": "Thomaston",
    "lng": -87.62370645346589,
    "lat": 32.26827853379223
  },
  {
    "name": "Thomaston",
    "lng": -84.32713136864925,
    "lat": 32.890724561629675
  },
  {
    "name": "Thomaston",
    "lng": -84.29982604929471,
    "lat": 32.90623872246896
  },
  {
    "name": "Thomaston",
    "lng": -73.7150094484854,
    "lat": 40.78764490911322
  },
  {
    "name": "Thomaston",
    "lng": -73.08587789202099,
    "lat": 41.669628985119644
  },
  {
    "name": "Thomaston",
    "lng": -69.17488574965533,
    "lat": 44.093040150048
  },
  {
    "name": "Thomasville",
    "lng": -87.74175675539229,
    "lat": 31.910871888545707
  },
  {
    "name": "Thomasville",
    "lng": -83.99237300304657,
    "lat": 30.800514202318386
  },
  {
    "name": "Thomasville",
    "lng": -83.9784186561912,
    "lat": 30.840009047735457
  },
  {
    "name": "Thomasville",
    "lng": -83.95611480042922,
    "lat": 30.872136177465894
  },
  {
    "name": "Thomasville",
    "lng": -83.95541618927767,
    "lat": 30.86983637956169
  },
  {
    "name": "Thomasville",
    "lng": -83.95364799411279,
    "lat": 30.867924400322032
  },
  {
    "name": "Thomasville",
    "lng": -83.95126474765569,
    "lat": 30.862691418443564
  },
  {
    "name": "Thomasville",
    "lng": -83.95010342994807,
    "lat": 30.87107809789452
  },
  {
    "name": "Thomasville",
    "lng": -83.94902226607888,
    "lat": 30.810476922183305
  },
  {
    "name": "Thomasville",
    "lng": -83.9443264742669,
    "lat": 30.852852161900884
  },
  {
    "name": "Thomasville",
    "lng": -80.08292535742963,
    "lat": 35.91417837616556
  },
  {
    "name": "Thomasville",
    "lng": -80.0808443973942,
    "lat": 35.88107963764541
  },
  {
    "name": "Thomasville",
    "lng": -80.05888624619854,
    "lat": 35.92750062503475
  },
  {
    "name": "Thompson",
    "lng": -97.10617067506722,
    "lat": 47.7755405504057
  },
  {
    "name": "Thompson",
    "lng": -93.77464062536654,
    "lat": 43.36974383088038
  },
  {
    "name": "Thompson",
    "lng": -71.87373645861686,
    "lat": 41.97970324318682
  },
  {
    "name": "Thompson Falls",
    "lng": -115.33854967524549,
    "lat": 47.6003962983815
  },
  {
    "name": "Thompson's Station",
    "lng": -86.9073134720356,
    "lat": 35.80800525216039
  },
  {
    "name": "Thompsons",
    "lng": -95.63040007337315,
    "lat": 29.490595740683215
  },
  {
    "name": "Thompsonville",
    "lng": -88.7622836942195,
    "lat": 37.91446311474948
  },
  {
    "name": "Thompsonville",
    "lng": -85.93874131804692,
    "lat": 44.52043357326172
  },
  {
    "name": "Thomson",
    "lng": -90.11122654835536,
    "lat": 41.971404143520886
  },
  {
    "name": "Thomson",
    "lng": -82.49872839828089,
    "lat": 33.467360000300424
  },
  {
    "name": "Thor",
    "lng": -94.04961671850101,
    "lat": 42.6881580778168
  },
  {
    "name": "Thornburg",
    "lng": -92.33111684649968,
    "lat": 41.45571191314354
  },
  {
    "name": "Thorndike",
    "lng": -69.23747159595361,
    "lat": 44.593580329649974
  },
  {
    "name": "Thorne Bay",
    "lng": -132.53318809960396,
    "lat": 55.66485213746043
  },
  {
    "name": "Thornhill",
    "lng": -85.62567671228541,
    "lat": 38.288171833074635
  },
  {
    "name": "Thornton",
    "lng": -104.94461501014372,
    "lat": 39.92106996582974
  },
  {
    "name": "Thornton",
    "lng": -96.57386387592715,
    "lat": 31.411138424171682
  },
  {
    "name": "Thornton",
    "lng": -93.3870333497423,
    "lat": 42.94421865811244
  },
  {
    "name": "Thornton",
    "lng": -92.48903103841769,
    "lat": 33.775551446698415
  },
  {
    "name": "Thornton",
    "lng": -87.61866523469091,
    "lat": 41.570981249242855
  },
  {
    "name": "Thorntonville",
    "lng": -102.92207697871847,
    "lat": 31.578925375164413
  },
  {
    "name": "Thorntown",
    "lng": -86.6101920303226,
    "lat": 40.12946316227667
  },
  {
    "name": "Thornville",
    "lng": -82.41409130143836,
    "lat": 39.8929090987765
  },
  {
    "name": "Thorp",
    "lng": -90.81311390213324,
    "lat": 44.975328051347375
  },
  {
    "name": "Thorp",
    "lng": -90.80190318420416,
    "lat": 44.95745189663601
  },
  {
    "name": "Thorsby",
    "lng": -86.71827439291634,
    "lat": 32.917176142928824
  },
  {
    "name": "Thousand Oaks",
    "lng": -118.91040124558482,
    "lat": 34.171655592364786
  },
  {
    "name": "Thousand Oaks",
    "lng": -118.87563936397557,
    "lat": 34.191352208581854
  },
  {
    "name": "Three Creeks",
    "lng": -91.00480439842687,
    "lat": 38.57810053627475
  },
  {
    "name": "Three Forks",
    "lng": -111.55389312954709,
    "lat": 45.890007724604956
  },
  {
    "name": "Three Oaks",
    "lng": -86.61238439897564,
    "lat": 41.79890850057924
  },
  {
    "name": "Three Rivers",
    "lng": -85.65673171584488,
    "lat": 41.94149776426674
  },
  {
    "name": "Three Rivers",
    "lng": -85.65093675006433,
    "lat": 41.938083077621044
  },
  {
    "name": "Three Rivers",
    "lng": -85.65066882085127,
    "lat": 41.95005890890077
  },
  {
    "name": "Three Rivers",
    "lng": -85.63446726052301,
    "lat": 41.96038978481352
  },
  {
    "name": "Three Rivers",
    "lng": -85.63445790911119,
    "lat": 41.9610769758675
  },
  {
    "name": "Three Rivers",
    "lng": -85.63140472742367,
    "lat": 41.96540733838363
  },
  {
    "name": "Three Rivers",
    "lng": -85.62709128208346,
    "lat": 41.946839687029545
  },
  {
    "name": "Three Way",
    "lng": -88.8568863842143,
    "lat": 35.77383293472398
  },
  {
    "name": "Throckmorton",
    "lng": -99.17976699000019,
    "lat": 33.18194559992987
  },
  {
    "name": "Thunderbolt",
    "lng": -81.0478244888896,
    "lat": 32.034019629903135
  },
  {
    "name": "Thurman",
    "lng": -95.7489132434769,
    "lat": 40.82015314886249
  },
  {
    "name": "Thurmond",
    "lng": -81.07955172414172,
    "lat": 37.96077507635141
  },
  {
    "name": "Thurmont",
    "lng": -77.40767179568752,
    "lat": 39.62253214529429
  },
  {
    "name": "Thurmont",
    "lng": -77.40442361596338,
    "lat": 39.6034310255232
  },
  {
    "name": "Thurston",
    "lng": -96.70050995328536,
    "lat": 42.1769477608852
  },
  {
    "name": "Thurston",
    "lng": -82.5445835370227,
    "lat": 39.842653945553046
  },
  {
    "name": "Tickfaw",
    "lng": -90.48715315173745,
    "lat": 30.577153740827516
  },
  {
    "name": "Tierra Verde",
    "lng": -82.7270878928058,
    "lat": 27.669659799357593
  },
  {
    "name": "Tieton",
    "lng": -120.7571595693393,
    "lat": 46.703350294604235
  },
  {
    "name": "Tieton",
    "lng": -120.73563032737874,
    "lat": 46.69312144881693
  },
  {
    "name": "Tiffin",
    "lng": -91.65899204354834,
    "lat": 41.70674025261186
  },
  {
    "name": "Tiffin",
    "lng": -83.18001246730728,
    "lat": 41.11666952363543
  },
  {
    "name": "Tifton",
    "lng": -83.5200381228672,
    "lat": 31.46261587852552
  },
  {
    "name": "Tiger",
    "lng": -83.43306181563172,
    "lat": 34.845791374997894
  },
  {
    "name": "Tigerton",
    "lng": -89.04645484386539,
    "lat": 44.74086067500488
  },
  {
    "name": "Tightwad",
    "lng": -93.54661701851205,
    "lat": 38.30961354069095
  },
  {
    "name": "Tignall",
    "lng": -82.74198182248679,
    "lat": 33.86736494218795
  },
  {
    "name": "Tijeras",
    "lng": -106.37483505927374,
    "lat": 35.088419370627015
  },
  {
    "name": "Tiki Island",
    "lng": -94.91514662044038,
    "lat": 29.297309425650393
  },
  {
    "name": "Tilden",
    "lng": -97.83198268374383,
    "lat": 42.04388720598798
  },
  {
    "name": "Tilden",
    "lng": -89.68395215575958,
    "lat": 38.21240803306048
  },
  {
    "name": "Tillatoba",
    "lng": -89.89605961522743,
    "lat": 33.98508690918627
  },
  {
    "name": "Tilton",
    "lng": -87.63976920598176,
    "lat": 40.09418701600066
  },
  {
    "name": "Tiltonsville",
    "lng": -80.69705467823005,
    "lat": 40.17203632813728
  },
  {
    "name": "Timber Lake",
    "lng": -101.0747400494927,
    "lat": 45.42794186862235
  },
  {
    "name": "Timbercreek Canyon",
    "lng": -101.81867819549694,
    "lat": 35.05389724729002
  },
  {
    "name": "Timberlake",
    "lng": -81.44296552001464,
    "lat": 41.66560851366601
  },
  {
    "name": "Timberlane",
    "lng": -88.86943267405954,
    "lat": 42.3371821171755
  },
  {
    "name": "Timberville",
    "lng": -78.77299817181982,
    "lat": 38.63404697571933
  },
  {
    "name": "Time",
    "lng": -90.72336590605146,
    "lat": 39.561128685474195
  },
  {
    "name": "Timken",
    "lng": -99.17755023279435,
    "lat": 38.47308908431947
  },
  {
    "name": "Timmonsville",
    "lng": -79.94194667348928,
    "lat": 34.1343368665812
  },
  {
    "name": "Timnath",
    "lng": -104.96269290168826,
    "lat": 40.52857693930366
  },
  {
    "name": "Timpson",
    "lng": -94.39713078021735,
    "lat": 31.90649014717934
  },
  {
    "name": "Tina",
    "lng": -93.4413181215272,
    "lat": 39.53788544590123
  },
  {
    "name": "Tindall",
    "lng": -93.60965414576955,
    "lat": 40.16087834524167
  },
  {
    "name": "Tingley",
    "lng": -94.19581477817225,
    "lat": 40.85269488241552
  },
  {
    "name": "Tinley Park",
    "lng": -87.8050719188699,
    "lat": 41.56725874817609
  },
  {
    "name": "Tinley Park",
    "lng": -87.781417830906,
    "lat": 41.6016177365583
  },
  {
    "name": "Tinsman",
    "lng": -92.35330172566573,
    "lat": 33.62918875306287
  },
  {
    "name": "Tintah",
    "lng": -96.3200857890524,
    "lat": 46.01081151308105
  },
  {
    "name": "Tioga",
    "lng": -102.93862557630666,
    "lat": 48.39764968407799
  },
  {
    "name": "Tioga",
    "lng": -96.91902164226542,
    "lat": 33.47122362112026
  },
  {
    "name": "Tionesta",
    "lng": -79.4479984205338,
    "lat": 41.4936615504249
  },
  {
    "name": "Tipp City",
    "lng": -84.18730750977724,
    "lat": 39.96512735689096
  },
  {
    "name": "Tipton",
    "lng": -119.31296976114734,
    "lat": 36.05852365244379
  },
  {
    "name": "Tipton",
    "lng": -99.13831431388027,
    "lat": 34.5021701797077
  },
  {
    "name": "Tipton",
    "lng": -98.47082468712738,
    "lat": 39.339519198205494
  },
  {
    "name": "Tipton",
    "lng": -92.78025383863913,
    "lat": 38.654884977449406
  },
  {
    "name": "Tipton",
    "lng": -91.12861502764169,
    "lat": 41.770077556143015
  },
  {
    "name": "Tipton",
    "lng": -86.04198112310637,
    "lat": 40.28429227082966
  },
  {
    "name": "Tiptonville",
    "lng": -89.47159147281687,
    "lat": 36.386660731407574
  },
  {
    "name": "Tira",
    "lng": -95.56073315223624,
    "lat": 33.32211804875529
  },
  {
    "name": "Tiro",
    "lng": -82.76899266171776,
    "lat": 40.906284388362806
  },
  {
    "name": "Tishomingo",
    "lng": -96.69073092946505,
    "lat": 34.22267535647776
  },
  {
    "name": "Tishomingo",
    "lng": -96.68061230423783,
    "lat": 34.238783057279406
  },
  {
    "name": "Tishomingo",
    "lng": -88.22868447691927,
    "lat": 34.63401131834677
  },
  {
    "name": "Tiskilwa",
    "lng": -89.50843889745609,
    "lat": 41.29268010845339
  },
  {
    "name": "Titonka",
    "lng": -94.04137705510705,
    "lat": 43.2368174202423
  },
  {
    "name": "Titusville",
    "lng": -80.85887117511005,
    "lat": 28.609693939145235
  },
  {
    "name": "Titusville",
    "lng": -80.85679897089065,
    "lat": 28.60997747654132
  },
  {
    "name": "Titusville",
    "lng": -80.85588593012513,
    "lat": 28.60963137579549
  },
  {
    "name": "Titusville",
    "lng": -80.85258318632043,
    "lat": 28.63051018095511
  },
  {
    "name": "Titusville",
    "lng": -80.83505025310605,
    "lat": 28.544291661663408
  },
  {
    "name": "Titusville",
    "lng": -80.83341627126258,
    "lat": 28.63908405194513
  },
  {
    "name": "Titusville",
    "lng": -80.82316340584624,
    "lat": 28.57095878515907
  },
  {
    "name": "Titusville",
    "lng": -80.7997903614847,
    "lat": 28.620340916661178
  },
  {
    "name": "Titusville",
    "lng": -80.79093246656359,
    "lat": 28.626108455800846
  },
  {
    "name": "Titusville",
    "lng": -80.78456620075713,
    "lat": 28.509916380613184
  },
  {
    "name": "Titusville",
    "lng": -79.66983209039695,
    "lat": 41.62723258706955
  },
  {
    "name": "Tiverton",
    "lng": -71.17368270252375,
    "lat": 41.60910333486899
  },
  {
    "name": "Tivoli",
    "lng": -73.91181300865564,
    "lat": 42.05861187209578
  },
  {
    "name": "Tobaccoville",
    "lng": -80.38583859853762,
    "lat": 36.23884419038185
  },
  {
    "name": "Tobaccoville",
    "lng": -80.36675889387844,
    "lat": 36.22111033995237
  },
  {
    "name": "Tobaccoville",
    "lng": -80.3602159172625,
    "lat": 36.22158269739304
  },
  {
    "name": "Tobaccoville",
    "lng": -80.35869139706651,
    "lat": 36.22596023851878
  },
  {
    "name": "Tobaccoville",
    "lng": -80.35671544410033,
    "lat": 36.235484975060004
  },
  {
    "name": "Tobias",
    "lng": -97.33661878765173,
    "lat": 40.41849811550576
  },
  {
    "name": "Toccoa",
    "lng": -83.34597865576548,
    "lat": 34.56917467041625
  },
  {
    "name": "Toccoa",
    "lng": -83.3422710004922,
    "lat": 34.56531971749181
  },
  {
    "name": "Toccoa",
    "lng": -83.32397809096929,
    "lat": 34.580520224165326
  },
  {
    "name": "Toccoa",
    "lng": -83.30951892234731,
    "lat": 34.58290485338339
  },
  {
    "name": "Toccopola",
    "lng": -89.2210012035588,
    "lat": 34.25195928926826
  },
  {
    "name": "Todd Mission",
    "lng": -95.83293659688104,
    "lat": 30.260847153337522
  },
  {
    "name": "Togiak",
    "lng": -160.49628358283317,
    "lat": 58.95860199889391
  },
  {
    "name": "Toksook Bay",
    "lng": -165.1256468960436,
    "lat": 60.507333196276996
  },
  {
    "name": "Tolar",
    "lng": -97.91910003541301,
    "lat": 32.38944289354444
  },
  {
    "name": "Toledo",
    "lng": -122.84957070142133,
    "lat": 46.4412291647004
  },
  {
    "name": "Toledo",
    "lng": -92.58035680583677,
    "lat": 41.99010396115266
  },
  {
    "name": "Toledo",
    "lng": -88.24218812376893,
    "lat": 39.27278202177645
  },
  {
    "name": "Toledo",
    "lng": -83.58189945805894,
    "lat": 41.664053159648255
  },
  {
    "name": "Tolland",
    "lng": -73.021399523986,
    "lat": 42.09178925866359
  },
  {
    "name": "Tolland",
    "lng": -72.36480826895301,
    "lat": 41.87862219225985
  },
  {
    "name": "Tollette",
    "lng": -93.89691335658627,
    "lat": 33.81808999300662
  },
  {
    "name": "Tolley",
    "lng": -101.8271966273442,
    "lat": 48.73036238888222
  },
  {
    "name": "Tolna",
    "lng": -98.43849294169125,
    "lat": 47.82588787455249
  },
  {
    "name": "Tolono",
    "lng": -88.26249808285576,
    "lat": 39.99120809628187
  },
  {
    "name": "Tolono",
    "lng": -88.2603122862833,
    "lat": 39.978239648616885
  },
  {
    "name": "Tolono",
    "lng": -88.2568631958705,
    "lat": 40.013104501982376
  },
  {
    "name": "Tolstoy",
    "lng": -99.61398531737132,
    "lat": 45.20814834991868
  },
  {
    "name": "Toluca",
    "lng": -89.13394443416266,
    "lat": 41.004502920642324
  },
  {
    "name": "Toluca",
    "lng": -89.12536369194557,
    "lat": 40.99551272077184
  },
  {
    "name": "Tom Bean",
    "lng": -96.48465067972474,
    "lat": 33.520413609603125
  },
  {
    "name": "Tomah",
    "lng": -90.52235011090302,
    "lat": 43.99587210588734
  },
  {
    "name": "Tomah",
    "lng": -90.5190866666038,
    "lat": 43.954660831214454
  },
  {
    "name": "Tomah",
    "lng": -90.4992294278083,
    "lat": 43.98864908802486
  },
  {
    "name": "Tomahawk",
    "lng": -89.7571556966628,
    "lat": 45.46757557565412
  },
  {
    "name": "Tomahawk",
    "lng": -89.72285872546493,
    "lat": 45.47460418361319
  },
  {
    "name": "Tomball",
    "lng": -95.61941697000837,
    "lat": 30.09569982217482
  },
  {
    "name": "Tomball",
    "lng": -95.61779389800415,
    "lat": 30.048942921035938
  },
  {
    "name": "Tompkinsville",
    "lng": -85.69234981329797,
    "lat": 36.701202937457076
  },
  {
    "name": "Toms Brook",
    "lng": -78.44006596395117,
    "lat": 38.946530792487295
  },
  {
    "name": "Tonalea",
    "lng": -110.96668527505429,
    "lat": 36.32173720187898
  },
  {
    "name": "Tonasket",
    "lng": -119.46565203840724,
    "lat": 48.72465510925781
  },
  {
    "name": "Tonasket",
    "lng": -119.43724421211928,
    "lat": 48.70464777715241
  },
  {
    "name": "Tonawanda",
    "lng": -78.87979644713829,
    "lat": 43.01069649933641
  },
  {
    "name": "Tonganoxie",
    "lng": -95.08320154256967,
    "lat": 39.10995344579402
  },
  {
    "name": "Tonica",
    "lng": -89.06995607589212,
    "lat": 41.215870832029744
  },
  {
    "name": "Tonka Bay",
    "lng": -93.58960470209146,
    "lat": 44.915490741155615
  },
  {
    "name": "Tonkawa",
    "lng": -97.35051359588958,
    "lat": 36.68806958643238
  },
  {
    "name": "Tonkawa",
    "lng": -97.35042603319853,
    "lat": 36.6558327783367
  },
  {
    "name": "Tonkawa",
    "lng": -97.3503907170134,
    "lat": 36.70261894168317
  },
  {
    "name": "Tonkawa",
    "lng": -97.35012581354525,
    "lat": 36.64609624424999
  },
  {
    "name": "Tonkawa",
    "lng": -97.33800750707394,
    "lat": 36.647725404678624
  },
  {
    "name": "Tonkawa",
    "lng": -97.33434102642221,
    "lat": 36.65492128733851
  },
  {
    "name": "Tonkawa",
    "lng": -97.32220264148074,
    "lat": 36.699029259424286
  },
  {
    "name": "Tonkawa",
    "lng": -97.31816722951207,
    "lat": 36.65889748344798
  },
  {
    "name": "Tonkawa",
    "lng": -97.31358291629604,
    "lat": 36.669634093707735
  },
  {
    "name": "Tonkawa",
    "lng": -97.31126952719848,
    "lat": 36.68392713627263
  },
  {
    "name": "Tontitown",
    "lng": -94.24566505768469,
    "lat": 36.16420714968904
  },
  {
    "name": "Tontogany",
    "lng": -83.74182039213343,
    "lat": 41.42129904001609
  },
  {
    "name": "Tony",
    "lng": -90.99715781059214,
    "lat": 45.48031529755507
  },
  {
    "name": "Tooele",
    "lng": -112.30825631028705,
    "lat": 40.53940576419697
  },
  {
    "name": "Tool",
    "lng": -96.17313591131115,
    "lat": 32.28100818896108
  },
  {
    "name": "Toomsboro",
    "lng": -83.0826380663618,
    "lat": 32.822373647276265
  },
  {
    "name": "Toone",
    "lng": -88.95394377653564,
    "lat": 35.35630755755622
  },
  {
    "name": "Topeka",
    "lng": -95.78018199098977,
    "lat": 39.01403680584268
  },
  {
    "name": "Topeka",
    "lng": -95.6987497403335,
    "lat": 38.95351159786291
  },
  {
    "name": "Topeka",
    "lng": -95.69563237256794,
    "lat": 39.03912184679941
  },
  {
    "name": "Topeka",
    "lng": -95.66971640896494,
    "lat": 38.92859281119542
  },
  {
    "name": "Topeka",
    "lng": -95.63123927828296,
    "lat": 39.01554260762777
  },
  {
    "name": "Topeka",
    "lng": -95.61953328899345,
    "lat": 38.989465552384296
  },
  {
    "name": "Topeka",
    "lng": -89.93061183196055,
    "lat": 40.33036230575484
  },
  {
    "name": "Topeka",
    "lng": -85.54726565025105,
    "lat": 41.53981708606678
  },
  {
    "name": "Toppenish",
    "lng": -120.31274581262561,
    "lat": 46.380396420444605
  },
  {
    "name": "Toppenish",
    "lng": -120.283281707356,
    "lat": 46.368931492394374
  },
  {
    "name": "Topsail Beach",
    "lng": -77.63247399818421,
    "lat": 34.37408617573784
  },
  {
    "name": "Topsfield",
    "lng": -67.78478833092029,
    "lat": 45.44167445009698
  },
  {
    "name": "Topsham",
    "lng": -69.95872859968883,
    "lat": 43.96146455764195
  },
  {
    "name": "Toquerville",
    "lng": -113.2951246700251,
    "lat": 37.26658357508637
  },
  {
    "name": "Toronto",
    "lng": -96.64226451856177,
    "lat": 44.572591014971124
  },
  {
    "name": "Toronto",
    "lng": -96.63270144039546,
    "lat": 44.57059847256037
  },
  {
    "name": "Toronto",
    "lng": -95.94957382531712,
    "lat": 37.79851916312021
  },
  {
    "name": "Toronto",
    "lng": -90.86262194889092,
    "lat": 41.9028672434426
  },
  {
    "name": "Toronto",
    "lng": -80.60817000592307,
    "lat": 40.45842432589528
  },
  {
    "name": "Torrance",
    "lng": -118.3555761958395,
    "lat": 33.830993012934016
  },
  {
    "name": "Torrey",
    "lng": -111.42683451674192,
    "lat": 38.307643232064336
  },
  {
    "name": "Torrington",
    "lng": -104.16226518353716,
    "lat": 42.06582651664345
  },
  {
    "name": "Torrington",
    "lng": -73.12814725480689,
    "lat": 41.83496753489821
  },
  {
    "name": "Toulon",
    "lng": -89.86313405600474,
    "lat": 41.09336552791218
  },
  {
    "name": "Tovey",
    "lng": -89.44856586972838,
    "lat": 39.588244418142004
  },
  {
    "name": "Towanda",
    "lng": -96.99980503338612,
    "lat": 37.78749282753595
  },
  {
    "name": "Towanda",
    "lng": -96.99624971050469,
    "lat": 37.79633166978762
  },
  {
    "name": "Towanda",
    "lng": -96.98828647169631,
    "lat": 37.80752403013633
  },
  {
    "name": "Towanda",
    "lng": -88.8998986663371,
    "lat": 40.56378714953451
  },
  {
    "name": "Tower",
    "lng": -92.28376538274324,
    "lat": 47.81039753758821
  },
  {
    "name": "Tower",
    "lng": -92.2627106333014,
    "lat": 47.798820642903316
  },
  {
    "name": "Tower City",
    "lng": -97.67596129389926,
    "lat": 46.92626010956903
  },
  {
    "name": "Tower Hill",
    "lng": -88.95924933849632,
    "lat": 39.38677455179036
  },
  {
    "name": "Tower Lakes",
    "lng": -88.15407879942478,
    "lat": 42.23158264086162
  },
  {
    "name": "Town and Country",
    "lng": -90.47906334170685,
    "lat": 38.63175070958942
  },
  {
    "name": "Town of Bedford",
    "lng": -73.66783129647511,
    "lat": 41.22502942138099
  },
  {
    "name": "Town of Camillus",
    "lng": -76.3054994703834,
    "lat": 43.05391361927158
  },
  {
    "name": "Town of Cicero",
    "lng": -76.06609468475077,
    "lat": 43.16633631497902
  },
  {
    "name": "Town of Clay",
    "lng": -76.19554104178671,
    "lat": 43.18099883002295
  },
  {
    "name": "Town of Colonie",
    "lng": -73.78601189797392,
    "lat": 42.73977582909614
  },
  {
    "name": "Town of Davenport",
    "lng": -74.90211250018325,
    "lat": 42.454949597052554
  },
  {
    "name": "Town of DeWitt",
    "lng": -76.07118974468239,
    "lat": 43.050379063435045
  },
  {
    "name": "Town of Decatur",
    "lng": -74.70376112047789,
    "lat": 42.66194307150682
  },
  {
    "name": "Town of Eastchester",
    "lng": -73.81339088716301,
    "lat": 40.95370503621651
  },
  {
    "name": "Town of Elbridge",
    "lng": -76.43882554317219,
    "lat": 43.0534491421757
  },
  {
    "name": "Town of Fabius",
    "lng": -75.99116029697818,
    "lat": 42.82193582072034
  },
  {
    "name": "Town of Franklin",
    "lng": -75.1111698492496,
    "lat": 42.33355499716354
  },
  {
    "name": "Town of Geddes",
    "lng": -76.22513308009015,
    "lat": 43.07627371674037
  },
  {
    "name": "Town of Greenburgh",
    "lng": -73.84064873409316,
    "lat": 41.03286884013263
  },
  {
    "name": "Town of Harpersfield",
    "lng": -74.69982300247231,
    "lat": 42.45180696541546
  },
  {
    "name": "Town of Harrison",
    "lng": -73.71924838782392,
    "lat": 41.02333034703862
  },
  {
    "name": "Town of Kortright",
    "lng": -74.7825320294114,
    "lat": 42.39147270954979
  },
  {
    "name": "Town of LaFayette",
    "lng": -76.10265338570149,
    "lat": 42.90594069658371
  },
  {
    "name": "Town of Lewisboro",
    "lng": -73.58284004534897,
    "lat": 41.269737439045656
  },
  {
    "name": "Town of Locke",
    "lng": -76.41347286375547,
    "lat": 42.656818017136786
  },
  {
    "name": "Town of Lysander",
    "lng": -76.3748425051688,
    "lat": 43.17977892358676
  },
  {
    "name": "Town of Mamaroneck",
    "lng": -73.74061831854041,
    "lat": 40.92523080819363
  },
  {
    "name": "Town of Manlius",
    "lng": -75.97933808784944,
    "lat": 43.04896824580826
  },
  {
    "name": "Town of Marcellus",
    "lng": -76.32546755578126,
    "lat": 42.95397032959967
  },
  {
    "name": "Town of Maryland",
    "lng": -74.85796571755128,
    "lat": 42.54245213616597
  },
  {
    "name": "Town of Meredith",
    "lng": -74.9434455025816,
    "lat": 42.364709547531405
  },
  {
    "name": "Town of Milford",
    "lng": -74.97792268731999,
    "lat": 42.54632591066562
  },
  {
    "name": "Town of Moravia",
    "lng": -76.41883079779761,
    "lat": 42.73785220007428
  },
  {
    "name": "Town of Mount Pleasant",
    "lng": -73.81143248932555,
    "lat": 41.11210726099592
  },
  {
    "name": "Town of New Castle",
    "lng": -73.77243749981628,
    "lat": 41.18406496942344
  },
  {
    "name": "Town of North Castle",
    "lng": -73.69485440983964,
    "lat": 41.1331320178438
  },
  {
    "name": "Town of Oneonta",
    "lng": -75.06656659969626,
    "lat": 42.46568869909146
  },
  {
    "name": "Town of Onondaga",
    "lng": -76.21682083933337,
    "lat": 42.968594192025684
  },
  {
    "name": "Town of Ossining",
    "lng": -73.85762002252564,
    "lat": 41.15541827353713
  },
  {
    "name": "Town of Otisco",
    "lng": -76.22263353046112,
    "lat": 42.864762814178434
  },
  {
    "name": "Town of Pelham",
    "lng": -73.80636890584707,
    "lat": 40.90003133407521
  },
  {
    "name": "Town of Pines",
    "lng": -86.95176048079767,
    "lat": 41.68819274316816
  },
  {
    "name": "Town of Pompey",
    "lng": -75.98026475266619,
    "lat": 42.922856711565025
  },
  {
    "name": "Town of Pound Ridge",
    "lng": -73.57464707603337,
    "lat": 41.21153973829416
  },
  {
    "name": "Town of Richmondville",
    "lng": -74.54860521586036,
    "lat": 42.638764171922006
  },
  {
    "name": "Town of Rye",
    "lng": -73.72344563698005,
    "lat": 40.950992264777994
  },
  {
    "name": "Town of Rye",
    "lng": -73.68630503864476,
    "lat": 41.03016102624568
  },
  {
    "name": "Town of Salina",
    "lng": -76.17665852775296,
    "lat": 43.10253419161381
  },
  {
    "name": "Town of Skaneateles",
    "lng": -76.41296348881406,
    "lat": 42.93195374556758
  },
  {
    "name": "Town of Spafford",
    "lng": -76.29051291832256,
    "lat": 42.83468968439383
  },
  {
    "name": "Town of Tully",
    "lng": -76.1350877802262,
    "lat": 42.81137131891455
  },
  {
    "name": "Town of Van Buren",
    "lng": -76.35913446801113,
    "lat": 43.12111453464649
  },
  {
    "name": "Town of Worcester",
    "lng": -74.71419004669417,
    "lat": 42.58337285352606
  },
  {
    "name": "Towner",
    "lng": -100.4069416942709,
    "lat": 48.347059096050714
  },
  {
    "name": "Townsend",
    "lng": -111.51975537596778,
    "lat": 46.31874232726019
  },
  {
    "name": "Townsend",
    "lng": -83.75406890203159,
    "lat": 35.67393753091833
  },
  {
    "name": "Townsend",
    "lng": -71.71158185052312,
    "lat": 42.6669725790837
  },
  {
    "name": "Township of Spring Arbor",
    "lng": -84.53955767062489,
    "lat": 42.204025654271454
  },
  {
    "name": "Toxey",
    "lng": -88.30915269335053,
    "lat": 31.910700740830006
  },
  {
    "name": "Toyah",
    "lng": -103.79461298435918,
    "lat": 31.312504854813525
  },
  {
    "name": "Tracy",
    "lng": -121.44445561151939,
    "lat": 37.72435960321947
  },
  {
    "name": "Tracy",
    "lng": -121.43752919409107,
    "lat": 37.67985756169312
  },
  {
    "name": "Tracy",
    "lng": -95.64179471204663,
    "lat": 44.24289443198356
  },
  {
    "name": "Tracy",
    "lng": -95.63454942706036,
    "lat": 44.239060836094346
  },
  {
    "name": "Tracy",
    "lng": -95.61508380255569,
    "lat": 44.23894496488575
  },
  {
    "name": "Tracy",
    "lng": -94.79406109360777,
    "lat": 39.37823379588496
  },
  {
    "name": "Tracy City",
    "lng": -85.75176285495775,
    "lat": 35.26198908668526
  },
  {
    "name": "Traer",
    "lng": -92.4640689040587,
    "lat": 42.189637880481435
  },
  {
    "name": "Trafalgar",
    "lng": -86.14961665348432,
    "lat": 39.41324260879224
  },
  {
    "name": "Trafford",
    "lng": -86.76064156438525,
    "lat": 33.83719952229853
  },
  {
    "name": "Trafford",
    "lng": -86.74941695614278,
    "lat": 33.820514416517774
  },
  {
    "name": "Trail",
    "lng": -95.6980024947114,
    "lat": 47.783287209277276
  },
  {
    "name": "Trail Creek",
    "lng": -86.85548916929315,
    "lat": 41.69634266908331
  },
  {
    "name": "Trappe",
    "lng": -76.05154833527828,
    "lat": 38.66311654952107
  },
  {
    "name": "Traskwood",
    "lng": -92.66425672256923,
    "lat": 34.45333370755552
  },
  {
    "name": "Travelers Rest",
    "lng": -82.44051808434551,
    "lat": 34.957659651860695
  },
  {
    "name": "Traverse City",
    "lng": -85.6036419110732,
    "lat": 44.75500145569182
  },
  {
    "name": "Traverse City",
    "lng": -85.58749103786461,
    "lat": 44.71893207672398
  },
  {
    "name": "Treasure Island",
    "lng": -82.7689611423427,
    "lat": 27.766782008673303
  },
  {
    "name": "Tremont",
    "lng": -89.49060785280422,
    "lat": 40.5248518132458
  },
  {
    "name": "Tremont",
    "lng": -88.25075618533589,
    "lat": 34.23270505630196
  },
  {
    "name": "Tremont",
    "lng": -68.40631562178253,
    "lat": 44.25894676488421
  },
  {
    "name": "Tremont City",
    "lng": -83.83840199057589,
    "lat": 40.01465453515123
  },
  {
    "name": "Tremonton",
    "lng": -112.19742078403968,
    "lat": 41.726605634035366
  },
  {
    "name": "Tremonton",
    "lng": -112.18900018749258,
    "lat": 41.718707896828874
  },
  {
    "name": "Trempealeau",
    "lng": -91.43097706333927,
    "lat": 44.003447092990434
  },
  {
    "name": "Trempealeau",
    "lng": -91.41889235114934,
    "lat": 44.008217544300166
  },
  {
    "name": "Trent",
    "lng": -100.12323388589576,
    "lat": 32.48849448922865
  },
  {
    "name": "Trent",
    "lng": -96.65781258975372,
    "lat": 43.90626476901453
  },
  {
    "name": "Trent Woods",
    "lng": -77.09363027136764,
    "lat": 35.0811889763766
  },
  {
    "name": "Trenton",
    "lng": -111.9356842449153,
    "lat": 41.91151587206237
  },
  {
    "name": "Trenton",
    "lng": -101.01357349070426,
    "lat": 40.17466918685585
  },
  {
    "name": "Trenton",
    "lng": -96.34250559765408,
    "lat": 33.4259491188662
  },
  {
    "name": "Trenton",
    "lng": -96.32146087787044,
    "lat": 33.437377412599055
  },
  {
    "name": "Trenton",
    "lng": -93.60371631151968,
    "lat": 40.082327885438836
  },
  {
    "name": "Trenton",
    "lng": -89.6844777708368,
    "lat": 38.60704845642267
  },
  {
    "name": "Trenton",
    "lng": -88.94569968472018,
    "lat": 35.96853801240375
  },
  {
    "name": "Trenton",
    "lng": -87.26343477332344,
    "lat": 36.72232899841001
  },
  {
    "name": "Trenton",
    "lng": -85.50965453492192,
    "lat": 34.87411162087355
  },
  {
    "name": "Trenton",
    "lng": -85.49705921088567,
    "lat": 34.8849190024602
  },
  {
    "name": "Trenton",
    "lng": -84.46177828007097,
    "lat": 39.47928940697951
  },
  {
    "name": "Trenton",
    "lng": -83.19288786172264,
    "lat": 42.1396283930255
  },
  {
    "name": "Trenton",
    "lng": -82.81453023586272,
    "lat": 29.609175582445875
  },
  {
    "name": "Trenton",
    "lng": -81.84043062032822,
    "lat": 33.74021091268654
  },
  {
    "name": "Trenton",
    "lng": -77.35603410141427,
    "lat": 35.06806047540752
  },
  {
    "name": "Trenton",
    "lng": -77.35588252499157,
    "lat": 35.066392835054636
  },
  {
    "name": "Trenton",
    "lng": -77.35559821969927,
    "lat": 35.06736131061429
  },
  {
    "name": "Trenton",
    "lng": -77.35526298493303,
    "lat": 35.06385851295448
  },
  {
    "name": "Trenton",
    "lng": -77.3552221810066,
    "lat": 35.06672893856511
  },
  {
    "name": "Trenton",
    "lng": -77.3550281535453,
    "lat": 35.067277305263076
  },
  {
    "name": "Trenton",
    "lng": -74.76416792625697,
    "lat": 40.223743120973204
  },
  {
    "name": "Trenton",
    "lng": -68.39969912505985,
    "lat": 44.443126289339524
  },
  {
    "name": "Trestle Trail",
    "lng": -71.774211270511,
    "lat": 41.69357165452234
  },
  {
    "name": "Trestle Trail",
    "lng": -71.75471998440958,
    "lat": 41.68971116632801
  },
  {
    "name": "Trestle Trail",
    "lng": -71.71937488679781,
    "lat": 41.689798878555244
  },
  {
    "name": "Trestle Trail",
    "lng": -71.66280071330718,
    "lat": 41.693143922739296
  },
  {
    "name": "Trestle Trail",
    "lng": -71.62286839027487,
    "lat": 41.70332118705969
  },
  {
    "name": "Treynor",
    "lng": -95.6065927232713,
    "lat": 41.23219319655286
  },
  {
    "name": "Trezevant",
    "lng": -88.62072059965107,
    "lat": 36.01104336244559
  },
  {
    "name": "Tri-Lakes",
    "lng": -85.44332201571433,
    "lat": 41.25072198605866
  },
  {
    "name": "Triadelphia",
    "lng": -80.6227158997795,
    "lat": 40.048320535419286
  },
  {
    "name": "Triana",
    "lng": -86.75835925045725,
    "lat": 34.59548974214683
  },
  {
    "name": "Triana",
    "lng": -86.74166272265583,
    "lat": 34.59029704133458
  },
  {
    "name": "Tribbey",
    "lng": -97.09372176151106,
    "lat": 35.09919871566186
  },
  {
    "name": "Tribune",
    "lng": -101.75455979198593,
    "lat": 38.47197090438499
  },
  {
    "name": "Trimble",
    "lng": -94.5611651815176,
    "lat": 39.47623686396064
  },
  {
    "name": "Trimble",
    "lng": -82.08003981641244,
    "lat": 39.48581926058638
  },
  {
    "name": "Trimont",
    "lng": -94.71619819261903,
    "lat": 43.76104558515578
  },
  {
    "name": "Trinidad",
    "lng": -124.14326948580938,
    "lat": 41.057563444876614
  },
  {
    "name": "Trinidad",
    "lng": -104.49061395149083,
    "lat": 37.175042007569985
  },
  {
    "name": "Trinidad",
    "lng": -96.10583028780205,
    "lat": 32.15700536361559
  },
  {
    "name": "Trinity",
    "lng": -95.37354226269296,
    "lat": 30.94446087555112
  },
  {
    "name": "Trinity",
    "lng": -87.08408638232716,
    "lat": 34.60071150086646
  },
  {
    "name": "Trinity",
    "lng": -87.0734822398859,
    "lat": 34.57495460713604
  },
  {
    "name": "Trinity",
    "lng": -87.05183340715645,
    "lat": 34.59869972808841
  },
  {
    "name": "Trinity",
    "lng": -82.65825708800433,
    "lat": 28.180844665689143
  },
  {
    "name": "Trinity",
    "lng": -80.00931243369601,
    "lat": 35.87560917863282
  },
  {
    "name": "Trion",
    "lng": -85.31134857071578,
    "lat": 34.54788038163002
  },
  {
    "name": "Triplett",
    "lng": -93.19385951513664,
    "lat": 39.49835480226201
  },
  {
    "name": "Tripoli",
    "lng": -92.25795645164342,
    "lat": 42.808396397484046
  },
  {
    "name": "Tripp",
    "lng": -97.96566132566281,
    "lat": 43.22529492927463
  },
  {
    "name": "Trommald",
    "lng": -94.01555618035842,
    "lat": 46.50568115349129
  },
  {
    "name": "Trophy Club",
    "lng": -97.19005160348216,
    "lat": 33.00419508983177
  },
  {
    "name": "Tropic",
    "lng": -112.09138135705663,
    "lat": 37.62941673391694
  },
  {
    "name": "Trosky",
    "lng": -96.25778770178414,
    "lat": 43.89150628600321
  },
  {
    "name": "Trosky",
    "lng": -96.24075681921286,
    "lat": 43.8786399493516
  },
  {
    "name": "Trotwood",
    "lng": -84.31778297994823,
    "lat": 39.79331840062886
  },
  {
    "name": "Trotwood",
    "lng": -84.26927346388109,
    "lat": 39.76800060413557
  },
  {
    "name": "Trotwood",
    "lng": -84.26792534628984,
    "lat": 39.775704912562645
  },
  {
    "name": "Trotwood",
    "lng": -84.25594955879626,
    "lat": 39.77615601843741
  },
  {
    "name": "Troup",
    "lng": -95.1223647999331,
    "lat": 32.14496886166662
  },
  {
    "name": "Trout Valley",
    "lng": -88.253768405768,
    "lat": 42.19784216210414
  },
  {
    "name": "Troutdale",
    "lng": -81.4413331711727,
    "lat": 36.70224259929665
  },
  {
    "name": "Troutman",
    "lng": -80.89450262731395,
    "lat": 35.70427355498837
  },
  {
    "name": "Troutville",
    "lng": -79.87804914374381,
    "lat": 37.4167153087428
  },
  {
    "name": "Troy",
    "lng": -116.77315324761439,
    "lat": 46.7379810043908
  },
  {
    "name": "Troy",
    "lng": -115.90481427670507,
    "lat": 48.47984131888934
  },
  {
    "name": "Troy",
    "lng": -115.89046077114307,
    "lat": 48.45992767503477
  },
  {
    "name": "Troy",
    "lng": -115.87835816423136,
    "lat": 48.44780734424305
  },
  {
    "name": "Troy",
    "lng": -97.3019155143604,
    "lat": 31.19745001751864
  },
  {
    "name": "Troy",
    "lng": -95.14442008772211,
    "lat": 39.81853382696329
  },
  {
    "name": "Troy",
    "lng": -95.10115503110595,
    "lat": 39.7922474918735
  },
  {
    "name": "Troy",
    "lng": -95.10097813192296,
    "lat": 39.81890354038339
  },
  {
    "name": "Troy",
    "lng": -95.09400147838996,
    "lat": 39.78408330622524
  },
  {
    "name": "Troy",
    "lng": -95.08308535000094,
    "lat": 39.79257995042184
  },
  {
    "name": "Troy",
    "lng": -90.97200963466658,
    "lat": 38.97161766719791
  },
  {
    "name": "Troy",
    "lng": -89.91739386069656,
    "lat": 38.70978877600367
  },
  {
    "name": "Troy",
    "lng": -89.90845347701875,
    "lat": 38.703933802988715
  },
  {
    "name": "Troy",
    "lng": -89.89639358822762,
    "lat": 38.72863418020022
  },
  {
    "name": "Troy",
    "lng": -89.86812054296698,
    "lat": 38.724329148221834
  },
  {
    "name": "Troy",
    "lng": -89.15805182962848,
    "lat": 36.34204709327949
  },
  {
    "name": "Troy",
    "lng": -86.80062766772646,
    "lat": 37.996018657324626
  },
  {
    "name": "Troy",
    "lng": -85.96563225768064,
    "lat": 31.80179166922915
  },
  {
    "name": "Troy",
    "lng": -85.95664982404693,
    "lat": 31.76401395584423
  },
  {
    "name": "Troy",
    "lng": -84.21862248512004,
    "lat": 40.04282774940511
  },
  {
    "name": "Troy",
    "lng": -83.14575869490051,
    "lat": 42.58175781941707
  },
  {
    "name": "Troy",
    "lng": -82.29742612760042,
    "lat": 33.98801929091741
  },
  {
    "name": "Troy",
    "lng": -79.90961503259798,
    "lat": 35.35771808474613
  },
  {
    "name": "Troy",
    "lng": -79.89936858518047,
    "lat": 35.350520501436094
  },
  {
    "name": "Troy",
    "lng": -79.8990420161915,
    "lat": 35.3499588323377
  },
  {
    "name": "Troy",
    "lng": -79.89880119648531,
    "lat": 35.3505158959471
  },
  {
    "name": "Troy",
    "lng": -79.89811591571508,
    "lat": 35.34991677361891
  },
  {
    "name": "Troy",
    "lng": -79.8979719651867,
    "lat": 35.3505109336316
  },
  {
    "name": "Troy",
    "lng": -79.8973159532083,
    "lat": 35.3493028960818
  },
  {
    "name": "Troy",
    "lng": -79.89699125384222,
    "lat": 35.350465511238546
  },
  {
    "name": "Troy",
    "lng": -79.89646738302628,
    "lat": 35.35096263408126
  },
  {
    "name": "Troy",
    "lng": -79.89633337936075,
    "lat": 35.349497325536106
  },
  {
    "name": "Troy",
    "lng": -79.89229081961065,
    "lat": 35.36483578659397
  },
  {
    "name": "Troy",
    "lng": -79.88157206806237,
    "lat": 35.38300767049133
  },
  {
    "name": "Troy",
    "lng": -79.86120519281816,
    "lat": 35.375647130042786
  },
  {
    "name": "Troy",
    "lng": -76.78948702057825,
    "lat": 41.78380113517615
  },
  {
    "name": "Troy",
    "lng": -69.25359845254604,
    "lat": 44.67917512903733
  },
  {
    "name": "Troy Grove",
    "lng": -89.08144684195818,
    "lat": 41.46581505288489
  },
  {
    "name": "Truckee",
    "lng": -120.1846054476803,
    "lat": 39.345335961774694
  },
  {
    "name": "Truesdale",
    "lng": -95.1835871279235,
    "lat": 42.729270328763334
  },
  {
    "name": "Truesdale",
    "lng": -91.12196061337019,
    "lat": 38.812408793615816
  },
  {
    "name": "Truman",
    "lng": -94.43660699390095,
    "lat": 43.827846458440995
  },
  {
    "name": "Trumann",
    "lng": -90.52506090255972,
    "lat": 35.67717548338465
  },
  {
    "name": "Trumansburg",
    "lng": -76.66165873246747,
    "lat": 42.54090010644527
  },
  {
    "name": "Trumbull",
    "lng": -98.27340320215289,
    "lat": 40.68012831841416
  },
  {
    "name": "Trumbull",
    "lng": -73.20817614703071,
    "lat": 41.26006542065156
  },
  {
    "name": "Truro",
    "lng": -93.84673424085547,
    "lat": 41.209785081980236
  },
  {
    "name": "Trussville",
    "lng": -86.61991956360181,
    "lat": 33.58703096746304
  },
  {
    "name": "Trussville",
    "lng": -86.60796711752204,
    "lat": 33.69757266211984
  },
  {
    "name": "Trussville",
    "lng": -86.59240433333335,
    "lat": 33.609179666259685
  },
  {
    "name": "Trussville",
    "lng": -86.58693258479482,
    "lat": 33.60108149891532
  },
  {
    "name": "Trussville",
    "lng": -86.5869325847948,
    "lat": 33.60108149891532
  },
  {
    "name": "Trussville",
    "lng": -86.58383658978231,
    "lat": 33.659990640039425
  },
  {
    "name": "Trussville",
    "lng": -86.58367233431909,
    "lat": 33.63822011873392
  },
  {
    "name": "Trussville",
    "lng": -86.57664944836473,
    "lat": 33.59122099496964
  },
  {
    "name": "Trussville",
    "lng": -86.56354754429695,
    "lat": 33.665034882167994
  },
  {
    "name": "Trussville",
    "lng": -86.55493673421799,
    "lat": 33.626628755238535
  },
  {
    "name": "Trussville",
    "lng": -86.53492490826848,
    "lat": 33.68681557599577
  },
  {
    "name": "Trussville",
    "lng": -86.53309889345213,
    "lat": 33.71159540791861
  },
  {
    "name": "Truth or Consequences",
    "lng": -107.2566366304288,
    "lat": 33.17245928382311
  },
  {
    "name": "Truxton",
    "lng": -91.24033379336818,
    "lat": 39.0035058610452
  },
  {
    "name": "Truxton Town",
    "lng": -76.02681937251528,
    "lat": 42.71088662850007
  },
  {
    "name": "Tryon",
    "lng": -96.96546469766189,
    "lat": 35.8817026466983
  },
  {
    "name": "Tryon",
    "lng": -82.2381269232478,
    "lat": 35.20809172541112
  },
  {
    "name": "Tuba City",
    "lng": -111.24673609452456,
    "lat": 36.12502460150954
  },
  {
    "name": "Tuckahoe",
    "lng": -73.82301022080459,
    "lat": 40.95310307567874
  },
  {
    "name": "Tucker",
    "lng": -84.22258191397874,
    "lat": 33.853493434250844
  },
  {
    "name": "Tucker",
    "lng": -84.20202072681539,
    "lat": 33.843632508039576
  },
  {
    "name": "Tuckerman",
    "lng": -91.20158882119932,
    "lat": 35.72593547582538
  },
  {
    "name": "Tucson",
    "lng": -110.8771473212775,
    "lat": 32.15689286743667
  },
  {
    "name": "Tukwila",
    "lng": -122.27280501435158,
    "lat": 47.47718697204903
  },
  {
    "name": "Tulare",
    "lng": -119.34016474054218,
    "lat": 36.20060192960826
  },
  {
    "name": "Tulare",
    "lng": -98.50893179634352,
    "lat": 44.7382207923168
  },
  {
    "name": "Tularosa",
    "lng": -106.01751872934696,
    "lat": 33.07524741689007
  },
  {
    "name": "Tulelake",
    "lng": -121.47484880212704,
    "lat": 41.95287729616168
  },
  {
    "name": "Tull",
    "lng": -92.57783456926583,
    "lat": 34.440907958967756
  },
  {
    "name": "Tullahassee",
    "lng": -95.43878633384142,
    "lat": 35.84204718293863
  },
  {
    "name": "Tullahoma",
    "lng": -86.2158110347561,
    "lat": 35.37140972254918
  },
  {
    "name": "Tullos",
    "lng": -92.32932876994138,
    "lat": 31.81878403303375
  },
  {
    "name": "Tullos",
    "lng": -92.31900442868246,
    "lat": 31.823322827837508
  },
  {
    "name": "Tully",
    "lng": -76.10906595172204,
    "lat": 42.797629614138984
  },
  {
    "name": "Tulsa",
    "lng": -95.90375994376816,
    "lat": 36.1282208360781
  },
  {
    "name": "Tulsa",
    "lng": -95.88428934298966,
    "lat": 36.28340451509987
  },
  {
    "name": "Tulsa",
    "lng": -95.83310596356733,
    "lat": 36.247315314202524
  },
  {
    "name": "Tulsa",
    "lng": -95.78428614863743,
    "lat": 36.24761403079559
  },
  {
    "name": "Tulsa",
    "lng": -95.75655837734053,
    "lat": 36.20564591103979
  },
  {
    "name": "Tumwater",
    "lng": -122.91732425780637,
    "lat": 46.9893145344088
  },
  {
    "name": "Tunica",
    "lng": -90.38093681937842,
    "lat": 34.6881372315126
  },
  {
    "name": "Tunnel Hill",
    "lng": -85.04057125148734,
    "lat": 34.848143042742315
  },
  {
    "name": "Tunnelton",
    "lng": -79.7465782975332,
    "lat": 39.39288611976698
  },
  {
    "name": "Tupelo",
    "lng": -96.420400091213,
    "lat": 34.60243195462134
  },
  {
    "name": "Tupelo",
    "lng": -91.22990409034344,
    "lat": 35.39033266187333
  },
  {
    "name": "Tupelo",
    "lng": -88.72982143323246,
    "lat": 34.26382508610266
  },
  {
    "name": "Tupper Lake",
    "lng": -74.46224274655576,
    "lat": 44.23248450914191
  },
  {
    "name": "Turbeville",
    "lng": -80.01616693315955,
    "lat": 33.88979443230447
  },
  {
    "name": "Turin",
    "lng": -95.9661736353079,
    "lat": 42.020882061581275
  },
  {
    "name": "Turin",
    "lng": -84.63708829470383,
    "lat": 33.32596082758189
  },
  {
    "name": "Turin",
    "lng": -75.41067234970052,
    "lat": 43.629179473394835
  },
  {
    "name": "Turkey",
    "lng": -100.89534083179544,
    "lat": 34.393903418830334
  },
  {
    "name": "Turkey",
    "lng": -100.88666207943659,
    "lat": 34.398451237916554
  },
  {
    "name": "Turkey",
    "lng": -78.1840630887539,
    "lat": 34.99457028606877
  },
  {
    "name": "Turkey Creek",
    "lng": -92.4169819787927,
    "lat": 30.87323892500631
  },
  {
    "name": "Turlock",
    "lng": -120.85455504448643,
    "lat": 37.50704450501269
  },
  {
    "name": "Turner",
    "lng": -83.78751443399952,
    "lat": 44.1421766631509
  },
  {
    "name": "Turner",
    "lng": -70.24292767195597,
    "lat": 44.26714738116235
  },
  {
    "name": "Turney",
    "lng": -94.32026052143641,
    "lat": 39.63866958982704
  },
  {
    "name": "Turon",
    "lng": -98.4279882183618,
    "lat": 37.807556208672516
  },
  {
    "name": "Turrell",
    "lng": -90.26987581356269,
    "lat": 35.37552083096265
  },
  {
    "name": "Turtle Lake",
    "lng": -100.89079078970114,
    "lat": 47.52157297379461
  },
  {
    "name": "Turtle Lake",
    "lng": -92.14512402850694,
    "lat": 45.39337145930817
  },
  {
    "name": "Turtle River",
    "lng": -94.75827444265835,
    "lat": 47.58833942605605
  },
  {
    "name": "Turton",
    "lng": -98.09740074148114,
    "lat": 45.04896199393602
  },
  {
    "name": "Tusayan",
    "lng": -112.13458836957221,
    "lat": 35.97648459610698
  },
  {
    "name": "Tuscaloosa",
    "lng": -87.52746960190214,
    "lat": 33.235501934363015
  },
  {
    "name": "Tuscarawas",
    "lng": -81.40105035993795,
    "lat": 40.395527622056896
  },
  {
    "name": "Tuscarawas",
    "lng": -81.39735649235489,
    "lat": 40.381261074824394
  },
  {
    "name": "Tuscola",
    "lng": -88.27497344417475,
    "lat": 39.796783167367394
  },
  {
    "name": "Tusculum",
    "lng": -82.7457060449845,
    "lat": 36.17449839562856
  },
  {
    "name": "Tuscumbia",
    "lng": -92.460038222957,
    "lat": 38.23705615752039
  },
  {
    "name": "Tuscumbia",
    "lng": -87.75051288934333,
    "lat": 34.7275632802079
  },
  {
    "name": "Tuscumbia",
    "lng": -87.7032849051468,
    "lat": 34.72132295935213
  },
  {
    "name": "Tushka",
    "lng": -96.16724341421623,
    "lat": 34.31801732682704
  },
  {
    "name": "Tuskegee",
    "lng": -85.71359863542091,
    "lat": 32.439715372727015
  },
  {
    "name": "Tustin",
    "lng": -117.82737828095597,
    "lat": 33.77079287511023
  },
  {
    "name": "Tustin",
    "lng": -117.81948290318672,
    "lat": 33.766315531832255
  },
  {
    "name": "Tustin",
    "lng": -117.81774497256183,
    "lat": 33.7503844397467
  },
  {
    "name": "Tustin",
    "lng": -117.81048894855284,
    "lat": 33.73097416792926
  },
  {
    "name": "Tustin",
    "lng": -117.80876018800569,
    "lat": 33.75920936404613
  },
  {
    "name": "Tustin",
    "lng": -117.8048105938615,
    "lat": 33.77767225844606
  },
  {
    "name": "Tustin",
    "lng": -117.79670073350096,
    "lat": 33.76912224283447
  },
  {
    "name": "Tustin",
    "lng": -117.79361930110261,
    "lat": 33.769176807385584
  },
  {
    "name": "Tustin",
    "lng": -117.78271705901429,
    "lat": 33.75309172059211
  },
  {
    "name": "Tustin",
    "lng": -85.45895852903473,
    "lat": 44.10081114226931
  },
  {
    "name": "Tuttle",
    "lng": -99.9934070091635,
    "lat": 47.144526253220334
  },
  {
    "name": "Tuttle",
    "lng": -97.75641745222583,
    "lat": 35.30709305654957
  },
  {
    "name": "Tutwiler",
    "lng": -90.43009060408811,
    "lat": 34.0121173697896
  },
  {
    "name": "Tuxedo Park",
    "lng": -74.20596991799837,
    "lat": 41.20078667893943
  },
  {
    "name": "Twentymile",
    "lng": -81.41911631448875,
    "lat": 30.125889785405676
  },
  {
    "name": "Twentynine Palms",
    "lng": -116.06545868818243,
    "lat": 34.14846272921746
  },
  {
    "name": "Twin",
    "lng": -87.85739774462073,
    "lat": 33.96924489458136
  },
  {
    "name": "Twin",
    "lng": -87.8573977446207,
    "lat": 33.969244894581344
  },
  {
    "name": "Twin",
    "lng": -87.85714085741392,
    "lat": 33.99505581353671
  },
  {
    "name": "Twin",
    "lng": -87.85500363012366,
    "lat": 34.01051878572545
  },
  {
    "name": "Twin",
    "lng": -87.85500363012366,
    "lat": 34.01051878572547
  },
  {
    "name": "Twin",
    "lng": -87.85306203794534,
    "lat": 33.96435219058054
  },
  {
    "name": "Twin",
    "lng": -87.85068730257895,
    "lat": 33.987424381010726
  },
  {
    "name": "Twin",
    "lng": -87.85068730257893,
    "lat": 33.98742438101072
  },
  {
    "name": "Twin",
    "lng": -87.84361977928175,
    "lat": 33.98204164793019
  },
  {
    "name": "Twin",
    "lng": -87.84355432403754,
    "lat": 34.02581939715351
  },
  {
    "name": "Twin",
    "lng": -87.8415167140278,
    "lat": 33.970917775275595
  },
  {
    "name": "Twin",
    "lng": -87.83735063185014,
    "lat": 34.033486441682136
  },
  {
    "name": "Twin",
    "lng": -87.83509021893583,
    "lat": 33.96379327286162
  },
  {
    "name": "Twin",
    "lng": -87.83509021893583,
    "lat": 33.96379327286164
  },
  {
    "name": "Twin",
    "lng": -87.82931243067978,
    "lat": 34.03145438129571
  },
  {
    "name": "Twin Bridges",
    "lng": -112.3336425987093,
    "lat": 45.54270642283544
  },
  {
    "name": "Twin Brooks",
    "lng": -96.78550580094812,
    "lat": 45.20897464877513
  },
  {
    "name": "Twin City",
    "lng": -82.15756460707891,
    "lat": 32.58277370438296
  },
  {
    "name": "Twin Falls",
    "lng": -114.4617448809784,
    "lat": 42.56475950346566
  },
  {
    "name": "Twin Groves",
    "lng": -92.45025990729181,
    "lat": 35.199901334310994
  },
  {
    "name": "Twin Groves",
    "lng": -92.42530697959737,
    "lat": 35.3187318783898
  },
  {
    "name": "Twin Lakes",
    "lng": -93.42352940496134,
    "lat": 43.56092579094315
  },
  {
    "name": "Twin Lakes",
    "lng": -88.24946736327304,
    "lat": 42.51890457430006
  },
  {
    "name": "Twin Lakes",
    "lng": -88.22507318328132,
    "lat": 42.5343111184594
  },
  {
    "name": "Twin Oaks",
    "lng": -90.50090506598066,
    "lat": 38.56575906461757
  },
  {
    "name": "Twin Oaks",
    "lng": -75.42717535665386,
    "lat": 39.84605426253058
  },
  {
    "name": "Twin Valley",
    "lng": -96.25920154516274,
    "lat": 47.25885863190791
  },
  {
    "name": "Twining",
    "lng": -83.8073932613454,
    "lat": 44.1130693697349
  },
  {
    "name": "Twinsburg",
    "lng": -81.44509379315312,
    "lat": 41.3219652833044
  },
  {
    "name": "Twisp",
    "lng": -120.11922360951027,
    "lat": 48.362837512104925
  },
  {
    "name": "Twisp",
    "lng": -120.09431690278544,
    "lat": 48.35048475707002
  },
  {
    "name": "Two Buttes",
    "lng": -102.39653647034396,
    "lat": 37.560667372719024
  },
  {
    "name": "Two Harbors",
    "lng": -91.67504205016297,
    "lat": 47.030074800567654
  },
  {
    "name": "Two Rivers",
    "lng": -87.5821498190949,
    "lat": 44.156721250079976
  },
  {
    "name": "Ty Ty",
    "lng": -83.64978677268678,
    "lat": 31.472398254581773
  },
  {
    "name": "Tybee Island",
    "lng": -80.85250907315735,
    "lat": 32.0104237092301
  },
  {
    "name": "Tyler",
    "lng": -96.1329877835972,
    "lat": 44.2761283130729
  },
  {
    "name": "Tyler",
    "lng": -95.30664305235804,
    "lat": 32.31822621009575
  },
  {
    "name": "Tylertown",
    "lng": -90.14442562332121,
    "lat": 31.117321783559635
  },
  {
    "name": "Tymochtee",
    "lng": -83.27226856650888,
    "lat": 40.94821510429044
  },
  {
    "name": "Tyndall",
    "lng": -97.86422316783809,
    "lat": 42.989870975913334
  },
  {
    "name": "Tyngsborough",
    "lng": -71.42905114807596,
    "lat": 42.666131473341025
  },
  {
    "name": "Tyringham",
    "lng": -73.19759040529175,
    "lat": 42.247350689506426
  },
  {
    "name": "Tyro",
    "lng": -95.8213009682475,
    "lat": 37.03725053274961
  },
  {
    "name": "Tyrone",
    "lng": -101.06794767442132,
    "lat": 36.955610545490735
  },
  {
    "name": "Tyrone",
    "lng": -84.59402473531733,
    "lat": 33.47536059843136
  },
  {
    "name": "Tyrone",
    "lng": -78.24604337143647,
    "lat": 40.67646063129501
  },
  {
    "name": "Tyronza",
    "lng": -90.35599900205669,
    "lat": 35.48676115088083
  },
  {
    "name": "Tysons",
    "lng": -77.23145287887654,
    "lat": 38.92028435444947
  },
  {
    "name": "Ubly",
    "lng": -82.93587709288487,
    "lat": 43.70778619954914
  },
  {
    "name": "Ucon",
    "lng": -111.95935707789155,
    "lat": 43.59353808919899
  },
  {
    "name": "Udall",
    "lng": -97.11753086101098,
    "lat": 37.389199880846895
  },
  {
    "name": "Udall",
    "lng": -97.10801444343626,
    "lat": 37.39365155016273
  },
  {
    "name": "Udell",
    "lng": -92.74324852695804,
    "lat": 40.78004246929266
  },
  {
    "name": "Uehling",
    "lng": -96.50551185961733,
    "lat": 41.7345450693914
  },
  {
    "name": "Uhland",
    "lng": -97.82765586659399,
    "lat": 29.966423035956872
  },
  {
    "name": "Uhland",
    "lng": -97.79432894501868,
    "lat": 29.960408398947052
  },
  {
    "name": "Uhrichsville",
    "lng": -81.35152629696076,
    "lat": 40.400573396833096
  },
  {
    "name": "Uhrichsville",
    "lng": -81.34325630155215,
    "lat": 40.382533340212795
  },
  {
    "name": "Uintah",
    "lng": -111.93323308664493,
    "lat": 41.14277207360193
  },
  {
    "name": "Ukiah",
    "lng": -123.21127596933457,
    "lat": 39.14698425926079
  },
  {
    "name": "Ukiah",
    "lng": -123.18804540325925,
    "lat": 39.11261177659847
  },
  {
    "name": "Ukiah",
    "lng": -123.18281901353764,
    "lat": 39.143890763421716
  },
  {
    "name": "Ulen",
    "lng": -96.25812533565765,
    "lat": 47.0781488892481
  },
  {
    "name": "Ulen",
    "lng": -86.46328757903628,
    "lat": 40.06443891655935
  },
  {
    "name": "Ullin",
    "lng": -89.17515305258915,
    "lat": 37.277656741245984
  },
  {
    "name": "Ulm",
    "lng": -91.46172671604178,
    "lat": 34.576284885141455
  },
  {
    "name": "Ulmer",
    "lng": -81.20738266407106,
    "lat": 33.095484856854654
  },
  {
    "name": "Ulysses",
    "lng": -101.38224701180296,
    "lat": 37.575501445734346
  },
  {
    "name": "Ulysses",
    "lng": -101.35433278874255,
    "lat": 37.577234680349946
  },
  {
    "name": "Ulysses",
    "lng": -97.2028775177,
    "lat": 41.07211218649619
  },
  {
    "name": "Umatilla",
    "lng": -81.68482048698156,
    "lat": 28.899093431860102
  },
  {
    "name": "Umatilla",
    "lng": -81.68020899375234,
    "lat": 28.926258774749407
  },
  {
    "name": "Umatilla",
    "lng": -81.6786882,
    "lat": 28.90887670035422
  },
  {
    "name": "Umatilla",
    "lng": -81.66906336174871,
    "lat": 28.939759057858197
  },
  {
    "name": "Umatilla",
    "lng": -81.66695291530144,
    "lat": 28.926500256119258
  },
  {
    "name": "Umatilla",
    "lng": -81.66627061925905,
    "lat": 28.901717837774047
  },
  {
    "name": "Umatilla",
    "lng": -81.66576628455401,
    "lat": 28.94672338412361
  },
  {
    "name": "Umatilla",
    "lng": -81.66034833027318,
    "lat": 28.948067457671815
  },
  {
    "name": "Umatilla",
    "lng": -81.65925882668704,
    "lat": 28.94664653088637
  },
  {
    "name": "Umatilla",
    "lng": -81.65831611819493,
    "lat": 28.949294364358867
  },
  {
    "name": "Umatilla",
    "lng": -81.65558617675325,
    "lat": 28.960219196199862
  },
  {
    "name": "Umatilla",
    "lng": -81.65446868312893,
    "lat": 28.927965374976495
  },
  {
    "name": "Umatilla",
    "lng": -81.6544538616593,
    "lat": 28.95190715508276
  },
  {
    "name": "Umatilla",
    "lng": -81.65023034631609,
    "lat": 28.935564495995173
  },
  {
    "name": "Umatilla",
    "lng": -81.64771751408173,
    "lat": 28.966725253906226
  },
  {
    "name": "Umatilla",
    "lng": -81.64449635068202,
    "lat": 28.933431441584407
  },
  {
    "name": "Umatilla",
    "lng": -81.6419641826889,
    "lat": 28.935959746790818
  },
  {
    "name": "Umatilla",
    "lng": -81.63880738200835,
    "lat": 28.937729756043893
  },
  {
    "name": "Umatilla",
    "lng": -81.63257931033819,
    "lat": 28.937004131641178
  },
  {
    "name": "Umber View Heights",
    "lng": -93.80354554426958,
    "lat": 37.62340776682274
  },
  {
    "name": "Unadilla",
    "lng": -96.27603153746037,
    "lat": 40.68240943718052
  },
  {
    "name": "Unadilla",
    "lng": -96.26983559335791,
    "lat": 40.68270280254794
  },
  {
    "name": "Unadilla",
    "lng": -83.7336416590165,
    "lat": 32.259163145195764
  },
  {
    "name": "Unadilla",
    "lng": -75.31550960802588,
    "lat": 42.32729501710219
  },
  {
    "name": "Unalakleet",
    "lng": -160.7954479485361,
    "lat": 63.89227076361639
  },
  {
    "name": "Unalaska",
    "lng": -166.54879595037545,
    "lat": 53.930293850207406
  },
  {
    "name": "Uncertain",
    "lng": -94.12675602277318,
    "lat": 32.70583674825082
  },
  {
    "name": "Uncertain",
    "lng": -94.12206520695105,
    "lat": 32.719485032264444
  },
  {
    "name": "Underwood",
    "lng": -101.14164804367977,
    "lat": 47.45519828364607
  },
  {
    "name": "Underwood",
    "lng": -95.87500116285486,
    "lat": 46.280525935801386
  },
  {
    "name": "Underwood",
    "lng": -95.87225183411293,
    "lat": 46.28704087682884
  },
  {
    "name": "Underwood",
    "lng": -95.68214711433825,
    "lat": 41.38069022925668
  },
  {
    "name": "Unicoi",
    "lng": -82.32890834725674,
    "lat": 36.22164398153463
  },
  {
    "name": "Union",
    "lng": -95.92146608915671,
    "lat": 40.814349957853636
  },
  {
    "name": "Union",
    "lng": -93.06259129122411,
    "lat": 42.24341397311376
  },
  {
    "name": "Union",
    "lng": -90.9927763483932,
    "lat": 38.4399096245734
  },
  {
    "name": "Union",
    "lng": -89.11520397965411,
    "lat": 32.57113507336954
  },
  {
    "name": "Union",
    "lng": -88.54469587790072,
    "lat": 42.231407875223276
  },
  {
    "name": "Union",
    "lng": -87.91082334805776,
    "lat": 32.985603376353346
  },
  {
    "name": "Union",
    "lng": -87.90475144330435,
    "lat": 32.99927493784905
  },
  {
    "name": "Union",
    "lng": -84.67290220912659,
    "lat": 38.94676719575203
  },
  {
    "name": "Union",
    "lng": -84.29695573206001,
    "lat": 39.91052395180866
  },
  {
    "name": "Union",
    "lng": -81.62473636193451,
    "lat": 34.72363668829786
  },
  {
    "name": "Union",
    "lng": -80.54200534579365,
    "lat": 37.59044238688775
  },
  {
    "name": "Union",
    "lng": -72.1598235128061,
    "lat": 41.992971398516396
  },
  {
    "name": "Union",
    "lng": -69.28850747164707,
    "lat": 44.2206440981432
  },
  {
    "name": "Union Bridge",
    "lng": -77.17429577829141,
    "lat": 39.57295533490728
  },
  {
    "name": "Union Center",
    "lng": -90.2637284437347,
    "lat": 43.68251682629811
  },
  {
    "name": "Union City",
    "lng": -97.90182799175169,
    "lat": 35.40447581835812
  },
  {
    "name": "Union City",
    "lng": -89.04742247437738,
    "lat": 36.4266408518452
  },
  {
    "name": "Union City",
    "lng": -85.14297220723107,
    "lat": 42.06635252240394
  },
  {
    "name": "Union City",
    "lng": -84.82743178108252,
    "lat": 40.19278634735912
  },
  {
    "name": "Union City",
    "lng": -84.817674502538,
    "lat": 40.201367111458566
  },
  {
    "name": "Union City",
    "lng": -84.79413608939186,
    "lat": 40.199613393478515
  },
  {
    "name": "Union City",
    "lng": -84.6639542230708,
    "lat": 33.58382506850353
  },
  {
    "name": "Union City",
    "lng": -84.65002693403864,
    "lat": 33.58902543767643
  },
  {
    "name": "Union City",
    "lng": -84.55877160339315,
    "lat": 33.59426563881681
  },
  {
    "name": "Union City",
    "lng": -84.5013522912891,
    "lat": 33.59121210599086
  },
  {
    "name": "Union City",
    "lng": -79.84383590386224,
    "lat": 41.89699578752976
  },
  {
    "name": "Union City",
    "lng": -74.03229107586873,
    "lat": 40.76746382839622
  },
  {
    "name": "Union Gap",
    "lng": -120.49368986831004,
    "lat": 46.556951767792974
  },
  {
    "name": "Union Grove",
    "lng": -94.90999920358695,
    "lat": 32.579563771075556
  },
  {
    "name": "Union Grove",
    "lng": -88.049393855319,
    "lat": 42.68638520170433
  },
  {
    "name": "Union Grove",
    "lng": -86.4483226824418,
    "lat": 34.402560394073795
  },
  {
    "name": "Union Hill",
    "lng": -88.14614923128258,
    "lat": 41.108492823216025
  },
  {
    "name": "Union Point",
    "lng": -83.07560125524391,
    "lat": 33.61776301261691
  },
  {
    "name": "Union Springs",
    "lng": -85.71428370930055,
    "lat": 32.13951222711624
  },
  {
    "name": "Union Springs",
    "lng": -76.69089153842646,
    "lat": 42.84576922474645
  },
  {
    "name": "Union Star",
    "lng": -94.59882907890777,
    "lat": 39.97939329082418
  },
  {
    "name": "Union Valley",
    "lng": -96.24711019779949,
    "lat": 32.93238534623357
  },
  {
    "name": "Uniondale",
    "lng": -85.24075285353148,
    "lat": 40.83000043171152
  },
  {
    "name": "Uniontown",
    "lng": -117.08619317820073,
    "lat": 46.538943081766774
  },
  {
    "name": "Uniontown",
    "lng": -94.97563220133814,
    "lat": 37.84726424919944
  },
  {
    "name": "Uniontown",
    "lng": -87.93257487303147,
    "lat": 37.77407244886937
  },
  {
    "name": "Uniontown",
    "lng": -79.72446381031038,
    "lat": 39.89932120459249
  },
  {
    "name": "Unionville",
    "lng": -93.00441587649883,
    "lat": 40.47570928853023
  },
  {
    "name": "Unionville",
    "lng": -92.69585888793313,
    "lat": 40.81814599788454
  },
  {
    "name": "Unionville",
    "lng": -83.46712481984831,
    "lat": 43.65369071989927
  },
  {
    "name": "Unionville",
    "lng": -80.51994660946329,
    "lat": 35.07460654282302
  },
  {
    "name": "Unionville",
    "lng": -74.56224072651791,
    "lat": 41.30139797992771
  },
  {
    "name": "Unionville Center",
    "lng": -83.3416273111411,
    "lat": 40.136987916027536
  },
  {
    "name": "Uniopolis",
    "lng": -84.08629235737511,
    "lat": 40.60203995848568
  },
  {
    "name": "Unity",
    "lng": -90.31304762009083,
    "lat": 44.85118677100795
  },
  {
    "name": "Unity",
    "lng": -69.34279823151087,
    "lat": 44.59053449269121
  },
  {
    "name": "Unity Village",
    "lng": -94.39853432760168,
    "lat": 38.95024027403061
  },
  {
    "name": "Universal",
    "lng": -87.45369193460049,
    "lat": 39.62197520158414
  },
  {
    "name": "Universal City",
    "lng": -98.30733306642178,
    "lat": 29.552110149535558
  },
  {
    "name": "University City",
    "lng": -90.33148577611834,
    "lat": 38.66568459348649
  },
  {
    "name": "University Heights",
    "lng": -91.55887774992591,
    "lat": 41.65507554283536
  },
  {
    "name": "University Heights",
    "lng": -81.53486696297104,
    "lat": 41.49478547385927
  },
  {
    "name": "University Park",
    "lng": -96.79376345043501,
    "lat": 32.8506000343951
  },
  {
    "name": "University Park",
    "lng": -92.61492990877606,
    "lat": 41.285603412062976
  },
  {
    "name": "University Park",
    "lng": -87.71670597746935,
    "lat": 41.44633294565239
  },
  {
    "name": "University Place",
    "lng": -122.54612476517754,
    "lat": 47.214568449417776
  },
  {
    "name": "Upham",
    "lng": -100.72863450874281,
    "lat": 48.58127724505602
  },
  {
    "name": "Upland",
    "lng": -117.66041506974139,
    "lat": 34.117750111539465
  },
  {
    "name": "Upland",
    "lng": -98.90218443061349,
    "lat": 40.31948248624591
  },
  {
    "name": "Upland",
    "lng": -85.50152425717363,
    "lat": 40.463672063766126
  },
  {
    "name": "Uplands Park",
    "lng": -90.28283598142293,
    "lat": 38.69257360476477
  },
  {
    "name": "Upper Arlington",
    "lng": -83.0704190232542,
    "lat": 40.02721423002811
  },
  {
    "name": "Upper Brookville",
    "lng": -73.56350432598502,
    "lat": 40.84820335574432
  },
  {
    "name": "Upper Kalskag",
    "lng": -160.3338521104331,
    "lat": 61.54188466422497
  },
  {
    "name": "Upper Marlboro",
    "lng": -76.75466944450869,
    "lat": 38.817180663210465
  },
  {
    "name": "Upper Nyack",
    "lng": -73.90638752292897,
    "lat": 41.114706400024104
  },
  {
    "name": "Upper Sandusky",
    "lng": -83.27186436703585,
    "lat": 40.82997317438111
  },
  {
    "name": "Upsala",
    "lng": -94.56736174247436,
    "lat": 45.810003814700764
  },
  {
    "name": "Upton",
    "lng": -104.63811496311455,
    "lat": 44.10276559341908
  },
  {
    "name": "Upton",
    "lng": -104.6105787903554,
    "lat": 44.09153137065855
  },
  {
    "name": "Upton",
    "lng": -85.89867096092864,
    "lat": 37.463485459242094
  },
  {
    "name": "Upton",
    "lng": -71.60433134729013,
    "lat": 42.17709685160031
  },
  {
    "name": "Upton",
    "lng": -70.98525898124964,
    "lat": 44.722729716474845
  },
  {
    "name": "Urania",
    "lng": -92.29122226267037,
    "lat": 31.86388882944016
  },
  {
    "name": "Urbana",
    "lng": -93.1675416748517,
    "lat": 37.84345717176535
  },
  {
    "name": "Urbana",
    "lng": -91.88549765226958,
    "lat": 42.23014360851411
  },
  {
    "name": "Urbana",
    "lng": -88.315769973353,
    "lat": 40.07190748021785
  },
  {
    "name": "Urbana",
    "lng": -88.31176120565507,
    "lat": 40.07864302055146
  },
  {
    "name": "Urbana",
    "lng": -88.30669499472216,
    "lat": 40.078734892701924
  },
  {
    "name": "Urbana",
    "lng": -88.27781310078807,
    "lat": 40.15015444559214
  },
  {
    "name": "Urbana",
    "lng": -88.27487425343661,
    "lat": 40.14396840176994
  },
  {
    "name": "Urbana",
    "lng": -88.19718693006456,
    "lat": 40.110633219100336
  },
  {
    "name": "Urbana",
    "lng": -88.17764049154539,
    "lat": 40.114040416700746
  },
  {
    "name": "Urbana",
    "lng": -88.1759454952477,
    "lat": 40.11461654135154
  },
  {
    "name": "Urbana",
    "lng": -83.75021447608663,
    "lat": 40.1097733674222
  },
  {
    "name": "Urbancrest",
    "lng": -83.08609849414789,
    "lat": 39.901496594808776
  },
  {
    "name": "Urbandale",
    "lng": -93.78124117926703,
    "lat": 41.63898352162319
  },
  {
    "name": "Urbank",
    "lng": -95.51044061766328,
    "lat": 46.12420975364828
  },
  {
    "name": "Urbanna",
    "lng": -76.57484498726994,
    "lat": 37.639856196368044
  },
  {
    "name": "Urich",
    "lng": -93.99898555008637,
    "lat": 38.46041759074602
  },
  {
    "name": "Ursa",
    "lng": -91.37237935185973,
    "lat": 40.074171278581346
  },
  {
    "name": "Utah",
    "lng": -111.68238828366457,
    "lat": 39.3642586986319
  },
  {
    "name": "Ute",
    "lng": -95.70577675210247,
    "lat": 42.050237294844486
  },
  {
    "name": "Utica",
    "lng": -100.16985136534934,
    "lat": 38.64264032484848
  },
  {
    "name": "Utica",
    "lng": -97.49666132978606,
    "lat": 42.981085479870494
  },
  {
    "name": "Utica",
    "lng": -97.34534000020952,
    "lat": 40.89557975523347
  },
  {
    "name": "Utica",
    "lng": -93.62863883434439,
    "lat": 39.745499975644925
  },
  {
    "name": "Utica",
    "lng": -91.94953563707833,
    "lat": 43.9771811505796
  },
  {
    "name": "Utica",
    "lng": -90.62209543074269,
    "lat": 32.105962537999766
  },
  {
    "name": "Utica",
    "lng": -85.65626778187287,
    "lat": 38.33391909518582
  },
  {
    "name": "Utica",
    "lng": -83.02236841032328,
    "lat": 42.628972493770156
  },
  {
    "name": "Utica",
    "lng": -82.44135671400134,
    "lat": 40.23351098566507
  },
  {
    "name": "Utqiaġvik",
    "lng": -156.75730060505887,
    "lat": 71.27294972309565
  },
  {
    "name": "Uvalda",
    "lng": -82.50787993984964,
    "lat": 32.036800802140185
  },
  {
    "name": "Uvalde",
    "lng": -99.7781889129082,
    "lat": 29.21532089637195
  },
  {
    "name": "Vacaville",
    "lng": -121.9942254719504,
    "lat": 38.376291456506294
  },
  {
    "name": "Vacaville",
    "lng": -121.96957155533167,
    "lat": 38.36007176350216
  },
  {
    "name": "Vacaville",
    "lng": -121.90190098614225,
    "lat": 38.341653325639825
  },
  {
    "name": "Vader",
    "lng": -122.95675468885048,
    "lat": 46.40432572058961
  },
  {
    "name": "Vadnais Heights",
    "lng": -93.07476668448784,
    "lat": 45.056979995185536
  },
  {
    "name": "Vaiden",
    "lng": -89.75338323685457,
    "lat": 33.333237506476316
  },
  {
    "name": "Vail",
    "lng": -106.36207614278136,
    "lat": 39.6388518427595
  },
  {
    "name": "Vail",
    "lng": -95.20052326510968,
    "lat": 42.05966056172657
  },
  {
    "name": "Valatie",
    "lng": -73.6778500644048,
    "lat": 42.41339606163515
  },
  {
    "name": "Valders",
    "lng": -87.88596124639545,
    "lat": 44.06927395839438
  },
  {
    "name": "Valdese",
    "lng": -81.57808792185351,
    "lat": 35.7592127794199
  },
  {
    "name": "Valdese",
    "lng": -81.5778492707779,
    "lat": 35.76475145428086
  },
  {
    "name": "Valdese",
    "lng": -81.56374856750246,
    "lat": 35.75718651952492
  },
  {
    "name": "Valdese",
    "lng": -81.56066382377755,
    "lat": 35.72414855188882
  },
  {
    "name": "Valdese",
    "lng": -81.55510995957749,
    "lat": 35.72773118473555
  },
  {
    "name": "Valdese",
    "lng": -81.54936610698056,
    "lat": 35.72412971646589
  },
  {
    "name": "Valdese",
    "lng": -81.53530171029443,
    "lat": 35.735844168106524
  },
  {
    "name": "Valdese",
    "lng": -81.52280776122659,
    "lat": 35.747945798556024
  },
  {
    "name": "Valdez",
    "lng": -146.3017627451285,
    "lat": 61.09702567669186
  },
  {
    "name": "Valdosta",
    "lng": -83.30870615981202,
    "lat": 30.83197794578929
  },
  {
    "name": "Valdosta",
    "lng": -83.27878575855277,
    "lat": 30.848981631652656
  },
  {
    "name": "Valdosta",
    "lng": -83.25104168676043,
    "lat": 30.908881257362854
  },
  {
    "name": "Valdosta",
    "lng": -83.24279397124074,
    "lat": 30.894957002544324
  },
  {
    "name": "Valentine",
    "lng": -104.49531367136045,
    "lat": 30.588611607767096
  },
  {
    "name": "Valentine",
    "lng": -100.55007054416195,
    "lat": 42.873892024239694
  },
  {
    "name": "Valeria",
    "lng": -93.32554543108796,
    "lat": 41.729777245000705
  },
  {
    "name": "Valier",
    "lng": -112.25210633631892,
    "lat": 48.30292781762578
  },
  {
    "name": "Valier",
    "lng": -112.25064566824996,
    "lat": 48.31235352374225
  },
  {
    "name": "Valier",
    "lng": -89.04386527882448,
    "lat": 38.01756651852361
  },
  {
    "name": "Vallejo",
    "lng": -122.2663654845855,
    "lat": 38.107049466523705
  },
  {
    "name": "Vallejo",
    "lng": -122.19830595595347,
    "lat": 38.1706275628664
  },
  {
    "name": "Valley",
    "lng": -96.38400898618009,
    "lat": 41.337129950211846
  },
  {
    "name": "Valley",
    "lng": -96.35627680140212,
    "lat": 41.314397026110946
  },
  {
    "name": "Valley",
    "lng": -85.21188816589441,
    "lat": 32.784865278067734
  },
  {
    "name": "Valley",
    "lng": -85.20137839056119,
    "lat": 32.76547695634795
  },
  {
    "name": "Valley",
    "lng": -85.19655860308237,
    "lat": 32.77698308430888
  },
  {
    "name": "Valley",
    "lng": -85.19654137971284,
    "lat": 32.768412057296636
  },
  {
    "name": "Valley",
    "lng": -85.17546630825801,
    "lat": 32.81081109101244
  },
  {
    "name": "Valley Brook",
    "lng": -97.48123333089988,
    "lat": 35.40236046068799
  },
  {
    "name": "Valley Center",
    "lng": -117.01521234385447,
    "lat": 33.23004939892274
  },
  {
    "name": "Valley Center",
    "lng": -97.36966288681944,
    "lat": 37.859215834450524
  },
  {
    "name": "Valley Center",
    "lng": -97.36448110256913,
    "lat": 37.832262380081026
  },
  {
    "name": "Valley City",
    "lng": -98.00563927040406,
    "lat": 46.92256807268338
  },
  {
    "name": "Valley City",
    "lng": -90.65139796912362,
    "lat": 39.7071357396302
  },
  {
    "name": "Valley Falls",
    "lng": -120.28358484818251,
    "lat": 42.48415236406355
  },
  {
    "name": "Valley Falls",
    "lng": -95.46095116875104,
    "lat": 39.34045173030376
  },
  {
    "name": "Valley Falls",
    "lng": -73.56269440156203,
    "lat": 42.90060874242914
  },
  {
    "name": "Valley Grande",
    "lng": -87.06677327286863,
    "lat": 32.47224257396687
  },
  {
    "name": "Valley Grande",
    "lng": -87.03037376144998,
    "lat": 32.485842480126955
  },
  {
    "name": "Valley Grande",
    "lng": -87.03022651105563,
    "lat": 32.485805419687246
  },
  {
    "name": "Valley Grande",
    "lng": -86.98022951247232,
    "lat": 32.526121673184385
  },
  {
    "name": "Valley Grande",
    "lng": -86.97873124458084,
    "lat": 32.52734329469474
  },
  {
    "name": "Valley Green",
    "lng": -76.79465178869044,
    "lat": 40.156691130219535
  },
  {
    "name": "Valley Grove",
    "lng": -80.569076576476,
    "lat": 40.09003875053898
  },
  {
    "name": "Valley Head",
    "lng": -85.62187177803777,
    "lat": 34.556762442895135
  },
  {
    "name": "Valley Hi",
    "lng": -83.67604608590021,
    "lat": 40.31554178115585
  },
  {
    "name": "Valley Mills",
    "lng": -97.4716315058218,
    "lat": 31.658405441763122
  },
  {
    "name": "Valley Park",
    "lng": -95.73633665373573,
    "lat": 36.28281029999281
  },
  {
    "name": "Valley Park",
    "lng": -95.7205014975083,
    "lat": 36.275931258764
  },
  {
    "name": "Valley Park",
    "lng": -95.70731232472505,
    "lat": 36.27554109687304
  },
  {
    "name": "Valley Park",
    "lng": -90.49241791763761,
    "lat": 38.551252814659954
  },
  {
    "name": "Valley Springs",
    "lng": -96.46594097469465,
    "lat": 43.583254933480895
  },
  {
    "name": "Valley Springs",
    "lng": -92.98971066938245,
    "lat": 36.153781520515224
  },
  {
    "name": "Valley Stream",
    "lng": -73.70436506674642,
    "lat": 40.664630690209705
  },
  {
    "name": "Valley View",
    "lng": -97.15341830423455,
    "lat": 33.48919885220027
  },
  {
    "name": "Valley View",
    "lng": -81.60713077581242,
    "lat": 41.38344020783111
  },
  {
    "name": "Valley View",
    "lng": -76.5381474887141,
    "lat": 40.64737585472333
  },
  {
    "name": "Valleyview",
    "lng": -83.07315589355453,
    "lat": 39.964427566643536
  },
  {
    "name": "Valliant",
    "lng": -95.08918294786163,
    "lat": 34.00413203102294
  },
  {
    "name": "Valmeyer",
    "lng": -90.29788702959269,
    "lat": 38.306375564397676
  },
  {
    "name": "Valmeyer",
    "lng": -90.271966,
    "lat": 38.319911333386386
  },
  {
    "name": "Valparaiso",
    "lng": -96.8330016093461,
    "lat": 41.0798340737768
  },
  {
    "name": "Valparaiso",
    "lng": -87.05243737274216,
    "lat": 41.48008429137179
  },
  {
    "name": "Valparaiso",
    "lng": -86.5079394132752,
    "lat": 30.49266583758673
  },
  {
    "name": "Van",
    "lng": -95.63730339107317,
    "lat": 32.52413285352507
  },
  {
    "name": "Van Buren",
    "lng": -91.01128626275361,
    "lat": 37.00822292942898
  },
  {
    "name": "Van Buren",
    "lng": -85.50413683150883,
    "lat": 40.616140274924355
  },
  {
    "name": "Van Buren",
    "lng": -83.64889612291053,
    "lat": 41.13866639491624
  },
  {
    "name": "Van Buren",
    "lng": -68.00704407126766,
    "lat": 47.180385256696496
  },
  {
    "name": "Van Etten",
    "lng": -76.55419852537403,
    "lat": 42.19749964501172
  },
  {
    "name": "Van Horn",
    "lng": -104.83398388109764,
    "lat": 31.04081505206685
  },
  {
    "name": "Van Horne",
    "lng": -92.09052958560012,
    "lat": 42.00854123856811
  },
  {
    "name": "Van Meter",
    "lng": -93.94293837055024,
    "lat": 41.51995830010297
  },
  {
    "name": "Van Tassell",
    "lng": -104.0912610375352,
    "lat": 42.664271374564045
  },
  {
    "name": "Van Wert",
    "lng": -93.79223373215221,
    "lat": 40.87081567982117
  },
  {
    "name": "Van Wert",
    "lng": -84.61470153314743,
    "lat": 40.88972719371891
  },
  {
    "name": "Van Wert",
    "lng": -84.58664499611201,
    "lat": 40.865351492737524
  },
  {
    "name": "Vance",
    "lng": -87.2734370530324,
    "lat": 33.1787152923809
  },
  {
    "name": "Vance",
    "lng": -87.23014961402788,
    "lat": 33.164842378788975
  },
  {
    "name": "Vance",
    "lng": -80.42012387835968,
    "lat": 33.436683869579625
  },
  {
    "name": "Vanceboro",
    "lng": -77.15670037601056,
    "lat": 35.3030560190989
  },
  {
    "name": "Vanceboro",
    "lng": -67.47031263692828,
    "lat": 45.56000605133798
  },
  {
    "name": "Vanceburg",
    "lng": -83.32115516752977,
    "lat": 38.59292190669283
  },
  {
    "name": "Vandalia",
    "lng": -91.48908018514976,
    "lat": 39.307911020939365
  },
  {
    "name": "Vandalia",
    "lng": -89.13669281916516,
    "lat": 38.970129011519084
  },
  {
    "name": "Vandalia",
    "lng": -89.12104018068598,
    "lat": 38.96810984556785
  },
  {
    "name": "Vandalia",
    "lng": -89.10235595535077,
    "lat": 38.96769125618047
  },
  {
    "name": "Vandalia",
    "lng": -89.10074067874172,
    "lat": 38.99517438300032
  },
  {
    "name": "Vandalia",
    "lng": -85.91438275323162,
    "lat": 41.91883820153858
  },
  {
    "name": "Vandalia",
    "lng": -84.19291632424493,
    "lat": 39.87905235483712
  },
  {
    "name": "Vandemere",
    "lng": -76.66508543744379,
    "lat": 35.18861918936008
  },
  {
    "name": "Vanderbilt",
    "lng": -84.67124694440389,
    "lat": 45.15479994085847
  },
  {
    "name": "Vanderbilt",
    "lng": -84.66319980535023,
    "lat": 45.14258944940716
  },
  {
    "name": "Vandervoort",
    "lng": -94.36437444245075,
    "lat": 34.379992708218246
  },
  {
    "name": "Vandiver",
    "lng": -91.84713896013301,
    "lat": 39.16218916091252
  },
  {
    "name": "Vanduser",
    "lng": -89.68638189744688,
    "lat": 36.991010717978384
  },
  {
    "name": "Vanleer",
    "lng": -87.4463489612562,
    "lat": 36.2382011407121
  },
  {
    "name": "Vanlue",
    "lng": -83.48234687688583,
    "lat": 40.97498861140681
  },
  {
    "name": "Vardaman",
    "lng": -89.1777957513669,
    "lat": 33.88219625359208
  },
  {
    "name": "Varina",
    "lng": -94.89802701172917,
    "lat": 42.65854526299843
  },
  {
    "name": "Varna",
    "lng": -89.22429341252922,
    "lat": 41.03522971713344
  },
  {
    "name": "Varnado",
    "lng": -89.8318938726281,
    "lat": 30.895313060518923
  },
  {
    "name": "Varnamtown",
    "lng": -78.23603740790557,
    "lat": 33.94316605244636
  },
  {
    "name": "Varnell",
    "lng": -84.96755848485755,
    "lat": 34.899788358032104
  },
  {
    "name": "Varnell",
    "lng": -84.95790064570086,
    "lat": 34.92243218049904
  },
  {
    "name": "Varnville",
    "lng": -81.0802259358839,
    "lat": 32.85203222886232
  },
  {
    "name": "Vass",
    "lng": -79.32546837179663,
    "lat": 35.214557375750296
  },
  {
    "name": "Vass",
    "lng": -79.3201511424878,
    "lat": 35.2244473395468
  },
  {
    "name": "Vass",
    "lng": -79.28174208291452,
    "lat": 35.25631392190778
  },
  {
    "name": "Vassalboro",
    "lng": -69.64786232773851,
    "lat": 44.42989125913169
  },
  {
    "name": "Vassar",
    "lng": -83.57692203945702,
    "lat": 43.371966411270755
  },
  {
    "name": "Vaughn",
    "lng": -105.2125795411287,
    "lat": 34.60720207814487
  },
  {
    "name": "Veazie",
    "lng": -68.7133938936791,
    "lat": 44.84067102976771
  },
  {
    "name": "Veblen",
    "lng": -97.28687592012324,
    "lat": 45.861927761596775
  },
  {
    "name": "Veedersburg",
    "lng": -87.25664025011525,
    "lat": 40.112852044875964
  },
  {
    "name": "Vega",
    "lng": -102.42642174793394,
    "lat": 35.24591621117755
  },
  {
    "name": "Velda City",
    "lng": -90.29343443332296,
    "lat": 38.69406907894612
  },
  {
    "name": "Velda Village Hills",
    "lng": -90.28754325108738,
    "lat": 38.6920072926202
  },
  {
    "name": "Velma",
    "lng": -97.66170306771198,
    "lat": 34.45475970411757
  },
  {
    "name": "Velva",
    "lng": -100.9309312232759,
    "lat": 48.057443771044376
  },
  {
    "name": "Venango",
    "lng": -102.04103423529493,
    "lat": 40.761976116795395
  },
  {
    "name": "Venedocia",
    "lng": -84.45589863013404,
    "lat": 40.785211211374
  },
  {
    "name": "Venedy",
    "lng": -89.64564851952053,
    "lat": 38.39661429951586
  },
  {
    "name": "Venice",
    "lng": -90.16885888759606,
    "lat": 38.67196377841601
  },
  {
    "name": "Venice",
    "lng": -82.42993015000108,
    "lat": 27.113226050147652
  },
  {
    "name": "Venice",
    "lng": -82.41243054267984,
    "lat": 27.117283579499848
  },
  {
    "name": "Ventnor City",
    "lng": -74.4826858807528,
    "lat": 39.34200631214756
  },
  {
    "name": "Ventura",
    "lng": -119.31059984575685,
    "lat": 34.364170893770556
  },
  {
    "name": "Ventura",
    "lng": -119.29568314349653,
    "lat": 34.343620026382524
  },
  {
    "name": "Ventura",
    "lng": -119.27791133333335,
    "lat": 34.310824999613736
  },
  {
    "name": "Ventura",
    "lng": -119.25566199523043,
    "lat": 34.26608850516843
  },
  {
    "name": "Ventura",
    "lng": -93.46206223876432,
    "lat": 43.12820044704512
  },
  {
    "name": "Venturia",
    "lng": -99.54974617578296,
    "lat": 45.997469988033714
  },
  {
    "name": "Venus",
    "lng": -97.11797657398479,
    "lat": 32.39229812896392
  },
  {
    "name": "Venus",
    "lng": -97.1016405399921,
    "lat": 32.43161066573113
  },
  {
    "name": "Venus",
    "lng": -97.08403775692956,
    "lat": 32.40702781010418
  },
  {
    "name": "Vera",
    "lng": -95.8946433602502,
    "lat": 36.44990348848174
  },
  {
    "name": "Vera Cruz",
    "lng": -85.07998053681402,
    "lat": 40.70141491965531
  },
  {
    "name": "Vera Cruz",
    "lng": -85.07521249850323,
    "lat": 40.70290844332993
  },
  {
    "name": "Verdel",
    "lng": -98.19329866944143,
    "lat": 42.81139839664347
  },
  {
    "name": "Verden",
    "lng": -98.0873562843385,
    "lat": 35.08324881936554
  },
  {
    "name": "Verdi-Mogul",
    "lng": -119.99736176668921,
    "lat": 39.48451988404451
  },
  {
    "name": "Verdi-Mogul",
    "lng": -119.9753267770528,
    "lat": 39.51832144516336
  },
  {
    "name": "Verdi-Mogul",
    "lng": -119.963103683403,
    "lat": 39.52064911591891
  },
  {
    "name": "Verdi-Mogul",
    "lng": -119.95899176807387,
    "lat": 39.54986095158031
  },
  {
    "name": "Verdi-Mogul",
    "lng": -119.90275155945533,
    "lat": 39.51153661533452
  },
  {
    "name": "Verdi-Mogul",
    "lng": -119.89267658909262,
    "lat": 39.49793310576676
  },
  {
    "name": "Verdi-Mogul",
    "lng": -119.890871,
    "lat": 39.504080666884235
  },
  {
    "name": "Verdigre",
    "lng": -98.03557621949363,
    "lat": 42.59729084725935
  },
  {
    "name": "Verdigris",
    "lng": -95.65793710230474,
    "lat": 36.25432057689136
  },
  {
    "name": "Verdigris",
    "lng": -95.64670447395277,
    "lat": 36.26887699357205
  },
  {
    "name": "Verdon",
    "lng": -98.09916166626394,
    "lat": 45.24420508101675
  },
  {
    "name": "Verdon",
    "lng": -95.71115351663737,
    "lat": 40.14908785604583
  },
  {
    "name": "Vergas",
    "lng": -95.8027451990641,
    "lat": 46.654747540322234
  },
  {
    "name": "Vergennes",
    "lng": -89.33990247883273,
    "lat": 37.90148646595046
  },
  {
    "name": "Vergennes",
    "lng": -73.25564774655898,
    "lat": 44.166391041062184
  },
  {
    "name": "Vermilion",
    "lng": -87.58774228056569,
    "lat": 39.58002944136698
  },
  {
    "name": "Vermilion",
    "lng": -82.32143520520975,
    "lat": 41.41034941046617
  },
  {
    "name": "Vermillion",
    "lng": -96.92442025102322,
    "lat": 42.78252340242974
  },
  {
    "name": "Vermillion",
    "lng": -96.26543534408866,
    "lat": 39.7180445602519
  },
  {
    "name": "Vermillion",
    "lng": -92.96431333131808,
    "lat": 44.67356409658664
  },
  {
    "name": "Vermont",
    "lng": -90.42901868558629,
    "lat": 40.29571528508268
  },
  {
    "name": "Vermont",
    "lng": -72.66109378188757,
    "lat": 44.08533049941003
  },
  {
    "name": "Vermontville",
    "lng": -85.02483140798628,
    "lat": 42.626761487267686
  },
  {
    "name": "Vernal",
    "lng": -109.5379995875051,
    "lat": 40.45168584177831
  },
  {
    "name": "Verndale",
    "lng": -95.01216294851407,
    "lat": 46.39685595967594
  },
  {
    "name": "Vernon",
    "lng": -118.21026930461821,
    "lat": 34.0018917810346
  },
  {
    "name": "Vernon",
    "lng": -112.44578804577093,
    "lat": 40.09558586479384
  },
  {
    "name": "Vernon",
    "lng": -99.29997627287632,
    "lat": 34.147986277612524
  },
  {
    "name": "Vernon",
    "lng": -89.08958598794112,
    "lat": 38.80140164887152
  },
  {
    "name": "Vernon",
    "lng": -88.11444121898488,
    "lat": 33.75901572966786
  },
  {
    "name": "Vernon",
    "lng": -85.61050839240664,
    "lat": 38.98524830879554
  },
  {
    "name": "Vernon",
    "lng": -84.03070104146748,
    "lat": 42.94015398665009
  },
  {
    "name": "Vernon",
    "lng": -75.53791637806293,
    "lat": 43.079518207794955
  },
  {
    "name": "Vernon",
    "lng": -72.46056518892448,
    "lat": 41.83641292319812
  },
  {
    "name": "Vernon Center",
    "lng": -94.16645687761851,
    "lat": 43.962663374181794
  },
  {
    "name": "Vernon Hills",
    "lng": -87.96073096023892,
    "lat": 42.23382776802902
  },
  {
    "name": "Vero Beach",
    "lng": -80.40828891070979,
    "lat": 27.646750638616226
  },
  {
    "name": "Vero Beach",
    "lng": -80.39671050402535,
    "lat": 27.65094202908374
  },
  {
    "name": "Vero Beach",
    "lng": -80.37403182287395,
    "lat": 27.657753488623094
  },
  {
    "name": "Vero Beach",
    "lng": -80.3734394951413,
    "lat": 27.647624527599284
  },
  {
    "name": "Vero Beach",
    "lng": -80.37244057734019,
    "lat": 27.62853372780891
  },
  {
    "name": "Vero Beach",
    "lng": -80.3696036367368,
    "lat": 27.64244507446714
  },
  {
    "name": "Vero Beach",
    "lng": -80.36933728137728,
    "lat": 27.63448547202222
  },
  {
    "name": "Vero Beach",
    "lng": -80.364635665696,
    "lat": 27.62224919326611
  },
  {
    "name": "Vero Beach",
    "lng": -80.35977558811791,
    "lat": 27.646309763778188
  },
  {
    "name": "Verona",
    "lng": -98.0712604230348,
    "lat": 46.366021837097485
  },
  {
    "name": "Verona",
    "lng": -93.79362039579813,
    "lat": 36.963099937510904
  },
  {
    "name": "Verona",
    "lng": -88.72035051676157,
    "lat": 34.19075508375816
  },
  {
    "name": "Verona",
    "lng": -88.50508070145341,
    "lat": 41.21566384765059
  },
  {
    "name": "Verona",
    "lng": -84.49635276648948,
    "lat": 39.901882746230385
  },
  {
    "name": "Verona Island",
    "lng": -68.7820949625079,
    "lat": 44.533751029062
  },
  {
    "name": "Versailles",
    "lng": -92.84525503179931,
    "lat": 38.43325751819764
  },
  {
    "name": "Versailles",
    "lng": -90.65870741716311,
    "lat": 39.8841903293526
  },
  {
    "name": "Versailles",
    "lng": -85.25643658543592,
    "lat": 39.06394978319889
  },
  {
    "name": "Versailles",
    "lng": -84.74651538655925,
    "lat": 38.06216547579371
  },
  {
    "name": "Versailles",
    "lng": -84.7323700076123,
    "lat": 38.04836556484094
  },
  {
    "name": "Versailles",
    "lng": -84.48297167469899,
    "lat": 40.22145250039357
  },
  {
    "name": "Versailles",
    "lng": -72.04596939663452,
    "lat": 41.60358890526885
  },
  {
    "name": "Vesper",
    "lng": -89.96748410102904,
    "lat": 44.480456562918455
  },
  {
    "name": "Vesta",
    "lng": -95.4165872743838,
    "lat": 44.50775999890611
  },
  {
    "name": "Vestavia Hills",
    "lng": -86.81558124585902,
    "lat": 33.4220986720885
  },
  {
    "name": "Vestavia Hills",
    "lng": -86.78568310405231,
    "lat": 33.43275396751829
  },
  {
    "name": "Vestavia Hills",
    "lng": -86.77247404156256,
    "lat": 33.4156282118395
  },
  {
    "name": "Vestavia Hills",
    "lng": -86.77203569956353,
    "lat": 33.42922831881781
  },
  {
    "name": "Vestavia Hills",
    "lng": -86.771485097769,
    "lat": 33.41437359736263
  },
  {
    "name": "Vestavia Hills",
    "lng": -86.76428170412612,
    "lat": 33.41224083447237
  },
  {
    "name": "Vestavia Hills",
    "lng": -86.75507454561158,
    "lat": 33.440996155411675
  },
  {
    "name": "Vestavia Hills",
    "lng": -86.75187244074753,
    "lat": 33.41766117964422
  },
  {
    "name": "Vestavia Hills",
    "lng": -86.7503282560949,
    "lat": 33.418106425098216
  },
  {
    "name": "Vestavia Hills",
    "lng": -86.74710900673097,
    "lat": 33.42887933913262
  },
  {
    "name": "Vestavia Hills",
    "lng": -86.74624481091757,
    "lat": 33.4349354800626
  },
  {
    "name": "Vestavia Hills",
    "lng": -86.7433741274101,
    "lat": 33.439375822461635
  },
  {
    "name": "Vestavia Hills",
    "lng": -86.74141200024542,
    "lat": 33.42751345819947
  },
  {
    "name": "Vestavia Hills",
    "lng": -86.74092653488826,
    "lat": 33.424281642850474
  },
  {
    "name": "Vestavia Hills",
    "lng": -86.74031933528941,
    "lat": 33.43052661565892
  },
  {
    "name": "Vestavia Hills",
    "lng": -86.73878135812048,
    "lat": 33.43641721453726
  },
  {
    "name": "Vestavia Hills",
    "lng": -86.732356853693,
    "lat": 33.43189246451497
  },
  {
    "name": "Vestavia Hills",
    "lng": -86.7254214364755,
    "lat": 33.43024924653926
  },
  {
    "name": "Vestavia Hills",
    "lng": -86.72409675323038,
    "lat": 33.460341665879476
  },
  {
    "name": "Vestavia Hills",
    "lng": -86.68416882352228,
    "lat": 33.48152031346016
  },
  {
    "name": "Vevay",
    "lng": -85.07891644879504,
    "lat": 38.74234569682596
  },
  {
    "name": "Vian",
    "lng": -94.97150335154573,
    "lat": 35.497170028253386
  },
  {
    "name": "Viborg",
    "lng": -97.0798541649652,
    "lat": 43.17139550254502
  },
  {
    "name": "Viburnum",
    "lng": -91.12948528262449,
    "lat": 37.71487601358954
  },
  {
    "name": "Vicco",
    "lng": -83.06105255092736,
    "lat": 37.21560067358929
  },
  {
    "name": "Vici",
    "lng": -99.29933831135389,
    "lat": 36.14870090269189
  },
  {
    "name": "Vicksburg",
    "lng": -90.88664378072059,
    "lat": 32.31718505621654
  },
  {
    "name": "Vicksburg",
    "lng": -85.54131374864815,
    "lat": 42.11851326829918
  },
  {
    "name": "Victor",
    "lng": -111.11084344840302,
    "lat": 43.60144436929165
  },
  {
    "name": "Victor",
    "lng": -92.2948300435707,
    "lat": 41.730701448567565
  },
  {
    "name": "Victor",
    "lng": -77.40988332078342,
    "lat": 42.982073909064276
  },
  {
    "name": "Victoria",
    "lng": -99.14731430913004,
    "lat": 38.85352664730551
  },
  {
    "name": "Victoria",
    "lng": -96.9850969901441,
    "lat": 28.828540785326197
  },
  {
    "name": "Victoria",
    "lng": -93.65854405518792,
    "lat": 44.86337052630773
  },
  {
    "name": "Victoria",
    "lng": -90.09523706392193,
    "lat": 41.0333679850183
  },
  {
    "name": "Victoria",
    "lng": -90.06012579006388,
    "lat": 35.7574207941492
  },
  {
    "name": "Victoria",
    "lng": -78.2241689191375,
    "lat": 36.99467663561996
  },
  {
    "name": "Victorville",
    "lng": -117.35373185910639,
    "lat": 34.527837432899766
  },
  {
    "name": "Victory",
    "lng": -73.59175804890256,
    "lat": 43.09185938053258
  },
  {
    "name": "Vidalia",
    "lng": -91.44034136113325,
    "lat": 31.566873807560484
  },
  {
    "name": "Vidalia",
    "lng": -82.40315247729173,
    "lat": 32.213344746859555
  },
  {
    "name": "Vidette",
    "lng": -82.24733325380035,
    "lat": 33.03766026893308
  },
  {
    "name": "Vidor",
    "lng": -93.99666331198934,
    "lat": 30.12917703822251
  },
  {
    "name": "Vienna",
    "lng": -97.49985399185525,
    "lat": 44.70343685486758
  },
  {
    "name": "Vienna",
    "lng": -92.64806680933673,
    "lat": 32.60918995155841
  },
  {
    "name": "Vienna",
    "lng": -91.95009590970359,
    "lat": 38.18742037156197
  },
  {
    "name": "Vienna",
    "lng": -88.88703858017935,
    "lat": 37.414349144264726
  },
  {
    "name": "Vienna",
    "lng": -83.78722558789352,
    "lat": 32.0927568002913
  },
  {
    "name": "Vienna",
    "lng": -81.53851306666347,
    "lat": 39.323250133793756
  },
  {
    "name": "Vienna",
    "lng": -81.51767221308721,
    "lat": 39.34314759483479
  },
  {
    "name": "Vienna",
    "lng": -77.25963582167091,
    "lat": 38.89964693948432
  },
  {
    "name": "Vienna",
    "lng": -75.8319619839793,
    "lat": 38.480915142083624
  },
  {
    "name": "Vienna",
    "lng": -70.00300397490474,
    "lat": 44.547428808782335
  },
  {
    "name": "Viking",
    "lng": -96.40673340455531,
    "lat": 48.218205605796314
  },
  {
    "name": "Vilas",
    "lng": -102.44743496244286,
    "lat": 37.37368547888142
  },
  {
    "name": "Vilas",
    "lng": -102.44176797676579,
    "lat": 37.37435196008059
  },
  {
    "name": "Vilas",
    "lng": -97.59594476451126,
    "lat": 44.008317726636314
  },
  {
    "name": "Villa Grove",
    "lng": -88.15998082916236,
    "lat": 39.864477207119
  },
  {
    "name": "Villa Hills",
    "lng": -84.59458379372745,
    "lat": 39.06575195566852
  },
  {
    "name": "Villa Park",
    "lng": -117.81023878516562,
    "lat": 33.818103568006705
  },
  {
    "name": "Villa Park",
    "lng": -87.97793899129557,
    "lat": 41.886393726113766
  },
  {
    "name": "Villa Rica",
    "lng": -84.91809430403909,
    "lat": 33.73135214859497
  },
  {
    "name": "Villa Rica",
    "lng": -84.8914878713126,
    "lat": 33.71019873111468
  },
  {
    "name": "Village of Akron",
    "lng": -78.49752649729072,
    "lat": 43.01809966256985
  },
  {
    "name": "Village of Birch Run",
    "lng": -83.79100834754442,
    "lat": 43.25033696301906
  },
  {
    "name": "Village of Blue Mounds",
    "lng": -89.82716091792102,
    "lat": 43.01755764903795
  },
  {
    "name": "Village of Boonville",
    "lng": -75.33009893912015,
    "lat": 43.4811463061141
  },
  {
    "name": "Village of Chesaning",
    "lng": -84.12006802984875,
    "lat": 43.18482933389147
  },
  {
    "name": "Village of Clarkston",
    "lng": -83.42041700601709,
    "lat": 42.734317731245085
  },
  {
    "name": "Village of Cottage Grove",
    "lng": -89.20524708151646,
    "lat": 43.09212353147599
  },
  {
    "name": "Village of East Hampton",
    "lng": -72.19693082371704,
    "lat": 40.9508147022741
  },
  {
    "name": "Village of Elderon",
    "lng": -89.24766188532044,
    "lat": 44.78410592453888
  },
  {
    "name": "Village of Four Seasons",
    "lng": -92.71880302484993,
    "lat": 38.197748980398096
  },
  {
    "name": "Village of Friendship Heights",
    "lng": -77.08997928713252,
    "lat": 38.963330266452424
  },
  {
    "name": "Village of Galway",
    "lng": -74.03183775726217,
    "lat": 43.018516128223546
  },
  {
    "name": "Village of Germantown",
    "lng": -88.12163603076583,
    "lat": 43.23449266413169
  },
  {
    "name": "Village of Hagaman",
    "lng": -74.15454079642024,
    "lat": 42.97280516412338
  },
  {
    "name": "Village of Jacksonwald",
    "lng": -75.84778928450135,
    "lat": 40.32421835194489
  },
  {
    "name": "Village of Kingston",
    "lng": -89.13058858360166,
    "lat": 43.69324057687157
  },
  {
    "name": "Village of La Valle",
    "lng": -90.13063526240965,
    "lat": 43.58288405007484
  },
  {
    "name": "Village of Lancaster",
    "lng": -78.67014091525824,
    "lat": 42.900732454493216
  },
  {
    "name": "Village of Marquette",
    "lng": -89.13836733750723,
    "lat": 43.74626678070013
  },
  {
    "name": "Village of Menands",
    "lng": -73.72687412145955,
    "lat": 42.69137811396069
  },
  {
    "name": "Village of New Hartford",
    "lng": -75.28863518572793,
    "lat": 43.070994950670666
  },
  {
    "name": "Village of New York Mills",
    "lng": -75.2926488011209,
    "lat": 43.1009365897894
  },
  {
    "name": "Village of Oakley",
    "lng": -84.16816396468484,
    "lat": 43.14324442025144
  },
  {
    "name": "Village of Pewaukee",
    "lng": -88.25236357244265,
    "lat": 43.08539100364376
  },
  {
    "name": "Village of Plover",
    "lng": -89.60302225450471,
    "lat": 44.45974929196292
  },
  {
    "name": "Village of Plover",
    "lng": -89.53635206334707,
    "lat": 44.46041925256461
  },
  {
    "name": "Village of Poland",
    "lng": -75.06142350108155,
    "lat": 43.22588096146104
  },
  {
    "name": "Village of Prairie du Sac",
    "lng": -89.73575747374639,
    "lat": 43.29156414826614
  },
  {
    "name": "Village of Princeton",
    "lng": -89.13047783481565,
    "lat": 43.8526839488399
  },
  {
    "name": "Village of Saint Charles",
    "lng": -84.14736527936375,
    "lat": 43.29919286032726
  },
  {
    "name": "Village of Sebewaing",
    "lng": -83.4507657943674,
    "lat": 43.73197937513501
  },
  {
    "name": "Village of Superior",
    "lng": -92.10837815156584,
    "lat": 46.653209473469545
  },
  {
    "name": "Village of Waterford",
    "lng": -73.67902072321685,
    "lat": 42.791657813584166
  },
  {
    "name": "Village of Weston",
    "lng": -89.54868780316745,
    "lat": 44.890614183034174
  },
  {
    "name": "Village of Woodman",
    "lng": -90.79699715733348,
    "lat": 43.09248399896692
  },
  {
    "name": "Village of Yorkville",
    "lng": -75.27396308462694,
    "lat": 43.1124311287814
  },
  {
    "name": "Village of the Branch",
    "lng": -73.18599906958647,
    "lat": 40.85029288750531
  },
  {
    "name": "Villard",
    "lng": -95.26888050777418,
    "lat": 45.713687891229306
  },
  {
    "name": "Villas",
    "lng": -81.8677660446639,
    "lat": 26.55052025220535
  },
  {
    "name": "Ville Platte",
    "lng": -92.27948114010812,
    "lat": 30.699946869842464
  },
  {
    "name": "Ville Platte",
    "lng": -92.27473329911103,
    "lat": 30.690161321726656
  },
  {
    "name": "Villisca",
    "lng": -94.98113272038023,
    "lat": 40.929880348447355
  },
  {
    "name": "Vilonia",
    "lng": -92.2334763883239,
    "lat": 35.0663868273121
  },
  {
    "name": "Vilonia",
    "lng": -92.2073564189509,
    "lat": 35.08157263058897
  },
  {
    "name": "Vilonia",
    "lng": -92.20735641895088,
    "lat": 35.08157263058897
  },
  {
    "name": "Vina",
    "lng": -88.05610447479324,
    "lat": 34.374431342961635
  },
  {
    "name": "Vinalhaven",
    "lng": -68.83426280532254,
    "lat": 44.03575541078146
  },
  {
    "name": "Vinalhaven",
    "lng": -68.72666548500605,
    "lat": 43.89395280834234
  },
  {
    "name": "Vincennes",
    "lng": -87.51006689260058,
    "lat": 38.674787915022385
  },
  {
    "name": "Vincennes",
    "lng": -87.50540662477744,
    "lat": 38.7247184582935
  },
  {
    "name": "Vincennes",
    "lng": -87.47719916428699,
    "lat": 38.69962451175722
  },
  {
    "name": "Vincent",
    "lng": -94.01854896070918,
    "lat": 42.591656294854786
  },
  {
    "name": "Vine Grove",
    "lng": -85.98096874286014,
    "lat": 37.81273111919816
  },
  {
    "name": "Vineyard",
    "lng": -111.75446104308625,
    "lat": 40.305380691451475
  },
  {
    "name": "Vining",
    "lng": -97.29377432233528,
    "lat": 39.56741691776088
  },
  {
    "name": "Vining",
    "lng": -95.5350113762766,
    "lat": 46.26187319109382
  },
  {
    "name": "Vining",
    "lng": -92.38718651697052,
    "lat": 41.9910536018948
  },
  {
    "name": "Vinita",
    "lng": -95.17738142619584,
    "lat": 36.63655698672641
  },
  {
    "name": "Vinita",
    "lng": -95.16470599310152,
    "lat": 36.62421966601275
  },
  {
    "name": "Vinita",
    "lng": -95.16247852011327,
    "lat": 36.6238360849132
  },
  {
    "name": "Vinita",
    "lng": -95.14119521819525,
    "lat": 36.631167502159094
  },
  {
    "name": "Vinita Park",
    "lng": -90.33924238404866,
    "lat": 38.6887908100739
  },
  {
    "name": "Vinton",
    "lng": -106.60385191583894,
    "lat": 31.949345850647525
  },
  {
    "name": "Vinton",
    "lng": -106.59353435082491,
    "lat": 31.959821997454068
  },
  {
    "name": "Vinton",
    "lng": -106.58027854248473,
    "lat": 31.95822243419973
  },
  {
    "name": "Vinton",
    "lng": -93.58182740944486,
    "lat": 30.195859392704854
  },
  {
    "name": "Vinton",
    "lng": -93.5816902967945,
    "lat": 30.177408270895953
  },
  {
    "name": "Vinton",
    "lng": -92.02605132901972,
    "lat": 42.163797328826824
  },
  {
    "name": "Vinton",
    "lng": -82.33740862267267,
    "lat": 38.97757049206424
  },
  {
    "name": "Vinton",
    "lng": -79.88874471917775,
    "lat": 37.274597503962475
  },
  {
    "name": "Viola",
    "lng": -97.6443256562116,
    "lat": 37.48271631326109
  },
  {
    "name": "Viola",
    "lng": -91.98666754828379,
    "lat": 36.39659138728026
  },
  {
    "name": "Viola",
    "lng": -90.67313256497886,
    "lat": 43.5072730472436
  },
  {
    "name": "Viola",
    "lng": -90.5865334173991,
    "lat": 41.20510065481965
  },
  {
    "name": "Viola",
    "lng": -85.86057474089203,
    "lat": 35.53870812626203
  },
  {
    "name": "Virden",
    "lng": -109.00150307819882,
    "lat": 32.68892572921192
  },
  {
    "name": "Virden",
    "lng": -89.77140242648747,
    "lat": 39.505073075705226
  },
  {
    "name": "Virden",
    "lng": -89.76528849692997,
    "lat": 39.524891748025475
  },
  {
    "name": "Virden",
    "lng": -89.76367214174185,
    "lat": 39.520281635096815
  },
  {
    "name": "Virgil",
    "lng": -98.42839870160257,
    "lat": 44.29058170868519
  },
  {
    "name": "Virgil",
    "lng": -96.0115961835274,
    "lat": 37.98069903752037
  },
  {
    "name": "Virgil",
    "lng": -88.52921528660218,
    "lat": 41.9555329212912
  },
  {
    "name": "Virgilina",
    "lng": -78.77553323381349,
    "lat": 36.54629844262183
  },
  {
    "name": "Virgin",
    "lng": -113.20756139150063,
    "lat": 37.20657765692155
  },
  {
    "name": "Virginia",
    "lng": -96.49882285745994,
    "lat": 40.245857013003345
  },
  {
    "name": "Virginia",
    "lng": -92.51211364707679,
    "lat": 47.51723942802015
  },
  {
    "name": "Virginia",
    "lng": -90.21070035661066,
    "lat": 39.952492292827316
  },
  {
    "name": "Virginia",
    "lng": -78.6616068877798,
    "lat": 37.52030111925748
  },
  {
    "name": "Virginia Beach",
    "lng": -76.02900478247581,
    "lat": 36.76607580502875
  },
  {
    "name": "Virginia City",
    "lng": -111.93578237771273,
    "lat": 45.296426608115354
  },
  {
    "name": "Virginia Gardens",
    "lng": -80.29752735006677,
    "lat": 25.809522449031586
  },
  {
    "name": "Viroqua",
    "lng": -90.88599979366592,
    "lat": 43.5602929817953
  },
  {
    "name": "Visalia",
    "lng": -119.40601879566505,
    "lat": 36.30475591055979
  },
  {
    "name": "Visalia",
    "lng": -119.31958080486727,
    "lat": 36.32733768478542
  },
  {
    "name": "Vista",
    "lng": -117.23856854706743,
    "lat": 33.18956364920319
  },
  {
    "name": "Vista",
    "lng": -93.66369445741995,
    "lat": 37.989061883737975
  },
  {
    "name": "Vivian",
    "lng": -93.9862854083834,
    "lat": 32.87141421271621
  },
  {
    "name": "Volcano CDP",
    "lng": -155.25207584969624,
    "lat": 19.477971010336756
  },
  {
    "name": "Volente",
    "lng": -97.90799386600699,
    "lat": 30.44535899546392
  },
  {
    "name": "Volga",
    "lng": -96.92222959319784,
    "lat": 44.32372171005274
  },
  {
    "name": "Volga",
    "lng": -91.54239979042171,
    "lat": 42.80300035203462
  },
  {
    "name": "Volin",
    "lng": -97.18121100158783,
    "lat": 42.95865548763469
  },
  {
    "name": "Volo",
    "lng": -88.17995248634921,
    "lat": 42.32316136688845
  },
  {
    "name": "Volo",
    "lng": -88.15949679846653,
    "lat": 42.33023812773974
  },
  {
    "name": "Voltaire",
    "lng": -100.84487972211447,
    "lat": 48.01928480427962
  },
  {
    "name": "Voluntown",
    "lng": -71.83240961464844,
    "lat": 41.57997359623301
  },
  {
    "name": "Von Ormy",
    "lng": -98.65542271008972,
    "lat": 29.280368271887767
  },
  {
    "name": "Vonore",
    "lng": -84.23538304612019,
    "lat": 35.5975304487845
  },
  {
    "name": "Voorheesville",
    "lng": -73.93492377377164,
    "lat": 42.651763561096416
  },
  {
    "name": "Vredenburgh",
    "lng": -87.31776746072556,
    "lat": 31.82370724045494
  },
  {
    "name": "WaKeeney",
    "lng": -99.88183145013497,
    "lat": 39.0237039123197
  },
  {
    "name": "Wabash",
    "lng": -85.83258080830215,
    "lat": 40.802945081740546
  },
  {
    "name": "Wabasha",
    "lng": -92.04199560785959,
    "lat": 44.370650449922415
  },
  {
    "name": "Wabasso",
    "lng": -95.25541634780292,
    "lat": 44.40251851055642
  },
  {
    "name": "Wabbaseka",
    "lng": -91.79268060332932,
    "lat": 34.35926242085863
  },
  {
    "name": "Wachapreague",
    "lng": -75.68962944643165,
    "lat": 37.60633211930762
  },
  {
    "name": "Waco",
    "lng": -97.46147889098894,
    "lat": 40.89693018321857
  },
  {
    "name": "Waco",
    "lng": -97.18838000615744,
    "lat": 31.559472163201786
  },
  {
    "name": "Waco",
    "lng": -94.6002794991004,
    "lat": 37.24695344977743
  },
  {
    "name": "Waco",
    "lng": -85.21015477194801,
    "lat": 33.708417565361316
  },
  {
    "name": "Waco",
    "lng": -85.18599123094894,
    "lat": 33.701699784832705
  },
  {
    "name": "Waco",
    "lng": -81.42867483708835,
    "lat": 35.36166426139181
  },
  {
    "name": "Waconia",
    "lng": -93.79067106651596,
    "lat": 44.841627534934354
  },
  {
    "name": "Waddington",
    "lng": -75.19676271389802,
    "lat": 44.85908725054189
  },
  {
    "name": "Wade",
    "lng": -78.7370381270167,
    "lat": 35.16381271162526
  },
  {
    "name": "Wade",
    "lng": -68.23830913779233,
    "lat": 46.79095432974634
  },
  {
    "name": "Wadena",
    "lng": -95.12907571973552,
    "lat": 46.44454101231447
  },
  {
    "name": "Wadena",
    "lng": -91.65935983960402,
    "lat": 42.83975365131603
  },
  {
    "name": "Wadesboro",
    "lng": -80.07446257069401,
    "lat": 34.963339850745676
  },
  {
    "name": "Wadley",
    "lng": -85.56806120713999,
    "lat": 33.123911577474516
  },
  {
    "name": "Wadley",
    "lng": -82.40137298471167,
    "lat": 32.86632045719247
  },
  {
    "name": "Wadsworth",
    "lng": -119.2901046895786,
    "lat": 39.632165820187076
  },
  {
    "name": "Wadsworth",
    "lng": -87.9619973386792,
    "lat": 42.47257581631329
  },
  {
    "name": "Wadsworth",
    "lng": -87.95025908222998,
    "lat": 42.484259857064195
  },
  {
    "name": "Wadsworth",
    "lng": -87.94448226464321,
    "lat": 42.48347848326318
  },
  {
    "name": "Wadsworth",
    "lng": -87.93978229220964,
    "lat": 42.48557303519181
  },
  {
    "name": "Wadsworth",
    "lng": -87.92513988893862,
    "lat": 42.466222916963645
  },
  {
    "name": "Wadsworth",
    "lng": -87.91787649169463,
    "lat": 42.44177472548368
  },
  {
    "name": "Wadsworth",
    "lng": -81.73222450115236,
    "lat": 41.02848374825908
  },
  {
    "name": "Wagener",
    "lng": -81.36345323695402,
    "lat": 33.652608949746416
  },
  {
    "name": "Waggoner",
    "lng": -89.65275023530842,
    "lat": 39.37761219258187
  },
  {
    "name": "Wagner",
    "lng": -98.29902811015269,
    "lat": 43.073697695882615
  },
  {
    "name": "Wagon Mound",
    "lng": -104.70921667038714,
    "lat": 36.004562678878365
  },
  {
    "name": "Wagoner",
    "lng": -95.3791993138116,
    "lat": 35.96391132146402
  },
  {
    "name": "Wagram",
    "lng": -79.3651999923773,
    "lat": 34.889098760985796
  },
  {
    "name": "Wahkon",
    "lng": -93.52235803865082,
    "lat": 46.12049537742494
  },
  {
    "name": "Wahoo",
    "lng": -96.62084743807956,
    "lat": 41.21514565150927
  },
  {
    "name": "Wahoo",
    "lng": -96.61921050671528,
    "lat": 41.19481907364963
  },
  {
    "name": "Wahoo",
    "lng": -96.59682059876185,
    "lat": 41.23782732383353
  },
  {
    "name": "Wahpeton",
    "lng": -96.61063842200511,
    "lat": 46.27119852891192
  },
  {
    "name": "Wahpeton",
    "lng": -95.1742208966901,
    "lat": 43.37423800504598
  },
  {
    "name": "Waianae",
    "lng": -158.18117462289635,
    "lat": 21.445175325098756
  },
  {
    "name": "Waikoloa Village",
    "lng": -155.7887191336848,
    "lat": 19.943948100813124
  },
  {
    "name": "Waimea/Kamuela CDP",
    "lng": -155.63796031914458,
    "lat": 20.012461815586235
  },
  {
    "name": "Wainaku CDP",
    "lng": -155.09760449558095,
    "lat": 19.74473449325826
  },
  {
    "name": "Wainwright",
    "lng": -160.0091430198687,
    "lat": 70.64832931432151
  },
  {
    "name": "Wainwright",
    "lng": -95.56556609610807,
    "lat": 35.613515928569285
  },
  {
    "name": "Waite",
    "lng": -67.63170364503873,
    "lat": 45.35750802604318
  },
  {
    "name": "Waite Hill",
    "lng": -81.38586772605349,
    "lat": 41.612461992509694
  },
  {
    "name": "Waite Park",
    "lng": -94.26067179999248,
    "lat": 45.51301546296071
  },
  {
    "name": "Waitsburg",
    "lng": -118.15124167646663,
    "lat": 46.26876706995884
  },
  {
    "name": "Wakarusa",
    "lng": -86.01253884899725,
    "lat": 41.53391856528523
  },
  {
    "name": "Wake Forest",
    "lng": -78.54876176682627,
    "lat": 35.97654733663642
  },
  {
    "name": "Wake Forest",
    "lng": -78.54717840640302,
    "lat": 35.977219508516185
  },
  {
    "name": "Wake Forest",
    "lng": -78.54187206095916,
    "lat": 35.997057299290745
  },
  {
    "name": "Wake Forest",
    "lng": -78.54020637241479,
    "lat": 35.99545625283242
  },
  {
    "name": "Wake Forest",
    "lng": -78.5289344112021,
    "lat": 35.922962062923155
  },
  {
    "name": "Wake Forest",
    "lng": -78.523324929534,
    "lat": 35.919359585048795
  },
  {
    "name": "Wake Forest",
    "lng": -78.5214894345125,
    "lat": 35.91203027253374
  },
  {
    "name": "Wake Forest",
    "lng": -78.52116097773005,
    "lat": 35.92205320654667
  },
  {
    "name": "Wake Forest",
    "lng": -78.51579594335288,
    "lat": 36.00491024462329
  },
  {
    "name": "Wake Forest",
    "lng": -78.51367507623138,
    "lat": 35.966541102370215
  },
  {
    "name": "Wake Forest",
    "lng": -78.48448291084384,
    "lat": 35.983162267518004
  },
  {
    "name": "Wake Forest",
    "lng": -78.47643212300174,
    "lat": 35.95099190257859
  },
  {
    "name": "Wakefield",
    "lng": -97.03398760288405,
    "lat": 39.214334607164
  },
  {
    "name": "Wakefield",
    "lng": -97.01639583704686,
    "lat": 39.21648491213747
  },
  {
    "name": "Wakefield",
    "lng": -96.86755033432517,
    "lat": 42.26662471135937
  },
  {
    "name": "Wakefield",
    "lng": -89.93345702389473,
    "lat": 46.47669424067422
  },
  {
    "name": "Wakefield",
    "lng": -76.9874962885492,
    "lat": 36.96863214150567
  },
  {
    "name": "Wakefield",
    "lng": -71.06558974491733,
    "lat": 42.50350917422214
  },
  {
    "name": "Wakeman",
    "lng": -82.40292334397095,
    "lat": 41.25297331345213
  },
  {
    "name": "Wakita",
    "lng": -97.9237657807612,
    "lat": 36.88170671531336
  },
  {
    "name": "Wakonda",
    "lng": -97.10597429977713,
    "lat": 43.008115817476614
  },
  {
    "name": "Walbridge",
    "lng": -83.4921575787962,
    "lat": 41.58647901735219
  },
  {
    "name": "Walcott",
    "lng": -96.93755025404023,
    "lat": 46.550473858576915
  },
  {
    "name": "Walcott",
    "lng": -90.77564036472108,
    "lat": 41.59722464673377
  },
  {
    "name": "Walcott",
    "lng": -90.7582152915227,
    "lat": 41.61514320593243
  },
  {
    "name": "Walden",
    "lng": -106.28127319114812,
    "lat": 40.731547141987015
  },
  {
    "name": "Walden",
    "lng": -85.30907995885627,
    "lat": 35.16395885112698
  },
  {
    "name": "Walden",
    "lng": -74.18787838907691,
    "lat": 41.560270922287444
  },
  {
    "name": "Waldenburg",
    "lng": -90.93455667982683,
    "lat": 35.56527036083804
  },
  {
    "name": "Waldo",
    "lng": -98.79794432983374,
    "lat": 39.11993905275229
  },
  {
    "name": "Waldo",
    "lng": -93.29561028592202,
    "lat": 33.352371156278565
  },
  {
    "name": "Waldo",
    "lng": -87.94816545548186,
    "lat": 43.67658307841911
  },
  {
    "name": "Waldo",
    "lng": -86.03599154087998,
    "lat": 33.38919631742342
  },
  {
    "name": "Waldo",
    "lng": -83.08483946665102,
    "lat": 40.4597608798101
  },
  {
    "name": "Waldo",
    "lng": -69.09097439226429,
    "lat": 44.474790781015
  },
  {
    "name": "Waldoboro",
    "lng": -69.36956264955485,
    "lat": 44.10987286970125
  },
  {
    "name": "Waldorf",
    "lng": -93.69755758716843,
    "lat": 43.933149063731996
  },
  {
    "name": "Waldron",
    "lng": -98.18254539483324,
    "lat": 37.00175243979708
  },
  {
    "name": "Waldron",
    "lng": -94.09514999226161,
    "lat": 34.902398429602066
  },
  {
    "name": "Waldron",
    "lng": -84.4189027812759,
    "lat": 41.72415535330388
  },
  {
    "name": "Wales",
    "lng": -168.08238816275554,
    "lat": 65.61453706482503
  },
  {
    "name": "Wales",
    "lng": -111.63607566132607,
    "lat": 39.4851807641461
  },
  {
    "name": "Wales",
    "lng": -98.60083094184924,
    "lat": 48.89515841643908
  },
  {
    "name": "Wales",
    "lng": -88.3737669691682,
    "lat": 43.004319902759356
  },
  {
    "name": "Wales",
    "lng": -72.23328427918598,
    "lat": 42.06230810538282
  },
  {
    "name": "Wales",
    "lng": -70.05399476196222,
    "lat": 44.163964626236584
  },
  {
    "name": "Waleska",
    "lng": -84.55054037835421,
    "lat": 34.31702684293953
  },
  {
    "name": "Walford",
    "lng": -91.83856206666667,
    "lat": 41.87605193466755
  },
  {
    "name": "Walford",
    "lng": -91.83010662038745,
    "lat": 41.88005070732854
  },
  {
    "name": "Walhalla",
    "lng": -97.91774870651105,
    "lat": 48.92114719479839
  },
  {
    "name": "Walhalla",
    "lng": -83.06142031963697,
    "lat": 34.770134724110946
  },
  {
    "name": "Walhalla",
    "lng": -83.05054925634887,
    "lat": 34.74443432120629
  },
  {
    "name": "Walker",
    "lng": -94.61249602337391,
    "lat": 47.098290214575975
  },
  {
    "name": "Walker",
    "lng": -94.58204557270498,
    "lat": 47.09308263372388
  },
  {
    "name": "Walker",
    "lng": -94.57360142502674,
    "lat": 47.06920491956647
  },
  {
    "name": "Walker",
    "lng": -94.23057884574658,
    "lat": 37.898903307258855
  },
  {
    "name": "Walker",
    "lng": -91.78083495242575,
    "lat": 42.28732460013892
  },
  {
    "name": "Walker",
    "lng": -90.86523101662058,
    "lat": 30.484600319594847
  },
  {
    "name": "Walker",
    "lng": -85.74429618991947,
    "lat": 42.98571762876717
  },
  {
    "name": "Walkersville",
    "lng": -77.35639089283607,
    "lat": 39.48303510331479
  },
  {
    "name": "Walkerton",
    "lng": -86.48243243105884,
    "lat": 41.4656929547857
  },
  {
    "name": "Walkertown",
    "lng": -80.16553255624144,
    "lat": 36.15822680535538
  },
  {
    "name": "Walkerville",
    "lng": -112.53974988431179,
    "lat": 46.0367701685895
  },
  {
    "name": "Walkerville",
    "lng": -86.12583592859727,
    "lat": 43.71421756088494
  },
  {
    "name": "Wall",
    "lng": -102.24224997942119,
    "lat": 43.991478266623545
  },
  {
    "name": "Wall Lake",
    "lng": -95.0929887196061,
    "lat": 42.26714533044085
  },
  {
    "name": "Walla Walla",
    "lng": -118.39804064221904,
    "lat": 46.07462943854727
  },
  {
    "name": "Walla Walla",
    "lng": -118.39374173901284,
    "lat": 46.08189607851667
  },
  {
    "name": "Walla Walla",
    "lng": -118.37511541985658,
    "lat": 46.07455065956305
  },
  {
    "name": "Walla Walla",
    "lng": -118.33259958306053,
    "lat": 46.06392323131913
  },
  {
    "name": "Walla Walla",
    "lng": -118.25007274297994,
    "lat": 46.093393652618495
  },
  {
    "name": "Wallace",
    "lng": -115.9225421461708,
    "lat": 47.473578164396706
  },
  {
    "name": "Wallace",
    "lng": -101.59301156803406,
    "lat": 38.91264951214363
  },
  {
    "name": "Wallace",
    "lng": -101.16447715226316,
    "lat": 40.837577988973074
  },
  {
    "name": "Wallace",
    "lng": -97.4781285804187,
    "lat": 45.08445860028522
  },
  {
    "name": "Wallace",
    "lng": -87.14799737256033,
    "lat": 39.98754409787347
  },
  {
    "name": "Wallace",
    "lng": -78.04468792384903,
    "lat": 34.74541635297215
  },
  {
    "name": "Wallace",
    "lng": -77.9937007966142,
    "lat": 34.737655882813385
  },
  {
    "name": "Wallace",
    "lng": -77.98221101860642,
    "lat": 34.72395903871147
  },
  {
    "name": "Wallace",
    "lng": -77.97458697269165,
    "lat": 34.74645919419564
  },
  {
    "name": "Wallace",
    "lng": -77.97377547445262,
    "lat": 34.74491554595562
  },
  {
    "name": "Wallagrass",
    "lng": -68.63097080711209,
    "lat": 47.15030644685108
  },
  {
    "name": "Wallburg",
    "lng": -80.14530312261526,
    "lat": 36.008727427841244
  },
  {
    "name": "Walled Lake",
    "lng": -83.47858645098064,
    "lat": 42.537882271608304
  },
  {
    "name": "Waller",
    "lng": -122.36504515777189,
    "lat": 47.202325878087784
  },
  {
    "name": "Waller",
    "lng": -95.92997181336388,
    "lat": 30.053223873898716
  },
  {
    "name": "Waller",
    "lng": -95.92309090433024,
    "lat": 30.060187107639443
  },
  {
    "name": "Wallingford",
    "lng": -94.79217030120653,
    "lat": 43.32026965892428
  },
  {
    "name": "Wallingford",
    "lng": -72.80388443639309,
    "lat": 41.45880482673542
  },
  {
    "name": "Wallins Creek",
    "lng": -83.4145164775897,
    "lat": 36.827369678022876
  },
  {
    "name": "Wallis",
    "lng": -96.06373578838543,
    "lat": 29.631653389104095
  },
  {
    "name": "Walls",
    "lng": -90.15553918023832,
    "lat": 34.92511000497916
  },
  {
    "name": "Wallsburg",
    "lng": -111.41928102174579,
    "lat": 40.386929495189506
  },
  {
    "name": "Walnut",
    "lng": -117.85928895275359,
    "lat": 34.033430411377715
  },
  {
    "name": "Walnut",
    "lng": -95.21981938994712,
    "lat": 41.48956962694729
  },
  {
    "name": "Walnut",
    "lng": -95.07508574018688,
    "lat": 37.600211085110935
  },
  {
    "name": "Walnut",
    "lng": -89.59076116278578,
    "lat": 41.55698745698424
  },
  {
    "name": "Walnut",
    "lng": -88.92367922748042,
    "lat": 34.94969215479968
  },
  {
    "name": "Walnut Cove",
    "lng": -80.14070018026096,
    "lat": 36.294254203871226
  },
  {
    "name": "Walnut Creek",
    "lng": -77.87010606086916,
    "lat": 35.30518043923621
  },
  {
    "name": "Walnut Grove",
    "lng": -95.46936389114026,
    "lat": 44.22502587854779
  },
  {
    "name": "Walnut Grove",
    "lng": -93.55083758475038,
    "lat": 37.403591529808814
  },
  {
    "name": "Walnut Grove",
    "lng": -93.54784626137457,
    "lat": 37.411936243768324
  },
  {
    "name": "Walnut Grove",
    "lng": -89.45782612869513,
    "lat": 32.5931705736575
  },
  {
    "name": "Walnut Grove",
    "lng": -83.86409166751133,
    "lat": 33.73883629806803
  },
  {
    "name": "Walnut Grove",
    "lng": -83.85025259565842,
    "lat": 33.74540889697349
  },
  {
    "name": "Walnut Hill",
    "lng": -89.04460535999277,
    "lat": 38.477730721315176
  },
  {
    "name": "Walnut Ridge",
    "lng": -90.94666418504106,
    "lat": 36.084887474249626
  },
  {
    "name": "Walnut Springs",
    "lng": -97.7508191652576,
    "lat": 32.05617724876421
  },
  {
    "name": "Walsh",
    "lng": -102.2799550263792,
    "lat": 37.38609281367227
  },
  {
    "name": "Walshville",
    "lng": -89.61893332958726,
    "lat": 39.06897458300104
  },
  {
    "name": "Walstonburg",
    "lng": -77.6980082679798,
    "lat": 35.5960939972898
  },
  {
    "name": "Walterboro",
    "lng": -80.67501137065348,
    "lat": 32.90078279921237
  },
  {
    "name": "Walters",
    "lng": -98.35651319558671,
    "lat": 34.36119704412514
  },
  {
    "name": "Walters",
    "lng": -93.67337943920181,
    "lat": 43.60470847685816
  },
  {
    "name": "Walthall",
    "lng": -89.27874143967769,
    "lat": 33.60693554405003
  },
  {
    "name": "Waltham",
    "lng": -92.87470635685831,
    "lat": 43.81931215319866
  },
  {
    "name": "Waltham",
    "lng": -71.24231433775536,
    "lat": 42.388927951021124
  },
  {
    "name": "Waltham",
    "lng": -68.33715581870543,
    "lat": 44.685779712926006
  },
  {
    "name": "Walthill",
    "lng": -96.4929112899528,
    "lat": 42.14885509193629
  },
  {
    "name": "Walthourville",
    "lng": -81.62177471383828,
    "lat": 31.771580073763122
  },
  {
    "name": "Walton",
    "lng": -97.25816926095922,
    "lat": 38.11930612923281
  },
  {
    "name": "Walton",
    "lng": -86.24427947384272,
    "lat": 40.66229207970652
  },
  {
    "name": "Walton",
    "lng": -84.61543867013556,
    "lat": 38.864329082049885
  },
  {
    "name": "Walton",
    "lng": -84.5962824013849,
    "lat": 38.880041244362125
  },
  {
    "name": "Walton",
    "lng": -75.13041814232673,
    "lat": 42.16879843244812
  },
  {
    "name": "Walton Hills",
    "lng": -81.55550477209526,
    "lat": 41.36616741146293
  },
  {
    "name": "Waltonville",
    "lng": -89.03803344189257,
    "lat": 38.21398232266068
  },
  {
    "name": "Walworth",
    "lng": -88.59672326460847,
    "lat": 42.53114123604146
  },
  {
    "name": "Wamac",
    "lng": -89.14564965030284,
    "lat": 38.49728974376531
  },
  {
    "name": "Wamego",
    "lng": -96.31080135379618,
    "lat": 39.20547627365412
  },
  {
    "name": "Wamego",
    "lng": -96.29397920944267,
    "lat": 39.19793609949799
  },
  {
    "name": "Wampsville",
    "lng": -75.70975150183214,
    "lat": 43.077637459209505
  },
  {
    "name": "Wamsutter",
    "lng": -107.97903271294687,
    "lat": 41.667901805310926
  },
  {
    "name": "Wanamingo",
    "lng": -92.78727802138313,
    "lat": 44.302711376555415
  },
  {
    "name": "Wanatah",
    "lng": -86.892560478006,
    "lat": 41.43040551528975
  },
  {
    "name": "Wanda",
    "lng": -95.2130401576901,
    "lat": 44.31491822150065
  },
  {
    "name": "Wanette",
    "lng": -97.03171088326718,
    "lat": 34.9625895379227
  },
  {
    "name": "Wann",
    "lng": -95.80511743104357,
    "lat": 36.91504646074372
  },
  {
    "name": "Wapakoneta",
    "lng": -84.19123264780144,
    "lat": 40.565706029597386
  },
  {
    "name": "Wapanucka",
    "lng": -96.42562344921838,
    "lat": 34.37316990596794
  },
  {
    "name": "Wapato",
    "lng": -120.42173459194807,
    "lat": 46.44362430796278
  },
  {
    "name": "Wapella",
    "lng": -88.96118550447893,
    "lat": 40.22177063809676
  },
  {
    "name": "Wapello",
    "lng": -91.18783147914642,
    "lat": 41.17735969483259
  },
  {
    "name": "Wappingers Falls",
    "lng": -73.91786024768203,
    "lat": 41.59866817051024
  },
  {
    "name": "War",
    "lng": -81.68037329564629,
    "lat": 37.30445803692426
  },
  {
    "name": "Warba",
    "lng": -93.26582802326136,
    "lat": 47.13675678716246
  },
  {
    "name": "Ward",
    "lng": -105.51361821869698,
    "lat": 40.07204182311185
  },
  {
    "name": "Ward",
    "lng": -96.46259191125797,
    "lat": 44.15481292763463
  },
  {
    "name": "Ward",
    "lng": -91.95735986850359,
    "lat": 35.01362021803367
  },
  {
    "name": "Ward",
    "lng": -81.73133401249245,
    "lat": 33.85729769007789
  },
  {
    "name": "Wardell",
    "lng": -89.81749124447546,
    "lat": 36.35150538641274
  },
  {
    "name": "Wardell",
    "lng": -89.81610932354081,
    "lat": 36.35876631366461
  },
  {
    "name": "Warden",
    "lng": -119.05249781219871,
    "lat": 46.967619158217495
  },
  {
    "name": "Wardensville",
    "lng": -78.59322883348142,
    "lat": 39.076814830816154
  },
  {
    "name": "Wardner",
    "lng": -116.13567264570653,
    "lat": 47.518973828415284
  },
  {
    "name": "Wardsville",
    "lng": -92.17851414074848,
    "lat": 38.48815507469621
  },
  {
    "name": "Ware",
    "lng": -72.284338045553,
    "lat": 42.28066113893926
  },
  {
    "name": "Ware Shoals",
    "lng": -82.24208607457817,
    "lat": 34.39224508693495
  },
  {
    "name": "Warfield",
    "lng": -82.42210782158988,
    "lat": 37.84145168393008
  },
  {
    "name": "Warm River",
    "lng": -111.31999822815514,
    "lat": 44.11883020699623
  },
  {
    "name": "Warm Springs",
    "lng": -84.67660788167598,
    "lat": 32.88522011892426
  },
  {
    "name": "Warner",
    "lng": -98.4954952761895,
    "lat": 45.3249706532212
  },
  {
    "name": "Warner",
    "lng": -95.30978642949002,
    "lat": 35.49471933583503
  },
  {
    "name": "Warner",
    "lng": -95.30878094662444,
    "lat": 35.47789423225582
  },
  {
    "name": "Warner",
    "lng": -71.82889041268321,
    "lat": 43.2819939649426
  },
  {
    "name": "Warner Robins",
    "lng": -83.7453912781584,
    "lat": 32.621770099721246
  },
  {
    "name": "Warner Robins",
    "lng": -83.744345386593,
    "lat": 32.61603193995924
  },
  {
    "name": "Warner Robins",
    "lng": -83.71730279297918,
    "lat": 32.62601406937057
  },
  {
    "name": "Warner Robins",
    "lng": -83.71176138293127,
    "lat": 32.60425586419356
  },
  {
    "name": "Warner Robins",
    "lng": -83.68565391740323,
    "lat": 32.60362326333499
  },
  {
    "name": "Warner Robins",
    "lng": -83.68530294538473,
    "lat": 32.602575012544335
  },
  {
    "name": "Warner Robins",
    "lng": -83.68110732715365,
    "lat": 32.56986143729736
  },
  {
    "name": "Warner Robins",
    "lng": -83.67892617908022,
    "lat": 32.555355134975336
  },
  {
    "name": "Warner Robins",
    "lng": -83.67853759125832,
    "lat": 32.57692902869693
  },
  {
    "name": "Warner Robins",
    "lng": -83.67810958052156,
    "lat": 32.53474291017903
  },
  {
    "name": "Warner Robins",
    "lng": -83.67604919791685,
    "lat": 32.54683226276573
  },
  {
    "name": "Warner Robins",
    "lng": -83.67393555101634,
    "lat": 32.555243245256186
  },
  {
    "name": "Warner Robins",
    "lng": -83.67381654998024,
    "lat": 32.573639478140514
  },
  {
    "name": "Warner Robins",
    "lng": -83.67213031733328,
    "lat": 32.58062542752278
  },
  {
    "name": "Warner Robins",
    "lng": -83.670934114829,
    "lat": 32.5301325561806
  },
  {
    "name": "Warner Robins",
    "lng": -83.66402345726873,
    "lat": 32.54159260563671
  },
  {
    "name": "Warner Robins",
    "lng": -83.66310462993808,
    "lat": 32.553971430599965
  },
  {
    "name": "Warner Robins",
    "lng": -83.66154643199022,
    "lat": 32.61703267694689
  },
  {
    "name": "Warner Robins",
    "lng": -83.66094308001607,
    "lat": 32.55862191436197
  },
  {
    "name": "Warner Robins",
    "lng": -83.65989600523908,
    "lat": 32.56116762792994
  },
  {
    "name": "Warner Robins",
    "lng": -83.6590533814517,
    "lat": 32.56183283915888
  },
  {
    "name": "Warner Robins",
    "lng": -83.65898972869692,
    "lat": 32.56021360175548
  },
  {
    "name": "Warner Robins",
    "lng": -83.65784268688476,
    "lat": 32.65472997501379
  },
  {
    "name": "Warner Robins",
    "lng": -83.65680605615721,
    "lat": 32.55701345576801
  },
  {
    "name": "Warner Robins",
    "lng": -83.6546403288602,
    "lat": 32.56007932448618
  },
  {
    "name": "Warner Robins",
    "lng": -83.65101761850552,
    "lat": 32.60180735156642
  },
  {
    "name": "Warner Robins",
    "lng": -83.64958505322356,
    "lat": 32.54728426402351
  },
  {
    "name": "Warner Robins",
    "lng": -83.64910014768616,
    "lat": 32.65268789105035
  },
  {
    "name": "Warner Robins",
    "lng": -83.64559050587343,
    "lat": 32.54763301439398
  },
  {
    "name": "Warner Robins",
    "lng": -83.64514129054193,
    "lat": 32.55027658668924
  },
  {
    "name": "Warner Robins",
    "lng": -83.64486030416874,
    "lat": 32.548105537074974
  },
  {
    "name": "Warner Robins",
    "lng": -83.64445250897445,
    "lat": 32.548264379613656
  },
  {
    "name": "Warner Robins",
    "lng": -83.64428129212867,
    "lat": 32.55081157084522
  },
  {
    "name": "Warner Robins",
    "lng": -83.64418618964665,
    "lat": 32.548363110874774
  },
  {
    "name": "Warner Robins",
    "lng": -83.64413372251902,
    "lat": 32.54917348758297
  },
  {
    "name": "Warner Robins",
    "lng": -83.64376769893836,
    "lat": 32.54970364938789
  },
  {
    "name": "Warner Robins",
    "lng": -83.6433411115424,
    "lat": 32.55082295583677
  },
  {
    "name": "Warner Robins",
    "lng": -83.64299112864889,
    "lat": 32.547141353655164
  },
  {
    "name": "Warner Robins",
    "lng": -83.60965429823247,
    "lat": 32.556273348266345
  },
  {
    "name": "Warner Robins",
    "lng": -83.6047936472648,
    "lat": 32.53077287084053
  },
  {
    "name": "Warner Robins",
    "lng": -83.59616130074097,
    "lat": 32.59181560891233
  },
  {
    "name": "Warner Robins",
    "lng": -83.59609760871565,
    "lat": 32.592532563229994
  },
  {
    "name": "Warner Robins",
    "lng": -83.59186388259494,
    "lat": 32.56458364062098
  },
  {
    "name": "Warner Robins",
    "lng": -83.58817303302904,
    "lat": 32.58922740568243
  },
  {
    "name": "Warner Robins",
    "lng": -83.58030511395957,
    "lat": 32.568501576061266
  },
  {
    "name": "Warr Acres",
    "lng": -97.61830370980363,
    "lat": 35.52833840337618
  },
  {
    "name": "Warren",
    "lng": -96.76960688271048,
    "lat": 48.19604517967846
  },
  {
    "name": "Warren",
    "lng": -92.0678822424576,
    "lat": 33.611192925335445
  },
  {
    "name": "Warren",
    "lng": -89.9912287295774,
    "lat": 42.49482713107054
  },
  {
    "name": "Warren",
    "lng": -85.42457342253523,
    "lat": 40.68786029614369
  },
  {
    "name": "Warren",
    "lng": -83.02702183027196,
    "lat": 42.49344719453566
  },
  {
    "name": "Warren",
    "lng": -80.81774983281096,
    "lat": 41.23889611207628
  },
  {
    "name": "Warren",
    "lng": -80.77197300762575,
    "lat": 41.22381646153743
  },
  {
    "name": "Warren",
    "lng": -79.1440791670982,
    "lat": 41.84316594050167
  },
  {
    "name": "Warren",
    "lng": -73.34487628608905,
    "lat": 41.740578716776206
  },
  {
    "name": "Warren",
    "lng": -72.2006199477673,
    "lat": 42.20018389535042
  },
  {
    "name": "Warren",
    "lng": -71.26150452376793,
    "lat": 41.72879611583685
  },
  {
    "name": "Warren",
    "lng": -69.24481174045978,
    "lat": 44.12951394419697
  },
  {
    "name": "Warren City",
    "lng": -94.90574934864618,
    "lat": 32.55274256452702
  },
  {
    "name": "Warren Park",
    "lng": -86.05586878326595,
    "lat": 39.78611667902259
  },
  {
    "name": "Warren Park",
    "lng": -86.05210695824567,
    "lat": 39.7848902865263
  },
  {
    "name": "Warren Park",
    "lng": -86.05148831345377,
    "lat": 39.77844562506909
  },
  {
    "name": "Warren Park",
    "lng": -86.04795259779826,
    "lat": 39.785269992519595
  },
  {
    "name": "Warrens",
    "lng": -90.54012392525608,
    "lat": 44.12639784899801
  },
  {
    "name": "Warrens",
    "lng": -90.53513359760748,
    "lat": 44.12183901922498
  },
  {
    "name": "Warrens",
    "lng": -90.534754466412,
    "lat": 44.13745236995114
  },
  {
    "name": "Warrens",
    "lng": -90.513029163726,
    "lat": 44.12871255842571
  },
  {
    "name": "Warrensburg",
    "lng": -93.7283482589634,
    "lat": 38.76178577662987
  },
  {
    "name": "Warrensburg",
    "lng": -89.0627605838475,
    "lat": 39.93195293816459
  },
  {
    "name": "Warrensville Heights",
    "lng": -81.52206212379548,
    "lat": 41.436335323813225
  },
  {
    "name": "Warrenton",
    "lng": -91.13446186774534,
    "lat": 38.81844470422645
  },
  {
    "name": "Warrenton",
    "lng": -82.66526792677762,
    "lat": 33.40672785797551
  },
  {
    "name": "Warrenton",
    "lng": -78.15688564057457,
    "lat": 36.3994513655229
  },
  {
    "name": "Warrenton",
    "lng": -77.79733417435841,
    "lat": 38.717685354777785
  },
  {
    "name": "Warrenville",
    "lng": -88.18567052146335,
    "lat": 41.82101459407609
  },
  {
    "name": "Warrior",
    "lng": -86.81818054914176,
    "lat": 33.81127214095119
  },
  {
    "name": "Warrior",
    "lng": -86.81817928355935,
    "lat": 33.81128044151378
  },
  {
    "name": "Warrior",
    "lng": -86.81308662972323,
    "lat": 33.840216054438926
  },
  {
    "name": "Warrior",
    "lng": -86.77422479457884,
    "lat": 33.82402276199187
  },
  {
    "name": "Warroad",
    "lng": -95.32694624945998,
    "lat": 48.91658107053657
  },
  {
    "name": "Warroad",
    "lng": -95.29572208552507,
    "lat": 48.902120649190046
  },
  {
    "name": "Warsaw",
    "lng": -93.37108792399377,
    "lat": 38.24658169832019
  },
  {
    "name": "Warsaw",
    "lng": -91.4277048242408,
    "lat": 40.35097460254893
  },
  {
    "name": "Warsaw",
    "lng": -85.84796588717246,
    "lat": 41.24339898725111
  },
  {
    "name": "Warsaw",
    "lng": -84.90166749218702,
    "lat": 38.777826487731524
  },
  {
    "name": "Warsaw",
    "lng": -82.00154689848985,
    "lat": 40.335479024349155
  },
  {
    "name": "Warsaw",
    "lng": -78.14634140098991,
    "lat": 34.99487947536054
  },
  {
    "name": "Warsaw",
    "lng": -78.14244547081533,
    "lat": 34.99493817517056
  },
  {
    "name": "Warsaw",
    "lng": -78.1412928813234,
    "lat": 42.742915044064446
  },
  {
    "name": "Warsaw",
    "lng": -78.13893803409961,
    "lat": 34.98957181190779
  },
  {
    "name": "Warsaw",
    "lng": -78.13782612160563,
    "lat": 34.994406687445604
  },
  {
    "name": "Warsaw",
    "lng": -78.12776448619417,
    "lat": 34.9952109872231
  },
  {
    "name": "Warsaw",
    "lng": -78.12674612375189,
    "lat": 34.99179783321992
  },
  {
    "name": "Warsaw",
    "lng": -78.1216603583183,
    "lat": 35.00706274175549
  },
  {
    "name": "Warsaw",
    "lng": -78.08973511690398,
    "lat": 34.9986299966584
  },
  {
    "name": "Warsaw",
    "lng": -78.06563461149707,
    "lat": 34.995066541599776
  },
  {
    "name": "Warsaw",
    "lng": -78.0639221132924,
    "lat": 34.997026549177434
  },
  {
    "name": "Warsaw",
    "lng": -78.0119988959164,
    "lat": 35.01029836860699
  },
  {
    "name": "Warsaw",
    "lng": -76.76105989000077,
    "lat": 37.9602533827608
  },
  {
    "name": "Warson Woods",
    "lng": -90.39120160795495,
    "lat": 38.60688324258765
  },
  {
    "name": "Wartburg",
    "lng": -84.58649838856599,
    "lat": 36.10431587297842
  },
  {
    "name": "Wartrace",
    "lng": -86.3327142724594,
    "lat": 35.52763248813685
  },
  {
    "name": "Warwick",
    "lng": -98.70569386311462,
    "lat": 47.85473995937094
  },
  {
    "name": "Warwick",
    "lng": -97.00135819519565,
    "lat": 35.688271660262586
  },
  {
    "name": "Warwick",
    "lng": -83.9206246732186,
    "lat": 31.829790163972294
  },
  {
    "name": "Warwick",
    "lng": -74.35507945671431,
    "lat": 41.25517329569148
  },
  {
    "name": "Warwick",
    "lng": -72.34055338772244,
    "lat": 42.6771063073092
  },
  {
    "name": "Warwick",
    "lng": -71.4558021514591,
    "lat": 41.648072901702356
  },
  {
    "name": "Warwick",
    "lng": -71.43912445185457,
    "lat": 41.65233936932434
  },
  {
    "name": "Warwick",
    "lng": -71.4343238680868,
    "lat": 41.71088338382505
  },
  {
    "name": "Warwick",
    "lng": -71.38116981997045,
    "lat": 41.75067920763868
  },
  {
    "name": "Warwick",
    "lng": -71.38115300790756,
    "lat": 41.75072184031594
  },
  {
    "name": "Warwick",
    "lng": -71.3810895223387,
    "lat": 41.750704366193176
  },
  {
    "name": "Warwick",
    "lng": -71.3804394809932,
    "lat": 41.748908244434695
  },
  {
    "name": "Warwick",
    "lng": -71.38043935439401,
    "lat": 41.749851368051935
  },
  {
    "name": "Warwick",
    "lng": -71.38043693858317,
    "lat": 41.75193371514825
  },
  {
    "name": "Warwick",
    "lng": -71.38041842845966,
    "lat": 41.74923128289949
  },
  {
    "name": "Warwick",
    "lng": -71.38032605054163,
    "lat": 41.75086532682556
  },
  {
    "name": "Warwick",
    "lng": -71.38028947110996,
    "lat": 41.750372716648926
  },
  {
    "name": "Wasco",
    "lng": -119.36730215066211,
    "lat": 35.593785690603134
  },
  {
    "name": "Waseca",
    "lng": -93.50258194156478,
    "lat": 44.08266341619898
  },
  {
    "name": "Washburn",
    "lng": -101.02745828254506,
    "lat": 47.29280280208125
  },
  {
    "name": "Washburn",
    "lng": -93.96552815116306,
    "lat": 36.588786401723226
  },
  {
    "name": "Washburn",
    "lng": -93.95173623047064,
    "lat": 36.59125935085518
  },
  {
    "name": "Washburn",
    "lng": -89.30274544730389,
    "lat": 40.922864988490126
  },
  {
    "name": "Washburn",
    "lng": -89.29198404207703,
    "lat": 40.92009589238912
  },
  {
    "name": "Washburn",
    "lng": -89.28319406266799,
    "lat": 40.92587618379847
  },
  {
    "name": "Washburn",
    "lng": -68.11686709328625,
    "lat": 46.79331262370418
  },
  {
    "name": "Washington",
    "lng": -120.5881945794137,
    "lat": 47.43409131764436
  },
  {
    "name": "Washington",
    "lng": -113.48767998982574,
    "lat": 37.12681682102261
  },
  {
    "name": "Washington",
    "lng": -97.49226237497471,
    "lat": 35.05825676415479
  },
  {
    "name": "Washington",
    "lng": -97.05209780226618,
    "lat": 39.81713502197976
  },
  {
    "name": "Washington",
    "lng": -97.03496624192695,
    "lat": 39.818313182938674
  },
  {
    "name": "Washington",
    "lng": -96.20745927867202,
    "lat": 41.397632323431885
  },
  {
    "name": "Washington",
    "lng": -92.05948867938194,
    "lat": 30.615044015734572
  },
  {
    "name": "Washington",
    "lng": -91.69292879605429,
    "lat": 41.29824896406475
  },
  {
    "name": "Washington",
    "lng": -91.01471838740103,
    "lat": 38.55153515326134
  },
  {
    "name": "Washington",
    "lng": -89.43461533235815,
    "lat": 40.70483985807095
  },
  {
    "name": "Washington",
    "lng": -87.15929256807048,
    "lat": 38.658553803268944
  },
  {
    "name": "Washington",
    "lng": -82.74272804726432,
    "lat": 33.73569518282506
  },
  {
    "name": "Washington",
    "lng": -80.24672978590063,
    "lat": 40.173965434887876
  },
  {
    "name": "Washington",
    "lng": -78.1598026502033,
    "lat": 38.7121482393798
  },
  {
    "name": "Washington",
    "lng": -77.11229042399384,
    "lat": 35.59510583736814
  },
  {
    "name": "Washington",
    "lng": -77.10502157411548,
    "lat": 35.58573649904977
  },
  {
    "name": "Washington",
    "lng": -77.09936498283108,
    "lat": 35.59356969141495
  },
  {
    "name": "Washington",
    "lng": -77.09446120068853,
    "lat": 35.58348283217608
  },
  {
    "name": "Washington",
    "lng": -77.09353927905951,
    "lat": 35.588180840903895
  },
  {
    "name": "Washington",
    "lng": -77.09265140731495,
    "lat": 35.58128673710349
  },
  {
    "name": "Washington",
    "lng": -77.09101693481375,
    "lat": 35.60347576947772
  },
  {
    "name": "Washington",
    "lng": -77.0903822845783,
    "lat": 35.579743998250215
  },
  {
    "name": "Washington",
    "lng": -77.08949723567639,
    "lat": 35.5724502053456
  },
  {
    "name": "Washington",
    "lng": -77.08714796877035,
    "lat": 35.57677292031303
  },
  {
    "name": "Washington",
    "lng": -77.08059357814147,
    "lat": 35.57601103193468
  },
  {
    "name": "Washington",
    "lng": -77.07877082643226,
    "lat": 35.53745955920107
  },
  {
    "name": "Washington",
    "lng": -77.07765970739469,
    "lat": 35.59820740721231
  },
  {
    "name": "Washington",
    "lng": -77.07139877756758,
    "lat": 35.56767189339113
  },
  {
    "name": "Washington",
    "lng": -77.06538540810836,
    "lat": 35.58030508098891
  },
  {
    "name": "Washington",
    "lng": -77.0620994944243,
    "lat": 35.57880574413464
  },
  {
    "name": "Washington",
    "lng": -77.04936759226331,
    "lat": 35.55418141655509
  },
  {
    "name": "Washington",
    "lng": -77.0358587998689,
    "lat": 35.57269565623636
  },
  {
    "name": "Washington",
    "lng": -77.03518905857962,
    "lat": 35.599042563043085
  },
  {
    "name": "Washington",
    "lng": -77.02287643329518,
    "lat": 35.534513872371036
  },
  {
    "name": "Washington",
    "lng": -77.02166355594211,
    "lat": 35.561076851306346
  },
  {
    "name": "Washington",
    "lng": -77.01740061917744,
    "lat": 35.54762406546871
  },
  {
    "name": "Washington",
    "lng": -77.00992634090497,
    "lat": 35.55743388812975
  },
  {
    "name": "Washington",
    "lng": -77.00590833762041,
    "lat": 35.54076397705397
  },
  {
    "name": "Washington",
    "lng": -76.99450914212949,
    "lat": 35.53859241175563
  },
  {
    "name": "Washington",
    "lng": -76.97823656451553,
    "lat": 35.53906611455956
  },
  {
    "name": "Washington",
    "lng": -69.39105237255832,
    "lat": 44.27340228013147
  },
  {
    "name": "Washington Park",
    "lng": -90.09240652727718,
    "lat": 38.6279969041475
  },
  {
    "name": "Washington Park",
    "lng": -77.03158003720075,
    "lat": 35.53286249722181
  },
  {
    "name": "Washington Terrace",
    "lng": -111.97814127200486,
    "lat": 41.16830176077696
  },
  {
    "name": "Washingtonville",
    "lng": -80.76765418894271,
    "lat": 40.89900446154387
  },
  {
    "name": "Washingtonville",
    "lng": -74.15794048048033,
    "lat": 41.42982910020409
  },
  {
    "name": "Washta",
    "lng": -95.72014879282929,
    "lat": 42.57557206582102
  },
  {
    "name": "Washtucna",
    "lng": -118.31120305511904,
    "lat": 46.75345570690839
  },
  {
    "name": "Wasilla",
    "lng": -149.46497162997574,
    "lat": 61.57691881827241
  },
  {
    "name": "Wasilla City Hall",
    "lng": -149.44431695143047,
    "lat": 61.58187671049684
  },
  {
    "name": "Waskom",
    "lng": -94.0651427508976,
    "lat": 32.47669146974069
  },
  {
    "name": "Wasta",
    "lng": -102.44637360508985,
    "lat": 44.06952333700695
  },
  {
    "name": "Wataga",
    "lng": -90.27544586422201,
    "lat": 41.02522987219322
  },
  {
    "name": "Watauga",
    "lng": -97.25150111564945,
    "lat": 32.87184786422451
  },
  {
    "name": "Watauga",
    "lng": -82.29339188543858,
    "lat": 36.36887601600561
  },
  {
    "name": "Water Valley",
    "lng": -89.62993082190364,
    "lat": 34.16200122955133
  },
  {
    "name": "Water Valley",
    "lng": -88.810730419932,
    "lat": 36.568887232874076
  },
  {
    "name": "Waterboro",
    "lng": -70.7303201591371,
    "lat": 43.59722643679844
  },
  {
    "name": "Waterbury",
    "lng": -96.73556710044916,
    "lat": 42.45723301849503
  },
  {
    "name": "Waterbury",
    "lng": -73.03612648479334,
    "lat": 41.55823905473151
  },
  {
    "name": "Waterbury",
    "lng": -72.7432512225469,
    "lat": 44.34278074010583
  },
  {
    "name": "Waterford",
    "lng": -120.76140555454798,
    "lat": 37.641075738981904
  },
  {
    "name": "Waterford",
    "lng": -88.21633494772226,
    "lat": 42.76529968334503
  },
  {
    "name": "Waterford",
    "lng": -72.14726536969644,
    "lat": 41.352713655920304
  },
  {
    "name": "Waterford",
    "lng": -70.71943165944352,
    "lat": 44.193853361452135
  },
  {
    "name": "Waterloo",
    "lng": -96.28505492021604,
    "lat": 41.285570051213355
  },
  {
    "name": "Waterloo",
    "lng": -92.35221532085887,
    "lat": 42.491952527627504
  },
  {
    "name": "Waterloo",
    "lng": -90.1538265321902,
    "lat": 38.34018985174533
  },
  {
    "name": "Waterloo",
    "lng": -88.98999456036864,
    "lat": 43.183413765575764
  },
  {
    "name": "Waterloo",
    "lng": -88.06544518934642,
    "lat": 34.91721974267513
  },
  {
    "name": "Waterloo",
    "lng": -85.026711197321,
    "lat": 41.431818176707154
  },
  {
    "name": "Waterloo",
    "lng": -82.05976816576585,
    "lat": 34.35446272356428
  },
  {
    "name": "Waterloo",
    "lng": -82.05296668127765,
    "lat": 34.36650032146591
  },
  {
    "name": "Waterloo",
    "lng": -76.85925131130507,
    "lat": 42.90489671142844
  },
  {
    "name": "Waterproof",
    "lng": -91.38614599742994,
    "lat": 31.80715971419513
  },
  {
    "name": "Watertown",
    "lng": -97.17036672784644,
    "lat": 44.943491086814355
  },
  {
    "name": "Watertown",
    "lng": -97.15629146819771,
    "lat": 44.91026400009518
  },
  {
    "name": "Watertown",
    "lng": -97.07881402285074,
    "lat": 44.88305439554419
  },
  {
    "name": "Watertown",
    "lng": -93.84293678095716,
    "lat": 44.960342491867756
  },
  {
    "name": "Watertown",
    "lng": -88.72884193976046,
    "lat": 43.18955369303965
  },
  {
    "name": "Watertown",
    "lng": -86.14028115208048,
    "lat": 36.10074990041983
  },
  {
    "name": "Watertown",
    "lng": -75.90935665886676,
    "lat": 43.973431514410265
  },
  {
    "name": "Watertown",
    "lng": -73.11767109684995,
    "lat": 41.61605363418092
  },
  {
    "name": "Watertown",
    "lng": -71.17744205578094,
    "lat": 42.3699729291348
  },
  {
    "name": "Waterville",
    "lng": -120.07069905557923,
    "lat": 47.64805599497376
  },
  {
    "name": "Waterville",
    "lng": -96.76064443421699,
    "lat": 39.69448868872439
  },
  {
    "name": "Waterville",
    "lng": -96.75472084607708,
    "lat": 39.702168358817154
  },
  {
    "name": "Waterville",
    "lng": -96.74849569553906,
    "lat": 39.69164619377684
  },
  {
    "name": "Waterville",
    "lng": -96.73046076348687,
    "lat": 39.695360356524354
  },
  {
    "name": "Waterville",
    "lng": -96.7297183306966,
    "lat": 39.68827743898802
  },
  {
    "name": "Waterville",
    "lng": -93.5746151084412,
    "lat": 44.223455924800845
  },
  {
    "name": "Waterville",
    "lng": -91.29676246922213,
    "lat": 43.20680789390961
  },
  {
    "name": "Waterville",
    "lng": -83.73445171250036,
    "lat": 41.500900210564176
  },
  {
    "name": "Waterville",
    "lng": -75.38076374088878,
    "lat": 42.930387227996086
  },
  {
    "name": "Waterville",
    "lng": -69.66242113621406,
    "lat": 44.54403493542085
  },
  {
    "name": "Watervliet",
    "lng": -86.25782997394636,
    "lat": 42.18836892551468
  },
  {
    "name": "Watford City",
    "lng": -103.26874922082345,
    "lat": 47.80270945932173
  },
  {
    "name": "Watha",
    "lng": -77.96290755246106,
    "lat": 34.64443684505588
  },
  {
    "name": "Wathena",
    "lng": -94.94404439723593,
    "lat": 39.76154382541742
  },
  {
    "name": "Wathena",
    "lng": -94.94312452677632,
    "lat": 39.748413056934645
  },
  {
    "name": "Watkins",
    "lng": -94.41246413784266,
    "lat": 45.315317919522556
  },
  {
    "name": "Watkins",
    "lng": -94.3975114378369,
    "lat": 45.312530286341044
  },
  {
    "name": "Watkins Glen",
    "lng": -76.87661095297445,
    "lat": 42.36864943928042
  },
  {
    "name": "Watkins Glen",
    "lng": -76.86724531933399,
    "lat": 42.38010392442936
  },
  {
    "name": "Watkinsville",
    "lng": -83.40730432831538,
    "lat": 33.86121368670702
  },
  {
    "name": "Watonga",
    "lng": -98.41684283184567,
    "lat": 35.86703281514421
  },
  {
    "name": "Watseka",
    "lng": -87.72986373812645,
    "lat": 40.77465661648415
  },
  {
    "name": "Watson",
    "lng": -95.80020321458531,
    "lat": 45.00981799754811
  },
  {
    "name": "Watson",
    "lng": -95.62347951931557,
    "lat": 40.47990581746828
  },
  {
    "name": "Watson",
    "lng": -91.25716061031632,
    "lat": 33.89388954505417
  },
  {
    "name": "Watson",
    "lng": -88.5682989014356,
    "lat": 39.026113478012675
  },
  {
    "name": "Watsontown",
    "lng": -76.86446490613562,
    "lat": 41.085104558087586
  },
  {
    "name": "Watsonville",
    "lng": -121.82359002895315,
    "lat": 36.914298111057825
  },
  {
    "name": "Watsonville",
    "lng": -121.81379912123302,
    "lat": 36.900566307853104
  },
  {
    "name": "Watsonville",
    "lng": -121.78739485380889,
    "lat": 37.01673982974276
  },
  {
    "name": "Watsonville",
    "lng": -121.78621891155959,
    "lat": 36.886173338815006
  },
  {
    "name": "Watsonville",
    "lng": -121.77010022904146,
    "lat": 36.92025886776987
  },
  {
    "name": "Watsonville",
    "lng": -121.7697698308627,
    "lat": 36.95354136708761
  },
  {
    "name": "Watterson Park",
    "lng": -85.68654546496953,
    "lat": 38.18918968264249
  },
  {
    "name": "Watts",
    "lng": -94.57168867269246,
    "lat": 36.107238714423936
  },
  {
    "name": "Waubay",
    "lng": -97.30545742751147,
    "lat": 45.33452862657835
  },
  {
    "name": "Waubun",
    "lng": -95.94063722449052,
    "lat": 47.18633909835767
  },
  {
    "name": "Wauchula",
    "lng": -81.83638458688009,
    "lat": 27.54730661935076
  },
  {
    "name": "Wauchula",
    "lng": -81.82553913104593,
    "lat": 27.545167050865484
  },
  {
    "name": "Wauchula",
    "lng": -81.81654158132287,
    "lat": 27.53322386740459
  },
  {
    "name": "Wauchula",
    "lng": -81.80891685226918,
    "lat": 27.546756953229682
  },
  {
    "name": "Wauchula",
    "lng": -81.78013822375144,
    "lat": 27.55327171253796
  },
  {
    "name": "Waucoma",
    "lng": -92.03452307258084,
    "lat": 43.055765069208036
  },
  {
    "name": "Wauconda",
    "lng": -88.13592869573138,
    "lat": 42.27477596062517
  },
  {
    "name": "Waukee",
    "lng": -93.88717974108397,
    "lat": 41.59907186097549
  },
  {
    "name": "Waukee",
    "lng": -93.87999682130184,
    "lat": 41.561179517457695
  },
  {
    "name": "Waukegan",
    "lng": -87.8737505216869,
    "lat": 42.376939517246534
  },
  {
    "name": "Waukegan",
    "lng": -87.87097567836304,
    "lat": 42.37040907450134
  },
  {
    "name": "Waukesha",
    "lng": -88.24603386182922,
    "lat": 43.00936205687037
  },
  {
    "name": "Waukomis",
    "lng": -97.90433935372712,
    "lat": 36.28161547084588
  },
  {
    "name": "Waukon",
    "lng": -91.49159908673673,
    "lat": 43.27984491181429
  },
  {
    "name": "Waukon",
    "lng": -91.47845728921774,
    "lat": 43.268189596574565
  },
  {
    "name": "Waunakee",
    "lng": -89.44443318297078,
    "lat": 43.18309702368762
  },
  {
    "name": "Wauneta",
    "lng": -101.37195746622781,
    "lat": 40.41623027706348
  },
  {
    "name": "Waupaca",
    "lng": -89.08414959950663,
    "lat": 44.318071141676285
  },
  {
    "name": "Waupaca",
    "lng": -89.0717645759157,
    "lat": 44.35116401780167
  },
  {
    "name": "Waupaca",
    "lng": -89.0562489236156,
    "lat": 44.35148678253235
  },
  {
    "name": "Waupun",
    "lng": -88.7360334731598,
    "lat": 43.630460617890414
  },
  {
    "name": "Waupun",
    "lng": -88.71995327238089,
    "lat": 43.64058708013068
  },
  {
    "name": "Wauregan",
    "lng": -71.91371815356429,
    "lat": 41.749818278372764
  },
  {
    "name": "Waurika",
    "lng": -98.02592066265356,
    "lat": 34.18526597223312
  },
  {
    "name": "Waurika",
    "lng": -97.99043715530573,
    "lat": 34.16966853169148
  },
  {
    "name": "Waurika",
    "lng": -97.9903593981743,
    "lat": 34.17078550308398
  },
  {
    "name": "Waurika",
    "lng": -97.99018933690049,
    "lat": 34.17161950653481
  },
  {
    "name": "Waurika",
    "lng": -97.97954100142861,
    "lat": 34.16328928407166
  },
  {
    "name": "Wausa",
    "lng": -97.53931621097541,
    "lat": 42.49782087702983
  },
  {
    "name": "Wausau",
    "lng": -89.64737391009696,
    "lat": 44.961594831699955
  },
  {
    "name": "Wausau",
    "lng": -89.60624559050764,
    "lat": 44.96267983143994
  },
  {
    "name": "Wausau",
    "lng": -89.58727256528606,
    "lat": 44.957911734777355
  },
  {
    "name": "Wausaukee",
    "lng": -87.95510887340198,
    "lat": 45.3779624151065
  },
  {
    "name": "Wauseon",
    "lng": -84.14098778388943,
    "lat": 41.553336279854506
  },
  {
    "name": "Wautoma",
    "lng": -89.30529958593263,
    "lat": 44.04154734491219
  },
  {
    "name": "Wautoma",
    "lng": -89.30300550183524,
    "lat": 44.10052669723636
  },
  {
    "name": "Wautoma",
    "lng": -89.2946667382791,
    "lat": 44.05795329062004
  },
  {
    "name": "Wautoma",
    "lng": -89.28836526625904,
    "lat": 44.07060698156391
  },
  {
    "name": "Wautoma",
    "lng": -89.26920477023044,
    "lat": 44.077141584877964
  },
  {
    "name": "Wautoma",
    "lng": -89.26451649120615,
    "lat": 44.05971459183389
  },
  {
    "name": "Wauwatosa",
    "lng": -88.0347583179742,
    "lat": 43.06158007654508
  },
  {
    "name": "Wauzeka",
    "lng": -90.89995380477804,
    "lat": 43.08506490783827
  },
  {
    "name": "Waveland",
    "lng": -89.39041527227738,
    "lat": 30.293169576774577
  },
  {
    "name": "Waveland",
    "lng": -87.04607847653415,
    "lat": 39.87779642684834
  },
  {
    "name": "Waverly",
    "lng": -117.23127863225908,
    "lat": 47.33805920490592
  },
  {
    "name": "Waverly",
    "lng": -96.53372210041123,
    "lat": 40.911907143357396
  },
  {
    "name": "Waverly",
    "lng": -95.60445999819332,
    "lat": 38.39619067241001
  },
  {
    "name": "Waverly",
    "lng": -95.59087504477284,
    "lat": 38.39009126666197
  },
  {
    "name": "Waverly",
    "lng": -93.96709153194685,
    "lat": 45.065062555766325
  },
  {
    "name": "Waverly",
    "lng": -93.51999034922457,
    "lat": 39.207357550902486
  },
  {
    "name": "Waverly",
    "lng": -92.47086855304266,
    "lat": 42.72507968294086
  },
  {
    "name": "Waverly",
    "lng": -89.95274736464323,
    "lat": 39.59250638213355
  },
  {
    "name": "Waverly",
    "lng": -87.81459696936835,
    "lat": 37.709200547461045
  },
  {
    "name": "Waverly",
    "lng": -87.78635156724884,
    "lat": 36.09327687680414
  },
  {
    "name": "Waverly",
    "lng": -77.09558542106701,
    "lat": 37.0340305981707
  },
  {
    "name": "Waverly",
    "lng": -76.54043162629264,
    "lat": 42.011951044550194
  },
  {
    "name": "Waverly City",
    "lng": -82.98332421297195,
    "lat": 39.12589246068481
  },
  {
    "name": "Waverly Hall",
    "lng": -84.73775425497294,
    "lat": 32.68368153994042
  },
  {
    "name": "Waxahachie",
    "lng": -96.843448367551,
    "lat": 32.400607808656005
  },
  {
    "name": "Waxhaw",
    "lng": -80.77140111258377,
    "lat": 34.95180084772848
  },
  {
    "name": "Waxhaw",
    "lng": -80.76327684999005,
    "lat": 34.93239783956308
  },
  {
    "name": "Waxhaw",
    "lng": -80.74507696712496,
    "lat": 34.92932740786128
  },
  {
    "name": "Waycross",
    "lng": -82.35864093552823,
    "lat": 31.2101550805025
  },
  {
    "name": "Wayland",
    "lng": -91.65765533176331,
    "lat": 41.14836560396998
  },
  {
    "name": "Wayland",
    "lng": -91.58189349989676,
    "lat": 40.39496534275851
  },
  {
    "name": "Wayland",
    "lng": -85.64080542138198,
    "lat": 42.67249583679214
  },
  {
    "name": "Wayland",
    "lng": -82.79935453935053,
    "lat": 37.44738289237946
  },
  {
    "name": "Wayland",
    "lng": -77.60253177926909,
    "lat": 42.55207908059148
  },
  {
    "name": "Wayland",
    "lng": -77.5916286066384,
    "lat": 42.56724766503774
  },
  {
    "name": "Wayland",
    "lng": -77.5810700298686,
    "lat": 42.57385394402602
  },
  {
    "name": "Wayne",
    "lng": -97.31657337562835,
    "lat": 34.91710583583103
  },
  {
    "name": "Wayne",
    "lng": -97.01542110067793,
    "lat": 42.237188517347896
  },
  {
    "name": "Wayne",
    "lng": -88.26675001383637,
    "lat": 41.97219548132288
  },
  {
    "name": "Wayne",
    "lng": -88.25831309318565,
    "lat": 41.94890979588574
  },
  {
    "name": "Wayne",
    "lng": -83.47172650057705,
    "lat": 41.30096109979004
  },
  {
    "name": "Wayne",
    "lng": -83.38771796669378,
    "lat": 42.27744909390946
  },
  {
    "name": "Wayne",
    "lng": -82.4414248194007,
    "lat": 38.22484809168554
  },
  {
    "name": "Wayne",
    "lng": -70.06494534352944,
    "lat": 44.34583495762126
  },
  {
    "name": "Wayne City",
    "lng": -88.61732726159974,
    "lat": 38.33637886409408
  },
  {
    "name": "Wayne City",
    "lng": -88.61123883219645,
    "lat": 38.34014057739553
  },
  {
    "name": "Wayne City",
    "lng": -88.58742169199405,
    "lat": 38.34888824060317
  },
  {
    "name": "Wayne Lakes",
    "lng": -84.6624734628697,
    "lat": 40.021561392665774
  },
  {
    "name": "Waynesboro",
    "lng": -88.63531967612802,
    "lat": 31.677332605885855
  },
  {
    "name": "Waynesboro",
    "lng": -87.7508744493137,
    "lat": 35.32463730806877
  },
  {
    "name": "Waynesboro",
    "lng": -82.01454827634292,
    "lat": 33.09105916278651
  },
  {
    "name": "Waynesboro",
    "lng": -78.9013566974038,
    "lat": 38.06741719895626
  },
  {
    "name": "Waynesboro",
    "lng": -77.58212575673222,
    "lat": 39.75228798674655
  },
  {
    "name": "Waynesburg",
    "lng": -81.2598343513377,
    "lat": 40.66827622635102
  },
  {
    "name": "Waynesburg",
    "lng": -80.18562617628307,
    "lat": 39.89822634889088
  },
  {
    "name": "Waynesfield",
    "lng": -83.97335694061097,
    "lat": 40.60242636495277
  },
  {
    "name": "Waynesville",
    "lng": -92.21954527745854,
    "lat": 37.8210005610507
  },
  {
    "name": "Waynesville",
    "lng": -89.12447727206508,
    "lat": 40.241253283844856
  },
  {
    "name": "Waynesville",
    "lng": -84.09046468956734,
    "lat": 39.532425149032875
  },
  {
    "name": "Waynesville",
    "lng": -83.00302416404996,
    "lat": 35.500008113273424
  },
  {
    "name": "Waynesville",
    "lng": -83.00066775617599,
    "lat": 35.52810353299051
  },
  {
    "name": "Waynesville",
    "lng": -83.00008423463356,
    "lat": 35.48391585371696
  },
  {
    "name": "Waynesville",
    "lng": -82.99988574607553,
    "lat": 35.528586344057494
  },
  {
    "name": "Waynesville",
    "lng": -82.99735753826432,
    "lat": 35.453286384546644
  },
  {
    "name": "Waynesville",
    "lng": -82.99729776861756,
    "lat": 35.527994509591494
  },
  {
    "name": "Waynesville",
    "lng": -82.99595993577151,
    "lat": 35.50522676482088
  },
  {
    "name": "Waynesville",
    "lng": -82.99433714639234,
    "lat": 35.525327434580774
  },
  {
    "name": "Waynesville",
    "lng": -82.99004221003892,
    "lat": 35.52577046559873
  },
  {
    "name": "Waynesville",
    "lng": -82.98983954255318,
    "lat": 35.52652569687436
  },
  {
    "name": "Waynesville",
    "lng": -82.98932284733212,
    "lat": 35.52653121301473
  },
  {
    "name": "Waynesville",
    "lng": -82.9881158773588,
    "lat": 35.52549242031557
  },
  {
    "name": "Waynesville",
    "lng": -82.98683892269501,
    "lat": 35.5211754126552
  },
  {
    "name": "Waynesville",
    "lng": -82.97868383043628,
    "lat": 35.47311721600601
  },
  {
    "name": "Waynesville",
    "lng": -82.97230878057043,
    "lat": 35.477080742916954
  },
  {
    "name": "Waynesville",
    "lng": -82.96781742765249,
    "lat": 35.513141653523
  },
  {
    "name": "Waynesville",
    "lng": -82.96489775881398,
    "lat": 35.50926815316716
  },
  {
    "name": "Waynetown",
    "lng": -87.06534222013771,
    "lat": 40.0878904654856
  },
  {
    "name": "Waynoka",
    "lng": -98.88099525962934,
    "lat": 36.58435729976265
  },
  {
    "name": "Waynoka",
    "lng": -98.88044184778983,
    "lat": 36.571584398034666
  },
  {
    "name": "Waynoka",
    "lng": -98.86748918479199,
    "lat": 36.59037742466701
  },
  {
    "name": "Wayzata",
    "lng": -93.51399155077341,
    "lat": 44.970785871161866
  },
  {
    "name": "Weare",
    "lng": -71.7220167605081,
    "lat": 43.08139143220184
  },
  {
    "name": "Weatherby",
    "lng": -94.24192212927034,
    "lat": 39.90929191820458
  },
  {
    "name": "Weatherby Lake",
    "lng": -94.69581326880844,
    "lat": 39.2366755450191
  },
  {
    "name": "Weatherford",
    "lng": -98.68756925268079,
    "lat": 35.53883386667381
  },
  {
    "name": "Weatherford",
    "lng": -97.76959808178853,
    "lat": 32.755198175878355
  },
  {
    "name": "Weaubleau",
    "lng": -93.54031498533365,
    "lat": 37.89151273888439
  },
  {
    "name": "Weaver",
    "lng": -85.81187690329104,
    "lat": 33.757056156305325
  },
  {
    "name": "Weaverville",
    "lng": -82.5833449448688,
    "lat": 35.70715671648729
  },
  {
    "name": "Weaverville",
    "lng": -82.58270222018001,
    "lat": 35.71062347240106
  },
  {
    "name": "Weaverville",
    "lng": -82.55966508870999,
    "lat": 35.71330742084724
  },
  {
    "name": "Weaverville",
    "lng": -82.55827898269693,
    "lat": 35.69604473012266
  },
  {
    "name": "Weaverville",
    "lng": -82.54231472824404,
    "lat": 35.68778112598561
  },
  {
    "name": "Webb",
    "lng": -95.01289891721875,
    "lat": 42.94975653705613
  },
  {
    "name": "Webb",
    "lng": -90.34633597216546,
    "lat": 33.94775017043557
  },
  {
    "name": "Webb",
    "lng": -85.28937804215698,
    "lat": 31.257609407783164
  },
  {
    "name": "Webb City",
    "lng": -96.71194557407303,
    "lat": 36.80742267503205
  },
  {
    "name": "Webb City",
    "lng": -94.4701033578971,
    "lat": 37.144521490543326
  },
  {
    "name": "Webber",
    "lng": -98.03514588652655,
    "lat": 39.93441445049837
  },
  {
    "name": "Webbers Falls",
    "lng": -95.17381821792894,
    "lat": 35.49252575500542
  },
  {
    "name": "Webbers Falls",
    "lng": -95.15928392702058,
    "lat": 35.51338594176965
  },
  {
    "name": "Webberville",
    "lng": -97.49958667099557,
    "lat": 30.225611624213233
  },
  {
    "name": "Webberville",
    "lng": -84.1785938619688,
    "lat": 42.66404149972705
  },
  {
    "name": "Weber City",
    "lng": -82.56307010773018,
    "lat": 36.62362744763634
  },
  {
    "name": "Webster",
    "lng": -97.52165100942447,
    "lat": 45.33545708181056
  },
  {
    "name": "Webster",
    "lng": -95.11864370039208,
    "lat": 29.531911936689205
  },
  {
    "name": "Webster",
    "lng": -92.36303034833945,
    "lat": 45.88006198019319
  },
  {
    "name": "Webster",
    "lng": -92.17144615982315,
    "lat": 41.43843037522114
  },
  {
    "name": "Webster",
    "lng": -83.21805876969478,
    "lat": 35.34990792648768
  },
  {
    "name": "Webster",
    "lng": -77.42238579455646,
    "lat": 43.21507922911151
  },
  {
    "name": "Webster",
    "lng": -71.7191246254109,
    "lat": 43.29983850681678
  },
  {
    "name": "Webster City",
    "lng": -93.81673164270308,
    "lat": 42.46225172114905
  },
  {
    "name": "Webster Groves",
    "lng": -90.3544211601761,
    "lat": 38.58657294209418
  },
  {
    "name": "Weddington",
    "lng": -80.78222926892649,
    "lat": 35.01699945975353
  },
  {
    "name": "Weddington",
    "lng": -80.73804328400264,
    "lat": 35.02279529906357
  },
  {
    "name": "Wedowee",
    "lng": -85.48641310580311,
    "lat": 33.30779049838162
  },
  {
    "name": "Weed",
    "lng": -122.38154463854978,
    "lat": 41.41279893975932
  },
  {
    "name": "Weedpatch",
    "lng": -118.90980392828617,
    "lat": 35.23789515100205
  },
  {
    "name": "Weedsport",
    "lng": -76.56360750811018,
    "lat": 43.04812453551476
  },
  {
    "name": "Weeping Water",
    "lng": -96.13930794590834,
    "lat": 40.86918648123806
  },
  {
    "name": "Weigelstown",
    "lng": -76.83152292319505,
    "lat": 39.98430644671516
  },
  {
    "name": "Weimar",
    "lng": -96.77725768155322,
    "lat": 29.699945669185666
  },
  {
    "name": "Weiner",
    "lng": -90.90528197112081,
    "lat": 35.61970708557511
  },
  {
    "name": "Weippe",
    "lng": -115.93934985981826,
    "lat": 46.37873339253481
  },
  {
    "name": "Weir",
    "lng": -94.774530576565,
    "lat": 37.30825569753233
  },
  {
    "name": "Weir",
    "lng": -89.29056861350115,
    "lat": 33.26344612194495
  },
  {
    "name": "Weirton",
    "lng": -80.56797250229349,
    "lat": 40.4063330501603
  },
  {
    "name": "Weisenberger City",
    "lng": -95.47281017163657,
    "lat": 29.928033058347516
  },
  {
    "name": "Weiser",
    "lng": -116.9687762488464,
    "lat": 44.25473718921731
  },
  {
    "name": "Welch",
    "lng": -95.09468699053367,
    "lat": 36.873870034463025
  },
  {
    "name": "Welch",
    "lng": -81.5802552395545,
    "lat": 37.4328774194882
  },
  {
    "name": "Welcome",
    "lng": -94.60732730034563,
    "lat": 43.66623082878541
  },
  {
    "name": "Weld",
    "lng": -70.45223539052448,
    "lat": 44.70625304714264
  },
  {
    "name": "Weldon",
    "lng": -93.73512356005465,
    "lat": 40.89754282275174
  },
  {
    "name": "Weldon",
    "lng": -91.23167748613828,
    "lat": 35.447969002478324
  },
  {
    "name": "Weldon",
    "lng": -88.74974154557196,
    "lat": 40.12163017313446
  },
  {
    "name": "Weldon",
    "lng": -77.6293423357201,
    "lat": 36.38315601609513
  },
  {
    "name": "Weldon",
    "lng": -77.61266632169895,
    "lat": 36.42290979282945
  },
  {
    "name": "Weldon Spring",
    "lng": -90.65153825095283,
    "lat": 38.71191785340171
  },
  {
    "name": "Weldon Spring Heights",
    "lng": -90.68532791504475,
    "lat": 38.70442156166776
  },
  {
    "name": "Weleetka",
    "lng": -96.13384136759653,
    "lat": 35.34768862800338
  },
  {
    "name": "Wellfleet",
    "lng": -100.73190141549864,
    "lat": 40.753920866122165
  },
  {
    "name": "Wellfleet",
    "lng": -100.72325153272513,
    "lat": 40.75299039169735
  },
  {
    "name": "Wellford",
    "lng": -82.09450744386866,
    "lat": 34.9817626456408
  },
  {
    "name": "Wellford",
    "lng": -82.09197185525991,
    "lat": 34.95100698759101
  },
  {
    "name": "Wellington",
    "lng": -110.73435479637773,
    "lat": 39.53582523744036
  },
  {
    "name": "Wellington",
    "lng": -105.00551760183734,
    "lat": 40.68617756467668
  },
  {
    "name": "Wellington",
    "lng": -100.21408690339646,
    "lat": 34.853798822865876
  },
  {
    "name": "Wellington",
    "lng": -97.52111800156055,
    "lat": 37.22136132194573
  },
  {
    "name": "Wellington",
    "lng": -97.3973598484019,
    "lat": 37.27197028560873
  },
  {
    "name": "Wellington",
    "lng": -97.38866530561364,
    "lat": 37.32425813448056
  },
  {
    "name": "Wellington",
    "lng": -93.98625721220142,
    "lat": 39.13845958327746
  },
  {
    "name": "Wellington",
    "lng": -87.67966944747344,
    "lat": 40.54045163470885
  },
  {
    "name": "Wellington",
    "lng": -85.67032279169635,
    "lat": 38.21664690434903
  },
  {
    "name": "Wellington",
    "lng": -82.2266746275793,
    "lat": 41.161040900514074
  },
  {
    "name": "Wellington",
    "lng": -69.57052857613009,
    "lat": 45.065508187307906
  },
  {
    "name": "Wellman",
    "lng": -91.83536964941523,
    "lat": 41.46868425458166
  },
  {
    "name": "Wells",
    "lng": -114.95353711324319,
    "lat": 41.11315603628815
  },
  {
    "name": "Wells",
    "lng": -94.94733002963868,
    "lat": 31.491522475809322
  },
  {
    "name": "Wells",
    "lng": -93.72832272132818,
    "lat": 43.74268215043521
  },
  {
    "name": "Wells",
    "lng": -70.61000214900845,
    "lat": 43.31719674200684
  },
  {
    "name": "Wells River",
    "lng": -72.06201545419933,
    "lat": 44.15380405037522
  },
  {
    "name": "Wellsboro",
    "lng": -77.3031027208509,
    "lat": 41.74594033087783
  },
  {
    "name": "Wellsburg",
    "lng": -92.92667081723425,
    "lat": 42.43383809597878
  },
  {
    "name": "Wellsburg",
    "lng": -80.61081741494299,
    "lat": 40.28089170371744
  },
  {
    "name": "Wellsburg",
    "lng": -76.7299021942797,
    "lat": 42.012670702560314
  },
  {
    "name": "Wellston",
    "lng": -97.06147146511401,
    "lat": 35.68603239443355
  },
  {
    "name": "Wellston",
    "lng": -90.29402096310393,
    "lat": 38.674928728620614
  },
  {
    "name": "Wellston",
    "lng": -82.53743769243788,
    "lat": 39.117303593822314
  },
  {
    "name": "Wellsville",
    "lng": -111.94285857161185,
    "lat": 41.622436624711426
  },
  {
    "name": "Wellsville",
    "lng": -95.08231856693618,
    "lat": 38.71681731026344
  },
  {
    "name": "Wellsville",
    "lng": -91.56920790775494,
    "lat": 39.07339835690048
  },
  {
    "name": "Wellsville",
    "lng": -80.65495456864106,
    "lat": 40.604302099804094
  },
  {
    "name": "Wellsville",
    "lng": -77.95005240800585,
    "lat": 42.134537286306745
  },
  {
    "name": "Wellsville",
    "lng": -77.94573410314891,
    "lat": 42.12071275195501
  },
  {
    "name": "Wellsville",
    "lng": -76.94069658137072,
    "lat": 40.05126631449031
  },
  {
    "name": "Wellton",
    "lng": -114.18489593846941,
    "lat": 32.646895658540124
  },
  {
    "name": "Welsh",
    "lng": -92.81348160151047,
    "lat": 30.235517687295854
  },
  {
    "name": "Welton",
    "lng": -90.59702826605647,
    "lat": 41.90817638826769
  },
  {
    "name": "Wenatchee",
    "lng": -120.32920046189403,
    "lat": 47.42562116984095
  },
  {
    "name": "Wendell",
    "lng": -114.70257944315138,
    "lat": 42.77449509249898
  },
  {
    "name": "Wendell",
    "lng": -96.10081617203957,
    "lat": 46.0359076539741
  },
  {
    "name": "Wendell",
    "lng": -78.43456608890574,
    "lat": 35.80526802961056
  },
  {
    "name": "Wendell",
    "lng": -78.43408170612848,
    "lat": 35.80327200569133
  },
  {
    "name": "Wendell",
    "lng": -78.42892450888453,
    "lat": 35.79410815783094
  },
  {
    "name": "Wendell",
    "lng": -78.42805112263027,
    "lat": 35.77202167855971
  },
  {
    "name": "Wendell",
    "lng": -78.42668691314674,
    "lat": 35.804099541178594
  },
  {
    "name": "Wendell",
    "lng": -78.42263846777635,
    "lat": 35.80476953179567
  },
  {
    "name": "Wendell",
    "lng": -78.42261249246287,
    "lat": 35.80325860639511
  },
  {
    "name": "Wendell",
    "lng": -78.42077148026557,
    "lat": 35.78455339397922
  },
  {
    "name": "Wendell",
    "lng": -78.41772181108895,
    "lat": 35.80249299902736
  },
  {
    "name": "Wendell",
    "lng": -78.41659577009187,
    "lat": 35.811117588628285
  },
  {
    "name": "Wendell",
    "lng": -78.40957356238448,
    "lat": 35.768029784764124
  },
  {
    "name": "Wendell",
    "lng": -78.40751661594821,
    "lat": 35.8058984606411
  },
  {
    "name": "Wendell",
    "lng": -78.40628452164451,
    "lat": 35.77122489042464
  },
  {
    "name": "Wendell",
    "lng": -78.40205328556553,
    "lat": 35.81414361440206
  },
  {
    "name": "Wendell",
    "lng": -78.40066275477707,
    "lat": 35.80111946340344
  },
  {
    "name": "Wendell",
    "lng": -78.39919738089235,
    "lat": 35.804056415407906
  },
  {
    "name": "Wendell",
    "lng": -78.39846165593518,
    "lat": 35.80323075169389
  },
  {
    "name": "Wendell",
    "lng": -78.39807059497365,
    "lat": 35.80535003831044
  },
  {
    "name": "Wendell",
    "lng": -78.39791538137996,
    "lat": 35.78779801168923
  },
  {
    "name": "Wendell",
    "lng": -78.38557367900549,
    "lat": 35.80047634557421
  },
  {
    "name": "Wendell",
    "lng": -78.36823554074518,
    "lat": 35.78017171584123
  },
  {
    "name": "Wendell",
    "lng": -78.35711163654061,
    "lat": 35.79824705849822
  },
  {
    "name": "Wendell",
    "lng": -72.40496624655724,
    "lat": 42.55356886707928
  },
  {
    "name": "Wendover",
    "lng": -114.02505032983282,
    "lat": 40.724090254519005
  },
  {
    "name": "Wenona",
    "lng": -89.05224254759585,
    "lat": 41.0485353486875
  },
  {
    "name": "Wenonah",
    "lng": -89.28793350354836,
    "lat": 39.319997529185024
  },
  {
    "name": "Wentworth",
    "lng": -96.96455641575403,
    "lat": 43.99696214005168
  },
  {
    "name": "Wentworth",
    "lng": -94.07548377773212,
    "lat": 36.99321787908823
  },
  {
    "name": "Wentworth",
    "lng": -79.75100122354303,
    "lat": 36.391438257819665
  },
  {
    "name": "Wentzville",
    "lng": -90.89853867119302,
    "lat": 38.82818113960178
  },
  {
    "name": "Wentzville",
    "lng": -90.8823920697953,
    "lat": 38.84942309334383
  },
  {
    "name": "Wentzville",
    "lng": -90.87360093807163,
    "lat": 38.77970235535291
  },
  {
    "name": "Wentzville",
    "lng": -90.86672105679598,
    "lat": 38.81645019243547
  },
  {
    "name": "Wentzville",
    "lng": -90.8620681693905,
    "lat": 38.77729932425718
  },
  {
    "name": "Wernersville",
    "lng": -76.08193021064692,
    "lat": 40.33015695904461
  },
  {
    "name": "Weslaco",
    "lng": -98.02026614840221,
    "lat": 26.14802762791641
  },
  {
    "name": "Weslaco",
    "lng": -97.98952345213088,
    "lat": 26.102361249707727
  },
  {
    "name": "Weslaco",
    "lng": -97.98570416138111,
    "lat": 26.168320980848836
  },
  {
    "name": "Wesley",
    "lng": -94.00587470586608,
    "lat": 43.09281346691579
  },
  {
    "name": "Wesley",
    "lng": -93.99350481927968,
    "lat": 43.08861136129753
  },
  {
    "name": "Wesley",
    "lng": -67.64965807645775,
    "lat": 44.91983601992467
  },
  {
    "name": "Wesley Chapel",
    "lng": -82.36419375053946,
    "lat": 28.18202109678285
  },
  {
    "name": "Wesley Chapel",
    "lng": -80.69060803829174,
    "lat": 34.99904300425847
  },
  {
    "name": "Wesley Hills",
    "lng": -74.0768652004818,
    "lat": 41.15784804328316
  },
  {
    "name": "Wessington",
    "lng": -98.696790404894,
    "lat": 44.45511231144784
  },
  {
    "name": "Wessington Springs",
    "lng": -98.57153250304978,
    "lat": 44.080527907653114
  },
  {
    "name": "Wesson",
    "lng": -90.3955206100471,
    "lat": 31.699207935366342
  },
  {
    "name": "West",
    "lng": -97.0945530307207,
    "lat": 31.80314411748264
  },
  {
    "name": "West",
    "lng": -89.7821019891166,
    "lat": 33.197753018632284
  },
  {
    "name": "West Alexandria",
    "lng": -84.53432948514096,
    "lat": 39.74436886692461
  },
  {
    "name": "West Allis",
    "lng": -88.05875369569912,
    "lat": 42.97841457539367
  },
  {
    "name": "West Allis",
    "lng": -88.02952671625557,
    "lat": 43.00685554815533
  },
  {
    "name": "West Alton",
    "lng": -90.20805928709854,
    "lat": 38.866391945288896
  },
  {
    "name": "West Baden Springs",
    "lng": -86.61207721890241,
    "lat": 38.567681430282526
  },
  {
    "name": "West Baraboo",
    "lng": -89.77229424601636,
    "lat": 43.48230400947879
  },
  {
    "name": "West Bath",
    "lng": -69.85959066935602,
    "lat": 43.875114866757734
  },
  {
    "name": "West Bend",
    "lng": -94.4458368866383,
    "lat": 42.95998275877305
  },
  {
    "name": "West Bend",
    "lng": -88.18216365525355,
    "lat": 43.418666504947204
  },
  {
    "name": "West Bend",
    "lng": -88.11320072626415,
    "lat": 43.428343548027705
  },
  {
    "name": "West Blocton",
    "lng": -87.1249549461333,
    "lat": 33.11795534929237
  },
  {
    "name": "West Bountiful",
    "lng": -111.90816860022262,
    "lat": 40.898223300396175
  },
  {
    "name": "West Boylston",
    "lng": -71.78455479382056,
    "lat": 42.36900392169872
  },
  {
    "name": "West Branch",
    "lng": -91.35007197796784,
    "lat": 41.673167114090234
  },
  {
    "name": "West Branch",
    "lng": -91.3353681798438,
    "lat": 41.65546834131764
  },
  {
    "name": "West Branch",
    "lng": -84.2359127324245,
    "lat": 44.273725746215575
  },
  {
    "name": "West Brookfield",
    "lng": -72.16004355515614,
    "lat": 42.25410213389139
  },
  {
    "name": "West Brooklyn",
    "lng": -89.14730498710561,
    "lat": 41.69280164834524
  },
  {
    "name": "West Buechel",
    "lng": -85.66738069489675,
    "lat": 38.195285945038435
  },
  {
    "name": "West Carrollton",
    "lng": -84.25412422441205,
    "lat": 39.670066652404365
  },
  {
    "name": "West Carthage",
    "lng": -75.62068586406366,
    "lat": 43.973092822961036
  },
  {
    "name": "West Chester",
    "lng": -91.81711314795737,
    "lat": 41.34002270707792
  },
  {
    "name": "West Chester",
    "lng": -75.60580428250707,
    "lat": 39.960130344237186
  },
  {
    "name": "West Chicago",
    "lng": -88.22693733723978,
    "lat": 41.89672249325891
  },
  {
    "name": "West Chicago",
    "lng": -88.20156026094358,
    "lat": 41.856316622322076
  },
  {
    "name": "West Chicago",
    "lng": -88.18709053070197,
    "lat": 41.90964541085531
  },
  {
    "name": "West Chicago",
    "lng": -88.18417025769622,
    "lat": 41.88659009201883
  },
  {
    "name": "West City",
    "lng": -88.94698869455856,
    "lat": 37.996166443096875
  },
  {
    "name": "West College Corner",
    "lng": -84.81888530150881,
    "lat": 39.56958603646255
  },
  {
    "name": "West Columbia",
    "lng": -95.64896987066629,
    "lat": 29.14221001416746
  },
  {
    "name": "West Columbia",
    "lng": -81.13587018829875,
    "lat": 34.01399668898198
  },
  {
    "name": "West Columbia",
    "lng": -81.11216912671232,
    "lat": 33.99796747722243
  },
  {
    "name": "West Columbia",
    "lng": -81.10289351486608,
    "lat": 33.98156654700486
  },
  {
    "name": "West Columbia",
    "lng": -81.08638734424022,
    "lat": 33.99442781576084
  },
  {
    "name": "West Concord",
    "lng": -92.89948759835656,
    "lat": 44.15289779480061
  },
  {
    "name": "West Covina",
    "lng": -117.91115863340173,
    "lat": 34.05546883122306
  },
  {
    "name": "West De Land",
    "lng": -81.3338666158631,
    "lat": 29.01545190776373
  },
  {
    "name": "West Des Moines",
    "lng": -93.78051942312962,
    "lat": 41.552255370334905
  },
  {
    "name": "West Dundee",
    "lng": -88.30678645016322,
    "lat": 42.09820548920054
  },
  {
    "name": "West Elkton",
    "lng": -84.55978051516668,
    "lat": 39.58813876631503
  },
  {
    "name": "West Fargo",
    "lng": -96.90419133449225,
    "lat": 46.85657019675703
  },
  {
    "name": "West Farmington",
    "lng": -80.97296618523782,
    "lat": 41.39085182785147
  },
  {
    "name": "West Fork",
    "lng": -94.20147869107352,
    "lat": 35.93190528378828
  },
  {
    "name": "West Fork",
    "lng": -94.17981157140275,
    "lat": 35.935493875981535
  },
  {
    "name": "West Fork",
    "lng": -94.17981157140267,
    "lat": 35.935493875981486
  },
  {
    "name": "West Fork",
    "lng": -94.16946423388598,
    "lat": 35.93819364496346
  },
  {
    "name": "West Fork",
    "lng": -94.16796680631153,
    "lat": 35.934127496523125
  },
  {
    "name": "West Frankfort",
    "lng": -88.95691309582116,
    "lat": 37.909683497621394
  },
  {
    "name": "West Frankfort",
    "lng": -88.92931438234164,
    "lat": 37.900249312277694
  },
  {
    "name": "West Gardiner",
    "lng": -69.87200424024745,
    "lat": 44.22419589678348
  },
  {
    "name": "West Greenwich",
    "lng": -71.66677174702971,
    "lat": 41.629002800106235
  },
  {
    "name": "West Hamlin",
    "lng": -82.19374108604012,
    "lat": 38.28019797241351
  },
  {
    "name": "West Hampton Dunes",
    "lng": -72.72511601226286,
    "lat": 40.77878643632394
  },
  {
    "name": "West Hampton Dunes",
    "lng": -72.7127701712758,
    "lat": 40.77667117556562
  },
  {
    "name": "West Harrison",
    "lng": -84.82256341121156,
    "lat": 39.258276403911125
  },
  {
    "name": "West Hartford",
    "lng": -72.7538762780798,
    "lat": 41.766963906521504
  },
  {
    "name": "West Haven",
    "lng": -112.05393214482966,
    "lat": 41.20816245154138
  },
  {
    "name": "West Haven",
    "lng": -72.9668842608869,
    "lat": 41.273891231235496
  },
  {
    "name": "West Haverstraw",
    "lng": -73.98839996060173,
    "lat": 41.20617671504176
  },
  {
    "name": "West Hollywood",
    "lng": -118.3718399555996,
    "lat": 34.088263855109865
  },
  {
    "name": "West Jefferson",
    "lng": -87.06920932557546,
    "lat": 33.64849969712199
  },
  {
    "name": "West Jefferson",
    "lng": -83.30992641074022,
    "lat": 39.94377436651978
  },
  {
    "name": "West Jefferson",
    "lng": -81.50754927486676,
    "lat": 36.36409652447782
  },
  {
    "name": "West Jefferson",
    "lng": -81.49669649048154,
    "lat": 36.37790865235281
  },
  {
    "name": "West Jefferson",
    "lng": -81.49207204321408,
    "lat": 36.39751679357
  },
  {
    "name": "West Jefferson",
    "lng": -81.479861945487,
    "lat": 36.394473910955014
  },
  {
    "name": "West Jefferson",
    "lng": -81.47886512121721,
    "lat": 36.391026848888245
  },
  {
    "name": "West Jefferson",
    "lng": -81.47394066288548,
    "lat": 36.37609445013881
  },
  {
    "name": "West Jefferson",
    "lng": -81.47311011139361,
    "lat": 36.3766865852591
  },
  {
    "name": "West Jefferson",
    "lng": -81.47094603038448,
    "lat": 36.375822726284596
  },
  {
    "name": "West Jefferson",
    "lng": -81.46845394442501,
    "lat": 36.38805660282187
  },
  {
    "name": "West Jefferson",
    "lng": -81.46580873910352,
    "lat": 36.37446599847107
  },
  {
    "name": "West Jordan",
    "lng": -112.00085719002533,
    "lat": 40.60244963476684
  },
  {
    "name": "West Lafayette",
    "lng": -86.92381830849895,
    "lat": 40.443270423144696
  },
  {
    "name": "West Lafayette",
    "lng": -81.75168328655779,
    "lat": 40.27614915933633
  },
  {
    "name": "West Lake Hills",
    "lng": -97.80839862471979,
    "lat": 30.29196488574122
  },
  {
    "name": "West Lawn",
    "lng": -75.9937139560505,
    "lat": 40.32895636156141
  },
  {
    "name": "West Lebanon",
    "lng": -87.38616684320158,
    "lat": 40.27236635247105
  },
  {
    "name": "West Leipsic",
    "lng": -84.00101241692971,
    "lat": 41.10523757406505
  },
  {
    "name": "West Liberty",
    "lng": -91.26346666586062,
    "lat": 41.57245564441263
  },
  {
    "name": "West Liberty",
    "lng": -83.75820031255029,
    "lat": 40.25601761922937
  },
  {
    "name": "West Liberty",
    "lng": -83.26717934435136,
    "lat": 37.91343642159161
  },
  {
    "name": "West Liberty",
    "lng": -80.59698180875985,
    "lat": 40.1669103076211
  },
  {
    "name": "West Line",
    "lng": -94.59114917294201,
    "lat": 38.63131528896766
  },
  {
    "name": "West Line",
    "lng": -94.58701927627578,
    "lat": 38.64031834012146
  },
  {
    "name": "West Line",
    "lng": -94.58455277254207,
    "lat": 38.633588817475434
  },
  {
    "name": "West Logan",
    "lng": -81.9906479779929,
    "lat": 37.86787918251589
  },
  {
    "name": "West Manchester",
    "lng": -84.62631485663798,
    "lat": 39.90334707411961
  },
  {
    "name": "West Mansfield",
    "lng": -83.54465202250812,
    "lat": 40.40035758255767
  },
  {
    "name": "West Memphis",
    "lng": -90.19952259749687,
    "lat": 35.15307937802783
  },
  {
    "name": "West Miami",
    "lng": -80.29690583034471,
    "lat": 25.757762803719828
  },
  {
    "name": "West Milford",
    "lng": -80.40338009924515,
    "lat": 39.204444686306225
  },
  {
    "name": "West Millgrove",
    "lng": -83.49140719098294,
    "lat": 41.24274545324341
  },
  {
    "name": "West Milton",
    "lng": -84.34370681639693,
    "lat": 39.96613662577649
  },
  {
    "name": "West Milton",
    "lng": -84.32385890187355,
    "lat": 39.957604658906774
  },
  {
    "name": "West Milwaukee",
    "lng": -87.97101284048455,
    "lat": 43.012407363166936
  },
  {
    "name": "West Mineral",
    "lng": -94.93887907823336,
    "lat": 37.273475406131624
  },
  {
    "name": "West Mineral",
    "lng": -94.9258238258931,
    "lat": 37.28480001276991
  },
  {
    "name": "West Monroe",
    "lng": -92.15144741378535,
    "lat": 32.51211923396577
  },
  {
    "name": "West New York",
    "lng": -74.00930874834575,
    "lat": 40.78565998033018
  },
  {
    "name": "West Okoboji",
    "lng": -95.16338851154963,
    "lat": 43.348902158991415
  },
  {
    "name": "West Orange",
    "lng": -93.7598689337752,
    "lat": 30.079160504103264
  },
  {
    "name": "West Palm Beach",
    "lng": -80.1316569076485,
    "lat": 26.74724397128535
  },
  {
    "name": "West Paris",
    "lng": -70.53379023826665,
    "lat": 44.32108446826852
  },
  {
    "name": "West Pelzer",
    "lng": -82.47412055481097,
    "lat": 34.64458159941514
  },
  {
    "name": "West Peoria",
    "lng": -89.64360238937759,
    "lat": 40.70386308356672
  },
  {
    "name": "West Peoria",
    "lng": -89.64006814006994,
    "lat": 40.69682025447372
  },
  {
    "name": "West Plains",
    "lng": -91.86797760966073,
    "lat": 36.737835832753376
  },
  {
    "name": "West Point",
    "lng": -112.09949826789072,
    "lat": 41.122069346223405
  },
  {
    "name": "West Point",
    "lng": -96.70611154549532,
    "lat": 41.83805605976608
  },
  {
    "name": "West Point",
    "lng": -91.6098906970536,
    "lat": 35.20330525818864
  },
  {
    "name": "West Point",
    "lng": -91.4528790687603,
    "lat": 40.71447125664861
  },
  {
    "name": "West Point",
    "lng": -91.1833074244397,
    "lat": 40.25557620373148
  },
  {
    "name": "West Point",
    "lng": -88.6571574323535,
    "lat": 33.606490688677006
  },
  {
    "name": "West Point",
    "lng": -86.96246001256178,
    "lat": 34.240066968381285
  },
  {
    "name": "West Point",
    "lng": -85.95580706693495,
    "lat": 37.985061381144035
  },
  {
    "name": "West Point",
    "lng": -85.1520163703855,
    "lat": 32.892824578976885
  },
  {
    "name": "West Point",
    "lng": -76.80109457281333,
    "lat": 37.55111652164924
  },
  {
    "name": "West Richland",
    "lng": -119.39966868903566,
    "lat": 46.31138787132205
  },
  {
    "name": "West Rushville",
    "lng": -82.4484299199881,
    "lat": 39.76388123590553
  },
  {
    "name": "West Salem",
    "lng": -91.13069854568677,
    "lat": 43.89446012540642
  },
  {
    "name": "West Salem",
    "lng": -91.0806776221215,
    "lat": 43.89977519276345
  },
  {
    "name": "West Salem",
    "lng": -88.00899858011451,
    "lat": 38.519683299302734
  },
  {
    "name": "West Salem",
    "lng": -82.10790318328513,
    "lat": 40.96952791028929
  },
  {
    "name": "West Seneca",
    "lng": -78.7508657254028,
    "lat": 42.837483079465855
  },
  {
    "name": "West Siloam Springs",
    "lng": -94.60859151838238,
    "lat": 36.183466259158784
  },
  {
    "name": "West Siloam Springs",
    "lng": -94.59414954346522,
    "lat": 36.17560279089215
  },
  {
    "name": "West Springfield",
    "lng": -72.6502406522557,
    "lat": 42.125332902987545
  },
  {
    "name": "West St. Paul",
    "lng": -93.08573755432353,
    "lat": 44.901851318860714
  },
  {
    "name": "West Stockbridge",
    "lng": -73.38822871028455,
    "lat": 42.310266821758354
  },
  {
    "name": "West Sullivan",
    "lng": -91.19116850577376,
    "lat": 38.19409723553243
  },
  {
    "name": "West Tawakoni",
    "lng": -96.021872643547,
    "lat": 32.8976208497331
  },
  {
    "name": "West Terre Haute",
    "lng": -87.450593887712,
    "lat": 39.4637655129764
  },
  {
    "name": "West Union",
    "lng": -95.11784060297362,
    "lat": 45.78336881844669
  },
  {
    "name": "West Union",
    "lng": -95.08346325310622,
    "lat": 45.80087819512139
  },
  {
    "name": "West Union",
    "lng": -91.81300196418529,
    "lat": 42.958946252761805
  },
  {
    "name": "West Union",
    "lng": -83.54413180443981,
    "lat": 38.79164178481585
  },
  {
    "name": "West Union",
    "lng": -83.04306684784814,
    "lat": 34.75933481588748
  },
  {
    "name": "West Union",
    "lng": -80.77516273395732,
    "lat": 39.2963670618574
  },
  {
    "name": "West Unity",
    "lng": -84.43324379598978,
    "lat": 41.585667624674954
  },
  {
    "name": "West University Place",
    "lng": -95.43230272769453,
    "lat": 29.71570404239335
  },
  {
    "name": "West Valley City",
    "lng": -112.01222770055476,
    "lat": 40.68856500463654
  },
  {
    "name": "West Virginia",
    "lng": -80.60882088986057,
    "lat": 38.65199162963095
  },
  {
    "name": "West Warwick",
    "lng": -71.51562610721743,
    "lat": 41.69859436967035
  },
  {
    "name": "West Wendover",
    "lng": -114.07845763561929,
    "lat": 40.74072566910977
  },
  {
    "name": "West Winfield",
    "lng": -75.19194594905295,
    "lat": 42.883908363767254
  },
  {
    "name": "West Wyomissing",
    "lng": -75.99421542831692,
    "lat": 40.323040030471574
  },
  {
    "name": "West Yellowstone",
    "lng": -111.10579336454657,
    "lat": 44.662759622704414
  },
  {
    "name": "West York",
    "lng": -76.76095823111784,
    "lat": 39.95337883694458
  },
  {
    "name": "Westboro",
    "lng": -95.32110619338451,
    "lat": 40.53505060776694
  },
  {
    "name": "Westborough",
    "lng": -71.61403050310774,
    "lat": 42.26815084097443
  },
  {
    "name": "Westbrook",
    "lng": -95.43749963226131,
    "lat": 44.04223513405896
  },
  {
    "name": "Westbrook",
    "lng": -72.46277542536664,
    "lat": 41.29621512173824
  },
  {
    "name": "Westbrook",
    "lng": -70.3538940908766,
    "lat": 43.69538511521526
  },
  {
    "name": "Westbury",
    "lng": -73.5891836569278,
    "lat": 40.75994463049211
  },
  {
    "name": "Westby",
    "lng": -104.05519019084856,
    "lat": 48.870498062530984
  },
  {
    "name": "Westby",
    "lng": -90.85706070560585,
    "lat": 43.65338162802719
  },
  {
    "name": "Westchester",
    "lng": -87.88762440582855,
    "lat": 41.85074921135625
  },
  {
    "name": "Westcliffe",
    "lng": -105.46542003867093,
    "lat": 38.13357243873665
  },
  {
    "name": "Westerly",
    "lng": -71.88549021563777,
    "lat": 41.325239575648865
  },
  {
    "name": "Westerly",
    "lng": -71.85204950022543,
    "lat": 41.32156100433721
  },
  {
    "name": "Westerly",
    "lng": -71.78969641490278,
    "lat": 41.36538335005255
  },
  {
    "name": "Westerly",
    "lng": -71.78529057508169,
    "lat": 41.33688398053227
  },
  {
    "name": "Westerly",
    "lng": -71.78470736512355,
    "lat": 41.33275635892376
  },
  {
    "name": "Westerly",
    "lng": -71.78351737715779,
    "lat": 41.333941638480056
  },
  {
    "name": "Westerly",
    "lng": -71.77945153292123,
    "lat": 41.33380738161902
  },
  {
    "name": "Westerly",
    "lng": -71.77132129082969,
    "lat": 41.33258970523788
  },
  {
    "name": "Westerly",
    "lng": -71.74976873572369,
    "lat": 41.336146793483145
  },
  {
    "name": "Westerly",
    "lng": -71.74871843696123,
    "lat": 41.3321893212388
  },
  {
    "name": "Westerly",
    "lng": -71.74760824822654,
    "lat": 41.334970059798344
  },
  {
    "name": "Western",
    "lng": -97.19915658062955,
    "lat": 40.39345979452545
  },
  {
    "name": "Western",
    "lng": -97.19280941762503,
    "lat": 40.38673200133238
  },
  {
    "name": "Western",
    "lng": -97.1927843298796,
    "lat": 40.387516501251135
  },
  {
    "name": "Western",
    "lng": -91.64453934534043,
    "lat": 41.864526953468804
  },
  {
    "name": "Western Grove",
    "lng": -92.9545581559993,
    "lat": 36.10111994658979
  },
  {
    "name": "Western Springs",
    "lng": -87.90060807548417,
    "lat": 41.80230072346681
  },
  {
    "name": "Westerville",
    "lng": -82.92052821720738,
    "lat": 40.12401235414455
  },
  {
    "name": "Westfield",
    "lng": -96.60549954782918,
    "lat": 42.75609586572311
  },
  {
    "name": "Westfield",
    "lng": -89.49299308889371,
    "lat": 43.88547644268494
  },
  {
    "name": "Westfield",
    "lng": -87.99665173607265,
    "lat": 39.45539534591127
  },
  {
    "name": "Westfield",
    "lng": -86.1531635282757,
    "lat": 40.03381400165799
  },
  {
    "name": "Westfield",
    "lng": -79.57475431628087,
    "lat": 42.3218729927728
  },
  {
    "name": "Westfield",
    "lng": -74.34336129804932,
    "lat": 40.65163673657504
  },
  {
    "name": "Westfield",
    "lng": -72.75610296355721,
    "lat": 42.138206685802835
  },
  {
    "name": "Westfield",
    "lng": -67.97682579342171,
    "lat": 46.551723382574956
  },
  {
    "name": "Westfield Center",
    "lng": -81.93146884597523,
    "lat": 41.02822762417248
  },
  {
    "name": "Westford",
    "lng": -71.44006623066716,
    "lat": 42.58643574231908
  },
  {
    "name": "Westgate",
    "lng": -91.99579503169726,
    "lat": 42.76836532348211
  },
  {
    "name": "Westhampton",
    "lng": -72.77842637563074,
    "lat": 42.3099518122405
  },
  {
    "name": "Westhampton Beach",
    "lng": -72.64848280259115,
    "lat": 40.80507342959293
  },
  {
    "name": "Westhope",
    "lng": -101.01767687225274,
    "lat": 48.91108435603629
  },
  {
    "name": "Westlake",
    "lng": -97.20394642472442,
    "lat": 32.98082778528638
  },
  {
    "name": "Westlake",
    "lng": -93.26353229965184,
    "lat": 30.26040736057073
  },
  {
    "name": "Westlake",
    "lng": -81.9294669964639,
    "lat": 41.452436119782874
  },
  {
    "name": "Westlake Village",
    "lng": -118.82206479993887,
    "lat": 34.13688885757807
  },
  {
    "name": "Westland",
    "lng": -83.38054480588964,
    "lat": 42.31923887847334
  },
  {
    "name": "Westmanland",
    "lng": -68.23986445949573,
    "lat": 46.9734525952158
  },
  {
    "name": "Westminster",
    "lng": -117.99395049790864,
    "lat": 33.75231410880963
  },
  {
    "name": "Westminster",
    "lng": -105.06276165392026,
    "lat": 39.88384865546804
  },
  {
    "name": "Westminster",
    "lng": -105.03595735368728,
    "lat": 39.85695155445821
  },
  {
    "name": "Westminster",
    "lng": -83.09043847608933,
    "lat": 34.66604691810078
  },
  {
    "name": "Westminster",
    "lng": -77.00642202264495,
    "lat": 39.57973245811524
  },
  {
    "name": "Westminster",
    "lng": -71.90265393119849,
    "lat": 42.55124743508098
  },
  {
    "name": "Westmont",
    "lng": -87.9742301652142,
    "lat": 41.7949268317037
  },
  {
    "name": "Westmoreland",
    "lng": -96.41372948717864,
    "lat": 39.39437447193162
  },
  {
    "name": "Westmoreland",
    "lng": -86.24480524791244,
    "lat": 36.56020445649221
  },
  {
    "name": "Westmorland",
    "lng": -115.6294106515612,
    "lat": 33.046290421114946
  },
  {
    "name": "Westmorland",
    "lng": -115.62248579880549,
    "lat": 33.037218891398744
  },
  {
    "name": "Weston",
    "lng": -111.97835129328062,
    "lat": 42.0368644847436
  },
  {
    "name": "Weston",
    "lng": -96.74117763467439,
    "lat": 41.191919878047955
  },
  {
    "name": "Weston",
    "lng": -94.88604844899406,
    "lat": 39.403010402441225
  },
  {
    "name": "Weston",
    "lng": -84.61155853746479,
    "lat": 31.976764537955997
  },
  {
    "name": "Weston",
    "lng": -83.79491620715237,
    "lat": 41.346453519643376
  },
  {
    "name": "Weston",
    "lng": -80.46888849892922,
    "lat": 39.04261269059698
  },
  {
    "name": "Weston",
    "lng": -73.37253000481904,
    "lat": 41.22840681141082
  },
  {
    "name": "Weston",
    "lng": -71.30007452235188,
    "lat": 42.35894632456955
  },
  {
    "name": "Weston",
    "lng": -67.86419869132502,
    "lat": 45.72861354398484
  },
  {
    "name": "Westover",
    "lng": -86.52628033092859,
    "lat": 33.35941211591631
  },
  {
    "name": "Westover",
    "lng": -79.97729579192422,
    "lat": 39.63227371765948
  },
  {
    "name": "Westover Hills",
    "lng": -97.41231804566179,
    "lat": 32.743839710859135
  },
  {
    "name": "Westphalia",
    "lng": -95.49076866111804,
    "lat": 38.181966099995186
  },
  {
    "name": "Westphalia",
    "lng": -95.39392123094733,
    "lat": 41.719301892827694
  },
  {
    "name": "Westphalia",
    "lng": -92.00043404320061,
    "lat": 38.44124415841267
  },
  {
    "name": "Westphalia",
    "lng": -84.79768096006829,
    "lat": 42.9303451996958
  },
  {
    "name": "Westport",
    "lng": -124.11314584440524,
    "lat": 46.89244339646483
  },
  {
    "name": "Westport",
    "lng": -96.39192558823426,
    "lat": 36.206271371158124
  },
  {
    "name": "Westport",
    "lng": -96.34960425196539,
    "lat": 36.218042330916774
  },
  {
    "name": "Westport",
    "lng": -96.31530624147544,
    "lat": 36.19563063275132
  },
  {
    "name": "Westport",
    "lng": -96.29249085042237,
    "lat": 36.16798700072177
  },
  {
    "name": "Westport",
    "lng": -95.1680310622021,
    "lat": 45.71455506517999
  },
  {
    "name": "Westport",
    "lng": -85.57510215738704,
    "lat": 39.17717700713467
  },
  {
    "name": "Westport",
    "lng": -73.34567566792133,
    "lat": 41.122585293892655
  },
  {
    "name": "Westport Island",
    "lng": -69.70076052361868,
    "lat": 43.90779421151317
  },
  {
    "name": "Westside",
    "lng": -95.10135919168418,
    "lat": 42.07568027645011
  },
  {
    "name": "Westville",
    "lng": -94.5907429447893,
    "lat": 35.997165088106534
  },
  {
    "name": "Westville",
    "lng": -94.58762201263693,
    "lat": 35.9941462428159
  },
  {
    "name": "Westville",
    "lng": -94.58497522553697,
    "lat": 36.00127528976578
  },
  {
    "name": "Westville",
    "lng": -94.57589750728114,
    "lat": 35.976036223700774
  },
  {
    "name": "Westville",
    "lng": -94.57520849898452,
    "lat": 35.9712272869567
  },
  {
    "name": "Westville",
    "lng": -94.57483055320026,
    "lat": 36.008935434541435
  },
  {
    "name": "Westville",
    "lng": -94.57388231691,
    "lat": 35.99086331298119
  },
  {
    "name": "Westville",
    "lng": -94.5654471617552,
    "lat": 35.985264533443996
  },
  {
    "name": "Westville",
    "lng": -94.55782956860715,
    "lat": 35.99829458096113
  },
  {
    "name": "Westville",
    "lng": -94.55694433353497,
    "lat": 35.99594016185201
  },
  {
    "name": "Westville",
    "lng": -94.55399928384439,
    "lat": 35.995972579337824
  },
  {
    "name": "Westville",
    "lng": -87.63893899208699,
    "lat": 40.043990279465866
  },
  {
    "name": "Westville",
    "lng": -86.9057454839575,
    "lat": 41.53814087061306
  },
  {
    "name": "Westwego",
    "lng": -90.14338096853672,
    "lat": 29.905674834063333
  },
  {
    "name": "Westwood",
    "lng": -94.61557685626026,
    "lat": 39.03940480153238
  },
  {
    "name": "Westwood",
    "lng": -91.62644027872129,
    "lat": 40.9649613681978
  },
  {
    "name": "Westwood",
    "lng": -90.43345222959788,
    "lat": 38.643493391432486
  },
  {
    "name": "Westwood",
    "lng": -85.58414634076927,
    "lat": 38.27955929910775
  },
  {
    "name": "Westwood Hills",
    "lng": -94.60966211924966,
    "lat": 39.038940464767386
  },
  {
    "name": "Westworth Village",
    "lng": -97.42390358501574,
    "lat": 32.75991713792774
  },
  {
    "name": "Wethersfield",
    "lng": -72.6703707723854,
    "lat": 41.70118495580898
  },
  {
    "name": "Wetmore",
    "lng": -95.81145003842046,
    "lat": 39.63559011681174
  },
  {
    "name": "Wetonka",
    "lng": -98.77174191828725,
    "lat": 45.624222626628914
  },
  {
    "name": "Wetumka",
    "lng": -96.23897921139532,
    "lat": 35.240918034303064
  },
  {
    "name": "Wetumpka",
    "lng": -86.25691351111458,
    "lat": 32.54089382066069
  },
  {
    "name": "Wetumpka",
    "lng": -86.24929393519231,
    "lat": 32.52975791897647
  },
  {
    "name": "Wetumpka",
    "lng": -86.22765013536862,
    "lat": 32.56947955258485
  },
  {
    "name": "Wetumpka",
    "lng": -86.20393022640427,
    "lat": 32.538637965098715
  },
  {
    "name": "Wewahitchka",
    "lng": -85.22208310560178,
    "lat": 30.132407959703436
  },
  {
    "name": "Wewahitchka",
    "lng": -85.2010149813969,
    "lat": 30.0824128123961
  },
  {
    "name": "Wewahitchka",
    "lng": -85.1943214691688,
    "lat": 30.11224224550332
  },
  {
    "name": "Wewoka",
    "lng": -96.49668546847012,
    "lat": 35.14413133238573
  },
  {
    "name": "Weyauwega",
    "lng": -88.9348579300424,
    "lat": 44.32295221665419
  },
  {
    "name": "Weyauwega",
    "lng": -88.91903289037641,
    "lat": 44.33236470657841
  },
  {
    "name": "Weyerhaeuser",
    "lng": -91.41444374484237,
    "lat": 45.42509517318369
  },
  {
    "name": "Whalan",
    "lng": -91.9239085506494,
    "lat": 43.73400511820172
  },
  {
    "name": "Wharton",
    "lng": -96.10435884756743,
    "lat": 29.313766772936976
  },
  {
    "name": "Wharton",
    "lng": -83.46481035258549,
    "lat": 40.86130610778407
  },
  {
    "name": "What Cheer",
    "lng": -92.35470217677826,
    "lat": 41.399674518619406
  },
  {
    "name": "Whately",
    "lng": -72.64441115760904,
    "lat": 42.43833349394138
  },
  {
    "name": "Wheatcroft",
    "lng": -87.86116155898478,
    "lat": 37.48930562653147
  },
  {
    "name": "Wheatfield",
    "lng": -87.05267244087155,
    "lat": 41.19138765381873
  },
  {
    "name": "Wheatland",
    "lng": -121.42852702602517,
    "lat": 39.00797405101582
  },
  {
    "name": "Wheatland",
    "lng": -104.95945629658085,
    "lat": 42.05165179359495
  },
  {
    "name": "Wheatland",
    "lng": -93.40379780667936,
    "lat": 37.9432805531926
  },
  {
    "name": "Wheatland",
    "lng": -90.83923978584633,
    "lat": 41.82545636672645
  },
  {
    "name": "Wheatland",
    "lng": -90.83795827377611,
    "lat": 41.833400047470796
  },
  {
    "name": "Wheatland",
    "lng": -87.3064161234686,
    "lat": 38.66327145124394
  },
  {
    "name": "Wheatley",
    "lng": -91.10625657966011,
    "lat": 34.92112210351766
  },
  {
    "name": "Wheaton",
    "lng": -96.49805479489235,
    "lat": 45.805879954397554
  },
  {
    "name": "Wheaton",
    "lng": -96.31883509023956,
    "lat": 39.5021677680698
  },
  {
    "name": "Wheaton",
    "lng": -94.05699111935115,
    "lat": 36.761613324177354
  },
  {
    "name": "Wheaton",
    "lng": -88.14463001706082,
    "lat": 41.83487944556951
  },
  {
    "name": "Wheaton",
    "lng": -88.11026206804533,
    "lat": 41.86050070544429
  },
  {
    "name": "Wheeler",
    "lng": -100.27522899462309,
    "lat": 35.44111867498906
  },
  {
    "name": "Wheeler",
    "lng": -91.90402995622095,
    "lat": 45.043927573357166
  },
  {
    "name": "Wheeler",
    "lng": -88.31876418531017,
    "lat": 39.04304203573641
  },
  {
    "name": "Wheelersburg",
    "lng": -82.8420660948652,
    "lat": 38.738293347619795
  },
  {
    "name": "Wheeling",
    "lng": -93.38601098584694,
    "lat": 39.78606556219263
  },
  {
    "name": "Wheeling",
    "lng": -87.92405286218559,
    "lat": 42.13066274044487
  },
  {
    "name": "Wheeling",
    "lng": -80.6950909385684,
    "lat": 40.07494192611667
  },
  {
    "name": "Wheelwright",
    "lng": -82.71870231992307,
    "lat": 37.33887108862621
  },
  {
    "name": "Whelen Springs",
    "lng": -93.12602968268067,
    "lat": 33.83314831782144
  },
  {
    "name": "Whigham",
    "lng": -84.32490662973485,
    "lat": 30.88475356753718
  },
  {
    "name": "Whiskey Creek",
    "lng": -81.89029415799216,
    "lat": 26.573258135080273
  },
  {
    "name": "Whispering Pines",
    "lng": -79.40633055406826,
    "lat": 35.247802056800424
  },
  {
    "name": "Whispering Pines",
    "lng": -79.40547918621462,
    "lat": 35.24616906808523
  },
  {
    "name": "Whispering Pines",
    "lng": -79.37546252184876,
    "lat": 35.25373069404652
  },
  {
    "name": "Whispering Pines",
    "lng": -79.36301289324398,
    "lat": 35.25369110506347
  },
  {
    "name": "Whispering Pines",
    "lng": -79.36214651475642,
    "lat": 35.24475842191221
  },
  {
    "name": "Whispering Pines",
    "lng": -79.36155346398263,
    "lat": 35.27682144180842
  },
  {
    "name": "Whitakers",
    "lng": -77.7130872735292,
    "lat": 36.10574663047738
  },
  {
    "name": "White",
    "lng": -96.6461324570705,
    "lat": 44.43680809399096
  },
  {
    "name": "White",
    "lng": -84.74763231477246,
    "lat": 34.28198671808726
  },
  {
    "name": "White",
    "lng": -84.73831038482018,
    "lat": 34.291760252593235
  },
  {
    "name": "White Bear Lake",
    "lng": -93.0152167314374,
    "lat": 45.06517285367656
  },
  {
    "name": "White Bear Lake",
    "lng": -92.99187997482706,
    "lat": 45.08161685306661
  },
  {
    "name": "White Bird",
    "lng": -116.30035144819752,
    "lat": 45.76267870324501
  },
  {
    "name": "White Bluff",
    "lng": -87.2113318279737,
    "lat": 36.10691280557988
  },
  {
    "name": "White Castle",
    "lng": -91.14954194470477,
    "lat": 30.161022588906967
  },
  {
    "name": "White City",
    "lng": -96.7350117468587,
    "lat": 38.793902012055995
  },
  {
    "name": "White City",
    "lng": -89.76368172473991,
    "lat": 39.07154623569837
  },
  {
    "name": "White Cloud",
    "lng": -95.29908666586891,
    "lat": 39.97454727160751
  },
  {
    "name": "White Cloud",
    "lng": -85.7718195486546,
    "lat": 43.55393346185165
  },
  {
    "name": "White Deer",
    "lng": -101.17509098634156,
    "lat": 35.4331968741573
  },
  {
    "name": "White Earth",
    "lng": -102.77180901522884,
    "lat": 48.37986660635002
  },
  {
    "name": "White Hall",
    "lng": -92.10047841537093,
    "lat": 34.27355087400814
  },
  {
    "name": "White Hall",
    "lng": -90.40193030854516,
    "lat": 39.438792155331015
  },
  {
    "name": "White Haven",
    "lng": -75.78010948792466,
    "lat": 41.056638003864556
  },
  {
    "name": "White House",
    "lng": -86.665958366004,
    "lat": 36.46323427536552
  },
  {
    "name": "White House",
    "lng": -86.6284508838739,
    "lat": 36.465379495774194
  },
  {
    "name": "White Lake",
    "lng": -98.71171530021238,
    "lat": 43.72804046527716
  },
  {
    "name": "White Lake",
    "lng": -88.77037681082712,
    "lat": 45.16176800412555
  },
  {
    "name": "White Lake",
    "lng": -78.52286703988699,
    "lat": 34.64883927668997
  },
  {
    "name": "White Lake",
    "lng": -78.49711367586085,
    "lat": 34.64261485615496
  },
  {
    "name": "White Oak",
    "lng": -94.8561993406515,
    "lat": 32.53164112629351
  },
  {
    "name": "White Pigeon",
    "lng": -85.64852455465105,
    "lat": 41.797943231982764
  },
  {
    "name": "White Pine",
    "lng": -83.29632828297774,
    "lat": 36.10920713180095
  },
  {
    "name": "White Plains",
    "lng": -87.38319976493452,
    "lat": 37.18041261469669
  },
  {
    "name": "White Plains",
    "lng": -83.03715643375806,
    "lat": 33.48062454041802
  },
  {
    "name": "White Plains",
    "lng": -73.75482390062596,
    "lat": 41.02205261377188
  },
  {
    "name": "White River",
    "lng": -100.74478024687468,
    "lat": 43.56742830408863
  },
  {
    "name": "White River",
    "lng": -100.74303024643793,
    "lat": 43.57365162559286
  },
  {
    "name": "White Rock",
    "lng": -96.57617562241593,
    "lat": 45.92474542456034
  },
  {
    "name": "White Salmon",
    "lng": -121.48533045470552,
    "lat": 45.72845611364317
  },
  {
    "name": "White Settlement",
    "lng": -97.46041716281061,
    "lat": 32.75537890541256
  },
  {
    "name": "White Stone",
    "lng": -76.39109919283395,
    "lat": 37.64461632231617
  },
  {
    "name": "White Sulphur Springs",
    "lng": -110.90388894366717,
    "lat": 46.54603894776506
  },
  {
    "name": "White Sulphur Springs",
    "lng": -80.30170005746989,
    "lat": 37.796261382639656
  },
  {
    "name": "Whiteash",
    "lng": -88.92930996463029,
    "lat": 37.78536934313591
  },
  {
    "name": "Whiteface",
    "lng": -102.61340289305177,
    "lat": 33.599559483717094
  },
  {
    "name": "Whitefield",
    "lng": -95.23908223345917,
    "lat": 35.2545597753389
  },
  {
    "name": "Whitefield",
    "lng": -69.61259061506732,
    "lat": 44.205263434267195
  },
  {
    "name": "Whitefish",
    "lng": -114.36864053642604,
    "lat": 48.44689517841618
  },
  {
    "name": "Whitefish",
    "lng": -114.36792206445689,
    "lat": 48.423285531365586
  },
  {
    "name": "Whitefish",
    "lng": -114.3666027869932,
    "lat": 48.42114530940415
  },
  {
    "name": "Whitefish",
    "lng": -114.36132483453554,
    "lat": 48.440498099934395
  },
  {
    "name": "Whitefish",
    "lng": -114.35696737741132,
    "lat": 48.44003183090078
  },
  {
    "name": "Whitefish",
    "lng": -114.35600582800011,
    "lat": 48.439957538122584
  },
  {
    "name": "Whitefish",
    "lng": -114.34095130835374,
    "lat": 48.40122016008965
  },
  {
    "name": "Whitefish",
    "lng": -114.31131278302703,
    "lat": 48.413271749278536
  },
  {
    "name": "Whitefish Bay",
    "lng": -87.89974704707275,
    "lat": 43.11328250527941
  },
  {
    "name": "Whitehall",
    "lng": -112.09755795612622,
    "lat": 45.871055584660645
  },
  {
    "name": "Whitehall",
    "lng": -112.08577972135977,
    "lat": 45.87322225196902
  },
  {
    "name": "Whitehall",
    "lng": -91.32775705582662,
    "lat": 44.36539253525644
  },
  {
    "name": "Whitehall",
    "lng": -86.34061521975671,
    "lat": 43.40028838849595
  },
  {
    "name": "Whitehall",
    "lng": -82.88332626860534,
    "lat": 39.96829675309995
  },
  {
    "name": "Whitehall",
    "lng": -80.18873345760132,
    "lat": 39.42434043044133
  },
  {
    "name": "Whitehall",
    "lng": -73.41515098351302,
    "lat": 43.5562568400298
  },
  {
    "name": "Whitehouse",
    "lng": -95.22182561932655,
    "lat": 32.222304655290486
  },
  {
    "name": "Whitehouse",
    "lng": -83.79752425850825,
    "lat": 41.52138158328815
  },
  {
    "name": "Whiteland",
    "lng": -86.08186603958085,
    "lat": 39.5509734550726
  },
  {
    "name": "Whitelaw",
    "lng": -87.82791023661422,
    "lat": 44.14529910252169
  },
  {
    "name": "Whitesboro",
    "lng": -96.90262826953908,
    "lat": 33.66133078152525
  },
  {
    "name": "Whitesboro",
    "lng": -75.29679364024784,
    "lat": 43.123980012953325
  },
  {
    "name": "Whitesburg",
    "lng": -84.9135729203637,
    "lat": 33.492672552341354
  },
  {
    "name": "Whitesburg",
    "lng": -82.82601615042847,
    "lat": 37.11857085660321
  },
  {
    "name": "Whiteside",
    "lng": -91.01672959773721,
    "lat": 39.18390236059089
  },
  {
    "name": "Whitestown",
    "lng": -86.34943397080298,
    "lat": 39.99110868078296
  },
  {
    "name": "Whitesville",
    "lng": -86.86997228762652,
    "lat": 37.683733099116246
  },
  {
    "name": "Whitesville",
    "lng": -81.5423659547893,
    "lat": 37.990814589165836
  },
  {
    "name": "Whitesville",
    "lng": -81.5415631554384,
    "lat": 37.98323423837925
  },
  {
    "name": "Whitesville",
    "lng": -81.53946401512711,
    "lat": 37.981168664074104
  },
  {
    "name": "Whitesville",
    "lng": -81.537608,
    "lat": 37.98360456136291
  },
  {
    "name": "Whitesville",
    "lng": -81.53170929366064,
    "lat": 37.977005774700615
  },
  {
    "name": "Whiteville",
    "lng": -89.1491692723943,
    "lat": 35.31869020009582
  },
  {
    "name": "Whiteville",
    "lng": -78.72565174081846,
    "lat": 34.292479204810974
  },
  {
    "name": "Whiteville",
    "lng": -78.70143108987986,
    "lat": 34.33052106989148
  },
  {
    "name": "Whitewater",
    "lng": -97.14707201189331,
    "lat": 37.963273160669125
  },
  {
    "name": "Whitewater",
    "lng": -89.79787109596194,
    "lat": 37.23674827467571
  },
  {
    "name": "Whitewater",
    "lng": -88.73424533822839,
    "lat": 42.837086794455466
  },
  {
    "name": "Whitewater",
    "lng": -84.83114326537327,
    "lat": 39.94470592731295
  },
  {
    "name": "Whitewood",
    "lng": -103.63829884827238,
    "lat": 44.461815226922106
  },
  {
    "name": "Whitewright",
    "lng": -96.39621961955689,
    "lat": 33.51069130408857
  },
  {
    "name": "Whitewright",
    "lng": -96.39081332643192,
    "lat": 33.471772008835224
  },
  {
    "name": "Whitfield",
    "lng": -76.00362048923736,
    "lat": 40.33583089015894
  },
  {
    "name": "Whiting",
    "lng": -96.1507213079839,
    "lat": 42.12720577532563
  },
  {
    "name": "Whiting",
    "lng": -95.6111822440049,
    "lat": 39.587852219854476
  },
  {
    "name": "Whiting",
    "lng": -89.56053322711726,
    "lat": 44.49042351437678
  },
  {
    "name": "Whiting",
    "lng": -87.48404225172214,
    "lat": 41.673005178578336
  },
  {
    "name": "Whiting",
    "lng": -67.25282725266104,
    "lat": 44.76129917201961
  },
  {
    "name": "Whitmire",
    "lng": -81.61432347810296,
    "lat": 34.504053956250225
  },
  {
    "name": "Whitney",
    "lng": -103.25669785349604,
    "lat": 42.78375880999853
  },
  {
    "name": "Whitney",
    "lng": -97.31927323596273,
    "lat": 31.95202643182092
  },
  {
    "name": "Whitney Point",
    "lng": -75.96789585382379,
    "lat": 42.33027217644538
  },
  {
    "name": "Whitneyville",
    "lng": -67.52399085252856,
    "lat": 44.72945060033065
  },
  {
    "name": "Whitsett",
    "lng": -79.58333011326117,
    "lat": 36.07731608254219
  },
  {
    "name": "Whitsett",
    "lng": -79.57194964174128,
    "lat": 36.07848245381294
  },
  {
    "name": "Whittemore",
    "lng": -94.4250493675761,
    "lat": 43.06340176599356
  },
  {
    "name": "Whittemore",
    "lng": -83.80325561344168,
    "lat": 44.233677654137885
  },
  {
    "name": "Whitten",
    "lng": -93.00918411344072,
    "lat": 42.26256533489392
  },
  {
    "name": "Whittier",
    "lng": -148.63645740110937,
    "lat": 60.78857547019006
  },
  {
    "name": "Whittier",
    "lng": -118.05528264858806,
    "lat": 34.02612862105691
  },
  {
    "name": "Whittier",
    "lng": -118.0186608929216,
    "lat": 33.967619472377024
  },
  {
    "name": "Whitwell",
    "lng": -85.52057667553305,
    "lat": 35.19271367367406
  },
  {
    "name": "Whitwell",
    "lng": -85.49824290271994,
    "lat": 35.219882126006276
  },
  {
    "name": "Wibaux",
    "lng": -104.1900431907201,
    "lat": 46.986615357753635
  },
  {
    "name": "Wichita",
    "lng": -97.72535327497182,
    "lat": 37.49656929374388
  },
  {
    "name": "Wichita",
    "lng": -97.53128351281485,
    "lat": 37.662825757746866
  },
  {
    "name": "Wichita",
    "lng": -97.51755161744535,
    "lat": 37.663286276326644
  },
  {
    "name": "Wichita",
    "lng": -97.51403718576637,
    "lat": 37.662911086361305
  },
  {
    "name": "Wichita",
    "lng": -97.49816101738342,
    "lat": 37.72142233307544
  },
  {
    "name": "Wichita",
    "lng": -97.49628397237203,
    "lat": 37.65760656383863
  },
  {
    "name": "Wichita",
    "lng": -97.49453585004002,
    "lat": 37.755022925359015
  },
  {
    "name": "Wichita",
    "lng": -97.48992805317266,
    "lat": 37.653816821402444
  },
  {
    "name": "Wichita",
    "lng": -97.48818586805952,
    "lat": 37.64718183608056
  },
  {
    "name": "Wichita",
    "lng": -97.40887146268051,
    "lat": 37.76452231212069
  },
  {
    "name": "Wichita",
    "lng": -97.40576311959478,
    "lat": 37.613741247994625
  },
  {
    "name": "Wichita",
    "lng": -97.39085913112005,
    "lat": 37.61596195783025
  },
  {
    "name": "Wichita",
    "lng": -97.34802478272181,
    "lat": 37.794579276789044
  },
  {
    "name": "Wichita",
    "lng": -97.34380967868823,
    "lat": 37.691428173457524
  },
  {
    "name": "Wichita",
    "lng": -97.29878556206816,
    "lat": 37.57773171902914
  },
  {
    "name": "Wichita",
    "lng": -97.29836484752147,
    "lat": 37.59158091911646
  },
  {
    "name": "Wichita",
    "lng": -97.29682779495468,
    "lat": 37.62249129410243
  },
  {
    "name": "Wichita",
    "lng": -97.29590390339848,
    "lat": 37.56420475098686
  },
  {
    "name": "Wichita",
    "lng": -97.29049638320112,
    "lat": 37.59455763945797
  },
  {
    "name": "Wichita",
    "lng": -97.2360232637256,
    "lat": 37.78164082452435
  },
  {
    "name": "Wichita",
    "lng": -97.19422710749767,
    "lat": 37.64647326725379
  },
  {
    "name": "Wichita",
    "lng": -97.19233713161205,
    "lat": 37.831737473421654
  },
  {
    "name": "Wichita",
    "lng": -97.18238796107863,
    "lat": 37.691789259923404
  },
  {
    "name": "Wichita",
    "lng": -97.16631275494372,
    "lat": 37.686112049986036
  },
  {
    "name": "Wichita",
    "lng": -97.15702312032938,
    "lat": 37.69688513696484
  },
  {
    "name": "Wichita Falls",
    "lng": -98.52901009253728,
    "lat": 33.90741403342582
  },
  {
    "name": "Wickes",
    "lng": -94.3331680358495,
    "lat": 34.29883932296869
  },
  {
    "name": "Wickett",
    "lng": -103.00594776787477,
    "lat": 31.567325408197423
  },
  {
    "name": "Wickliffe",
    "lng": -89.08223419863927,
    "lat": 36.96582418151036
  },
  {
    "name": "Wickliffe",
    "lng": -81.46904563842915,
    "lat": 41.607208319259264
  },
  {
    "name": "Widener",
    "lng": -90.6836809305145,
    "lat": 35.022665639986855
  },
  {
    "name": "Wiederkehr Village",
    "lng": -93.7578518609709,
    "lat": 35.481275406934415
  },
  {
    "name": "Wiggins",
    "lng": -104.07317533793079,
    "lat": 40.22750955091276
  },
  {
    "name": "Wiggins",
    "lng": -89.13855785037391,
    "lat": 30.855665287928527
  },
  {
    "name": "Wilber",
    "lng": -96.97738450539217,
    "lat": 40.477230236583054
  },
  {
    "name": "Wilber",
    "lng": -96.96359189621289,
    "lat": 40.480804536286115
  },
  {
    "name": "Wilbraham",
    "lng": -72.43083098117651,
    "lat": 42.12700079908052
  },
  {
    "name": "Wilbur",
    "lng": -118.75223522426735,
    "lat": 47.75032435522836
  },
  {
    "name": "Wilbur",
    "lng": -118.72820264565696,
    "lat": 47.76825496570429
  },
  {
    "name": "Wilbur",
    "lng": -118.70506459387252,
    "lat": 47.75766479593874
  },
  {
    "name": "Wilbur Park",
    "lng": -90.30871821287597,
    "lat": 38.55324089535919
  },
  {
    "name": "Wilburton",
    "lng": -95.3047128163694,
    "lat": 34.91834484031353
  },
  {
    "name": "Wilcox",
    "lng": -99.1694103752013,
    "lat": 40.364112007459944
  },
  {
    "name": "Wild Rose",
    "lng": -89.24678457017569,
    "lat": 44.17674423298725
  },
  {
    "name": "Wild Rose",
    "lng": -89.21614998040485,
    "lat": 44.19924423632102
  },
  {
    "name": "Wilder",
    "lng": -116.90700071890596,
    "lat": 43.678111642906636
  },
  {
    "name": "Wilder",
    "lng": -95.2058468069821,
    "lat": 43.8280379994699
  },
  {
    "name": "Wilder",
    "lng": -84.48150595232893,
    "lat": 39.0402204475899
  },
  {
    "name": "Wildrose",
    "lng": -103.18380031471374,
    "lat": 48.6298737501039
  },
  {
    "name": "Wildwood",
    "lng": -90.66968944281763,
    "lat": 38.579843682358316
  },
  {
    "name": "Wildwood",
    "lng": -85.5742490988901,
    "lat": 38.24883001869173
  },
  {
    "name": "Wildwood",
    "lng": -82.05342686139947,
    "lat": 28.859754114169768
  },
  {
    "name": "Wildwood",
    "lng": -82.05252616211101,
    "lat": 28.912552793381572
  },
  {
    "name": "Wildwood",
    "lng": -82.04380413076336,
    "lat": 28.928259436339793
  },
  {
    "name": "Wildwood",
    "lng": -82.0381434796973,
    "lat": 28.85277762319155
  },
  {
    "name": "Wildwood",
    "lng": -82.03784615279284,
    "lat": 28.89214767371051
  },
  {
    "name": "Wildwood",
    "lng": -82.0366552235311,
    "lat": 28.926431697735
  },
  {
    "name": "Wildwood",
    "lng": -82.03659786551681,
    "lat": 28.892489524801725
  },
  {
    "name": "Wildwood",
    "lng": -82.03633627706148,
    "lat": 28.946567984622416
  },
  {
    "name": "Wildwood",
    "lng": -82.03600950084338,
    "lat": 28.89646502263639
  },
  {
    "name": "Wildwood",
    "lng": -82.03593468648543,
    "lat": 28.929933948542867
  },
  {
    "name": "Wildwood",
    "lng": -82.03553091389183,
    "lat": 28.926995055883257
  },
  {
    "name": "Wildwood",
    "lng": -82.03510026554746,
    "lat": 28.89054889451451
  },
  {
    "name": "Wildwood",
    "lng": -82.03277594363122,
    "lat": 28.852574494373187
  },
  {
    "name": "Wildwood",
    "lng": -82.03262131008366,
    "lat": 28.938938126768022
  },
  {
    "name": "Wildwood",
    "lng": -82.03074921971606,
    "lat": 28.926662652644026
  },
  {
    "name": "Wildwood",
    "lng": -82.02925481077297,
    "lat": 28.905291481834787
  },
  {
    "name": "Wildwood",
    "lng": -82.02731670328667,
    "lat": 28.91158454910914
  },
  {
    "name": "Wildwood",
    "lng": -82.02022088631709,
    "lat": 28.89127998429338
  },
  {
    "name": "Wildwood",
    "lng": -82.01827456118592,
    "lat": 28.905741136498307
  },
  {
    "name": "Wildwood",
    "lng": -82.01742721146563,
    "lat": 28.891790883497578
  },
  {
    "name": "Wildwood",
    "lng": -82.00712517412454,
    "lat": 28.86834835531472
  },
  {
    "name": "Wildwood",
    "lng": -81.9926296406457,
    "lat": 28.83086931931537
  },
  {
    "name": "Wildwood",
    "lng": -81.98558981192485,
    "lat": 28.74234633367513
  },
  {
    "name": "Wildwood",
    "lng": -81.97299332374872,
    "lat": 28.816651839060487
  },
  {
    "name": "Wildwood",
    "lng": -74.81822900449414,
    "lat": 38.987147015288784
  },
  {
    "name": "Wiley",
    "lng": -102.719201692455,
    "lat": 38.155927933108885
  },
  {
    "name": "Wilkes-Barre",
    "lng": -75.87604732402369,
    "lat": 41.24691828673392
  },
  {
    "name": "Wilkesboro",
    "lng": -81.17402185893327,
    "lat": 36.141497896369735
  },
  {
    "name": "Wilkeson",
    "lng": -122.04942307534913,
    "lat": 47.103625723046676
  },
  {
    "name": "Wilkesville",
    "lng": -82.32717519103021,
    "lat": 39.076450258140426
  },
  {
    "name": "Wilkinson",
    "lng": -85.6078612395098,
    "lat": 39.885989823802426
  },
  {
    "name": "Willacoochee",
    "lng": -83.04470772594509,
    "lat": 31.336100786262268
  },
  {
    "name": "Willard",
    "lng": -112.04478708397524,
    "lat": 41.41482018223149
  },
  {
    "name": "Willard",
    "lng": -106.03172379393601,
    "lat": 34.594859336875835
  },
  {
    "name": "Willard",
    "lng": -95.94336309020944,
    "lat": 39.09387993092087
  },
  {
    "name": "Willard",
    "lng": -93.41693378508295,
    "lat": 37.29433855891156
  },
  {
    "name": "Willard",
    "lng": -82.7233149790172,
    "lat": 41.05192998170957
  },
  {
    "name": "Willards",
    "lng": -75.34930030756526,
    "lat": 38.39218069495811
  },
  {
    "name": "Willernie",
    "lng": -92.95709708798245,
    "lat": 45.053900918174016
  },
  {
    "name": "Willey",
    "lng": -94.82238278915193,
    "lat": 41.9787830713857
  },
  {
    "name": "Williams",
    "lng": -122.13725283936816,
    "lat": 39.14868718548549
  },
  {
    "name": "Williams",
    "lng": -112.18314681245401,
    "lat": 35.24834392622057
  },
  {
    "name": "Williams",
    "lng": -94.95329701357616,
    "lat": 48.76795154503262
  },
  {
    "name": "Williams",
    "lng": -93.5410901721315,
    "lat": 42.48974665206481
  },
  {
    "name": "Williams",
    "lng": -80.84284360422488,
    "lat": 33.034153856599715
  },
  {
    "name": "Williams Bay",
    "lng": -88.54402263256608,
    "lat": 42.57662642599609
  },
  {
    "name": "Williams Creek",
    "lng": -86.15025831568,
    "lat": 39.90023044663362
  },
  {
    "name": "Williamsburg",
    "lng": -107.29611641921447,
    "lat": 33.11564683354491
  },
  {
    "name": "Williamsburg",
    "lng": -105.16873564704777,
    "lat": 38.38333361418411
  },
  {
    "name": "Williamsburg",
    "lng": -95.47745091645574,
    "lat": 38.48990680305065
  },
  {
    "name": "Williamsburg",
    "lng": -95.47359129408089,
    "lat": 38.49211773237069
  },
  {
    "name": "Williamsburg",
    "lng": -95.4703630312002,
    "lat": 38.4816512576284
  },
  {
    "name": "Williamsburg",
    "lng": -92.0100506459987,
    "lat": 41.668364123885425
  },
  {
    "name": "Williamsburg",
    "lng": -84.187493259208,
    "lat": 36.72482297398606
  },
  {
    "name": "Williamsburg",
    "lng": -84.16385709292528,
    "lat": 36.73917385772807
  },
  {
    "name": "Williamsburg",
    "lng": -84.0469299842121,
    "lat": 39.05183129766756
  },
  {
    "name": "Williamsburg",
    "lng": -81.44459027602096,
    "lat": 28.40597263158332
  },
  {
    "name": "Williamsburg",
    "lng": -76.70764388686335,
    "lat": 37.269322382170344
  },
  {
    "name": "Williamsburg",
    "lng": -72.72953495922063,
    "lat": 42.40243528029227
  },
  {
    "name": "Williamsburg CDP",
    "lng": -81.44630527818946,
    "lat": 28.400612520190286
  },
  {
    "name": "Williamsfield",
    "lng": -90.01816754388776,
    "lat": 40.92638020790536
  },
  {
    "name": "Williamson",
    "lng": -93.25697681122467,
    "lat": 41.08826214728789
  },
  {
    "name": "Williamson",
    "lng": -89.7643782254836,
    "lat": 38.98723209430643
  },
  {
    "name": "Williamson",
    "lng": -84.35996137899306,
    "lat": 33.17821625695236
  },
  {
    "name": "Williamson",
    "lng": -82.2708875369954,
    "lat": 37.67411770664396
  },
  {
    "name": "Williamsport",
    "lng": -87.29337603929498,
    "lat": 40.28674356814575
  },
  {
    "name": "Williamsport",
    "lng": -83.12055002573555,
    "lat": 39.582403177319655
  },
  {
    "name": "Williamsport",
    "lng": -77.03674499706996,
    "lat": 41.23983796485374
  },
  {
    "name": "Williamston",
    "lng": -84.28461091583816,
    "lat": 42.68382279789513
  },
  {
    "name": "Williamston",
    "lng": -82.47942217121094,
    "lat": 34.61931242844102
  },
  {
    "name": "Williamston",
    "lng": -77.10981082542872,
    "lat": 35.818635892687304
  },
  {
    "name": "Williamston",
    "lng": -77.06608757644278,
    "lat": 35.81733603910909
  },
  {
    "name": "Williamston",
    "lng": -77.06575797953357,
    "lat": 35.82836180318388
  },
  {
    "name": "Williamston",
    "lng": -77.0649930167346,
    "lat": 35.849401849352674
  },
  {
    "name": "Williamstown",
    "lng": -84.56888488129889,
    "lat": 38.64047201458374
  },
  {
    "name": "Williamstown",
    "lng": -81.45168624160779,
    "lat": 39.40048778358397
  },
  {
    "name": "Williamstown",
    "lng": -73.22832793464504,
    "lat": 42.684789828734964
  },
  {
    "name": "Williamsville",
    "lng": -90.5478283217314,
    "lat": 36.97365432053028
  },
  {
    "name": "Williamsville",
    "lng": -89.55069741942852,
    "lat": 39.95173842129163
  },
  {
    "name": "Williamsville",
    "lng": -78.74176300806394,
    "lat": 42.96243739952021
  },
  {
    "name": "Williford",
    "lng": -91.36045756750399,
    "lat": 36.2518315238181
  },
  {
    "name": "Willimantic",
    "lng": -69.37731718285272,
    "lat": 45.30696229828736
  },
  {
    "name": "Willington",
    "lng": -72.25921370944872,
    "lat": 41.88973462632651
  },
  {
    "name": "Willis",
    "lng": -95.50568452019971,
    "lat": 39.7226687898898
  },
  {
    "name": "Willis",
    "lng": -95.483197910392,
    "lat": 30.43144029271685
  },
  {
    "name": "Willisburg",
    "lng": -85.1208055815307,
    "lat": 37.80943225238351
  },
  {
    "name": "Williston",
    "lng": -103.63641931714434,
    "lat": 48.181395815609676
  },
  {
    "name": "Williston",
    "lng": -89.37544675714355,
    "lat": 35.15824138983649
  },
  {
    "name": "Williston",
    "lng": -82.45790335456948,
    "lat": 29.3739651775613
  },
  {
    "name": "Williston",
    "lng": -81.42209146372115,
    "lat": 33.40174195796883
  },
  {
    "name": "Williston Park",
    "lng": -73.6465776250647,
    "lat": 40.75874198432688
  },
  {
    "name": "Willisville",
    "lng": -93.29490840895242,
    "lat": 33.5193732121784
  },
  {
    "name": "Willisville",
    "lng": -89.58980023136807,
    "lat": 37.98281814318312
  },
  {
    "name": "Willits",
    "lng": -123.37249837455568,
    "lat": 39.44987941626622
  },
  {
    "name": "Willits",
    "lng": -123.34939286742726,
    "lat": 39.40400352475836
  },
  {
    "name": "Willits",
    "lng": -123.30195779686099,
    "lat": 39.3699758792054
  },
  {
    "name": "Willits",
    "lng": -123.30165803374133,
    "lat": 39.38212468361028
  },
  {
    "name": "Willmar",
    "lng": -95.1510229116198,
    "lat": 45.09874119712934
  },
  {
    "name": "Willmar",
    "lng": -95.13247490064373,
    "lat": 45.11793524740624
  },
  {
    "name": "Willmar",
    "lng": -95.11327236376925,
    "lat": 45.109248527182885
  },
  {
    "name": "Willmar",
    "lng": -95.04914831400662,
    "lat": 45.12279094804661
  },
  {
    "name": "Willoughby",
    "lng": -81.40835700724904,
    "lat": 41.64614528419872
  },
  {
    "name": "Willoughby Hills",
    "lng": -81.43331508523396,
    "lat": 41.5872436585153
  },
  {
    "name": "Willow",
    "lng": -99.50992105015642,
    "lat": 35.051508846940145
  },
  {
    "name": "Willow City",
    "lng": -100.2932562811865,
    "lat": 48.604480770654064
  },
  {
    "name": "Willow Hill",
    "lng": -88.02181655421535,
    "lat": 38.995838804057875
  },
  {
    "name": "Willow Lake",
    "lng": -97.63857910040774,
    "lat": 44.6279437686506
  },
  {
    "name": "Willow Park",
    "lng": -97.65040835899896,
    "lat": 32.753453278633025
  },
  {
    "name": "Willow River",
    "lng": -92.83466102548111,
    "lat": 46.320529579719455
  },
  {
    "name": "Willow Springs",
    "lng": -91.98651836085254,
    "lat": 36.98138678591391
  },
  {
    "name": "Willow Springs",
    "lng": -91.98171824530972,
    "lat": 36.98207707800272
  },
  {
    "name": "Willow Springs",
    "lng": -91.96290354203362,
    "lat": 36.988537670792006
  },
  {
    "name": "Willow Springs",
    "lng": -87.88432775193282,
    "lat": 41.750761758238724
  },
  {
    "name": "Willow Springs",
    "lng": -87.88354790814215,
    "lat": 41.73464450053551
  },
  {
    "name": "Willow Street",
    "lng": -76.27050900576353,
    "lat": 39.98084836602397
  },
  {
    "name": "Willowbrook",
    "lng": -97.99200984806788,
    "lat": 38.10109958301848
  },
  {
    "name": "Willowbrook",
    "lng": -87.94548684603085,
    "lat": 41.76395531855658
  },
  {
    "name": "Willowcreek Ranch",
    "lng": -95.65109542737258,
    "lat": 30.06152245709598
  },
  {
    "name": "Willowick",
    "lng": -81.46786411230967,
    "lat": 41.634298591016176
  },
  {
    "name": "Willows",
    "lng": -122.19923832205482,
    "lat": 39.5146387172933
  },
  {
    "name": "Wills Point",
    "lng": -96.00476648896006,
    "lat": 32.709466277055505
  },
  {
    "name": "Willshire",
    "lng": -84.79250553735426,
    "lat": 40.74657569356341
  },
  {
    "name": "Wilmar",
    "lng": -91.9293418991196,
    "lat": 33.62637948082045
  },
  {
    "name": "Wilmer",
    "lng": -96.68574914377315,
    "lat": 32.61701248530939
  },
  {
    "name": "Wilmer",
    "lng": -96.68256090786669,
    "lat": 32.597475658200274
  },
  {
    "name": "Wilmette",
    "lng": -87.72788212565365,
    "lat": 42.077092194491
  },
  {
    "name": "Wilmington",
    "lng": -90.48676584920987,
    "lat": 39.48068350885634
  },
  {
    "name": "Wilmington",
    "lng": -88.23354990104855,
    "lat": 41.373938706800274
  },
  {
    "name": "Wilmington",
    "lng": -88.20957039999998,
    "lat": 41.304095200936615
  },
  {
    "name": "Wilmington",
    "lng": -88.20743732570716,
    "lat": 41.32415869188058
  },
  {
    "name": "Wilmington",
    "lng": -88.18578043672778,
    "lat": 41.28781411972635
  },
  {
    "name": "Wilmington",
    "lng": -88.16902973815704,
    "lat": 41.31162276064988
  },
  {
    "name": "Wilmington",
    "lng": -88.16403872707859,
    "lat": 41.30042099347746
  },
  {
    "name": "Wilmington",
    "lng": -88.16356993585707,
    "lat": 41.30148845584369
  },
  {
    "name": "Wilmington",
    "lng": -88.15690374832943,
    "lat": 41.31393575089599
  },
  {
    "name": "Wilmington",
    "lng": -88.15001006285206,
    "lat": 41.28792836922718
  },
  {
    "name": "Wilmington",
    "lng": -88.12521393517187,
    "lat": 41.275538153655035
  },
  {
    "name": "Wilmington",
    "lng": -88.11858025959384,
    "lat": 41.295094069307275
  },
  {
    "name": "Wilmington",
    "lng": -88.10979193380736,
    "lat": 41.33929262648963
  },
  {
    "name": "Wilmington",
    "lng": -83.82776720145267,
    "lat": 39.43908339961689
  },
  {
    "name": "Wilmington",
    "lng": -77.89857956062203,
    "lat": 34.14896999590665
  },
  {
    "name": "Wilmington",
    "lng": -77.89441237597738,
    "lat": 34.15104942778055
  },
  {
    "name": "Wilmington",
    "lng": -77.88430402151462,
    "lat": 34.212981073727356
  },
  {
    "name": "Wilmington",
    "lng": -71.1654038537123,
    "lat": 42.56092701032063
  },
  {
    "name": "Wilmont",
    "lng": -95.8268885331057,
    "lat": 43.76530479724989
  },
  {
    "name": "Wilmore",
    "lng": -99.21026624046145,
    "lat": 37.33492260481669
  },
  {
    "name": "Wilmore",
    "lng": -84.6574801235754,
    "lat": 37.86967889540874
  },
  {
    "name": "Wilmot",
    "lng": -96.85689367072582,
    "lat": 45.40920591063268
  },
  {
    "name": "Wilmot",
    "lng": -91.57484404380246,
    "lat": 33.05875181427363
  },
  {
    "name": "Wilmot",
    "lng": -81.6344148131206,
    "lat": 40.65588251941571
  },
  {
    "name": "Wilmot",
    "lng": -71.91792034770829,
    "lat": 43.451660077636
  },
  {
    "name": "Wilsey",
    "lng": -96.67647902391043,
    "lat": 38.63580203291353
  },
  {
    "name": "Wilson",
    "lng": -101.7273533952991,
    "lat": 33.31932130503676
  },
  {
    "name": "Wilson",
    "lng": -98.47490907631591,
    "lat": 38.8253085148669
  },
  {
    "name": "Wilson",
    "lng": -97.42600436506677,
    "lat": 34.168375553812076
  },
  {
    "name": "Wilson",
    "lng": -92.17047740392483,
    "lat": 44.95852292077394
  },
  {
    "name": "Wilson",
    "lng": -91.10899339226582,
    "lat": 30.923644712421577
  },
  {
    "name": "Wilson",
    "lng": -90.04295790904959,
    "lat": 35.5661017159863
  },
  {
    "name": "Wilson",
    "lng": -81.06890197981421,
    "lat": 39.85978876317481
  },
  {
    "name": "Wilson",
    "lng": -78.82642750558838,
    "lat": 43.31155726084928
  },
  {
    "name": "Wilson",
    "lng": -78.00316171643185,
    "lat": 35.71050450555492
  },
  {
    "name": "Wilson",
    "lng": -77.97156150389992,
    "lat": 35.74741506521257
  },
  {
    "name": "Wilson",
    "lng": -77.97030199584056,
    "lat": 35.73376716025433
  },
  {
    "name": "Wilson",
    "lng": -77.95651632643994,
    "lat": 35.77741580511265
  },
  {
    "name": "Wilson",
    "lng": -77.950565891024,
    "lat": 35.80200031278808
  },
  {
    "name": "Wilson",
    "lng": -77.95002866666665,
    "lat": 35.70421599971178
  },
  {
    "name": "Wilson",
    "lng": -77.94881686266685,
    "lat": 35.68716344451297
  },
  {
    "name": "Wilson",
    "lng": -77.94824007348741,
    "lat": 35.812278793896255
  },
  {
    "name": "Wilson",
    "lng": -77.93229422912682,
    "lat": 35.733909492819855
  },
  {
    "name": "Wilson",
    "lng": -77.91460660722684,
    "lat": 35.77834783428702
  },
  {
    "name": "Wilson",
    "lng": -77.9141598760895,
    "lat": 35.76508256068347
  },
  {
    "name": "Wilson",
    "lng": -77.87281994459282,
    "lat": 35.75125722641418
  },
  {
    "name": "Wilson",
    "lng": -77.86995009451734,
    "lat": 35.75459219919668
  },
  {
    "name": "Wilson",
    "lng": -77.86627190054934,
    "lat": 35.75418129773874
  },
  {
    "name": "Wilson City",
    "lng": -89.22301701129601,
    "lat": 36.92358758218413
  },
  {
    "name": "Wilson Creek",
    "lng": -119.11784123172357,
    "lat": 47.42296348143959
  },
  {
    "name": "Wilsons Mills",
    "lng": -78.35597498668722,
    "lat": 35.584010491758065
  },
  {
    "name": "Wilsonville",
    "lng": -100.10667633087178,
    "lat": 40.11187357815422
  },
  {
    "name": "Wilsonville",
    "lng": -89.85530346986253,
    "lat": 39.06942928703224
  },
  {
    "name": "Wilsonville",
    "lng": -86.48652204685119,
    "lat": 33.23550663692413
  },
  {
    "name": "Wilsonville",
    "lng": -86.48094715074018,
    "lat": 33.25972204448943
  },
  {
    "name": "Wilton",
    "lng": -100.78507961145526,
    "lat": 47.15833970021709
  },
  {
    "name": "Wilton",
    "lng": -95.00223160726857,
    "lat": 47.51577533585743
  },
  {
    "name": "Wilton",
    "lng": -94.99704945966042,
    "lat": 47.50574407029486
  },
  {
    "name": "Wilton",
    "lng": -94.97832465247174,
    "lat": 47.51503099080567
  },
  {
    "name": "Wilton",
    "lng": -94.14728321290823,
    "lat": 33.740067758029895
  },
  {
    "name": "Wilton",
    "lng": -91.02699298687011,
    "lat": 41.58990700908467
  },
  {
    "name": "Wilton",
    "lng": -90.52690803405811,
    "lat": 43.81328578540284
  },
  {
    "name": "Wilton",
    "lng": -86.88000928173807,
    "lat": 33.08156671500326
  },
  {
    "name": "Wilton",
    "lng": -73.43999663367978,
    "lat": 41.20702281743161
  },
  {
    "name": "Wilton",
    "lng": -71.77330568721601,
    "lat": 42.831288269780536
  },
  {
    "name": "Wilton",
    "lng": -70.24654990136419,
    "lat": 44.61379081928036
  },
  {
    "name": "Wimberley",
    "lng": -98.08975779619352,
    "lat": 29.984094522341856
  },
  {
    "name": "Wimbledon",
    "lng": -98.4595413683127,
    "lat": 47.1705227213022
  },
  {
    "name": "Wimbledon",
    "lng": -98.45190200542173,
    "lat": 47.16217672430839
  },
  {
    "name": "Winamac",
    "lng": -86.60345559915682,
    "lat": 41.053315958109096
  },
  {
    "name": "Winchendon",
    "lng": -72.04875280214712,
    "lat": 42.66679080336834
  },
  {
    "name": "Winchester",
    "lng": -116.62397975189377,
    "lat": 46.24080603447605
  },
  {
    "name": "Winchester",
    "lng": -95.9983225941814,
    "lat": 35.7906097288207
  },
  {
    "name": "Winchester",
    "lng": -95.26894029931842,
    "lat": 39.32259033321616
  },
  {
    "name": "Winchester",
    "lng": -91.47369257703103,
    "lat": 33.77411727064365
  },
  {
    "name": "Winchester",
    "lng": -90.5260960777232,
    "lat": 38.58965560280464
  },
  {
    "name": "Winchester",
    "lng": -90.455961347137,
    "lat": 39.629780254241126
  },
  {
    "name": "Winchester",
    "lng": -86.11284557642169,
    "lat": 35.23621618820177
  },
  {
    "name": "Winchester",
    "lng": -86.1085205881275,
    "lat": 35.18962843117089
  },
  {
    "name": "Winchester",
    "lng": -84.97612444155315,
    "lat": 40.17201713517579
  },
  {
    "name": "Winchester",
    "lng": -84.1902983125567,
    "lat": 38.002359757888264
  },
  {
    "name": "Winchester",
    "lng": -83.65397134317263,
    "lat": 38.943625451682145
  },
  {
    "name": "Winchester",
    "lng": -78.17466419616747,
    "lat": 39.173494102176676
  },
  {
    "name": "Winchester",
    "lng": -73.10280467812102,
    "lat": 41.921877092161765
  },
  {
    "name": "Winchester",
    "lng": -71.14630541373158,
    "lat": 42.45178994475481
  },
  {
    "name": "Wind Point",
    "lng": -87.77077829922972,
    "lat": 42.78144196283559
  },
  {
    "name": "Windber",
    "lng": -78.82470093454398,
    "lat": 40.23553258591255
  },
  {
    "name": "Windcrest",
    "lng": -98.38170841913613,
    "lat": 29.51508191067268
  },
  {
    "name": "Winder",
    "lng": -83.76069923433555,
    "lat": 34.002761958638416
  },
  {
    "name": "Winder",
    "lng": -83.74728171106015,
    "lat": 34.00389948442029
  },
  {
    "name": "Winder",
    "lng": -83.7206653480645,
    "lat": 33.99130711991619
  },
  {
    "name": "Windermere",
    "lng": -81.53753734467527,
    "lat": 28.502915877306133
  },
  {
    "name": "Windermere",
    "lng": -81.51314778194437,
    "lat": 28.49283077324633
  },
  {
    "name": "Windfall City",
    "lng": -85.95717064359829,
    "lat": 40.36252290620206
  },
  {
    "name": "Windham",
    "lng": -81.03728856235955,
    "lat": 41.23746931731682
  },
  {
    "name": "Windham",
    "lng": -71.29935434520945,
    "lat": 42.80767646307624
  },
  {
    "name": "Windham",
    "lng": -70.4055554252925,
    "lat": 43.79806068450874
  },
  {
    "name": "Windom",
    "lng": -97.91097505630172,
    "lat": 38.38420433184978
  },
  {
    "name": "Windom",
    "lng": -95.99851958769084,
    "lat": 33.564798882888056
  },
  {
    "name": "Windom",
    "lng": -95.12042765547325,
    "lat": 43.873851259239444
  },
  {
    "name": "Windom",
    "lng": -95.10312163727667,
    "lat": 43.861422621232705
  },
  {
    "name": "Windom",
    "lng": -95.09853247232773,
    "lat": 43.857525436659934
  },
  {
    "name": "Windsor",
    "lng": -122.85685631940619,
    "lat": 38.5085365199929
  },
  {
    "name": "Windsor",
    "lng": -122.85288599039446,
    "lat": 38.56088748160958
  },
  {
    "name": "Windsor",
    "lng": -122.84836346013668,
    "lat": 38.49723624846054
  },
  {
    "name": "Windsor",
    "lng": -122.82817580860842,
    "lat": 38.524865578832156
  },
  {
    "name": "Windsor",
    "lng": -122.80486094329919,
    "lat": 38.54401535055953
  },
  {
    "name": "Windsor",
    "lng": -122.80442693369767,
    "lat": 38.51972976073264
  },
  {
    "name": "Windsor",
    "lng": -122.76033921796386,
    "lat": 38.53245550759881
  },
  {
    "name": "Windsor",
    "lng": -104.92075412659865,
    "lat": 40.468461676944486
  },
  {
    "name": "Windsor",
    "lng": -93.52278958673885,
    "lat": 38.53220552805538
  },
  {
    "name": "Windsor",
    "lng": -90.44435910203322,
    "lat": 41.201821801345595
  },
  {
    "name": "Windsor",
    "lng": -88.59538784179762,
    "lat": 39.43889406303478
  },
  {
    "name": "Windsor",
    "lng": -81.51333004311812,
    "lat": 33.480766650691
  },
  {
    "name": "Windsor",
    "lng": -76.96989372485618,
    "lat": 35.977162805158706
  },
  {
    "name": "Windsor",
    "lng": -76.95887131244274,
    "lat": 36.02531391198648
  },
  {
    "name": "Windsor",
    "lng": -76.9576831003328,
    "lat": 35.9866479492283
  },
  {
    "name": "Windsor",
    "lng": -76.95534962590588,
    "lat": 35.979978373936795
  },
  {
    "name": "Windsor",
    "lng": -76.95513224635535,
    "lat": 35.9836499788554
  },
  {
    "name": "Windsor",
    "lng": -76.95270742903809,
    "lat": 35.97806281154139
  },
  {
    "name": "Windsor",
    "lng": -76.94909561048321,
    "lat": 35.981044626277985
  },
  {
    "name": "Windsor",
    "lng": -76.94117471130251,
    "lat": 35.995922512741664
  },
  {
    "name": "Windsor",
    "lng": -76.91006418738837,
    "lat": 35.96823360156211
  },
  {
    "name": "Windsor",
    "lng": -76.74062149187317,
    "lat": 36.80953408616981
  },
  {
    "name": "Windsor",
    "lng": -76.58396653636458,
    "lat": 39.916278464937164
  },
  {
    "name": "Windsor",
    "lng": -75.64055532022117,
    "lat": 42.07756398621817
  },
  {
    "name": "Windsor",
    "lng": -73.04338714673587,
    "lat": 42.513626997695276
  },
  {
    "name": "Windsor",
    "lng": -72.67367180644459,
    "lat": 41.87130916393255
  },
  {
    "name": "Windsor",
    "lng": -72.01771929438246,
    "lat": 43.112662849667686
  },
  {
    "name": "Windsor",
    "lng": -69.57804757217544,
    "lat": 44.31102562805396
  },
  {
    "name": "Windsor Heights",
    "lng": -93.71280259669,
    "lat": 41.604461539562955
  },
  {
    "name": "Windsor Heights",
    "lng": -80.66473572143292,
    "lat": 40.19132595491535
  },
  {
    "name": "Windsor Locks",
    "lng": -72.65483193023103,
    "lat": 41.926797466809184
  },
  {
    "name": "Windsor Place",
    "lng": -92.70293462892396,
    "lat": 38.93483818844301
  },
  {
    "name": "Windthorst",
    "lng": -98.43488451065846,
    "lat": 33.576024001428884
  },
  {
    "name": "Windy Hills",
    "lng": -85.63768364826439,
    "lat": 38.27030843840912
  },
  {
    "name": "Winfall",
    "lng": -76.45441825446551,
    "lat": 36.210270197144844
  },
  {
    "name": "Winfield",
    "lng": -97.01848232561136,
    "lat": 37.2214973397212
  },
  {
    "name": "Winfield",
    "lng": -96.98583392774707,
    "lat": 37.237769975710776
  },
  {
    "name": "Winfield",
    "lng": -96.9596691999837,
    "lat": 37.2296819516653
  },
  {
    "name": "Winfield",
    "lng": -96.87121427621264,
    "lat": 37.354737006440025
  },
  {
    "name": "Winfield",
    "lng": -95.11048674015852,
    "lat": 33.165774073697726
  },
  {
    "name": "Winfield",
    "lng": -91.43813280425768,
    "lat": 41.12591256834514
  },
  {
    "name": "Winfield",
    "lng": -90.74272530075045,
    "lat": 38.99483394840494
  },
  {
    "name": "Winfield",
    "lng": -88.150662274567,
    "lat": 41.87735463513563
  },
  {
    "name": "Winfield",
    "lng": -87.81093722260593,
    "lat": 33.884994585296674
  },
  {
    "name": "Winfield",
    "lng": -87.80987980026595,
    "lat": 33.956283250606404
  },
  {
    "name": "Winfield",
    "lng": -87.79992774665524,
    "lat": 33.92519079915432
  },
  {
    "name": "Winfield",
    "lng": -87.77194753415142,
    "lat": 33.9914294665538
  },
  {
    "name": "Winfield",
    "lng": -87.26319561721164,
    "lat": 41.40978722448692
  },
  {
    "name": "Winfield",
    "lng": -84.44602283616653,
    "lat": 36.56450329360958
  },
  {
    "name": "Winfield",
    "lng": -81.90692344384296,
    "lat": 38.52342582688643
  },
  {
    "name": "Winfield",
    "lng": -81.88351138180019,
    "lat": 38.5281406128647
  },
  {
    "name": "Wing",
    "lng": -100.28314819665921,
    "lat": 47.14247589815818
  },
  {
    "name": "Wingate",
    "lng": -87.07296792275864,
    "lat": 40.17081600083175
  },
  {
    "name": "Wingate",
    "lng": -80.45181958312236,
    "lat": 34.984106519854556
  },
  {
    "name": "Wingate",
    "lng": -80.4220012779378,
    "lat": 35.00011129127275
  },
  {
    "name": "Winger",
    "lng": -95.98599555021974,
    "lat": 47.536332237242
  },
  {
    "name": "Wingo",
    "lng": -88.738514322037,
    "lat": 36.64200460157382
  },
  {
    "name": "Winifred",
    "lng": -109.3768324097297,
    "lat": 47.562154984161104
  },
  {
    "name": "Wink",
    "lng": -103.15424006882861,
    "lat": 31.754390229331797
  },
  {
    "name": "Winkelman",
    "lng": -110.77054463485268,
    "lat": 32.98972550279193
  },
  {
    "name": "Winkelman",
    "lng": -110.76173292296296,
    "lat": 32.98151496912965
  },
  {
    "name": "Winkelman",
    "lng": -110.76173292296293,
    "lat": 32.981514969129634
  },
  {
    "name": "Winlock",
    "lng": -122.93542013004644,
    "lat": 46.49129441997314
  },
  {
    "name": "Winn",
    "lng": -68.33101230552059,
    "lat": 45.45501171016604
  },
  {
    "name": "Winnebago",
    "lng": -96.47222794220941,
    "lat": 42.23720794821745
  },
  {
    "name": "Winnebago",
    "lng": -94.17025989509547,
    "lat": 43.76459432450003
  },
  {
    "name": "Winnebago",
    "lng": -89.23374991128878,
    "lat": 42.267126538728796
  },
  {
    "name": "Winneconne",
    "lng": -88.71075407934084,
    "lat": 44.11178807611602
  },
  {
    "name": "Winnemucca",
    "lng": -117.72476560651903,
    "lat": 40.96767331576617
  },
  {
    "name": "Winner",
    "lng": -99.885820002643,
    "lat": 43.38209133014968
  },
  {
    "name": "Winner",
    "lng": -99.88508036118434,
    "lat": 43.37980965714787
  },
  {
    "name": "Winner",
    "lng": -99.85652129795596,
    "lat": 43.373753275516
  },
  {
    "name": "Winnetka",
    "lng": -87.74198737832539,
    "lat": 42.10655378471398
  },
  {
    "name": "Winnetoon",
    "lng": -97.96013707459367,
    "lat": 42.513528554497036
  },
  {
    "name": "Winnett",
    "lng": -108.34623200142458,
    "lat": 47.00474726496147
  },
  {
    "name": "Winnfield",
    "lng": -92.64247265015528,
    "lat": 31.9239289442186
  },
  {
    "name": "Winnsboro",
    "lng": -95.29108869431421,
    "lat": 32.9557692026529
  },
  {
    "name": "Winnsboro",
    "lng": -91.7210821758928,
    "lat": 32.16553027706155
  },
  {
    "name": "Winnsboro",
    "lng": -81.1016284588934,
    "lat": 34.38689758558574
  },
  {
    "name": "Winnsboro",
    "lng": -81.09056175601292,
    "lat": 34.37241230527438
  },
  {
    "name": "Winona",
    "lng": -101.24509654746947,
    "lat": 39.06181582928491
  },
  {
    "name": "Winona",
    "lng": -95.17466067499153,
    "lat": 32.493595946749856
  },
  {
    "name": "Winona",
    "lng": -91.70729062619746,
    "lat": 44.05867601651556
  },
  {
    "name": "Winona",
    "lng": -91.68971022920206,
    "lat": 44.01369764223862
  },
  {
    "name": "Winona",
    "lng": -91.67004334093905,
    "lat": 44.05223570552393
  },
  {
    "name": "Winona",
    "lng": -91.61292238636702,
    "lat": 44.01606107096905
  },
  {
    "name": "Winona",
    "lng": -91.61125808091954,
    "lat": 44.01310845353717
  },
  {
    "name": "Winona",
    "lng": -91.61080935392853,
    "lat": 44.011811821383766
  },
  {
    "name": "Winona",
    "lng": -91.61054372726474,
    "lat": 44.009127523842594
  },
  {
    "name": "Winona",
    "lng": -91.60655334357446,
    "lat": 44.00413045331098
  },
  {
    "name": "Winona",
    "lng": -91.60639541473991,
    "lat": 43.98365179242984
  },
  {
    "name": "Winona",
    "lng": -91.606266199696,
    "lat": 44.003766901156425
  },
  {
    "name": "Winona",
    "lng": -91.60391503361865,
    "lat": 44.01832830541964
  },
  {
    "name": "Winona",
    "lng": -91.60291216000995,
    "lat": 44.01954449022103
  },
  {
    "name": "Winona",
    "lng": -91.60268684398642,
    "lat": 44.01796003965193
  },
  {
    "name": "Winona",
    "lng": -91.6023501801191,
    "lat": 44.01929802268799
  },
  {
    "name": "Winona",
    "lng": -91.59590312807424,
    "lat": 43.99399406212446
  },
  {
    "name": "Winona",
    "lng": -91.3269636947977,
    "lat": 37.004152199884565
  },
  {
    "name": "Winona",
    "lng": -89.72789937606335,
    "lat": 33.48975746548388
  },
  {
    "name": "Winona Lake",
    "lng": -85.81025255215995,
    "lat": 41.216493409782636
  },
  {
    "name": "Winooski",
    "lng": -73.18446740194707,
    "lat": 44.494921994227894
  },
  {
    "name": "Winside",
    "lng": -97.17539241058878,
    "lat": 42.177443761269146
  },
  {
    "name": "Winslow",
    "lng": -110.70917898953859,
    "lat": 35.025222085633914
  },
  {
    "name": "Winslow",
    "lng": -96.50452816045483,
    "lat": 41.60903066399945
  },
  {
    "name": "Winslow",
    "lng": -94.1304593262094,
    "lat": 35.80079300958176
  },
  {
    "name": "Winslow",
    "lng": -89.79487874481417,
    "lat": 42.493009634823345
  },
  {
    "name": "Winslow",
    "lng": -87.21295563176714,
    "lat": 38.38256924492227
  },
  {
    "name": "Winslow",
    "lng": -69.5768589542783,
    "lat": 44.52772924820676
  },
  {
    "name": "Winslow West",
    "lng": -110.74844991655024,
    "lat": 35.034691196162996
  },
  {
    "name": "Winsted",
    "lng": -94.05125614765826,
    "lat": 44.94562965093176
  },
  {
    "name": "Winsted",
    "lng": -94.05089333556829,
    "lat": 44.965201856907406
  },
  {
    "name": "Winsted",
    "lng": -94.04821289470296,
    "lat": 44.956598131778385
  },
  {
    "name": "Winsted",
    "lng": -94.04185657941744,
    "lat": 44.95497952290892
  },
  {
    "name": "Winsted",
    "lng": -94.03121154951663,
    "lat": 44.9655091488632
  },
  {
    "name": "Winsted",
    "lng": -73.06742781298085,
    "lat": 41.92676949048237
  },
  {
    "name": "Winston",
    "lng": -94.14155303235232,
    "lat": 39.869867456418056
  },
  {
    "name": "Winston-Salem",
    "lng": -80.34611470033225,
    "lat": 36.03066783965696
  },
  {
    "name": "Winston-Salem",
    "lng": -80.26070895482522,
    "lat": 36.10313997458288
  },
  {
    "name": "Winstonville",
    "lng": -90.75278541124554,
    "lat": 33.91236987190117
  },
  {
    "name": "Winter",
    "lng": -91.01304059568999,
    "lat": 45.82214292043633
  },
  {
    "name": "Winter Harbor",
    "lng": -68.08611672435048,
    "lat": 44.338461413745165
  },
  {
    "name": "Winter Haven",
    "lng": -81.76192365328305,
    "lat": 28.018318244707732
  },
  {
    "name": "Winter Haven",
    "lng": -81.7012824736158,
    "lat": 28.01167832003246
  },
  {
    "name": "Winter Haven",
    "lng": -81.67540056375256,
    "lat": 28.02434728403132
  },
  {
    "name": "Winter Haven",
    "lng": -81.6647155446348,
    "lat": 28.022009103699826
  },
  {
    "name": "Winter Park",
    "lng": -105.78278340533036,
    "lat": 39.87786454972005
  },
  {
    "name": "Winterport",
    "lng": -68.91187891307695,
    "lat": 44.65841458836448
  },
  {
    "name": "Winters",
    "lng": -121.992933820931,
    "lat": 38.5459848982818
  },
  {
    "name": "Winters",
    "lng": -121.97405952572751,
    "lat": 38.52752647867785
  },
  {
    "name": "Winters",
    "lng": -99.92982676244134,
    "lat": 31.956469928444715
  },
  {
    "name": "Winterset",
    "lng": -94.04125018690377,
    "lat": 41.34245870129736
  },
  {
    "name": "Winterset",
    "lng": -94.01926461759726,
    "lat": 41.353491540823654
  },
  {
    "name": "Winterset",
    "lng": -94.0127290007287,
    "lat": 41.34382682992244
  },
  {
    "name": "Winterstown",
    "lng": -76.617442020839,
    "lat": 39.84081876743321
  },
  {
    "name": "Wintersville",
    "lng": -80.73934502264875,
    "lat": 40.38780059994262
  },
  {
    "name": "Wintersville",
    "lng": -80.70590577874424,
    "lat": 40.37800208339476
  },
  {
    "name": "Winterville",
    "lng": -83.28155808420753,
    "lat": 33.966903078324826
  },
  {
    "name": "Winterville",
    "lng": -77.43075028078707,
    "lat": 35.5381474102962
  },
  {
    "name": "Winterville",
    "lng": -77.41918342783406,
    "lat": 35.518573328680105
  },
  {
    "name": "Winterville",
    "lng": -77.3988150473399,
    "lat": 35.52865240529275
  },
  {
    "name": "Winthrop",
    "lng": -120.19188828637309,
    "lat": 48.478083528646366
  },
  {
    "name": "Winthrop",
    "lng": -120.18682053040243,
    "lat": 48.47730865490766
  },
  {
    "name": "Winthrop",
    "lng": -120.17812017120046,
    "lat": 48.47140507824387
  },
  {
    "name": "Winthrop",
    "lng": -94.37322350427138,
    "lat": 44.533830653016054
  },
  {
    "name": "Winthrop",
    "lng": -94.35973985162398,
    "lat": 44.54283214117353
  },
  {
    "name": "Winthrop",
    "lng": -94.35428656036416,
    "lat": 33.830818107692274
  },
  {
    "name": "Winthrop",
    "lng": -91.73730606111782,
    "lat": 42.470787693940416
  },
  {
    "name": "Winthrop",
    "lng": -69.96157730215826,
    "lat": 44.31161915372655
  },
  {
    "name": "Winthrop Harbor",
    "lng": -87.8287107382332,
    "lat": 42.480638151051664
  },
  {
    "name": "Winton",
    "lng": -91.80047591460365,
    "lat": 47.92847343650521
  },
  {
    "name": "Winton",
    "lng": -76.93494050093543,
    "lat": 36.38989278587019
  },
  {
    "name": "Wiota",
    "lng": -94.8873900460914,
    "lat": 41.400794514167714
  },
  {
    "name": "Wiscasset",
    "lng": -69.68839500293942,
    "lat": 44.01260250785114
  },
  {
    "name": "Wisconsin",
    "lng": -89.74556387945205,
    "lat": 44.675783983200205
  },
  {
    "name": "Wisconsin Dells",
    "lng": -89.77617049681923,
    "lat": 43.63210831528868
  },
  {
    "name": "Wisconsin Rapids",
    "lng": -89.83217209955468,
    "lat": 44.361836285243406
  },
  {
    "name": "Wisconsin Rapids",
    "lng": -89.827567669643,
    "lat": 44.39295359255531
  },
  {
    "name": "Wise",
    "lng": -82.58087840565327,
    "lat": 36.97689436446536
  },
  {
    "name": "Wishek",
    "lng": -99.55475981313904,
    "lat": 46.25528671697992
  },
  {
    "name": "Wisner",
    "lng": -96.91406121763286,
    "lat": 41.9880480455438
  },
  {
    "name": "Wisner",
    "lng": -91.65509014379279,
    "lat": 31.980428564943857
  },
  {
    "name": "Wister",
    "lng": -94.72052766716062,
    "lat": 34.96972471187549
  },
  {
    "name": "Witches Woods",
    "lng": -72.072345321083,
    "lat": 41.948599146082714
  },
  {
    "name": "Withee",
    "lng": -90.59870301901434,
    "lat": 44.950789707395266
  },
  {
    "name": "Witt",
    "lng": -89.34918903987688,
    "lat": 39.254518292722615
  },
  {
    "name": "Wittenberg",
    "lng": -89.16652954742582,
    "lat": 44.826059713628105
  },
  {
    "name": "Wixom",
    "lng": -83.53470963808749,
    "lat": 42.52431507991493
  },
  {
    "name": "Wixon Valley",
    "lng": -96.31929229815131,
    "lat": 30.763950037194146
  },
  {
    "name": "Woburn",
    "lng": -71.15426282226449,
    "lat": 42.48688533318203
  },
  {
    "name": "Woden",
    "lng": -93.9114062821934,
    "lat": 43.230797973793415
  },
  {
    "name": "Wolbach",
    "lng": -98.39403555838362,
    "lat": 41.401288692378664
  },
  {
    "name": "Wolcott",
    "lng": -87.04210072852031,
    "lat": 40.759041533553436
  },
  {
    "name": "Wolcott",
    "lng": -76.81341978873239,
    "lat": 43.22253838542214
  },
  {
    "name": "Wolcottville",
    "lng": -85.36669770016653,
    "lat": 41.525570913171286
  },
  {
    "name": "Wolf Lake",
    "lng": -95.35260685636676,
    "lat": 46.806645368207384
  },
  {
    "name": "Wolf Point",
    "lng": -105.64146910259228,
    "lat": 48.093011553222475
  },
  {
    "name": "Wolfe City",
    "lng": -96.07199359773581,
    "lat": 33.36853404937373
  },
  {
    "name": "Wolfforth",
    "lng": -102.00662035496295,
    "lat": 33.51480172996903
  },
  {
    "name": "Wolford",
    "lng": -99.70427896320032,
    "lat": 48.49780291856725
  },
  {
    "name": "Wolsey",
    "lng": -98.47381610126105,
    "lat": 44.41052607061206
  },
  {
    "name": "Wolverine",
    "lng": -84.6049641641587,
    "lat": 45.27304068983992
  },
  {
    "name": "Wolverine Lake",
    "lng": -83.48825397652145,
    "lat": 42.55501778447473
  },
  {
    "name": "Wolverton",
    "lng": -96.73616798924196,
    "lat": 46.563041774802045
  },
  {
    "name": "Womelsdorf (Coalton)",
    "lng": -79.96373468918735,
    "lat": 38.89802021572911
  },
  {
    "name": "Wonder Lake",
    "lng": -88.3621437241945,
    "lat": 42.38371635182873
  },
  {
    "name": "Wonewoc",
    "lng": -90.22392654861346,
    "lat": 43.65219386648621
  },
  {
    "name": "Wood",
    "lng": -100.48023340533717,
    "lat": 43.49709330128145
  },
  {
    "name": "Wood Dale",
    "lng": -87.98087293562908,
    "lat": 41.96653360373428
  },
  {
    "name": "Wood Heights",
    "lng": -94.16358096834868,
    "lat": 39.34137578853726
  },
  {
    "name": "Wood Lake",
    "lng": -100.2375893575767,
    "lat": 42.638583148747436
  },
  {
    "name": "Wood Lake",
    "lng": -95.5358418359124,
    "lat": 44.65131519322512
  },
  {
    "name": "Wood River",
    "lng": -98.61006756690108,
    "lat": 40.813939179782125
  },
  {
    "name": "Wood River",
    "lng": -98.59898384492294,
    "lat": 40.821692996622865
  },
  {
    "name": "Wood River",
    "lng": -90.07735494219068,
    "lat": 38.863178673781505
  },
  {
    "name": "Woodbine",
    "lng": -96.9593525129735,
    "lat": 38.79560578758286
  },
  {
    "name": "Woodbine",
    "lng": -95.7111815514986,
    "lat": 41.73575997415883
  },
  {
    "name": "Woodbine",
    "lng": -81.7174782168114,
    "lat": 30.959519106560347
  },
  {
    "name": "Woodbranch",
    "lng": -95.18265115231485,
    "lat": 30.181464167950843
  },
  {
    "name": "Woodbridge",
    "lng": -73.01004955810825,
    "lat": 41.35666359044832
  },
  {
    "name": "Woodburn",
    "lng": -93.59632847051023,
    "lat": 41.010871965794664
  },
  {
    "name": "Woodburn",
    "lng": -86.53024478865076,
    "lat": 36.842132299651446
  },
  {
    "name": "Woodburn",
    "lng": -84.85025984760236,
    "lat": 41.12864995698426
  },
  {
    "name": "Woodbury",
    "lng": -92.92304993635827,
    "lat": 44.905673049054414
  },
  {
    "name": "Woodbury",
    "lng": -86.6353948093358,
    "lat": 37.183403112892854
  },
  {
    "name": "Woodbury",
    "lng": -86.06966755313076,
    "lat": 35.82362197507598
  },
  {
    "name": "Woodbury",
    "lng": -84.58165227765362,
    "lat": 32.984768520773265
  },
  {
    "name": "Woodbury",
    "lng": -75.15233997611817,
    "lat": 39.83777984464401
  },
  {
    "name": "Woodbury",
    "lng": -74.10041234635496,
    "lat": 41.32851240945726
  },
  {
    "name": "Woodbury",
    "lng": -73.20702767986208,
    "lat": 41.56150907181556
  },
  {
    "name": "Woodcreek",
    "lng": -98.11160068250659,
    "lat": 30.026820320287012
  },
  {
    "name": "Woodfin",
    "lng": -82.59119357234185,
    "lat": 35.64668783031879
  },
  {
    "name": "Woodford",
    "lng": -81.11092593091395,
    "lat": 33.66865025921629
  },
  {
    "name": "Woodhaven",
    "lng": -83.23729677123626,
    "lat": 42.13202303059971
  },
  {
    "name": "Woodhull",
    "lng": -90.32222643336125,
    "lat": 41.17845036807398
  },
  {
    "name": "Woodinville",
    "lng": -122.14769965652228,
    "lat": 47.756997656126586
  },
  {
    "name": "Woodlake",
    "lng": -119.09976294491383,
    "lat": 36.412726575776155
  },
  {
    "name": "Woodland",
    "lng": -122.74768942579708,
    "lat": 45.917027239420094
  },
  {
    "name": "Woodland",
    "lng": -121.7570201288586,
    "lat": 38.672237051479875
  },
  {
    "name": "Woodland",
    "lng": -121.69243100000001,
    "lat": 38.66216135910906
  },
  {
    "name": "Woodland",
    "lng": -93.51369588172649,
    "lat": 44.950285573734156
  },
  {
    "name": "Woodland",
    "lng": -89.05067683834248,
    "lat": 33.77966331262861
  },
  {
    "name": "Woodland",
    "lng": -87.7306483944204,
    "lat": 40.71398043771216
  },
  {
    "name": "Woodland",
    "lng": -85.39877559310624,
    "lat": 33.374479851826486
  },
  {
    "name": "Woodland",
    "lng": -85.13462351022841,
    "lat": 42.726852737216106
  },
  {
    "name": "Woodland",
    "lng": -84.56056194214678,
    "lat": 32.78762917888364
  },
  {
    "name": "Woodland",
    "lng": -77.24960454106909,
    "lat": 36.31028754862739
  },
  {
    "name": "Woodland",
    "lng": -77.21200799602089,
    "lat": 36.33230624177669
  },
  {
    "name": "Woodland",
    "lng": -68.11951204408695,
    "lat": 46.88408827138165
  },
  {
    "name": "Woodland Hills",
    "lng": -111.65579152499059,
    "lat": 40.013217239478585
  },
  {
    "name": "Woodland Hills",
    "lng": -85.528318906011,
    "lat": 38.23952856706117
  },
  {
    "name": "Woodland Mills",
    "lng": -89.11083928770576,
    "lat": 36.47822790699495
  },
  {
    "name": "Woodland Park",
    "lng": -105.05898357818978,
    "lat": 38.998728909175426
  },
  {
    "name": "Woodland Park",
    "lng": -105.04499852434252,
    "lat": 39.009789020949405
  },
  {
    "name": "Woodlawn",
    "lng": -89.03450347810491,
    "lat": 38.32823961963861
  },
  {
    "name": "Woodlawn",
    "lng": -84.4724609203277,
    "lat": 39.091323621414524
  },
  {
    "name": "Woodlawn",
    "lng": -84.47109933641943,
    "lat": 39.255504436577326
  },
  {
    "name": "Woodlawn Heights",
    "lng": -85.69693959104066,
    "lat": 40.11844049325028
  },
  {
    "name": "Woodlawn Park",
    "lng": -97.64980221276045,
    "lat": 35.50916135353992
  },
  {
    "name": "Woodlawn Park",
    "lng": -85.63055169822368,
    "lat": 38.26142427664033
  },
  {
    "name": "Woodloch",
    "lng": -95.41352041419152,
    "lat": 30.21732693577258
  },
  {
    "name": "Woodmere",
    "lng": -81.47982969151896,
    "lat": 41.459954308382926
  },
  {
    "name": "Woodridge",
    "lng": -88.07994975587647,
    "lat": 41.74786745850916
  },
  {
    "name": "Woodridge",
    "lng": -88.06289660878237,
    "lat": 41.75599465463142
  },
  {
    "name": "Woodridge",
    "lng": -88.0406499728864,
    "lat": 41.73686088593935
  },
  {
    "name": "Woodridge",
    "lng": -88.01640158086234,
    "lat": 41.686363948236085
  },
  {
    "name": "Woodridge",
    "lng": -88.00364149895711,
    "lat": 41.715147002291666
  },
  {
    "name": "Woodridge",
    "lng": -74.57379721261303,
    "lat": 41.71229946941386
  },
  {
    "name": "Woodruff",
    "lng": -111.16395937685107,
    "lat": 41.521193012746274
  },
  {
    "name": "Woodruff",
    "lng": -82.03320762643726,
    "lat": 34.73993308966068
  },
  {
    "name": "Woodruff",
    "lng": -82.00780933683025,
    "lat": 34.72775900654666
  },
  {
    "name": "Woods Cross",
    "lng": -111.9170211324203,
    "lat": 40.87309063654151
  },
  {
    "name": "Woodsboro",
    "lng": -97.32538003724987,
    "lat": 28.237806043070123
  },
  {
    "name": "Woodsboro",
    "lng": -77.30962027165991,
    "lat": 39.53285437940083
  },
  {
    "name": "Woodsburgh",
    "lng": -73.70679718834008,
    "lat": 40.621474917380816
  },
  {
    "name": "Woodsfield",
    "lng": -81.11656833809336,
    "lat": 39.76300544453766
  },
  {
    "name": "Woodside",
    "lng": -122.25861094755922,
    "lat": 37.42220699740096
  },
  {
    "name": "Woodson",
    "lng": -99.05342845243557,
    "lat": 33.014931288174346
  },
  {
    "name": "Woodson",
    "lng": -90.22563488600065,
    "lat": 39.62741048208544
  },
  {
    "name": "Woodson Terrace",
    "lng": -90.3600165179884,
    "lat": 38.72866907556409
  },
  {
    "name": "Woodstock",
    "lng": -96.09659745412186,
    "lat": 44.011240685848286
  },
  {
    "name": "Woodstock",
    "lng": -88.43533645441049,
    "lat": 42.31048747214947
  },
  {
    "name": "Woodstock",
    "lng": -87.14596104789747,
    "lat": 33.2004139185242
  },
  {
    "name": "Woodstock",
    "lng": -87.14074036356526,
    "lat": 33.19427094223576
  },
  {
    "name": "Woodstock",
    "lng": -84.5090669587756,
    "lat": 34.10263010097309
  },
  {
    "name": "Woodstock",
    "lng": -84.46831176690809,
    "lat": 34.108008574833036
  },
  {
    "name": "Woodstock",
    "lng": -83.52772901645287,
    "lat": 40.17369866333938
  },
  {
    "name": "Woodstock",
    "lng": -78.51604377509402,
    "lat": 38.87499774619383
  },
  {
    "name": "Woodstock",
    "lng": -72.02242738303627,
    "lat": 41.969610256119026
  },
  {
    "name": "Woodstock",
    "lng": -70.58933689360718,
    "lat": 44.393156078348696
  },
  {
    "name": "Woodston",
    "lng": -99.0985772004355,
    "lat": 39.45393299924572
  },
  {
    "name": "Woodville",
    "lng": -119.20504275520653,
    "lat": 36.089862016682154
  },
  {
    "name": "Woodville",
    "lng": -94.42353163455996,
    "lat": 30.773884532193986
  },
  {
    "name": "Woodville",
    "lng": -92.28527495071589,
    "lat": 44.948645983495574
  },
  {
    "name": "Woodville",
    "lng": -91.29928855491222,
    "lat": 31.103052597655516
  },
  {
    "name": "Woodville",
    "lng": -83.36283331504406,
    "lat": 41.45084381143437
  },
  {
    "name": "Woodville",
    "lng": -83.10634379924679,
    "lat": 33.67142689909604
  },
  {
    "name": "Woodville",
    "lng": -68.44912586009302,
    "lat": 45.52565725829874
  },
  {
    "name": "Woodward",
    "lng": -99.4061030818268,
    "lat": 36.424629890122766
  },
  {
    "name": "Woodward",
    "lng": -93.9206820567038,
    "lat": 41.85253192520089
  },
  {
    "name": "Woodway",
    "lng": -122.40774383914464,
    "lat": 47.793202557991
  },
  {
    "name": "Woodway",
    "lng": -97.2316105912983,
    "lat": 31.498604706399213
  },
  {
    "name": "Woodworth",
    "lng": -99.30438639698005,
    "lat": 47.1422836855604
  },
  {
    "name": "Wooldridge",
    "lng": -92.52139025203707,
    "lat": 38.90658078146921
  },
  {
    "name": "Woolstock",
    "lng": -93.84317652562964,
    "lat": 42.56487136935517
  },
  {
    "name": "Woolwich",
    "lng": -69.76870027454456,
    "lat": 43.9622148096304
  },
  {
    "name": "Woonsocket",
    "lng": -98.27236429792137,
    "lat": 44.054054670393505
  },
  {
    "name": "Woonsocket",
    "lng": -71.49917458868497,
    "lat": 42.00110758284447
  },
  {
    "name": "Wooster",
    "lng": -92.45025990729181,
    "lat": 35.199901334310994
  },
  {
    "name": "Wooster",
    "lng": -81.93371545521372,
    "lat": 40.81718149309567
  },
  {
    "name": "Worcester",
    "lng": -71.80793932099328,
    "lat": 42.27055933313418
  },
  {
    "name": "Worden",
    "lng": -89.8403362679453,
    "lat": 38.931509786205176
  },
  {
    "name": "Worland",
    "lng": -107.95660161552803,
    "lat": 44.0164745022206
  },
  {
    "name": "Worland",
    "lng": -107.9492094521548,
    "lat": 43.965440206331294
  },
  {
    "name": "Worley",
    "lng": -116.91925421628355,
    "lat": 47.400533153973825
  },
  {
    "name": "Worth",
    "lng": -94.44649496785107,
    "lat": 40.404516657430804
  },
  {
    "name": "Worth",
    "lng": -87.791679361113,
    "lat": 41.68763086953735
  },
  {
    "name": "Wortham",
    "lng": -96.46097105591247,
    "lat": 31.78966053859332
  },
  {
    "name": "Worthing",
    "lng": -96.76660112523616,
    "lat": 43.32978937838174
  },
  {
    "name": "Worthington",
    "lng": -95.59901633591315,
    "lat": 43.62809123826556
  },
  {
    "name": "Worthington",
    "lng": -92.68925214371534,
    "lat": 40.409023521663244
  },
  {
    "name": "Worthington",
    "lng": -91.12039534378395,
    "lat": 42.39792746173214
  },
  {
    "name": "Worthington",
    "lng": -86.97935512696424,
    "lat": 39.118502558735194
  },
  {
    "name": "Worthington",
    "lng": -83.02054258656987,
    "lat": 40.09507302882847
  },
  {
    "name": "Worthington",
    "lng": -82.73513585074713,
    "lat": 38.550883193158135
  },
  {
    "name": "Worthington",
    "lng": -80.26351338327414,
    "lat": 39.45680732215934
  },
  {
    "name": "Worthington",
    "lng": -72.94243570785275,
    "lat": 42.3953747439536
  },
  {
    "name": "Worthington Hills",
    "lng": -85.52731156807586,
    "lat": 38.309487252671374
  },
  {
    "name": "Worthville",
    "lng": -85.06816706194103,
    "lat": 38.6096831465217
  },
  {
    "name": "Wrangell",
    "lng": -132.3200501886644,
    "lat": 56.41342866005386
  },
  {
    "name": "Wray",
    "lng": -102.22833120725593,
    "lat": 40.08164598019389
  },
  {
    "name": "Wren",
    "lng": -84.77460210653123,
    "lat": 40.8005525235903
  },
  {
    "name": "Wrens",
    "lng": -82.38818272630232,
    "lat": 33.20699572100092
  },
  {
    "name": "Wrenshall",
    "lng": -92.38406109310947,
    "lat": 46.62169568944508
  },
  {
    "name": "Wright",
    "lng": -105.51980948216384,
    "lat": 43.74731888986089
  },
  {
    "name": "Wright",
    "lng": -105.49590602948622,
    "lat": 43.74852789408404
  },
  {
    "name": "Wright",
    "lng": -93.00693304696888,
    "lat": 46.67255028906221
  },
  {
    "name": "Wright",
    "lng": -85.80832136573079,
    "lat": 43.11047943538964
  },
  {
    "name": "Wright City",
    "lng": -95.00754760842797,
    "lat": 34.064600750945026
  },
  {
    "name": "Wright City",
    "lng": -91.03211322982753,
    "lat": 38.82716313548768
  },
  {
    "name": "Wrightstown",
    "lng": -88.17527343666741,
    "lat": 44.32644689305725
  },
  {
    "name": "Wrightsville",
    "lng": -92.21362542216742,
    "lat": 34.61091618290641
  },
  {
    "name": "Wrightsville",
    "lng": -82.71970993062963,
    "lat": 32.72651608562933
  },
  {
    "name": "Wrightsville",
    "lng": -76.53113875569204,
    "lat": 40.02384593888735
  },
  {
    "name": "Wrightsville Beach",
    "lng": -77.82664804831477,
    "lat": 34.219525675954614
  },
  {
    "name": "Wrightsville Beach",
    "lng": -77.8243930731677,
    "lat": 34.22147540869552
  },
  {
    "name": "Wrightsville Beach",
    "lng": -77.82154523285986,
    "lat": 34.220139955104266
  },
  {
    "name": "Wrightsville Beach",
    "lng": -77.82057747392712,
    "lat": 34.22039701292403
  },
  {
    "name": "Wrightsville Beach",
    "lng": -77.81983390188974,
    "lat": 34.221756911861846
  },
  {
    "name": "Wrightsville Beach",
    "lng": -77.8186579989336,
    "lat": 34.21987214003027
  },
  {
    "name": "Wrightsville Beach",
    "lng": -77.81710908074413,
    "lat": 34.21987036270662
  },
  {
    "name": "Wrightsville Beach",
    "lng": -77.81670688448823,
    "lat": 34.2213004408808
  },
  {
    "name": "Wrightsville Beach",
    "lng": -77.81495210686025,
    "lat": 34.21945633964307
  },
  {
    "name": "Wrightsville Beach",
    "lng": -77.81420250091679,
    "lat": 34.218941965093784
  },
  {
    "name": "Wrightsville Beach",
    "lng": -77.79620099208744,
    "lat": 34.21137418619233
  },
  {
    "name": "Wrightwood",
    "lng": -117.63012072644943,
    "lat": 34.359308115700586
  },
  {
    "name": "Wurtland",
    "lng": -82.77968811490189,
    "lat": 38.54931675105675
  },
  {
    "name": "Wurtsboro",
    "lng": -74.48584665883696,
    "lat": 41.57632650668874
  },
  {
    "name": "Wyaconda",
    "lng": -91.92595685601525,
    "lat": 40.392154531985376
  },
  {
    "name": "Wyandotte",
    "lng": -94.72313627812399,
    "lat": 36.792578720327015
  },
  {
    "name": "Wyandotte",
    "lng": -83.15603975278819,
    "lat": 42.21104183203152
  },
  {
    "name": "Wyanet",
    "lng": -89.58306587211781,
    "lat": 41.36059911205761
  },
  {
    "name": "Wyatt",
    "lng": -89.19112130697692,
    "lat": 36.93704575532356
  },
  {
    "name": "Wyeville",
    "lng": -90.38768466939742,
    "lat": 44.028472809555154
  },
  {
    "name": "Wyeville",
    "lng": -90.37290695518934,
    "lat": 44.0247270346752
  },
  {
    "name": "Wykoff",
    "lng": -92.26739024746738,
    "lat": 43.70865731323415
  },
  {
    "name": "Wylie",
    "lng": -96.51672034968321,
    "lat": 33.035924370441805
  },
  {
    "name": "Wymore",
    "lng": -96.66430259344376,
    "lat": 40.122096149963134
  },
  {
    "name": "Wyndmere",
    "lng": -97.13098287868954,
    "lat": 46.26397072847624
  },
  {
    "name": "Wynne",
    "lng": -90.83328000326205,
    "lat": 35.21805821373466
  },
  {
    "name": "Wynne",
    "lng": -90.78898789926565,
    "lat": 35.232475288619526
  },
  {
    "name": "Wynnedale",
    "lng": -86.19980861991536,
    "lat": 39.83243382997055
  },
  {
    "name": "Wynnewood",
    "lng": -97.16273003773254,
    "lat": 34.64416280108079
  },
  {
    "name": "Wynona",
    "lng": -96.32666543253748,
    "lat": 36.54575500858843
  },
  {
    "name": "Wynot",
    "lng": -97.16964125116372,
    "lat": 42.74009262720626
  },
  {
    "name": "Wyocena",
    "lng": -89.30974145117811,
    "lat": 43.49297488824438
  },
  {
    "name": "Wyoming",
    "lng": -107.55150573925522,
    "lat": 43.03215737767362
  },
  {
    "name": "Wyoming",
    "lng": -92.97675153174922,
    "lat": 45.33651856047357
  },
  {
    "name": "Wyoming",
    "lng": -91.00518181411847,
    "lat": 42.059540647729875
  },
  {
    "name": "Wyoming",
    "lng": -89.77293071777481,
    "lat": 41.063682983570814
  },
  {
    "name": "Wyoming",
    "lng": -85.70677630240708,
    "lat": 42.8910004566938
  },
  {
    "name": "Wyoming",
    "lng": -84.48153944204797,
    "lat": 39.22958654085474
  },
  {
    "name": "Wyoming",
    "lng": -78.08407784440455,
    "lat": 42.82403455824427
  },
  {
    "name": "Wyoming",
    "lng": -75.84153003052663,
    "lat": 41.30575813396059
  },
  {
    "name": "Wytheville",
    "lng": -81.08797505863426,
    "lat": 36.952966778541615
  },
  {
    "name": "Xenia",
    "lng": -88.63751467185604,
    "lat": 38.63726520167209
  },
  {
    "name": "Xenia",
    "lng": -83.94098849279229,
    "lat": 39.68363876613132
  },
  {
    "name": "Xenia",
    "lng": -83.86426969439046,
    "lat": 39.71049602207596
  },
  {
    "name": "Yadkinville",
    "lng": -80.66033087217188,
    "lat": 36.13104587828781
  },
  {
    "name": "Yadkinville",
    "lng": -80.63539615147798,
    "lat": 36.117258737592635
  },
  {
    "name": "Yakima",
    "lng": -120.547511756067,
    "lat": 46.59255602847287
  },
  {
    "name": "Yale",
    "lng": -97.9885674905058,
    "lat": 44.43388951289312
  },
  {
    "name": "Yale",
    "lng": -96.70623792703057,
    "lat": 36.121376743884035
  },
  {
    "name": "Yale",
    "lng": -96.7007658335449,
    "lat": 36.11488547568758
  },
  {
    "name": "Yale",
    "lng": -94.35778190071859,
    "lat": 41.77513752823593
  },
  {
    "name": "Yale",
    "lng": -88.0245594676964,
    "lat": 39.120293888685815
  },
  {
    "name": "Yale",
    "lng": -82.79733134394608,
    "lat": 43.12770249063552
  },
  {
    "name": "Yampa",
    "lng": -106.90849248379024,
    "lat": 40.152980620776596
  },
  {
    "name": "Yanceyville",
    "lng": -79.39025976297293,
    "lat": 36.400240990447124
  },
  {
    "name": "Yanceyville",
    "lng": -79.34376218237004,
    "lat": 36.409862592539625
  },
  {
    "name": "Yanceyville",
    "lng": -79.32024737970526,
    "lat": 36.39021147813411
  },
  {
    "name": "Yanceyville",
    "lng": -79.29833234694269,
    "lat": 36.4071720995242
  },
  {
    "name": "Yanceyville",
    "lng": -79.22126190933403,
    "lat": 36.531827067971435
  },
  {
    "name": "Yankee Lake",
    "lng": -80.56902748033808,
    "lat": 41.2683205823076
  },
  {
    "name": "Yankton",
    "lng": -97.39269972856367,
    "lat": 42.88998318612221
  },
  {
    "name": "Yantis",
    "lng": -95.57612679902216,
    "lat": 32.9305298029891
  },
  {
    "name": "Yarmouth",
    "lng": -70.15931455640697,
    "lat": 43.788359448494276
  },
  {
    "name": "Yarrow Point",
    "lng": -122.21791840843233,
    "lat": 47.64516496119645
  },
  {
    "name": "Yates Center",
    "lng": -95.7996971988375,
    "lat": 37.8382726366242
  },
  {
    "name": "Yates Center",
    "lng": -95.73692644165065,
    "lat": 37.877069984626345
  },
  {
    "name": "Yates City",
    "lng": -90.01389698314878,
    "lat": 40.77764792681688
  },
  {
    "name": "Yatesville",
    "lng": -84.14257888204378,
    "lat": 32.91443393760978
  },
  {
    "name": "Yazoo City",
    "lng": -90.40680631735768,
    "lat": 32.86203746636823
  },
  {
    "name": "Yeager",
    "lng": -96.339977604277,
    "lat": 35.15662066366278
  },
  {
    "name": "Yeagertown",
    "lng": -77.58038131941666,
    "lat": 40.64165152766291
  },
  {
    "name": "Yellow Bluff",
    "lng": -87.48053359923225,
    "lat": 31.962273179221352
  },
  {
    "name": "Yellow Springs",
    "lng": -83.89493691051351,
    "lat": 39.79976791424861
  },
  {
    "name": "Yellville",
    "lng": -92.68598168009984,
    "lat": 36.228863507464965
  },
  {
    "name": "Yellville",
    "lng": -92.68035515004357,
    "lat": 36.24270020439972
  },
  {
    "name": "Yelm",
    "lng": -122.62597831977519,
    "lat": 46.939762482683065
  },
  {
    "name": "Yelm",
    "lng": -122.58463124037164,
    "lat": 46.94763809966851
  },
  {
    "name": "Yeoman",
    "lng": -86.72359637922516,
    "lat": 40.66769575184422
  },
  {
    "name": "Yerington",
    "lng": -119.1631275314576,
    "lat": 38.992979940924705
  },
  {
    "name": "Yetter",
    "lng": -94.8431742866763,
    "lat": 42.3161193041516
  },
  {
    "name": "Yoder",
    "lng": -104.29538901477562,
    "lat": 41.91697793580939
  },
  {
    "name": "Yoe",
    "lng": -76.6367261916362,
    "lat": 39.9093791000416
  },
  {
    "name": "Yonkers",
    "lng": -73.86748240349071,
    "lat": 40.94660652093692
  },
  {
    "name": "Yorba Linda",
    "lng": -117.77137968045193,
    "lat": 33.88892734369964
  },
  {
    "name": "York",
    "lng": -99.57357129268678,
    "lat": 48.31307983627834
  },
  {
    "name": "York",
    "lng": -97.59476735522293,
    "lat": 40.87092147300824
  },
  {
    "name": "York",
    "lng": -88.29377560789442,
    "lat": 32.50026413645979
  },
  {
    "name": "York",
    "lng": -81.23534786940057,
    "lat": 34.997188736026544
  },
  {
    "name": "York",
    "lng": -76.73154892719495,
    "lat": 39.965099378194054
  },
  {
    "name": "York",
    "lng": -70.58468639623511,
    "lat": 43.15747638910074
  },
  {
    "name": "York Haven",
    "lng": -76.71490664917134,
    "lat": 40.110582159101945
  },
  {
    "name": "Yorkana",
    "lng": -76.58469375315208,
    "lat": 39.97596361549555
  },
  {
    "name": "Yorkshire",
    "lng": -84.49502678792035,
    "lat": 40.32531224974434
  },
  {
    "name": "Yorktown",
    "lng": -97.50490945526204,
    "lat": 28.983073587295884
  },
  {
    "name": "Yorktown",
    "lng": -95.15436449071548,
    "lat": 40.73530123557661
  },
  {
    "name": "Yorktown",
    "lng": -85.47825645471559,
    "lat": 40.19189218676528
  },
  {
    "name": "Yorkville",
    "lng": -89.1184938951936,
    "lat": 36.09678453790234
  },
  {
    "name": "Yorkville",
    "lng": -88.51945909498961,
    "lat": 41.58074332180867
  },
  {
    "name": "Yorkville",
    "lng": -88.49188035787344,
    "lat": 41.60331773608592
  },
  {
    "name": "Yorkville",
    "lng": -88.48448459345443,
    "lat": 41.63307919575908
  },
  {
    "name": "Yorkville",
    "lng": -88.48307929712897,
    "lat": 41.690594371497426
  },
  {
    "name": "Yorkville",
    "lng": -88.48151891397332,
    "lat": 41.67912439364191
  },
  {
    "name": "Yorkville",
    "lng": -88.46516395353059,
    "lat": 41.64605867351361
  },
  {
    "name": "Yorkville",
    "lng": -88.46441733926086,
    "lat": 41.679390421136965
  },
  {
    "name": "Yorkville",
    "lng": -88.45998635434991,
    "lat": 41.61856260562529
  },
  {
    "name": "Yorkville",
    "lng": -88.45424867613234,
    "lat": 41.643815991980176
  },
  {
    "name": "Yorkville",
    "lng": -88.45332371330014,
    "lat": 41.644013215830704
  },
  {
    "name": "Yorkville",
    "lng": -88.44648153672432,
    "lat": 41.70340769335196
  },
  {
    "name": "Yorkville",
    "lng": -88.43955582447005,
    "lat": 41.650387359921915
  },
  {
    "name": "Yorkville",
    "lng": -88.40909187805396,
    "lat": 41.69250962453681
  },
  {
    "name": "Yorkville",
    "lng": -88.39926256230035,
    "lat": 41.69856782905171
  },
  {
    "name": "Yorkville",
    "lng": -80.70770902876258,
    "lat": 40.152580205205965
  },
  {
    "name": "Young Harris",
    "lng": -83.84712263364683,
    "lat": 34.93558839099079
  },
  {
    "name": "Youngstown",
    "lng": -80.64586352684684,
    "lat": 41.098997720534236
  },
  {
    "name": "Youngsville",
    "lng": -91.99640117971126,
    "lat": 30.09596762669604
  },
  {
    "name": "Youngsville",
    "lng": -78.49766828884967,
    "lat": 36.039213937087595
  },
  {
    "name": "Youngsville",
    "lng": -78.48012369523381,
    "lat": 36.024452791256884
  },
  {
    "name": "Youngsville",
    "lng": -78.46277458869018,
    "lat": 36.02397764169466
  },
  {
    "name": "Ypsilanti",
    "lng": -83.62065661400803,
    "lat": 42.24376265017568
  },
  {
    "name": "Yreka",
    "lng": -122.63116775615376,
    "lat": 41.72926118344722
  },
  {
    "name": "Yuba",
    "lng": -90.426756427404,
    "lat": 43.53653527292251
  },
  {
    "name": "Yuba City",
    "lng": -121.63596051344284,
    "lat": 39.135547622115126
  },
  {
    "name": "Yucaipa",
    "lng": -117.0425288734488,
    "lat": 34.03356595887516
  },
  {
    "name": "Yucca Valley",
    "lng": -116.4215062134739,
    "lat": 34.12308273374223
  },
  {
    "name": "Yukon",
    "lng": -97.83536207562807,
    "lat": 35.50431870466949
  },
  {
    "name": "Yukon",
    "lng": -97.76259148655032,
    "lat": 35.52047180574352
  },
  {
    "name": "Yuma",
    "lng": -114.65868922197312,
    "lat": 32.709716714598045
  },
  {
    "name": "Yuma",
    "lng": -114.65376942591493,
    "lat": 32.72168954262169
  },
  {
    "name": "Yuma",
    "lng": -114.65263880888861,
    "lat": 32.701209475613005
  },
  {
    "name": "Yuma",
    "lng": -114.6523560713235,
    "lat": 32.72576485187217
  },
  {
    "name": "Yuma",
    "lng": -114.64909097416017,
    "lat": 32.67128334908407
  },
  {
    "name": "Yuma",
    "lng": -114.64468396186112,
    "lat": 32.701387658234786
  },
  {
    "name": "Yuma",
    "lng": -114.64425358068317,
    "lat": 32.68023876582008
  },
  {
    "name": "Yuma",
    "lng": -114.61474469081409,
    "lat": 32.70170780613508
  },
  {
    "name": "Yuma",
    "lng": -114.61248460874748,
    "lat": 32.698695324382655
  },
  {
    "name": "Yuma",
    "lng": -114.47727082770126,
    "lat": 32.66452477371226
  },
  {
    "name": "Yuma",
    "lng": -102.71643452013177,
    "lat": 40.12388738028936
  },
  {
    "name": "Yurt Village",
    "lng": -73.57131895791467,
    "lat": 41.30881766990137
  },
  {
    "name": "Yutan",
    "lng": -96.39651199077207,
    "lat": 41.24308821320593
  },
  {
    "name": "Zachary",
    "lng": -91.16236204769817,
    "lat": 30.66371841085164
  },
  {
    "name": "Zaleski",
    "lng": -82.3949719927518,
    "lat": 39.28107778805364
  },
  {
    "name": "Zalma",
    "lng": -90.07990657103426,
    "lat": 37.14399887164015
  },
  {
    "name": "Zanesfield",
    "lng": -83.67797345477324,
    "lat": 40.33844114899863
  },
  {
    "name": "Zanesville",
    "lng": -85.28069707714866,
    "lat": 40.915437398103606
  },
  {
    "name": "Zanesville",
    "lng": -82.01345061541852,
    "lat": 39.956636558299174
  },
  {
    "name": "Zap",
    "lng": -101.92334449398034,
    "lat": 47.284670422192555
  },
  {
    "name": "Zearing",
    "lng": -93.29718124559348,
    "lat": 42.158927702563
  },
  {
    "name": "Zebulon",
    "lng": -84.34237979142078,
    "lat": 33.094431633709206
  },
  {
    "name": "Zebulon",
    "lng": -78.34744265390432,
    "lat": 35.82272310205625
  },
  {
    "name": "Zebulon",
    "lng": -78.33998615743728,
    "lat": 35.82760084230807
  },
  {
    "name": "Zebulon",
    "lng": -78.33268240427579,
    "lat": 35.858997340924276
  },
  {
    "name": "Zebulon",
    "lng": -78.31599916717411,
    "lat": 35.8276238803206
  },
  {
    "name": "Zebulon",
    "lng": -78.29636709705359,
    "lat": 35.8428615920704
  },
  {
    "name": "Zebulon",
    "lng": -78.29485979641345,
    "lat": 35.84124810802918
  },
  {
    "name": "Zebulon",
    "lng": -78.29371343614338,
    "lat": 35.833630768176114
  },
  {
    "name": "Zebulon",
    "lng": -78.29018220881542,
    "lat": 35.83843388819491
  },
  {
    "name": "Zeeland",
    "lng": -99.83237792629427,
    "lat": 45.9729507163906
  },
  {
    "name": "Zeeland",
    "lng": -86.01290842013992,
    "lat": 42.81391806566832
  },
  {
    "name": "Zeigler",
    "lng": -89.05905431727312,
    "lat": 37.89053248429663
  },
  {
    "name": "Zeigler",
    "lng": -89.05889095485654,
    "lat": 37.908864934100194
  },
  {
    "name": "Zeigler",
    "lng": -89.05276712389046,
    "lat": 37.900520651257004
  },
  {
    "name": "Zemple",
    "lng": -93.78622915232216,
    "lat": 47.32090897308669
  },
  {
    "name": "Zenda",
    "lng": -98.28164822567881,
    "lat": 37.44468144066838
  },
  {
    "name": "Zillah",
    "lng": -120.27286158421474,
    "lat": 46.40823718923662
  },
  {
    "name": "Zilwaukee",
    "lng": -83.92230340040496,
    "lat": 43.48077216466834
  },
  {
    "name": "Zimmerman",
    "lng": -93.5981530839705,
    "lat": 45.441367752362964
  },
  {
    "name": "Zimmerman",
    "lng": -93.5957763081074,
    "lat": 45.45562709760826
  },
  {
    "name": "Zimmerman",
    "lng": -93.57241460000564,
    "lat": 45.44802920055437
  },
  {
    "name": "Zinc",
    "lng": -92.91469371665717,
    "lat": 36.28361703193059
  },
  {
    "name": "Zion",
    "lng": -87.85276679986433,
    "lat": 42.4591600563148
  },
  {
    "name": "Zion",
    "lng": -87.80685268011997,
    "lat": 42.46787297580545
  },
  {
    "name": "Zion Hill",
    "lng": -84.6385707744423,
    "lat": 38.121529907053194
  },
  {
    "name": "Zionsville",
    "lng": -86.26890161549396,
    "lat": 39.96150832636548
  },
  {
    "name": "Zionsville",
    "lng": -86.26132937707384,
    "lat": 39.92635952010404
  },
  {
    "name": "Zoar",
    "lng": -81.42306411209914,
    "lat": 40.61329650189222
  },
  {
    "name": "Zolfo Springs",
    "lng": -81.78666264227138,
    "lat": 27.49254984217259
  },
  {
    "name": "Zumbro Falls",
    "lng": -92.42634692262877,
    "lat": 44.28472981365852
  },
  {
    "name": "Zumbrota",
    "lng": -92.69500395842122,
    "lat": 44.30153279982711
  },
  {
    "name": "Zumbrota",
    "lng": -92.69360525529166,
    "lat": 44.306354295948964
  },
  {
    "name": "Zumbrota",
    "lng": -92.68997296589419,
    "lat": 44.30238862206334
  },
  {
    "name": "Zumbrota",
    "lng": -92.6761744989837,
    "lat": 44.3130465067953
  },
  {
    "name": "Zumbrota",
    "lng": -92.67225670767051,
    "lat": 44.29146665966102
  },
  {
    "name": "Zurich",
    "lng": -99.43465207915908,
    "lat": 39.23215373540717
  },
  {
    "name": "Zwingle",
    "lng": -90.68742537132327,
    "lat": 42.29730459380544
  },
  {
    "name": "Zwolle",
    "lng": -93.66946242224701,
    "lat": 31.634824110754582
  },
  {
    "name": "Zwolle",
    "lng": -93.64080550911295,
    "lat": 31.63990418380946
  },
  {
    "name": "ny",
    "lng": -73.35693186447179,
    "lat": 40.74161083972902
  },
]

export const cities = {};

citiesOptions.forEach((element)=>cities[element.name] = element)

export const selectFiltersData = [
  { filter: "amenity", options: amenityFilterOptions, value: null },
  { filter: "building", options: buildingOptions, value: null },
  { filter: "city", options: Object.values(cities), value: null },
  {
    filter: "commercial",
    options: commercialOptions,
    type: "checkbox",
    value: null,
  },
  {
    filter: "residental",
    options: commercialOptions,
    type: "checkbox",
    value: null,
  },
  {
    filter: "industrial",
    options: ["industrial"],
    type: "checkbox",
    value: null,
  },
];

export const layersData = [
  { filter: "buildings", value: true },
  { filter: "parcels", value: false },
];

export const checkboxesData = [
  { filter: "commercial", options: commercialOptions, value: null },
  { filter: "residental", options: residentialOptions, value: null },
];
