import { cities } from "../../../../data/filters";
import BaseMapLayer from "../base-layer";

const TILE_SERVICE_URL = process.env.REACT_APP_TILE_SERVICE_URL;

export class BuildingsLayer extends BaseMapLayer {
  constructor({ id, map, popupCtrl, sourceLayer }) {
    super();
    this.sourceLayer = sourceLayer;
    this.sourceId = `${id}-data`;
    this.layerId = `${id}-layer`;
    this.map = map;
    this.popupCtrl = popupCtrl;
    this.color = "#fff";
    this.visible = null;
  }

  init() {
    const url = `https://${TILE_SERVICE_URL}/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=usa:${this.sourceLayer}&STYLE=&TILEMATRIX=EPSG:900913:{z}&TILEMATRIXSET=EPSG:900913&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`;

    const layer = {
      id: this.layerId,
      source: {
        type: "vector",
        tiles: [url],
      },
      type: "fill-extrusion",
      minzoom: 7,
      paint: {
        "fill-extrusion-color": this.color,
        "fill-extrusion-height": [
          "interpolate",
          ["linear"],
          ["zoom"],
          10,
          0,
          10.05,
          3,
        ],
        "fill-extrusion-base": [
          "interpolate",
          ["linear"],
          ["zoom"],
          10,
          0,
          10.05,
          0,
        ],
        "fill-extrusion-opacity": 0.6,
      },
      "source-layer": this.sourceLayer,
    };

    if (this.popupCtrl) {
      this.map.on("click", this.layerId, (e) => {
        console.log(e);
        if (e.features[0]) {
          const { properties } = e.features[0];

          this.popupCtrl.render({ properties, center: e.lngLat });
        }
      });
    }

    this.map.addLayer(layer);

    return this;
  }

  setFilter(filters) {
    const filtersFields = Object.values(filters).map((item) => item.filter);

    const newFilters = [];

    filtersFields.forEach((filter, index) => {
      const value = filters[index].value;

      if (!value) {
        this.map.setFilter(this.layerId, null);
      } else {
        if (Array.isArray(value)) {
          switch (filter) {
            case "commercial":
              newFilters.push(["in", "building", ...value]);
              break;
            case "residental":
              newFilters.push(["in", "building", ...value]);
              break;
            case "industrial":
              newFilters.push(["in", "landuse", ...value]);
              break;
            default:
              break;
          }
        } else {
          switch (filter) {
            case "city":
              newFilters.push(["==", ["get", "locality"], value || null]);
              const city = Object.values(cities)?.find((el) => el.name === value);
              this.map?.flyTo({ center: [city.lng, city.lat] });
              break;
            case "amenity":
            case "building":
              newFilters.push(["==", ["get", filter], value || null]);
              break;
            default:
              newFilters.push(["==", ["get", filter], value || null]);
              break;
          }
        }
      }
    });
    this.map.setFilter(this.layerId, ["all", ...newFilters]);
  }
}
