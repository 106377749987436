import { Select, Checkbox, Button } from "antd";
import { useContext } from "react";
import { MapContext } from "../../context/map-context";
import { LayersContext } from "../../context/layers-context";
import "./index.scss";

const { Option } = Select;

export const Drawer = ({ parcelnumb, setParcelnumb }) => {
  const [filters, setFilters] = useContext(MapContext);
  const [layers, setLayers] = useContext(LayersContext);

  const onChange = (value, index) => {
    const newFilters = [...filters];
    newFilters[index].value = value;
    setFilters(newFilters);
  };

  const changeLayersFilter = (value, index) => {
    const newLayers = [...layers];
    newLayers[index].value = value;
    setLayers(newLayers);
  };
  return (
    <div className="drawer">
      <div className="drawer-title">Layers</div>
      <div className="drawer-layers">
        {layers.map(({ filter, value }, index) => {
          return (
            <div className="drawer-filters-item">
              {filter}
              {value}
              <Checkbox
                value={value}
                checked={value}
                onChange={(e) =>
                  changeLayersFilter(e.target.checked ? true : false, index)
                }
              />
            </div>
          );
        })}
      </div>
      {parcelnumb && (
        <div className="drawer-filters-item">
          Parcelnumb: {parcelnumb}
          <Button onClick={() => setParcelnumb(null)} type="primary" size="small">X</Button>
        </div>
      )}

      <div className="drawer-title">Filters</div>
      <div className="drawer-filters">
        {filters.map(({ filter, options, type }, index) => (
          <div className="drawer-filters-item" key={index}>
            <div className="drawer-filters-item-title">{filter}</div>
            {type !== "checkbox" ? (
              <Select
                allowClear
                showSearch
                style={{ width: 220 }}
                optionFilterProp="children"
                onChange={(value) => onChange(value, index)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {options?.map((option, index) => (
                  <Option value={option.name ? option.name : option} key={option.name+index}>
                    {option.name ? option.name : option}
                  </Option>
                ))}
              </Select>
            ) : (
              <Checkbox
                value={options}
                key={index + 1}
                onChange={(e) =>
                  e.target.checked
                    ? onChange(e.target.value, index)
                    : onChange(null, index)
                }
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
