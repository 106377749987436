import mapboxgl from "mapbox-gl";

export default class BaseMapLayer extends mapboxgl.Evented {
  constructor() {
    super();
    this.popup = null;
    this.visible = null;
  }

  flyTo(zoom) {
    this.map?.on("click", this.layerId, (e) => {
      const center = e.lngLat.toArray();
      this.map.flyTo({
        center,
        zoom,
        speed: 0.8,
        essential: true
      });
    });
  }

  add() {
    this.addSource();
    this.addLayer();
  }

  remove() {
    this.removeLayer();
    this.removeSource();
  }

  removeLayer() {
    if (this.getLayer()) {
      return this.map.removeLayer(this.layerId);
    }
  }

  removeSource() {
    if (this.getSource()) {
      this.map.removeSource(this.sourceId);
    }
  }

  getSource() {
    return this.map.getSource(this.sourceId);
  }

  getLayer() {
    return this.map.getLayer(this.layerId);
  }

  getRenderedFeatures() {
    if (this.getLayer())
      return this.map.queryRenderedFeatures({ layers: [this.layerId] });
  }

  show() {
    if (this.getLayer() && (!this.visible || this.visible === null)) {
      this.map.setLayoutProperty(this.layerId, "visibility", "visible");
      this.visible = true;
    }
  }

  hide() {
    if (this.getLayer() && (this.visible || this.visible === null)) {
      this.map.setLayoutProperty(this.layerId, "visibility", "none");
      this.visible = false;
    }
  }

  setParcelFilter(parcelnumb) {
    if (!parcelnumb) {
      this.popupCtrl.remove();
      return this.map.setFilter(this.layerId, [
        "!=",
        ["get", "landgrid_parcelnumb"],
        999999999999999 
      ]);
    }
    this.map.setFilter(this.layerId, [
      "==",
      ["get", "landgrid_parcelnumb"],
      parcelnumb || null
    ]);
  }
}
