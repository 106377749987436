import { Map } from "./features/map";
import { Drawer } from "./ui";
import { MapContext } from "./context/map-context";
import { LayersContext } from "./context/layers-context";
import { useState } from "react";
import "./App.css";

import "antd/dist/antd.css";
import "mapbox-gl/dist/mapbox-gl.css";

import { layersData, selectFiltersData } from "./data/filters";

function App() {
  const [filters, setFilters] = useState(selectFiltersData);
  const [layers, setLayers] = useState(layersData);
  const [parcelnumb, setParcelnumb] = useState();

  return (
    <MapContext.Provider value={[filters, setFilters]}>
      <LayersContext.Provider value={[layers, setLayers]}>
        <div className="app">
          <Drawer parcelnumb={parcelnumb} setParcelnumb={setParcelnumb} />
          <Map parcelnumb={parcelnumb} setParcelnumb={setParcelnumb} />
        </div>
      </LayersContext.Provider>
    </MapContext.Provider>
  );
}

export default App;
